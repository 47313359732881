import { LABEL_MAP } from "___REFACTOR___/constants";
import { Location, LocationProps } from "../Location";

class LoadLocation extends Location {
  static label = LABEL_MAP.loadLocation;
  static get Editor() {
    return LocationProps.prototype.Editor;
  }
}

class LoadLocationProps extends LocationProps {}

LoadLocation.prototype.Props = LoadLocationProps;
LoadLocationProps.prototype.label = LABEL_MAP.loadLocation;

export { LoadLocation, LoadLocationProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface LoadLocation {
  Props: typeof LoadLocationProps;
  _: LoadLocationProps;
}
