import React, { ReactNode } from "react";
import classNames from "classnames";
import { Status } from "___REFACTOR___/models";
import { LABEL_MAP } from "___REFACTOR___/constants";
import "./Label.scss";

export function Label(props: Props) {
  let { name, label, children, asterisk, status } = props;
  if (label === "") return null;

  children = children || label || LABEL_MAP[name!];

  if (!children) return null;

  if (asterisk) children = `${children}*`;

  const className = classNames(status?.type);

  return <label className={className}>{children}</label>;
}

interface Props {
  type?: "Field" | "SomeOtherCommonSmallishLabel";
  children?: ReactNode;
  name?: string;
  label?: ReactNode;
  asterisk?: boolean;
  status?: Status;
}
