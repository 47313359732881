import { action, makeObservable, observable } from "mobx";
import { TradeAPI, tradeAPI } from "___REFACTOR___/apis";
import { monitorChange } from "___REFACTOR___/utils";
import { EmailList } from "___REFACTOR___/models/Trade";

class EmailLists {
  constructor() {
    this.arr = [];

    makeObservable(this, { arr: observable.ref, getData: action, setData: action });
  }

  async getData() {
    const res = await tradeAPI.getEmailLists();

    if (!res.ok) return;

    this.arr = res.data.map(EmailList.new);

    return res;
  }

  setData(data: TradeAPI.EmailList[]) {
    this.arr = data.map(EmailList.new);
  }

  async monitorChange(predicate: monitorChange.Predicate<TradeAPI.EmailList[] | undefined>, config?: monitorChange.Config) {
    const getData = async () => {
      const res = await tradeAPI.getEmailLists();

      if (!res.ok) return this.arr;

      return res.data;
    };

    return monitorChange<TradeAPI.EmailList[] | undefined>(predicate, getData, config);
  }
}

const emailLists = new EmailLists();

export { emailLists };

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

interface EmailLists {
  arr: EmailList[];
}
