import { IUnitValue } from "./IUnitValue";
import { IKeyValuePair } from "./IKeyValuePair";

export type FreightRateUnit = "PerMT" | "LumpSum";

export type IFreightRate = IUnitValue<FreightRateUnit>;

export const FreightRateUnitDisplay: { [key in string]: string } = {
  PerMT: "Per MT",
  LumpSum: "Lump Sum",
};

export const FreightRateUnitShortDisplay: {
  [key in string]: string;
} = {
  PerMT: "M/T",
  LumpSum: "LS",
};

export const FreightRateOptions: IKeyValuePair[] = [
  { key: "LumpSum", value: "Lump Sum" },
  { key: "PerMT", value: "Per MT" },
];
