class PromiseBox<T = any> {
  // @ts-ignore
  resolve: (value: T) => void;
  // @ts-ignore
  reject: (reason?: T) => void;
  promise = new Promise<T>((resolve, reject) => {
    this.resolve = resolve;
    this.reject = reject;
  });
}

export { PromiseBox };
