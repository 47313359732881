// import { DEVELOPMENT } from "@/config";
import { legacy } from "@/legacy";
import moment from "moment";

type EventName =
  | OrderCreationTypeEvents
  | DistributionTypeEvents
  | NegotiationTypeEvents
  | MainTermsTypeEvents
  | SeaChatEvents
  | OrderArchiveEvents
  | WithdrawEvents; // union type of another enums per feature

export enum OrderCreationTypeEvents {
  ORDER_CREATION_STARTED = "Order creation started",
  STANDARD_VOY = "Standard Voy order created",
  STANDARD_TCT = "Standard Tct order created",
  STANDARD_TCT_AND_VOY = "Standard Tct and Voy order created",
  STANDARD_COA = "Standard Coa order created",
  CARGO_TYPE_SELECTED = "Order Cargo Type Selected",
  DEAL_CAPTURE_VOY = "Deal Capture Voy order created",
  DEAL_CAPTURE_TCT = "Deal Capture Tct order created",
  DEAL_CAPTURE_COA = "Deal Capture Coa order created",
  TEMPLATE_SELECTED = "Order Template Selected",
}

export enum DistributionTypeEvents {
  DISTRIBUTE_NEGOTIATE_INITIAL = "Neg - Distribute and NIT",
  DISTRIBUTE_ABSENT_OWNER = "Neg - Absent Owner ",
  DISTRIBUTE_TC_MAIN_TERMS = "Neg - TC Main Terms",
  DISTRIBUTE_VOY_MAIN_TERMS = "Neg - VOY Main Terms",
  DISTRIBUTE_COA_MAIN_TERMS = "Neg - COA Main Terms",
  SELECT_DISTRIBUTION_USERS_START = "Distribution users selection started",
  SELECT_DISTRIBUTION_USERS_FINISHED = "Distribution users selection finished",
  DISTRIBUTE_ORDER_ABSENT_OWNER = "Absent Owner - Distribute",
  DISTRIBUTE_ORDER = "Distribute",
  DISTRIBUTE_ORDER_MIXED_USERS = "Mixed Users - Distribute",
  MANAGE_DISTRIBUTION_LISTS = "Manage Distribution Lists",
  CREATE_NEW_LIST = "Create Distribution List",
  CREATE_NEW_LIST_CONFIRM = "Create Distribution List - Create",
}

export enum NegotiationTypeEvents {
  SEND_OWNER_INDICATION = "NIT - Chart - Send Owner Indication",
  REQUEST_FIRM = "NIT - Chart - Request Firm Offer ",
  SEND_FIRM_BID = "NIT - Chart - Send firm Bid",
  SEND_INDICATION_ONLY = "NIT - Owner - Indication only",
  SEND_FIRM_OFFER = "NIT - Owner - Firm Offer",
  ACCEPT_FIRM_BID = "Accept Firm Bid",
  ACCEPT_FIRM_OFFER = "Accept Firm Offer",
  PROCEEED_TO_MAIN_TERMS = "Order Proceed to Main Terms",
  KEBAB_PROCEEED_TO_MAIN_TERMS = "Order Proceed to Main Terms through kebab menu",
  VIEW_IN_CONTRACTS = "View in Sea Contracts",
  SUBS_LIFT_OPERATIONAL = "Manage Subs Lift Operational",
  SUBS_LIFT_COMMERCIAL = "Manage Subs Lift Commercial",
  SUBS_LIFT_AND_SUBJECT = "Manage Subs Lift and Subject to CP",
  SUBS_LIFT_AND_CLEAN_FIX = "Manage Subs Lift and Clean Fix",
  FAIL_SUBS_CONFIRM = "Fail Subs - Confirm",
  COPY_INDICATION = "Trade - Copy Indication confirmed",
  COPY_INDICATION_CANCELLED = "Trade - Copy Indication cancelled",
  NOMINATE_VESSEL = "Neg - Nom Ves - Nominate",
  NOMINATE_VESSEL_CONFIRM = "Neg - Nom Ves - Accept Now",
  NOMINATE_VESSEL_ACCEPT = "Neg - Nom Ves - Accept Now",
  NOMINATE_VESSEL_SEA_NET = "Neg - Nom Ves - Sea Net",
  NOMINATE_VESSEL_SEA_ANALYTICS = "Neg - Nom Ves - Sea Analytics",
  USE_INDICATION = "Neg - Use Indication",
  SEND_NOTE = "Neg - Send a Note",
  EXPORT_NEG_CSV = "Neg - Export All Neg as CSV",
}

export enum MainTermsTypeEvents {
  MAIN_TERMS_OPENED = "Open Main Terms",
  CP_TERMS_OPENED = "Open CP Terms",
  MAIN_TERMS_STARTED = "Main terms started",
  MAIN_TERMS_CONTINUE = "Main terms continue",
  MAIN_TERMS_SEND_FIRM_BID = "Main terms send firm bid",
  MAIN_TERMS_SEND_ON_SUBS = "Main terms on subs",
  MAIN_TERMS_SEND_CLEAN_FIXED = "Main terms clean fixed",
  TERMSET_SELECTED = "Terms Builder - Termset Set",
  PREV_EXECTUTED_CHARTER_PARTY_SELECTED = "Terms Builder - Previously Executed Set",
  NOMINATE_VESSEL = "Terms Builder - Nominate Vessel",
  ADD_TERM = "Terms Builder - Add Term",
}

export enum SeaChatEvents {
  SEA_CHAT_BTN_LEFT_SIDE_MENU = "Main chat button clicked",
  SEA_CHAT_BTN_NEGOTIATION_SIDE_MENU = "Chat opened from negotiation row",
  SEA_CHAT_BTN_ORDER_ROW = "Chat icon on order clicked",
  SEA_CHAT_CONTEXT_MENU = "Chat opened from order row",
}

export enum OrderArchiveEvents {
  SINGLE_ORDER_ARCHIVE_START = "Single order archive started",
  SINGLE_ORDER_ARCHIVE_FINISH = "Single order archive finished",
  SINGLE_ORDER_UNARCHIVE_START = "Single order unarchive started",
  SINGLE_ORDER_UNARCHIVE_FINISH = "Single order unarchive finished",
  BULK_ORDER_ARCHIVE_START = "Bulk orders archive started",
  BULK_ORDER_ARCHIVE_FINISH = "Bulk orders archive finished",
  BULK_ORDER_UNARCHIVE_START = "Bulk orders unarchive started",
  BULK_ORDER_UNARCHIVE_FINISH = "Bulk orders unarchive finished",
  ARCHIVE_OPERATION_FAILED = "Archive operation failed",
  UNARCHIVE_OPERATION_FAILED = "Unarchive operation failed",
  NEG_ARCHIVE_START = "Negotiation archive started",
  NEG_ARCHIVE_FINISH = "Negotiation archive finished",
  NEG_UNARCHIVE_START = "Negotiation unarchive started",
  NEG_UNARCHIVE_FINISH = "Negotiation unarchive finished",
  BULK_NEG_ARCHIVE_START = "Bulk Negotiation archive started",
  BULK_NEG_ARCHIVE_FINISH = "Bulk Negotiation archive finished",
  BULK_NEG_UNARCHIVE_START = "Bulk Negotiation unarchive started",
  BULK_NEG_UNARCHIVE_FINISH = "Bulk Negotiation unarchive finished",
}

export enum WithdrawEvents {
  ORDER_WITHDRAWAL_START = "Order Withdrawal started",
  ORDER_WITHDRAWAL_FINISH = "Order Withdrawal finished",
  ORDER_WITHDRAWAL_FAILED = "Order Withdrawal failed",
  ORDER_WITHDRAWAL_BY_ANOTHER_USER = "Order Withdrawal started by another user",
  NEG_WITHDRAWAL_START = "Negotiation Withdrawal started",
  NEG_WITHDRAWAL_FINISH = "Negotiation Withdrawal finished",
  NEG_WITHDRAWAL_FAILED = "Negotiation Withdrawal failed",
}

interface IPayload {
  companyId: string;
  [key: string]: unknown;
}

type TrackEvent = (eventName: EventName, payload?: object, sendImmediately?: boolean) => void;

type SeaTracker = {
  trackEvent: TrackEvent;
};

interface IUsageMetrics {
  trackEvent: TrackEvent;
}

class UsageMetrics implements IUsageMetrics {
  trackEvent: TrackEvent = (eventName, payload, sendImmediately) => {
    const seaTracker: SeaTracker | undefined = window?.seaTracker;
    // if (DEVELOPMENT) console.log(`"${eventName}" tracked`);
    // I want to keep this here for when we're adding more events in the future
    if (seaTracker) {
      const companyId = legacy.user.CompanyId;
      const newPayload: IPayload = payload ? { companyId, ...payload } : { companyId };

      seaTracker.trackEvent(`Trade: ${eventName}` as EventName, newPayload, sendImmediately);
    } else {
      console.error(
        "UsageMetrics: There is no seaTracker in global object. Check if moduleId has been added to sea header config."
      );
    }
  };

  // @param archiveEvent
  // @param entityId
  // @param archivedCount
  // @returns start measure utc time

  startTrackingAction = (eventName: EventName, entityId: string[] | string | undefined, archivedCount = 1) => {
    const startTimeUtc = moment.utc();
    usageMetrics.trackEvent(eventName, { entityId: entityId, count: archivedCount });
    return startTimeUtc;
  };

  finishTrackingAction = (
    eventName: EventName,
    startTime: moment.Moment,
    entityId: string[] | string | undefined,
    archivedCount = 1,
    payload?: object
  ) => {
    const finishTimeUtc = moment.utc();
    const elapsedDurationUtc = moment.duration(finishTimeUtc.diff(startTime)).asSeconds();
    if (payload) {
      usageMetrics.trackEvent(eventName, {
        "Total Time In Seconds": elapsedDurationUtc,
        count: archivedCount,
        entityId,
        payload,
      });
    } else {
      usageMetrics.trackEvent(eventName, {
        "Total Time In Seconds": elapsedDurationUtc,
        count: archivedCount,
        entityId,
      });
    }
  };
}

export const usageMetrics = new UsageMetrics();
