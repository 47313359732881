import { useState, memo } from "react";
import { ElementHOC } from "../Element";
import "./RadioDateTimezone.scss";

function RadioDateTimezone(props) {
  const [showWarning, setShowWarning] = useState(false);
  const { element } = props;
  const { timezone, date, radio, bottomText, children } = element;
  const Date = ElementHOC({
    ...date,
    type: "Date",
    onChangeCallback: (newDate) => {
      const today = new window.Date();
      today.setHours(0, 0, 0, 0);
      setShowWarning(newDate < today);
    },
  });
  const Timezone = ElementHOC({ ...timezone, type: "Timezone" });
  const Radio = ElementHOC({ ...radio, type: "Radio" });

  return (
    <div className="RadioDateTimezone">
      {Radio}
      <div className="RadioDateTimezone-content">
        <div className="RadioDateTimezone-row">
          <div>Charter Party Date:</div>
          {Date}
        </div>
        <div className="RadioDateTimezone-row">
          <div>Time Zone:</div>
          {Timezone}
        </div>
        {showWarning && <div className={"RadioDateTimezone-warning"}>The date chosen is in the past</div>}
        <div>{children?.map((element) => ElementHOC(element))}</div>
      </div>
      {bottomText && <div className="RadioDateTimezone-bottomText">{bottomText}</div>}
    </div>
  );
}

const Memo = memo(RadioDateTimezone);

export { Memo as RadioDateTimezone };
