import { TextareaString, TextareaStringProps } from "../TextareaString";
import { schema } from "./schema";

class OrderNotes extends TextareaString {}
class OrderNotesProps extends TextareaStringProps {
  get schema() {
    return schema;
  }
}

OrderNotes.prototype.Props = OrderNotesProps;
OrderNotesProps.prototype.label = "Notes";

/* -------------------------------------------------------------------------- */
/*                               VOYNotes                                     */
/* -------------------------------------------------------------------------- */

class VOYNotes extends OrderNotes {
  static label = "Voyage Notes";
}
class VOYNotesProps extends OrderNotesProps {}

VOYNotes.prototype.Props = VOYNotesProps;
VOYNotesProps.prototype.label = "Voyage Notes";

/* -------------------------------------------------------------------------- */
/*                               TCTNotes                                     */
/* -------------------------------------------------------------------------- */

class TCTNotes extends OrderNotes {
  static label = "TC Notes";
}
class TCTNotesProps extends OrderNotesProps {}

TCTNotes.prototype.Props = TCTNotesProps;
TCTNotesProps.prototype.label = "TC Notes";

/* -------------------------------------------------------------------------- */
/*                               COANotes                                     */
/* -------------------------------------------------------------------------- */

class COANotes extends OrderNotes {
  static label = "COA Notes";
}
class COANotesProps extends OrderNotesProps {}

COANotes.prototype.Props = COANotesProps;
COANotesProps.prototype.label = "COA Notes";

export { OrderNotes, OrderNotesProps, VOYNotes, VOYNotesProps, TCTNotes, TCTNotesProps, COANotes, COANotesProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface OrderNotes {
  Props: typeof OrderNotesProps;
  _: OrderNotesProps;
}

interface VOYNotes {
  Props: typeof VOYNotesProps;
  _: VOYNotesProps;
}

interface TCTNotes {
  Props: typeof TCTNotesProps;
  _: TCTNotesProps;
}

interface COANotes {
  Props: typeof COANotesProps;
  _: COANotesProps;
}
