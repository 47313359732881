import { OrderNegotiationStore } from "../OrderNegotiationStore";

class COAOrderNegotiationStore extends OrderNegotiationStore {
  supportedOrderType = {
    Coa: true,
  };

  orderDataQuery = new URLSearchParams({ type: "coa" });
}

export const coaOrderNegotiationStore = new COAOrderNegotiationStore();
