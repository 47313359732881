import React from "react";
import { useParams } from "react-router-dom";
import styles from "./OrderNegs.module.scss";
import LoadingSpinner from "sharedFolder/components/common/LoadingSpinner/LoadingSpinner";
import { InfoMessage } from "sharedFolder/components/common/InfoMessage/InfoMessage";
import Liftings from "negotiations/components/Liftings/Liftings";
import { DismissableNotificationProvider } from "negotiations/components/Liftings/DismissableNotificationContext";
import { NegotiationsByOrderIdProvider } from "sharedFolder/contexts/NegotiationsByOrderId.context";
import { useOrder } from "../__legacy/orderNegs/useOrder";

export const OnlyLiftingsOrderNegs = (props: any) => {
  const { config } = props;
  const ctradeUrl = config.ctradeUrl;
  // @ts-ignore
  const { orderId } = useParams();
  // @ts-ignore
  const { orderSummaryState, orderSummary } = useOrder(ctradeUrl, orderId);

  if (orderSummaryState === "error") {
    return <InfoMessage title="Order Not Found" message="Order/Negotiation not found. Please contact support@sea.live" />;
  }

  if (orderSummaryState === "loading") {
    return (
      <div className={styles.spinnerContainer}>
        <LoadingSpinner loadingText="Loading..." />
      </div>
    );
  }

  if (orderSummary && orderSummary.status === "Archived") {
    return <InfoMessage title="Order Archived" message="This order has been archived and can no longer be accessed" />;
  }

  return (
    <NegotiationsByOrderIdProvider orderId={orderId}>
      <DismissableNotificationProvider>
        {orderSummary && <Liftings orderId={orderId!} orderStatus={orderSummary.status} {...config} />}
      </DismissableNotificationProvider>
    </NegotiationsByOrderIdProvider>
  );
};
