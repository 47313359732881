import { ITimerCount } from "sharedFolder/components/common/interfaces";

export function formatStatusCountDown(countDown: ITimerCount | undefined) {
  function unitIsZero(unit: string) {
    return Number(unit) === 0;
  }

  function removeLeadingZeros(value: string) {
    return value.replace(/^0+/, "");
  }

  if (!countDown || countDown.Expired) {
    return null;
  }

  if (countDown.Days && !unitIsZero(countDown.Days)) {
    return `< ${Number(removeLeadingZeros(countDown.Days)) + 1} days`;
  } else if (countDown.Hours && !unitIsZero(countDown.Hours) && Number(countDown.Hours) >= 1) {
    return `< ${Number(removeLeadingZeros(countDown.Hours)) + 1} hrs`;
  } else if (countDown.Minutes && !unitIsZero(countDown.Minutes) && Number(countDown.Minutes) <= 60) {
    return `${removeLeadingZeros(countDown.Minutes)} mins`;
  } else if (countDown.Seconds && !unitIsZero(countDown.Seconds) && Number(countDown.Seconds) <= 60) {
    return `${removeLeadingZeros(countDown.Seconds)}s`;
  } else {
    return null;
  }
}
