import { LABEL_MAP } from "___REFACTOR___/constants";
import { Commission, CommissionProps } from "../Commission";

class BrokerCommission extends Commission {
  static label = LABEL_MAP.brokerCommission;
}

class BrokerCommissionProps extends CommissionProps {
  get label() {
    return LABEL_MAP.brokerCommission;
  }
}

BrokerCommission.prototype.Props = BrokerCommissionProps;

export { BrokerCommission, BrokerCommissionProps };
