import React, { useEffect, useState } from "react";
import { Button } from "@/components";
import "./Details.scss";

const lastViewedPublishedVersionKey = "LAST_VIEWED_PUBLISHED_VERSION";

export function AcceptChartererSuggestionsButton({
  acceptChartererSuggestions,
  version = 0,
  negotiationId,
}: {
  negotiationId: string;
  acceptChartererSuggestions: () => Promise<void>;
  version?: number;
}) {
  const [highlightUseChartererSuggestions, setHighlightUseChartererSuggestions] = useState<
    "Disabled" | "Enabled" | "Highlighted"
  >("Disabled");
  const key = `${lastViewedPublishedVersionKey}-${negotiationId}`;

  useEffect(() => {
    const lastRecordedNegVersion = localStorage.getItem(key) || 0;
    if (version !== 0) {
      if (version) {
        const versionIsNewerThanLastViewed = lastRecordedNegVersion < version;
        setHighlightUseChartererSuggestions(versionIsNewerThanLastViewed ? "Highlighted" : "Enabled");
      }
    } else {
      setHighlightUseChartererSuggestions("Disabled");
      localStorage.setItem(key, String(0));
    }
  }, [version, key]);

  const onClick = async () => {
    await acceptChartererSuggestions();
    localStorage.setItem(key, String(version));
    setHighlightUseChartererSuggestions("Enabled");
  };

  return (
    <Button
      icon="visibility"
      className={highlightUseChartererSuggestions === "Highlighted" ? "reveal-button-highlighted" : "reveal-button"}
      variant="flat"
      tooltip="Charterer Response"
      onClick={onClick}
      dataTest={"accept-charterer-suggestions-btn"}
      disabled={highlightUseChartererSuggestions === "Disabled"}
    >
      charterer response
    </Button>
  );
}
