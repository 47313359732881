import { object, string, number } from "yup";
import { VesselSize } from "../VesselSize";

// https://github.com/jquense/yup
const schema = object({
  vesselSizeFull: string().required("Required"),
  vesselSizeAbbreviation: string().required("Required"),
  sizeFrom: number().integer().required("Required"),
  sizeTo: number().integer().required("Required"),
  notes: string(),
}).transform(toJSON);

function toJSON(vesselSize: VesselSize) {
  return vesselSize?.toJSON();
}

export { schema };
