// BLACKBOX
import {
  deleteOrderAttachment,
  downloadOrderAttachment,
  uploadOrderAttachment,
} from "__legacy/negotiations/services/attachments";
import { FetchInterceptor } from "__legacy/dashboard/components/FetchInterceptor/FetchInterceptorOld";
import { UserContext, UserProvider } from "__legacy/dashboard/contexts/UserProvider";
import { Attachments } from "__legacy/sharedFolder/components/common/Attachments/Attachments";
import { ConfigurationContext } from "__legacy/sharedFolder/ConfigurationContext";

import { useContext, useState } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { tradeAPI as oldTradeAPI } from "@/apis";
import { history } from "@/history";
import { config } from "@/config";
import { isTruthy, uid } from "@/utils";
import { auth, dialog, Negotiation, Order, router } from "@/models";
import { Button, CheckboxInput as Checkbox, RadioInput as Radio, SummaryDetails } from "@/components";
import { DistributionTypeEvents, usageMetrics } from "@/services/UsageMetrics";
import { Context } from "../../../Orders";
import "./Initialization.scss";
import { getDetailArrayForDisplay } from "@/models/Order/utils";

function Initialization() {
  const { user } = auth?.trade;
  const [addAttachments, setAddAttachments] = useState<boolean>(false);
  const [radioValue, setRadioValue] = useState<any>(null);
  const context = useContext(Context);
  const { orderId } = useParams<StringRecord>();
  const { orderNegotiationStore } = context;
  const order = orderId ? orderNegotiationStore?.upsertOrderModel({ id: orderId }) : undefined;
  const distributionRoute = router.active.parent?.get("distribution");
  const circulationRoute = router.active.parent?.get("circulate");
  const mainTermsRoute = router.get("mainTerms");
  const hasVoy = order?.details?.originallySelectedTypes?.includes("Voy");
  const hasTct = order?.details?.originallySelectedTypes?.includes("Tct");
  const hasCoa = order?.details?.originallySelectedTypes?.includes("Coa");
  const toVoyMainTerms = mainTermsRoute?.compile({ orderId, negotiationId: `$${uid()}`, type: "Voy" });
  const toTctMainTerms = mainTermsRoute?.compile({ orderId, negotiationId: `$${uid()}`, type: "Tct" });
  const toCoaMainTerms = mainTermsRoute?.compile({ orderId, negotiationId: `$${uid()}`, type: "Coa" });
  const toDistribution = distributionRoute?.compile({ orderId });
  const toCirculation = circulationRoute?.compile({ orderId, noOfUsers: order?.distributionList?.length });
  const entries = Object.entries(getDetailsByType(order));
  const canAddOrDelete = !user?.CompanyRoles?.includes("broker");
  const isDealCapture = order?.details?.isDealCapture;
  let to = radioValue?.to;
  const isWithdrawn = order?.status === "Withdrawn";
  const withdrawalReason = order?.withdrawalState?.reason;
  let Refactor: Await<ReturnType<typeof resolveRefactorExports>>;
  const resolveRefactorExports = async () => ({
    ...(await import("___REFACTOR___/apis/Trade")),
  });
  resolveRefactorExports().then((exports) => (Refactor = exports));

  if (!order?.details) return null;

  if (isDealCapture) {
    const types = order.getAllTypes();

    if (types?.[0] === "Voy") to = router.get("captureMainTerms")?.compile({ orderId, negotiationId: `$${uid()}`, type: "Voy" });
    if (types?.[0] === "Tct") to = router.get("captureMainTerms")?.compile({ orderId, negotiationId: `$${uid()}`, type: "Tct" });
    if (types?.[0] === "Coa") to = router.get("captureMainTerms")?.compile({ orderId, negotiationId: `$${uid()}`, type: "Coa" });
  }

  return (
    <div className="order-initialization" data-order-model-version={order?._.version}>
      {entries.map(
        ([type, details]) =>
          // @ts-ignore
          !!details.length && (
            <div className="order-initialization-order-summary" key={type}>
              <h2>{TYPE_LABEL_MAP[type]} Terms</h2>
              <SummaryDetails skinny list={details} />
            </div>
          )
      )}
      {!isWithdrawn && (
        <div>
          {canAddOrDelete && (
            <>
              <div className="order-initialization-inputs">
                <Checkbox
                  value={addAttachments}
                  onChange={() => setAddAttachments(!addAttachments)}
                  dataTest="Add order attachments"
                />
                <span>Add attachments to order</span>
              </div>
              {addAttachments && order && (
                <div className="LEGACY_CONTAINER">
                  <FetchInterceptor baseUrl={config.ctradeUrl} exceptions={["/token$"]}>
                    <ConfigurationContext.Provider value={config}>
                      <UserProvider config={config}>
                        <UserContext.Consumer>
                          {(user) => (
                            <Attachments
                              areOrderLevelAttachments={true}
                              attachments={order?.attachments as any}
                              userRoles={user.companyRoles}
                              onDelete={(fileId: string) =>
                                deleteOrderAttachment(config.ctradeUrl, order.id, fileId, order.updateToken as any)
                              }
                              onDownload={(fileId: string, fileName: string) =>
                                downloadOrderAttachment(order.id)(fileId, fileName, order.updateToken as any)
                              }
                              onUpload={(formData: FormData) =>
                                uploadOrderAttachment(order.id, config.ctradeUrl)(formData, order.updateToken as any)
                              }
                            />
                          )}
                        </UserContext.Consumer>
                      </UserProvider>
                    </ConfigurationContext.Provider>
                  </FetchInterceptor>
                </div>
              )}
            </>
          )}

          <div className="order-initialization-links" hidden={isDealCapture}>
            <div className="order-initialization-inputs">
              <Radio
                value={radioValue?.type === "initial"}
                onChange={() => setRadioValue({ type: "initial", to: toDistribution })}
                dataTest="distributeRadio"
              />
              <span>Distribute order and negotiate initial terms (e.g. freight rate, demurrage and laycan)</span>
            </div>
            <div className="order-initialization-inputs">
              <Radio
                value={radioValue?.type === "absentOwner"}
                onChange={() => setRadioValue({ type: "absentOwner", to: toCirculation })}
                dataTest="absent-owner-radio"
              />
              <span>Create absent owner negotiation</span>
            </div>
            {hasVoy && (
              <div className="order-initialization-inputs">
                <Radio
                  value={radioValue?.type === "voy"}
                  onChange={() => setRadioValue({ type: "voy", to: toVoyMainTerms })}
                  dataTest="voyage-mainterms-radio"
                />
                <span>Negotiate Voyage Main Terms (full termset)</span>
              </div>
            )}
            {hasTct && (
              <div className="order-initialization-inputs">
                <Radio
                  value={radioValue?.type === "tc"}
                  onChange={() => setRadioValue({ type: "tc", to: toTctMainTerms })}
                  dataTest="order-main-terms-radio"
                />
                <span>Negotiate TC Main Terms (full termset)</span>
              </div>
            )}
            {hasCoa && (
              <div className="order-initialization-inputs">
                <Radio
                  value={radioValue?.type === "coa"}
                  onChange={() => setRadioValue({ type: "coa", to: toCoaMainTerms })}
                  dataTest="coa-main-terms-radio"
                />
                <span>Negotiate COA Main Terms (full termset)</span>
              </div>
            )}
          </div>
        </div>
      )}

      <Button variant="action" onClick={onContinueClick} disabled={!to} hidden={isWithdrawn} dataTest="continue-from-order">
        Continue
      </Button>
    </div>
  );

  async function onContinueClick() {
    if (radioValue?.type === "absentOwner") {
      const noExistingAbsentOwnerOnOrder = !order?.distributionList.find((user) => {
        if (user.email) return Negotiation.prototype.isAbsentOwnerEmail(user.email);
        if (user.actor.email) return Negotiation.prototype.isAbsentOwnerEmail(user.actor.email);
        else return undefined;
      });
      if (noExistingAbsentOwnerOnOrder) {
        const currentUserInDistribution = order?.distributionList?.find(
          (user) => user.knownUser?.systemUserId === auth.trade.user?.SystemUserId
        );
        const actor = currentUserInDistribution?.knownUser!;
        const res = await Refactor.tradeAPI.ownerAbsent.generateEmail.post();
        if (!res.ok) {
          const status = {
            type: "error",
            title: "Absent Owner Email Fetch Failure",
            dump: { res: res },
          } as Status;

          dialog.show({
            status,
            dataTest: "handle-absent-owner-email-fetch-fail",
          });
          return;
        }
        //@ts-ignore
        order?.distributionList.push({
          email: `1#${res.data.email}`,
          role: "owner",
          actor,
        });

        const distributionPayload = {
          distributionList: order?.distributionList,
          updateToken: order?.updateToken,
        };

        const distributionRes = await oldTradeAPI.postOrderDistribution(order?.id!, distributionPayload);

        if (!distributionRes.ok) {
          return;
        }
        to = circulationRoute?.compile({ orderId, noOfUsers: order?.distributionList.length });
      }
    }

    let event;

    if (to.includes("distribution")) {
      event = DistributionTypeEvents.DISTRIBUTE_NEGOTIATE_INITIAL;
    }
    if (to.includes("circulate")) {
      event = DistributionTypeEvents.DISTRIBUTE_ABSENT_OWNER;
    }
    if (to.includes("Voy")) {
      event = DistributionTypeEvents.DISTRIBUTE_VOY_MAIN_TERMS;
    }
    if (to.includes("Tct")) {
      event = DistributionTypeEvents.DISTRIBUTE_TC_MAIN_TERMS;
    }
    if (to.includes("Coa")) {
      event = DistributionTypeEvents.DISTRIBUTE_COA_MAIN_TERMS;
    }

    if (event) usageMetrics.trackEvent(event);

    history.push(to);
  }
}

const TYPE_LABEL_MAP = {
  Tct: "TC",
  Voy: "Voyage",
  Coa: "COA",
};

function getDetailsByType(order?: Order) {
  if (!order) return {};

  const {
    chartererAccount,
    laycan,
    cargoType,
    addressCommission,
    brokerCommission,
    cargoSize,
    loadLocation,
    dischargeLocation,
    voyageNotes,
    vesselSize,
    deliveryLocation,
    viaLocation,
    redeliveryLocation,
    duration,
    period,
    coaCargoSize,
    liftings,
    nominations,
    coaNotes,
    tctNotes,
  } = order.details || {};

  if (!order.getAllTypes().includes("Coa")) {
    const standard = {
      Order: getDetailArrayForDisplay({ chartererAccount, laycan, cargoType, addressCommission, brokerCommission }).filter(
        isTruthy
      ),
      Voy: getDetailArrayForDisplay({ cargoSize, loadLocation, dischargeLocation, voyageNotes }, { type: "Voy" }).filter(
        isTruthy
      ),
      Tct: getDetailArrayForDisplay(
        { vesselSize, deliveryLocation, viaLocation, redeliveryLocation, duration, tctNotes },
        { type: "Tct" }
      ).filter(isTruthy),
    };

    return standard;
  }

  const coa = {
    Coa: getDetailArrayForDisplay(
      {
        chartererAccount,
        period,
        liftings,
        nominations,
        cargoType,
        coaCargoSize,
        addressCommission,
        brokerCommission,
        loadLocation,
        dischargeLocation,
        coaNotes,
      },
      { type: "Coa" }
    ).filter(isTruthy),
  };

  return coa;
}

const Observer = observer(Initialization);

export { Observer as Initialization };
