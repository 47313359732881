import { emptyArrowFn } from "@/utils";
import { createContext } from "react";
import { empty } from "rxjs";
import { IOfferRepFormContext } from "./IOfferRepFormContext";

export const OfferRepNegotiationContext = createContext<IOfferRepFormContext>({
  offerRepApi: {
    firmBidAccept: () => empty(),
    sendOffer: () => empty(),
    sendOfferRepNote: () => empty(),
  },
  firmOrIndicationState: "INDICATION_ONLY",
  setFirmOrIndicationState: emptyArrowFn,
});
