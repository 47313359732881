import { useEffect, useRef } from "react";
import dayjs from "dayjs";
import classNames from "classnames";
import { capitalizeFirstLetter, isTruthy, timeleft, timepassed, toKebabCase, useEfficientTimeRerenderer } from "@/utils";
import {
  Account,
  AccountProps,
  BallastBonus,
  BallastBonusProps,
  CargoSize,
  CargoSizeProps,
  CargoType,
  CLDDU,
  COACargoSize,
  COACargoSizeProps,
  DataModel,
  DeliveryLocation,
  Demurrage,
  DemurrageProps,
  Desk,
  dialog,
  DischargeLocation,
  FreightRate,
  FreightRateProps,
  HireRate,
  HireRateProps,
  Laycan,
  LaycanProps,
  Liftings,
  LiftingsProps,
  LoadLocation,
  NegotiationTypeCount,
  OrderNegotiationStore,
  OwnerAccount,
  Period,
  PeriodProps,
  RedeliveryLocation,
  Vessel,
  VesselProps,
  Vessels,
  VesselSize,
  VesselSizeProps,
  ViaLocation,
  dropdown,
  Negotiation,
  Order,
  seaChatWidget,
  slideout,
} from "@/models";
import { Img, Icon, NegotiationAttachments, AggridColDef, AggridResolveDefParams, ImgProps, StripedLoader } from "@/components";
import { Actions } from "@/models/Negotiation/Actions";
import { vesselScoreStore } from "@/stores";
import { CargoSizeFilter } from "./CargoSizeFilter";
import { LocationZoneFilter } from "./LocationZoneFilter";
import { StageFilter } from "./StageFilter";
import { MultiValueHeaderCell } from "./MultiValueHeaderCell";
import { DeskFilter } from "./DeskFilter";
import { EMDASH, testSelectors } from "@/constants";
import { TradeAPI, VesselScoreAPI } from "@/apis";
import { usageMetrics, SeaChatEvents } from "@/services/UsageMetrics";
import { archiveRequestsLimit } from "sharedFolder/constants";
import "./columns.scss";
import { CheckboxSelectionCallbackParams } from "@ag-grid-community/core";
import { renderToStaticMarkup } from "react-dom/server";
// @ts-ignore
import { default as sanctionsLogo } from "@/static/images/PurpleTrac-logo.svg";
// @ts-ignore
import { default as inspectionsLogo } from "@/static/images/Rightship-logo.svg";

const standardAgSetColumnFilter = {
  filter: "agSetColumnFilter",
  comparator: standardAgSetColumnFilterComparator,
};

export function getOrderColumnMap() {
  return {
    checkbox: {
      field: "checkbox",
      headerName: "",
      checkboxSelection: (params: CheckboxSelectionCallbackParams) => {
        const selectedCount = params.api?.getSelectedRows().length;
        const isSelected = params.node.isSelected();
        if (isSelected) {
          return true;
        }
        if (selectedCount) {
          return selectedCount < archiveRequestsLimit;
        } else {
          return true;
        }
      },
      maxWidth: 30,
      suppressMenu: true,
      cellRenderer: (params) => {
        const order = params.data as OrderNegotiationStore["Order"];
        params.eGridCell.setAttribute("data-test", `${testSelectors.bulkSelectRowCheckbox}-${order.id}`);
      },
      valueGetter: (params) => {
        const tooltip = `Sorry you can't select more than ${archiveRequestsLimit} orders`;
        return { tooltip };
      },
    },
    accountAndOrderReference: {
      ...standardAgSetColumnFilter,
      ...resolveMultiValueColDef({
        sequence: [
          { name: "account", heading: AccountProps.prototype.label },
          { name: "orderReference", heading: "Order ID" },
        ],
      }),
      valueGetter: (params) => {
        const order = params.data as OrderNegotiationStore["Order"];

        return {
          account: order.summary?.chartererAccount,
          orderReference: order.summary?.orderReference,
        };
      },
      minWidth: 200,
    },
    cargoSizeAndCargoType: {
      ...standardAgSetColumnFilter,
      ...resolveMultiValueColDef({
        sequence: [
          { name: "cargoType", heading: "Cargo" },
          { name: "cargoSize", heading: "Quantity" },
        ],
      }),
      filter: "agMultiColumnFilter",
      filterParams: {
        filters: [
          {
            $reactFilter: CargoSizeFilter,
          },
          standardAgSetColumnFilter,
        ],
      },
      valueGetter: (params) => {
        const order = params.data as OrderNegotiationStore["Order"];
        let cargoSize;
        let cargoType;

        if (order.details?.cargoSize) {
          const value = order.details?.cargoSize[`${CargoSizeProps.prototype.sortKey}`];
          const text = CargoSize.prototype.toGridView.call(order.details?.cargoSize);

          cargoSize = { value, text };

          //
        } else if (order.details?.coaCargoSize) {
          const value = order.details?.coaCargoSize[`${COACargoSizeProps.prototype.sortKey}`];
          const text = COACargoSize.prototype.toGridView.call(order.details?.coaCargoSize);

          cargoSize = { value, text };

          //
        } else if (order.details?.vesselSize) {
          const value = order.details?.vesselSize[`${VesselSizeProps.prototype.sortKey}`];
          const text = VesselSize.prototype.toGridView.call(order.details?.vesselSize);

          cargoSize = { value, text };
        }

        if (order.details?.cargoType) {
          cargoType = CargoType.prototype.toGridView.call(order.details?.cargoType);
        }

        return {
          cargoSize,
          cargoType,
        };
      },
    },
    laycanStartAndLaycanEnd: {
      ...standardAgSetColumnFilter,
      ...resolveMultiValueColDef({
        sequence: [
          { name: "start", heading: LaycanProps.prototype.label + " Start" },
          { name: "end", heading: LaycanProps.prototype.label + " End" },
        ],
      }),
      valueGetter: (params) => {
        const order = params.data as OrderNegotiationStore["Order"];
        let start;
        let end;

        if (order.details?.laycan?.from || order.details?.laycan?.start) {
          const value = dayjs(order.details?.laycan?.from || order.details?.laycan?.start);

          start = { value, text: value.utc().format("DD MMM YY") };
        }

        if (order.details?.laycan?.to || order.details?.laycan?.end) {
          const value = dayjs(order.details?.laycan?.to || order.details?.laycan?.end);

          end = { value, text: value.utc().format("DD MMM YY") };
        }

        return {
          start,
          end,
        };
      },
    },
    liftingsAndPeriod: {
      ...standardAgSetColumnFilter,
      ...resolveMultiValueColDef({
        sequence: [
          { name: "liftings", heading: LiftingsProps.prototype.label },
          { name: "period", heading: PeriodProps.prototype.label },
        ],
      }),
      valueGetter: (params) => {
        const order = params.data as OrderNegotiationStore["Order"];
        let liftings;
        let period;

        if (order?.details?.liftings) {
          liftings = Liftings.prototype.toGridView.call(order?.details?.liftings);
        }

        if (order.details?.period) {
          const value = dayjs(order.details?.period?.start);
          const text = Period.prototype.toGridView.call(order.details?.period);

          period = { value, text };
        }

        return {
          liftings,
          period,
        };
      },
    },
    owningCompanyAndFixedOn: {
      ...standardAgSetColumnFilter,
      ...resolveMultiValueColDef({
        sequence: [
          { name: "owningCompany", heading: "Counterparty" },
          { name: "fixedOn", heading: "Fixed on" },
        ],
      }),
      valueGetter: (params) => {
        const order = params.data as OrderNegotiationStore["Order"];
        let fixedOn;

        if (order.fixedOn) {
          const value = dayjs(order.fixedOn);

          fixedOn = { value, text: value.format("DD MMM YY") };
        }

        return {
          owningCompany: order?.owningCompany,
          fixedOn,
        };
      },
    },
    loadLocationAndDischargeLocation: {
      ...standardAgSetColumnFilter,
      ...resolveMultiValueColDef({
        sequence: [
          { name: "loadLocation", heading: "Load" },
          { name: "discharge", heading: "Discharge" },
        ],
      }),
      filter: "agMultiColumnFilter",
      filterParams: {
        filters: [
          {
            $reactFilter: LocationZoneFilter,
          },
          standardAgSetColumnFilter,
        ],
      },
      valueGetter: (params) => {
        const order = params.data as OrderNegotiationStore["Order"];

        return locationsValueGetter({
          loadLocation: order?.details?.loadLocation,
          dischargeLocation: order?.details?.dischargeLocation,
          deliveryLocation: order?.details?.deliveryLocation,
          viaLocation: order?.details?.viaLocation,
          redeliveryLocation: order?.details?.redeliveryLocation,
        });
      },
      cellRenderer: multiLocationCellRenderer,
    },
    locations: {
      ...standardAgSetColumnFilter,
      ...resolveMultiValueColDef({
        sequence: [
          { name: "loadOrDelivery", heading: "Load / Delivery" },
          { name: "dischargeOrRedelivery", heading: "Disch / Redelivery" },
        ],
      }),
      filter: "agMultiColumnFilter",
      filterParams: {
        filters: [
          {
            $reactFilter: LocationZoneFilter,
          },
          standardAgSetColumnFilter,
        ],
      },
      valueGetter: (params) => {
        const order = params.data as OrderNegotiationStore["Order"];

        return locationsValueGetter({
          loadLocation: order?.details?.loadLocation,
          dischargeLocation: order?.details?.dischargeLocation,
          deliveryLocation: order?.details?.deliveryLocation,
          viaLocation: order?.details?.viaLocation,
          redeliveryLocation: order?.details?.redeliveryLocation,
        });
      },
      cellRenderer: multiLocationCellRenderer,
    },
    voyTcCount: {
      ...standardAgSetColumnFilter,
      ...resolveMultiValueColDef({
        sequence: [
          [
            { name: "voyCount", heading: "VOY" },
            { name: "tctCount", heading: "TC" },
          ],
        ],
      }),
      valueGetter: (params) => {
        const order = params.data as OrderNegotiationStore["Order"];
        let voyCount;
        let tctCount;

        if (order.summary?.voyCount) {
          const text = NegotiationTypeCount.prototype.toGridView.call({ type: "VOY", count: order.summary?.voyCount });

          voyCount = { value: order.summary?.voyCount, text };
        }

        if (order.summary?.tctCount) {
          const text = NegotiationTypeCount.prototype.toGridView.call({ type: "TC", count: order.summary?.tctCount });

          tctCount = { value: order.summary?.tctCount, text };
        }

        return {
          voyCount,
          tctCount,
        };
      },
      cellRenderer: voyTctCountRenderer,
    },
    statusAndLastUpdated: {
      ...standardAgSetColumnFilter,
      ...resolveMultiValueColDef({
        sequence: [
          { name: "status", heading: "Status" },
          { name: "lastUpdated", heading: "Last Updated" },
        ],
      }),
      $reactFilter: StageFilter,
      valueGetter: (params) => {
        const order = params.data as OrderNegotiationStore["Order"];
        const { responseRequired } = order;
        const isBeyondInitialTerms = Order.prototype.isBeyondInitialTerms.call(order);
        const lastFirmedNonExpiredActions = Order.prototype.getLastFirmedNonExpiredActions.call(order);
        const status = Order.prototype.getStage.call(order);

        let lastUpdated;
        let statusRow;

        if (!isBeyondInitialTerms) {
          if (lastFirmedNonExpiredActions?.brokerChartererFirmed && !lastFirmedNonExpiredActions?.brokerChartererFirmedExpired) {
            statusRow = {
              expiresOn: lastFirmedNonExpiredActions.brokerChartererFirmExpiresOn,
              text: "Firm Bid",
              icon: { name: "schedule", className: "color-they-need-to-do-something" },
            };
          }

          if (lastFirmedNonExpiredActions?.ownerFirmed && !lastFirmedNonExpiredActions?.ownerFirmedExpired) {
            statusRow = {
              expiresOn: lastFirmedNonExpiredActions.ownerFirmExpiresOn,
              text: "Firm Offer",
              icon: { name: "schedule", className: "color-i-need-to-do-something" },
            };
          }
        }

        if (status === "OnSubs" && responseRequired) {
          statusRow = {
            expiresOn: responseRequired,
            icon: { name: "schedule", className: "color-i-need-to-do-something" },
          };
        }

        if (order.lastUpdated) {
          const value = dayjs(order.lastUpdated);

          lastUpdated = { value, text: value.format("DD MMM hh:mm") };
        }

        return {
          statusRow,
          status,
          lastUpdated,
        };
      },
      $reactCellRenderer: RenderStatusAndLastUpdated,
    },
    createdByAndCreatedOn: {
      ...resolveMultiValueColDef({
        sequence: [
          [
            { name: "createdBy", heading: "Created by" },
            { name: "deskView", heading: "Desk" },
          ],
          [
            { name: "locationName", heading: "Location" },
            { name: "createdOn", heading: "Created on" },
          ],
        ],
      }),
      minWidth: 200,
      valueGetter: (params) => {
        const order = params.data as OrderNegotiationStore["Order"];
        let createdBy;
        let deskView;
        let deskId;
        let locationName;
        let createdOn;

        if (order.createdBy) {
          const value = CLDDU.prototype.toGridView.call(order.createdBy);

          createdBy = value;
        }

        if (order.createdBy?.desk) {
          const value = Desk.prototype.toGridView.call(order.createdBy.desk);

          deskView = { value };
          deskId = order.createdBy?.desk.id;
          locationName = order.createdBy?.location?.name;
        }

        if (order.createdOn) {
          const value = dayjs(order.createdOn);

          createdOn = {
            suppressFilterValue: true, // to prevent timestamps from creating lots of different values in SetFilter
            value,
            text: value.format("DD MMM hh:mm A"),
          };
        }

        return {
          createdBy,
          deskView,
          deskId,
          locationName,
          createdOn,
        };
      },
      filter: "agMultiColumnFilter",
      filterValueGetter: (params) => {
        const sequence = [...params.colDef.cellRendererParams.sequence, { name: "deskId" }];

        return standardMultiValueFilterValueGetter(params, sequence);
      },
      filterParams: {
        filters: [
          {
            $reactFilter: DeskFilter,
          },
          {
            filter: "agSetColumnFilter",
            filterParams: {
              cellRenderer: (params) => {
                if (!params.value || params.value === "(Select All)") {
                  return params.value;
                }

                const valueArray = params.value?.split(EMDASH);

                const valuesWithoutDeskId = valueArray?.slice(0, valueArray.length - 1).join(` ${EMDASH} `);

                return valuesWithoutDeskId;
              },
            },
          },
        ],
      },
    },
    archivedOn: {
      ...resolveMultiValueColDef({
        sequence: [{ name: "archivedOn", heading: "Archive Date" }],
        headerClass: "header-left-padding",
      }),
      minWidth: 130,
      cellStyle: { paddingLeft: "40px" },
      valueGetter: (params) => {
        const order = params.data as OrderNegotiationStore["Order"];
        let archivedOn;

        if (order.archivedOn) {
          const value = dayjs(order.archivedOn);

          archivedOn = {
            value,
            text: value.format("DD MMM YYYY"),
          };
        }

        return {
          archivedOn,
        };
      },
    },
    orderChat: {
      flex: 0,
      width: 36,
      pinned: "right",
      suppressMenu: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      valueGetter: (params) => (seaChatWidget.initialized ? params.data?.groupChats : NaN),
      headerName: "",
      cellClass: "aggrid-icon-cell",
      cellRenderer: renderOrderChatIcon,
      onCellClicked: onOrderChatCellClick,
    },
    kebab: {
      flex: 0,
      width: 32,
      field: "kebab",
      headerName: "",
      pinned: "right",
      suppressMovable: true,
      suppressMenu: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      cellClass: "aggrid-icon-cell kebab-menu-cell",
      onCellClicked: (params, e) => {
        // @ts-ignore
        if (e) params.api.contextMenuFactory.showMenu(params.node, params.column, params.value, e);
      },
      cellRenderer: (params) => {
        const order = params.data as OrderNegotiationStore["Order"];
        const eIcon = renderIcon("kebab", params);

        if (!eIcon) return;

        eIcon.setAttribute("data-test", `more-actions-${order.id}`);

        return eIcon;
      },
    },
  } as RecordOf<AggridColDef>;
}

export function getNegotiationColumnMap() {
  const sectionAccess = vesselScoreStore.security.sectionAccess;

  return {
    checkbox: {
      field: "checkbox",
      headerName: "",
      maxWidth: 30,
      checkboxSelection: (params: CheckboxSelectionCallbackParams) => {
        if (params.node.isSelected()) {
          return true;
        }
        const selectedCount = params.api?.getSelectedRows().length;
        if (selectedCount) {
          return selectedCount < archiveRequestsLimit;
        } else {
          return true;
        }
      },
      suppressMenu: true,
      cellRenderer: (params) => {
        const neg = params.data as OrderNegotiationStore["Negotiation"];
        params.eGridCell.setAttribute("data-test", `${testSelectors.bulkSelectRowCheckbox}-${neg.id}`);
      },
      valueGetter: (params) => {
        const tooltip = `Sorry you can't select more than ${archiveRequestsLimit} orders`;
        return { tooltip };
      },
    },
    accountAndOrderReference: {
      ...standardAgSetColumnFilter,
      ...resolveMultiValueColDef({
        sequence: [
          { name: "account", heading: AccountProps.prototype.label },
          { name: "orderReference", heading: "Order ID" },
        ],
      }),
      valueGetter: (params) => {
        const negotiation = params.data as OrderNegotiationStore["Negotiation"];
        let account;

        if (negotiation.chartererAccount) {
          account = Account.prototype.toGridView.call(negotiation.chartererAccount);
        }

        return {
          account,
          orderReference: negotiation.orderReference,
        };
      },
      minWidth: 200,
    },
    cargoSizeAndCargoType: {
      ...standardAgSetColumnFilter,
      ...resolveMultiValueColDef({
        sequence: [
          { name: "cargoType", heading: "Cargo" },
          { name: "cargoSize", heading: "Quantity" },
        ],
      }),
      filter: "agMultiColumnFilter",
      filterParams: {
        filters: [
          {
            $reactFilter: CargoSizeFilter,
          },
          standardAgSetColumnFilter,
        ],
      },
      valueGetter: (params) => {
        const negotiation = params.data as OrderNegotiationStore["Negotiation"];
        const bidOrOffer = negotiation.bid || negotiation.offer;
        let cargoSize;
        let cargoType;

        if (bidOrOffer?.cargoSize?.value) {
          const value = bidOrOffer?.cargoSize?.value[`${CargoSizeProps.prototype.sortKey}`];
          const text = CargoSize.prototype.toGridView.call(bidOrOffer?.cargoSize?.value);

          cargoSize = { value, text };

          //
        } else if (bidOrOffer?.coaCargoSize?.value) {
          const value = bidOrOffer?.coaCargoSize?.value[`${COACargoSizeProps.prototype.sortKey}`];
          const text = COACargoSize.prototype.toGridView.call(bidOrOffer?.coaCargoSize?.value);

          cargoSize = { value, text };

          //
        } else if (bidOrOffer?.vesselSize?.value) {
          const value = bidOrOffer?.vesselSize?.value[`${VesselSizeProps.prototype.sortKey}`];
          const text = VesselSize.prototype.toGridView.call(bidOrOffer?.vesselSize?.value);

          cargoSize = { value, text };
        }

        if (bidOrOffer?.cargoType?.value) {
          cargoType = CargoType.prototype.toGridView.call(bidOrOffer?.cargoType?.value);
        }

        return {
          cargoSize,
          cargoType,
        };
      },
    },
    laycanOrPeriodAndNegotiationType: {
      ...standardAgSetColumnFilter,
      ...resolveMultiValueColDef({
        sequence: [
          { name: "laycanOrPeriod", heading: `${LaycanProps.prototype.label} / ${PeriodProps.prototype.label}` },
          { name: "type", heading: "VOY / TC / COA" },
        ],
      }),
      valueGetter: (params) => {
        const negotiation = params.data as OrderNegotiationStore["Negotiation"];
        const bidOrOffer = negotiation.bid || negotiation.offer;

        let laycanOrPeriod;

        if (bidOrOffer?.laycan?.value) {
          const value = Laycan.prototype.toSortValue.call(bidOrOffer?.laycan?.value);
          const text = Laycan.prototype.toGridView.call(bidOrOffer?.laycan?.value);
          laycanOrPeriod = { value, text };
        }

        if (bidOrOffer?.period?.value) {
          const value = Period.prototype.toSortValue.call(bidOrOffer?.period?.value);
          const text = Period.prototype.toGridView.call(bidOrOffer?.period?.value);

          laycanOrPeriod = { value, text };
        }

        return {
          laycanOrPeriod,
          type: negotiation.type?.toUpperCase(),
        };
      },
      minWidth: 200,
    },
    locations: {
      ...standardAgSetColumnFilter,
      ...resolveMultiValueColDef({
        sequence: [
          { name: "loadOrDelivery", heading: "Load / Delivery" },
          { name: "dischargeOrRedelivery", heading: "Disch / Redelivery" },
        ],
      }),
      filter: "agMultiColumnFilter",
      filterParams: {
        filters: [
          {
            $reactFilter: LocationZoneFilter,
          },
          standardAgSetColumnFilter,
        ],
      },
      valueGetter: (params) => {
        const negotiation = params.data as OrderNegotiationStore["Negotiation"];
        const bidOrOffer = negotiation.bid || negotiation.offer;

        return locationsValueGetter({
          loadLocation: bidOrOffer?.loadLocation?.value,
          dischargeLocation: bidOrOffer?.dischargeLocation?.value,
          deliveryLocation: bidOrOffer?.deliveryLocation?.value,
          viaLocation: bidOrOffer?.viaLocation?.value,
          redeliveryLocation: bidOrOffer?.redeliveryLocation?.value,
        });
      },
      cellRenderer: multiLocationCellRenderer,
    },
    vesselAndDWT: {
      ...standardAgSetColumnFilter,
      ...resolveMultiValueColDef({
        sequence: [
          { name: "vessel", heading: VesselProps.prototype.label },
          { name: "dwt", heading: "DWT" },
        ],
      }),
      cellClassRules: {
        "aggrid-vessel-name": () => true,
        accepted: (params) => params.value?.acceptedVessel,
        named: (params) => params.value?.firstVessel && !params.value?.acceptedVessel,
      },
      valueGetter: (params) => {
        const negotiation = params.data as OrderNegotiationStore["Negotiation"];

        let firstVessel;
        let acceptedVessel;
        let vessel;
        let dwt;

        if (negotiation.vessels?.length) {
          acceptedVessel = Vessels.prototype.getAcceptedVessel.call({ data: negotiation.vessels });
          firstVessel = negotiation.vessels?.[0];
          vessel = acceptedVessel || firstVessel;

          dwt = vessel?.dwt;
        }

        if (vessel) {
          vessel = Vessel.prototype.toGridView.call(vessel);
        }

        return {
          firstVessel,
          acceptedVessel,
          vessel,
          dwt,
        };
      },
    },
    vessel: {
      ...standardAgSetColumnFilter,
      ...resolveMultiValueColDef({
        sequence: sectionAccess.hasAccess
          ? [
              { name: "vessel", heading: VesselProps.prototype.label },
              { name: "VESSEL_SCORE_VESSEL_NAME_PLACEHOLDER", heading: "" },
            ]
          : [{ name: "vessel", heading: VesselProps.prototype.label }],
      }),
      cellClassRules: {
        "aggrid-vessel-name": () => true,
        accepted: (params) => params.value?.acceptedVessel,
        named: (params) => params.value?.firstVessel && !params.value?.acceptedVessel,
      },
      valueGetter: (params) => {
        const negotiation = params.data as OrderNegotiationStore["Negotiation"];

        let firstNonRejectedVessel;
        let acceptedVessel;
        let vessel;

        if (negotiation.vessels?.length) {
          acceptedVessel = Vessels.prototype.getAcceptedVessel.call({ data: negotiation.vessels });
          firstNonRejectedVessel = Vessels.prototype.getNonRejectedFirstVessel.call({ data: negotiation.vessels });
          vessel = acceptedVessel || firstNonRejectedVessel;
        }

        if (!vessel) {
          vessel = "TBN";
        } else {
          vessel = Vessels.prototype.toGridView.call({ data: negotiation.vessels });
        }

        return {
          firstNonRejectedVessel,
          acceptedVessel,
          vessel,
        };
      },
      cellRenderer: NegVesselScoreCellRenderer.bind(null, "VesselName"),
    },
    ownerAccountOrInvitee: {
      ...standardAgSetColumnFilter,
      ...resolveMultiValueColDef({
        sequence: sectionAccess.hasAccess
          ? [
              { name: "ownerAccountOrInvitee", heading: "Counterparty" },
              { name: "vesselScoreValue", heading: "Safety" },
            ]
          : [{ name: "ownerAccountOrInvitee", heading: "Counterparty" }],
      }),
      valueGetter: (params) => {
        const negotiation = params.data as OrderNegotiationStore["Negotiation"];
        const vesselScoreMap = negotiation._.vesselScoreMap;

        let ownerAccount;

        if (negotiation.owningCompany) {
          ownerAccount = OwnerAccount.prototype.toGridView.call(negotiation.owningCompany);
        }

        let safety;

        if (vesselScoreMap && sectionAccess.has("Inspections")) {
          let safestVesselScore: VesselScoreAPI.VesselScore | undefined;

          for (const vesselScore of vesselScoreMap.values()) {
            if (!safestVesselScore) safestVesselScore = vesselScore;

            if (
              safestVesselScore.inspectionResult?.safetyScore?.safetyScoreValue <
              vesselScore.inspectionResult?.safetyScore?.safetyScoreValue
            ) {
              safestVesselScore = vesselScore;
            }
          }

          if (safestVesselScore) {
            safety = {
              value: safestVesselScore.inspectionResult?.safetyScore?.safetyScoreValue,
            };
          }
        }

        return {
          ownerAccountOrInvitee:
            ownerAccount || Negotiation.prototype.getAbsentOwnerDisplay(negotiation.invitee) || negotiation.invitee,
          vesselScoreValue: safety,
        };
      },
      cellRenderer: NegVesselScoreCellRenderer.bind(null, "Safety"),
    },
    "freightRate | hireRate of mainTermsDetails | firm bid/offer | offer": {
      ...standardAgSetColumnFilter,
      ...resolveMultiValueColDef({
        sequence: sectionAccess.has("Inspections")
          ? [
              { name: "freightRate | hireRate of mainTermsDetails | firm bid/offer | offer", heading: "Freight / Hire" },
              { name: "vesselScoreValue", heading: "GHG" },
            ]
          : [{ name: "freightRate | hireRate of mainTermsDetails | firm bid/offer | offer", heading: "Freight / Hire" }],
      }),
      valueGetter: (params) => {
        const negotiation = params.data as OrderNegotiationStore["Negotiation"];
        const vesselScoreMap = negotiation._.vesselScoreMap;
        const isFirm = negotiation.status === "Firm";
        const actions = new Actions(negotiation.actions);
        const offer = negotiation.offer;
        const bid = negotiation.bid;
        const mainTermsDetails = negotiation.mainTermsDetails;
        let details = { freightRate: offer.freightRate?.value, hireRate: offer.hireRate?.value };
        let freightRate;
        let hireRate;

        if (isFirm && actions.firmBidAccepted) {
          details = { freightRate: bid.freightRate?.value, hireRate: bid.hireRate?.value };
        }

        if (mainTermsDetails) {
          details = { freightRate: mainTermsDetails.freightRate, hireRate: mainTermsDetails.hireRate };
        }

        if (details?.freightRate) {
          const value = details?.freightRate[`${FreightRateProps.prototype.sortKey}`];
          const text = FreightRate.prototype.toGridView.call(details?.freightRate);

          freightRate = { value, text };
        }

        if (details?.hireRate) {
          const value = details?.hireRate[`${HireRateProps.prototype.sortKey}`];
          const text = HireRate.prototype.toGridView.call(details?.hireRate);

          hireRate = { value, text };
        }

        let ghg;

        if (vesselScoreMap && sectionAccess.hasAccess) {
          let bestVesselScore: VesselScoreAPI.VesselScore | undefined;

          for (const vesselScore of vesselScoreMap.values()) {
            if (!bestVesselScore) bestVesselScore = vesselScore;

            if (bestVesselScore.inspectionResult?.ghg?.evdi < vesselScore.inspectionResult?.ghg?.evdi) {
              bestVesselScore = vesselScore;
            }
          }

          if (bestVesselScore) {
            ghg = {
              value: bestVesselScore.inspectionResult?.ghg?.evdi,
            };
          }
        }

        return {
          "freightRate | hireRate of mainTermsDetails | firm bid/offer | offer": freightRate || hireRate,
          vesselScoreValue: ghg,
        };
      },
      cellRenderer: NegVesselScoreCellRenderer.bind(null, "GHG"),
    },
    "demurrage | ballastBonus of mainTermsDetails | firm bid/offer | offer": {
      ...standardAgSetColumnFilter,
      ...resolveMultiValueColDef({
        sequence: sectionAccess.has("Inspections")
          ? [
              { name: "demurrage | ballastBonus of mainTermsDetails | firm bid/offer | offer", heading: "Demurrage / BB" },
              { name: "vesselScoreValue", heading: "Inspection Outcome" },
            ]
          : [{ name: "demurrage | ballastBonus of mainTermsDetails | firm bid/offer | offer", heading: "Demurrage / BB" }],
      }),
      valueGetter: (params) => {
        const negotiation = params.data as OrderNegotiationStore["Negotiation"];
        const vesselScoreMap = negotiation._.vesselScoreMap;
        const isFirm = negotiation.status === "Firm";
        const actions = new Actions(negotiation.actions);
        const offer = negotiation.offer;
        const bid = negotiation.bid;
        const mainTermsDetails = negotiation.mainTermsDetails;
        let details = { demurrage: offer.demurrage?.value, ballastBonus: offer.ballastBonus?.value };
        let demurrage;
        let ballastBonus;

        if (isFirm && actions.firmBidAccepted) {
          details = { demurrage: bid.demurrage?.value, ballastBonus: bid.ballastBonus?.value };
        }

        if (mainTermsDetails) {
          details = { demurrage: mainTermsDetails.demurrage, ballastBonus: mainTermsDetails.ballastBonus };
        }

        if (details?.demurrage) {
          const value = details?.demurrage[`${DemurrageProps.prototype.sortKey}`];
          const text = Demurrage.prototype.toGridView.call(details?.demurrage);

          demurrage = { value, text };
        }

        if (details?.ballastBonus) {
          const value = details?.ballastBonus[`${BallastBonusProps.prototype.sortKey}`];
          const text = BallastBonus.prototype.toGridView.call(details?.ballastBonus);

          ballastBonus = { value, text };
        }

        let safetyInspectionDate;

        if (vesselScoreMap && sectionAccess.hasAccess) {
          let bestVesselScore: VesselScoreAPI.VesselScore | undefined;

          for (const vesselScore of vesselScoreMap.values()) {
            if (
              vesselScore.inspectionResult &&
              vesselScore.inspectionResult.latestInspection &&
              !vesselScore.inspectionResult?.latestInspection?.lastInspectionOutcome
            ) {
              // @ts-ignore
              vesselScore.inspectionResult.latestInspection.lastInspectionOutcome = undefined;
            }

            if (!bestVesselScore) bestVesselScore = vesselScore;

            if (
              bestVesselScore.inspectionResult?.latestInspection?.lastInspectionOutcome <
              vesselScore.inspectionResult?.latestInspection?.lastInspectionOutcome
            ) {
              bestVesselScore = vesselScore;
            }
          }

          if (bestVesselScore) {
            safetyInspectionDate = {
              value: bestVesselScore.inspectionResult?.latestInspection?.lastInspectionOutcome,
            };
          }
        }

        return {
          "demurrage | ballastBonus of mainTermsDetails | firm bid/offer | offer": demurrage || ballastBonus,
          vesselScoreValue: safetyInspectionDate,
        };
      },
      cellRenderer: NegVesselScoreCellRenderer.bind(null, "Inspection Outcome"),
    },

    lastUpdated: {
      ...standardAgSetColumnFilter,
      ...resolveMultiValueColDef({
        sequence: sectionAccess.has("Sanctions")
          ? [
              { name: "lastUpdated", heading: "Updated" },
              { name: "vesselScoreValue", heading: "Sanctions" },
            ]
          : [{ name: "lastUpdated", heading: "Updated" }],
      }),
      valueGetter: (params) => {
        const negotiation = params.data as OrderNegotiationStore["Negotiation"];
        const vesselScoreMap = negotiation._.vesselScoreMap;
        let lastUpdated;

        if (negotiation.lastUpdated) {
          const value = dayjs(negotiation.lastUpdated);
          const text = timepassed(value);

          lastUpdated = { value, text };
        }

        let sanctions;

        if (vesselScoreMap && sectionAccess.hasAccess) {
          let bestVesselScore: VesselScoreAPI.VesselScore | undefined;

          for (const vesselScore of vesselScoreMap.values()) {
            if (!vesselScore.sanctionResult) continue;

            if (!bestVesselScore) bestVesselScore = vesselScore;

            if (!bestVesselScore.sanctionResult) {
              bestVesselScore = vesselScore;

              continue;
            }

            if (bestVesselScore.sanctionResult.riskScore > vesselScore.sanctionResult.riskScore) {
              bestVesselScore = vesselScore;
            }
          }

          if (bestVesselScore) {
            sanctions = {
              value: bestVesselScore.sanctionResult?.riskScore,
            };
          }
        }

        return {
          lastUpdated,
          vesselScoreValue: sanctions,
        };
      },
      $reactCellRenderer: NegVesselScoreLastUpdatedCellRenderer,
    },
    coaLastUpdated: {
      ...standardAgSetColumnFilter,
      ...resolveMultiValueColDef({
        sequence: sectionAccess.has("Inspections")
          ? [
              { name: "lastUpdated", heading: "Updated" },
              { name: "vesselScoreValue", heading: "Inspection Outcome" },
            ]
          : [{ name: "lastUpdated", heading: "Updated" }],
      }),
      valueGetter: (params) => {
        const negotiation = params.data as OrderNegotiationStore["Negotiation"];
        const vesselScoreMap = negotiation._.vesselScoreMap;
        let lastUpdated;

        if (negotiation.lastUpdated) {
          const value = dayjs(negotiation.lastUpdated);
          const text = timepassed(value);

          lastUpdated = { value, text };
        }

        let safetyInspectionDate;

        if (vesselScoreMap && sectionAccess.hasAccess) {
          let bestVesselScore: VesselScoreAPI.VesselScore | undefined;

          for (const vesselScore of vesselScoreMap.values()) {
            if (
              vesselScore.inspectionResult &&
              vesselScore.inspectionResult.latestInspection &&
              !vesselScore.inspectionResult?.latestInspection?.lastInspectionOutcome
            ) {
              // @ts-ignore
              vesselScore.inspectionResult.latestInspection.lastInspectionOutcome = undefined;
            }

            if (!bestVesselScore) bestVesselScore = vesselScore;

            if (
              bestVesselScore.inspectionResult?.latestInspection?.lastInspectionOutcome <
              vesselScore.inspectionResult?.latestInspection?.lastInspectionOutcome
            ) {
              bestVesselScore = vesselScore;
            }
          }

          if (bestVesselScore) {
            safetyInspectionDate = {
              value: bestVesselScore.inspectionResult?.latestInspection?.lastInspectionOutcome,
            };
          }
        }

        return {
          lastUpdated,
          vesselScoreValue: safetyInspectionDate,
        };
      },
      $reactCellRenderer: CoaNegVesselScoreUpdatedCellRenderer,
    },
    bidFreightRateOrHireRateAndDemurrageOrBallastBonus: {
      ...standardAgSetColumnFilter,
      ...resolveMultiValueColDef({
        sequence: [
          { name: "freightRateOrHireRate", heading: "Freight / Hire (in)" },
          { name: "demurrageOrBallastBonus", heading: "Demurrage / BB (in)" },
        ],
        cellRendererParams: {
          icon: "firm-received",
        },
      }),
      valueGetter: (params) => {
        const negotiation = params.data as OrderNegotiationStore["Negotiation"];
        let freightRateOrHireRate;
        let demurrageOrBallastBonus;

        if (negotiation?.bid?.freightRate?.value) {
          const value = negotiation?.bid?.freightRate?.value[`${FreightRateProps.prototype.sortKey}`];
          const text = FreightRate.prototype.toGridView.call(negotiation?.bid?.freightRate?.value);

          freightRateOrHireRate = { value, text };
        }

        if (negotiation?.bid?.hireRate?.value) {
          const value = negotiation?.bid?.hireRate?.value[`${HireRateProps.prototype.sortKey}`];
          const text = HireRate.prototype.toGridView.call(negotiation?.bid?.hireRate?.value);

          freightRateOrHireRate = { value, text };
        }

        if (negotiation?.bid?.demurrage?.value) {
          const value = negotiation?.bid?.demurrage?.value[`${DemurrageProps.prototype.sortKey}`];
          const text = Demurrage.prototype.toGridView.call(negotiation?.bid?.demurrage?.value);

          demurrageOrBallastBonus = { value, text };
        }

        if (negotiation?.bid?.ballastBonus?.value) {
          const value = negotiation?.bid?.ballastBonus?.value[`${BallastBonusProps.prototype.sortKey}`];
          const text = BallastBonus.prototype.toGridView.call(negotiation?.bid?.ballastBonus?.value);

          demurrageOrBallastBonus = { value, text };
        }

        return {
          freightRateOrHireRate,
          demurrageOrBallastBonus,
        };
      },
    },
    offerFreightRateOrHireRateAndDemurrageOrBallastBonus: {
      ...standardAgSetColumnFilter,
      ...resolveMultiValueColDef({
        sequence: [
          { name: "freightRateOrHireRate", heading: "Freight / Hire (out)" },
          { name: "demurrageOrBallastBonus", heading: "Demurrage / BB (out)" },
        ],
        cellRendererParams: {
          icon: "firm-sent",
        },
      }),
      valueGetter: (params) => {
        const negotiation = params.data as OrderNegotiationStore["Negotiation"];
        let freightRateOrHireRate;
        let demurrageOrBallastBonus;

        if (negotiation?.offer?.freightRate?.value) {
          const value = negotiation?.offer?.freightRate?.value[`${FreightRateProps.prototype.sortKey}`];
          const text = FreightRate.prototype.toGridView.call(negotiation?.offer?.freightRate?.value);

          freightRateOrHireRate = { value, text };
        }

        if (negotiation?.offer?.hireRate?.value) {
          const value = negotiation?.offer?.hireRate?.value[`${HireRateProps.prototype.sortKey}`];
          const text = HireRate.prototype.toGridView.call(negotiation?.offer?.hireRate?.value);

          freightRateOrHireRate = { value, text };
        }

        if (negotiation?.offer?.demurrage?.value) {
          const value = negotiation?.offer?.demurrage?.value[`${DemurrageProps.prototype.sortKey}`];
          const text = Demurrage.prototype.toGridView.call(negotiation?.offer?.demurrage?.value);

          demurrageOrBallastBonus = { value, text };
        }

        if (negotiation?.offer?.ballastBonus?.value) {
          const value = negotiation?.offer?.ballastBonus?.value[`${BallastBonusProps.prototype.sortKey}`];
          const text = BallastBonus.prototype.toGridView.call(negotiation?.offer?.ballastBonus?.value);

          demurrageOrBallastBonus = { value, text };
        }

        return {
          freightRateOrHireRate,
          demurrageOrBallastBonus,
        };
      },
    },
    circulatedByAndCreatedOn: {
      ...standardAgSetColumnFilter,
      ...resolveMultiValueColDef({
        sequence: [
          { name: "circulatedBy", heading: "Received from" },
          { name: "createdOn", heading: "Received on" },
        ],
      }),
      valueGetter: (params) => {
        const negotiation = params.data as OrderNegotiationStore["Negotiation"];
        let createdOn;

        if (negotiation.createdOn) {
          const value = dayjs(negotiation.createdOn);

          createdOn = { value, text: `${value.utc().format("DD MMM hh:mm A")} GMT/UTC` };
        }

        return {
          circulatedBy: negotiation.circulatedBy,
          createdOn,
        };
      },
    },
    offerPeriod: {
      ...standardAgSetColumnFilter,
      ...resolveMultiValueColDef({
        sequence: sectionAccess.has("Inspections")
          ? [
              { name: "offerPeriod", heading: "Period" },
              { name: "vesselScoreValue", heading: "GHG" },
            ]
          : [{ name: "offerPeriod", heading: "Period" }],
      }),
      valueGetter: (params) => {
        const negotiation = params.data as OrderNegotiationStore["Negotiation"];
        const vesselScoreMap = negotiation._.vesselScoreMap;
        let offerPeriod;

        if (negotiation.offer?.period?.value) {
          const text = Laycan.prototype.toGridView.call(negotiation.offer?.period?.value);
          const value = new Date(negotiation.offer?.period?.value.from);

          offerPeriod = { text, value };
        }

        let ghg;

        if (vesselScoreMap && sectionAccess.hasAccess) {
          let bestVesselScore: VesselScoreAPI.VesselScore | undefined;

          for (const vesselScore of vesselScoreMap.values()) {
            if (!bestVesselScore) bestVesselScore = vesselScore;

            if (bestVesselScore.inspectionResult?.ghg?.evdi < vesselScore.inspectionResult?.ghg?.evdi) {
              bestVesselScore = vesselScore;
            }
          }

          if (bestVesselScore) {
            ghg = {
              value: bestVesselScore.inspectionResult?.ghg?.evdi,
            };
          }
        }

        return {
          offerPeriod,
          vesselScoreValue: ghg,
        };
      },
      cellRenderer: NegVesselScoreCellRenderer.bind(null, "GHG"),
    },
    statusAndLastUpdated: {
      ...standardAgSetColumnFilter,
      ...resolveMultiValueColDef({
        sequence: [
          { name: "status", heading: "Status" },
          { name: "lastUpdated", heading: "Last Updated" },
        ],
      }),
      $reactFilter: StageFilter,
      valueGetter: negotiationStatusValueGetter,
      $reactCellRenderer: RenderStatusAndLastUpdated,
    },
    status: {
      ...standardAgSetColumnFilter,
      ...resolveMultiValueColDef({
        sequence: [{ name: "status", heading: "Status" }],
      }),
      cellClass: "aggrid-status-cell",
      $reactFilter: StageFilter,
      valueGetter: negotiationStatusValueGetter,
      $reactCellRenderer: NegStatus,
    },
    coaStatus: {
      ...standardAgSetColumnFilter,
      ...resolveMultiValueColDef({
        sequence: sectionAccess.has("Sanctions")
          ? [
              { name: "status", heading: "Status" },
              { name: "vesselScoreValue", heading: "Sanctions" },
            ]
          : [{ name: "status", heading: "Status" }],
      }),
      $reactFilter: StageFilter,
      valueGetter: negotiationStatusValueGetter,
      $reactCellRenderer: NegVesselScoreStatusCellRenderer,
    },
    archivedOn: {
      ...resolveMultiValueColDef({
        sequence: [{ name: "archivedOn", heading: "Archive Date" }],
        headerClass: "header-left-padding",
      }),
      minWidth: 130,
      cellStyle: { paddingLeft: "40px" },
      valueGetter: (params) => {
        const order = params.data as OrderNegotiationStore["Order"];
        let archivedOn;

        if (order.archivedOn) {
          const value = dayjs(order.archivedOn);

          archivedOn = {
            value,
            text: value.format("DD MMM YYYY"),
          };
        }

        return {
          archivedOn,
        };
      },
    },
    chat: {
      flex: 0,
      width: 36,
      pinned: "right",
      suppressMovable: true,
      suppressMenu: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      valueGetter: (params) => (seaChatWidget.initialized ? params.data?.groupChat : NaN),
      headerName: "",
      cellClass: "aggrid-icon-cell",
      cellRenderer: renderNegotiationChatIcon,
      onCellClicked: onNegotiationChatCellClick,
    },
    kebab: {
      flex: 0,
      width: 32,
      field: "kebab",
      headerName: "",
      pinned: "right",
      suppressMovable: true,
      suppressMenu: true,
      suppressColumnsToolPanel: true,
      suppressFiltersToolPanel: true,
      cellClass: "aggrid-icon-cell kebab-menu-cell",
      onCellClicked: (params, e) => {
        // @ts-ignore
        if (e) params.api.contextMenuFactory.showMenu(params.node, params.column, params.value, e);
      },
      cellRenderer: (params) => {
        const order = params.data as OrderNegotiationStore["Order"];
        const eIcon = renderIcon("kebab", params);

        if (!eIcon) return;

        eIcon.setAttribute("data-test", `more-actions-${order.id}`);

        return eIcon;
      },
    },
  } as RecordOf<AggridColDef>;
}

export const commonColumnMap = {
  gutterForUnseenBookmark: {
    flex: 0,
    width: 3,
    maxWidth: 3,
    pinned: true,
    suppressMenu: true,
    suppressMovable: true,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
    $blank: true,
  },
  chevron: {
    flex: 0,
    width: 30,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
    suppressMenu: true,
    cellRenderer: (params) => {
      const eIcon = renderIcon("chevron-right", params);

      if (!eIcon) return;

      const negotiation = params.node?.data as Negotiation;
      const negotiationId = negotiation.id;

      eIcon.setAttribute("data-test", `Negotiation-row-${negotiationId}`);

      return eIcon;
    },
    headerName: "",
  },
  attachments: {
    flex: 0,
    width: 54,
    pinned: "right",
    suppressMovable: true,
    suppressMenu: true,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
    field: "attachments",
    headerName: "",
    cellClass: "aggrid-icon-cell",
    onCellClicked: (params, e) => {
      e.stopPropagation();

      slideout.show({
        content: <NegotiationAttachments negotiation={params.data} />,
      });
    },
    cellRenderer: (params) => {
      const eIcon = renderIcon("attach-file", params);

      if (!eIcon) return;

      eIcon.innerText = `(${params.data.attachments.length})`;
      eIcon.setAttribute("data-test", "attachments-icon-and-count");

      return eIcon;
    },
  },
  kebab: {
    flex: 0,
    width: 24,
    pinned: "right",
    suppressMovable: true,
    suppressMenu: true,
    suppressColumnsToolPanel: true,
    suppressFiltersToolPanel: true,
    cellClass: "aggrid-icon-cell",
    onCellClicked: (params, e) => {
      e.stopPropagation();

      // @ts-ignore
      params.api.contextMenuFactory.showMenu(params.node, params.column, params.value, e);
    },
    cellRenderer: (params) => {
      const eIcon = renderIcon("kebab", params);

      if (!eIcon) return;
      eIcon.setAttribute("data-test", "more-actions");

      return eIcon;
    },
  },
} as RecordOf<AggridColDef>;

export const columns = {};

function renderStripedLoader() {
  const loader = <StripedLoader status={{ loading: true }} size={"small"}></StripedLoader>;
  const staticElement = renderToStaticMarkup(loader);
  const element = document.createElement("div");
  element.style.position = "fixed";
  element.style.left = "50%";
  element.innerHTML = staticElement;
  return element;
}

function NegVesselScoreCellRenderer(
  vesselScoreColName: "VesselName" | "Safety" | "GHG" | "Inspection Outcome" | "Sanctions",
  params,
  suppressNegValue = false
) {
  const { eGridCell, node, value } = params;
  const sequence = params.sequence as MultiValueSequence;
  const sequenceFlat = sequence.flat();
  const negValueItem = sequenceFlat[0];
  const negValue = value[negValueItem.name];
  const neg = node.data as OrderNegotiationStore["Negotiation"];
  const vesselScoreMap = neg._.vesselScoreMap;
  const vessels = neg.vessels;
  const vesselScoreError = neg._.vesselScoreError;
  const isVesselScoreDataLoaded = (!vesselScoreMap && !!vesselScoreError) || (!!vesselScoreMap && !vesselScoreError);
  const hasVessels = vessels && vessels.length > 0;
  const sectionAccess = vesselScoreStore.security.sectionAccess;
  const isFirstCell = vesselScoreColName === "VesselName";

  eGridCell.classList.add("order-neg-layout-neg-vessel-score-cell");

  if (!suppressNegValue) {
    const negRow = document.createElement("sea-aggrid-text-row");

    negRow.innerText = getRowText(negValue);

    eGridCell.append(negRow);
  }

  if (!sectionAccess.isDataReady) {
    if (hasVessels && isFirstCell) {
      eGridCell.append(renderStripedLoader());
    }
    return;
  }

  if (sectionAccess.hasAccess && hasVessels && !isVesselScoreDataLoaded && isFirstCell) {
    eGridCell.append(renderStripedLoader());
    return;
  }

  if (sectionAccess.hasAccess && vesselScoreMap && isVesselScoreDataLoaded) {
    for (let i = 0; i < vessels.length; i++) {
      const vessel = vessels[i];
      const imo = vessel.vesselImo.toString();
      const isEmptyImo = imo === "0";
      let vesselScore = vesselScoreMap.get(vessel.vesselImo.toString());
      if (!vesselScore) vesselScore = { imo } as VesselScoreAPI.VesselScore;
      const { inspectionResult, sanctionResult } = vesselScore;
      const vesselScoreLoading = !inspectionResult && !sanctionResult && !isEmptyImo;

      const vesselRow = document.createElement("sea-aggrid-text-row");

      if (!isEmptyImo) {
        vesselRow.onclick = NegVesselScoreCellRenderer.openDialog.bind(null, vesselScore, vessel);
      }

      if (vesselScoreColName === "VesselName") {
        const text = document.createTextNode(vessel.registrationDataName);
        vesselRow.append(text);
        if (vesselScoreLoading) {
          eGridCell.append(renderStripedLoader());
          continue;
        }
      } else if (vesselScoreColName === "Safety") {
        if (inspectionResult?.safetyScore || isEmptyImo) {
          const img = document.createElement("img");
          let text;
          if (isEmptyImo) {
            text = "IMO not supported";
          } else {
            eGridCell.title = `Safety score provided by ${inspectionResult?.provider}`;
            text = inspectionResult?.safetyScore?.displayValue;
            img.src = inspectionsLogo;
          }
          const textNode = document.createTextNode(text);
          vesselRow.append(textNode, img);
        }
      } else if (vesselScoreColName === "GHG" && inspectionResult?.ghg) {
        eGridCell.title = `Green house gas emission score provided by ${inspectionResult?.provider}`;

        const img = document.createElement("img");
        const text = document.createTextNode(
          `${inspectionResult?.ghg?.displayValue}${
            inspectionResult?.ghg?.evdi ? ` (${Number(inspectionResult?.ghg?.evdi).toFixed(1)})` : ""
          }`
        );

        img.src = inspectionsLogo;

        vesselRow.append(text, img);

        //
      } else if (
        vesselScoreColName === "Inspection Outcome" &&
        sectionAccess.has("Inspections") &&
        inspectionResult &&
        inspectionResult?.latestInspection &&
        inspectionResult?.latestInspection?.lastInspectionOutcome
      ) {
        eGridCell.title = `Safety inspection done by ${inspectionResult?.provider}`;

        const img = document.createElement("img");
        const text = document.createTextNode(inspectionResult?.latestInspection?.lastInspectionOutcome);

        img.src = inspectionsLogo;

        vesselRow.append(text, img);
      } else if ((vesselScoreColName === "Sanctions" && sanctionResult && sectionAccess.has("Sanctions")) || isEmptyImo) {
        const img = document.createElement("img");
        const pill = document.createElement("order-neg-layout-neg-vessel-score-cell-pill");

        if (isEmptyImo) {
          pill.innerText = "IMO not supported";
        } else {
          const { riskLevel, sanctionedCompany, sanctionedCountryFlag, sanctionedVessel } = sanctionResult;
          const isRed = sanctionedCompany || sanctionedCountryFlag || sanctionedVessel;
          pill.innerText = riskLevel;

          eGridCell.title = `Risk levels provided by ${sanctionResult.provider}`;

          if (isRed) pill.classList.add("warning");

          img.src = sanctionsLogo;
        }

        vesselRow.append(pill, img);
      } else if (!vesselScoreLoading) {
        const text = document.createTextNode("--");

        vesselRow.append(text);

        vesselRow.onclick = null;
      }

      eGridCell.append(vesselRow);
    }
  }

  if (sectionAccess.hasAccess && vesselScoreError) {
    vessels.forEach((vessel) => {
      const vesselRow = document.createElement("sea-aggrid-text-row");
      if (vesselScoreColName === "VesselName") {
        const text = document.createTextNode(vessel.registrationDataName);
        vesselRow.append(text);
      }
      if (vesselScoreColName === "Safety") {
        eGridCell.title = "Error Message";
        const text = "Server Error";

        vesselRow.innerText = getRowText(text);
      }
      eGridCell.append(vesselRow);
    });
  }
}
NegVesselScoreCellRenderer.openDialog = function openDialog(
  vesselScore: VesselScoreAPI.VesselScore,
  vessel: TradeAPI["Vessel"],
  e: MouseEvent
) {
  e.stopPropagation();

  const { inspectionResult, sanctionResult } = vesselScore;
  const inspectionProviderImg = <Img src={inspectionsLogo} />;
  const sanctionsProviderImg = sanctionResult?.provider && <Img src={sanctionsLogo} />;

  dialog.show({
    content: (
      <order-neg-layout-neg-vessel-score-dialog>
        <order-neg-layout-neg-vessel-score-dialog-name>
          {Vessel.prototype.toString.call(vessel)}
        </order-neg-layout-neg-vessel-score-dialog-name>

        <order-neg-layout-neg-vessel-score-dialog-more
          title={`Safety score provided by ${inspectionResult?.provider}`}
          hidden={!inspectionResult?.safetyScore?.moreDetailsDisplayValue}
        >
          <order-neg-layout-neg-vessel-score-dialog-more-title>
            Safety {inspectionProviderImg}
          </order-neg-layout-neg-vessel-score-dialog-more-title>
          <order-neg-layout-neg-vessel-score-dialog-more-content>
            {inspectionResult?.safetyScore?.moreDetailsDisplayValue}
          </order-neg-layout-neg-vessel-score-dialog-more-content>
        </order-neg-layout-neg-vessel-score-dialog-more>

        <order-neg-layout-neg-vessel-score-dialog-more
          title={`Inspection Outcome provided by ${inspectionResult?.provider}`}
          hidden={!inspectionResult?.latestInspection?.moreDetailsDisplayValue}
        >
          <order-neg-layout-neg-vessel-score-dialog-more-title>
            Inspection Outcome {inspectionProviderImg}
          </order-neg-layout-neg-vessel-score-dialog-more-title>
          <order-neg-layout-neg-vessel-score-dialog-more-content>
            {inspectionResult?.latestInspection?.moreDetailsDisplayValue}
          </order-neg-layout-neg-vessel-score-dialog-more-content>
        </order-neg-layout-neg-vessel-score-dialog-more>

        <order-neg-layout-neg-vessel-score-dialog-more
          title={`Green house gas emission score provided by ${inspectionResult?.provider}`}
          hidden={!inspectionResult?.ghg?.moreDetailsDisplayValue}
        >
          <order-neg-layout-neg-vessel-score-dialog-more-title>
            GHG {inspectionProviderImg}
          </order-neg-layout-neg-vessel-score-dialog-more-title>
          <order-neg-layout-neg-vessel-score-dialog-more-content>
            {inspectionResult?.ghg?.moreDetailsDisplayValue}
          </order-neg-layout-neg-vessel-score-dialog-more-content>
        </order-neg-layout-neg-vessel-score-dialog-more>

        <order-neg-layout-neg-vessel-score-dialog-more
          title={`Risk levels provided by ${sanctionResult?.provider}`}
          hidden={!sanctionResult?.moreDetailsDisplayValue}
        >
          <order-neg-layout-neg-vessel-score-dialog-more-title>
            Sanctions {sanctionsProviderImg}
          </order-neg-layout-neg-vessel-score-dialog-more-title>
          <order-neg-layout-neg-vessel-score-dialog-more-content>
            {sanctionResult?.moreDetailsDisplayValue}
          </order-neg-layout-neg-vessel-score-dialog-more-content>
        </order-neg-layout-neg-vessel-score-dialog-more>
      </order-neg-layout-neg-vessel-score-dialog>
    ),
  });
};

function NegVesselScoreLastUpdatedCellRenderer(params) {
  const { eGridCell, node } = params;
  const { lastUpdated } = params.value;
  const neg = node.data as OrderNegotiationStore["Negotiation"];
  const vesselScoreMap = neg._.vesselScoreMap;
  const vessels = neg.vessels;
  const vesselScoreCellRef = useRef<HTMLSpanElement>(null);
  const text = timepassed(lastUpdated.value);

  eGridCell.setAttribute("title", text);

  useEffect(renderNegVesselScoreCell, [vesselScoreMap?.size, vessels.length]);
  useEfficientTimeRerenderer({ date: lastUpdated.value, type: "timepassed" });

  return (
    <>
      <sea-aggrid-text-row>{text}</sea-aggrid-text-row>
      <order-neg-layout-last-updated-cell-vessel-score-cell ref={vesselScoreCellRef} />
    </>
  );

  function renderNegVesselScoreCell() {
    if (!vesselScoreCellRef.current) return;

    vesselScoreCellRef.current.innerHTML = "";

    params = { ...params };

    params.eGridCell = vesselScoreCellRef.current;

    NegVesselScoreCellRenderer("Sanctions", params, true);
  }
}
NegVesselScoreLastUpdatedCellRenderer.displayName = "NegVesselScoreLastUpdatedCellRenderer";

function CoaNegVesselScoreUpdatedCellRenderer(params) {
  const { eGridCell, node } = params;
  const { lastUpdated } = params.value;
  const neg = node.data as OrderNegotiationStore["Negotiation"];
  const vesselScoreMap = neg._.vesselScoreMap;
  const vessels = neg.vessels;
  const vesselScoreCellRef = useRef<HTMLSpanElement>(null);
  const text = timepassed(lastUpdated.value);

  eGridCell.setAttribute("title", text);

  useEffect(renderNegVesselScoreCell, [vesselScoreMap?.size, vessels.length]);
  useEfficientTimeRerenderer({ date: lastUpdated.value, type: "timepassed" });

  return (
    <>
      <sea-aggrid-text-row>{text}</sea-aggrid-text-row>
      <order-neg-layout-last-updated-cell-vessel-score-cell ref={vesselScoreCellRef} />
    </>
  );

  function renderNegVesselScoreCell() {
    if (!vesselScoreCellRef.current) return;

    vesselScoreCellRef.current.innerHTML = "";

    params = { ...params };

    params.eGridCell = vesselScoreCellRef.current;

    NegVesselScoreCellRenderer("Inspection Outcome", params, true);
  }
}
CoaNegVesselScoreUpdatedCellRenderer.displayName = "CoaNegVesselScoreUpdatedCellRenderer";

function NegVesselScoreStatusCellRenderer(params) {
  const { node } = params;
  const neg = node.data as OrderNegotiationStore["Negotiation"];
  const vesselScoreMap = neg._.vesselScoreMap;
  const vessels = neg.vessels;
  const vesselScoreCellRef = useRef<HTMLSpanElement>(null);

  useEffect(renderNegVesselScoreCell, [vesselScoreMap?.size, vessels.length]);

  return (
    <>
      <sea-aggrid-text-row>
        <NegStatus {...params} />
      </sea-aggrid-text-row>
      <order-neg-layout-status-cell-vessel-score-cell ref={vesselScoreCellRef} />
    </>
  );

  function renderNegVesselScoreCell() {
    if (!vesselScoreCellRef.current) return;

    vesselScoreCellRef.current.innerHTML = "";

    params = { ...params };

    params.eGridCell = vesselScoreCellRef.current;

    NegVesselScoreCellRenderer("Sanctions", params, true);
  }
}
NegVesselScoreStatusCellRenderer.displayName = "NegVesselScoreStatusCellRenderer";

function NegStatus(params) {
  let { status, statusRow } = params.value;

  statusRow = { ...STATUS_ROW[status], ...statusRow };

  const { expiresOn } = statusRow;
  const timeleftTillExpiry = formatStatusTimeleft(timeleft(expiresOn), expiresOn);
  const dataTest = statusRow.text ? `status-${toKebabCase(statusRow.text)}` : null;

  statusRow.text = `${statusRow.text || ""}${timeleftTillExpiry}`;

  useEfficientTimeRerenderer({ date: expiresOn, type: "timeleft" });

  const { text, icon } = statusRow;

  if (!text) return standardValueDisplayFormatter(text);

  return (
    <>
      <Icon icon={icon} data-test={dataTest} />
      {text}
    </>
  );
}

function negotiationStatusValueGetter(params) {
  const negotiation = params.data as OrderNegotiationStore["Negotiation"];
  const { responseRequired } = negotiation;
  const isBeyondInitialTerms = Negotiation.prototype.isBeyondInitialTerms.call(negotiation);
  const actions = new Actions(negotiation.actions);
  const status = Negotiation.prototype.getStage.call(negotiation);

  let statusRow;
  let lastUpdated;
  let lastUpdatedBy;

  if (negotiation.lastUpdatedBy) {
    lastUpdatedBy = negotiation.lastUpdatedBy === "owner" ? "You" : capitalizeFirstLetter(negotiation.lastUpdatedBy);
  }

  if (!isBeyondInitialTerms) {
    if (actions?.brokerChartererFirmed && !actions?.brokerChartererFirmedExpired) {
      statusRow = {
        expiresOn: actions.brokerChartererFirmExpiresOn,
        text: "Firm Bid",
        icon: { name: "schedule", className: "firmbid" },
      };
    }
    if (actions?.ownerFirmed && !actions?.ownerFirmedExpired) {
      statusRow = {
        expiresOn: actions.ownerFirmExpiresOn,
        text: "Firm Offer",
        icon: { name: "schedule", className: "firmoffer" },
      };
    }
  }

  if (status === "OnSubs" && responseRequired) {
    statusRow = {
      expiresOn: responseRequired,
    };
  }

  if (negotiation.lastUpdated) {
    const value = dayjs(negotiation.lastUpdated);

    lastUpdated = { value, text: `${value.utc().format("DD MMM hh:mm")} GMT/UTC` };
  }

  return {
    lastUpdatedBy,
    statusRow,
    status,
    lastUpdated,
    responseRequired,
  };
}

function locationsValueGetter({ loadLocation, dischargeLocation, deliveryLocation, viaLocation, redeliveryLocation }) {
  if (loadLocation) {
    const value = LoadLocation.prototype.toGridView.call(loadLocation);
    const img = LoadLocation.prototype.getImg.call(loadLocation);

    loadLocation = { value, text: value, img, data: loadLocation };
  }

  if (dischargeLocation) {
    const value = DischargeLocation.prototype.toGridView.call(dischargeLocation);
    const img = DischargeLocation.prototype.getImg.call(dischargeLocation);

    dischargeLocation = { value, text: value, img, data: dischargeLocation };
  }

  if (deliveryLocation) {
    const value = DeliveryLocation.prototype.toGridView.call(deliveryLocation);
    const img = DeliveryLocation.prototype.getImg.call(deliveryLocation);

    deliveryLocation = { value, text: value, img, data: deliveryLocation };
  }

  if (viaLocation) {
    const value = ViaLocation.prototype.toGridView.call(viaLocation);
    const img = ViaLocation.prototype.getImg.call(viaLocation);

    viaLocation = { value, text: value, img, data: viaLocation };
  }

  if (redeliveryLocation) {
    const value = RedeliveryLocation.prototype.toGridView.call(redeliveryLocation);
    const img = RedeliveryLocation.prototype.getImg.call(redeliveryLocation);

    redeliveryLocation = { value, text: value, img, data: redeliveryLocation };
  }

  return {
    loadLocation,
    dischargeLocation,
    deliveryLocation,
    viaLocation,
    redeliveryLocation,
    loadOrDelivery: loadLocation || deliveryLocation,
    dischargeOrRedelivery: dischargeLocation || redeliveryLocation,
    locations: [loadLocation, dischargeLocation, deliveryLocation, viaLocation, redeliveryLocation], // used by LocationZoneFilter
  };
}

function resolveMultiValueColDef(params: ResolveMultiValueColDefParams): AggridColDef {
  const { sequence, headerClass, cellRendererParams } = params;

  return {
    headerName: joinSequenceHeadings(sequence),
    $reactHeaderComponent: MultiValueHeaderCell,
    headerComponentParams: { sequence },
    cellClass: resolveMultiValueCellClass,
    headerClass,
    cellRenderer: standardMultiValueCellRenderer,
    cellRendererParams: { ...cellRendererParams, sequence },
    filterValueGetter: standardMultiValueFilterValueGetter,
    $resolveDef: (params) => ({
      comparator: multiValueComparator.bind(null, params),
    }),
  };
}

function joinSequenceHeadings(sequence: MultiValueSequence) {
  const headings = sequence.flat().map((item) => item.heading);

  return headings.join(" & ");
}

function multiValueComparator(params: AggridResolveDefParams, a, b, aNode, bNode, isInverted) {
  const { columnStateAPI, col } = params;
  const multiValueSortName = `${columnStateAPI.state[`${col.colId}`]?.multiValueSortName}`;
  const _a = a?.[multiValueSortName];
  const _b = b?.[multiValueSortName];

  a = _a?.sortValue || _a?.value || _a?.text || _a;
  b = _b?.sortValue || _b?.value || _b?.text || _b;

  return standardComparator(a, b, aNode, bNode, isInverted);
}

function standardComparator(a, b, aNode, bNode, isInverted) {
  // isInverted === false -- asc
  // isInverted === true -- desc

  if (a && typeof a === "string") a = a.toUpperCase();
  if (b && typeof b === "string") b = b.toUpperCase();

  if (a === undefined || a === null || a === "" || Number.isNaN(a)) a = null;
  if (b === undefined || b === null || b === "" || Number.isNaN(b)) b = null;

  // @ts-ignore
  if (a instanceof dayjs) a = a.unix();
  // @ts-ignore
  if (b instanceof dayjs) b = b.unix();

  if (a === null && b !== null) return isInverted ? -1 : 1;
  if (a !== null && b === null) return isInverted ? 1 : -1;

  if (a === b) return 0;

  return a < b ? -1 : 1;
}

function renderOrderChatIcon(params) {
  const { groupChats } = params.data as Order;
  const disabled = !seaChatWidget.initialized || !groupChats?.length;
  const eIcon = renderIcon({ name: "chat", disabled }, params);

  if (!seaChatWidget.initialized) waitForSeaChatAndRerenderCell(params);

  return eIcon;
}

async function onOrderChatCellClick(params, e) {
  class GroupChat extends DataModel {
    toString() {
      // @ts-ignore
      return this.name;
    }
  }
  const { eGridCell } = params;
  const data = params.data as Order;
  const groupChats = data?.groupChats?.map((data) => new GroupChat(data));
  const eIcon = eGridCell.firstElementChild;

  usageMetrics.trackEvent(SeaChatEvents.SEA_CHAT_BTN_ORDER_ROW, { orderId: data.id });

  if (!groupChats?.length) return;

  e.stopPropagation();

  const chatIds = data.groupChats.filter((groupChat) => groupChat.id);

  const groupChat = await dropdown.show({
    target: eIcon,
    data: groupChats,
    style: { minWidth: 200 },
    dataTest: "group-chat",
    onItemSelected: () => usageMetrics.trackEvent(SeaChatEvents.SEA_CHAT_CONTEXT_MENU, { orderId: data.id, chatIds }),
  });

  seaChatWidget.openGroupChat(groupChat.id);
}

function renderNegotiationChatIcon(params) {
  // TODO change person icon to the one from the designs
  type iconType = "chat" | "people" | "flash-on" | "person";

  const iconTitle: Record<iconType, string> = {
    chat: "Sea Chat",
    people: "Broker Managed Negotiation",
    "flash-on": "Deal Capture",
    person: "Owner Absent Negotiation",
  };
  const { groupChat, isDealCapture, hasBroker } = params.data as Negotiation;
  const negModel = new Negotiation(params.data);
  const disabled = !seaChatWidget.initialized || isDealCapture || !groupChat;
  const isBrokeredNeg = hasBroker;
  const name: iconType = negModel.isOwnerAbsent ? "person" : isDealCapture ? "flash-on" : isBrokeredNeg ? "people" : "chat";

  if (negModel.isOwnerAbsent) {
    const eIcon = document.createElement("div");

    eIcon.innerHTML = `<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" >
    <defs>
        <path d="M19.9168155,11.0173205 L21.8613591,12.9618642 L18.9445436,15.8786797 L21.8613591,18.7954951 L19.9168155,20.7400388 L17,17.8232233 L14.0831845,20.7400388 L12.1386409,18.7954951 L15.0554564,15.8786797 L12.1386409,12.9618642 L14.0831845,11.0173205 L17,13.934136 L19.9168155,11.0173205 Z M10.6964659,14.0053865 L12.5770065,15.8868589 L9.45272102,19.0111444 L10.441,20 L3,20 L3,18 C3,15.4373778 7.94689977,14.099879 10.6964659,14.0053865 Z M11,4 C13.21,4 15,5.79 15,8 C15,8.40588816 14.9396216,8.79760934 14.8273521,9.16667636 L13.8973483,8.23644404 L10.2112593,11.9223366 C8.37931903,11.5563287 7,9.93998388 7,8 C7,5.79 8.79,4 11,4 Z" id="path-1"></path>
    </defs>
    <g id="Icons/People/No-User" stroke="none" stroke-width="1" fill="currentColor" fill-rule="evenodd">
        <mask id="mask-2" fill="white">
            <use xlink:href="#path-1"></use>
        </mask>
        <use id="Combined-Shape" fill="currentColor" xlink:href="#path-1"></use>
        <g id="Group" mask="url(#mask-2)" fill="currentColor">
            <g id="🎨Colour">
                <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
            </g>
        </g>
    </g>
    </svg>`;
    eIcon.classList.add("noUserIcon");
    eIcon.setAttribute("data-test", "noUserIcon");

    params.eGridCell.title = iconTitle[name];

    return eIcon;
  }

  if (!seaChatWidget.initialized) waitForSeaChatAndRerenderCell(params);

  return renderIcon({ name, disabled }, { ...params, title: iconTitle[name] });
}

async function onNegotiationChatCellClick(params, e) {
  const { groupChat, id, orderId } = params.data as Negotiation;
  usageMetrics.trackEvent(SeaChatEvents.SEA_CHAT_BTN_NEGOTIATION_SIDE_MENU, {
    groupChatId: groupChat?.id,
    orderId,
    negotiationId: id,
  });

  if (!groupChat) return;

  e.stopPropagation();

  seaChatWidget.openGroupChat(groupChat?.id);
}

async function waitForSeaChatAndRerenderCell(params) {
  await seaChatWidget.promise;

  params.refreshCell(true);
}

function RenderStatusAndLastUpdated(params) {
  let { status, lastUpdated, statusRow, lastUpdatedBy } = params.value;

  statusRow = { ...STATUS_ROW[status], ...statusRow };

  const { expiresOn } = statusRow;
  const timeleftTillExpiry = formatStatusTimeleft(timeleft(expiresOn), expiresOn);
  const lastUpdatedRow = `${timepassed(lastUpdated.value)}${lastUpdatedBy ? ` by ${lastUpdatedBy}` : ""}`;

  statusRow.text = `${statusRow.text || ""}${timeleftTillExpiry}`;

  useEfficientTimeRerenderer({ date: expiresOn, type: "timeleft" }, { date: lastUpdated.value, type: "timepassed" });

  return renderMultipleRowsReact({ rows: [statusRow, lastUpdatedRow] });
}

function multiLocationCellRenderer(params) {
  const locationMap = params.value;
  const { loadLocation, dischargeLocation, deliveryLocation, viaLocation, redeliveryLocation } = locationMap || {};

  const loadDeliveryLocations = [loadLocation, deliveryLocation].filter(isTruthy);
  const dishRedeliveryLoctations = [dischargeLocation, redeliveryLocation].filter(isTruthy);
  const locations = [loadLocation, dischargeLocation, deliveryLocation, redeliveryLocation, viaLocation].filter(isTruthy);

  const overloadCount = Math.max(0, locations.length - 2);
  const overload = overloadCount > 0 ? ` +${overloadCount}` : "";
  const location0 = loadDeliveryLocations[0];
  const location1 = { ...dishRedeliveryLoctations[0], text: `${dishRedeliveryLoctations[0]?.text || ""}${overload}` };
  const tooltip = renderTooltipString(locations);
  const rows = [location0, location1];

  return standardMultipleRowRenderer({ rows, tooltip, params });
}

function voyTctCountRenderer(params) {
  const { voyCount, tctCount } = params.value;
  const text = [voyCount?.text, tctCount?.text].filter(isTruthy).join(" / ");

  params.value = text;

  return standardCellRenderer(params);
}

function standardMultiValueFilterValueGetter(params, sequence?: MultiValueSequence) {
  const _sequence = sequence || (params.colDef.cellRendererParams.sequence as MultiValueSequence);
  const valueMap = params.colDef.valueGetter(params) as RecordOf<MultiValueValue>;
  const joint = _sequence.flat().map(sequenceValueSelector).filter(isTruthy).join(` ${EMDASH} `);

  return joint;

  function sequenceValueSelector(sequenceItem: MultiValueSequenceItem) {
    const value = valueMap[sequenceItem.name];

    if (value && typeof value === "object") {
      if (value.suppressFilterValue) return;

      return value.filterValue || value.text || value.value;
    }

    return value;
  }
}

function standardMultiValueCellRenderer(params) {
  const sequence = params.sequence as MultiValueSequence;
  const valueMap = params.value as RecordOf<MultiValueValue>;
  const sequenceFlat = sequence.flat();

  if (sequenceFlat.length === 1) {
    const value = valueMap[sequenceFlat[0].name];
    const text = value && typeof value === "object" ? value?.text || value?.value : value;

    params.value = text;

    return standardCellRenderer(params);
  }

  function sequenceValueSelector(sequenceItem: MultiValueSequenceItem | MultiValueSequenceItem[]) {
    if (Array.isArray(sequenceItem)) {
      return sequenceItem.map(sequenceValueSelector);
    }

    const value = valueMap[sequenceItem.name];

    if (value && typeof value === "object") return { ...value, text: value.text || value.value };

    return value;
  }

  // @ts-ignore
  const rows = sequence.map(sequenceValueSelector);

  return standardMultipleRowRenderer({ rows, params });
}

function standardCellRenderer(params) {
  const value = standardValueDisplayFormatter(params.value);

  params.eGridCell.innerHTML = value;
  params.eGridCell.setAttribute("title", value);
}

function standardMultipleRowRenderer({ rows, params, ...options }) {
  const tooltip = options.tooltip || renderTooltipString(rows.flat());

  rows = rows.map(renderRowOrMergeRowsHTML.bind(null, options, params)).join("");

  params.eGridCell.innerHTML = rows;
  params.eGridCell.setAttribute("title", tooltip);

  return;
}

function renderRowOrMergeRowsHTML(options, params, rowOrRows) {
  const rows = [].concat(rowOrRows);

  return `<sea-aggrid-text-row>${rows.map(renderRowContentHTML.bind(null, options, params)).join(" / ")}</sea-aggrid-text-row>`;
}

function renderRowContentHTML(options, params, row) {
  if (row instanceof DataModel) row = row.toGridView();

  if (typeof row !== "object") row = { text: row };

  let { icon, img } = { ...options, ...row } as any;
  let { text } = row;

  text = text || "--";
  icon = renderIconHTML(icon || params.icon);
  img = renderImgHTML(img);

  return `${img}${icon}${text}`;
}

function renderMultipleRowsReact({ rows, ...options }) {
  const tooltip = renderTooltipString(rows);

  options = { tooltip, ...options };

  return rows.map(renderRowReact.bind(null, options));
}

function renderRowReact(options, row, i) {
  if (row instanceof DataModel) row = row.toGridView();

  if (typeof row !== "object") row = { text: row };

  let { className, tooltip, icon, img } = { ...options, ...row } as any;
  let { text } = row;

  text = text || "--";
  tooltip = tooltip || text;

  return (
    <sea-aggrid-text-row className={className} title={tooltip} key={i}>
      <Img img={img} />
      <Icon icon={icon} />
      {text}
    </sea-aggrid-text-row>
  );
}

function renderCheckboxWithTooltipReact(params) {
  const { tooltip } = params.value;

  return (
    <sea-aggrid-text-row
      style={{
        position: "absolute",
        left: "4px",
        bottom: "8px",
        width: "24px",
        height: "24px",
        zIndex: 999,
        // TODO: add cursor styles if needed or remove ugly "not-allowed"
        // cursor: `url(""), not-allowed`,
        cursor: "not-allowed",
      }}
      title={tooltip}
    ></sea-aggrid-text-row>
  );
}

export function renderIcon(icon, params) {
  const { eGridCell, colDef, title } = params;

  if (typeof icon !== "object") icon = { name: icon };

  const { name, className, disabled } = icon;

  if (!name) return;

  const eIcon = document.createElement("sea-icon");

  eIcon.className = classNames(className, `icon--${name}`, name === "kebab" ? "expand-clickable-space" : "", { disabled });

  if (title) eIcon.title = title;

  eGridCell.onclick = colDef.onCellClicked && onClick;

  function onClick(e) {
    if (disabled) {
      e.stopPropagation();
      return;
    }
    colDef.onCellClicked(params, e);
  }

  return eIcon;
}

function renderIconHTML(icon) {
  if (typeof icon !== "object") icon = { name: icon };

  let { name, className } = icon;

  if (!name) return "";

  className = className || "";

  return `<sea-icon data-test class="${className} icon--${name}"></sea-icon>`;
}

function renderImgHTML(img) {
  if (typeof img !== "object") img = { src: img };

  const { src } = img;

  if (!src) return "";

  return `<img src="${src}">`;
}

function getRowText(row) {
  if (row instanceof DataModel) row = row.toGridView();

  if (typeof row !== "object") row = { text: row };

  return row?.text || "";
}

function renderTooltipString(...rows) {
  rows = rows.flat().map(getRowText).filter(isTruthy);

  return rows.join(`
`);
}

function resolveMultiValueCellClass(params) {
  if (params.colDef.cellRendererParams.sequence.length === 1) return "aggrid-bold-cell";

  return "";
}

function standardValueDisplayFormatter(value) {
  if (value instanceof DataModel) value = value.toGridView();

  return value || "--";
}

function standardAgSetColumnFilterComparator(a, b) {
  a = `${a}`;
  b = `${b}`;

  if (a === b) return 0;

  return a < b ? 1 : -1;
}

function formatStatusTimeleft(duration, expiresOn) {
  if (!expiresOn) return "";
  if (duration?.days > 2) return ` > ${duration?.days} days`;
  if (duration?.hours) return ` > ${duration?.days * 24 + duration?.hours}hr`;
  if (duration?.minutes) return ` > ${duration?.minutes}min`;
  if (duration?.secs) return ` > ${duration.secs}s`;

  return " Expired";
}

const STATUS_ROW = {
  Withdrawn: { text: "Withdrawn", icon: { className: "color-withdrawn", name: "no-entry" } },
  Nomination: { text: "Nomination", icon: { className: "color-nomination", name: "in-negotiation" } },
  InactiveNegotiations: { icon: { className: "color-inactive" } },
  Inactive: { icon: { className: "color-inactive" } },
  Active: { text: "Active", icon: { className: "color-active-negotiation", name: "in-negotiation" } },
  Failed: { text: "Failed", icon: { className: "color-failed" } },
  Firm: { text: "Firm", icon: { className: "color-firm", name: "done" } },
  TermsLoading: { text: "Terms Loading", icon: { className: "color-termsloading" } },
  MainTerms: { text: "Main Terms", icon: { className: "color-mainterms", name: "file" } },
  OnSubs: { text: "On Subs", icon: { className: "color-onsubs", name: "clock" } },
  SubsLifted: { text: "Subs Lifted", icon: { className: "color-subslifted", name: "clock-arrow" } },
  SubsFailed: { text: "Subs Failed", icon: { className: "color-subsfailed", name: "clock-strike" } },
  Fixed: { text: "Fixed", icon: { className: "color-fixed", name: "thumbs-up" } },
  Confirmed: { text: "Confirmed", icon: { className: "color-confirmed", name: "thumbs-up" } },
  Archived: { text: "Archived", icon: { className: "color-archived" } },
};

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

type MultiValueValue = Primitive | IMultiValueValue;

interface IMultiValueValue {
  value: Primitive;
  text: string;
  sortValue?: Primitive | Date;
  filterValue?: Primitive;
  suppressFilterValue?: boolean;
  img?: ImgProps["img"];
}

interface ResolveMultiValueColDefParams {
  sequence: MultiValueSequence;
  headerClass?: string;
  cellRendererParams?: AggridColDef["cellRendererParams"];
}

type MultiValueSequence = (MultiValueSequenceItem | MultiValueSequenceItem[])[];

interface MultiValueSequenceItem {
  name: string;
  heading: string | undefined;
}
