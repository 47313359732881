import React from "react";
import { CleaningPrice, CleaningPriceProps } from "@/models";
import { UnitValueEditorProps, UnitValueEditor } from "../UnitValue";

export function CleaningPriceEditor(props: Props) {
  props = { ...props };

  props.className = `${props.className} CleaningPrice`;

  function onChange(value: CleaningPrice) {
    const next = new CleaningPrice(value);

    props.onChange?.(next);
  }

  return (
    <UnitValueEditor {...props} units={CleaningPriceProps.prototype.units} onChange={onChange} dataTest={`${props.dataTest}`} />
  );
}

interface Props extends Omit<UnitValueEditorProps, "value" | "onChange"> {
  value?: CleaningPrice;
  onChange?: (value: CleaningPrice) => void;
}

export type CleaningPriceEditorProps = Props;
