import { LABEL_MAP } from "___REFACTOR___/constants";
import { Period, PeriodProps } from "../Period";

class Laycan extends Period {
  static label = LABEL_MAP.laycan;
}

class LaycanProps extends PeriodProps {
  get label() {
    return LABEL_MAP.laycan;
  }
}

Laycan.prototype.Props = LaycanProps;

export { Laycan, LaycanProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface Laycan {
  Props: typeof LaycanProps;
  _: LaycanProps;
}
