import React, { useState, CSSProperties } from "react";
import { createPortal } from "react-dom";
import { observer } from "mobx-react";
import { useSimpleEffect, wait } from "___REFACTOR___/utils";
import * as Services from "___REFACTOR___/services";
import { CircularSpinner } from "___REFACTOR___/components/common";
import "./Spinners.scss";

function Spinner(props: Spinner.Props) {
  const { target } = props.spinner.props;
  const [style, setStyle] = useState<CSSProperties>({});

  useSimpleEffect(effect, [target]);

  if (!target) return null;

  return (
    <spinners-service-instance style={style}>
      <CircularSpinner style={style} />
    </spinners-service-instance>
  );

  async function effect() {
    if (!target) return;

    await wait(0);

    const { width, height } = target.getBoundingClientRect();

    target.style.position = "relative";

    setStyle({ width, height });
  }
}

function SpinnersPortal() {
  const spinnerArr = Object.values(Services.spinners.registry);

  return (
    <spinner-service>
      {spinnerArr.map((spinner) => createPortal(<Spinner spinner={spinner} key={spinner.id} />, spinner.props.target))}
    </spinner-service>
  );
}

const Spinners = observer(SpinnersPortal);

export { Spinners };

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

declare namespace Spinners {
  export interface Props {
    target: HTMLElement;
  }
}

declare namespace Spinner {
  export interface Props {
    spinner: Services.Spinners.Spinner;
  }
}
