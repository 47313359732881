import React from "react";
import { TextareaField, NumberField, Field } from "___REFACTOR___/components";
import { BunkerDelivery, BunkerRedelivery } from "___REFACTOR___/models";
import { Group } from "../../Group";
import { Row } from "../../Row";

function BunkerBaseEditor(props: BunkerBaseEditor.Props) {
  props = { ...props };

  const { value, editorType, dataTest, hidden, bunkerType } = props;
  const { price, quantity, fuelTypes, notes } = value || {};

  function onChange(key: keyof BunkerRedelivery | keyof BunkerDelivery, propertyValue) {
    const newValue = { ...value, [key]: propertyValue };
    const next = editorType === "BunkerDelivery" ? new BunkerDelivery(newValue) : new BunkerRedelivery(newValue);

    props.onChange?.(next);
  }

  return (
    <Group {...props} editorType={editorType} hidden={hidden}>
      <Row className="flex-end">
        <NumberField
          {...props}
          label={`Bunker Price ${bunkerType} ($)`}
          value={price}
          onChange={onChange.bind(null, "price")}
          min={0}
          max={999_999_999_999}
          placeholder="e.g. 30000"
          dataTest={`${dataTest}.price`}
        />
        <NumberField
          {...props}
          label="Quantity"
          value={quantity}
          onChange={onChange.bind(null, "quantity")}
          min={0}
          max={999_999_999_999}
          placeholder="e.g. 30000"
          dataTest={`${dataTest}.quantity`}
        />
      </Row>

      <TextareaField
        {...props}
        label="Fuel Types"
        value={fuelTypes}
        placeholder=""
        dataTest={`${dataTest}.fuelTypes`}
        onChange={onChange.bind(null, "fuelTypes")}
      />

      <TextareaField
        {...props}
        label="Notes"
        value={notes || ""}
        onChange={onChange.bind(null, "notes")}
        placeholder="Additional bunker notes"
        dataTest={`${dataTest}.notes`}
        hidden={!value}
        required={false}
      />
    </Group>
  );
}

export { BunkerBaseEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace BunkerBaseEditor {
  export interface Props extends Omit<Field.Props, "value" | "onChange" | "bunkerType"> {
    value: Value;
    onChange: (value: Value) => void;
    bunkerType: "Delivery" | "Re-Delivery";
  }

  export type Value = BunkerDelivery | BunkerRedelivery | undefined;
}
