import React, { useContext, useMemo, useEffect } from "react";
import { reaction } from "mobx";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { testSelectors } from "@/constants";
import { OrderTemplate, UniversalOrderNegotiationFormValues } from "@/models";
import { UniversalOrderNegotiationForm, FormContext } from "@/components";
import { OrderCreationTypeEvents, usageMetrics } from "@/services/UsageMetrics";
import { FormControl } from "./";
import { Context } from "../../../Orders";
import "./NewOrderForm.scss";

function NewOrderForm(props: Props) {
  const { orderId } = useParams<StringRecord>();
  const context = useContext(Context);
  const { dataTest, orderNegotiationStore, view } = context;
  const { orderSearchQuery } = view.order;
  const contextViewOrderProps = view.order[props.section];
  const { form, title } = contextViewOrderProps || {};
  const formContext = form?.context as FormContext<UniversalOrderNegotiationFormValues>;
  const order = orderId ? orderNegotiationStore?.upsertOrderModel({ id: orderId }) : undefined;
  const initialValues = useMemo(getInitialValues, []);
  const elements = useMemo(createElements, []);
  let startTime;

  useEffect(subscribeToTemplateChange, []);
  useEffect(() => {
    startTime = usageMetrics.startTrackingAction(OrderCreationTypeEvents.ORDER_CREATION_STARTED, undefined);
  }, []);

  return (
    <>
      <div className="OrderCreation" data-test={testSelectors.newOrderForm}>
        <UniversalOrderNegotiationForm
          {...form}
          context={formContext}
          elements={elements}
          initialValues={initialValues}
          definition={initialValues}
          onSubmit={onSubmit}
          enableReinitialize
        />
      </div>
      <footer className="OrderFooter">
        <FormControl contextViewOrderProps={contextViewOrderProps} dataTest={dataTest} />
      </footer>
    </>
  );

  function onSubmit(data: UniversalOrderNegotiationFormValues) {
    props.onSubmit(order, context, data, startTime);
  }

  function subscribeToTemplateChange() {
    const unsubscribe = reaction(templateChangeReaction, handleTemplateChange);

    return unsubscribe;
  }

  function templateChangeReaction() {
    const { orderTemplate } = formContext?.values || {};

    return orderTemplate;
  }

  function handleTemplateChange(orderTemplate?: OrderTemplate, prevOrderTemplate?: OrderTemplate) {
    if (orderTemplate?.id === prevOrderTemplate?.id) return;

    if (!orderTemplate?.id) return;

    const details = props.getPayload({ ...orderTemplate.getFormValues() });

    const values = new UniversalOrderNegotiationFormValues({ ...details, orderTemplate });

    formContext.formik?.setValues(values);
  }

  function createElements() {
    const elements = form?.elements || [];

    const defaultElements = [
      {
        type: "CollapsibleGroup",
        label: title,
        children: [
          {
            name: "orderTemplate",
            label: "Template",
            orderSearchQuery,
          },
        ],
      },
    ];

    return [...defaultElements, ...elements];
  }

  function getInitialValues() {
    return new UniversalOrderNegotiationFormValues();
  }
}

const Observer = observer(NewOrderForm);

export { Observer as NewOrderForm };

interface Props {
  getPayload: any;
  section: "capture" | "creation";
  onSubmit: any;
}
