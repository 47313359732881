import { NegotiationUIStatus } from "../../offerRep/OwnerIndicationForm/reducers/offerRepStatusReducer";
import { IMessageState } from "sharedFolder/components/common/Messages/types";
import { CirculatedByRoleDescription } from "negotiations/components/negotiate/Negotiation/Role";

type MessageType = NegotiationUIStatus | "Close";

type MessageStatus = {
  type: MessageType;
};

export function negotiationMessageReducer(state: IMessageState, action: MessageStatus): IMessageState {
  const counterParty = CirculatedByRoleDescription(state.circulatedByRole);
  switch (action.type) {
    case "None":
      return {
        ...state,
        colour: "blue",
        icon: "lightbulb-outline",
        open: true,
        title: "Invitation to Negotiate",
        body: `Enter your OFFER below to negotiate with the ${counterParty}`,
      };
    case "RequestErrorOccured":
      return {
        ...state,
        colour: "red",
        icon: "warning",
        open: true,
        title: "Something Went Wrong",
        body: "Your responses were not submitted, please try again",
      };
    case "IndicationBidSent":
      return {
        ...state,
        colour: "blue",
        icon: "lightbulb-outline",
        open: true,
        title: `The ${counterParty} Has Responded`,
        body: "It's your turn to respond below",
      };

    case "FirmOfferCounteredWithIndication":
      return {
        ...state,
        colour: "blue",
        icon: "lightbulb-outline",
        open: true,
        title: "Responses",
        body: `The ${counterParty} has responded with an INDICATION. You can respond by updating your firm OFFER and clicking 'Send Firm OFFER to ${counterParty}'`,
      };
    case "IndicationOffer":
      return {
        ...state,
        colour: "blue",
        icon: "lightbulb-outline",
        open: true,
        title: "Submitted",
        body: `It's now the ${counterParty}'s turn to respond. You can still edit your response below`,
      };
    case "FirmOfferRequested":
      return {
        ...state,
        colour: "blue",
        icon: "lightbulb-outline",
        open: true,
        title: `The ${counterParty} Has Requested A Firm OFFER`,
        body: "Respond below",
      };
    case "FirmOffer":
      return {
        ...state,
        colour: "blue",
        icon: "lightbulb-outline",
        open: true,
        title: "Firm OFFER Sent",
        body: `The ${counterParty} has until the timer runs out to respond`,
      };
    case "FirmBid":
      return {
        ...state,
        colour: "orange",
        icon: "flag",
        open: true,
        title: `The ${counterParty} Sent A Firm Offer`,
        body: "It's your turn to respond",
      };
    case "FirmBidAccepted":
      return {
        ...state,
        colour: "green",
        icon: "done",
        open: true,
        title: "Firm Offer Accepted",
        body: "You will shortly receive an email to participate in a main terms negotiation",
      };
    case "FirmOfferAccepted":
      return {
        ...state,
        colour: "green",
        icon: "done",
        open: true,
        title: "Firm OFFER Accepted",
        body: "You will shortly receive an email to participate in a main terms negotiation",
      };
    case "SendRecap":
      return {
        ...state,
        colour: "green",
        icon: "done",
        open: true,
        title: "Recap Sent",
        body: "A recap of this negotiation has been sent to all parties via email",
      };
    case "FirmOfferExpired":
      return {
        ...state,
        colour: "orange",
        icon: "flag",
        title: "Your Firm OFFER Expired",
        open: true,
        body: "You can continue to negotiate below",
      };
    case "FirmBidExpired":
      return {
        ...state,
        colour: "orange",
        icon: "flag",
        title: `The ${counterParty}'s Firm Offer Expired`,
        open: true,
        body: "You can continue to negotiate below",
      };
    case "Withdrawn":
      return {
        ...state,
        colour: "orange",
        icon: "flag",
        title: "This negotiation has been withdrawn",
        open: true,
        body: "You can no longer submit INDICATIONS or OFFERS.",
      };

    case "Close":
      return { ...state, open: false };
    default:
      return { ...state, open: false };
  }
}
