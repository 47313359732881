import { DataModel, DataModelProps } from "../DataModel";

class NegotiationTypeCount extends DataModel {
  constructor(data: PartialData) {
    super(data);
  }

  toString() {
    if (!this.type || !this.count) return "";

    return `${this.type} (${this.count})`;
  }

  toGridView() {
    if (!this.type || !this.count) return "";

    return `${this.type} (${this.count})`;
  }
}

class NegotiationTypeCountProps extends DataModelProps {}

NegotiationTypeCount.prototype.Props = NegotiationTypeCountProps;
NegotiationTypeCountProps.prototype.sortKey = "count";

export { NegotiationTypeCount, NegotiationTypeCountProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface NegotiationTypeCount extends Data {
  Props: typeof NegotiationTypeCountProps;
  _: NegotiationTypeCountProps;
}

interface NegotiationTypeCountProps {
  sortKey: keyof Data;
}

type PartialData = Partial<Data>;
interface Data {
  type: string;
  count: number;
}
