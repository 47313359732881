import { TextareaField } from "___REFACTOR___/components/common/Field/type/Textarea";
import { PrimitiveDataModel, PrimitiveDataModelProps } from "../PrimitiveDataModel";

class TextareaString extends PrimitiveDataModel {
  static Editor = TextareaField;

  constructor(data?: Data) {
    super(data);
  }
}

class TextareaStringProps extends PrimitiveDataModelProps {
  get Editor() {
    return TextareaField;
  }
}

TextareaString.prototype.Props = TextareaStringProps;

export { TextareaString, TextareaStringProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface TextareaString {
  Props: typeof TextareaStringProps;
  _: TextareaStringProps;

  data: Data;
}

type Data = string;
