import { createTheme } from "@material-ui/core";

export const theme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#2196f3",
    },
    background: {
      default: "#112539",
      paper: "#112539",
    },
  },
});
