import * as ReactRouterDOM from "react-router-dom";
import { Route as Model } from "___REFACTOR___/models/common/Route";

function Route(props: Route.Props) {
  let { route, path, suppressMatching, ...rest } = props;
  let routePath: Route.Path;

  if (Array.isArray(route)) {
    routePath = route.map((route) => route.path);
  }
  //
  else if (route) {
    routePath = route.path;
  }

  path = path || routePath;

  const override = {} as ReactRouterDOM.RouteProps;

  if (suppressMatching) override.path = "*";

  return <ReactRouterDOM.Route path={path} {...rest} {...override} />;
}

// @ts-ignore
ReactRouterDOM.Route.displayName = "react-router-dom.Route";

export { Route };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace Route {
  interface Props extends ReactRouterDOM.RouteProps {
    route?: Model | Model[];
    suppressMatching?: boolean;
  }

  type Path = ReactRouterDOM.RouteProps["path"];
}
