import { ICargoTypeView, ICommissionView } from "sharedFolder/Models/IDetails";

import { formatToDecimal } from "../../services/utils/converters";

export function cargoTypeText(value: ICargoTypeView): string | undefined {
  const summary = [value.name, value.notes].filter((x) => Boolean(x)).join(" - ");
  return summary || undefined;
}

export function commissionText(value: ICommissionView): string | undefined {
  if (value.value) return percentageThreeDecimalText(value.value);
  return undefined;
}

export function percentageThreeDecimalText(value: string): string {
  return `${formatToDecimal(value, 3)} %`;
}
