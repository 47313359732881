import { Observable, Subject } from "rxjs";

export function observableFetch<T>(fetch: () => Promise<Response>) {
  return Observable.create((subject: Subject<T>) => {
    fetch()
      .then((response) => {
        if (!response.ok) {
          throw Error(`${response.status}: ${response.statusText}`);
        }
        return response.json();
      })
      .then((data: T) => {
        subject.next(data);
        subject.complete();
      })
      .catch((err) => subject.error(err));
  });
}
