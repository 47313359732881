import React from "react";
import { Liftings, LiftingsProps } from "___REFACTOR___/models";
import { SelectField, Field, TextareaField, NumberField, StatusMessages } from "___REFACTOR___/components";
import { Group } from "../../Group";
import { Row } from "../../Row";
import "./Liftings.scss";

function LiftingsEditor(props: LiftingsEditor.Props) {
  const { className, value, status, dataTest, ...rest } = props;
  const { min, max, dispersal } = value || {};

  return (
    <Group {...props} editorType="Liftings">
      <Row>
        <NumberField
          {...rest}
          label="Liftings / Min"
          desc="Decides what is the minimum value for liftings option"
          placeholder="e.g. 5"
          className="Liftings-min"
          status={status?.childMap?.min}
          value={min}
          onChange={onChange.bind(null, "min")}
          dataTest={`${dataTest}.min`}
          suppressStatusMessages
        />
        <NumberField
          {...rest}
          label="Max"
          desc="Decides what is the maximum value for liftings option"
          placeholder="e.g. 10"
          className="Liftings-max"
          status={status?.childMap?.max}
          value={max}
          onChange={onChange.bind(null, "max")}
          dataTest={`${dataTest}.max`}
          suppressStatusMessages
        />
        <SelectField
          {...rest}
          label="Dispersal"
          desc="Dispersal defines the lifting option type"
          className="Liftings-dispersal"
          data={LiftingsProps.prototype.dispersalArr}
          filterKey="name"
          status={status?.childMap?.dispersal}
          value={dispersal || LiftingsProps.prototype.JSONDefaults?.dispersal}
          onChange={onDispersalChange}
          dataTest={`${dataTest}.dispersal`}
          unclearable
          nonFilterSelect
          suppressStatusMessages
        />
      </Row>
      <StatusMessages status={status} />
      <TextareaField
        {...props}
        label="Notes"
        desc="Liftings notes"
        value={value?.notes || ""}
        onChange={onNotesChange}
        status={status?.childMap?.notes}
        dataTest={`${dataTest}.notes`}
      />
    </Group>
  );

  function onChange(name: keyof Liftings, inputValue: NumberField.Input.Value) {
    const next = new Liftings({
      ...value,
      [name]: inputValue,
    });

    props.onChange?.(next);
  }

  function onDispersalChange(dispersal) {
    const next = new Liftings({
      ...value,
      dispersal,
    });

    props.onChange?.(next);
  }

  function onNotesChange(notes: TextareaField.Input.Value) {
    if (typeof notes !== "string") return;

    props.onChange?.(new Liftings({ ...props.value, notes }));
  }
}

export { LiftingsEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace LiftingsEditor {
  export interface Props extends Field.Props {
    value: Value;
    onChange: (value: Value) => void;
  }

  export type Value = Liftings | undefined;
}
