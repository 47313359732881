import * as React from "react";
import vesselSizes from "orders/staticData/vesselSizes.json";
import { Notes } from "sharedFolder/components/common/Notes/Notes";
import { IVesselSizeView } from "sharedFolder/Models/IDetails";
import { LookUpSingleEntry } from "sharedFolder/components/editors/LookUpSingleEntry/LookUpSingleEntry";

interface IVesselSizeEditorProps {
  disabled?: boolean;
  vesselSize: IVesselSizeView;
  onChange: (value: IVesselSizeView) => void;
  focus?: boolean;
  required?: boolean;
  displayLabel?: boolean;
}

interface IVesselSizeSearchResult {
  vesselSizeFull: string;
  vesselSizeAbbreviation: string;
  sizeFrom: number;
  sizeTo: number;
}

export class VesselSizeEditor extends React.Component<IVesselSizeEditorProps> {
  constructor(props: IVesselSizeEditorProps) {
    super(props);
    this.onVesselSizeSelected = this.onVesselSizeSelected.bind(this);
    this.handleNotesChange = this.handleNotesChange.bind(this);
    this.getVesselSizes = this.getVesselSizes.bind(this);
  }

  public render() {
    return (
      <>
        {this.props.displayLabel && <label htmlFor="">Vessel Size</label>}
        <LookUpSingleEntry
          id={"VesselSize"}
          defaultValue={
            this.props.vesselSize.vesselSizeAbbreviation !== ""
              ? [this.getVesselSizeById(this.props.vesselSize.vesselSizeAbbreviation)]
              : null
          }
          autoCompleteFunction={this.getVesselSizes}
          onChange={this.onVesselSizeSelected}
          getDisplayText={this.getDisplayText}
          disabled={this.props.disabled}
          focus={this.props.focus}
        />
        <Notes onChange={this.handleNotesChange} value={this.props.vesselSize.notes} disabled={this.props.disabled} />
      </>
    );
  }

  private async getVesselSizes(filter: string): Promise<IVesselSizeSearchResult[]> {
    return await this.filterVesselSizes(filter);
  }

  private filterVesselSizes = (filter: string): IVesselSizeSearchResult[] => {
    return (
      vesselSizes.filter((vesseSize) => vesseSize.vesselSizeAbbreviation.toUpperCase().indexOf(filter.toUpperCase()) >= 0) || []
    );
  };

  private getDisplayText(item: IVesselSizeSearchResult | IVesselSizeSearchResult[]): string {
    if (Array.isArray(item) && item.length > 0) {
      return item[0].vesselSizeAbbreviation;
    }
    const vesselSizeSearchResult = item as IVesselSizeSearchResult;
    return vesselSizeSearchResult.vesselSizeAbbreviation;
  }

  private handleNotesChange(notes: string) {
    this.props.onChange({
      ...this.props.vesselSize,
      display:
        this.props.vesselSize.vesselSizeAbbreviation !== "" ? `${this.props.vesselSize.vesselSizeAbbreviation} ${notes}` : "",
      notes: notes,
    });
  }

  private onVesselSizeSelected(selected: any) {
    if (selected) {
      const vesselSize: IVesselSizeView = {
        ...this.props.vesselSize,
        ...selected,
        display: `${selected.vesselSizeAbbreviation} ${this.props.vesselSize.notes}`,
      };
      this.props.onChange(vesselSize);
    } else {
      this.props.onChange({
        ...this.props.vesselSize,
        vesselSizeFull: "",
        vesselSizeAbbreviation: "",
        sizeFrom: "",
        sizeTo: "",
        display: "",
      });
    }
  }

  private getVesselSizeById = (id: string) => {
    return vesselSizes.filter((vesseSize) => vesseSize["vesselSizeAbbreviation"] === id)[0];
  };
}
