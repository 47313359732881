import { useEffect, useMemo, useRef } from "react";
import { AnySchema } from "yup";
import { log } from "___REFACTOR___/services";
import { unsavedChanges } from "@/models";
import { useFormik, yupToFormErrors, FormikConfig, FormikProps, FormikErrors, FormikHelpers } from "formik";
import { get, isEqual } from "lodash-es";
import { Status } from "___REFACTOR___/models";

// https://formik.org/docs/overview
// https://github.com/formium/formik/pull/1429
function useForm<Values>(config: Form.Config<Values>) {
  const { validationSchema, onReset: propsOnReset, ...rest } = config;

  const _config = {
    validate: validateWithValidationSchema,
    onReset,
    ...rest,
  };

  const eventListenerMap = useMemo(getEventListenerMap, []);
  // @ts-ignore
  const props = useFormik(_config) as Form.Props<Values>;

  useEffect(registerUnsavedChangesHandler, [props]);

  function registerUnsavedChangesHandler() {
    return unsavedChanges.register(() => !isEqual(props.values, props.initialValues));
  }

  const propsRef = useRef(props);
  propsRef.current = props;

  props.getEditorProps = getEditorProps;
  props.addEventListener = addEventListener;
  props.removeEventListener = removeEventListener;
  props.isInvalid = !props.isValid;
  props.hasAttemptedSubmit = props.submitCount > 0;
  props.shouldDisplayAllErrors = props.isInvalid && props.hasAttemptedSubmit;
  props.isSubmitDisabled = props.shouldDisplayAllErrors || !props.dirty;

  if (config.isSubmitDisabled) props.isSubmitDisabled = config.isSubmitDisabled(props);
  if (config.isSubmitEnabled) props.isSubmitDisabled = !config.isSubmitEnabled(props);

  return props;

  function getEditorProps(
    name,
    getEditorPropsConfig = DEFAULT_GET_EDITOR_PROPS_CONFIG,
    onChangeMethod?: (value: string) => void
  ) {
    const touched = get(props.touched, name);
    const shouldDisplayError = touched || props.shouldDisplayAllErrors || getEditorPropsConfig.shouldDisplayError;
    let status: Status | undefined;

    if (shouldDisplayError) {
      status = errorToStatus(get(props.errors, name));
    }

    const setField = props.setFieldValue.bind(null, name);
    const res = {
      value: get(props.values, name),
      onChange: (value: any, shouldValidate?: boolean) => {
        if (onChangeMethod) {
          onChangeMethod(value);
        }
        setField(value, shouldValidate);
      },
      onBlur: props.setFieldTouched.bind(null, name, true),
      setTouched: props.setFieldTouched.bind(null, name),
      status,
      dataTest: `${config.dataTest}.${name}`,
    };

    return res;
  }

  function onReset(values: Values, formikHelpers: FormikHelpers<Values>) {
    propsOnReset?.(values, formikHelpers);

    const listeners = eventListenerMap.reset.keys();

    setTimeout(iterateListeners.bind(null, listeners));
  }

  function iterateListeners(listeners: IterableIterator<Form.Event.Listener<Values>>) {
    for (const listener of listeners) listener(propsRef.current);
  }

  function addEventListener(type: Form.Event.Type, listener: Form.Event.Listener<Values>) {
    eventListenerMap[type].set(listener, listener);

    return removeEventListener.bind(null, type, listener);
  }

  function removeEventListener(type: Form.Event.Type, listener: Form.Event.Listener<Values>) {
    eventListenerMap[type].delete(listener);
  }

  function getEventListenerMap() {
    return { reset: new Map(), submit: new Map() } as Form.Event.ListenerMap<Values>;
  }

  async function validateWithValidationSchema(values: Values) {
    if (!validationSchema) return;

    let formikErrors;

    try {
      await validationSchema.validate(values, { abortEarly: false });
      //
    } catch (yupValidatonError) {
      formikErrors = yupToFormErrors(yupValidatonError);

      log.system(`################### form ${config.dataTest}`);
      log.system("yupValidatonError", yupValidatonError);
      log.system("errors", formikErrors);
      log.system("values", values);
      log.system(`################### form ${config.dataTest}`);
    }

    return formikErrors;
  }
}

function errorToStatus(error: Form.Errors) {
  if (!error) return;

  const status = new Status({ type: "error" });

  if (typeof error === "string") {
    status.message = error;
  }

  if (typeof error === "object") {
    status.childMap = {};

    const entries = Object.entries(error);

    for (let i = 0; i < entries.length; i += 1) {
      const [name, error] = entries[i];

      const childStatus = errorToStatus(error);

      if (!childStatus) continue;

      status.childMap[name] = childStatus;
    }
  }

  return status;
}

const DEFAULT_GET_EDITOR_PROPS_CONFIG = {} as Form.GetEditorPropsConfig;

export { useForm, errorToStatus };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace Form {
  interface Config<Values> extends FormikConfig<Values> {
    validationSchema?: AnySchema;
    dataTest?: string;
    isSubmitDisabled?(props: Props<Values>): boolean;
    isSubmitEnabled?(props: Props<Values>): boolean;
  }

  interface Props<Values> extends Omit<FormikProps<Values>, "errors"> {
    getEditorProps<Value>(
      name: keyof Values,
      config?: GetEditorPropsConfig,
      onChange?: (value: string) => void
    ): EditorProps<Value>;
    getEditorProps<Value>(name: string, config?: GetEditorPropsConfig, onChange?: (value: string) => void): EditorProps<Value>;
    addEventListener(type: Event.Type, listener: Event.Listener<Values>): Event.RemoveThisEventListener;
    removeEventListener: Event.RemoveEventListener<Values>;
    isInvalid: boolean;
    hasAttemptedSubmit: boolean;
    shouldDisplayAllErrors: boolean;
    isSubmitDisabled: boolean;
    // @ts-ignore
    errors: DeepAny<Values>;
  }

  namespace Event {
    type Type = "reset" | "submit";

    type Listener<Values, P = Props<Values>> = (props: P) => void;
    type RemoveEventListener<Values> = (type: Event.Type, listener: Event.Listener<Values>) => void;
    type RemoveThisEventListener = () => void;
    interface ListenerMap<Values> {
      [eventType: string]: Map<Listener<Values>, Listener<Values>>;
    }
  }

  interface GetEditorPropsConfig {
    shouldDisplayError?: boolean;
  }

  interface EditorProps<Value> {
    value: Value extends unknown ? any : Value;
    onChange: (value: Value extends unknown ? any : Value, shouldValidate?: boolean) => void;
    status?: Status;
    dataTest: string;
  }

  type Errors = string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
}

export type { Form };
