import React, { useState, useEffect } from "react";
import classNames from "classnames";
import moment from "moment";
import VesselDetailEditor from "../VesselDetailEditor/VesselDetailEditor";
import { VesselNominiationStatus, IVesselView } from "sharedFolder/Models/IDetails";
import { IconTypes } from "sharedFolder/components/common/types";
import DatePicker from "sharedFolder/components/common/DatePicker/DatePicker";
import Button from "__legacy/sharedFolder/components/common/Button/Button";
import Icon from "__legacy/sharedFolder/components/common/Icon/Icon";
import TextArea from "sharedFolder/components/common/TextArea/TextArea";
import styles from "./VesselDisplay.module.scss";
import { emptyArrowFn } from "../../../../@/utils";

interface IVesselDisplayProps {
  disabled?: boolean;
  vessel: IVesselView;
  className?: string;
  onDelete?: (() => void) | null;
  onVesselChange: (vessel: IVesselView) => void;
  hideWarningFlag?: boolean;
}

interface IVesselDisplayState {
  ownerChain: string;
  itinerary: string;
  eta: Date | undefined;
  speedAndConsumption: string;
  additionalNotes: string;
  vesselDescription: string;
  isDateInThePast: boolean;
}

type IEditorItems = "ownerChain" | "itinerary" | "speedAndConsumption" | "eta" | "vesselDescription" | "additionalNotes";

const VesselStatus = ({ status }: { status?: VesselNominiationStatus }) => {
  if (!status || status === "named") {
    // no need to show anything for named
    return null;
  }
  const text = {
    accepted: "Accepted",
    rejected: "Rejected",
    named: "",
  };
  const stylesLookup: {
    [K in VesselNominiationStatus]: {
      icon: IconTypes;
      iconColor: string;
    };
  } = {
    accepted: {
      icon: "tick",
      iconColor: styles.acceptedIcon,
    },
    rejected: {
      icon: "withdrawn",
      iconColor: styles.rejectedIcon,
    },
    named: {
      icon: null,
      iconColor: "",
    },
  };
  return (
    <span className={styles.statusContainer}>
      <Icon icon={stylesLookup[status].icon} className={stylesLookup[status].iconColor} />
      {text[status]}
    </span>
  );
};

export const VesselDisplay = (props: IVesselDisplayProps) => {
  const { disabled } = props;
  const [editMode, setEditMode] = useState<IEditorItems | "">("");
  const { status, vesselName, vesselIMO, buildYear, dwt } = props.vessel;

  function mapToIVesselState(vessel: IVesselView): IVesselDisplayState {
    const { ownerChain, itinerary, speedAndConsumption, eta, vesselDescription, additionalNotes } = vessel;

    return {
      ownerChain: ownerChain || "",
      itinerary: itinerary || "",
      eta: eta ? new Date(eta) : undefined,
      speedAndConsumption: speedAndConsumption || "",
      vesselDescription: vesselDescription || "",
      additionalNotes: additionalNotes || "",
      isDateInThePast: false,
    };
  }

  const [vessel, setVessel] = useState<IVesselDisplayState>(mapToIVesselState(props.vessel));

  useEffect(() => {
    setVessel(mapToIVesselState(props.vessel));
  }, [props.vessel]);

  const mapToIVessel = (): IVesselView => {
    const newVessel = {
      ...props.vessel,
      ownerChain: vessel.ownerChain,
      itinerary: vessel.itinerary,
      speedAndConsumption: vessel.speedAndConsumption,
      additionalNotes: vessel.additionalNotes,
      vesselDescription: vessel.vesselDescription,
      eta: vessel.eta && moment(vessel.eta).isValid ? vessel.eta.toISOString() : "",
    };
    return newVessel;
  };

  const handleSave = () => {
    setEditMode("");
    props.onVesselChange(mapToIVessel());
  };

  const handleCancel = (item: IEditorItems) => {
    setEditMode("");
    revertState(item);
  };

  const handleToggleEditor = () => {
    if (editMode) {
      revertState(editMode);
    }
  };

  const revertState = (itemInEditMode: IEditorItems) => {
    if (itemInEditMode === "eta") {
      const newDate = props.vessel.eta ? new Date(props.vessel.eta) : undefined;
      setVessel({ ...vessel, eta: newDate, isDateInThePast: false });
    } else {
      setVessel({
        ...vessel,
        [itemInEditMode]: props.vessel[itemInEditMode] || "",
      });
    }
  };

  return (
    <div className={classNames(styles.container, props.className)}>
      <div className={styles.content}>
        <div className={styles.title} data-test="Vessel Display Name">
          {vesselName} - IMO {vesselIMO} YOB {buildYear} DWT {dwt}
          <div className={styles.status}>
            <VesselStatus status={status} />
          </div>
          {props.onDelete && (
            <Button type="flat" icon="delete" title="Delete Vessel" onClick={() => props.onDelete && props.onDelete()}>
              delete
            </Button>
          )}
        </div>
        <VesselDetailEditor
          disabled={disabled}
          editMode={editMode === "ownerChain"}
          editor={(onChange: (val: string) => void, value: string) => (
            <TextArea
              dataTest="vessel-owner-chain"
              onChange={(e) => onChange(e.currentTarget.value)}
              maxLength={2000}
              value={value}
              rows={3}
              autoFocus={true}
            />
          )}
          label={"Chartering Chain"}
          name="Chartering Chain"
          onCancel={() => handleCancel("ownerChain")}
          onChange={(ownerChain) => setVessel({ ...vessel, ownerChain })}
          onSave={handleSave}
          onToggleEditor={handleToggleEditor}
          value={vessel.ownerChain}
          toggleEditor={(open) => setEditMode(open ? "ownerChain" : "")}
          isValid={true}
        />
        <VesselDetailEditor
          disabled={disabled}
          editMode={editMode === "itinerary"}
          editor={(onChange: (val: string) => void, value: string) => (
            <TextArea
              dataTest="vessel-itinerary"
              onChange={(e) => onChange(e.currentTarget.value)}
              maxLength={2000}
              value={value}
              rows={3}
              autoFocus={true}
            />
          )}
          label="Vessel Itinerary"
          name="Vessel Itinerary"
          onCancel={() => handleCancel("itinerary")}
          onChange={(itinerary) => setVessel({ ...vessel, itinerary })}
          onSave={handleSave}
          onToggleEditor={handleToggleEditor}
          value={vessel.itinerary}
          toggleEditor={(open) => setEditMode(open ? "itinerary" : "")}
          isValid={true}
        />
        <VesselDetailEditor
          disabled={disabled}
          editMode={editMode === "eta"}
          editor={() => (
            <div data-test="vessel-eta">
              <DatePicker
                className={styles.etaContainer}
                allowTextInput={true}
                onSelectDate={(date: Date | null | undefined) => {
                  const now = new Date();
                  return setVessel({
                    ...vessel,
                    eta: date ? date : undefined,
                    isDateInThePast: date ? date && date < now : false,
                  });
                }}
                value={vessel.eta}
              />
              {vessel.isDateInThePast && <label className={styles.errorMessage}>The date you have entered is in the past</label>}
            </div>
          )}
          label="ETA"
          name="ETA"
          onCancel={() => handleCancel("eta")}
          onChange={emptyArrowFn}
          onSave={handleSave}
          onToggleEditor={handleToggleEditor}
          value={vessel.eta && moment(vessel.eta).isValid ? moment(vessel.eta).format("DD MMM YY") : ""}
          toggleEditor={(open) => setEditMode(open ? "eta" : "")}
          isValid={!vessel.isDateInThePast}
        />

        <VesselDetailEditor
          disabled={disabled}
          editMode={editMode === "speedAndConsumption"}
          editor={(onChange: (val: string) => void, value: string) => (
            <TextArea
              dataTest="vessel-speed-and-consumption"
              onChange={(e) => onChange(e.currentTarget.value)}
              maxLength={2000}
              value={value}
              rows={8}
              autoFocus={true}
            />
          )}
          label="Speed and Consumption"
          name="Speed and Consumption"
          onCancel={() => handleCancel("speedAndConsumption")}
          onChange={(speedAndConsumption) => setVessel({ ...vessel, speedAndConsumption })}
          onSave={handleSave}
          onToggleEditor={handleToggleEditor}
          value={vessel.speedAndConsumption}
          toggleEditor={(open) => setEditMode(open ? "speedAndConsumption" : "")}
          isValid={true}
        />
        <VesselDetailEditor
          disabled={disabled}
          editMode={editMode === "additionalNotes"}
          editor={(onChange: (val: string) => void, value: string) => (
            <TextArea
              dataTest="vessel-additional-notes"
              onChange={(e) => onChange(e.currentTarget.value)}
              maxLength={2000}
              value={value}
              rows={8}
              autoFocus={true}
            />
          )}
          label="Additional Notes"
          name="Additional Notes"
          onCancel={() => handleCancel("additionalNotes")}
          onChange={(additionalNotes) => setVessel({ ...vessel, additionalNotes })}
          onSave={handleSave}
          onToggleEditor={handleToggleEditor}
          value={vessel.additionalNotes}
          toggleEditor={(open) => setEditMode(open ? "additionalNotes" : "")}
          isValid={true}
        />
        <VesselDetailEditor
          disabled={disabled}
          editMode={editMode === "vesselDescription"}
          editor={(onChange: (val: string) => void, value: string) => (
            <TextArea
              dataTest="vessel-description"
              onChange={(e) => onChange(e.currentTarget.value)}
              maxLength={2000}
              value={value}
              rows={8}
              autoFocus={true}
            />
          )}
          label="Vessel Description"
          name="Vessel Description"
          onCancel={() => handleCancel("vesselDescription")}
          onChange={(vesselDescription) => setVessel({ ...vessel, vesselDescription })}
          onSave={handleSave}
          onToggleEditor={handleToggleEditor}
          value={vessel.vesselDescription}
          toggleEditor={(open) => setEditMode(open ? "vesselDescription" : "")}
          isValid={true}
        />
      </div>
    </div>
  );
};
