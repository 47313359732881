import React from "react";
import classNames from "classnames";
import { auth, CLDDUBroker } from "@/models";
import { CLDDUEditor, CLDDUEditorProps } from "../CLDDU";

export function CLDDUBrokerEditor(props: Props) {
  props = { ...props };

  props.className = classNames(props.className, "CLDDUBroker");

  const { className, ...rest } = props;

  let CLDDUQuery = "companyType eq 'broker'";

  if (auth.trade.user?._.companyRoleMap.broker) {
    CLDDUQuery += ` and companyId eq '${auth.trade.user.CompanyId}'`;
  }

  return (
    <CLDDUEditor
      {...rest}
      searchMessages={searchMessages}
      placeholder={placeholder}
      CLDDUSubset="users"
      CLDDUQuery={CLDDUQuery}
      Constructor={CLDDUBroker}
    />
  );
}

const placeholder = "Search for brokers";

const searchMessages = {
  initial: "Waiting for your input",
  searching: "Searching for brokers",
  noData: "No brokers found",
} as Props["searchMessages"];

type Props = CLDDUEditorProps;

export type CLDDUBrokerEditorProps = Props;
