import React from "react";
import Field from "../Field/Field";
import EditorRowEmptyValue from "../EditorRowEmptyValue/EditorRowEmptyValue";
import { NewUnitValueEditor } from "sharedFolder/components/editors/NewUnitValueEditor/NewUnitValueEditor";
import { IUnitValueView } from "sharedFolder/Models/IDetails";
import { FreightRateOptions } from "sharedFolder/Models/IFreightRate";
import { unitValueTextOrDisplay } from "sharedFolder/components/common/fields";

interface IFreightRateFieldProps<T> {
  isFieldValid: (key: T) => boolean;
  updateForm: (key: T, value: any) => void;
  selectedItem?: IUnitValueView;
  isExpanded: boolean;
  setExpandedItem: (name: T) => void;
  required: boolean;
}

export const FreightRateField = (props: IFreightRateFieldProps<"freightRate">) => {
  return (
    <Field
      id={"freightRate"}
      label={"Freight rate *"}
      name={"freightRate"}
      edit={
        <NewUnitValueEditor
          dataTest="freight-rate-notes"
          id="freightRateValue"
          type="freightRate"
          label="Freight Rate"
          defaultOption={"PerMT"}
          isValid={props.isFieldValid("freightRate")}
          notesPlaceholderText={"Additional freight rate notes"}
          displayNotes={true}
          onChange={(value?: IUnitValueView) => {
            props.updateForm("freightRate", value);
          }}
          required={props.required}
          options={FreightRateOptions}
          value={
            props.selectedItem || {
              unit: "",
              unitDisplay: "",
              value: null,
            }
          }
          focus={props.isExpanded}
        />
      }
      expanded={props.isExpanded}
      expand={() => {
        props.setExpandedItem("freightRate");
      }}
      display={
        <>
          {(props.selectedItem && freightRatesDisplay(props.selectedItem)) || <EditorRowEmptyValue required={props.required} />}
        </>
      }
      isValid={props.isFieldValid("freightRate")}
    />
  );
};

const freightRatesDisplay = (value?: IUnitValueView) => {
  return value ? unitValueTextOrDisplay(value) : "--";
};
