import * as React from "react";
import { CustomDropdown } from "sharedFolder/components/common/CustomDropdown/CustomDropdown";
import { getLocationSafetyWarranties, getLocationSafetyWarrantyByKey } from "sharedFolder/apis/LocationService";
import styles from "./LocationSafetyWarranty.module.scss";
import { ILocationSafeWarranty, ILocationView } from "sharedFolder/Models/ILocation";
import { getLocationSafetyWarrantyByMinMax } from "negotiations/services/client/search/locationSafetyWarranty";

interface ILocationSafetyWarrantyProps {
  value?: ILocationView;
  type: string;
  disabled: boolean;
  onSafeBerthsChanged?: (selected: ILocationSafeWarranty | undefined) => void;
  onSafeAnchoragesChanged?: (selected: ILocationSafeWarranty | undefined) => void;
  onSafePortsChanged?: (selected: ILocationSafeWarranty | undefined) => void;
}

export class LocationSafetyWarranty extends React.Component<ILocationSafetyWarrantyProps> {
  constructor(props: ILocationSafetyWarrantyProps) {
    super(props);
    this.safeBerthsChangedHandler = this.safeBerthsChangedHandler.bind(this);
    this.safePortsChangedHandler = this.safePortsChangedHandler.bind(this);
    this.safeAnchoragesChangedHandler = this.safeAnchoragesChangedHandler.bind(this);
  }

  render() {
    const options = getLocationSafetyWarranties().map((o) => ({
      key: o.key,
      text: o.key,
    }));

    options.unshift({ key: "", text: "-" });

    const sb = this.getSafeBerths();
    const sp = this.getSafePorts();
    const sa = this.getSafeAnchorages();

    return (
      <div className={styles.container}>
        <div className={styles.part}>
          <div className={styles.label}>Safe Berth(s)</div>
          {
            <CustomDropdown
              id={`Dropdown-${this.props.type}-SB`}
              dataTest={`Dropdown-${this.props.type}-SB`}
              ariaLabel={"Safe Berth"}
              disabled={this.props.disabled}
              options={options}
              defaultSelectedKey={sb}
              onChange={this.safeBerthsChangedHandler}
              width="small"
            />
          }
        </div>
        <div className={styles.part}>
          <div className={styles.label}>Safe Port(s)</div>
          {
            <CustomDropdown
              id={`Dropdown-${this.props.type}-SP`}
              dataTest={`Dropdown-${this.props.type}-SP`}
              ariaLabel={"Safe Port"}
              disabled={this.props.disabled}
              options={options}
              defaultSelectedKey={sp}
              onChange={this.safePortsChangedHandler}
              width="small"
            />
          }
        </div>
        <div className={styles.part}>
          <div className={styles.label}>Safe Anchorage(s)</div>
          {
            <CustomDropdown
              id={`Dropdown-${this.props.type}-SA`}
              dataTest={`Dropdown-${this.props.type}-SA`}
              ariaLabel={"Safe Anchorage"}
              disabled={this.props.disabled}
              options={options}
              defaultSelectedKey={sa}
              onChange={this.safeAnchoragesChangedHandler}
              width="small"
            />
          }
        </div>
      </div>
    );
  }

  // TODO: probably can remove this later, view model is getting the key.
  private getSafeBerths() {
    if (this.props.value && this.props.value.safeBerthsMin && this.props.value.safeBerthsMax) {
      const item = getLocationSafetyWarrantyByMinMax(this.props.value.safeBerthsMin, this.props.value.safeBerthsMax);
      if (item) {
        return item.key;
      }
    }
    return "";
  }

  private getSafePorts() {
    if (this.props.value && this.props.value.safePortsMin && this.props.value.safePortsMax) {
      const item = getLocationSafetyWarrantyByMinMax(this.props.value.safePortsMin, this.props.value.safePortsMax);
      if (item) {
        return item.key;
      }
    }
    return "";
  }

  private getSafeAnchorages() {
    if (this.props.value && this.props.value.safeAnchoragesMin && this.props.value.safeAnchoragesMax) {
      const item = getLocationSafetyWarrantyByMinMax(this.props.value.safeAnchoragesMin, this.props.value.safeAnchoragesMax);
      if (item) {
        return item.key;
      }
    }
    return "";
  }

  private safeBerthsChangedHandler(selectedKey: React.ReactText) {
    if (this.props.onSafeBerthsChanged) {
      if (selectedKey === "") {
        this.props.onSafeBerthsChanged(undefined);
      } else {
        const selectedItem = getLocationSafetyWarrantyByKey(selectedKey.toString());
        if (selectedItem) {
          this.props.onSafeBerthsChanged(selectedItem);
        }
      }
    }
  }

  private safeAnchoragesChangedHandler(selectedKey: React.ReactText) {
    if (this.props.onSafeAnchoragesChanged) {
      if (selectedKey === "") {
        this.props.onSafeAnchoragesChanged(undefined);
      } else {
        const selectedItem = getLocationSafetyWarrantyByKey(selectedKey.toString());
        if (selectedItem) {
          this.props.onSafeAnchoragesChanged(selectedItem);
        }
      }
    }
  }

  private safePortsChangedHandler(selectedKey: React.ReactText) {
    if (this.props.onSafePortsChanged) {
      if (selectedKey === "") {
        this.props.onSafePortsChanged(undefined);
      } else {
        const selectedItem = getLocationSafetyWarrantyByKey(selectedKey.toString());
        if (selectedItem) {
          this.props.onSafePortsChanged(selectedItem);
        }
      }
    }
  }
}
