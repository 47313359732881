import { ISearchUserView, DistributionUserRole } from "../../../sharedFolder/Models/IDetails";

export interface DistributionVanillaEmailState {
  vanillaEmailsToSearchOn: string[];
  searchResults: ISearchUserView[];
  hasVanillaEmailsToSearchOn: Boolean;
}

export const initialVanillaEmailSearchState: DistributionVanillaEmailState = {
  vanillaEmailsToSearchOn: [],
  searchResults: [],
  hasVanillaEmailsToSearchOn: false,
};

type ResolveUserAction = {
  type: "resolveUsersReturnedBySearch";
  payload: ISearchUserView[];
};

type AddUsersAsEmail = { type: "addsUserAsEmail"; payload: string[] };

type SetUserRole = {
  type: "setSavedUserRole";
  payload: { email: string; role: DistributionUserRole };
};

type DistributionVanillaEmailActions = ResolveUserAction | SetUserRole | AddUsersAsEmail;

export const distributionVanillaEmailSearchReducer = (
  state: DistributionVanillaEmailState,
  action: DistributionVanillaEmailActions
): DistributionVanillaEmailState => {
  switch (action.type) {
    case "addsUserAsEmail":
      // Save the email addresses to search on to the state AND set the notification that we have some email to search on
      return {
        ...state,
        vanillaEmailsToSearchOn: action.payload,
        hasVanillaEmailsToSearchOn: Boolean(action.payload.length),
      };
    case "resolveUsersReturnedBySearch":
      return {
        ...state,
        hasVanillaEmailsToSearchOn: false,
        vanillaEmailsToSearchOn: [],
        searchResults: action.payload,
      };
    default:
      return state;
  }
};
