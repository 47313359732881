import { makeAutoObservable } from "mobx";
import { keyBy } from "lodash-es";
import { tradeAPI } from "___REFACTOR___/apis";
import { createTimezone, Timezone } from "___REFACTOR___/models";

class Timezones {
  constructor() {
    makeAutoObservable(this);
  }

  data: Timezone[] = [];
  dataById: DataById = {};

  promise = new Promise((resolve) => {
    this.resolve = resolve;
  });

  getData = async () => {
    const res = await tradeAPI.getTimezones();

    if (res.data) this.setData(res.data.map(createTimezone));
  };

  setData = (data: Timezone[]) => {
    this.data = data;
    this.dataById = keyBy(data, "id");

    this.resolve();
  };
}

export const timezones = new Timezones();

interface Timezones {
  resolve: (value?) => void;
}

interface DataById {
  [name: string]: Timezone;
}
