import React, { useRef } from "react";
import { formatNumber, maximumFractionDigits } from "___REFACTOR___/utils";
import { DataModel } from "___REFACTOR___/models";
import { TextInput } from "../Text";

function NumberInput(props: NumberInput.Props) {
  const { min = 0, max = Infinity } = props;
  let value: number | undefined;

  if (props.value instanceof DataModel) value = Number(props.value.toView());
  if (typeof props.value === "number") value = props.value;

  const initialShadowStr = typeof value === "number" ? toString(value) : "";
  const shadowStr = useRef(initialShadowStr);

  let display = "";

  if (typeof value === "number") {
    const valStr = toString(value);
    const valStrSplit = valStr.split(".");
    const valStrInteger = valStrSplit[0];
    const valStrContainsDecimal = valStr.includes(".");
    const shadowStrContainsDecimal = shadowStr.current.includes(".");
    const shadowStrSplit = shadowStr.current.split(".");
    const shadowStrInteger = shadowStrSplit[0];
    const shadowStrContainsDecimals = shadowStrSplit[1];
    const integersAreOutOfSync = valStrInteger !== shadowStrInteger;

    display = valStr;

    if (shadowStrContainsDecimal && !valStrContainsDecimal && !integersAreOutOfSync) {
      display = `${valStr}.${(shadowStrContainsDecimals || "").slice(0, maximumFractionDigits)}`;
    }
  }

  if (shadowStr.current === "" && !value && value !== 0) {
    display = "";
  }

  return (
    <TextInput
      {...props}
      desc={`${props.desc || ""}

Increment/decrement with arrow up/down keys: Ctrl ±100, Shift ±10, Alt ±0.1`}
      value={display}
      onChange={onInputChange}
      onKeyDown={onKeyDown}
      placeholder={props.placeholder}
    />
  );

  function onChange(numericalValue: number, inputValue?: string) {
    let nextValue = numericalValue as NumberInput.Value;

    // @ts-ignore
    if (props.value instanceof DataModel) nextValue = new props.value.constructor(numericalValue);

    if (inputValue === "") {
      nextValue = undefined;
    }

    // @ts-ignore
    props.onChange?.(nextValue);
  }

  function onInputChange(inputValue: TextInput.Value) {
    if (typeof inputValue !== "string") return;

    shadowStr.current = inputValue;

    const next = minMax(toNumber(inputValue));

    onChange(next, inputValue);
  }

  function onKeyDown(e) {
    const isNotAllowed = !KEY_MAP[e.key];
    const preventSecondDot = display.includes(".") && e.key === ".";
    const isCtrlHotkey = e.ctrlKey && CTRL_KEY_MAP[e.key];

    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      e.preventDefault();

      let increment = 1;

      if (e.altKey) increment = 0.1;
      if (e.shiftKey) increment = 10;
      if (e.ctrlKey) increment = 100;
      if (e.key === "ArrowDown") increment = -increment;

      const next = minMax(toNumber(display) + increment);

      onChange(next);

      return;
    }

    if (isCtrlHotkey) return;

    if (isNotAllowed || preventSecondDot) {
      e.preventDefault();

      return;
    }
  }

  function minMax(value: number) {
    value = Math.max(value, min);
    value = Math.min(value, max);

    return value;
  }
}

function toNumber(string: string) {
  const replaced = string.replace(/,/g, "");
  const number = Number(replaced);
  const formatted = formatNumber(number);
  const formattedReplaced = formatted.replace(/,/g, "");
  const res = Number(formattedReplaced);

  return res;
}

function toString(number: number) {
  const res = formatNumber(number);

  return res;
}

const KEY_MAP: KeyMap = {
  "0": true,
  "1": true,
  "2": true,
  "3": true,
  "4": true,
  "5": true,
  "6": true,
  "7": true,
  "8": true,
  "9": true,
  ".": true,
  Enter: true,
  Esc: true,
  Backspace: true,
  Delete: true,
  Home: true,
  End: true,
  Tab: true,
  Control: true,
  ArrowUp: true,
  ArrowDown: true,
  ArrowLeft: true,
  ArrowRight: true,
};

const CTRL_KEY_MAP: KeyMap = {
  a: true,
  c: true,
  x: true,
  z: true,
  v: true,
  ArrowUp: true,
  ArrowDown: true,
};

export { NumberInput };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

// eslint-disable-next-line no-redeclare
declare namespace NumberInput {
  export interface Props extends Omit<TextInput.Props, "value" | "onChange"> {
    value: Value;
    onChange: (value: Value) => void;
    min?: number;
    max?: number;
  }

  export type Value = DataModel | number | undefined;
}

type KeyMap = {
  [key: string]: boolean;
};
