import React from "react";
import styles from "./VesselDetailEditor.module.scss";
import Button from "__legacy/sharedFolder/components/common/Button/Button";

interface IVesselDetailEditorProps {
  disabled?: boolean;
  editMode: boolean;
  editor: (onChange: (val: string) => void, value: string) => React.ReactNode;
  label: string | JSX.Element;
  name: string;
  onChange: (val: string) => void;
  toggleEditor: (open: boolean) => void;
  onCancel: () => void;
  onToggleEditor: () => void;
  onSave: () => void;
  value: string;
  isValid?: boolean;
}

const VesselDetailEditor = (props: IVesselDetailEditorProps) => {
  const { disabled, editor, editMode, label, name, onCancel, onChange, onSave, toggleEditor, onToggleEditor, value, isValid } =
    props;

  const editorClicked = () => {
    onToggleEditor();
    toggleEditor(true);
  };

  return (
    <div
      className={styles.container}
      onKeyDown={(e) => {
        // if in edit mode and escape key is pressed, user wants to get out of the editor
        if (editMode && e.keyCode === 27) {
          onCancel();
        }
      }}
    >
      <div className={styles.row}>
        <div className={styles.detailName}>{label}</div>
        {editMode && <div className={styles.details}>{editor(onChange, value)}</div>}
        {!editMode && (
          <>
            <div className={styles.detail}>{value ? value : <span className={styles.emptyDetail}>No details supplied</span>}</div>
            <Button
              ariaLabel="edit-group"
              className={styles.edit}
              type="plain"
              icon="mode-edit"
              disabled={disabled}
              title={`Edit ${name}`}
              onClick={editorClicked}
            />
          </>
        )}
      </div>
      {editMode && (
        // Actions are outside .row's flex layout so that .detailName can be centered aligned nicely
        <div className={styles.actions}>
          <Button ariaLabel="cancel" type="flat" title="Cancel" onClick={onCancel}>
            Cancel
          </Button>
          <Button ariaLabel="save" type="action" title="Save" onClick={onSave} disabled={!isValid}>
            Save
          </Button>
        </div>
      )}
    </div>
  );
};

export default VesselDetailEditor;
