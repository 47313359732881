import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { Icon, StatusMessages } from "___REFACTOR___/components";
import { CommaSeparatedValues } from "___REFACTOR___/models/common";
import { Container } from "../../Container";
import { Input } from "../../Input";
import "./CommaSeparatedValues.scss";

function CommaSeparatedValuesInput(props: CommaSeparatedValuesInput.Props) {
  const { value, onChange, dataTest, status, placeholder, submitOnSpace, submitOnEnter } = props;
  const itemArr = value?.itemArr || [];
  const [head, setHead] = useState("");
  const headRef = useRef() as ReactHTMLInputDomRef;
  const headStyle = { width: `${head.length}ch` };
  const latestScopeRef = useRef({ value, head });
  latestScopeRef.current = { value, head };

  useEffect(onHeadChange, [head]);
  useEffect(setupDomKeyDownEvent, []);

  return (
    <Container {...props} type="CommaSeparatedValues">
      <comma-separated-values class="app-text-input">
        <comma-separated-values-backdrop onClick={onBackdropClick} />
        {itemArr.map(ItemHOC)}
        <input
          value={head}
          onChange={onHeadInputChange}
          onBlur={onHeadInputBlur}
          onPaste={onHeadPaste}
          className="comma-separated-values-head"
          style={headStyle}
          ref={headRef}
          placeholder={placeholder}
          data-test={`${dataTest}-head`}
        />
      </comma-separated-values>
    </Container>
  );

  function onHeadChange() {
    const { itemArr, itemMap, conversion } = getValueWithHead();

    onChange(new CommaSeparatedValues({ itemArr, itemMap }));

    if (conversion.itemArr.length) setHead("");
  }

  function submitAnyHead() {
    const { itemArr, itemMap, conversion } = getValueWithHead({ minSeparatorCount: 0 });

    onChange(new CommaSeparatedValues({ itemArr, itemMap }));

    if (conversion.itemArr.length) setHead("");
  }

  function getValueWithHead(config?: CommaSeparatedValues.Convert.String.To.Item.Config) {
    const { value, head } = latestScopeRef.current;
    config = { minSeparatorCount: 1, itemMap: value?.itemMap, ...config };
    const itemArr = [] as CommaSeparatedValues.Item[];
    const itemMap = {} as CommaSeparatedValues.Item.Map;
    const conversion = CommaSeparatedValues.convert.string.to.item(head, config);

    if (value) {
      itemArr.push(...value.itemArr);
      Object.assign(itemMap, value.itemMap);
    }

    itemArr.push(...conversion.itemArr);
    Object.assign(itemMap, conversion.itemMap);

    return { itemArr, itemMap, conversion };
  }

  function ItemHOC(item: CommaSeparatedValues.Item, i: number) {
    const itemStatusChildMap = status?.childMap?.itemArr?.childMap?.[i]?.childMap as any;
    const valueStatus = itemStatusChildMap?.value;
    const className = classNames(valueStatus?.type);

    return (
      <comma-separated-value class={className} key={item.id}>
        <comma-separated-value-pill>
          <comma-separated-value-input>
            <input value={item.value} onChange={onItemChange} data-test={`${dataTest}-value-${item.value}`} />
            <comma-separated-value-input-width-provider>{item.value}</comma-separated-value-input-width-provider>
          </comma-separated-value-input>
          <Icon onClick={onItemRemove} icon="cancel" />
        </comma-separated-value-pill>
        <StatusMessages status={valueStatus} />
      </comma-separated-value>
    );

    function onItemChange(e) {
      if (!value) return;

      item.value = e.target.value;

      onChange(new CommaSeparatedValues({ ...value }));
    }

    function onItemRemove() {
      if (!value) return;

      value.remove(item);

      onChange(new CommaSeparatedValues({ ...value }));
    }
  }

  function onBackdropClick() {
    headRef.current!.focus();
  }

  function setupDomKeyDownEvent() {
    headRef.current!.onkeydown = onHeadInputKeyDown;

    function onHeadInputKeyDown(e) {
      if (e.key === "Enter" && submitOnEnter) setTimeout(submitAnyHead, 1);
      if (e.key === " " && submitOnSpace) setTimeout(submitAnyHead, 1);
    }
  }

  function onHeadInputBlur() {
    submitAnyHead();
  }

  function onHeadInputChange(e) {
    setHead(e.target.value);
  }

  function onHeadPaste() {
    setTimeout(submitAnyHead, 1);
  }
}

CommaSeparatedValuesInput.Value = CommaSeparatedValues;

export { CommaSeparatedValuesInput };

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

declare namespace CommaSeparatedValuesInput {
  interface Props extends Input.Props {
    value: Value;
    onChange(value: Value): void;
    submitOnSpace?: boolean;
    submitOnEnter?: boolean;
  }

  type Value = CommaSeparatedValues | undefined;
}
