import { cloneDeep } from "lodash-es";
import * as Details from "../Models/IDetails";
import { IVesselSummary } from "../Models/IVesselSummary";
import { vesselColor } from "./vesselColor";

/**
 * With collection of vessels will return string of 'First Vessel (+2)'
 * where number is the number of other vessels
 * @param vessels  Vessels to include in shortened text
 */
const shortenVessels = (vessels: Details.IVesselView[]) => {
  const firstVessel = vessels.length ? vessels[0] : undefined;
  const otherVessels = vessels.slice(1);
  const otherVesselsCountText = otherVessels.length > 0 ? ` (+${otherVessels.length})` : "";
  if (firstVessel && vessels.length) {
    return `${firstVessel.vesselName}${otherVesselsCountText}`;
  }
  if (!firstVessel) return "(TBN)";
  return firstVessel.vesselName;
};

function anyVesselsAccepted(vessels: Details.IVesselView[]) {
  const clonedVessels = cloneDeep(vessels);
  return clonedVessels.some((vessel) => vessel.status === "accepted");
}

function vesselsSummaryColor(vessels: Details.IVesselView[]) {
  let color;
  if (!vessels.length) {
    color = "grey";
  } else if (anyVesselsAccepted(vessels)) {
    color = "green";
  } else if (vessels.filter((vessel) => vessel.status !== "rejected").length) {
    color = "yellow";
  }

  return color;
}

export function getVesselSummary(vessels: Details.IVesselView[]): IVesselSummary {
  const color = vesselsSummaryColor(vessels);
  const className = vesselColor(color);

  const acceptedVessels = vessels.filter((x) => x.status === "accepted");

  // If any vessel has been accepted
  if (acceptedVessels.length > 0) {
    return {
      className,
      long: acceptedVessels.map((vessel) => vessel.vesselName).join(", "),
      short: shortenVessels(acceptedVessels),
      color,
    };
  }

  // Otherwise display non-rejected vessels
  const nonRejectedVessels = vessels.filter((vessel) => vessel.status !== "rejected");

  return {
    className,
    long: nonRejectedVessels.map((vessel) => vessel.vesselName).join(", "),
    short: shortenVessels(nonRejectedVessels),
    color,
  };
}
