import { useEffect, useState } from "react";
import { IEmailListPUT, IEmailList } from "../../services/settings";
import { useAPIFetch } from "./useAPIFetch";
import { IGenericErrorObject, renderDistributionGroupError } from "./DistributionListPanel";
import { InlineSingleTextEditor } from "./InlineSingleTextEditor";
import { getGroupNameErrors } from "./groupNameValidator";

interface IDistributionGroupNameEditor {
  currentList: IEmailList[];
  item: IEmailList;
  onEditorClose: () => void;
  updateList: (updatedItem: IEmailList) => void;
}

export function DistributionGroupNameEditor(props: IDistributionGroupNameEditor) {
  const { currentList, item, onEditorClose, updateList } = props;
  const [groupName, setGroupName] = useState("");
  const [updatedListBody, setUpdatedListBody] = useState<null | IEmailListPUT>(null);

  const updateAction = useAPIFetch<IEmailListPUT>(`/settings/emaillists/${item.id}`, "PUT", updatedListBody);

  useEffect(() => {
    // on item changes from the outside, sync the local group name with the passed in prop so it's up to date when the editor is open
    setGroupName(item.name);
  }, [item]);

  useEffect(() => {
    function softUpdateGroupName() {
      updateList({
        ...item,
        name: groupName,
      });
    }
    // on successful update
    if (updateAction && updateAction.response) {
      // update our local distribution state
      softUpdateGroupName();
      onEditorClose();
    }
  }, [item, groupName, onEditorClose, updateAction, updateList]);

  const [validationErrors, setValidationErrors] = useState<null | IGenericErrorObject>(null);

  function submitEditForm() {
    const errors = getGroupNameErrors(currentList, groupName);
    if (Object.keys(errors).some((key) => errors[key])) {
      setValidationErrors(errors);
    } else {
      setValidationErrors(null);
      setUpdatedListBody({
        data: {
          name: groupName,
          values: item.values,
        },
        updateToken: item.updateToken,
      });
    }
  }
  return (
    <InlineSingleTextEditor
      disabled={updateAction.fetching || !groupName || groupName === item.name}
      errorMessage={
        validationErrors || updateAction.error
          ? renderDistributionGroupError({
              apiError: updateAction.error,
              validationErrors,
            })
          : null
      }
      onClose={() => {
        onEditorClose();
        // reset the local group name state
        setGroupName(item.name);
      }}
      onSubmit={() => {
        submitEditForm();
      }}
      placeholder="Edit Group name"
      setValue={(value: string) => setGroupName(value)}
      value={groupName}
    />
  );
}
