import { IOrder } from "orders/models/IOrder";
import { useEffect, useState } from "react";
import { DistributionState } from "./reducers/distributionReducer";

export function useDisableNext(state: DistributionState, currentLoggedInUserEmail: string, order?: IOrder) {
  const [usersAreInLoadingState, setUsersAreInLoadingState] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    /**
     * usersAreInLoadingState --
     * If any users are waiting for their default role OR
     * are 'loading' meaning they are a vanilla email address
     * that is undergoing a search, to see if they are a registered
     * existing user
     */
    const usersAreInLoadingState =
      state.savedUsersState.savedUsers.recipients.some((recipient) => recipient.loading) ||
      Boolean(state.vanillaEmailSearchState.vanillaEmailsToSearchOn.length);
    setUsersAreInLoadingState(usersAreInLoadingState);
  }, [order, currentLoggedInUserEmail, state]);

  const isDisabled =
    state.savedUsersState.totalNumberOfUsersSelected === 0 || // Should be disabled if there are no users selected
    usersAreInLoadingState || // Should be disabled if users are currently loading, the page therefore is expecting to be updated OR
    isSubmitting; // Should be disabled if the user has clicked the submit button and the request is being made

  return {
    isDisabled,
    setIsSubmitting,
  };
}
