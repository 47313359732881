import { Negotiation, dialog } from "@/models";
import { usageMetrics, WithdrawEvents } from "@/services/UsageMetrics";
import { Context } from "../Orders";

export async function withdrawNegotiation(model: Negotiation | undefined, context: Context) {
  const startMeasureTime = usageMetrics.startTrackingAction(WithdrawEvents.NEG_WITHDRAWAL_START, model?.id);
  if (!model) {
    console.error("withdrawNegotiation:", { model, context });

    const status = {} as Status;

    status.type = "error";
    status.title = "Negotiation Withdrawal Failure";

    dialog.show({
      status,
      dataTest: `withdraw-negotiation-fail`,
    });

    usageMetrics.finishTrackingAction(WithdrawEvents.NEG_WITHDRAWAL_FAILED, startMeasureTime, undefined);
    return;
  }

  const { res: confirmed } = await dialog.show({
    status: {
      type: "warning",
      title: "Negotiation Withdrawal",
      message: "Are you sure you want to withdraw the negotiation? This cannot be undone.",
    },
    dataTest: "withdraw-negotiation-confirm",
  });

  if (!confirmed) return;

  model._.status.loading = true;
  model._.status.message = `Withdrawing Negotiation`;

  const res = await model.deleteNegotiation();

  if (!res?.ok) {
    model._.status.type = "error";
    model._.status.title = "Negotiation Withdrawal Failure";
    model._.status.dump = { res, entity: model };

    dialog.show({
      status: model._.status,
      dataTest: `withdraw-negotiation-fail`,
    });

    model._.status.loading = false;
    model._.status.message = null;

    usageMetrics.finishTrackingAction(WithdrawEvents.NEG_WITHDRAWAL_FAILED, startMeasureTime, model?.id);
    return res;
  }

  model._.status.loading = false;
  model._.status.message = null;

  // dialog.show({
  //   status: {
  //     type: "success",
  //     title: "Successful Negotiation Withdrawal",
  //     message: "You have successfuly withdrawn the negotiation",
  //   },
  //   dataTest: `withdraw-negotiation-success`,
  // });

  usageMetrics.finishTrackingAction(WithdrawEvents.NEG_WITHDRAWAL_FINISH, startMeasureTime, model?.id);
  return res;
}
