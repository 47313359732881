import { DurationUnit } from "../components/editors/DurationEditor/DurationEditor";
import { LiftingDisperalType } from "../../api/models/ILiftings";
import { IKnownUser, IOwnerNegotiationDetails } from "api/orders/models/IDistributionList";
import dayjs from "dayjs";

export interface IAccountView {
  accountId: string;
  accountName: string;
  arcContactId?: string;
  gainAccountGroupId?: string;
  gainAccountId?: string;
  isLegalEntity?: boolean;
  display?: string;
}

export interface ICommissionView {
  value: string;
  display?: string;
}

export interface IUnitValueView {
  unit: string;
  unitDisplay: string;
  // having this as a string in the view to mitigate formatting issues ot not being able to set 10.00 and also not being able accept incorrect strings in the fields without a lot of mapping functions
  value: string | null;
  display?: string;
  notes?: string;
}

export interface ICargoSizeView {
  display?: string;
  shortDisplay?: string;
  summarydisplay?: string;
  unit: string;
  value: string | null; // TODO: change to number
  variance: string | null; // TODO: change to number
  option: string | null; // TODO: change this to enum
  notes?: string;
}

export interface ICOACargoSizeView {
  display?: string;
  min?: string;
  max?: string;
  variance?: string;
  option?: string;
  notes?: string;
  unit?: string;
}

export interface ICargoTypeView {
  arcId: string | null;
  name: string;
  notes: string;
  display?: string;
  shortDisplay?: string;
}

export const isCargoTypeViewValid = (cargoTypeView?: ICargoTypeView, isRequired?: boolean): boolean => {
  return isRequired ? Boolean(cargoTypeView?.arcId && cargoTypeView?.name) : true;
};

export interface ILaycanView {
  shortDisplay: string;
  display: string;
  from: string | null;
  to: string | null;
  notes?: string;
}

export const isLaycanValid = (value?: ILaycanView) => {
  if (!value || value.display === "") {
    return false;
  }

  return Boolean(value.display && value.from);
};

export const laycanAmendDisplay = (value: ILaycanView) => {
  const format = "DD MMM YY";
  value.display =
    value.from && value.to
      ? `${dayjs.utc(value.from as string).format(format)} - ${dayjs.utc(value.to as string).format(format)}`
      : value?.display;

  return value;
};

export interface ILiftingsView {
  min?: number | null;
  max?: number | null;
  dispersal?: LiftingDisperalType;
  display?: string;
  notes?: string;
}

export const liftingsDisplay = (value: ILiftingsView) => {
  const min = value.min !== undefined && value.min !== null ? value.min : "";
  const max = value.max !== undefined && value.max !== null ? value.max : "";
  return min && max ? `${min}-${max} ${value.dispersal} ${value.notes || ""}` : "";
};

export const isLiftingsValid = (liftings?: ILiftingsView) => {
  if (liftings && liftings.max && (liftings.min === null || liftings.min === undefined)) {
    return false; // if max is set but not min, invalid
  }
  if (liftings && liftings.min !== undefined && liftings.min !== null && liftings.max !== undefined && liftings.max !== null) {
    return liftings.min < liftings.max;
  }
  return true;
};

export interface INominationsView {
  display?: string;
  noticePerLaycan?: number | null;
  laycanSpread?: number | null;
  finalLaycanNotice?: number | null;
  notes?: string;
}

export const nominationsDisplay = (nominations: INominationsView) => {
  const noticePerLaycan =
    nominations.noticePerLaycan !== undefined && nominations.noticePerLaycan !== null ? nominations.noticePerLaycan : "";
  const laycanSpread =
    nominations.laycanSpread !== undefined && nominations.laycanSpread !== null ? nominations.laycanSpread : "";
  const finalLaycanNotice =
    nominations.finalLaycanNotice !== undefined && nominations.finalLaycanNotice !== null ? nominations.finalLaycanNotice : "";

  return noticePerLaycan === "" && laycanSpread === ""
    ? ""
    : `${noticePerLaycan} / ${laycanSpread} / ${finalLaycanNotice} ${nominations.notes || ""}`;
};

export interface IPeriodView extends ILaycanView {}

export interface INotesView {
  value: string;
  display?: string;
}

export interface INegotiationNotesView {
  value: string | null;
  timestamp: string | null;
  display?: string;
}

export type VesselNominiationStatus = "named" | "accepted" | "rejected";
export interface IVesselView {
  dwt: number;
  buildYear: number;
  vesselName: string;
  vesselIMO: string;
  arcVesselId: number;
  consumptions?: string;
  status?: VesselNominiationStatus;
  rejectionReason?: string;
  ownerChain?: string;
  speedAndConsumption?: string;
  vesselDescription?: string;
  additionalNotes?: string;
  eta?: string;
  itinerary?: string;
  deletable?: boolean;
}

export interface IVesselSizeView {
  vesselSizeFull: string;
  vesselSizeAbbreviation: string;
  sizeFrom: string;
  sizeTo: string;
  notes: string;
  display?: string;
  status?: string;
}

export interface IDurationView {
  min: number | null;
  max: number | null;
  unit: DurationUnit | undefined;
  notes?: string;
  display?: string;
}

export type DistributionUserRole = "unknown" | "broker" | "charterer" | "owner";
export const hasOnlyUnknownRoleOrEmpty = (roles: DistributionUserRole[]) =>
  roles.length === 0 || (roles.length === 1 && roles.includes("unknown"));

export type DistributionUserStatus = "fail" | "pending" | "success";

export interface IDistributionViewModel {
  userId?: string;
  name: string;
  email: string;
  companyName?: string;
  companyId?: string;
  locationName?: string;
  locationId?: string;
  deskName?: string;
  deskId?: string;
  divisionName?: string;
  divisionId?: string;
  loading: boolean;
  role: DistributionUserRole;
  availableRoles: DistributionUserRole[];
  systemUserId?: number;
  status: DistributionUserStatus;
  sentOn?: string;
  negDetails: IOwnerNegotiationDetails | null;
  actor?: IKnownUser;
  disableUserRow: boolean; // Defines whether the row in the UI should be 'disabled'
  groupEmailAddress: string | null;
}

export interface ICompany {
  companyId: string;
  companyName: string;
  desks: IDeskView[];
  hasPendingRecipients: boolean;
}

export interface IDeskView {
  deskName: string;
  deskId: string;
  companyName: string;
  companyId: string;
  isDeskOpen?: boolean;
  recipients: IDistributionViewModel[];
  isDeskDisabled?: boolean;
}

export interface IDistributionListViewModel {
  companies: ICompany[];
  recipients: IDistributionViewModel[];
}

export interface ISearchUserView {
  userId: string;
  systemUserId: string;
  email: string;
  userName: string;
  companyName: string;
  companyId: string;
  companyRoles: string[];
  locationName: string;
  locationId: string;
  deskName: string;
  deskId: string;
  divisionName: string;
  divisionId: string;
  groupEmailAddress: string | null;
}

export interface ISubsTextView {
  title: string;
  value: string;
  display?: string;
}
