export const REGIONS = [
  {
    label: "TBN",
    value: "TBN",
  },
  {
    label: "Arctic",
    value: "ART",
  },
  {
    label: "Australasia",
    value: "AUSNZ",
  },
  {
    label: "East Asia",
    value: "EASIA",
  },
  {
    label: "East Coast Africa",
    value: "EAFR",
  },
  {
    label: "East Coast North America",
    value: "ECNA",
  },
  {
    label: "East Coast South America",
    value: "ECSA",
  },
  {
    label: "Indian Subcontinent",
    value: "INDIA",
  },
  {
    label: " Mediterranean / Black Sea",
    value: "MEDBS",
  },
  {
    label: "Middle East",
    value: "MEG",
  },
  {
    label: "North Asia",
    value: "NASIA",
  },
  {
    label: "South East Asia",
    value: "SEASIA",
  },
  {
    label: "Southern Ocean",
    value: "SOCEAN",
  },
  {
    label: "United Kingdom / Continent",
    value: "UKCONT",
  },
  {
    label: "West Coast Africa",
    value: "WAFR",
  },
  {
    label: "West Coast North America",
    value: "WCNA",
  },
  {
    label: "West Coast South America",
    value: "WCSA",
  },
];
