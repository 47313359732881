import { IRecipient } from "api/orders/models/IDistributionList";
import { differenceBy, find } from "lodash-es";
import { mapRecipientList } from "sharedFolder/mappers/mapRecipientList";
import { DistributionState } from "../reducers/distributionReducer";
import { getRecipientEmail } from "../reducers/distributionReducerUtilities";

const getUsersWithUpdatedRoles = (existingUsers: IRecipient[], savedUsers: IRecipient[]): IRecipient[] => {
  const isSameRole = (existingUsers: IRecipient[]) => (email: string | null, role: string) => {
    const foundUser = find(existingUsers, (recipient) => recipient.email === email);
    return foundUser?.role === role;
  };
  const doesEmailAndRoleExist = isSameRole(existingUsers);
  return savedUsers.filter((savedUser) => !doesEmailAndRoleExist(savedUser.email, savedUser.role));
};

export const validRoles = ["broker", "charterer", "owner"];
export const hasUsersWithNoRoleSelected = (state: DistributionState): boolean => {
  return mapRecipientList.toApi(state.savedUsersState.savedUsers).every((recipient) => !validRoles.includes(recipient.role));
};
/**
 * Returns true if there are users in 'savedUsers' that need to be POSTed.
 * If none, returns false
 * @param state DistributionState
 */
export const haveUsersOrRolesChanges = (state: DistributionState): boolean => {
  const existing = mapRecipientList.toApi(state.existingUsers);
  const saved = mapRecipientList.toApi(state.savedUsersState.savedUsers);
  const existingUsersMinusCurrrentActor = existing.filter(
    (recipient) =>
      getRecipientEmail(recipient) !== (state.currentlyLoggedInUser && getRecipientEmail(state.currentlyLoggedInUser))
  );
  const haveUsersBeenAddedOrRemoved = differenceBy(existingUsersMinusCurrrentActor, saved, "email");
  const haveRolesBeenUpdatedForAnyUsers = getUsersWithUpdatedRoles(existing, saved);
  return Boolean(haveUsersBeenAddedOrRemoved.length) || Boolean(haveRolesBeenUpdatedForAnyUsers.length);
};
