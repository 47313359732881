import { TradeAPI } from "___REFACTOR___/apis";
import { HandlebarParser, HandlebarTextChunk, termsetHandlebars } from "___REFACTOR___/services";
import "./TermDetails.scss";

interface TermDetailsProps {
  term: TradeAPI.Termset.Content.Term;
}

const handlebarParser = new HandlebarParser();

export function TermDetails(props: TermDetailsProps) {
  const { term } = props;

  const pills = term.pills?.map((text) => HandlebarTextChunk.pill(text)) ?? [];
  const contentWithReplacedHandlebars = handlebarParser.replaceHandlebarsIfNeeded(term);
  const result: React.ReactNode[] = [...pills, ...contentWithReplacedHandlebars].map(renderTextChunk);

  return <span className="TermDetails">{result}</span>;
}

function renderTextChunk(text: HandlebarTextChunk, i: number) {
  switch (text.type) {
    case "text":
      return renderPlainText(text, i);
    case "pill":
      return renderPill(text, i);
    case "handlebar":
      if (text.value) {
        return renderReplacedHandlebar(text, i);
      } else {
        return renderEmptyHandlebar(text, i);
      }
    case "deferred_handlebar":
      return renderDeferredHandlebar(text, i);
    case "unknown_handlebar":
      return renderPlainText(text, i);
    default:
      return null;
  }
}

function renderReplacedHandlebar(text: HandlebarTextChunk, i: number) {
  return (
    <strong className="TermDetails_handlebar-with-value" key={text.value + i}>
      {text.value}
    </strong>
  );
}

function renderDeferredHandlebar(text: HandlebarTextChunk, i: number) {
  return (
    <strong className="TermDetails_handlebar-deferred-value" key={text.value + i}>
      {getHandlebarReadableName(text.handlebar)}:&nbsp;
      <span className="light-weight">AUTO POPULATED</span>
    </strong>
  );
}

function renderEmptyHandlebar(text: HandlebarTextChunk, i: number) {
  return (
    <span className="TermDetails_handlebar-no-value" key={text.value + i}>
      {getHandlebarReadableName(text.handlebar)}:&nbsp;
      <span className="light-weight">BLANK</span>
    </span>
  );
}

function renderPill(text: HandlebarTextChunk, i: number) {
  return (
    <span className="TermDetails_pill" key={text.value + i}>
      {text.value}
    </span>
  );
}

function renderPlainText(text: HandlebarTextChunk, i: number) {
  return <span key={text.value + i}>{text.value}</span>;
}

function getHandlebarReadableName(handlebarKey: string): string {
  const handlebars = termsetHandlebars.handlebarKeyToName;
  if (!handlebars) {
    return handlebarKey;
  }
  return handlebars.get(handlebarKey) || handlebarKey;
}
