import React, { useContext } from "react";
import { observer } from "mobx-react";
import { Button, Status, SeaContracts } from "@/components";
import { MainTermsTypeEvents, usageMetrics } from "@/services/UsageMetrics";
import { Context } from "../../NegotiationDetail";
import "./Fixed.scss";

function Fixed() {
  const { negotiation } = useContext(Context);
  const { arcUrlWithCentralToken } = negotiation;
  const status = {
    type: "success",
    title: "Negotiation is Fixed",
  } as Status;

  return (
    <div className="Fixed">
      <Status status={status} />
      <Button
        variant="action"
        href={arcUrlWithCentralToken}
        icon="on-subs"
        data-test="open-main-terms"
        openHrefInNewWindow
        onClick={() => {
          usageMetrics.trackEvent(MainTermsTypeEvents.MAIN_TERMS_OPENED);
        }}
      >
        open Main Terms
      </Button>
      {negotiation.seaContractsUrl && <SeaContracts seaContractsUrl={negotiation.seaContractsUrl} />}
    </div>
  );
}

const Observer = observer(Fixed);

export { Observer as Fixed };
