import { isValidElement, ReactElement } from "react";
import { action, computed, makeObservable, observable } from "mobx";
import { Status } from "___REFACTOR___/models";
import { Slideout as Component } from "___REFACTOR___/components/services/Slideout";
import { PromiseBox } from "___REFACTOR___/models/common";

class Slideout {
  constructor() {
    this.reset();

    makeObservable(this, { isOpen: computed, props: observable, open: action, close: action, reset: action });
  }

  private promiseBox?: PromiseBox;

  get isOpen() {
    return !!this.props;
  }

  get promise() {
    return this.promiseBox?.promise;
  }

  get resolve() {
    return this.promiseBox?.resolve;
  }

  open(param: Props | ReactElement | Status) {
    if (param instanceof Status) this.props = { status: param };
    //
    else if (isValidElement(param)) this.props = { children: param };
    //
    else this.props = param;

    const promiseBox = new PromiseBox();

    this.promiseBox = promiseBox;

    return promiseBox.promise;
  }

  close(result?: Result) {
    this.promiseBox?.resolve(result);

    this.reset();
  }

  reset() {
    this.promiseBox = undefined;
    this.props = undefined;
  }
}

const slideout = new Slideout();
const slideout0 = new Slideout();

export { slideout, slideout0, Slideout };

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

interface Slideout {
  props?: Props;
}

declare namespace Slideout {
  export { Props, Result };
}

type Props = Component.Props;

type Result = any;
