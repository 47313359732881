import { termsetHandlebars } from "___REFACTOR___/services";
import { cargoTypes } from "./CargoTypes";
import { timezones } from "./Timezones";
import { companies } from "./Companies";
import { emailLists } from "./EmailLists";
import { userCompany } from "./UserCompany";

async function setupWithoutOrderNegStores() {
  await userCompany.getData();
  cargoTypes.getData();
  emailLists.getData();
  timezones.getData();
  companies.getData();
  termsetHandlebars.getHandlebars();
}

export { setupWithoutOrderNegStores };
