import { format } from "___REFACTOR___/utils";
import { LABEL_MAP } from "___REFACTOR___/constants";
import { TradeAPI } from "___REFACTOR___/apis";
import { CargoSizeEditor } from "___REFACTOR___/components";
import { DataModel, DataModelProps } from "../DataModel";
import { schema } from "./schema";

class CargoSize extends DataModel {
  static label = LABEL_MAP.cargoSize;
  static get Editor() {
    return CargoSizeEditor;
  }

  constructor(data?: PartialData) {
    super(data);
  }

  toJSON() {
    const json = DataModel.prototype.toJSON.call(this) as TradeAPI.CargoSize;

    return DataModel.prototype.toJSON.call(this) as TradeAPI.CargoSize;
  }

  isValid() {
    return schema.isValidSync(this);
  }

  toString() {
    if (!this) return "";

    if (typeof this.variance !== "number" || typeof this.value !== "number" || !this.option) return "";

    const notes = this.notes || "";

    return `${format.number.display(this.value)} / ${this.variance}% / ${this.option} ${notes}`;
  }

  toGridView() {
    if (!this) return "";

    if (typeof this.variance !== "number" || typeof this.value !== "number" || !this.option) return "";

    return `${format.number.display(this.value)} / ${this.variance}% / ${this.option}`;
  }
}

class CargoSizeProps extends DataModelProps {
  get Editor() {
    return CargoSizeEditor;
  }

  get schema() {
    return schema;
  }
}

CargoSize.prototype.Props = CargoSizeProps;
CargoSizeProps.prototype.label = LABEL_MAP.cargoSize;
CargoSizeProps.prototype.sortKey = "value";
CargoSizeProps.prototype.JSONDefaults = {
  option: "MOLOO",
};

export { CargoSize, CargoSizeProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface CargoSize extends Data {
  Props: typeof CargoSizeProps;
  _: CargoSizeProps;
}

interface CargoSizeProps {
  JSONDefaults: PartialData;
  sortKey: keyof Data;
}

type PartialData = Partial<Data>;
type Data = TradeAPI.CargoSize;
