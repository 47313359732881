import * as api from "../../api/negotiations/models";
import { IAccountView } from "../Models/IDetails";
import { withNullable, IMapper } from "./map";

const map: IMapper<api.IAccount, IAccountView> = {
  toApi: (viewModel: IAccountView) => {
    return {
      accountId: viewModel.accountId,
      accountName: viewModel.accountName,
      arcContactId: viewModel.arcContactId,
      gainAccountId: viewModel.gainAccountId,
      gainAccountGroupId: viewModel.gainAccountGroupId,
      isLegalEntity: viewModel.isLegalEntity,
    };
  },
  toView: (apiModel: api.IAccount) => {
    return {
      accountId: apiModel.accountId || "",
      accountName: apiModel.accountName,
      arcContactId: apiModel.arcContactId,
      display: apiModel.display,
      gainAccountGroupId: apiModel.gainAccountGroupId,
      gainAccountId: apiModel.gainAccountId,
      isLegalEntity: apiModel.isLegalEntity,
    };
  },
  emptyViewModel: {
    accountId: "",
    accountName: "",
  },
};
export const mapAccount = withNullable(map);
