import { LABEL_MAP } from "___REFACTOR___/constants";
import { TradeAPI } from "___REFACTOR___/apis";
import { BallastBonusEditor } from "___REFACTOR___/components";
import { UnitValue, UnitValueProps } from "../UnitValue";

class BallastBonus extends UnitValue {
  static label = LABEL_MAP.ballastBonus;
  static get Editor() {
    return BallastBonusEditor;
  }

  constructor(data?: PartialData) {
    super(data);
  }
}
class BallastBonusProps extends UnitValueProps {}

BallastBonus.prototype.Props = BallastBonusProps;
BallastBonusProps.prototype.Editor = BallastBonusEditor;
BallastBonusProps.prototype.sortKey = "value";
BallastBonusProps.prototype.label = LABEL_MAP.ballastBonus;
BallastBonusProps.prototype.JSONDefaults = {
  unit: "LumpSum",
};
BallastBonusProps.prototype.unitArr = ["PerDay", "LumpSum"];

export { BallastBonus, BallastBonusProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface BallastBonus extends Data {
  Props: typeof BallastBonusProps;
  _: BallastBonusProps;
}

interface BallastBonusProps {
  sortKey: keyof Data;
  JSONDefaults: Partial<Data>;
  unitArr: TradeAPI.BallastBonus.Unit[];
}

type PartialData = Partial<Data>;
type Data = TradeAPI.BallastBonus;
