import React from "react";
import { INegotiationView } from "sharedFolder/Models/Negotiation/INegotiationView";
import { getNegotiationHandler } from "../common/Context/NegotiationContext";
import { NegotiationNote } from "sharedFolder/components/common/NegotiationNote/NegotiationNote";
import { Role } from "../negotiate/Negotiation/Role";

export const LiftingsNoteWrapper = (props: {
  ctradeUrl: string;
  negotiation: INegotiationView;
  noteRole: Role;
  setDisplayNote: (display: boolean) => void;
}) => {
  if (!props.negotiation?.details) return null;
  const negotiationHandler = getNegotiationHandler(props.ctradeUrl, props.negotiation.type, props.negotiation);
  return (
    <NegotiationNote
      role={props.noteRole}
      controllerNegotiationNotes={props.negotiation.details?.controllerNegotiationNotes}
      offerRepNegotiationNotes={props.negotiation.details?.offerRepNegotiationNotes}
      onDismiss={() => props.setDisplayNote(false)}
      updateNote={negotiationHandler.updateNote}
    />
  );
};
