import React from "react";
import { Vessels, Vessel } from "___REFACTOR___/models";
import { Button } from "___REFACTOR___/components";
import { Group } from "../../Group";
import { VesselEditor } from "../Vessel";
import "./Vessels.scss";

function VesselsEditor(props: VesselsEditor.Props) {
  const { className, onChange, value, hidden, ...rest } = props;

  return (
    <Group {...props} editorType="Vessels" hidden={hidden}>
      {value?.vessels?.map(VesselEditorHOC)}
      <Button icon="plus" onClick={addVessel} dataTest="nominate-vessel">
        Nominate a Vessel
      </Button>
    </Group>
  );

  function onVesselChange(i: number, vessel?: Vessel | null) {
    if (!vessel || !value?.vessels) return;

    const vessels = [...value.vessels];

    vessels[i] = vessel;

    const next = new Vessels();

    next.vessels = vessels;

    props.onChange?.(next);
  }

  function addVessel() {
    const vessels = value?.vessels ? [...value.vessels] : [];

    vessels.push(new Vessel());

    const next = new Vessels();

    next.vessels = vessels;

    props.onChange?.(next);
  }

  function removeVessel(i: number) {
    const vessels = value?.vessels ? [...value.vessels] : [];

    vessels.splice(i, 1);

    const next = new Vessels();

    next.vessels = vessels;

    props.onChange?.(next);
  }

  function VesselEditorHOC(vessel: Vessel, i: number) {
    const key = `${vessel?.vesselIMO}${i}`;

    return (
      <div className="vessels-vessel" key={key}>
        <VesselEditor {...rest} value={vessel} onRemove={removeVessel.bind(null, i)} onChange={onVesselChange.bind(null, i)} />
      </div>
    );
  }
}

export { VesselsEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace VesselsEditor {
  export interface Props extends Omit<VesselEditor.Props, "value" | "onChange"> {
    value: Value;
    onChange: (value: Value) => void;
  }

  export type Value = Vessels | undefined;
}
