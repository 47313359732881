import React, { useRef, useState } from "react";
import classNames from "classnames";
import { useShallowStateOld, emptyFn } from "@/utils";
import { tradeAPI } from "@/apis";
import { Location, LocationProps } from "@/models";
import { TextareaField, SelectField, InputFocusEvent } from "@/components";
import { Group } from "../../Group";
import { Row } from "../../Row";
import { LocationEditor as LocationEditorInner, LocationEditorProps } from "./Editor";
import "./Location.scss";

export function LocationEditor(props: Props) {
  props = { ...props };

  props.className = classNames(props.className, "Location");

  const { className, value, status } = props;
  const [data, setData] = useState<LocationEditorProps["data"]>(undefined);
  const [, setDropdownStatus, dropdownStatus] = useShallowStateOld<Status>({
    message: "Please enter a location",
  });
  const isLocationSelected = value?.locationId;

  const cancel = useRef(emptyFn);

  async function onFilter(search: string) {
    cancel.current();

    setData(undefined);
    setDropdownStatus({ message: undefined, loading: false });

    if (!search) {
      setDropdownStatus({ message: "Please enter a location" });

      return;
    }

    setDropdownStatus({ message: "Searching for locations", loading: true });

    const promise = tradeAPI.searchLocations(search);

    cancel.current = promise.cancel;

    const res = await promise;

    if (res.cancelled) return;

    if (!res.data || !res.data.length) {
      setData(undefined);
      setDropdownStatus({ message: "No locations found", loading: false });

      return;
    }

    const data = res.data.map((el) => new Location(el.document));

    setData(data);
    setDropdownStatus({ message: undefined, loading: false });
  }

  function onBlur(e: InputFocusEvent) {
    cancel.current();

    setData(undefined);
    setDropdownStatus({ message: "Please enter a location", loading: false });

    props.onBlur && props.onBlur(e);
  }

  function onClear() {
    props.onChange?.(new Location());
  }

  function onChange(location: Location) {
    props.onChange?.(new Location(location ? { ...value, ...location } : undefined));
  }

  function onOptionsChange(minName: string, maxName: string, option: typeof LocationProps.prototype.safeOptions[number]) {
    props.onChange?.(new Location({ ...value, [minName]: option?.min || null, [maxName]: option?.max || null }));
  }

  function onNotesChange(notes: string) {
    props.onChange?.(new Location({ ...value, notes }));
  }

  return (
    <Group className={className}>
      <LocationEditorInner
        {...props}
        value={props.value}
        onChange={onChange}
        onClear={onClear}
        data={data}
        dropdownStatus={dropdownStatus}
        onFilter={onFilter}
        onBlur={onBlur}
        dataTest={`${props.dataTest}.value`}
      />
      {!props.hideSafes && (
        <Row>
          <SelectField
            {...props}
            label="Berth(s)"
            desc="Safe Berth(s)"
            className="trade-location-options-berth"
            filterKey="label"
            data={LocationProps.prototype.safeOptions}
            value={LocationProps.prototype.safeOptionLabelByMinMax[`${value?.safeBerthsMin}${value?.safeBerthsMax}`]}
            onChange={onOptionsChange.bind(null, "safeBerthsMin", "safeBerthsMax")}
            status={status?.children?.safeBerthsMin || status?.children?.safeBerthsMax}
            dataTest={`${props.dataTest}.berth`}
            key="berth"
            nonFilterSelect
            disabled={!isLocationSelected}
          />
          <SelectField
            {...props}
            label="Port(s)"
            desc="Safe Port(s)"
            className="trade-location-options-port"
            filterKey="label"
            data={LocationProps.prototype.safeOptions}
            value={LocationProps.prototype.safeOptionLabelByMinMax[`${value?.safePortsMin}${value?.safePortsMax}`]}
            onChange={onOptionsChange.bind(null, "safePortsMin", "safePortsMax")}
            status={status?.children?.safePortsMin || status?.children?.safePortsMax}
            dataTest={`${props.dataTest}.port`}
            key="port"
            nonFilterSelect
            disabled={!isLocationSelected}
          />
          <SelectField
            {...props}
            label="Anchorage(s)"
            desc="Safe Anchorage(s)"
            className="trade-location-options-anchorage"
            filterKey="label"
            data={LocationProps.prototype.safeOptions}
            value={LocationProps.prototype.safeOptionLabelByMinMax[`${value?.safeAnchoragesMin}${value?.safeAnchoragesMax}`]}
            onChange={onOptionsChange.bind(null, "safeAnchoragesMin", "safeAnchoragesMax")}
            status={status?.children?.safeAnchoragesMin || status?.children?.safeAnchoragesMax}
            key="anchorage"
            dataTest={`${props.dataTest}.anchorage`}
            nonFilterSelect
            disabled={!isLocationSelected}
          />
        </Row>
      )}
      <TextareaField
        {...props}
        label="Notes"
        desc="Location notes"
        value={value?.notes || ""}
        onChange={onNotesChange}
        status={status?.children?.notes}
        dataTest={`${props.dataTest}.notes`}
        disabled={!isLocationSelected}
      />
    </Group>
  );
}

interface Props extends Omit<LocationEditorProps, "value" | "onChange"> {
  value?: Location | null;
  onChange?: (value: Location | null) => void;
  hideSafes?: boolean;
}

export type TradeLocationProps = Props;
