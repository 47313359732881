import React from "react";
import { CustomDropdown } from "sharedFolder/components/common/CustomDropdown/CustomDropdown";
import { Filter } from "../Filter/Filter";
import styles from "./FilterAndSortHeader.module.scss";
import { sortKey, ISelectableOption } from "sharedFolder/Models/userSettings";
import { CloseButton } from "sharedFolder/components/common/CloseButton/CloseButton";

const sortingUnits: Array<{ key: sortKey; text: string }> = [
  { key: sortKey.createdDate, text: "Last Created" },
  { key: sortKey.updatedDate, text: "Last Updated" },
  { key: sortKey.laycan, text: "Best Laycan" },
  { key: sortKey.invitee, text: "Invitee A-Z" },
  { key: sortKey.bestOffer, text: "Best Offer" },
];

interface IFilterAndSortHeaderProps {
  toggleFilterSort: () => void;
  onSort: (selectedSortField: sortKey) => void;
  selectedSortKey?: sortKey;
  options: ISelectableOption[];
  onFilter: (option: string, selected: boolean) => void;
}

export const FilterAndSortHeader = (props: IFilterAndSortHeaderProps) => {
  function handleSort(value: string | number) {
    const sort = Number(value);
    props.onSort(sort);
  }

  return (
    <div className={styles.filterSortContainer}>
      <Filter title="Add Filters" options={props.options} className={styles.filterOptions} onOptionChange={props.onFilter} />

      <label className={styles.sortLabel} htmlFor="Sort by">
        Sort By
      </label>
      <div className={styles.sortContainer}>
        <CustomDropdown
          options={sortingUnits}
          id="sorting-option"
          ariaLabel="sorting dropdown"
          defaultSelectedKey={props.selectedSortKey}
          onChange={handleSort}
        />
      </div>
      <div className={styles.closeButton}>
        <CloseButton onClick={props.toggleFilterSort} />
      </div>
    </div>
  );
};
