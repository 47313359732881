import { TradeAPI } from "@/apis";
import { jwtDecode, stringArrToBoolMap } from "@/utils";

export class ParsedUpdateToken {
  constructor(updateToken?: string) {
    if (!updateToken) return;

    const parsedUpdateToken = jwtDecode(updateToken) as Data;
    const permittedMethodsMap = stringArrToBoolMap(parsedUpdateToken.permittedMethods);

    Object.assign(this, parsedUpdateToken);

    this.permittedMethodsMap = permittedMethodsMap;
  }
}

export interface ParsedUpdateToken extends Data {
  permittedMethodsMap: Record<PermittedMethod, boolean | undefined>;
}

type PermittedMethod = PermittedMethods[number];
type PermittedMethods = Data["permittedMethods"];
type Data = TradeAPI["NegotiationParsedUpdateToken"];
