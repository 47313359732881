import { Commission, CommissionProps } from "../Commission";
import { LABELS } from "@/constants";

export class BrokerCommission extends Commission {}

class BrokerCommissionProps extends CommissionProps {}

BrokerCommissionProps.prototype.label = LABELS["brokerCommission"];

BrokerCommission.prototype.Props = BrokerCommissionProps;
BrokerCommission.prototype._ = new BrokerCommissionProps();

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */
