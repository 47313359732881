import { makeAutoObservable } from "mobx";
import { Handlebar as TermsetHandlebar } from "___REFACTOR___/models/Termset/Handlebar";
import { Status } from "___REFACTOR___/models";
import { TradeAPI } from "___REFACTOR___/apis";

export class TermsetHandlebars {
  constructor() {
    makeAutoObservable(this);
  }

  status = {} as Status;
  handlebarsByTypeId = null as HandlebarsByTypeId | null;
  handlebarKeyToName: Map<string, string> = new Map<string, string>();

  getHandlebars = async () => {
    this.handlebarsByTypeId = {
      1: handlebars?.voy.map(createTermsetHandlebar),
      2: handlebars?.tct.map(createTermsetHandlebar),
      3: handlebars?.coa.map(createTermsetHandlebar),
    };

    for (const handlebars of Object.values(this.handlebarsByTypeId)) {
      for (const handlebar of handlebars) {
        this.handlebarKeyToName.set(handlebar.key, handlebar.name);
      }
    }
  };
}

function createTermsetHandlebar(data: TradeAPI.Termset.Handlebar) {
  return new TermsetHandlebar(data);
}

export const termsetHandlebars = new TermsetHandlebars();

interface HandlebarsByTypeId {
  1: TermsetHandlebar[];
  2: TermsetHandlebar[];
  3: TermsetHandlebar[];
}

const handlebars = {
  coa: [
    { name: "Account", key: "ACCOUNT" },
    { name: "Address Commission", key: "ADDRESS_COMMISSION" },
    { name: "Broker Commission", key: "BROKER_COMMISSION" },
    { name: "Broker Company", key: "BROKER_COMPANY" },
    { name: "Cargo Size", key: "CARGO_SIZE" },
    { name: "Cargo Size Notes", key: "ADDITIONAL_CARGO_SIZES" },
    { name: "Cargo", key: "CARGO" },
    { name: "Cargo Type Notes", key: "ADDITIONAL_CARGO_TYPES" },
    { name: "Charterer Address", key: "CHARTERER_ADDRESS" },
    { name: "Charterer Name", key: "CHARTERER_NAME" },
    { name: "COA Period from", key: "COA_PERIOD_FROM" },
    { name: "COA Period to", key: "COA_PERIOD_TO" },
    { name: "Demurrage", key: "DEMURRAGE" },
    { name: "Demurrage And Despatch", key: "DEMURRAGE_AND_DESPATCH" },
    { name: "Demurrage Unit", key: "DEMURRAGE_UNIT" },
    { name: "Demurrage Notes", key: "DEMURRAGE_NOTES" },
    { name: "Discharge Location", key: "DISCHARGE_LOCATIONS" },
    { name: "Discharge Location Notes", key: "ADDITIONAL_DISCHARGE_LOCATIONS" },
    { name: "Freight Rate", key: "FREIGHT_RATE" },
    { name: "Freight Rate Unit", key: "FREIGHT_RATE_UNIT" },
    { name: "Freight Rate Notes", key: "FREIGHT_RATE_NOTES" },
    { name: "Liftings Min", key: "LIFTINGS_MIN" },
    { name: "Liftings Max", key: "LIFTINGS_MAX" },
    { name: "Liftings Dispersal", key: "LIFTINGS_DISPERSAL" },
    { name: "Liftings Notes", key: "LIFTINGS_NOTES" },
    { name: "Load Location", key: "LOAD_LOCATIONS" },
    { name: "Load Location Notes", key: "ADDITIONAL_LOAD_LOCATIONS" },
    { name: "Nominations Notice per laycan", key: "NOMINATIONS_NOTICE_PER_LAYCAN" },
    { name: "Nominations Laycan spread", key: "NOMINATIONS_LAYCAN_SPREAD" },
    { name: "Nominations Final laycan notice", key: "NOMINATIONS_FINAL_LAYCAN_NOTICE" },
    { name: "Nominations Nominations notes", key: "NOMINATIONS_NOTES" },
    { name: "Owner Address", key: "OWNER_ADDRESS" },
    { name: "Owner Name", key: "OWNER_NAME" },
    { name: "Cargo Size Maximum", key: "CARGO_SIZE_MAXIMUM" },
    { name: "Cargo Size Minimum", key: "CARGO_SIZE_MINIMUM" },
    { name: "Cargo Size Option", key: "CARGO_SIZE_VARIANCE_OPTION" },
    { name: "Cargo Size Variance Value", key: "CARGO_SIZE_VARIANCE_VALUE" },
    { name: "COA Order Notes", key: "COA_ORDER_NOTES" },
    { name: "Discharge Location Safe Anchorages", key: "DISCHARGE_LOCATION_SAFE_ANCHORAGES" },
    { name: "Discharge Location Safe Berths", key: "DISCHARGE_LOCATION_SAFE_BERTHS" },
    { name: "Discharge Location Safe Ports", key: "DISCHARGE_LOCATION_SAFE_PORTS" },
    { name: "Load Location Safe Anchorages", key: "LOAD_LOCATION_SAFE_ANCHORAGES" },
    { name: "Load Location Safe Berths", key: "LOAD_LOCATION_SAFE_BERTHS" },
    { name: "Load Location Safe Ports", key: "LOAD_LOCATION_SAFE_PORTS" },
    { name: "Vessel Additional Notes", key: "VESSEL_ADDITIONAL_NOTES" },
  ],
  voy: [
    { name: "Account", key: "ACCOUNT" },
    { name: "Address Commission", key: "ADDRESS_COMMISSION" },
    { name: "Broker Commission", key: "BROKER_COMMISSION" },
    { name: "Broker Company", key: "BROKER_COMPANY" },
    { name: "Cargo Size", key: "CARGO_SIZE" },
    { name: "Cargo Size Notes", key: "ADDITIONAL_CARGO_SIZES" },
    { name: "Cargo", key: "CARGO" },
    { name: "Cargo Type Notes", key: "ADDITIONAL_CARGO_TYPES" },
    { name: "Charterer Address", key: "CHARTERER_ADDRESS" },
    { name: "Charterer Name", key: "CHARTERER_NAME" },
    { name: "Demurrage", key: "DEMURRAGE" },
    { name: "Demurrage And Despatch", key: "DEMURRAGE_AND_DESPATCH" },
    { name: "Demurrage Unit", key: "DEMURRAGE_UNIT" },
    { name: "Demurrage Notes", key: "DEMURRAGE_NOTES" },
    { name: "Discharge Location", key: "DISCHARGE_LOCATIONS" },
    { name: "Discharge Location Notes", key: "ADDITIONAL_DISCHARGE_LOCATIONS" },
    { name: "Disponent Owner", key: "DISPONENT_OWNERS_NAME" },
    { name: "Freight Rate", key: "FREIGHT_RATE" },
    { name: "Freight Rate Unit", key: "FREIGHT_RATE_UNIT" },
    { name: "Freight Rate Notes", key: "FREIGHT_RATE_NOTES" },
    { name: "Laycan", key: "LAYCAN" },
    { name: "Laycan From Date", key: "LAYCAN_FROM" },
    { name: "Laycan Too Date", key: "LAYCAN_TO" },
    { name: "Load Location", key: "LOAD_LOCATIONS" },
    { name: "Load Location Notes", key: "ADDITIONAL_LOAD_LOCATIONS" },
    { name: "Owner Address", key: "OWNER_ADDRESS" },
    { name: "Owner Name", key: "OWNER_NAME" },
    { name: "Vessel IMO Number", key: "VESSEL_IMO_NUMBER" },
    { name: "Vessel Name", key: "VESSEL_NAME" },
    { name: "Vessel Owner Chain", key: "VESSEL_OWNER_CHAIN" },
    { name: "Vessel Itinerary", key: "VESSEL_ITINERARY" },
    { name: "Vessel Eta", key: "VESSEL_ETA" },
    { name: "Vessel Speed and Cons", key: "VESSEL_SPEED_AND_CONS" },
    { name: "Vessel Notes", key: "ADDITIONAL_VESSELS" },
    { name: "Cargo Size Option", key: "CARGO_SIZE_VARIANCE_OPTION" },
    { name: "Cargo Size Variance Value", key: "CARGO_SIZE_VARIANCE_VALUE" },
    { name: "Discharge Location Safe Anchorages", key: "DISCHARGE_LOCATION_SAFE_ANCHORAGES" },
    { name: "Discharge Location Safe Berths", key: "DISCHARGE_LOCATION_SAFE_BERTHS" },
    { name: "Discharge Location Safe Ports", key: "DISCHARGE_LOCATION_SAFE_PORTS" },
    { name: "Load Location Safe Anchorages", key: "LOAD_LOCATION_SAFE_ANCHORAGES" },
    { name: "Load Location Safe Berths", key: "LOAD_LOCATION_SAFE_BERTHS" },
    { name: "Load Location Safe Ports", key: "LOAD_LOCATION_SAFE_PORTS" },
    { name: "Order Notes", key: "VOY_ORDER_NOTES" },
    { name: "Vessel Additional Notes", key: "VESSEL_ADDITIONAL_NOTES" },
  ],
  tct: [
    { name: "Account", key: "ACCOUNT" },
    { name: "Address Commission", key: "ADDRESS_COMMISSION" },
    { name: "Broker Company", key: "BROKER_COMPANY" },
    { name: "Broker Commission", key: "BROKER_COMMISSION" },
    { name: "Cargo Type", key: "CARGO" },
    { name: "Cargo Type Notes", key: "ADDITIONAL_CARGO_TYPES" },
    { name: "Charterer Address", key: "CHARTERER_ADDRESS" },
    { name: "Charterer Name", key: "CHARTERER_NAME" },
    { name: "Delivery Location", key: "DELIVERY_LOCATION" },
    { name: "Disponent Owner", key: "DISPONENT_OWNERS_NAME" },
    { name: "Duration", key: "DURATION" },
    { name: "Duration Unit", key: "DURATION_UNIT" },
    { name: "Hire Rate", key: "HIRE_RATE" },
    { name: "Hire Rate Unit", key: "HIRE_RATE_UNIT" },
    { name: "ILOHC", key: "ILOHC" },
    { name: "ILOHC Unit", key: "ILOHC_UNIT" },
    { name: "CVE", key: "CVE" },
    { name: "CVE Unit", key: "CVE_UNIT" },
    { name: "Trading Exclusions", key: "TRADING_EXCLUSIONS" },
    { name: "Bunker Price Delivery", key: "BUNKER_PRICE_DELIVERY" },
    { name: "Bunker Quantity Delivery", key: "BUNKER_QUANTITY_DELIVERY" },
    { name: "Fuel Type Delivery", key: "FUEL_TYPE_DELIVERY" },
    { name: "Bunker Notes Delivery", key: "BUNKER_NOTES_DELIVERY" },
    { name: "Bunker Price Redelivery", key: "BUNKER_PRICE_REDELIVERY" },
    { name: "Bunker Quantity Redelivery", key: "BUNKER_QUANTITY_REDELIVERY" },
    { name: "Fuel Type Redelivery", key: "FUEL_TYPE_REDELIVERY" },
    { name: "Bunker Notes Redelivery", key: "BUNKER_NOTES_REDELIVERY" },
    { name: "Cargo Exclusions", key: "CARGO_EXCLUSIONS" },
    { name: "Laycan", key: "LAYCAN" },
    { name: "Laycan From Date", key: "LAYCAN_FROM" },
    { name: "Laycan Too Date", key: "LAYCAN_TO" },
    { name: "Owner Address", key: "OWNER_ADDRESS" },
    { name: "Owner Name", key: "OWNER_NAME" },
    { name: "Re-delivery Location", key: "REDELIVERY_LOCATION" },
    { name: "Vessel IMO Number", key: "VESSEL_IMO_NUMBER" },
    { name: "Vessel Name", key: "VESSEL_NAME" },
    { name: "Vessel Owner Chain", key: "VESSEL_OWNER_CHAIN" },
    { name: "Vessel Itinerary", key: "VESSEL_ITINERARY" },
    { name: "Vessel Eta", key: "VESSEL_ETA" },
    { name: "Vessel Speed and Cons", key: "VESSEL_SPEED_AND_CONS" },
    { name: "Vessel Notes", key: "ADDITIONAL_VESSELS" },
    { name: "Via Location", key: "VIA_LOCATION" },
    { name: "Order Notes", key: "TC_ORDER_NOTES" },
    { name: "Ballast Bonus Notes", key: "BALLAST_BONUS_NOTES" },
    { name: "CVE Notes", key: "CVE_NOTES" },
    { name: "Delivery Location Notes", key: "DELIVERY_LOCATION_NOTES" },
    { name: "Duration Maximum", key: "DURATION_MAXIMUM" },
    { name: "Duration Minimum", key: "DURATION_MINIMUM" },
    { name: "Duration Notes", key: "DURATION_NOTES" },
    { name: "Hire Rate Notes", key: "HIRE_RATE_NOTES" },
    { name: "ILOHC Notes", key: "ILOHC_NOTES" },
    { name: "Redelivery Location Notes", key: "REDELIVERY_LOCATION_NOTES" },
    { name: "Vessel Additional Notes", key: "VESSEL_ADDITIONAL_NOTES" },
    { name: "Vessel Size", key: "VESSEL_SIZE" },
    { name: "Via Location Notes", key: "VIA_LOCATION_NOTES" },
  ],
};
