import { UniversalOrderNegotiationFormValues, getOfferDetails, Negotiation, dialog } from "@/models";

export async function postNegotiationOffer(model: Negotiation | undefined, values: UniversalOrderNegotiationFormValues) {
  if (!model) {
    console.error("postNegotiationOffer:", { model, values });

    const status = {} as Status;

    status.type = "error";
    status.title = "Negotiation Offer Failure";

    dialog.show({
      status,
      dataTest: `post-negotiation-offer-fail`,
    });

    return;
  }

  const indicationType = values.indicationType || "indicated";

  model._.status.loading = true;
  model._.status.message = loadingMessageByType[indicationType];

  const payload = {
    details: indicationType === "indicated" || indicationType === "firmed" ? getOfferDetails(values) : null,
    action: indicationType,
    expiresOn:
      indicationType === "firmed"
        ? values.timelimitVariant === "DateOffset"
          ? values.timelimitDateOffset?.toString()
          : values.timelimitDate?.toString()
        : null,
    updateToken: model.updateToken,
  };

  const res = await model.postOffer(payload);

  if (!res?.ok) {
    model._.status.type = "error";
    model._.status.title = "Negotiation Offer Failure";
    model._.status.dump = { res, entity: model };

    dialog.show({
      status: model._.status,
      dataTest: `post-negotiation-offer-fail`,
    });

    model._.status.loading = false;
    model._.status.message = null;

    return res;
  }

  model._.status.loading = false;
  model._.status.message = null;

  // dialog.show({
  //   status: {
  //     type: "success",
  //     title: successMessageTitleByType[indicationType],
  //     message: successMessageByType[indicationType],
  //   },
  //   dataTest: `post-negotiation-offer-success`,
  // });

  return res;
}

const loadingMessageByType = {
  indicated: "Updating Offer",
  firmRequested: "Sending Firm Request",
  firmed: "Sending Firm Offer",
  firmAccepted: "Accepting Firm Offer",
} as StringRecord;

// const successMessageByType = {
//   indicated: "You have successfully updated the negotiation offer",
//   firmRequested: "You have successfully requested for a firm bid from the charterer",
//   firmed: "You have successfully sent the charterer a firm offer",
//   firmAccepted: "You have successfully accepted a firm bid",
// } as StringRecord;

// const successMessageTitleByType = {
//   indicated: "Successful Indication",
//   firmRequested: "Successful Firm Request",
//   firmed: "Successful Firm Offer",
//   firmAccepted: "Firm Bid Accepted",
// } as StringRecord;
