import React from "react";
import classNames from "classnames";
import { VesselSize, VesselSizeProps } from "@/models";
import { TextareaField, SelectField, SelectFieldProps } from "@/components";
import { Group } from "../../Group";
import "./VesselSize.scss";

export function VesselSizeEditor(props: Props) {
  props = { ...props };

  props.className = classNames(props.className, "VesselSize");

  const { value, status } = props;

  function onNotesChange(notes: string) {
    props.onChange?.(new VesselSize({ ...value, notes }));
  }

  // const data = getDataMemoized();

  return (
    <Group className={props.className}>
      <SelectField
        {...props}
        data={VesselSizeProps.prototype.vesselSizeArray}
        noDataStatus={noDataStatus}
        dataTest={`${props.dataTest}.value`}
      />
      <TextareaField
        {...props}
        label="Notes"
        desc="Vessel size notes"
        value={value?.notes || ""}
        onChange={onNotesChange}
        status={status?.children?.notes}
        dataTest={`${props.dataTest}.notes`}
      />
    </Group>
  );
}

const noDataStatus = { message: "No matching vessel sizes" };

interface Props extends Omit<SelectFieldProps, "value" | "onChange"> {
  value?: VesselSize | null;
  onChange?: (value: VesselSize | null) => void;
}
