import React, { useRef, useState, MutableRefObject, CSSProperties, useMemo } from "react";
import { observer } from "mobx-react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useSimpleEffect, wait } from "___REFACTOR___/utils";
import * as Services from "___REFACTOR___/services";
import "./MiniConfirm.scss";

function MiniConfirmComponent(props: MiniConfirmComponent.Props) {
  const { message = "confirm", target } = props;
  const messageDOMRef = useRef(null) as MessageDOMRef;
  const wrapperDOMRef = useRef(null) as WrapperDOMRef;
  const [style, setStyle] = useState<CSSProperties>({});
  const messageStyle = { width: messageDOMRef.current?.clientWidth || 0 };

  useSimpleEffect(effect, [target]);

  if (!target) return null;

  return (
    <mini-confirm-instance>
      <mini-confirm-overlay onClick={decline} />
      <mini-confirm-wrapper style={style} ref={wrapperDOMRef}>
        <mini-confirm-message-wrapper onClick={confirm} style={messageStyle}>
          <mini-confirm-message ref={messageDOMRef}>{message}</mini-confirm-message>
        </mini-confirm-message-wrapper>
      </mini-confirm-wrapper>
    </mini-confirm-instance>
  );

  async function effect() {
    if (!target) return;

    await wait(0);

    wrapperDOMRef.current!.append(target.cloneNode());

    const t = target.getBoundingClientRect();
    const left = t.right - t.width;
    const top = t.top;
    const height = t.height;

    setStyle({ top, left, height });
  }

  function decline() {
    Services.miniConfirm.decline();
  }

  function confirm() {
    Services.miniConfirm.confirm();
  }
}

function MiniConfirmTransition() {
  const { props, isOpen } = Services.miniConfirm;
  const transitionKey = useMemo(getTransitionKey, [props]);

  return (
    <TransitionGroup component="mini-confirm">
      <CSSTransition {...CSS_TRANSITION_PROPS} in={isOpen} key={transitionKey}>
        <MiniConfirmComponent {...props} />
      </CSSTransition>
    </TransitionGroup>
  );

  function getTransitionKey() {
    return new Date().getTime();
  }
}

const CSS_TRANSITION_PROPS = { timeout: { appear: 0, enter: 250, exit: 250 }, classNames: "mini-confirm" };

const MiniConfirm = observer(MiniConfirmTransition);

export { MiniConfirm };

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

declare namespace MiniConfirm {
  export interface Props {
    message?: string;
    target: HTMLElement;
  }
}

declare namespace MiniConfirmComponent {
  export interface Props {
    message?: string;
    target?: HTMLElement;
  }
}

type MessageDOMRef = MutableRefObject<HTMLSpanElement | null>;
type WrapperDOMRef = MutableRefObject<HTMLDivElement | null>;
