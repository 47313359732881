import React from "react";
import Icon from "__legacy/sharedFolder/components/common/Icon/Icon";
import styles from "./NoAccess.module.scss";

export default function NoAccess() {
  return (
    <div className={styles.flexedContainer} data-test="account-fatal-error">
      <Icon icon="info-outline" />

      <div className={styles.messageContainer}>
        <div>
          <h1 className={styles.heading}>There seems to be a problem with your account.</h1>
          <p className={styles.secondaryHeading}>Please contact Customer Support on:</p>
          <div className={styles.contactInfo}>
            <p>UK: (+44) 20 7334 3052</p>
            <p>US: (+1) 281 657 1414</p>
            <p>SG: (+65) 3163 4949</p>
            <p>
              Email: <a href="mailto:support@sea.live">support@sea.live</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
