// BLACKBOX
import { OrderNotesAndAttachments } from "offerRep/OrderDetails/OrderNotesAndAttachments";
import { OfferRepNegotiationContext } from "offerRep/OwnerIndicationForm/Contexts/OfferRepNegotiationContext";
import { MinimalConfigurationContext } from "__legacy/sharedFolder/MinimalConfigurationContext";
import { useOfferRepNegotiation } from "offerRep/useOfferRepNegotiation";
import React from "react";
import { useParams } from "react-router-dom";
import { config } from "@/config";

export function NotesAndAttachments() {
  const { offerRepToken } = useParams<StringRecord>();
  const { offerRepNegotiationService, negotiation } = useOfferRepNegotiation(config.ctradeUrl, offerRepToken);

  return (
    <div className="Offerrep-NotesAndAttachments LEGACY_CONTAINER">
      {/* <FetchInterceptor baseUrl={config.ctradeUrl} exceptions={["/token$"]}> */}
      <OfferRepNegotiationContext.Provider
        value={{
          offerRepApi: offerRepNegotiationService,
          firmOrIndicationState: "INDICATION_ONLY",
          setFirmOrIndicationState: console.info.bind(null, "Something executed setFirmOrIndicationState"),
        }}
      >
        <MinimalConfigurationContext.Provider value={config}>
          {negotiation?.updateToken && (
            <OrderNotesAndAttachments
              count={negotiation.orderAttachments.length}
              offerRepToken={offerRepToken}
              negotiation={negotiation}
            />
          )}
        </MinimalConfigurationContext.Provider>
      </OfferRepNegotiationContext.Provider>
    </div>
  );
}
