import { TradeAPI } from "___REFACTOR___/apis";
import { SubjectTermsetEditor } from "___REFACTOR___/components";
import { DataModel, DataModelProps } from "../DataModel";

class Termset extends DataModel {
  constructor(data?: DeepPartial<Data>) {
    super(data);
  }

  toString() {
    return this.name;
  }
}

class TermsetProps extends DataModelProps {}

Termset.prototype.Props = TermsetProps;

/* -------------------------------------------------------------------------- */
/*                               SubjectTermset                               */
/* -------------------------------------------------------------------------- */

class SubjectTermset extends Termset {}
class SubjectTermsetProps extends TermsetProps {}

SubjectTermset.prototype.Props = SubjectTermsetProps;
SubjectTermsetProps.prototype.Editor = SubjectTermsetEditor;

export { Termset, TermsetProps, SubjectTermset, SubjectTermsetProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface Termset extends Data {
  Props: typeof TermsetProps;
  _: TermsetProps;
}

declare namespace Termset {
  export type Id = TradeAPI.Termset.Id;
}

type Data = TradeAPI.Termset;
