import { FIELD_TYPE } from "@/components";
import { AccountEditor as Account } from "./Account";
import { PeriodEditor as Period } from "./Period";
import { CargoTypeEditor as CargoType } from "./CargoType";
import { CargoSizeEditor as CargoSize } from "./CargoSize";
import { COACargoSizeEditor as COACargoSize } from "./COACargoSize";
import { CommissionEditor as Commission } from "./Commission";
import { CompanyEditor as Company } from "./Company";
import { LocationEditor as Location } from "./Location";
import { LiftingsEditor as Liftings } from "./Liftings";
import { NominationsEditor as Nominations } from "./Nominations";
import { VesselSizeEditor as VesselSize } from "./VesselSize";
import { DurationEditor as Duration } from "./Duration";
import { TermsetBuilder } from "./TermsetBuilder";
import { SubjectTermsetEditor as SubjectTermset } from "./SubjectTermset";
import { ProformaLayoutEditor as ProformaLayout } from "./ProformaLayout";
import { ProformaLayoutKeyEditor as ProformaLayoutKey } from "./ProformaLayoutKey";
import { OwnerAccountEditor as OwnerAccount } from "./OwnerAccount";
import { VesselEditor as Vessel } from "./Vessel";
import { VesselsEditor as Vessels } from "./Vessels";
import { TimelimitEditor as Timelimit } from "./Timelimit";
import { OrderTemplateSearchEditor as OrderTemplateSearch } from "./OrderTemplateSearch";
import { UnverifiedAccountEditor as UnverifiedAccount } from "./UnverifiedAccount";
import { FreightRateEditor as FreightRate } from "./FreightRate";
import { NegotiationNoteEditor as NegotiationNote } from "./NegotiationNote";
import { CLDDUEditor as CLDDU } from "./CLDDU";
import { CLDDUOwnerInviteeEditor as CLDDUOwner } from "./CLDDUOwner";
import { CLDDUChartererEditor as CLDDUCharterer } from "./CLDDUCharterer";
import { CLDDUBrokerEditor as CLDDUBroker } from "./CLDDUBroker";
import { SubsEditor as Subs } from "./Subs";
import { TimezoneEditor as Timezone } from "./Timezone";

export let EDITOR_TYPE = {
  Timezone,
  CLDDU,
  CLDDUOwner,
  CLDDUCharterer,
  CLDDUBroker,
  Period,
  Account,
  Company,
  SubjectTermset,
  Duration,
  Location,
  Liftings,
  CargoType,
  CargoSize,
  Commission,
  VesselSize,
  Nominations,
  COACargoSize,
  TermsetBuilder,
  ProformaLayout,
  ProformaLayoutKey,
  OwnerAccount,
  Vessel,
  Vessels,
  Timelimit,
  OrderTemplateSearch,
  UnverifiedAccount,
  FreightRate,
  NegotiationNote,
  Subs,
};

setTimeout(lazyTypeMerge);

function lazyTypeMerge() {
  EDITOR_TYPE = {
    ...FIELD_TYPE,
    Timezone,
    CLDDU,
    CLDDUOwner,
    CLDDUCharterer,
    CLDDUBroker,
    Period,
    Account,
    Company,
    SubjectTermset,
    Duration,
    Location,
    Liftings,
    CargoType,
    CargoSize,
    Commission,
    VesselSize,
    Nominations,
    COACargoSize,
    TermsetBuilder,
    ProformaLayout,
    ProformaLayoutKey,
    OwnerAccount,
    Vessel,
    Vessels,
    Timelimit,
    OrderTemplateSearch,
    UnverifiedAccount,
    FreightRate,
    NegotiationNote,
    Subs,
  };
}

export type EditorType = keyof typeof EDITOR_TYPE;
