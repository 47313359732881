import React, { useState } from "react";
import { OrderNegStatus } from "sharedFolder/Models/OrderNegStatus";
import { Status } from "../Status/Status";
import { useTimer } from "../DigitalTimer/useTimer";
import { ITimerCount } from "sharedFolder/components/common/interfaces";
import { LastUpdatedAction } from "sharedFolder/Models/LastUpdatedAction";
import { OrderNegStatusMapper } from "sharedFolder/Utilities/OrderNegStatusMapper";
import { formatStatusCountDown } from "sharedFolder/Utilities/formatStatusCountDown";

interface INegotiationStatusContainerProps {
  firmBidExpiresOn: string | null;
  firmOfferExpiresOn: string | null;
  onSubsExpiresOn: string | null;
  status: OrderNegStatus;
  lastUpdatedAction: LastUpdatedAction;
}

const NegotiationStatusContainer = ({
  lastUpdatedAction,
  firmBidExpiresOn,
  firmOfferExpiresOn,
  onSubsExpiresOn,
  status,
}: INegotiationStatusContainerProps) => {
  const [countDown, setCountDown] = useState<ITimerCount>();
  const [text, icon, expiryDate] = OrderNegStatusMapper(
    status,
    lastUpdatedAction,
    firmBidExpiresOn,
    firmOfferExpiresOn,
    onSubsExpiresOn
  );

  useTimer((timeDiff) => {
    setCountDown(timeDiff);
  }, expiryDate || "");

  return (
    <>
      {status === "Inactive" ? (
        <div>--</div>
      ) : (
        <Status icon={icon} status={text} timeRemaining={formatStatusCountDown(countDown)} />
      )}
    </>
  );
};

export default NegotiationStatusContainer;
