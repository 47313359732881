import React, { createRef, Component } from "react";
import { Dropdown, IDropdownOption, IDropdownStyles, IDropdown } from "office-ui-fabric-react";
import classNames from "classnames";
import { InputWidthType } from "sharedFolder/components/common/types";
import DropDownCaret from "../DropDownCaret/DropDownCaret";
import styles from "./CustomDropdown.module.scss";

interface ICustomDropdownProps {
  placeholder?: string;
  id: string;
  ariaLabel: string;
  dataTest?: string;
  disabled?: boolean;
  options: IDropdownOption[];
  defaultSelectedKey?: string | number | string[] | number[];
  isValid?: boolean;
  onChange: (value: string | number) => void;
  focus?: boolean;
  dropdownClassName?: string;
  width?: InputWidthType;
}

interface ICustomDropdownState {
  selectedItem?: string | number | string[] | number[];
}

export class CustomDropdown extends Component<ICustomDropdownProps, ICustomDropdownState> {
  private basicDropdown = createRef<IDropdown>();

  constructor(props: ICustomDropdownProps) {
    super(props);
    this.state = {
      selectedItem: this.props.defaultSelectedKey,
    };
  }

  public render() {
    const { isValid = true, width } = this.props;
    const styleWidth = width ? styles[width] : "";
    const combineClasses = classNames(!isValid ? "error-state" : "", styles.container, this.props.dropdownClassName, styleWidth);

    const dropdownStyles: Partial<IDropdownStyles> = {
      callout: { maxHeight: 253, overflowY: "auto" },
    };

    return (
      <Dropdown
        data-test={this.props.dataTest}
        componentRef={this.basicDropdown}
        className={combineClasses}
        placeholder={this.props.placeholder}
        id={this.props.id}
        ariaLabel={this.props.ariaLabel}
        disabled={this.props.disabled}
        options={this.props.options}
        selectedKey={this.props.defaultSelectedKey}
        onChange={this.changeState}
        responsiveMode={5} // pass 5 to force the options not to displayed in a panel
        onRenderCaretDown={() => <DropDownCaret />}
        styles={dropdownStyles}
      />
    );
  }

  public componentDidUpdate(prevProps: ICustomDropdownProps) {
    if (this.state.selectedItem !== this.props.defaultSelectedKey) {
      this.setState({ selectedItem: this.props.defaultSelectedKey });
    }
  }

  public componentDidMount() {
    if (this.basicDropdown && this.basicDropdown.current && this.props.focus) {
      this.basicDropdown.current.focus();
    }
  }

  public changeState = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
    if (item !== undefined) {
      this.setState({ selectedItem: item.key });
      this.props.onChange(item.key);
    }
  };
}
