class Timer {
  executeOn = executeOn;
  keepExecuting = keepExecuting;
}

function executeOn(date: string | number, callback?: any, interval = 1000) {
  const intervalId = window.setInterval(check, interval);

  function check() {
    if (new Date() < new Date(date)) return;

    callback();

    clear();
  }

  function clear() {
    return window.clearInterval(intervalId);
  }

  return clear;
}

function keepExecuting(callback, interval = 1000) {
  const intervalId = window.setInterval(callback, interval);

  function clear() {
    return window.clearInterval(intervalId);
  }

  return clear;
}

export const timer = new Timer();
