import React from "react";
import { HireRate, HireRateProps } from "@/models";
import { UnitValueEditorProps, UnitValueEditor } from "../UnitValue";

export function HireRateEditor(props: Props) {
  props = { ...props };

  props.className = `${props.className} HireRate`;

  function onChange(value: HireRate) {
    const next = new HireRate(value);

    props.onChange?.(next);
  }

  return <UnitValueEditor {...props} units={HireRateProps.prototype.units} onChange={onChange} dataTest={`${props.dataTest}`} />;
}

interface Props extends Omit<UnitValueEditorProps, "value" | "onChange"> {
  value?: HireRate;
  onChange?: (value: HireRate) => void;
}

export type HireRateEditorProps = Props;
