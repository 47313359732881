import { dialog } from "@/models";
import React, { useState } from "react";
import { Button, TextareaField, TextInput } from "___REFACTOR___/components/common";
import { testSelectors } from "@/constants";
import styles from "./Withdrawn.module.scss";
import { Divider } from "@/components/common/Divider";

export function Withdrawn(props: Props) {
  return <>{props.children}</>;
}

interface Props {
  children?: React.ReactNode;
}

interface OrderWithdrawalProps {
  orderId: string;
}

export function OrderWithdrawal(props: OrderWithdrawalProps) {
  function onSubmit() {
    dialog.resolve?.({
      res: { reason },
    });

    dialog.hide();
  }

  const [reason, setReason] = useState<TextInput.Value>("");
  const reasonDescription = "Add a reason for the order withdrawal";
  const truncatedOrderIdToDisplay = props.orderId.substring(0, 6).toUpperCase();

  return (
    <div className={styles.orderWithdrawalForm}>
      <h2 className={styles.withdrawalHeader}>Withdraw order {truncatedOrderIdToDisplay}?</h2>
      <Divider />
      <div className={styles.withdrawalDescription}>
        <p>The order will be withdrawn, along with all associated negotiations. This action cannot be undone.</p>
        <p>All parties will be informed of the withdrawal by email and Sea/chat.</p>
      </div>
      <TextareaField
        label="Reason (optional)"
        desc={reasonDescription}
        value={reason}
        onChange={setReason}
        placeholder={reasonDescription}
        dataTest={testSelectors.orderWithdrawReasonTextBox}
      />
      <Divider />
      <div className={styles.withdrawalActions}>
        <Button variant="flat" onClick={dialog.hide} data-test={testSelectors.orderWithdrawCancelButton}>
          Cancel
        </Button>
        <Button
          icon={"withdrawn"}
          variant="action"
          className={styles.withdrawButton}
          onClick={onSubmit}
          background={"warning"}
          data-test={testSelectors.orderWithdrawSubmitButton}
        >
          Withdraw
        </Button>
      </div>
    </div>
  );
}
