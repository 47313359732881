import { memo } from "react";
import { get } from "lodash-es";
import { ElementHOC } from "../Element";
import { switchTypeForFieldName } from "@/utils/switchFieldType";
import "./TitledRow.scss";

function TitledRow(props) {
  let { children, title, subtitle, className } = props.element;
  const { context } = props;

  const copyValue = (value, name, label) => {
    const fieldName = switchTypeForFieldName(label, name);

    context.setFieldValue(fieldName, value);
  };

  children = children.map((children) => ({ ...children, copyValue }));
  className = `${className || ""} TitledRow-row`;

  return (
    <div className={className}>
      {title && (
        <div className="TitledRow-header">
          <div className="TitledRow-header-title">{title}</div>
          <div className="TitledRow-header-subtitle">{subtitle}</div>
        </div>
      )}
      <div className="TitledRow-children">{children?.map(ChildHOC)}</div>
    </div>
  );
}

function ChildHOC(child, i) {
  return (
    <div className="TitledRow-child" key={i}>
      {ElementHOC({
        ...child,
        copyButton: true,
        onButtonClick: (value) => child.copyValue(value, child.name, child.label),
        value: child.value,
      })}
      {!child.readOnly &&
        ElementHOC({
          ...child,
          value: get(child, ["formProps", "initialValues", child.name]),
          readOnly: true,
          label: "Current indication",
        })}
    </div>
  );
}

const Memo = memo(TitledRow);

export { Memo as TitledRow };
