import { UniversalOrderNegotiationFormValues, Negotiation, dialog } from "@/models";
import { Context } from "../Orders";

export async function updateNegotiationVessel(
  model: Negotiation | undefined,
  context: Context,
  values: UniversalOrderNegotiationFormValues
) {
  if (!model || !values.vessel) {
    console.error("updateNegotiationVessel:", { model, context, values });

    const status = {} as Status;

    status.type = "error";
    status.title = "Vessel Update Failure";

    dialog.show({
      status,
      dataTest: `update-negotiation-vessel-fail`,
    });

    return;
  }

  model._.status.loading = true;
  model._.status.message = `Updating ${values.vessel?.registrationDataName}`;

  const res = await model.postUpdateVessel(values.vessel);

  if (!res?.ok) {
    model._.status.type = "error";
    model._.status.title = "Vessel Update Failure";
    model._.status.dump = { res, entity: model };

    dialog.show({
      status: model._.status,
      dataTest: `update-negotiation-vessel-fail`,
    });

    model._.status.loading = false;
    model._.status.message = null;

    return res;
  }

  model._.status.loading = false;
  model._.status.message = null;

  dialog.show({
    status: {
      type: "success",
      title: "Successful Vessel Update",
      message: "You have successfuly updated the vessel",
    },
    dataTest: `update-negotiation-vessel-success`,
  });

  return res;
}
