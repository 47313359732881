import React, { ReactNode } from "react";
import { DEVELOPMENT } from "___REFACTOR___/config";
import { DateOffset } from "___REFACTOR___/models";
import { Input } from "../../Input";
import { Container } from "../../Container";
import { NumberInput } from "../Number";
import "./DateOffset.scss";

function DateOffsetInput(props: DateOffsetInput.Props) {
  const { value, units = DEFAULT_UNITS, onChange, dataTest, ...rest } = props;

  // if (DEVELOPMENT) units = [...units, "second"];

  return (
    <Container {...props} type="DateOffset">
      {units.reduce(NumberInputHOC, [])}
    </Container>
  );

  function onUnitChange(unit: DateOffsetInput.Unit, unitValue: NumberInput.Value) {
    onChange?.(new DateOffset({ ...value, [unit]: unitValue }));
  }

  function NumberInputHOC(acc: ReactNode[], unit: DateOffsetInput.Unit, i: number, arr: string[]) {
    const input = (
      <NumberInput
        dataTest={`${dataTest}.${unit}`}
        {...rest}
        value={value?.[unit]}
        onChange={onUnitChange.bind(null, unit)}
        {...PROP_MAP[unit]}
        key={i}
      />
    );
    const colon = <span key={arr.length + i}>:</span>;

    if (i < arr.length - 1) return [...acc, input, colon];

    return [...acc, input];
  }
}

const PROP_MAP = {
  second: { placeholder: "secs" },
  minute: { placeholder: "mins", max: 59 },
  hour: { placeholder: "hrs", max: 72 },
  month: { placeholder: "mths" },
  year: { placeholder: "yrs" },
};

const DEFAULT_UNITS = ["hour", "minute"] as DateOffsetInput.Unit[];

export { DateOffsetInput };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

// eslint-disable-next-line no-redeclare
declare namespace DateOffsetInput {
  export interface Props extends Input.Props {
    value: Value;
    onChange: (value: Value) => void;
    units?: Unit[];
  }

  export type Value = DateOffset | undefined;
  export type Unit = "second" | "minute" | "hour" | "month" | "year";
}
