import { IDistributionViewModel, DistributionUserStatus } from "../Models/IDetails";
import { IMapper, withNullable } from "./map";
import { IRecipient, IKnownUser } from "../../api/orders/models/IDistributionList";

const map: IMapper<IRecipient, IDistributionViewModel> = {
  toApi: (distribution: IDistributionViewModel): IRecipient => {
    const knownUser = distribution.userId
      ? ({
          id: distribution.userId,
          name: distribution.name,
          email: distribution.email,
          systemUserId: distribution.systemUserId?.toString(),
          companyRoles: distribution.availableRoles,
          company: {
            name: distribution.companyName || "",
            id: distribution.companyId || "",
          },
          location: {
            name: distribution.locationName || "",
            id: distribution.locationId || "",
          },
          division: {
            name: distribution.divisionName || "",
            id: distribution.divisionId || "",
          },
          desk: {
            name: distribution.deskName || "",
            id: distribution.deskId || "",
          },
          companyType: distribution.role,
        } as IKnownUser)
      : null;

    return {
      email: distribution.email,
      availableRoles: distribution.availableRoles,
      knownUser,
      actor: distribution.actor,
      role: distribution.role,
      sentOn: distribution.sentOn,
      status: distribution.status,
      negDetails: distribution.negDetails,
      additionalData: {
        disableUserRow: distribution.disableUserRow,
        groupEmailAddress: distribution.groupEmailAddress,
      },
    };
  },
  toView: (recipient: IRecipient): IDistributionViewModel => {
    const role = recipient.role || "unknown";
    const email = recipient.knownUser ? recipient.knownUser.email : recipient.email || "--";
    const parsedStatus = recipient.status as DistributionUserStatus;
    if (!parsedStatus && recipient.status) {
      console.error(`No status parse-able for recipient:${JSON.stringify(recipient)}`);
    }
    return {
      userId: recipient.knownUser !== null ? recipient.knownUser?.id : undefined,
      name: recipient.knownUser ? recipient.knownUser.name : email,
      email,
      companyName: recipient.knownUser?.company.name,
      companyId: recipient.knownUser?.company.id,
      locationName: recipient.knownUser?.location.name,
      locationId: recipient.knownUser?.location.id,
      deskName: recipient.knownUser?.desk.name,
      deskId: recipient.knownUser?.desk.id,
      divisionName: recipient.knownUser?.division.name,
      divisionId: recipient.knownUser?.division.id,
      systemUserId: recipient.knownUser?.systemUserId ? Number(recipient.knownUser?.systemUserId) : undefined,
      status: parsedStatus || "pending",
      sentOn: recipient.sentOn,
      loading: recipient.additionalData?.isLoading || false,
      role,
      negDetails: recipient.negDetails,
      availableRoles: recipient.availableRoles || [],
      actor: recipient.actor,
      disableUserRow: recipient.additionalData?.disableUserRow || false,
      groupEmailAddress: recipient.additionalData?.groupEmailAddress || null,
    };
  },
  emptyViewModel: {
    name: "",
    email: "",
    companyName: "",
    locationName: "",
    deskName: "",
    divisionName: "",
    loading: false,
    status: "pending",
    role: "unknown",
    negDetails: null,
    availableRoles: [],
    disableUserRow: false,
    groupEmailAddress: null,
  },
};

export const mapDistribution = withNullable(map);
