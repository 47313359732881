import React from "react";
import Button from "__legacy/sharedFolder/components/common/Button/Button";

interface ICloseButtonProps {
  onClick: () => void;
}
/**This close button is positioned on the right */
export const PanelCloseButton = (props: ICloseButtonProps) => (
  <div className="panelCloseButtonContainer">
    <CloseButton {...props} />
  </div>
);

/** The close button  component, the parent should position it**/
export const CloseButton = (props: ICloseButtonProps) => (
  <Button className="closeButton" icon="close" ariaLabel="Close" onClick={props.onClick} />
);
