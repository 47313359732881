import React, { forwardRef, Ref, useCallback, useImperativeHandle, useState } from "react";
import { debounce } from "lodash-es";
import { AggridFilterComp, AggridFilterParams, RangeField, NumberInput } from "@/components";
import "./CargoSizeFilter.scss";

function CargoSizeFilter(params: AggridFilterParams, ref: Ref<AggridFilterComp>) {
  const { filterChangedCallback } = params;
  const [range, setRange] = useState(defaultState);
  const from = range[0];
  const to = range[1];
  const _filterChangedCallback = useCallback(debounce(filterChangedCallback, 200), [filterChangedCallback]);

  useImperativeHandle(ref, getCurrentInstance);

  function getCurrentInstance() {
    return {
      isFilterActive,
      doesFilterPass,
      getModel,
      setModel,
    };
  }

  function isFilterActive() {
    return from !== min || to !== max;
  }

  function doesFilterPass(params) {
    const { data } = params;
    let cargoSize;
    let vesselSize;
    let coaCargoSize;

    if (data?._?.Type === "Order") {
      cargoSize = data.details?.cargoSize;
      vesselSize = data.details?.vesselSize;
      coaCargoSize = data.details?.coaCargoSize;
    }

    if (data?._?.Type === "Negotiation") {
      cargoSize = data.bid?.cargoSize?.value || data.offer?.cargoSize?.value;
      vesselSize = data.bid?.vesselSize?.value || data.offer?.vesselSize?.value;
      coaCargoSize = data.bid?.coaCargoSize?.value || data.offer?.coaCargoSize?.value;
    }

    const minValue = cargoSize?.value || coaCargoSize?.min || vesselSize?.sizeFrom;
    const maxValue = cargoSize?.value || coaCargoSize?.max || vesselSize?.sizeTo;

    if (typeof minValue !== "number" && typeof maxValue !== "number") return false;

    const isMaxValueInRange = from <= maxValue && maxValue <= to;
    const isMinValueInRange = from <= minValue && minValue <= to;

    return isMinValueInRange || isMaxValueInRange;
  }

  function getModel() {
    return range;
  }

  function setModel(range) {
    if (!range) {
      setRange(defaultState);

      return;
    }

    setRange(range);
  }

  function onChange(value) {
    setRange(value);

    _filterChangedCallback();
  }

  function setFrom(fromNext) {
    setRange([fromNext, to]);

    _filterChangedCallback();
  }

  function setTo(toNext) {
    setRange([from, toNext]);

    _filterChangedCallback();
  }

  const label = (
    <div className="aggrid-cargo-size-filter-label">
      Quantity Filter (from{" "}
      <NumberInput
        value={from}
        onChange={setFrom}
        min={min}
        max={max}
        dataTest="aggrid-cargo-size-filter-from"
        contentBasedWidth
      />{" "}
      to <NumberInput value={to} onChange={setTo} min={min} max={max} dataTest="aggrid-cargo-size-filter-to" contentBasedWidth />)
    </div>
  );

  return (
    <RangeField
      value={range}
      onChange={onChange}
      min={min}
      max={max}
      step={1000}
      label={label}
      className="aggrid-cargo-size-filter"
      dataTest="quantity-filter"
    />
  );
}

const min = 0;
const max = 1000000;
const defaultState = [min, max];

const ForwardRef = forwardRef(CargoSizeFilter);

ForwardRef.displayName = "CargoSizeFilter";

export { ForwardRef as CargoSizeFilter };
