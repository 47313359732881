import React from "react";
import { Subs } from "@/models";
import { TextareaField, TextareaFieldProps } from "@/components";

export function SubsEditor(props: Props) {
  props = { ...props };

  props.className = `${props.className} Commission`;

  function onChange(value: string) {
    const next = new Subs({ value });

    props.onChange?.(next);
  }

  return (
    <TextareaField
      {...props}
      value={props.value?.value}
      onChange={onChange}
      placeholder=""
      dataTest={`${props.dataTest}.value`}
    />
  );
}

interface Props extends Omit<TextareaFieldProps, "value" | "onChange"> {
  value?: Subs;
  onChange?: (value: Subs) => void;
}

export type SubsEditorProps = Props;
