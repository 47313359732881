import { Component } from "react";
import { IOfferRepNegotiableItemsProps } from "./INegotiableItemProps";
import { NegotiableItem, OwnerOnlyItem } from "./NegotiableItem";
import { UnitValueEditor } from "sharedFolder/components/editors/UnitValueEditor/UnitValueEditor";
import {
  CommissionDisplay,
  LaycanDisplay,
  UnitValueDisplay,
  CargoTypeDisplay,
  CargoSizeDisplay,
  LocationDisplay,
  LaycanHasValue,
  CargoTypeHasValue,
  CargoSizeHasValue,
  CommissionHasValue,
  UnitValueHasValue,
  LocationHasValue,
  VesselSizeDisplay,
  DurationDisplay,
  LiftingsHasValue,
  LiftingsDisplay,
  NominationsDisplay,
  NominationsHasValue,
  COACargoSizeDisplay,
  COACargoSizeHasValue,
  SubsTextHasValue,
  SubsTextDisplay,
  BunkerHasValue,
} from "../../../DetailsView/DetailsViewOfferRep";
import {
  IUnitValueView,
  ICommissionView,
  ICargoSizeView,
  IVesselSizeView,
  IDurationView,
  ILiftingsView,
  INominationsView,
  ICOACargoSizeView,
  ILaycanView,
  ICargoTypeView,
} from "sharedFolder/Models/IDetails";
import { IOfferState } from "../IOfferState";
import { CommissionEditor } from "negotiations/components/common/Editors/Commission/Commission";
import { IndicationOrFirmResponse } from "./IndicationOrFirmResponse";
import { CargoTypeEditor } from "sharedFolder/components/editors/CargoTypeEditor/CargoTypeEditor";
import { ILocationView } from "sharedFolder/Models/ILocation";
import { editorValueResolverFunctions } from "negotiations/components/negotiate/Negotiation/NegotiableDetails/editorValueResolverFunctions";
import AccountInput from "negotiations/components/common/Editors/AccountInput/AccountInput";
import { IAccountSearchResult } from "negotiations/models/common/IAccount";
import { FreightRateOptions } from "sharedFolder/Models/IFreightRate";
import {
  DemurrageOptions,
  BallastBonusOptions,
  HireRateOptions,
  CleaningPriceOptions,
  SupplyPriceOptions,
} from "../../../../api/negotiations/models";
import { MinimalConfigurationContext } from "__legacy/sharedFolder/MinimalConfigurationContext";
import { INegotiationView } from "sharedFolder/Models/Negotiation/INegotiationView";
import { DurationEditor } from "sharedFolder/components/editors/DurationEditor/DurationEditor";
import { NewLiftingsEditor } from "sharedFolder/components/editors/NewLiftingsEditor/NewLiftingsEditor";
import { NewNominationsEditor } from "sharedFolder/components/editors/NewNominationsEditor/NewNominationsEditor";
import { COACargoSizeEditor } from "sharedFolder/components/editors/COACargoSizeEditor/COACargoSizeEditor";
import { LaycanBaseEditor } from "sharedFolder/components/editors/LaycanBaseEditor/LaycanBaseEditor";
import { LocationEditor } from "sharedFolder/components/editors/LocationEditor/LocationEditor";
import { VesselSizeEditor } from "sharedFolder/components/editors/VesselSizeEditor/VesselSizeEditor";
import { NewCargoSizeEditorWithValidContext } from "sharedFolder/components/editors/NewCargoSizeEditor/NewCargoSizeEditor";
import { NewUnitValueEditorWithValidContext } from "sharedFolder/components/editors/NewUnitValueEditor/NewUnitValueEditor";
import SubsTextEditor from "sharedFolder/components/editors/SubsTextEditor/SubsTextEditor";
import { IOfferRepViewNegotiation } from "negotiations/models/common/IOfferRep";
import { BunkerDisplay } from "negotiations/components/common/DetailsView/DetailsView";
import { BunkerDeliveryEditor, BunkerRedeliveryEditor } from "@/components";

const getOffer = (negotiation: INegotiationView, offer?: IOfferState) => {
  if (!negotiation.details)
    return {
      version: negotiation.version,
    };

  return {
    ownerAccount: offer?.ownerAccount ||
      negotiation.owningCompany || {
        accountId: "",
        accountName: "",
        arcContactId: "",
        display: "",
        gainAccountGroupId: "",
        gainAccountId: "",
        isLegalEntity: undefined,
        shortDisplay: "",
      },
    freightRate: offer?.freightRate || negotiation.details?.freightRate?.offer,
    duration: offer?.duration || negotiation.details?.duration?.offer,
    laycan: offer?.laycan || negotiation.details.laycan.offer,
    liftings: offer?.liftings || negotiation.details?.liftings?.offer,
    nominations: offer?.nominations || negotiation.details?.nominations?.offer,
    period: offer?.period || negotiation.details?.period?.offer,
    ballastBonus: offer?.ballastBonus || negotiation.details?.ballastBonus?.offer,
    hireRate: offer?.hireRate || negotiation.details?.hireRate?.offer,
    cleaningPrice: offer?.cleaningPrice || negotiation.details?.cleaningPrice?.offer,
    supplyPrice: offer?.supplyPrice || negotiation.details?.supplyPrice?.offer,
    tradingExclusions: offer?.tradingExclusions || negotiation.details?.tradingExclusions?.offer,
    bunkerDelivery: offer?.bunkerDelivery || negotiation.details?.bunkerDelivery?.offer,
    bunkerRedelivery: offer?.bunkerRedelivery || negotiation.details?.bunkerRedelivery?.offer,
    cargoExclusionsText: offer?.cargoExclusionsText || negotiation.details?.cargoExclusionsText?.offer,
    coaCargoSize: offer?.coaCargoSize || negotiation.details?.coaCargoSize?.offer,
    demurrage: offer?.demurrage || negotiation.details?.demurrage?.offer,
    brokerCommission: offer?.brokerCommission || negotiation.details.brokerCommission.offer,
    addressCommission: offer?.addressCommission || negotiation.details.addressCommission.offer,
    dischargeLocation: offer?.dischargeLocation || negotiation.details?.dischargeLocation?.offer,
    deliveryLocation: offer?.deliveryLocation || negotiation.details?.deliveryLocation?.offer,
    viaLocation: offer?.viaLocation || negotiation.details?.viaLocation?.offer,
    redeliveryLocation: offer?.redeliveryLocation || negotiation.details?.redeliveryLocation?.offer,
    cargoSize: offer?.cargoSize || negotiation.details?.cargoSize?.offer,
    cargoType: offer?.cargoType || negotiation.details.cargoType.offer,
    loadLocation: offer?.loadLocation || negotiation.details?.loadLocation?.offer,
    version: negotiation.version,
    commercialSubs: offer?.commercialSubs || negotiation.details?.commercialSubs?.offer,
    operationalSubs: offer?.operationalSubs || negotiation.details?.operationalSubs?.offer,
  };
};

export class OfferRepNegotiableItems extends Component<
  IOfferRepNegotiableItemsProps<INegotiationView, IOfferState>,
  IOfferState
> {
  constructor(props: IOfferRepNegotiableItemsProps<INegotiationView, IOfferState>) {
    super(props);
    this.state = getOffer(this.props.currentOwnerNegotiation, this.props.offer);
  }
  static getDerivedStateFromProps(props: IOfferRepNegotiableItemsProps<INegotiationView, IOfferState>, state: IOfferState) {
    if (props.currentOwnerNegotiation.version !== state.version) return getOffer(props.currentOwnerNegotiation, props.offer);
    return null;
  }

  public render() {
    if (
      !(
        this.props.currentOwnerNegotiation &&
        this.props.currentOwnerNegotiation.details &&
        this.props.chartererNegotiation &&
        this.props.chartererNegotiation.details
      )
    )
      return null;

    const {
      freightRate: currentOwnerFreightRate,
      laycan: currentOwnerLaycan,
      period: currentOwnerPeriod,
      coaCargoSize: currentOwnerCoaCargoSize,
      nominations: currentOwnerNominations,
      liftings: currentOwnerLiftings,
      demurrage: currentOwnerDemurrage,
      brokerCommission: currentOwnerBrokerCommission,
      addressCommission: currentOwnerAddressCommission,
      dischargeLocation: currentOwnerDischargeLocation,
      loadLocation: currentOwnerLoadLocation,
      cargoSize: currentOwnerCargoSize,
      cargoType: currentOwnerCargoType,
      vesselSize: currentOwnerVesselSize,
      duration: currentOwnerDuration,
      hireRate: currentOwnerHireRate,
      cleaningPrice: currentOwnerCleaningPrice,
      supplyPrice: currentOwnerSupplyPrice,
      tradingExclusions: currentOwnerTradingExclusions,
      bunkerDelivery: currentOwnerBunkerDelivery,
      bunkerRedelivery: currentOwnerBunkerRedelivery,
      cargoExclusionsText: currentOwnerCargoExclusionsText,
      ballastBonus: currentOwnerBallastBonus,
      deliveryLocation: currentOwnerDeliveryLocation,
      viaLocation: currentOwnerViaLocation,
      redeliveryLocation: currentOwnerRedeliveryLocation,
      commercialSubs: currentOwnerCommercialSubs,
      operationalSubs: currentOwnerOperationalSubs,
    } = this.props.currentOwnerNegotiation.details;

    const {
      freightRate: chartererFreightRate,
      laycan: chartererLaycan,
      period: chartererPeriod,
      coaCargoSize: chartererCoaCargoSize,
      nominations: chartererNominations,
      liftings: chartererLiftings,
      demurrage: chartererDemurrage,
      brokerCommission: chartererBrokerCommission,
      addressCommission: chartererAddressCommission,
      dischargeLocation: chartererDischargeLocation,
      loadLocation: chartererLoadLocation,
      cargoSize: chartererCargoSize,
      cargoType: chartererCargoType,
      vesselSize: chartererVesselSize,
      duration: chartererDuration,
      hireRate: chartererHireRate,
      cleaningPrice: chartererCleaningPrice,
      supplyPrice: chartererSupplyPrice,
      tradingExclusions: chartererTradingExclusions,
      bunkerDelivery: chartererBunkerDelivery,
      bunkerRedelivery: chartererBunkerRedelivery,
      cargoExclusionsText: chartererCargoExclusionsText,
      ballastBonus: chartererBallastBonus,
      deliveryLocation: chartererDeliveryLocation,
      viaLocation: chartererViaLocation,
      redeliveryLocation: chartererRedeliveryLocation,
      commercialSubs: chartererCommercialSubs,
      operationalSubs: chartererOperationalSubs,
    } = this.props.chartererNegotiation.details;

    const { type } = this.props.chartererNegotiation;
    const circulatedByRole = (this.props.chartererNegotiation as IOfferRepViewNegotiation).circulatedByRole;

    return (
      <MinimalConfigurationContext.Consumer>
        {(config) => (
          <div>
            <IndicationOrFirmResponse
              onFirmOrIndicationSelected={this.props.onFirmOrIndicationSelected}
              negotiationUIStatus={this.props.negotiationUIStatus}
            />
            {this.state.ownerAccount && (
              <OwnerOnlyItem typeTitle="Owning Group" label="Please identify the owner you represent">
                <AccountInput
                  dataTest={"owner-account"}
                  onChange={(value: IAccountSearchResult) => {
                    this.setState(
                      {
                        ownerAccount: editorValueResolverFunctions["ownerAccount"].to(value),
                      },
                      () => this.props.onChange(this.state, "ownerAccount")
                    );
                  }}
                  value={{
                    account: editorValueResolverFunctions.ownerAccount.from(this.state.ownerAccount),
                  }}
                  disabled={this.props.disabled}
                />
              </OwnerOnlyItem>
            )}

            {currentOwnerVesselSize &&
              currentOwnerVesselSize.negotiable &&
              currentOwnerVesselSize.included &&
              this.state.vesselSize && (
                <NegotiableItem
                  typeTitle="Vessel Size"
                  lastUpdated={currentOwnerVesselSize.lastUpdated}
                  circulatedByRole={circulatedByRole}
                  displayControl={<VesselSizeDisplay item={currentOwnerVesselSize.bid} />}
                  currentValue={chartererVesselSize && <VesselSizeDisplay item={chartererVesselSize.offer} />}
                >
                  <VesselSizeEditor
                    vesselSize={this.state.vesselSize}
                    onChange={(value: IVesselSizeView) => {
                      if (value)
                        this.setState(
                          {
                            vesselSize: value,
                          },
                          () => this.props.onChange(this.state, "vesselSize")
                        );
                    }}
                    disabled={this.props.disabled}
                  />
                </NegotiableItem>
              )}

            {currentOwnerDuration && currentOwnerDuration.negotiable && currentOwnerDuration.included && (
              <NegotiableItem
                typeTitle="Duration"
                lastUpdated={currentOwnerDuration.lastUpdated}
                circulatedByRole={circulatedByRole}
                displayControl={<DurationDisplay item={currentOwnerDuration.bid} />}
                currentValue={chartererDuration && <DurationDisplay item={chartererDuration.offer} />}
              >
                <DurationEditor
                  value={this.state.duration}
                  onChange={(value: IDurationView) => {
                    if (value)
                      this.setState(
                        {
                          duration: value,
                        },
                        () => this.props.onChange(this.state, "duration")
                      );
                  }}
                  disabled={this.props.disabled}
                />
              </NegotiableItem>
            )}

            {currentOwnerCargoType.negotiable && currentOwnerCargoType.included && (
              <NegotiableItem
                typeTitle={"Cargo Type(s)"}
                lastUpdated={currentOwnerCargoType.lastUpdated}
                circulatedByRole={circulatedByRole}
                displayControl={<CargoTypeDisplay item={currentOwnerCargoType.bid} />}
                currentValue={
                  CargoTypeHasValue(chartererCargoType.offer) ? <CargoTypeDisplay item={chartererCargoType.offer} /> : undefined
                }
              >
                <CargoTypeEditor
                  id="cargoType"
                  onChange={(cargoType?: ICargoTypeView) => {
                    this.setState(
                      {
                        cargoType,
                      },
                      () => this.props.onChange(this.state, "cargoType")
                    );
                  }}
                  selectedItem={
                    this.state.cargoType?.name ? editorValueResolverFunctions["cargoType"].from(this.state.cargoType) : undefined
                  }
                  disabled={this.props.disabled}
                />
              </NegotiableItem>
            )}

            {currentOwnerCargoSize && currentOwnerCargoSize.negotiable && currentOwnerCargoSize.included && this.state.cargoSize && (
              <NegotiableItem
                typeTitle="Cargo Size(s)"
                lastUpdated={currentOwnerCargoSize.lastUpdated}
                circulatedByRole={circulatedByRole}
                displayControl={<CargoSizeDisplay item={currentOwnerCargoSize.bid} />}
                currentValue={
                  chartererCargoSize && CargoSizeHasValue(chartererCargoSize.offer) ? (
                    <CargoSizeDisplay item={chartererCargoSize.offer} />
                  ) : undefined
                }
              >
                <NewCargoSizeEditorWithValidContext
                  defaultOption={"MOLOO"}
                  label="Cargo Size(s)"
                  onChange={(value: ICargoSizeView) => {
                    if (value)
                      this.setState({ cargoSize: { ...value, unit: "MT" } }, () => this.props.onChange(this.state, "cargoSize"));
                  }}
                  value={this.state.cargoSize}
                  disabled={this.props.disabled}
                  withNotes
                />
              </NegotiableItem>
            )}

            {currentOwnerLoadLocation && currentOwnerLoadLocation.negotiable && currentOwnerLoadLocation.included && (
              <NegotiableItem
                typeTitle={`${type === "COA" ? "Load Location(s)" : "Load Location"}`}
                lastUpdated={currentOwnerLoadLocation.lastUpdated}
                circulatedByRole={circulatedByRole}
                displayControl={<LocationDisplay item={currentOwnerLoadLocation.bid} />}
                currentValue={
                  chartererLoadLocation && LocationHasValue(chartererLoadLocation.offer) ? (
                    <LocationDisplay item={chartererLoadLocation.offer} />
                  ) : undefined
                }
              >
                <LocationEditor
                  ctradeUrl={config.ctradeUrl}
                  id="LoadLocation"
                  value={editorValueResolverFunctions["loadLocation"].from(this.state.loadLocation)}
                  onChange={(value?: ILocationView) => {
                    this.setState(
                      {
                        loadLocation: editorValueResolverFunctions["loadLocation"].to(value),
                      },
                      () => this.props.onChange(this.state, "loadLocation")
                    );
                  }}
                  disabled={this.props.disabled}
                />
              </NegotiableItem>
            )}

            {currentOwnerDischargeLocation && currentOwnerDischargeLocation.negotiable && currentOwnerDischargeLocation.included && (
              <NegotiableItem
                typeTitle={`${type === "COA" ? "Discharge Location(s)" : "Discharge Location"}`}
                lastUpdated={currentOwnerDischargeLocation.lastUpdated}
                circulatedByRole={circulatedByRole}
                displayControl={<LocationDisplay item={currentOwnerDischargeLocation.bid} />}
                currentValue={
                  chartererDischargeLocation && LocationHasValue(chartererDischargeLocation.offer) ? (
                    <LocationDisplay item={chartererDischargeLocation.offer} />
                  ) : undefined
                }
              >
                <LocationEditor
                  ctradeUrl={config.ctradeUrl}
                  id="dischargeLocation"
                  value={editorValueResolverFunctions["dischargeLocation"].from(this.state.dischargeLocation)}
                  onChange={(value?: ILocationView) => {
                    this.setState(
                      {
                        dischargeLocation: editorValueResolverFunctions["dischargeLocation"].to(value),
                      },
                      () => this.props.onChange(this.state, "dischargeLocation")
                    );
                  }}
                  disabled={this.props.disabled}
                />
              </NegotiableItem>
            )}

            {currentOwnerDeliveryLocation && currentOwnerDeliveryLocation.negotiable && currentOwnerDeliveryLocation.included && (
              <NegotiableItem
                typeTitle="Delivery Location"
                lastUpdated={currentOwnerDeliveryLocation.lastUpdated}
                circulatedByRole={circulatedByRole}
                displayControl={<LocationDisplay item={currentOwnerDeliveryLocation.bid} />}
                currentValue={
                  chartererDeliveryLocation && LocationHasValue(chartererDeliveryLocation.offer) ? (
                    <LocationDisplay item={chartererDeliveryLocation.offer} />
                  ) : undefined
                }
              >
                <LocationEditor
                  ctradeUrl={config.ctradeUrl}
                  id="deliveryLocation"
                  value={editorValueResolverFunctions.deliveryLocation.from(this.state.deliveryLocation)}
                  onChange={(value?: ILocationView) => {
                    this.setState(
                      {
                        deliveryLocation: editorValueResolverFunctions.deliveryLocation.to(value),
                      },
                      () => this.props.onChange(this.state, "deliveryLocation")
                    );
                  }}
                  disabled={this.props.disabled}
                  hideLocationSafetyWarranty={true}
                />
              </NegotiableItem>
            )}

            {currentOwnerViaLocation && currentOwnerViaLocation.negotiable && currentOwnerViaLocation.included && (
              <NegotiableItem
                typeTitle="Via Location"
                lastUpdated={currentOwnerViaLocation.lastUpdated}
                circulatedByRole={circulatedByRole}
                displayControl={<LocationDisplay item={currentOwnerViaLocation.bid} />}
                currentValue={
                  chartererViaLocation && LocationHasValue(chartererViaLocation.offer) ? (
                    <LocationDisplay item={chartererViaLocation.offer} />
                  ) : undefined
                }
              >
                <LocationEditor
                  ctradeUrl={config.ctradeUrl}
                  id="viaLocation"
                  value={editorValueResolverFunctions.viaLocation.from(this.state.viaLocation)}
                  onChange={(value?: ILocationView) => {
                    this.setState(
                      {
                        viaLocation: editorValueResolverFunctions.viaLocation.to(value),
                      },
                      () => this.props.onChange(this.state, "viaLocation")
                    );
                  }}
                  disabled={this.props.disabled}
                  hideLocationSafetyWarranty={true}
                />
              </NegotiableItem>
            )}

            {currentOwnerRedeliveryLocation &&
              currentOwnerRedeliveryLocation.negotiable &&
              currentOwnerRedeliveryLocation.included && (
                <NegotiableItem
                  typeTitle="Redelivery Location"
                  lastUpdated={currentOwnerRedeliveryLocation.lastUpdated}
                  circulatedByRole={circulatedByRole}
                  displayControl={<LocationDisplay item={currentOwnerRedeliveryLocation.bid} />}
                  currentValue={
                    chartererRedeliveryLocation && LocationHasValue(chartererRedeliveryLocation.offer) ? (
                      <LocationDisplay item={chartererRedeliveryLocation.offer} />
                    ) : undefined
                  }
                >
                  <LocationEditor
                    ctradeUrl={config.ctradeUrl}
                    id="redeliveryLocation"
                    value={editorValueResolverFunctions.redeliveryLocation.from(this.state.redeliveryLocation)}
                    onChange={(value?: ILocationView) => {
                      this.setState(
                        {
                          redeliveryLocation: editorValueResolverFunctions.redeliveryLocation.to(value),
                        },
                        () => this.props.onChange(this.state, "redeliveryLocation")
                      );
                    }}
                    disabled={this.props.disabled}
                    hideLocationSafetyWarranty={true}
                  />
                </NegotiableItem>
              )}
            {currentOwnerLaycan.negotiable && currentOwnerLaycan.included && (
              <NegotiableItem
                typeTitle="Laycan"
                lastUpdated={currentOwnerLaycan.lastUpdated}
                circulatedByRole={circulatedByRole}
                displayControl={<LaycanDisplay item={currentOwnerLaycan.bid} />}
                currentValue={LaycanHasValue(chartererLaycan.offer) ? <LaycanDisplay item={chartererLaycan.offer} /> : undefined}
              >
                <LaycanBaseEditor
                  id="laycan"
                  required={false}
                  url={config.ctradeUrl}
                  value={editorValueResolverFunctions["laycan"].from(this.state.laycan)}
                  onChange={(value?: ILaycanView) => {
                    this.setState(
                      {
                        laycan: editorValueResolverFunctions["laycan"].to(value),
                      },
                      () => this.props.onChange(this.state, "laycan")
                    );
                  }}
                  disabled={this.props.disabled}
                />
              </NegotiableItem>
            )}
            {currentOwnerPeriod?.negotiable && currentOwnerPeriod?.included && (
              <NegotiableItem
                typeTitle="Period"
                lastUpdated={currentOwnerPeriod.lastUpdated}
                circulatedByRole={circulatedByRole}
                displayControl={<LaycanDisplay item={currentOwnerPeriod.bid} />}
                currentValue={
                  chartererPeriod && LaycanHasValue(chartererPeriod.offer) ? (
                    <LaycanDisplay item={chartererPeriod.offer} />
                  ) : undefined
                }
              >
                <LaycanBaseEditor
                  id="period"
                  required={false}
                  url={config.ctradeUrl}
                  value={editorValueResolverFunctions["period"].from(this.state.period)}
                  onChange={(value?: ILaycanView) => {
                    this.setState(
                      {
                        period: editorValueResolverFunctions["period"].to(value),
                      },
                      () => this.props.onChange(this.state, "period")
                    );
                  }}
                  disabled={this.props.disabled}
                />
              </NegotiableItem>
            )}
            {currentOwnerCoaCargoSize?.negotiable && currentOwnerCoaCargoSize?.included && (
              <NegotiableItem
                typeTitle="Cargo Size(s)"
                lastUpdated={currentOwnerCoaCargoSize.lastUpdated}
                circulatedByRole={circulatedByRole}
                displayControl={<COACargoSizeDisplay item={currentOwnerCoaCargoSize.bid} />}
                currentValue={
                  chartererCoaCargoSize && COACargoSizeHasValue(chartererCoaCargoSize.offer) ? (
                    <COACargoSizeDisplay item={currentOwnerCoaCargoSize.offer} />
                  ) : undefined
                }
              >
                <COACargoSizeEditor
                  id="coaCargoSize"
                  focus={true}
                  value={editorValueResolverFunctions["coaCargoSize"].to(this.state.coaCargoSize)}
                  onChange={(value: ICOACargoSizeView | undefined) => {
                    this.setState(
                      {
                        coaCargoSize: editorValueResolverFunctions["coaCargoSize"].from(value),
                      },
                      () => this.props.onChange(this.state, "coaCargoSize")
                    );
                  }}
                />
              </NegotiableItem>
            )}
            {/* */}
            {currentOwnerLiftings?.negotiable && currentOwnerLiftings?.included && (
              <NegotiableItem
                typeTitle="Liftings"
                lastUpdated={currentOwnerLiftings.lastUpdated}
                circulatedByRole={circulatedByRole}
                displayControl={<LiftingsDisplay item={currentOwnerLiftings.bid} />}
                currentValue={
                  chartererLiftings && LiftingsHasValue(chartererLiftings.offer) ? (
                    <LiftingsDisplay item={chartererLiftings.offer} />
                  ) : undefined
                }
              >
                <NewLiftingsEditor
                  value={editorValueResolverFunctions["liftings"].to(this.state.liftings)}
                  onChange={(value: ILiftingsView | undefined) => {
                    this.setState(
                      {
                        liftings: editorValueResolverFunctions["liftings"].from(value),
                      },
                      () => this.props.onChange(this.state, "liftings")
                    );
                  }}
                  disabled={this.props.disabled}
                />
              </NegotiableItem>
            )}
            {currentOwnerNominations?.negotiable && currentOwnerNominations?.included && (
              <NegotiableItem
                typeTitle="Nominations"
                lastUpdated={currentOwnerNominations.lastUpdated}
                circulatedByRole={circulatedByRole}
                displayControl={<NominationsDisplay item={currentOwnerNominations.bid} />}
                currentValue={
                  chartererNominations && NominationsHasValue(chartererNominations.offer) ? (
                    <NominationsDisplay item={chartererNominations.offer} />
                  ) : undefined
                }
              >
                <NewNominationsEditor
                  value={editorValueResolverFunctions["nominations"].from(this.state.nominations)}
                  onChange={(value: INominationsView | undefined) => {
                    this.setState(
                      {
                        nominations: editorValueResolverFunctions["nominations"].to(value),
                      },
                      () => this.props.onChange(this.state, "nominations")
                    );
                  }}
                  disabled={this.props.disabled}
                />
              </NegotiableItem>
            )}

            {currentOwnerAddressCommission.negotiable && currentOwnerAddressCommission.included && this.state.addressCommission && (
              <NegotiableItem
                typeTitle="Address Commission"
                lastUpdated={currentOwnerAddressCommission.lastUpdated}
                circulatedByRole={circulatedByRole}
                displayControl={<CommissionDisplay item={currentOwnerAddressCommission.bid} />}
                currentValue={
                  CommissionHasValue(chartererAddressCommission.offer) ? (
                    <CommissionDisplay item={chartererAddressCommission.offer} />
                  ) : undefined
                }
              >
                <CommissionEditor
                  id="addressComission"
                  onChange={(value: ICommissionView) => {
                    if (value)
                      this.setState({ addressCommission: value }, () => this.props.onChange(this.state, "addressCommission"));
                  }}
                  value={this.state.addressCommission.value}
                  disabled={this.props.disabled}
                />
              </NegotiableItem>
            )}

            {currentOwnerBrokerCommission.negotiable && currentOwnerBrokerCommission.included && this.state.brokerCommission && (
              <NegotiableItem
                typeTitle="Broker Commission"
                lastUpdated={currentOwnerBrokerCommission.lastUpdated}
                circulatedByRole={circulatedByRole}
                displayControl={<CommissionDisplay item={currentOwnerBrokerCommission.bid} />}
                currentValue={
                  CommissionHasValue(chartererBrokerCommission.offer) ? (
                    <CommissionDisplay item={chartererBrokerCommission.offer} />
                  ) : undefined
                }
              >
                <CommissionEditor
                  id="brokerComission"
                  onChange={(value: ICommissionView) => {
                    if (value)
                      this.setState({ brokerCommission: value }, () => this.props.onChange(this.state, "brokerCommission"));
                  }}
                  value={this.state.brokerCommission.value}
                  disabled={this.props.disabled}
                />
              </NegotiableItem>
            )}

            {currentOwnerFreightRate &&
              currentOwnerFreightRate.negotiable &&
              currentOwnerFreightRate.included &&
              this.state.freightRate && (
                <NegotiableItem
                  typeTitle="Freight Rate"
                  lastUpdated={currentOwnerFreightRate.lastUpdated}
                  circulatedByRole={circulatedByRole}
                  displayControl={<UnitValueDisplay item={currentOwnerFreightRate.bid} />}
                  currentValue={
                    chartererFreightRate && UnitValueHasValue(chartererFreightRate.offer) ? (
                      <UnitValueDisplay item={chartererFreightRate.offer} />
                    ) : undefined
                  }
                >
                  <NewUnitValueEditorWithValidContext
                    displayNotes
                    dataTest="freight-rate-notes"
                    id="freightRate"
                    type="freightRate"
                    notesId={"freight-rate-notes"}
                    notesPlaceholderText={"Additional freight rate notes"}
                    onChange={(value: IUnitValueView | undefined) => {
                      if (value) this.setState({ freightRate: value }, () => this.props.onChange(this.state, "freightRate"));
                    }}
                    defaultOption={"PerMT"}
                    options={FreightRateOptions}
                    value={this.state.freightRate}
                    disabled={this.props.disabled}
                  />
                </NegotiableItem>
              )}

            {currentOwnerDemurrage && currentOwnerDemurrage.negotiable && currentOwnerDemurrage.included && this.state.demurrage && (
              <NegotiableItem
                typeTitle="Demurrage"
                lastUpdated={currentOwnerDemurrage.lastUpdated}
                circulatedByRole={circulatedByRole}
                displayControl={<UnitValueDisplay item={currentOwnerDemurrage.bid} />}
                currentValue={
                  chartererDemurrage && UnitValueHasValue(chartererDemurrage.offer) ? (
                    <UnitValueDisplay item={chartererDemurrage.offer} />
                  ) : undefined
                }
              >
                <NewUnitValueEditorWithValidContext
                  displayNotes
                  dataTest="demurrage-notes"
                  id="demurrage"
                  type="demurrage"
                  notesId={"demurrage-notes"}
                  notesPlaceholderText={"Additional demurrage notes"}
                  onChange={(value: IUnitValueView | undefined) => {
                    if (value) this.setState({ demurrage: value }, () => this.props.onChange(this.state, "demurrage"));
                  }}
                  defaultOption={"PerDay"}
                  options={DemurrageOptions}
                  value={this.state.demurrage}
                  disabled={this.props.disabled}
                />
              </NegotiableItem>
            )}

            {currentOwnerHireRate && currentOwnerHireRate.negotiable && currentOwnerHireRate.included && this.state.hireRate && (
              <NegotiableItem
                typeTitle="Hire Rate"
                lastUpdated={currentOwnerHireRate.lastUpdated}
                circulatedByRole={circulatedByRole}
                displayControl={<UnitValueDisplay item={currentOwnerHireRate.bid} />}
                currentValue={
                  chartererHireRate && UnitValueHasValue(chartererHireRate.offer) ? (
                    <UnitValueDisplay item={chartererHireRate.offer} />
                  ) : undefined
                }
              >
                <UnitValueEditor
                  id="hireRate"
                  type="hireRate"
                  options={HireRateOptions}
                  defaultOption={"PerDay"}
                  displayNotes={true}
                  notesPlaceholderText={"Additional hire rate notes"}
                  notesId={"hirerate_notes"}
                  onChange={(value) => {
                    if (value) this.setState({ hireRate: value }, () => this.props.onChange(this.state, "hireRate"));
                  }}
                  value={this.state.hireRate}
                  disabled={this.props.disabled}
                />
              </NegotiableItem>
            )}

            {currentOwnerCleaningPrice &&
              currentOwnerCleaningPrice.negotiable &&
              currentOwnerCleaningPrice.included &&
              this.state.cleaningPrice && (
                <NegotiableItem
                  typeTitle="ILOHC"
                  lastUpdated={currentOwnerCleaningPrice.lastUpdated}
                  circulatedByRole={circulatedByRole}
                  displayControl={<UnitValueDisplay item={currentOwnerCleaningPrice.bid} />}
                  currentValue={
                    chartererCleaningPrice && UnitValueHasValue(chartererCleaningPrice.offer) ? (
                      <UnitValueDisplay item={chartererCleaningPrice.offer} />
                    ) : undefined
                  }
                >
                  <UnitValueEditor
                    id="cleaningPrice"
                    type="cleaningPrice"
                    options={CleaningPriceOptions}
                    defaultOption={"PerDay"}
                    displayNotes={true}
                    notesPlaceholderText={"Additional ILOHC notes"}
                    notesId={"cleaningPrice_notes"}
                    onChange={(value) => {
                      if (value) this.setState({ cleaningPrice: value }, () => this.props.onChange(this.state, "cleaningPrice"));
                    }}
                    value={this.state.cleaningPrice}
                    disabled={this.props.disabled}
                  />
                </NegotiableItem>
              )}
            {currentOwnerSupplyPrice &&
              currentOwnerSupplyPrice.negotiable &&
              currentOwnerSupplyPrice.included &&
              this.state.supplyPrice && (
                <NegotiableItem
                  typeTitle="CVE"
                  lastUpdated={currentOwnerSupplyPrice.lastUpdated}
                  circulatedByRole={circulatedByRole}
                  displayControl={<UnitValueDisplay item={currentOwnerSupplyPrice.bid} />}
                  currentValue={
                    chartererSupplyPrice && UnitValueHasValue(chartererSupplyPrice.offer) ? (
                      <UnitValueDisplay item={chartererSupplyPrice.offer} />
                    ) : undefined
                  }
                >
                  <UnitValueEditor
                    id="supplyPrice"
                    type="supplyPrice"
                    options={SupplyPriceOptions}
                    defaultOption={"PerDay"}
                    displayNotes={true}
                    notesPlaceholderText={"Additional ILOHC notes"}
                    notesId={"supplyPrice_notes"}
                    onChange={(value) => {
                      if (value) this.setState({ supplyPrice: value }, () => this.props.onChange(this.state, "supplyPrice"));
                    }}
                    value={this.state.supplyPrice}
                    disabled={this.props.disabled}
                  />
                </NegotiableItem>
              )}

            {currentOwnerTradingExclusions &&
              currentOwnerTradingExclusions.negotiable &&
              currentOwnerTradingExclusions.included &&
              this.state.tradingExclusions && (
                <NegotiableItem
                  typeTitle="Trading Exclusions"
                  lastUpdated={currentOwnerTradingExclusions.lastUpdated}
                  displayControl={<SubsTextDisplay item={currentOwnerTradingExclusions.bid} />}
                  currentValue={
                    chartererTradingExclusions && SubsTextHasValue(chartererTradingExclusions.offer) ? (
                      <SubsTextDisplay item={chartererTradingExclusions.offer} />
                    ) : undefined
                  }
                >
                  <SubsTextEditor
                    id="tradingExclusions"
                    onChange={(value) => {
                      if (value)
                        this.setState({ tradingExclusions: value }, () => this.props.onChange(this.state, "tradingExclusions"));
                    }}
                    value={this.state.tradingExclusions}
                    disabled={this.props.disabled}
                  />
                </NegotiableItem>
              )}
            {currentOwnerBunkerDelivery &&
              currentOwnerBunkerDelivery.negotiable &&
              currentOwnerBunkerDelivery.included &&
              this.state.bunkerDelivery && (
                <NegotiableItem
                  typeTitle="Bunker Delivery"
                  lastUpdated={currentOwnerBunkerDelivery.lastUpdated}
                  displayControl={<BunkerDisplay item={currentOwnerBunkerDelivery.bid} />}
                  currentValue={
                    chartererBunkerDelivery && BunkerHasValue(chartererBunkerDelivery.offer) ? (
                      <BunkerDisplay item={chartererBunkerDelivery.offer} />
                    ) : undefined
                  }
                >
                  <BunkerDeliveryEditor
                    onChange={(value) => {
                      if (value)
                        this.setState({ bunkerDelivery: value }, () => this.props.onChange(this.state, "bunkerDelivery"));
                    }}
                    value={this.state.bunkerDelivery}
                  />
                </NegotiableItem>
              )}
            {currentOwnerBunkerRedelivery &&
              currentOwnerBunkerRedelivery.negotiable &&
              currentOwnerBunkerRedelivery.included &&
              this.state.bunkerRedelivery && (
                <NegotiableItem
                  typeTitle="Bunker Re-Delivery"
                  lastUpdated={currentOwnerBunkerRedelivery.lastUpdated}
                  displayControl={<BunkerDisplay item={currentOwnerBunkerRedelivery.bid} />}
                  currentValue={
                    chartererBunkerRedelivery && BunkerHasValue(chartererBunkerRedelivery.offer) ? (
                      <BunkerDisplay item={chartererBunkerRedelivery.offer} />
                    ) : undefined
                  }
                >
                  <BunkerRedeliveryEditor
                    onChange={(value) => {
                      if (value)
                        this.setState({ bunkerRedelivery: value }, () => this.props.onChange(this.state, "bunkerRedelivery"));
                    }}
                    value={this.state.bunkerRedelivery}
                  />
                </NegotiableItem>
              )}
            {currentOwnerCargoExclusionsText &&
              currentOwnerCargoExclusionsText.negotiable &&
              currentOwnerCargoExclusionsText.included &&
              this.state.cargoExclusionsText && (
                <NegotiableItem
                  typeTitle="Cargo Exclusions"
                  lastUpdated={currentOwnerCargoExclusionsText.lastUpdated}
                  displayControl={<SubsTextDisplay item={currentOwnerCargoExclusionsText.bid} />}
                  currentValue={
                    chartererCargoExclusionsText && SubsTextHasValue(chartererCargoExclusionsText.offer) ? (
                      <SubsTextDisplay item={chartererCargoExclusionsText.offer} />
                    ) : undefined
                  }
                >
                  <SubsTextEditor
                    id="cargoExclusionsText"
                    onChange={(value) => {
                      if (value)
                        this.setState({ cargoExclusionsText: value }, () =>
                          this.props.onChange(this.state, "cargoExclusionsText")
                        );
                    }}
                    value={this.state.cargoExclusionsText}
                    disabled={this.props.disabled}
                  />
                </NegotiableItem>
              )}
            {currentOwnerBallastBonus &&
              currentOwnerBallastBonus.negotiable &&
              currentOwnerBallastBonus.included &&
              this.state.ballastBonus && (
                <NegotiableItem
                  typeTitle="Ballast Bonus"
                  lastUpdated={currentOwnerBallastBonus.lastUpdated}
                  circulatedByRole={circulatedByRole}
                  displayControl={<UnitValueDisplay item={currentOwnerBallastBonus.bid} />}
                  currentValue={
                    chartererBallastBonus && UnitValueHasValue(chartererBallastBonus.offer) ? (
                      <UnitValueDisplay item={chartererBallastBonus.offer} />
                    ) : undefined
                  }
                >
                  <UnitValueEditor
                    id="ballastBonus"
                    type="ballastBonus"
                    options={BallastBonusOptions}
                    defaultOption={"LumpSum"}
                    onChange={(value) => {
                      if (value) this.setState({ ballastBonus: value }, () => this.props.onChange(this.state, "ballastBonus"));
                    }}
                    value={this.state.ballastBonus}
                    disabled={this.props.disabled}
                  />
                </NegotiableItem>
              )}

            {currentOwnerCommercialSubs &&
              currentOwnerCommercialSubs.negotiable &&
              currentOwnerCommercialSubs.included &&
              this.state.commercialSubs && (
                <NegotiableItem
                  typeTitle="Commercial Subs"
                  lastUpdated={currentOwnerCommercialSubs.lastUpdated}
                  displayControl={<SubsTextDisplay item={currentOwnerCommercialSubs.bid} />}
                  currentValue={
                    chartererCommercialSubs && SubsTextHasValue(chartererCommercialSubs.offer) ? (
                      <SubsTextDisplay item={chartererCommercialSubs.offer} />
                    ) : undefined
                  }
                >
                  <SubsTextEditor
                    id="commercialSubs"
                    onChange={(value) => {
                      if (value)
                        this.setState({ commercialSubs: value }, () => this.props.onChange(this.state, "commercialSubs"));
                    }}
                    value={this.state.commercialSubs}
                    disabled={this.props.disabled}
                  />
                </NegotiableItem>
              )}

            {currentOwnerOperationalSubs &&
              currentOwnerOperationalSubs.negotiable &&
              currentOwnerOperationalSubs.included &&
              this.state.operationalSubs && (
                <NegotiableItem
                  typeTitle="Operational Subs"
                  lastUpdated={currentOwnerOperationalSubs.lastUpdated}
                  displayControl={<SubsTextDisplay item={currentOwnerOperationalSubs.bid} />}
                  currentValue={
                    chartererOperationalSubs && SubsTextHasValue(chartererOperationalSubs.offer) ? (
                      <SubsTextDisplay item={chartererOperationalSubs.offer} />
                    ) : undefined
                  }
                >
                  <SubsTextEditor
                    id="operationalSubs"
                    onChange={(value) => {
                      if (value)
                        this.setState({ operationalSubs: value }, () => this.props.onChange(this.state, "operationalSubs"));
                    }}
                    value={this.state.operationalSubs}
                    disabled={this.props.disabled}
                  />
                </NegotiableItem>
              )}
          </div>
        )}
      </MinimalConfigurationContext.Consumer>
    );
  }
}
