import classNames from "classnames";
import * as ClassNames from "classnames";
import { observer } from "mobx-react";
import { Status as Model } from "___REFACTOR___/models";
import { Dialog, StatusIcon, Icon } from "___REFACTOR___/components/common";
import "./Status.scss";

const Status = observer((props: Status.Props) => {
  if (props.status) props = { ...props.status, ...props };

  props = { ...DEFAULT_PROPS_BY_STATUS_MAP[props.type!], ...props };

  const dialog = Dialog.useContext();
  const { message, title, type, icon, res, hidden } = props;
  const className = classNames(props.className, type);
  const devTrace = getDevTrace();

  if (hidden) return null;

  return (
    <app-status class={className}>
      <app-status-header>
        <StatusIcon icon={icon} type={type!} />
        <app-status-title>{title}</app-status-title>
        <Icon name="close" onClick={dialog.onClose} className="close-dialog-btn" hidden={!dialog.isOpen} />
      </app-status-header>
      <app-vertical-separator />
      <app-status-body>
        {message}
        <a href="mailto:support@sea.live">support@sea.live</a>.
      </app-status-body>
      <app-status-dev-trace style={{ display: devTrace ? "" : "none" }}>{devTrace}</app-status-dev-trace>
    </app-status>
  );

  function getDevTrace() {
    if (!res) return;

    const { statusText, status, config, data } = res;
    const { url, method } = config;
    const dataText = JSON.stringify(data);

    return `${status} ${statusText} ${method} ${url} ${dataText}`;
  }
});

const DEFAULT_PROPS_BY_STATUS_MAP = {
  error: {
    title: "Sorry, something went wrong",
  },
};

export { Status };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace Status {
  interface Props extends Model {
    status?: Model;
    className?: ClassNames.Argument;
    hidden?: boolean;
  }
}
