import { Route } from "___REFACTOR___/models/common/Route";

class Root extends Route {
  readonly relpath = "/";
}

const root = new Root();

export { root };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */
