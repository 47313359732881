import { action, makeObservable, observable } from "mobx";
import { randomInteger } from "@/utils";
import { TradeAPI, tradeAPI } from "@/apis";
import { log } from "@/services";
import { DataModel, DataModelProps } from "../DataModel";
import { Account } from "../Account";
import { Period } from "../Period";
import { Laycan } from "../Laycan";
import { AddressCommission } from "../AddressCommission";
import { BrokerCommission } from "../BrokerCommission";
import { CargoSize } from "../CargoSize";
import { CargoType } from "../CargoType";
import { VesselSize } from "../VesselSize";
import { Duration } from "../Duration";
import { DischargeLocation } from "../DischargeLocation";
import { DeliveryLocation } from "../DeliveryLocation";
import { RedeliveryLocation } from "../RedeliveryLocation";
import { LoadLocation } from "../LoadLocation";
import { ViaLocation } from "../ViaLocation";
import { Nominations } from "../Nominations";
import { Liftings } from "../Liftings";
import { COACargoSize } from "../COACargoSize";
import { CLDDU, CLDDUBroker, CLDDUCharterer } from "../CLDDU";
import { CreatedOn, PeriodStartEnd, Timepassed } from "../Timestamp";
import { NegotiationTypeCount } from "../NegotiationTypeCount";
import { ParsedUpdateToken } from "./ParsedUpdateToken";
import { Actions } from "../Negotiation/Actions";
import { Desk } from "../Desk";
import { CreatedByAndDesk } from "../CreatedByAndDesk";
import { Negotiation } from "../Negotiation";
import { UniversalOrderNegotiationFormValues } from "../UniversalOrderNegotiationFormValues";
import { OrderNegotiationStore } from "../OrderNegotiationStore";
import { COANotes, OrderNotes, TCNotes, VoyageNotes } from "../OrderNotes";
import { SubjectTermset } from "../Termset";
import { getDetailArrayForDisplay, GetDetailArrayForDisplay } from "./utils";

export class Order extends DataModel<Data, Props, UpdateConfig, PartialData, PartialProps> {
  onConstruct(data?: PartialData, props?: PartialProps, updateConfig = defaultUpdateConfig) {
    if (this._.orderNegotiationStore && data) {
      this._.orderNegotiationStoreOrder = this._.orderNegotiationStore.upsertOrder(data, {
        ...updateConfig?.orderNegotiationStoreOrderUpsertConfig,
        shouldUpdateModel: false,
      });
      this._.orderNegotiationStoreOrder._.model = this;

      this.assignData(this._.orderNegotiationStoreOrder);
    }

    this.makeObservable();
  }

  onUpdate(data?: PartialData, props?: PartialProps, updateConfig = defaultUpdateConfig) {
    if (updateConfig.shouldUpdateOrderNegotiationStore && data) {
      this._.orderNegotiationStore?.upsertOrder(data, { shouldUpdateModel: false });
    }
  }

  isDataStale(data?: PartialData, props?: PartialProps, updateConfig = defaultUpdateConfig) {
    if (updateConfig?.forceUpdate) return false;

    if (!data) return true;

    const incomingVersion = data.version || 0;
    const incomingLastUpdated = data.lastUpdated || 0;

    const isCurrentVersionSameOrNewer = incomingVersion <= this.version;
    const isCurrentLastUpdatedSameOrNewer = new Date(incomingLastUpdated) <= new Date(this.lastUpdated);

    const isStale = isCurrentVersionSameOrNewer || isCurrentLastUpdatedSameOrNewer;

    return isStale;
  }

  upsertNegotiation(data: DeepPartial<TradeAPI["Negotiation"]>) {
    if (!data.id) {
      logError("Order.upsertNegotiation: Missing negotiation.id", { data, this: this });

      throw new Error("Order.upsertNegotiation: Missing negotiation.id");
    }

    data.orderId = data.orderId || this.id;

    if (data.orderId !== this.id) {
      logError("Order.upsertNegotiation: negotiation.orderId does not match this.id", { data, this: this });

      throw new Error("Order.upsertNegotiation: negotiation.orderId does not match this.id");
    }

    const existingNegotiation = this._.negotiationMap[data.id];

    existingNegotiation?.update(data);

    if (existingNegotiation) return existingNegotiation;

    const negotiation = new this._.NegotiationConstructor(data, {
      order: this,
      orderNegotiationStore: this._.orderNegotiationStore,
    });

    this._.negotiationMap[data.id] = negotiation;

    return negotiation;
  }

  makeObservable() {
    this.distributionList = this.distributionList || undefined;

    makeObservable(this, { update: action, distributionList: observable.ref, version: observable });
    makeObservable(this._, {
      version: observable,
      status: observable,
      orderNegotiationStoreOrderNegotiationArray: observable.ref,
    });
  }

  get data() {
    return {
      updateToken: this.updateToken,
      id: this.id,
      dealCaptureBrokerContact: this.dealCaptureBrokerContact,
      dealCaptureChartererContact: this.dealCaptureChartererContact,
      details: this.details,
      charterer: this.charterer,
      chartererEmail: this.chartererEmail,
      charterersCompanyId: this.charterersCompanyId,
      status: this.status,
      teamMembers: this.teamMembers,
      distributionList: this.distributionList,
      groupChats: this.groupChats,
      types: this.types,
      version: this.version,
      createdOn: this.createdOn,
      lastUpdated: this.lastUpdated,
      owningCompany: this.owningCompany,
      fixedOn: this.fixedOn,
      brokers: this.brokers,
      sharedWith: this.sharedWith,
      responseRequired: this.responseRequired,
      createdBy: this.createdBy,
      attachments: this.attachments,
      negAttachments: this.negAttachments,
      summary: this.summary,
      isArchived: this.isArchived,
    };
  }

  async getData() {
    if (!this.id) return;

    const res = await tradeAPI.getOrder(this.id);

    if (res.ok && res.data) {
      this.update(res.data);
    }

    return res;
  }

  async capture(data, query) {
    const res = await tradeAPI.createDealCapture(data, query);

    return res;
  }

  async create(data, query) {
    const res = await tradeAPI.createOrder(data, query);

    return res;
  }

  getAllTypes() {
    const types = this.types || [];
    const originallySelectedTypes = this.details?.originallySelectedTypes || [];

    return [...types, ...originallySelectedTypes];
  }

  getPageTitle() {
    const chartererAccount = this.summary?.chartererAccount || "";
    const orderReference = this.summary?.orderReference || "";

    return `${chartererAccount}${orderReference}`;
  }

  getParsedUpdateToken() {
    if (this.updateToken) return new ParsedUpdateToken(this.updateToken);
  }

  getLastFirmedNonExpiredActions() {
    return getLastFirmedNonExpiredActions(this);
  }

  getCreatedNegotiationTypes() {
    return getCreatedNegotiationTypes(this);
  }

  isBeyondInitialTerms() {
    return isBeyondInitialTerms(this);
  }

  isResponseRequiredExpired() {
    return new Date() > new Date(this.responseRequired || 0);
  }

  getStage() {
    const { status } = this;

    const _isBeyondInitialTerms = isBeyondInitialTerms(this);
    const lastFirmedNonExpiredActions = getLastFirmedNonExpiredActions(this);
    const activeNegotiationTypes = getCreatedNegotiationTypes(this);

    let res = status as Stage;

    if (!_isBeyondInitialTerms) {
      if (status === "Inactive" && activeNegotiationTypes?.length) res = "InactiveNegotiations";

      if (lastFirmedNonExpiredActions?.brokerChartererFirmed) res = "FirmBid";

      if (lastFirmedNonExpiredActions?.ownerFirmed) res = "FirmOffer";
    }

    return res;
  }

  areThereAnyNegs() {
    return this.summary && (this.summary.voyCount || this.summary.tctCount);
  }

  getVoyCountModel() {
    if (this.summary?.voyCount) return new NegotiationTypeCount({ type: "VOY", count: this.summary?.voyCount });
  }

  getTctCountModel() {
    if (this.summary?.tctCount) return new NegotiationTypeCount({ type: "TC", count: this.summary?.tctCount });
  }

  getCreatedByModel() {
    if (this.createdBy) return new CLDDU(this.createdBy);
  }

  getCreatedOnModel() {
    if (this.createdOn) return new CreatedOn(this.createdOn);
  }

  getFixedOnModel() {
    if (this.fixedOn) return new CreatedOn(this.fixedOn);
  }

  getLastUpdatedModel() {
    if (this.fixedOn) return new Timepassed(this.lastUpdated);
  }

  getLaycanStartModel() {
    if (this.details?.laycan?.start) return new PeriodStartEnd(this.details?.laycan?.start);
  }

  getLaycanEndModel() {
    if (this.details?.laycan?.end) return new PeriodStartEnd(this.details?.laycan?.end);
  }

  getDeskModel() {
    if (this.createdBy?.desk) {
      return new Desk(this.createdBy.desk);
    }
  }

  getRandomDesk() {
    return MOCK_DESKS[randomInteger(0, MOCK_DESKS.length - 1)];
  }

  getCreatedByAndDeskModel() {
    const desk = this.getDeskModel();
    const createdBy = this.getCreatedByModel();

    if (!desk && !createdBy) return;

    return new CreatedByAndDesk({ desk, createdBy });
  }

  isOwnerOrder() {
    return !!this.teamMembers?.find(ownerTeamMemberSelector);
  }

  getDetailsModelMap() {
    const { details } = this;

    return {
      chartererAccount: details?.chartererAccount ? new Account(details?.chartererAccount) : undefined,
      laycan: details?.laycan ? new Laycan(details?.laycan) : undefined,
      period: details?.period ? new Period(details?.period) : undefined,
      liftings: details?.liftings ? new Liftings(details?.liftings) : undefined,
      nominations: details?.nominations ? new Nominations(details?.nominations) : undefined,
      addressCommission: details?.addressCommission ? new AddressCommission(details?.addressCommission) : undefined,
      brokerCommission: details?.brokerCommission ? new BrokerCommission(details?.brokerCommission) : undefined,
      cargoType: details?.cargoType ? new CargoType(details?.cargoType) : undefined,
      cargoSize: details?.cargoSize ? new CargoSize(details?.cargoSize) : undefined,
      coaCargoSize: details?.coaCargoSize ? new COACargoSize(details?.coaCargoSize) : undefined,
      loadLocation: details?.loadLocation ? new LoadLocation(details?.loadLocation) : undefined,
      dischargeLocation: details?.dischargeLocation ? new DischargeLocation(details?.dischargeLocation) : undefined,
      deliveryLocation: details?.deliveryLocation ? new DeliveryLocation(details?.deliveryLocation) : undefined,
      duration: details?.duration ? new Duration(details?.duration) : undefined,
      redeliveryLocation: details?.redeliveryLocation ? new RedeliveryLocation(details?.redeliveryLocation) : undefined,
      vesselSize: details?.vesselSize ? new VesselSize(details?.vesselSize) : undefined,
      viaLocation: details?.viaLocation ? new ViaLocation(details?.viaLocation) : undefined,
      voyageNotes: details?.voyageNotes ? new VoyageNotes(details?.voyageNotes) : undefined,
      coaNotes: details?.coaNotes ? new COANotes(details?.coaNotes) : undefined,
      tctNotes: details?.tctNotes ? new TCNotes(details?.tctNotes) : undefined,
      dealCaptureType: details?.dealCaptureType,
    };
  }

  getDetailsModelArray(config?: GetDetailArrayForDisplay.Config) {
    const { details } = this;

    return getDetailArrayForDisplay(details, config);
  }

  getMainTermsFormValues(type?: TradeAPI["OrderType"] | "Lft") {
    const subjectTermset = new SubjectTermset(DEFAULT_SUBJECT_TERMSET);

    let notes = "";

    if (type === "Coa" && this.details?.coaNotes) {
      notes = this.details.coaNotes;
    }

    if (type === "Voy" && this.details?.voyageNotes) {
      notes = this.details.voyageNotes;
    }

    if (type === "Tct" && this.details?.tctNotes) {
      notes = this.details.tctNotes;
    }

    return new UniversalOrderNegotiationFormValues({
      ...this.getDetailsModelMap(),
      brokerContact: this.dealCaptureBrokerContact ? new CLDDUBroker(this.dealCaptureBrokerContact) : undefined,
      chartererContact: this.dealCaptureChartererContact ? new CLDDUCharterer(this.dealCaptureChartererContact) : undefined,
      notes: new OrderNotes(notes),
      subjectTermset,
    });
  }
}

function isBeyondInitialTerms(order: PartialData) {
  return !(order.status && INITIAL_TERMS_STATUS[order.status]);
}

function getLastFirmedNonExpiredActions(order: PartialData) {
  const { summary } = order;
  const { firmExpires } = summary || {};
  const lastActionByNegId = {};

  let lastNonExpiredFirmedAction: Actions | undefined;

  if (!firmExpires) return null;

  for (let i = 0; i < firmExpires.length; i++) {
    const record = firmExpires[i] as any;
    const { actions } = record || {};
    const { lastUpdatedBy, brokerCharterer, owner, ownerFirmExpiresOn, brokerChartererFirmExpiresOn } = actions || {};
    const ownerFirmedExpired = new Date() > new Date(ownerFirmExpiresOn || 0);
    const brokerChartererFirmedExpired = new Date() > new Date(brokerChartererFirmExpiresOn || 0);

    record.actions.firmExpires = firmExpires;
    //@ts-ignore
    record.actions.orderReference = summary.orderReference;

    if (lastActionByNegId[record.negId]) return;

    if (!lastActionByNegId[record.negId]) {
      if (lastUpdatedBy === "brokerCharterer" && brokerCharterer === "firmed" && !brokerChartererFirmedExpired) {
        lastNonExpiredFirmedAction = record.actions;
        break;
      }

      if (lastUpdatedBy === "owner" && owner === "firmed" && !ownerFirmedExpired) {
        lastNonExpiredFirmedAction = record.actions;
        break;
      }

      lastActionByNegId[record.negId] = record;
    }
  }

  lastNonExpiredFirmedAction = lastNonExpiredFirmedAction && new Actions(lastNonExpiredFirmedAction);

  return lastNonExpiredFirmedAction;
}

function getCreatedNegotiationTypes(order: PartialData) {
  return order.types;
}

const DEFAULT_SUBJECT_TERMSET = {
  content: {
    mainTermTemplates: [
      {
        title: "Operational Subs",
        TermTemplateType: "Operational",
        cpmProformaKey: undefined,
        content: "",
        termId: "OPERATIONAL_SUBS",
      },
      {
        title: "Commercial Subs",
        TermTemplateType: "Commercial",
        cpmProformaKey: undefined,
        content: "",
        termId: "COMMERCIAL_SUBS",
      },
    ],
  },
};

class Props extends DataModelProps<Data> {
  NegotiationConstructor = Negotiation;
  version = 0;
  status = {} as Status;
  negotiationMap = {} as MaybeRecordOf<Negotiation>;
  orderNegotiationStoreOrderNegotiationArray = [] as OrderNegotiationStore["Negotiation"][];
}

Order.prototype.Props = Props;
Order.prototype._ = new Props();

Props.prototype.stageMetadataMap = {
  Inactive: { value: "Inactive", label: "New" },
  InactiveNegotiations: { value: "InactiveNegotiations", label: "Inactive Negotiations" },
  Active: { value: "Active", label: "Active Negotiations" },
  FirmOffer: { value: "FirmOffer", label: "Firm Offer" },
  FirmBid: { value: "FirmBid", label: "Firm Bid" },
  Firm: { value: "Firm", label: "Firm" },
  TermsLoading: { value: "TermsLoading", label: "Terms Loading" },
  MainTerms: { value: "MainTerms", label: "Main Terms" },
  OnSubs: { value: "OnSubs", label: "On Subs" },
  SubsLifted: { value: "SubsLifted", label: "Subjects Lifted" },
  SubsFailed: { value: "SubsFailed", label: "Subjects Failed" },
  Fixed: { value: "Fixed", label: "Fixed" },
  Failed: { value: "Failed", label: "Failed" },
  Archived: { value: "Archived", label: "Archived" },
  Withdrawn: { value: "Withdrawn", label: "Order Withdrawn" },
};

function ownerTeamMemberSelector(teamMember) {
  return teamMember?.companyType === "owner";
}

const INITIAL_TERMS_STATUS = {
  Inactive: true,
  Active: true,
  Firm: true,
};

const defaultUpdateConfig = {
  orderNegotiationStoreOrder: true,
} as UpdateConfig;

export const MOCK_DESKS = [
  { name: "Desk A", id: "Desk A" },
  { name: "Desk B", id: "Desk B" },
  { name: "Desk C", id: "Desk C" },
  { name: "Desk D", id: "Desk D" },
  { name: "Desk E", id: "Desk E" },
  { name: "Desk F", id: "Desk F" },
  { name: "Desk G", id: "Desk G" },
  { name: "Desk H", id: "Desk H" },
  { name: "Desk I", id: "Desk I" },
];

const errorDialogProps = {
  status: {
    type: "error",
    title: "Order Negotiation Store Failure",
  } as Status,
  dataTest: "order-negotiation-store-fail",
};

function logError(message, dump) {
  log.error(errorDialogProps, message, dump);
}

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

export interface Order extends Data {
  _: Props;

  UpdateConfig: UpdateConfig;
  StageLowercase: StageLowercase;
}

type PartialProps = Partial<Props>;

interface Props {
  orderNegotiationStore?: OrderNegotiationStore;
  orderNegotiationStoreOrder?: OrderNegotiationStore["Order"];
  stageMetadataMap: Record<Stage, StageMetadata>;
}

export type Stage =
  | "Inactive"
  | "InactiveNegotiations"
  | "Active"
  | "FirmOffer"
  | "FirmBid"
  | "Firm"
  | "TermsLoading"
  | "MainTerms"
  | "OnSubs"
  | "SubsLifted"
  | "SubsFailed"
  | "Fixed"
  | "Failed"
  | "Archived"
  | "Withdrawn";

export type StageLowercase =
  | "inactive"
  | "inactiveNegotiations"
  | "active"
  | "firmOffer"
  | "firmBid"
  | "firm"
  | "termsLoading"
  | "mainTerms"
  | "onSubs"
  | "subsLifted"
  | "subsFailed"
  | "fixed"
  | "failed"
  | "archived";

type PartialData = DeepPartial<Data>;
type Data = TradeAPI["Order"];

type UpdateConfig = {
  shouldUpdateOrderNegotiationStore?: boolean;
  orderNegotiationStoreOrderUpsertConfig?: OrderNegotiationStore["EntityUpsertConfig"];
  forceUpdate?: boolean;
};

interface StageMetadata {
  value: Stage;
  label: string;
}
