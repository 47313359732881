import { Negotiation, dialog } from "@/models";

export async function publishNegotiation(publishSwitch: "published" | "revealedToOwner", model?: Negotiation) {
  if (!model) {
    console.error("publishNegotiation:", { model });

    const status = {} as Status;

    status.type = "error";
    status.title = "Reveal Negotiation Failure";

    dialog.show({
      status,
      dataTest: `publish-negotiation-fail`,
    });

    return;
  }

  model._.status.loading = true;
  model._.status.message = `Revealing Negotiation to the ${publishSwitch === "published" ? "Charterer" : "Owner"}`;

  const res = await model.postPublish(publishSwitch);

  if (!res?.ok) {
    model._.status.type = "error";
    model._.status.title = "Reveal Negotiation Failure";
    model._.status.dump = { res, entity: model };

    dialog.show({
      status: model._.status,
      dataTest: `publish-negotiation-fail`,
    });

    model._.status.loading = false;
    model._.status.message = null;

    return res;
  }

  model._.status.loading = false;
  model._.status.message = null;

  return res;
}
