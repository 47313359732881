import React, { ReactNode } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { router, Route as RouteModel } from "@/models";
import { CircularSpinner } from "@/components";

// TODO JOGI - Refine this down to display just the text for the active tab

const CollapsedLabel = (props: Props) => {
  const params = useParams();
  const { active } = router;
  const { nav } = active;

  if (!nav?.length) return null;

  return (
    <span className="collapsed-label" data-test="order-header-nav">
      {nav?.map(TabHOC.bind(null, params))}
      {props.children}
    </span>
  );
};

function TabHOC(params: any, route: RouteModel) {
  return <TabObserver params={params} route={route} key={route.absPath} />;
}

const TabObserver = observer(function Tab(props: { params: any; route: RouteModel }) {
  const { route, params } = props;
  const { label, getMiscInfo } = route;
  const { badgeCount, loading } = getMiscInfo?.(params) || 0;
  const spinner = loading ? <CircularSpinner /> : null;
  const isActive = route.match(window.location.pathname);

  return (
    <>
      {isActive && (
        <>
          {`${label} `}
          {badgeCount > 0 && !loading && `(${badgeCount})`}
          {spinner}
        </>
      )}
    </>
  );
});

const Observer = observer(CollapsedLabel);

export { Observer as CollapsedLabel };

interface Props {
  children?: ReactNode;
}
