import { action, makeObservable, observable } from "mobx";
import { TradeAPI } from "___REFACTOR___/apis";
import { DataModel } from "../DataModel";
import { ChartererAccount } from "../Account";
import { Period } from "../Period";
import { CargoSize } from "../CargoSize";
import { CargoType } from "../CargoType";
import { VesselSize } from "../VesselSize";
import { LoadLocation } from "../LoadLocation";
import { DischargeLocation } from "../DischargeLocation";
import { DeliveryLocation } from "../DeliveryLocation";
import { RedeliveryLocation } from "../RedeliveryLocation";
import { ViaLocation } from "../ViaLocation";
import { Duration } from "../Duration";
import { Nominations } from "../Nominations";
import { Liftings } from "../Liftings";
import { COACargoSize } from "../COACargoSize";
import { Vessels } from "../Vessels";
import { Vessel } from "../Vessel";
import { FreightRate } from "../FreightRate";
import { HireRate } from "../HireRate";
import { CleaningPrice } from "../CleaningPrice";
import { SupplyPrice } from "../SupplyPrice";
import { Demurrage } from "../Demurrage";
import { BallastBonus } from "../BallastBonus";
import { CommercialSubs, OperationalSubs, TradingExclusions, CargoExclusionsText } from "../Subs";
import { AddressCommission } from "../AddressCommission";
import { BrokerCommission } from "../BrokerCommission";
import { Attachment } from "../Trade/Negotiation/Attachment";
import { OrderNotes } from "../OrderNotes";
import { Actions } from "./Actions";
import { NegotiationProps } from "./Props";
import { BunkerDelivery, BunkerRedelivery } from "../Bunker";

class Negotiation extends DataModel {
  static Attachment = Attachment;
  static Actions = Actions;

  constructor(data?: Negotiation.PartialData, props?: NegotiationProps.ConstructorProps) {
    super(data, { suppressProps: true });

    this._ = new NegotiationProps({ ...props, neg: this });

    if (!this.isDataStale(data)) {
      this.assignData(data);
    }

    if (!this.isDataStale(this._.orderNegStore?.neg)) {
      this.assignData(this._.orderNegStore?.neg);
    }

    this.vessels = this.vessels?.map((vessel) => new Vessel(vessel));

    this.makeObservable();
  }

  get hasData() {
    return !!this.updateToken;
  }

  toJSON() {
    const { _, ...rest } = this;

    return rest;
  }

  update(data?: Negotiation.PartialData, props?: Partial<NegotiationProps>, updateConfig = defaultUpdateConfig) {
    if (!data) return;
    if (this.isDataStale(data)) return;

    this.assignData(data);
    this._.version++;

    this.vessels = this.vessels?.map((vessel) => new Vessel(vessel));

    if (updateConfig.shouldUpdateOrderNegStore) {
      this._.orderNegStore?.store.upsertNeg(data, { shouldUpdateModel: false, callee: this });
    }
  }

  isDataStale(data?: Negotiation.PartialData, props?: Partial<NegotiationProps>, updateConfig = defaultUpdateConfig) {
    if (updateConfig?.forceUpdate) return false;

    if (!data) return true;

    const incomingVersion = data.version || 0;
    const incomingLastUpdated = data.lastUpdated || 0;

    const isCurrentVersionSameOrNewer = incomingVersion <= this.version;
    const isCurrentLastUpdatedSameOrNewer = new Date(incomingLastUpdated) <= new Date(this.lastUpdated);

    const isStale = isCurrentVersionSameOrNewer || isCurrentLastUpdatedSameOrNewer;

    return isStale;
  }

  makeObservable() {
    Object.assign(this, {
      version: this.version || undefined,
      lastUpdated: this.lastUpdated || undefined,
    });

    // @ts-ignore
    makeObservable(this, { update: action, version: observable, lastUpdated: observable });
  }

  getCounterparty() {
    return this.mainTermsDetails?.ownerAccount?.accountName || this.owningCompany?.accountName || "TBN";
  }

  isResponseRequiredExpired() {
    return new Date() > new Date(this.responseRequired || 0);
  }

  getMainTermsDetailsModelMap() {
    const { mainTermsDetails } = this;

    return {
      notes: mainTermsDetails?.notes && new OrderNotes(mainTermsDetails.notes),
      chartererAccount: mainTermsDetails?.chartererAccount && new ChartererAccount(mainTermsDetails.chartererAccount),
      laycan: mainTermsDetails?.laycan && new Period(mainTermsDetails.laycan),
      period: mainTermsDetails?.period && new Period(mainTermsDetails.period),
      liftings: mainTermsDetails?.liftings && new Liftings(mainTermsDetails.liftings),
      nominations: mainTermsDetails?.nominations && new Nominations(mainTermsDetails.nominations),
      addressCommission: mainTermsDetails?.addressCommission && new AddressCommission(mainTermsDetails.addressCommission),
      brokerCommission: mainTermsDetails?.brokerCommission && new BrokerCommission(mainTermsDetails.brokerCommission),
      cargoType: mainTermsDetails?.cargoType && new CargoType(mainTermsDetails.cargoType),
      cargoSize: mainTermsDetails?.cargoSize && new CargoSize(mainTermsDetails.cargoSize),
      coaCargoSize: mainTermsDetails?.coaCargoSize && new COACargoSize(mainTermsDetails.coaCargoSize),
      dischargeLocation: mainTermsDetails?.dischargeLocation && new DischargeLocation(mainTermsDetails.dischargeLocation),
      loadLocation: mainTermsDetails?.loadLocation && new LoadLocation(mainTermsDetails.loadLocation),
      deliveryLocation: mainTermsDetails?.deliveryLocation && new DeliveryLocation(mainTermsDetails.deliveryLocation),
      duration: mainTermsDetails?.duration && new Duration(mainTermsDetails.duration),
      redeliveryLocation: mainTermsDetails?.redeliveryLocation && new RedeliveryLocation(mainTermsDetails.redeliveryLocation),
      vesselSize: mainTermsDetails?.vesselSize && new VesselSize(mainTermsDetails.vesselSize),
      viaLocation: mainTermsDetails?.viaLocation && new ViaLocation(mainTermsDetails.viaLocation),
      ballastBonus: mainTermsDetails?.ballastBonus && new BallastBonus(mainTermsDetails.ballastBonus),
      demurrage: mainTermsDetails?.demurrage && new Demurrage(mainTermsDetails.demurrage),
      freightRate: mainTermsDetails?.freightRate && new FreightRate(mainTermsDetails.freightRate),
      hireRate: mainTermsDetails?.hireRate && new HireRate(mainTermsDetails.hireRate),
      cleaningPrice: mainTermsDetails?.cleaningPrice && new CleaningPrice(mainTermsDetails.cleaningPrice),
      supplyPrice: mainTermsDetails?.supplyPrice && new SupplyPrice(mainTermsDetails.supplyPrice),
      tradingExclusions: mainTermsDetails?.tradingExclusions && new TradingExclusions(mainTermsDetails.tradingExclusions),
      bunkerDelivery: mainTermsDetails?.bunkerDelivery && new BunkerDelivery(mainTermsDetails.bunkerDelivery),
      bunkerRedelivery: mainTermsDetails?.bunkerRedelivery && new BunkerRedelivery(mainTermsDetails.bunkerRedelivery),
      cargoExclusionsText: mainTermsDetails?.cargoExclusionsText && new CargoExclusionsText(mainTermsDetails.cargoExclusionsText),
      operationalSubs: mainTermsDetails?.operationalSubs && new OperationalSubs(mainTermsDetails.operationalSubs),
      commercialSubs: mainTermsDetails?.commercialSubs && new CommercialSubs(mainTermsDetails.commercialSubs),
      vessels: mainTermsDetails?.vessels && new Vessels(mainTermsDetails.vessels),
    };
  }

  isLifting() {
    return this.type === "Lft";
  }
}

Negotiation.prototype.Props = NegotiationProps;

const defaultUpdateConfig = {
  shouldUpdateOrderNegStore: true,
} as Negotiation.UpdateConfig;

export { Negotiation };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface Negotiation extends Negotiation.Data {
  Props: typeof NegotiationProps;
  _: NegotiationProps;
}

declare namespace Negotiation {
  namespace T {
    export { Attachment };
  }

  type PartialData = Partial<Data>;
  type Data = TradeAPI.Negotiation;

  namespace Stage {
    type All = TradeAPI.Order.Status.All | "FirmBid" | "FirmOffer" | "Withdrawn";

    interface Metadata {
      value: Stage.All;
      label: string;
    }
    namespace Metadata {}
  }

  interface UpdateConfig {
    shouldUpdateOrderNegStore?: boolean;
    forceUpdate?: boolean;
  }
  namespace UpdateConfig {}
}
