import React from "react";
import { observer } from "mobx-react";
import { dropdown } from "@/models";
import { Dropdown, DropdownProps } from "@/components";

function GlobalDropdown() {
  const { props, open } = dropdown;

  if (!props || !open) return null;

  return <Dropdown {...props} hide={dropdown.hide} select={dropdown.select} />;
}

const Observer = observer(GlobalDropdown);

export { Observer as GlobalDropdown };

type Props = Omit<DropdownProps, "hide" | "select">;

export type GlobalDropdownProps = Props;
