import React from "react";
import { SupplyPrice, SupplyPriceProps } from "@/models";
import { UnitValueEditorProps, UnitValueEditor } from "../UnitValue";

export function SupplyPriceEditor(props: Props) {
  props = { ...props };

  props.className = `${props.className} SupplyPrice`;

  function onChange(value: SupplyPrice) {
    const next = new SupplyPrice(value);

    props.onChange?.(next);
  }

  return (
    <UnitValueEditor {...props} units={SupplyPriceProps.prototype.units} onChange={onChange} dataTest={`${props.dataTest}`} />
  );
}

interface Props extends Omit<UnitValueEditorProps, "value" | "onChange"> {
  value?: SupplyPrice;
  onChange?: (value: SupplyPrice) => void;
}

export type SupplyPriceEditorProps = Props;
