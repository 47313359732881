import React, { ReactNode } from "react";
import { observer } from "mobx-react";
import { NavLink } from "@/components";
import "./Nav.scss";

const Nav = (props: Props) => {
  return (
    <div className="offer-rep-details">
      <div className="tabs">
        <NavLink to="negotiation" id="negotiation-tab">
          Negotiation
        </NavLink>
        <NavLink to="summary" dataTest={"Summary-Tab"}>
          Summary
        </NavLink>
        <NavLink to="notes-and-attachments" dataTest={"Notes & Attachments-Tab"}>
          Notes & Attachments
        </NavLink>
      </div>
      <div className="offer-rep-details-body">{props.children}</div>
    </div>
  );
};

const Observer = observer(Nav);

export { Observer as Nav };

interface Props {
  children?: ReactNode;
}
