import { UniversalOrderNegotiationFormValues, Negotiation, dialog } from "@/models";
import { Context } from "../Orders";

export async function postNegotiationVesselReject(
  model: Negotiation | undefined,
  context: Context,
  values: UniversalOrderNegotiationFormValues
) {
  if (!model || !values.vessel) {
    console.error("postNegotiationVesselReject:", { model, context, values });

    const status = {} as Status;

    status.type = "error";
    status.title = "Vessel Failed to be Rejected";

    dialog.show({
      status,
      dataTest: `post-negotiation-vessel-reject-fail`,
    });

    return;
  }

  model._.status.loading = true;
  model._.status.message = `Rejecting ${values.vessel.registrationDataName}`;

  const res = await model.postRejectVessel(values.vessel);

  if (!res?.ok) {
    model._.status.type = "error";
    model._.status.title = "Vessel Failed to be Rejected";
    model._.status.dump = { res, entity: model };

    dialog.show({
      status: model._.status,
      dataTest: `post-negotiation-vessel-reject-fail`,
    });

    model._.status.loading = false;
    model._.status.message = null;

    return res;
  }

  model._.status.loading = false;
  model._.status.message = null;

  // dialog.show({
  //   status: {
  //     type: "success",
  //     title: "Vessel Successfully Rejected",
  //   },
  //   dataTest: `post-negotiation-vessel-reject-success`,
  // });

  return res;
}
