import Axios from "axios";

type AuthUrl = string;

export interface IConfiguration {
  seaChatsignUpUrl: string;
  signUpUrl: string;
  authUrl: AuthUrl;
  cchatUrl: string;
  cchatWidgetUrl: string;
  cchatBaseUrl: string;
  headerCdnUrl: string;
  ctradeUrl: string;
  version: string;
  environment: "development" | "production";
  cloudApplicationCode: string;
  useMockTermSetClient: string;
  appInsightsKey: string;
  vesselActivityUrl: string;
  vesselViewUrl: string;
  maxDistributionCount?: number;
}

/**
 * Retrieve config
 */
export const Configuration = async () => {
  const client = Axios.create({ baseURL: "" });
  return await client
    .get("/config.json")
    .then((result) => result.data)
    .then((parsed) => parsed as IConfiguration);
};
