import { observer } from "mobx-react";
import { UniversalOrderNegotiationFormValues } from "@/models";
import { MainTermsForm } from "./";
import { SPLIT_ELEMENT, VOY_CONFIG, TCT_CONFIG, COA_CONFIG, TYPE_ID_BY_TYPE } from "./elements";

function getElements(type: UniversalOrderNegotiationFormValues["type"], isDummyNegotiation: boolean) {
  const elements = type && ELEMENTS_BY_TYPE[type];
  const required = type && REQUIRED_BY_TYPE[type];
  const typeId = type && TYPE_ID_BY_TYPE[type];
  // @ts-ignore
  MISC_CONFIG.elements[0].children[0].readOnly = !isDummyNegotiation;

  return { elements, required, typeId };
}

function MainTerms(props) {
  return <MainTermsForm {...props} getElements={getElements} section="standard" />;
}

const Observer = observer(MainTerms);

export { Observer as MainTerms };

const MISC_CONFIG = {
  elements: [
    {
      type: "CollapsibleGroup",
      label: "Owner/Vessel Details",
      children: [
        { type: "CollapsibleRow", name: "invitee", hybridMode: true, readOnly: false, onChangeSelector: "email" },
        { type: "CollapsibleRow", name: "ownerAccount" },
        { type: "CollapsibleRow", name: "vessels" },
      ],
    },
  ],
  required: ["invitee", "ownerAccount", "termset"],
};

const ELEMENTS_BY_TYPE = {
  Voy: SPLIT_ELEMENT([...VOY_CONFIG.elements, ...MISC_CONFIG.elements]),
  Tct: SPLIT_ELEMENT([...TCT_CONFIG.elements, ...MISC_CONFIG.elements]),
  Coa: SPLIT_ELEMENT([...COA_CONFIG.elements, ...MISC_CONFIG.elements]),
} as AnyRecord;

const REQUIRED_BY_TYPE = {
  Voy: [...VOY_CONFIG.required, ...MISC_CONFIG.required],
  Tct: [...TCT_CONFIG.required, ...MISC_CONFIG.required],
  Coa: [...COA_CONFIG.required, ...MISC_CONFIG.required],
} as AnyRecord;
