import { uid } from "___REFACTOR___/utils";
import { TextField } from "___REFACTOR___/components";
import { DataModel } from "../../DataModel";
import { getSchema } from "./schema";

class Props extends DataModel.Props {}

class CommaSeparatedValues extends DataModel {
  static Props = Props;
  static Editor = TextField;
  static get = {
    schema: getSchema,
  };
  static convert = {
    string: {
      to: {
        item: (string: string, config?: Convert.String.To.Item.Config) => {
          config = { ...config };
          const { minSeparatorCount = 0 } = { ...config };
          let itemArr = [] as Item[];
          let itemMap = { ...config.itemMap } as Item.Map;

          let value = "";
          let lastSeparatorIndex = 0;
          const stringEndIndex = string.length - 1;
          let separatorCount = 0;

          for (let i = 0; i < string.length; i++) {
            const chr = string[i];
            const separator = SEPARATOR_MAP[chr];
            const id = uid();

            if (!separator) value += chr;
            if (separator) separatorCount++;

            const existingValue = itemMap[value];

            if (i === stringEndIndex) {
              value = value.trim();

              const item = { value, separator, startIdx: lastSeparatorIndex, endIdx: stringEndIndex, id };

              if (!existingValue) {
                itemArr.push(item);
                itemMap[value] = item;
              }
            }
            //
            else if (separator) {
              value = value.trim();

              const item = { value, separator, startIdx: lastSeparatorIndex, endIdx: i, id };

              if (!existingValue) {
                itemArr.push(item);
                itemMap[value] = item;
              }

              lastSeparatorIndex = i;
              value = "";
            }
          }

          if (separatorCount < minSeparatorCount) {
            itemArr = [];
            itemMap = {};
          }

          return { itemArr, itemMap };
        },
      },
    },
    itemArr: {
      to: {
        string: (itemArr: Item[]) => {
          let res = "";

          for (let i = 0; i < itemArr.length; i++) {
            const item = itemArr[i];

            res += item.value;

            if (item.separator) res += item.separator;
          }

          return res;
        },
        valueArr: (itemArr: Item[]) => {
          const res = [] as string[];

          for (let i = 0; i < itemArr.length; i++) {
            const item = itemArr[i];

            res.push(item.value);
          }

          return res;
        },
      },
    },
  };

  constructor(seed: Seed) {
    super(seed);

    Object.assign(this, seed);
  }

  toJSON() {
    return CommaSeparatedValues.convert.itemArr.to.string(this.itemArr);
  }

  remove(toRemove: Item) {
    const { [toRemove.value]: removed, ...itemMap } = this.itemMap;

    this.itemMap = itemMap;
    this.itemArr = this.itemArr.filter((item) => item !== toRemove);
  }

  get valueArr() {
    return CommaSeparatedValues.convert.itemArr.to.valueArr(this.itemArr);
  }
}

const SEPARATOR_MAP = {
  ",": ",",
  ";": ";",
};

CommaSeparatedValues.prototype.Props = Props;

export { CommaSeparatedValues };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface CommaSeparatedValues {
  Props: typeof Props;
  _: Props;

  itemMap: Item.Map;
  itemArr: Item[];
}

declare namespace CommaSeparatedValues {
  type Props = DataModel.Props;
}

declare namespace CommaSeparatedValues {
  export { Seed, Item, Convert };
}

interface Seed {
  itemArr: Item[];
  itemMap: Item.Map;
}

type Item = Item.Seed;
declare namespace Item {
  interface Seed {
    separator: string;
    value: string;
    startIdx: number;
    endIdx: number;
    id: string;
  }

  interface Map {
    [value: string]: Item;
  }
}

interface Props extends CommaSeparatedValues.Props {}

declare namespace Convert {
  namespace String {
    namespace To {
      namespace Item {
        interface Config {
          minSeparatorCount?: number;
          itemMap?: CommaSeparatedValues.Item.Map;
        }
      }
    }
  }
}
