import Axios, { AxiosRequestConfig, AxiosError, AxiosResponse, AxiosInstance, CancelTokenSource, Canceler } from "axios";
import { cleanValue } from "@/utils";
import { log } from "@/services";
import { tradeAPI } from "@/apis";
import { auth } from "@/models";
import { config as tradeConfig } from "@/config";
import { delay } from "@/utils/delay";

export class Request {
  constructor(config?: Config) {
    this.config = { ...config };
    this.axios = Axios.create(config);

    this.axios.interceptors.request.use(this.reqSuccess as any);
    this.axios.interceptors.response.use(this.resSuccess as any, this.resFailure as any);
  }

  get = <Data>(url: string, config?: Config, config0?: Config) => {
    config = { ...config, ...config0 };

    // eslint-disable-next-line import/no-named-as-default-member
    const cancelTokenSource = Axios.CancelToken.source();

    config.cancelToken = cancelTokenSource.token;
    config.executor = this.get.bind(this, url, config);

    const promise = this.axios.get(url, config) as Res<Data>;

    promise.cancelTokenSource = cancelTokenSource;
    promise.cancel = cancelTokenSource.cancel;

    return promise;
  };

  post = <Data>(url: string, data: any, config?: Config, config0?: Config) => {
    if (!config?.suppressCleaning) data = cleanValue(data);

    config = { ...config, ...config0 };

    // eslint-disable-next-line import/no-named-as-default-member
    const cancelTokenSource = Axios.CancelToken.source();

    config.cancelToken = cancelTokenSource.token;
    config.executor = this.post.bind(this, url, data, config);

    const promise = this.axios.post(url, data, config) as Res<Data>;

    promise.cancelTokenSource = cancelTokenSource;
    promise.cancel = cancelTokenSource.cancel;

    return promise;
  };

  put = <Data>(url: string, data: any, config?: Config, config0?: Config) => {
    if (!config?.suppressCleaning) data = cleanValue(data);

    config = { ...config, ...config0 };

    // eslint-disable-next-line import/no-named-as-default-member
    const cancelTokenSource = Axios.CancelToken.source();

    config.cancelToken = cancelTokenSource.token;
    config.executor = this.put.bind(this, url, data, config);

    const promise = this.axios.put(url, data, config) as Res<Data>;

    promise.cancelTokenSource = cancelTokenSource;
    promise.cancel = cancelTokenSource.cancel;

    return promise;
  };

  delete = <Data>(url: string, data?: any, config?: Config, config0?: Config) => {
    if (!config?.suppressCleaning) data = cleanValue(data);

    config = { data, ...config, ...config0 };

    // eslint-disable-next-line import/no-named-as-default-member
    const cancelTokenSource = Axios.CancelToken.source();

    config.cancelToken = cancelTokenSource.token;
    config.executor = this.delete.bind(this, url, config);

    const promise = this.axios.delete(url, config) as Res<Data>;

    promise.cancelTokenSource = cancelTokenSource;
    promise.cancel = cancelTokenSource.cancel;

    return promise;
  };

  reqSuccess = (config: Config) => {
    // trade gateway sometimes doesn't like ;charset=utf-8, which is applied by default to application/json
    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] = "application/json";
    }

    // config.headers["x-caller-id"] = "tradeui/v2.6.9.420";

    return config;
  };

  resSuccess = (axiosResponse: AxiosResponse) => {
    const response = axiosResponse as Response;

    response.cancelled = false;
    response.ok = true;

    return response;
  };

  resFailure = async (axiosError: Error) => {
    const config = this.config;
    const UnavailableDefaultRetryCount = 5;
    const UnavailableDefaultRetryDelayMs = 500;
    const DefaultDelayIncrement = 250;
    const errorConfig = axiosError.config as Config;
    const status = axiosError.response?.status || 0;
    const ignoreStatus = config?.ignoreStatus;
    const retryStatus = config?.retryStatus;
    const response = { ...axiosError.response } as Response;

    response.cancelled = axiosError.__CANCEL__;
    response.ok = false;

    if (!response.cancelled) console.error(axiosError);

    if (!auth.trade.user?.email) return response;

    if (status === 401 && !ignoreStatus?.[status]) {
      log.system("Request: Failed with status 401. Refreshing Auth.", { ...axiosError });

      const isRefeshing = !!auth.trade.refreshPromise;
      const token = isRefeshing ? await auth.trade.refreshPromise : await auth.trade.refresh();

      if (!token) {
        log.system("Request: Auth refresh failure. Resetting Auth.", { ...axiosError });

        return auth.reset();
      }

      log.system("Request: Auth successfully refreshed. Retrying the request.", { ...axiosError });

      errorConfig.headers.authorization = `Bearer ${token}`;
      errorConfig.ignoreStatus = { ...errorConfig.ignoreStatus, 401: true };

      const res = await errorConfig.executor?.(errorConfig);

      if (res?.status === 401) {
        log.system("Request: Failed again with status 401. Resetting Auth.", res);

        return auth.reset();
      }

      if (!isRefeshing) {
        log.system("Request: Resetting TradeAPI after successful auth refresh.");

        tradeAPI.setup();
      }

      return res;
    }

    if (status === 403 && !ignoreStatus?.[status]) {
      log.system("Request: Failed with status 403. Refreshing Auth.", { ...axiosError });

      const isRefeshing = !!auth.trade.refreshPromise;
      const token = isRefeshing ? await auth.trade.refreshPromise : await auth.trade.refresh();

      if (!token) {
        log.system("Request: Auth refresh failure. Resetting Auth.", { ...axiosError });

        return auth.reset();
      }

      log.system("Request: Auth successfully refreshed. Retrying the request.", { ...axiosError });

      errorConfig.headers.authorization = `Bearer ${token}`;
      errorConfig.ignoreStatus = { ...errorConfig.ignoreStatus, 403: true };

      const res = await errorConfig.executor?.(errorConfig);

      if (res?.status === 403) {
        log.system("Request: Failed again with status 403. Resetting Auth.", res);

        return auth.reset();
      }

      if (!isRefeshing) {
        log.system("Request: Resetting TradeAPI after successful auth refresh.");

        tradeAPI.setup();
      }

      return res;
    }

    // If Gateway responses with ServiceUnavailable status code, then we retry this request for couple of times
    // In case if this is a deployment going in
    if ((status === 503 || retryStatus?.[status]) && !ignoreStatus?.[status]) {
      const maxRetryCount = tradeConfig?.faultTolerancePolicy?.retryCount ?? UnavailableDefaultRetryCount;
      const minDelay = tradeConfig?.faultTolerancePolicy?.minRetryPauseMs ?? UnavailableDefaultRetryDelayMs;
      const maxDelay = tradeConfig?.faultTolerancePolicy?.maxRetryPauseMs ?? UnavailableDefaultRetryDelayMs;
      const delayIncrement = tradeConfig?.faultTolerancePolicy?.pauseIncremenet ?? DefaultDelayIncrement;

      if (errorConfig.retryCount === undefined) {
        errorConfig.retryCount = 0;
      } else {
        errorConfig.retryCount++;
      }

      if (errorConfig.retryCount >= maxRetryCount) {
        return response;
      }

      const delayTime = Math.min(minDelay + errorConfig.retryCount * delayIncrement, maxDelay);
      await delay(delayTime);
      return await errorConfig.executor?.(errorConfig);
    }

    return response;
  };
}

type Res<Data = any> = RequestPromise<Response<Data>>;

export interface Request {
  config: Config;
  axios: AxiosInstance;
}

export interface Response<T = any> extends Omit<AxiosResponse<T>, "data"> {
  data: T;
  config: Config;
  ok: boolean;
  cancelled: boolean;
}

interface Error extends AxiosError {
  __CANCEL__: boolean;
}

export interface Config extends AxiosRequestConfig {
  ignoreStatus?: BoolRecord;
  retryStatus?: BoolRecord;
  suppressCleaning?: boolean;
  retryCount?: number;
  executor?: (config: Config) => Res;
}

export interface RequestPromise<T> extends Promise<T> {
  cancelTokenSource: CancelTokenSource;
  cancel: Canceler;
}
