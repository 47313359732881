import React, { MutableRefObject, ReactNode, useEffect, useRef } from "react";
import { kebabCase } from "lodash-es";
import { emptyFn } from "___REFACTOR___/utils";
import { Input } from "../../Input";
import { Container } from "../../Container";
import "./Radio.scss";

function RadioInput(props: RadioInput.Props) {
  const {
    checkedValue = true,
    xlabel,
    children = xlabel,
    dataTest,
    dataTestTail = typeof children === "string" ? kebabCase(children) : undefined,
  } = props;
  const checked = checkedValue === props.value;
  const domRef = useRef(null) as RadioInput.DomRef;

  useEffect(onMount, []);

  return (
    <Container {...props} onClick={onClick} type="Radio" disabled={props.disabled}>
      <input
        checked={checked}
        onChange={emptyFn}
        placeholder={props.placeholder}
        disabled={props.disabled}
        data-test={`${dataTest}.${dataTestTail}.input`}
        ref={domRef}
        type="radio"
      />
      {typeof children === "string" ? <label>{children}</label> : children}
    </Container>
  );

  function onMount() {
    if (props.domRef) props.domRef.current = domRef.current;

    return onUnmount;
  }

  function onUnmount() {
    if (props.domRef) props.domRef.current = null;
  }

  function onChange() {
    props.onChange?.(checkedValue);
  }

  function onClick() {
    onChange();
  }
}

export { RadioInput };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

// eslint-disable-next-line no-redeclare
declare namespace RadioInput {
  interface Props extends Input.Props {
    value: Value;
    onChange: (value: Value) => void;
    checkedValue?: Value;
    xlabel?: ReactNode;
    children?: ReactNode;
    domRef?: DomRef;
    dataTestTail?: string;
  }

  type Value = any;
  type CheckedValue = Value;
  type DomRef = MutableRefObject<HTMLInputElement | null>;
}
