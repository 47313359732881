import { Location, LocationProps } from "../Location";
import { LABELS } from "@/constants";

class ViaLocation extends Location {}

class ViaLocationProps extends LocationProps {}

ViaLocationProps.prototype.label = LABELS["viaLocation"];

ViaLocation.prototype.Props = ViaLocationProps;
ViaLocation.prototype._ = new ViaLocationProps();

export { ViaLocation, ViaLocationProps };

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */
