/**
 * Abstraction for retrieving token from local Storage or cookie
 */
export class TokenStore {
  private readonly tokenId: string;
  constructor(id: string) {
    this.tokenId = `ctrade-token-${id}`;
  }

  public get(): string | null {
    // attempt to fetch from local storage
    const token = localStorage.getItem(this.tokenId);
    // or resolve from cookie if possible
    return token || this.getFromCookie();
  }

  public set(value: string) {
    localStorage.setItem(this.tokenId, value);
  }

  public clear() {
    localStorage.removeItem(this.tokenId);
  }

  private getFromCookie(): string | null {
    if (!document.cookie) {
      return null;
    }

    const parts = document.cookie.split(";").map((part) => part.split("=").map((o) => o.trim()));
    const match = parts.find((p) => p.length === 2 && p[0] === this.tokenId);

    return match ? match[1] : null;
  }
}
