import { TradeAPI } from "___REFACTOR___/apis";
import { PrimitiveDataModel, PrimitiveDataModelProps } from "../../PrimitiveDataModel";

class Dispersal extends PrimitiveDataModel {
  constructor(data?: Data) {
    super(data);
  }

  toString() {
    if (this.data === "Fairly even spread") return this.data;
    if (this.data === "Monthly") return this.data;
    if (this.data === "Adhoc") return "Ad hoc";

    return "";
  }
}

class DispersalProps extends PrimitiveDataModelProps {}

Dispersal.prototype.Props = DispersalProps;

function createDispersal(dispersal: Data) {
  return new Dispersal(dispersal);
}

const DATA_ARR = ["Fairly even spread", "Monthly", "Adhoc"] as Data[];
const DISPERSAL_ARR = DATA_ARR.map(createDispersal);

export { Dispersal, DISPERSAL_ARR };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface Dispersal {
  data: Data;
}

type Data = TradeAPI.Liftings.Dispersal;
