import { getNegotiationApiService } from "../../api/negotiations/Negotiations";
import { INegotiationNoteUpdate, roleToActor } from "../../api/negotiations/models";
import { Role } from "../components/negotiate/Negotiation/Role";

export function updateNote(ctradeUrl: string, orderId: string, negId: string, role: Role, note: string, updateToken: string) {
  // take the current neg and apply the action
  const updateNote = getNegotiationApiService(ctradeUrl, orderId).updateNote;
  const body: INegotiationNoteUpdate = {
    as: roleToActor(role),
    value: note,
    updateToken,
  };
  return updateNote(orderId, negId, body);
}
