import React from "react";
import { Note as NegotiationNote } from "@/models/Negotiation/Note";
import { Group } from "../../Group";
import { Icon, TextareaField, TextareaFieldProps } from "@/components";
import "./NegotiationNote.scss";

export function NegotiationNoteEditor(props: Props) {
  props = { ...props };

  props.className = `${props.className} NegotiationNote`;

  const { className, name, ...rest } = props;

  function onChange(value: string) {
    const next = new NegotiationNote({ value });

    props.onChange?.(next);
  }

  return (
    <Group className={className}>
      <TextareaField {...rest} value={props.value?.value} onChange={onChange} dataTest={`${props.dataTest}.value`} />
      <div className="NegotiationNote-info">
        <Icon icon="info-outline">
          {name === "negotiationOwnerNote"
            ? "You are updating this note on the Owner's behalf. You can change what is written here at any time."
            : "The Owner will receive this note as soon as you click 'SEND'. You can change what is written here at any time."}
        </Icon>
      </div>
    </Group>
  );
}

interface Props extends Omit<TextareaFieldProps, "value" | "onChange"> {
  value?: NegotiationNote;
  onChange?: (value: NegotiationNote) => void;
}

export type NegotiationNoteEditorProps = Props;
