import React from "react";
import classNames from "classnames";
import "./CircularSpinner.scss";

export function CircularSpinner(props: Props) {
  props = { ...props };

  props.className = classNames(
    props.className,
    "CircularSpinner MuiCircularProgress-root MuiCircularProgress-colorPrimary MuiCircularProgress-indeterminate"
  );

  return (
    <div {...props} role="progressbar" style={style}>
      <svg className="MuiCircularProgress-svg" viewBox="22 22 44 44">
        <circle
          className="MuiCircularProgress-circle MuiCircularProgress-circleIndeterminate"
          cx="44"
          cy="44"
          r="18.4"
          fill="none"
          strokeWidth="7.2"
        ></circle>
      </svg>
    </div>
  );
}

const style = { width: 14, height: 14 };

interface Props {
  className?: string;
}
