import { OrderArchivingResponsePayload, tradeAPI } from "@/apis";
import { Order } from "@/models";
import { ArchivingAction, BaseArchivingAction, IArchivingEntity } from "./BaseArchivingAction";
import { config as tradeConfig } from "@/config";

export class OrderArchivingAction extends BaseArchivingAction<Order, OrderArchivingResponsePayload> {
  protected countOfRetries = tradeConfig.archiveWaitPolicy?.singleWaitRetryCount ?? this.SingleDefaultWaitRetryCount;
  protected retryDelay = tradeConfig.archiveWaitPolicy?.singleWaitRetryDelayMs ?? this.DefaultRetryDelayMs;
  protected actionName = ArchivingAction.Archive;
  protected operationName = "Archiving";
  protected finishedOperationName = "archived";
  protected entityName = "order";

  protected getEntityArray = () => [this.model];

  protected isModelUpdatedAsync = async () => {
    return this.getValidationAction()(this.model);
  };

  protected archivingActionAsync = async () => {
    const payload = {
      orderId: this.model.id,
      updateToken: this.model.updateToken,
    };

    return await tradeAPI.archiveOrder(payload);
  };
}
