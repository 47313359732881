import React, { useContext, useReducer, useEffect } from "react";
import { IFetchReducerState, IFetchReducerAction, fetchReducer } from "../../reducers/fetchReducer";
import { MinimalConfigurationContext } from "__legacy/sharedFolder/MinimalConfigurationContext";
import { from, Subscription } from "rxjs";

const commonHeaders = {
  "X-API-Version": "2",
  "Content-Type": "application/json",
};

export function useAPIFetch<T>(url: string, method: "DELETE" | "POST" | "PUT", data: T | null) {
  const config = useContext(MinimalConfigurationContext);
  const [{ fetching, error, response }, dispatch] = useReducer<React.Reducer<IFetchReducerState, IFetchReducerAction>>(
    fetchReducer,
    {
      fetching: false,
      response: null,
      error: null,
    }
  );

  useEffect(() => {
    let subscription: Subscription;
    if (data) {
      dispatch({ type: "fetching" });
      subscription = from(
        fetch(`${config.ctradeUrl + url}`, {
          method: method,
          headers: commonHeaders,
          body: JSON.stringify(data),
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error("request failed");
            }
          })
          .then((data: { id: string; version: number }) => {
            return {
              id: data.id,
            };
          })
      ).subscribe(
        (response) => {
          dispatch({ type: "fetched", response });
        },
        (error) => {
          dispatch({ type: "error", error: error.message });
        }
      );
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [config, data, method, url]);

  return {
    error,
    fetching,
    response,
  };
}
