import React from "react";
import classNames from "classnames";
import * as ClassNames from "classnames";
import "./Row.scss";

export function Row(props: Props) {
  const { hidden } = props;
  const className = classNames(props.className);

  if (hidden) return null;

  return <app-field-row class={className}>{props.children}</app-field-row>;
}

interface Props {
  className?: ClassNames.Argument;
  hidden?: boolean;
  children: React.ReactNode;
}
