import { object, string, number, TestContext } from "yup";
import { TradeAPI } from "___REFACTOR___/apis";
import { Duration } from "../Duration";

const unitArr = ["Days", "Months"] as TradeAPI.Duration.Unit[];

// https://github.com/jquense/yup
const schema = object({
  min: number().integer().required("Required"),
  max: number().integer().required("Required"),
  unit: string().oneOf(unitArr).required("Required"),
  notes: string().nullable(),
})
  .test({
    test: minMaxValidation,
    name: "minMaxValidation",
    message: "Something is wrong with Min / Max",
  })
  .transform(toJSON);

function toJSON(duration: Duration) {
  return duration?.toJSON();
}

function minMaxValidation(this: TestContext, duration) {
  if (!duration) return true;

  const { min, max } = duration as TradeAPI.Duration;

  const error = this.createError();

  if (typeof min === "number" && typeof max === "number" && min > max) {
    error.message = "Min should be less than or equal to Max";
    error.path = `${error.path}.min`;

    return error;
  }

  return true;
}

export { schema };
