import { CommaSeparatedValues } from "___REFACTOR___/models/common";
import { EmailList } from "___REFACTOR___/models/Trade";
import { createSchema } from "./schema";

class Body {
  static create = {
    schema: createSchema,
  };

  constructor(props: Body.Seed) {
    Object.assign(this, props);

    this.isPrivate = !this.emailList.isShared;
  }

  sharingVersion = 0;

  toJSON() {
    const emailList = this.emailList.toJSON();

    if (this.isPrivate) {
      return {
        ...emailList,
        sharing: [],
      };
    }

    return emailList;
  }
}

export { Body };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface Body {
  emailList: EmailList;
  commaSeparatedValues: CommaSeparatedValues;
  isPrivate: boolean;
}

declare namespace Body {
  interface Seed {
    emailList: EmailList;
    commaSeparatedValues: CommaSeparatedValues;
  }
}
