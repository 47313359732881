import React from "react";
import Field from "../Field/Field";
import EditorRowEmptyValue from "../EditorRowEmptyValue/EditorRowEmptyValue";
import { NewUnitValueEditor } from "sharedFolder/components/editors/NewUnitValueEditor/NewUnitValueEditor";
import { IUnitValueView } from "sharedFolder/Models/IDetails";
import { DemurrageOptions } from "sharedFolder/Models/IDemurrage";
import { unitValueTextOrDisplay } from "sharedFolder/components/common/fields";

interface IDemurrageFieldProps<T> {
  isFieldValid: (key: T) => boolean;
  updateForm: (key: T, value: any) => void;
  selectedItem?: IUnitValueView;
  isExpanded: boolean;
  setExpandedItem: (name: T) => void;
  required: boolean;
}

export const DemurrageField = (props: IDemurrageFieldProps<"demurrage">) => {
  return (
    <Field
      id={"demurrage"}
      label={"Demurrage *"}
      name={"demurrage"}
      edit={
        <NewUnitValueEditor
          dataTest="demurrage-notes"
          id="demurrageValue"
          type="demurrage"
          label="Demurrage"
          defaultOption={"PerDay"}
          isValid={props.isFieldValid("demurrage")}
          notesPlaceholderText={"Additional demurrage notes"}
          displayNotes={true}
          onChange={(value?: IUnitValueView) => {
            props.updateForm("demurrage", value);
          }}
          required={props.required}
          options={DemurrageOptions}
          value={
            props.selectedItem || {
              unit: "",
              unitDisplay: "",
              value: null,
            }
          }
          focus={props.isExpanded}
        />
      }
      expanded={props.isExpanded}
      expand={() => {
        props.setExpandedItem("demurrage");
      }}
      display={
        <>{(props.selectedItem && demurragesDisplay(props.selectedItem)) || <EditorRowEmptyValue required={props.required} />}</>
      }
      isValid={props.isFieldValid("demurrage")}
    />
  );
};

const demurragesDisplay = (value?: IUnitValueView) => {
  return value ? unitValueTextOrDisplay(value) : "--";
};
