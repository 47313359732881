import { IDistributionListViewModel, IDeskView, ICompany } from "../Models/IDetails";
import { IMapper, withNullable } from "./map";
import { ISearchUser } from "../../api/models/ISearchUser";
import { mapSearchUserToDistributionUser } from "./mapSearchUserToDistributionUser";
import { groupBy } from "lodash-es";
import { mapRecipientList } from "./mapRecipientList";

const map: IMapper<ISearchUser[], IDistributionListViewModel> = {
  toApi: (viewModel: IDistributionListViewModel) => {
    throw new Error("mapSearchUserToDistributionUser.toApi not implemented");
  },
  toView: (apiModel: ISearchUser[]) => {
    const groupDesks = (companyUsers: ISearchUser[]): IDeskView[] => {
      const result = groupBy(companyUsers, (searchUser: ISearchUser) => searchUser.deskId);
      return Object.keys(result).map(
        (key) =>
          ({
            deskName: result[key][0].deskName,
            deskId: result[key][0].deskId,
            recipients: result[key].map(mapSearchUserToDistributionUser.toView),
          } as IDeskView)
      );
    };

    const groupByCompany = (searchUsers: ISearchUser[]): ICompany[] => {
      const groupedCompanies = groupBy(searchUsers, (searchUser: ISearchUser) => searchUser.companyId);

      return Object.keys(groupedCompanies).map((key) => ({
        companyId: groupedCompanies[key][0].companyId,
        companyName: groupedCompanies[key][0].companyName,
        desks: groupDesks(groupedCompanies[key]),
        hasPendingRecipients: false,
      }));
    };

    return {
      companies: groupByCompany(apiModel),
      recipients: [],
    };
  },
  emptyViewModel: mapRecipientList.emptyViewModel,
};

export const mapSearchUserToDistributionList = withNullable(map);
