import { offerRepSendOfferRepNote, offerRepOfferDetail } from "../api/offerRepUpdate";
import { IOfferRepApi } from "../OwnerIndicationForm/Contexts/IOfferRepFormContext";

export const OfferRepNegotiationService = (apiUrl: string, offerRepToken: string, updateToken: string): IOfferRepApi => {
  const baseUrl = `${apiUrl}/offerrep/${offerRepToken}`;
  return {
    sendOffer: (data: any) =>
      offerRepOfferDetail(baseUrl, {
        ...data,
        updateToken,
      }),

    firmBidAccept: () =>
      offerRepOfferDetail(baseUrl, {
        action: "firmAccepted",
        updateToken: updateToken,
      }),

    sendOfferRepNote: (note: string) =>
      offerRepSendOfferRepNote(baseUrl, {
        value: note,
        as: "owner",
        updateToken: updateToken,
      }),
  };
};
