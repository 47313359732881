import { Location, LocationProps } from "../Location";
import { LABELS } from "@/constants";

class DeliveryLocation extends Location {}

class DeliveryLocationProps extends LocationProps {}

DeliveryLocationProps.prototype.label = LABELS["deliveryLocation"];

DeliveryLocation.prototype.Props = DeliveryLocationProps;
DeliveryLocation.prototype._ = new DeliveryLocationProps();

export { DeliveryLocation, DeliveryLocationProps };

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */
