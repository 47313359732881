import { memo, useMemo, useEffect } from "react";
import { Formik, Form as FormikForm } from "formik";
import { isEqual, cloneDeep, get, debounce, set } from "lodash-es";
import { DEVELOPMENT, PRODUCTION } from "@/config";
import { emptyFn, cleanValue } from "@/utils";
import { log } from "@/services";
import { unsavedChanges } from "@/models";
import { Overlay } from "@/components";
import { ElementHOC } from "./Element";
import { JSONSchemaToYup } from "./util";
import { FormContext } from "./Context";
import "./Form.scss";

function FormInner(props) {
  const {
    className,
    status,
    elements,
    definition,
    context,
    mutateValues,
    initialValues,
    yup,
    JSONSchema,
    suppressUnsavedChanges,
    ...formik
  } = props;

  const _elements = useMemo(resolveElements, [elements, definition]);
  const yupFromJSONSchema = useMemo(resolveYupFromJSONSchema, [JSONSchema, definition]);
  const _initialValues = useMemo(resolveInitialValues, [initialValues, yupFromJSONSchema]);
  const assignContextDebounced = debounce(assignContext);
  const mutateValuesDebounced = debounce(mutateValues);

  useEffect(onMount, []);
  useEffect(registerUnsavedChanges, []);

  function onMount() {
    return onUnmount;
  }

  function onUnmount() {
    if (context) context.formik = null;
  }

  function registerUnsavedChanges() {
    if (suppressUnsavedChanges) return;

    const unregisterUnsavedChanges = unsavedChanges.register(checkForUnsavedChanges);

    function checkForUnsavedChanges() {
      return !isEqual(context.formik?.values, context.formik?.initialValues);
    }

    return unregisterUnsavedChanges;
  }

  function assignContext(formikContext) {
    context.formik = formikContext;
  }

  function resolveElements() {
    function iterate(el) {
      if (!el) return { type: "__FALSY__", hidden: true };

      if (Array.isArray(el)) el = { type: "Row", children: el };

      if (typeof el === "string") el = { name: el };

      const value = get(definition, el.name);

      el = { ...el, el, _: value?._, formProps: props };

      if (el.children) el.children = el.children.map(iterate);

      return el;
    }

    return elements.map(iterate);
  }

  function resolveYupFromJSONSchema() {
    if (JSONSchema) {
      return JSONSchemaToYup(JSONSchema, definition);
    }
  }

  function resolveInitialValues() {
    const res = props.initialValues ? cloneDeep(props.initialValues) : yupFromJSONSchema?.cast();

    return res;
  }

  async function validate(values) {
    const formikErrors = {};

    values = { ...values };

    const entries = Object.entries(values);

    for (let i = 0; i < entries.length; i++) {
      const [key, value] = entries[i];

      values[key] = cleanValue(value, { keepBEProps: false });
    }

    context.validationValues = values;

    try {
      await yupFromJSONSchema?.validate(values, { abortEarly: false });

      //
    } catch (yupValidatonError) {
      const { inner } = yupValidatonError;

      for (let i = 0; i < inner.length; i++) {
        const yupValidatonError = inner[i];
        const { path, message } = yupValidatonError;

        set(formikErrors, path, message);
      }
    }

    try {
      await yup?.validate(values, { abortEarly: false });

      //
    } catch (yupValidatonError) {
      const { inner } = yupValidatonError;

      for (let i = 0; i < inner.length; i++) {
        const yupValidatonError = inner[i];
        const { path, message } = yupValidatonError;

        set(formikErrors, path, message);
      }
    }

    return formikErrors;
  }

  function FormHOC(formikContext) {
    consoleLog(formikContext, props);

    mutateValuesDebounced(formikContext.values);

    assignContextDebounced(formikContext);

    return (
      <FormikForm className={className}>
        <div className="form-elements">{_elements.map(ElementHOC)}</div>
        <Overlay status={status} />
      </FormikForm>
    );
  }

  return (
    <Formik enableReinitialize {...formik} initialValues={_initialValues} validate={validate}>
      {FormHOC}
    </Formik>
  );
}

function Form(props) {
  const _context = useMemo(newFormContext, []);

  let { hidden, context, mutateValues, elements, ...rest } = props;

  if (hidden) return null;

  context = context || _context;
  mutateValues = mutateValues || emptyFn;
  elements = elements || [];

  return <FormInner {...rest} context={context} mutateValues={mutateValues} elements={elements} />;
}

function consoleLog(formikContext, props) {
  formikContext = { ...formikContext };

  if (DEVELOPMENT && localStorage.ENABLE_FORM_LOG) {
    log.system("Form", formikContext, props);
  } else if (PRODUCTION) {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(log.system.bind(null, "Form", formikContext, props), 2000);
  }
}
let timeoutId = -1;

function newFormContext() {
  return new FormContext();
}

const Memo = memo(Form);

export { Memo as Form };
