import React from "react";
import classNames from "classnames";
import { ExpiresOnValue } from "sharedFolder/components/common/types";
import { ExpiresOn } from "sharedFolder/components/common/ExpiresOn/ExpiresOn";
import styles from "./FirmOfferTimer.module.scss";

export const FirmOfferTimer: React.FC<{
  disabled: boolean;
  expiryDateHasBeenSelected: (expires: ExpiresOnValue) => void;
}> = (props) => {
  return (
    <>
      <div className={classNames(styles.setTimeLimitSection, props.disabled ? styles.disabled : "")}>Set Time Limit</div>
      <div className={classNames(styles.expiresOnWrapper, props.disabled && styles.disabled)}>
        <div className={styles.expiresOnContainer}>
          <ExpiresOn action="to accept the firm OFFER *" targetRole="Charterer" onChange={props.expiryDateHasBeenSelected} />
        </div>
      </div>
    </>
  );
};
