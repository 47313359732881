import { useMemo, useRef, useEffect, useState, ForwardRefExoticComponent } from "react";
import { observable } from "mobx";
import * as ClassNames from "classnames";
import classNames from "classnames";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import {
  AllModules,
  LicenseManager,
  ColDef,
  GridReadyEvent,
  GridApi,
  RowEvent,
  GetContextMenuItemsParams,
  RowDataTransaction,
  ICellRendererParams,
  IFilterParams,
  IDoesFilterPassParams,
  RowNode,
  IFilterComp,
  PostProcessPopupParams,
  ColumnApi,
  CellClickedEvent,
  ICellRenderer,
  Column,
  FilterChangedEvent,
  ICellEditorParams,
  CellValueChangedEvent,
  ValueGetterParams,
  ValueSetterParams,
  RowClickedEvent as AgGridRowClickedEvent,
} from "@ag-grid-enterprise/all-modules";
import { AgGridReact, AgGridReactProps, ICellEditorReactComp } from "@ag-grid-community/react";
import { DEVELOPMENT } from "___REFACTOR___/config";
import { isTruthy, uid, useShallowStateRef, useSimpleEffect, wait } from "___REFACTOR___/utils";
import { log } from "___REFACTOR___/services";
import { DataModel } from "___REFACTOR___/models/DataModel";
import { Status } from "___REFACTOR___/models/Status";
import { Icon as CommonIcon } from "___REFACTOR___/components/common";
import { resolveMultiValueColDef } from "___REFACTOR___/components/Orders/Grid/columns";
import { AggridContext } from "./Context";
import { AggridColumnStateAPI, AggridDefaultColumnState } from "./ColumnStateAPI";
import { CellEditorPopup, Editor } from "./frameworkComponents";
import "./Aggrid.scss";

const Aggrid = (props: AggridProps) => {
  const defaultContext = useMemo(createContext, []);
  const { className: propsClassName, context = defaultContext, hidden, rowData, columnDefs, ...rest } = props;
  const className = classNames(propsClassName, { hidden, "dom-layout-auto-height": props.domLayout === "autoHeight" });
  const transaction = props.transaction || undefined;
  const frameworkComponents = useRef({
    ...DEFAULT_FRAMEWORK_COMPONENTS,
    ...props.frameworkComponents,
  });
  const container = useRef<HTMLDivElement>(null);
  const [isGridReady, setIsGridReady] = useState(false);
  const initialState = useMemo(createInitialState, []);
  const columnStateAPI = useMemo(newColumnStateAPI, []);
  const [state, setState] = useShallowStateRef(initialState);
  const transactionRef = useRef(transaction);
  const resolveDefParams = { context, state, columnStateAPI };
  const colDefs = useMemo(mapColumnDefs.bind(null, columnDefs), [columnDefs]) as Aggrid.Col.Def[];

  transactionRef.current = transaction;

  context.state = state;
  context.setState = setState;
  context.columnStateAPI = columnStateAPI;
  context.props = props;

  useEffect(onMount, []);
  useEffect(deriveRowData, [rowData]);
  useSimpleEffect(deriveTransactionProtected, [transactionRef.current]);

  return (
    <app-aggrid class={className} ref={container}>
      <AgGridReact
        headerHeight={31}
        rowHeight={31}
        animateRows
        suppressLoadingOverlay
        suppressNoRowsOverlay
        singleClickEdit
        {...rest}
        frameworkComponents={frameworkComponents.current}
        columnDefs={colDefs}
        rowData={rowData}
        onGridReady={onGridReady}
        postProcessPopup={postProcessPopup}
        getContextMenuItems={getContextMenuItems}
        context={context}
        // @ts-ignore
        popupParent={container.current}
        modules={modules}
      />
    </app-aggrid>
  );

  function onMount() {
    function onUnmount() {
      context.api = null;
      context.resetGridReadyPromise();
    }

    return onUnmount;
  }

  function onGridReady(params: AggridGridReadyEvent) {
    const { api, columnApi } = params;

    context.api = api;
    context.columnApi = columnApi;

    columnStateAPI.onGridReady();

    if (rowData) api.setRowData(rowData);

    applyTransaction();

    if (props.onGridReady) props.onGridReady(params);

    setIsGridReady(true);

    context.resolveGridReadyPromise(params);
    context.onGridReady?.(params);

    // @ts-ignore
    window.aggridContext = context;
  }

  function deriveRowData() {
    if (!rowData) return;

    context.api?.setRowData(rowData);
  }

  function deriveTransactionProtected() {
    if (!isGridReady) return;

    applyTransaction();
  }

  function applyTransaction() {
    const transaction = transactionRef.current;

    if (!transaction) return;

    if (Array.isArray(transaction)) {
      const add = [] as any[];
      const update = [] as any[];
      // const remove = [] as any[];
      const existingDataById = {} as AnyRecord;

      context.api?.forEachNode((node) => {
        existingDataById[node.data?.id] = node.data;
      });

      for (let i = 0; i < transaction.length; i++) {
        const data = transaction[i];
        const existingData = existingDataById[data.id];

        const hasChanged = existingData?._.__AGGRID_LAST_USED_VERSION__ !== data._.version;

        data._.__AGGRID_LAST_USED_VERSION__ = data._.version;

        if (existingData && hasChanged) update.push(data);

        if (!existingData) add.push(data);
      }

      const _transaction = { add, update, addIndex: 0 };

      context.api?.applyTransaction(_transaction);

      //
    } else {
      transaction.addIndex = transaction.addIndex || 0;

      context.api?.applyTransaction(transaction);
    }
  }

  function mapColumnDefs(columnDefs: Aggrid.Col.Defs = []) {
    const sanitizedColumnDefs = columnDefs.filter(isTruthy) as Aggrid.Col.Def[];

    columnDefs = sanitizedColumnDefs.map(mapColumnDef);

    columnDefs = [...columnDefs, API_INJECTION_COLUMN];

    return columnDefs;
  }

  function mapColumnDef(originalCol: Aggrid.Col.Def) {
    let col = { ...props.defaultColDef, ...originalCol };

    col.colId = col.colId !== undefined ? col.colId : `${uid()}`;

    if (col?.$resolveDef) Object.assign(col, col?.$resolveDef({ ...resolveDefParams, col }));

    const {
      $reactCellEditor,
      $reactCellRenderer,
      $reactFilter,
      $reactHeaderComponent,
      $defaultColumnState,
      $resolveDef,
      $blank,
      ...rest
    } = col;

    col = rest;

    col.filter = col.filter || "agSetColumnFilter";

    col.headerComponentParams = col.headerComponentParams || DEFAULT_HEADER_COMPONENT_PARAMS;

    col.flex = typeof col.flex === "number" ? col.flex : 1;

    col.minWidth = typeof col.minWidth !== "number" && typeof col.width !== "number" ? 100 : col.minWidth;

    col.sortable = typeof col.sortable === "boolean" ? col.sortable : true;

    col.menuTabs = col.menuTabs || ["filterMenuTab"];

    col.cellClassRules = {
      ...DEFAULT_CELL_CLASS_RULES,
      ...col.cellClassRules,
    };

    if ($defaultColumnState) {
      columnStateAPI.updateColumnState(col.colId, { defaults: $defaultColumnState });
    }

    if ($reactHeaderComponent) {
      const name = resolveFrameworkComponentName($reactHeaderComponent, "reactHeaderComponentName");

      frameworkComponents.current[name] = $reactHeaderComponent;

      col.headerComponent = name;
    }

    if ($reactCellRenderer) {
      const name = resolveFrameworkComponentName($reactCellRenderer, "reactCellRenderer");

      frameworkComponents.current[name] = $reactCellRenderer;

      col.cellRenderer = name;
    }

    if ($reactCellEditor) {
      const name = resolveFrameworkComponentName($reactCellEditor, "reactCellEditor");

      frameworkComponents.current[name] = $reactCellEditor;

      col.cellEditor = name;
    }

    if ($reactFilter) {
      const name = resolveFrameworkComponentName($reactFilter, "reactFilterName");

      frameworkComponents.current[name] = $reactFilter;

      col.filter = name;
    }

    if (col.filterParams?.filters) {
      col.filterParams = { ...col.filterParams };

      col.filterParams.filters = col.filterParams.filters.map(mapFilter);
    }

    col.cellRenderer = col.cellRenderer || standardCellRenderer;

    if (col.rowGroup) {
      col.cellRenderer = undefined;
    }

    if (col.rowDrag) {
      col.flex = 0;
      col.width = 36;
      col.minWidth = undefined;
      col.pinned = "left";
      col.rowDrag = true;
      col.editable = false;
      col.suppressMenu = true;
      col.suppressMovable = true;
      col.suppressColumnsToolPanel = true;
      col.suppressFiltersToolPanel = true;
      col.headerName = "";
      col.cellClass = classNames(col.cellClass, "aggrid-row-drag", "aggrid-icon-cell");
      col.cellRenderer = null;
      col.resizable = false;
    }

    if ($blank) {
      col.editable = false;
      col.cellRenderer = null;
      col.resizable = false;
      col.suppressMenu = true;
      col.suppressMovable = true;
      col.suppressColumnsToolPanel = true;
      col.suppressFiltersToolPanel = true;
    }

    return col;
  }

  function mapFilter(originalFilter) {
    const { $reactFilter, ...filter } = originalFilter;

    filter.display = filter.display || "inline";

    if ($reactFilter) {
      const name = resolveFrameworkComponentName($reactFilter, "reactMultiFilterName");

      frameworkComponents.current[name] = $reactFilter;

      filter.filter = name;

      filter.title = filter.title || $reactFilter.title;
    }

    return filter;
  }

  function getContextMenuItems(params: GetContextMenuItemsParams) {
    const items = props.getContextMenuItems?.(params) || [];
    const dev = DEVELOPMENT
      ? [
          {
            name: `Console Log Params`,
            tooltip: "This will create an email template you can send to the Owner",
            action: log.system.bind(null, "Aggrid: Node params", params.node?.data, params),
          },
        ]
      : [];

    return [...items, ...dev];
  }

  async function postProcessPopup(params: PostProcessPopupParams) {
    const { ePopup, type } = params;

    if (!container.current) return;

    if (type === "columnMenu") {
      ePopup.style.opacity = "0";
      ePopup.style.width = "0";

      await wait(100);

      ePopup.style.opacity = "1";
      ePopup.style.width = "auto";

      const { right, top } = ePopup.getBoundingClientRect();
      const { right: cRight, top: cTop } = container.current.getBoundingClientRect();

      const horizontalOverlow = right - cRight;
      const verticalGap = top - cTop;

      if (horizontalOverlow > 0) {
        const styleLeft = parseFloat(ePopup.style.left);

        ePopup.style.left = `${styleLeft - horizontalOverlow - 16}px`;
      }

      if (verticalGap > 0) {
        const styleTop = parseFloat(ePopup.style.top);

        ePopup.style.top = `${styleTop - verticalGap}px`;
      }
    }
  }

  function newColumnStateAPI() {
    return new AggridColumnStateAPI(context);
  }
};

const DEFAULT_STATE = {} as AggridState;

function createInitialState() {
  return observable(DEFAULT_STATE);
}

const API_INJECTION_COLUMN = {
  field: "API_INJECTION_COLUMN",
  cellClass: "API_INJECTION_COLUMN",
  width: 0,
  minWidth: 0,
  pinned: "right",
  suppressMenu: true,
  suppressMovable: true,
  suppressColumnsToolPanel: true,
  suppressFiltersToolPanel: true,
  valueGetter: function injectDataWithAPI(params) {
    const { data, node, context } = params;

    if (!data?._) return;

    data._.aggridRowNode = node;
    data._.aggridContext = context;
  },
};

const DEFAULT_FRAMEWORK_COMPONENTS = {
  Editor,
  standardCellRenderer,
};

const modules = [...AllModules, ClientSideRowModelModule];

const DEFAULT_HEADER_COMPONENT_PARAMS = {
  template: `
  <div class="ag-cell-label-container" role="presentation">
  <div ref="eLabel" class="ag-header-cell-label" role="presentation">
    <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>
    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>
    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>
    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>
  </div>
  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
</div>`,
};

function createContext() {
  return new AggridContext();
}

function standardCellRenderer(params) {
  const value = standardValueDisplayFormatter(params.value);

  params.eGridCell.innerHTML = value;
  params.eGridCell.setAttribute("title", value);
}

function standardValueDisplayFormatter(value) {
  if (value instanceof DataModel) value = value.toString();

  return value || "--";
}

function resolveFrameworkComponentName(component, baseAnonymousName) {
  const existingComponentName = frameworkComponentMap.get(component);

  if (existingComponentName) return existingComponentName;

  const functionName = component.name?.includes("$") ? null : component.name;
  const name = functionName || component.displayName || `${baseAnonymousName}${uid()}`;

  frameworkComponentMap.set(component, name);

  return name;
}

const frameworkComponentMap = new Map();

Aggrid.create = {
  multiValue: {
    colDef: function <D = any>(params: resolveMultiValueColDef.Params<D>) {
      const { sequence, cellRendererParams, ...rest } = params;

      return { ...resolveMultiValueColDef(params), ...rest } as Aggrid.Col.Def<D>;
    },
  },
  icon: {
    html: (icon: Aggrid.Icon.IconProp) => {
      if (typeof icon !== "object") icon = { name: icon };

      const { name } = icon;
      let { className } = icon;

      if (!name) return "";

      className = className || "";

      return `<app-icon class="${className} icon--${name}"></app-icon>`;
    },
    dom: (icon: Aggrid.Icon.IconProp) => {
      if (typeof icon !== "object") icon = { name: icon };

      const { name, className, title, tooltip } = icon;

      const eIcon = document.createElement("app-icon");

      eIcon.className = classNames(className, `icon--${name}`);
      eIcon.setAttribute("title", title || "");
      if (tooltip) eIcon.setAttribute("tooltip", tooltip);

      return eIcon;
    },
    colDef: (icon: Aggrid.Icon.IconProp, colDef?: Aggrid.Col.Def) => {
      return {
        cellRenderer: (params: Aggrid.Cell.Renderer.Params) => {
          if (typeof icon === "string") icon = { name: icon };
          const { eGridCell } = params;
          const { stopPropagation = true, tooltip, title = tooltip } = icon;

          const eIcon = Aggrid.create.icon.dom(icon);

          if (icon.onClick) eGridCell.onclick = onClick;

          if (icon.dataTest) eGridCell.setAttribute("dataTest", icon.dataTest);
          if (title) eGridCell.title = title;

          return eIcon;

          function onClick(e: MouseEvent) {
            if (typeof icon === "string") icon = { name: icon };

            if (stopPropagation) e.stopPropagation();

            icon.onClick?.(params, e, eIcon);
          }
        },
        headerName: "",
        flex: 0,
        width: 32,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
        suppressMenu: true,
        ...colDef,
        cellClass: classNames(colDef?.cellClass, "aggrid-icon-cell-v0"),
      } as Aggrid.Col.Def;
    },
  },
  chevron: {
    colDef: function <D = any>(params?) {
      const { sequence, cellRendererParams, ...rest } = params;

      return {
        flex: 0,
        width: 40,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
        suppressMenu: true,
        cellRenderer: (params) => {
          return Aggrid.create.icon.dom("chevron-right");
        },
        headerName: "",
        ...rest,
      } as Aggrid.Col.Def<D>;
    },
  },
};

Aggrid.Context = AggridContext;
Aggrid.CellEditorPopup = CellEditorPopup;
Aggrid.meta = {
  cell: {
    padding: {
      left: 8,
      top: 8,
    },
  },
};

const DEFAULT_CELL_CLASS_RULES = {
  "app-aggrid-cell-focusable": (params: Aggrid.Cell.Renderer.Params) =>
    params.colDef?.editable || params.colDef?.cellClass === "aggrid-icon-cell-v0",
};

export { Aggrid };

LicenseManager.setLicenseKey(
  "CompanyName=MARITECH DEVELOPMENT LIMITED,LicensedGroup=Sea/Trade,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-021559,ExpiryDate=6_December_2022_[v2]_MTY3MDI4NDgwMDAwMA==e60a2b4a469e1d618655b9c5e3e6f13e"
);

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

declare namespace Aggrid {
  type Context = AggridContext;

  type Props = AggridProps;

  namespace Grid {
    type Api = GridApi;
  }

  namespace ContextMenu {
    type GetItemsParams = AggridGetContextMenuItemsParams;
  }

  namespace Row {
    type Node = RowNode;
  }

  namespace Icon {
    interface Props extends Omit<CommonIcon.Props, "onClick"> {
      onClick?: (params: Aggrid.Cell.Renderer.Params, e: MouseEvent, eIcon: HTMLElement) => void;
      dataTest?: string;
    }

    type IconProp = CommonIcon.Name | (Omit<Icon.Props, "icon"> & { stopPropagation?: boolean });
  }

  namespace Col {
    namespace ValueGetter {
      type Params = ValueGetterParams;
    }

    namespace ValueSetter {
      type Params = ValueSetterParams;
    }
  }

  namespace Cell {
    type Renderer = Function;
    namespace Renderer {
      interface Params<D = any> extends ICellRendererParams {
        data: D;
      }
    }

    namespace ValueGetter {
      interface Params<D = any> extends ValueGetterParams {
        data: D;
      }
    }

    namespace ValueSetter {
      type Params = ValueSetterParams;
    }

    namespace Editor {
      interface Params extends ICellEditorParams {
        reactContainer: HTMLDivElement;
      }
      type Comp = ICellEditorReactComp;
    }
  }

  namespace Col {
    interface Def<D = any> extends Omit<ColDef, "cellClassRules"> {
      // cellRenderer?:
      //   | {
      //       new (): ICellRendererComp;
      //     }
      //   | ICellRendererFunc
      //   | string;
      cellRenderer?: any;
      cellClassRules?: { [className: string]: (params: Cell.Renderer.Params<D>) => truthyfalsy };
      onCellClicked?(e: CellClickedEvent, ...args): void;
      valueGetter?: (params: Cell.ValueGetter.Params<D>) => any;
      dataTest?: string;

      $blank?: boolean;
      $reactHeaderComponent?: any;
      $reactFilter?: any;
      $reactCellRenderer?: any;
      $reactCellEditor?:
        | ((params: Aggrid.Cell.Editor.Params) => JSX.Element)
        | ForwardRefExoticComponent<Aggrid.Cell.Editor.Params>;
      $defaultColumnState?: AggridDefaultColumnState;
      $resolveDef?(params: AggridResolveDefParams): AggridColDef | void;
    }
    type Defs = (Def | undefined | false | null)[];
  }

  namespace Event {
    namespace Grid {
      type Ready = AggridGridReadyEvent;
    }

    namespace Row {
      type Click = AgGridRowClickedEvent;
    }

    namespace Cell {
      type Click = CellClickedEvent;
      type ValueChange = CellValueChangedEvent;
    }
  }

  namespace MasterDetail {
    namespace CustomDetailCell {
      type Params = ICellRendererParams;
    }
  }
}

export interface AggridProps extends Omit<AgGridReactProps, "rowData" | "columnDefs" | "getRowNodeId"> {
  context?: AggridContext;
  className?: ClassNames.Argument;
  rowData?: AgGridReactProps["rowData"];
  columnDefs?: Aggrid.Col.Defs;
  status?: Status;
  transaction?: AgGridReactProps["rowData"] | RowDataTransaction;
  getRowNodeId?(data): string | number;
  defaultColDef?: Aggrid.Col.Def;
  hidden?: boolean;
}

export interface AggridState {}

export type AggridColDef = Aggrid.Col.Def;

export type AggridResolveDefParams = {
  context: AggridContext;
  state: AggridState;
  columnStateAPI: AggridColumnStateAPI;
  col: AggridColDef;
};

export type AggridGridReadyEvent = GridReadyEvent;
export type AggridApi = GridApi;
export type AggridColumnApi = ColumnApi;
export type AggridRowNode = RowNode;
export type AggridCellRendererParams = ICellRendererParams;
export type AggridRowEvent = RowEvent;
export type AggridRowClickedEvent = AgGridRowClickedEvent;
export type AggridGetContextMenuItemsParams = GetContextMenuItemsParams;
export interface AggridFilterParams extends IFilterParams {
  agGridReact: AgGridReactProps;
}
export type AggridDoesFilterPassParams = IDoesFilterPassParams;
export type AggridFilterComp = Omit<IFilterComp, "getGui">;
export interface AggridHeaderComp extends Omit<ICellRenderer, "getGui" | "refresh"> {
  refresh(params: AggridHeaderCompParams): boolean;
}
export interface AggridHeaderCompParams extends Omit<ICellRendererParams, "getGui" | "column"> {
  column: AggridColumn;

  context: AggridContext;

  // the name to display for the column. if the column is using a headerValueGetter,
  // the displayName will take this into account.
  displayName: string;

  // whether sorting is enabled for the column. only put sort logic into
  // your header if this is true.
  enableSorting: boolean;

  // whether menu is enabled for the column. only display a menu button
  // in your header if this is true.
  enableMenu: boolean;

  // the header the grid provides. the custom header component is a child of the grid provided
  // header. the grid's header component is what contains the grid managed functionality such as
  // resizing, keyboard navigation etc. this is provided should you want to make changes to this
  // cell, eg add ARIA tags, or add keyboard event listener (as focus goes here when navigating
  // to the header).
  eGridHeader: HTMLElement;

  // callback to progress the sort for this column.
  // the grid will decide the next sort direction eg ascending, descending or 'no sort'.
  // pass multiSort=true if you want to do a multi sort (eg user has shift held down when
  // they click)
  progressSort(multiSort: boolean): void;

  // callback to set the sort for this column.
  // pass the sort direction to use ignoring the current sort eg one of 'asc', 'desc' or null
  // (for no sort). pass multiSort=true if you want to do a multi sort (eg user has shift held
  // down when they click)
  setSort(sort: string, multiSort?: boolean): void;

  // callback to request the grid to show the column menu.
  // pass in the html element of the column menu to have the
  // grid position the menu over the button.
  showColumnMenu(menuButton: HTMLElement | null): void;

  [key: string]: any;
}

interface AggridColumn extends Omit<Column, "colId"> {
  colId: string;
}

export type AggridFilterChangedEvent = FilterChangedEvent;
