import React from "react";
import { Modal } from "office-ui-fabric-react";
import Button from "__legacy/sharedFolder/components/common/Button/Button";
import modalStyles from "__legacy/sharedFolder/styles/modal-styles.module.scss";
import styles from "./DisclaimerModal.module.scss";
import classNames from "classnames";
import { emptyArrowFn } from "@/utils";

interface IDisclaimerModalProps {
  onDismiss: () => void;
}
export const firstLine = `Any reference to "Order" with Sea/trade shall refer to an invitation which is non-binding and subject to contract and terms and conditions`;
export const secondLine = `Any reference to "Offer" or "Indication" shall be subject to contract and terms and conditions`;

export const DisclaimerModal = ({ onDismiss }: IDisclaimerModalProps) => {
  return (
    <>
      <Modal isOpen={true} onDismiss={emptyArrowFn}>
        <div className={classNames(modalStyles.modalContainer, styles.disclaimerModal)}>
          <div>
            <h2>Confirm</h2>
            <p>{firstLine}</p>
            <p>{secondLine}</p>
          </div>
          <div className={modalStyles.modalOptions}>
            <Button type="action" ariaLabel="confirm and send" dataTest="ConfirmAndSendOK" onClick={onDismiss}>
              Confirm and Send
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
