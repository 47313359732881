import { INominationsView } from "../Models/IDetails";
import { INominations } from "../../api/models/INominations";
import { IMapper, withNullable } from "./map";

const map: IMapper<INominations, INominationsView> = {
  toApi: (nominations: INominationsView): INominations => {
    return {
      noticePerLaycan: nominations.noticePerLaycan || 0,
      laycanSpread: nominations.laycanSpread || 0,
      finalLaycanNotice: nominations.finalLaycanNotice || 0,
      notes: nominations.notes || "",
      display: nominations.display || "",
    };
  },
  toView: (nominations: INominations): INominationsView => {
    return {
      noticePerLaycan: nominations.noticePerLaycan,
      laycanSpread: nominations.laycanSpread,
      finalLaycanNotice: nominations.finalLaycanNotice,
      display: nominations.display,
      notes: nominations.notes,
    };
  },
  emptyViewModel: {},
};

export const mapNominations = withNullable(map);
