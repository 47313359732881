import React, { useState, useEffect } from "react";
import { Subscription } from "rxjs";
import { NewLaycanBaseEditor } from "sharedFolder/components/editors/NewLaycanBaseEditor/NewLaycanBaseEditor";
import { ILaycanSearchResult, mapLaycanSearchResultToILaycanView } from "sharedFolder/Models/ILaycanSearchResult";
import { getLaycan } from "sharedFolder/apis/LaycanService";
import { useConfig } from "__legacy/sharedFolder/ConfigurationContext";
import { mapLaycan } from "sharedFolder/mappers/mapLaycan";
import { ILaycanView } from "sharedFolder/Models/IDetails";
import { Notes } from "sharedFolder/components/common/Notes/Notes";

interface IProps {
  id?: string;
  isValid: boolean;
  required: boolean | undefined;
  onChange: (laycan?: ILaycanView) => void;
  value?: ILaycanView;
  label?: string;
  disabled?: boolean;
  focus?: boolean;
}

const NewPeriodEditorWithNotes = (props: IProps) => {
  const { disabled, focus, id, onChange, value } = props;
  const [notes, setNotes] = useState<string>(props.value?.notes || "");
  const [laycan, setLaycan] = useState<ILaycanView | undefined>(props.value);
  const { ctradeUrl } = useConfig();
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);

  useEffect(() => {
    return () => {
      subscriptions?.forEach((s) => s.unsubscribe());
    };
  }, [subscriptions]);

  function handleLaycanFetch(value: string) {
    if (value) {
      setSubscriptions((subs) => [
        ...subs,
        getLaycan(value).subscribe((result: ILaycanSearchResult | null) => {
          onChangeLaycan(mapLaycanSearchResultToILaycanView(result));
        }),
      ]);
    }
  }

  const onChangeLaycan = (laycanInput?: ILaycanView) => {
    setLaycan(laycanInput);
    onChange({
      ...(laycanInput || mapLaycan.emptyViewModel),
      notes,
    });
  };

  const onChangeNotes = (notesInput: string) => {
    setNotes(notesInput);
    onChange({
      ...(laycan || mapLaycan.emptyViewModel),
      notes: notesInput,
    });
  };

  return (
    <div>
      <NewLaycanBaseEditor
        id={id || "period"}
        isValid={props.isValid} // passing isValid is what's new about this component
        required={false}
        url={ctradeUrl}
        focus={focus}
        handleFetch={handleLaycanFetch}
        value={value}
        onChange={onChangeLaycan}
        disabled={disabled}
      />
      <Notes
        name={`TextAreaInput-${id}`}
        dataTest={`${id}-input`}
        onChange={(value) => onChangeNotes(value)}
        rows={5}
        maxLength={2000}
        value={notes}
      />
    </div>
  );
};

export default NewPeriodEditorWithNotes;
