import React from "react";
import classNames from "classnames";
import * as ClassNames from "classnames";
import "./Actions.scss";

function Actions(props: Actions.Props) {
  let { className, ...rest } = props;

  className = classNames(className);

  return <app-form-actions class={className} {...rest} />;
}

export { Actions };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace Actions {
  export interface Props extends Omit<ReactHTMLFormProps, "className"> {
    className?: ClassNames.Argument;
  }

  type ReactHTMLFormProps = React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLDivElement>, HTMLDivElement>;
}
