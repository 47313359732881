import dayjs, { isDayjs } from "dayjs";
import { DateField } from "@/components";
import { DataModel, DataModelProps } from "../DataModel";

export class CustomDate extends DataModel<Data> {
  get unitsExist() {
    const { second, minute, hour, month, year } = this;

    return (
      typeof second === "number" ||
      typeof minute === "number" ||
      typeof hour === "number" ||
      typeof month === "number" ||
      typeof year === "number"
    );
  }

  toString() {
    const { unitsExist, date, withoutTimezone, startOfDay } = this;
    let res = dayjs();

    if (typeof date === "string") {
      res = dayjs(date);
    }

    if (date instanceof Date) {
      res = dayjs(date);
    }

    if (isDayjs(date)) {
      res = date;
    }

    if (unitsExist) {
      res = res.startOf("day");

      if (typeof this.second === "number") res = res.add(this.second, "second");
      if (typeof this.minute === "number") res = res.add(this.minute, "minute");
      if (typeof this.hour === "number") res = res.add(this.hour, "hour");
      if (typeof this.month === "number") res = res.add(this.month, "month");
      if (typeof this.year === "number") res = res.add(this.year, "year");
    }

    if (withoutTimezone) {
      const year = res.year();
      const month = res.month();
      const date = res.date();
      const hour = res.hour();
      const minute = res.minute();
      const second = res.second();
      const millisecond = res.millisecond();
      const dateObj = { year, month, date, hour, minute, second, millisecond };

      // @ts-ignore -- missing types for objectSupport https://day.js.org/docs/en/plugin/object-support
      res = dayjs.utc(dateObj);
    }

    if (startOfDay) {
      res = res.startOf("day");
    }

    return res.toISOString();
  }

  toJSON() {
    return this.toString();
  }
}

class DateProps extends DataModelProps<CustomDate> {}

DateProps.prototype.editor = DateField;

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

export interface CustomDate extends Data {
  _: DateProps;
}

type Data = {
  second?: number;
  minute?: number;
  hour?: number;
  month?: number;
  year?: number;
  date?: string | Date | dayjs.Dayjs;
  withoutTimezone?: true;
  startOfDay?: true;
};

// date = date || dayjs();

//     const unitsExist =
//       typeof second === "number" || typeof minute === "number" || typeof hour === "number" || typeof month === "number" || typeof year === "number";

//     if (unitsExist) {
//       if (typeof second === "number") res = res.add(this.second, "second");
//       if (typeof minute === "number") res = res.add(minute, "minute");
//       if (typeof hour === "number") res = res.add(hour, "hour");
//       if (typeof month === "number") res = res.add(month, "month");
//       if (typeof year === "number") res = res.add(year, "year");

//       return res.toISOString();
//     }

//     if (this.withoutTimezone) {
//       return withoutTimezone(date).toISOString();
//     }
