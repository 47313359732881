// BLACKBOX
import { OrderNotesAndAttachmentsForDashboard } from "offerRep/OrderDetails/OrderNotesAndAttachments";
import { FetchInterceptor } from "__legacy/dashboard/components/FetchInterceptor/FetchInterceptorOld";
import { OfferRepNegotiationContext } from "offerRep/OwnerIndicationForm/Contexts/OfferRepNegotiationContext";
import { OwnerDashboardService } from "offerRep/Contexts/OwnerDashboardService";
import offerRepMapper from "offerRep/api/OfferRepMapper";
import React, { useContext } from "react";
import { config } from "@/config";
import { Context } from "../Negotiation";
export function NotesAndAttachments() {
  const context = useContext(Context);
  const negotiation = context.negotiation;
  const version = negotiation?._.version; // eslint-disable-line @typescript-eslint/no-unused-vars
  const ownerDashboardService = OwnerDashboardService(
    config.ctradeUrl,
    // @ts-ignore
    negotiation?.orderId,
    negotiation?.id,
    negotiation?.updateToken
  );
  return (
    <div className="OrderNegotiationNotesAndAttachments LEGACY_CONTAINER">
      <FetchInterceptor baseUrl={config.ctradeUrl} exceptions={["/token$"]}>
        <OfferRepNegotiationContext.Provider
          value={{
            offerRepApi: ownerDashboardService,
            firmOrIndicationState: "INDICATION_ONLY",
            setFirmOrIndicationState: console.info.bind(null, "Something executed setFirmOrIndicationState"),
          }}
        >
          {negotiation?.updateToken && (
            <OrderNotesAndAttachmentsForDashboard
              // @ts-ignore
              negotiation={offerRepMapper(negotiation)}
            />
          )}
        </OfferRepNegotiationContext.Provider>
      </FetchInterceptor>
    </div>
  );
}
