import React, { ComponentType } from "react";
import { Status } from "___REFACTOR___/models";
import "./Spinner.scss";

export function Spinner(props: Props) {
  const { status, type = "Striped" } = props;

  if (!type || !status) return null;

  const { message, loading } = status;

  if (!message && !loading) return null;

  const Spinner = SPINNER_MAP[type];
  const label = message && <label>{message}</label>;
  const spinner = loading && (
    <div className="Spinner-spinner">
      <Spinner />
    </div>
  );

  return (
    <div className="Spinner">
      {spinner}
      {label}
    </div>
  );
}

function Striped() {
  return (
    <div className="StripedSpinner">
      <div className="stripe">
        <div className="stripe-inner" />
      </div>
      <div className="stripe">
        <div className="stripe-inner" />
      </div>
      <div className="stripe">
        <div className="stripe-inner" />
      </div>
      <div className="stripe">
        <div className="stripe-inner" />
      </div>
      <div className="stripe">
        <div className="stripe-inner" />
      </div>
    </div>
  );
}

const SPINNER_MAP = {
  Striped,
} as Record<Type, ComponentType>;

interface Props {
  type?: Type;
  status?: Status;
}

type Type = "Striped";
