import { useEffect, useState } from "react";
import Button from "__legacy/sharedFolder/components/common/Button/Button";
import styles from "./DistributionListPanel.module.scss";
import modalStyles from "__legacy/sharedFolder/styles/modal-styles.module.scss";
import { IEmailList, IEmailListPUT } from "../../services/settings";
import { DistributionEmailEditor } from "./DistributionEmailEditor";
import { Modal } from "office-ui-fabric-react";
import { useAPIFetch } from "./useAPIFetch";

interface IDistributionEmail {
  email: string;
  expandEditor?: boolean;
  item: IEmailList;
  mode: "edit" | "select";
  toggleEditor?: () => void;
  updateList?: (updatedItem: IEmailList) => void;
}

export const DistributionEmail = ({ email, expandEditor, item, mode, toggleEditor, updateList }: IDistributionEmail) => {
  const [deleteDialogOpened, setDeleteDialogOpened] = useState(false);

  const [updatedListBody, setUpdatedListBody] = useState<null | IEmailListPUT>(null);

  // deleting an email actually required a PUT
  const deleteAction = useAPIFetch<IEmailListPUT>(`/settings/emaillists/${item.id}`, "PUT", updatedListBody);

  useEffect(() => {
    if (mode !== "edit") {
      return;
    }
    function softDeleteEmail() {
      // to delete an email we are actually going to update the group
      updateList &&
        updateList({
          ...item,
          values: [...item.values.filter((value) => value !== email)],
        });
    }

    if (deleteAction && deleteAction.response) {
      softDeleteEmail();
      setDeleteDialogOpened(false);
    }
  }, [deleteAction, email, item, mode, updateList]);

  function submitDelete() {
    setUpdatedListBody({
      data: {
        name: item.name,
        values: [...item.values.filter((value) => value !== email)],
      },
      updateToken: item.updateToken,
    });
  }

  return (
    <>
      {deleteDialogOpened && (
        <Modal isOpen={true} onDismiss={() => setDeleteDialogOpened(false)} className="modalPanel">
          <div className={modalStyles.modalContainer}>
            <p>{`Are you sure you want to delete email '${email}'?`}</p>
            {deleteAction.error && <p className={styles.formErrorMessage}>{deleteAction.error}</p>}
          </div>
          <div className={modalStyles.modalOptions}>
            <Button type="action" ariaLabel="delete email" disabled={deleteAction.fetching} onClick={submitDelete}>
              OK
            </Button>
            <Button type="flat" onClick={() => setDeleteDialogOpened(false)} ariaLabel="delete email cancel">
              Cancel
            </Button>
          </div>
        </Modal>
      )}
      {mode === "edit" && expandEditor && updateList && toggleEditor ? (
        <div className={styles.distributionEmailContainer}>
          Edit Email Address
          <DistributionEmailEditor
            email={email}
            item={item}
            currentEmails={item.values}
            onEditorClose={toggleEditor}
            updateList={updateList}
          />
        </div>
      ) : (
        <div className={styles.emailRow}>
          <div className={styles.entityName}>{email}</div>
          <div className={styles.alignedRight}>
            {mode === "edit" && (
              <>
                <Button className={styles.edit} type="plain" icon="mode-edit" title="Edit Email" onClick={toggleEditor} />
                <Button type="plain" icon="delete" title="Delete Email" onClick={() => setDeleteDialogOpened(true)} />
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
