import React, { useState } from "react";
import { Vessels } from "../negotiate/Negotiation/Vessels/Vessels";
import Heading from "sharedFolder/components/common/Heading/Heading";
import { IVesselView } from "sharedFolder/Models/IDetails";
import { InfoMessage } from "sharedFolder/components/common/InfoMessage/InfoMessage";
import { VesselPanelNegotiationContainer, VesselPanelActions } from "../common/VesselNomination/VesselPanelNegotiationContainer";
import { useDismissableNotification } from "./DismissableNotificationContext";
import styles from "./LiftingVesselManagement.module.scss";

interface IProps {
  liftingId: string;
  liftingCargoId: string;
  orderId: string;
  vessels?: IVesselView[];
}

const LiftingVesselManagement = (props: IProps) => {
  const { showNotification } = useDismissableNotification();
  const [openVessel, setOpenVessel] = useState<IVesselView | null>();
  const { liftingId, vessels } = props;
  return (
    <section className={styles.container}>
      <Heading className={styles.overridenHeaderStyles}>Nominated Vessel(s)</Heading>
      <div>
        {!vessels || !vessels.length ? (
          <InfoMessage message="" title={"Waiting for the owner to nominate a vessel"} />
        ) : (
          <div className={styles.vesselRow}>
            <span>Vessel(s)</span>
            <Vessels vessels={vessels} onVesselClicked={(vessel) => setOpenVessel(vessel)} />
          </div>
        )}
      </div>
      {openVessel && (
        <VesselPanelNegotiationContainer
          {...props}
          onPanelClosed={(action?: VesselPanelActions) => {
            setOpenVessel(null);
            if (action === "accept") {
              showNotification("ACCEPT_LIFTING", { liftingId });
            } else if (action === "reject") {
              showNotification("REJECT_LIFTING");
            }
          }}
          nominatingVessel={openVessel}
          vessels={vessels}
        />
      )}
    </section>
  );
};

export default LiftingVesselManagement;
