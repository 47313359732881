import { TradeAPI } from "@/apis";
import { format } from "@/utils";
import { NominationsEditor } from "@/components";
import { DataModel, DataModelProps } from "../DataModel";

export class Nominations extends DataModel<Data> {
  onUpdate() {
    delete this.display;
    delete this.shortDisplay;
  }

  toString() {
    if (!this.isValid()) return "";

    const notes = this.notes || "";

    return `${format.number.display(this.noticePerLaycan)}d  ${format.number.display(this.laycanSpread)}d  ${
      this.finalLaycanNotice
    }d ${notes}`;
  }

  toGridView() {
    if (!this.isValid()) return "";

    return `${format.number.display(this.noticePerLaycan)}d  ${format.number.display(this.laycanSpread)}d  ${
      this.finalLaycanNotice
    }d`;
  }

  isValid() {
    return (
      typeof this.noticePerLaycan === "number" &&
      typeof this.laycanSpread === "number" &&
      typeof this.finalLaycanNotice === "number"
    );
  }
}

class NominationsProps extends DataModelProps<Data> {}
NominationsProps.prototype.editor = NominationsEditor;
NominationsProps.prototype.label = "Nominations";
Nominations.prototype._ = new NominationsProps();

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

export interface Nominations extends Data {
  _: NominationsProps;
}

type Data = DeepPartial<TradeAPI["Nominations"]>;
