import { ComponentType } from "react";
import { makeAutoObservable } from "mobx";
import { RedirectProps, RouteProps } from "react-router";
import {
  compile as pathToRegexpCompile,
  match as pathToRegexpMatch,
  ParseOptions,
  TokensToRegexpOptions,
  RegexpToFunctionOptions,
  Match,
} from "path-to-regexp";
import { history, HistoryState } from "@/history";
import { auth, Auth, router } from "@/models";
import { IconProps, RouteStub, SeaChatWidgetProps } from "@/components";

export class Route {
  constructor(seed: Seed, parent?: Route) {
    this.seed = seed;
    this.pageTitle = seed.pageTitle;
    this.path = seed.path;
    this.icon = seed.icon;
    this.desc = seed.desc;
    this.label = seed.label;
    this.getMiscInfo = seed.getMiscInfo;
    this.exact = seed.exact;
    this.comp = seed.comp || RouteStub;
    this.auth = seed.auth;
    this.parent = parent;
    this._seaHeaderHidden = seed.seaHeaderHidden;
    this._seaChatWidgetProps = seed.seaChatWidgetProps;
    this.absPath = `${parent?.absPath || ""}${seed.path || ""}`.replace("//", "/");
    this.match = match.bind(null, this.absPath);
    this.compile = compile.bind(null, this.absPath);

    const { arr, map } = createChildren(seed.children, this);

    this.children = arr;
    this.childrenMap = map;

    makeAutoObservable(this, {
      replace: false,
      get: false,
      push: false,
    });
  }

  get active() {
    return router.active === this;
  }

  get authorised() {
    if (!this.auth) return true;

    return this.auth(auth);
  }

  get sidenav() {
    if (this._sidenav === null) return null;

    return this._sidenav || this.parent?.sidenav;
  }

  get nav() {
    return this._nav || this.parent?.nav;
  }

  get seaHeaderHidden() {
    return this._seaHeaderHidden || this.parent?.seaHeaderHidden;
  }

  get seaChatWidgetProps() {
    return this._seaChatWidgetProps || this.parent?.seaChatWidgetProps;
  }

  get = (childName: string) => {
    return this.childrenMap[childName];
  };

  push = (params?: Params, state?: HistoryState, searchParams?: URLSearchParams) => {
    let searchParamsString = `${searchParams}`;
    searchParamsString = searchParams ? `?${searchParams}` : "";

    const pathname = `${this.compile(params)}${searchParamsString}`;

    history.push(pathname, state);
  };

  replace = (params?: Params, state?: HistoryState, searchParams?: URLSearchParams) => {
    let searchParamsString = `${searchParams}`;
    searchParamsString = searchParams ? `?${searchParams}` : "";

    const pathname = `${this.compile(params)}${searchParamsString}`;

    history.replace(pathname, state);
  };
}

function createChildren(children: SeedChildren | undefined, parent: Route) {
  const arr: Route[] = [];
  const map: RouteChildrenMap = {};
  const res = { arr, map };

  if (!children) return res;

  const entries = Object.entries(children);

  for (let i = 0; i < entries.length; i += 1) {
    const [name, seed] = entries[i];

    if (seed.path === undefined) seed.path = `/${name}`;

    const instance = createChild(seed, parent);

    arr[i] = instance;
    map[name] = instance;
  }

  return res;
}

function createChild(route: Seed, parent: Route) {
  const child = new Route(route, parent);

  return child;
}

function match(path: string, string?: string, config?: ParseOptions & TokensToRegexpOptions & RegexpToFunctionOptions) {
  if (!string) return false;

  const matcher = pathToRegexpMatch(path, config);
  const match = matcher(string);

  return match;
}

function compile(path: string, params?: Params, config?: ParseOptions & TokensToRegexpOptions & RegexpToFunctionOptions) {
  const matcher = pathToRegexpCompile(path, config);
  const match = matcher(params);

  return match;
}

export interface Route extends Omit<Seed, "children" | "sidenav" | "nav" | "redirect"> {
  parent?: Route;
  seed: Seed;
  active: boolean;
  children: Route[];
  childrenMap: RouteChildrenMap;
  _seaHeaderHidden?: boolean;
  _sidenav?: (Route | undefined)[];
  _nav?: (Route | undefined)[];
  _seaChatWidgetProps?: SeaChatWidgetProps;
  absPath: string;
  match: (testString?: string, config?: ParseOptions & TokensToRegexpOptions & RegexpToFunctionOptions) => Match<object>;
  compile: (params?: Params, config?: ParseOptions & TokensToRegexpOptions & RegexpToFunctionOptions) => string;
  redirect: { from: Route; to: Route } | (() => { from: Route; to: Route } | void) | void;
}

type RouteChildrenMap = Record<string, Route>;

interface Seed extends Omit<RouteProps, "component"> {
  path?: string;
  exact?: boolean;
  comp?: ComponentType<any>;
  children?: SeedChildren;
  pageTitle?: string;
  icon?: IconProps["icon"];
  desc?: string;
  label?: string;
  sidenav?: Key[] | null;
  nav?: Key[];
  seaChatWidgetProps?: SeaChatWidgetProps;
  getMiscInfo?: (any?) => any;
  redirect?: Redirect | string | (() => Redirect | string | undefined);
  seaHeaderHidden?: boolean;
  auth?: (auth: Auth) => boolean;
}

type SeedChildren = Record<string, Seed>;

type Params = AnyRecord;

export type Key =
  | {
      key: string;
      optional?: boolean;
    }
  | string;

interface Redirect extends RedirectProps {
  to: string;
}

export type RouteSeed = Seed;
