import React, { useEffect, useRef, useMemo, createContext, useContext } from "react";
import { createPortal } from "react-dom";
import { Transition } from "react-transition-group";
import classNames, * as ClassNames from "classnames";
import { emptyFn, useKeepValue } from "___REFACTOR___/utils";
import { Status } from "___REFACTOR___/models";
import { Icon, Status as StatusComp, NewDialog } from "___REFACTOR___/components/common";
import "./Dialog.scss";

function Dialog(props: Props) {
  const { isOpen, useNewVersion = false } = props;
  const staleProps = useKeepValue(props, [isOpen], 250);
  const finalProps = isOpen ? props : staleProps;
  const {
    status,
    children,
    suppressCloseBtn = false,
    className,
    onClose,
    suppressPortal = false,
    autoFocusChildrenExist = false,
  } = finalProps;
  const domRef = useRef() as ReactElementDomRef;
  const keyboardEventMap = useMemo(getKeyboardEventMap, []);

  useEffect(setup, [domRef.current]);

  if (useNewVersion) {
    return (
      <Context.Provider value={finalProps}>
        <NewDialog open={isOpen}>{children ? children : <StatusComp status={status} />}</NewDialog>
      </Context.Provider>
    );
  }

  const dialog = (
    <Transition {...TRANSITION_PROPS} in={isOpen}>
      {(transStatus) => {
        if (transStatus === "exited") return null;

        return (
          <Context.Provider value={finalProps}>
            <app-dialog-overlay class={transStatus}>
              <app-dialog-backdrop onClick={onClose} />
              <app-dialog
                class={classNames(className, {
                  [`${className}`]: className,
                  "suppress-close-btn": suppressCloseBtn,
                })}
                tabIndex={0}
                ref={domRef}
              >
                {children ? children : <StatusComp status={status} />}
                <Icon name="close" className="app-dialog-close-btn" onClick={onClose} />
              </app-dialog>
            </app-dialog-overlay>
          </Context.Provider>
        );
      }}
    </Transition>
  );

  if (suppressPortal) return dialog;

  return createPortal(dialog, window.react.services.dialogPortal);

  function setup() {
    if (!domRef.current) return;

    if (!autoFocusChildrenExist) domRef.current.focus();

    domRef.current.addEventListener("keydown", onKeyDown);

    return removeEventListeners;

    function onKeyDown(e: KeyboardEvent) {
      const eventHandler = keyboardEventMap[`${e.shiftKey ? "Shift" : ""}${e.key}`];

      if (eventHandler) {
        e.stopPropagation();
        eventHandler?.(e);
      }
    }

    function removeEventListeners() {
      domRef.current?.removeEventListener("keydown", onKeyDown);
    }
  }

  function getKeyboardEventMap() {
    return { Escape: onClose };
  }
}

Dialog.useContext = () => {
  return useContext(Context);
};

export const Context = createContext<ContextValue>({ isOpen: false, onClose: emptyFn });

const TRANSITION_PROPS = {
  timeout: { exit: 250 },
};

export { Dialog };

/*-------------------------------------------------------------*/
/*                            TYPES                            */
/*-------------------------------------------------------------*/

declare namespace Dialog {
  export { Props };
}

interface Props extends HTMLProps {
  suppressCloseBtn?: boolean;
  useNewVersion?: boolean;
  autoFocusChildrenExist?: boolean;
  className?: ClassNames.Argument;
  status?: Status;
  children?: React.ReactNode;
  dialogTitle?: React.ReactNode;
  dialogActions?: React.ReactNode;
  suppressPortal?: boolean;
  isOpen: boolean;
  onClose(...args): void;
}

export type ContextValue = Props;

type HTMLProps = Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "className">;
