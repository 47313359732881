import moment from "moment";
import { useInterval } from "./useInterval";
import { IClock } from "sharedFolder/components/common/interfaces";

const leadingZero = (value: number): string => {
  return value.toString().padStart(2, "0");
};

function calculateNow(): IClock {
  const now = moment().utc();
  return {
    Hours: leadingZero(now.hours()),
    Minutes: leadingZero(now.minutes()),
  };
}

type IntervalFunction = (timeDiff: IClock) => void;

export function useTimer(callback: IntervalFunction) {
  useInterval(() => {
    const countNow = calculateNow();
    callback(countNow);
  }, 1000);
}
