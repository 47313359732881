import * as React from "react";
import TextArea from "sharedFolder/components/common/TextArea/TextArea";

interface IOrderNotesPartInputProps {
  id: string;
  value?: string;
  onChange: (value: string) => void;
  focus?: boolean;
}

// TODO: move to sharedFolder
export class OrderNotesPartInput extends React.PureComponent<IOrderNotesPartInputProps> {
  constructor(props: IOrderNotesPartInputProps) {
    super(props);
    this.inputChangedHandler = this.inputChangedHandler.bind(this);
    this.inputBlurHandler = this.inputBlurHandler.bind(this);
  }

  public render() {
    return (
      <TextArea
        focus={this.props.focus}
        label=""
        name={`TextAreaInput-${this.props.id}`}
        ariaLabel={`TextAreaInput-${this.props.id}`}
        dataTest={`${this.props.id}-input`}
        onChange={this.inputChangedHandler}
        onBlur={this.inputBlurHandler}
        defaultValue={this.props.value}
        rows={5}
        maxLength={2000}
        value={this.props.value}
      />
    );
  }

  private inputChangedHandler(e: React.FormEvent<HTMLTextAreaElement>) {
    this.props.onChange(e.currentTarget.value);
  }

  private inputBlurHandler(e: React.FormEvent<HTMLTextAreaElement>) {
    let value = e.currentTarget.value;
    value = e.currentTarget.value.replace(/\n\s*\n\s*\n/g, "\n\n");
    if (value) {
      this.props.onChange(value);
    }
  }
}
