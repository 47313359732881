import React, { Component } from "react";
import { IAccountSearchResult } from "../../../../models/client/IAccountSearchClient";
import { IInputProps } from "sharedFolder/components/common/interfaces";
import Input from "sharedFolder/components/common/Input/Input";

interface IAccountInputState {
  value: string | undefined;
}

interface IAccountInputProps extends IInputProps {
  type?: "number";
  disabled?: boolean;
  min?: number;
  max?: number;
  onChange: (value: any) => void;
  required?: boolean;
  value?: { account?: IAccountSearchResult };
}

class AccountInput extends Component<IAccountInputProps, IAccountInputState> {
  constructor(props: IAccountInputProps) {
    super(props);
    this.renderAccount = this.renderAccount.bind(this);
    this.state = {
      value: this.renderAccount(this.props.value && this.props.value.account) || "",
    };
  }

  componentDidUpdate(prevProps: IAccountInputProps) {
    const { value } = prevProps;
    const prevPropsValue = this.renderAccount(value && value.account) || "";
    const currentPropsValue = this.renderAccount(this.props.value && this.props.value.account) || "";
    if (currentPropsValue !== prevPropsValue) {
      this.setState({ value: currentPropsValue });
    }
  }

  // TODO: HACKY solution with focu => need to have defaultValue in Input component
  public render() {
    return (
      <Input
        dataTest={this.props.dataTest}
        id={this.props.id}
        ariaLabel={this.props.id}
        key={this.props.id}
        isValid={this.props.isValid}
        onChange={this.handleOnChange}
        value={this.state.value}
        type="text"
        dataKey={this.props.dataKey}
        disabled={this.props.disabled}
        required={this.props.required}
        focus={true}
      />
    );
  }

  private handleOnChange = (event: React.FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    this.setState({ value }, () => {
      this.props.onChange({
        document: {
          accountName: value,
        },
      });
    });
  };

  private renderAccount(account?: IAccountSearchResult) {
    return (account && account.document && account.document.accountName) || "";
  }
}

export default AccountInput;
