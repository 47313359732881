import {
  AddressCommission,
  BallastBonus,
  BrokerCommission,
  CargoSize,
  CargoType,
  ChartererAccount,
  CpSearchItem,
  CustomDate,
  DateOffset,
  DeliveryLocation,
  Demurrage,
  DischargeLocation,
  Duration,
  FreightRate,
  HireRate,
  CleaningPrice,
  SupplyPrice,
  TradingExclusions,
  BunkerDelivery,
  BunkerRedelivery,
  CargoExclusionsText,
  Laycan,
  LoadLocation,
  OrderDetails,
  OrderTemplate,
  OwnerAccount,
  Period,
  RedeliveryLocation,
  TCTNotes,
  Termset,
  Timezone,
  Vessels,
  VesselSize,
  ViaLocation,
  VOYNotes,
  OrderCoaDetails,
  COACargoSize,
  COANotes,
} from "___REFACTOR___/models";
import dayjs from "dayjs";
import { TradeAPI, tradeAPI } from "___REFACTOR___/apis";
import { auth, dialog } from "___REFACTOR___/services";
import { usageMetrics, OrderCreationTypeEvents } from "@/services/UsageMetrics";

class Body {
  constructor(seed: Seed, selectedStatus: Body.Status) {
    Object.assign(this, seed);
    const user = auth.trade.user;
    const timeZone = new Timezone({ id: user?.Timezone, display: user?.Timezone });

    this.statusDetails = {
      selectedStatus,
      type: "dateOffset",
      charterPartyDate: new CustomDate({ value: new Date() }),
      date: undefined,
      dateOffset: undefined,
      timeZone,
      auditNotes: "",
    };
  }

  subjectTermsetMainTermTemplates = [
    {
      title: "Operational Subs",
      TermTemplateType: "Operational",
      content: "",
      termId: "OPERATIONAL_SUBS",
    },
    {
      title: "Commercial Subs",
      TermTemplateType: "Commercial",
      content: "",
      termId: "COMMERCIAL_SUBS",
    },
  ] as TradeAPI.Termset.Content.Term[];

  get type() {
    if (this.typeMap.Voy) {
      return "Voy";
    }
    if (this.typeMap.Tct) {
      return "Tct";
    }
    if (this.typeMap.Coa) {
      return "Coa";
    }
    return "Voy";
  }

  get selectedStatus() {
    if (this.statusMap.MainTerms) {
      return "MainTerms";
    }
    if (this.statusMap.OnSubs) {
      return "OnSubs";
    }
    if (this.statusMap.Fixed) {
      return "Fixed";
    }
    return null;
  }

  get typeId() {
    return TradeAPI.TYPE_ID_BY_TYPE[this.type];
  }

  get contractUrlBySearchItem() {
    const { cpFormName, cpId } = this.currentCpSearchItem;
    return `https://contracts.sea.live//#/cp/${cpFormName.toLowerCase()}/${cpId}/1`;
  }

  toJSON() {
    const details = this.details;
    if (this.type === "Tct") {
      const req = {
        chartererAccount: details.chartererAccount,
        laycan: details.laycan,
        cargoType: details.cargoType,
        addressCommission: details.addressCommission,
        brokerCommission: details.brokerCommission,
        vesselSize: details.vesselSize,
        deliveryLocation: details.deliveryLocation,
        redeliveryLocation: details.redeliveryLocation,
        viaLocation: details.viaLocation,
        ballastBonus: details.ballastBonus,
        hireRate: details.hireRate,
        cleaningPrice: details.cleaningPrice,
        supplyPrice: details.supplyPrice,
        tradingExclusions: details.tradingExclusions,
        bunkerDelivery: details.bunkerDelivery,
        bunkerRedelivery: details.bunkerRedelivery,
        cargoExclusionsText: details.cargoExclusionsText,
        duration: details.duration,
        tctNotes: details.tctNotes?.toJSON(),
        orderType: "Tct",
        jumpToStatus: this.statusDetails.selectedStatus,
        responseDateTime:
          this.statusDetails.type === "date" ? this.statusDetails.date?.toString() : this.statusDetails.dateOffset?.toString(),
        vessels: details.vessels?.vessels,
        owningCompany: details.ownerAccount,
        termset: this.termset?.content,
        previouslyExecutedCpId: details.previouslyExecutedCpId,
        userTimeZone: this.statusDetails.timeZone?.toString(),
        cpDate: this.statusDetails.charterPartyDate.date?.toISOString(),
      } as any;
      console.log(req);
      return req;
    }
    if (this.type === "Voy") {
      const req = {
        chartererAccount: details.chartererAccount,
        laycan: details.laycan,
        cargoType: details.cargoType,
        addressCommission: details.addressCommission,
        brokerCommission: details.brokerCommission,
        cargoSize: details.cargoSize,
        loadLocation: details.loadLocation,
        dischargeLocation: details.dischargeLocation,
        freightRate: details.freightRate,
        demurrage: details.demurrage,
        voyageNotes: details.voyageNotes?.toJSON(),
        orderType: "Voy",
        jumpToStatus: this.statusDetails.selectedStatus,
        responseDateTime:
          this.statusDetails.type === "date" ? this.statusDetails.date?.toString() : this.statusDetails.dateOffset?.toString(),
        vessels: details.vessels?.vessels,
        owningCompany: details.ownerAccount,
        termset: this.termset?.content,
        previouslyExecutedCpId: details.previouslyExecutedCpId,
        userTimeZone: this.statusDetails.timeZone?.toString(),
        cpDate: this.statusDetails.charterPartyDate.date?.toISOString(),
      } as any;
      console.log(req);
      return req;
    }
    return undefined;
  }

  async onSubmit() {
    // submit the termset
    // maybe don't need this
    const res = await tradeAPI.ownerDealCapture.post(
      this.toJSON() as TradeAPI.Enp.OwnerDealCapture.Put.Req.VoyBody | TradeAPI.Enp.OwnerDealCapture.Put.Req.TctBody
    );
    if (!res.ok) await dialog.open({ type: "error", message: "Deal Capture Submit Failed" });
    // go to home page

    const charterType = this.toJSON().types;

    switch (charterType) {
      case "Voy":
        usageMetrics.trackEvent(OrderCreationTypeEvents.DEAL_CAPTURE_VOY, {
          orderType: "Deal Capture",
          charterType,
        });
        break;
      case "Tct":
        usageMetrics.trackEvent(OrderCreationTypeEvents.DEAL_CAPTURE_TCT, {
          orderType: "Deal Capture",
          charterType,
        });
        break;
      case "Coa":
        usageMetrics.trackEvent(OrderCreationTypeEvents.DEAL_CAPTURE_COA, {
          orderType: "Deal Capture",
          charterType,
        });
        break;
    }

    return res.data;
  }

  async getCpTermsets(cpSearchItem: CpSearchItem) {
    // get the termsets for a single CP
    const res = await tradeAPI.termsets.search.byLayoutName.get(cpSearchItem.cpFormName, this.typeId);
    if (!res.ok) await dialog.open({ type: "error", message: "Termset Search Failed" });
    return res.data.value;
  }

  async searchCps(searchTerm: string) {
    const searchParams = new URLSearchParams();
    searchParams.set("q", searchTerm);
    const res = await tradeAPI.contracts.search.get(searchParams);
    if (!res.ok) await dialog.open({ type: "error", message: "CP Search Failed" });
    return res.data;
  }

  async getCp(cpId: TradeAPI.CpSearchItem.Id) {
    const res = await tradeAPI.contracts.get(cpId);
    if (!res.ok) await dialog.open({ type: "error", message: "CP Import Failed" });
    return res.data;
  }

  async getTermset(termsetId: TradeAPI.Termset.Id) {
    const res = await tradeAPI.termsets.get(termsetId);
    if (!res.ok) await dialog.open({ type: "error", message: "Termset Import Failed" });
    return res.data;
  }

  async getCpsWithDate(searchTerm: string, dateRange: Period) {
    const dateFormat = "YYYY-MM-DD";
    const from = dayjs.utc(dateRange.from).format(dateFormat);
    const to = dayjs.utc(dateRange.to).format(dateFormat);
    const searchParams = new URLSearchParams();
    searchParams.set("q", searchTerm);
    searchParams.set("datefrom", from);
    searchParams.set("dateto", to);
    const res = await tradeAPI.contracts.search.get(searchParams);
    return res.data;
  }

  inheritFromTemplate(orderTemplate: OrderTemplate) {
    const { template } = orderTemplate;
    const { order: o, voyage: v, tct: t, coa: c } = template as Partial<OrderTemplate["template"]>;
    if (v && Object.keys(v).length)
      this.typeMap = {
        Voy: true,
        Tct: undefined,
        Coa: undefined,
        Lft: undefined,
      };
    if (t && Object.keys(t).length)
      this.typeMap = {
        Voy: undefined,
        Tct: true,
        Coa: undefined,
        Lft: undefined,
      };
    if (c && Object.keys(c).length)
      this.typeMap = {
        Voy: undefined,
        Tct: undefined,
        Coa: true,
        Lft: undefined,
      };

    this.details = {} as Body.Details;
    if (o?.chartererAccount) this.details.chartererAccount = new ChartererAccount(o.chartererAccount);
    if (o?.addressCommission) this.details.addressCommission = new AddressCommission(o.addressCommission);
    if (o?.brokerCommission) this.details.brokerCommission = new BrokerCommission(o.brokerCommission);
    if (o?.cargoType) this.details.cargoType = new CargoType(o.cargoType);
    if (v?.cargoSize) this.details.cargoSize = new CargoSize(v.cargoSize);
    if (v?.dischargeLocation) this.details.dischargeLocation = new DischargeLocation(v?.dischargeLocation);
    if (v?.loadLocation) this.details.loadLocation = new LoadLocation(v?.loadLocation);
    if (t?.deliveryLocation) this.details.deliveryLocation = new DeliveryLocation(t.deliveryLocation);
    if (t?.viaLocation) this.details.viaLocation = new ViaLocation(t.viaLocation);
    if (t?.redeliveryLocation) this.details.redeliveryLocation = new RedeliveryLocation(t.redeliveryLocation);
    if (t?.duration) this.details.duration = new Duration(t.duration);
    if (t?.vesselSize) this.details.vesselSize = new VesselSize(t.vesselSize);
    if (v?.notes) this.details.voyageNotes = new VOYNotes(v.notes);
    if (t?.notes) this.details.tctNotes = new TCTNotes(t.notes);
    this.details.previouslyExecutedCpId = null;
  }
}

export { Body };

/*-------------------------------------------------------------*/
/*                            TYPES                            */
/*-------------------------------------------------------------*/
type SearchOptions = "Termset Template" | "Previously Executed";

interface Body extends Seed {
  details: Body.Details;
  statusDetails: Body.StatusDetails;
  passedType: Body.Type;
  termsetId?: TradeAPI.Termset.Id;
  termset?: Termset;
  template: OrderTemplate;
  currentCpSearchItem: CpSearchItem;
}

declare namespace Body {
  export { Seed };
}

declare namespace Body {
  type As = TradeAPI.User.CompanyRole.Owner | TradeAPI.User.CompanyRole.Charterer;
  type Type = TradeAPI.Negotiation.Type;

  class Details implements OrderDetails, OrderCoaDetails {
    coaNotes: COANotes;
    coaCargoSize?: COACargoSize | undefined;
    laycan?: Laycan;
    cargoType?: CargoType;
    vessels?: Vessels;
    addressCommission?: AddressCommission;
    brokerCommission?: BrokerCommission;
    types?: TradeAPI.Order.Type[];
    chartererAccount?: ChartererAccount;
    ownerAccount?: OwnerAccount;
    period?: Period;
    loadLocation?: LoadLocation;
    dischargeLocation?: DischargeLocation;
    cargoSize?: CargoSize;
    duration?: Duration;
    vesselSize?: VesselSize;
    deliveryLocation?: DeliveryLocation;
    viaLocation?: ViaLocation;
    redeliveryLocation?: RedeliveryLocation;
    tctNotes?: TCTNotes;
    voyageNotes?: VOYNotes;
    freightRate?: FreightRate;
    demurrage?: Demurrage;
    hireRate?: HireRate;
    cleaningPrice?: CleaningPrice;
    supplyPrice?: SupplyPrice;
    tradingExclusions?: TradingExclusions;
    bunkerDelivery?: BunkerDelivery;
    bunkerRedelivery?: BunkerRedelivery;
    cargoExclusionsText?: CargoExclusionsText;
    ballastBonus?: BallastBonus;
    previouslyExecutedCpId: number | null;
  }

  type StatusDetails = {
    selectedStatus: Status;
    type: "date" | "dateOffset";
    date: CustomDate | undefined;
    dateOffset: DateOffset | undefined;
    charterPartyDate: CustomDate;
    timeZone: Timezone;
    auditNotes: string;
  };
  type Status = TradeAPI.Negotiation.Status.MainTerms | TradeAPI.Negotiation.Status.OnSubs | TradeAPI.Negotiation.Status.Fixed;
}

interface Seed {
  typeMap: { [type in Body.Type]?: true };
  statusMap: { [status in Body.Status]?: true };
  as?: Body.As;
  cpSearchValue?: string;
  cpSearchDateRange?: Period;
  cpSearchResults?: any[];
  selectedOption?: SearchOptions;
}
