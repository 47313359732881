import React, { ReactNode, useEffect, useRef, MutableRefObject, ButtonHTMLAttributes } from "react";
import { NavLink } from "react-router-dom";
import { kebabCase } from "lodash";
import classNames, * as ClassNames from "classnames";
import { useRipple, wait } from "___REFACTOR___/utils";
import { Status } from "___REFACTOR___/models";
import { Route } from "___REFACTOR___/models/common/Route";
import { Icon, Img, ImgProps } from "___REFACTOR___/components";
import { Spinner } from "./Spinner";
import "./Button.scss";

function Button(props: Button.Props) {
  const {
    label,
    children = label,
    variant = "flat",
    img,
    icon,
    loading,
    disabled = loading,
    hidden,
    domRef,
    href,
    to,
    background,
    openHrefInNewWindow,
    tooltip,
    status,
    autoFocus,
    className,
    title = tooltip,
    dataTest = typeof children === "string" ? kebabCase(children) : undefined,
    ...rest
  } = props;

  const defaultDOMRef = useRef(null) as Button.DomRef;
  const _domRef = domRef || defaultDOMRef;
  const _loading = props.loading || status?.loading;
  const _to = typeof to === "string" ? to : to?.compile();
  const _className = classNames(className, variant, background, typeof icon === "string" ? icon : icon?.name, {
    "contains-icon": icon,
    "has-text": children,
    "icon-only": icon && !children,
    hidden,
    disabled,
    loading: _loading,
  });

  useEffect(onMount, []);
  useRipple(_domRef);

  const content = (
    <>
      <Img img={img} />
      <Icon icon={icon} />
      <Spinner visible={loading} />
      {children && <app-button-text>{children}</app-button-text>}
    </>
  );
  let anchor;

  if (href) {
    anchor = <a href={href} onClick={onAnchorClick} tabIndex={0} />;

    //
  } else if (_to) {
    anchor = <NavLink to={_to} />;
  }

  return (
    <app-button {...rest} class={_className} ref={_domRef} title={title} data-test={dataTest} tabIndex={1}>
      {content}
      {anchor}
    </app-button>
  );

  function onMount() {
    if (autoFocus) focus();

    return onUnmount;
  }

  function onUnmount() {
    _domRef.current = null;
  }

  async function focus() {
    await wait(100);

    _domRef.current?.focus();
  }

  function onAnchorClick(e) {
    if (!openHrefInNewWindow) return;

    e.preventDefault();

    window.open(href || _to, "_blank");
  }
}

Button.extractProps = function extractProps<T extends Button.Props>(props: T) {
  return {
    about: props.about,
    accessKey: props.accessKey,
    autoCapitalize: props.autoCapitalize,
    autoCorrect: props.autoCorrect,
    autoFocus: props.autoFocus,
    autoSave: props.autoSave,
    children: props.children,
    className: props.className,
    background: props.background,
    color: props.color,
    contentEditable: props.contentEditable,
    contextMenu: props.contextMenu,
    dataTest: props.dataTest,
    datatype: props.datatype,
    defaultChecked: props.defaultChecked,
    defaultValue: props.defaultValue,
    dir: props.dir,
    disabled: props.disabled,
    DomRef: props.domRef,
    draggable: props.draggable,
    form: props.form,
    formAction: props.formAction,
    formEncType: props.formEncType,
    formMethod: props.formMethod,
    formNoValidate: props.formNoValidate,
    formTarget: props.formTarget,
    hidden: props.hidden,
    href: props.href,
    icon: props.icon,
    id: props.id,
    img: props.img,
    inlist: props.inlist,
    inputMode: props.inputMode,
    is: props.is,
    itemID: props.itemID,
    itemProp: props.itemProp,
    itemRef: props.itemRef,
    itemScope: props.itemScope,
    itemType: props.itemType,
    label: props.label,
    lang: props.lang,
    loading: props.loading,
    name: props.name,
    onClick: props.onClick,
    openHrefInNewWindow: props.openHrefInNewWindow,
    placeholder: props.placeholder,
    prefix: props.prefix,
    property: props.property,
    radioGroup: props.radioGroup,
    resource: props.resource,
    results: props.results,
    role: props.role,
    security: props.security,
    slot: props.slot,
    spellCheck: props.spellCheck,
    status: props.status,
    style: props.style,
    suppressContentEditableWarning: props.suppressContentEditableWarning,
    suppressHydrationWarning: props.suppressHydrationWarning,
    tabIndex: props.tabIndex,
    title: props.title,
    to: props.to,
    tooltip: props.tooltip,
    translate: props.translate,
    type: props.type,
    typeof: props.typeof,
    unselectable: props.unselectable,
    value: props.value,
    variant: props.variant,
    vocab: props.vocab,
  };
};

export { Button };

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

declare namespace Button {
  interface ResetButtonProps extends Props {
    confirmationProps?: ResetConfirmationProps;
  }

  export interface ResetConfirmationProps {
    title: string;
    message: string;
    submitText: string;
    cancelText: string;
  }

  interface Props extends HTMLProps {
    children?: ReactNode;
    className?: ClassNames.Argument;
    dataTest?: string;
    domRef?: DomRef;
    href?: string;
    icon?: Icon.IconProp;
    img?: ImgProps["img"];
    label?: string;
    loading?: boolean;
    openHrefInNewWindow?: boolean;
    status?: Status;
    to?: string | Route;
    tooltip?: string;
    variant?: Variant;
    background?: "positive" | "warning";
  }

  type Variant = "action" | "flat";
  type DomRef = MutableRefObject<HTMLButtonElement | null>;

  type HTMLProps = Omit<ButtonHTMLAttributes<HTMLButtonElement>, "className">;
}
