import React, { forwardRef, Ref, useImperativeHandle, useState } from "react";
import { chunk } from "lodash-es";
import { isTruthy } from "@/utils";
import { Negotiation, Order, OrderNegotiationStore } from "@/models";
import { AggridFilterComp, AggridFilterParams, CheckboxInput, AggridContext } from "@/components";
import "./StageFilter.scss";

function StageFilter(params: AggridFilterParams, ref: Ref<AggridFilterComp>) {
  const { filterChangedCallback } = params;
  const context = params.context as AggridContext;
  const Model = context.EntityModel || Negotiation;
  const stageColumns = chunk(
    [
      Model === Order && Order.prototype._.stageMetadataMap.Inactive,
      Model === Order && Order.prototype._.stageMetadataMap.InactiveNegotiations,
      Model === Order && Order.prototype._.stageMetadataMap.Active,
      Model === Order && Order.prototype._.stageMetadataMap.FirmOffer,
      Model === Order && Order.prototype._.stageMetadataMap.FirmBid,
      Model === Order && Order.prototype._.stageMetadataMap.Firm,
      Model === Order && Order.prototype._.stageMetadataMap.TermsLoading,
      Model === Order && Order.prototype._.stageMetadataMap.MainTerms,
      Model === Order && Order.prototype._.stageMetadataMap.OnSubs,
      Model === Order && Order.prototype._.stageMetadataMap.SubsLifted,
      Model === Order && Order.prototype._.stageMetadataMap.SubsFailed,
      Model === Order && Order.prototype._.stageMetadataMap.Fixed,
      Model === Order && Order.prototype._.stageMetadataMap.Withdrawn,
      Model === Negotiation && Negotiation.prototype._.stageMetadataMap.Inactive,
      Model === Negotiation && Negotiation.prototype._.stageMetadataMap.Active,
      Model === Negotiation && Negotiation.prototype._.stageMetadataMap.FirmOffer,
      Model === Negotiation && Negotiation.prototype._.stageMetadataMap.FirmBid,
      Model === Negotiation && Negotiation.prototype._.stageMetadataMap.Firm,
      Model === Negotiation && Negotiation.prototype._.stageMetadataMap.TermsLoading,
      Model === Negotiation && Negotiation.prototype._.stageMetadataMap.MainTerms,
      Model === Negotiation && Negotiation.prototype._.stageMetadataMap.OnSubs,
      Model === Negotiation && Negotiation.prototype._.stageMetadataMap.SubsLifted,
      Model === Negotiation && Negotiation.prototype._.stageMetadataMap.SubsFailed,
      Model === Negotiation && Negotiation.prototype._.stageMetadataMap.Fixed,
      Model === Negotiation && Negotiation.prototype._.stageMetadataMap.Nomination,
      Model === Negotiation && Negotiation.prototype._.stageMetadataMap.Confirmed,
      Model === Negotiation && Negotiation.prototype._.stageMetadataMap.Withdrawn,
    ].filter(isTruthy),
    5
  );

  const [state, setState] = useState(defaultState);
  const isActive = !!Object.keys(state).length;

  useImperativeHandle(ref, getCurrentInstance);

  function getCurrentInstance() {
    return {
      isFilterActive,
      doesFilterPass,
      getModel,
      setModel,
    };
  }

  function isFilterActive() {
    return isActive;
  }

  function doesFilterPass(params) {
    const order = params.data as OrderNegotiationStore["Order"];
    const stage = Model.prototype.getStage.call(order);

    if (!stage) return false;

    return state[stage];
  }

  function getModel() {
    return state;
  }

  function setModel(state) {
    setState(state ? state : defaultState);
  }

  return <sea-stage-filter>{stageColumns.map(ColumnHOC)}</sea-stage-filter>;

  function ColumnHOC(stages, i) {
    return <sea-stage-filter-column key={i}>{stages.map(CheckboxHOC)}</sea-stage-filter-column>;
  }

  function CheckboxHOC(stage) {
    if (!stage) return null;

    function onChange(value) {
      if (value) {
        setState({ ...state, [stage.value]: value });

        //
      } else {
        const { [stage.value]: removed, ...rest } = state;

        setState(rest);
      }

      setTimeout(filterChangedCallback);
    }

    return (
      <CheckboxInput
        value={state[stage.value]}
        onChange={onChange}
        label={stage.label}
        dataTest={stage.value}
        key={stage.value}
      />
    );
  }
}

const defaultState = {} as State;

const ForwardRef = forwardRef(StageFilter);

ForwardRef.displayName = "StageFilter";

export { ForwardRef as StageFilter };

type State = BoolRecord;
