import { useEffect, useState } from "react";
import { Subscription } from "rxjs";
import { order as orderUpdates } from "dashboard/services/realtime/updates";
import { IOrderSummary } from "negotiations/models/IOrderSummary";
import { getApiOrder, mapToOrderSummary } from "negotiations/services/getOrderSummary";
import { IOrder } from "orders/models/IOrder";
import { mapOrder } from "orders/services/mappings/mapOrder";
import { useAuth } from "__legacy/auth-provider";

export function useOrder(ctradeUrl: string, orderId: string, dependency?: any) {
  const { auth } = useAuth();
  const [orderSummary, setOrderSummary] = useState<IOrderSummary>();
  const [orderSummaryState, setOrderSummaryState] = useState<"loading" | "error" | null>();
  const [orderModel, setOrderModel] = useState<IOrder>();
  useEffect(() => {
    let sub: Subscription | undefined;
    if (orderId) {
      sub = getApiOrder(`${ctradeUrl}/orders`, orderId, orderUpdates(ctradeUrl, orderId, auth.claims)).subscribe(
        (order) => {
          setOrderModel(mapOrder(order));
          setOrderSummaryState(null);
          setOrderSummary(mapToOrderSummary(order));
        },
        (error) => {
          setOrderSummaryState("error");
        }
      );
    }
    return () => sub && sub.unsubscribe();
  }, [ctradeUrl, orderId, auth.claims, dependency]);

  return { orderModel, orderSummaryState, orderSummary };
}
