import {
  INegotiationCreateView,
  IOrderCreate,
  INegotiationCreate,
  IOrderITOCreate,
  IOrderCOACreate,
  INegotiableViewOptional,
} from "../models/NegotiationCreateView";
import { getOrderApiService } from "../../api/orders/Orders";
import * as api from "../../api/orders/models";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { mapAccount } from "sharedFolder/mappers/mapAccount";
import { OrderPermission } from "../models/OrderPermissions";
import { permissionsFromJwt } from "sharedFolder/apis/permissionsFromJwt";
import { typesFromOrder } from "../../api/orders/typesFromOrder";
import { mapToNegotiableView, withEmpty, mapApiToNegotiableViewOptional } from "sharedFolder/mappers/map";
import { mapCommission } from "sharedFolder/mappers/mapCommission";
import { mapCOACargoSize } from "sharedFolder/mappers/mapCOACargoSize";
import { mapLiftings } from "sharedFolder/mappers/mapLiftings";
import { ILocationView } from "sharedFolder/Models/ILocation";
import { mapLocation } from "sharedFolder/mappers/mapLocation";
import { mapLaycan } from "sharedFolder/mappers/mapLaycan";
import { mapDuration } from "sharedFolder/mappers/mapDuration";
import { mapVesselSize } from "sharedFolder/mappers/mapVesselSize";
import { mapCargoType } from "sharedFolder/mappers/mapCargoType";
import { mapCargoSize } from "sharedFolder/mappers/mapCargoSize";
import { mapNominations } from "sharedFolder/mappers/mapNominations";
import { INotesView } from "sharedFolder/Models/IDetails";
import { mapNotes } from "sharedFolder/mappers/mapUnitValue";
import { mapOrderNegType } from "sharedFolder/mappers/mapOrderNegType";
import { mapSubsText } from "sharedFolder/mappers/mapSubsText";

export function getCirculateOrder(ordersUrl: string, orderId: string): Observable<INegotiationCreateView> {
  return getOrderApiService(ordersUrl).get(orderId).pipe(map(mapToCirculateOrder));
}

function mapToCirculateOrder(order: api.IOrder): INegotiationCreateView {
  const common: IOrderCreate = {
    addressCommission: mapApiToNegotiableViewOptional(mapCommission)(order.details.addressCommission),
    brokerCommission: mapApiToNegotiableViewOptional(mapCommission)(order.details.brokerCommission),
    cargoType: mapApiToNegotiableViewOptional(mapCargoType)(order.details.cargoType),
    chartererAccount: mapApiToNegotiableViewOptional(mapAccount)(order.details.chartererAccount),
  };

  const itoOrderCreate: IOrderITOCreate = {
    ...common,
    laycan: mapToNegotiableView(
      "laycan",
      mapLaycan.emptyViewModel,
      withEmpty(mapLaycan)(order.details.laycan),
      order.details.laycan
    ),
  };

  const coaOrderCreate: IOrderCOACreate = {
    ...common,
    coaNotes: mapNotesToView(order.details.coaNotes),
    demurrage: { included: true, negotiable: true },
    dischargeLocation: mapLocationToView(order.details.dischargeLocation),
    freightRate: { included: true, negotiable: true },
    period: mapToNegotiableView(
      "period",
      mapLaycan.emptyViewModel,
      withEmpty(mapLaycan)(order.details.period),
      order.details.period
    ),
    liftings: mapToNegotiableView(
      "liftings",
      mapLiftings.emptyViewModel,
      withEmpty(mapLiftings)(order.details.liftings),
      order.details.liftings
    ),
    loadLocation: mapLocationToView(order.details.loadLocation),
    nominations: mapToNegotiableView(
      "nominations",
      mapNominations.emptyViewModel,
      withEmpty(mapNominations)(order.details.nominations),
      order.details.nominations
    ),
    coaCargoSize: mapToNegotiableView(
      "coaCargoSize",
      mapCOACargoSize.emptyViewModel,
      withEmpty(mapCOACargoSize)(order.details.coaCargoSize),
      order.details.coaCargoSize
    ),
    commercialSubs: mapToNegotiableView(
      "commercialSubs",
      mapSubsText.emptyViewModel,
      withEmpty(mapSubsText)(order.details.commercialSubs),
      order.details.commercialSubs
    ),
    operationalSubs: mapToNegotiableView(
      "operationalSubs",
      mapSubsText.emptyViewModel,
      withEmpty(mapSubsText)(order.details.operationalSubs),
      order.details.operationalSubs
    ),
  };

  function mapLocationToView(location: api.ILocation | undefined): INegotiableViewOptional<ILocationView> {
    return {
      included: true,
      negotiable: false,
      value: location === undefined ? undefined : mapLocation.toView(location),
    };
  }
  const details: INegotiationCreate = {
    cargoSize: mapApiToNegotiableViewOptional(mapCargoSize)(order.details.cargoSize),
    demurrage: { included: true, negotiable: true },
    dischargeLocation: mapLocationToView(order.details.dischargeLocation),
    freightRate: { included: true, negotiable: true },
    loadLocation: mapLocationToView(order.details.loadLocation),
    voyNotes: mapNotesToView(order.details.voyageNotes),
    ballastBonus: { included: true, negotiable: true },
    hireRate: { included: true, negotiable: true },
    cleaningPrice: { included: true, negotiable: true },
    supplyPrice: { included: true, negotiable: true },
    tradingExclusions: { included: true, negotiable: true },
    bunkerDelivery: { included: true, negotiable: true },
    bunkerRedelivery: { included: true, negotiable: true },
    cargoExclusionsText: { included: true, negotiable: true },
    deliveryLocation: {
      ...mapLocationToView(order.details.deliveryLocation),
      negotiable: true,
    },
    voyOperationalSubs: {
      included: true,
      negotiable: true,
    },
    voyCommercialSubs: {
      included: true,
      negotiable: true,
    },
    tctOperationalSubs: {
      included: true,
      negotiable: true,
    },
    tctCommercialSubs: {
      included: true,
      negotiable: true,
    },
    viaLocation: mapLocationToView(order.details.viaLocation),
    redeliveryLocation: {
      ...mapLocationToView(order.details.redeliveryLocation),
      negotiable: true,
    },
    duration: mapToNegotiableView(
      "duration",
      undefined,
      order.details.duration && mapDuration.toView(order.details.duration),
      order.details.duration
    ),
    tctNotes: mapNotesToView(order.details.tctNotes),

    vesselSize: mapToNegotiableView(
      "vesselSize",
      undefined,
      order.details.vesselSize && mapVesselSize.toView(order.details.vesselSize),
      order.details.vesselSize
    ),
  };
  const types = typesFromOrder(order);

  return {
    details: {
      order: types.some((t) => t === "COA") ? coaOrderCreate : itoOrderCreate,
      details,
    },
    invitees: [],
    distributionList: order.distributionList,
    tctSelected: types.includes("TCT"),
    voySelected: types.includes("VOY"),
    permittedMethods: order.updateToken ? permissionsFromJwt<OrderPermission>(order.updateToken) : [],
    teamMembers: order.teamMembers,
    originallySelectedTypes: order.details.originallySelectedTypes.map(mapOrderNegType.toView),
    updateToken: order.updateToken,
  };
}
function mapNotesToView(notes: string | undefined): INegotiableViewOptional<INotesView> {
  return {
    value: notes ? mapNotes.toView(notes) : undefined,
  };
}
