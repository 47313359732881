import { useContext, useReducer, useEffect } from "react";
import { useVesselSelector } from "../OwnerIndicationForm/Form/VesselSelector/useVesselSelector";
import { IOfferRepViewNegotiation } from "negotiations/models/common/IOfferRep";
import { MinimalConfigurationContext } from "__legacy/sharedFolder/MinimalConfigurationContext";
import { OwnerDashboardApi } from "../api/OwnerDashboardApi";
import { mapVessel } from "sharedFolder/mappers/mapVessel";
import { liftingsUIStatusReducer } from "./liftingUIStatusReducer";

export function useSendNomination(negotiation: IOfferRepViewNegotiation) {
  const { onRemoveVessel, onAddNewVessel, onEditVessel, selectedVessels, vessels } = useVesselSelector(negotiation);

  const config = useContext(MinimalConfigurationContext);

  const [liftingsState, dispatch] = useReducer(liftingsUIStatusReducer, "None");

  useEffect(() => {
    dispatch({ type: "UpdateNegotiation", payload: negotiation });
  }, [negotiation, dispatch]);

  const sendNomination = async () => {
    dispatch({ type: "InProgress", payload: negotiation });
    const result = await OwnerDashboardApi(config.ctradeUrl, negotiation.updateToken).indicateVessel(
      negotiation.orderId,
      negotiation.id,
      selectedVessels.map(mapVessel.toApi)
    );

    if (result.ok) {
      dispatch({ type: "NominationSuccessful", payload: negotiation });
    }
    // TODO: Deal with error
  };

  const newVessels = selectedVessels.filter(
    (selectedVessel) =>
      !vessels.find(
        (vessel) =>
          vessel.vesselIMO === selectedVessel.vesselIMO &&
          vessel.eta === selectedVessel.eta &&
          vessel.itinerary === selectedVessel.itinerary &&
          vessel.ownerChain === selectedVessel.ownerChain &&
          vessel.speedAndConsumption === selectedVessel.speedAndConsumption &&
          vessel.additionalNotes === selectedVessel.additionalNotes
      )
  );

  return {
    onRemoveVessel,
    onAddNewVessel,
    onEditVessel,
    selectedVessels,
    sendNominationButtonEnabled: newVessels.length > 0 && !["RequestInProgress", "Withdrawn", "Accepted"].includes(liftingsState),
    requestChangesButtonEnabled: !["RequestInProgress", "Withdrawn", "Accepted"].includes(liftingsState),
    sendNomination,
    liftingsState,
  };
}
