import { mapOrderNegType } from "sharedFolder/mappers/mapOrderNegType";
import { INegotiation, ICirculateOrder } from "../models";

export function mapCirculateOrderToNegotiation(circulateOrder: ICirculateOrder, orderId: string, id: string): INegotiation {
  return {
    id,
    version: 1,
    orderVersion: 1,
    type: mapOrderNegType.toApi(circulateOrder.type),
    orderId: orderId,
    orderReference: orderId.substring(0, 8),
    invitee: circulateOrder.invitee,
    createdOn: "",
    status: "Inactive",
    vessels: [],
    charterers: [], // where do I get this from
    attachments: [],
    actions: {},
    bid: circulateOrder.details,
    offer: {},
    detailSettings: {},
    notes: {},
    orderNotes: "",
    offerRepToken: "",
    hasBroker: false,
    hasCharterer: false,
    isOfferRepClaimed: false,
    operationalSubsLifted: false,
    commercialSubsLifted: false,
  };
}
