import { TradeAPI } from "___REFACTOR___/apis";
import { NegotiationNoteEditor } from "___REFACTOR___/components";
import { DataModel, DataModelProps } from "../../DataModel";

class Note extends DataModel {
  constructor(data: PartialData) {
    super(data);
  }

  toString() {
    return this.value;
  }
}

class NoteProps extends DataModelProps {
  constructor() {
    super();

    this.Editor = NegotiationNoteEditor;
  }
}

Note.prototype.Props = NoteProps;
NoteProps.prototype.Editor = NegotiationNoteEditor;

export { Note, NoteProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface Note extends Data {
  Props: typeof NoteProps;
  _: NoteProps;
}

type PartialData = Partial<Data>;
type Data = TradeAPI.Negotiation.Notes.Note;
