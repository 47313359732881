import { DealCaptureType } from "@/apis";
import { dialog, getCreateDealCapturePayload, Order, router, UniversalOrderNegotiationFormValues } from "@/models";
import { usageMetrics, OrderCreationTypeEvents } from "@/services/UsageMetrics";
import { uid } from "@/utils";
import { when } from "mobx";
import { Context } from "../Orders";

// prettier-ignore
export async function createDealCapture(model: Order | undefined, context: Context, values: UniversalOrderNegotiationFormValues) {
  if (!model) {
    console.error("createDealCapture:", { model, context, values });

    throw new Error(`createDealCapture: Order model is undefined`);
  }

  model._.status.loading = true;
  model._.status.message = "Creating Deal Capture";



  const createDealCapturePayload = (values) => {
    const isDCOnBehalfOfOwner = new URLSearchParams(window.location.search).get("onbehalfof") === 'owner';

    if (values.types.includes("Coa")) {
      return {
        ...getCreateDealCapturePayload(values),
      };
    }

    return {
      ...getCreateDealCapturePayload(values),
      dealCaptureType: isDCOnBehalfOfOwner ? DealCaptureType.Owner : DealCaptureType.Charterer,
    }
  }
  const orderCreationPayload = createDealCapturePayload(values);

  const res = await model.capture(orderCreationPayload, context.view.order.orderCreationQuery);

  if (!res?.data?.id) {
    model._.status.type = "error";
    model._.status.title = `${context.view.order.name} Deal Capture Failure`;
    model._.status.dump = { res, entity: orderCreationPayload };

    dialog.show({
      status: model._.status,
      dataTest: `${context.dataTest}-creation-fail`,
    });

    model._.status.loading = false;
    model._.status.message = null;

    return;
  }

  const newOrderModel = context.orderNegotiationStore?.upsertOrderModel({ id: res?.data?.id }, {
    orderNegotiationStoreOrderUpsertConfig: {
      shouldUpdateArray: false
    }
  });

  const currentNewOrderModelVersion = newOrderModel?._.version;

  await when(() => currentNewOrderModelVersion !== newOrderModel?._.version);

  if (newOrderModel?._.orderNegotiationStoreOrder) {
    context.orderNegotiationStore?.upsertOrder(newOrderModel?._.orderNegotiationStoreOrder, { shouldAddExistingOrderToArray: true });
    router.get("captureMainTerms")?.push({ orderId: res?.data?.id, negotiationId: `$${uid()}`, type: values?.types?.[0] }, { ignoreUnsavedChanges: true });
  }

  const charterType = orderCreationPayload.types![0];

  switch (orderCreationPayload.types![0]) {
    case "Voy":
      usageMetrics.trackEvent(OrderCreationTypeEvents.DEAL_CAPTURE_VOY, {
        orderType: "Deal Capture",
        charterType
      });
      break;
    case "Tct":
      usageMetrics.trackEvent(OrderCreationTypeEvents.DEAL_CAPTURE_TCT, {
        orderType: "Deal Capture",
        charterType
      });
      break;
    case "Coa":
      usageMetrics.trackEvent(OrderCreationTypeEvents.DEAL_CAPTURE_COA, {
        orderType: "Deal Capture",
        charterType
      });
      break;
  }

  model._.status.loading = false;
  model._.status.message = null;
}
