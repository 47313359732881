import React from "react";
import classnames from "classnames";
import { ILocationView, ILocationViewPart, ILocationSafeWarranty } from "sharedFolder/Models/ILocation";
import styles from "../LocationEditor/LocationEditor.module.scss";
import { getLocations } from "sharedFolder/apis/LocationService";
import { LookUpSingleEntry } from "../LookUpSingleEntry/LookUpSingleEntry";
import TextArea from "sharedFolder/components/common/TextArea/TextArea";
import { mapLocation } from "sharedFolder/mappers/mapLocation";
import { locationViewText } from "sharedFolder/display/location";
import { LocationSafetyWarranty } from "../../common/LocationSafetyWarranty/LocationSafetyWarranty";

interface ILocationProps {
  id: string;
  label?: string;
  required?: boolean;
  disabled?: boolean;
  value?: ILocationView;
  focus?: boolean;
  onChange: (value?: ILocationView) => void;
  ctradeUrl: string;
  isValid: boolean;
  hideLocationSafetyWarranty?: boolean;
}

export const NewLocationEditor: React.FC<ILocationProps> = (props) => {
  const { id, label, value, required, onChange } = props;

  function displayName(location: ILocationView): string {
    const name = location.name === "NULL" ? "" : location.name;
    const country = location.country === "NULL" ? "" : location.country;
    const zone = location.zone === "NULL" ? "" : location.zone;

    return `${name} ${country ? ", " + country : ""}  ${zone ? ", " + zone : ""}`;
  }

  const update = (location: ILocationView) => {
    const updatedLocation = {
      ...(value || mapLocation.emptyViewModel),
      ...location,
    };
    onChange({
      ...updatedLocation,
      display: locationViewText(updatedLocation),
    });
  };

  function handleLocationPickerChange(location?: any) {
    const locationPart = {
      locationId: "",
      name: "",
      countryCode: "",
      countryId: "",
      zone: "",
      zoneId: "",
      parents: "",
      country: "",
    };
    const newLocation: ILocationViewPart = location ? location : locationPart;
    update({ ...(value || mapLocation.emptyViewModel), ...newLocation });
  }

  const isLocationSafetyWarrantyDisabled = Boolean(!value || (value && !value.locationId) || props.disabled);

  return (
    <>
      <div className={styles.inputRow} id={id}>
        <div className={styles.inputGroup}>
          {label && (
            <label htmlFor={`${id}_location`} className={required ? styles.mandatory : undefined}>
              {label}
            </label>
          )}

          <LookUpSingleEntry
            id={`${id}_location`}
            defaultValue={value && value.locationId && value}
            autoCompleteFunction={async (filter: string) => await getLocations(props.ctradeUrl, filter)}
            onChange={handleLocationPickerChange}
            getDisplayText={(item) => displayName(item)}
            noResultsFoundText="No locations Found"
            disabled={props.disabled}
            focus={props.focus}
          />

          {!props.hideLocationSafetyWarranty && (
            <LocationSafetyWarranty
              value={isLocationSafetyWarrantyDisabled ? undefined : props.value}
              type="portassignment"
              disabled={isLocationSafetyWarrantyDisabled}
              onSafeBerthsChanged={(safeBerths?: ILocationSafeWarranty) => {
                update({
                  ...(value || mapLocation.emptyViewModel),
                  safeBerthsMin: safeBerths?.value.min,
                  safeBerthsMax: safeBerths?.value.max,
                });
              }}
              onSafeAnchoragesChanged={(safeAnchorages?: ILocationSafeWarranty) => {
                update({
                  ...(value || mapLocation.emptyViewModel),
                  safeAnchoragesMin: safeAnchorages?.value.min,
                  safeAnchoragesMax: safeAnchorages?.value.max,
                });
              }}
              onSafePortsChanged={(safePorts?: ILocationSafeWarranty) => {
                update({
                  ...(value || mapLocation.emptyViewModel),
                  safePortsMin: safePorts?.value.min,
                  safePortsMax: safePorts?.value.max,
                });
              }}
            />
          )}

          <TextArea
            id={`${props.label || "location"}_notes`}
            dataTest={`${props.label || "location"}_notes`}
            placeholder="Additional location notes"
            className={classnames(styles.textarea)}
            value={(value && value.notes) || ""}
            onChange={(evt: React.FocusEvent<HTMLTextAreaElement>) =>
              update({
                ...(value || mapLocation.emptyViewModel),
                notes: evt.target.value,
              })
            }
            disabled={props.disabled}
          />
        </div>
      </div>
    </>
  );
};
