import { Request, Config } from "@/request";
import { auth } from "@/models";
import { config } from "@/config";

class CentralAPI {
  setup = async () => {
    this.request = new Request({ baseURL: config.authUrl });

    await auth.central.promise;

    Object.assign(this.request.axios.defaults.headers.common, {
      "clarksons.cloud.logintoken": auth.central.token,
    });
  };

  getUser = async (centralToken = auth.central.token, config?: Config) => {
    config = {
      headers: {
        "clarksons.cloud.logintoken": centralToken,
        ...config?.headers,
      },
      ...config,
    };

    return this.request.get("/API/1_4/Security/GetCurrentUser", config);
  };
}

export const centralAPI = new CentralAPI();

interface CentralAPI {
  request: Request;
}

export interface User {
  TokenExpiryUtc: string;
}
