import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import Checkbox from "__legacy/sharedFolder/components/common/Checkbox/Checkbox";
import { ISelectableOption } from "sharedFolder/Models/userSettings";
import { useOrderType } from "__legacy/sharedFolder/contexts/order-type-context";
import Icon from "__legacy/sharedFolder/components/common/Icon/Icon";
import styles from "./Filter.module.scss";

interface IFilterProps {
  title?: string;
  className?: string;
  options: ISelectableOption[];
  onOptionChange: (option: string, selected: boolean) => void;
}

export const Filter: React.FC<IFilterProps> = (props: IFilterProps) => {
  const [expanded, setExpanded] = useState(false);
  const filterContainer = useRef<HTMLDivElement>(null);
  const orderType = useOrderType();
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick, false);
    return function cleanup() {
      document.removeEventListener("mousedown", handleOutsideClick, false);
    };
  });

  function handleOutsideClick(e: any) {
    const current = filterContainer.current;
    if (current && current.contains(e.target)) {
      return;
    }
    setExpanded(false);
  }

  function handleClick(option: string, selected: boolean) {
    props.onOptionChange(option, selected);
  }

  return (
    <div className={classNames(styles.container, props.className)} ref={filterContainer} tabIndex={-1}>
      <div
        className={styles.header}
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        <label className={styles.title}>{props.title}</label>
        <Icon icon={expanded ? "arrow-drop-up" : "arrow-drop-down"} className={styles.toggle} />
      </div>
      {expanded && (
        <div className={styles.list}>
          {props.options.map(function (option, index) {
            return (
              <div className={styles.listItem} key={"listItem" + option.name}>
                <Checkbox
                  ariaLabel={`${option.name}-checkbox`}
                  defaultChecked={option.selected}
                  key={"listItem" + option.name}
                  label={orderType === "COA" && option.name === "Exact Laycan" ? "Exact Period" : option.name}
                  labelPos="right"
                  onChange={() => handleClick(option.name, !option.selected)}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
