import { action, makeObservable, observable } from "mobx";
import * as PathToRegexp from "path-to-regexp";
import { history, History } from "___REFACTOR___/services/History";

class Route<P, SP> {
  static match<Params>(route: Route, path: string, options?: Match.Options) {
    // @ts-ignore
    const match = PathToRegexp.match<Params>(route.path, options);

    return match(path);
  }

  static compile<Params>(route: Route, params?: Params, options?: Compile.Options) {
    const compile = PathToRegexp.compile(route.path, options);

    // @ts-ignore
    return compile(params);
  }

  constructor() {
    this.isActive = false;
    this.isActiveExact = false;
    // @ts-ignore
    this.params = {};
    // @ts-ignore
    this.searchParams = {};

    history.listen(this.onHistoryUpdate.bind(this));

    makeObservable(this, {
      isActive: observable,
      isActiveExact: observable,
      params: observable.ref,
      searchParams: observable.ref,
      onHistoryUpdate: action,
    });
  }

  get path() {
    let res = "";

    if (this.parent) res += this.parent.path !== "/" ? this.parent.path : "";
    if (this.relpath) res += this.relpath;

    return res || "/";
  }

  get children() {
    const { isActive, isActiveExact, ...childMap } = this;

    const children = Object.values(childMap) as Route[];

    return children;
  }

  onHistoryUpdate(location: History.Location, action: History.Action) {
    const softMatch = this.match(location.pathname, { end: false });
    const urlSearchParams = new URLSearchParams(location.search);
    const searchParams = {} as any;

    urlSearchParams.forEach((value, key) => (searchParams[key] = value));

    this.isActive = !!softMatch;
    this.isActiveExact = !!this.match(location.pathname);

    this.params = softMatch?.params || {};
    this.searchParams = searchParams;
  }

  match(path = window.location.pathname, options?: Match.Options) {
    return Route.match(this, path, options);
  }

  compile(params?, options?: Compile.Options) {
    return Route.compile(this, params, options);
  }

  history = {
    push: (params?, options?: Compile.Options) => history.push(this.compile(params, options)),
    replace: (params?, options?: Compile.Options) => history.replace(this.compile(params, options)),
  };
}

export { Route };

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

interface Route<P = unknown, SP = unknown> {
  readonly parent: Route;
  readonly relpath: string;
  readonly label: string;
  readonly desc: string;

  inheritable: Inheritable;
  isActive: boolean;
  isActiveExact: boolean;
  params: P;
  searchParams: SP;
}
declare namespace Route {
  export { Inheritable, Params, Match, Compile };
}

interface Inheritable {}
declare namespace Inheritable {}

interface Params {
  [name: string]: Param;
}
declare namespace Params {
  export { Param };
}

type Param = string;

declare namespace Compile {
  type Options = PathToRegexp.ParseOptions & PathToRegexp.TokensToFunctionOptions;

  // @ts-ignore
  type Function<Params> = PathToRegexp.PathFunction<Params>;
}

// @ts-ignore
type Match<Params> = PathToRegexp.Match<Params>;
declare namespace Match {
  type Options = PathToRegexp.ParseOptions & PathToRegexp.TokensToRegexpOptions & PathToRegexp.RegexpToFunctionOptions;

  // @ts-ignore
  type Function<Params> = PathToRegexp.MatchFunction<Params>;
}
