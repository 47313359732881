import { ICargoSizeView, ICOACargoSizeView } from "../Models/IDetails";
import { isCargoSizeValid, cargoSizeFormatter, isCOACargoSizeValid } from "../components/common/fields";

export const cargoSizeText = (value?: ICargoSizeView): string | undefined => {
  if (!value || !value.value || !value.variance || !isCargoSizeValid(value)) {
    return undefined;
  }
  return `${cargoSizeFormatter(value.value)} MT${
    value.variance === "" || value.variance === null
      ? ""
      : ` / ${value.variance}% ${value.option}${value.notes ? ` ${value.notes}` : ""}`
  }`;
};

export const COAcargoSizeText = (value?: ICOACargoSizeView): string | undefined => {
  if (!value || !value.min || !value.max || !value.variance || !isCOACargoSizeValid(value)) {
    return undefined;
  }
  const unit = value.min || value.max ? "MT" : "";
  const separator = !value.min || !value.max ? "--" : "-";
  const min = cargoSizeFormatter(value.min);
  const max = cargoSizeFormatter(value.max);

  return `${min}${separator}${max} ${unit}${
    value.variance === "" || value.variance === null ? "" : ` / ${value.variance}% ${value.option}`
  } ${value.notes || ""}`;
};
