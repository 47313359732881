import React from "react";
import styles from "./InputWithCancelButton.module.scss";
import Button from "__legacy/sharedFolder/components/common/Button/Button";

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
type InputPropsWithOnCleared = InputProps & { onCleared: () => void };

export const InputWithCancelButton = (props: InputPropsWithOnCleared) => {
  const propsWithoutOnCleared = { ...props };
  // @ts-ignore
  delete propsWithoutOnCleared.onCleared;
  return (
    <div className={styles.container}>
      <input className={styles.input} {...propsWithoutOnCleared} />
      <Button
        className={styles.button}
        icon="close"
        onClick={props.onCleared}
        dataTest="input-clear-button"
        type="plain"
      ></Button>
    </div>
  );
};
