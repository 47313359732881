import React, { useContext } from "react";
import styles from "./FirmNoticePanel.module.scss";
import { FirmSentNotification } from "./FirmSentNotification";
import { FirmSummary } from "./FirmSummary";
import { FirmControls } from "./FirmControls";
import { FirmOfferRequestedControls } from "./FirmOfferRequestControls";
import { OpenMainTerms } from "./OpenMainTerms/OpenMainTerms";
import { NegotiationUIStatus, NegotiationEvent } from "../../reducers/offerRepStatusReducer";
import { TrackingContext } from "../../../../analytics/TrackingContext";
import { OfferRepNegotiationContext } from "../../Contexts/OfferRepNegotiationContext";
import { OrderNegType } from "sharedFolder/Models/OrderNegType";
import { INegotiationView } from "sharedFolder/Models/Negotiation/INegotiationView";
import { SendRecapToAllParties } from "sharedFolder/components/common/SendRecapToAllParties/SendRecapToAllParties";
import { UserContext } from "__legacy/dashboard/contexts/UserProvider";
import { IOfferRepViewNegotiation } from "negotiations/models/common/IOfferRep";
import { emptyArrowFn } from "@/utils";

interface IFirmNoticePanel {
  displayMessage: (negotiationEvent: NegotiationUIStatus) => void;
  negotiation: IOfferRepViewNegotiation;
  ownerNegotiation: INegotiationView;
  orderType: OrderNegType;
  setCharaterIndicationToFirmOffer: (date: string) => void;
  dispatchAction: (offerRepInput: NegotiationEvent) => void;
  negotiationUIStatus: NegotiationUIStatus;
  maintermsOrsignUpUrl: string;
}

export const FirmNoticePanel: React.SFC<IFirmNoticePanel> = ({
  displayMessage,
  negotiation,
  ownerNegotiation,
  orderType,
  setCharaterIndicationToFirmOffer,
  negotiationUIStatus,
  dispatchAction,
  maintermsOrsignUpUrl,
}) => {
  const userContext = useContext(UserContext);
  const tracking = useContext(TrackingContext);
  const { offerRepApi } = useContext(OfferRepNegotiationContext);

  const hideForStatuses = [
    "None",
    "IndicationOffer",
    "IndicationBid",
    "RequestInProgress",
    "IndicationBidSent",
    "SetExpiryDateOfFirmOffer",
    "FirmBidExpired",
    "FirmOfferExpired",
    "TimerExpired",
  ];

  if (hideForStatuses.indexOf(negotiationUIStatus) >= 0) {
    return null;
  }
  const accepted = ["FirmBidAccepted", "FirmOfferAccepted", "MainTerms"];

  const onDeclineFirmOfferRequest = () => dispatchAction("DeclineFirmOfferRequest");

  const onAcceptFirmBid = () => {
    tracking.interaction("OfferRepFirmBidAccepted");
    offerRepApi.firmBidAccept().subscribe(emptyArrowFn, () => dispatchAction("RequestErrorOccured"));
  };

  const onCounterFirmBid = () => dispatchAction("CounterFirmBid");
  const onTimeExpired = () => dispatchAction("TimerExpired");

  const sendRecapStatus = ["MainTerms", "Fixed", "OnSubs", "SubsLifted"];

  return (
    <div className={styles.container} data-test="FirmNoticePanel">
      {(negotiationUIStatus === "CounterFirmBid" ||
        negotiationUIStatus === "FirmOffer" ||
        negotiationUIStatus === "FirmOfferCounteredWithIndication" ||
        negotiationUIStatus === "FirmBid" ||
        negotiationUIStatus === "FirmOfferRequested") && (
        <FirmSentNotification
          onTimeExpired={onTimeExpired}
          firmBidExpiresOn={negotiation.firmBidExpiresOn}
          firmOfferExpiresOn={negotiation.firmOfferExpiresOn}
          negotiationUIStatus={negotiationUIStatus}
          circulatedByRole={negotiation.circulatedByRole}
        />
      )}
      {accepted.includes(negotiationUIStatus) && (
        <div className={styles.goneFirmMessage}>
          {negotiation.details && negotiation.ctradeOfferRepUrl && (
            // <OpenMainTerms maintermsOrsignUpUrl={maintermsOrsignUpUrl} isOfferRepClaimed={negotiation.isOfferRepClaimed} />
            <OpenMainTerms ctradeOfferRepUrl={negotiation.ctradeOfferRepUrl} />
          )}
          {userContext.companyRoles.includes("owner") && sendRecapStatus.includes(negotiation.status) && (
            <SendRecapToAllParties
              ariaLabel="sendRecapToAllParties"
              key="sendRecapToAllParties"
              negotiation={negotiation}
              onEmailSent={() => {
                displayMessage("SendRecap");
              }}
              type="action"
            />
          )}
        </div>
      )}
      <FirmSummary orderType={orderType} ownerNegotiation={ownerNegotiation} negotiationUIStatus={negotiationUIStatus} />
      {(negotiationUIStatus === "FirmBid" || negotiationUIStatus === "CounterFirmBid") && (
        <FirmControls
          negotiationUiStatus={negotiationUIStatus}
          onAcceptFirmBid={onAcceptFirmBid}
          onCounterFirmBid={onCounterFirmBid}
        />
      )}
      {negotiationUIStatus === "FirmOfferRequested" && (
        <FirmOfferRequestedControls
          onDeclineFirmOfferRequest={onDeclineFirmOfferRequest}
          onSubmitFirmOffer={setCharaterIndicationToFirmOffer}
        />
      )}
    </div>
  );
};
