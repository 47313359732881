import { useMemo } from "react";
import { FormContext, Form, Button } from "@/components";
import { UniversalOrderNegotiationFormValues, Negotiation, slideout } from "@/models";
import { updateNegotiationNote as updateNegotiationNoteAction } from "@/components/Orders/actions";
import { Context } from "@/components/Orders/Orders";
import { usageMetrics, NegotiationTypeEvents } from "@/services/UsageMetrics";
import "./NotesSlideout.scss";

export function NotesSlideout(props: Props) {
  const { negotiation, context } = props;
  const initialValues = useMemo(getInitialValues, []);

  function updateNegotiationNote(values: UniversalOrderNegotiationFormValues) {
    if (values.ownerAccount) updateNegotiationNoteAction(negotiation, context, values);
  }

  function submit() {
    usageMetrics.trackEvent(NegotiationTypeEvents.SEND_NOTE);

    slideout.hide();

    updateNegotiationNote(form.values);
  }

  function getInitialValues() {
    const notes = negotiation.getNotesModelMap();

    return new UniversalOrderNegotiationFormValues({
      negotiationOwnerNote: notes?.owner,
      negotiationBrokerChartererNote: notes?.brokerCharterer,
      updateNegotiationNoteFor: props.updateNegotiationNoteFor || "brokerCharterer",
    });
  }

  return (
    <div className="negotiation-details-slideout-form negotiation-details-notes">
      <h2>Edit response</h2>
      <Form initialValues={initialValues} definition={initialValues} elements={elements} context={form} />
      <div className="vessel-slideout-form-control">
        <Button onClick={slideout.hide}>cancel</Button>
        <Button variant="action" onClick={submit}>
          Submit
        </Button>
      </div>
    </div>
  );
}

const form = new FormContext();

const elements = [
  {
    type: "CollapsibleGroup",
    label: "Responding for",
    className: "with-border",
    children: [
      {
        type: "Radio",
        name: "updateNegotiationNoteFor",
        radioLabel: "Charterer",
        checkedValue: "brokerCharterer",
      },
      {
        type: "Radio",
        name: "updateNegotiationNoteFor",
        radioLabel: "Owner",
        checkedValue: "owner",
      },
    ],
  },
  {
    visible: (context) => context.values?.updateNegotiationNoteFor === "brokerCharterer",
    type: "CollapsibleGroup",
    label: "Send a note to the Owner",
    children: ["negotiationBrokerChartererNote"],
  },
  {
    visible: (context) => context.values?.updateNegotiationNoteFor === "owner",
    type: "CollapsibleGroup",
    label: "Send a note to the Charterer",
    children: ["negotiationOwnerNote"],
  },
];

interface Props {
  negotiation: Negotiation;
  context: Context;
  updateNegotiationNoteFor?: string | null;
}
