import React, { MutableRefObject, useEffect, useRef } from "react";
import { Input } from "../../Input";
import { Container } from "../../Container";
import "./Textarea.scss";

function TextareaInput(props: TextareaInput.Props) {
  const { autoFocus, autoSelectText, dataTest, onKeyDown, onChange, placeholder, disabled, tabIndex, value = "" } = props;
  const domRef = useRef(null) as TextareaInput.DomRef;

  useEffect(onMount, []);

  return (
    <Container {...props} type="Textarea">
      <textarea
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        disabled={disabled}
        tabIndex={tabIndex}
        data-test={`${dataTest}.input`}
        ref={domRef}
        autoFocus={autoFocus}
      />
    </Container>
  );

  function onMount() {
    const eInput = domRef.current!;

    if (props.domRef) props.domRef.current = eInput;

    if (autoSelectText) eInput.setSelectionRange(0, eInput.value.length);

    return onUnmount;
  }

  function onUnmount() {
    if (props.domRef) props.domRef.current = null;
  }
}

export { TextareaInput };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

// eslint-disable-next-line no-redeclare
declare namespace TextareaInput {
  interface Props extends Input.Props {
    value: Value;
    onChange: (value: Value) => void;
    domRef?: DomRef;
    autoSelectText?: boolean;
  }

  type Value = string | undefined;
  type DomRef = MutableRefObject<HTMLTextAreaElement | null>;
}
