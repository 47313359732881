import { percentageThreeDecimalText } from "negotiations/services/client/SummaryService";
import * as api from "../../api/negotiations/models";
import { ICommissionView } from "sharedFolder/Models/IDetails";
import { IMapper, withNullable } from "./map";

const map: IMapper<api.ICommission, ICommissionView> = {
  toApi: (viewModel: ICommissionView) => {
    const { value, display } = viewModel;
    return {
      value: parseFloat(value),
      display,
    };
  },
  toView: (apiModel: api.ICommission) => {
    return {
      value: String(apiModel.value),
      display: percentageThreeDecimalText(String(apiModel.value)),
    };
  },
  emptyViewModel: {
    value: "",
  },
};

export const mapCommission = withNullable(map);
