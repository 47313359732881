import React from "react";
import { UnverifiedAccount } from "@/models";
import { Icon, TextField, TextFieldProps } from "@/components";

export function UnverifiedAccountEditor(props: Props) {
  props = { ...props };

  props.className = `${props.className} UnverifiedAccount`;

  function onChange(accountName: string) {
    const next = new UnverifiedAccount({ accountName });

    props.onChange?.(next);
  }

  return (
    <>
      <TextField
        {...props}
        label="Please identify the Owner you represent"
        value={props.value?.accountName}
        onChange={onChange}
        placeholder=""
        dataTest={`${props.dataTest}.value`}
      />
      <div className="OwnerAccount-info">
        <Icon icon="help" />
        Can&apos;t find the right entity? Search for and select &apos;TBN&apos; for now and let us know the details of the entity
        by clicking{" "}
        <a href="mailto:support@sea.live?subject=Please%20add%20this%20owner%20entity&amp;body=Hi%2C%0A%0AI%20tried%20to%20use%20the%20below%20entity%20in%20Sea%2Ftrade%20but%20could%20not%20find%20it.%20Could%20you%20please%20add%20them%20to%20your%20system.%0A%0AClient%20full%20name%3A%20XXXX%0ARegistered%20Address%3A%20XXXX%0ATelephone%20Number%3A%20XXXX%0AEmail%20Address%3A%20XXXX%0A%20%20">
          here
        </a>
      </div>
    </>
  );
}

interface Props extends Omit<TextFieldProps, "value" | "onChange"> {
  value?: UnverifiedAccount;
  onChange?: (value: UnverifiedAccount) => void;
}

export type UnverifiedAccountEditorProps = Props;
