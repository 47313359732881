import { PureComponent } from "react";
import { IValidationContext, ValidationContext } from "sharedFolder/contexts/ValidationContext";
import { vesselSearch } from "sharedFolder/apis/vesselSearch";
import { withContext } from "sharedFolder/contexts/withContext";
import { IVesselSearch } from "sharedFolder/Models/IVessel";
import { LookUpSingleEntry } from "../../editors/LookUpSingleEntry/LookUpSingleEntry";

interface IVesselSearchPickerProps {
  baseUrl: string;
  id: string;
  division: string;
  required?: boolean;
  selected?: IVesselSearch;
  onChange: (optionSelected: IVesselSearch | undefined) => void;
  focus?: boolean;
  selectedVessels?: IVesselSearch[];
  defaultVessel?: IVesselSearch;
  onVesselSelectedInSelectedVessels?: (selectedVessel: IVesselSearch) => void;
  hideHeader?: boolean;
}

class VesselSearchPickerComponent extends PureComponent<IVesselSearchPickerProps & IValidationContext> {
  constructor(props: IVesselSearchPickerProps & IValidationContext) {
    super(props);
    this.getDataItems = this.getDataItems.bind(this);
    this.handleItemSelected = this.handleItemSelected.bind(this);
    this.isValid = this.isValid.bind(this);
  }

  public componentDidMount() {
    this.props.fieldValidityChanged("VesselSearchPicker", this.isValid());
  }

  public componentDidUpdate() {
    this.props.fieldValidityChanged("VesselSearchPicker", this.isValid());
  }

  public render() {
    return (
      <LookUpSingleEntry
        id={this.props.id}
        label={this.props.hideHeader ? "" : "Vessel"}
        isValid={this.isValid()}
        autoCompleteFunction={this.getDataItems}
        onChange={this.handleItemSelected}
        getDisplayText={formatVesselName}
        focus={this.props.focus}
        placeholder="Search by vessel name or IMO number"
        defaultValue={this.props.defaultVessel}
      />
    );
  }

  private async getDataItems(imo: string): Promise<IVesselSearch[]> {
    return await vesselSearch(this.props.baseUrl, imo, this.props.division);
  }

  private vesselInSelectedVesselList(vessel: IVesselSearch) {
    if (vessel && this.props.selectedVessels) {
      const existingSelectedVessel = this.props.selectedVessels.find((v) => v.arcVesselId === vessel.arcVesselId);
      return existingSelectedVessel;
    }
    return false;
  }

  private handleItemSelected(item: any) {
    const vessel = item as IVesselSearch;
    if (this.vesselInSelectedVesselList(vessel)) {
      if (this.props.onVesselSelectedInSelectedVessels) {
        this.props.onVesselSelectedInSelectedVessels(vessel);
      }
    } else {
      this.props.onChange(item as IVesselSearch);
    }
  }

  private isValid() {
    return !(this.props.required && !this.props.selected);
  }
}

export const formatVesselName: any = (v: IVesselSearch) => {
  return `${v.vesselName} - IMO ${v.vesselIMO} ${v.buildYear ? ` / YOB ${v.buildYear}` : ""} ${v.dwt ? ` / DWT ${v.dwt}` : ""}
  `;
};

export const VesselSearchPicker = withContext<IVesselSearchPickerProps, IValidationContext>(
  VesselSearchPickerComponent,
  ValidationContext
);
