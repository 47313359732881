import { cleanValue, isTruthy } from "___REFACTOR___/utils";
import { COUNTRY_BY_ALPHA2_CODE_MAP, COUNTRY_BY_ALPHA3_CODE_MAP, COUNTRY_BY_NAME_MAP } from "___REFACTOR___/constants";
import { TradeAPI } from "___REFACTOR___/apis";
import { LocationEditor } from "___REFACTOR___/components";
import { DataModel, DataModelProps } from "../DataModel";
import { SAFE_OPTION_LABEL_BY_MIN_MAX_MAP, SAFE_OPTION_ARR } from "./SafeOption";
import { schema } from "./schema";

class Location extends DataModel {
  static get Editor() {
    return LocationEditor;
  }

  constructor(data?: PartialData) {
    data = cleanValue(data);

    super(data);

    this._.img = this.getImg();
  }

  toJSON() {
    const json = DataModel.prototype.toJSON.call(this) as TradeAPI.Location;
    json.globalZone = "";
    return json;
  }

  isValid() {
    return schema.isValidSync(this);
  }

  toString() {
    let safes = stringifySafes(this);

    if (!this.name) return "";

    const country = this.country ? `, ${this.country}` : "";
    safes = safes ? `${safes} ` : "";
    const notes = this.notes ? ` ${this.notes}` : "";

    return `${safes}${this.name}${country}${notes}`;
  }

  toDropdownView() {
    if (!this.name) return "";

    const country = this.country ? `, ${this.country}` : "";

    return `${this.name}${country}`;
  }

  toGridView() {
    if (!this) return "";
    if (!this.name) return "";

    return this.name;
  }

  getImg() {
    let countryCode = this.countryCode;

    if (this.name === "Georgia") countryCode = "GEO";
    if (this.name === "WAUS") countryCode = "AUS";
    if (this.name === "UK") countryCode = "GBR";
    if (this.name === "USA") countryCode = "USA";

    const countryByAlpha2 = countryCode && COUNTRY_BY_ALPHA2_CODE_MAP[countryCode];
    const countryByAlpha3 = countryCode && COUNTRY_BY_ALPHA3_CODE_MAP[countryCode];
    const countryByName = this.name && COUNTRY_BY_NAME_MAP[this.name];
    const country = countryByAlpha2 || countryByAlpha3 || countryByName;

    let tooltip = "";
    let src = BLANK_COUNTRY_SRC;

    if (country) {
      tooltip = this.country || country.name;
      src = `https://pltfrmcdn-ckncdnlive-cdn-prd.sea.live/Data/development/src/flags/3chr/${country.alpha3Code.toLowerCase()}.svg`;
    }

    return {
      src,
      tooltip,
    };
  }
}

function stringifySafes(l: Location) {
  let b = SAFE_OPTION_LABEL_BY_MIN_MAX_MAP[`${l.safeBerthsMin}${l.safeBerthsMax}`];
  let p = SAFE_OPTION_LABEL_BY_MIN_MAX_MAP[`${l.safePortsMin}${l.safePortsMax}`];
  let a = SAFE_OPTION_LABEL_BY_MIN_MAX_MAP[`${l.safeAnchoragesMin}${l.safeAnchoragesMax}`];

  if (!b && !p && !a) return "";

  b = b ? `${b}sb` : "";
  p = p ? `${p}sp` : "";
  a = a ? `${a}sa` : "";

  return [b, p, a].filter(isTruthy).join(", ");
}

const BLANK_COUNTRY_SRC = "/images/blank.png";

class LocationProps extends DataModelProps {
  get schema() {
    return schema;
  }

  get Editor() {
    return LocationEditor;
  }
}

Location.prototype.Props = LocationProps;
LocationProps.prototype.label = "Location";
LocationProps.prototype.safeOptionArr = SAFE_OPTION_ARR;
LocationProps.prototype.safeOptionLabelByMinMaxMap = SAFE_OPTION_LABEL_BY_MIN_MAX_MAP;

export { Location, LocationProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface Location extends Data {
  _: LocationProps;
}

interface LocationProps {
  safeOptionArr: typeof SAFE_OPTION_ARR;
  safeOptionLabelByMinMaxMap: typeof SAFE_OPTION_LABEL_BY_MIN_MAX_MAP;
}

type PartialData = Partial<Data>;
type Data = TradeAPI.Location;
