import { IRecipient } from "api/orders/models/IDistributionList";
import { IUserContext } from "__legacy/dashboard/contexts/UserProvider";
import { DistributionUserRole, hasOnlyUnknownRoleOrEmpty } from "sharedFolder/Models/IDetails";
import { filterAvailableRoles } from "./availableRoles";
import { getDefaultRole } from "./getDefaultRole";

export const updateRecipientRole = (recipients: IRecipient[], email: string, role: DistributionUserRole) => {
  const updateRecipient =
    (email: string, role: DistributionUserRole) =>
    (recipient: IRecipient): IRecipient =>
      recipient.email === email ? { ...recipient, role } : recipient;
  return recipients.map(updateRecipient(email, role));
};

export const updateRecipientRoles = (recipients: IRecipient[], currentlyLoggedInUser?: IUserContext): IRecipient[] => {
  const getDistributionUserWithAvailableRoles = (userRecipient: IRecipient, currentlyLoggedInUser: IUserContext): IRecipient => {
    const availableRoles = filterAvailableRoles(userRecipient, currentlyLoggedInUser);
    const noSelectableRole = hasOnlyUnknownRoleOrEmpty(availableRoles);
    const newUserWithUpdatedRoles: IRecipient = {
      ...userRecipient,
      availableRoles,
      additionalData: {
        ...userRecipient.additionalData,
        disableUserRow: noSelectableRole || userRecipient.additionalData?.disableUserRow,
        disableDesk: noSelectableRole || userRecipient.additionalData?.disableDesk,
      },
    };
    return {
      ...newUserWithUpdatedRoles,
      role: getDefaultRole(userRecipient, currentlyLoggedInUser),
    };
  };

  if (!currentlyLoggedInUser) return recipients;

  return recipients.map((recipient: IRecipient) => getDistributionUserWithAvailableRoles(recipient, currentlyLoggedInUser));
};
