import { getNegotiationApiService } from "../../api/negotiations/Negotiations";
import { INegotiationIndication } from "../../api/negotiations/models";
import { AllNegotiableDetails } from "../components/negotiate/NegotiableTypes";

export function indication<TDetail>(
  ctradeUrl: string,
  orderId: string,
  negId: string,
  detailTypes: Array<keyof AllNegotiableDetails>,
  updateToken: string,
  version: number,
  details: Partial<AllNegotiableDetails>,
  side: "bid" | "offer"
) {
  // take the current neg and apply the action
  const indication = getNegotiationApiService(ctradeUrl, orderId).indication;
  const body: INegotiationIndication = {
    updateToken,
    action: "indicated",
    details: {},
  };

  detailTypes.forEach((type) => {
    const detail = details[type];
    body.details = {
      ...body.details,
      [type]: {
        value: detail && detail[side],
      },
    };
  });

  return indication(orderId, negId, body, side);
}
