import locationSafetyWarranty from "../../../staticData/locationSafetyWarranty.json";
import { ILocationSafeWarranty, ILocationView } from "sharedFolder/Models/ILocation";

export const getLocationSafetyWarrantyByMinMax = (min: number, max: number) => {
  return locationSafetyWarranty.find((item: ILocationSafeWarranty) => item.value.min === min && item.value.max === max);
};

export const getWarrantySafetyInformationAbbreviation = (locationView?: ILocationView) => {
  if (!locationView) return "";
  const { safeBerthsMin, safeBerthsMax, safePortsMin, safePortsMax, safeAnchoragesMin, safeAnchoragesMax } = locationView;
  const safetyItems = [] as any;

  if (safeBerthsMin && safeBerthsMax) {
    const safeBerths = getLocationSafetyWarrantyByMinMax(safeBerthsMin, safeBerthsMax);
    if (safeBerths) safetyItems.push(`${safeBerths.key}sb`);
  }

  if (safePortsMin && safePortsMax) {
    const safePorts = getLocationSafetyWarrantyByMinMax(safePortsMin, safePortsMax);
    if (safePorts) safetyItems.push(`${safePorts.key}sp`);
  }

  if (safeAnchoragesMin && safeAnchoragesMax) {
    const safeAnchorages = getLocationSafetyWarrantyByMinMax(safeAnchoragesMin, safeAnchoragesMax);
    if (safeAnchorages) {
      safetyItems.push(`${safeAnchorages.key}sa`);
    }
  }

  return safetyItems.join(", ");
};
