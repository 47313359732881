import { isTruthy } from "___REFACTOR___/utils";

export function renderTooltipString(...rows) {
  rows = rows.flat().map(getRowText).filter(isTruthy);

  return rows.join(`
`);
}

function getRowText(row) {
  if (typeof row !== "object") row = { text: row };

  return row?.text || "";
}
