import * as signalR from "@microsoft/signalr";
import { makeObservable, observable } from "mobx";
import { JSONSchema7 } from "json-schema";
import { dialog, log, auth, HandlebarWithReplacement } from "___REFACTOR___/services";
import { Request, Response } from "___REFACTOR___/models/common";
import { Trade } from "___REFACTOR___/models";
import { config } from "___REFACTOR___/config";

class TradeAPI {
  constructor() {
    this.connection = undefined;

    makeObservable(this, { connection: observable.ref });
  }

  setup = async () => {
    this.req = new Request({ baseURL: config.ctradeUrl });

    this.req.interceptors.response.use(undefined, this.onFail);
    this.req.interceptors.request.use(this.onRequest);

    await auth.trade.promise;

    const headers = this.req.axios.defaults.headers.common;

    Object.assign(headers, {
      "clarksons.cloud.logintoken": headers["clarksons.cloud.logintoken"] || auth.central.token,
      authorization: `Bearer ${auth.trade.token}`,
    });

    const signalRCredentials = await this.getSignalRCredentials();

    if (signalRCredentials.ok && signalRCredentials.data) {
      this.connection?.off?.("seatrade"); // we unsub, because trade.setup may be called more than once

      const { url, accessToken } = signalRCredentials.data;

      const accessTokenFactory = () => accessToken;

      this.connection = new signalR.HubConnectionBuilder()
        .withUrl(url, { accessTokenFactory })
        .configureLogging(signalR.LogLevel.Debug)
        .build();

      this.connection.on("seatrade", logSignalRMessage.bind(null, "Update"));

      this.connection.onclose(this.onConnectionClose);

      this.connection.start();
    }

    logSignalRMessage("Successfully connected.");

    this.resolve();
  };

  promise = new Promise((resolve) => {
    this.resolve = resolve;
  });

  onConnectionClose = async () => {
    logSignalRMessage("Closed. Refreshing Auth.");

    const token = await auth.trade.refresh();

    if (!token) {
      logSignalRMessage("Auth refresh failure. Resetting Auth.");

      auth.reset();

      return;
    }

    logSignalRMessage("Auth successfully refreshed. Resetting TradeAPI with new connection.");

    this.setup();
  };

  onRequest = (config: Request.ExtendedConfig) => {
    if (!config.headers["Content-Type"]) config.headers["Content-Type"] = "application/json";

    return config;
  };

  onFail = async (res: Response) => {
    if (!auth.trade.authenticated) return res;

    if (res.config.isRetry) return res;

    if (res.config.url === "/token") return this.onTokenFail(res);

    if (res.status === 401) {
      logMessage("Sea/central token is invalid. Redirecting user to Sea/central.");

      auth.reset();
    }
    //
    else if (res.status === 403) {
      logMessage("Sea/trade token is invalid. Refreshing Sea/trade Auth.");

      const refreshPromise = auth.trade.refreshPromise;
      const token = refreshPromise ? await refreshPromise : await auth.trade.refresh();

      if (!token) {
        logMessage("Sea/trade Auth refresh failure. Redirecting user to Sea/central.");

        return res;
      }

      res.config.headers.authorization = `Bearer ${token}`;
      res.config.isRetry = true;

      const retryRes = await res.config.executor(res.config);

      if (!retryRes.ok) return retryRes;

      logMessage("Successful Sea/trade Auth refresh. Setting Sea/tradeAPI with fresh credentials.");

      this.setup();
    }

    return res;
  };

  onTokenFail = async (res: Response) => {
    if (res.status === 401) {
      logMessage("Sea/central token is invalid. Redirecting user to Sea/central.");

      auth.reset();
    }
    //
    else if (res.status === 403) {
      logMessage("Sea/central user was not found in Sea/trade.");

      dialog.open({ type: "error", title: "Invalid User Configuration", dataTest: "invalid-user-configuration" });
    }

    return res;
  };

  getToken = (centralToken = auth.central.token) => {
    return this.req.post<TradeAPI.Token>("/token", null, { headers: { "clarksons.cloud.logintoken": centralToken } });
  };

  getSignalRCredentials = () => {
    return this.req.post<TradeAPI.SignalR.Credentials>("/notifications", null);
  };

  searchOrderTemplates = (search: string, query = "") => {
    const url = "/ordertemplates?";
    const urlParams = new URLSearchParams({ encodedTerm: search });

    if (query) {
      return this.req.get<TradeAPI.Order.Template[]>(`${url}${urlParams}${query}`);
    }
    return this.req.get<TradeAPI.Order.Template[]>(`${url}${urlParams}`);
  };

  getProformaLayouts = () => {
    return this.req.get<TradeAPI.Termset.Content.ProformaLayout[]>(`/proforma/layouts`);
  };

  getProformaLayout = (id: number) => {
    return this.req.get<TradeAPI.Termset.Content.ProformaLayout>(`/proforma/layouts/${id}`);
  };

  searchAccounts = (search: string, legalEntitiesOnly: boolean) => {
    const url = `/accounts?q=${search}&legalEntitiesOnly=${legalEntitiesOnly}`;

    return this.req.get<TradeAPI.Account.Search.Item[]>(url);
  };

  searchCLDDUs = (subset: TradeAPI.LegacyCLDDUser.Search.Subset, search: string, query?: string) => {
    let url = `/clddus/${subset}/${search}`;

    if (query) url += `/${query}`;

    return this.req.get<TradeAPI.LegacyCLDDUser[]>(url);
  };

  searchLocations = (search: string) => {
    return this.req.get<TradeAPI.Location.Search.Item[]>(`/locations/${search}`);
  };

  getCompanies = () => {
    return this.req.get<TradeAPI.Company[]>(`/companies`);
  };

  searchVessels = (search: string) => {
    return this.req.get<TradeAPI.Vessel[]>(`/vessels?term=${search}`);
  };

  getTimezones = () => {
    return this.req.get<TradeAPI.Timezone[]>(`/timezones`);
  };

  getEmailLists() {
    const url = "/settings/emaillists";

    return this.req.get<TradeAPI.Enp.Settings.EmailLists.Get.Res.Body>(url);
  }

  getCompany = async (id: TradeAPI.Company.Id) => {
    const res = await this.req.get<TradeAPI.Clddu.Company[]>(`/companies/${id}`);

    // this is done to get locationId into desk, as desks are missing their locationIds (if someone's not lazy you can find the location in the memberships array, as that'd be the more correct thing to do)
    for (let i = 0; i < res.data.length; i++) {
      const company = res.data[i];

      for (let i = 0; i < company.desks.length; i++) {
        const desk = company.desks[i];

        desk.locationId = desk.memberships.find((entity) => entity.level === "location")?.id!;
      }
    }

    return res;
  };

  settings = {
    emailLists: {
      get: () => {
        const url = "/settings/emaillists";

        return this.req.get<TradeAPI.Enp.Settings.EmailLists.Get.Res.Body>(url);
      },
      post: (body: Trade.Enp.Settings.EmailLists.Post.Req.Body) => {
        const url = "/settings/emaillists";

        return this.req.post<TradeAPI.Enp.Settings.EmailLists.Post.Res.Body>(url, body);
      },
      put: (body: Trade.Enp.Settings.EmailLists.Put.Req.Body) => {
        const url = `/settings/emaillists/${body.emailList.id}`;

        return this.req.put<TradeAPI.Enp.Settings.EmailLists.Post.Res.Body>(url, body);
      },
      id: {
        delete: (id: TradeAPI.EmailList.Id, updateToken: TradeAPI.EmailList.UpdateToken) => {
          const url = `/settings/emaillists/${id}`;

          return this.req.delete<TradeAPI.Enp.Settings.EmailLists.Delete.Res.Body>(url, { updateToken });
        },
      },
    },
  };

  clddus = {
    usersAndOwners: {
      get: (search: string, query?: URLSearchParams) => {
        let url = `/clddus/usersAndOwners/${search}`;

        if (query) url += `/${query}`;

        return this.req.get<TradeAPI.Enp.Clddus.UsersAndOwners.Get.Res.Body>(url);
      },
    },
  };

  ownerDealCapture = {
    post: (data: TradeAPI.Enp.OwnerDealCapture.Put.Req.TctBody | TradeAPI.Enp.OwnerDealCapture.Put.Req.VoyBody) => {
      return this.req.post<TradeAPI.Enp.OwnerDealCapture.Get.Res.Body>(`/ownerdealcapture`, data);
    },
  };

  termsets = {
    get: (id: TradeAPI.Encoding.Id.GUID) => {
      const url = `/termsets/${id}`;

      return this.req.get<TradeAPI.Termset>(url);
    },
    put: (data: TradeAPI.Termset) => {
      return this.req.put<TradeAPI.Termset>(`/termsets`, data);
    },
    delete: (id: TradeAPI.Termset.Id, companyId: string) => {
      const isMaritech = auth.trade.user?.isMaritechUser;
      companyId = isMaritech ? companyId : "";
      const url = `/termsets/${id}/${companyId}`;

      return this.req.delete<undefined>(url);
    },
    search: {
      byTermsetName: {
        get: (search: string, typeId: TradeAPI.Termset.TypeId) => {
          const url = "/termsets?";
          const urlParams = new URLSearchParams({ encodedTerm: search });

          if (typeId) {
            urlParams.append("termsetType", typeId.toString());
          }

          return this.req.get<TradeAPI.TermsetResponse>(`${url}${urlParams}`);
        },
      },
      byLayoutName: {
        get: (layoutName: TradeAPI.Termset.Content.ProformaLayout.Name, typeId: TradeAPI.Termset.TypeId) => {
          const url = "/termsets/layout?";
          const urlParams = new URLSearchParams({ encodedTerm: layoutName });

          if (typeId) {
            urlParams.append("termsetType", typeId.toString());
          }

          return this.req.get<TradeAPI.TermsetResponse>(`${url}${urlParams}`);
        },
      },
    },
    schema: {
      get: () => {
        return this.req.get<JSONSchema7>("/termsets/schema");
      },
    },
  };

  contracts = {
    search: {
      get: (searchParams: URLSearchParams) => {
        let url = "/contracts/search";
        if (searchParams) url += `?${searchParams}`;
        return this.req.get<TradeAPI.Enp.Contracts.Search.Get.Res.Body>(url);
      },
    },
    get: (cpId: TradeAPI.CpSearchItem.Id) => {
      const url = `/contracts/${cpId}`;
      return this.req.get<TradeAPI.Enp.Contracts.Get.Res.Body>(url);
    },
  };

  ownerAbsent = {
    generateEmail: {
      post: () => {
        const url = "/owner-absent/generate-email";

        return this.req.post<TradeAPI.Enp.OwnerAbsent.Post.Body>(url, {});
      },
    },
  };

  static TYPE_ID_BY_TYPE = {
    Voy: 1,
    Tct: 2,
    Coa: 3,
  };

  static map = {
    clddu: {
      user: {
        to: {
          teamMember: (user: TradeAPI.Clddu.User) => {
            return {
              id: user.id,
              name: user.name,
              email: user.email,
              systemUserId: user.systemUserId,
              companyType: user.companyType || user.companyRoles[0],
              company: {
                id: user.companyId,
                name: user.name,
              },
              location: {
                id: user.locationId,
                name: user.locationName,
              },
              division: {
                id: user.divisionId,
                name: user.divisionName,
              },
              desk: {
                id: user.deskId,
                name: user.deskName,
              },
            };
          },
        },
      },
    },
    teamMember: {
      to: {
        clddu: {
          user: (teamMember: TradeAPI.TeamMember) => {
            return {
              companyId: teamMember.company?.id,
              companyName: teamMember.company?.name,
              locationId: teamMember.location?.id,
              locationName: teamMember.location?.name,
              divisionId: teamMember.division?.id,
              divisionName: teamMember.division?.name,
              deskId: teamMember.desk?.id,
              deskName: teamMember.desk?.name,

              id: teamMember.id,
              userId: teamMember.id,
              name: teamMember.name,
              userName: teamMember.name,
              companyType: teamMember.companyType,
              companyRoles: [teamMember.companyType],
              email: teamMember.email,
              groupEmailAddress: "",
              systemUserId: teamMember.systemUserId,
            };
          },
        },
      },
    },
  };
}

function logMessage(message, ...rest) {
  log.system(["Sea/tradeAPI:", message], ...rest);
}

function logSignalRMessage(message, ...rest) {
  log.system(["Sea/trade SignalR Connection:", message], ...rest);
}

const tradeAPI = new TradeAPI();

export { tradeAPI, TradeAPI };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface TradeAPI {
  req: Request;
  connection: signalR.HubConnection | undefined;
  resolve: (value?) => void;
}

declare namespace TradeAPI {
  type Token = Encoding.JWT.ISOString;

  interface Order {
    updateToken: Order.UpdateToken;
    id: Encoding.Id.GUID;
    dealCaptureBrokerContact: LegacyCLDDUser;
    dealCaptureChartererContact: LegacyCLDDUser;
    details: Order.Details;
    charterer: string;
    chartererEmail: string;
    charterersCompanyId: string;
    status: Order.Status.All;
    teamMembers: TeamMember[];
    distributionList: Distribution.User[];
    groupChats: Order.GroupChat[];
    types: Order.Type[];
    version: Order.Version;
    createdOn: Encoding.Date.ISOString;
    lastUpdated: Encoding.Date.ISOString;
    owningCompany: string;
    fixedOn: Encoding.Date.ISOString;
    brokers: string[];
    sharedWith: string[];
    responseRequired: string;
    createdBy: TeamMember;
    attachments: Order.Attachment[];
    negAttachments: Order.NegAttachment[];
    summary: {
      orderReference: string;
      chartererAccount: string;
      cargoType: string;
      laycan: string;
      locations: string[];
      createdBy: string;
      negotiationCount: number;
      tctCount: number;
      voyCount: number;
      onSubsExpires: string[];
      firmExpires: object[];
      invitees: string[];
    };
  }

  namespace Order {
    type Id = Encoding.Id.GUID;
    type Version = number;
    type UpdateToken = Encoding.JWT.ISOString;
    type Type = "Voy" | "Tct" | "Coa";
    type TypeUppercase = "VOY" | "TCT" | "COA";

    namespace Status {
      type All = Lifecycle.InitialTerms | Lifecycle.MainTerms | Lifecycle.Fixed | Lifecycle.Misc;
      type Inactive = "Inactive";
      type Active = "Active";
      type Firm = "Firm";
      type TermsLoading = "TermsLoading";
      type MainTerms = "MainTerms";
      type OnSubs = "OnSubs";
      type SubsLifted = "SubsLifted";
      type SubsFailed = "SubsFailed";
      type Fixed = "Fixed";
      type Archived = "Archived";

      namespace Lifecycle {
        type InitialTerms = Inactive | Active | Firm;
        type MainTerms = TermsLoading | Status.MainTerms | OnSubs | SubsLifted | SubsFailed;
        type Fixed = Status.Fixed;
        type Misc = Archived;
      }
    }

    interface ParsedUpdateToken {
      exp: number;
      iat: number;
      orderId: string;
      sub: string;
      version: Version;
      permittedMethods: ParsedUpdateToken.PermittedMethod[];
    }

    namespace ParsedUpdateToken {
      type PermittedMethod =
        | "ArchiveOrder"
        | "SetCharterer"
        | "AddTeamMember"
        | "CirculateOrder"
        | "DistributeOrder"
        | "UpdateDistributionList";
    }

    type DistributionList = DistributionList.User[];
    namespace DistributionList {
      interface User {
        email: Encoding.Email.ISOString; // always send
        role: Company.Type; // always send
        status: User.Status; // appears in the order after Post /distribution update
        sentOn: Encoding.Date.ISOString; // appears in the order after Post /distribution update
        knownUser: TeamMember;
        actor: TeamMember;
        availableRoles: Company.Type[];
        negDetails: {
          voyNegId: string;
          voyNegOfferRepToken: string;
          coaNegId: string;
          coaNegOfferRepToken: string;
          tctNegId: string;
          tctNegOfferRepToken: string;
        };
      }
      namespace User {
        type Status = "pending" | "success";
      }
    }

    interface Details extends Details.All {
      isDealCapture: boolean;
      originallySelectedTypes: Type[];
    }

    namespace Details {
      namespace Standard {
        interface Common {
          chartererAccount: Account;
          laycan: Period;
          cargoType: CargoType;
          addressCommission: Commission;
          brokerCommission: Commission;
        }

        interface VOY {
          cargoSize: CargoSize;
          loadLocation: Location;
          dischargeLocation: Location;
          voyageNotes: string;
        }
        interface TCT {
          duration: Duration;
          vesselSize: VesselSize;
          deliveryLocation: Location;
          viaLocation: Location;
          redeliveryLocation: Location;
          tctNotes: string;
        }
      }

      interface COA {
        chartererAccount: Account;
        period: Period;
        nominations: Nominations;
        liftings: Liftings;
        coaCargoSize: COACargoSize;
        addressCommission: Commission;
        brokerCommission: Commission;
        loadLocation: Location;
        dischargeLocation: Location;
        coaNotes: string;
      }

      type All = Standard.Common & Standard.VOY & Standard.TCT & COA;
    }

    interface Template {
      id: Id;
      name: string;
      orderType: string;
      companyId: string;
      companyName: string;
      isDeleted: boolean;
      template: {
        coa: {
          coaCargoSize: COACargoSize;
          nominations: Nominations;
          period: Period;
          liftings: Liftings;
          loadLocation: Location;
          dischargeLocation: Location;
          notes: string;
        };
        order: {
          chartererAccount: Account;
          cargoType: CargoType;
          laycan: Period;
          addressCommission: Commission;
          brokerCommission: Commission;
        };
        voyage: {
          cargoSize: CargoSize;
          loadLocation: Location;
          dischargeLocation: Location;
          notes: string;
        };
        tct: {
          vesselSize: VesselSize;
          deliveryLocation: Location;
          viaLocation: Location;
          redeliveryLocation: Location;
          duration: Duration;
          notes: string;
        };
      };
    }

    namespace Template {
      type Id = Encoding.Id.GUID;
    }

    interface GroupChat {
      name: string;
      id: Encoding.Id.GUID;
      brokerCompany: GroupChat.CompanyChat;
      chartererCompany: GroupChat.CompanyChat;
    }
    namespace GroupChat {
      interface CompanyChat {
        companyType: Company.Type;
        id: Encoding.Id.GUID;
        name: string;
      }
    }

    interface Attachment {
      fileId: Attachment.Id;
      fileName: string;
      fileSizeInBytes: number;
      timeStamp: Encoding.Date.ISOString;
      user: TeamMember;
    }
    namespace Attachment {
      type Id = Encoding.Id.GUID;
    }

    interface NegAttachment extends Attachment {
      negId: Id;
      owner: Encoding.Email.ISOString;
      vesselNames: string[];
      type: Type;
      status: Status.All;
    }

    namespace NegAttachment {}
  }

  interface Negotiation {
    id: Encoding.Id.GUID;
    liftingId: number;
    orderId: Order.Id;
    jumpToStatus: string;
    mainTermsDetails: Negotiation.MainTermsDetails;
    bid: Negotiation.BidOffer;
    offer: Negotiation.BidOffer;
    detailSettings: Negotiation.DetailSettings;
    circulatedBy: string;
    createdOn: Encoding.Date.ISOString;
    version: number;
    status: Negotiation.Status.All;
    orderReference: string;
    lastUpdated: string;
    lastUpdatedBy: string;
    vessels: Vessel[];
    attachments: Negotiation.Attachment[];
    type: Negotiation.Type;
    invitee: Encoding.Email.ISOString;
    inviteeSystemUserId: number;
    updateToken: Negotiation.UpdateToken;
    chartererAccount: Account;
    offerRepToken: Negotiation.OfferRepToken;
    actions: Negotiation.Actions;
    owningCompany: Account;
    orderNotes: string;
    groupChat: Negotiation.GroupChat;
    groupChatId: Negotiation.GroupChat.Id;
    circulatedDivision: string;
    hasBroker: boolean;
    hasCharterer: boolean;
    brokerGroupChatId: string;
    isOfferRepClaimed: boolean;
    isDealCapture: boolean;
    brokerEmailAddresses: Encoding.Email.ISOString[];
    chartererEmailAddresses: Encoding.Email.ISOString[];
    ownerEmailAddresses: Encoding.Email.ISOString[];
    seaContractsUrl: string;
    notes: Negotiation.Notes;
    orderVersion: number;
    orderAttachments: Order.Attachment[];
    responseRequired: string;
    arcUrl: string;
    offerRepArcUrl: string;
    arcNegotiationHash: string;
    commercialSubsLifted: boolean;
    operationalSubsLifted: boolean;
    publishedNeg: {
      bid: Negotiation.BidOffer;
      actions: Negotiation.Actions;
      version: number;
    };
    sortIndexes: {
      laycan: string;
      invitee: string;
      createdDate: number;
      updatedDate: number;
    };
    filterIndexes: {
      isExactLaycan: boolean;
      active: boolean;
    };
  }

  namespace Negotiation {
    type Id = Encoding.Id.GUID;
    type Version = number;
    type UpdateToken = Encoding.JWT.ISOString;
    type Type = Order.Type | "Lft";
    type OfferRepToken = string;

    namespace Status {
      type All = Lifecycle.InitialTerms | Lifecycle.MainTerms | Lifecycle.Fixed | Lifecycle.Lifting | Lifecycle.Misc;
      type Inactive = "Inactive";
      type Active = "Active";
      type Firm = "Firm";
      type TermsLoading = "TermsLoading";
      type MainTerms = "MainTerms";
      type OnSubs = "OnSubs";
      type SubsLifted = "SubsLifted";
      type SubsFailed = "SubsFailed";
      type Fixed = "Fixed";
      type Nomination = "Nomination";
      type Confirmed = "Confirmed";
      type Withdrawn = "Withdrawn";
      type Archived = "Archived";

      namespace Lifecycle {
        type InitialTerms = Inactive | Active | Firm;
        type MainTerms = TermsLoading | Status.MainTerms | OnSubs | SubsLifted | SubsFailed;
        type Fixed = Status.Fixed;
        type Lifting = Nomination | Confirmed | Withdrawn;
        type Misc = Archived;
      }
    }
    interface GroupChat {
      id: GroupChat.Id;
      name: string;
    }
    namespace GroupChat {
      type Id = Encoding.Id.GUID;
    }

    interface Actions {
      brokerCharterer: Action.Type;
      lastUpdatedBy: Action.Role;
      owner: Action.Type;
      ownerFirmExpiresOn: Encoding.Date.ISOString;
      brokerChartererFirmExpiresOn: Encoding.Date.ISOString;
    }
    namespace Actions {}

    namespace Action {
      type Role = "owner" | "brokerCharterer";
      type Type = "indicated" | "firmRequested" | "firmed" | "firmAccepted";
    }

    type Attachment = Order.Attachment;
    namespace Attachment {}

    interface ParsedUpdateToken {
      exp: number;
      iat: number;
      negotiationId: string | null;
      orderId: string;
      sub: string;
      version: number;
      permittedMethods: ParsedUpdateToken.PermittedMethod[];
    }
    namespace ParsedUpdateToken {
      type PermittedMethod =
        | "BidIndicate"
        | "BidFirm"
        | "BidFirmAccept"
        | "OfferIndicate"
        | "OfferFirm"
        | "OfferFirmRequest"
        | "PtMainTerms"
        | "PtSubs"
        | "Fix"
        | "NameVessel"
        | "UpdateVessel"
        | "UpdateNotes"
        | "WithdrawNeg"
        | "SetOwningCompany"
        | "AddVessel"
        | "AcceptVessel"
        | "RejectVessel"
        | "GroupChatOfferRepAdded"
        | "AddAttachment"
        | "DeleteAttachment"
        | "PublishToCharterer"
        | "OwnerRequestChangesToDeclaredCargo";
    }

    namespace Details {
      namespace Standard {
        interface Common extends Exclude<Order.Details.Standard.Common, "chartererAccount"> {
          operationalSubs: Subs;
          commercialSubs: Subs;
        }
        interface VOY extends Order.Details.Standard.VOY {
          freightRate: FreightRate;
          demurrage: Demurrage;
        }
        interface TCT extends Order.Details.Standard.TCT {
          hireRate: HireRate;
          cleaningPrice: CleaningPrice;
          supplyPrice: SupplyPrice;
          tradingExclusions: TradingExclusions;
          bunkerDelivery: Bunker;
          bunkerRedelivery: Bunker;
          cargoExclusionsText: CargoExclusionsText;
          ballastBonus: BallastBonus;
        }
      }

      type COA = Exclude<Order.Details.COA, "chartererAccount">;

      type All = Standard.Common & Standard.VOY & Standard.TCT & COA;
    }

    interface MainTermsDetails extends Details.All {
      notes: string;
      invitee: Encoding.Email.ISOString;
      ownerAccount: Account;
      vessels: Vessel[];
      controllers: any[];
    }
    namespace MainTermsDetails {}

    interface BidOffer {
      laycan: BidOffer.Item<Period>;
      period: BidOffer.Item<Period>;
      liftings: BidOffer.Item<Liftings>;
      nominations: BidOffer.Item<Nominations>;
      cargoType: BidOffer.Item<CargoType>;
      cargoSize: BidOffer.Item<CargoSize>;
      coaCargoSize: BidOffer.Item<COACargoSize>;
      loadLocation: BidOffer.Item<Location>;
      dischargeLocation: BidOffer.Item<Location>;
      vesselSize: BidOffer.Item<VesselSize>;
      deliveryLocation: BidOffer.Item<Location>;
      viaLocation: BidOffer.Item<Location>;
      redeliveryLocation: BidOffer.Item<Location>;
      duration: BidOffer.Item<Duration>;
      addressCommission: BidOffer.Item<Commission>;
      brokerCommission: BidOffer.Item<Commission>;
      freightRate: BidOffer.Item<FreightRate>;
      hireRate: BidOffer.Item<HireRate>;
      cleaningPrice: BidOffer.Item<CleaningPrice>;
      supplyPrice: BidOffer.Item<SupplyPrice>;
      tradingExclusions: BidOffer.Item<TradingExclusions>;
      bunkerDelivery: BidOffer.Item<Bunker>;
      bunkerRedelivery: BidOffer.Item<Bunker>;
      cargoExclusionsText: BidOffer.Item<CargoExclusionsText>;
      demurrage: BidOffer.Item<Demurrage>;
      ballastBonus: BidOffer.Item<BallastBonus>;
      operationalSubs: BidOffer.Item<Subs>;
      commercialSubs: BidOffer.Item<Subs>;
    }
    namespace BidOffer {
      interface Item<T> {
        value: T;
      }
    }

    interface DetailSettings {
      laycan: DetailSettings.Setting;
      period: DetailSettings.Setting;
      liftings: DetailSettings.Setting;
      nominations: DetailSettings.Setting;
      cargoType: DetailSettings.Setting;
      cargoSize: DetailSettings.Setting;
      coaCargoSize: DetailSettings.Setting;
      loadLocation: DetailSettings.Setting;
      dischargeLocation: DetailSettings.Setting;
      vesselSize: DetailSettings.Setting;
      deliveryLocation: DetailSettings.Setting;
      viaLocation: DetailSettings.Setting;
      redeliveryLocation: DetailSettings.Setting;
      duration: DetailSettings.Setting;
      addressCommission: DetailSettings.Setting;
      brokerCommission: DetailSettings.Setting;
      freightRate: DetailSettings.Setting;
      demurrage: DetailSettings.Setting;
      hireRate: DetailSettings.Setting;
      cleaningPrice: DetailSettings.Setting;
      supplyPrice: DetailSettings.Setting;
      tradingExclusions: DetailSettings.Setting;
      bunkerDelivery: DetailSettings.Setting;
      bunkerRedelivery: DetailSettings.Setting;
      cargoExclusionsText: DetailSettings.Setting;
      ballastBonus: DetailSettings.Setting;
      operationalSubs: DetailSettings.Setting;
      commercialSubs: DetailSettings.Setting;
    }
    namespace DetailSettings {
      interface Setting {
        negotiable: boolean;
        side: string;
      }

      namespace Setting {
        type Side = "Bid" | "Offer";
      }
    }

    interface Notes {
      brokerCharterer: Notes.Note;
      owner: Notes.Note;
    }
    namespace Notes {
      interface Note {
        timestamp: Encoding.Date.ISOString;
        value: string;
      }
      namespace Note {}
    }

    namespace Payload {
      interface ProceedToMainTerms {
        details: MainTermsDetails;
        termsetId: Encoding.Id.GUID;
        type: Order.Type;
        cpDate: Encoding.Date.ISOString;
        userTimeZone: Encoding.Timezone.ISOString;
        orderId: Order.Id;
        negotiationId: Id;
        chartererEmail: Encoding.Email.ISOString;
        updateToken: UpdateToken;
        offerRepToken: string;
        jumpToStatus: Status.MainTerms | Status.OnSubs | Status.Fixed;
        responseDateTime: Encoding.Timezone.ISOString;
        isOfferRepClaimed: boolean;
        brokerEmailAddresses: Encoding.Email.ISOString[];
        chartererEmailAddresses: Encoding.Email.ISOString[];
        ownerEmailAddresses: Encoding.Email.ISOString[];
      }

      interface UpdateBid {
        updateToken: UpdateToken;
        action: Action.Type;
        details: Details.All;
        expiresOn: Encoding.Date.ISOString;
      }

      interface UpdateOffer {
        updateToken: UpdateToken;
        action: Exclude<Action.Type, "firmRequested">;
        details: Details.All;
        expiresOn: Encoding.Date.ISOString;
      }

      namespace Publish {
        interface Model {
          updateToken: UpdateToken;
          action: string;
        }
      }

      interface UpdateOwningCompany {
        value: Account;
      }

      interface UpdateNote {
        updateToken: UpdateToken;
        as: UpdateNote.As;
        value: Notes.Note;
      }
      namespace UpdateNote {
        type As = Action.Role;
      }

      interface Withdraw {
        updateToken: UpdateToken;
      }

      interface OfferrepDisclaimer {
        updateToken: string;
      }
    }

    namespace Response {
      type ProceedToMainTerms = GenericUpdate;
      type UpdateBid = GenericUpdate;
      type UpdateOffer = GenericUpdate;
      type UpdateAll = GenericUpdate;
      type Publish = GenericUpdate;
      type UpdateOwningCompany = GenericUpdate;
      type UpdateNote = GenericUpdate;
      type Withdraw = GenericUpdate;
      type Disclaimer = GenericUpdate;
      namespace OfferrepDisclaimer {
        type Model = GenericUpdate;
      }

      interface GenericUpdate {
        id: Id;
        version: Version;
      }
    }
  }

  interface Lifting {
    id: Lifting.Id;
    cargoId?: Lifting.CargoID;
    neg?: Negotiation;
    version: Lifting.Version;
  }

  namespace Lifting {
    type Id = number;
    type CargoID = Encoding.Id.GUID;
    type Version = number;
  }

  interface Vessel {
    // stuff you get from TradeAPI.searchVessels
    vesselIMO: number;
    dwt: number;
    arcVesselId: number;
    buildYear: number;
    registrationDataName: string;

    // actual TradeAPI model
    vesselImo: number;
    status: Vessel.Status;
    eta: Encoding.Date.ISOString;
    itinerary: string;
    speedAndConsumption: string;
    ownerChain: string;
    additionalNotes: string;
    vesselDescription: string;

    display: string;
    shortDisplay: string;
  }

  namespace Vessel {
    type Status = "named" | "accepted" | "rejected";
  }

  namespace SignalR {
    interface Credentials {
      accessToken: string;
      url: string;
    }
    namespace Credentials {}
  }

  interface Location {
    country: string;
    countryCode: string;
    countryId: string;
    locationId: string;
    name: string;
    zone: string;
    zoneId: string;
    parents: string[];
    notes: string;
    safeBerthsMin: number;
    safeBerthsMax: number;
    safePortsMin: number;
    safePortsMax: number;
    safeAnchoragesMin: number;
    safeAnchoragesMax: number;
    globalZone: string;

    display: string;
    shortDisplay: string;
  }

  namespace Location {
    namespace Search {
      interface Item {
        document: Location;
        "@search.text": string;
      }
      namespace Item {}
    }
  }

  interface Account {
    id: Encoding.Id.GUID;
    companyId: Company.Id;
    companyName: Company.Name;
    accountId: Encoding.Id.GUID;
    arcContactId: string;
    accountName: string;
    gainAccountId: string;
    gainAccountGroupId: string | null;
    isLegalEntity: boolean;
    email: Encoding.Email.ISOString;
  }

  namespace Account {
    namespace Search {
      interface Item {
        document: Account;
        "@search.text": string;
      }
    }
  }

  interface UnverifiedAccount {
    accountName: string;
  }

  interface LegacyCLDDUser {
    companyId: string;
    companyName: string;
    companyRoles: string[];
    companyType: string;
    deskId: string;
    deskName: string;
    divisionId: string;
    divisionName: string;
    email: string;
    groupEmailAddress: string;
    locationId: string;
    locationName: string;
    systemUserId: number;
    userId: string;
    userName: string;
    name: string;
  }

  namespace LegacyCLDDUser {
    namespace Search {
      type Subset = "owners" | "charterers" | "brokers" | "users" | "usersandowners";
    }
  }

  interface Timezone {
    id: Encoding.Id.GUID;
    display: string;
  }

  namespace Timezone {}

  interface Company {
    companyId: Company.Id;
    name: Company.Name;
  }

  namespace Company {
    type Name = string;
    type Id = Encoding.Id.GUID;
    type Type = "broker" | "charterer" | "owner";
  }

  interface Desk {
    id: Desk.Id;
    name: Desk.Name;
  }

  namespace Desk {
    type Name = string;
    type Id = Encoding.Id.GUID;
  }

  interface COACargoSize {
    option: COACargoSize.Option;
    min: number;
    max: number;
    variance: number;
    notes: string;

    display: string;
    shortDisplay: string;
  }

  namespace COACargoSize {
    type Option = "MIN/MAX" | "MOLOO" | "MOLCHOPT";
  }

  interface Nominations {
    noticePerLaycan: number;
    laycanSpread: number;
    finalLaycanNotice: number;
    notes: string;

    display: string;
    shortDisplay: string;
  }

  namespace Nominations {}

  interface Liftings {
    min: number;
    max: number;
    dispersal: Liftings.Dispersal;
    notes: string;
    display: string;
    shortDisplay: string;
  }

  namespace Liftings {
    type Dispersal = "Fairly even spread" | "Monthly" | "Adhoc";
  }

  interface CargoSize {
    option: CargoSize.Option;
    value: number;
    variance: number;
    notes: string;

    display: string;
    shortDisplay: string;
  }

  namespace CargoSize {
    type Option = "MIN/MAX" | "MOLOO" | "MOLCHOPT";
  }

  interface UnitValue {
    value: number;
    variance: number;
    notes: string;

    display: string;
    shortDisplay: string;
  }

  namespace UnitValue {}

  interface FreightRate extends UnitValue {
    unit: FreightRate.Unit;
  }

  namespace FreightRate {
    type Unit = "PerMT" | "LumpSum";
  }

  interface HireRate extends UnitValue {
    unit: HireRate.Unit;
    notes: string;
  }

  namespace HireRate {
    type Unit = "PerDay" | "LumpSum";
  }
  interface CleaningPrice extends UnitValue {
    unit: CleaningPrice.Unit;
    notes: string;
  }
  namespace CleaningPrice {
    type Unit = "PerDay" | "LumpSum" | "PerMT";
  }
  interface SupplyPrice extends UnitValue {
    unit: SupplyPrice.Unit;
    notes: string;
  }
  namespace SupplyPrice {
    type Unit = "PerDay" | "LumpSum" | "PerMT";
  }
  interface Demurrage extends UnitValue {
    unit: Demurrage.Unit;
  }

  namespace Demurrage {
    type Unit = "PerDay" | "LumpSum";
  }

  interface BallastBonus extends UnitValue {
    unit: BallastBonus.Unit;
    notes: string;
  }

  namespace BallastBonus {
    type Unit = "PerDay" | "LumpSum";
  }

  interface CargoType {
    arcId: number;
    name: string;
    notes: string;

    display: string;
    shortDisplay: string;
  }

  namespace CargoType {}

  interface Period {
    start: string;
    end: string;
    style: number;
    styleDescription: string;
    term: string;

    // /laycandate returns start/end, but BE decided they want from/to
    from: string;
    to: string;

    display: string;
    shortDisplay: string;
  }
  namespace Period {}

  interface Duration {
    unit: Duration.Unit;
    min: number;
    max: number;
    notes: string;

    display: string;
    shortDisplay: string;
  }

  namespace Duration {
    type Unit = "Days" | "Months";
  }

  interface Commission {
    value: number;

    display: string;
    shortDisplay: string;
  }
  namespace Commission {}

  interface Bunker {
    price: number;
    quantity: number;
    fuelTypes: string;
    notes: string;

    display: string;
    shortDisplay: string;
  }

  interface TradingExclusions {
    value: string;
    display: string;
    shortDisplay: string;
  }

  interface BunkerDeliveryText {
    value: string;
    display: string;
    shortDisplay: string;
  }

  interface BunkerRedeliveryText {
    value: string;
    display: string;
    shortDisplay: string;
  }

  interface CargoExclusionsText {
    value: string;
    display: string;
    shortDisplay: string;
  }

  interface Subs {
    value: string;

    display: string;
    shortDisplay: string;
  }
  namespace Subs {}

  interface VesselSize {
    vesselSizeFull: string;
    vesselSizeAbbreviation: string;
    sizeFrom: number;
    sizeTo: number;
    notes: string;

    display: string;
    shortDisplay: string;
  }
  namespace VesselSize {}

  namespace CpSearchItem {
    type Id = number;
    type Status = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 100; // Not sure how many
    type StatusDescription =
      | "Draft"
      | "OnSubs"
      | "FullyFixed"
      | "Cancelled"
      | "WorkingCopy"
      | "Final"
      | "DryDraft"
      | "DryCancelled"
      | "Proforma"
      | "TankerProforma"
      | "Unknown";
    type PermissionDescription = "Read" | "Edit" | "FullControl" | "EmailOnly" | "ReadOnlyExtended";
    type Date = string; // ??????
    type Permission = 0 | 1 | 2 | 3 | 4 | 5 | 6; // Not sure how many
  }
  interface CpSearchItem {
    cpId: CpSearchItem.Id;
    vesselName?: string;
    status: CpSearchItem.Status;
    statusDescription: CpSearchItem.StatusDescription;
    cpFormName: string;
    cpDate?: CpSearchItem.Date;
    charterer: string;
    broker: string;
    ownerGroup?: string;
    owner?: string;
    lastUpdatedOn: CpSearchItem.Date;
    permission: CpSearchItem.Permission;
    permissionDescription: string;
    hasCautions: boolean;
  }
  namespace CP {
    interface CpField {
      FieldName: string;
      SystemName: string;
      DataType: string;
      ValidationRegex: string | null;
      ValidationMessage: string | null;
      Q88Path: string | null;
      LookupValues: string;
      DataFieldLookupType: string | null;
      DataFieldGroup: number;
      Value: string;
      DataFieldOrder: number;
      DataFieldUnit: string | null;
      FiledDescription: string;
    }
  }
  interface CP {
    DataFields: CP.CpField[];
  }
  interface TermsetResponse {
    value: Termset[];
  }
  interface Termset {
    updated: Encoding.Date.ISOString;
    updatedBy: User;
    created: Encoding.Date.ISOString;
    createdBy: User;
    published: Encoding.Date.ISOString;
    publishedBy: User;
    isPublished: boolean;
    isDeleted: boolean;
    id: Encoding.Id.GUID;
    typeId: Termset.TypeId;
    orderType: number;
    companyId: Company.Id;
    companyName: Company.Name;
    name: string;
    content: Termset.Content;
  }
  namespace Termset {
    type Id = Encoding.Id.GUID;
    type TypeId = number | string;
    interface Content {
      proformaLayoutId: Content.ProformaLayout.Id;
      proformaLayoutName: Content.ProformaLayout.Name;
      previouslyExecutedCpId: Content.PreviouslyExecutedContract.Id;
      proformaOtherName: string;
      templateDetails: {
        displayName: string;
        description: string;
      };
      mainTermTemplates: Content.Term[];
      orderDetails: any;
    }
    namespace Content {
      interface ProformaLayout {
        layoutId: ProformaLayout.Id; // alias for id, only present in /proforma/layouts
        text: ProformaLayout.Name; // alias for name, only present in /proforma/layouts
        key: ProformaLayout.Id; // alias for id, only present in /proforma/layouts
        id: ProformaLayout.Id;
        name: ProformaLayout.Name;
        proformaKey: string;
        keys: string[];
        ownerCpmProformaKeyOverride: string;
        chartererCpmProformaKeyOverride: string;
      }
      namespace ProformaLayout {
        type Id = number;
        type Name = string;
      }

      interface PreviouslyExecutedContract {
        id: PreviouslyExecutedContract.Id;
      }
      namespace PreviouslyExecutedContract {
        type Id = number;
      }

      interface Term {
        TermTemplateType: string;
        title: string;
        cpmProformaKey: string;
        content: string;
        overrideContent: string;
        termId: string;
        cpField: CP.CpField;
        cpValueOverride: boolean;
        parentCPID: number;
        uid?: string;
        handlebars: HandlebarWithReplacement[];
        pills?: string[];
      }
      namespace Term {}
    }

    interface Handlebar {
      name: string;
      key: string;
    }
  }

  namespace TermsetImport {
    interface Payload {
      proformaLayoutId: number;
      previouslyExecutedCpId?: number;
      proformaLayoutName: string;
      orderDetails: {
        orderTypeId: number;
        laycanOffsetDays: number;
        laycanFromToDays: number;
        cargoType: number;
        loadPortId: string;
        dischargePortId: string;
        deliveryLocationId: string;
        viaLocationId: string;
        redeliveryLocationId: string;
        cargoSizeFrom: number;
        cargoSizeTo: number;
        cargoSizeText: string;
        arcContactId: number;
        replyTimeMinutes: number;
      };
      templateDetails: { displayName: string };
      mainTermTemplates: Termset.Content.Term[];
    }
  }

  interface User {
    Company: string;
    CompanyId: string;
    CompanyRoles: User.CompanyRole[];
    IsCompanyVerified: boolean;
    Desk: string;
    DeskId: string;
    Division: string;
    DivisionId: string;
    Features: null;
    Timezone: string;
    Location: string;
    LocationId: string;
    SystemUserId: number;
    UserEmail: string;
    UserName: string;
    aud: string;
    email: string;
    exp: number;
    iat: number;
    isCtradeAdministrator: boolean;
    isMaritechUser: boolean;
    HasConfirmedDisclaimer: boolean;
    iss: string;
    memberships: User.Membership[];
    name: string;
    sub: string;
  }
  namespace User {
    type Name = string;
    type CompanyRole = CompanyRole.Owner | CompanyRole.Charterer | CompanyRole.Broker;

    namespace CompanyRole {
      type Owner = "owner";
      type Charterer = "charterer";
      type Broker = "broker";
    }

    interface Membership {
      companyRoles: string[];
      id: Encoding.Id.GUID;
      level: string;
      name: string;
    }
  }

  interface TeamMember {
    id: Encoding.Id.GUID;
    name: string;
    email: Encoding.Email.ISOString;
    systemUserId: number;
    companyType: Company.Type;
    company: {
      name: string;
      id: Encoding.Id.GUID;
    };
    location: {
      name: string;
      id: Encoding.Id.GUID;
    };
    division: {
      name: string;
      id: Encoding.Id.GUID;
    };
    desk: {
      name: string;
      id: Encoding.Id.GUID;
    };
  }

  namespace Distribution {
    interface User {
      email: Encoding.Email.ISOString; // always send
      role: Company.Type; // always send
      status: User.Status; // appears in the order after Post /distribution update
      sentOn: Encoding.Date.ISOString; // appears in the order after Post /distribution update
      knownUser: TeamMember;
      actor: TeamMember;
      availableRoles: Company.Type[];
      negDetails: {
        voyNegId: string;
        voyNegOfferRepToken: string;
        coaNegId: string;
        coaNegOfferRepToken: string;
        tctNegId: string;
        tctNegOfferRepToken: string;
      };
    }
    namespace User {
      type Status = "pending" | "success";
    }
  }

  interface EmailList {
    id: EmailList.Id;
    createdBy: User.Name;
    createdOn: Encoding.Date.ISOString;
    lastUpdate: Encoding.Date.ISOString;
    name: EmailList.Name;
    updateToken: EmailList.UpdateToken;
    values: EmailList.Values;
    version: number;
    sharing: (Clddu.Company.Id | Clddu.Location.Id | Clddu.Division.Id | Clddu.Desk.Id)[];
  }
  namespace EmailList {
    type Id = Encoding.Id.GUID;
    type Name = string;
    type Values = Encoding.Email.ISOString[];
    type UpdateToken = string;
  }

  namespace Clddu {
    interface Entity {
      id: Entity.Id;
      name: Entity.Name;
      level: Entity.Level;
      directParents: Entity[];
      memberships: Entity[];
      companyRoles: TradeAPI.User.CompanyRole[];
    }
    namespace Entity {
      type Id = Encoding.Id.GUID;
      type Name = string;
      type Level = "company" | "location" | "division" | "desk";
    }

    interface Company extends Entity {
      divisions: Division[];
      locations: Location[];
      desks: Desk[];
      companyId: Company.Id;
    }
    namespace Company {
      type Name = string;
      type Id = Encoding.Id.GUID;
    }
    interface Location extends Entity {
      companyId: Company.Id;
    }
    namespace Location {
      type Name = string;
      type Id = Encoding.Id.GUID;
    }
    interface Division extends Entity {
      companyId: Company.Id;
      locationId: Location.Id;
    }
    namespace Division {
      type Name = string;
      type Id = Encoding.Id.GUID;
    }
    interface Desk extends Entity {
      companyId: Company.Id;
      locationId: Location.Id;
      divisionId: Division.Id;
    }
    namespace Desk {
      type Name = string;
      type Id = Encoding.Id.GUID;
    }
    interface User {
      companyId: Company.Id;
      companyName: Company.Name;
      locationId: Location.Id;
      locationName: Location.Name;
      divisionId: Division.Id;
      divisionName: Division.Name;
      deskId: Desk.Id;
      deskName: Desk.Name;

      id: User.Id;
      userId: User.Id;
      name: User.Name;
      userName: User.Name;
      companyType: TradeAPI.User.CompanyRole;
      companyRoles: TradeAPI.User.CompanyRole[];
      email: string;
      groupEmailAddress: string;
      systemUserId: number;
    }
    namespace User {
      type Name = string;
      type Id = Encoding.Id.GUID;
    }

    namespace Search {
      type Subset = "owners" | "charterers" | "brokers" | "users" | "usersandowners";
    }
  }

  namespace Encoding {
    namespace Date {
      type ISOString = string;
      type Unix = number;
    }

    namespace Timezone {
      type ISOString = string;
    }

    namespace Id {
      type GUID = string;
      type UUID = string;
    }

    namespace JWT {
      type ISOString = string;
    }

    namespace Email {
      type ISOString = string;
    }
  }

  namespace Enp {
    namespace Settings {
      namespace EmailLists {
        namespace Get {
          namespace Res {
            type Body = EmailList[];
          }
        }

        namespace Post {
          namespace Res {
            type Body = GenericRes;
          }
        }

        namespace Put {
          namespace Res {
            type Body = GenericRes;
          }
        }

        namespace Delete {
          namespace Res {
            type Body = GenericRes;
          }
        }

        interface GenericRes {
          id: string;
          version: number;
        }
      }
    }

    namespace Clddus {
      namespace UsersAndOwners {
        namespace Get {
          namespace Res {
            type Body = Clddu.User[];
          }
        }
      }
    }

    namespace OwnerAbsent {
      namespace Post {
        interface Body {
          email: string;
        }
      }
    }
    namespace Orders {
      namespace Post {
        namespace Res {
          interface Body {
            id: TradeAPI.Order.Id;
            version: TradeAPI.Order.Version;
          }
        }
      }
      namespace Order {
        namespace Negotiations {
          namespace Negotiation {
            namespace All {
              namespace Put {
                namespace Res {
                  export interface Body {
                    id: TradeAPI.Order.Id;
                    version: TradeAPI.Order.Version;
                  }
                }
              }
            }
            namespace Vessels {
              namespace Put {
                namespace Res {
                  export interface Body {
                    id: TradeAPI.Order.Id;
                    version: TradeAPI.Order.Version;
                  }
                }
              }
            }
            namespace OwningCompany {
              namespace Put {
                namespace Res {
                  interface Body {}
                }
              }
            }

            namespace Vessels {
              namespace Post {
                namespace Res {
                  interface Body {}
                }
              }

              namespace Vessel {
                namespace Put {
                  namespace Res {
                    interface Body {}
                  }
                }
              }
            }
          }
        }

        namespace Liftings {
          namespace Get {
            namespace Res {
              interface Body {
                canChartererManagedLiftings: boolean;
                displayValues: TradeAPI.Order.Details.Standard.VOY;
                liftingStatus: Body.Status;
                liftings: TradeAPI.Lifting[];
                updateToken: TradeAPI.Order.UpdateToken;
              }

              namespace Body {
                type Status = "waiting_for_slots" | "slots_ready";
              }
            }
          }
          export namespace Post {
            export namespace Res {
              export interface Body {
                id: TradeAPI.Order.Id;
                version: TradeAPI.Order.Version;
              }
            }
          }
        }

        namespace Files {
          namespace Id {
            namespace Delete {
              namespace Res {
                interface Body {}
              }
            }
          }
        }
      }

      namespace Capture {
        namespace Post {
          namespace Res {
            interface Body {
              id: TradeAPI.Order.Id;
              version: TradeAPI.Order.Version;
            }
          }
        }
      }
    }
    namespace OwnerDealCapture {
      namespace Put {
        // TODO or maybe Post? not sure
        namespace Req {
          interface TctBody {
            chartererAccount: Account;
            laycan: Period;
            cargoType: CargoType;
            addressCommission: Commission;
            brokerCommission: Commission;
            vesselSize: VesselSize;
            deliveryLocation: Location;
            redeliveryLocation: Location;
            viaLocation: Location;
            ballastBonus: BallastBonus;
            hireRate: HireRate;
            cleaningPrice: CleaningPrice;
            supplyPrice: SupplyPrice;
            tradingExclusions: TradingExclusions;
            bunkerDelivery: Bunker;
            bunkerRedelivery: Bunker;
            cargoExclusionsText: CargoExclusionsText;
            duration: Duration;
            tctNotes: string;
            orderType: Order.Type;
            jumpToStatus: Negotiation.Status.MainTerms | Negotiation.Status.OnSubs | Negotiation.Status.Fixed;
            responseDateTime: string;
            vessels: Vessel[];
            owningCompany: Account;
            termset: Termset;
            userTimeZone: string;
            cpDate: Encoding.Date.ISOString;
          }
          interface VoyBody {
            chartererAccount: Account;
            laycan: Period;
            cargoType: CargoType;
            addressCommission: Commission;
            brokerCommission: Commission;
            cargoSize: CargoSize;
            loadLocation: Location;
            dischargeLocation: Location;
            freightRate: FreightRate;
            demurrage: Demurrage;
            voyageNotes: string;
            orderType: Order.Type;
            jumpToStatus: Negotiation.Status.MainTerms | Negotiation.Status.OnSubs | Negotiation.Status.Fixed;
            responseDateTime: string;
            vessels: Vessel[];
            owningCompany: Account;
            termset: Termset;
            userTimeZone: string;
            cpDate: Encoding.Date.ISOString;
          }
        }
      }
      namespace Get {
        namespace Res {
          interface Body {
            orderId: Order.Id;
            negotiationId: Negotiation.Id;
            termsetId: Termset.Id;
          }
        }
      }
    }
    namespace Contracts {
      namespace Get {
        namespace Res {
          type Body = CP;
        }
      }
      namespace Search {
        namespace Get {
          namespace Res {
            type Body = CpSearchItem[];
          }
        }
      }
    }
  }
}
