import React, { memo, ReactNode, ButtonHTMLAttributes, useEffect, useRef } from "react";
import { kebabCase } from "lodash-es";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { wait } from "@/utils";
import { Icon, IconProps, Img, ImgProps } from "@/components";
import "./Button.scss";

function Button(props: Props) {
  let {
    children,
    disabled,
    icon,
    img,
    dom,
    href,
    to,
    type,
    label,
    openHrefInNewWindow,
    dataTest,
    hidden,
    tooltip,
    title,
    variant,
    containerClassName,
    ...rest
  } = props;

  const buttonRef = useRef<HTMLButtonElement>(null);

  const content = children || label;

  rest.className = classNames(rest.className, variant, icon, {
    "contains-icon": icon,
    "has-content": content,
    "icon-only": icon && !content,
  });

  useEffect(onMount, []);

  let button = (
    <button
      {...rest}
      disabled={disabled}
      type={type}
      ref={dom || buttonRef}
      title={tooltip || title}
      data-test={dataTest || kebabCase(`${content}`)}
    >
      <Img img={img} />
      <Icon icon={icon} />
      {content && <span className="text">{content}</span>}
    </button>
  );

  containerClassName = classNames(containerClassName, "sea-button", disabled && "disabled", { hidden });

  if (href) {
    let onClick;

    if (openHrefInNewWindow) onClick = openInNewWindow.bind(null, href || to);

    button = (
      <a className={containerClassName} href={href} onClick={onClick}>
        {button}
      </a>
    );

    //
  } else if (to) {
    button = (
      <NavLink className={containerClassName} to={to}>
        {button}
      </NavLink>
    );
  } else {
    button = <sea-button class={containerClassName}>{button}</sea-button>;
  }

  return button;

  function onMount() {
    if (dom) dom.current = buttonRef.current;

    if (props.autoFocus) autoFocus();

    return onUnmount;
  }

  function onUnmount() {
    if (dom) dom.current = null;
  }

  async function autoFocus() {
    await wait(100);

    buttonRef.current?.focus();
  }
}

function openInNewWindow(url, e) {
  e.preventDefault();

  window.open(url, "_blank");
}

Button.defaultProps = {
  variant: "flat",
  type: "button",
};

const Memo = memo(Button);

export { Memo as Button };

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  tooltip?: string;
  icon?: IconProps["icon"];
  img?: ImgProps["img"];
  variant?: Variant;
  className?: string;
  containerClassName?: string;
  children?: ReactNode;
  onClick?: (e: MouseEvent) => void;
  disabled?: boolean;
  dom?: DOMRef;
  href?: string | null;
  to?: string | null;
  openHrefInNewWindow?: boolean;
  dataTest?: string;
}

type Variant = "action" | "positive" | "warning" | "flat" | "text" | "link";

type MouseEvent = React.MouseEvent<HTMLButtonElement>;

type DOMRef = React.MutableRefObject<HTMLButtonElement | null>;

export type ButtonProps = Props;
export type ButtonMouseEvent = MouseEvent;
