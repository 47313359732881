import React, { ReactNode } from "react";
import { DateField, DateFieldProps, DateOffsetField, DateOffsetFieldProps } from "@/components/common/Field";
import { Group } from "../../Group";
import "./Timelimit.scss";

export function TimelimitEditor(props: Props) {
  props = { ...props };

  props.className = `${props.className} Timelimit`;
  props.dataTest = props.dataTest || "timelimit";

  const { className, variant = "Date", onChange, getLayout, dataTest, ...rest } = props;

  function onTimeChange(date: Value) {
    props.onChange?.(date);
  }

  const InputComp = INPUT_VARIANT[variant];

  // @ts-ignore
  const input = <InputComp {...rest} onChange={onTimeChange} time={true} key="input" dataTest={`${dataTest}.value`} />;

  const layout = getLayout?.(input)?.map(LayoutElementHOC);

  if (!variant) return null;

  return (
    <Group className={className}>
      <div className="Timelimit-layout">{layout}</div>
    </Group>
  );
}

function LayoutElementHOC(element: ReactNode, i: number) {
  if (typeof element === "string") element = <span key={i}>{element}</span>;

  return element;
}

const INPUT_VARIANT = {
  Date: DateField,
  DateOffset: DateOffsetField,
};

interface Props extends DateFieldPropsWithoutUnmergable, DateOffsetFieldPropsWithoutUnmergable {
  value?: Value;
  onChange?: (value: Value) => void;
  variant?: "Date" | "DateOffset";
  getLayout?: (input: ReactNode) => ReactNode[];
}

type Value = DateFieldProps["value"] | DateOffsetFieldProps["value"];

type DateFieldPropsWithoutUnmergable = Omit<DateFieldProps, "value" | "onChange" | "interceptChangeHandler">;
type DateOffsetFieldPropsWithoutUnmergable = Omit<DateOffsetFieldProps, "value" | "onChange">;

export type TimelimitEditorProps = Props;
