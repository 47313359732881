import React, { useMemo } from "react";
import { RouteSeed } from "@/models";
import { MainTerms, Context } from "@/components/MainTerms";

export const mainTerms = {
  path: "/mainterms/:orderId/:negotiationId/:type?",
  pageTitle: "Main Terms",
  comp: MainTermsWrapper,
  sidenav: ["orders", "owner", "coa"],
} as RouteSeed;

function MainTermsWrapper() {
  const context = useMemo(getContext, []);

  return <MainTerms context={context} />;
}

function getContext() {
  return context;
}

const context = {
  dataTest: "MainTerms",
} as Context;
