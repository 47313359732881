import { createContext, useContext } from "react";
import { Claims } from "dashboard/services/ClaimsProvider";
import { auth } from "@/models";
import { emptyArrowFn } from "@/utils";
interface Auth {
  claims: Claims;
  cloudToken: string;
  ctradeToken: string;
  cloudTokenExpiry: string;
}

interface IAuthContext {
  auth: Auth;
  fatalAuthError: (message: string) => void;
  refreshCtradeTokenAndSaveToStorage: (cloudToken: string) => Promise<string | void>;
}

const AuthContext = createContext<IAuthContext>({
  // without this default object, auth needs to be checked for null and undefined everywhere it's used throughout the site
  auth: {
    claims: {
      exp: "",
      iat: "",
      sub: "",
      memberships: [],
      email: "",
      UserName: "",
      Company: "",
      Timezone: "",
      CompanyId: "",
      Division: "",
      DivisionId: "",
      Location: "",
      LocationId: "",
      Desk: "",
      DeskId: "",
      CompanyRoles: [],
      SystemUserId: 0,
      HasConfirmedDisclaimer: false,
    },
    cloudToken: "",
    ctradeToken: "",
    cloudTokenExpiry: "",
  },
  fatalAuthError: emptyArrowFn,
  refreshCtradeTokenAndSaveToStorage: () => Promise.resolve(""),
});

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthContext`);
  }

  // @ts-ignore
  context.auth.claims = auth.trade.user;

  return context;
}
