import { ICreateOrder } from "../models/ICreateOrder";
import { IOrder } from "../models/IOrder";
import moment from "moment";

/**
 * Initialise a new IOrder model given an initial CreateOrder data model
 * @param createOrder Create Order
 */
export function mapCreateOrder(createOrder: ICreateOrder): IOrder {
  const createdOn = moment().format();

  const locations: string[] = [
    createOrder.loadLocation,
    createOrder.dischargeLocation,
    createOrder.deliveryLocation,
    createOrder.viaLocation,
    createOrder.redeliveryLocation,
  ]
    .filter(Boolean)
    .map((l) => (l && l.name ? l.name : ""));

  return {
    brokers: [],
    charterer: undefined,
    createdOn,
    details: createOrder,
    id: new Date().getTime().toString(), // initial ID
    responseRequired: undefined,
    sharedWith: [],
    status: "Inactive",
    summary: {
      cargoType: createOrder.cargoType?.name || "",
      coaCargoSize: createOrder.coaCargoSize?.display || "",
      chartererAccount: createOrder.chartererAccount.accountName,
      createdBy: "",
      firmExpires: [],
      laycan: createOrder.laycan?.display || "",
      liftings: createOrder.liftings?.display || "",
      locations: locations,
      period: createOrder.period?.display || "",
      tctCount: 0,
      voyCount: 0,
      onSubsExpires: [],
      orderReference: "",
    },
    types: [],
    updateToken: "",
    version: 0,
    groupChats: [],
    teamMembers: [],
    distributionList: [],
    attachments: [],
    negAttachments: [],
  };
}
