import React, { useState } from "react";
import styles from "./ViewMoreEmailGroups.module.scss";
import Button from "__legacy/sharedFolder/components/common/Button/Button";

interface IViewMoreProps {
  title: string;
  children?: JSX.Element;
}

export const ViewMoreEmailGroups = (props: IViewMoreProps) => {
  const [display, setDisplay] = useState(false);

  return (
    <div className={styles.container}>
      <Button
        className={`view-more-email-groups-btn ${display ? "display" : ""}`}
        type="flat"
        icon="chevron-right"
        dataTest="email-groups-button"
        onClick={() => setDisplay(!display)}
      >
        distribution lists
      </Button>
      {display && <div className={styles.moreDetailsContent}>{props.children}</div>}
    </div>
  );
};
