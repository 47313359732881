import { Negotiation } from "@/models";
import React from "react";
import "./NegotiableDetailSummary.scss";

export function NegotiableDetailSummary(props: Props) {
  const { negotiation, side } = props;
  const negotiableArray =
    side === "offer" ? negotiation.getNegotiableOfferModelArray() : negotiation.getNegotiableBidModelArray();
  const title = side === "offer" ? "Offer Summary" : "Bid Summary";
  // @ts-ignore
  const details = negotiableArray.map(DetailHOC);

  return (
    <div className="NegotiableDetailSummary">
      <h2>{title}</h2>
      {details}
    </div>
  );

  function DetailHOC(detail, i) {
    if (!detail) return;

    return (
      <div className="NegotiableDetailSummary-detail" key={i}>
        <label>{detail._.label}</label>
        <div className="NegotiableDetailSummary-detail-value">{detail.toView()}</div>
      </div>
    );
  }
}

interface Props {
  negotiation: Negotiation;
  side: "bid" | "offer";
}
