import { useMemo } from "react";
import { cloneDeep, set } from "lodash-es";
import { observer } from "mobx-react";
import { Aggrid, StatusMessages } from "@/components";
import "./SubjectTermset.scss";

function SubjectTermsetEditor(props) {
  const { onChange, value: termset, columnDefs = defaultColumnDefs, status } = props;
  const mainTermTemplates = termset?.content?.mainTermTemplates;
  const mainTermTemplatesClone = useMemo(() => cloneDeep(mainTermTemplates), [mainTermTemplates]);

  function onCellValueChanged(params) {
    const { colDef, rowIndex, newValue } = params;

    onChange(set(termset, `content.mainTermTemplates.${rowIndex}.${colDef.field}`, newValue));
  }

  const mainTermTemplatesStatus = status?.children?.content?.children?.mainTermTemplates;

  return (
    <div className="SubjectTermset">
      <StatusMessages status={mainTermTemplatesStatus} />
      <Aggrid
        rowData={mainTermTemplatesClone}
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        onCellValueChanged={onCellValueChanged}
        getRowNodeId={getRowNodeId}
        immutableData={true}
        rowHeight={53}
      />
    </div>
  );
}

const defaultColumnDefs = [
  {
    flex: 0,
    width: 36,
    $blank: true,
  },
  {
    flex: 0,
    width: 36,
    $blank: true,
  },
  {
    field: "title",
    headerName: "Subject Title",
    cellEditor: "Editor",
    cellEditorParams: {
      props: {
        type: "Text",
      },
    },
    flex: 2,
  },
  {
    field: "content",
    headerName: "Subject Details",
    cellEditor: "Editor",
    cellEditorParams: {
      props: {
        type: "Textarea",
      },
    },
    flex: 3,
  },
];

const defaultColDef = {
  suppressMenu: true,
  suppressMovable: true,
  sortable: false,
  editable: true,
};

function getRowNodeId(item) {
  return item?.termId;
}

const Observer = observer(SubjectTermsetEditor);

export { Observer as SubjectTermsetEditor };
