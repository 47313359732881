import { Order, dialog } from "@/models";
import { testSelectors } from "@/constants";
import { OrderWithdrawal } from "../Order/Status/Withdrawn";
import { tradeAPI } from "@/apis";
import { actionRetry } from "@/utils/actionRetry";
import { config as tradeConfig } from "@/config";

function showWithdrawalFailureDialog(order: Order, response: any) {
  order._.status.type = "error";
  order._.status.title = "Order Withdrawal Failure";
  if (response) {
    order._.status.dump = { response, entity: order };
  } else {
    order._.status.dump = { entity: order };
  }

  dialog.show({
    status: order._.status,
  });

  order._.status.loading = false;
  order._.status.message = null;
}

export async function withdrawOrder(order: Order) {
  const { res } = await dialog.show({
    content: <OrderWithdrawal orderId={order.id}></OrderWithdrawal>,
    dataTest: testSelectors.confirmSubsFailDialog,
  });

  if (!res) {
    return;
  }

  const payload = {
    updateToken: order.updateToken,
    reason: res.reason,
  };

  // Loading will be set to false after Trade UI receive an update from SignalR
  order._.status.loading = true;
  order._.status.message = "Withdrawing order...";

  let response = { ok: false };
  try {
    response = await tradeAPI.withdrawOrder(order.id, payload);
  } finally {
    if (!response?.ok) {
      showWithdrawalFailureDialog(order, response);
    }
  }

  const countOfRetries = tradeConfig.archiveWaitPolicy?.singleWaitRetryCount ?? 300;
  const retryDelay = tradeConfig.archiveWaitPolicy?.singleWaitRetryDelayMs ?? 100;

  return await actionRetry(
    {
      action: () => order.withdrawalState,
      handleSuccess: () => true,
      handleFailure: () => {
        showWithdrawalFailureDialog(order, null);
        return false;
      },
    },
    countOfRetries,
    retryDelay
  );
}
