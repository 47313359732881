import React, { useRef, useState } from "react";
import { useShallowStateOld, emptyFn } from "___REFACTOR___/utils";
import { tradeAPI } from "___REFACTOR___/apis";
import { Status, Termset } from "___REFACTOR___/models";
import { SelectField } from "___REFACTOR___/components";
import { MainTermsTypeEvents, usageMetrics } from "@/services/UsageMetrics";

function TermsetSearchEditor(props: TermsetSearchEditor.Props) {
  const { status, dataTest, typeId } = props;
  const [data, setData] = useState<Termset[] | undefined>(undefined);
  const [, setDropdownStatus, dropdownStatus] = useShallowStateOld<Status>({
    message: "Waiting for your input",
  });
  const selectedTermset = useRef<Termset>();
  const cancel = useRef(emptyFn);
  const { childMap, ...termsetStatus } = status || {};
  const termsetIdStatus = childMap?.id;

  if (!props.value) {
    selectedTermset.current = undefined;
  }

  return (
    <SelectField
      {...props}
      editorType="TermsetSearch"
      value={selectedTermset.current}
      onChange={onChange}
      data={data}
      status={termsetStatus || termsetIdStatus}
      placeholder="Search for a termset template, e.g. Iron"
      dropdownStatus={dropdownStatus}
      onFilter={onFilter}
      onBlur={onBlur}
      dataTest={`${dataTest}.value`}
    />
  );

  async function onFilter(search: string) {
    cancel.current();

    setData(undefined);
    setDropdownStatus({ message: undefined, loading: false, search });

    if (!search) {
      setDropdownStatus({ message: "Waiting for your input" });

      return;
    }

    setDropdownStatus({ message: "Searching for termsets", loading: true });

    const promise = tradeAPI.termsets.search.byTermsetName.get(search, typeId);

    cancel.current = promise.cancel;

    const res = await promise;

    if (res.cancelled) return;

    if (!res.data || !res.data.value || !res.data.value.length) {
      setData(undefined);
      setDropdownStatus({ message: "No termsets found", loading: false });

      return;
    }

    const data = res.data.value.map((termset) => new Termset(termset));

    setData(data);
    setDropdownStatus({ message: undefined, loading: false });
  }

  function onChange(termset: Termset) {
    usageMetrics.trackEvent(MainTermsTypeEvents.TERMSET_SELECTED, { termset });

    selectedTermset.current = termset;

    props.onChange?.(termset?.id);
  }

  function onBlur(e) {
    cancel.current();

    setData(undefined);
    setDropdownStatus({
      message: "Waiting for your input",
      loading: false,
    });

    props.onBlur?.(e);
  }
}

export { TermsetSearchEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace TermsetSearchEditor {
  export interface Props extends SelectField.Props {
    value: Value;
    onChange: (value: Value) => void;
    typeId: number;
  }

  export type Value = Termset.Id | undefined;
}
