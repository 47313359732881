import { makeAutoObservable, observable } from "mobx";
import { MiniConfirm as Component } from "___REFACTOR___/components/services/MiniConfirm";
import { PromiseBox } from "___REFACTOR___/models/common";

class MiniConfirm {
  constructor() {
    this.props = undefined;

    makeAutoObservable(this, { props: observable.ref });
  }

  private promiseBox?: PromiseBox<boolean>;

  get isOpen() {
    return !!this.props;
  }

  get promise() {
    return this.promiseBox?.promise;
  }

  open(props: MiniConfirm.Props) {
    this.props = props;

    const promiseBox = new PromiseBox<boolean>();

    this.promiseBox = promiseBox;

    return promiseBox.promise;
  }

  confirm() {
    this.promiseBox?.resolve(true);
    this.reset();
  }

  decline() {
    this.promiseBox?.resolve(false);
    this.reset();
  }

  private reset() {
    this.props = undefined;
  }
}

const miniConfirm = new MiniConfirm();

export { miniConfirm, MiniConfirm };

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

interface MiniConfirm {
  props?: MiniConfirm.Props;
}

declare namespace MiniConfirm {
  export type Props = Component.Props;
}
