import { object, string, array } from "yup";
import { Email } from "___REFACTOR___/models/common/Email";

function createSchema() {
  // https://github.com/jquense/yup
  const schema = object({
    name: string().required("Required"),
    _: object({
      emailArr: array().of(Email.schema),
    }),
  });

  return schema;
}

export { createSchema };
