import { action, observable, makeObservable } from "mobx";
import { TradeAPI, tradeAPI } from "___REFACTOR___/apis";
import { auth } from "___REFACTOR___/services";
import { Clddu, Company } from "___REFACTOR___/models/Trade";

class UserCompany {
  constructor() {
    // @ts-ignore
    this.company = undefined;

    makeObservable(this, {
      company: observable.ref,
      getData: action,
    });
  }

  async getData() {
    const res = await tradeAPI.getCompany(auth.trade.user?.CompanyId!);

    if (!res.ok) return;

    this.data = res.data[0];

    const clddu = new Clddu();
    this.company = clddu.upsertChild({ ...res.data[0], parent: clddu, clddu });
    this.company.initFromFullData();
  }
}

const userCompany = new UserCompany();

export { userCompany };

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

interface UserCompany {
  company: Company;
  data: TradeAPI.Clddu.Company;
}
