import React, { ReactNode } from "react";
import dayjs from "dayjs";
import classNames from "classnames";
import { duration, useKeepRerendering } from "@/utils";

export function Timeleft(props: Props) {
  props = { ...props };

  props.className = classNames(props.className, "timeleft");

  const { until, format = defaultFormat, textOnly } = props;

  useKeepRerendering();

  let timeleft = "";

  if (until) {
    if (new Date() <= new Date(until)) timeleft = duration(dayjs(until).diff(new Date())).text;
    //
    else timeleft = "";
  }

  let content = format(timeleft);

  content = textOnly ? <>{content}</> : <span className={props.className}>{content}</span>;

  return content;
}

function defaultFormat(content) {
  return content;
}

interface Props {
  className?: string;
  until: string | null | undefined;
  format?: (timeleft: string) => ReactNode;
  textOnly?: boolean;
}
