import { capitalizeFirstLetter, isTruthy } from "___REFACTOR___/utils";
import { LABEL_MAP } from "___REFACTOR___/constants";
import { TradeAPI } from "___REFACTOR___/apis";
import { CLDDUEditor, CLDDUBrokerEditor, CLDDUChartererEditor } from "___REFACTOR___/components";
import { auth } from "___REFACTOR___/services";
import { DataModel, DataModelProps } from "../DataModel";
import { User } from "../User";

class CLDDU extends DataModel {
  constructor(data?: PartialData, props?: PartialProps) {
    super(data);

    if (props?.user) {
      this.inheritFromUser(props.user);
    }
  }

  inheritFromUser(user: User) {
    this.companyType = user.CompanyRoles?.[0];
    this.companyName = user.Company;
    this.userName = user.UserName;
    this.companyId = user.CompanyId;
    this.companyName = user.Company;
    this.companyRoles = user.CompanyRoles;
    this.deskId = user.DeskId;
    this.deskName = user.Desk;
    this.divisionId = user.DivisionId;
    this.divisionName = user.Division;
    this.email = user.email;
    this.locationId = user.LocationId;
    this.locationName = user.Location;
    this.systemUserId = user.SystemUserId;
    this.userId = user.sub;
    this.userName = user.UserName;
  }

  toString() {
    if (typeof auth.trade.user?.SystemUserId === "number" && auth.trade.user?.SystemUserId === this.systemUserId) return "Me";

    const text = [this.getName(), capitalizeFirstLetter(this.companyType), this.email, this.deskName, this.companyName]
      .filter(isTruthy)
      .join(" | ");

    return text;
  }

  toGridView() {
    if (typeof auth.trade.user?.SystemUserId === "number" && auth.trade.user?.SystemUserId === this.systemUserId) return "Me";

    return getName(this);
  }

  getName() {
    return getName(this);
  }
}

function getName(clddu: CLDDU) {
  return clddu.name || clddu.userName;
}

class CLDDUProps extends DataModelProps {}

CLDDU.prototype.Props = CLDDUProps;
CLDDUProps.prototype.Editor = CLDDUEditor;

/* -------------------------------------------------------------------------- */
/*                                 CLDDUBroker                                */
/* -------------------------------------------------------------------------- */

class CLDDUBroker extends CLDDU {
  constructor(data?: Data, props?: PartialProps) {
    super(data);

    if (props?.user?._.companyRoleMap.broker) {
      this.inheritFromUser(props.user);
    }
  }
}

class CLDDUBrokerProps extends CLDDUProps {}

CLDDUBroker.prototype.Props = CLDDUBrokerProps;
CLDDUBrokerProps.prototype.Editor = CLDDUBrokerEditor;
CLDDUBrokerProps.prototype.label = LABEL_MAP.brokerContact;

/* -------------------------------------------------------------------------- */
/*                               CLDDUCharterer                               */
/* -------------------------------------------------------------------------- */

class CLDDUCharterer extends CLDDU {
  constructor(data?: Data, props?: PartialProps) {
    super(data);

    if (props?.user?._.companyRoleMap.charterer) {
      this.inheritFromUser(props.user);
    }
  }
}

class CLDDUChartererProps extends CLDDUProps {}

CLDDUCharterer.prototype.Props = CLDDUChartererProps;
CLDDUChartererProps.prototype.Editor = CLDDUChartererEditor;
CLDDUChartererProps.prototype.label = LABEL_MAP.chartererContact;

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface CLDDU extends Data {
  Props: typeof CLDDUProps;
  _: CLDDUProps;
}

interface CLDDUCharterer {
  Props: typeof CLDDUChartererProps;
  _: CLDDUChartererProps;
}

interface CLDDUBroker {
  Props: typeof CLDDUBrokerProps;
  _: CLDDUBrokerProps;
}

interface CLDDUProps {
  user: User;
}

type PartialProps = Partial<CLDDUProps>;
type PartialData = Partial<Data>;
type Data = TradeAPI.LegacyCLDDUser;

export { CLDDU, CLDDUProps, CLDDUBroker, CLDDUBrokerProps, CLDDUCharterer, CLDDUChartererProps };
