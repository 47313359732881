import { DataModel } from "@/models";
import { cloneDeep } from "lodash-es";

export function cleanValue(value, config: CleanValueConfig = {}) {
  const { clone = true } = config;

  if (value instanceof DataModel) value = value.toJSON();

  if (clone) value = cloneDeep(value);

  if (value === "") return;
  if (value === null) return;
  if (value === undefined) return;
  if (Number.isNaN(value)) return;
  if (typeof value === "function") return;

  if (typeof value === "string") return value;
  if (typeof value === "number") return value;
  if (typeof value === "boolean") return value;

  const entries = Object.entries(value);

  let allChildrenEmtpty = true;
  const removedKeys = [] as string[];

  for (let i = 0; i < entries.length; i++) {
    let [childKey, childValue] = entries[i];

    childValue = cleanValue(childValue, { ...config, clone: false });

    if (childValue === undefined) {
      delete value[childKey];
      removedKeys.push(childKey);
    }

    if (childValue !== undefined) allChildrenEmtpty = false;
  }

  if (allChildrenEmtpty) return;

  if (Array.isArray(value) && removedKeys.length > 0) {
    removedKeys.forEach((index) => value.splice(index, 1));
  }

  return value;
}

interface CleanValueConfig {
  clone?: boolean;
}
