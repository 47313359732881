import { IKnownUser } from "api/orders/models/IDistributionList";
import { IUserContext } from "__legacy/dashboard/contexts/UserProvider";
export const mapUserContext = (userContext: IUserContext): IKnownUser => {
  return {
    id: userContext.id,
    name: userContext.userName,
    email: userContext.email,
    systemUserId: String(userContext.systemUserId),
    company: { id: userContext.companyId, name: userContext.companyName },
    location: { id: userContext.locationId, name: userContext.locationName },
    division: { id: userContext.divisionId, name: userContext.divisionName },
    desk: { id: userContext.deskId, name: userContext.deskName },
    companyType: userContext.companyRoles.find((r) => r === "charterer")
      ? "charterer"
      : userContext.companyRoles.find((r) => r === "broker")
      ? "broker"
      : "owner",
  };
};
