import React from "react";
import { UNIT_VALUE_LABEL_MAP } from "___REFACTOR___/models";
import { TextareaField, NumberField, SelectField, StatusMessages, Field } from "___REFACTOR___/components";
import { Row } from "../../Row";
import { Group } from "../../Group";
import "./UnitValue.scss";

function UnitValueEditor(props: UnitValueEditor.Props) {
  const { status, dataTest, value, className, editorType, ...rest } = props;
  const unit = value?.unit || props.defaultUnit || "";
  const unitLabel = UNIT_VALUE_LABEL_MAP[unit];

  return (
    <Group {...props} editorType={editorType} className={className} hidden={props.hidden}>
      <Row>
        <NumberField
          {...rest}
          status={status?.childMap?.value || status}
          value={value?.value}
          onChange={onChange?.bind(null, "value")}
          dataTest={`${dataTest}.value`}
        />
        <SelectField
          {...rest}
          required={false}
          label="Unit"
          className="UnitValue-unit"
          data={props.units}
          status={status?.childMap?.unit}
          value={unitLabel}
          onChange={onUnitChange}
          dataTest={`${dataTest}.unit`}
          unclearable
          nonFilterSelect
        />
      </Row>
      <TextareaField
        {...rest}
        required={false}
        label="Notes"
        className="UnitValue-notes"
        value={value?.notes || ""}
        onChange={onChange?.bind(null, "notes")}
        status={status?.childMap?.notes}
        dataTest={`${dataTest}.notes`}
        disabled={value?.value === undefined}
      />
    </Group>
  );

  function onChange(name, inputValue) {
    props.onChange?.({ ...value, [name]: inputValue });
  }

  function onUnitChange(unit) {
    props.onChange?.({ ...value, unit });
  }
}

export { UnitValueEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace UnitValueEditor {
  export interface Props extends Field.Props {
    value: any;
    onChange: (value) => void;
    units?: string[];
    defaultUnit?: string;
  }
}
