import { when } from "mobx";
import { TradeAPI, tradeAPI } from "@/apis";
import { poll } from "@/utils";
import {
  getTimelimit,
  UniversalOrderNegotiationFormValues,
  DistributionUser,
  resolveNegotiationCreationDetails,
  getMainTermsDetails,
  Negotiation,
  dialog,
  router,
  auth,
} from "@/models";
import { Context } from "../MainTermsForm";
import uuid from "uuid";
import { TimeoutMs } from "@/components/Orders/actions";

let Refactor: Await<ReturnType<typeof resolveRefactorExports>>;
const resolveRefactorExports = async () => ({
  ...(await import("___REFACTOR___/services/EntityStorage")),
});
resolveRefactorExports().then((exports) => (Refactor = exports));

export async function postNegotiationMainTerms(
  negotiation: Negotiation | undefined,
  context: Context,
  values: UniversalOrderNegotiationFormValues,
  dialogValues: UniversalOrderNegotiationFormValues
) {
  if (!negotiation) {
    console.error("postNegotiationMainTerms:", { negotiation, context, values });

    const status = {} as Status;

    status.type = "error";
    status.title = "Main Terms Runtime Failure";

    dialog.show({
      status,
      dataTest: `mainterms-runtime-fail`,
    });

    return;
  }

  const { id } = negotiation;
  const negotiationDoesNotExist = `${id}`.includes("$");
  const order = negotiation._.order;
  let potentiallyNewNegotiation = negotiation;

  // find the current user in the distribution list, instead of creating the actor payload out of auth.trade.user -- will be done properly when we start re-implementing the distribution / circulation components
  const currentUserInDistribution = order.distributionList?.find(
    (user) => user.knownUser?.systemUserId === auth.trade.user?.SystemUserId
  );
  const actor = currentUserInDistribution?.knownUser;
  const inviteeEmail = values.invitee?.toString();

  function distributionInviteeSelector(user?: TradeAPI["DistributionUser"]) {
    return user?.email === inviteeEmail;
  }

  function isInviteeInOrder(order: TradeAPI["Order"]) {
    return !!order.distributionList?.find(distributionInviteeSelector);
  }

  function newNegotiationSelector(negotiation: TradeAPI["Negotiation"], newNegotiationId: string) {
    return negotiation.id === newNegotiationId;
  }

  async function updateOrderOnInviteeUpdate() {
    const res = await tradeAPI.getOrder(order.id);

    if (res.data && isInviteeInOrder(res.data)) {
      order.update(res.data);

      return res;
    }
  }

  if (negotiationDoesNotExist) {
    negotiation._.status.loading = true;
    negotiation._.status.message = "Updating the Order Distribution";

    order.distributionList = order.distributionList || [];

    const distributionList = [...order.distributionList, new DistributionUser({ email: inviteeEmail, role: "owner", actor })];

    const distributionPayload = {
      distributionList,
      updateToken: order?.updateToken,
    };

    const distributionRes = await tradeAPI.postOrderDistribution(order.id, distributionPayload);

    if (!distributionRes?.ok) {
      negotiation._.status.type = "error";
      negotiation._.status.title = "Main Terms Distribution List Update Failure";
      negotiation._.status.dump = { res: distributionRes };

      dialog.show({
        status: negotiation._.status,
        dataTest: `mainterms-distribution-fail`,
      });

      negotiation._.status.loading = false;
      negotiation._.status.message = null;

      return;
    }

    negotiation._.status.message = "Waiting for the Order to be Updated";

    poll(updateOrderOnInviteeUpdate, 500);

    const orderUpdate = when(isInviteeInOrder.bind(null, order));
    const orderUpdateTimeout = new Promise((resolve) => setTimeout(resolve, TimeoutMs, "timedout"));

    const orderRaceRes = await Promise.race([orderUpdate, orderUpdateTimeout]);

    if (orderRaceRes === "timedout") {
      negotiation._.status.type = "error";
      negotiation._.status.title = "Main Terms Order Update Timed Out After Distribution";

      dialog.show({
        status: negotiation._.status,
        dataTest: `mainterms-order-update-timedout-after-distribution-fail`,
      });

      negotiation._.status.loading = false;
      negotiation._.status.message = null;

      return;
    }

    const orderDistributionSendPayload = {
      ...resolveNegotiationCreationDetails(values),
      isDirectPtMt: true,
      distributionList: order.distributionList,
      updateToken: order?.updateToken,
      types: [negotiation.type || "Voy"],
      vessels: values.vessels,
      owningCompany: values.ownerAccount,
      groupChats: order.groupChats,
      teamMembers: order.teamMembers,
    };

    negotiation._.status.message = "Distributing the Order";

    const distributionSendRes = await tradeAPI.postOrderDistributionSend(order.id, orderDistributionSendPayload);

    if (!distributionSendRes?.ok) {
      negotiation._.status.type = "error";
      negotiation._.status.title = "Main Terms Distribution Failure";
      negotiation._.status.dump = { res: distributionRes };

      dialog.show({
        status: negotiation._.status,
        dataTest: `mainterms-distribution-send-fail`,
      });

      negotiation._.status.loading = false;
      negotiation._.status.message = null;

      return;
    }

    negotiation._.status.message = "Waiting for the Order to be Updated";

    const distributionInvitee = order.distributionList.find(distributionInviteeSelector);
    const newNegotiationId = distributionInvitee?.negDetails[`${potentiallyNewNegotiation.type.toLowerCase()}NegId`];

    /* eslint-disable-next-line no-inner-declarations */
    async function updateOrderWithCreatedNegotiation() {
      const res = await tradeAPI.getNegotiation(order.id, newNegotiationId);

      if (res.ok && res.data) {
        order.upsertNegotiation(res.data);

        return res;
      }
    }

    poll(updateOrderWithCreatedNegotiation, 500);

    const newNegotiationUpdate = poll(
      () => order._.orderNegotiationStoreOrderNegotiationArray?.find((x) => newNegotiationSelector(x, newNegotiationId))?._.model,
      100
    );
    const newNegotiationUpdateTimeout = new Promise((resolve) => setTimeout(resolve, TimeoutMs, "timedout"));
    const newNegotiationRaceRes = await Promise.race([newNegotiationUpdate, newNegotiationUpdateTimeout]);
    const newNegotiation = await newNegotiationUpdate;

    if (newNegotiationRaceRes === "timedout") {
      negotiation._.status.type = "error";
      negotiation._.status.title = "Main Terms New Negotiation Update Timed Out";

      dialog.show({
        status: negotiation._.status,
        dataTest: `mainterms-new-negotiation-timedout-fail`,
      });

      negotiation._.status.loading = false;
      negotiation._.status.message = null;

      return;
    }

    potentiallyNewNegotiation = newNegotiation;
  }

  const newArcTermsetId = uuid();

  const mainTermTemplates = values?.termset?.content?.mainTermTemplates || [];
  const subjectMainTermTemplates = values?.subjectTermset?.content?.mainTermTemplates || [];

  const orderTypeId = values?.termset?.content?.orderDetails?.orderTypeId;

  const termsetImportPayload = {
    mainTermTemplates: [...mainTermTemplates, ...subjectMainTermTemplates],
    proformaLayoutId: values?.termset?.content?.proformaLayoutId,
    previouslyExecutedCpId: values?.termset?.content?.previouslyExecutedCpId,
    proformaLayoutName: values?.termset?.content?.proformaLayoutName,
    orderDetails: {
      ...values?.termset?.content?.orderDetails,
      orderTypeId: orderTypeId === 3 ? 1 : orderTypeId, // COA (3) is not supported by /termsetimport
    },
    templateDetails: {
      ...values?.termset?.content?.templateDetails,
      displayName: newArcTermsetId, // apparently the displayName is the termsetId!
    },
  };

  negotiation._.status.loading = true;
  negotiation._.status.message = "Uploading Termset";

  const importRes = await tradeAPI.termsetImport(termsetImportPayload);

  if (!importRes?.ok) {
    negotiation._.status.type = "error";
    negotiation._.status.title = "Main Terms Termset Import Failure";
    negotiation._.status.dump = { res: importRes };

    dialog.show({
      status: negotiation._.status,
      dataTest: `mainterms-termset-import-fail`,
    });

    negotiation._.status.loading = false;
    negotiation._.status.message = null;

    return;
  }

  values.type = potentiallyNewNegotiation?.type;

  const payload = {
    details: getMainTermsDetails(values),
    termsetId: newArcTermsetId,
    type: potentiallyNewNegotiation?.type,
    orderId: potentiallyNewNegotiation?.orderId,
    negotiationId: potentiallyNewNegotiation?.id,
    updateToken: potentiallyNewNegotiation?.updateToken,
    offerRepToken: potentiallyNewNegotiation?.offerRepToken,
    jumpToStatus: dialogValues.jumpTo,

    responseDateTime: getTimelimit(dialogValues),
    isOfferRepClaimed: potentiallyNewNegotiation?.isOfferRepClaimed,
    orderGroupChatId: getOrderGroupChatId(order, potentiallyNewNegotiation),
    brokerEmailAddresses: potentiallyNewNegotiation.brokerEmailAddresses,
    chartererEmailAddresses: potentiallyNewNegotiation.chartererEmailAddresses,
    ownerEmailAddresses: [inviteeEmail],
    inviteeSystemUserId: potentiallyNewNegotiation.inviteeSystemUserId,
    negGroupChatId: potentiallyNewNegotiation.groupChatId,
    chartererEmail: order.chartererEmail,
    ...(dialogValues.jumpTo === "Fixed" && {
      cpDate: dialogValues.cpDate,
      userTimeZone: dialogValues.userTimeZone,
    }),
    auditNotes: dialogValues.auditNotes,
  };

  negotiation._.status.loading = true;
  negotiation._.status.message = PROCEEDING_TO_MESSAGE_MAP[`${dialogValues.jumpTo}`];

  const res = await potentiallyNewNegotiation.postNegotiationMainTerms(payload);

  if (!res?.ok) {
    negotiation._.status.type = "error";
    negotiation._.status.title = "Main Terms Proceed Failure";
    negotiation._.status.dump = { res, entity: negotiation };

    dialog.show({
      status: negotiation._.status,
      dataTest: `mainterms-post-negotiation-mainterms-fail`,
    });

    negotiation._.status.loading = false;
    negotiation._.status.message = null;

    return;
  }

  negotiation._.status.loading = false;
  negotiation._.status.message = null;

  if (negotiation.type === "Coa") {
    router.get("coa.order").push({ orderId: order.id }, { ignoreUnsavedChanges: true });
  } else {
    router.get("orders.order").push({ orderId: order.id }, { ignoreUnsavedChanges: true });
  }
}

function getOrderGroupChatId(order: TradeAPI["Order"], negotiation: TradeAPI["Negotiation"]) {
  const isUserCharterer = auth.trade.user?._.companyRoleMap.charterer;
  const isBrokerManaged = negotiation.hasBroker && isUserCharterer;
  const groupChats = order.groupChats;
  const user = auth.trade?.user;
  let groupChat;

  if (user?._.companyRoleMap.broker) {
    groupChat = groupChats.find((x) => x.brokerCompany.id === user?.CompanyId);
  }

  if (isUserCharterer) {
    groupChat = groupChats.find((x) => x.chartererCompany.id === user?.CompanyId);
  }

  if (isBrokerManaged) {
    const brokerEmailAddress = negotiation.brokerEmailAddresses[0];
    const distributionList = order.distributionList;
    const distributionBroker = distributionList.find((x) => x.knownUser.email === brokerEmailAddress);
    const distributionBrokerCompanyId = distributionBroker?.knownUser.company.id;

    if (isUserCharterer) {
      groupChat = groupChats.find(
        (x) => x.chartererCompany.id === user?.CompanyId && x.brokerCompany.id === distributionBrokerCompanyId
      );
    }
  }

  return groupChat && groupChat.id;
}

const PROCEEDING_TO_MESSAGE_MAP = {
  MainTerms: "Proceeding to Main Terms",
  OnSubs: "Proceeding to On Subs",
  Fixed: "Proceeding to Fixed",
} as StringRecord;
