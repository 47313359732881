import React from "react";
import { BallastBonus, BallastBonusProps } from "___REFACTOR___/models";
import { UnitValueEditor } from "../UnitValue";

function BallastBonusEditor(props: BallastBonusEditor.Props) {
  return (
    <UnitValueEditor
      {...props}
      onChange={onChange}
      units={BallastBonusProps.prototype.unitArr}
      defaultUnit={BallastBonusProps.prototype.JSONDefaults?.unit}
      editorType="BallastBonus"
    />
  );

  function onChange(value) {
    const next = new BallastBonus(value);

    props.onChange?.(next);
  }
}

export { BallastBonusEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace BallastBonusEditor {
  export interface Props extends Omit<UnitValueEditor.Props, "value" | "onChange"> {
    value: Value;
    onChange: (value: Value) => void;
  }

  export type Value = BallastBonus | undefined;
}
