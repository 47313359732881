import dayjs, { Dayjs } from "dayjs";
import { DateOffsetField } from "___REFACTOR___/components";
import { DataModel, DataModelProps } from "../DataModel";

export class DateOffset extends DataModel {
  constructor(data?: PartialData) {
    super(data);
  }

  toString() {
    const { second, minute, hour, month, year } = this;

    const now = dayjs();

    let res = dayjs(now);

    if (typeof second === "number") res = res.add(second, "second");
    if (typeof minute === "number") res = res.add(minute, "minute");
    if (typeof hour === "number") res = res.add(hour, "hour");
    if (typeof month === "number") res = res.add(month, "month");
    if (typeof year === "number") res = res.add(year, "year");

    if (res.valueOf() === now.valueOf()) return "";

    return res.toISOString();
  }

  toJSON() {
    return this;
  }
}

class DateOffsetProps extends DataModelProps {}

DateOffset.prototype.Props = DateOffsetProps;
DateOffsetProps.prototype.Editor = DateOffsetField;

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

export interface DateOffset extends Data {
  Props: typeof DateOffsetProps;
  _: DateOffsetProps;
}

type PartialData = Partial<Data>;
type Data = {
  second?: number;
  minute?: number;
  hour?: number;
  month?: number;
  year?: number;
  date?: Dayjs;
};
