import React, { useContext } from "react";
import { Panel, PanelType } from "office-ui-fabric-react";
import { PanelCloseButton } from "sharedFolder/components/common/CloseButton/CloseButton";
import { Attachments } from "__legacy/sharedFolder/components/common/Attachments/Attachments";
import {
  deleteNegAttachment,
  downloadOrderAttachment,
  downloadNegAttachment,
  uploadNegAttachment,
} from "__legacy/negotiations/services/attachments";
import { UserContext } from "__legacy/dashboard/contexts/UserProvider";
import { MinimalConfigurationContext } from "__legacy/sharedFolder/MinimalConfigurationContext";
import Button from "__legacy/sharedFolder/components/common/Button/Button";
import { IAttachment } from "__legacy/sharedFolder/Models/IAttachment";
import "./NegotiationAttachments.scss";

const NegotiationAttachmentsPanel = (props: {
  title?: string;
  negotiationId: string;
  orderId: string;
  attachments: IAttachment[];
  updateToken: string;
  attachmentPanelExpanded: boolean;
  setAttachmentPanelExpanded: (status: boolean) => void;
}) => {
  const { ctradeUrl } = useContext(MinimalConfigurationContext);
  const { attachmentPanelExpanded, orderId, negotiationId, attachments, updateToken, title } = props;
  return (
    <>
      {attachmentPanelExpanded && (
        <Panel
          isOpen={true}
          type={PanelType.medium}
          // the onOuterClick prop is used here as a workaround to a bug where closing the delete dialog would call onDismiss on the panel
          // more info here https://github.com/OfficeDev/office-ui-fabric-react/issues/6476
          onOuterClick={() => {
            return;
          }}
          onRenderFooter={() => (
            <div className="attachment-panel-footer">
              <Button type="flat" onClick={() => props.setAttachmentPanelExpanded(false)}>
                Close
              </Button>
            </div>
          )}
          onDismiss={() => {
            props.setAttachmentPanelExpanded(false);
          }}
          onRenderNavigation={() => <PanelCloseButton onClick={() => props.setAttachmentPanelExpanded(false)} />}
        >
          <h2>Attachments</h2>
          <h5>{title}</h5>
          <UserContext.Consumer>
            {(user) => (
              <Attachments
                areOrderLevelAttachments={false}
                attachments={attachments}
                userRoles={user.companyRoles}
                onDelete={(fileId: string) => deleteNegAttachment(orderId, negotiationId)(ctradeUrl, fileId, updateToken)}
                onDownload={(fileId: string, fileName: string, isOrderAttachment: boolean) =>
                  isOrderAttachment
                    ? downloadOrderAttachment(orderId)(fileId, fileName, updateToken)
                    : downloadNegAttachment(orderId, negotiationId)(fileId, fileName, updateToken)
                }
                onUpload={(formData: FormData) => uploadNegAttachment(orderId, negotiationId)(ctradeUrl, formData, updateToken)}
              />
            )}
          </UserContext.Consumer>
        </Panel>
      )}
    </>
  );
};

export default NegotiationAttachmentsPanel;
