// @ts-ignore
import Split from "react-split";
import React, { createContext, useEffect, useState, useRef, useMemo } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useForceUpdate } from "@/utils";
import { userStorage } from "@/services";
import { Route, OrderNegotiationStore } from "@/models";
import { AggridProps, Button, FormProps, ButtonProps } from "@/components";
import { Grid } from "./Grid";
import "./Orders.scss";

function Orders(props: Props) {
  props = { ...props };

  const { area } = props.context;
  const userSettings = getUserSettings(area);
  const [rightPanelActive, setRightPanelActive] = useState<boolean>(false);
  const [rightPanelCollapsed, setRightPanelCollapsed] = useState<boolean>(userSettings.rightPanelCollapsed);
  const [size0, setSize0] = useState<number>(userSettings.size0);
  const [size1, setSize1] = useState<number>(userSettings.size1);
  const sizes = useMemo(getSizes, [size0, size1]);
  const container = useRef<any>();
  const forceUpdate = useForceUpdate();

  useEffect(onMount, []);
  useEffect(persistUserSettings, [area, rightPanelCollapsed, size0, size1]);

  const className = classNames(area === "owner-dashboard" ? "orders owner-dashboard" : "orders", {
    rightPanelNotActive: !rightPanelActive,
    rightPanelActive,
    rightPanelCollapsed,
  });
  const widthControlStyle =
    rightPanelCollapsed && container.current ? { width: (container.current.parent.clientWidth * size1) / 100 - 2.5 } : {};

  props.context.onOrderComponentMount = onOrderComponentMount;
  props.context.onOrderComponentUnmount = onOrderComponentUnmount;

  return (
    <Context.Provider value={props.context}>
      <Split className={className} sizes={sizes} gutterSize={5} onDragEnd={onDragEnd} ref={container}>
        <Grid key={area} />
        <div className="order">
          <div className="order-width-control" style={widthControlStyle}>
            <Button className="pin" variant="flat" icon="unpinned" onClick={toggleCollapse} />
            <div className="order-overflow-control">
              <div className="order-content">{props.children}</div>
            </div>
            <Button className="pinned" variant="flat" onClick={toggleCollapse} />
          </div>
        </div>
      </Split>
    </Context.Provider>
  );

  function onMount() {
    forceUpdate();
  }

  function onOrderComponentMount(orderId) {
    setRightPanelActive(true);

    if (orderId.includes("$")) {
      setRightPanelCollapsed(false);
    }
  }

  function onOrderComponentUnmount(orderId) {
    setRightPanelActive(false);
  }

  function persistUserSettings() {
    const userSettings = userStorage.get("BASE_GRID");

    userStorage.set("BASE_GRID", { ...userSettings, [area]: { rightPanelCollapsed, size0, size1 } });
  }

  function onDragEnd(sizes) {
    setSize0(sizes[0]);
    setSize1(sizes[1]);
  }

  function toggleCollapse() {
    setRightPanelCollapsed(!rightPanelCollapsed);
  }

  function getSizes() {
    return [size0, size1];
  }
}

function getUserSettings(area: string) {
  const userAreaSettings = userStorage.get("BASE_GRID");
  const userSettings = userAreaSettings?.[area] || defaultUserSettings;

  return userSettings;
}

const defaultUserSettings = {
  size0: 60,
  size1: 40,
  rightPanelCollapsed: false,
};

const Observer = observer(Orders);

export { Observer as Orders };

// @ts-ignore
export const Context = createContext<Context>(null);

interface Props {
  context: Context;
  children?: React.ReactNode;
}

export interface Context {
  area: string;
  onOrderComponentMount?(orderId): void;
  onOrderComponentUnmount?(orderId): void;
  legacyOrderType?: "COA" | "ITO";
  orderNegotiationStore?: OrderNegotiationStore;
  activeItemsStoreRef?: OrderNegotiationStore;
  routes: {
    orders?: Route;
    archivedOrders?: Route;
    order: {
      index?: Route;
      new?: Route;
      negotiations?: Route;
      capture?: Route;
      inactive?: Route;
      inactiveNegotiations?: Route;
      initialization?: Route;
      withdrawn?: Route;
      nomination?: Route;
      active?: Route;
      failed?: Route;
      firm?: Route;
      termsLoading?: Route;
      mainTerms?: Route;
      onSubs?: Route;
      subsLifted?: Route;
      subsFailed?: Route;
      fixed?: Route;
      archived?: Route;
      negotiation?: Route;
      negotiationInitialTerms?: Route;
      negotiationMainTerms?: Route;
      lifting?: Route;
    };
  };

  view: {
    grid: {
      type?: "OwnerNegotiationGrid" | "OrderGrid";
      name: string;
      props: AggridProps;
      btn?: {
        capture?: ButtonProps;
        add?: ButtonProps;
        dealCapture?: ButtonProps;
        close?: ButtonProps;
        kebab?: ButtonProps;
      };
    };
    order: {
      orderSearchQuery?: string;
      orderCreationQuery?: string;
      suppressOverlay?: boolean;
      apiErrorDef?: Record<string, true>;
      capture?: {
        title: string;
        buttons: {
          submit: string;
          cancel: string;
        };
        form: Omit<FormProps, "initialValues" | "onSubmit">;
      };
      creation?: {
        title: string;
        buttons: {
          submit: string;
          cancel: string;
        };
        form: Omit<FormProps, "initialValues" | "onSubmit">;
      };
      name: string;
      negotiations: {
        grid: AggridProps;
      };
    };
  };

  dataTest: string;
  name?: string;
}
