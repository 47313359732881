import { IOfferRepApi } from "../OwnerIndicationForm/Contexts/IOfferRepFormContext";
import { offerRepOfferDetail, offerRepSendOfferRepNote } from "../api/offerRepUpdate";

export const OwnerDashboardService = (
  apiUrl: string,
  orderId: string,
  negotiationId: string,
  updateToken: string
): IOfferRepApi => {
  const baseUrl = `${apiUrl}/orders/${orderId}/negotiations/${negotiationId}`;
  return {
    sendOffer: (data: any) =>
      offerRepOfferDetail(baseUrl, {
        ...data,
        updateToken,
      }),
    firmBidAccept: () =>
      offerRepOfferDetail(baseUrl, {
        action: "firmAccepted",
        updateToken: updateToken,
      }),
    sendOfferRepNote: (note: string) =>
      offerRepSendOfferRepNote(baseUrl, {
        value: note,
        as: "owner",
        updateToken: updateToken,
      }),
  };
};
