import { Vessel, Negotiation, dialog } from "@/models";
import { Context } from "../Orders";
import { postNegotiationVessel } from "./postNegotiationVessel";

export async function postNegotiationVesselAndAccept(model: Negotiation | undefined, context: Context, data: Vessel) {
  if (!model) {
    console.error("postNegotiationVesselAndAccept:", { model, context, data });

    const status = {} as Status;

    status.type = "error";
    status.title = "Vessel Acceptance Failure";

    dialog.show({
      status,
      dataTest: `post-negotiation-vessel-accept-fail`,
    });

    return;
  }

  const postVesselRes = await postNegotiationVessel(model, context, data);

  let res;

  data.updateToken = model.updateToken;

  if (postVesselRes?.ok) res = await model.postAcceptVessel(data);

  if (!postVesselRes?.ok || !res?.ok) {
    model._.status.type = "error";
    model._.status.title = "Vessel Acceptance Failure";
    model._.status.dump = { res, entity: model };

    dialog.show({
      status: model._.status,
      dataTest: `post-negotiation-vessel-accept-fail`,
    });

    model._.status.loading = false;
    model._.status.message = null;

    return res;
  }

  model._.status.loading = false;
  model._.status.message = null;

  dialog.show({
    status: {
      type: "success",
      title: "Successful Vessel Acceptance",
      message: "You have successfuly accepted a vessel",
    },
    dataTest: `post-negotiation-vessel-accept-success`,
  });

  return res;
}
