import { UniversalOrderNegotiationFormValues, getBidOfferDetails, Negotiation, dialog } from "@/models";

export async function sendNegotiationOfferViaOfferrep(
  model: Negotiation | undefined,
  values: UniversalOrderNegotiationFormValues
) {
  if (!model) {
    console.error("sendNegotiationOfferViaOfferrep:", { model, values });

    const status = {} as Status;

    status.type = "error";
    status.title = "Negotiation Offer Failure";

    dialog.show({
      status,
      dataTest: `offerrep-offer-fail`,
    });

    return;
  }

  model._.status.loading = true;
  model._.status.message = `Updating Offer`;

  const indicationType = values.indicationType || "indicated";

  const payload = {
    details: indicationType === "indicated" || indicationType === "firmed" ? getBidOfferDetails(values) : null,
    action: indicationType,
    expiresOn:
      indicationType === "firmed"
        ? values.timelimitVariant === "DateOffset"
          ? values.timelimitDateOffset?.toString()
          : values.timelimitDate?.toString()
        : null,
    account: values.account,
    vessels: values.vessels,
    updateToken: model.updateToken,
  };

  const res = await model.putOfferViaOfferrep(payload);

  if (!res?.ok) {
    model._.status.type = "error";
    model._.status.title = "Negotiation Offer Failure";
    model._.status.dump = { res, entity: model };

    dialog.show({
      status: model._.status,
      dataTest: `offerrep-offer-fail`,
    });

    model._.status.loading = false;
    model._.status.message = null;

    return res;
  }

  model._.status.loading = false;
  model._.status.message = null;

  // dialog.show({
  //   status: {
  //     type: "success",
  //     title: "Successful Negotiation Offer",
  //     message: successMessageByType[indicationType],
  //   },
  //   dataTest: `offerrep-offer-success`,
  // });

  return res;
}

// const successMessageByType = {
//   indicated: "You have successfuly updated the negotiation offer",
//   firmRequested: "You have successfuly requested for a firm offer from the charterer",
//   firmed: "You have successfuly sent the charterer a firm offer",
// } as StringRecord;
