import React, { useEffect, useState } from "react";
import posed from "react-pose";
import { Observable } from "rxjs";
import classNames from "classnames";
import { AttachmentErrorCodesType } from "__legacy/sharedFolder/components/common/types";
import { IAttachmentErrors, IFileWithError } from "__legacy/sharedFolder/components/common/interfaces";
import { Icon } from "@/components";
import "./AttachmentUploadDrop.scss";
import { UploadAttachmentResult } from "__legacy/negotiations/services/attachments";

interface IProps {
  onUpload: (formData: FormData) => Observable<UploadAttachmentResult>;
  onChangeValidationErrors: (attachmentErrors: IAttachmentErrors | null) => void;
  numberOfAttachments: number;
}

interface IState {
  expandPanel: boolean;
}

const initialState: IState = {
  expandPanel: false,
};

function getFileExtension(fileName: string) {
  const fileNameRegexPattern = /^.+\..+$/;
  const extensionIndicator = /^.+\.(.+)$/;
  const extensionRegex = fileName.match(extensionIndicator);
  if (!fileNameRegexPattern.test(fileName)) {
    return "";
  } else {
    return extensionRegex ? extensionRegex[1] : "";
  }
}

function validateAttachments(files: FileList): { isValid: boolean; validationErrors: IAttachmentErrors } {
  const tooManyFilesUploaded = files.length > 10;

  const isExtensionValid = (ext: string) => {
    const validFileExtensions = [
      "cad",
      "csv",
      "doc",
      "docx",
      "gif",
      "jpeg",
      "jpg",
      "msg",
      "pdf",
      "png",
      "pps",
      "ppt",
      "pptm",
      "pptx",
      "rar",
      "tif",
      "tiff",
      "txt",
      "vsd",
      "vsdx",
      "xls",
      "xlsx",
      "zip",
      "mp4",
    ];

    return !!validFileExtensions.find((d) => d === ext);
  };

  const filesWithErrors = [] as any;

  const bytesToMegaBytes = (bytes: number): number => bytes / (1024 * 1024);
  for (let i = 0; i < files.length; i++) {
    if (bytesToMegaBytes(files[i].size) > 50) {
      filesWithErrors.push({
        fileName: files[i].name,
        error: "fileTooBig" as AttachmentErrorCodesType,
      });
    }

    if (!isExtensionValid(getFileExtension(files[i].name))) {
      filesWithErrors.push({
        fileName: files[i].name,
        error: "wrongExtension" as AttachmentErrorCodesType,
      });
    }
  }

  return {
    isValid: !tooManyFilesUploaded && filesWithErrors.length === 0,
    validationErrors: {
      tooManyFilesUploaded,
      filesWithErrors,
      partialFailure: false,
    },
  };
}

const bezierCurveEase = [0.72, 0.45, 0.9, 0.12];

const AnimatingOverlay = posed.div({
  start: { width: 0 },
  started: {
    width: "95%",
    transition: { duration: 3000, ease: bezierCurveEase },
  },
  finished: {
    width: "100%",
    transition: { duration: 200, ease: bezierCurveEase },
  },
});

export const AttachmentUploadDrop = (props: IProps) => {
  const [attachmentView, setAttachmentView] = useState<"progress" | "dropZone">("dropZone");
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [dragging, setDragging] = useState<boolean>(false);
  const [dragCounter, setDragCounter] = useState<number>(0);
  const [state, setState] = useState<IState>(initialState);
  const [animatingState, setAnimatingState] = useState<null | "started" | "finished">(null);

  useEffect(() => {
    if (animatingState === "finished") {
      setTimeout(() => {
        setAttachmentView("dropZone");
        setAnimatingState(null);
        setSelectedFiles(null);
      }, 1000);
    }
  }, [animatingState]);

  const onFileSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length) {
      upload(e.target.files);
    } else {
      console.error("file not uploaded");
    }
  };

  const upload = (files: FileList) => {
    setSelectedFiles(files);
    setAnimatingState("started");
    setAttachmentView("progress");
    props.onChangeValidationErrors(null);
    if (!files || !files.length) {
      console.error("no file was selected");
      return false;
    }
    // first we should show file names that failed with the upload and then work our way to everything else
    // run the validation, the validation should return an array of file names along with errors
    // this function then somehow shows the errors

    const { isValid, validationErrors } = validateAttachments(files);

    if (!isValid) {
      setAnimatingState("finished");
      setState({
        ...state,
      });
      props.onChangeValidationErrors(validationErrors);
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append(files[i].name, files[i]);
    }

    props.onUpload(formData).subscribe((response: UploadAttachmentResult) => {
      if (response.success) {
        setAnimatingState("finished");
      } else {
        const files = [...formData.entries()].map((file) => file[0]);
        const filesWithErrors = getFilesWithErrors(files, response);
        props.onChangeValidationErrors({
          filesWithErrors: filesWithErrors,
          partialFailure: filesWithErrors.length < files.length,
        });
        setAnimatingState("finished");
      }
    });
  };

  const getFilesWithErrors = (files: string[], uploadResult: UploadAttachmentResult): IFileWithError[] => {
    if (uploadResult.successPerFile !== null && uploadResult.successPerFile.length === files.length) {
      const filesWithErrors: IFileWithError[] = [];
      uploadResult.successPerFile.forEach((success, index) => {
        if (!success) {
          filesWithErrors.push({ fileName: files[index], error: "apiError" });
        }
      });
      return filesWithErrors;
    } else {
      return files.map((f) => ({ fileName: f, error: "apiError" }));
    }
  };

  const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragCounter(dragCounter + 1);
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const newDragCounterValue = dragCounter - 1;
    setDragCounter(newDragCounterValue);
    if (newDragCounterValue === 0) {
      setDragging(false);
    }
  };

  const onFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      upload(e.dataTransfer.files);
      if (e.dataTransfer.clearData) e.dataTransfer.clearData();
      setDragCounter(0);
    }
  };

  const renderInProgressFiles = (): File[] => {
    const files = [] as any;
    // doing this because File[] is not an array
    if (selectedFiles && selectedFiles.length) {
      for (let i = 0; i < selectedFiles.length; i++) {
        files.push(selectedFiles[i]);
      }
    }
    return files;
  };

  if (attachmentView === "progress") {
    const inProgressFiles = renderInProgressFiles();
    return (
      <div className="attachmentPanelContent">
        <div className={classNames("flexColumn", "flexCenter", "selectedFileContainer")}>
          {animatingState && <AnimatingOverlay className="animatingOverlay" pose={animatingState} initialPose={"start"} />}
          {inProgressFiles.map((file) => (
            <p key={file.name + file.lastModified} data-test={`selectedFile-${file.name}`}>
              {file.name}
            </p>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="attachmentPanelContent">
      <div
        data-test={"dropzone"}
        className={classNames("attachmentContainer", "flexCenter", dragging && "draggingEffect")}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDragOver={onDragOver}
        onDrop={onFileDrop}
      >
        <div className="attachmentContent">
          <div className="flexRow">
            <span>
              <Icon icon="cloud-upload" />
            </span>
            <p>Drop your files here</p>
          </div>
          <div className="flexRow">
            <p>or</p>
            <div className="uploadBtnWrapper">
              <div className="browseButton" data-test="browse-for-attachments">
                Browse
              </div>
              <input className="uploadInput" type="file" name="file" multiple onChange={onFileSelection} autoFocus={true} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
