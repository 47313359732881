import { formatDate, timeleft, timepassed } from "@/utils";
import { PrimitiveDataModel, PrimitiveDataModelProps } from "../PrimitiveDataModel";

export class Timestamp extends PrimitiveDataModel<Data> {
  onUpdate() {
    this.date = new Date(this.data || 0);
  }

  toString() {
    if (!this.date) return "";

    return formatDate(this.date, this.format);
  }

  format = "HH:mm YYYY-MM-DD";
}

class TimestampProps extends PrimitiveDataModelProps<Data> {}

TimestampProps.prototype.sortKey = "date";

Timestamp.prototype._ = new TimestampProps();

export class PeriodStartEnd extends Timestamp {
  format = "DD MMM YY";
}

export class CreatedOn extends Timestamp {
  format = "DD MMM HH:mm";
}

export class Timepassed extends Timestamp {
  toString() {
    return timepassed(this.date);
  }
}

export class Timeleft extends Timestamp {
  toString() {
    return timeleft(this.date);
  }
}

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

export interface Timestamp {
  date: Date;
}

type Data = string;
