import React, { ReactNode, useEffect, useRef } from "react";
import classNames from "classnames";
import { emptyFn } from "@/utils";
import { InputProps } from "../../Input";
import { Container } from "../../Container";
import "./Radio.scss";

export function RadioInput(props: RadioInputProps) {
  props = { ...props };

  props.className = classNames(props.className, "Radio");

  const { checkedValue = true, reactNode, radioLabel } = props;

  const checked = checkedValue === props.value;
  const input = useRef<HTMLInputElement | null>(null);

  useEffect(onMount, []);

  function onMount() {
    if (props.input) props.input.current = input.current;

    return onUnmount;
  }

  function onUnmount() {
    if (props.input) props.input.current = null;
  }

  function onChange() {
    props.onChange?.(checkedValue);
  }

  function onClick() {
    onChange();
  }

  const label = reactNode || radioLabel;
  const left = <label>{label}</label>;

  return (
    <Container {...props} onClick={onClick}>
      <input
        checked={checked}
        onChange={emptyFn}
        placeholder={props.placeholder}
        disabled={props.disabled}
        data-test={`${props.dataTest}.input`}
        ref={input}
        type="radio"
      />
      {left}
    </Container>
  );
}

export interface RadioInputProps extends InputProps<Value> {
  checkedValue?: any;
  radioLabel?: string;
  reactNode?: ReactNode;
}

type Value = boolean | null;
