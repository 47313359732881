import { TradeAPI } from "@/apis";

export class Actions {
  constructor(data?: PartialData) {
    if (!data) return;

    Object.assign(this, data);

    const { owner, brokerCharterer, brokerChartererFirmExpiresOn, ownerFirmExpiresOn, lastUpdatedBy } = data;

    const lastUpdatedByOwner = lastUpdatedBy === "owner";
    const ownerIndicated = lastUpdatedByOwner && owner === "indicated";
    const ownerFirmed = lastUpdatedByOwner && owner === "firmed";
    const firmBidAccepted = owner === "firmAccepted";
    const ownerFirmedExpired = new Date() > new Date(ownerFirmExpiresOn || 0);
    const ownerNonExpiredFirmed = !ownerFirmedExpired && ownerFirmed;

    const lastUpdatedByBrokerCharterer = lastUpdatedBy === "brokerCharterer";
    const brokerChartererIndicated = lastUpdatedByBrokerCharterer && brokerCharterer === "indicated";
    const brokerChartererFirmRequested = lastUpdatedByBrokerCharterer && brokerCharterer === "firmRequested";
    const brokerChartererFirmed = lastUpdatedByBrokerCharterer && brokerCharterer === "firmed";
    const firmOfferAccepted = brokerCharterer === "firmAccepted";
    const brokerChartererFirmedExpired = new Date() > new Date(brokerChartererFirmExpiresOn || 0);

    this.lastUpdatedByOwner = lastUpdatedByOwner;
    this.ownerIndicated = ownerIndicated;
    this.ownerFirmed = ownerFirmed;
    this.ownerFirmedExpired = ownerFirmedExpired;
    this.ownerNonExpiredFirmed = ownerNonExpiredFirmed;
    this.firmBidAccepted = firmBidAccepted;
    this.lastUpdatedByBrokerCharterer = lastUpdatedByBrokerCharterer;
    this.brokerChartererIndicated = brokerChartererIndicated;
    this.brokerChartererFirmRequested = brokerChartererFirmRequested;
    this.brokerChartererFirmed = brokerChartererFirmed;
    this.brokerChartererFirmedExpired = brokerChartererFirmedExpired;
    this.firmOfferAccepted = firmOfferAccepted;
    this.hasExpiredAction = (ownerFirmed && ownerFirmedExpired) || (brokerChartererFirmed && brokerChartererFirmedExpired);
  }
}

export interface Actions extends Data {
  lastUpdatedByOwner: boolean;
  ownerIndicated: boolean;
  ownerFirmed: boolean;
  ownerFirmedExpired: boolean;
  ownerNonExpiredFirmed: boolean;
  firmBidAccepted: boolean;
  lastUpdatedByBrokerCharterer: boolean;
  brokerChartererIndicated: boolean;
  brokerChartererFirmRequested: boolean;
  brokerChartererFirmed: boolean;
  brokerChartererFirmedExpired: boolean;
  firmOfferAccepted: boolean;
  hasExpiredAction: boolean;
}

type PartialData = Partial<Data>;
type Data = DeepPartial<TradeAPI["Negotiation"]["actions"]>;
