import * as api from "../../../api/negotiations/models";
import { IGetView, LastFirmAction } from "sharedFolder/Models/Negotiation/IGetView";
import { INegotiationCommonView } from "sharedFolder/Models/Negotiation/INegotiationCommonView";
import { NegotiationState } from "sharedFolder/Models/Negotiation/NegotiationState";
import { IBidOfferAndSetting } from "./../mapping/view/mapBidOffer";
import {
  ICommissionView,
  ILaycanView,
  ICargoSizeView,
  ICargoTypeView,
  IUnitValueView,
  IDurationView,
  ILiftingsView,
  INominationsView,
  ICOACargoSizeView,
  IVesselSizeView,
} from "sharedFolder/Models/IDetails";
import { mapCommission } from "sharedFolder/mappers/mapCommission";
import { mapAccount } from "sharedFolder/mappers/mapAccount";
import { mapLaycan } from "sharedFolder/mappers/mapLaycan";
import { mapCargoType } from "sharedFolder/mappers/mapCargoType";
import {
  mapBallastBonus,
  mapDemurrage,
  mapFreightRate,
  mapHireRate,
  mapCleaningPrice,
  mapSupplyPrice,
} from "sharedFolder/mappers/mapUnitValue";
import { mapLocation } from "sharedFolder/mappers/mapLocation";
import { mapDuration } from "sharedFolder/mappers/mapDuration";
import { mapVesselSize } from "sharedFolder/mappers/mapVesselSize";
import { OrderNegStatus } from "sharedFolder/Models/OrderNegStatus";
import { mapAttachment } from "./../mapping/view/mapAttachment";
import { NegotiationPermission } from "sharedFolder/Models/Negotiation/NegotiationPermission";
import { IOrderNegActions } from "sharedFolder/Models/IOrderNegActions";
import { permissionsFromJwt } from "sharedFolder/apis/permissionsFromJwt";
import { isAllowed } from "sharedFolder/Utilities/isAllowed";
import { orderVessels } from "sharedFolder/Utilities/orderVessels";
import lastUpdatedAction from "sharedFolder/Utilities/lastUpdatedAction";
import { INegotiationDetailsView } from "sharedFolder/Models/Negotiation/INegotiationView";
import {
  VoyDetailType,
  TctDetailType,
  VoySpecificType,
  TctSpecificType,
  CoaSpecificType,
  NegotiableCommonDetailType,
} from "../../models/common/DetailType";
import { IBidOfferView } from "sharedFolder/Models/Negotiation/NegotiationViews";
import { mapLiftings } from "sharedFolder/mappers/mapLiftings";
import { mapCOACargoSize } from "sharedFolder/mappers/mapCOACargoSize";
import { mapNominations } from "sharedFolder/mappers/mapNominations";
import { IMapperNullable } from "sharedFolder/mappers/map";
import { mapCargoSize } from "sharedFolder/mappers/mapCargoSize";
import { mapNotesToView } from "./view/mapNotesToView";
import { ILocationView } from "sharedFolder/Models/ILocation";
import { mapVessel } from "sharedFolder/mappers/mapVessel";
import { mapOrderNegType } from "sharedFolder/mappers/mapOrderNegType";
import { NegotiationSide } from "sharedFolder/Models/Direction";
import { ISubsTextView } from "../../../api/negotiations/models";
import { mapSubsText } from "sharedFolder/mappers/mapSubsText";
import { mapBunker } from "sharedFolder/mappers/mapBunker";
import { IBunkerView } from "sharedFolder/Models/IBunker";

function lastFirmAction(actions: IOrderNegActions): LastFirmAction | null {
  const firmOptions = ["firmed", "firmAccepted", "firmRequested"];
  const ownerFirmAction = firmOptions.includes(actions.owner || "");
  const chartererFirmAction = firmOptions.includes(actions.brokerCharterer || "");
  // If both the owner and the charterer have their last actions as 'firm', use lastUpdatedBy
  if (ownerFirmAction && chartererFirmAction && actions.lastUpdatedBy) {
    if (actions.lastUpdatedBy === "brokerCharterer") {
      return "charterer";
    } else {
      return "owner";
    }
  }
  if (ownerFirmAction) return "owner";
  if (chartererFirmAction) return "charterer";
  return null;
}

function isNegDisabled(updateToken: string | undefined, negotiationStatus: OrderNegStatus) {
  if (!updateToken) return true;
  else {
    const disabledStatuses: OrderNegStatus[] = ["MainTerms", "Fixed", "Firm", "OnSubs", "SubsFailed", "Withdrawn"];

    if (disabledStatuses.includes(negotiationStatus)) return true;
    else {
      return false;
    }
  }
}

function isNegReadOnlyForUser(permittedMethods: NegotiationPermission[]) {
  return (
    // if the user is not allowed to perform these actions, we can say the user is readonly
    // this is probably not the most efficient way to check if this is a brokered neg
    // if the user can do any of the below actions, then this is NOT a readonly neg
    !isAllowed(permittedMethods, "BidIndicate") &&
    !isAllowed(permittedMethods, "OfferIndicate") &&
    !isAllowed(permittedMethods, "BidFirm") &&
    !isAllowed(permittedMethods, "OfferFirm") &&
    !isAllowed(permittedMethods, "OfferFirmRequest") &&
    !isAllowed(permittedMethods, "PtMainTerms")
  );
}

function isPermittedToProceedNegForUser(permittedMethods: NegotiationPermission[]) {
  return (
    // here we check does is permitted for user to proceed with offer
    isAllowed(permittedMethods, "BidFirmAccept")
  );
}

function mapCommon(neg: api.INegotiation): Omit<IGetView<INegotiationCommonView>, "details"> {
  const permittedMethods = neg.updateToken ? permissionsFromJwt<NegotiationPermission>(neg.updateToken) : [];
  return {
    actions: neg.actions,
    arcNegotiationHash: neg.arcNegotiationHash || "",
    attachments: neg.attachments.map(mapAttachment),
    createdOn: neg.createdOn,
    hasBroker: neg.hasBroker,
    hasCharterer: neg.hasCharterer,
    controllerNames: neg.circulatedBy ? [neg.circulatedBy] : [],
    brokerGroupChatId: neg.brokerGroupChatId,
    chartererCompanyId: neg.chartererCompanyId,
    ctradeControllerUrl: neg.arcUrl,
    ctradeOfferRepUrl: neg.offerRepArcUrl,
    firmBidExpiresOn: neg.actions.brokerChartererFirmExpiresOn || null,
    onSubsExpiresOn: neg.onSubsExpiresOn || null,
    orderId: neg.orderId,
    invitee: neg.invitee,
    version: neg.version,
    orderVersion: neg.orderVersion,
    firmOfferExpiresOn: neg.actions.ownerFirmExpiresOn || null,
    id: neg.id,
    isLifting: false, // not doing liftings
    isReadOnly: isNegReadOnlyForUser(permittedMethods),
    isPermittedToProceed: isPermittedToProceedNegForUser(permittedMethods),
    isDisabled: isNegDisabled(neg.updateToken, neg.status),
    type: mapOrderNegType.toView(neg.type),
    lastFirmAction: lastFirmAction(neg.actions),
    lastUpdate: neg.lastUpdated || "",
    lastUpdatedAction: lastUpdatedAction(neg.actions),
    lastUpdatedBy: neg.actions.lastUpdatedBy,
    liftingId: neg.liftingId,
    liftingCargoId: neg.liftingCargoId,
    offerRepToken: neg.offerRepToken, // this should come through as a full URL now
    owningCompany: mapAccount.toViewOrUndefined(neg.owningCompany),
    showCharterer: false, // TODO: neg.visibleToCharterer,
    sortFilter: {
      filterIndexes: neg.filterIndexes || {
        active: false,
        isExactLaycan: false,
      },
      sortIndexes: neg.sortIndexes || {
        invitee: "",
        createdDate: 0,
        updatedDate: 0,
        laycan: 0,
      },
    },
    state: stateFromNegStatus(neg.status, neg.actions),
    permittedMethods,
    //     state: stateFromPermissions(neg.updateToken), // to expand on this later - feed through permittedActions instead
    status: neg.status,
    updateToken: neg.updateToken || "",
    groupChat: neg.groupChat || null,
    seaContractsUrl: neg.seaContractsUrl || "",
    coaNegotiationId: neg.coaNegotiationId || null,
    operationalSubsLifted: neg.operationalSubsLifted || false,
    commercialSubsLifted: neg.commercialSubsLifted || false,
    isDealCapture: !!neg.isDealCapture,
    inviteeSystemUserId: neg.inviteeSystemUserId,
  };
}

function mapCommonDetails(neg: api.INegotiation): INegotiationCommonView {
  const mapOrEmpty = mapOrEmptyWithNegotiation(neg);
  return {
    addressCommission: mapOrEmpty<api.ICommission, ICommissionView>("addressCommission", mapCommission),
    brokerCommission: mapOrEmpty<api.ICommission, ICommissionView>("brokerCommission", mapCommission),
    cargoType: mapOrEmpty<api.ICargoType, ICargoTypeView>("cargoType", mapCargoType),
    chartererAccount: mapAccount.toViewOrEmpty(neg.chartererAccount),
    laycan: mapOrEmpty<api.ILaycan, ILaycanView>("laycan", mapLaycan),
    notes: {
      display: neg.orderNotes,
      value: neg.orderNotes,
    },
    controllerNegotiationNotes: mapNotesToView(neg.notes, "brokerCharterer"),
    offerRepNegotiationNotes: mapNotesToView(neg.notes, "owner"),
    vessels: orderVessels(neg.vessels.map(mapVessel.toView)),
  };
}

const getBidOfferAndSetting = <TApiModel>(
  detailType: VoyDetailType | TctDetailType | CoaSpecificType,
  x: api.INegotiation
): IBidOfferAndSetting<TApiModel> => {
  return {
    bid: x.bid[detailType],
    offer: x.offer[detailType],
    setting: x.detailSettings[detailType],
  } as IBidOfferAndSetting<TApiModel>;
};

const mapSide: { [key in "Bid" | "Offer"]: NegotiationSide } = {
  Bid: NegotiationSide.charterer,
  Offer: NegotiationSide.owner,
};

const mapOrEmptyWithNegotiation =
  (negotiation: api.INegotiation) =>
  <API, Model>(
    specificType: TctSpecificType | VoySpecificType | CoaSpecificType | NegotiableCommonDetailType,
    mapper: IMapperNullable<API, Model>
  ): IBidOfferView<Model> => {
    const { bid, offer, setting } = getBidOfferAndSetting<API>(specificType, negotiation);

    const side = setting ? mapSide[setting.side] : NegotiationSide.charterer;
    return {
      bid: mapper.toViewOrEmpty(bid?.value),
      offer: mapper.toViewOrEmpty(offer?.value),
      lastUpdated: side,
      negotiable: setting ? setting.negotiable : false,
      included: setting !== undefined,
    };
  };

export function mapToNegotiableView(negotiation: api.INegotiation): IGetView<INegotiationDetailsView> {
  return {
    ...mapCommon(negotiation),
    details: {
      ...mapCommonDetails(negotiation),
      ...mapNegotiationDetails(negotiation),
    },
  };
}

function mapNegotiationDetails(negotiation: api.INegotiation): INegotiationDetailsView {
  const mapOrEmpty = mapOrEmptyWithNegotiation(negotiation);

  const voyDetails = {
    cargoSize: mapOrEmpty<api.ICargoSize, ICargoSizeView>("cargoSize", mapCargoSize),
    commercialSubs: mapOrEmpty<api.ISubsText, ISubsTextView>("commercialSubs", mapSubsText),
    operationalSubs: mapOrEmpty<api.ISubsText, ISubsTextView>("operationalSubs", mapSubsText),
    freightRate: mapOrEmpty<api.IUnitValue<api.FreightRateUnit>, IUnitValueView>("freightRate", mapFreightRate),
    loadLocation: mapOrEmpty<api.ILocation, ILocationView>("loadLocation", mapLocation),
    dischargeLocation: mapOrEmpty<api.ILocation, ILocationView>("dischargeLocation", mapLocation),
    demurrage: mapOrEmpty<api.IUnitValue<api.DemurrageUnit>, IUnitValueView>("demurrage", mapDemurrage),
  } as INegotiationDetailsView;

  if (["Coa"].some((type) => type === negotiation.type)) {
    return {
      ...voyDetails,
      period: mapOrEmpty<api.ILaycan, ILaycanView>("period", mapLaycan),
      liftings: mapOrEmpty<api.ILiftings, ILiftingsView>("liftings", mapLiftings),
      nominations: mapOrEmpty<api.INominations, INominationsView>("nominations", mapNominations),
      coaCargoSize: mapOrEmpty<api.ICOACargoSize, ICOACargoSizeView>("coaCargoSize", mapCOACargoSize),
    } as INegotiationDetailsView;
  }

  if (["Voy", "Lft"].some((type) => type === negotiation.type)) {
    return voyDetails;
  }

  if (["Tct"].some((type) => type === negotiation.type)) {
    return {
      vesselSize: mapOrEmpty<api.IVesselSize, IVesselSizeView>("vesselSize", mapVesselSize),
      commercialSubs: mapOrEmpty<api.ISubsText, ISubsTextView>("commercialSubs", mapSubsText),
      operationalSubs: mapOrEmpty<api.ISubsText, ISubsTextView>("operationalSubs", mapSubsText),
      duration: mapOrEmpty<api.IDuration, IDurationView>("duration", mapDuration),
      hireRate: mapOrEmpty<api.IUnitValue<api.HireRateUnit>, IUnitValueView>("hireRate", mapHireRate),
      cleaningPrice: mapOrEmpty<api.IUnitValue<api.CleaningPriceUnit>, IUnitValueView>("cleaningPrice", mapCleaningPrice),
      supplyPrice: mapOrEmpty<api.IUnitValue<api.SupplyPriceUnit>, IUnitValueView>("supplyPrice", mapSupplyPrice),
      tradingExclusions: mapOrEmpty<api.ISubsText, ISubsTextView>("tradingExclusions", mapSubsText),
      bunkerDelivery: mapOrEmpty<api.IBunker, IBunkerView>("bunkerDelivery", mapBunker),
      bunkerRedelivery: mapOrEmpty<api.IBunker, IBunkerView>("bunkerRedelivery", mapBunker),
      cargoExclusionsText: mapOrEmpty<api.ISubsText, ISubsTextView>("cargoExclusionsText", mapSubsText),
      ballastBonus: mapOrEmpty<api.IUnitValue<api.BallastBonusUnit>, IUnitValueView>("ballastBonus", mapBallastBonus),
      deliveryLocation: mapOrEmpty<api.ILocation, ILocationView>("deliveryLocation", mapLocation),
      viaLocation: mapOrEmpty<api.ILocation, ILocationView>("viaLocation", mapLocation),
      redeliveryLocation: mapOrEmpty<api.ILocation, ILocationView>("redeliveryLocation", mapLocation),
    } as INegotiationDetailsView;
  }

  throw new Error(`No condition available for: ${negotiation.type}`);
}

/**
 * The NegotiationState drives the display of different states of a negotiation
 * i.e. show the "Proceed to Main Terms" button at the right time
 * =====================
 * This method IS A HACK - we should be formulating the state based on the permittedMethods
 * =====================
 * but in the interrim let's try to figure out the state based on the NegStatus
 * @param status Negotiation Status
 */
function stateFromNegStatus(status: OrderNegStatus, actions: IOrderNegActions): NegotiationState {
  const firmAccepted = actions.brokerCharterer === "firmAccepted" || actions.owner === "firmAccepted";
  const map: { [key in OrderNegStatus]: NegotiationState } = {
    Active: firmAccepted ? "CanGoMainTerms" : "Initial",
    Failed: "Initial",
    Firm: "CanGoMainTerms",
    Fixed: "CanOpenMainTerms", // should never be shown
    Inactive: "Initial",
    MainTerms: "CanOpenMainTerms",
    OnSubs: "OnSubs",
    TermsLoading: "Initial",
    SubsFailed: "OnSubs",
    SubsLifted: "OnSubs",
    Withdrawn: "Initial",
    Archived: "Initial",
    Nomination: "Nomination",
    Confirmed: "Confirmed",
  };
  return map[status];
}
