import React, { useEffect, createContext, useMemo, useState, useRef } from "react";
import { Moment } from "moment";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import classNames from "classnames";
import {
  Negotiation,
  UniversalOrderNegotiationFormValues,
  router,
  dialog,
  anyOrderNegotiationStore,
  seaChatWidget,
} from "@/models";
import { Button, Element, UniversalOrderNegotiationForm, FormContext, Overlay, FormProps } from "@/components";
import {
  getOrder as getOrderAction,
  getNegotiation as getNegotiationAction,
  postNegotiationMainTerms,
  postNegotiationDealCaptureMainTerms,
} from "./actions";
import { MainTermsTypeEvents, usageMetrics } from "@/services/UsageMetrics";
import { CaptureProceedTo, ProceedTo } from "./ProceedTo";
import "./MainTerms.scss";
import { MainTermsResetConfirmationProps } from "___REFACTOR___/pages/DealCapture";
import { useFlags } from "launchdarkly-react-client-sdk";
import { filterElementsByElementName, NewTcFields, shouldEnableLaunchDarklyFeature } from "@/utils";
import { LaunchDarklyFeature } from "@/config";

function MainTermsForm(props: Props) {
  const { context, getElements, section, ...rest } = props;
  const [isInitialized, setIsInitialized] = useState(false);
  const [isProceeding, setIsProceeding] = useState(false);
  const params = useParams<StringRecord>();
  const { orderId, negotiationId } = params;
  const paramsType = params.type as Negotiation["type"];
  const orderModel = orderId ? anyOrderNegotiationStore.upsertOrderModel({ id: orderId }) : undefined;
  const negotiationModel = orderModel?.upsertNegotiation({ id: negotiationId, orderId });
  if (negotiationModel) negotiationModel.type = negotiationModel.type || paramsType;
  const type = negotiationModel?.type;
  const isDummyNegotiation = negotiationId.includes("$");
  const initialValuesRef = useRef(new UniversalOrderNegotiationFormValues());
  const initialValues = useMemo(getInitialValues, [orderModel?._.version, negotiationModel?._.version]);
  initialValuesRef.current = initialValues || initialValuesRef.current;
  let { elements, required } = useMemo(_getElements, [
    type,
    isDummyNegotiation,
    orderModel?._.version,
    negotiationModel?._.version,
  ]);
  const flags = useFlags();
  const enableTcFields = shouldEnableLaunchDarklyFeature(LaunchDarklyFeature.TCFields, flags);
  if (enableTcFields === false && elements) {
    elements = filterElementsByElementName(elements, NewTcFields);
  }

  const orderRoute = type === "Coa" ? "coa.order" : "orders.order";
  const backHref = `${router.get(orderRoute).compile({ orderId })}/${orderModel?.getStage()}`;
  const overlayStatus = (!isInitialized || isProceeding) && [orderModel?._.status, negotiationModel?._.status];

  let eventStartTime: Moment;

  useEffect(getOrder, [orderId]);
  useEffect(getNegotiation, [negotiationId]);
  useEffect(onInitialValueChange, [initialValues]);
  useEffect(() => {
    eventStartTime = usageMetrics.startTrackingAction(MainTermsTypeEvents.MAIN_TERMS_STARTED, orderId);
  }, []);

  return (
    <div className="main-terms">
      <div className="main-terms-form">
        <UniversalOrderNegotiationForm
          {...rest}
          initialValues={initialValues || initialValuesRef.current}
          elements={elements}
          required={required}
          context={form}
          yup={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
          suppressUnsavedChanges={false}
        />
      </div>
      <div className="main-terms-actions">
        <Button to={backHref} icon="arrow-back" className={classNames(seaChatWidget.initialized && "with-sea-chat-widget")}>
          back
        </Button>
        <div className="flex children-margin-left-small-medium">
          <Button onClick={clearMainTermsForm}>reset</Button>
          <Button
            variant="action"
            onClick={() => {
              usageMetrics.finishTrackingAction(MainTermsTypeEvents.MAIN_TERMS_CONTINUE, eventStartTime, orderId);
              form.submit();
            }}
            dataTest="continue"
          >
            continue
          </Button>
        </div>
      </div>
      <Overlay status={overlayStatus} />
    </div>
  );

  function clearMainTermsForm() {
    if (form?.formik?.dirty) {
      const { title, message, submitText, cancelText } = MainTermsResetConfirmationProps;
      dialog.show({
        status: { type: "warning", title: title, message: message, hideIcon: true },
        suppressStatusActions: true,
        actions: [
          { label: cancelText, variant: "flat" },
          { label: submitText, variant: "warning", onClick: cleanMainTermsFormAfterConfirmation },
        ],
      });
    } else {
      cleanMainTermsFormAfterConfirmation();
    }
  }

  function cleanMainTermsFormAfterConfirmation() {
    form.reset();
    // workaround to clean TermsetBuilder
    document.getElementById("clearTermsetBuilderButton")?.click();
  }

  async function onSubmit(values: UniversalOrderNegotiationFormValues) {
    setIsProceeding(true);

    const { res: dialogData } = await dialog.show(
      section === "standard"
        ? {
            content: <ProceedTo />,
            dataTest: "mainterms-proceed-dialog",
          }
        : {
            content: <CaptureProceedTo />,
            dataTest: "deal-capture-mainterms-proceed-dialog",
          }
    );

    if (!dialogData) return;

    _postNegotiationMainTerms(values, dialogData);
  }

  function getOrder() {
    getOrderAction(orderModel, context);
  }

  function getNegotiation() {
    getNegotiationAction(negotiationModel, context);
  }

  function _postNegotiationMainTerms(data: UniversalOrderNegotiationFormValues, dialogData: UniversalOrderNegotiationFormValues) {
    if (data.vessels) {
      data.vessels.vessels = data.vessels?.vessels?.filter((v) => v.vesselImo != null);
    }

    if (section === "standard") {
      postNegotiationMainTerms(negotiationModel, context, data, dialogData);
    } else {
      postNegotiationDealCaptureMainTerms(negotiationModel, context, data, dialogData);
    }
  }

  function getInitialValues() {
    if (isInitialized) return initialValuesRef.current;
    if (negotiationModel?.updateToken && orderModel?.updateToken) {
      const orderDetails = orderModel.getMainTermsFormValues();
      const negotiationDetails = negotiationModel?.getMainTermsFormValues();

      return new UniversalOrderNegotiationFormValues({ ...orderDetails, ...negotiationDetails, type });
    }

    if (isDummyNegotiation && orderModel?.updateToken) {
      const orderDetails = orderModel.getMainTermsFormValues(type);
      return new UniversalOrderNegotiationFormValues({ ...orderDetails, type });
    }
  }

  function _getElements() {
    return getElements(type, isDummyNegotiation, initialValuesRef.current);
  }

  function onInitialValueChange() {
    if (initialValues) {
      setIsInitialized(true);
    }
  }
}

const validationSchema = yup
  .object()
  .test(
    "validateTermset",
    "Something is wrong with Termset",
    function validateTermset(this: yup.TestContext, values: UniversalOrderNegotiationFormValues) {
      if (!values) return false;

      const duplicateTermTitle = resolveDuplicateMainTermTemplateTitle(values.termset?.content?.mainTermTemplates);

      if (duplicateTermTitle) {
        const error = this.createError();

        error.message = `Termset contains duplicate term title: ${duplicateTermTitle}`;
        error.path = "termset.content.mainTermTemplates";

        return error;
      }
      return true;
    }
  )
  .test(
    "validateSubjectTermset",
    "Something is wrong with Subject Termset",
    function validateTermset(this: yup.TestContext, values: UniversalOrderNegotiationFormValues) {
      if (!values) return false;

      const duplicateTermTitle = resolveDuplicateMainTermTemplateTitle(values.subjectTermset?.content?.mainTermTemplates);

      if (duplicateTermTitle) {
        const error = this.createError();

        error.message = `Subject Termset contains duplicate term title: ${duplicateTermTitle}`;
        error.path = "subjectTermset.content.mainTermTemplates";

        return error;
      }
      return true;
    }
  );

function resolveDuplicateMainTermTemplateTitle(mainTermTemplates) {
  mainTermTemplates = mainTermTemplates || [];

  let duplicateTermTitle;
  const termTitleCountMap = {};

  for (let i = 0; i < mainTermTemplates.length; i++) {
    const term = mainTermTemplates[i];
    const termTitle = term.title?.toUpperCase();

    if (!termTitle) continue;

    termTitleCountMap[termTitle] = termTitleCountMap[termTitle] || 0;

    termTitleCountMap[termTitle]++;

    if (termTitleCountMap[termTitle] > 1) {
      duplicateTermTitle = termTitle;
    }
  }

  return duplicateTermTitle;
}

const form = new FormContext();

const Observer = observer(MainTermsForm);

export { Observer as MainTermsForm };

// @ts-ignore
export const Context = createContext<Context>(null);

export interface Props extends Omit<FormProps, "context" | "children"> {
  section: string;
  context: Context;
  children?: React.ReactNode;
  getElements: (
    type: UniversalOrderNegotiationFormValues["type"],
    isDummyNegotiation: boolean,
    values: UniversalOrderNegotiationFormValues | undefined
  ) => { elements: Element<any>[]; required?: string[] };
}

export interface Context {
  dataTest: string;
}
