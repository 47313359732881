import React, { useState, useContext } from "react";
import tokenStyles from "__legacy/sharedFolder/styles/token-search-styles.module.scss";
import styles from "./NegotiationTab.module.scss";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { ISelectableOption, sortKey } from "sharedFolder/Models/userSettings";
import { FilterAndSortHeader } from "./components/filterAndSort/FilterAndSortHeader";
import { UserContext } from "__legacy/dashboard/contexts/UserProvider";
import { cloneDeep } from "lodash-es";
import { useOrderType } from "__legacy/sharedFolder/contexts/order-type-context";
import ifITOElseCOA from "sharedFolder/Utilities/ifITOElseCOA";
import Icon from "__legacy/sharedFolder/components/common/Icon/Icon";
import { useNegotiationsByOrderId } from "sharedFolder/contexts/NegotiationsByOrderId.context";
import { useOrder } from "__legacy/orderNegs/useOrder";
import { useConfig } from "__legacy/sharedFolder/ConfigurationContext";

interface INegotiationTabProps {
  filters: ISelectableOption[];
  orderId: string;
  showFilters?: boolean;
  sortField?: sortKey;
}

const NegotiationTab = (props: INegotiationTabProps) => {
  const [isFilterSortExpanded, setIsFilterSortExpanded] = useState(false);
  const {
    orderPreferences: { clearOrderFilters, setOrderSort, setOrderFilter },
  } = useContext(UserContext);

  const orderType = useOrderType();
  const { ctradeUrl } = useConfig();
  // @ts-ignore
  const { orderModel } = useOrder(ctradeUrl, props.orderId);
  const { negotiations } = useNegotiationsByOrderId();
  const filtersApplied = props.filters.filter((i) => i.selected).length;

  const liftingsAttachmentsCount = orderModel?.negsAttachments.filter((attachment) => attachment.type === "LFT").length || 0;
  const coaAttachmentsCount =
    orderModel?.negsAttachments.filter((attachment) => attachment.type === "COA" && attachment.status === "Fixed").length || 0;
  const orderAttachments = (orderModel?.orderAttachments || []).length;
  const negotiationAttachments = negotiations.flatMap((negotiation) => negotiation.attachments).length;
  const attachmentsNumberForCOA = ifITOElseCOA(
    orderType,
    orderAttachments + negotiationAttachments,
    liftingsAttachmentsCount + coaAttachmentsCount + orderAttachments
  );

  function handleSort(selectedSortField: string | number) {
    const key = Number(selectedSortField);
    setOrderSort(props.orderId, key);
  }

  function handleFilter(option: string, selected: boolean) {
    const newFilters = cloneDeep(props.filters).map((filter) => {
      if (filter.name === option) {
        filter.selected = selected;
      }
      return filter;
    });

    setOrderFilter(props.orderId, newFilters);
  }

  function toggleFilterSort() {
    setIsFilterSortExpanded(!isFilterSortExpanded);
  }

  return (
    <section id="negotiationsactions" className={styles.negotiationsActions}>
      <div className={styles.actionBar}>
        <Tabs attachmentsNumber={attachmentsNumberForCOA} orderId={props.orderId} currentLocation={`/orders/${props.orderId}`} />
        {props.showFilters && (
          <AppliedFilters
            clearFilters={() => clearOrderFilters(props.orderId)}
            toggleFilterSort={toggleFilterSort}
            numberOfFilters={filtersApplied}
          />
        )}
      </div>
      {isFilterSortExpanded && (
        <FilterAndSortHeader
          toggleFilterSort={toggleFilterSort}
          onSort={handleSort}
          selectedSortKey={props.sortField}
          onFilter={handleFilter}
          options={props.filters}
        />
      )}
    </section>
  );
};

const Tabs = (props: { attachmentsNumber: number; currentLocation: string; orderId: string }) => {
  const orderType = useOrderType();
  const ordersNavigationBase = ifITOElseCOA(orderType, "/orders/", "/coas/orders/");
  return (
    <div className={styles.negsAndSharingButtonsContainer}>
      <NavLink
        data-test="Negotiations-Tab"
        className={classNames(styles.actionLink)}
        activeClassName={styles.selectedTab}
        isActive={(match, location) => {
          const { pathname } = location;
          return (
            pathname.endsWith(`/orders/${props.orderId}`) ||
            pathname.endsWith(`/orders/${props.orderId}/negotiationswaiting`) ||
            pathname.endsWith(`/orders/${props.orderId}/negotiations`)
          );
        }}
        to={`${ordersNavigationBase}${props.orderId}`}
        exact={true}
      >
        Negotiations
      </NavLink>
      {ifITOElseCOA(
        orderType,
        null,
        <NavLink
          className={classNames(styles.actionLink)}
          activeClassName={styles.selectedTab}
          to={ordersNavigationBase + props.orderId + "/liftings"}
          data-test="Liftings-Tab"
        >
          Liftings
        </NavLink>
      )}
      {ifITOElseCOA(
        orderType,
        null,
        <NavLink
          className={classNames(styles.actionLink)}
          activeClassName={styles.selectedTab}
          to={ordersNavigationBase + props.orderId + "/coa-details"}
          data-test="CoaDetails-Tab"
        >
          COA Details
        </NavLink>
      )}
      <NavLink
        className={classNames(styles.actionLink)}
        activeClassName={styles.selectedTab}
        to={ordersNavigationBase + props.orderId + "/distribution"}
        data-test="Distribution-Tab"
      >
        Distribution
      </NavLink>
      <NavLink
        className={classNames(styles.actionLink)}
        activeClassName={styles.selectedTab}
        to={ordersNavigationBase + props.orderId + "/attachments"}
        data-test="Attachments-Tab"
      >
        Attachments
        {props.attachmentsNumber > 0 && (
          <sup className={styles.badge} data-test="attachments-icon-and-count-tab">
            {props.attachmentsNumber}
          </sup>
        )}
      </NavLink>
    </div>
  );
};

const AppliedFilters = ({
  clearFilters,
  numberOfFilters,
  toggleFilterSort,
}: {
  numberOfFilters: any;
  clearFilters: () => void;
  toggleFilterSort: () => void;
}) => {
  return (
    <div className={styles.filterSortContainer}>
      {numberOfFilters > 0 && (
        <span className={classNames(tokenStyles.tokenSearchItem, styles.filterTag)} onClick={clearFilters}>
          {numberOfFilters}
          {numberOfFilters === 1 ? " Filter" : " Filters"} Applied
        </span>
      )}
      <div className={styles.filterSortContent} onClick={toggleFilterSort}>
        Filter/Sort
        <Icon icon="sort" className={styles.filterSortButton} />
      </div>
    </div>
  );
};

export default NegotiationTab;
