import React, { forwardRef, Ref, useImperativeHandle, useState } from "react";
import { REGIONS } from "@/constants";
import { AggridFilterComp, AggridFilterParams, CheckboxInput } from "@/components";
import "./LocationZoneFilter.scss";
import { Location } from "@/models";

function LocationZoneFilter(params: AggridFilterParams, ref: Ref<AggridFilterComp>) {
  const { filterChangedCallback, colDef } = params;
  const colValueGetter = colDef.valueGetter;
  const [state, setState] = useState(defaultState);
  const isActive = !!Object.keys(state).length;

  useImperativeHandle(ref, getCurrentInstance);

  function getCurrentInstance() {
    return {
      isFilterActive,
      doesFilterPass,
      getModel,
      setModel,
    };
  }

  function isFilterActive() {
    return isActive;
  }

  function doesFilterPass(params) {
    let locations;

    if (typeof colValueGetter === "function") {
      const locationMap = colValueGetter(params);

      locations = locationMap.locations;
    }

    if (!locations) return false;

    let containsOneOfTheZones;

    for (let i = 0; i < locations.length; i++) {
      if (containsOneOfTheZones) break;

      const location = locations[i]?.data as Location;

      if (!location || !location.parents) continue;

      for (let i = 0; i < location.parents.length; i++) {
        const parent = location.parents[i];

        containsOneOfTheZones = state[parent];

        if (containsOneOfTheZones) break;
      }
    }

    return containsOneOfTheZones;
  }

  function getModel() {
    return state;
  }

  function setModel(state) {
    setState(state ? state : defaultState);
  }

  return (
    <sea-location-region-filter>
      <sea-location-region-filter-column>{REGION_FIRST_COLUMN.map(CheckboxHOC)}</sea-location-region-filter-column>
      <sea-location-region-filter-column>{REGION_SECOND_COLUMN.map(CheckboxHOC)}</sea-location-region-filter-column>
      <sea-location-region-filter-column>{REGION_THIRD_COLUMN.map(CheckboxHOC)}</sea-location-region-filter-column>
    </sea-location-region-filter>
  );

  function CheckboxHOC(region) {
    function onChange(value) {
      if (value) {
        setState({ ...state, [region.value]: value });

        //
      } else {
        const { [region.value]: removed, ...rest } = state;

        setState(rest);
      }

      setTimeout(filterChangedCallback);
    }

    return (
      <CheckboxInput
        value={state[region.value]}
        onChange={onChange}
        label={region.label}
        dataTest={region.value}
        key={region.value}
      />
    );
  }
}

const defaultState = {} as State;

const REGION_FIRST_COLUMN = REGIONS.slice(0, 6);
const REGION_SECOND_COLUMN = REGIONS.slice(6, 12);
const REGION_THIRD_COLUMN = REGIONS.slice(12, 18);

const ForwardRef = forwardRef(LocationZoneFilter);

ForwardRef.displayName = "LocationZoneFilter";

export { ForwardRef as LocationZoneFilter };

type State = BoolRecord;
