import { config } from "@/config";
import { Vessel, auth } from "@/models";
import { Button } from "@/components";
import { NegotiationTypeEvents, usageMetrics } from "@/services/UsageMetrics";
import "./VesselButton.scss";

export function VesselButton(props: IProps) {
  const { vessel, seaAnalyticsOrSeaNet } = props;

  const imo = vessel.vesselImo || vessel.vesselIMO;
  const seaNetUrl = `${config.vesselViewUrl}/${imo}?token=${auth.central.token}`;
  const seaAnalyticsUrl = `${config.vesselActivityUrl}${imo}`;
  const href = seaAnalyticsOrSeaNet === "Net" ? seaNetUrl : seaAnalyticsUrl;

  return (
    <Button
      onClick={() => {
        usageMetrics.trackEvent(
          seaAnalyticsOrSeaNet === "Net"
            ? NegotiationTypeEvents.NOMINATE_VESSEL_SEA_NET
            : NegotiationTypeEvents.NOMINATE_VESSEL_SEA_ANALYTICS
        );
      }}
      href={href}
      className={seaAnalyticsOrSeaNet === "Analytics" ? "btn-sea-analytics" : "btn-sea-net"}
      dataTest={`vessel.${seaAnalyticsOrSeaNet === "Analytics"}`}
      hidden={!imo}
      disabled={typeof auth.trade.user?.SystemUserId !== "number"}
      openHrefInNewWindow
    />
  );
}

interface IProps {
  vessel: Vessel;
  seaAnalyticsOrSeaNet: "Analytics" | "Net";
}
