import { Negotiation, dialog } from "@/models";

export async function sendNegotiationRecap(model: Negotiation | undefined) {
  if (!model) {
    console.error("sendNegotiationRecap:", { model });

    const status = {} as Status;

    status.type = "error";
    status.title = "Send Recap Failure";

    dialog.show({
      status,
      dataTest: `post-negotiation-recap-fail`,
    });

    return;
  }

  model._.status.loading = true;
  model._.status.message = `Sending Recap to All Parties`;

  const res = await model.postArcSendRecap();

  if (!res?.ok) {
    model._.status.type = "error";
    model._.status.title = "Send Recap Failure";
    model._.status.dump = { res, entity: model };

    dialog.show({
      status: model._.status,
      dataTest: `post-negotiation-recap-fail`,
    });

    model._.status.loading = false;
    model._.status.message = null;

    return res;
  }

  model._.status.loading = false;
  model._.status.message = null;

  // dialog.show({
  //   status: {
  //     type: "success",
  //     title: "Successfully Sent Recap to All Parties",
  //   },
  //   dataTest: `post-negotiation-recap-success`,
  // });

  return res;
}
