import React, { useContext } from "react";
import styles from "./OfferRepAttachments.module.scss";
import {
  downloadOfferRepNegAttachment,
  downloadOfferRepOrderAttachment,
  deleteNegAttachment,
  uploadNegAttachment,
  downloadNegAttachment,
  downloadOrderAttachment,
  UploadAttachmentResult,
} from "__legacy/negotiations/services/attachments";
import { Attachments } from "__legacy/sharedFolder/components/common/Attachments/Attachments";
import { IAttachment } from "__legacy/sharedFolder/Models/IAttachment";
import { MinimalConfigurationContext } from "__legacy/sharedFolder/MinimalConfigurationContext";
import { uploadOfferRepAttachment, deleteOfferRepAttachment } from "../api/offerRepUpdate";
import { Observable, of } from "rxjs";
import { AttachmentsList } from "__legacy/sharedFolder/components/common/Attachments/AttachmentsList";
import { emptyArrowFn } from "@/utils";

/**
 * Represents the properties on all props interfaces
 */
interface IAttachmentBase {
  negotiationAttachments: IAttachment[];
  orderAttachments: IAttachment[];
  updateToken: string;
  invitee?: string;
}

/**
 * Represents the actions that can be taken on the Attachments component
 */
interface IAttachmentActionsProps extends IAttachmentBase {
  deleteAttachment: (baseUrl: string, fileId: string, updateToken: string) => Observable<{ success: boolean }>;
  downloadAttachment: (fileId: string, fileName: string, updateToken: string, isOrderAttachment: boolean) => void;
  uploadAttachment: (baseUrl: string, formData: FormData, updateToken: string) => Observable<UploadAttachmentResult>;
}

interface IOfferRepAttachmentProps extends IAttachmentBase {
  offerRepToken: string;
}

interface IOrderNegAttachmentProps extends IAttachmentBase {
  negotiationId: string;
  orderId: string;
}

export const OfferRepAttachments: React.SFC<IOfferRepAttachmentProps> = (props) => {
  return (
    <InternalAttachments
      {...props}
      invitee={props.invitee}
      uploadAttachment={uploadOfferRepAttachment(props.offerRepToken)}
      deleteAttachment={deleteOfferRepAttachment(props.offerRepToken)}
      downloadAttachment={(fileId, fileName, updateToken, isOrderAttachment: boolean) =>
        isOrderAttachment
          ? downloadOfferRepOrderAttachment(props.offerRepToken)(fileId, fileName, updateToken)
          : downloadOfferRepNegAttachment(props.offerRepToken)(fileId, fileName, updateToken)
      }
    />
  );
};

export const OwnerDashboardAttachments: React.SFC<IOrderNegAttachmentProps> = (props) => {
  return (
    <InternalAttachments
      {...props}
      invitee={props.invitee}
      uploadAttachment={(baseUrl, formData, updateToken) =>
        uploadNegAttachment(props.orderId, props.negotiationId)(`${baseUrl}/owner`, formData, updateToken)
      }
      deleteAttachment={(baseUrl, fileId, updateToken) =>
        deleteNegAttachment(props.orderId, props.negotiationId)(`${baseUrl}/owner`, fileId, updateToken)
      }
      downloadAttachment={(fileId, fileName, updateToken, isOrderAttachment: boolean) =>
        isOrderAttachment
          ? downloadOrderAttachment(props.orderId)(fileId, fileName, updateToken)
          : downloadNegAttachment(props.orderId, props.negotiationId)(fileId, fileName, updateToken)
      }
    />
  );
};

const InternalAttachments = ({
  invitee,
  negotiationAttachments,
  orderAttachments,
  updateToken,
  deleteAttachment,
  downloadAttachment,
  uploadAttachment,
}: IAttachmentActionsProps) => {
  const { ctradeUrl } = useContext(MinimalConfigurationContext);

  return (
    <>
      <div className={styles.sectionTitle}>Order Attachments</div>
      <div>
        <div className={styles.attachmentsContainer}>
          <AttachmentsList
            invitee={invitee}
            areOrderLevelAttachments={true}
            attachments={orderAttachments}
            userRoles={["owner"]}
            onDelete={() => of()} // This intentially left blank as will never be used by and owner
            onDownload={(fileId: string, fileName: string) => downloadAttachment(fileId, fileName, updateToken, true)}
            onChangeValidationErrors={emptyArrowFn}
          />
        </div>
      </div>
      <div className={styles.sectionTitle}>Your Attachments</div>
      <div>
        <div className={styles.attachmentsContainer}>
          <Attachments
            invitee={invitee}
            areOrderLevelAttachments={false}
            attachments={negotiationAttachments}
            userRoles={["owner", "charterer"]}
            onDelete={(fileId: string) => deleteAttachment(ctradeUrl, fileId, updateToken)}
            onDownload={(fileId: string, fileName: string) => downloadAttachment(fileId, fileName, updateToken, false)}
            onUpload={(formData: FormData) => uploadAttachment(ctradeUrl, formData, updateToken)}
          />
        </div>
      </div>
    </>
  );
};
