import { TradeAPI } from "@/apis";
import { TimezoneEditor } from "@/components";
import { DataModel, DataModelProps } from "../DataModel";

export class Timezone extends DataModel<Data> {
  toString() {
    return this.display;
  }
}

class TimezoneProps extends DataModelProps<Data> {}

TimezoneProps.prototype.editor = TimezoneEditor;

Timezone.prototype._ = new TimezoneProps();

export function newTimezone(data: Data) {
  return new Timezone(data);
}

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

export interface Timezone extends Data {
  _: TimezoneProps;
}

type Data = DeepPartial<TradeAPI["Timezone"]>;
