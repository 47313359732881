import React from "react";
import { dialog, dialog0 } from "@/models";
import { LoadingOverlay, Dialog } from "@/components";

export function Preauth() {
  return (
    <>
      <LoadingOverlay status={{ loading: true }} />
      <Dialog model={dialog} />
      <Dialog model={dialog0} />
    </>
  );
}
