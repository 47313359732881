export function formatNumber(number) {
  return formatNumberFormatter.format(number);
}

export const maximumFractionDigits = 3;

const formatNumberFormatter = new Intl.NumberFormat("en-UK", {
  style: "decimal",
  maximumFractionDigits,
});
