import { memo } from "react";
import classNames from "classnames";
import "./TextOnly.scss";

function TextOnly(props) {
  const { element } = props;
  const { children } = element;
  const className = classNames(element.className, "TextOnly");

  if (!children) return null;

  return (
    <div className={className}>
      {children.map((text, i) => (
        <p key={`${text.text}-${i}`}>{text.text}</p>
      ))}
    </div>
  );
}

const Memo = memo(TextOnly);

export { Memo as TextOnly };
