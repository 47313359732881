import React from "react";
import { observer } from "mobx-react";
import { CargoType } from "___REFACTOR___/models";
import { cargoTypes } from "___REFACTOR___/stores";
import { TextareaField, SelectField, StatusMessages, Field } from "___REFACTOR___/components";
import { Group } from "../../Group";
import "./CargoType.scss";

function _CargoTypeEditor(props: CargoTypeEditor.Props) {
  const { className, status, value, dataTest, ...rest } = props;

  return (
    <Group {...props} className={className} editorType="CargoType">
      <SelectField
        {...rest}
        value={value}
        className="CargoType-value"
        data={cargoTypes.data}
        onChange={onValueChange}
        dataTest={`${dataTest}.value`}
        filterKey="name"
        noDataStatus={noDataStatus}
      />
      <TextareaField
        {...rest}
        required={false}
        label="Notes"
        desc="Cargo type notes"
        className="CargoType-notes"
        value={value?.notes || ""}
        onChange={onNotesChange}
        status={status?.childMap?.notes}
        hidden={!value}
        dataTest={`${dataTest}.notes`}
      />
      <StatusMessages status={status} />
    </Group>
  );

  function onValueChange(cargoType: CargoType) {
    if (cargoType === undefined) return props.onChange(undefined);

    props.onChange?.(new CargoType(cargoType));
  }

  function onNotesChange(notes: TextareaField.Input.Value) {
    if (typeof notes !== "string") return;

    props.onChange?.(new CargoType({ ...value, notes }));
  }
}

const noDataStatus = { message: "No matching cargo types" };

const CargoTypeEditor = observer(_CargoTypeEditor);

export { CargoTypeEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace CargoTypeEditor {
  export interface Props extends Field.Props {
    value: Value;
    onChange: (value: Value) => void;
  }

  export type Value = CargoType | undefined;
}
