import React from "react";
import { Subs } from "___REFACTOR___/models";
import { TextareaField } from "___REFACTOR___/components";

function SubsEditor(props: SubsEditor.Props) {
  const { dataTest, value } = props;

  return <TextareaField {...props} value={value?.value} onChange={onChange} editorType="Subs" dataTest={`${dataTest}.value`} />;

  function onChange(value: TextareaField.Input.Value) {
    if (typeof value !== "string") return;

    props.onChange(new Subs({ value }));
  }
}

export { SubsEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace SubsEditor {
  export interface Props extends Omit<TextareaField.Props, "value" | "onChange"> {
    value: Value;
    onChange: (value: Value) => void;
  }

  export type Value = Subs | undefined;
}
