import React from "react";
import { FieldProps, NumberField, TextareaField } from "@/components";
import { BunkerDelivery, BunkerRedelivery } from "@/models";
import { Group } from "../../Group";
import { Row } from "../../Row";
import { IBunkerView } from "sharedFolder/Models/IBunker";

export function BunkerBaseEditor(props: Props) {
  props = { ...props };
  props.className = `${props.className} Bunker`;

  const { value, dataTest, className, onChange: onFieldChange, bunkerType } = props;
  const { price, quantity, fuelTypes, notes } = value || {};

  const onChange = (key, propertyValue) => {
    onFieldChange?.(key, propertyValue);
  };

  return (
    <Group className={className}>
      <Row className="flex-end">
        <NumberField
          {...props}
          label={`Bunker Price ${bunkerType} ($)`}
          value={price}
          onChange={onChange.bind(null, "price")}
          min={0}
          max={999_999_999_999}
          placeholder="e.g. 30000"
          dataTest={`${dataTest}.price`}
        />
        <NumberField
          {...props}
          label="Quantity"
          value={quantity}
          onChange={onChange.bind(null, "quantity")}
          min={0}
          max={999_999_999_999}
          placeholder="e.g. 30000"
          dataTest={`${dataTest}.quantity`}
        />
      </Row>

      <TextareaField
        {...props}
        label="Fuel Types"
        value={fuelTypes}
        placeholder=""
        dataTest={`${dataTest}.fuelTypes`}
        onChange={onChange.bind(null, "fuelTypes")}
      />

      <TextareaField
        {...props}
        label="Notes"
        value={notes || ""}
        onChange={onChange.bind(null, "notes")}
        placeholder="Additional bunker notes"
        dataTest={`${dataTest}.notes`}
      />
    </Group>
  );
}

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface Props extends Omit<FieldProps, "value" | "onChange" | "bunkerType"> {
  value?: BunkerDelivery | BunkerRedelivery | IBunkerView;
  onChange?: (key: any, propertyValue: any) => void;
  bunkerType: "Delivery" | "Re-Delivery";
}

export type BunkerProps = Props;
