// BLACKBOX
import { FetchInterceptor } from "__legacy/dashboard/components/FetchInterceptor/FetchInterceptorOld";
import { UserProvider } from "__legacy/dashboard/contexts/UserProvider";
import { useOrder } from "__legacy/orderNegs/useOrder";
import { COAOrderAttachments } from "__legacy/negotiations/COAOrderAttachments";

import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { useSimpleEffect } from "@/utils";
import { config } from "@/config";
import { Context } from "../../../Orders";
import "../Attachments/Attachments.scss";

function COAAttachments() {
  const { orderId } = useParams<StringRecord>();
  const context = useContext(Context);
  const { orderNegotiationStore } = context;
  const order = orderId ? orderNegotiationStore?.upsertOrderModel({ id: orderId }) : undefined;
  const version = order?.version; // eslint-disable-line @typescript-eslint/no-unused-vars
  const { orderModel } = useOrder(config.ctradeUrl, orderId); // ts-ignore;

  useSimpleEffect(getNegotiationsDataByOrderId, [orderId]);

  if (!order) return null;

  if (orderModel) {
    orderModel.negsAttachments = orderModel.negsAttachments || [];
    orderModel.orderAttachments = orderModel.orderAttachments || [];
  }

  return (
    <div className="COAOrderAttachments LEGACY_CONTAINER">
      <FetchInterceptor baseUrl={config.ctradeUrl} exceptions={["/token$"]}>
        <UserProvider config={config}>
          <COAOrderAttachments {...config} order={orderModel} orderId={orderId} />
        </UserProvider>
      </FetchInterceptor>
    </div>
  );

  function getNegotiationsDataByOrderId() {
    orderNegotiationStore?.getNegotiationsDataByOrderId(orderId);
  }
}

const Observer = observer(COAAttachments);

export { Observer as COAAttachments };
