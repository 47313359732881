import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { Negotiation } from "@/models";
import { Timeleft, Button } from "@/components";
import "./OwnerNegotiationLegalMessage.scss";

function OwnerNegotiationLegalMessage(props: Props) {
  const { negotiation, toggleIndicationView, indicationView } = props;
  const { version } = negotiation?._ || {};
  const actionsModel = negotiation?.getActionsModel();
  const { brokerChartererFirmRequested, brokerChartererFirmed, brokerChartererFirmedExpired, brokerChartererFirmExpiresOn } =
    actionsModel || {};
  const view = useMemo(resolveView, [version, indicationView]);

  function resolveView() {
    if (brokerChartererFirmed) {
      if (brokerChartererFirmedExpired) return null;

      return (
        <>
          <div className="OwnerNegotiationLegalMessage-buttons">
            <Button variant="action" onClick={toggleIndicationView}>
              {indicationView ? "show firm bid" : "decline"}
            </Button>
          </div>
          <span>
            The Charterer sent you a <b>Firm Bid</b>. You have
            <Timeleft until={brokerChartererFirmExpiresOn} />
            to respond.
          </span>
        </>
      );
    }

    if (brokerChartererFirmRequested) {
      return (
        <>
          <div className="OwnerNegotiationLegalMessage-buttons">
            <Button variant="action" onClick={toggleIndicationView}>
              {indicationView ? "show my firm offer" : "decline"}
            </Button>
          </div>
          <span>
            The Charterer has requested you to make your last Indication a <b>firm OFFER</b>.
          </span>
        </>
      );
    }

    return null;
  }

  if (!view) return null;

  return <div className="OwnerNegotiationLegalMessage">{view}</div>;
}

const Observer = observer(OwnerNegotiationLegalMessage);

export { Observer as OwnerNegotiationLegalMessage };

interface Props {
  indicationView?: boolean;
  toggleIndicationView?: () => void;
  onAcceptFirmBid?: () => void;
  negotiation?: Negotiation;
}
