import { BunkerRedeliveryEditor } from "@/components";
import { LABELS } from "@/constants";
import { Bunker, BunkerProps } from "@/models/Bunker";

export class BunkerRedelivery extends Bunker {}
export class BunkerRedeliveryProps extends BunkerProps {}

BunkerRedeliveryProps.prototype.editor = BunkerRedeliveryEditor;
BunkerRedeliveryProps.prototype.label = LABELS["bunkerRedelivery"];
BunkerRedelivery.prototype._ = new BunkerRedeliveryProps();
