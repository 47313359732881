import { object, string, number, TestContext } from "yup";
import { TradeAPI } from "___REFACTOR___/apis";
import { Liftings } from "../Liftings";

const dispersalArr = ["Adhoc", "Fairly even spread", "Monthly"] as TradeAPI.Liftings.Dispersal[];

// https://github.com/jquense/yup
const schema = object({
  min: number().integer().required("Required"),
  max: number().integer().required("Required"),
  dispersal: string().oneOf(dispersalArr).required("Required"),
  notes: string(),
})
  .test({
    test: minMaxValidation,
    name: "minMaxValidation",
    message: "Something is wrong with Min / Max",
  })
  .transform(toJSON);

function toJSON(liftings: Liftings) {
  return liftings?.toJSON();
}

function minMaxValidation(this: TestContext, liftings) {
  if (!liftings) return true;

  const { min, max } = liftings as TradeAPI.Liftings;

  const error = this.createError();

  if (typeof min === "number" && typeof max === "number" && min > max) {
    error.message = "Min should be less than or equal to Max";
    error.path = `${error.path}.min`;

    return error;
  }

  return true;
}

export { schema };
