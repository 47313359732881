import React from "react";
import classNames from "classnames";
import Button from "__legacy/sharedFolder/components/common/Button/Button";
import { BackgroundColorType, IconTypes } from "sharedFolder/components/common/types";
import Icon from "__legacy/sharedFolder/components/common/Icon/Icon";
import styles from "./DismissableNotification.module.scss";

const getBGColor = (bgColor: BackgroundColorType) => {
  switch (bgColor) {
    case "blue":
      return styles.blue;
    case "orange":
      return styles.orange;
    case "green":
      return styles.green;
    case "red":
      return styles.red;
    default:
      return new Error(`No background color for ${bgColor}`);
  }
};

const DismissableNotificationInner: React.SFC<{
  onClose?: () => void;
  children: JSX.Element;
  backgroundColor: BackgroundColorType;
}> = ({ onClose, children, backgroundColor }) => {
  return (
    <div className={classNames(styles.container, getBGColor(backgroundColor))}>
      {children}
      {onClose && <Button className={styles.closeButton} onClick={onClose} icon="close" ariaLabel="dismiss ribbon" />}
    </div>
  );
};

export const DismissableNotification: React.SFC<{
  onClose?: () => void;
  backgroundColor: BackgroundColorType;
  icon: IconTypes;
  title?: string;
  body?: string;
}> = ({ onClose, backgroundColor, icon, title, body }) => {
  return (
    <DismissableNotificationInner onClose={onClose} backgroundColor={backgroundColor}>
      <div className={styles.notification} data-test="dismissable-message">
        <Icon icon={icon} />
        {title && (
          <div className={styles.title} data-test={`Dismissable-Notification-${title}`}>
            {title}
          </div>
        )}
        <div className={styles.description}>{body}</div>
      </div>
    </DismissableNotificationInner>
  );
};
