// @ts-ignore
import Split from "react-split";
import classNames from "classnames";
import React, { useEffect, useMemo, createContext, useState } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { config } from "@/config";
import { TradeAPI } from "@/apis";
import { storage, capitalizeFirstLetter, useSimpleEffect } from "@/utils";
import { Negotiation, router } from "@/models";
import { NavLink } from "../common";
import { Overlay, Timepassed, Button } from "@/components";
import { getNegotiation as getNegotiationAction } from "./actions";
import { Form } from "./Form";
import "./Offerrep.scss";

const Offerrep = (props: IProps) => {
  const { children } = props;
  const { offerRepToken } = useParams<StringRecord>();
  const [initialNegotiationData, setInitialNegotiationData] = useState<TradeAPI["Negotiation"]>();
  const negotiationModel = useMemo(getNegotiationModel, [initialNegotiationData]);
  const [status, setStatus] = useState<Status>(defaultStatus);
  const signUpURL = createSignUpUrl(offerRepToken);
  const offerrepColumns = storage.local.get(OFFERREP_COLUMN_STORAGE_KEY) || [50, 50];
  const showFormOnly = window.location.pathname.includes("/negotiation");
  const className = classNames({ "show-form-only": showFormOnly });
  const context = { ...useMemo(createContext, [negotiationModel?.version]), signUpURL };

  useSimpleEffect(getInitialNegotiation, [offerRepToken]);
  useEffect(setupPolling, [initialNegotiationData]);

  return (
    <Context.Provider value={context}>
      <offerrep-container class={className}>
        <offerrep-responsive-header>
          <img src="/svg/sea.trade-white.svg" alt="Sea/trade" />
          <a href="https://www.sea.live/" className="learn-more" rel="noopener noreferrer">
            Learn More
          </a>
          <a href={signUpURL} rel="noopener noreferrer">
            Sign up for full access
          </a>
        </offerrep-responsive-header>
        <offerrep-header>
          <h2>Order {negotiationModel?.orderReference}</h2>
          <span>From: {negotiationModel?.circulatedBy} Re: Invitation to Offer</span>
          <span>
            Updated by{" "}
            {capitalizeFirstLetter(negotiationModel?.lastUpdatedBy === "owner" ? "You" : negotiationModel?.lastUpdatedBy)}{" "}
            <Timepassed since={negotiationModel?.lastUpdated} textOnly />
          </span>
        </offerrep-header>
        <Split className="offerrep-split" {...splitProps} sizes={offerrepColumns} onDragEnd={(sizes) => onDragEnd(sizes)}>
          <offerrep-left-pane>
            <offerrep-left-pane-tabs class="tabs">
              <NavLink to="negotiation">Negotiation</NavLink>
              <NavLink to="summary" dataTest={"Summary-Tab"}>
                Summary
              </NavLink>
              <NavLink to="notes-and-attachments" dataTest={"Notes & Attachments-Tab"}>
                Notes & Attachments
              </NavLink>
            </offerrep-left-pane-tabs>
            <Form />
          </offerrep-left-pane>
          <offerrep-right-pane>{children}</offerrep-right-pane>
        </Split>
        <Overlay status={status} />
      </offerrep-container>
      <offerrep-header-stuff>
        <span>
          This page is for unregistered users - sign up to negotiate, fix and manage all your shipping deals in one place
        </span>
        <offerrep-header-stuff-links>
          <a href="https://www.sea.live/" className="learn-more" rel="noopener noreferrer">
            Learn More
          </a>
          <Button href={signUpURL} variant="action" icon="send" className="sign-up-btn">
            Sign up for full access
          </Button>
        </offerrep-header-stuff-links>
      </offerrep-header-stuff>
    </Context.Provider>
  );

  function getNegotiationModel() {
    return new Negotiation(initialNegotiationData || { offerRepToken });
  }

  async function getInitialNegotiation() {
    const res = await getNegotiationAction(negotiationModel);

    setStatus({ message: null, loading: false });

    if (!res?.ok) return;

    setInitialNegotiationData(res.data);
  }

  function setupPolling() {
    if (!initialNegotiationData) return;

    const timeoutId = window.setInterval(getNegotiationAction.bind(null, negotiationModel), 2000);

    function unsetInterval() {
      window.clearInterval(timeoutId);
    }

    return unsetInterval;
  }

  function createContext() {
    return { negotiation: negotiationModel };
  }

  function onDragEnd(size: any) {
    storage.local.set(OFFERREP_COLUMN_STORAGE_KEY, size);
  }
};

function createSignUpUrl(offerRepToken) {
  let signUpURL = `${config.authUrl}/#/manual-signup?moduleId=40`;
  signUpURL = `${signUpURL}&redirect=${window.location.origin}${router.get("handleNewOwnerUser").compile({ offerRepToken })}`;

  return signUpURL;
}

// @ts-ignore
window.createSignUpUrl = createSignUpUrl;

const OFFERREP_COLUMN_STORAGE_KEY = "OFFERREP_COLUMNS";

const defaultStatus = {
  message: "Loading",
  loading: true,
};

const splitProps = {
  gutterSize: 5,
};

// @ts-ignore
export const Context = createContext<Context>(null);

const Observer = observer(Offerrep);

export { Observer as Offerrep };

interface IProps {
  context: Context;
  children?: React.ReactNode;
}

export interface Context {
  negotiation: Negotiation;
  signUpURL: string;
}
