import React from "react";
import { Modal } from "office-ui-fabric-react";
import { ConfirmationDialog } from "sharedFolder/components/common/ConfirmationDialog/ConfirmationDialog";
import { emptyArrowFn } from "@/utils";

export const LiftingsModalConfirm = (props: { onCancel: () => void; onConfirm: () => void; isSaving: boolean }) => {
  return (
    <Modal isOpen={true} onDismiss={emptyArrowFn}>
      <ConfirmationDialog
        title="Ready to declare?"
        message={
          <span>
            This will send the cargo details to the owner.
            <br /> You will still be able to edit the details.
          </span>
        }
        dataTestId="AcceptFirmOKButton"
        isDisabled={props.isSaving}
        isCancelDisabled={props.isSaving}
        displayLoading={props.isSaving}
        ariaLabel="Declare to owner"
        okButtonText="Declare to owner"
        onSubmit={props.onConfirm}
        onCancel={props.onCancel}
      />
    </Modal>
  );
};
