import React from "react";
import classnames from "classnames";
import { DateInput, DateInputProps } from "@/components";
import { FieldProps as FullFieldProps } from "../../Field";
import { Container } from "../../Container";

export function DateField(props: DateFieldProps) {
  let { className, desc, ...rest } = props;

  className = classnames(className, "Date");

  return (
    <Container {...rest} className={className} desc={desc}>
      <DateInput {...rest} />
    </Container>
  );
}

export interface DateFieldProps extends FieldProps, DateInputProps {}

type FieldProps = Omit<FullFieldProps, "value" | "onChange">;
