import { TradeAPI } from "___REFACTOR___/apis";
import { Negotiation } from "___REFACTOR___/models/Negotiation";
import { DataModel } from "___REFACTOR___/models/DataModel";

class Lifting extends DataModel {
  constructor(data: Data, liftingsData: LiftingsData, neg?: Negotiation) {
    super(data);

    if (data.neg) this.neg = new Negotiation(data.neg);

    this.neg = neg || this.neg;

    this.liftingsData = liftingsData;
  }

  get acceptedVessel() {
    return this.neg?.vessels.find((vessel) => vessel.status === "accepted");
  }

  get statusVessel() {
    return "";
  }
}

export { Lifting };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface Lifting extends Omit<Data, "neg"> {
  neg?: Negotiation;
  liftingsData: LiftingsData;
}

declare namespace Lifting {
  export { Data };
}

type Data = TradeAPI.Lifting;

type LiftingsData = TradeAPI.Enp.Orders.Order.Liftings.Get.Res.Body;
