import React, { useState } from "react";
import { LiftingsCargoDetails } from "./LiftingsCargoDetails";
import { INegotiationView } from "sharedFolder/Models/Negotiation/INegotiationView";
import Button from "__legacy/sharedFolder/components/common/Button/Button";
import { WithdrawLiftingModalConfirm } from "./WithdrawLiftingModalConfirm";
import { useConfig } from "__legacy/sharedFolder/ConfigurationContext";
import { withdrawLifting } from "../../services/withdrawLifting";
import { useDismissableNotification } from "./DismissableNotificationContext";
import { EditorFieldProvider } from "sharedFolder/components/common/EditorFields/EditorFieldContextProvider";
import { head } from "lodash-es";
import { laycanAmendDisplay } from "sharedFolder/Models/IDetails";

interface ILiftingsCargoDetailsProps {
  liftingId: string;
  liftingCargoId: string;
  updateToken: string;
  orderId: string;
  negotiation: INegotiationView;
  isDealCapture: boolean;
  canChartererManagedLiftings: boolean;
}

export const LiftingsCargoDetailsNegotiation = (props: ILiftingsCargoDetailsProps) => {
  const { liftingId, liftingCargoId, updateToken, orderId, negotiation, isDealCapture } = props;

  const [isEditable, setIsEditable] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const config = useConfig();
  const { showNotification: toggleNotification } = useDismissableNotification();

  const form = {
    laycan: laycanAmendDisplay(negotiation.details?.laycan && negotiation.details.laycan.bid),
    cargoType: negotiation.details?.cargoType && negotiation.details.cargoType.bid,
    cargoSize: negotiation.details?.cargoSize && negotiation.details.cargoSize.bid,
    discharge: negotiation.details?.dischargeLocation && negotiation.details.dischargeLocation.bid,
    load: negotiation.details?.loadLocation && negotiation.details?.loadLocation.bid,
    freightRate: negotiation.details?.freightRate && negotiation.details.freightRate.bid,
    demurrage: negotiation.details?.demurrage && negotiation.details.demurrage.bid,
    notes: negotiation.details?.notes.value,
    vessel: head(negotiation.details?.vessels),
  };

  const confirm = () => {
    setIsSaving(true);

    const isConfirmedLifting = negotiation.status === "Confirmed";

    withdrawLifting(
      config.ctradeUrl,
      negotiation.orderId,
      negotiation.liftingId!,
      negotiation.liftingCargoId!,
      isConfirmedLifting,
      updateToken
    ).subscribe(
      () => {
        toggleNotification("WITHDRAW_LIFTING");
      },
      (error) => {
        // TODO: Deal with error case
        throw error;
      },
      () => {
        setConfirmDialog(false);
        setIsSaving(false);
      }
    );
  };

  let buttons = (
    <>
      <Button
        dataTest={`edit-btn-${negotiation.id}`}
        disabled={negotiation.status === "Confirmed"}
        type="flat"
        icon="mode-edit"
        // TODO: Implement Cancel functionality
        onClick={() => setIsEditable(!isEditable)}
      >
        Edit
      </Button>
      <Button
        dataTest={`withdrawn-btn-${negotiation.id}`}
        disabled={false}
        type="flat"
        icon="withdrawn"
        onClick={() => setConfirmDialog(true)}
      >
        Withdraw
      </Button>
    </>
  );

  if (props.isDealCapture) {
    buttons = <></>;
  }

  return (
    <EditorFieldProvider isEditable={isEditable}>
      <LiftingsCargoDetails
        liftingId={liftingId}
        liftingCargoId={liftingCargoId}
        updateToken={updateToken}
        orderId={orderId}
        liftingsDetails={form}
        createdOn={negotiation.createdOn}
        isEditable={isEditable}
        onCancel={() => setIsEditable(false)}
        buttons={buttons}
        isDealCapture={isDealCapture}
        canChartererManagedLiftings={props.canChartererManagedLiftings}
      />
      {confirmDialog && (
        <WithdrawLiftingModalConfirm onCancel={() => setConfirmDialog(false)} onConfirm={confirm} isSaving={isSaving} />
      )}
    </EditorFieldProvider>
  );
};
