import React, { useRef, useEffect } from "react";
import classNames from "classnames";
import { DataModel } from "@/models";
import { InputChangeEvent, InputProps } from "../../Input";
import { Container } from "../../Container";
import "./Textarea.scss";

export function TextareaInput(props: Props) {
  props = { ...props };

  props.className = classNames(props.className, "textarea");

  let value = props.value;

  if (value instanceof DataModel) value = value.toView();

  if (!value) value = "";

  const input = useRef<HTMLTextAreaElement | null>(null);

  useEffect(setInputRef, []);

  function setInputRef() {
    if (props.input) props.input.current = input.current;

    return unsetInputRef;
  }

  function unsetInputRef() {
    if (props.input) props.input.current = null;
  }

  function onChange(e: InputChangeEvent) {
    let next = e.currentTarget.value;

    // @ts-ignore
    if (props.value instanceof DataModel) next = new props.value.constructor(next);

    props.onChange?.(next);
  }

  return (
    <Container {...props}>
      <textarea
        value={value}
        onChange={onChange}
        placeholder={props.placeholder}
        disabled={props.disabled}
        ref={input}
        data-test={`${props.dataTest}.input`}
      />
    </Container>
  );
}

type Props = InputProps<Value, string>;

type Value = any;

export type TextareaInputProps = Props;
