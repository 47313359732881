import React, { useContext } from "react";
import { observer } from "mobx-react";
import { Button, Status } from "@/components";
import { usageMetrics, MainTermsTypeEvents } from "@/services/UsageMetrics";
import { Context } from "../../NegotiationDetail";
import "./MainTerms.scss";

function MainTerms() {
  const { negotiation } = useContext(Context);
  const { arcUrlWithCentralToken } = negotiation;
  const status = {
    type: "info",
    title: "Main Terms",
    icon: "on-subs",
  } as Status;

  return (
    <div className="MainTerms">
      <Status status={status} />
      <Button
        variant="action"
        href={arcUrlWithCentralToken}
        icon="file"
        data-test="open-main-terms"
        openHrefInNewWindow
        onClick={() => {
          usageMetrics.trackEvent(MainTermsTypeEvents.MAIN_TERMS_OPENED);
        }}
      >
        open Main Terms
      </Button>
    </div>
  );
}

const Observer = observer(MainTerms);

export { Observer as MainTerms };
