import { mapOrderNegType } from "sharedFolder/mappers/mapOrderNegType";
import { IOrder, IOrderDetails, OrderNegType, OrderNegApiType } from "./models";

const voyFields: Array<keyof IOrderDetails> = ["cargoSize", "dischargeLocation", "loadLocation", "voyageNotes"];

const coaFields: Array<keyof IOrderDetails> = [
  "cargoSize",
  "dischargeLocation",
  "loadLocation",
  "nominations",
  "liftings",
  "coaNotes",
];
const tctFields: Array<keyof IOrderDetails> = [
  "deliveryLocation",
  "viaLocation",
  "redeliveryLocation",
  "duration",
  "tctNotes",
  "vesselSize",
];

/**
 * Resolve the types associated with this order, based on
 * the presence of type-specific details
 * @param order Order API model
 */
export function typesFromOrder(order: IOrder): OrderNegType[] {
  let result: Array<{ type: OrderNegApiType; includes: boolean }> = [];
  if (order.details.originallySelectedTypes) {
    result = [
      {
        type: "Voy",
        includes: Boolean(order.details.originallySelectedTypes.includes("Voy" as OrderNegApiType)),
      },
      {
        type: "Tct",
        includes: Boolean(order.details.originallySelectedTypes.includes("Tct" as OrderNegApiType)),
      },
      {
        type: "Coa",
        includes: Boolean(order.details.originallySelectedTypes.includes("Coa" as OrderNegApiType)),
      },
    ];
  } else {
    result = [
      { type: "Voy", includes: voyFields.some((f) => Boolean(order.details[f])) },
      { type: "Tct", includes: tctFields.some((f) => Boolean(order.details[f])) },
      { type: "Coa", includes: coaFields.some((f) => Boolean(order.details[f])) },
    ];
  }
  return result
    .filter((r) => r.includes)
    .map((r) => r.type)
    .map(mapOrderNegType.toView);
}
