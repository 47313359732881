import { useState } from "react";
import { Role } from "./Role";

export const useNegotiationNote = () => {
  const [displayNote, setDisplayNote] = useState<boolean>(false);
  const [noteRole, setNoteRole] = useState<Role>("charterer");
  return {
    displayNote,
    setDisplayNote,
    noteRole,
    setNoteRole,
  };
};
