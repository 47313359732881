import * as api from "../../api/negotiations/models";
import { ILiftingsView } from "../Models/IDetails";
import { IMapper, withNullable } from "./map";

const map: IMapper<api.ILiftings, ILiftingsView> = {
  toApi: (liftings: ILiftingsView) => {
    return {
      min: liftings.min || 0,
      max: liftings.max || 0,
      dispersal: liftings.dispersal || "Adhoc",
      notes: liftings.notes || "",
      display: liftings.display || "",
    };
  },
  toView: (liftings: api.ILiftings) => {
    return {
      min: liftings.min,
      max: liftings.max,
      dispersal: liftings.dispersal,
      display: liftings.display,
      notes: liftings.notes,
    };
  },
  emptyViewModel: {},
};

export const mapLiftings = withNullable(map);
