import React, { Component } from "react";
import { IMinimalConfigurationContext, MinimalConfigurationContext } from "__legacy/sharedFolder/MinimalConfigurationContext";
import { CargoTypeEditor } from "sharedFolder/components/editors/CargoTypeEditor/CargoTypeEditor";
import { CommissionEditor } from "../common/Editors/Commission/Commission";
import { ICommissionView, ICargoTypeView, ILaycanView, IAccountView } from "sharedFolder/Models/IDetails";
import { IOrderITOCreate, INegotiableViewOptional, INegotiableView } from "../../models/NegotiationCreateView";
import { CreateNegotiationRow } from "./CreateNegotiationRow";
import { commissionText, cargoTypeText } from "../../services/client/SummaryService";
import styles from "./OrderDetails.module.scss";
import { CreateNegotiationSectionHeader } from "./CreateNegotiationSectionHeader";
import { Excluded } from "./Excluded";
import { ValidationContext } from "sharedFolder/contexts/ValidationContext";
import { withContext } from "sharedFolder/contexts/withContext";
import { ChartererSearch } from "sharedFolder/components/common/ChartererSearch/ChartererSearch";
import LaycanEditor from "sharedFolder/components/editors/LaycanEditor/LaycanEditor";
import { Laycan } from "@/models";

interface IOrderDetailsProps {
  className?: string;
  orderId?: string;
  orderData: IOrderITOCreate;
  ctradeUrl?: string;
  onOrderChanged: (newValue: IOrderITOCreate) => void;
}

interface IOrderDetailsState {
  expandedItem: keyof IOrderITOCreate | null;
}
class AccountRow extends CreateNegotiationRow<INegotiableViewOptional<IAccountView>, IOrderITOCreate> {}
class CargoTypeRow extends CreateNegotiationRow<INegotiableViewOptional<ICargoTypeView>, IOrderITOCreate> {}
class CommissionRow extends CreateNegotiationRow<INegotiableViewOptional<ICommissionView>, IOrderITOCreate> {}
class LaycanRow extends CreateNegotiationRow<INegotiableView<ILaycanView>, IOrderITOCreate> {}
class ExcludedOrderItems extends Excluded<IOrderITOCreate> {}

/**
 * Placeholder for Order Details widget
 */
class OrderDetailsComponent extends Component<IOrderDetailsProps & IMinimalConfigurationContext, IOrderDetailsState> {
  constructor(props: IOrderDetailsProps & IMinimalConfigurationContext) {
    super(props);

    this.state = {
      expandedItem: null,
    };

    this.handleCargoTypeChanged = this.handleCargoTypeChanged.bind(this);
    this.handleCargoTypeValueChanged = this.handleCargoTypeValueChanged.bind(this);
    this.handleFocusRow = this.handleFocusRow.bind(this);
    this.handleChartererAccountChanged = this.handleChartererAccountChanged.bind(this);
    this.handleChartererAccountValueChanged = this.handleChartererAccountValueChanged.bind(this);
    this.handleAddressCommissionChanged = this.handleAddressCommissionChanged.bind(this);
    this.handleAddressCommissionValueChanged = this.handleAddressCommissionValueChanged.bind(this);
    this.handleBrokerCommissionValueChanged = this.handleBrokerCommissionValueChanged.bind(this);
    this.handleBrokerCommissionChanged = this.handleBrokerCommissionChanged.bind(this);
    this.handleLaycanChanged = this.handleLaycanChanged.bind(this);
    this.handleIncludeDetail = this.handleIncludeDetail.bind(this);
  }

  public render() {
    const excludedItems: Array<{
      key: keyof IOrderITOCreate;
      included?: boolean;
      label: string;
    }> = [
      {
        key: "addressCommission",
        included: this.props.orderData.addressCommission.included,
        label: "Address Commission",
      },
      {
        key: "brokerCommission",
        included: this.props.orderData.brokerCommission.included,
        label: "Broker Commission",
      },
      {
        key: "cargoType",
        included: this.props.orderData.cargoType.included,
        label: "Cargo Type(s)",
      },
    ];

    if (this.props.orderData.laycan.value.to && this.props.orderData.laycan.value.from) {
      this.props.orderData.laycan.value = {
        ...this.props.orderData.laycan.value,
        display: Laycan.prototype.toView.call(this.props.orderData.laycan.value),
        shortDisplay: Laycan.prototype.toView.call(this.props.orderData.laycan.value),
      };
    }

    return (
      <ValidationContext.Consumer>
        {(validation) => (
          <>
            <CreateNegotiationSectionHeader header="Order Terms" />
            <div className={styles.details}>
              <AccountRow
                label="Charterer"
                id="chartererAccount"
                expanded={this.state.expandedItem === "chartererAccount"}
                onExpand={this.handleFocusRow}
                value={{
                  ...this.props.orderData.chartererAccount,
                  included: true,
                }}
                onValueChanged={this.handleChartererAccountChanged}
                text={this.props.orderData.chartererAccount.value?.display}
                required={true}
                {...validation}
              >
                <ChartererSearch
                  id="chartererAccount"
                  baseUrl={this.props.ctradeUrl}
                  onChange={this.handleChartererAccountValueChanged}
                  selectedItem={this.props.orderData.chartererAccount.value}
                  focus={this.state.expandedItem === "chartererAccount"}
                />
              </AccountRow>
              <LaycanRow
                label="Laycan"
                id="laycan"
                expanded={this.state.expandedItem === "laycan"}
                onExpand={this.handleFocusRow}
                value={this.props.orderData.laycan}
                onValueChanged={this.handleLaycanChanged}
                text={this.props.orderData.laycan.value.display}
                required={true}
                {...validation}
              >
                <LaycanEditor
                  id="laycanValue"
                  required={true}
                  url={this.props.ctradeUrl}
                  value={this.props.orderData.laycan.value}
                  onChange={(laycan) => this.handleLaycanValueChanged(laycan)}
                  focus={this.state.expandedItem === "laycan"}
                />
              </LaycanRow>
              <CargoTypeRow
                label="Cargo Type(s)"
                id="cargoType"
                expanded={this.state.expandedItem === "cargoType"}
                onExpand={this.handleFocusRow}
                value={this.props.orderData.cargoType}
                text={this.props.orderData.cargoType.value && this.props.orderData.cargoType.value.display}
                onValueChanged={this.handleCargoTypeChanged}
                {...validation}
              >
                <CargoTypeEditor
                  id="cargoTypeValue"
                  required={!this.props.orderData.cargoType.negotiable}
                  onChange={(cargoType) => {
                    this.handleCargoTypeValueChanged(
                      cargoType !== undefined
                        ? {
                            ...cargoType,
                            display: cargoTypeText(cargoType),
                          }
                        : undefined
                    );
                  }}
                  selectedItem={this.props.orderData.cargoType.value}
                  label="Cargo Type(s)"
                  focus={this.state.expandedItem === "cargoType"}
                />
              </CargoTypeRow>
              <CommissionRow
                label="Address Commission"
                id="addressCommission"
                expanded={this.state.expandedItem === "addressCommission"}
                onExpand={this.handleFocusRow}
                value={this.props.orderData.addressCommission}
                onValueChanged={this.handleAddressCommissionChanged}
                text={this.props.orderData.addressCommission.value && this.props.orderData.addressCommission.value.display}
                {...validation}
              >
                <CommissionEditor
                  id="addressCommissionValue"
                  label="Address Commission"
                  required={!this.props.orderData.addressCommission.negotiable}
                  value={
                    this.props.orderData.addressCommission.value ? this.props.orderData.addressCommission.value.value : undefined
                  }
                  onChange={this.handleAddressCommissionValueChanged}
                  focus={this.state.expandedItem === "addressCommission"}
                />
              </CommissionRow>
              <CommissionRow
                label="Broker Commission"
                id="brokerCommission"
                expanded={this.state.expandedItem === "brokerCommission"}
                onExpand={this.handleFocusRow}
                value={this.props.orderData.brokerCommission}
                onValueChanged={this.handleBrokerCommissionChanged}
                text={this.props.orderData.brokerCommission.value && this.props.orderData.brokerCommission.value.display}
                {...validation}
              >
                <CommissionEditor
                  id="brokerCommissionValue"
                  label="Broker Commission"
                  required={!this.props.orderData.brokerCommission.negotiable}
                  value={
                    this.props.orderData.brokerCommission.value ? this.props.orderData.brokerCommission.value.value : undefined
                  }
                  onChange={this.handleBrokerCommissionValueChanged}
                  focus={this.state.expandedItem === "brokerCommission"}
                />
              </CommissionRow>
              <ExcludedOrderItems items={excludedItems} onInclude={this.handleIncludeDetail} />
            </div>
          </>
        )}
      </ValidationContext.Consumer>
    );
  }
  private handleCargoTypeChanged(value: INegotiableViewOptional<ICargoTypeView>) {
    this.props.onOrderChanged({
      ...this.props.orderData,
      cargoType: {
        ...value,
      },
    });
  }

  private handleCargoTypeValueChanged(value?: ICargoTypeView) {
    this.handleCargoTypeChanged({
      ...this.props.orderData.cargoType,
      value: value,
    });
  }

  private handleChartererAccountChanged(value: INegotiableViewOptional<IAccountView>) {
    this.props.onOrderChanged({
      ...this.props.orderData,
      chartererAccount: {
        ...value,
      },
    });
  }

  private handleChartererAccountValueChanged(value: IAccountView | undefined) {
    this.handleChartererAccountChanged({
      ...this.props.orderData.chartererAccount,
      value,
    });
  }

  private handleAddressCommissionValueChanged(commission: ICommissionView) {
    this.handleAddressCommissionChanged({
      ...this.props.orderData.addressCommission,
      value: {
        value: commission.value,
        display: commission.value ? commissionText(commission) : undefined,
      },
    });
  }

  private handleAddressCommissionChanged(commission: INegotiableViewOptional<ICommissionView>) {
    this.props.onOrderChanged({
      ...this.props.orderData,
      addressCommission: {
        ...this.props.orderData.addressCommission,
        ...commission,
      },
    });
  }

  private handleBrokerCommissionValueChanged(commission: ICommissionView) {
    this.handleBrokerCommissionChanged({
      ...this.props.orderData.brokerCommission,
      value: {
        value: commission.value,
        display: commission.value ? commissionText(commission) : undefined,
      },
    });
  }

  private handleBrokerCommissionChanged(commission: INegotiableViewOptional<ICommissionView>) {
    this.props.onOrderChanged({
      ...this.props.orderData,
      brokerCommission: {
        ...this.props.orderData.brokerCommission,
        ...commission,
      },
    });
  }

  private handleLaycanValueChanged(value: ILaycanView | undefined) {
    this.handleLaycanChanged({
      ...this.props.orderData.laycan,
      value: value
        ? {
            shortDisplay: value.shortDisplay,
            display: value.display,
            from: value.from,
            to: value.to,
          }
        : {
            shortDisplay: "",
            display: "",
            from: null,
            to: null,
          },
    });
  }

  private handleLaycanChanged(laycan: INegotiableView<ILaycanView>) {
    this.props.onOrderChanged({
      ...this.props.orderData,
      laycan: {
        ...laycan,
      },
    });
  }
  private handleFocusRow(key: keyof IOrderITOCreate | null) {
    this.setState({
      expandedItem: key,
    });
  }

  private handleIncludeDetail(key: keyof IOrderITOCreate) {
    const updatedOrderData = { ...this.props.orderData };
    updatedOrderData[key].included = true;
    this.props.onOrderChanged(updatedOrderData);
    // contract the expanded item upon restore
    if (this.state.expandedItem === key) {
      this.setState({
        expandedItem: null,
      });
    }
  }
}

//this is the Order Terms for ITO
export const OrderDetailsITO = withContext<IOrderDetailsProps, IMinimalConfigurationContext>(
  OrderDetailsComponent,
  MinimalConfigurationContext
);
