import { getNegotiationApiService } from "../../api/negotiations/Negotiations";
import { INegotiationFirmed } from "../../api/negotiations/models";

export function firmed(
  ctradeUrl: string,
  orderId: string,
  negId: string,
  expiresOn: string,
  updateToken: string,
  side: "bid" | "offer"
) {
  // take the current neg and apply the action
  const firmed = getNegotiationApiService(ctradeUrl, orderId).firmed;
  const body: INegotiationFirmed = {
    expiresOn,
    action: "firmed",
    updateToken,
  };
  return firmed(orderId, negId, body, side);
}
