export function permissionsFromJwt<T>(jwt: string): T[] {
  const parts = jwt.split(".");
  // validate
  if (parts.length !== 3) {
    // invalid JWT; no permissions
    console.error("Invalid JWT");
    return [];
  }
  // parse
  try {
    const payload: { permittedMethods?: T[] } = JSON.parse(atob(parts[1]));
    return payload.permittedMethods ? payload.permittedMethods : [];
  } catch (err) {
    console.error(err);
    return [];
  }
}
