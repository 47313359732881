import { IVesselForm } from "../Models/IVessel";
import { IVesselView } from "../Models/IDetails";

export const mapToVesselForm = (vesselForm: IVesselForm | undefined): IVesselView | undefined => {
  if (vesselForm && vesselForm.vessel) {
    const vessel = {
      ...vesselForm.vessel,
      ownerChain: vesselForm.ownerChain,
      speedAndConsumption: vesselForm.speedAndConsumption,
      vesselDescription: vesselForm.vesselDescription,
      additionalNotes: vesselForm.additionalNotes,
      itinerary: vesselForm.itinerary,
      eta: vesselForm.eta,
    };
    return vessel;
  }
};
