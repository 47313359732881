import { tradeAPI } from "@/apis";
import { auth, dialog } from "@/models";
import { firstLine, secondLine } from "offerRep/DisclaimerModal/DisclaimerModal";
import React from "react";
import { TokenStore } from "services/TokenStore";

const tokenStoreKey = "OfferRepDisclaimerAgreed";
const getDisclaimerAgreement = async (setDisclaimerAsAgreed: () => Promise<boolean>): Promise<boolean> => {
  const { res: confirmed } = await dialog.show({
    status: {
      type: "confirm",
      title: "Confirm",
      message: (
        <div className="disclaimer-circulate-confirmation-message">
          <p>{firstLine}</p>
          <p>{secondLine}</p>
        </div>
      ),
    },
    dataTest: "disclaimer-circulate-confirm",
  });
  if (confirmed) {
    return setDisclaimerAsAgreed();
  }
  return false;
};

export function useDismissableDisclaimer() {
  const user = auth.trade.user;
  const hasUserAlreadyAgreed = (): boolean => {
    return user?.HasConfirmedDisclaimer || false;
  };

  const setDisclaimerAgreed = async (): Promise<boolean> => {
    const result = await tradeAPI.disclaimerConfirm({ disclaimer: `${firstLine} ${secondLine}` });
    // refresh the token in local storage so that it has the latest values
    await auth.trade.refresh();
    return result.ok;
  };

  return {
    hasUserAlreadyAgreed,
    getDisclaimerAgreement,
    setDisclaimerAgreed,
  };
}
export function useDismissableDisclaimerAnoymousUser() {
  const hasUserAlreadyAgreed = (): boolean => {
    const store = new TokenStore(tokenStoreKey);
    const isAlreadyAgreed = store.get() === "AGREED";
    return isAlreadyAgreed;
  };

  const setDisclaimerAgreed = async (offerRepToken: string, updateToken: string): Promise<boolean> => {
    const result = await tradeAPI.disclaimerConfirmAnoymousUser(offerRepToken, {
      disclaimer: `${firstLine} ${secondLine}`,
      updateToken,
    });
    if (result.ok) {
      const store = new TokenStore(tokenStoreKey);
      store.set("AGREED");
      return true;
    }
    return false;
  };

  return {
    hasUserAlreadyAgreed,
    getDisclaimerAgreement,
    setDisclaimerAgreed,
  };
}
