import { object, string } from "yup";
import { Account } from "../Account";

// https://github.com/jquense/yup
const schema = object({
  accountId: string().required("Required"),
  accountName: string().required("Required"),
  arcContactId: string().required("Required"),
  gainAccountId: string().required("Required"),
  gainAccountGroupId: string().nullable(),
}).transform(toJSON);

function toJSON(account: Account) {
  return account?.toJSON();
}

export { schema };
