import React from "react";
import { FieldProps as FullFieldProps } from "../../Field";
import { Container } from "../../Container";
import { TextInput, TextInputProps } from "@/components";

export function TextField(props: TextFieldProps) {
  const { className, desc, ...rest } = props;

  return (
    <Container {...rest} className={className} desc={desc}>
      <TextInput {...rest} />
    </Container>
  );
}

export interface TextFieldProps extends FieldProps, TextInputProps {}

type FieldProps = Omit<FullFieldProps, "value" | "onChange">;
