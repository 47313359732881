import React, { useReducer, useEffect } from "react";
import { DismissableNotification } from "../DismissableNotification/DismissableNotification";
import { storage } from "../../../storage";
import { BackgroundColorType, IconTypes } from "sharedFolder/components/common/types";
import { IMessageState } from "./types";
import { CirculatedByRole } from "negotiations/components/negotiate/Negotiation/Role";

const initialState: IMessageState = {
  colour: null,
  title: undefined,
  icon: "flag" as IconTypes,
  open: false,
  body: undefined,
};

export function Messages<T>(props: IMessageProps<T>) {
  const { message, messageReducer, negotiationId, onOpenStateChanged, circulatedByRole } = props;

  const [state, dispatch] = useReducer(messageReducer, {
    ...initialState,
    circulatedByRole,
  });
  const storageKey = `negotiation.${negotiationId}.banners`;
  const { closedBanner } = storage.local.get(storageKey, {});

  useEffect(() => {
    if (message && closedBanner !== message) {
      dispatch({ type: message });
    }
  }, [message, closedBanner]);

  useEffect(() => {
    onOpenStateChanged && onOpenStateChanged(state.open);
  }, [state.open, onOpenStateChanged]);

  if (!state.open) return null;

  return (
    <div data-test="Messages">
      <DismissableNotification
        onClose={() => {
          dispatch({ type: "Close" });

          storage.local.set(storageKey, { closedBanner: message });
        }}
        backgroundColor={state.colour as BackgroundColorType}
        title={state.title}
        icon={state.icon}
        body={state.body}
        aria-label="Messages"
      />
    </div>
  );
}

interface IMessageProps<T> {
  message: T | undefined;
  negotiationId: string;
  onOpenStateChanged?: (isOpen: boolean) => void;
  circulatedByRole?: CirculatedByRole;
  messageReducer: (
    state: IMessageState,
    action: {
      type: T | "Close";
    }
  ) => IMessageState;
}
