export interface IFetchReducerState {
  fetching: boolean;
  response: null | any;
  error?: null | boolean;
}

export interface IFetchReducerAction {
  type: "fetching" | "fetched" | "error";
  response?: null | any;
  error?: null | boolean;
}

export const fetchReducer: React.Reducer<IFetchReducerState, IFetchReducerAction> = (state, action) => {
  const { type, response, error } = action;
  switch (type) {
    case "fetching": {
      return { fetching: true, response: null, error: null };
    }
    case "fetched": {
      return { fetching: false, response, error: null };
    }
    case "error": {
      return { fetching: false, response: null, error };
    }
    default:
      throw new Error(`Unsupported type: ${type}`);
  }
};
