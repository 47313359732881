import { WithdrawalState } from "@/apis";
import { Tabs } from "@/components";
import { ObjectContext, WithdrawBanner } from "@/components/common/WithdrawBanner";
import "./Header.scss";

export function Header(props: Props) {
  return (
    <header className="OrderHeader">
      <WithdrawBanner {...props} />
      <Tabs />
    </header>
  );
}

interface Props {
  status?: string;
  withdrawalState?: WithdrawalState;
  ribbonContext: ObjectContext;
}
