import { DataModel, DataModelProps } from "../DataModel";

class PrimitiveDataModelProps extends DataModelProps {}

class PrimitiveDataModel extends DataModel {
  constructor(data?: Primitive, props?) {
    super({ data }, props);
  }

  toString() {
    return String(this.data);
  }

  toJSON() {
    return this.data;
  }

  static Props = PrimitiveDataModelProps;
}

PrimitiveDataModel.prototype.Props = PrimitiveDataModelProps;
PrimitiveDataModelProps.prototype.sortKey = "data";

export { PrimitiveDataModel, PrimitiveDataModelProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface PrimitiveDataModel {
  Props: typeof PrimitiveDataModelProps;
  _: PrimitiveDataModelProps;

  data: Primitive;
}

declare namespace PrimitiveDataModel {
  type Props = DataModelProps;
}

interface PrimitiveDataModelProps extends PrimitiveDataModel.Props {}
