import { makeObservable, computed, observable } from "mobx";
import { FormikContextType } from "formik";
import { isEqual } from "lodash-es";

export class FormContext {
  constructor() {
    this.formik = null;

    makeObservable(this, {
      formik: observable.ref,
      values: computed,
      isChanged: computed,
      isValid: computed,
    });
  }

  get isChanged() {
    return !isEqual(this.formik?.initialValues, this.formik?.values);
  }

  get isValid() {
    return this.formik?.isValid;
  }

  get values() {
    return this.formik?.values;
  }

  submit = () => {
    return this.formik?.submitForm();
  };

  reset = () => {
    return this.formik?.resetForm();
  };
}

export interface FormContext<Values = any> {
  formik: FormikContextType<Values> | null;
}
