import { INegotiationDetailsView } from "sharedFolder/Models/Negotiation/INegotiationView";
import { OrderNegType } from "sharedFolder/Models/OrderNegType";

export type NegotiableCommonDetailType = "addressCommission" | "brokerCommission" | "laycan" | "cargoType";

export type TctSpecificType =
  | "vesselSize"
  | "hireRate"
  | "cleaningPrice"
  | "supplyPrice"
  | "tradingExclusions"
  | "bunkerDelivery"
  | "bunkerRedelivery"
  | "cargoExclusionsText"
  | "ballastBonus"
  | "duration"
  | "deliveryLocation"
  | "redeliveryLocation"
  | "viaLocation"
  | "commercialSubs"
  | "operationalSubs";

export type VoySpecificType =
  | "cargoSize"
  | "freightRate"
  | "demurrage"
  | "loadLocation"
  | "dischargeLocation"
  | "commercialSubs"
  | "operationalSubs";

export type CoaSpecificType = "period" | "liftings" | "nominations" | "coaCargoSize";

export type VoyDetailType = NegotiableCommonDetailType | VoySpecificType;
export type TctDetailType = NegotiableCommonDetailType | TctSpecificType;
export type CombinedDetailType = VoyDetailType | TctDetailType;

export type DetailType = keyof INegotiationDetailsView;

export const detailTypeDisplay = (unit: keyof INegotiationDetailsView, orderNegType?: OrderNegType): string => {
  const dischargeLocation = orderNegType && orderNegType === "COA" ? "Discharge Location(s)" : "Discharge Location";

  const loadLocation = orderNegType && orderNegType === "COA" ? "Load Location(s)" : "Load Location";

  const notes = orderNegType && orderNegType === "LFT" ? "Additional notes" : "Order Notes";

  const mapping: { [key in keyof INegotiationDetailsView]: string } = {
    chartererAccount: "Charterer",
    freightRate: "Freight Rate",
    demurrage: "Demurrage",
    hireRate: "Hire Rate",
    cleaningPrice: "ILOHC",
    supplyPrice: "CVE",
    tradingExclusions: "Trading Exclusions",
    bunkerDelivery: "Bunker Delivery",
    bunkerRedelivery: "Bunker Re-Delivery",
    cargoExclusionsText: "Cargo Exclusions",
    ballastBonus: "Ballast Bonus",
    loadLocation,
    dischargeLocation,
    deliveryLocation: "Delivery Location",
    viaLocation: "Via Location",
    redeliveryLocation: "Redelivery Location",
    laycan: "Laycan",
    period: "Period",
    nominations: "Nominations",
    liftings: "Liftings",
    cargoType: "Cargo Type(s)",
    cargoSize: "Cargo Size(s)",
    coaCargoSize: "Cargo Size(s)",
    duration: "Duration",
    addressCommission: "Address Commission",
    brokerCommission: "Broker Commission",
    vesselSize: "Vessel Size",
    notes,
    offerRepNegotiationNotes: "Offer Rep Notes",
    controllerNegotiationNotes: "Controller Negotiation Notes",
    vessels: "Vessels",
    commercialSubs: "Commercial Subs",
    operationalSubs: "Operational Subs",
  };

  return mapping[unit] || "";
};
