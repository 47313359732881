import * as React from "react";
import { IValidationContext } from "sharedFolder/contexts/ValidationContext";
import { withValidationContext } from "sharedFolder/contexts/withValidationContext";
import { INominationsEditorProps, NewNominationsEditor } from "../NewNominationsEditor/NewNominationsEditor";
import { INominationsView, nominationsDisplay } from "sharedFolder/Models/IDetails";

interface INominationsEditorWithValidationProps extends INominationsEditorProps {
  id: string;
}

class NominationsEditorComponent extends React.Component<INominationsEditorWithValidationProps & IValidationContext, {}> {
  constructor(props: INominationsEditorWithValidationProps & IValidationContext) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  private onChange(nominationsView: INominationsView) {
    const view = {
      ...nominationsView,
      display: nominationsDisplay(nominationsView),
    };

    if (view) {
      this.props.onChange(view);
    }
  }

  render() {
    return <NewNominationsEditor {...this.props} onChange={this.onChange} />;
  }
}

export const NominationsEditor = withValidationContext(NominationsEditorComponent);
