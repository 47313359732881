import React from "react";
import { NumberInput, NumberInputProps } from "@/components";
import { FieldProps as FullFieldProps } from "../../Field";
import { Container } from "../../Container";

export function NumberField(props: NumberFieldProps) {
  const { className, desc, onKeyDown, ...rest } = props;

  return (
    <Container {...rest} className={className} desc={desc}>
      <NumberInput {...rest} onKeyDown={onKeyDown} />
    </Container>
  );
}

export interface NumberFieldProps extends FieldProps, NumberInputProps {}

type FieldProps = Omit<FullFieldProps, "value" | "onChange">;
