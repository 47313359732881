import React from "react";
import classNames from "classnames";
import * as ClassNames from "classnames";
import "./Container.scss";

function Container(props: Container.Props) {
  let { className, ...rest } = props;

  className = classNames(className, "app-form");

  return <form className={className} {...rest} />;
}

export { Container };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace Container {
  export interface Props extends Omit<ReactHTMLFormProps, "className"> {
    className?: ClassNames.Argument;
  }

  type ReactHTMLFormProps = React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>;
}
