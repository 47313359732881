import * as React from "react";
import classnames from "classnames";
import { ILoadingSpinnerProps } from "sharedFolder/components/common/interfaces";
import styles from "./LoadingSpinner.module.scss";

const theStripe = (key: string) => (
  <div className={styles.stripe} key={key}>
    <div className={styles.stripeInner}>&nbsp;</div>
  </div>
);

const LoadingSpinner: React.SFC<ILoadingSpinnerProps> = ({ additionalCss, loadingText }) => (
  <div data-test="LoadingSpinner">
    <div className={classnames(styles.stripedLoader, additionalCss)}>
      {Array.from({ length: 5 }, (_, i) => i).map((key) => theStripe(`stripe${key}`))}
    </div>
    {loadingText && <div className={styles.loadingTextContainer}>{loadingText}</div>}
  </div>
);

export default LoadingSpinner;
