import React from "react";
import { VesselSize, VesselSizeProps } from "___REFACTOR___/models";
import { TextareaField, SelectField, Field } from "___REFACTOR___/components";
import { Group } from "../../Group";
import "./VesselSize.scss";

function VesselSizeEditor(props: VesselSizeEditor.Props) {
  const { value, status, dataTest, hidden } = props;

  return (
    <Group {...props} editorType="VesselSize" hidden={hidden}>
      <SelectField
        {...props}
        data={VesselSizeProps.prototype.vesselSizeArray}
        noDataStatus={noDataStatus}
        dataTest={`${dataTest}.value`}
      />
      <TextareaField
        {...props}
        label="Notes"
        desc="Vessel size notes"
        value={value?.notes || ""}
        onChange={onNotesChange}
        status={status?.childMap?.notes}
        dataTest={`${dataTest}.notes`}
        hidden={!value}
      />
    </Group>
  );

  function onNotesChange(notes: TextareaField.Input.Value) {
    if (typeof notes !== "string") return;

    props.onChange?.(new VesselSize({ ...value, notes }));
  }
}

const noDataStatus = { message: "No matching vessel sizes" };

export { VesselSizeEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace VesselSizeEditor {
  export interface Props extends Field.Props {
    value: Value;
    onChange: (value: Value) => void;
  }

  export type Value = VesselSize | undefined;
}
