import { IVesselRejectUpdate, IResponse } from "../../api/negotiations/models";
import { Observable } from "rxjs";

export function rejectVessel(
  ctradeUrl: string,
  orderId: string,
  negId: string,
  updateToken: string,
  imo: string,
  updater: (orderId: string, negotiationId: string, detail: IVesselRejectUpdate, imo: string) => Observable<IResponse>
) {
  const body: IVesselRejectUpdate = {
    action: "reject",
    updateToken,
  };
  return updater(orderId, negId, body, imo);
}
