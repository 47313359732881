import React from "react";
import "./Spinner.scss";

function Spinner(props: Spinner.Props) {
  const { visible } = props;

  if (!visible) return null;

  return (
    <app-button-spinner-overlay>
      <app-button-spinner>
        <stripe-outer>
          <stripe-inner />
        </stripe-outer>
        <stripe-outer>
          <stripe-inner />
        </stripe-outer>
        <stripe-outer>
          <stripe-inner />
        </stripe-outer>
        <stripe-outer>
          <stripe-inner />
        </stripe-outer>
        <stripe-outer>
          <stripe-inner />
        </stripe-outer>
      </app-button-spinner>
    </app-button-spinner-overlay>
  );
}

export { Spinner };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace Spinner {
  export interface Props {
    hidden?: boolean;
    visible?: boolean;
  }
}
