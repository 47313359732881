import React from "react";
import styles from "./NegotiableItemContainer.module.scss";
import { NegotiationSide } from "sharedFolder/Models/Direction";
import { CirculatedByRole, CirculatedByRoleDescription } from "negotiations/components/negotiate/Negotiation/Role";

export const NegotiableItemContainer: React.SFC<{
  lastUpdated: NegotiationSide | null;
  circulatedByRole?: CirculatedByRole;
  children: JSX.Element;
  typeTitle: string;
  hideDirectionalMessage?: boolean;
}> = (props) => {
  return (
    <div className={styles.detail} arial-label="Negotiable Detail">
      <div className={styles.type} aria-label="Detail type">
        {props.typeTitle}
        <span className={styles.termCaption}>
          {!props.hideDirectionalMessage &&
            props.lastUpdated === NegotiationSide.owner &&
            `It's the ${CirculatedByRoleDescription(props.circulatedByRole)}'s turn to respond`}

          {!props.hideDirectionalMessage &&
            props.lastUpdated === NegotiationSide.charterer &&
            `The ${CirculatedByRoleDescription(props.circulatedByRole)} has sent you a bid** - it's your turn to respond`}
        </span>
      </div>
      <div className={styles.content} aria-label="Detail Content">
        {props.children}
      </div>
    </div>
  );
};
