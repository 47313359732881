import React from "react";
import classNames from "classnames";
import { Group } from "../../Group";
import { AccountEditor, AccountEditorProps } from "../Account";
import { Icon } from "@/components";
import "./OwnerAccount.scss";

export function OwnerAccountEditor(props: Props) {
  props = { ...props };

  props.className = classNames(props.className, "OwnerAccount");

  const { className, ...rest } = props;

  return (
    <Group className={className}>
      <AccountEditor {...rest} searchMessages={searchMessages} placeholder={placeholder} legalEntitiesOnly />
      <div className="OwnerAccount-info">
        <Icon icon="help" />
        Can&apos;t find the right entity? Search for and select &apos;TBN&apos; for now and let us know the details of the entity
        by clicking{" "}
        <a href="mailto:support@sea.live?subject=Please%20add%20this%20owner%20entity&amp;body=Hi%2C%0A%0AI%20tried%20to%20use%20the%20below%20entity%20in%20Sea%2Ftrade%20but%20could%20not%20find%20it.%20Could%20you%20please%20add%20them%20to%20your%20system.%0A%0AClient%20full%20name%3A%20XXXX%0ARegistered%20Address%3A%20XXXX%0ATelephone%20Number%3A%20XXXX%0AEmail%20Address%3A%20XXXX%0A%20%20">
          here
        </a>
      </div>
    </Group>
  );
}

const placeholder = "Search for entities";

const searchMessages = {
  initial: "Waiting for your input",
  searching: "Searching for companies",
  noData: "No companies found",
} as Props["searchMessages"];

type Props = AccountEditorProps;

export type OwnerAccountEditorProps = Props;
