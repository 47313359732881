import { Observable, defer, from } from "rxjs";
import { ICommissionView, ILaycanView, IAccountView, ICargoTypeView } from "sharedFolder/Models/IDetails";
import { processFileUploadResponses, UploadAttachmentResult } from "__legacy/negotiations/services/attachments";
import { IVessel } from "../../api/models/IVessel";

const commonHeaders = {
  "X-API-Version": "2",
};
export interface IUpdateResponse {
  id: string;
  version: string;
}

export interface IUpdateResponseWithDetailType {
  id: string;
  version: string;
}

export type valueTypes = ICommissionView | ILaycanView | IAccountView | ICargoTypeView;
export interface IDetailType {
  [k: string]: { value: valueTypes };
}

export interface IMergedPost {
  vessels: IVessel[];
  detail?: IDetailType;
  expiresOn?: string;
  account: {
    accountName: string;
  };
  action: "indicated" | "firmed";
}
interface IFirmAccepted {
  action: "firmAccepted";
  updateToken: string;
}

export function offerRepOfferDetail(
  baseUrl: string,
  data: IMergedPost | IFirmAccepted
): Observable<IUpdateResponseWithDetailType> {
  return defer(async () => {
    const result = await fetch(`${baseUrl}/all`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (!result.ok) {
      throw new Error("Unable to accept detail");
    }
    const json = await result.json();
    return { id: json.id, version: json.version };
  });
}

export function offerRepSendOfferRepNote(baseUrl: string, data: any): Observable<IUpdateResponse> {
  const url = `${baseUrl}/note`;

  return defer(async () => {
    const result = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!result.ok) {
      throw new Error("Unable to accept note");
    }
    const json = await result.json();
    return { id: json.id, version: json.version };
  });
}

export const uploadOfferRepAttachment =
  (offerRepToken: string) =>
  (baseUrl: string, formData: FormData, updateToken: string): Observable<UploadAttachmentResult> => {
    return from(
      fetch(`${baseUrl}/offerrep/${offerRepToken}/files`, {
        method: "POST",
        headers: {
          ...commonHeaders,
          "x-entity-authorization": `Bearer ${updateToken}`,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((response) => processFileUploadResponses(response))
        .catch((err) => {
          console.error("an error occured while creating the attachment", err);
          return new UploadAttachmentResult(false);
        })
    );
  };

export const deleteOfferRepAttachment =
  (offerRepToken: string) =>
  (baseUrl: string, fileId: string, updateToken: string): Observable<{ success: boolean }> => {
    return from(
      fetch(`${baseUrl}/offerrep/${offerRepToken}/files/${fileId}`, {
        method: "DELETE",
        headers: {
          ...commonHeaders,
          "x-entity-authorization": `Bearer ${updateToken}`,
        },
      })
        .then((response) => response.json())
        .then(() => {
          return {
            success: true,
          };
        })
        .catch((err) => {
          console.error("an error occured while delete the attachment", err);
          return {
            success: false,
          };
        })
    );
  };
