import moment from "moment";

export function getNow(): moment.Moment {
  return moment();
}

export function formatDateWithTime(timestamp: string): string {
  if (!timestamp) {
    return "";
  }
  return `${moment(timestamp).utc().format("DD MMM YYYY HH:mm")} GMT/UTC`;
}
