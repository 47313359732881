import React, { useRef, useState } from "react";
import { useShallowStateOld, emptyFn } from "@/utils";
import { tradeAPI } from "@/apis";
import { CustomDate, Vessel } from "@/models";
import {
  VesselButton,
  SelectField,
  Button,
  SelectFieldProps,
  InputFocusEvent,
  TextareaField,
  DateField,
  DateFieldProps,
  StatusIcon,
  statusMap,
} from "@/components";
import { Group } from "../../Group";
import { Row } from "../../Row";
import "./Vessel.scss";

export function VesselEditor(props: Props) {
  props = { ...props };

  props.className = `${props.className} Vessel`;

  const { searchMessages = defaultSearchMessages, className, value, status, dataTest, ...rest } = props;
  const [data, setData] = useState<SelectFieldProps["data"]>(undefined);
  const [, setDropdownStatus, dropdownStatus] = useShallowStateOld<Status>({
    message: searchMessages?.initial,
  });

  const cancel = useRef(emptyFn);
  const isVesselSelected = value?.registrationDataName;
  const vesselStatus = value?.status && statusMap[value?.status];

  async function onFilter(search: string) {
    cancel.current();

    setData(undefined);
    setDropdownStatus({ message: undefined, loading: false, search });

    if (!search) {
      setDropdownStatus({ message: searchMessages?.initial });

      return;
    }

    setDropdownStatus({ message: searchMessages?.searching, loading: true });

    const promise = tradeAPI.searchVessels(search);

    cancel.current = promise.cancel;

    const res = await promise;

    if (res.cancelled) return;

    if (!res.data || !res.data.length) {
      setData(undefined);
      setDropdownStatus({ message: searchMessages?.noData, loading: false });

      return;
    }

    const data = res.data.map((el) => new Vessel(el));

    setData(data);
    setDropdownStatus({ message: undefined, loading: false });
  }

  function onChange(key: keyof Vessel, propertyValue) {
    props.onChange?.(new Vessel({ ...value, [key]: propertyValue }));
  }

  function onEtaChange(eta: DateFieldProps["value"]) {
    if (eta instanceof CustomDate) {
      eta = eta.toString();
    }

    props.onChange?.(new Vessel({ ...value, eta }));
  }

  function onBlur(e: InputFocusEvent) {
    cancel.current();

    setData(undefined);
    setDropdownStatus({
      message: searchMessages?.initial,
      loading: false,
    });

    props.onBlur && props.onBlur(e);
  }

  function onClear() {
    props.onChange?.(new Vessel());
  }

  return (
    <Group className={className}>
      <Row>
        <SelectField
          {...rest}
          onClear={onClear}
          label="Vessel"
          value={value}
          data={data}
          placeholder=""
          dropdownStatus={dropdownStatus}
          dropdownClassName="Vessel"
          onFilter={onFilter}
          onBlur={onBlur}
          dataTest={`${dataTest}.value`}
          className="vessel-selector"
        />
        {vesselStatus?.type === "success" && <StatusIcon status={vesselStatus} />}
        <DateField
          {...rest}
          disabled={!isVesselSelected}
          className="vessel-calendar"
          withoutTimezone
          label="ETA"
          value={value?.eta}
          onChange={onEtaChange}
          dataTest={`${dataTest}.eta`}
          status={status?.children?.eta}
        />
        {props.onRemove && <Button onClick={props.onRemove} containerClassName="vessel-delete-btn" icon="trash" type="button" />}
      </Row>
      <Row className="vessel-sea-buttons">
        {value && <VesselButton vessel={value} seaAnalyticsOrSeaNet="Net" />}
        {value && <VesselButton vessel={value} seaAnalyticsOrSeaNet="Analytics" />}
      </Row>
      <DateField
        {...rest}
        disabled={!isVesselSelected}
        className="vessel-calendar-responsive"
        withoutTimezone
        label="ETA"
        value={value?.eta}
        onChange={onEtaChange}
        dataTest={`${dataTest}.eta`}
        status={status?.children?.eta}
      />
      <TextareaField
        {...rest}
        value={value?.ownerChain}
        onChange={onChange.bind(null, "ownerChain")}
        label="Owner Chain"
        dataTest={`${dataTest}.ownerChain`}
        disabled={!isVesselSelected}
      />
      <TextareaField
        {...rest}
        value={value?.itinerary}
        onChange={onChange.bind(null, "itinerary")}
        label="Owner Itinerary"
        dataTest={`${dataTest}.itinerary`}
        disabled={!isVesselSelected}
      />
      <TextareaField
        {...rest}
        value={value?.speedAndConsumption}
        onChange={onChange.bind(null, "speedAndConsumption")}
        label="Speed & Consumption"
        dataTest={`${dataTest}.speedAndConsumption`}
        disabled={!isVesselSelected}
      />
      <TextareaField
        {...rest}
        value={value?.additionalNotes}
        onChange={onChange.bind(null, "additionalNotes")}
        label="Additional Notes"
        dataTest={`${dataTest}.additionalNotes`}
        disabled={!isVesselSelected}
      />
      <TextareaField
        {...rest}
        value={value?.vesselDescription}
        onChange={onChange.bind(null, "vesselDescription")}
        label="Vessel Description"
        dataTest={`${dataTest}.vesselDescription`}
        disabled={!isVesselSelected}
      />
    </Group>
  );
}

const defaultSearchMessages = {
  initial: "Waiting for your input",
  searching: "Searching for vessels",
  noData: "No vessels found",
} as Props["searchMessages"];

interface Props extends SelectFieldProps {
  onRemove?: () => void;
  value?: Vessel;
  onChange?: (value?: Props["value"]) => void;
  searchMessages?: {
    initial: string;
    searching: string;
    noData: string;
  };
}

export type VesselEditorProps = Props;
