import React, { useContext } from "react";
import { Negotiation } from "@/models";
import { SummaryDetails } from "@/components";
import { Context } from "../../Offerrep";

export function Summary() {
  const context = useContext(Context);

  return <NegotiationSummary negotiation={context.negotiation} />;
}

export function NegotiationSummary({ negotiation }: { negotiation?: Negotiation }) {
  const mainTermsDetails = negotiation?.isBeyondInitialTerms() && negotiation?.getMainTermsDetailsModelArray();
  const details = mainTermsDetails || negotiation?.getNonNegotiableInitialTermModelArray();

  return <SummaryDetails list={details} />;
}
