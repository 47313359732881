import React, { ReactNode } from "react";
import classNames from "classnames";
import { DataModel } from "@/models";
import "./SummaryDetails.scss";

export function SummaryDetails(props) {
  return (
    <div className={classNames("summary-details", props.skinny && "summary-details-skinny")} data-test="Summary-Details">
      {props.list?.map((detail: DataModel | Detail | undefined, index: number) => {
        if (!detail) return null;

        if (detail instanceof DataModel) {
          if (detail.toView() === undefined) {
            return null;
          }

          return (
            <div className="summary-details-row" key={index}>
              <span className="summary-details-label">{detail._.label}</span>
              <span className="summary-details-display">{detail.toView()}</span>
            </div>
          );
        }

        return (
          <div className="summary-details-row" key={index}>
            <span className="summary-details-label">{detail.label}</span>
            <span className="summary-details-display">{detail.display}</span>
          </div>
        );
      })}
    </div>
  );
}

interface Detail {
  label: string;
  display: ReactNode;
}
