import React from "react";
import { LiftingsCargoDetails } from "./LiftingsCargoDetails";
import { ILiftingsDisplayValues } from "../../../sharedFolder/Models/ILiftingsCollectionView";
import { useUser } from "__legacy/dashboard/contexts/UserProvider";

interface ILiftingsCargoDetailsProps {
  liftingId: string;
  liftingCargoId: string;
  updateToken: string;
  orderId: string;
  displayValues: ILiftingsDisplayValues;
  onDismiss: () => void;
  isDealCapture: boolean;
  canChartererManagedLiftings: boolean;
}

export const LiftingsCargoDetailsDisplayValues = (props: ILiftingsCargoDetailsProps) => {
  const { liftingId, liftingCargoId, updateToken, orderId, displayValues, onDismiss, isDealCapture } = props;

  const form = {
    cargoType: displayValues.cargoType,
    demurrage: displayValues.demurrage,
    freightRate: displayValues.freightRate,
    load: displayValues.loadLocation,
    discharge: displayValues.dischargeLocation,
  };
  return (
    <LiftingsCargoDetails
      liftingId={liftingId}
      liftingCargoId={liftingCargoId}
      updateToken={updateToken}
      orderId={orderId}
      liftingsDetails={form}
      isEditable={useUser().companyRoles.includes("charterer") && props.canChartererManagedLiftings ? false : true}
      onCancel={onDismiss}
      isDealCapture={isDealCapture}
      canChartererManagedLiftings={props.canChartererManagedLiftings}
    />
  );
};
