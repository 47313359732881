import { OrderNegApiType } from "sharedFolder/Models/OrderNegType";
import { INegSide } from "./INegSide";
import { IAccount } from "../../models/IAccount";
import { INegDetailSettings } from "./INegDetailSettings";
import { CirculatedByRole, Role } from "negotiations/components/negotiate/Negotiation/Role";
import { IAttachment } from "../../models/IAttachment";
import { IOrderNegActions } from "sharedFolder/Models/IOrderNegActions";
import { IGroupChat } from "../../orders/models/IGroupChat";
import { IVessel } from "../../models/IVessel";
import { OrderNegStatus } from "sharedFolder/Models/OrderNegStatus";
import { ISubsTerm } from "sharedFolder/Models/ISubsTerm";

export interface INegotiation {
  id: string;
  version: number;
  orderVersion: number;
  type: OrderNegApiType; // currently called "types"
  orderId: string; // missing from model
  orderReference: string; // missing from model
  invitee: string;
  updateToken?: string;
  createdOn: string;
  lastUpdated?: string; // missing from model
  notes: INegNotes;
  orderNotes: string;
  status: OrderNegStatus;
  vessels: IVessel[]; // missing from model
  charterers: string[]; // missing from model
  attachments: IAttachment[]; // missing from model
  orderAttachments?: IAttachment[]; // missing from model
  chartererAccount?: IAccount; // missing from model
  owningCompany?: IAccount;
  actions: IOrderNegActions;
  arcNegotiationHash?: string; // missingFromModel
  arcUrl?: string; // missing from model
  bid: INegSide;
  brokerGroupChatId?: string;
  chartererCompanyId?: string;
  circulatedBy?: string;
  circulatedByRole?: CirculatedByRole;
  detailSettings: INegDetailSettings;
  filterIndexes?: IFilterIndexes; // missing from model
  groupChat?: IGroupChat;
  hasBroker: boolean;
  hasCharterer: boolean;
  isOfferRepClaimed: boolean;
  inviteeSystemUserId?: number;
  lastUpdatedBy?: string; // missing from model
  liftingId?: string;
  liftingCargoId?: string;
  offer: INegSide;
  offerRepArcUrl?: string;
  offerRepToken: string;
  onSubsExpiresOn?: string;
  seaContractsUrl?: string;
  sortIndexes?: ISortFilter; // missing from model
  brokerEmailAddresses?: string[];
  chartererEmailAddresses?: string[];
  ownerEmailAddresses?: string[];
  coaNegotiationId?: string;
  commercialSubs?: ISubsTerm;
  operationalSubs?: ISubsTerm;
  operationalSubsLifted: boolean;
  commercialSubsLifted: boolean;
  isDealCapture?: boolean;
}

interface ISortFilter {
  laycan: number;
  invitee: string;
  createdDate: number;
  updatedDate: number;
}

interface IFilterIndexes {
  isExactLaycan: boolean;
  active: boolean;
}

export type INegNotes = {
  [key in IActors]?: {
    timestamp: string;
    value: string;
  };
};

export type IActors = "owner" | "brokerCharterer" | "broker" | "charterer";

export function actorToActorDescription(actor: IActors): string {
  const updatedBy: Record<IActors, string> = {
    broker: "Broker",
    brokerCharterer: "Broker",
    charterer: "Chrtr.",
    owner: "you",
  };
  return updatedBy[actor as IActors];
}

export function roleToActor(role: Role): IActors {
  switch (role) {
    case "owner":
      return "owner";
    case "charterer":
      return "brokerCharterer";
    default:
      throw new Error("role could not be determined");
  }
}

/**
 * Will return 'bid' if the Negotiation is a FirmBidAccepted and 'offer'
 * if the FirmOfferAccepted, or null if neither are 'firmAccepted'
 * @param actions Negotiations on the api
 */
export function getBidOrOffer(actions: IOrderNegActions): "bid" | "offer" | null {
  if (actions.owner && actions.owner === "firmAccepted") return "bid";
  if (actions.brokerCharterer && actions.brokerCharterer === "firmAccepted") return "offer";
  return null;
}
