import { object, string, number, array } from "yup";
import { Location } from "../Location";

// https://github.com/jquense/yup
const schema = object({
  locationId: string().required("Required"),
  name: string().required("Required"),
  country: string(),
  countryCode: string(),
  countryId: string(),
  zone: string(),
  zoneId: string(),
  notes: string(),
  safeBerthsMin: number(),
  safeBerthsMax: number(),
  safePortsMin: number(),
  safePortsMax: number(),
  safeAnchoragesMin: number(),
  safeAnchoragesMax: number(),
  parents: array(string()),
}).transform(toJSON);

function toJSON(location: Location) {
  return location?.toJSON();
}

export { schema };
