import { useUser } from "__legacy/dashboard/contexts/UserProvider";
import React, { useReducer, createContext, Dispatch, useContext } from "react";
import { initialState, distributionReducer, DistributionActions, DistributionState } from "./reducers/distributionReducer";

const DistributionContext = createContext<{
  state: DistributionState;
  dispatch: Dispatch<DistributionActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const DistributionAppProvider: React.FC = ({ children }) => {
  const user = useUser();
  const [state, dispatch] = useReducer(distributionReducer, {
    ...initialState,
    currentlyLoggedInUserContext: user,
  });

  return <DistributionContext.Provider value={{ state, dispatch }}>{children}</DistributionContext.Provider>;
};

function useDistribution() {
  const context = useContext(DistributionContext);
  if (context === undefined) {
    throw new Error("useDistribution must be within a DistributionProvider");
  }

  return context;
}

export { DistributionAppProvider, useDistribution };
