import React, { useRef, useState } from "react";
import { get } from "lodash-es";
import { useShallowStateOld, emptyFn } from "@/utils";
import { tradeAPI, TradeAPI } from "@/apis";
import { CLDDU } from "@/models";
import { SelectField, SelectFieldProps, InputFocusEvent } from "@/components";

export function CLDDUEditor(props: Props) {
  props = { ...props };

  props.className = `${props.className} CLDDU`;
  props.dataTest = `${props.dataTest}.value`;

  const {
    searchMessages = defaultSearchMessages,
    CLDDUSubset = "usersandowners",
    CLDDUQuery,
    CLDDUSelector,
    Constructor = CLDDU,
  } = props;
  const [data, setData] = useState<SelectFieldProps["data"]>(undefined);
  const [, setDropdownStatus, dropdownStatus] = useShallowStateOld<Status>({
    message: searchMessages?.initial,
  });

  const cancel = useRef(emptyFn);

  async function onFilter(search: string) {
    cancel.current();

    setData(undefined);
    setDropdownStatus({ message: undefined, loading: false, search });

    if (!search) {
      setDropdownStatus({ message: searchMessages?.initial });

      return;
    }

    setDropdownStatus({ message: searchMessages?.searching, loading: true });

    const promise = tradeAPI.searchCLDDUs(CLDDUSubset, search, CLDDUQuery);

    cancel.current = promise.cancel;

    const res = await promise;

    if (res.cancelled) return;

    if (!res.data || !res.data.length) {
      setData(undefined);
      setDropdownStatus({ message: searchMessages?.noData, loading: false });

      return;
    }

    let data = res.data;

    if (CLDDUSelector) data = res.data.filter(CLDDUSelector);

    const clddus = data.map((el) => new Constructor(el));

    setData(clddus);
    setDropdownStatus({ message: undefined, loading: false });
  }

  function onBlur(e: InputFocusEvent) {
    cancel.current();

    setData(undefined);
    setDropdownStatus({
      message: searchMessages?.initial,
      loading: false,
    });

    props.onBlur && props.onBlur(e);
  }

  function onChange(value: CLDDU) {
    if (props?.interceptChangeHandler) value = props.interceptChangeHandler(value);

    if (props?.onChangeSelector && value instanceof CLDDU) {
      value = get(value, props?.onChangeSelector);
    }

    props.onChange?.(value);
  }

  return (
    <SelectField
      {...props}
      onChange={onChange}
      value={props.value}
      data={data}
      placeholder=""
      dropdownStatus={dropdownStatus}
      onFilter={onFilter}
      onBlur={onBlur}
    />
  );
}

const defaultSearchMessages = {
  initial: "Please enter name, email or company",
  searching: "Searching for records",
  noData: "No records found",
} as Props["searchMessages"];

interface Props extends SelectFieldProps {
  CLDDUSubset: TradeAPI["CLDDUSubset"];
  CLDDUQuery: string;
  CLDDUSelector?(clddu: TradeAPI["CLDDU"]): boolean;
  onChangeSelector?: string;
  legalEntitiesOnly?: boolean;
  searchMessages?: {
    initial: string;
    searching: string;
    noData: string;
  };
  Constructor: typeof CLDDU;
}

export type CLDDUEditorProps = Props;
