import React from "react";
import { Label as CommonLabel } from "___REFACTOR___/components";
import { Field } from "../Field";
import "./Label.scss";

export function Label(props: Props) {
  return <CommonLabel label={props.label} name={props.name} asterisk={props.required} />;
}

type Props = Omit<Field.Props, "value" | "onChange">;
