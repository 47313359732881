import { ReactNode } from "react";
import { Response } from "___REFACTOR___/models/common";
import { Icon } from "___REFACTOR___/components";
// import { makeAutoObservable } from "mobx";

class Status {
  static isStatus(val): val is Status {
    if (!val) return false;

    if (typeof val !== "object") return false;

    if (val instanceof Status) return true;

    const { type, title, message } = val;

    if (type !== undefined) return true;

    if (title !== undefined && message !== undefined) return true;

    return false;
  }

  constructor(props?: Status) {
    Object.assign(this, props);

    // makeAutoObservable(this);
  }
}

export { Status };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface Status<ChildMap extends StatusMap = StatusMap> {
  name?: Status.Name;
  type?: Status.Type;
  icon?: Icon.IconProp;
  title?: ReactNode;
  message?: ReactNode;
  loading?: boolean;
  res?: Response;
  dataTest?: string;
  childMap?: ChildMap;
}

declare namespace Status {
  type Type = "info" | "success" | "warning" | "error" | "fatal-error";
  type Name = string;
}

interface StatusMap {
  [key: string]: Status;
}
