import React from "react";
import { ValidationContext, IValidationContext } from "./ValidationContext";
/**
 * Bind a React Context consumer to a Component.
 * Returns a new component with the context attached.
 *
 * Assuming that the props of the component are a union of IProps and IContext interfaces
 * e.g. class Component extends React.PureComponent<IProps & IContext> { ... }
 *
 * Usage: const MyComponentWithContext = withContext<IProps, IContext>(Component, Context);
 *
 * @param Component The Component to compliment with a context
 * @param Context The Context with which to extend the component's props
 */
export function withValidationContext<IProps>(
  Component: string | React.ComponentClass<IProps & IValidationContext> | React.SFC<IProps & IValidationContext>
): (p: IProps) => React.ReactElement<IProps> {
  return function (props: IProps): React.ReactElement<IProps> {
    return <ValidationContext.Consumer>{(ctx) => <Component {...ctx} {...props} />}</ValidationContext.Consumer>;
  };
}
