import React from "react";
import { Label as CommonLabel } from "@/components";
import { FieldProps } from "../Field";
import "./Label.scss";

export function Label(props: Props) {
  return <CommonLabel label={props.label} name={props.name} />;
}

type Props = FieldProps;
