import React, { useContext, useMemo } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { useSimpleEffect, uid, downloadArrayAsCSV, getCopyIndicationType, shouldEnableLaunchDarklyFeature } from "@/utils";
import { TradeAPI } from "@/apis";
import {
  Negotiation as NegotiationModel,
  router,
  slideout,
  Negotiation,
  FreightRate,
  HireRate,
  CleaningPrice,
  SupplyPrice,
  TradingExclusions,
  BunkerDelivery,
  BunkerRedelivery,
  CargoExclusionsText,
  Demurrage,
  BallastBonus,
  Laycan,
  Period,
  LoadLocation,
  DischargeLocation,
  DeliveryLocation,
  ViaLocation,
  RedeliveryLocation,
  CargoType,
  CargoSize,
  COACargoSize,
  VesselSize,
  Vessels,
  auth,
  OrderNegotiationStore,
} from "@/models";
import { vesselScoreStore } from "@/stores";
import { Aggrid, AggridRowClickedEvent, AggridGetContextMenuItemsParams } from "@/components";
import { AggridGridReadyEvent } from "@/components/common";
import { Context } from "@/components/Orders";
import {
  sendNegotiationRecap,
  withdrawNegotiation as withdrawNegotiationAction,
  copyIndication as copyIndicatrionAction,
} from "@/components/Orders/actions";
import { NegotiationDetail } from "./NegotiationDetail";
import { NameVesselSlideout } from "./NameVesselSlideout";
import { NotesSlideout } from "./NotesSlideout";
import { SlideoutBidOfferForm } from "./NegotiationDetail/SlideoutBidOfferForm";
import { NegotiationTypeEvents, usageMetrics } from "@/services/UsageMetrics";
import { LaunchDarklyFeature } from "@/config";
import { useFlags } from "launchdarkly-react-client-sdk";
import "./Negotiations.scss";

function Negotiations(props: Props) {
  const { orderId } = useParams() as StringRecord;
  const context = useContext(Context);
  const { orderNegotiationStore, view } = context;
  const { grid } = view.order.negotiations;
  const order = orderNegotiationStore?.upsertOrderModel({ id: orderId });
  const negotiations = useMemo(getNegotiationNonLiftingModels, [order?._.orderNegotiationStoreOrderNegotiationArray]);
  const flags = useFlags();
  const enableCopyIndication = shouldEnableLaunchDarklyFeature(LaunchDarklyFeature.CopyIndication, flags);
  const enableTcFields = shouldEnableLaunchDarklyFeature(LaunchDarklyFeature.TCFields, flags);

  const rowClassRules = {
    unseen: (params) => {
      const neg = params.data as OrderNegotiationStore["Negotiation"];
      const lastUpdated = new Date(neg.lastUpdated);
      const firstVisit = new Date(localStorage.FIRST_VISIT_ON);
      const isLastUpdatedByCharterer = neg.lastUpdatedBy === "charterer";
      const isLastUpdatedByBroker = neg.lastUpdatedBy === "broker";
      const isLastUpdatedByOwner = neg.lastUpdatedBy === "owner";
      const isLastUpdatedBeforeFirstVisit = lastUpdated < firstVisit;
      const isVersionUnseen = !!context.orderNegotiationStore?.isUnseen(params.data);
      const isNotFreshNeg = neg.version > 2;
      const isUserBroker = auth.trade.user?._.companyRoleMap.broker;
      const isUserChartererInBrokerManagedNeg = neg.hasBroker;
      const isUserBrokerInBrokerManagedNeg = neg.hasCharterer && isUserBroker;
      let isUnseen = isVersionUnseen && isNotFreshNeg && isLastUpdatedByOwner;

      if (isUserChartererInBrokerManagedNeg) {
        isUnseen = isVersionUnseen && isLastUpdatedByBroker;
      }

      if (isUserBrokerInBrokerManagedNeg) {
        isUnseen = isVersionUnseen && (isLastUpdatedByCharterer || isLastUpdatedByOwner);
      }

      if (isLastUpdatedBeforeFirstVisit) {
        isUnseen = false;
      }

      updateRowHeight(params);

      return isUnseen;
    },
  };

  useSimpleEffect(getNegotiations, [orderId]);

  return (
    <Aggrid
      {...grid}
      {...props}
      onGridReady={onGridReady}
      rowData={negotiations}
      onRowClicked={onRowClicked}
      getRowNodeId={getRowNodeId}
      getContextMenuItems={getContextMenuItems}
      rowClassRules={rowClassRules}
      className="order-negotiations"
      detailCellRenderer="NegotiationDetail"
      detailCellRendererParams={detailCellRendererParams}
      frameworkComponents={frameworkComponents}
      getRowHeight={getRowHeight}
      headerHeight={31}
      detailRowHeight={window.innerHeight - 300}
      suppressClickEdit
      masterDetail
    />
  );

  function updateRowHeight(params) {
    if (!vesselScoreStore.security.sectionAccess.hasAccess) return;

    params.node.setRowHeight(getRowHeight(params));
  }

  function onGridReady(params: AggridGridReadyEvent) {
    if (vesselScoreStore.security.sectionAccess.hasAccess) {
      params.api.setHeaderHeight(52);
    }
  }

  function getRowHeight(params) {
    if (params.node.detail) return;

    const sectionAccess = vesselScoreStore.security.sectionAccess;
    if (sectionAccess.isDataReady && !sectionAccess.hasAccess) return 31;

    const data = params.data as TradeAPI["Negotiation"];
    const vesselCount = data.vessels?.length || 0;

    return 31 + vesselCount * 21;
  }

  function onRowClicked(params: AggridRowClickedEvent) {
    if (!params.node.detail) params.api.onGroupExpandedOrCollapsed(params.node.setExpanded(!params.node.expanded));

    orderNegotiationStore?.setSeenVersion(params.data);
  }

  function getNegotiations() {
    if (!orderId || orderId.includes("$")) return;

    orderNegotiationStore?.getNegotiationsDataByOrderId(orderId);
  }

  function nonLiftingSelector(negotiation: TradeAPI["Negotiation"]) {
    return negotiation.type !== "Lft";
  }

  function getContextMenuItems(params: AggridGetContextMenuItemsParams) {
    const negotiation = params.node?.data as TradeAPI["Negotiation"];
    const { type, status, hasBroker, id: negotiationId } = negotiation;
    const negotiationModel = new Negotiation(negotiation);
    const actorIsCharterer = !auth.trade.user?._.companyRoleMap.broker;
    const isChartererWithBrokeredNeg = actorIsCharterer && hasBroker;
    const permittedMethodsMap = negotiationModel.getParsedUpdateToken()?.permittedMethodsMap;
    const vesselText =
      negotiationModel.vessels && negotiation?.vessels?.length > 0 ? "Nominate another vessel" : "Nominate a vessel";

    const createInvitationEmailDisabled =
      !SEND_INVITATION_SUPPORTED_STATUS[status] || negotiationModel.isOwnerAbsent || isChartererWithBrokeredNeg;
    const isBrokedNegInFirmState = isChartererWithBrokeredNeg && status === "Firm";
    const proceedToMainTermsEnabled = isBrokedNegInFirmState || (!isChartererWithBrokeredNeg && permittedMethodsMap?.PtMainTerms);

    const contextMenuItems = [
      {
        disabled: createInvitationEmailDisabled,
        name: !createInvitationEmailDisabled
          ? `<a href="${negotiationModel.getInvitationEmail()}" data-test="create-invitation-email">Create Invitation Email</a>`
          : "Create Invitation Email",
      },
      {
        disabled: !RECAPABLE_STATUSES[status],
        name: '<span data-test="send-recap">Send Recap to All Parties</span>',
        action: sendNegotiationRecap.bind(null, negotiationModel),
      },
      {
        disabled: !permittedMethodsMap?.NameVessel,
        name: `<span data-test="nominate-vessel">${vesselText}</span>`,
        action: openVesselSlideout.bind(null, negotiationModel),
      },
      {
        disabled: !permittedMethodsMap?.UpdateNotes,
        name: '<span data-test="send-note">Send a note</span>',
        action: openNotesSlideout.bind(null, negotiationModel),
      },
      {
        disabled: !permittedMethodsMap?.BidFirm,
        name: '<span data-test="firm-bid-offer">Firm Bid/Offer</span>',
        action: openNegotiationBidOfferSlideout.bind(null, negotiationModel, "bid", "firmed"),
      },
      {
        disabled: negotiationModel.isOwnerAbsent || !permittedMethodsMap?.OfferFirmRequest,
        name: `<span data-test="request-firm-from-Owner">Request Firm from ${
          isChartererWithBrokeredNeg ? "Broker" : "Owner"
        }</span>`,
        action: openNegotiationBidOfferSlideout.bind(null, negotiationModel, "bid", "firmRequested"),
      },
      {
        disabled: !proceedToMainTermsEnabled,
        name: proceedToMainTermsEnabled
          ? `<a href="${router
              .get("mainTerms")
              .compile({ orderId, negotiationId, type })}" data-test="proceed-to-main-terms">Proceed to Main Terms</a>`
          : "Proceed to Main Terms",
      },
      {
        disabled: !permittedMethodsMap?.WithdrawNeg,
        name: '<span data-test="withdraw-negotiation">Withdraw Negotiation</span>',
        action: withdrawNegotiation.bind(null, negotiationModel),
      },
      "separator",
      {
        name: "Export all negotiations as CSV",
        disabled: !order?._.orderNegotiationStoreOrderNegotiationArray?.length,
        action: exportNegotiationsAsCSV,
      },
    ];

    if (enableCopyIndication) {
      const copyIndicationType = getCopyIndicationType(negotiation);
      const negotiableFields = Object.entries(negotiation.detailSettings).filter((field) => field[1]["negotiable"] === true);
      const keys = negotiableFields.map((neg) => neg[0]);
      const isNoOwnerIndication =
        copyIndicationType === "Owner's Indication" &&
        !keys.find((key) => Object.prototype.hasOwnProperty.call(negotiation.offer, key));
      const menuItemPosition = 7;
      const copyIndicationItem = {
        disabled: isNoOwnerIndication,
        name: `<span data-test="copy-indication">
          Copy ${copyIndicationType} to clipboard
        </span>`,
        action: copyIndication.bind(null, negotiationModel),
      };

      if (copyIndicationType) contextMenuItems.splice(menuItemPosition, 0, copyIndicationItem);
    }

    return contextMenuItems;
  }

  function exportNegotiationsAsCSV() {
    const negotiations = order?._.orderNegotiationStoreOrderNegotiationArray;
    if (!negotiations) return;
    if (!order?.summary.orderReference) return;
    downloadArrayAsCSV(`Seatrade Order ${order.summary.orderReference}`, negotiations, negotiationExportColumnConfigs);
    usageMetrics.trackEvent(NegotiationTypeEvents.EXPORT_NEG_CSV);
  }

  function openVesselSlideout(negotiation: NegotiationModel) {
    slideout.show({
      content: <NameVesselSlideout negotiation={negotiation} context={context} />,
    });
  }

  function openNotesSlideout(negotiation: NegotiationModel) {
    slideout.show({
      content: <NotesSlideout negotiation={negotiation} context={context} />,
    });
  }

  function openNegotiationBidOfferSlideout(
    negotiation: NegotiationModel,
    type: "bid" | "offer",
    indicationType?: TradeAPI["IndicationType"]
  ) {
    slideout.show({
      adjustable: true,
      content: (
        <SlideoutBidOfferForm type={type} negotiation={negotiation} indicationType={indicationType} featureFlags={flags} />
      ),
    });
  }

  function withdrawNegotiation(negotiation: NegotiationModel) {
    withdrawNegotiationAction(negotiation, context);
  }

  function copyIndication(negotiation: NegotiationModel) {
    copyIndicatrionAction(negotiation, context, enableTcFields);
  }

  function getNegotiationNonLiftingModels() {
    return order?._.orderNegotiationStoreOrderNegotiationArray.filter(nonLiftingSelector);
  }
}

const negotiationExportColumnConfigs = [
  {
    heading: "Order ID",
    valuePath: "orderId",
  },
  {
    heading: "Negotiation ID",
    valuePath: "id",
  },
  {
    heading: "Type",
    valuePath: "type",
  },
  {
    heading: "Status",
    valuePath: "status",
  },
  {
    heading: "Invitee",
    valuePath: "invitee",
  },
  {
    heading: "Vessel",
    getValue: (negotiation: TradeAPI["Negotiation"]) => {
      const acceptedOrFirstVessel = Vessels.prototype.getAcceptedOrFirstVesselModel
        .call({ data: negotiation.vessels })
        ?.toGridView();

      return acceptedOrFirstVessel || "";
    },
  },
  {
    heading: "Counterparty",
    getValue: (negotiation: TradeAPI["Negotiation"]) => Negotiation.prototype.getCounterparty.call(negotiation),
  },
  {
    heading: "Freight Rate",
    getValue: (negotiation: TradeAPI["Negotiation"]) => {
      const details = Negotiation.prototype.getMainTermsDetailOrOfferOrBidMapSensitivelyToBrokerHandbrake.call(negotiation);
      const freightRate = FreightRate.prototype.toGridView.call(details.freightRate);

      return freightRate;
    },
  },
  {
    heading: "Hire Rate",
    getValue: (negotiation: TradeAPI["Negotiation"]) => {
      const details = Negotiation.prototype.getMainTermsDetailOrOfferOrBidMapSensitivelyToBrokerHandbrake.call(negotiation);
      const hireRate = HireRate.prototype.toGridView.call(details.hireRate);

      return hireRate;
    },
  },
  {
    heading: "ILOHC",
    getValue: (negotiation: TradeAPI["Negotiation"]) => {
      const details = Negotiation.prototype.getMainTermsDetailOrOfferOrBidMapSensitivelyToBrokerHandbrake.call(negotiation);
      const cleaningPrice = CleaningPrice.prototype.toGridView.call(details.cleaningPrice);

      return cleaningPrice;
    },
  },
  {
    heading: "CVE",
    getValue: (negotiation: TradeAPI["Negotiation"]) => {
      const details = Negotiation.prototype.getMainTermsDetailOrOfferOrBidMapSensitivelyToBrokerHandbrake.call(negotiation);
      const supplyPrice = SupplyPrice.prototype.toGridView.call(details.supplyPrice);

      return supplyPrice;
    },
  },
  {
    heading: "Trading Exclusions",
    getValue: (negotiation: TradeAPI["Negotiation"]) => {
      const details = Negotiation.prototype.getMainTermsDetailOrOfferOrBidMapSensitivelyToBrokerHandbrake.call(negotiation);
      const tradingExclusions = TradingExclusions.prototype.toGridView.call(details.tradingExclusions);

      return tradingExclusions;
    },
  },
  {
    heading: "Bunker Delivery",
    getValue: (negotiation: TradeAPI["Negotiation"]) => {
      const details = Negotiation.prototype.getMainTermsDetailOrOfferOrBidMapSensitivelyToBrokerHandbrake.call(negotiation);
      const data = BunkerDelivery.prototype.toGridView.call(details.bunkerDelivery);

      return data;
    },
  },
  {
    heading: "Bunker Re-Delivery",
    getValue: (negotiation: TradeAPI["Negotiation"]) => {
      const details = Negotiation.prototype.getMainTermsDetailOrOfferOrBidMapSensitivelyToBrokerHandbrake.call(negotiation);
      const data = BunkerRedelivery.prototype.toGridView.call(details.bunkerRedelivery);

      return data;
    },
  },
  {
    heading: "Cargo Exclusions",
    getValue: (negotiation: TradeAPI["Negotiation"]) => {
      const details = Negotiation.prototype.getMainTermsDetailOrOfferOrBidMapSensitivelyToBrokerHandbrake.call(negotiation);
      const cargoExclusionsText = CargoExclusionsText.prototype.toGridView.call(details.cargoExclusionsText);

      return cargoExclusionsText;
    },
  },
  {
    heading: "Demurrage",
    getValue: (negotiation: TradeAPI["Negotiation"]) => {
      const details = Negotiation.prototype.getMainTermsDetailOrOfferOrBidMapSensitivelyToBrokerHandbrake.call(negotiation);
      const demurrage = Demurrage.prototype.toGridView.call(details.demurrage);

      return demurrage;
    },
  },
  {
    heading: "Ballast Bonus",
    getValue: (negotiation: TradeAPI["Negotiation"]) => {
      const details = Negotiation.prototype.getMainTermsDetailOrOfferOrBidMapSensitivelyToBrokerHandbrake.call(negotiation);
      const ballastBonus = BallastBonus.prototype.toGridView.call(details.ballastBonus);

      return ballastBonus;
    },
  },
  {
    heading: "Laycan",
    getValue: (negotiation: TradeAPI["Negotiation"]) => {
      const details = Negotiation.prototype.getMainTermsDetailOrOfferOrBidMapSensitivelyToBrokerHandbrake.call(negotiation);
      const laycan = Laycan.prototype.toGridView.call(details.laycan);

      return laycan;
    },
  },
  {
    heading: "Period",
    getValue: (negotiation: TradeAPI["Negotiation"]) => {
      const details = Negotiation.prototype.getMainTermsDetailOrOfferOrBidMapSensitivelyToBrokerHandbrake.call(negotiation);
      const period = Period.prototype.toGridView.call(details.period);

      return period;
    },
  },
  {
    heading: "Load Location",
    getValue: (negotiation: TradeAPI["Negotiation"]) => {
      const details = Negotiation.prototype.getMainTermsDetailOrOfferOrBidMapSensitivelyToBrokerHandbrake.call(negotiation);
      const loadLocation = LoadLocation.prototype.toGridView.call(details.loadLocation);

      return loadLocation;
    },
  },
  {
    heading: "Discharge Location",
    getValue: (negotiation: TradeAPI["Negotiation"]) => {
      const details = Negotiation.prototype.getMainTermsDetailOrOfferOrBidMapSensitivelyToBrokerHandbrake.call(negotiation);
      const dischargeLocation = DischargeLocation.prototype.toGridView.call(details.dischargeLocation);

      return dischargeLocation;
    },
  },
  {
    heading: "Delivery Location",
    getValue: (negotiation: TradeAPI["Negotiation"]) => {
      const details = Negotiation.prototype.getMainTermsDetailOrOfferOrBidMapSensitivelyToBrokerHandbrake.call(negotiation);
      const deliveryLocation = DeliveryLocation.prototype.toGridView.call(details.deliveryLocation);

      return deliveryLocation;
    },
  },
  {
    heading: "Via Location",
    getValue: (negotiation: TradeAPI["Negotiation"]) => {
      const details = Negotiation.prototype.getMainTermsDetailOrOfferOrBidMapSensitivelyToBrokerHandbrake.call(negotiation);
      const viaLocation = ViaLocation.prototype.toGridView.call(details.viaLocation);

      return viaLocation;
    },
  },
  {
    heading: "Redelivery Location",
    getValue: (negotiation: TradeAPI["Negotiation"]) => {
      const details = Negotiation.prototype.getMainTermsDetailOrOfferOrBidMapSensitivelyToBrokerHandbrake.call(negotiation);
      const redeliveryLocation = RedeliveryLocation.prototype.toGridView.call(details.redeliveryLocation);

      return redeliveryLocation;
    },
  },
  {
    heading: "Cargo Type",
    getValue: (negotiation: TradeAPI["Negotiation"]) => {
      const details = Negotiation.prototype.getMainTermsDetailOrOfferOrBidMapSensitivelyToBrokerHandbrake.call(negotiation);
      const cargoType = CargoType.prototype.toGridView.call(details.cargoType);

      return cargoType;
    },
  },
  {
    heading: "Cargo Size",
    getValue: (negotiation: TradeAPI["Negotiation"]) => {
      const details = Negotiation.prototype.getMainTermsDetailOrOfferOrBidMapSensitivelyToBrokerHandbrake.call(negotiation);
      const cargoSize = CargoSize.prototype.toGridView.call(details.cargoSize);

      return cargoSize;
    },
  },
  {
    heading: "COA Cargo Size",
    getValue: (negotiation: TradeAPI["Negotiation"]) => {
      const details = Negotiation.prototype.getMainTermsDetailOrOfferOrBidMapSensitivelyToBrokerHandbrake.call(negotiation);
      const coaCargoSize = COACargoSize.prototype.toGridView.call(details.coaCargoSize);

      return coaCargoSize;
    },
  },
  {
    heading: "Vessel Size",
    getValue: (negotiation: TradeAPI["Negotiation"]) => {
      const details = Negotiation.prototype.getMainTermsDetailOrOfferOrBidMapSensitivelyToBrokerHandbrake.call(negotiation);
      const vesselSize = VesselSize.prototype.toGridView.call(details.vesselSize);

      return vesselSize;
    },
  },
  {
    heading: "Last Updated",
    getValue: (negotiation: TradeAPI["Negotiation"]) => {
      return negotiation.lastUpdated;
    },
  },
];

const SEND_INVITATION_SUPPORTED_STATUS = {
  Inactive: true,
  Active: true,
} as BoolRecord;

const RECAPABLE_STATUSES = {
  MainTerms: true,
  Fixed: true,
  OnSubs: true,
  SubsLifted: true,
} as BoolRecord;

const detailCellRendererParams = {
  refreshStrategy: "everything",
};

function getRowNodeId(order: Negotiation) {
  return order?.id || `${uid()}`;
}

const frameworkComponents = {
  NegotiationDetail,
};

const Observer = observer(Negotiations);

export { Observer as Negotiations };

interface Props {
  hidden?: boolean;
}
