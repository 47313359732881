import { makeObservable, observable } from "mobx";
import { Negotiation, OrderNegStore } from "___REFACTOR___/models";
import { DataModelProps } from "___REFACTOR___/models/DataModel";
import { Order } from "../Order";
import { OrderNegLayout } from "./OrderNegLayout";

class OrderProps extends DataModelProps {
  constructor(props?: OrderProps.ConstructorProps) {
    super();

    Object.assign(this, props);

    this.orderNegLayout = new OrderNegLayout(this.order);

    if (this.orderNegStore) {
      const data = this.order.toJSON();
      this.orderNegStore.order = this.orderNegStore.store.upsertOrder(data, { shouldUpdateModel: false });
      this.orderNegStore.order._.model = this.order;
      this.orderNegStore.negArr = this.orderNegStore.order._.negArr;

      makeObservable(this.orderNegStore, { negArr: observable.ref });
    }

    makeObservable(this, { version: observable });
  }

  NegotiationConstructor = Negotiation;

  version = 0;

  negMap = {} as MaybeRecordOf<Negotiation>;

  get stageMetadataMap() {
    return {
      Inactive: { value: "Inactive", label: "New" },
      InactiveNegotiations: { value: "InactiveNegotiations", label: "Inactive Negotiations" },
      Active: { value: "Active", label: "Active Negotiations" },
      FirmOffer: { value: "FirmOffer", label: "Firm Offer" },
      FirmBid: { value: "FirmBid", label: "Firm Bid" },
      Firm: { value: "Firm", label: "Firm" },
      TermsLoading: { value: "TermsLoading", label: "Terms Loading" },
      MainTerms: { value: "MainTerms", label: "Main Terms" },
      OnSubs: { value: "OnSubs", label: "On Subs" },
      SubsLifted: { value: "SubsLifted", label: "Subjects Lifted" },
      SubsFailed: { value: "SubsFailed", label: "Subjects Failed" },
      Fixed: { value: "Fixed", label: "Fixed" },
      Failed: { value: "Failed", label: "Failed" },
      Archived: { value: "Archived", label: "Archived" },
      Withdrawn: { value: "Withdrawn", label: "Order Withdrawn" },
    };
  }
}

export { OrderProps };

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

interface OrderProps {
  order: Order;
  orderNegLayout: OrderNegLayout;
  orderNegStore?: {
    store: OrderNegStore;
    order: OrderNegStore.Order;
    negArr: OrderNegStore.Negotiation[];
  };
}

declare namespace OrderProps {
  export interface ConstructorProps {
    order: Order;
    orderNegStore?: {
      store: OrderNegStore;
    };
    NegotiationConstructor?: typeof Negotiation;
  }
}
