import React from "react";
import classNames from "classnames";
import { COACargoSize, COACargoSizeProps } from "___REFACTOR___/models";
import { TextareaField, NumberField, SelectField, Field, StatusMessages } from "___REFACTOR___/components";
import { Group } from "../../Group";
import { Row } from "../../Row";
import "./COACargoSize.scss";

function COACargoSizeEditor(props: COACargoSizeEditor.Props) {
  const { value, status, dataTest, ...rest } = props;

  return (
    <Group {...props} editorType="COACargoSize">
      <Row>
        <NumberField
          {...rest}
          label="Cargo Size / Min"
          desc="COA cargo min weight"
          className="COACargoSize-min-value"
          placeholder="Metric tons, e.g. 120,000"
          status={status?.childMap?.min}
          value={value?.min}
          onBlur={onMinBlur}
          onChange={onChange.bind(null, "min")}
          dataTest={`${dataTest}.min`}
          suppressStatusMessages
          max={10000000}
        />
        <NumberField
          {...rest}
          label="Max"
          desc="COA cargo max weight"
          className="COACargoSize-max-value"
          placeholder="e.g. 200,000"
          status={status?.childMap?.max}
          value={value?.max}
          onBlur={onMaxBlur}
          onChange={onChange.bind(null, "max")}
          dataTest={`${dataTest}.max`}
          suppressStatusMessages
          max={10000000}
        />
        <SelectField
          {...rest}
          label="Option"
          className="COACargoSize-option"
          desc={`COA cargo size option

      MIN/MAX - the lowest / highest acceptable load weight.

      MOLCHOP - More or Less in Charterer's Option - Option allowed to a voyage charterer to load up to a certain quantity, normally expressed as a percentage or a number of tons, over or under a quantity specified in the contract of carriage. This option may be sought if the charterer is not certain of the exact quantity that will be available at the time of loading.

      MOLOO - More or Less in Owner's Option - Option allowed to a shipowner to carry up to a certain quantity, normally expressed as a percentage or number of tons, over or under a quantity specified in the voyage charter. This option may be sought if the shipowner is not certain what the ship's cargo capacity will be, taking into consideration bunkers, stores and fresh water, or if he wants flexibility to adjust the ship's trim.
      `}
          data={OPTIONS}
          status={status?.childMap?.option}
          value={value?.option || COACargoSizeProps.prototype.JSONDefaults?.option}
          onChange={onOptionChange}
          dataTest={`${dataTest}.option`}
          unclearable
          nonFilterSelect
          suppressStatusMessages
        />
        <NumberField
          {...rest}
          className={classNames("COACargoSize-variance", {
            hidden: value?.option === "MIN/MAX",
          })}
          label="Variance(%)"
          placeholder="e.g. 3"
          desc="COA cargo weight variance (%)"
          status={status?.childMap?.variance}
          value={value?.variance}
          max={99}
          onChange={onChange.bind(null, "variance")}
          dataTest={`${dataTest}.variance`}
          suppressStatusMessages
        />
      </Row>
      <StatusMessages status={status} />
      <TextareaField
        {...props}
        label="Notes"
        desc="COA cargo size notes"
        className="COACargoSize-notes"
        value={value?.notes || ""}
        onChange={onNotesChange}
        status={status?.childMap?.notes}
        dataTest={`${dataTest}.notes`}
      />
    </Group>
  );

  function onChange(name: keyof COACargoSize, inputValue: NumberField.Input.Value) {
    props.onChange?.(new COACargoSize({ ...value, [name]: inputValue }));
  }

  function onOptionChange(option) {
    const next = new COACargoSize({ ...value, option });

    props.onChange?.(next);
  }

  function onNotesChange(notes: TextareaField.Input.Value) {
    if (typeof notes !== "string") return;

    props.onChange?.(new COACargoSize({ ...props.value, notes }));
  }

  function onMinBlur() {
    if (!value?.min) return;

    if (value.min < 1000) {
      props.onChange?.(new COACargoSize({ ...value, min: value.min * 1000 }));
    }
  }

  function onMaxBlur() {
    if (!value?.max) return;

    if (value.max < 1000) {
      props.onChange?.(new COACargoSize({ ...value, max: value.max * 1000 }));
    }
  }
}

const OPTIONS = ["MIN/MAX", "MOLOO", "MOLCHOPT"];

export { COACargoSizeEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace COACargoSizeEditor {
  export interface Props extends Field.Props {
    value: Value;
    onChange: (value: Value) => void;
  }

  export type Value = COACargoSize | undefined;
}
