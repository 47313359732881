import React, { useContext } from "react";
import styles from "./VesselPanelActions.module.scss";
import Button from "__legacy/sharedFolder/components/common/Button/Button";
import classNames from "classnames";
import { NegotiationPermittedMethodsContext } from "sharedFolder/contexts/NegotiationPermittedMethodsProvider";
import { isAllowed } from "sharedFolder/Utilities/isAllowed";
import { SeaNetButton } from "../ExternalButtons/ExternalButton";
import { ConfigurationContext } from "__legacy/sharedFolder/ConfigurationContext";
import { IVesselView } from "sharedFolder/Models/IDetails";
import { useAuth } from "__legacy/auth-provider";

interface IVesselPanelActions {
  isValid?: boolean;
  vessel?: IVesselView;
  onDismiss: () => void;
  nominateVessel?: () => void;
  nominateAcceptVessel?: () => void;
  acceptVessel?: () => void;
  rejectVessel?: () => void;
  addVessel?: () => void;
}

export const VesselPanelActions = (props: IVesselPanelActions) => {
  const { auth } = useAuth();
  const { permittedMethods } = useContext(NegotiationPermittedMethodsContext);
  const config = useContext(ConfigurationContext);
  if (props.addVessel) {
    if (props.vessel) {
      return (
        <>
          <div className={styles.seaNetButton} data-test="seanet">
            <SeaNetButton vesselID={props.vessel.vesselIMO} cloudToken={auth.cloudToken} seaNetUrl={config.vesselViewUrl} />
          </div>
          <div className={styles.oneOrTwoButtonsFooter}>
            <Button className={styles.button} type="action" onClick={props.onDismiss}>
              Done
            </Button>
          </div>
        </>
      );
    } else {
      return (
        <div className={styles.oneOrTwoButtonsFooter}>
          <Button className={styles.button} type="flat" dataTest="vessel-cancel" onClick={props.onDismiss}>
            Cancel
          </Button>
          <Button
            className={styles.button}
            type="action"
            dataTest="vessel-add"
            disabled={!props.isValid}
            onClick={props.addVessel}
          >
            Add
          </Button>
        </div>
      );
    }
  } else {
    return (
      <>
        {props.vessel && (
          <Button
            className={classNames(styles.vesselActivityButton, styles.button)}
            type="flat"
            dataTest="vessel-vesselactivity"
            onClick={() => {
              window.open(`${config.vesselActivityUrl}${props.vessel?.vesselIMO}`, "_blank");
            }}
          >
            View Vessel Activity Report
          </Button>
        )}

        {props.vessel && props.vessel.vesselIMO && (
          <div className={styles.seaNetButton} data-test="seanet">
            <SeaNetButton vesselID={props.vessel.vesselIMO} cloudToken={auth.cloudToken} seaNetUrl={config.vesselViewUrl} />
          </div>
        )}
        <div className={styles.footer}>
          {!props.addVessel && (
            <Button className={styles.button} type="flat" dataTest="vessel-cancel" onClick={props.onDismiss}>
              Cancel
            </Button>
          )}

          <div>
            {props.nominateAcceptVessel && !props.vessel && (
              <Button
                className={styles.button}
                type="positive"
                dataTest="vessel-accept"
                disabled={!isAllowed(permittedMethods, "AcceptVessel") || !props.isValid}
                onClick={props.nominateAcceptVessel}
              >
                Accept Now
              </Button>
            )}
            {props.nominateVessel && !props.vessel && (
              <Button
                className={styles.button}
                type="action"
                dataTest="vessel-submit"
                disabled={!isAllowed(permittedMethods, "AddVessel") || !props.isValid}
                onClick={props.nominateVessel}
              >
                Submit
              </Button>
            )}
            {props.rejectVessel && props.vessel && (
              <Button
                className={styles.button}
                type="warning"
                dataTest="vessel-reject"
                onClick={props.rejectVessel}
                disabled={!isAllowed(permittedMethods, "RejectVessel") || props.vessel.status === "rejected"}
              >
                {props.vessel.status === "rejected" ? "Rejected" : "Reject"}
              </Button>
            )}
            {props.acceptVessel && props.vessel && (
              <Button
                className={styles.button}
                type="positive"
                dataTest="vessel-accept"
                onClick={props.acceptVessel}
                disabled={!isAllowed(permittedMethods, "AcceptVessel") || props.vessel.status === "accepted"}
              >
                {props.vessel.status === "accepted" ? "Accepted" : "Accept Now"}
              </Button>
            )}
          </div>
        </div>
      </>
    );
  }
};
