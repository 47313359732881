import React from "react";
import styles from "./OrderSharing.module.scss";
import Icon from "__legacy/sharedFolder/components/common/Icon/Icon";

export const notAssigned = (role: "charterer" | "broker" | "teamMember") => {
  return (
    <div className={styles.noRoleContainerWrapper} data-test="charterer-display">
      <div className={styles.noRoleContainer}>
        <Icon icon="info-outline" className={styles.noRoleInfo} />
        <div className={styles.noRoleTitle}>{roleMessages[role]}</div>
      </div>
    </div>
  );
};

const roleMessages = {
  charterer: "Charterers have not been assigned yet",
  broker: "Brokers have not been assigned yet",
  teamMember: "Team Members have not been assigned yet",
};
