import { TradeAPI } from "___REFACTOR___/apis";
import { DataModel, DataModelProps } from "../DataModel";

class Desk extends DataModel {
  constructor(data?: PartialData) {
    super(data);
  }

  toString() {
    return this.name;
  }

  toGridView() {
    return this.name;
  }
}

class DeskProps extends DataModelProps {}

DeskProps.prototype.sortKey = "name";

export { Desk, DeskProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface Desk extends Data {
  Props: typeof DeskProps;
  _: DeskProps;
}

interface DeskProps {
  sortKey: keyof Data;
}

type PartialData = Partial<Data>;
type Data = TradeAPI.Desk;
