import moment, { duration as momentDuration } from "moment";
import { getNow } from "../Utilities/getNow";

const expiryTypeReducer: {
  [key in "duration" | "timestamp"]: (value: string) => string;
} = {
  duration: (value: string) => {
    const now = getNow();
    const duration = momentDuration(value);
    now.add(duration);
    now.utc();
    return now.toISOString();
  },
  timestamp: (value: string) => moment(value).toISOString(),
};

export default expiryTypeReducer;
