import { from, Observable } from "rxjs";
import downloadjs from "downloadjs";
import { config } from "@/config";

const commonHeaders = {
  "X-API-Version": "2",
};

type FileUploadResponse = {
  id: number;
  name: string;
  fileSizeInBytes: number;
  success: boolean;
  error: string;
  offerRepToken: string;
};

export class UploadAttachmentResult {
  constructor(public success: boolean, public successPerFile: boolean[] | null = null) {}
}

export function processFileUploadResponses(uploadResults: FileUploadResponse[]): UploadAttachmentResult {
  const failedUploads = uploadResults.filter((r) => !r.success);
  failedUploads
    .filter((r) => r.error)
    .forEach((r) => {
      console.log(r.error);
    });
  const wasUploadSuccessfull = failedUploads.length === 0;
  return {
    success: wasUploadSuccessfull,
    successPerFile: uploadResults.map((u) => u.success),
  };
}

export const uploadOrderAttachment =
  (orderId: string, baseUrl: string) =>
  (formData: FormData, updateToken: string): Observable<UploadAttachmentResult> =>
    upload(`${config.ctradeUrl}/orders/${orderId}/files`, formData, updateToken);

export const uploadNegAttachment =
  (orderId: string, negotiationId: string) =>
  (baseUrl: string, formData: FormData, updateToken: string): Observable<UploadAttachmentResult> =>
    upload(`${config.ctradeUrl}/orders/${orderId}/negotiations/${negotiationId}/files`, formData, updateToken);

/**
 *  Download [START]
 */

export const downloadNegAttachment =
  (orderId: string, negotiationId: string) => (fileId: string, fileName: string, updateToken: string) =>
    download(`${config.ctradeUrl}/orders/${orderId}/negotiations/${negotiationId}/files/${fileId}`, fileName, updateToken);

export const downloadOrderAttachment = (orderId: string) => (fileId: string, fileName: string, updateToken: string) =>
  download(`${config.ctradeUrl}/orders/${orderId}/files/${fileId}`, fileName, updateToken);

export const downloadOfferRepNegAttachment = (offerRepToken: string) => (fileId: string, fileName: string, updateToken: string) =>
  download(`${config.ctradeUrl}/offerrep/${offerRepToken}/files/${fileId}`, fileName, updateToken);

export const downloadOfferRepOrderAttachment =
  (offerRepToken: string) => (fileId: string, fileName: string, updateToken: string) =>
    download(`${config.ctradeUrl}/offerrep/${offerRepToken}/order/files/${fileId}`, fileName, updateToken);

/**
 *  Download [END]
 */

/**
 *  Deleting [START]
 */

export const deleteNegAttachment =
  (orderId: string, negotiationId: string) =>
  (baseUrl: string, fileId: string, updateToken: string): Observable<{ success: boolean }> =>
    deleteAttachment(`${config.ctradeUrl}/orders/${orderId}/negotiations/${negotiationId}/files/${fileId}`, updateToken);
export const deleteOrderAttachment = (
  baseUrl: string,
  orderId: string,
  fileId: string,
  updateToken: string
): Observable<{ success: boolean }> => deleteAttachment(`${config.ctradeUrl}/orders/${orderId}/files/${fileId}`, updateToken);

/**
 *  Deleting [END]
 */

/**
 * Helper method for deleting
 * @param deleteUrl Fully Qualified URL to the file
 * @param updateToken
 */
const deleteAttachment = (deleteUrl: string, updateToken: string): Observable<{ success: boolean }> => {
  return from(
    fetch(deleteUrl, {
      method: "DELETE",
      headers: {
        ...commonHeaders,
        "x-entity-authorization": `Bearer ${updateToken}`,
      },
    })
      .then((response) => response.json())
      .then(() => {
        return {
          success: true,
        };
      })
      .catch((err) => {
        console.error("an error occured while delete the attachment", err);
        return {
          success: false,
        };
      })
  );
};

/**
 * Helper method for downloading
 * @param downloadUrl Fully Qualified URL to the file
 * @param fileName Filename (plus suffix) so that it can maintain the name of the uploadedfile
 * @param updateToken
 */
const download = (downloadUrl: string, fileName: string, updateToken: string) =>
  fetch(downloadUrl, {
    method: "GET",
    headers: {
      ...commonHeaders,
      "x-entity-authorization": `Bearer ${updateToken}`,
    },
  })
    .then((response) => {
      return response.blob();
    })
    .then((blob) => {
      downloadjs(blob, fileName, "application/octet-stream");
    });

const upload = (uploadUrl: string, formData: FormData, updateToken: string): Observable<UploadAttachmentResult> => {
  return from(
    fetch(uploadUrl, {
      method: "POST",
      headers: {
        ...commonHeaders,
        "x-entity-authorization": `Bearer ${updateToken}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response: FileUploadResponse[]) => processFileUploadResponses(response))
      .catch((err) => {
        console.error("an error occured while creating the attachment", err);
        return new UploadAttachmentResult(false);
      })
  );
};
