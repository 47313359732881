import { isValidElement, ReactElement } from "react";
import { emptyFn } from "___REFACTOR___/utils";
import { Status } from "___REFACTOR___/models";
import { Dialog as Component } from "___REFACTOR___/components/services/Dialog";
import { PromiseBox } from "___REFACTOR___/models/common";
import { action, makeObservable, observable } from "mobx";

class Dialog {
  constructor() {
    this.props = undefined;

    makeObservable(this, { props: observable.ref, open: action, close: action, reset: action });
  }

  private promiseBox?: PromiseBox;

  get isOpen() {
    return !!this.props;
  }

  get promise() {
    return this.promiseBox?.promise;
  }

  get resolve() {
    return this.promiseBox?.resolve || emptyFn;
  }

  open = <Result>(...params: (JSX.Element | Status | Props)[]) => {
    let props: Props;

    params.forEach((param) => {
      if (isValidElement(param)) props = { ...props, children: param };
      //
      else if (Status.isStatus(param)) props = { ...props, status: param };
      //
      else if (param) props = { ...props, ...param };
    });

    this.props = props!;

    const promiseBox = new PromiseBox<Result>();

    this.promiseBox = promiseBox;

    return promiseBox.promise;
  };

  close = (result?: Dialog.Result) => {
    if (!result?.reject) {
      this.promiseBox?.resolve(result);
    } else {
      this.promiseBox?.reject(result);
    }

    this.reset();
  };

  reset = () => {
    this.promiseBox = undefined;
    this.props = undefined;
  };
}

const dialog = new Dialog();
const dialog0 = new Dialog();

export { Dialog, dialog, dialog0 };

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

interface Dialog {
  props?: Props;
}

declare namespace Dialog {
  export { Props };
}

declare namespace Dialog {
  type Result = any;
}

type Props = Component.Props;
