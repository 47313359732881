import React, { useEffect, useState, ReactNode, useRef } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { useSimpleEffect } from "@/utils";
import { Dialog as Model } from "@/models";
import { ButtonProps, Status } from "@/components";
import { Icon } from "../common";
import { Actions } from "./Actions";
import "./Dialog.scss";

function Dialog(props: ComponentProps) {
  const { model } = props;
  const { status, dataTest, content: providedContent, className } = model.props || {};
  const container = useRef<HTMLDialogElement>(null);
  const [visible, setVisible] = useState(false);

  useEffect(setupEvents, [model.open]);
  useSimpleEffect(controlVisibility, [model.open]);

  if (!model.open) return null;

  const res = (
    <dialog className={classNames(className, { visible })} open={model.open} ref={container} data-test={`${dataTest}-dialog`}>
      <div className="dialog-backdrop" onClick={close} />
      <div className="dialog-box-wrapper" hidden={!providedContent && !status}>
        <div className="dialog-box">
          <Content {...model.props} model={model}>
            {providedContent}
          </Content>
        </div>
        <Icon name="close" onClick={close} />
      </div>
    </dialog>
  );

  async function controlVisibility() {
    setVisible(model.open);
  }

  function setupEvents() {
    if (!model.open) return;

    window.addEventListener("keydown", keyDownHandler);

    function keyDownHandler(e: KeyboardEvent) {
      const handler = KEYBOARD_EVENT_HANDLER[e.key];

      if (handler) handler(e);
    }

    return () => {
      window.removeEventListener("keydown", keyDownHandler);
    };
  }

  function close(e: React.MouseEvent) {
    e.stopPropagation();

    model.hide();
  }

  const KEYBOARD_EVENT_HANDLER: KeyboardEventMap = {
    Escape: (e: KeyboardEvent) => {
      e.preventDefault();

      model.hide();
    },
  };

  return res;
}

function Content(props: ContentProps) {
  const { status, children, actions, suppressStatusActions, model } = props;
  const content = useRef<HTMLDivElement>(null);

  return (
    <div className="dialog-box-content" ref={content}>
      <Status status={status} />
      {children}
      <Actions model={model} actions={actions} status={status} suppressStatusActions={suppressStatusActions} />
    </div>
  );
}

const Observer = observer(Dialog);

export { Observer as Dialog };

interface ComponentProps {
  model: Model;
}

interface Props {
  className?: string;
  content?: ReactNode;
  actions?: Action[];
  status?: Status;
  suppressStatusActions?: boolean;
  dataTest?: string;
}

interface ContentProps extends Omit<Props, "dataTest"> {
  model: Model;
  children?: ReactNode;
  dataTest?: string;
}

type Actions = Action[];

interface Action extends Omit<ButtonProps, "children"> {
  label: string;
}

interface KeyboardEventMap {
  [key: string]: (e: KeyboardEvent) => void;
}

export type DialogProps = Props;
export type DialogAction = Action;
