import React from "react";
import styles from "./LineSeparator.module.scss";
import classNames from "classnames";

interface IProps {
  className?: string;
}

const LineSeparator = (props: IProps) => <div className={classNames(styles.container, props.className)} />;

export default LineSeparator;
