import React, { Children, ReactNode, isValidElement, useState } from "react";
import classNames from "classnames";
import * as ClassNames from "classnames";
import "./Tabs.scss";

function Tabs(props: Tabs.Props) {
  const { className: propsClassName, children, ...rest } = props;
  const [activeIndex, setActiveIndex] = useState(0);

  const className = classNames(propsClassName);

  return (
    <>
      <app-form-tabs {...rest} class={className}>
        {Children.map(children, HeadingHOC)}
      </app-form-tabs>
      {Children.map(children, ChildHOC)}
    </>
  );

  function HeadingHOC(reactNode: ReactNode, i: number) {
    let children = "Tab";

    if (isValidElement(reactNode)) children = reactNode.props.title;

    return (
      <Heading isActive={i === activeIndex} onClick={setActiveIndex.bind(null, i)}>
        {children}
      </Heading>
    );
  }

  function ChildHOC(reactNode: ReactNode, i: number) {
    if (i === activeIndex) return reactNode;

    return null;
  }
}

function Heading(props: Heading.Props) {
  const { className: propsClassName, isActive, ...rest } = props;

  const className = classNames(propsClassName, { active: isActive });

  return <app-form-tabs-heading {...rest} class={className} />;
}

export { Tabs };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace Tabs {
  interface Props extends Omit<ReactHTMLFormProps, "className"> {
    className?: ClassNames.Argument;
  }

  type ReactHTMLFormProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
}

declare namespace Heading {
  interface Props extends Omit<ReactHTMLFormProps, "className"> {
    className?: ClassNames.Argument;
    isActive: boolean;
  }

  type ReactHTMLFormProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
}
