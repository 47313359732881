import { setup as REFACTOR_setupAPIs } from "___REFACTOR___/apis";
import { setup as REFACTOR_setupConfig } from "___REFACTOR___/config";
import { setupWithoutOrderNegStores as REFACTOR_setupWithoutOrderNegStores } from "___REFACTOR___/stores";
import { setup as REFACTOR_setupOfficeUiFabric } from "___REFACTOR___/styles/office-ui-frabric-react";

import { setup as setupAPIs } from "@/apis";
import { setup as setupModels, auth, seaHeader, env, setupDummyHeader } from "@/models";
import { setup as setupStores } from "@/stores";
import { setup as setupControllers } from "@/controllers";
import { setup as setupLegacy } from "@/legacy";

export function preauthSetup() {
  REFACTOR_setupOfficeUiFabric();
  setupViewportUnitCSSVarProvision();
  timestampFirstVisit();
}

// sequence matters
export async function setup() {
  await REFACTOR_setupConfig();

  env.setup();

  // await window.__SEA_HEADER_CSS_PROMISE__;
  // await window.__SEA_HEADER_ICON_PROMISE__;

  if (!preventAuth) seaHeader.setup();

  REFACTOR_setupAPIs();
  setupAPIs();

  if (!preventAuth) await auth.setup();

  await setupModels();

  await REFACTOR_setupWithoutOrderNegStores();
  await setupStores();

  setupControllers();

  if (isOfferrep) offerrepSetup();

  setupLegacy();
}

function offerrepSetup() {
  document.body.classList.add("offerrep");

  setupDummyHeader();
}

function timestampFirstVisit() {
  if (!localStorage.FIRST_VISIT_ON) {
    localStorage.FIRST_VISIT_ON = new Date().toISOString();
  }
}

function setupViewportUnitCSSVarProvision() {
  document.documentElement.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);

  window.addEventListener("resize", () => {
    document.documentElement.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
  });
}

const pathname = window.location.pathname;

const isOfferrep = pathname.includes("offerrep");

const preventAuth = isOfferrep || pathname.includes("handle-new-owner-user");
