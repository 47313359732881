import { IOfferRepViewNegotiation } from "negotiations/models/common/IOfferRep";
import * as api from "../../api/negotiations/models";
import { mapToNegotiableView } from "negotiations/services/mapping/subMapping";
import { mapOrderNegType } from "sharedFolder/mappers/mapOrderNegType";
import { mapAttachment } from "negotiations/services/mapping/view/mapAttachment";

const offerRepMapper = (data: api.INegotiation): IOfferRepViewNegotiation => {
  return {
    ...mapToNegotiableView(data),
    groupChat: data.groupChat,
    type: mapOrderNegType.toView(data.type),
    orderNumber: data.orderReference,
    circulatedBy: data.circulatedBy,
    circulatedByRole: data.circulatedByRole,
    orderAttachments: data.orderAttachments?.map(mapAttachment),
    isOfferRepClaimed: data.isOfferRepClaimed,
  } as IOfferRepViewNegotiation;
};

export default offerRepMapper;
