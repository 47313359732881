import { INegotiableView, INegotiableViewOptional } from "negotiations/models/NegotiationCreateView";
import { AllNegotiableDetailKeys } from "negotiations/components/negotiate/NegotiableTypes";

const defaultToNegotiable: AllNegotiableDetailKeys[] = ["laycan", "period", "duration", "commercialSubs", "operationalSubs"];
const defaultToIncluded: AllNegotiableDetailKeys[] = [
  "liftings",
  "coaCargoSize",
  "laycan",
  "period",
  "duration",
  "vesselSize",
  "cargoType",
  "nominations",
  "commercialSubs",
  "operationalSubs",
];

export function mapToNegotiableView<TViewModel, TApiModel>(
  mapType: AllNegotiableDetailKeys,
  emptyViewModel: TViewModel,
  viewModel?: TViewModel,
  negotiableApiModel?: TApiModel
): INegotiableView<TViewModel> {
  return {
    negotiable: defaultToNegotiable.some((x) => x === mapType),
    included: defaultToIncluded.some((x) => x === mapType),
    value: viewModel || emptyViewModel,
  };
}

export interface IMapper<TApiModel, TViewModel> {
  toApi(viewModel: TViewModel): TApiModel;
  toView(apiModel: TApiModel): TViewModel;
  emptyViewModel: TViewModel;
}

export interface IMapperNullable<TApiModel, TViewModel> extends IMapper<TApiModel, TViewModel> {
  /**
   * Returns undefined or apimodel
   * @param viewModel ViewModel or undefined
   */
  toApiOrUndefined(viewModel?: TViewModel): TApiModel | undefined;
  /**
   * Returns undefined or ViewModel
   * @param apiModel ApiModel or undefined
   */
  toViewOrUndefined(apiModel?: TApiModel): TViewModel | undefined;
  /**
   * if ApiModel is undefined will return the empty model instead of undefined
   * @param apiModel ApiModel or undefined
   */
  toViewOrEmpty(apiModel?: TApiModel): TViewModel;
}

export const withNullable = <TApiModel, TViewModel>(
  mapper: IMapper<TApiModel, TViewModel>
): IMapperNullable<TApiModel, TViewModel> => ({
  toApiOrUndefined: (viewModel?: TViewModel) => viewModel && mapper.toApi(viewModel),
  toViewOrUndefined: (apiModel?: TApiModel) => apiModel && mapper.toView(apiModel),
  toViewOrEmpty: (apiModel?: TApiModel) => (apiModel ? mapper.toView(apiModel) : mapper.emptyViewModel),
  ...mapper,
});

export const withEmpty =
  <TApiModel, TViewModel>(mapper: IMapper<TApiModel, TViewModel>) =>
  (apiModel?: TApiModel) => {
    if (apiModel) {
      return mapper.toView(apiModel);
    }
    return mapper.emptyViewModel;
  };

export const toNegotiableApiOptional =
  <TApiModel, TViewModel>(mapper: IMapper<TApiModel, TViewModel>) =>
  (viewModel?: INegotiableViewOptional<TViewModel>) => {
    if (!viewModel || !viewModel.included) {
      return undefined;
    }
    return {
      negotiable: Boolean(viewModel.negotiable),
      value: viewModel.value ? mapper.toApi(viewModel.value) : undefined,
    };
  };
export const toNegotiableApi =
  <TApiModel, TViewModel>(mapper: IMapper<TApiModel, TViewModel>) =>
  (viewModel: INegotiableView<TViewModel>) => {
    return {
      negotiable: Boolean(viewModel.negotiable),
      value: mapper.toApi(viewModel.value),
    };
  };

export const mapApiToNegotiableViewOptional =
  <TApiModel, TViewModel>(mapper: IMapper<TApiModel, TViewModel>) =>
  (apiModel?: TApiModel): INegotiableViewOptional<TViewModel> => {
    return !apiModel
      ? {
          included: true,
          negotiable: false,
        }
      : {
          included: true,
          negotiable: false,
          value: apiModel && mapper.toView(apiModel),
        };
  };
