import React from "react";
import { RadioInput } from "___REFACTOR___/components";
import { Field } from "../../Field";
import { Container } from "../../Container";

function RadioField(props: RadioField.Props) {
  return (
    <Container {...props} type="Radio">
      <RadioInput {...props} />
    </Container>
  );
}

export { RadioField };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace RadioField {
  export interface Props extends Omit<RadioInput.Props, "type">, Omit<Field.Props, "value" | "onChange"> {}

  export { RadioInput as Input };
}
