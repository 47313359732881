import React, { useContext, useEffect, useState, useMemo, useCallback } from "react";
import { Route, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import * as PathToRegexp from "path-to-regexp";
import { APP_DEVELOPMENT } from "@/config";
import { lowerCaseFirstLetter, useSimpleEffect } from "@/utils";
import { history } from "@/history";
import * as Models from "@/models";
import { CollapsedLabel, Overlay, Status } from "@/components";
import { Context } from "../Orders";
import { getOrder as getOrderAction } from "../actions";
import { Header } from "./Header";
import { Initialization, Negotiations, Distribution, Circulate, Attachments, COAAttachments, COADetails, Liftings } from "./";
import { VesselScoreAPI, vesselScoreAPI } from "@/apis";
import { debounce } from "@material-ui/core";
import { vesselScoreStore } from "@/stores";
import { useFlags } from "launchdarkly-react-client-sdk";
import "./Order.scss";

function Order(props: Props) {
  const { orderId } = useParams<StringRecord>();
  const [errorStatus, setErrorStatus] = useState<Status | undefined>();
  const context = useContext(Context);
  const { view, orderNegotiationStore, routes } = context;
  const order = orderId ? orderNegotiationStore?.upsertOrderModel({ id: orderId }) : undefined;
  const version = order?._.version; // eslint-disable-line @typescript-eslint/no-unused-vars
  const negs = order?._.orderNegotiationStoreOrderNegotiationArray;
  const stage = order?.getStage();
  const pageTitle = order?.getPageTitle();
  const pathMatcher = useMemo(getPathMatcher, []);
  const pathCompiler = useMemo(getPathCompiler, []);
  const componentMatchers = useMemo(getComponentMatchers, [orderId]);
  const ComponentByActivePath = getComponentByActivePath();
  const updateNegsWithVesselScoresDebounced = useCallback(debounce(updateNegsWithVesselScores, 100), [orderId]);
  const flags = useFlags();
  const pollFrequency = flags.vesselScoreApiPollFrequency;

  useEffect(onMount, []);
  useEffect(navigateToStage, [stage]);
  useSimpleEffect(setPageTitle, [pageTitle]);
  useSimpleEffect(getOrder, [orderId]);
  useEffect(setupVesselScorePoll, [orderId]);
  useSimpleEffect(updateNegsWithVesselScoresDebounced, [orderId, negs, order?.version]);
  useEffect(clearErrorStatus, [orderId]);
  setSeenVersion();

  if (errorStatus) return <Status className="order-error-status" status={errorStatus} />;

  return (
    <>
      <Header status={stage} withdrawalState={order?.withdrawalState} ribbonContext="Order" />
      {props.children}
      <ComponentByActivePath />
      <Overlay status={order?._.status} hidden={view.order.suppressOverlay} />
      {/* TODO JOGI - Hacky displaying text under the collapsed button */}
      <CollapsedLabel />
    </>
  );

  function onMount() {
    context.onOrderComponentMount?.(orderId);

    return onUnmount;
  }

  function onUnmount() {
    context.onOrderComponentUnmount?.(orderId);
  }

  function setupVesselScorePoll() {
    return clearInterval.bind(null, setInterval(updateNegsWithVesselScoresDebounced, pollFrequency));
  }

  async function updateNegsWithVesselScores() {
    if (!vesselScoreStore.security.sectionAccess.hasAccess) return;

    const negs = order?._.orderNegotiationStoreOrderNegotiationArray;

    if (!negs?.length) return;

    const vesselScoreResponse = await vesselScoreAPI.getAllResults(negs);

    negs.forEach((neg) => {
      if (vesselScoreResponse instanceof Map) {
        const newMap = new Map<string, VesselScoreAPI.VesselScore>();
        neg.vessels.forEach((vessel) => {
          const imo = vessel.vesselImo.toString();
          const vesselScore = vesselScoreResponse.get(vessel.vesselImo.toString());
          if (vesselScore) newMap.set(imo, vesselScore);
        });
        neg._.vesselScoreMap = newMap;
        neg._.vesselScoreError = undefined;
      } else {
        neg._.vesselScoreError = vesselScoreResponse;
      }
    });

    if (!orderNegotiationStore) return;

    orderNegotiationStore.upsertNegotiations(negs, { forceUpdate: true });
  }

  async function getOrder() {
    const res = await getOrderAction(order, context);

    if (!res) return;

    setErrorStatus(order?._.status);
  }

  async function setPageTitle() {
    if (pageTitle) document.title = APP_DEVELOPMENT ? `Sea/trade [RF] Orders ${pageTitle}` : `Sea/trade Orders ${pageTitle}`;
  }

  async function setSeenVersion() {
    if (!order) return;

    orderNegotiationStore?.setSeenVersion(order);
  }

  function navigateToStage() {
    let stageLowercase = lowerCaseFirstLetter(stage) as Models.Order["StageLowercase"];

    if (stageLowercase === "firmOffer" || stageLowercase === "firmBid") stageLowercase = "active";

    if (!stageLowercase) return;

    const match = pathMatcher(window.location.pathname);

    if (!match) return;

    const nextStatusRoute = routes.order[stageLowercase] as Models.Route;
    const doesNextStatusRouteHaveCurrentTab = !!nextStatusRoute.children.find(nextStatusRouteTabChildSelector);

    if (!doesNextStatusRouteHaveCurrentTab) {
      delete match.params.tab;
    }

    const nextPath = pathCompiler({ ...match.params, orderStage: stageLowercase });

    history.push(nextPath, { ignoreUnsavedChanges: true });

    function nextStatusRouteTabChildSelector(route: Models.Route) {
      if (!match) return;

      const tabPath = `/${match.params.tab}`;

      if (route.path === "/circulate/:noOfUsers?" && tabPath === "/circulate") return true;

      return route.path === tabPath;
    }
  }

  function clearErrorStatus() {
    setErrorStatus(undefined);
  }

  function getComponentByActivePath() {
    for (let i = 0; i < componentMatchers.length; i++) {
      const { matcher, orderMatcher, Component } = componentMatchers[i];
      if (orderMatcher && order && !orderMatcher(order)) {
        continue;
      }

      if (matcher(window.location.pathname)) {
        return Component;
      }
    }

    return ComponentFallback;
  }

  function getPathMatcher() {
    const matcher = PathToRegexp.match<PathParams>("/:page/:orderId/:orderStage?/:tab?/:otherParam?", { end: false });

    return matcher;
  }

  function getPathCompiler() {
    const compiler = PathToRegexp.compile<PathParams>("/:page/:orderId/:orderStage?/:tab?/:otherParam?");

    return compiler;
  }

  function getComponentMatchers() {
    return [
      {
        matcher: PathToRegexp.match("/:page/:orderId/withdrawn/negotiations", { end: false }),
        orderMatcher: (order: Models.Order) => !order.areThereAnyNegs(),
        Component: Initialization,
      },
      { matcher: PathToRegexp.match("/:page/:orderId/:orderStage/initialization", { end: false }), Component: Initialization },
      { matcher: PathToRegexp.match("/:page/:orderId/:orderStage/negotiations", { end: false }), Component: Negotiations },
      { matcher: PathToRegexp.match("/:page/:orderId/:orderStage/distribution", { end: false }), Component: Distribution },
      {
        matcher: PathToRegexp.match("/:page/:orderId/:orderStage/circulate/:noOfUsers?", { end: false }),
        Component: CirculateWithRoute,
      },
      { matcher: PathToRegexp.match("/:page/:orderId/:orderStage/attachments", { end: false }), Component: Attachments },
      { matcher: PathToRegexp.match("/:page/:orderId/:orderStage/coa-details", { end: false }), Component: COADetails },
      { matcher: PathToRegexp.match("/:page/:orderId/:orderStage/coa-attachments", { end: false }), Component: COAAttachments },
      { matcher: PathToRegexp.match("/:page/:orderId/:orderStage/liftings", { end: false }), Component: Liftings },
    ];
  }
}

function ComponentFallback() {
  return null;
}

function CirculateWithRoute() {
  return (
    <Route path="/:page/:orderId/:orderStage/circulate/:noOfUsers?">
      <Circulate />
    </Route>
  );
}

const Observer = observer(Order);

export { Observer as Order };

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

interface Props {
  children?: React.ReactNode;
}

interface PathParams {
  page?: "coas" | "orders";
  orderId?: string;
  orderStage?: Models.Order["StageLowercase"];
  tab?:
    | "initialization"
    | "negotiations"
    | "distribution"
    | "circulate"
    | "attachments"
    | "coa-details"
    | "coa-attachments"
    | "liftings";
  otherParam?: "noOfUsers";
}
