import React from "react";
import { TextareaInput } from "___REFACTOR___/components";
import { Field } from "../../Field";
import { Container } from "../../Container";

function TextareaField(props: TextareaField.Props) {
  return (
    <Container {...props} type="Textarea">
      <TextareaInput {...props} />
    </Container>
  );
}

export { TextareaField };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace TextareaField {
  export interface Props extends Omit<TextareaInput.Props, "type">, Omit<Field.Props, "value" | "onChange"> {}

  export { TextareaInput as Input };
}
