import { NegotiationArchivingResponsePayload, tradeAPI } from "@/apis";
import { Negotiation } from "@/models";
import { ArchivingAction, BaseArchivingAction } from "./BaseArchivingAction";
import { config as tradeConfig } from "@/config";

export class NegArchivingAction extends BaseArchivingAction<Negotiation, NegotiationArchivingResponsePayload> {
  protected countOfRetries = tradeConfig.archiveWaitPolicy?.singleWaitRetryCount ?? this.SingleDefaultWaitRetryCount;
  protected retryDelay = tradeConfig.archiveWaitPolicy?.singleWaitRetryDelayMs ?? this.DefaultRetryDelayMs;
  protected actionName = ArchivingAction.Archive;
  protected operationName = "Archiving";
  protected finishedOperationName = "archived";
  protected entityName = "negotiation";

  protected getEntityArray = () => [this.model];

  protected isModelUpdatedAsync = async () => this.getValidationAction()(this.model);

  protected archivingActionAsync = async () => {
    const payload = {
      orderId: this.model.orderId,
      negotiationId: this.model.id,
      updateToken: this.model.updateToken,
    };

    return await tradeAPI.archiveNegotiation(payload);
  };

  protected getArrayStatus = (): Status | undefined => {
    return this.context.orderNegotiationStore?.negotiationArrayStatus;
  };
}
