import { TradeAPI } from "@/apis";
import { isTruthy } from "@/utils";
import { VesselEditor } from "@/components";
import { LABELS } from "@/constants";
import { DataModel, DataModelProps } from "../DataModel";

class Vessel extends DataModel<Data> {
  onUpdate() {
    this.vesselImo = this.vesselImo || this.vesselIMO;
    this.vesselIMO = this.vesselImo || this.vesselIMO;

    delete this.display;
    delete this.shortDisplay;
  }

  toString() {
    return [
      this.registrationDataName && `${this.registrationDataName} -`,
      this.vesselIMO && `IMO ${this.vesselIMO}`,
      this.buildYear && `YOB ${this.buildYear}`,
      this.dwt && `DWT ${this.dwt}`,
    ]
      .filter(isTruthy)
      .join(" ");
  }

  toGridView() {
    if (!this) return "";

    if (!this.registrationDataName) return "";

    return this.registrationDataName;
  }
}

class VesselProps extends DataModelProps<Data> {}

VesselProps.prototype.editor = VesselEditor;
VesselProps.prototype.label = LABELS["vessels"];

Vessel.prototype._ = new VesselProps();

function newVessel(vessel: Data) {
  return new Vessel(vessel);
}

export { Vessel, VesselProps, newVessel };

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

interface Vessel extends Data {
  _: VesselProps;
}

type Data = DeepPartial<TradeAPI["Vessel"]>;
