import { ReactNode, useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useSimpleEffect } from "@/utils";
import { CollapsedLabel, Status } from "@/components";
import { Header } from "../Order/Header";
import { Context } from "../Orders";
import { getOrder as getOrderAction, getNegotiation as getNegotiationAction } from "../actions";
import { WithdrawalState } from "@/apis";
import { Stage } from "@/models";

export function OrderStub(props: Props) {
  const { orderId, negotiationId } = useParams<StringRecord>();
  const [errorStatus, setErrorStatus] = useState<Status | undefined>();
  const context = useContext(Context);
  const order = orderId ? context.orderNegotiationStore?.upsertOrderModel({ id: orderId }) : undefined;
  const negotiation = order?.upsertNegotiation({ id: negotiationId });
  const [stage, setStage] = useState<Stage | undefined>();
  const [withdrawalState, setWithdrawalState] = useState<WithdrawalState | undefined>();

  useEffect(onMount, []);
  useSimpleEffect(getOrder, [negotiationId]);
  useSimpleEffect(getNegotiation, [negotiationId]);
  useEffect(resetErrorStatus, [negotiationId]);

  if (errorStatus) return <Status className="order-error-status" status={errorStatus} />;

  return (
    <>
      <Header status={stage} withdrawalState={withdrawalState} ribbonContext="Negotiation" />
      {props.children}
      <CollapsedLabel />
    </>
  );

  function onMount() {
    context.onOrderComponentMount?.(orderId);

    return onUnmount;
  }

  function onUnmount() {
    context.onOrderComponentUnmount?.(orderId);
  }

  async function getOrder() {
    const res = await getOrderAction(order, context);
    setWithdrawalState(order?.withdrawalState);
    setStage(order?.getStage());
    if (!res) return;

    setErrorStatus(order?._.status);
  }

  async function getNegotiation() {
    const res = await getNegotiationAction(negotiation, context, true);

    if (!res) return;

    setErrorStatus(negotiation?._.status);
  }

  function resetErrorStatus() {
    setErrorStatus(undefined);
  }
}

interface Props {
  children?: ReactNode;
}
