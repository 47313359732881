import { TextInput as Text } from "./Text";
import { NumberInput as Number } from "./Number";
import { SelectInput as Select } from "./Select";
import { TextareaInput as Textarea } from "./Textarea";
import { CheckboxInput as Checkbox } from "./Checkbox";
import { DateInput as Date } from "./Date";
import { RadioInput as Radio } from "./Radio";
import { DateOffsetInput as DateOffset } from "./DateOffset";
import { RangeInput as Range } from "./Range";

export const INPUT_TYPE = {
  Text,
  Number,
  Select,
  Checkbox,
  Textarea,
  Date,
  Radio,
  DateOffset,
  Range,
};

export type InputType = keyof typeof INPUT_TYPE;
