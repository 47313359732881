import React, { useReducer, useEffect, useState, useRef } from "react";
import Downshift, { DownshiftState, StateChangeOptions } from "downshift";
import styles from "./LookUpSingleEntry.module.scss";
import classNames from "classnames";
import LoadingSpinner from "../../common/LoadingSpinner/LoadingSpinner";
import Button from "__legacy/sharedFolder/components/common/Button/Button";

interface ILookUpSingleEntryProps {
  placeholder?: string;
  disabled?: boolean;

  /**
   * The Id of the auto complete component
   */
  id: string;

  /**
   * The Label of the auto complete component
   */
  label?: string;

  /**
   * On change handler
   */
  onChange: <T>(optionSelected: T | undefined) => void;

  /**
   * The function to call in order to get the data for the auto complete
   */
  autoCompleteFunction: (filterText: string) => Promise<any[]>;

  /**
   * Method to resolve the display text of the underlying data item
   */
  getDisplayText: (item: any) => string;

  /**
   * Text to display in case no result found
   */
  noResultsFoundText?: string;

  /**
   * The default value (optional)
   */
  defaultValue?: any;

  /**
   * Whether the field is considered valid or not (optional)
   */
  isValid?: boolean;

  /**
   * Specifies whether the component will be autofocus when mounted
   */
  focus?: boolean;
}

interface ILookUpSingleEntryState {
  lastSearchTerm: string;
  items: any[];
  isLoading: boolean;
}

interface ILookUpSingleEntryPayload {
  term: string;
  items: any[];
}

// Keeps a reference to the previous value
function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function LookUpSingleEntry({
  placeholder,
  disabled,
  id,
  label,
  onChange,
  autoCompleteFunction,
  getDisplayText,
  defaultValue,
  noResultsFoundText,
  focus,
  isValid = true,
}: ILookUpSingleEntryProps) {
  function lookUpReducer(
    state: ILookUpSingleEntryState,
    action: {
      type: "setSearchTerm" | "resolveSearch";
      payload: ILookUpSingleEntryPayload;
    }
  ) {
    switch (action.type) {
      case "setSearchTerm":
        return {
          ...state,
          lastSearchTerm: action.payload.term,
          items: [],
          isLoading: true,
        };
      case "resolveSearch":
        // resolve the search if the search term being resolved is the same
        // as the last search term entered by the user
        // this is a guard against the penultimate search term request resolving
        // last and stale data being displayed to the user
        if (state.lastSearchTerm === action.payload.term) {
          return { ...state, items: action.payload.items, isLoading: false };
        }
        return { ...state };
      default:
        throw new Error();
    }
  }
  const inputRef = useRef<HTMLInputElement>(null);

  const [lookUpState, lookUpDispatch] = useReducer(lookUpReducer, {
    lastSearchTerm: "",
    items: [],
    isLoading: false,
  });

  const previousFocusValue = usePrevious(focus);
  useEffect(() => {
    if (previousFocusValue !== focus && focus) {
      inputRef?.current?.focus();
    }
  });
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    setSelectedItem(defaultValue === undefined ? null : defaultValue);
  }, [defaultValue]);

  const onInputValueChange = async (inputValue: string) => {
    if (!selectedItem && inputValue) {
      lookUpDispatch({
        type: "setSearchTerm",
        payload: { term: inputValue, items: [] },
      });
      const data = await autoCompleteFunction(inputValue);
      lookUpDispatch({
        type: "resolveSearch",
        payload: { term: inputValue, items: data },
      });
    }
  };

  const itemToString = (item: any) => {
    if (!item) return "";
    return getDisplayText(item);
  };

  const { isLoading, items } = lookUpState;
  function stateReducer(state: DownshiftState<any>, changes: StateChangeOptions<any>) {
    switch (changes.type) {
      // These represent event types when the look up is selected by the user and/or when the
      // Downshift internal state for 'selectedItem' has changed
      case Downshift.stateChangeTypes.clickItem:
      case Downshift.stateChangeTypes.keyDownEnter:
      case Downshift.stateChangeTypes.controlledPropUpdatedSelectedItem:
        if (changes.selectedItem) setSelectedItem(changes.selectedItem);
        return changes;
      default:
        return changes;
    }
  }

  return (
    <Downshift
      selectedItem={selectedItem}
      itemToString={itemToString}
      onInputValueChange={onInputValueChange}
      onSelect={(selectedItem) => {
        onChange(selectedItem);
      }}
      stateReducer={stateReducer}
    >
      {({
        isOpen,
        getToggleButtonProps,
        getMenuProps,
        getInputProps,
        highlightedIndex,
        getItemProps,
        selectedItem,
        clearSelection,
      }) => {
        return (
          <div className={styles.outerContainer}>
            {label}
            <div className={classNames(styles.container)} id={id} data-test={id}>
              <input
                {...getInputProps()}
                className={classNames(!isValid && "error-state", styles.input)}
                placeholder={placeholder}
                disabled={disabled}
                spellCheck={false}
                data-test={`${id}-look-up-input`}
                autoFocus={focus}
                ref={inputRef}
              />
              {selectedItem && (
                <Button
                  className={styles.controllerButton}
                  icon="cancel"
                  {...getToggleButtonProps()}
                  onClick={() => {
                    clearSelection();
                    setSelectedItem(null);
                  }}
                  dataTest="cancel"
                />
              )}
            </div>
            <ul {...getMenuProps()} className={styles.menuStyles}>
              {isOpen && isLoading && (
                <li className={styles.loading}>
                  Loading <LoadingSpinner />
                </li>
              )}
              {isOpen &&
                !isLoading &&
                items.length > 0 &&
                items.map((item, index) => (
                  <li
                    style={
                      highlightedIndex === index
                        ? { backgroundColor: "#0f4570" } // TODO
                        : {}
                    }
                    key={`${getDisplayText(item)}${index}`}
                    className={styles.item}
                    data-test={`sug-${index}`}
                    {...getItemProps({ item, index })}
                  >
                    {getDisplayText(item)}
                  </li>
                ))}
              {isOpen && !isLoading && items.length === 0 && (
                <li className={styles.item}>{noResultsFoundText || "No Results Found"}</li>
              )}
            </ul>
          </div>
        );
      }}
    </Downshift>
  );
}
