import React, { useState } from "react";
import { archiveRequestsLimit } from "sharedFolder/constants";
import Button from "__legacy/sharedFolder/components/common/Button/Button";
import Checkbox from "__legacy/sharedFolder/components/common/Checkbox/Checkbox";
import Tooltip from "../Editor/type/TermsetBuilder/Tooltip";
import { testSelectors } from "@/constants";
import { Context } from "../../Orders";
import { CheckboxState } from "@/utils/archiveHelpers";
import { BulkPanelAction } from "@/components/Orders/Grid/BaseGrid";
import "./BulkActionsPanel.scss";

export interface BulkActionsPanelProperties {
  actions: BulkPanelAction[] | undefined;
  closeAction: (() => void) | undefined;
  onCheckboxClick: () => void;
  selectedRowsCount: number;
  setMultiSelectCheckboxState: React.Dispatch<React.SetStateAction<CheckboxState>>;
  checkboxState: string;
  context: Context;
}

export const BulkActionsPanel = (props: BulkActionsPanelProperties) => {
  const { actions, closeAction, selectedRowsCount, checkboxState, onCheckboxClick } = props;
  const multiSelectCheckbox = document.getElementById("multi-select") as HTMLInputElement;
  const [openTooltip, setOpenTooltip] = useState(false);
  const [actionInProgress, setActionInProgress] = useState(false);

  if (multiSelectCheckbox) {
    if (checkboxState === "indeterminate") {
      multiSelectCheckbox.indeterminate = true;
    } else if (checkboxState === "checked") {
      multiSelectCheckbox.indeterminate = false;
      multiSelectCheckbox.checked = true;
    } else {
      multiSelectCheckbox.indeterminate = false;
      multiSelectCheckbox.checked = false;
    }
  }

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    if (checkboxState !== "checked") {
      setOpenTooltip(true);
    }
  };

  return (
    <div className="bulk-container">
      <Tooltip
        open={openTooltip}
        onOpen={handleTooltipOpen}
        onClose={handleTooltipClose}
        placement="bottom"
        title={
          <div className="tooltip-container">
            <p className="enhanced-readability">
              {checkboxState !== "checked"
                ? `Select the top ${archiveRequestsLimit} rows`
                : `Top ${archiveRequestsLimit} rows selected`}
            </p>
          </div>
        }
        interactive
        enterDelay={500}
        leaveDelay={100}
      >
        <label className="label">
          <Checkbox
            disabled={actionInProgress}
            scale="small"
            onClick={() => onCheckboxClick()}
            id="multi-select"
            dataTest={testSelectors.bulkSelectAllCheckbox}
          />
        </label>
      </Tooltip>

      <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <div className="buttons-container">
          {actions?.map((action, index) => {
            return (
              <Button
                dataTest={action.dataTest}
                key={index}
                variant="action"
                className="secondary action flat"
                icon={action.icon}
                onClick={async () => {
                  setActionInProgress(true);
                  try {
                    await action.onClick();
                  } finally {
                    setActionInProgress(false);
                  }
                }}
                disabled={!selectedRowsCount || actionInProgress}
              >
                {action.label}
              </Button>
            );
          })}
        </div>
        <Button type="link" onClick={closeAction} dataTest={testSelectors.closeBulkPanelButton}>
          Close
        </Button>
      </div>
    </div>
  );
};
