import classNames from "classnames";
import { useEffect, useState } from "react";
import { useAPIFetch } from "./useAPIFetch";
import { IEmailList, IEmailListDELETE } from "../../services/settings";
import Button from "__legacy/sharedFolder/components/common/Button/Button";
import styles from "./DistributionListPanel.module.scss";
import modalStyles from "__legacy/sharedFolder/styles/modal-styles.module.scss";
import { DistributionGroupNameEditor } from "./DistributionGroupNameEditor";
import { NewDistributionEmailForm } from "./NewDistributionEmailForm";
import { DistributionEmail } from "./DistributionEmail";
import { Modal } from "office-ui-fabric-react";

interface IDistributionGroup {
  currentList: IEmailList[];
  deleteList?: (deletedItemId: string) => void;
  expandEditor?: boolean;
  expandList: boolean;
  mode: "edit" | "select";
  onGroupSelection?: (emails: string[]) => void;
  item: IEmailList;
  toggleEditor?: () => void;
  toggleList: () => void;
  updateList?: (updatedItem: IEmailList) => void;
}

export const DistributionGroup = ({
  currentList,
  deleteList,
  expandEditor,
  mode,
  expandList,
  onGroupSelection,
  toggleEditor,
  toggleList,
  item,
  updateList,
}: IDistributionGroup) => {
  const [currentlyOpenedEmailEditor, setCurrentlyOpenedEmailEditor] = useState<null | string>();
  const [deleteDialogOpened, setDeleteDialogOpened] = useState(false);
  const [newEmailFormExpanded, setNewEmailFormExpanded] = useState(false);
  const [deletedListBody, setDeletedListBody] = useState<null | IEmailListDELETE>(null);
  const deleteAction = useAPIFetch<IEmailListDELETE>(`/settings/emaillists/${item.id}`, "DELETE", deletedListBody);
  // Empty updateTokens mean that the item has been added optimistically but a confirmation hasn't been received from the server
  // in this case, we don't allow the user to interact with the group
  const itemCanBeUpdated = item.updateToken;

  useEffect(() => {
    if (mode !== "edit") {
      return;
    }
    function softDeleteGroupName() {
      // if mode is "edit" then deleteList has to be provided
      deleteList && deleteList(item.id);
    }

    if (deleteAction && deleteAction.response) {
      softDeleteGroupName();
      setDeleteDialogOpened(false);
    }
  }, [deleteList, item, deleteAction, mode]);

  function submitDelete() {
    setDeletedListBody({
      updateToken: item.updateToken,
    });
  }

  return (
    <div className={styles.groupContainer}>
      {deleteDialogOpened && (
        <Modal isOpen={true} onDismiss={() => setDeleteDialogOpened(false)} className="modalPanel">
          <div className={modalStyles.modalContainer}>
            <p>{`Are you sure you want to delete the email group '${item.name}'?`}</p>
            {deleteAction.error && <p className={styles.formErrorMessage}>{deleteAction.error}</p>}
          </div>
          <div className={modalStyles.modalOptions}>
            <Button type="action" ariaLabel="delete emaillist" disabled={deleteAction.fetching} onClick={submitDelete}>
              OK
            </Button>
            <Button type="flat" onClick={() => setDeleteDialogOpened(false)} ariaLabel="delete emaillist cancel">
              Cancel
            </Button>
          </div>
        </Modal>
      )}
      {expandEditor && mode === "edit" && updateList && toggleEditor ? (
        <div>
          Edit Group Name
          <DistributionGroupNameEditor
            item={item}
            currentList={currentList}
            onEditorClose={toggleEditor}
            updateList={updateList}
          />
        </div>
      ) : (
        <div className={classNames(styles.groupRow, expandList && styles.highlighted)}>
          <Button icon={expandList ? "chevron-down" : "chevron-right"} type="plain" onClick={toggleList} />
          <div className={styles.groupName} onClick={toggleList}>
            {item.name} ({item.values.length}) {!!item.sharing?.length && <email-list-tag>shared</email-list-tag>}
          </div>
          <div className={styles.alignedRight}>
            {mode === "edit" && itemCanBeUpdated ? (
              <>
                <Button
                  ariaLabel="edit-group"
                  dataTest="edit-group"
                  type="plain"
                  icon="mode-edit"
                  title="Edit Group"
                  onClick={toggleEditor}
                />
                <Button
                  ariaLabel="delete-group"
                  dataTest="delete-group"
                  icon="delete"
                  type="plain"
                  title="Delete Group"
                  onClick={() => setDeleteDialogOpened(true)}
                />
              </>
            ) : mode === "select" && item.values.length ? (
              <Button
                className={styles.useButton}
                type="flat"
                onClick={() => onGroupSelection && onGroupSelection(item.values)}
                ariaLabel="Use"
              >
                Use
              </Button>
            ) : null}
          </div>
        </div>
      )}
      {expandList && (
        <>
          <div className={styles.emailsContainer}>
            {item.values &&
              item.values.map((email) => (
                <DistributionEmail
                  key={email}
                  item={item}
                  mode={mode}
                  expandEditor={currentlyOpenedEmailEditor === email}
                  toggleEditor={
                    currentlyOpenedEmailEditor === email
                      ? () => setCurrentlyOpenedEmailEditor(null)
                      : () => setCurrentlyOpenedEmailEditor(email)
                  }
                  email={email}
                  updateList={updateList}
                />
              ))}
          </div>
          {mode === "edit" && itemCanBeUpdated && updateList && (
            <div>
              {!newEmailFormExpanded ? (
                <div className={styles.newEmailContainer}>
                  <Button
                    className={styles.createNewEmailBtn}
                    type="flat"
                    icon="plus"
                    onClick={() => setNewEmailFormExpanded(true)}
                    ariaLabel="Add contacts"
                  >
                    Add contacts
                  </Button>
                </div>
              ) : (
                <NewDistributionEmailForm
                  item={item}
                  currentEmails={item.values}
                  onFormClose={() => setNewEmailFormExpanded(false)}
                  updateList={updateList}
                />
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};
