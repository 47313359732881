import { createBrowserHistory, Location } from "history"; // is installed by react-router
import { match, compile } from "path-to-regexp";
import { coaOrderNegotiationStore, dialog, standardOrderNegotiationStore, unsavedChanges } from "@/models";
import { orderNegototiation } from "@/controllers/OrderNegotiation";

export const history = createBrowserHistory({
  async getUserConfirmation(status, callback) {
    if (status === "ignore") return callback(false);

    const { res } = await dialog.show({
      status: STATUS[status],
      dataTest: "unsaved-changes",
    });

    callback(res);
  },
});

history.block((location) => {
  if (location.pathname === "/orders/new") {
    history.push("/orders/$0/new/creation");
    return "ignore";
  }
  if (location.pathname === "/orders/capture") {
    history.push("/orders/$0/new/capture");
    return "ignore";
  }
  if (location.pathname === "/coas/orders/new") {
    history.push("/coas/$0/new/creation");
    return "ignore";
  }
  if (location.pathname === "/coas/orders/capture") {
    history.push("/coas/$0/new/capture");
    return "ignore";
  }
  const state = location.state as HistoryState | null;
  const ordersRerouteStatus = rerouteOrders(location, "/orders", "/orders");
  const ordersArchiveRerouteStatus = rerouteOrders(location, "/orders-archive", "/orders-archive");
  const coasRerouteStatus = rerouteOrders(location, "/coas", "/coas/orders");
  const coasArchiveRerouteStatus = rerouteOrders(location, "/coas-archive", "/coas-archive/orders");
  const ownersRerouteStatus = rerouteOwners(location, "/owner-dashboard", "/orders");
  const ownersArchiveRerouteStatus = rerouteOwners(location, "/owner-archive", "/orders-archive");
  const likelyToLoseChanges = !location.pathname.includes(window.location.pathname);

  if (ordersRerouteStatus) return ordersRerouteStatus;
  if (ordersArchiveRerouteStatus) return ordersArchiveRerouteStatus;
  if (coasArchiveRerouteStatus) return coasArchiveRerouteStatus;
  if (coasRerouteStatus) return coasRerouteStatus;
  if (ownersArchiveRerouteStatus) return ownersArchiveRerouteStatus;
  if (ownersRerouteStatus) return ownersRerouteStatus;

  let ignoreUnsavedChanges = false;

  if (typeof state?.ignoreUnsavedChanges === "boolean") {
    ignoreUnsavedChanges = state?.ignoreUnsavedChanges;
  }

  if (unsavedChanges.exist && !ignoreUnsavedChanges && likelyToLoseChanges) {
    return "unsavedChanges";
  }
});

function rerouteOrders(location: Location, root: string, legacyRoot: string) {
  const ordersPath = `${root}/:orderId/:status?/:dontCare?/:dontCare0?`;
  const ordersMatcher = match(ordersPath);
  const ordersCompiler = compile(ordersPath);
  const ordersMatch = ordersMatcher(window.location.pathname);

  const circulatePath = `${root}/:orderId/:status/circulate/:noOfUsers`;
  const circulateCompiler = compile(circulatePath);

  const distributionPath = `${root}/:orderId/:status/distribution`;
  const distributionCompiler = compile(distributionPath);

  const legacyCirculatePath = `${legacyRoot}/:orderId/circulate/:noOfUsers?`;
  const legacyCirculateMatcher = match(legacyCirculatePath);
  const legacyCirculateMatch = legacyCirculateMatcher(location.pathname);

  const legacyDistributionPath = `${legacyRoot}/:orderId/distribution`;
  const legacyDistributionMatcher = match(legacyDistributionPath);
  const legacyDistributionMatch = legacyDistributionMatcher(location.pathname);

  const legacyNegotiationsPath = `${legacyRoot}/:orderId/negotiations`;
  const legacyNegotiationsMatcher = match(legacyNegotiationsPath);
  const legacyNegotiationsMatch = legacyNegotiationsMatcher(location.pathname);

  const legacyNegotiationsWaitingPath = `${legacyRoot}/:orderId/negotiationswaiting`;
  const legacyNegotiationsWaitingMatcher = match(legacyNegotiationsWaitingPath);
  const legacyNegotiationsWaitingMatch = legacyNegotiationsWaitingMatcher(location.pathname);

  if (legacyCirculateMatch && ordersMatch) {
    const redirect = circulateCompiler({ ...legacyCirculateMatch.params, ...ordersMatch.params });

    history.push(redirect);

    return "ignore";
  }

  if (legacyDistributionMatch && ordersMatch) {
    const redirect = distributionCompiler({ ...legacyDistributionMatch.params, ...ordersMatch.params });

    history.push(redirect);

    return "ignore";
  }

  if (legacyNegotiationsMatch && ordersMatch) {
    // @ts-ignore
    const orderId = legacyNegotiationsMatch?.params?.orderId || ordersMatch.params?.orderId;

    waitForOrderSignalRUpdateAndPushOrderRoute(ordersCompiler, orderId, root);

    return "ignore";
  }

  if (legacyNegotiationsWaitingMatch && ordersMatch) {
    // @ts-ignore
    const orderId = legacyNegotiationsWaitingMatch?.params?.orderId || ordersMatch.params?.orderId;

    waitForOrderSignalRUpdateAndPushOrderRoute(ordersCompiler, orderId, root);

    return "ignore";
  }
}

function rerouteOwners(location: Location, root: string, newRoot: string) {
  const ownersCapturePath = `${root}/:orderId/:negotiationId/new/capture`;
  const ordersCapturePath = `${newRoot}/:orderId/new/capture`;
  const ownersCaptureMatcher = match(ownersCapturePath);
  const ordersCaptureCompiler = compile(ordersCapturePath);
  const ownersCaptureMatch = ownersCaptureMatcher(location.pathname);

  if (ownersCaptureMatch) {
    const redirect = ordersCaptureCompiler(ownersCaptureMatch.params);

    history.push(redirect);

    return "ignore";
  }
}

async function waitForOrderSignalRUpdateAndPushOrderRoute(ordersCompiler, orderId, root) {
  const redirect = ordersCompiler({ orderId });
  const orderNegStore = root === "/coas" ? coaOrderNegotiationStore : standardOrderNegotiationStore;
  const order = orderNegStore.orderMap[orderId]?._.model;

  if (order) {
    order._.status.message = "Loading";
    order._.status.loading = true;
  }

  await orderNegototiation.getNextOrderUpdatePromise(orderId);

  await Promise.all([order?.getData(), orderNegStore.getNegotiationsDataByOrderId(orderId)]);

  history.push(root);
  history.push(redirect);
}

const STATUS = {
  unsavedChanges: {
    type: "warning",
    title: "Unsaved Changes",
    message: "You have unsaved changes, are you sure you want to navigate away?",
  },
} as Record<string, Status>;

export interface HistoryState {
  ignoreUnsavedChanges?: boolean;
}
