import { IUnitValue, IUnitValueAPI } from "./IUnitValue";
import { IKeyValuePair } from "./IKeyValuePair";
import { IUnitValueView } from "./IDetails";

export type CleaningPriceUnit = "PerDay" | "LumpSum" | "PerMT";

export type ICleaningPrice = IUnitValue<CleaningPriceUnit>;

export const CleaningPriceUnitDisplay: { [key in CleaningPriceUnit]: string } = {
  PerDay: "Per Day",
  LumpSum: "Lump Sum",
  PerMT: "Per MT",
};

export const CleaningPriceUnitShortDisplay: { [key in string]: string } = {
  PerDay: " / Day",
  LumpSum: "LS",
  PerMT: "M/T",
};

export const CleaningPriceOptions: IKeyValuePair[] = [
  { key: "LumpSum", value: "Lump Sum" },
  { key: "PerDay", value: "Per Day" },
  { key: "PerMT", value: "Per MT" },
];

export const mapStringToCleaningPriceUnit = (unit?: string): CleaningPriceUnit | undefined => {
  if (!unit) {
    return undefined;
  }

  switch (unit.toLowerCase()) {
    case "perday":
      return "PerDay";
    case "lumpsum":
      return "LumpSum";
    case "permt":
      return "PerMT";
    default:
      return undefined;
  }
};

export const mapCleaningPriceViewToICleaningPrice = (
  item: IUnitValueView | IUnitValueAPI | undefined
): ICleaningPrice | undefined => {
  if (!item || (item.unit === undefined && item.value === undefined)) {
    return undefined;
  }

  return {
    unit: mapStringToCleaningPriceUnit(item.unit),
    value: Number(item.value) || undefined,
    notes: item.notes ? item.notes : "",
  };
};
