import React from "react";
import { router } from "@/models";
import { Sidenav, Route } from "@/components";
import { config } from "___REFACTOR___/config";
import { auth } from "___REFACTOR___/services";
import { LDProvider } from "launchdarkly-react-client-sdk";
import "./Main.scss";

let Refactor: Await<ReturnType<typeof resolveRefactorExports>>;
const resolveRefactorExports = async () => ({
  ...(await import("___REFACTOR___/components/common/Route")),
  ...(await import("___REFACTOR___/pages/DealCapture")),
  routes: await import("___REFACTOR___/routes"),
});
resolveRefactorExports().then((exports) => (Refactor = exports));

export function Main() {
  const launchDarklyKey = config.featureToggleSdkKey;
  const tradeUser = auth.trade.user;
  const user = {
    key: tradeUser?.CompanyId,
    name: tradeUser?.Company,
  };

  return (
    <main data-test="main">
      <LDProvider clientSideID={launchDarklyKey} user={user}>
        <Sidenav />
        <Route route={router.root} />
        <Refactor.Route route={Refactor.routes.dealCapture}>
          <Refactor.DealCapture />
        </Refactor.Route>
      </LDProvider>
    </main>
  );
}
