class AuthenticationResult {
  /**
   * the bearer part of the token
   */
  private bearer = "CLARKSONS-CLOUD";

  /**
   * the Auth token from the login to platou
   */
  private authorisationToken = "";

  /**
   * Set the Auth token from the login to platou
   */
  public setAuthorisationToken(authToken: string) {
    this.authorisationToken = authToken;
  }
  /**
   * Get the full token including the bearer
   */
  public getBearerToken(): string {
    return `${this.bearer} ${this.authorisationToken}`;
  }

  /**
   * Have we been authenticated?
   */
  public hasBeenAuthenticated(): boolean {
    return this.authorisationToken !== "";
  }

  /**
   * Get the authorisation toke
   */
  public getAuthorisationToken(): string {
    return this.authorisationToken;
  }
}

const authenticationResult = new AuthenticationResult();
export { authenticationResult };
