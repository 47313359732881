import { IOrderNegActions } from "../Models/IOrderNegActions";
import { LastUpdatedAction } from "../Models/LastUpdatedAction";

function lastUpdatedAction(actions: IOrderNegActions): LastUpdatedAction {
  let state: LastUpdatedAction = "None";
  if (actions.lastUpdatedBy === "owner") {
    switch (actions.owner) {
      case "indicated":
        state = "IndicationOffer";
        break;
      case "firmed":
        state = "FirmOffer";
        break;
      case "firmAccepted":
        state = "FirmBidAccepted";
        break;
      default:
        state = "None";
    }
  } else if (actions.lastUpdatedBy === "brokerCharterer") {
    switch (actions.brokerCharterer) {
      case "indicated":
        state = "IndicationBid";
        break;
      case "firmed":
        state = "FirmBid";
        break;
      case "firmRequested":
        state = "FirmOfferRequested";
        break;
      case "firmAccepted":
        state = "FirmOfferAccepted";
        break;
      default:
        state = "None";
    }
  }
  return state;
}

export default lastUpdatedAction;
