import numeral from "numeral";

/**
 * Gets a number and returns it back converted to the precision decimal places
 * @param value number to be converted
 * @param precision precision of decimal places. Defaults to 2 decimal places.
 */
export const formatToDecimal = (
  //TODO: This has a sibling in the code base - remove one of them
  value: string | number | null | undefined,
  precision?: number | undefined
): string | "" => {
  if (value === "" || value === undefined || value === null) {
    return "";
  }
  return numeral(value).format(`0,0.${Array((precision === undefined ? 2 : precision) + 1).join("0")}`);
};

/**
 * There are at least 3 formatting types for numbers in different screens
 * TODO: make all screens consistent and remove options in this method
 * Gets a number and returns it back converted in accordance with chosen type
 * @param value - number to be converted
 * @param formattingType - chosen type for formatting:
 * FIXED_TO - fixed to specific decimal digits: 2 -> 2.000, 2.1 -> 2.100, 2.1234 -> 2.123
 * LIMITED_TO - fixed only as max of decimal digits: 2 -> 2, 2.1 -> 2.1, 2.1234 -> 2.123
 * COMBINED - no decimal digits if it's integer, fixed to specific amount if decimal: 2 -> 2, 2.1 -> 2.100, 2.1234 -> 2.123
 * DEFAULT - no additional formating: 2 -> 2, 2.1 -> 2.1, 2.1234 -> 2.1234
 */

export const formatNumberToStringDisplay = (value: number | null | undefined, formattingType: Format): string | "" => {
  if (!value) {
    return "";
  }

  const defaultFractionDigits = 3;
  const maximumFractionDigits = defaultFractionDigits;
  let minimumFractionDigits;

  switch (formattingType) {
    case Format.FIXED_TO:
      minimumFractionDigits = defaultFractionDigits;

      return value.toLocaleString(undefined, { minimumFractionDigits, maximumFractionDigits });

    case Format.COMBINED:
      minimumFractionDigits = Math.ceil(value % 1) * defaultFractionDigits;

      return value.toLocaleString(undefined, { minimumFractionDigits, maximumFractionDigits });

    case Format.LIMITED_TO:
      return value.toLocaleString(undefined, { maximumFractionDigits });

    case Format.DEFAULT:
    default:
      return value.toLocaleString();
  }
};

export enum Format {
  FIXED_TO,
  LIMITED_TO,
  COMBINED,
  DEFAULT,
}
