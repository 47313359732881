import * as api from "../../api/negotiations/models";
import { IVesselSizeView } from "../Models/IDetails";
import { IMapper, withNullable } from "./map";

const map: IMapper<api.IVesselSize, IVesselSizeView> = {
  toApi: (viewModel: IVesselSizeView) => {
    return {
      notes: viewModel.notes,
      sizeFrom: parseInt(viewModel.sizeFrom) || 0,
      sizeTo: parseInt(viewModel.sizeTo) || 0,
      vesselSizeAbbreviation: viewModel.vesselSizeAbbreviation,
      vesselSizeFull: viewModel.vesselSizeFull,
    };
  },
  toView: (apiModel: api.IVesselSize) => {
    return {
      display: `${apiModel.vesselSizeAbbreviation} ${apiModel.notes || ""}`,
      shortDisplay: apiModel.shortDisplay,
      notes: apiModel.notes || "",
      sizeFrom: apiModel.sizeFrom ? String(apiModel.sizeFrom) : "",
      sizeTo: apiModel.sizeTo ? String(apiModel.sizeTo) : "",
      vesselSizeAbbreviation: apiModel.vesselSizeAbbreviation,
      vesselSizeFull: apiModel.vesselSizeFull,
    };
  },
  emptyViewModel: {
    notes: "",
    sizeFrom: "",
    sizeTo: "",
    vesselSizeAbbreviation: "",
    vesselSizeFull: "",
  },
};

export const mapVesselSize = withNullable(map);
