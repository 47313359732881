import React from "react";
import { IOfferRepViewNegotiation } from "negotiations/models/common/IOfferRep";
import { NegotiationNotes } from "../NegotiationNotes/NegotiationNotes";
import { OfferRepAttachments, OwnerDashboardAttachments } from "../OfferRepAttachments/OfferRepAttachments";

export const OrderNotesAndAttachments: React.FC<{
  negotiation: IOfferRepViewNegotiation;
  offerRepToken: string;
  count: number;
}> = ({ negotiation, offerRepToken, count }) => {
  return (
    <div>
      <NegotiationNotes id="negotiationNotes" negotiation={negotiation} />
      <OfferRepAttachments
        invitee={negotiation.invitee}
        negotiationAttachments={negotiation.attachments}
        orderAttachments={negotiation.orderAttachments}
        offerRepToken={offerRepToken}
        updateToken={negotiation.updateToken}
      />
    </div>
  );
};

export const OrderNotesAndAttachmentsForDashboard: React.FC<{
  negotiation: IOfferRepViewNegotiation;
}> = ({ negotiation }) => (
  <div>
    <NegotiationNotes id="negotiationNotes" negotiation={negotiation} />
    <OwnerDashboardAttachments
      negotiationAttachments={negotiation.attachments}
      orderAttachments={negotiation.orderAttachments}
      negotiationId={negotiation.id}
      orderId={negotiation.orderId}
      updateToken={negotiation.updateToken}
    />
  </div>
);
