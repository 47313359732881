export function safeGet<T, P extends keyof NonNullable<T>>(
  obj: T | undefined,
  prop: P | undefined
): NonNullable<T>[P] | undefined {
  if (obj && prop) {
    return (obj as NonNullable<T>)[prop];
  } else {
    return undefined;
  }
}
