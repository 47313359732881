type TextChunkType = "handlebar" | "unknown_handlebar" | "deferred_handlebar" | "text" | "pill";

export class HandlebarTextChunk {
  constructor(public value: string, public type: TextChunkType, public handlebar: string) {}

  public static text(value: string) {
    return new HandlebarTextChunk(value, "text", "");
  }

  public static pill(value: string) {
    return new HandlebarTextChunk(value, "pill", "");
  }

  public static handlebar(value: string, handlebar: string) {
    return new HandlebarTextChunk(value, "handlebar", handlebar);
  }

  public static deferredHandlebar(value: string, handlebar: string) {
    return new HandlebarTextChunk(value, "deferred_handlebar", handlebar);
  }

  public static unknownHandlebar(value: string) {
    return new HandlebarTextChunk(value, "unknown_handlebar", value);
  }
}
