import { IGenericErrorObject } from "./DistributionListPanel";
import { IEmailList } from "../../services/settings";

/**
 * Takes two numbers and returns their sum
 * @param currentList All the email lists currently available
 * @param newGroupName The new groupname that we are attempting to add
 * @returns An IGenericErrorObject
 */
export const getGroupNameErrors = (currentList: IEmailList[], newGroupName: string): IGenericErrorObject => {
  return {
    nameAlreadyExist:
      currentList.map((d) => d.name.trim().toLocaleLowerCase()).indexOf(newGroupName.trim().toLocaleLowerCase()) >= 0,
    nameEmpty: !newGroupName.length,
    nameTooLong: newGroupName.length > 100,
  };
};
