import { memo } from "react";
import { useField, useFormikContext } from "formik";
import { get } from "lodash-es";
import { EDITOR_TYPE } from "@/components";
import { Row } from "./Row";
import { Column } from "./Column";
import { CollapsibleGroup } from "./CollapsibleGroup";
import { CollapsibleRow } from "./CollapsibleRow";
import { TitledRow } from "./TitledRow";
import { Field } from "./Field";
import { Split } from "./Split";
import { RadioTimelimit } from "./RadioTimelimit";
import { RadioRadioTimelimits } from "./RadioRadioTimelimits";
import { RadioDateTimezone } from "./RadioDateTimezone";
import { TextOnly } from "./TextOnly";

function ElementHOC(element, i) {
  const { type, _ } = element;

  let Comp;

  if (element.hidden) return null;

  if (COMP_BY_TYPE[type]) {
    Comp = COMP_BY_TYPE[type];

    //
  } else if (EDITOR_TYPE[type]) {
    Comp = Field;

    //
  } else if (_?.editor) {
    Comp = Field;
  }

  if (!Comp) {
    console.error(`Unrecognized form element: ${type}`, element);

    return null;
  }

  return <Element element={element} Comp={Comp} key={i} />;
}

const Element = memo(Element_);

function Element_(props) {
  const { element, Comp } = props;
  let { name, inheritErrorFrom } = element;

  name = `${name}`;

  const [field, meta, helpers] = useField(name);
  const context = useFormikContext();
  const { submitCount } = context;

  if (element.visible && !element.visible(context)) return null;

  let { error, touched } = meta;

  error = error || get(context.errors, inheritErrorFrom);

  error = errorToStatus(error);

  touched = touched || submitCount > 0;

  const status = touched && error;

  const tabIndex = element.disabled ? -1 : 0;

  const extras = {
    context,
    value: element.value || field.value,
    onChange: helpers.setValue,
    onBlur: helpers.setTouched,
    status,
    tabIndex,
    dataTest: element.name,
    copyButton: element.copyButton,
    onButtonClick: element.onButtonClick,
  };

  return <Comp element={element} {...extras} />;
}

function errorToStatus(error) {
  if (typeof error === "string") {
    return {
      type: "error",
      message: error,
    };
  }

  if (typeof error === "object") {
    const res = {
      type: "error",
      children: {},
    };
    const entries = Object.entries(error);

    for (let i = 0; i < entries.length; i += 1) {
      const [name, error] = entries[i];

      res.children[name] = errorToStatus(error);
    }

    return res;
  }
}

const COMP_BY_TYPE = {
  Row,
  Column,
  CollapsibleRow,
  CollapsibleGroup,
  TitledRow,
  Split,
  RadioTimelimit,
  RadioRadioTimelimits,
  RadioDateTimezone,
  TextOnly,
};

export { ElementHOC };
