import React, { useEffect } from "react";
import TextArea from "sharedFolder/components/common/TextArea/TextArea";
import { withValidationContext } from "sharedFolder/contexts/withValidationContext";
import { ISubsTextView } from "sharedFolder/Models/ISubsText";

interface IProps {
  disabled?: boolean;
  id: string;
  value?: ISubsTextView;
  onChange: (value: ISubsTextView) => void;
  focus?: boolean;
  required?: boolean;
  isValid?: boolean;
}

function SubsTextEditor(props: IProps) {
  return (
    <TextArea
      id={props.id}
      dataTest={props.id}
      value={props.value?.value ? props.value.value : ""}
      onChange={(evt: React.FocusEvent<HTMLTextAreaElement>) => {
        const val = evt.target.value;
        props.onChange({
          display: val,
          value: val,
          title: "",
        });
      }}
      focus={props.focus}
      isValid={props.isValid}
      disabled={props.disabled}
    />
  );
}

export const SubsTextEditorWithValidContext = withValidationContext<Omit<IProps, "isValid">>((props) => {
  const { fieldValidityChanged, id, value, required } = props;
  const isValid = props.required && props.value?.value === "" ? false : true;
  useEffect(() => {
    fieldValidityChanged(id, isValid);
  }, [id, isValid, required, value, fieldValidityChanged]);
  return <SubsTextEditor {...props} isValid={isValid} />;
});

export default SubsTextEditor;
