import React from "react";
import styles from "./Field.module.scss";
import classNames from "classnames";
import { useEditorFieldProvider } from "../EditorFields/EditorFieldContextProvider";

interface IFieldProps {
  display: JSX.Element;
  edit: JSX.Element;
  expanded: boolean;
  expand: () => void;
  id: string;
  label: string;
  isValid?: boolean;
  name: string;
}

const Field = (props: IFieldProps) => {
  const { display, edit, expanded, expand, id, label, name, isValid } = props;

  const isEditable = useEditorFieldProvider();

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    expand();
  };

  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === "Tab" && !expanded) {
      expand();
    }
  };

  return (
    <div
      data-test={`${name}-field`}
      className={classNames(styles.fieldContainer, !isValid && "rowHasError", !isEditable && styles.readOnly)}
    >
      <label className={styles.label} onClick={handleClick} data-test={`${name}-label`}>
        {label}
      </label>
      {expanded ? (
        <div className={classNames(styles.editorContainer)} data-test={`${id}-expanded-content`} onKeyUp={handleKeyUp}>
          {edit}
        </div>
      ) : (
        <div
          tabIndex={0}
          onClick={handleClick} // onClick is ONLY on the display side and not on the edit side so clicking on the input doesn't fire a collapse trigger
          className={classNames(styles.displayContainer)}
          data-test={`${id}-collapsed-content`}
          onKeyUp={handleKeyUp}
        >
          {display}
        </div>
      )}
    </div>
  );
};

Field.Display = () => <p>Display</p>;

export default Field;
