import { Account, Negotiation, dialog } from "@/models";
import { Context } from "../Orders";

export async function updateNegotiationOwnerAccount(model: Negotiation | undefined, context: Context, data: Account) {
  if (!model) {
    console.error("updateNegotiationOwnerAccount:", { model, context, data });

    const status = {} as Status;

    status.type = "error";
    status.title = "Counterparty Update Failure";

    dialog.show({
      status,
      dataTest: `update-negotiation-owner-account-fail`,
    });

    return;
  }

  model._.status.loading = true;
  model._.status.message = `Updating counterparty to ${data.accountName}`;

  const res = await model.postOwningCompany({ value: data });

  if (!res?.ok) {
    model._.status.type = "error";
    model._.status.title = "Counterparty Update Failure";
    model._.status.dump = { res, entity: model };

    dialog.show({
      status: model._.status,
      dataTest: `update-negotiation-owner-account-fail`,
    });

    model._.status.loading = false;
    model._.status.message = null;

    return res;
  }

  model._.status.loading = false;
  model._.status.message = null;

  // dialog.show({
  //   status: {
  //     type: "success",
  //     title: "Successful Counterparty Update",
  //     message: "You have successfuly updated the negotiation counterparty",
  //   },
  //   dataTest: `update-negotiation-owner-account-success`,
  // });

  return res;
}
