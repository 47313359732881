import React from "react";
import styles from "./FirmSummary.module.scss";
import {
  CargoTypeDisplay,
  CargoSizeDisplay,
  COACargoSizeDisplay,
  CommissionDisplay,
  LaycanDisplay,
  LiftingsDisplay,
  LocationDisplay,
  NominationsDisplay,
  PeriodDisplay,
  UnitValueDisplay,
  VesselSizeDisplay,
} from "../../../DetailsView/DetailsViewOfferRep";
import {
  ILaycanView,
  ICommissionView,
  ICOACargoSizeView,
  ICargoTypeView,
  ICargoSizeView,
  ILiftingsView,
  INominationsView,
  IVesselSizeView,
  IDurationView,
  IPeriodView,
  ISubsTextView,
  IUnitValueView,
} from "sharedFolder/Models/IDetails";
import { BunkerDisplay, DurationDisplay } from "negotiations/components/common/DetailsView/DetailsView";
import { NegotiationUIStatus } from "../../reducers/offerRepStatusReducer";
import { IBidOfferView } from "sharedFolder/Models/Negotiation/NegotiationViews";
import { OrderNegType } from "sharedFolder/Models/OrderNegType";
import { INegotiationDetailsView, INegotiationView } from "sharedFolder/Models/Negotiation/INegotiationView";
import { ILocationView } from "sharedFolder/Models/ILocation";
import { IBunkerView } from "sharedFolder/Models/IBunker";
import ifITOElseCOA from "sharedFolder/Utilities/ifITOElseCOA";

type bidOrOffer = "Bid" | "Offer";

interface IFirmSummaryProps {
  ownerNegotiation: INegotiationView;
  orderType: OrderNegType;
  negotiationUIStatus?: NegotiationUIStatus;
}

const renderFirmItem = function (bidOrOffer: bidOrOffer) {
  return function <T>(
    bidOfferAcceptView: IBidOfferView<T>,
    displayLabel: string,
    render: (bid: T) => JSX.Element | null
  ): JSX.Element | null {
    if (bidOfferAcceptView && bidOfferAcceptView.included && bidOfferAcceptView.negotiable) {
      return (
        <div className={styles.summaryItem}>
          <span className={styles.smallTitle}> {displayLabel}</span>
          {bidOrOffer === "Offer" ? render(bidOfferAcceptView.offer) : render(bidOfferAcceptView.bid)}
        </div>
      );
    }
    return null;
  };
};

const renderDetails = (details: INegotiationDetailsView, bidOrOffer: bidOrOffer, orderType: string): JSX.Element | null => {
  const render = renderFirmItem(bidOrOffer);
  const orderFormType = orderType === "COA" ? "COA" : "ITO";

  if (details) {
    return (
      <>
        {details.vesselSize &&
          render<IVesselSizeView>(details.vesselSize, "Vessel Size", (bidOrOffer) => <VesselSizeDisplay item={bidOrOffer} />)}
        {details.duration &&
          render<IDurationView>(details.duration, "Duration", (bidOrOffer) => <DurationDisplay item={bidOrOffer} />)}

        {details.laycan && render<ILaycanView>(details.laycan, "Laycan", (bidOrOffer) => <LaycanDisplay item={bidOrOffer} />)}

        {details.period && render<IPeriodView>(details.period, "Period", (bidOrOffer) => <PeriodDisplay item={bidOrOffer} />)}

        {details.cargoType &&
          render<ICargoTypeView>(details.cargoType, "Cargo Type(s)", (bidOrOffer) => <CargoTypeDisplay item={bidOrOffer} />)}

        {ifITOElseCOA(
          orderFormType,
          details.cargoSize &&
            render<ICargoSizeView>(details.cargoSize, "Cargo Size(s)", (bidOrOffer) => <CargoSizeDisplay item={bidOrOffer} />),
          details.coaCargoSize &&
            render<ICOACargoSizeView>(details.coaCargoSize, "Cargo Size(s)", (bidOrOffer) => (
              <COACargoSizeDisplay item={bidOrOffer} />
            ))
        )}

        {details.loadLocation &&
          render<ILocationView>(
            details.loadLocation,
            ifITOElseCOA(orderFormType, "Load Location", "Load Location(s)"),
            (bidOrOffer) => <LocationDisplay item={bidOrOffer} />
          )}
        {details.dischargeLocation &&
          render<ILocationView>(
            details.dischargeLocation,
            ifITOElseCOA(orderFormType, "Discharge Location", "Discharge Location(s)"),
            (bidOrOffer) => <LocationDisplay item={bidOrOffer} />
          )}
        {details.deliveryLocation &&
          render<ILocationView>(details.deliveryLocation, "Delivery Location", (bidOrOffer) => (
            <LocationDisplay item={bidOrOffer} />
          ))}
        {details.viaLocation &&
          render<ILocationView>(details.viaLocation, "Via Location", (bidOrOffer) => <LocationDisplay item={bidOrOffer} />)}
        {details.redeliveryLocation &&
          render<ILocationView>(details.redeliveryLocation, "Redelivery Location", (bidOrOffer) => (
            <LocationDisplay item={bidOrOffer} />
          ))}

        {render<ICommissionView>(details.addressCommission, "Address Commission", (bidOrOffer) => (
          <CommissionDisplay item={bidOrOffer} />
        ))}
        {render<ICommissionView>(details.brokerCommission, "Broker Commission", (bidOrOffer) => (
          <CommissionDisplay item={bidOrOffer} />
        ))}

        {orderFormType === "COA" &&
          details.liftings &&
          render<ILiftingsView>(details.liftings, "Liftings", (bidOrOffer) => <LiftingsDisplay item={bidOrOffer} />)}
        {orderFormType === "COA" &&
          details.nominations &&
          render<INominationsView>(details.nominations, "Nominations", (bidOrOffer) => <NominationsDisplay item={bidOrOffer} />)}

        {details.operationalSubs &&
          render<ISubsTextView>(details.operationalSubs, "Operational Subs", (bidOrOffer) => <>{bidOrOffer.value}</>)}

        {details.commercialSubs &&
          render<ISubsTextView>(details.commercialSubs, "Commercial Subs", (bidOrOffer) => <>{bidOrOffer.value}</>)}

        {/* FREIGHT / DEMURRAGE / HIRE / BALLAST / BUNKER */}
        {details.freightRate &&
          render<IUnitValueView>(details.freightRate, "Freight Rate", (bidOrOffer) => <UnitValueDisplay item={bidOrOffer} />)}
        {details.demurrage &&
          render<IUnitValueView>(details.demurrage, "Demurrage", (bidOrOffer) => <UnitValueDisplay item={bidOrOffer} />)}
        {details.hireRate &&
          render<IUnitValueView>(details.hireRate, "Hire Rate", (bidOrOffer) => <UnitValueDisplay item={bidOrOffer} />)}
        {details.cleaningPrice &&
          render<IUnitValueView>(details.cleaningPrice, "ILOHC", (bidOrOffer) => <UnitValueDisplay item={bidOrOffer} />)}
        {details.supplyPrice &&
          render<IUnitValueView>(details.supplyPrice, "CVE", (bidOrOffer) => <UnitValueDisplay item={bidOrOffer} />)}
        {details.tradingExclusions &&
          render<ISubsTextView>(details.tradingExclusions, "Trading Exclusions", (bidOrOffer) => <>{bidOrOffer.value}</>)}
        {details.bunkerDelivery &&
          render<IBunkerView>(details.bunkerDelivery, "Bunker Delivery", (bidOffer) => <BunkerDisplay item={bidOffer} />)}
        {details.bunkerRedelivery &&
          render<IBunkerView>(details.bunkerRedelivery, "Bunker Re-Delivery", (bidOffer) => <BunkerDisplay item={bidOffer} />)}
        {details.cargoExclusionsText &&
          render<ISubsTextView>(details.cargoExclusionsText, "Cargo Exclusions", (bidOrOffer) => <>{bidOrOffer.value}</>)}
        {details.ballastBonus &&
          render<IUnitValueView>(details.ballastBonus, "Ballast Bonus", (bidOrOffer) => <UnitValueDisplay item={bidOrOffer} />)}
      </>
    );
  }
  return null;
};

export const FirmSummary: React.FC<IFirmSummaryProps> = ({ ownerNegotiation, orderType, negotiationUIStatus }) => {
  const bidOrOffer: bidOrOffer =
    negotiationUIStatus === "FirmOffer" ||
    negotiationUIStatus === "FirmOfferRequested" ||
    negotiationUIStatus === "FirmOfferAccepted"
      ? "Offer"
      : "Bid";
  return (
    <div className={styles.container}>
      {negotiationUIStatus === "FirmBid" && <span data-key="Summary-Title">Firm Bid Summary</span>}
      {negotiationUIStatus === "FirmOffer" && <span data-key="Summary-Title">Firm OFFER Summary</span>}
      {negotiationUIStatus === "FirmOfferRequested" && <span data-key="Summary-Title">Firm OFFER Summary</span>}
      {renderDetails(ownerNegotiation.details as INegotiationDetailsView, bidOrOffer, orderType)}
    </div>
  );
};

export const FirmOfferSummary: React.FC<IFirmSummaryProps> = ({ ownerNegotiation, orderType }) => {
  return (
    <div className={styles.container}>
      {renderDetails(ownerNegotiation.details as INegotiationDetailsView, "Offer", orderType)}
    </div>
  );
};
