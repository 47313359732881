import React, { useEffect, useState, useContext } from "react";
import { VesselPanel } from "./VesselPanel/VesselPanel";
import { NegotiationContext } from "../Context/NegotiationContext";
import { IVesselView } from "sharedFolder/Models/IDetails";
import { emptyArrowFn } from "@/utils";

export type VesselPanelActions = "accept" | "reject" | "nominate" | "nominate-accept" | "edit";

interface IVesselPanelNegotiationContainerProps {
  className?: string;
  vessels: IVesselView[] | undefined;
  onPanelClosed: (action?: VesselPanelActions) => void;
  nominatingVessel?: IVesselView;
  isReadOnly?: boolean;
}

export const VesselPanelNegotiationContainer: React.FC<IVesselPanelNegotiationContainerProps> = (
  props: IVesselPanelNegotiationContainerProps
) => {
  const [nominating, setNominating] = useState<IVesselView | undefined>(props.nominatingVessel);
  useEffect(() => {
    setNominating(props.nominatingVessel);
  }, [props.nominatingVessel]);

  const negotiationContext = useContext(NegotiationContext);

  const vesselExists = (vessel: IVesselView) => props.vessels && props.vessels.some((v) => v.vesselIMO === vessel.vesselIMO);

  function dismissPanel(action?: VesselPanelActions) {
    setNominating(undefined);
    props.onPanelClosed(action);
  }

  return (
    <>
      <VesselPanel
        isReadOnly={props.isReadOnly}
        vessel={nominating}
        onDismiss={dismissPanel}
        onAccept={(vessel) =>
          negotiationContext.acceptVessel(vessel.vesselIMO).subscribe(() => {
            dismissPanel("accept");
          })
        }
        onReject={(vessel) =>
          negotiationContext.rejectVessel(vessel.vesselIMO).subscribe(() => {
            dismissPanel("reject");
          })
        }
        onNominate={(vessel) => {
          !vesselExists(vessel) &&
            negotiationContext.nominateVessel(vessel).subscribe(() => {
              dismissPanel("nominate");
            });
        }}
        focus={true}
        onNominateAccept={(vessel) =>
          !vesselExists(vessel) &&
          negotiationContext.nominateAcceptVessel(vessel).subscribe(() => {
            dismissPanel("nominate-accept");
          })
        }
        onEdit={(vessel) => negotiationContext.editVessel(vessel).subscribe(emptyArrowFn)}
        nominatedVessels={props.vessels}
      />
    </>
  );
};
