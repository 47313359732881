import React, { useRef, useState } from "react";
import { useShallowStateOld, emptyFn } from "___REFACTOR___/utils";
import { tradeAPI } from "___REFACTOR___/apis";
import { CustomDate, Status, Vessel } from "___REFACTOR___/models";
import { VesselButton, SelectField, Button, TextareaField, DateField, Field, StatusIcon } from "___REFACTOR___/components";
import { Group } from "../../Group";
import { Row } from "../../Row";
import "./Vessel.scss";

function VesselEditor(props: VesselEditor.Props) {
  const { searchMessageMap = defaultSearchMessages, value, status, dataTest, hidden, vesselReadOnly, ...rest } = props;
  const [data, setData] = useState<Vessel[] | undefined>(undefined);
  const [, setDropdownStatus, dropdownStatus] = useShallowStateOld<Status>({
    message: searchMessageMap?.initial,
  });
  const cancel = useRef(emptyFn);
  const isVesselSelected = value?.vesselImo;
  const vesselStatus = VESSEL_STATUS_MAP[value?.status!];

  return (
    <Group {...props} editorType="Vessel" hidden={hidden}>
      <Row>
        <SelectField
          {...rest}
          onClear={onClear}
          label="Vessel"
          value={value}
          data={data}
          status={status?.childMap?.vesselImo}
          placeholder=""
          dropdownStatus={dropdownStatus}
          dropdownClassName="Vessel"
          onFilter={onFilter}
          onBlur={onBlur}
          dataTest={`${dataTest}.value`}
          readOnly={vesselReadOnly}
          className="vessel-selector"
        />
        {vesselStatus?.type === "success" && <StatusIcon status={vesselStatus} />}
        <DateField
          {...rest}
          className="vessel-calendar"
          withoutTimezone
          label="ETA"
          value={value?._.eta}
          status={status?.childMap?.eta}
          onChange={onEtaChange}
          dataTest={`${dataTest}.CustomDate`}
          hidden={!isVesselSelected}
        />
        {props.onRemove && <Button onClick={props.onRemove} className="vessel-delete-btn" icon="trash" type="button" />}
      </Row>
      <Row className="vessel-app-buttons">
        {value && <VesselButton vessel={value} seaAnalyticsOrSeaNet="Net" />}
        {value && <VesselButton vessel={value} seaAnalyticsOrSeaNet="Analytics" />}
      </Row>
      <TextareaField
        {...rest}
        value={value?.ownerChain}
        onChange={onChange.bind(null, "ownerChain")}
        label="Owner chain"
        dataTest={`${dataTest}.ownerChain`}
        hidden={!isVesselSelected}
      />
      <TextareaField
        {...rest}
        value={value?.itinerary}
        onChange={onChange.bind(null, "itinerary")}
        label="Owner itinerary"
        dataTest={`${dataTest}.itinerary`}
        hidden={!isVesselSelected}
      />
      <TextareaField
        {...rest}
        value={value?.speedAndConsumption}
        onChange={onChange.bind(null, "speedAndConsumption")}
        label="Speed & consumption"
        dataTest={`${dataTest}.speedAndConsumption`}
        hidden={!isVesselSelected}
      />
      <TextareaField
        {...rest}
        value={value?.additionalNotes}
        onChange={onChange.bind(null, "additionalNotes")}
        label="Additional Notes"
        dataTest={`${dataTest}.additionalNotes`}
        hidden={!isVesselSelected}
      />
      <TextareaField
        {...rest}
        value={value?.vesselDescription}
        onChange={onChange.bind(null, "vesselDescription")}
        label="Vessel Description"
        dataTest={`${dataTest}.vesselDescription`}
        hidden={!isVesselSelected}
      />
    </Group>
  );

  async function onFilter(search: string) {
    cancel.current();

    setData(undefined);
    setDropdownStatus({ message: undefined, loading: false, search });

    if (!search) {
      setDropdownStatus({ message: searchMessageMap?.initial });

      return;
    }

    setDropdownStatus({ message: searchMessageMap?.searching, loading: true });

    const promise = tradeAPI.searchVessels(search);

    cancel.current = promise.cancel;

    const res = await promise;

    if (res.cancelled) return;

    if (!res.data || !res.data.length) {
      setData(undefined);
      setDropdownStatus({ message: searchMessageMap?.noData, loading: false });

      return;
    }

    const data = res.data.map((el) => new Vessel(el));

    setData(data);
    setDropdownStatus({ message: undefined, loading: false });
  }

  function onChange(key: keyof Vessel, propertyValue) {
    const next = new Vessel({ ...value, [key]: propertyValue });

    if (value?._.eta) next._.eta = value._.eta;

    props.onChange?.(next);
  }

  function onEtaChange(eta) {
    if (eta instanceof CustomDate) {
      props.onChange?.(new Vessel(value, { eta }));
    }
  }

  function onBlur(e) {
    cancel.current();

    setData(undefined);
    setDropdownStatus({
      message: searchMessageMap?.initial,
      loading: false,
    });

    props.onBlur?.(e);
  }

  function onClear() {
    props.onChange?.(new Vessel());
  }
}

const defaultSearchMessages = {
  initial: "Waiting for your input",
  searching: "Searching for vessels",
  noData: "No vessels found",
} as VesselEditor.SearchMessageMap;

const VESSEL_STATUS_MAP = {
  accepted: { type: "success" },
  rejected: { type: "withdrawn" },
} as const;

export { VesselEditor, VESSEL_STATUS_MAP };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace VesselEditor {
  export interface Props extends Field.Props {
    value: Value;
    onChange: (value: Value) => void;
    onRemove?: () => void;
    searchMessageMap?: SearchMessageMap;
    vesselReadOnly?: boolean;
  }

  export type Value = Vessel | undefined;
  export interface SearchMessageMap {
    initial: string;
    searching: string;
    noData: string;
  }
}
