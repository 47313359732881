import { memo } from "react";
import { ElementHOC } from "../Element";
import "./RadioRadioTimelimits.scss";

function RadioRadioTimelimits(props) {
  const { element } = props;
  const { radio, radioTimelimits, bottomText, children, reactChildren } = element;
  const RadioTimelimit = radioTimelimits.map((radioTimelimit, i) => ElementHOC({ ...radioTimelimit, type: "RadioTimelimit" }, i));
  const Radio = ElementHOC({ ...radio, type: "Radio" });

  return (
    <div className="RadioRadioTimelimits">
      {Radio}
      <div className="RadioRadioTimelimits-timelimits">
        {RadioTimelimit}
        <div>{children?.map((element) => ElementHOC(element))}</div>
      </div>
      <div className="RadioRadioTimelimits-bottomText">{bottomText}</div>
      {reactChildren}
    </div>
  );
}

const Memo = memo(RadioRadioTimelimits);

export { Memo as RadioRadioTimelimits };
