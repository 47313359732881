import React, { useContext } from "react";
import styles from "./SendIndicationFirmOfferButtons.module.scss";
import classNames from "classnames";
import { ExpiresOnValue } from "sharedFolder/components/common/types";
import expiryTypeReducer from "sharedFolder/reducers/expiryTypeReducer";
import { IOfferRepViewNegotiation } from "negotiations/models/common/IOfferRep";
import { NegotiationUIStatus } from "../../reducers/offerRepStatusReducer";
import { OfferRepNegotiationContext } from "../../Contexts/OfferRepNegotiationContext";
import Button from "__legacy/sharedFolder/components/common/Button/Button";
import { NegotiationTypeEvents, usageMetrics } from "@/services/UsageMetrics";

export const SendIndicationFirmOfferButtons: React.SFC<{
  disabled: boolean;
  update: () => void;
  updateFirmBid: (date: string) => void;
  expiryDateHasBeenSelected: (date: string) => void;
  negotiation: IOfferRepViewNegotiation;
  negotiationUIStatus: NegotiationUIStatus;
  expiryDate: ExpiresOnValue | undefined;
}> = (props) => {
  const { firmOrIndicationState } = useContext(OfferRepNegotiationContext);
  const { expiryDate } = props;
  const updateFirmBid = () => {
    if (expiryDate) {
      props.updateFirmBid(expiryTypeReducer[expiryDate.type](expiryDate.value));
    }
  };

  function update() {
    usageMetrics.trackEvent(NegotiationTypeEvents.SEND_INDICATION_ONLY);
    props.update();
  }

  return (
    <>
      <div className={classNames(styles.sendIndication)}>
        {firmOrIndicationState === "INDICATION_ONLY" && (
          <Button dataTest="SendIndicationButton" type="action" disabled={props.disabled} onClick={update}>
            Send Indication
          </Button>
        )}
        {firmOrIndicationState === "FIRM_OFFER" && (
          <Button
            dataTest="SendFirmOfferButton"
            type="action"
            disabled={props.disabled || !expiryDate}
            onClick={() => updateFirmBid()}
          >
            Send Firm OFFER
          </Button>
        )}
      </div>
    </>
  );
};
