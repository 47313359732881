import { TradeAPI } from "@/apis";
import { DataModel, DataModelProps } from "../DataModel";
import { SubsEditor } from "@/components";
import { LABELS } from "@/constants";

export class Subs extends DataModel<Data> {
  onUpdate() {
    delete this.display;
    delete this.shortDisplay;
  }

  toString() {
    return this.value;
  }

  toGridView() {
    if (!this) return "";
    if (!this.value) return "";

    return this.value;
  }
}

export class SubsProps extends DataModelProps<Data> {}

SubsProps.prototype.label = "Subs";

SubsProps.prototype.editor = SubsEditor;

Subs.prototype._ = new SubsProps();

/* -------------------------------------------------------------------------- */
/*                               TradingExclusions                            */
/* -------------------------------------------------------------------------- */

export class TradingExclusions extends Subs {}

export class TradingExclusionsProps extends SubsProps {}

TradingExclusionsProps.prototype.label = LABELS["tradingExclusions"];

TradingExclusions.prototype._ = new TradingExclusionsProps();

/* -------------------------------------------------------------------------- */
/*                               CargoExclusionsText                          */
/* -------------------------------------------------------------------------- */

export class CargoExclusionsText extends Subs {}

export class CargoExclusionsTextProps extends SubsProps {}

CargoExclusionsTextProps.prototype.label = LABELS["cargoExclusionsText"];

CargoExclusionsText.prototype._ = new CargoExclusionsTextProps();

/* -------------------------------------------------------------------------- */
/*                               OperationalSubs                              */
/* -------------------------------------------------------------------------- */

export class OperationalSubs extends Subs {}

export class OperationalSubsProps extends SubsProps {}

OperationalSubsProps.prototype.label = LABELS["operationalSubs"];

OperationalSubs.prototype._ = new OperationalSubsProps();

/* -------------------------------------------------------------------------- */
/*                               CommercialSubs                              */
/* -------------------------------------------------------------------------- */

export class CommercialSubs extends Subs {}

export class CommercialSubsProps extends SubsProps {}

CommercialSubsProps.prototype.label = LABELS["commercialSubs"];

CommercialSubs.prototype._ = new CommercialSubsProps();

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

export interface Subs extends Data {
  _: SubsProps;
}

type Data = DeepPartial<TradeAPI["Subs"]>;
