import React from "react";
import { observer } from "mobx-react";
import {
  //  Button,
  Status,
} from "@/components";
// import { Context } from "../../NegotiationDetail";
import "./Withdrawn.scss";

function Withdrawn() {
  // const { negotiation } = useContext(Context);
  // const { arcUrlWithCentralToken } = negotiation;
  const status = {
    type: "fail",
    title: "Withdrawn",
    icon: "withdrawn",
  } as Status;

  return (
    <div className="Withdrawn">
      <Status status={status} />
    </div>
  );
}

const Observer = observer(Withdrawn);

export { Observer as Withdrawn };
