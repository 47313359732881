import { TradeAPI } from "___REFACTOR___/apis";
import { DataModel, DataModelProps } from "../DataModel";

export class CP extends DataModel {
  constructor(data: PartialData) {
    super(data);
  }
}

class CpProps extends DataModelProps {}

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

export interface CP extends Data {
  Props: typeof CpProps;
  _: CpProps;
}

type PartialData = Partial<Data>;
type Data = TradeAPI.CP;
