import { TradeAPI } from "@/apis";
import { stringArrToBoolMap } from "@/utils";
import { auth } from "../Auth";
import { DataModel, DataModelProps } from "../DataModel";

export class User extends DataModel<Data> {
  onUpdate() {
    if (this.CompanyRoles) this._.companyRoleMap = stringArrToBoolMap(this.CompanyRoles);
  }

  toString() {
    if (auth.trade.user?.SystemUserId === this.SystemUserId) return "Me";

    return this.UserName;
  }

  getUserId(): string | null {
    return this.memberships?.find((m) => m.level === "user")?.id ?? null;
  }
}

class UserProps extends DataModelProps<Data> {}

User.prototype.Props = UserProps;
User.prototype._ = new UserProps();

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

export interface User extends Data {
  _: UserProps;
}

interface UserProps {
  companyRoleMap: {
    owner?: boolean;
    charterer?: boolean;
    broker?: boolean;
  };
}

type Data = DeepPartial<TradeAPI["User"]>;
