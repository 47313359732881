import { LABEL_MAP } from "___REFACTOR___/constants";
import { TradeAPI } from "___REFACTOR___/apis";
import { SupplyPriceEditor } from "___REFACTOR___/components";
import { UnitValue, UnitValueProps } from "../UnitValue";

class SupplyPrice extends UnitValue {
  static label = LABEL_MAP.supplyPrice;
  static get Editor() {
    return SupplyPriceEditor;
  }

  constructor(data?: PartialData) {
    super(data);
  }
}
class SupplyPriceProps extends UnitValueProps {}

SupplyPrice.prototype.Props = SupplyPriceProps;
SupplyPriceProps.prototype.Editor = SupplyPriceEditor;
SupplyPriceProps.prototype.sortKey = "value";
SupplyPriceProps.prototype.label = LABEL_MAP.supplyPrice;
SupplyPriceProps.prototype.JSONDefaults = {
  unit: "PerDay",
};
SupplyPriceProps.prototype.unitArr = ["PerMT", "PerDay", "LumpSum"];

export { SupplyPrice, SupplyPriceProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface SupplyPrice extends Data {
  Props: typeof SupplyPriceProps;
  _: SupplyPriceProps;
}

interface SupplyPriceProps {
  sortKey: keyof Data;
  JSONDefaults: Partial<Data>;
  unitArr: TradeAPI.SupplyPrice.Unit[];
}

type PartialData = Partial<Data>;
type Data = TradeAPI.SupplyPrice;
