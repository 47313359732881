import React from "react";
import Field from "../Field/Field";
import EditorRowEmptyValue from "../EditorRowEmptyValue/EditorRowEmptyValue";
import { ICargoSizeView } from "sharedFolder/Models/IDetails";
import { NewCargoSizeEditor } from "../../editors/NewCargoSizeEditor/NewCargoSizeEditor";

interface ICargoSizeFieldProps<T> {
  isFieldValid: (key: T) => boolean;
  updateForm: (key: T, value: any) => void;
  selectedItem?: ICargoSizeView;
  isExpanded: boolean;
  setExpandedItem: (name: T) => void;
  required: boolean;
}

export const CargoSizeField = (props: ICargoSizeFieldProps<"cargoSize">) => {
  return (
    <Field
      id={"cargoSize"}
      label={"Cargo Size(s) *"}
      name={"cargoSize"}
      edit={
        <NewCargoSizeEditor
          focus={true}
          label="Size"
          isValid={props.isFieldValid("cargoSize")}
          value={props.selectedItem}
          onChange={(value?: ICargoSizeView) => {
            props.updateForm("cargoSize", value);
          }}
          withNotes={true}
        />
      }
      expanded={props.isExpanded}
      expand={() => {
        props.setExpandedItem("cargoSize");
      }}
      display={
        <>{(props.selectedItem && cargoSizeDisplay(props.selectedItem)) || <EditorRowEmptyValue required={props.required} />}</>
      }
      isValid={props.isFieldValid("cargoSize")}
    />
  );
};

const cargoSizeDisplay = (value?: ICargoSizeView) => {
  return value?.display;
};
