import { NegotiationUIStatus } from "./offerRepStatusReducer";
import { IVesselSelectedView } from "../Form/VesselSelector/VesselSelector";
import { DetailTypePlusOwner } from "../Form/Negotiable/INegotiableItemProps";
import { IVesselView } from "sharedFolder/Models/IDetails";
import { omit, isEqual } from "lodash-es";

interface OfferRepEnabledState {
  isEnabled: boolean;
  isSubmitButtonEnabled: boolean;
}

export const initialState = {
  isEnabled: true,
  isSubmitButtonEnabled: true,
};

type OfferRepEnabledAction = {
  type: NegotiationUIStatus;
  payload: {
    detailTypesChanged: DetailTypePlusOwner[];
    vessels: IVesselView[];
    allVesselsSelectedOnTheClient: IVesselSelectedView[];
  };
};

/**
 * Returns true if the user has updated the Vessels on the UI and there are therefore vessels
 * ready to be POSTed to backend
 * @param vessels Vessels that are currently on the negotation (server-side)
 * @param allVesselsSelectedOnTheClient All Vessels including those that have been recently added on the client side
 */
const hasOwnerChangedAnyNegVessels = (vessels: IVesselView[], allVesselsSelectedOnTheClient: IVesselSelectedView[]) => {
  // prepare both the current and the neg vessels for comparison
  const currentVessels = allVesselsSelectedOnTheClient.map((v) => omit(v, ["isDeletable", "vesselName"]));
  // make the negVessels look like IVesselSelectedView
  const existingVesselsOnNegotiation = vessels.map((v) => omit(v, ["vesselName"]));
  return !isEqual(existingVesselsOnNegotiation, currentVessels);
};

export const offerRepFormEnabledReducer = (state: OfferRepEnabledState, action: OfferRepEnabledAction) => {
  switch (action.type) {
    // Below are the states that are always disabled
    case "Withdrawn":
    case "FirmBid":
    case "FirmBidAccepted":
    case "FirmOfferRequested":
    case "RequestInProgress":
    case "MainTerms":
    case "FirmOfferAccepted":
      return {
        ...state,
        isEnabled: false,
        isSubmitButtonEnabled: false,
      };

    // Below are the states that are always enabled
    case "FirmOffer":
    case "FirmOfferExpired":
    case "FirmBidExpired":
    case "FirmOfferCounteredWithIndication":
    case "CounterFirmBid":
    case "DeclineFirmOfferRequest":
    case "SetExpiryDateOfFirmOffer":
    case "RequestErrorOccured":
    case "TimerExpired":
      return {
        ...state,
        isEnabled: true,
        isSubmitButtonEnabled: true,
      };

    // below are the states that are enabled only if there is something set by the user
    case "IndicationBid":
    case "IndicationBidSent":
    case "IndicationOffer":
      if (
        hasOwnerChangedAnyNegVessels(action.payload.vessels, action.payload.allVesselsSelectedOnTheClient) ||
        action.payload.detailTypesChanged.length > 0
      ) {
        return {
          ...state,
          isSubmitButtonEnabled: true,
          isEnabled: true,
        };
      }
      return {
        ...state,
        isSubmitButtonEnabled: false,
        isEnabled: true,
      };

    case "None":
    case "SendRecap":
      return state;

    default:
      throw new Error(`offerRepFormEnabledReducer - no condition for '${action.type}'`);
  }
};
