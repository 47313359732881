import * as api from "../../api/negotiations/models";
import { IDurationView } from "../Models/IDetails";
import { DurationUnit } from "../components/editors/DurationEditor/DurationEditor";
import { IMapper, withNullable } from "./map";

const durationUnitToEnum: { [key in api.DurationUnit]: DurationUnit } = {
  Days: DurationUnit.Days,
  Months: DurationUnit.Months,
};

const map: IMapper<api.IDuration, IDurationView> = {
  toApi: (viewModel: IDurationView) => {
    return {
      max: viewModel.max || 0,
      min: viewModel.min || 0,
      unit: viewModel.unit || "Days",
      notes: viewModel.notes || undefined,
      display: viewModel.display,
    };
  },
  toView: (apiModel: api.IDuration) => {
    return {
      display: apiModel.display,
      max: apiModel.max,
      min: apiModel.min,
      unit: durationUnitToEnum[apiModel.unit || "Days"],
      notes: apiModel.notes,
    };
  },
  emptyViewModel: {
    max: null,
    min: null,
    unit: undefined,
  },
};

export const mapDuration = withNullable(map);
