import { Location, LocationProps } from "../Location";
import { LABELS } from "@/constants";

class RedeliveryLocation extends Location {}

class RedeliveryLocationProps extends LocationProps {}

RedeliveryLocationProps.prototype.label = LABELS["redeliveryLocation"];

RedeliveryLocation.prototype.Props = RedeliveryLocationProps;
RedeliveryLocation.prototype._ = new RedeliveryLocationProps();

export { RedeliveryLocation, RedeliveryLocationProps };

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */
