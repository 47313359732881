import { getNegotiationApiService } from "../../api/negotiations/Negotiations";
import { INegotiationOwningCompanyUpdate } from "../../api/negotiations/models";
import { IAccountView } from "sharedFolder/Models/IDetails";
import { IAccount } from "../../api/models/IAccount";

// Not sure if we are going to use IAccount in the BE, will investigate that and remove this if unnecessary
const mapAccountViewToAccount = (account: IAccountView): IAccount => {
  return {
    accountId: account.accountId,
    accountName: account.accountName,
    arcContactId: account.arcContactId,
    gainAccountId: account.gainAccountId,
    gainAccountGroupId: account.gainAccountGroupId,
    isLegalEntity: account.isLegalEntity,
  };
};

export function setOwningCompany(
  ctradeUrl: string,
  orderId: string,
  negId: string,
  updateToken: string,
  company: IAccountView,
  chartererCompanyId?: string
) {
  // take the current neg and apply the action
  const setOwningCompany = getNegotiationApiService(ctradeUrl, orderId).setCounterParty;
  const body: INegotiationOwningCompanyUpdate = {
    value: mapAccountViewToAccount(company),
    chartererCompanyId,
    updateToken,
  };
  return setOwningCompany(orderId, negId, body);
}
