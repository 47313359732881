import * as React from "react";
import { IValidationContext } from "sharedFolder/contexts/ValidationContext";
import { withValidationContext } from "sharedFolder/contexts/withValidationContext";
import { NewLiftingsEditor } from "../NewLiftingsEditor/NewLiftingsEditor";
import { ILiftingsProps } from "sharedFolder/components/common/interfaces";
import { liftingsDisplay, ILiftingsView, isLiftingsValid } from "sharedFolder/Models/IDetails";

interface ILiftingsEditorProps extends ILiftingsProps {
  id: string;
}

class LiftingsEditorComponent extends React.Component<ILiftingsEditorProps & IValidationContext, {}> {
  constructor(props: ILiftingsEditorProps & IValidationContext) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  private onChange(liftingsView: ILiftingsView) {
    const view = {
      ...liftingsView,
      display: liftingsDisplay(liftingsView),
    };

    this.props.onChange(view);
    if (view) {
      const isValid = isLiftingsValid(view);
      this.props.fieldValidityChanged(this.props.id, isValid);
    }
  }

  render() {
    return <NewLiftingsEditor {...this.props} onChange={this.onChange} />;
  }
}

export const LiftingsEditor = withValidationContext(LiftingsEditorComponent);
