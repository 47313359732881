import React, { useReducer, useEffect } from "react";
import { isEqual } from "lodash-es";
import { isNumeric } from "validator";
import { INominationsView } from "sharedFolder/Models/IDetails";
import { parseValuesAsString } from "sharedFolder/Utilities/parseValuesAsString";
import Input from "sharedFolder/components/common/Input/Input";
import { Notes } from "sharedFolder/components/common/Notes/Notes";
import styles from "../NominationsEditor/NominationsEditor.module.scss";
import { emptyArrowFn } from "../../../../@/utils";

interface INominationsEditorState {
  noticePerLaycan?: number | string | null;
  laycanSpread?: number | string | null;
  finalLaycanNotice?: number | string | null;
  notes?: string;
}

type action = "replaceForm" | "setNoticePerLaycan" | "setLaycanSpread" | "setFinalLaycanNotice" | "setNotes";

const theReducer =
  (onChange: (value: INominationsView) => void) =>
  (
    state: INominationsEditorState,
    action: { type: action; value: string | number | INominationsEditorState }
  ): INominationsEditorState => {
    const triggerOnChange = (state: INominationsEditorState) => {
      const noticePerLaycan =
        state.noticePerLaycan !== undefined &&
        isNumeric(String(state.noticePerLaycan), {
          no_symbols: true,
        })
          ? Number(state.noticePerLaycan)
          : null;
      const laycanSpread =
        state.laycanSpread !== undefined &&
        isNumeric(String(state.laycanSpread), {
          no_symbols: true,
        })
          ? Number(state.laycanSpread)
          : null;
      const finalLaycanNotice =
        state.finalLaycanNotice !== undefined &&
        isNumeric(String(state.finalLaycanNotice), {
          no_symbols: true,
        })
          ? Number(state.finalLaycanNotice)
          : null;

      onChange({
        noticePerLaycan,
        laycanSpread,
        finalLaycanNotice,
        notes: state.notes,
      });
      return state;
    };
    switch (action.type) {
      case "setNoticePerLaycan":
        return triggerOnChange({
          ...state,
          noticePerLaycan: action.value as number,
        });
      case "setLaycanSpread":
        return triggerOnChange({
          ...state,
          laycanSpread: action.value as number,
        });
      case "setFinalLaycanNotice":
        return triggerOnChange({
          ...state,
          finalLaycanNotice: action.value as number,
        });
      case "setNotes":
        return triggerOnChange({ ...state, notes: action.value as string });
      case "replaceForm":
        return triggerOnChange({
          ...state,
          ...(action.value as INominationsEditorState),
        });
    }
    return state;
  };

export interface INominationsEditorProps {
  focus?: boolean;
  disabled?: boolean;
  isValid?: boolean;
  required?: boolean;
  onChange: (value: INominationsView) => void;
  value?: INominationsView;
}

export const NewNominationsEditor: React.SFC<INominationsEditorProps> = ({ onChange, required, disabled, focus, value }) => {
  const parsedValue = {
    noticePerLaycan: parseValuesAsString(value?.noticePerLaycan),
    laycanSpread: parseValuesAsString(value?.laycanSpread),
    finalLaycanNotice: parseValuesAsString(value?.finalLaycanNotice),
    notes: value?.notes,
  };
  const [state, dispatch] = useReducer(theReducer(onChange), parsedValue);

  useEffect(() => {
    // this is mainly for when a tempalte changes, a better approach would be to lift the state of this component up to the form level
    if (!isEqual(parsedValue, state)) {
      dispatch({ type: "replaceForm", value: parsedValue });
    }
  }, [parsedValue, state]);

  return (
    <>
      <div className={styles.inputRow}>
        <div className={styles.inputGroup}>
          <div className={styles.container}>
            <div className={styles.part}>
              <div className={styles.label}>Notice per laycan</div>
              <Input
                id={"notice_per_laycan"}
                ariaLabel={"Notice per Laycan"}
                dataTest={"notice-per-laycan"}
                onChange={(evt) => {
                  dispatch({
                    type: "setNoticePerLaycan",
                    value: evt.currentTarget.value,
                  });
                }}
                value={state.noticePerLaycan}
                required={required}
                focus={focus}
                disabled={disabled}
                width="small"
              />
            </div>
            <div className={styles.part}>
              <div className={styles.label}>Laycan spread</div>
              <Input
                id={"laycan_spread"}
                ariaLabel={"Laycan Spread"}
                dataTest={"laycan-spread"}
                onChange={(evt) => {
                  dispatch({
                    type: "setLaycanSpread",
                    value: evt.currentTarget.value,
                  });
                }}
                value={state.laycanSpread}
                required={required}
                onBlur={emptyArrowFn}
                disabled={disabled}
                width="small"
              />
            </div>
            <div className={styles.part}>
              <div className={styles.label}>Final laycan notice</div>
              <Input
                id={"final_laycan_notice"}
                ariaLabel={"Final Laycan Notice"}
                dataTest={"final-laycan-notice"}
                onChange={(evt) => {
                  dispatch({
                    type: "setFinalLaycanNotice",
                    value: evt.currentTarget.value,
                  });
                }}
                value={state.finalLaycanNotice}
                required={required}
                disabled={disabled}
                width="small"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.inputRow}>
        <div className={styles.inputGroup}>
          <Notes
            id={`nominations_notes`}
            dataTest="nominations-notes"
            label="Notes"
            placeholder="Additional nominations notes"
            className={styles.notes}
            value={state.notes}
            onChange={(value) => {
              dispatch({ type: "setNotes", value: value });
            }}
            disabled={disabled}
            maxLength={200}
          />
        </div>
      </div>
    </>
  );
};
