import { Negotiation, dialog } from "@/models";
import { when } from "mobx";

export async function postArcNegotiationSubs(model: Negotiation | undefined, option) {
  const title = option.statusTitle;

  if (!model) {
    console.error("postArcNegotiationSubs:", { model, option });

    const status = {} as Status;

    status.type = "error";
    status.title = `Negotiation ${title} Failure`;

    dialog.show({
      status,
      dataTest: `post-negotiation-onsubs-${option.action}-fail`,
    });

    return;
  }

  model._.status.loading = true;
  model._.status.message = option.loadingMessage;

  const dataRes = await model.getArcData();

  if (!dataRes?.ok) {
    model._.status.type = "error";
    model._.status.title = `Negotiation Get Arc Data Failure`;
    model._.status.dump = { res: dataRes, entity: model };

    dialog.show({
      status: model._.status,
      dataTest: `post-negotiation-onsubs-get-arc-data-fail`,
    });

    model._.status.loading = false;
    model._.status.message = null;

    return dataRes;
  }

  let { cpDate, userTimeZone, ...payload } = option?.payload || {};

  payload = {
    ...payload,
    ...(option.action === "postArcSubsLiftAndFix" && {
      cpDate,
      userTimeZone,
    }),
    updateToken: dataRes.data?.updateToken || model.updateToken,
  };

  const res = await model[option.action](payload);

  if (!res?.ok) {
    model._.status.type = "error";
    model._.status.title = `Negotiation ${title} Failure`;
    model._.status.dump = { res, entity: model };

    dialog.show({
      status: model._.status,
      dataTest: `post-negotiation-onsubs-${option.action}-fail`,
    });

    model._.status.loading = false;
    model._.status.message = null;

    return res;
  }

  const order = model._.order;
  const currentOrderVersion = order._.version;
  const orderUpdate = when(() => currentOrderVersion !== order._.version);
  const orderUpdateTimeout = new Promise((resolve) => setTimeout(resolve, 90000, "timedout"));
  const orderRaceRes = await Promise.race([orderUpdate, orderUpdateTimeout]);

  if (orderRaceRes === "timedout") {
    model._.status.type = "error";
    model._.status.title = `Negotiation ${title} Timed Out`;

    dialog.show({
      status: model._.status,
      dataTest: `post-negotiation-onsubs-${option.action}-timedout-fail`,
    });

    model._.status.loading = false;
    model._.status.message = null;

    return;
  }

  model._.status.loading = false;
  model._.status.message = null;

  if (option.successStatus) {
    dialog.show({
      status: option.successStatus,
      dataTest: `post-negotiation-onsubs-${option.action}-success`,
    });
  }

  return res;
}
