import React, { useState } from "react";
import { OrderNegStatus } from "sharedFolder/Models/OrderNegStatus";
import NegotiationAttachmentsPanel from "./NegotiationAttachmentsPanel";
import { INegotiationCommonView } from "sharedFolder/Models/Negotiation/INegotiationCommonView";
import { IGetView } from "sharedFolder/Models/Negotiation/IGetView";
import Icon from "__legacy/sharedFolder/components/common/Icon/Icon";

const NegotiationGridAttachmentsIcon = (props: {
  negotiation: IGetView<INegotiationCommonView>;
  orderId: string;
  orderStatus: OrderNegStatus;
  dom?: DOMRef;
}) => {
  const [attachmentPanelExpanded, setAttachmentPanelExpanded] = useState<boolean>(false);
  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setAttachmentPanelExpanded(true);
      }}
      aria-label="Attachments"
      data-test="attachments-icon-and-count"
    >
      <Icon reactRef={props.dom} icon="attach-file" />({props.negotiation.attachments.length})
      {attachmentPanelExpanded && (
        <NegotiationAttachmentsPanel
          attachmentPanelExpanded={attachmentPanelExpanded}
          setAttachmentPanelExpanded={(status) => setAttachmentPanelExpanded(status)}
          negotiationId={props.negotiation.id}
          orderId={props.negotiation.orderId}
          updateToken={props.negotiation.updateToken}
          attachments={props.negotiation.attachments}
        />
      )}
    </div>
  );
};

export default NegotiationGridAttachmentsIcon;
type DOMRef = React.MutableRefObject<HTMLButtonElement | null>;
