import dayjs from "dayjs";
import { TradeAPI } from "___REFACTOR___/apis";
import { DataModel, DataModelProps } from "../DataModel";

export class CpSearchItem extends DataModel {
  constructor(data: PartialData) {
    super(data);
  }

  get cpDateGridView() {
    const format = "DD MMM YY";
    const formattedDate = dayjs.utc(this.cpDate).format(format);
    return formattedDate;
  }
}

class CpSearchItemProps extends DataModelProps {}

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

export interface CpSearchItem extends Data {
  Props: typeof CpSearchItemProps;
  _: CpSearchItemProps;
}

type PartialData = Partial<Data>;
type Data = TradeAPI.CpSearchItem;
