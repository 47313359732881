import React, { MutableRefObject, useRef, useState } from "react";
import classNames from "classnames";
import * as ClassNames from "classnames";
import { StatusMessages } from "___REFACTOR___/components";
import { Input } from "../../Input";
import { Heading } from "../Heading";
import "./InputHeading.scss";

function InputHeading(props: InputHeading.Props) {
  let { className, dataTest, value, onChange, children, status, setTouched, type, ...rest } = props;
  const [focus, setFocus] = useState(false);
  const DomRef = useRef(null) as InputDOMRef;
  const Type = Input.TYPE_MAP[type];

  className = classNames(className, status?.type, { focus });

  return (
    <app-form-input-heading class={className} {...rest} onClick={onClick}>
      <app-form-input-heading-row>
        <Type
          value={value}
          onChange={onChange}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          status={status}
          dataTest={dataTest}
          domRef={DomRef}
        />
        <Heading>{children}</Heading>
      </app-form-input-heading-row>
      <StatusMessages status={status} />
    </app-form-input-heading>
  );

  function onClick() {
    DomRef.current?.click();

    setTouched?.(true);
  }

  function onInputFocus() {
    setFocus(true);
  }

  function onInputBlur() {
    setFocus(false);
  }
}

export { InputHeading };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace InputHeading {
  export interface Props extends Omit<ReactHTMLHeadingProps, "className" | "onChange"> {
    type: Input.Type;
    className?: ClassNames.Argument;
    setTouched?: (value: boolean) => void;
    value: Input.Props["value"];
    onChange: Input.Props["onChange"];
    status?: Input.Props["status"];
    dataTest: Input.Props["dataTest"];
  }

  type ReactHTMLHeadingProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>;
}

type InputDOMRef = MutableRefObject<any>;
