import React from "react";
import { observer } from "mobx-react";
import { testSelectors } from "@/constants";
import { Button } from "@/components";
import { UniversalOrderNegotiationFormValues } from "@/models";
import "./FormControl.scss";

function FormControl(props) {
  const { contextViewOrderProps } = props;

  const formContext = contextViewOrderProps.form.context;
  const { buttons } = contextViewOrderProps;
  const submitText = buttons?.submit || "Submit";
  const cancelText = buttons?.cancel || "Cancel";

  if (!formContext?.formik) return null;

  const { submit, reset } = formContext;
  const { isSubmitting } = formContext?.formik;
  const values = formContext.values as UniversalOrderNegotiationFormValues;
  const submittable = values?.orderTemplate?.id || formContext.isChanged;

  if (!submittable) return null;

  return (
    <div className="OrderCreation-form-control">
      <Button onClick={reset} variant="flat" disabled={isSubmitting}>
        {cancelText}
      </Button>
      <Button onClick={submit} variant="action" disabled={isSubmitting} data-test={testSelectors.submitNewOrderButton}>
        {submitText}
      </Button>
    </div>
  );
}

const Observer = observer(FormControl);

export { Observer as FormControl };
