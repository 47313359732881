import { getNegotiationApiService } from "../../api/negotiations/Negotiations";
import { INegotiationFirmAccepted } from "../../api/negotiations/models";

export function firmAccepted(ctradeUrl: string, orderId: string, negId: string, updateToken: string, side: "bid" | "offer") {
  // take the current neg and apply the action
  const firmAccepted = getNegotiationApiService(ctradeUrl, orderId).firmAccepted;
  const body: INegotiationFirmAccepted = {
    action: "firmAccepted",
    updateToken,
  };
  return firmAccepted(orderId, negId, body, side);
}
