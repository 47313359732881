import { makeAutoObservable, observable } from "mobx";
import { Dropdown as Component } from "___REFACTOR___/components/services/Dropdown";

class Dropdown {
  constructor() {
    this.open = false;
    this.props = undefined;
    this.promise = undefined;
    this.resolve = undefined;

    makeAutoObservable(this, {
      props: observable.ref,
    });
  }

  show = (props: Component.Props) => {
    this.open = true;
    this.props = props;
    this.promise = new Promise((resolve) => {
      this.resolve = resolve;
    });

    return this.promise;
  };

  update = (props: Component.Props) => {
    this.props = { ...this.props, ...props };
  };

  hide = () => {
    if (this.resolve) this.resolve();

    this.open = false;
    this.props = undefined;
    this.promise = undefined;
    this.resolve = undefined;
  };

  select = (item) => {
    if (this.resolve) this.resolve(item);

    this.hide();
  };
}

export const dropdown = new Dropdown();

interface Dropdown {
  promise: Promise<Component.Item> | undefined;
  resolve: ((item?: Component.Item) => void) | undefined;
  open: boolean;
  props?: Component.Props;
}
