// import React from "react";
import { observer } from "mobx-react";
// import classNames from "classnames";
// import { ProformaLayout } from "___REFACTOR___/models";
// import { proformaLayouts } from "___REFACTOR___/models";
// import { SelectField, SelectFieldProps } from "___REFACTOR___/components";

function ProformaLayoutKeyEditor(props) {
  return null;

  // props = { ...props };

  // props.className = classNames(props.className, "ProformaLayoutKey");

  // const { layoutsById } = proformaLayouts;

  // const layoutId = props.layoutId;
  // const data = layoutId ? layoutsById?.[layoutId]?.keys : undefined;

  // return (
  //   <SelectField
  //     {...props}
  //     data={data}
  //     noDataStatus={{
  //       message: layoutId ? "No matching Proforma Key" : "Missing Proforma Layout",
  //     }}
  //     dataTest={`${props.dataTest}.value`}
  //   />
  // );
}

const Observer = observer(ProformaLayoutKeyEditor);

export { Observer as ProformaLayoutKeyEditor };

// interface Props extends Omit<SelectFieldProps, "value" | "onChange"> {
//   value?: string;
//   onChange?: (value: string) => void;
//   layoutId?: ProformaLayout["layoutId"];
//   observableProps?: Omit<Props, "observableProps">;
// }
