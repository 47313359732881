import { useState } from "react";
import { Panel, PanelType } from "office-ui-fabric-react";
import styles from "./DistributionListPanel.module.scss";
import Button from "__legacy/sharedFolder/components/common/Button/Button";
import { IEmailList } from "../../services/settings";
import { DistributionGroup } from "./DistributionGroup";
import { PanelCloseButton } from "sharedFolder/components/common/CloseButton/CloseButton";

export type IGenericErrorObject = { [key: string]: boolean };

interface IRenderError {
  validationErrors?: IGenericErrorObject | null;
  apiError?: boolean | null;
}

interface IDistributionListPanelProps {
  list: IEmailList[];
  softUpdate: (newList: IEmailList[]) => void;
  onDismiss: () => void;
}

export function renderDistributionGroupError(allErrors: IRenderError) {
  const { apiError, validationErrors } = allErrors;
  if (!apiError && !validationErrors) {
    return null;
  }
  if (apiError) {
    return "failed to post to the server";
  } else if (validationErrors && validationErrors.nameAlreadyExist) {
    return "Duplicate names not allowed";
  } else if (validationErrors && validationErrors.nameTooLong) {
    return "Name is too long";
  } else if (validationErrors && validationErrors.nameEmpty) {
    return "Name cannot be empty";
  } else {
    // default
    return "Please correct invalid entries";
  }
}

// TODO: this is almost identical right now to renderDistributionGroupError with the exception of the message content
export function renderDistributionEmailError(allErrors: IRenderError) {
  const { apiError, validationErrors } = allErrors;
  if (!apiError && !validationErrors) {
    return null;
  }
  if (apiError) {
    return "failed to post to the server";
  } else if (validationErrors && validationErrors.emailAlreadyExist) {
    return "Duplicate emails not allowed";
  } else if (validationErrors && validationErrors.emailTooLong) {
    return "Email is too long";
  } else if (validationErrors && validationErrors.emailEmpty) {
    return "Email cannot be empty";
  } else if (validationErrors && validationErrors.invalidEmail) {
    return "Must be a valid email";
  } else {
    // default
    return "Please correct invalid entries";
  }
}

const DistributionListPanel = ({ list, softUpdate, onDismiss }: IDistributionListPanelProps) => {
  const [currentlyExpandedGroup, setCurrentlyExpandedGroup] = useState<string | null>(null);
  const [currentlyOpenedEditor, setCurrentlyOpenedEditor] = useState<string | null>(null);

  return (
    <Panel
      isOpen={true}
      type={PanelType.medium}
      onRenderFooter={() => <></>}
      onDismiss={onDismiss}
      onRenderNavigation={() => <PanelCloseButton onClick={onDismiss} />}
    >
      <div>
        <div className={styles.header}>
          <h2>Manage distribution lists</h2>
          <div className={styles.newGroupContainer}>
            <Button type="flat" icon="plus" ariaLabel="Create New Group" data-test="new-group-name">
              Create New Group
            </Button>
          </div>
        </div>
        <div className={styles.listContainer}>
          {list.map((item: IEmailList) => {
            const uniqueId = `${item.id}${item.name}`;
            return (
              <DistributionGroup
                key={uniqueId}
                mode="edit"
                expandEditor={currentlyOpenedEditor === uniqueId}
                toggleEditor={
                  currentlyOpenedEditor === uniqueId
                    ? () => setCurrentlyOpenedEditor(null)
                    : () => setCurrentlyOpenedEditor(uniqueId)
                }
                expandList={currentlyExpandedGroup === uniqueId}
                toggleList={
                  currentlyExpandedGroup === uniqueId
                    ? () => setCurrentlyExpandedGroup(null)
                    : () => setCurrentlyExpandedGroup(uniqueId)
                }
                currentList={list}
                item={item}
              />
            );
          })}
        </div>
      </div>
      <div className={styles.doneButton}>
        <Button type="action" onClick={onDismiss} ariaLabel="Done">
          Done
        </Button>
      </div>
    </Panel>
  );
};

export default DistributionListPanel;
