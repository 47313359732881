import { TradeAPI } from "___REFACTOR___/apis";
import { isTruthy } from "___REFACTOR___/utils";
import { VesselEditor } from "___REFACTOR___/components";
import { DataModel, DataModelProps } from "../DataModel";
import { CustomDate } from "../CustomDate";
import { schema } from "./schema";

class Vessel extends DataModel {
  static Status = {
    accepted: 3,
    named: 2,
    rejected: 1,
  };

  constructor(data?: PartialData, props?: Partial<VesselProps>) {
    super(data);

    this.vesselImo = this.vesselImo || this.vesselIMO;
    this.vesselIMO = this.vesselImo || this.vesselIMO;

    this._.eta = props?.eta || new CustomDate({ value: data?.eta });
  }

  toString() {
    return [
      this.registrationDataName && `${this.registrationDataName} -`,
      this.vesselIMO && `IMO ${this.vesselIMO}`,
      this.buildYear && `YOB ${this.buildYear}`,
      this.dwt && `DWT ${this.dwt}`,
    ]
      .filter(isTruthy)
      .join(" ");
  }

  toGridView() {
    if (!this) return "";

    if (!this.registrationDataName) return "";

    return this.registrationDataName;
  }

  toJSON() {
    const { _, ...rest } = this;

    return {
      ...rest,
      eta: _.eta,
    };
  }

  static Editor = VesselEditor;
}

function createVessel(vessel: Data) {
  return new Vessel(vessel);
}

class VesselProps extends DataModelProps {
  get schema() {
    return schema;
  }
}

Vessel.prototype.Props = VesselProps;
VesselProps.prototype.label = "Vessel";

export { Vessel, VesselProps, createVessel };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface Vessel extends Data {
  Props: typeof VesselProps;
  _: VesselProps;
}

interface VesselProps {
  eta: CustomDate;
}

type PartialData = Partial<Data>;
type Data = TradeAPI.Vessel;
