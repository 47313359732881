import { memo } from "react";
import { Label } from "@/components";
import { Field } from "../Field";
import "./CollapsibleRow.scss";

function CollapsibleRow(props) {
  const { status, element } = props;
  const { label, name, formProps } = element;

  return (
    <div className="form-CollapsibleRow">
      <Label label={label} name={name} asterisk={formProps?.JSONSchema?.required?.includes(name)} status={status} />
      <div className="form-CollapsibleRow-children">
        <Field {...props} />
      </div>
    </div>
  );
}

const Memo = memo(CollapsibleRow);

export { Memo as CollapsibleRow };
