import { Negotiation, Order } from "@/models";
import { userStorage } from "../UserStorage";

class EntityStorage {
  get = (entity, defaultValue?) => {
    if (!entity || !entity.id) return;
    if (entity.id.includes("$")) return;

    const { id } = entity;

    if (entity instanceof Order) return userStorage.get(`order/${id}`, defaultValue);

    if (entity instanceof Negotiation) return userStorage.get(`negotiation/${id}`, defaultValue);
  };

  set(entity: Negotiation | Order | undefined, data) {
    if (!entity || !entity.id) return;
    if (entity.id.includes("$")) return;

    const current = this.get(entity);

    if (current) {
      data = Object.assign(current, data);
    }

    if (entity instanceof Order) userStorage.set(`order/${entity.id}`, data);

    if (entity instanceof Negotiation) userStorage.set(`negotiation/${entity.id}`, data);
  }

  setVersionWhenBannerWasClosed(entity?: Negotiation | Order) {
    this.set(entity, { versionWhenBannerWasClosed: entity?.version });
  }

  setExpiryDismissBannerToggle(value: Boolean, entity?: Negotiation | Order) {
    const storageData = this.get(entity);
    if (storageData?.expiryDismiss === true && storageData?.expiryDismissVersion === entity?.version) return;
    this.set(entity, { expiryDismiss: value, expiryDismissVersion: entity?.version });
  }
}

export const entityStorage = new EntityStorage();

class GloballyUniqueEntityStorage {
  get(entity): GloballyUniqueEntityStorageData {
    return userStorage.get(`guid/${entity.id}`);
  }

  set(entity, data: GloballyUniqueEntityStorageData) {
    return userStorage.set(`guid/${entity.id}`, data);
  }

  setSeenVersion(entity) {
    const stored = this.get(entity);

    this.set(entity, { ...stored, seenVersion: entity.version });
  }
}

export const globallyUniqueEntityStorage = new GloballyUniqueEntityStorage();

interface GloballyUniqueEntityStorageData {
  seenVersion?: number;
}
