import React from "react";
import LiftingVesselManagement from "./LiftingVesselManagement";
import { ILiftingsDisplayValues } from "sharedFolder/Models/ILiftingsCollectionView";
import { INegotiationView } from "sharedFolder/Models/Negotiation/INegotiationView";
import { LiftingsCargoDetailsNegotiation } from "./LiftingsCargoDetailsNegotiation";
import { LiftingsCargoDetailsDisplayValues } from "./LiftingsCargoDetailsDisplayValues";
import LineSeparator from "sharedFolder/components/common/LineSeparator/LineSeparator";
import styles from "./LiftingsView.module.scss";
import { NegotiationContext, getLiftingsHandler } from "../common/Context/NegotiationContext";
import { useConfig } from "__legacy/sharedFolder/ConfigurationContext";
import { NegotiationPermittedMethodsContext } from "sharedFolder/contexts/NegotiationPermittedMethodsProvider";
import { Role } from "../negotiate/Negotiation/Role";
import { NegotiationNotes } from "../negotiate/Negotiation/NegotiableDetails/NegotiationNotes/NegotiationNotes";
import { useNegotiationNote } from "../negotiate/Negotiation/useNegotiationNote";
import { LiftingsNoteWrapper } from "./LiftingsNoteWrapper";

interface IProps {
  displayValues: ILiftingsDisplayValues;
  id: string;
  cargoId: string;
  neg?: INegotiationView;
  orderId: string;
  updateToken: string;
  onDismiss: () => void;
  isDealCapture: boolean;
  canChartererManagedLiftings: boolean;
}

const LiftingsView = (props: IProps) => {
  const { displayValues, id, cargoId, neg, orderId, onDismiss, updateToken, isDealCapture } = props;

  const { ctradeUrl } = useConfig();
  const { displayNote, setDisplayNote, noteRole, setNoteRole } = useNegotiationNote();
  return (
    <div className={styles.container} data-test={`liftings-view-${id}`}>
      {neg?.details && !isDealCapture && (
        <NegotiationContext.Provider value={getLiftingsHandler(ctradeUrl, updateToken, neg)}>
          <NegotiationPermittedMethodsContext.Provider value={{ permittedMethods: neg.permittedMethods }}>
            <LiftingVesselManagement
              liftingId={id}
              liftingCargoId={cargoId}
              orderId={neg.orderId}
              vessels={neg.details.vessels}
            />
          </NegotiationPermittedMethodsContext.Provider>
          <LineSeparator className={styles.lineSeparator} />
          <NegotiationNotes
            negotiation={neg}
            openNotePanel={(role: Role) => {
              setNoteRole(role);
              setDisplayNote(true);
            }}
            status={"indication"}
          />
        </NegotiationContext.Provider>
      )}

      {neg ? (
        <LiftingsCargoDetailsNegotiation
          liftingId={id}
          liftingCargoId={cargoId}
          updateToken={updateToken}
          orderId={orderId}
          negotiation={neg}
          isDealCapture={isDealCapture}
          canChartererManagedLiftings={props.canChartererManagedLiftings}
        />
      ) : (
        <LiftingsCargoDetailsDisplayValues
          liftingId={id}
          liftingCargoId={cargoId}
          updateToken={updateToken}
          orderId={orderId}
          displayValues={displayValues}
          onDismiss={onDismiss}
          isDealCapture={isDealCapture}
          canChartererManagedLiftings={props.canChartererManagedLiftings}
        />
      )}
      {displayNote && neg?.details && (
        <LiftingsNoteWrapper noteRole={noteRole} negotiation={neg} ctradeUrl={ctradeUrl} setDisplayNote={setDisplayNote} />
      )}
    </div>
  );
};

export default LiftingsView;
