import { CARGO_TYPES, CargoType } from "@/constants";

let cargoes: ICargoType[];

export async function getCargoTypes(filter: string): Promise<ICargoType[]> {
  if (!cargoes) {
    const data = CARGO_TYPES;

    cargoes = data.map((type: CargoType) => ({
      key: type.cargoTypeId,
      name: type.commodity,
    }));
  }

  const res =
    cargoes.filter((type: ICargoType) => {
      const name = `${type.name}`;
      const isMatching = name.toUpperCase().indexOf(filter.toUpperCase()) === 0;

      return isMatching;
    }) || [];

  return res;
}

interface ICargoType {
  key: number;
  name: string;
}
