import { ILaycanView } from "./IDetails";
export interface ILaycanSearchResult {
  start: string;
  end: string;
  display: string;
  shortDisplay: string;
  notes?: string;
}

export const mapLaycanSearchResultToILaycanView = (laycanSearchResult: ILaycanSearchResult | null): ILaycanView | undefined => {
  return laycanSearchResult
    ? {
        ...laycanSearchResult,
        to: laycanSearchResult.end,
        from: laycanSearchResult.start,
      }
    : undefined;
};
