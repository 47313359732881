import { IOrderSummary } from "../models/IOrderSummary";
import { Observable } from "rxjs";
import { getOrderApiService } from "../../api/orders/Orders";
import * as api from "../../api/orders/models";
import { cargoSizeText } from "sharedFolder/display/cargoSize";
import { mapCargoSize } from "sharedFolder/mappers/mapCargoSize";
import { mapOrderNegType } from "sharedFolder/mappers/mapOrderNegType";
import { mapCommission } from "sharedFolder/mappers/mapCommission";

/**
 *  This will return a collection of api.IOrder and will therefore need mapping to a View model
 *  whereever it is called
 */
export function getApiOrder(
  ordersUrl: string,
  orderId: string,
  withUpdates?: Observable<{ id: string; version: number }>
): Observable<api.IOrder> {
  return getOrderApiService(ordersUrl, withUpdates).get(orderId);
}

export function mapToOrderSummary(order: api.IOrder): IOrderSummary {
  const coa = {
    coaCargoSize: order.details.coaCargoSize ? order.details.coaCargoSize.display : undefined,
    dischargeLocation: order.details.dischargeLocation ? order.details.dischargeLocation.display : undefined,
    liftings: order.details.liftings ? order.details.liftings.display : undefined,
    loadLocation: order.details.loadLocation ? order.details.loadLocation.display : undefined,
    nominations: order.details.nominations ? order.details.nominations.display : undefined,
    notes: order.details.coaNotes || "",
    period: order.details.period ? order.details.period.display : undefined,
  };
  const tct = {
    deliveryLocation: order.details.deliveryLocation ? order.details.deliveryLocation.display : undefined,
    redeliveryLocation: order.details.redeliveryLocation ? order.details.redeliveryLocation.display : undefined,
    viaLocation: order.details.viaLocation ? order.details.viaLocation.display : undefined,
    notes: order.details.tctNotes || "",
    vesselSize: order.details.vesselSize ? order.details.vesselSize.shortDisplay || "" : "",
    duration: order.details.duration ? order.details.duration.display || "" : "",
  };
  const voyage = {
    loadLocation: order.details.loadLocation ? order.details.loadLocation.display : undefined,
    dischargeLocation: order.details.dischargeLocation ? order.details.dischargeLocation.display : undefined,
    cargoSize: order.details.cargoSize ? cargoSizeText(mapCargoSize.toView(order.details.cargoSize)) : undefined,
    notes: order.details.voyageNotes || "",
  };
  const general = {
    addressCommission: mapCommission.toViewOrEmpty(order.details.addressCommission)?.display || "",
    brokerCommission: mapCommission.toViewOrEmpty(order.details.brokerCommission)?.display || "",
    cargoType: order.details.cargoType ? order.details.cargoType.display : undefined,
    chartererAccount: order.details.chartererAccount.display || "",
    laycan: order.details.laycan?.display || "",
  };

  return {
    details: { ...general, ...voyage, ...tct, ...coa },
    voyNotes: order.details.voyageNotes || "",
    tctNotes: order.details.tctNotes || "",
    coaNotes: order.details.coaNotes || "",
    orderNumber: order.summary.orderReference,
    originallySelectedTypes: order.details.originallySelectedTypes.map(mapOrderNegType.toView),
    teamMembers: order.teamMembers,
    status: order.status,
    orderId: order.id,
    seaContractsUrl: order.seaContractsUrl,
  };
}
