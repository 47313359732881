import { TradeAPI } from "@/apis";
import { format } from "@/utils";
import { COACargoSizeEditor } from "@/components";
import { DataModel, DataModelProps } from "../DataModel";
import { LABELS } from "@/constants";

export class COACargoSize extends DataModel<Data> {
  onUpdate() {
    delete this.display;
    delete this.shortDisplay;
  }

  sizeRangeToString(): string {
    return `${format.number.display(this.min) ?? ""}-${format.number.display(this.max) ?? ""}`;
  }

  toString() {
    if (!this) return "";
    if (!isValid(this)) return "";

    const notes = this.notes || "";

    return `${this.sizeRangeToString()} / ${this.variance}% - ${this.option} ${notes}`;
  }

  toGridView() {
    if (!this) return "";
    if (!isValid(this)) return "";

    return `${format.number.display(this.min)}-${format.number.display(this.max)} / ${this.variance}% - ${this.option}`;
  }

  isValid() {
    return isValid(this);
  }
}

function isValid(data: Data) {
  return typeof data.variance === "number" && typeof data.min === "number" && typeof data.max === "number";
}

export class COACargoSizeProps extends DataModelProps<Data> {}

COACargoSizeProps.prototype.editor = COACargoSizeEditor;
COACargoSizeProps.prototype.sortKey = "max";
COACargoSizeProps.prototype.label = LABELS["coaCargoSize"];
COACargoSizeProps.prototype.JSONDefaults = {
  option: "MOLOO",
};

COACargoSize.prototype._ = new COACargoSizeProps();

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

export interface COACargoSize extends Data {
  _: COACargoSizeProps;

  sizeRangeToString(): string;
}

type Data = DeepPartial<TradeAPI["COACargoSize"]>;
