import React, { useState } from "react";
import classnames from "classnames";
import { IOfferRepViewNegotiation } from "negotiations/models/common/IOfferRep";
import { IConfiguration } from "sharedFolder/Configuration";
import { CargoDetails } from "sharedFolder/components/common/CargoDetails/CargoDetails";
import { useRequestChanges } from "./useRequestChanges";
import TextArea from "sharedFolder/components/common/TextArea/TextArea";
import styles from "./LiftingsLayout.module.scss";
import { Messages } from "sharedFolder/components/common/Messages/Messages";
import { LiftingsUIStatus } from "./liftingUIStatusReducer";
import { liftingsMessageReducer } from "sharedFolder/reducers/liftingsMessageReducer";

interface IRequestChangesFormProps {
  onCancel: () => void;
  config: IConfiguration;
  negotiation: IOfferRepViewNegotiation;
}

export const RequestChangesForm: React.FC<IRequestChangesFormProps> = (props: IRequestChangesFormProps) => {
  const { sendRequestChanges, sendButtonEnabled, liftingsState, notes, setNotes } = useRequestChanges(props.negotiation);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Messages<LiftingsUIStatus>
        message={liftingsState}
        negotiationId={props.negotiation.id}
        onOpenStateChanged={setIsOpen}
        messageReducer={liftingsMessageReducer}
      />
      <div className={classnames(styles.liftingsLayout, isOpen && styles.bannerHeight)} data-test="owner-dashboard-form">
        <div className={styles.topSection}>
          <div className={styles.title} data-test="title">
            Request changes to this cargo
          </div>
          <div className={styles.changeRequestBoxContainer}>
            <div>Describe what you would like to change</div>
            <TextArea
              ariaLabel="request-changes-text-area"
              dataTest="Request Changes Textbox"
              value={notes}
              className={styles.textarea}
              maxLength={10000}
              onChange={(evt: React.FocusEvent<HTMLTextAreaElement>) => setNotes(evt.target.value)}
            />
          </div>
          <div className={styles.cargoDetails}>
            <CargoDetails negotiation={props.negotiation} />
          </div>
        </div>
        <div className={styles.bottomSection}>
          <button
            className="action-button"
            disabled={!sendButtonEnabled}
            onClick={sendRequestChanges}
            data-test="Sent to Charterer"
          >
            Sent to Charterer
          </button>
          <button className="action-button" data-test="Cancel button" onClick={props.onCancel}>
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};
