import { DataModel } from "../../DataModel";

class SafeOption extends DataModel {
  constructor(data: Data) {
    super(data);
  }

  toString() {
    return this.label;
  }
}

function createSafeOption(option: Data) {
  return new SafeOption(option);
}

const DATA_ARR = [
  {
    label: "1",
    min: 1,
    max: 1,
  },
  {
    label: "1-2",
    min: 1,
    max: 2,
  },
  {
    label: "1-3",
    min: 1,
    max: 3,
  },
  {
    label: "1-4",
    min: 1,
    max: 4,
  },
  {
    label: "1-5",
    min: 1,
    max: 5,
  },
  {
    label: "2",
    min: 2,
    max: 2,
  },
  {
    label: "2-3",
    min: 2,
    max: 3,
  },
  {
    label: "2-4",
    min: 2,
    max: 4,
  },
  {
    label: "2-5",
    min: 2,
    max: 5,
  },
  {
    label: "3",
    min: 3,
    max: 3,
  },
  {
    label: "3-4",
    min: 3,
    max: 4,
  },
  {
    label: "3-5",
    min: 3,
    max: 5,
  },
  {
    label: "4",
    min: 4,
    max: 4,
  },
  {
    label: "4-5",
    min: 4,
    max: 5,
  },
  {
    label: "5",
    min: 5,
    max: 5,
  },
];

const SAFE_OPTION_ARR = DATA_ARR.map(createSafeOption);

const SAFE_OPTION_LABEL_BY_MIN_MAX_MAP = DATA_ARR.reduce((acc, el) => {
  acc[`${el.min}${el.max}`] = el.label;

  return acc;
}, {}) as StringRecord;

export { SafeOption, SAFE_OPTION_LABEL_BY_MIN_MAX_MAP, SAFE_OPTION_ARR };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface SafeOption extends Data {}

interface Data {
  label: string;
  min: number;
  max: number;
}
