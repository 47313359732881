import { TextareaString, TextareaStringProps } from "../TextareaString";
import { LABELS } from "@/constants";

export class OrderNotes extends TextareaString {}
class OrderNotesProps extends TextareaStringProps {}
OrderNotesProps.prototype.label = LABELS["notes"];
OrderNotes.prototype._ = new OrderNotesProps();

export class VoyageNotes extends TextareaString {}
class VoyageNotesProps extends TextareaStringProps {}
VoyageNotesProps.prototype.label = LABELS["voyageNotes"];
VoyageNotes.prototype._ = new VoyageNotesProps();

export class TCNotes extends TextareaString {}
class TCNotesProps extends TextareaStringProps {}
TCNotesProps.prototype.label = LABELS["tctNotes"];
TCNotes.prototype._ = new TCNotesProps();

export class COANotes extends TextareaString {}
class COANotesProps extends TextareaStringProps {}
COANotesProps.prototype.label = LABELS["coaNotes"];
COANotes.prototype._ = new COANotesProps();

export class AuditNotes extends TextareaString {}
class AuditNotesProps extends TextareaStringProps {}
AuditNotesProps.prototype.label = LABELS["auditNotes"];
AuditNotes.prototype._ = new AuditNotesProps();

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */
