import { makeAutoObservable, observable } from "mobx";
import { uid } from "___REFACTOR___/utils";
import { Spinners as Component } from "___REFACTOR___/components/services/Spinners";

class Spinners {
  constructor() {
    this.registry = {};

    makeAutoObservable(this, { registry: observable.ref });
  }

  register(props: Spinners.Props) {
    const id = uid();
    const spinner = new Spinner({ parent: this, id, props });

    this.registry = {
      ...this.registry,
      [id]: spinner,
    };

    return this.unregister.bind(this, spinner);
  }

  unregister(spinner: Spinners.Spinner) {
    const { [spinner.id]: unregistered, ...rest } = this.registry;

    this.registry = rest;
  }
}

class Spinner {
  constructor(constructorProps: Spinner.ConstructorProps) {
    Object.assign(this, constructorProps);
  }

  unregister() {
    this.parent.unregister(this);
  }
}

const spinners = new Spinners();

export { spinners, Spinners };

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

interface Spinners {
  registry: Spinners.Registry;
}

declare namespace Spinners {
  export type Registry = { [id: string]: Spinner };
  export type Props = Component.Props;
  export { Spinner };
}

interface Spinner {
  id: string;
  props: Spinners.Props;
  parent: Spinners;
}

declare namespace Spinner {
  interface ConstructorProps {
    id: string;
    props: Spinners.Props;
    parent: Spinners;
  }
}
