import React from "react";
import modalStyles from "__legacy/sharedFolder/styles/modal-styles.module.scss";
import { Modal } from "office-ui-fabric-react";
import Button from "__legacy/sharedFolder/components/common/Button/Button";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { emptyArrowFn } from "../../../../@/utils";

interface IConfirmationDialogProps {
  dataTestId: string;
  title: string;
  message: string | JSX.Element;
  ariaLabel: string;
  isDisabled?: boolean;
  isCancelDisabled?: boolean;
  okButtonText?: string;
  cancelButtonText?: string;
  displayLoading?: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

export const ConfirmationDialog = ({
  title,
  dataTestId,
  message,
  ariaLabel,
  isDisabled = false,
  isCancelDisabled = false,
  displayLoading = false,
  okButtonText = "OK",
  cancelButtonText = "CANCEL",
  onSubmit,
  onCancel,
}: IConfirmationDialogProps) => {
  const handleCloseDialog = emptyArrowFn;

  return (
    <>
      <Modal isOpen={true} onDismiss={handleCloseDialog}>
        <div className={modalStyles.modalContainer}>
          <label className={modalStyles.title}>{title}</label>
          <p>{message}</p>

          <div className={modalStyles.modalOptions}>
            <Button type="action" ariaLabel={ariaLabel} dataTest={dataTestId} disabled={isDisabled} onClick={onSubmit}>
              {okButtonText}
            </Button>
            <Button type="flat" onClick={onCancel} disabled={isCancelDisabled} dataTest={`${dataTestId}-cancel-button`}>
              {cancelButtonText}
            </Button>
            {displayLoading && <LoadingSpinner />}
          </div>
        </div>
      </Modal>
    </>
  );
};
