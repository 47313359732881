import { object, string, number, TestContext } from "yup";
import { TradeAPI } from "___REFACTOR___/apis";
import { COACargoSize } from "../COACargoSize";

const options = ["MIN/MAX", "MOLCHOPT", "MOLOO"] as TradeAPI.COACargoSize.Option[];

// https://github.com/jquense/yup
const schema = object({
  min: number().integer().required("Required"),
  max: number().integer().required("Required"),
  variance: number().integer().nullable(),
  option: string().oneOf(options),
  notes: string().nullable(),
})
  .test({
    test: varianceTest,
    name: "varianceTest",
    message: "Something is wrong with Variance",
  })
  .test({
    test: minMaxTest,
    name: "varianceTest",
    message: "Something is wrong with Min / Max",
  })
  .transform(toJSON);

function toJSON(coaCargoSize: COACargoSize) {
  return coaCargoSize?.toJSON();
}

function minMaxTest(this: TestContext, coaCargoSize) {
  if (!coaCargoSize) return true;

  const { min, max } = coaCargoSize as TradeAPI.COACargoSize;

  const error = this.createError();

  if (typeof min === "number" && typeof max === "number" && min > max) {
    error.message = "Min should be less than or equal to Max";
    error.path = `${error.path}.min`;

    return error;
  }

  return true;
}

function varianceTest(this: TestContext, coaCargoSize) {
  if (!coaCargoSize) return true;

  const { option, variance } = coaCargoSize as TradeAPI.COACargoSize;

  const error = this.createError();

  if (option === "MIN/MAX" && variance) {
    error.message = "Variance should be 0 with MIN/MAX option";
    error.path = `${error.path}.variance`;

    return error;
  }

  if (option === "MOLCHOPT" && !variance) {
    error.message = "Variance should be above 0 with MOLCHOPT option";
    error.path = `${error.path}.variance`;

    return error;
  }

  if (option === "MOLOO" && !variance) {
    error.message = "Variance should be above 0 with MOLOO option";
    error.path = `${error.path}.variance`;

    return error;
  }

  return true;
}

export { schema };
