import { cloneDeep } from "lodash-es";

export function cleanValue<T extends any>(value: T, config: CleanValueConfig = {}) {
  const { clone = true } = config;

  if (clone) value = cloneDeep(value);

  if (value === null) return;
  if (value === undefined) return;
  if (Number.isNaN(value)) return;
  if (typeof value === "function") return;
  if (typeof value === "string") return value;
  if (typeof value === "number") return value;
  if (typeof value === "boolean") return value;

  const entries = Object.entries(value as object);

  let allChildrenEmtpty = true;

  for (let i = 0; i < entries.length; i++) {
    let [childKey, childValue] = entries[i];

    childValue = cleanValue(childValue, { ...config, clone: false });

    if (childValue === undefined) {
      delete value[childKey];
    }

    if (childValue !== undefined) allChildrenEmtpty = false;
  }

  if (allChildrenEmtpty) return;

  return value;
}

interface CleanValueConfig {
  clone?: boolean;
}
