import * as React from "react";
import { getCargoTypes } from "negotiations/services/cargoTypes";
import { LookUpSingleEntry } from "../../editors/LookUpSingleEntry/LookUpSingleEntry";
import { ICargoTypeView } from "sharedFolder/Models/IDetails";
import { ICargoTypeSearchResult } from "sharedFolder/components/common/interfaces";

interface ICargoTypePickerProps {
  id: string;
  selectedItem?: ICargoTypeSearchResult;
  onChange: (value?: ICargoTypeSearchResult) => void;
  disabled?: boolean;
  focus?: boolean;
  isValid?: boolean;
}

export const toCargoTypeSearchResult = (cargoTypeView: ICargoTypeView) => {
  if (cargoTypeView.arcId && cargoTypeView.name) return { key: Number(cargoTypeView.arcId), name: cargoTypeView.name };
  return undefined;
};

export class CargoTypePicker extends React.Component<ICargoTypePickerProps> {
  constructor(props: ICargoTypePickerProps) {
    super(props);
    this.onItemSelected = this.onItemSelected.bind(this);
  }

  public render() {
    return (
      <LookUpSingleEntry
        id={this.props.id}
        isValid={this.props.isValid}
        defaultValue={this.props.selectedItem && this.props.selectedItem}
        autoCompleteFunction={this.getDataItems}
        onChange={this.onItemSelected}
        getDisplayText={this.getDisplayText}
        disabled={this.props.disabled}
        focus={this.props.focus}
      />
    );
  }

  private async getDataItems(filter: string): Promise<any[]> {
    return await getCargoTypes(filter);
  }

  private onItemSelected(selected: any, isValid?: boolean) {
    this.props.onChange(selected);
  }

  private getDisplayText(item: ICargoTypeSearchResult): string {
    return item.name;
  }
}
