import React from "react";
import { ILoadingSpinnerProps } from "sharedFolder/components/common/interfaces";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

import styles from "./BlackLoadingPage.module.scss";

interface IBlankLoadingPageProps {
  loadingSpinnerProps?: ILoadingSpinnerProps;
}

const BlankLoadingPage = (props: IBlankLoadingPageProps) => {
  const { loadingSpinnerProps } = props;
  return (
    <div className={styles.loadingPageContainer} data-test="Blank Page Loading">
      <LoadingSpinner {...loadingSpinnerProps} />
    </div>
  );
};

export default BlankLoadingPage;
