import React, { ReactNode } from "react";
import { DateField, DateOffsetField, Field } from "___REFACTOR___/components/common/Field";
import { Group } from "../../Group";
import "./Timelimit.scss";

function TimelimitEditor(props: TimelimitEditor.Props) {
  const { className, variant = "Date", onChange, getLayout, dataTest, hidden, ...rest } = props;
  const InputComp = INPUT_VARIANT[variant];
  // @ts-ignore
  const input = <InputComp {...rest} onChange={onTimeChange} time={true} key="input" dataTest={`${dataTest}.value`} />;
  const layout = getLayout?.(input)?.map(LayoutElementHOC);

  if (!variant) return null;

  return (
    <Group {...props} editorType="Timelimit" hidden={hidden}>
      <div className="Timelimit-layout">{layout}</div>
    </Group>
  );

  function onTimeChange(date: TimelimitEditor.Value) {
    props.onChange?.(date);
  }
}

function LayoutElementHOC(element: ReactNode, i: number) {
  if (typeof element === "string") element = <span key={i}>{element}</span>;

  return element;
}

const INPUT_VARIANT = {
  Date: DateField,
  DateOffset: DateOffsetField,
};

export { TimelimitEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace TimelimitEditor {
  export interface Props extends Field.Props {
    value: Value;
    onChange: (value: Value) => void;
    variant?: "Date" | "DateOffset";
    getLayout?: (input: ReactNode) => ReactNode[];
  }

  export type Value = DateOffsetField.Input.Value | DateField.Input.Value;
}
