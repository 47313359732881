import { ICargoTypeView } from "../Models/IDetails";
import { ICargoType } from "../../api/negotiations/models";
import { IMapper, withNullable } from "./map";

const map: IMapper<ICargoType, ICargoTypeView> = {
  toApi: (viewModel: ICargoTypeView) => {
    return {
      arcId: viewModel.arcId || "",
      name: viewModel.name,
      notes: viewModel.notes,
    };
  },
  toView: (apiModel: ICargoType) => {
    return {
      arcId: apiModel.arcId,
      display: apiModel.display,
      shortDisplay: apiModel.shortDisplay,
      notes: apiModel.notes || "",
      name: apiModel.name,
    };
  },
  emptyViewModel: {
    arcId: null,
    name: "",
    notes: "",
  },
};

export const mapCargoType = withNullable(map);
