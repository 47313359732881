import { IVessel } from "../../api/models/IVessel";

export const OwnerDashboardApi = (ctradeUrl: string, updateToken: string) => {
  return {
    indicateVessel: async (orderId: string, negotiationId: string, vessels: IVessel[]) => {
      return fetch(`${ctradeUrl}/orders/${orderId}/negotiations/${negotiationId}/all`, {
        method: "PUT",
        headers: {
          "X-API-Version": "3",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          vessels,
          action: "indicated",
          updateToken,
        }),
      });
    },
    requestChanges: (orderId: string, negotiationId: string, requestChangesText: string) => {
      return fetch(`${ctradeUrl}/orders/${orderId}/negotiations/${negotiationId}/all`, {
        method: "PUT",
        headers: {
          "X-API-Version": "3",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          details: {
            liftingCargoDetailsRequestChanges: {
              value: requestChangesText,
            },
          },
          action: "indicated",
          updateToken,
        }),
      });
    },
  };
};
