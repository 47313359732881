import React, { useEffect, useReducer, useRef } from "react";
import classNames from "classnames";
import styles from "./NewCargoSizeEditor.module.scss";
import { ICargoSizeView } from "../../../Models/IDetails";
import { CustomDropdown } from "../../common/CustomDropdown/CustomDropdown";
import formStyles from "__legacy/sharedFolder/styles/form-styles.module.scss";
import { CargoSizeUnits } from "../../../Models/CargoSizeUnits";
import { cargoSizeText } from "../../../display/cargoSize";
import { isCargoSizeSizeValid, isCargoSizeVarianceValid } from "../../common/fields";
import { NumberInput } from "../../common/NumberInput/NumberInput";
import { withValidationContext } from "../../../contexts/withValidationContext";
import { Notes } from "../../common/Notes/Notes";

interface IProps {
  defaultOption?: string;
  id?: string;
  value?: ICargoSizeView;
  onChange: (value: ICargoSizeView) => void;
  required?: boolean;
  label?: string;
  focus?: boolean;
  disabled?: boolean;
  isValid: boolean;
  withNotes?: boolean;
}

interface IState {
  isRequired: boolean;
  isSizeValid: boolean;
  isVarianceValid: boolean;
  size: string;
  option: string;
  variance: string;
  notes?: string;
}

interface IAction {
  type: "setSize" | "setOption" | "setVariance" | "setRequired" | "setNotes";
  payload: IPayload;
}

interface IPayload {
  size?: string;
  option?: string;
  variance?: string;
  isRequired?: boolean;
  notes?: string;
}

const getCargoSizeView = (state: IState): ICargoSizeView => {
  const cargoSize = {
    variance: state.variance,
    option: state.option,
    value: state.size === null ? "" : String(state.size.replace(/,/g, "")),
    notes: state.notes,
    unit: "MT",
  };
  return {
    ...cargoSize,
    display: cargoSizeText(cargoSize) || "",
  };
};

const reducer = (state: IState, action: IAction): IState => {
  const stateValidated = {
    ...state,
    isSizeValid: isCargoSizeSizeValid(
      action.payload.size !== undefined ? action.payload.size : state.size,
      action.payload.isRequired !== undefined ? action.payload.isRequired : state.isRequired
    ),
    isVarianceValid: isCargoSizeVarianceValid(
      action.payload.variance !== undefined ? action.payload.variance : state.variance,
      action.payload.option !== undefined ? action.payload.option : state.option,
      action.payload.isRequired !== undefined ? action.payload.isRequired : state.isRequired
    ),
  };

  switch (action.type) {
    case "setSize":
      return {
        ...stateValidated,
        size: action.payload.size !== undefined ? action.payload.size : "",
      };
    case "setOption":
      return {
        ...stateValidated,
        option: action.payload.option !== undefined ? action.payload.option : "",
      };
    case "setVariance":
      return {
        ...stateValidated,
        variance: action.payload.variance !== undefined ? action.payload.variance : "",
      };
    case "setNotes":
      return {
        ...stateValidated,
        notes: action.payload.notes !== undefined ? action.payload.notes : "",
      };
    case "setRequired":
      return {
        ...stateValidated,
        isRequired: action.payload.isRequired !== undefined ? action.payload.isRequired : false,
      };
    default:
      return state;
  }
};

function usePrevious(value: IState): IState | undefined {
  const ref = useRef<IState>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const NewCargoSizeEditor: React.FC<IProps> = ({
  id = "cargoSizeEditor",
  label,
  value,
  onChange,
  defaultOption,
  disabled,
  required,
  focus,
  withNotes,
}: IProps) => {
  const size = value?.value || "";
  const variance = value?.variance ? value.variance : "";
  const option = value?.option ? value.option : defaultOption || "";
  const initialState: IState = {
    size: size,
    isRequired: Boolean(required),
    variance: variance,
    option: option,
    isSizeValid: isCargoSizeSizeValid(size, Boolean(required)),
    isVarianceValid: isCargoSizeVarianceValid(variance, option, Boolean(required)),
  };

  if (withNotes) {
    initialState.notes = value?.notes || "";
  }

  const [state, dispatch] = useReducer(reducer, initialState);
  const prevState = usePrevious(state);

  useEffect(() => {
    if (Boolean(required) !== state.isRequired) {
      dispatch({
        type: "setRequired",
        payload: { isRequired: Boolean(required) },
      });
    }
  }, [state, required]);

  useEffect(() => {
    if (
      state &&
      prevState &&
      (state.size !== prevState.size ||
        state.variance !== prevState.variance ||
        state.option !== prevState.option ||
        (withNotes && state.notes !== prevState.notes) ||
        state.isRequired !== prevState.isRequired)
    ) {
      onChange(getCargoSizeView(state));
    }
  });

  return (
    <>
      <div className={styles.topRowFields}>
        <div className={styles.inputContainer}>
          <label className={classNames(styles.label, styles.secondaryLabel)} htmlFor={`${id}_value`}>
            {label}
          </label>
          <NumberInput
            id={`${id}_value`}
            className={classNames(formStyles.input, styles.input, !state.isSizeValid ? styles.errorState : null)}
            onChange={(value) => {
              dispatch({
                type: "setSize",
                payload: { size: value?.toLocaleString() || "" },
              });
            }}
            defaultValue={state?.size.length > 0 ? Number(state.size) : undefined}
            formatOnBlur={(value) => {
              if (value === undefined) {
                return "";
              }
              return Math.round(value).toLocaleString();
            }}
            correctOnBlur={(value) => Math.round(value < 1000 ? value * 1000 : value)}
            required={state.isRequired}
            disabled={disabled}
            autoFocus={focus}
            width="small"
          />
        </div>

        <div className={styles.inputContainer}>
          <label className={classNames(styles.label, styles.secondaryLabel)} htmlFor="">
            Option
          </label>
          <CustomDropdown
            id={`${id}_option`}
            ariaLabel={"Option"}
            isValid={true}
            options={CargoSizeUnits.map((_) => ({
              key: _.key,
              text: _.value,
            }))}
            defaultSelectedKey={state.option || defaultOption}
            onChange={(optionValue) => {
              dispatch({
                type: "setOption",
                payload: { option: optionValue.toString() },
              });
            }}
            disabled={disabled}
            width="small"
          />
        </div>
        <div className={styles.inputContainer}>
          <label className={classNames(styles.label, styles.secondaryLabel)} htmlFor="">
            Variance (%)
          </label>
          <NumberInput
            id={`${id}_variance`}
            className={classNames(formStyles.input, styles.input, !state.isVarianceValid ? styles.errorState : null)}
            onChange={(value) =>
              dispatch({
                type: "setVariance",
                payload: { variance: value?.toLocaleString() || "" },
              })
            }
            correctOnBlur={(value) => Math.round(value)}
            defaultValue={state?.variance.length > 0 ? Number(state.variance) : undefined}
            required={state.isRequired}
            disabled={disabled}
            width="small"
          />
        </div>
      </div>
      {withNotes && (
        <div className={styles.inputContainer}>
          <Notes
            id={`cargoSizeNotes`}
            dataTest="cargoSizeNotes"
            label="Notes"
            className={styles.notes}
            value={state.notes}
            onChange={(value) => {
              dispatch({ type: "setNotes", payload: { notes: value } });
            }}
            disabled={disabled}
            // maxLength={200}
          />
        </div>
      )}
    </>
  );
};

export const NewCargoSizeEditorWithValidContext = withValidationContext<Omit<IProps, "isValid">>((props) => {
  const { defaultOption, fieldValidityChanged, id, required, value } = props;
  const isSizeValid = isCargoSizeSizeValid(props.value?.value || "", required);
  const isVariaceValid = isCargoSizeVarianceValid(
    value?.variance || "",
    value?.option ? value.option : defaultOption || "",
    Boolean(required)
  );
  const isValid = isSizeValid && isVariaceValid;
  useEffect(() => {
    fieldValidityChanged("cargoSize", isValid);
  }, [id, isValid, required, value, fieldValidityChanged]);

  return <NewCargoSizeEditor {...props} isValid={isValid} />;
});
