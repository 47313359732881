import React from "react";
import { observer } from "mobx-react";
import { getCreateOrderPayload } from "@/models";
import { createOrder } from "../../../actions";
import { NewOrderForm } from "../NewOrderForm";

function Creation() {
  return <NewOrderForm getPayload={getCreateOrderPayload} section="creation" onSubmit={createOrder} />;
}

const Observer = observer(Creation);

export { Observer as Creation };
