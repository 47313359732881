import React from "react";
import { Commission } from "___REFACTOR___/models";
import { NumberField, Field } from "___REFACTOR___/components";

function CommissionEditor(props: CommissionEditor.Props) {
  const { dataTest } = props;

  return (
    <NumberField
      {...props}
      editorType="Commission"
      value={props.value?.value}
      onChange={onChange}
      min={0}
      max={100}
      placeholder="e.g. 3.75"
      dataTest={`${dataTest}.value`}
    />
  );

  function onChange(value: NumberField.Input.Value) {
    if (typeof value !== "number" && typeof value !== "undefined") return;

    const next = new Commission({ value });

    props.onChange?.(next);
  }
}

export { CommissionEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace CommissionEditor {
  export interface Props extends Field.Props {
    value: Value;
    onChange: (value: Value) => void;
  }

  export type Value = Commission | undefined;
}
