import { OrderNegStatus } from "sharedFolder/Models/OrderNegStatus";
import { hasDateExpired } from "../components/common/DigitalTimer/useTimer";
import { LastUpdatedAction } from "../Models/LastUpdatedAction";
import { IconTypes } from "sharedFolder/components/common/types";

// This is used to turn neg and order status e.g. "Active", "OnSubs" to "sub statuses" e.g. "FirmBid" and "FirmOffer". (this whole mapper should ideally come from the BE at some point)

export const OrderNegStatusMapper = (
  status: OrderNegStatus,
  lastUpdatedAction: LastUpdatedAction,
  firmBidExpiresOn: string | null,
  firmOfferExpiresOn: string | null,
  onSubsExpiresOn: string | null
): [string, IconTypes, string?] => {
  const mapper: { [key in OrderNegStatus]: [string, IconTypes, string?] } = {
    Withdrawn: ["Withdrawn", "withdrawn"],
    Inactive: ["Inactive", null],
    Active:
      // Firm bid and offer come in when the status is Active. You could say they are sub statuses of Active
      lastUpdatedAction === "FirmBid" && !hasDateExpired(firmBidExpiresOn)
        ? ["FirmBid", "schedule", firmBidExpiresOn || ""]
        : lastUpdatedAction === "FirmOffer" && !hasDateExpired(firmOfferExpiresOn)
        ? ["FirmOffer", "schedule", firmOfferExpiresOn || ""]
        : ["Active", "in-negotiation"],
    Failed: ["Failed", null],
    Firm: ["Firm", "done"],
    TermsLoading: ["TermsLoading", null],
    MainTerms: ["MainTerms", "on-subs"],
    OnSubs: ["OnSubs", "schedule", onSubsExpiresOn || ""],
    SubsLifted: ["SubsLifted", "done-all"],
    SubsFailed: ["SubsFailed", "failed"],
    Fixed: ["Fixed", "thumbs-up"],
    Archived: ["", null],
    Nomination: ["Nomination", "in-negotiation"],
    Confirmed: ["Confirmed", "thumbs-up"],
  };
  return mapper[status];
};
