import React from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useSimpleEffect } from "___REFACTOR___/utils";
import { Button } from "___REFACTOR___/components";
import * as Services from "___REFACTOR___/services";
import "./SeaChatWidget.scss";

// todo: export as a base component for usage in responsive pages
function SeaChatWidgetComponent() {
  const props = { ...Services.seaChatWidget.props, ...Services.seaChatWidget.routeProps };
  const { open } = Services.seaChatWidget;
  const { position } = props;
  const className = classNames("app-chat-widget", position);
  const containerClassName = classNames("app-chat-widget-container", position);

  useSimpleEffect(onPositionChange, [position]);
  useSimpleEffect(onVisibilityChange, [open]);

  async function show() {
    Services.seaChatWidget.show();

    Services.seaChatWidget.instance.container.className = containerClassName;
  }

  async function hide() {
    Services.seaChatWidget.hide();
  }

  async function onVisibilityChange() {
    if (!Services.seaChatWidget.instance?.container) return;

    Services.seaChatWidget.instance.container.className = containerClassName;
  }

  async function onPositionChange() {
    if (!Services.seaChatWidget.instance?.container) return;

    Services.seaChatWidget.instance.container.className = containerClassName;
  }

  return Services.seaChatWidget.initialized ? (
    <>
      <Button onClick={show} className={className} icon="chat" data-key="SeaChatWidget" />
      <div className="app-chat-widget-backdrop" onClick={hide} hidden={!open} />
    </>
  ) : null;
}

function SeaChatWidget() {
  return observer(SeaChatWidgetComponent);
}

SeaChatWidget.DEFAULT_WIDGET_PROPS = {
  position: "bottomLeft",
} as SeaChatWidget.Props;

export { SeaChatWidget };

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

declare namespace SeaChatWidget {
  interface Props {
    position?: "topLeft" | "topRight" | "bottomRight" | "bottomLeft";
  }
}
