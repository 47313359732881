// BLACKBOX
import { UserProvider } from "__legacy/dashboard/contexts/UserProvider";
import { MinimalConfigurationContext } from "__legacy/sharedFolder/MinimalConfigurationContext";
import { OrderTypeProvider } from "__legacy/sharedFolder/contexts/order-type-context";
import { DistributionAppProvider } from "orders/Distribution/DistributionContext";
import { FetchInterceptor } from "__legacy/dashboard/components/FetchInterceptor/FetchInterceptorOld";
import { Circulate as LegacyCirculate } from "orders/Distribution/Circulate";
import { useOrder } from "__legacy/orderNegs/useOrder";

import { useContext } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { config } from "@/config";
import { Context } from "../../../Orders";
import "./Circulate.scss";

function Circulate() {
  const { orderId, noOfUsers } = useParams<StringRecord>();
  const context = useContext(Context);
  const order = orderId ? context.orderNegotiationStore?.upsertOrderModel({ id: orderId }) : undefined;
  const version = order?._.version;
  const { orderModel } = useOrder(config.ctradeUrl, orderId, version);

  if (!order) return null;

  return (
    <div className="Circulate LEGACY_CONTAINER">
      <FetchInterceptor baseUrl={config.ctradeUrl} exceptions={["/token$"]}>
        <MinimalConfigurationContext.Provider value={config}>
          <UserProvider config={config}>
            <OrderTypeProvider orderType={context.legacyOrderType || "COA"}>
              <DistributionAppProvider>
                <LegacyCirculate order={orderModel} ctradeUrl={config.ctradeUrl} noOfUsers={Number(noOfUsers || 0)} />
              </DistributionAppProvider>
            </OrderTypeProvider>
          </UserProvider>
        </MinimalConfigurationContext.Provider>
      </FetchInterceptor>
    </div>
  );
}

const Observer = observer(Circulate);

export { Observer as Circulate };
