import React from "react";
import { VesselSelector, IVesselSelectedView } from "../OwnerIndicationForm/Form/VesselSelector/VesselSelector";
import { IOfferRepViewNegotiation } from "negotiations/models/common/IOfferRep";
import { VesselNominateProvider } from "sharedFolder/contexts/vesselnominate-context";

interface IVesselWrapperProps {
  negotiation: IOfferRepViewNegotiation;
  onAddNewVessel: (vessel: IVesselSelectedView) => void;
  onRemoveVessel: (vessel: IVesselSelectedView) => void;
  onEditVessel: (vessel: IVesselSelectedView) => void;
  selectedVessels: IVesselSelectedView[];
}

export const VesselsWrapper: React.FC<IVesselWrapperProps> = (props: IVesselWrapperProps) => {
  const { onRemoveVessel, onAddNewVessel, onEditVessel, selectedVessels } = props;

  return (
    <div>
      <VesselNominateProvider showAdditionalNotes={true} postFixUrl="">
        <VesselSelector
          negotiation={props.negotiation}
          vessels={selectedVessels}
          onAddNewVessel={onAddNewVessel}
          onRemoveVessel={onRemoveVessel}
          onEditVessel={onEditVessel}
          disabled={selectedVessels.some((v) => v.status === "accepted")}
        />
      </VesselNominateProvider>
    </div>
  );
};
