import { object, string, number } from "yup";
import { Nominations } from "../Nominations";

// https://github.com/jquense/yup
const schema = object({
  noticePerLaycan: number().integer().required("Required"),
  laycanSpread: number().integer().required("Required"),
  finalLaycanNotice: number().integer().required("Required"),
  notes: string(),
}).transform(toJSON);

function toJSON(nominations: Nominations) {
  return nominations?.toJSON();
}

export { schema };
