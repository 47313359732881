import { UniversalOrderNegotiationFormValues, getBidDetails, Negotiation, dialog } from "@/models";

export async function postNegotiationBid(model: Negotiation | undefined, values: UniversalOrderNegotiationFormValues) {
  if (!model) {
    console.error("postNegotiationBid:", { model, values });

    const status = {} as Status;

    status.type = "error";
    status.title = "Negotiation Bid Failure";

    dialog.show({
      status,
      dataTest: `post-negotiation-bid-fail`,
    });

    return;
  }

  const indicationType = values.indicationType || "indicated";

  model._.status.loading = true;
  model._.status.message = loadingMessageByType[indicationType];

  const payload = {
    details: indicationType === "indicated" || indicationType === "firmed" ? getBidDetails(values) : null,
    action: indicationType,
    expiresOn:
      indicationType === "firmed"
        ? values.timelimitVariant === "DateOffset"
          ? values.timelimitDateOffset?.toString()
          : values.timelimitDate?.toString()
        : null,
    updateToken: model.updateToken,
  };

  const res = await model.postBid(payload);

  if (!res?.ok) {
    model._.status.type = "error";
    model._.status.title = "Negotiation Bid Failure";
    model._.status.dump = { res, entity: model };

    dialog.show({
      status: model._.status,
      dataTest: `post-negotiation-bid-fail`,
    });

    model._.status.loading = false;
    model._.status.message = null;

    return res;
  }

  model._.status.loading = false;
  model._.status.message = null;

  // dialog.show({
  //   status: {
  //     type: "success",
  //     title: successMessageTitleByType[indicationType],
  //     message: successMessageByType[indicationType],
  //   },
  //   dataTest: `post-negotiation-bid-success`,
  // });

  return res;
}

const loadingMessageByType = {
  indicated: "Updating Bid",
  firmRequested: "Sending Firm Request",
  firmed: "Sending Firm Offer",
  firmAccepted: "Accepting Firm Offer",
} as StringRecord;

// const successMessageByType = {
//   indicated: "You have successfully updated the negotiation bid",
//   firmRequested: "You have successfully requested for a firm offer from the Owner",
//   firmed: "You have successfully sent the Owner a firm bid",
//   firmAccepted: "You have successfully accepted a firm offer",
// } as StringRecord;

// const successMessageTitleByType = {
//   indicated: "Successful Indication",
//   firmRequested: "Successful Firm Request",
//   firmed: "Successful Firm Bid",
//   firmAccepted: "Firm Offer Accepted",
// } as StringRecord;
