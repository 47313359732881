import * as React from "react";
import { Notes } from "../../common/Notes/Notes";
import { CargoTypePicker, toCargoTypeSearchResult } from "../../common/CargoTypePicker/CargoTypePicker";
import { IValidationContext } from "sharedFolder/contexts/ValidationContext";
import { withValidationContext } from "sharedFolder/contexts/withValidationContext";
import { ICargoTypeView, isCargoTypeViewValid } from "sharedFolder/Models/IDetails";
import { mapCargoType } from "sharedFolder/mappers/mapCargoType";
import { ICargoTypeSearchResult } from "sharedFolder/components/common/interfaces";
import styles from "./CargoTypeEditor.module.scss";

interface ICargoTypeEditorProps {
  disabled?: boolean;
  selectedItem?: ICargoTypeView;
  onChange: (value?: ICargoTypeView) => void;
  focus?: boolean;
  id: string;
  required?: boolean;
  label?: string;
}

interface ICargoEditorState {
  valid: boolean;
}

class CargoTypeEditorComponent extends React.Component<ICargoTypeEditorProps & IValidationContext, ICargoEditorState> {
  constructor(props: ICargoTypeEditorProps & IValidationContext) {
    super(props);
    this.onItemSelected = this.onItemSelected.bind(this);
    this.handleNotesChange = this.handleNotesChange.bind(this);

    const valid = this.isValid();
    this.state = {
      valid: valid,
    };
    this.props.fieldValidityChanged(this.props.id, valid);
  }

  public componentDidUpdate(prevProps: ICargoTypeEditorProps & IValidationContext) {
    if (prevProps.required !== this.props.required) {
      const isValid = this.isValid();
      this.setState({
        valid: isValid,
      });
      this.props.fieldValidityChanged(this.props.id, isValid);
    }
  }

  private isValid(): boolean {
    return isCargoTypeViewValid(this.props.selectedItem);
  }

  private handleNotesChange(value: string) {
    this.props.onChange({
      ...(this.props.selectedItem || mapCargoType.emptyViewModel),
      notes: value,
    });
  }

  private onItemSelected(selected?: ICargoTypeSearchResult) {
    if (selected) {
      this.props.onChange({
        ...(this.props.selectedItem || { notes: "" }),
        arcId: String(selected.key),
        name: selected.name,
        display: selected.name,
      });
    } else {
      this.props.onChange();
    }

    const isValid = this.props.required ? selected !== undefined : true;
    this.props.fieldValidityChanged(this.props.id, isValid);

    this.setState({
      valid: isValid,
    });
  }

  render() {
    return (
      <>
        {this.props.label && <label htmlFor="">{this.props.label}</label>}
        <>
          <CargoTypePicker
            id={"CargoTypePicker"}
            isValid={this.state.valid}
            selectedItem={this.props.selectedItem && toCargoTypeSearchResult(this.props.selectedItem)}
            onChange={this.onItemSelected}
            disabled={this.props.disabled}
            focus={this.props.focus}
          />
          <Notes
            className={styles.notes}
            dataTest="Cargo Type notes textarea"
            onChange={this.handleNotesChange}
            value={this.props.selectedItem && this.props.selectedItem.notes}
            ariaLabel="Cargo Type Notes text area"
            disabled={this.props.disabled}
          />
        </>
      </>
    );
  }
}

export const CargoTypeEditor = withValidationContext(CargoTypeEditorComponent);
