import classNames from "classnames";
import { Tooltip as MuiTooltip, makeStyles, TooltipProps } from "@material-ui/core";

const useStyles = (interactive?: boolean, arrow?: boolean) =>
  makeStyles((theme) => ({
    root: {
      background: arrow ? undefined : theme.palette.background.paper,
      width: "auto",
      maxWidth: interactive ? 400 : 225,
      maxHeight: "40vh",
      fontWeight: "normal",
      fontSize: "inherit",
      color: "#ccc",
      border: arrow ? undefined : "1px solid #ccc",
      position: "relative",
      padding: "0px 10px",
      overflowY: interactive ? "auto" : "visible",
      whiteSpace: "pre-wrap",

      "& .tooltip-customArrow": {
        position: "absolute",
        zIndex: 1,
        background: theme.palette.background.paper,
        borderTop: "1px solid #ccc",
        borderLeft: "1px solid #ccc",
        width: 10,
        height: 10,
      },
      "& .tooltip-customArrow--top": {
        top: -6,
        right: `calc(50% - 10px)`,
        transform: "rotate(45deg)",
      },
      "& .tooltip-customArrow--right": {
        right: -6,
        top: `calc(50% - 10px)`,
        transform: "rotate(135deg)",
      },
    },
  }));

const Tooltip = ({ className, interactive, arrow, ...rest }: TooltipProps) => {
  const classes = useStyles(interactive, arrow)();

  return (
    <MuiTooltip
      classes={{
        tooltip: classes.root,
      }}
      className={classNames({
        [`${className}`]: !!className,
      })}
      interactive={interactive}
      arrow={arrow}
      {...rest}
    />
  );
};

export default Tooltip;
