import classNames from "classnames";
import { Status } from "___REFACTOR___/models";
import { Icon } from "../Icon";
import "./StatusIcon.scss";

function StatusIcon(props: StatusIcon.Props) {
  let { type, status, className, name, ...rest } = props;

  type = type || status?.type;
  name = name || STATUS_TO_ICON_MAP[type!];
  className = classNames(className, "status-icon", type);

  return <Icon {...rest} name={name} className={className} />;
}

const STATUS_TO_ICON_MAP = {
  error: "alert-note",
  "fatal-error": "alert-note",
  withdrawn: "withdrawn",
  warning: "warning",
  info: "alert-note",
  confirm: "confirm",
  success: "done",
} as const;

export { StatusIcon };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace StatusIcon {
  export interface Props extends Icon.Props, Omit<Status, "name" | "title"> {
    status?: Status;
  }
}
