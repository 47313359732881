import * as React from "react";
import classNames from "classnames";
import uniqueId from "lodash-es/uniqueId";
import styles from "./Checkbox.module.scss";

interface ICheckboxProps extends React.HTMLProps<HTMLInputElement> {
  ariaLabel?: string;
  dataTest?: string;
  isValid?: boolean;
  label?: string;
  labelPos?: "left" | "right";
  scale?: "small" | "normal" | "large";
  reactRef?: React.Ref<HTMLInputElement>;
}

export default (props: ICheckboxProps) => {
  const { ariaLabel, dataTest, label, reactRef } = props;
  const labelPos = props.labelPos ? props.labelPos : "left";
  const id = props.id ? props.id : uniqueId("Checkbox-");
  const scale = props.scale ? props.scale : "normal";

  const checkboxProps = { ...props };
  delete checkboxProps.ariaLabel;
  delete checkboxProps.dataTest;
  delete checkboxProps.label;
  delete checkboxProps.labelPos;
  delete checkboxProps.scale;

  const Input = (
    <input
      {...checkboxProps}
      aria-label={ariaLabel}
      className={classNames(styles.checkbox, styles[scale], props.className, !props.isValid ? styles.error : null)}
      data-test={dataTest}
      ref={reactRef}
      type="checkbox"
      id={id}
    />
  );

  const Label = (
    <label className={classNames(styles.label, styles[`label-${labelPos}`])} htmlFor={id}>
      {label}
    </label>
  );

  return (
    <>
      {label ? (
        <div className={styles.wrapper}>
          {labelPos === "left" && Label}
          {Input}
          {labelPos === "right" && Label}
        </div>
      ) : (
        Input
      )}
    </>
  );
};
