import { memo } from "react";
import { get } from "lodash-es";
import { ElementHOC } from "../Element";
import "./RadioTimelimit.scss";

function RadioTimelimit(props) {
  const { element, context } = props;
  const { radio, timelimit } = element;

  const radioValue = get(context.values, radio.name);
  const checkedValue = radio.checkedValue;
  const isChecked = radioValue === checkedValue;

  const Timelimit = ElementHOC({
    ...timelimit,
    type: "Timelimit",
    tabIndex: isChecked ? 1 : -1,
  });
  const Radio = ElementHOC({ ...radio, className: "RadioTimelimit", reactNode: Timelimit, type: "Radio" });

  return Radio;
}

const Memo = memo(RadioTimelimit);

export { Memo as RadioTimelimit };
