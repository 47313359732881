import React from "react";
import styles from "./RolesSelector.module.scss";
import { DistributionUserRole, IDistributionViewModel } from "../../sharedFolder/Models/IDetails";
import { validRoles } from "./roles/haveUsersOrRolesChanged";
import { roles } from "./roles/availableRoles";

export const RolesSelector = (props: {
  role?: DistributionUserRole;
  showValidation: boolean;
  disabled?: boolean;
  user: IDistributionViewModel;
  onUpdateRole: (role: DistributionUserRole) => void;
}) => {
  const display = ["Unassigned", "Broker", "Charterer", "Owner"];
  const { email } = props.user;

  const isInvalid = props.showValidation && !validRoles.includes(props.role || "");

  const dataTest = `role-selector-${email.replace("@", "-")}`;
  return (
    <div className={styles.container}>
      <select
        onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
          props.onUpdateRole(event.target.value as DistributionUserRole);
        }}
        className={isInvalid ? styles.error : undefined}
        data-test={dataTest}
        value={props.role}
        disabled={props.disabled}
      >
        {roles.map((role, index) => {
          if (props.user.availableRoles.includes(role)) {
            return (
              <option key={role} value={role} data-test={role === props.role ? `${dataTest}-${props.role}` : undefined}>
                {display[index]}
              </option>
            );
          } else {
            return null;
          }
        })}
      </select>
    </div>
  );
};
