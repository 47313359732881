import React, { useState, useEffect } from "react";
import { getTheme, loadTheme } from "office-ui-fabric-react";
import { MinimalConfigurationContext } from "__legacy/sharedFolder/MinimalConfigurationContext";
import { firmOrIndicationType } from "./Form/Negotiable/IndicationOrFirmResponse";
import styles from "../Owner.module.scss";
import { TrackedApp } from "../../analytics/TrackedApp";
import { createBrowserHistory } from "history";
import { IConfiguration } from "sharedFolder/Configuration";
import { IOfferRepViewNegotiation } from "negotiations/models/common/IOfferRep";
import LoadingSpinner from "sharedFolder/components/common/LoadingSpinner/LoadingSpinner";
import { IOfferRepApi } from "./Contexts/IOfferRepFormContext";
import { OfferRepNegotiationContext } from "./Contexts/OfferRepNegotiationContext";

interface IOfferRepOrDashboardContainerProps {
  config: IConfiguration;
  negotiation: IOfferRepViewNegotiation | null;
  error: boolean;
  loading: boolean;
  offerRepNegotiationService: IOfferRepApi;
}

export const OfferRepOrDashboardContainer: React.FC<IOfferRepOrDashboardContainerProps> = ({
  config,
  negotiation,
  error,
  loading,
  offerRepNegotiationService,
  children,
}) => {
  const [firmOrIndicationState, setFirmOrIndicationState] = useState<firmOrIndicationType>("INDICATION_ONLY");

  useEffect(() => {
    const theme = getTheme();
    loadTheme({
      palette: {
        ...theme.palette,
        themePrimary: "var(--yellow)",
        neutralTertiary: "var(--yellow)",
      },
    });
  }, []);

  if (error) {
    return (
      <div className={styles.errorContainer}>
        An error has occured, please refresh the page.
        <br />
        If the problem persists please contact: <a href="mailto:support@sea.live">support@sea.live</a>
      </div>
    );
  }

  if (!loading && negotiation) {
    return (
      <div className={styles.offerRepCanvas}>
        <TrackedApp history={createBrowserHistory({ basename: "" })}>
          <OfferRepNegotiationContext.Provider
            value={{
              offerRepApi: offerRepNegotiationService,
              firmOrIndicationState,
              setFirmOrIndicationState,
            }}
          >
            <MinimalConfigurationContext.Provider value={config}>{children}</MinimalConfigurationContext.Provider>
          </OfferRepNegotiationContext.Provider>
        </TrackedApp>
      </div>
    );
  }

  return (
    <div className={styles.spinnerContainer}>
      <LoadingSpinner loadingText="Loading..." />
    </div>
  );
};
