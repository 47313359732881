import { storage } from "___REFACTOR___/utils";
import { auth } from "___REFACTOR___/services";

class UserStorage {
  remove = (key: string) => storage.local.get(`${auth.trade.user?.SystemUserId}/${key}`);

  get = (key: string, defaultValue?) => storage.local.get(`${auth.trade.user?.SystemUserId}/${key}`, defaultValue);

  set = (key: string, value) => storage.local.set(`${auth.trade.user?.SystemUserId}/${key}`, value);
}

export const userStorage = new UserStorage();
