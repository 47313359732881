import { object, string, number } from "yup";
import { TradeAPI } from "___REFACTOR___/apis";
import { FreightRate } from "../FreightRate";

const unitArr = ["PerMT", "LumpSum"] as TradeAPI.FreightRate.Unit[];

// https://github.com/jquense/yup
const schema = object({
  value: number().required("Required"),
  unit: string().oneOf(unitArr).required("Required"),
  notes: string(),
}).transform(toJSON);

function toJSON(freightRate: FreightRate) {
  return freightRate?.toJSON();
}

export { schema };
