import { keyBy } from "lodash-es";
// import blockedDomains from "./blocked-domains.json";

// let theList: string[] = [];

const getList = (): string[] => {
  // Patrick - We are removing the blocked domain functionality from PROD for a while, the business are going to
  // decide what to do for the next step of adding it back in
  return [];
  // if (theList.length) {
  //   return theList;
  // }
  // return (theList = blockedDomains.list.map(
  //   blockedDomain => blockedDomain.Domain
  // ));
};

export const blocked = (email: string): boolean => {
  const emailSplit = email.split("@");
  if (emailSplit.length !== 2) return false;
  return getList().includes(emailSplit[1]);
};

export const BLOCKED_DOMAIN = keyBy(
  // Leaving one item in this list as we don't expect an empty list elsewhere
  [{ Id: 0, Domain: "0-00.usa.cc" }],
  "Domain"
);
// export const BLOCKED_DOMAIN = keyBy(blockedDomains.list, "Domain");
