import { useRef, useState } from "react";

export function useShallowStateRef<T>(initialState: Partial<T>) {
  const state = useRef(initialState);
  const [, rerender] = useState(0);

  function setState(partialState: Partial<T>) {
    Object.assign(state.current, partialState);

    rerender(increment);
  }

  return [state.current, setState] as const;
}

function increment(counter) {
  return ++counter;
}
