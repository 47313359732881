import React from "react";
import { TradeAPI } from "___REFACTOR___/apis";
import { CLDDUOwner } from "___REFACTOR___/models";
import { Group } from "../../Group";
import { CLDDUEditor } from "../CLDDU";
import "./CLDDUOwner.scss";

function CLDDUOwnerEditor(props: CLDDUOwnerEditor.Props) {
  return (
    <Group {...props} hidden={props.hidden}>
      <CLDDUEditor
        {...props}
        editorType="CLDDUOwner"
        searchMessageMap={searchMessageMap}
        placeholder={placeholder}
        CLDDUSubset="usersandowners"
        CLDDUSelector={CLDDUSelector}
        Constructor={CLDDUOwner}
      />
      <div className="CLDDUOwner-info">
        Can&apos;t find the right owner? Just enter the owner&apos;s email and we will do the rest
      </div>
    </Group>
  );
}

function CLDDUSelector(clddu: TradeAPI.LegacyCLDDUser) {
  return clddu.companyType === "owner";
}

const placeholder = "Search for owners";

const searchMessageMap = {
  initial: "Waiting for your input",
  searching: "Searching for owners",
  noData: "No owners found",
} as CLDDUEditor.SearchMessageMap;

export { CLDDUOwnerEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace CLDDUOwnerEditor {
  interface Props extends Omit<CLDDUEditor.Props, "CLDDUSubset" | "Constructor"> {
    value: Value;
    onChange: (value: Value) => void;
  }

  type Value = CLDDUOwner | undefined;
}
