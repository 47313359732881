export function switchTypeForFieldName(label: string, fieldName: string) {
  const type = label === "Charterer's Indication" ? "charterer" : "owner";

  switch (type) {
    case "owner":
      return `${fieldName}0`;
    case "charterer":
      return fieldName.slice(0, fieldName.length - 1);
    default:
      return fieldName;
  }
}
