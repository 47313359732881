import { observer } from "mobx-react";
import classNames from "classnames";
import { ProformaLayout, proformaLayouts } from "@/models";
import { SelectField, SelectFieldProps } from "@/components";

function ProformaLayoutKeyEditor(props: Props) {
  props = { ...props };

  props.className = classNames(props.className, "ProformaLayoutKey");

  const { layoutsById } = proformaLayouts;

  const layoutId = props.layoutId;
  const data = layoutId ? layoutsById?.[layoutId]?.keys : undefined;

  return (
    <SelectField
      {...props}
      data={data}
      noDataStatus={{
        message: layoutId ? "No matching Proforma Key" : "Missing Proforma Layout",
      }}
      dataTest={`${props.dataTest}.value`}
    />
  );
}

const Observer = observer(ProformaLayoutKeyEditor);

export { Observer as ProformaLayoutKeyEditor };

interface Props extends Omit<SelectFieldProps, "value" | "onChange"> {
  value?: string;
  onChange?: (value: string) => void;
  layoutId?: ProformaLayout["layoutId"];
  observableProps?: Omit<Props, "observableProps">;
}
