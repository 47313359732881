import { useState, useEffect } from "react";
import { IVesselSelectedView } from "./VesselSelector";
import { IOfferRepViewNegotiation } from "negotiations/models/common/IOfferRep";

/**
 * This hook deals with holding the vessels to POST and allows the editing of existing vessels
 * as well as adding/removing of others
 */
export function useVesselSelector(negotiation: IOfferRepViewNegotiation) {
  const [selectedVessels, setSelectedVessels] = useState<IVesselSelectedView[]>([]);

  const vessels = (negotiation.details && negotiation.details.vessels) || [];

  useEffect(() => {
    setSelectedVessels(
      // these are also coming from the neg object and are not deletable
      vessels.map((x) => {
        return { ...x, isDeletable: false };
      })
    );
  }, [setSelectedVessels, vessels]);

  const onAddNewVessel = (vessel: IVesselSelectedView) => {
    setSelectedVessels([...selectedVessels, vessel]);
  };

  const onEditVessel = (updatedVessel: IVesselSelectedView) => {
    const currentVessels = JSON.parse(JSON.stringify(selectedVessels));
    const foundIndex = currentVessels.findIndex((v: IVesselSelectedView) => v.vesselIMO === updatedVessel.vesselIMO);
    currentVessels.splice(foundIndex, 1, updatedVessel);
    setSelectedVessels(currentVessels);
  };

  const onRemoveVessel = (removeVessel: IVesselSelectedView) => {
    const notDeletableVessels = selectedVessels.filter((v) => !v.isDeletable);
    // if the vessel attempting to be deleted is not one of the vessels added by the charterer, go ahead
    if (!notDeletableVessels.filter((v) => v.vesselIMO === removeVessel.vesselIMO).length) {
      const currentVessels = JSON.parse(JSON.stringify(selectedVessels));
      const foundIndex = currentVessels.findIndex((v: IVesselSelectedView) => v.vesselIMO === removeVessel.vesselIMO);
      currentVessels.splice(foundIndex, 1);
      setSelectedVessels(currentVessels);
    }
  };

  return {
    onRemoveVessel,
    onAddNewVessel,
    onEditVessel,
    selectedVessels,
    vessels,
  };
}
