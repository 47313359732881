import React, { memo, ReactNode } from "react";
import { NavLink as RRNavLink, NavLinkProps } from "react-router-dom";
import classNames from "classnames";
import { Icon, IconProps } from "@/components";
import "./NavLink.scss";

function NavLink(props: Props) {
  const { icon, children, desc, hidden, dataTest, ...rest } = props;
  const to = props.to;

  if (hidden) return null;

  if (typeof to !== "string") return null;

  rest.className = classNames(rest.className, "navlink");

  return (
    <RRNavLink {...rest} to={to} title={rest.title || ""} data-test={dataTest}>
      <Icon icon={icon} />
      {children && children}
    </RRNavLink>
  );
}

const Memo = memo(NavLink);

export { Memo as NavLink };

interface Props extends Omit<NavLinkProps, "title" | "to"> {
  desc?: string;
  to: string | undefined | null;
  icon?: IconProps["icon"];
  children?: ReactNode;
  title?: null | string;
  dataTest?: string;
}
