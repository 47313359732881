import React from "react";
import Field from "../Field/Field";
import { OrderNotesPartInput } from "orders/components/OrderNotesPartInput/OrderNotesPartInput";
import { OrderNotesPartValue } from "orders/components/OrderNotesPartValue/OrderNotesPartValue";

type notesType = "notes" | "coaNotes";

interface INotesFieldProps<T extends notesType> {
  isFieldValid: (key: T) => boolean;
  updateForm: (key: T, value: any) => void;
  selectedItem?: string;
  isExpanded: boolean;
  setExpandedItem: (name: T) => void;
  notesType: T;
  labelOverride?: string;
}

export const NotesField = <T extends notesType>(props: INotesFieldProps<T>) => {
  const coaNotesProps = {
    id: "coaNotes",
    label: "Coa Notes",
    name: "Coa Notes",
  };
  const notesProps = {
    id: "notes",
    label: "Notes",
    name: "Notes",
  };
  const notesTypeProps = props.notesType === "coaNotes" ? coaNotesProps : notesProps;

  return (
    <Field
      {...notesTypeProps}
      {...(props.labelOverride && { label: props.labelOverride })}
      edit={
        <OrderNotesPartInput
          id="Notes"
          value={props.selectedItem}
          onChange={(value: string) => props.updateForm(props.notesType, value)}
          focus={true}
        />
      }
      expanded={props.isExpanded}
      expand={() => {
        props.setExpandedItem(props.notesType);
      }}
      display={
        <>
          {props.selectedItem ? (
            <div>
              <OrderNotesPartValue value={props.selectedItem} />
            </div>
          ) : (
            "--"
          )}
        </>
      }
      isValid={props.isFieldValid(props.notesType)}
    />
  );
};
