import { IMessageState } from "sharedFolder/components/common/Messages/types";
import { LiftingsUIStatus } from "../../offerRep/Layouts/liftingUIStatusReducer";

type MessageStatus = {
  type: LiftingsUIStatus | "Close";
};

export function liftingsMessageReducer(state: IMessageState, action: MessageStatus): IMessageState {
  switch (action.type) {
    case "Rejected":
      return {
        ...state,
        colour: "red",
        icon: "warning",
        open: true,
        title: "Vessel Rejected",
        body: "",
      };
    case "Accepted":
      return {
        ...state,
        colour: "green",
        icon: "tick",
        open: true,
        title: "Vessel Accepted",
        body: "Lifting Confirmed",
      };
    case "Withdrawn":
      return {
        ...state,
        colour: "orange",
        icon: "lightbulb-outline",
        open: true,
        title: "This lifting has been withdrawn",
        body: "",
      };
    case "NominationSuccessful":
      return {
        ...state,
        colour: "blue",
        icon: "lightbulb-outline",
        open: true,
        title: "Nomination Sent",
        body: "",
      };
    case "ChangesRequestedSuccessful":
      return {
        ...state,
        colour: "blue",
        icon: "lightbulb-outline",
        open: true,
        title: "Changes Requested",
        body: "You can find details in the Notes tab",
      };

    case "Close":
      return { ...state, open: false };
    default:
      return { ...state, open: false };
  }
}
