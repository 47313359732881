import React, { useReducer } from "react";
import classNames from "classnames";
import { CustomDropdown } from "sharedFolder/components/common/CustomDropdown/CustomDropdown";
import { CargoSizeUnits } from "sharedFolder/Models/CargoSizeUnits";
import { NumberInput, valueFromString } from "../../common/NumberInput/NumberInput";
import { areMinAndMaxValid, isCargoSizeVarianceValid } from "../../common/fields";
import { ICOACargoSizeView } from "sharedFolder/Models/IDetails";
import { ICOACargoSizeEditorProps } from "sharedFolder/components/common/interfaces";
import Input from "sharedFolder/components/common/Input/Input";
import { Notes } from "sharedFolder/components/common/Notes/Notes";
import styles from "../COACargoSizeEditor/COACargoSizeEditor.module.scss";

interface IState {
  display: string;
  min?: string;
  max?: string;
  variance?: string;
  option?: string;
  notes?: string;
  unit: string;
}

type action = "setMin" | "setMax" | "setVariance" | "setOption" | "setNotes";

const reducer = (onChange: (value: ICOACargoSizeView) => void) => (state: IState, action: { type: action; value: string }) => {
  const triggerOnChange = (state: IState) => {
    onChange({
      display: state.display,
      min: state.min,
      max: state.max,
      variance: state.variance,
      option: state.option,
      unit: "MT",
      notes: state.notes,
    });
    return state;
  };

  switch (action.type) {
    case "setMin":
      return triggerOnChange({ ...state, min: action.value });
    case "setMax":
      return triggerOnChange({ ...state, max: action.value });
    case "setNotes":
      return triggerOnChange({ ...state, notes: action.value });
    case "setVariance":
      return triggerOnChange({ ...state, variance: action.value });
    case "setOption":
      return triggerOnChange({
        ...state,
        option: action.value,
      });
  }
  return state;
};

export const NewCOACargoSizeEditor: React.FC<ICOACargoSizeEditorProps> = ({
  disabled,
  focus,
  isValid,
  required,
  value,
  onChange,
}: ICOACargoSizeEditorProps) => {
  const [state, dispatch] = useReducer(reducer(onChange), {
    display: value?.display || "",
    min: value?.min,
    max: value?.max,
    variance: value?.variance,
    option: value?.option,
    notes: value?.notes,
    unit: "MT",
  });

  const isMinInvalid = (isValid !== undefined && isValid === false) || !areMinAndMaxValid(state);
  const isMaxInvalid = (isValid !== undefined && isValid === false) || !areMinAndMaxValid(state);

  return (
    <>
      <div className={styles.inputRow}>
        <div className={styles.inputGroup}>
          <div className={styles.container}>
            <div className={styles.part}>
              <div className={styles.label}>Min</div>
              <NumberInput
                id={`coaCargoSizeMin`}
                className={classNames(isMinInvalid ? styles.error : null)}
                onChange={(value) => {
                  dispatch({
                    type: "setMin",
                    value: value?.toLocaleString() || "",
                  });
                }}
                defaultValue={state.min && state.min.length > 0 ? valueFromString(state.min) : undefined}
                formatOnBlur={(value) => {
                  if (value === undefined) {
                    return "";
                  }
                  return Math.round(value).toLocaleString();
                }}
                correctOnBlur={(value) => Math.round(value < 1000 ? value * 1000 : value)}
                required={required}
                disabled={disabled}
                autoFocus={focus}
                width="smallMedium"
              />
            </div>
            <div className={styles.part}>
              <div className={styles.label}>Max</div>
              <NumberInput
                id={`coaCargoSizeMax`}
                className={classNames(isMaxInvalid ? styles.error : null)}
                onChange={(value) => {
                  dispatch({
                    type: "setMax",
                    value: value?.toLocaleString() || "",
                  });
                }}
                defaultValue={state.max && state.max.length > 0 ? valueFromString(state.max) : undefined}
                formatOnBlur={(value) => {
                  if (value === undefined) {
                    return "";
                  }
                  return Math.round(value).toLocaleString();
                }}
                correctOnBlur={(value) => Math.round(value < 1000 ? value * 1000 : value)}
                required={required}
                disabled={disabled}
                width="smallMedium"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.inputRow}>
        <div className={styles.inputGroup}>
          <div className={styles.container}>
            <div className={styles.part}>
              <div className={styles.label}>Variance (%)</div>
              <Input
                id={"coaCargoSizeVariance"}
                ariaLabel={"coaCargoSizeVariance"}
                dataTest={"coaCargoSizeVariance-input"}
                onChange={(evt) => {
                  dispatch({
                    type: "setVariance",
                    value: evt.currentTarget.value,
                  });
                }}
                value={state.variance === null ? undefined : state.variance}
                required={required}
                // focus={focus}
                isValid={isValid || isCargoSizeVarianceValid(state.variance || null, state.option || null)}
                disabled={disabled}
                width="smallMedium"
              />
            </div>
            <div className={styles.part}>
              <div className={styles.label}>&nbsp;</div>
              <CustomDropdown
                id={`coaCargoSizeoption`}
                ariaLabel={"Option"}
                isValid={true}
                options={CargoSizeUnits.map((_) => ({
                  key: _.key,
                  text: _.value,
                }))}
                defaultSelectedKey={state.option}
                onChange={(optionValue) => {
                  dispatch({
                    type: "setOption",
                    value: optionValue.toString(),
                  });
                }}
                disabled={disabled}
                width="smallMedium"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.inputRow}>
        <div className={styles.inputGroup}>
          <Notes
            id={`coaCargoSizenotes`}
            dataTest="coaCargoSizenotes"
            label="Notes"
            placeholder="Additional cargo size notes"
            className={styles.notes}
            value={state.notes}
            onChange={(value) => {
              dispatch({ type: "setNotes", value: value });
            }}
            disabled={disabled}
            maxLength={200}
          />
        </div>
      </div>
    </>
  );
};
