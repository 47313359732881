import { useState } from "react";
import styles from "./ViewGroups.module.scss";
import { IEmailList } from "negotiations/services/settings";
import { DistributionGroup } from "negotiations/components/distributionList/DistributionGroup";
import Button from "__legacy/sharedFolder/components/common/Button/Button";
import Icon from "__legacy/sharedFolder/components/common/Icon/Icon";
import { dialog } from "@/models/Dialog";
import { ViewMoreEmailGroups } from "./ViewMoreEmailGroups";
import { observer } from "mobx-react";

let Refactor: Await<ReturnType<typeof resolveRefactorExports>>;
const resolveRefactorExports = async () => ({
  ...(await import("___REFACTOR___/components/common/Button/Button")),
  ...(await import("___REFACTOR___/components/common/Icon/Icon")),
  ...(await import("___REFACTOR___/apis/Trade")),
});
resolveRefactorExports().then((exports) => (Refactor = exports));
interface IViewGroupsProps {
  onGroupSelection: (distributionEmails: string[]) => void;
  addAbsentOwner: (generatedEmail: string) => void;
}

export const ViewGroups = observer((props: IViewGroupsProps) => {
  const { onGroupSelection, addAbsentOwner } = props;
  const [currentlyExpandedGroup, setCurrentlyExpandedGroup] = useState<string | null>(null);
  const list = EmailListsStoreExports.emailLists.arr;

  return (
    <div className={styles.outerContainer}>
      <ViewMoreEmailGroups title="distribution lists">
        <div className={styles.innerContainer}>
          {list.map((item: IEmailList) => {
            const uniqueId = `${item.id}${item.name}`;
            return (
              <DistributionGroup
                key={uniqueId}
                mode="select"
                expandList={currentlyExpandedGroup === uniqueId}
                toggleList={
                  currentlyExpandedGroup === uniqueId
                    ? () => setCurrentlyExpandedGroup(null)
                    : () => setCurrentlyExpandedGroup(uniqueId)
                }
                onGroupSelection={onGroupSelection}
                currentList={list}
                item={item}
              />
            );
          })}
        </div>
      </ViewMoreEmailGroups>
      <Refactor.Button
        dataTest="add-absent-owner-button"
        icon="plus"
        onClick={onAddAbsentOwnerClick}
        className={styles.absentOwnerButton}
      >
        Add Absent Owner
        <Refactor.Icon
          className={styles.absentOwnerInfoIcon}
          icon="info-outline"
          tooltip="Act on behalf of an owner when negotiating terms"
        ></Refactor.Icon>
      </Refactor.Button>
      <Button type="flat" ariaLabel="Manage groups" onClick={EmailListManagerExports.EmailListManager.open}>
        Manage distribution lists
      </Button>
    </div>
  );

  async function onAddAbsentOwnerClick() {
    const res = await Refactor.tradeAPI.ownerAbsent.generateEmail.post();
    if (!res.ok) {
      const status = {
        type: "error",
        title: "Absent Owner Email Fetch Failure",
        dump: { res: res },
      } as Status;

      dialog.show({
        status,
        dataTest: "handle-absent-owner-email-fetch-fail",
      });
      return;
    }
    addAbsentOwner(res.data.email);
  }
});

const NoGroups = () => (
  <div className={styles.noGroupsContainer}>
    <Icon icon="alert-note" className={styles.alertIcon} />
    <div>
      <h3>No Groups</h3>
      <p>You have not yet created any groups</p>
      <p>Click on MANAGE DISTRIBUTION LISTS to create, remove and update your contact groups.</p>
    </div>
  </div>
);

const EmailListsStorePromise = import("___REFACTOR___/stores/EmailLists");

let EmailListsStoreExports: Await<typeof EmailListsStorePromise>;

EmailListsStorePromise.then((exports) => (EmailListsStoreExports = exports));

const EmailListManagerPromise = import("___REFACTOR___/components/DistList/EmailListManager");

let EmailListManagerExports: Await<typeof EmailListManagerPromise>;

EmailListManagerPromise.then((exports) => (EmailListManagerExports = exports));
