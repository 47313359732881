import { ILifting } from "../../api/negotiations/models/ILifting";
import { Observable } from "rxjs";
import { getLiftingsApiService } from "api/liftings/Liftings";

export function updateLiftingCargo(
  apiUrl: string,
  orderId: string,
  liftingId: string,
  liftingCargoId: string,
  details: ILifting
): Observable<{ id: string; version: number }> {
  return getLiftingsApiService(apiUrl, orderId).updateLiftingCargo(orderId, liftingId, liftingCargoId, details);
}
