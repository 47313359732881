import { TradeAPI } from "@/apis";
import { format } from "@/utils";
import { VesselSizeEditor } from "@/components";
import { LABELS } from "@/constants";
import { DataModel, DataModelProps } from "../DataModel";
import vesselSizes from "./vesselSizes.json";

export class VesselSize extends DataModel<Data> {
  onUpdate() {
    delete this.display;
    delete this.shortDisplay;
  }

  toString() {
    return this.vesselSizeAbbreviation;
  }

  toDropdownView() {
    if (!this.vesselSizeFull || !this.vesselSizeAbbreviation) return "";

    return `${this.vesselSizeFull} (${this.vesselSizeAbbreviation})`;
  }

  toGridView() {
    if (!this) return "";

    if (!this.sizeFrom || !this.sizeTo || !this.vesselSizeAbbreviation) return "";

    return `${format.number.display(this.sizeFrom)} / ${format.number.display(this.sizeTo)} / ${this.vesselSizeAbbreviation}`;
  }
}

export class VesselSizeProps extends DataModelProps<Data> {}

VesselSizeProps.prototype.editor = VesselSizeEditor;
VesselSizeProps.prototype.label = LABELS["vesselSize"];
VesselSizeProps.prototype.sortKey = "sizeTo";
VesselSizeProps.prototype.vesselSizeArray = vesselSizes.map(newVesselSize);

VesselSize.prototype._ = new VesselSizeProps();

function newVesselSize(vesselSize: Data) {
  return new VesselSize(vesselSize);
}

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

export interface VesselSize extends Data {
  _: VesselSizeProps;
}

export interface VesselSizeProps {
  vesselSizeArray: VesselSize[];
}

type Data = DeepPartial<TradeAPI["VesselSize"]>;
