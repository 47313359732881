import { useState } from "react";

export function useUndoHistory<T>(initial: T) {
  const [history, setHistory] = useState<T[]>([initial]);
  const [index, setIndex] = useState(0);
  const length = history.length - 1;
  const value = history[index];

  return {
    value,

    do(value: T) {
      setHistory((history) => {
        let next;

        if (index < length) {
          next = history.slice(0, index).concat(value);
        } else {
          next = history.concat(value);
        }

        return next;
      });

      setIndex((index) => min(index + 1));
    },
    undo() {
      setIndex((index) => max(0, index - 1));
    },
    redo() {
      setIndex((index) => min(index + 1, length));
    },
  };
}

const { min, max } = Math;
