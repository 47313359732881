const dark = {
  palette: {
    themePrimary: "var(--action)",
    themeLighterAlt: "var(--font-colour-primary)",
    themeLighter: "var(menu-highlight)",
    themeLight: "var(--menu-highlight)",
    themeTertiary: "#e8e8e8",
    themeSecondary: "#ededed",
    themeDarkAlt: "#0078d4",
    themeDark: "#f6f6f6",
    themeDarker: "#fafafa",
    neutralLighterAlt: "var(--font-colour-secondary)",
    neutralLighter: "var(--menu-highlight)",
    neutralLight: "var(--menu-highlight)",
    neutralQuaternaryAlt: "#28425d",
    neutralQuaternary: "#2d4863",
    neutralTertiaryAlt: "#44617e",
    neutralTertiary: "var(--font-colour-secondary)",
    neutralSecondary: "var(--input-field-border)",
    neutralPrimaryAlt: "#a6a6a6",
    neutralPrimary: "var(--font-colour-secondary)",
    neutralDark: "var(--font-colour-secondary)",
    black: "#dadada",
    white: "var(--background-light)",
  },
};

export { dark };
