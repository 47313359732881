import React, { useState } from "react";
import styles from "./FirmControls.module.scss";
import { NegotiationUIStatus } from "../../reducers/offerRepStatusReducer";
import { Modal } from "office-ui-fabric-react";
import { ConfirmationDialog } from "sharedFolder/components/common/ConfirmationDialog/ConfirmationDialog";
import Button from "__legacy/sharedFolder/components/common/Button/Button";

export const FirmControls: React.SFC<{
  negotiationUiStatus: NegotiationUIStatus;
  onAcceptFirmBid: () => void;
  onCounterFirmBid: () => void;
}> = ({ onAcceptFirmBid, onCounterFirmBid, negotiationUiStatus }) => {
  const acceptButtonEnabled = ["FirmBid", "CounterFirmBid"];
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [disabledOKButton, setDisabledOKButton] = useState(false);
  return (
    <div className={styles.topBorder}>
      <Button dataTest="CounterFirmBidButton" type="action" onClick={onCounterFirmBid}>
        Counter
      </Button>
      <Button
        dataTest="AcceptAndGoFirmButton"
        type="positive"
        disabled={!acceptButtonEnabled.includes(negotiationUiStatus)}
        onClick={() => {
          setOpenConfirmDialog(true);
          setDisabledOKButton(false);
        }}
      >
        Accept and Go Firm
      </Button>
      {/* TODO: This code is the same as AcceptFirmBox.tsx, they should probably be
      shared  */}
      <Modal isOpen={openConfirmDialog} onDismiss={() => setOpenConfirmDialog(false)}>
        <ConfirmationDialog
          title="Accept bid"
          message="Are you sure you wish to Accept this firm bid? This cannot be undone at a later point"
          dataTestId="AcceptFirmOKButton"
          isDisabled={disabledOKButton}
          ariaLabel="accept firm OK"
          onSubmit={() => {
            setDisabledOKButton(true);
            onAcceptFirmBid();
          }}
          onCancel={() => setOpenConfirmDialog(false)}
        />
      </Modal>
    </div>
  );
};
