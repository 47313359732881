import { makeObservable, observable } from "mobx";
import { Status } from "../../../Status";
import { Negotiation } from "../../Negotiation";

class OrderNegLayout {
  constructor(neg: Negotiation) {
    this.neg = neg;

    makeObservable(this, { statusMap: observable.shallow });
  }

  statusMap = {
    getInitialData: undefined as Status | undefined,
    getInitialDataFail: undefined as Status | undefined,
    updateOwningCompany: undefined as Status | undefined,
    updatingOwningCompanyFail: undefined as Status | undefined,
    nominateVessel: undefined as Status | undefined,
    nominateVesselFail: undefined as Status | undefined,
    updateVessel: undefined as Status | undefined,
    updateVesselFail: undefined as Status | undefined,
  };

  get heading() {
    if (!this.neg.hasData) return "";

    if (this.neg.isLifting())
      return `${this.neg.chartererAccount.accountName} - ${this.neg.orderReference} - Lifting ${this.neg.liftingId}`;

    return `${this.neg.chartererAccount.accountName} - ${this.neg.orderReference}`;
  }
}

export { OrderNegLayout };

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

interface OrderNegLayout {
  neg: Negotiation;
}
