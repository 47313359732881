import * as React from "react";
import { Button } from "@/components";

export default (props: any) => {
  const {
    ariaLabel,
    children,
    className,
    dataKey,
    dataTest,
    disabled,
    href,
    icon,
    reactRef,
    rel,
    title,
    target,
    onClick,
    img,
    type,
    router,
  } = props;

  const _props = {
    children,
    className,
    disabled,
    icon,
    onClick,
    href: !router ? href : null,
    to: router ? href : null,
    img,
    openHrefInNewWindow: target === "_blank",
    variant: type === "plain" ? "flat" : type,
    dom: reactRef,
    rel,
    title,
  };

  return <Button aria-label={ariaLabel} data-key={dataKey} data-test={dataTest} {..._props} />;
};
