import { TradeAPI, tradeAPI } from "@/apis";
import { dialog } from "../Dialog";
import { Negotiation } from "../Negotiation";
import { Order } from "../Order";
import { EntityUpsertConfig, OrderNegotiationStore } from "../OrderNegotiationStore";

class OwnerOrderNegotiationStore extends OrderNegotiationStore {
  OrderConstructor = OwnerOrder;
  NegotiationConstructor = OwnerNegotiation;

  async getNegotiationsDataByOrderId(orderId: string, upsertConfig?: EntityUpsertConfig) {
    const order = this.upsertOrderModel({ id: orderId });

    order._.status.loading = true;
    order._.status.message = "Fetching Negotiations";

    const res = await tradeAPI.getOwnerNegotiations(orderId);

    if (!res?.ok) {
      order._.status.type = "error";
      order._.status.title = "Order Negotiations Fetch Failure";
      order._.status.dump = { res, entity: order };

      dialog.show({
        status: order._.status,
        dataTest: "ownerOrderNegotiationStore-order-negotiationArray-fetch-fail",
      });

      order._.status.loading = false;
      order._.status.message = null;

      return res;
    }

    if (res.ok && res.data) {
      const negotiations = [] as TradeAPI["Negotiation"][];

      for (let i = 0; i < res.data.length; i++) {
        const negotiation = res.data[i];
        const { orderId, id } = negotiation;
        const existingNegotiation = this.orderMap[orderId]?._.negotiationMap?.[id];

        if (existingNegotiation || negotiation.status !== "Withdrawn") {
          negotiations.push(negotiation);
        }
      }

      this.upsertNegotiations(negotiations, upsertConfig);
    }

    return res;
  }

  async getUnarchivedNegotiationsData() {
    this.negotiationArrayStatus.loading = true;
    this.negotiationArrayStatus.message = "Loading";
    const res = await tradeAPI.getUnarchivedOwnerNegotiationsAll();

    if (!res?.ok) {
      this.negotiationArrayStatus.type = "error";
      this.negotiationArrayStatus.title = "Negotiations Fetch Failure";
      this.negotiationArrayStatus.dump = { res };

      dialog.show({
        status: this.negotiationArrayStatus,
        dataTest: "ownerOrderNegotiationStore-negotiationArray-fetch-fail",
      });

      this.negotiationArrayStatus.loading = false;
      this.negotiationArrayStatus.message = null;

      return res;
    }

    if (res.ok && res.data) {
      this.upsertNegotiations(res.data);
    }

    this.negotiationArrayStatus.loading = false;
    this.negotiationArrayStatus.message = null;

    return res;
  }

  canNegotationBeInserted(negotiation: TradeAPI["Negotiation"]) {
    return !negotiation.isArchived;
  }
}

class OwnerOrder extends Order {
  // async getData() {
  //   if (!this.id) return;
  //   const res = await tradeAPI.getOwnerOrder(this.id);
  //   if (res.ok && res.data) {
  //     this.update(res.data);
  //   }
  //   return res;
  // }
}

class OwnerNegotiation extends Negotiation {
  async getData() {
    if (!this.orderId || !this.id) return;

    const res = await tradeAPI.getOwnerNegotiation(this.orderId, this.id);

    if (res.ok && res.data) this.update(res.data);

    return res;
  }
}

export const ownerOrderNegotiationStore = new OwnerOrderNegotiationStore();
