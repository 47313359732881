import { TradeAPI } from "___REFACTOR___/apis";
import { DataModel, DataModelProps } from "../DataModel";
import { format } from "___REFACTOR___/utils";

class UnitValue extends DataModel {
  constructor(data?: PartialData) {
    super(data);
  }

  toJSON() {
    const json = DataModel.prototype.toJSON.call(this) as TradeAPI.Location;

    return json;
  }

  toString() {
    if (!this) return "";

    // @ts-ignore
    const unit = this.unit;

    if (typeof this.value !== "number" || typeof unit !== "string") return "";

    return `$${format.monetary.display(this.value)} ${UNIT_VALUE_LABEL_MAP[unit]} ${this.notes || ""}`;
  }

  toGridView() {
    if (!this) return "";

    // @ts-ignore
    const unit = this.unit;

    if (typeof this.value !== "number" || typeof unit !== "string") return "";

    return `$${format.monetary.display(this.value)} ${UNIT_VALUE_LABEL_MAP[unit]}`;
  }
}

class UnitValueProps extends DataModelProps {}

const UNIT_VALUE_LABEL_MAP = {
  PerDay: "Per Day",
  LumpSum: "Lump Sum",
  PerMT: "Per MT",
};

UnitValue.prototype.Props = UnitValueProps;
UnitValueProps.prototype.sortKey = "value";
UnitValueProps.prototype.JSONDefaults = {
  unit: "PerDay",
};

export { UnitValue, UnitValueProps, UNIT_VALUE_LABEL_MAP };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface UnitValue extends Data {
  Props: typeof UnitValueProps;
  _: UnitValueProps;
}
interface UnitValueProps {}

type PartialData = Partial<Data>;
type Data = TradeAPI.UnitValue;
