import React, { useState, useContext } from "react";
import styles from "./VesselSelector.module.scss";
import { IVesselForm } from "sharedFolder/Models/IVessel";
import { VesselDisplay } from "sharedFolder/components/common/VesselDisplay/VesselDisplay";
import Button from "__legacy/sharedFolder/components/common/Button/Button";
import { VesselNominate } from "sharedFolder/components/common/VesselNominate/VesselNominate";
import { VesselPanelActions } from "sharedFolder/components/common/VesselPanelActions/VesselPanelActions";
import { mapToVesselForm } from "sharedFolder/mappers/mapToVesselForm";
import { IOfferRepViewNegotiation } from "negotiations/models/common/IOfferRep";
import { ValidationContext } from "sharedFolder/contexts/ValidationContext";
import { IVesselView } from "sharedFolder/Models/IDetails";

export interface IVesselSelectedView extends IVesselView {
  isDeletable: boolean;
}

interface IVesselDetailRowProps {
  vessels: IVesselSelectedView[];
  negotiation: IOfferRepViewNegotiation;
  onAddNewVessel: (vessel: IVesselSelectedView) => void;
  onRemoveVessel: (vessel: IVesselSelectedView) => void;
  onEditVessel: (vessel: IVesselSelectedView) => void;
  disabled?: boolean;
}

export const VesselSelector: React.FC<IVesselDetailRowProps> = (props: IVesselDetailRowProps) => {
  const [newVessel, setNewVessel] = useState<IVesselForm | undefined>();
  const { disabled, vessels } = props;
  const [nominateVesselPanel, openNominateVesselPanel] = useState(false);
  const validationContext = useContext(ValidationContext);
  const addVessel = (vessel: IVesselView | undefined) => {
    if (!vessel) {
      return console.error("vessel was not found");
    }
    if (vessels.find((v) => v.arcVesselId === vessel.arcVesselId)) {
      // duplicate vessels aren't alowed
      return console.error("duplicate vessels");
    }
    const newVessel = { ...vessel, isDeletable: true };
    props.onAddNewVessel(newVessel);
    openNominateVesselPanel(false);
  };

  const editVessel = (vessel: IVesselView) => {
    props.onEditVessel({
      ...vessel,
      isDeletable: (vessel as IVesselSelectedView).isDeletable,
    });
  };

  const removeVessel = (vessel: IVesselView) => {
    if (vessel) {
      const vesselToRemove = { ...vessel, isDeletable: true };
      props.onRemoveVessel(vesselToRemove);
    }
  };

  const onDismiss = () => {
    // Set the validation to 'true' on dismiss, so that the any isValid:false
    // value doesn't stick around after the control has been removed
    validationContext.fieldValidityChanged("VesselSearchPicker", true);
    openNominateVesselPanel(false);
  };

  return (
    <>
      <div className={styles.content}>
        {vessels.map((vessel) => (
          <div key={vessel.vesselIMO} className={styles.vesselDisplayContainer}>
            <VesselDisplay
              disabled={disabled}
              vessel={vessel}
              onDelete={vessel.isDeletable ? () => removeVessel(vessel) : null}
              onVesselChange={editVessel}
              hideWarningFlag={true}
            />
          </div>
        ))}
        {!vessels.length && !nominateVesselPanel && <div>TBN</div>}
        {nominateVesselPanel ? (
          <div>
            <VesselNominate
              nominatedVessels={vessels}
              onVesselChanged={(vessel: IVesselForm | undefined) => {
                setNewVessel(vessel);
              }}
            />

            <VesselPanelActions
              isValid={newVessel && newVessel.isValid}
              addVessel={() => {
                addVessel(mapToVesselForm(newVessel));
              }}
              onDismiss={onDismiss}
            />
          </div>
        ) : (
          <div className={styles.vesselActionContainer}>
            <Button
              type="flat"
              icon="plus"
              dataTest="nominate-vessel"
              onClick={() => openNominateVesselPanel(true)}
              disabled={disabled}
            >
              {!vessels.length ? "nominate a vessel" : "nominate another vessel"}
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
