import React, { useEffect, useContext, useState } from "react";
import moment from "moment";
import { IOfferRepViewNegotiation } from "negotiations/models/common/IOfferRep";
import styles from "./DashboardLayout.module.scss";
import classnames from "classnames";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { TrackingContext } from "../../analytics/TrackingContext";
import { IConfiguration } from "sharedFolder/Configuration";
import { OrderDetails } from "sharedFolder/components/common/OrderDetails/OrderDetails";
import { FormWrapper } from "../OwnerIndicationForm/Form/FormWrapper";
import { IOfferState } from "../OwnerIndicationForm/Form/IOfferState";
import { OrderNotesAndAttachmentsForDashboard } from "../OrderDetails/OrderNotesAndAttachments";
import { INegotiationDetailsView } from "sharedFolder/Models/Negotiation/INegotiationView";

interface ITermsProps {
  config: IConfiguration;
  negotiation: IOfferRepViewNegotiation;
}

export const DashboardLayout: React.FC<ITermsProps> = (props: ITermsProps) => {
  // tracking - log on interactions
  const tracking = useContext(TrackingContext);
  useEffect(() => {
    // treat the initial render of this component as a 'login' event
    // TODO: How to we track the clicks in the owner dashboard
    // tracking.authEvent("login", `offerRep.${props.negotiation.invitee}`);
  }, [tracking, props.negotiation]);
  // end tracking

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [offer, setOffer] = useState<IOfferState>();

  return (
    <div className={classnames(styles.tabsContainer, selectedTabIndex === 0 && styles.fixHeightHack)}>
      <Tabs onSelect={setSelectedTabIndex}>
        <TabList className={classnames(styles.tabHeaderRibbon)}>
          <Tab data-test="negotiation-tab">
            <label>Negotiation</label>
          </Tab>
          <Tab data-test="summary-tab">
            <label>Order Details</label>
          </Tab>
          <Tab data-test="notes-attachments">
            <label>Notes & Attachments</label>
            <sup className={styles.badge}>
              {props.negotiation.attachments.length +
                props.negotiation?.orderAttachments.length +
                (props.negotiation.details?.controllerNegotiationNotes?.value ? 1 : 0) +
                (props.negotiation.details?.offerRepNegotiationNotes?.value ? 1 : 0)}
            </sup>
          </Tab>
        </TabList>
        <TabPanel>
          <div className={classnames(styles.layoutOwners)} data-test="owner-dashboard-form">
            <div className={styles.sectionTitle}>
              <label>Negotiation</label>
              <span>
                updated by {props.negotiation.lastUpdatedBy === "owner" ? "you" : "counterparty"}{" "}
                {moment(props.negotiation.lastUpdate).fromNow()}
              </span>
            </div>{" "}
            <FormWrapper {...props} onOfferChange={setOffer} offer={offer} offerRepContext="OwnerDashboard" />
          </div>
        </TabPanel>
        <TabPanel>
          <div className={styles.summaryLayout}>
            <OrderDetails
              negotiation={props.negotiation.details as INegotiationDetailsView}
              displayWithBorders={true}
              orderNegType={props.negotiation.type}
            />
          </div>
        </TabPanel>
        <TabPanel>
          <OrderNotesAndAttachmentsForDashboard negotiation={props.negotiation} />
        </TabPanel>
      </Tabs>
    </div>
  );
};
