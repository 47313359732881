import { OrderNegStore, Order } from "___REFACTOR___/models";
import { DataModelProps } from "___REFACTOR___/models/DataModel";
import { makeObservable, observable } from "mobx";
import { Negotiation } from "../Negotiation";
import { OrderNegLayout } from "./OrderNegLayout";

class NegotiationProps extends DataModelProps {
  constructor(props: NegotiationProps.ConstructorProps) {
    super();

    Object.assign(this, props);

    this.orderNegLayout = new OrderNegLayout(this.neg);

    if (this.orderNegStore) {
      const data = this.neg.toJSON();
      this.orderNegStore.neg = this.orderNegStore.store.upsertNeg(data, { shouldUpdateModel: false });
      this.orderNegStore.neg._.model = this.neg;
    }

    makeObservable(this, { version: observable });
  }

  version = 0;

  get parent() {
    return this.order;
  }

  get stageMetadataMap() {
    return {
      Inactive: { value: "Inactive", label: "New" },
      Active: { value: "Active", label: "Active" },
      FirmOffer: { value: "FirmOffer", label: "Firm Offer" },
      FirmBid: { value: "FirmBid", label: "Firm Bid" },
      Firm: { value: "Firm", label: "Firm" },
      TermsLoading: { value: "TermsLoading", label: "Terms Loading" },
      MainTerms: { value: "MainTerms", label: "Main Terms" },
      OnSubs: { value: "OnSubs", label: "On Subs" },
      SubsLifted: { value: "SubsLifted", label: "Subjects Lifted" },
      SubsFailed: { value: "SubsFailed", label: "Subjects Failed" },
      Fixed: { value: "Fixed", label: "Fixed" },
      Nomination: { value: "Nomination", label: "Nomination" },
      Confirmed: { value: "Confirmed", label: "Confirmed" },
      Withdrawn: { value: "Withdrawn", label: "Withdrawn" },
    };
  }
}

export { NegotiationProps };

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

interface NegotiationProps {
  neg: Negotiation;
  order: Order;
  orderNegLayout: OrderNegLayout;
  orderNegStore?: {
    store: OrderNegStore;
    neg: OrderNegStore.Negotiation;
  };
}

declare namespace NegotiationProps {
  export interface ConstructorProps {
    order?: Order;
    neg?: Negotiation;
    orderNegStore?: {
      store: OrderNegStore;
    };
  }
}
