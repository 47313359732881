import { IUnitValue, IUnitValueAPI } from "./IUnitValue";
import { IKeyValuePair } from "./IKeyValuePair";
import { IUnitValueView } from "./IDetails";

export type BallastBonusUnit = "PerDay" | "LumpSum";

export type IBallastBonus = IUnitValue<BallastBonusUnit>;

export const BallastBonusUnitDisplay: { [key in BallastBonusUnit]: string } = {
  PerDay: "Per Day",
  LumpSum: "Lump Sum",
};

export const BallastBonusUnitShortDisplay: {
  [key in string]: string;
} = {
  PerDay: "/ Day",
  LumpSum: "LS",
};

export const BallastBonusOptions: IKeyValuePair[] = [
  { key: "LumpSum", value: "Lump Sum" },
  { key: "PerDay", value: "Per Day" },
];

export const mapStringToBallastBonusUnit = (unit?: string): BallastBonusUnit | undefined => {
  return unit === "PerDay" || unit === "LumpSum" ? unit : undefined;
};

export const mapBallastBonusViewToIBallastBonus = (
  item: IUnitValueView | IUnitValueAPI | undefined
): IBallastBonus | undefined => {
  if (!item || (item.unit === undefined && item.value === undefined)) {
    return undefined;
  }
  return {
    unit: mapStringToBallastBonusUnit(item.unit),
    value: Number(item.value) || undefined,
  };
};
