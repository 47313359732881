import { observer } from "mobx-react";
import { object, array, TestContext } from "yup";
import React, { useMemo, useState, useEffect, useRef, createContext as createReactContext } from "react";
import Split from "react-split";
import { useAutoChangingRef, useObsBox } from "___REFACTOR___/utils/reactHooks";
import { TradeAPI } from "___REFACTOR___/apis";
import { dialog, userStorage, auth, history } from "___REFACTOR___/services";
import {
  ChartererAccount,
  Laycan,
  CargoType,
  AddressCommission,
  BrokerCommission,
  CargoSize,
  LoadLocation,
  DischargeLocation,
  VesselSize,
  Duration,
  DeliveryLocation,
  ViaLocation,
  RedeliveryLocation,
  OwnerAccount,
  CLDDUOwner,
  FreightRate,
  Demurrage,
  HireRate,
  CleaningPrice,
  SupplyPrice,
  TradingExclusions,
  BunkerDelivery,
  BunkerRedelivery,
  CargoExclusionsText,
  BallastBonus,
  Vessels,
  VOYNotes,
  TCTNotes,
  OrderTemplateProps,
  CLDDU,
  ChartererAccountProps,
  CargoTypeProps,
  CargoSizeProps,
  LocationProps,
  FreightRateProps,
  DemurrageProps,
  OwnerAccountProps,
  DurationProps,
} from "___REFACTOR___/models";
import { dealCapture } from "___REFACTOR___/routes";
import * as sf from "___REFACTOR___/components/common/StandardForm";
import { Heading } from "___REFACTOR___/components/common/Form";
import { Button, Icon } from "___REFACTOR___/components/common";
import { CaptureProceedTo } from "./CaptureProceedTo";
import { router } from "@/models/Router/Router";
import { Body, TermsetBuilder, resolveDuplicateTermTitle } from "@/components/common/Editor/type/TermsetBuilder";
import { emptyFn } from "___REFACTOR___/utils";
import { useFlags } from "launchdarkly-react-client-sdk";
import { shouldEnableLaunchDarklyFeature } from "@/utils";
import { LaunchDarklyFeature } from "@/config";
import "./DealCapture.scss";

const Component = observer(() => {
  const { as } = dealCapture.searchParams;
  const formConfig = useMemo(getFormConfig, []);
  const formProps = sf.useStandardForm(formConfig);
  const formPropsBox = useObsBox(formProps, [formProps.values]);
  const formPropsRef = useAutoChangingRef(formProps);
  const Context = useMemo(createContext, []);
  const { getEditorProps, values: body, setFieldValue, setValues } = formProps;
  const { typeMap, template } = body;
  const storedProps = useMemo(getStoredProps, []);
  const [splitSizes, setSplitSizes] = useState(storedProps.splitSizes);
  const gutter = useMemo(createGutter, []);
  const backHref = router.get("owner").compile();

  const onResetRef = useRef<() => void>(() => null);

  useEffect(onBodyChange, [body]);
  useEffect(onTemplateChange, [template?.id]);
  useEffect(() => {
    if (typeMap.Voy) {
      setValidationSchema("Voy");
    } else if (typeMap.Tct) {
      setValidationSchema("Tct");
    }
  }, [typeMap]);

  const orderTemplateEditorProps = getEditorProps("template");
  const flags = useFlags();
  const enableTcFields = shouldEnableLaunchDarklyFeature(LaunchDarklyFeature.TCFields, flags);

  return (
    <Context.Provider value={formProps}>
      <sf.Container {...formProps} className="owner-deal-capture">
        <sf.Body {...formProps}>
          <Split className="owner-deal-capture-split" sizes={splitSizes} onDragEnd={setSplitSizes} gutterSize={5}>
            {/*
              ======================================== 
              LEFT HAND SIDE (Main form)
              ========================================
            */}
            <owner-deal-capture-terms>
              <h1 className="main-title">
                Deal Capture <Icon name="flash-on" />
              </h1>
              <owner-deal-capture-message>
                Choose an Order Template (or manually fill in the details below)
              </owner-deal-capture-message>
              <OrderTemplateProps.prototype.Editor
                {...orderTemplateEditorProps}
                onChange={(value) => {
                  formProps.resetForm();
                  orderTemplateEditorProps.onChange(value);
                }}
                orderSearchQuery="&orderType=std"
              />
              <sf.Separator />
              {/*
                ======================================== 
                ORDER TERMS
                ========================================
              */}
              <h2>Order Details</h2>
              <ChartererAccount.Editor
                {...getEditorProps("details.chartererAccount")}
                label={ChartererAccount.label}
                key="chartererAccount"
                required
              />

              <Laycan.Editor {...getEditorProps("details.laycan")} label={Laycan.label} key="laycan" required />

              <CargoType.Editor {...getEditorProps("details.cargoType")} label={CargoType.label} key="cargoType" required />

              <AddressCommission.Editor
                {...getEditorProps("details.addressCommission")}
                label={AddressCommission.label}
                key="addressCommission"
              />

              <BrokerCommission.Editor
                {...getEditorProps("details.brokerCommission")}
                label={BrokerCommission.label}
                key="brokerCommission"
              />

              {/*
                ======================================== 
                VOYAGE TERMS (VOY)
                ========================================
              */}
              <sf.InputHeading
                {...getEditorProps("details.types")}
                type="Radio"
                value={typeMap.Voy}
                onChange={toggleType.bind(null, "Voy")}
              >
                Voyage Terms
              </sf.InputHeading>
              {/*
                ======================================== 
                VOYAGE FIELDS
                ========================================
              */}
              <CargoSize.Editor
                {...getEditorProps("details.cargoSize")}
                label={CargoSize.label}
                hidden={!typeMap.Voy}
                required={typeMap.Voy}
              />
              {typeMap.Voy && <sf.Separator />}

              <LoadLocation.Editor
                {...getEditorProps("details.loadLocation")}
                label={LoadLocation.label}
                hidden={!typeMap.Voy}
                required={typeMap.Voy}
              />
              {typeMap.Voy && <sf.Separator />}

              <DischargeLocation.Editor
                {...getEditorProps("details.dischargeLocation")}
                label={DischargeLocation.label}
                hidden={!typeMap.Voy}
                required={typeMap.Voy}
              />
              {typeMap.Voy && <sf.Separator />}

              <FreightRate.Editor
                {...getEditorProps("details.freightRate")}
                label={FreightRate.label}
                hidden={!typeMap.Voy}
                required={typeMap.Voy}
              />
              {typeMap.Voy && <sf.Separator />}

              <Demurrage.Editor
                {...getEditorProps("details.demurrage")}
                label={Demurrage.label}
                hidden={!typeMap.Voy}
                required={typeMap.Voy}
              />
              {typeMap.Voy && <sf.Separator />}

              <VOYNotes.Editor {...getEditorProps("details.voyageNotes.data")} label={VOYNotes.label} hidden={!typeMap.Voy} />

              {/*
                ======================================== 
                TIME CHARTERER TERMS (TCT)
                ========================================
              */}
              <sf.InputHeading
                {...getEditorProps("details.types")}
                type="Radio"
                value={typeMap.Tct}
                onChange={toggleType.bind(null, "Tct")}
              >
                Time Charter Terms
              </sf.InputHeading>
              {/*
                ======================================== 
                TIME CARTERER FIELDS
                ========================================
              */}
              <VesselSize.Editor {...getEditorProps("details.vesselSize")} label={VesselSize.label} hidden={!typeMap.Tct} />
              {typeMap.Tct && <sf.Separator />}

              <Duration.Editor {...getEditorProps("details.duration")} label={Duration.label} hidden={!typeMap.Tct} />
              {typeMap.Tct && <sf.Separator />}

              <DeliveryLocation.Editor
                {...getEditorProps("details.deliveryLocation")}
                label={DeliveryLocation.label}
                hidden={!typeMap.Tct}
                required={typeMap.Tct}
              />
              {typeMap.Tct && <sf.Separator />}

              <ViaLocation.Editor {...getEditorProps("details.viaLocation")} label={ViaLocation.label} hidden={!typeMap.Tct} />
              {typeMap.Tct && <sf.Separator />}

              <RedeliveryLocation.Editor
                {...getEditorProps("details.redeliveryLocation")}
                label={RedeliveryLocation.label}
                hidden={!typeMap.Tct}
              />
              {typeMap.Tct && <sf.Separator />}

              <HireRate.Editor {...getEditorProps("details.hireRate")} label={HireRate.label} hidden={!typeMap.Tct} />
              {typeMap.Tct && <sf.Separator />}

              <CleaningPrice.Editor
                {...getEditorProps("details.cleaningPrice")}
                label={CleaningPrice.label}
                hidden={!typeMap.Tct || !enableTcFields}
              />
              {typeMap.Tct && enableTcFields && <sf.Separator />}

              <SupplyPrice.Editor
                {...getEditorProps("details.supplyPrice")}
                label={SupplyPrice.label}
                hidden={!typeMap.Tct || !enableTcFields}
              />
              {typeMap.Tct && enableTcFields && <sf.Separator />}

              <TradingExclusions.Editor
                {...getEditorProps("details.tradingExclusions")}
                label={TradingExclusions.label}
                hidden={!typeMap.Tct || !enableTcFields}
              />
              {typeMap.Tct && enableTcFields && <sf.Separator />}

              <BunkerDelivery.Editor
                {...getEditorProps("details.bunkerDelivery")}
                label={BunkerDelivery.label}
                key="bunkerDelivery"
                hidden={!typeMap.Tct || !enableTcFields}
              />
              {typeMap.Tct && enableTcFields && <sf.Separator />}

              <BunkerRedelivery.Editor
                {...getEditorProps("details.bunkerRedelivery")}
                label={BunkerRedelivery.label}
                key="bunkerRedelivery"
                hidden={!typeMap.Tct || !enableTcFields}
              />
              {typeMap.Tct && enableTcFields && <sf.Separator />}

              <CargoExclusionsText.Editor
                {...getEditorProps("details.cargoExclusionsText")}
                label={CargoExclusionsText.label}
                hidden={!typeMap.Tct || !enableTcFields}
              />
              {typeMap.Tct && enableTcFields && <sf.Separator />}

              <BallastBonus.Editor {...getEditorProps("details.ballastBonus")} label={BallastBonus.label} hidden={!typeMap.Tct} />
              {typeMap.Tct && <sf.Separator />}

              <TCTNotes.Editor {...getEditorProps("details.tctNotes.data")} label={TCTNotes.label} hidden={!typeMap.Tct} />

              {/*
                ======================================== 
                OWNER / VESSEL DETAILS
                ========================================
              */}
              <sf.Separator />
              <Heading>Owner / Vessel Details</Heading>
              {sortOwnerInput()}
              <sf.Separator />

              <OwnerAccount.Editor
                {...getEditorProps("details.ownerAccount")}
                label={OwnerAccount.label}
                key="ownerAccount"
                required
              />
              <sf.Separator />

              <Vessels.Editor {...getEditorProps("details.vessels")} label={Vessels.label} key="vessels" />
            </owner-deal-capture-terms>

            {/*
              ======================================== 
              RIGHT HAND SIDE (Terms Builder)
              ========================================
            */}
            <div>
              <TermsetBuilder
                {...getEditorProps("termset")}
                onReset={onResetRef}
                onChange={onChangeTermset}
                onChangeSubjects={onChangeSubjects}
                formProps={formProps}
              />
            </div>
          </Split>
        </sf.Body>
        <sf.Footer {...formProps} className="owner-deal-capture-footer">
          <Button icon="arrow-back" variant="flat" label="Back" onClick={onBackClicked} />
          <sf.DefaultActions {...formProps} />
        </sf.Footer>
      </sf.Container>
    </Context.Provider>
  );

  function onChangeTermset(termset: any) {
    formProps.setFieldValue("termset", termset);
  }

  function onChangeSubjects(subjects: any) {
    body.subjectTermsetMainTermTemplates = subjects;
  }

  function onBackClicked() {
    history.push(backHref);
  }

  function onTemplateChange() {
    if (!template) return;

    body.inheritFromTemplate(template);

    setValues(body);
  }

  function sortOwnerInput() {
    return (
      <CLDDUOwner.Editor
        {...getEditorProps("details.invitee")}
        label={CLDDUOwner.label}
        key="invitee"
        value={new CLDDU(auth.trade.user)}
        readOnly
      />
    );
  }

  async function openSubmitDialog() {
    const SubmitDialog = observer(() => {
      const formProps = formPropsBox.get();
      return <CaptureProceedTo section="deal-capture" formProps={formProps} />;
    });
    return await dialog.open(<SubmitDialog />, { suppressCloseBtn: true, useNewVersion: false }).then(emptyFn, function () {
      formProps.setSubmitting(false);
    });
  }

  function getFormConfig() {
    return {
      initialValues: new Body(
        { as, typeMap: { Voy: true }, statusMap: { Fixed: true }, selectedOption: "Termset Template" },
        "Fixed"
      ),
      onSubmit: () => {
        openSubmitDialog();
      },
      onReset: () => {
        if (onResetRef?.current) {
          onResetRef.current();
        }
      },
      validationSchema: validationSchemaVOY,
      resetButtonProps: {
        confirmationProps: resetConfirmationProps,
      },
      dataTest: "owner-deal-capture",
    } as sf.StandardForm.Config<Body>;
  }

  function createContext() {
    return createReactContext(formPropsRef.current);
  }

  function onBodyChange() {
    // @ts-ignore
    body.termset?.content.mainTermTemplates.forEach((term, i) => (term.i = i));
    // @ts-ignore
    body.subjectTermsetMainTermTemplates.forEach((term, i) => (term.i = i));

    console.log(body);
  }

  function toggleType(type: Body.Type) {
    setFieldValue("typeMap", { [type]: true });
  }

  function setValidationSchema(type: Body.Type) {
    let nextValidationSchema: any;
    switch (type) {
      case "Tct":
        nextValidationSchema = validationSchemaTCT;
        break;
      default:
        nextValidationSchema = validationSchemaVOY;
        break;
    }
    formConfig.validationSchema = nextValidationSchema;
  }

  function getStoredProps() {
    const storedProps = userStorage.get(STORAGE_KEY, DEFAULT_STORED_PROPS) as StoredProps;

    return storedProps;
  }

  function createGutter() {
    const gutter = document.createElement("order-neg-layout-resizer");

    return gutter;
  }
});

const resetConfirmationProps: Button.ResetConfirmationProps = {
  title: "Unsaved Changes",
  submitText: "Confirm",
  cancelText: "Cancel",
  message: "You have unsaved changes, are you sure you want to reset the page?",
};

const STORAGE_KEY = "owner-deal-capture";

const DEFAULT_STORED_PROPS = {
  splitSizes: [40, 60],
};

const validationSchemaVOY = object({
  details: object({
    laycan: Laycan.schema.default(undefined).required("Laycan is required"),
    chartererAccount: ChartererAccountProps.prototype.schema.default(undefined).required("Charterer is required"),
    cargoType: CargoTypeProps.prototype.schema.default(undefined).required("Cargo type is required"),
    cargoSize: CargoSizeProps.prototype.schema.default(undefined).required("Cargo size is required"),
    loadLocation: LocationProps.prototype.schema.default(undefined).required("Load location is required"),
    dischargeLocation: LocationProps.prototype.schema.default(undefined).required("Discharge location is required"),
    freightRate: FreightRateProps.prototype.schema.default(undefined).required("Freight rate is required"),
    demurrage: DemurrageProps.prototype.schema.default(undefined).required("Demurrage is required"),
    ownerAccount: OwnerAccountProps.prototype.schema.default(undefined).required("Owning Company is required"),
  }),
  termset: object({
    content: object({
      mainTermTemplates: array()
        .of(
          object({}).test({
            name: "Unique Term Title",
            message: "Something is wrong with the Terms",
            test: function (this: TestContext, value) {
              const term = value as TradeAPI.Termset.Content.Term;
              const duplicateTerm = resolveDuplicateTermTitle(this.parent);
              const isDuplicate = duplicateTerm?.title.toUpperCase() === term.title.toUpperCase();

              if (duplicateTerm && isDuplicate) {
                const error = this.createError();

                error.message = `Duplicate term title: ${term.title}, ${duplicateTerm.title}`;
                error.path = `${this.path}.title`;

                return error;
              }

              return true;
            },
          })
        )
        .required("Required")
        .min(1, "Required"),
    }).defined(),
  }).defined(),
});

const validationSchemaTCT = object({
  details: object({
    laycan: Laycan.schema.default(undefined).required("Laycan is required"),
    chartererAccount: ChartererAccountProps.prototype.schema.default(undefined).required("Charterer is required"),
    cargoType: CargoTypeProps.prototype.schema.default(undefined).required("Cargo type is required"),
    deliveryLocation: LocationProps.prototype.schema.default(undefined).required("Delivery location is required"),
    ownerAccount: OwnerAccountProps.prototype.schema.default(undefined).required("Owning Company is required"),
    duration: DurationProps.prototype.schema.default(undefined).required("Duration is required"),
  }),
  termset: object({
    content: object({
      mainTermTemplates: array()
        .of(
          object({}).test({
            name: "Unique Term Title",
            message: "Something is wrong with the Terms",
            test: function (this: TestContext, value) {
              const term = value as TradeAPI.Termset.Content.Term;
              const duplicateTerm = resolveDuplicateTermTitle(this.parent);
              const isDuplicate = duplicateTerm?.title.toUpperCase() === term.title.toUpperCase();

              if (duplicateTerm && isDuplicate) {
                const error = this.createError();

                error.message = `Duplicate term title: ${term.title}, ${duplicateTerm.title}`;
                error.path = `${this.path}.title`;

                return error;
              }

              return true;
            },
          })
        )
        .required("Required")
        .min(1, "Required"),
    }).defined(),
  }).defined(),
});

function DealCapture(props) {
  return <Component {...props} />;
}

export { DealCapture, resetConfirmationProps as MainTermsResetConfirmationProps };

/*-------------------------------------------------------------*/
/*                            TYPES                            */
/*-------------------------------------------------------------*/

declare namespace DealCapture {}
interface StoredProps {
  splitSizes: number[];
}
