import {
  IDistributionViewModel,
  IDistributionListViewModel,
  IDeskView,
  ISearchUserView,
  DistributionUserRole,
} from "../../../sharedFolder/Models/IDetails";
import { find } from "lodash-es";
import { mapRecipientList } from "sharedFolder/mappers/mapRecipientList";
import { mapDistribution } from "sharedFolder/mappers/mapDistribution";
import { IRecipient } from "api/orders/models/IDistributionList";
import {
  distributionVanillaEmailSearchReducer,
  DistributionVanillaEmailState,
  initialVanillaEmailSearchState,
} from "./distributionVanillaEmailSearchReducer";
import {
  DistributionSearchActions,
  distributionSearchReducer,
  DistributionSearchState,
  initialSearchState,
} from "./distributionSearchReducer";
import { IUserContext } from "__legacy/dashboard/contexts/UserProvider";
import { hasUsersWithNoRoleSelected } from "../roles/haveUsersOrRolesChanged";
import {
  DistributionSavedUserActions,
  distributionSavedUsersReducer,
  DistributionSavedUsersState,
  initialSavedUsersState,
} from "./distributionSavedUsersReducer";

type ValidationType =
  | "GENERAL_VALIDATION_WARNING"
  | "MULTIPLE_CHARTERER_WARNING"
  | "SAME_COMPANY_DIFFERENT_ROLE_WARNING"
  | "NONE";

export interface DistributionState {
  savedUsersState: DistributionSavedUsersState;
  existingUsers: IDistributionListViewModel;
  currentlyLoggedInUser?: IRecipient;
  vanillaEmailSearchState: DistributionVanillaEmailState;
  searchResultsState: DistributionSearchState;
  validationShow: ValidationType;
  currentlyLoggedInUserContext?: IUserContext;
}

export const initialState: DistributionState = {
  savedUsersState: initialSavedUsersState,
  existingUsers: mapRecipientList.emptyViewModel,
  vanillaEmailSearchState: initialVanillaEmailSearchState,
  searchResultsState: initialSearchState,
  validationShow: "NONE",
};

type ResolveSearchAction = {
  type: "resolveSearch";
  payload: { results: IDistributionListViewModel; searchTerm: string };
};

type ResolveUserAction = {
  type: "resolveUserByEmailAddress";
  payload: { results: ISearchUserView[]; originalEmail: string };
};

type ResolveUsersAction = {
  type: "resolveUsersByEmailAddress";
  payload: ISearchUserView[];
};

type SetSearchTermAction = {
  type: "setSearchTerm";
  payload: string;
};

type AddUser = {
  type: "addUser";
  payload: IDistributionViewModel;
};

type AddAllUsers = {
  type: "addAllUsers";
};

type AddUsersAsEmail = { type: "addsUsersAsEmail"; payload: string[] };

type AddAbsentOwner = { type: "addAbsentOwner"; payload: string };

type AddDesk = {
  type: "addDesk";
  payload: IDeskView;
};

type RemoveDesk = {
  type: "removeDesk";
  payload: IDeskView;
};

type RemoveUser = {
  type: "removeUser";
  payload: IDistributionViewModel;
};

type SetExistingUsers = {
  type: "setExistingUsers";
  payload: {
    distributionList: IDistributionListViewModel;
    currentLoggedInUserEmail: string;
  };
};

type SetUserRole = {
  type: "setSavedUserRole";
  payload: { email: string; role: DistributionUserRole };
};

type ShowValidation = {
  type: "showValidation";
};

export type DistributionActions =
  | ResolveSearchAction
  | SetSearchTermAction
  | AddUser
  | AddAllUsers
  | AddUsersAsEmail
  | AddAbsentOwner
  | AddDesk
  | ResolveUsersAction
  | RemoveDesk
  | RemoveUser
  | ResolveUserAction
  | SetUserRole
  | ShowValidation
  | SetExistingUsers;

/**
 * These are alias over the mapping functions just to make the code a little clearer as they are
 * essentially being used as functions to flatten and recontruct the tree view
 */

export const flatten = mapRecipientList.toApi;
export const unflatten = mapRecipientList.toView;

const sendActionToSavedUsers = (state: DistributionState, actions: DistributionSavedUserActions): DistributionSavedUsersState => {
  return distributionSavedUsersReducer(
    {
      ...state.savedUsersState,
      currentlyLoggedInUserContext: state.currentlyLoggedInUserContext,
    },
    actions
  );
};

const sendActionToSearchUsers = (state: DistributionState, actions: DistributionSearchActions): DistributionSearchState => {
  return distributionSearchReducer(
    {
      ...state.searchResultsState,
      currentlyLoggedInUserContext: state.currentlyLoggedInUserContext,
    },
    actions
  );
};

/**
 * ** Distribution Reducers - an explanation **
 * --------------------------------------------
 *
 * - distributionSearchReducer - to hold all the state of the state for the search element of the screen
 * - distributionSavedUsers - to hold all the state for the saved users beneath the search results area
 * - distributionVanillaEmailSearchReducer - which will contain the state for any vanilla email addresses added
 *   to saved users, these vanilla email addresses require a subsequent call to search the backend to find if the user is an existing Trade user
 * - distributionReducer - This is the orchestration reducer, we need an orchestration reducer as the state of savedUsers
 *    sometimes relies on knowing the state of searchUsers. For instance, if user 'Ted Bundy' appears in the savedUsers,
 *    when we display him/her/ze in the searchUsers, we will want to display Ted as disabled to indicate 'already selected'
 */
export const distributionReducer = (state: DistributionState, action: DistributionActions): DistributionState => {
  switch (action.type) {
    case "setExistingUsers": {
      const incomingRecipients = flatten(action.payload.distributionList);
      return {
        ...state,
        currentlyLoggedInUser: find(
          incomingRecipients,
          (recipient) => recipient.email === action.payload.currentLoggedInUserEmail
        ),
        existingUsers: action.payload.distributionList,
        savedUsersState: sendActionToSavedUsers(state, {
          type: "setExistingUsers",
          payload: {
            ...action.payload,
          },
        }),
      };
    }
    case "addUser": {
      const userFoundInSearchResults = find(
        flatten(state.searchResultsState.searchResultsDistributions),
        (recipient) => recipient.knownUser?.id === action.payload.userId
      );

      if (userFoundInSearchResults) {
        const addUserSavedUsersState = distributionSavedUsersReducer(state.savedUsersState, {
          type: "addUser",
          payload: { recipientBeingAdded: userFoundInSearchResults },
        });

        return {
          ...state,
          savedUsersState: addUserSavedUsersState,
          searchResultsState: sendActionToSearchUsers(state, {
            type: "updateSearchResults",
            payload: { savedUsers: addUserSavedUsersState.savedUsers },
          }),
        };
      }

      return state;
    }
    case "addAllUsers": {
      const newSavedUsersState = sendActionToSavedUsers(state, {
        type: "addUsers",
        payload: {
          recipientsBeingAdded: flatten(state.searchResultsState.searchResultsDistributions),
        },
      });
      return {
        ...state,
        savedUsersState: newSavedUsersState,
        searchResultsState: sendActionToSearchUsers(state, {
          type: "updateSearchResults",
          payload: { savedUsers: newSavedUsersState.savedUsers },
        }),
      };
    }
    case "removeDesk": {
      const removeDeskSavedUsersState = sendActionToSavedUsers(state, {
        type: "removeDesk",
        payload: action.payload.deskId,
      });

      return {
        ...state,
        savedUsersState: removeDeskSavedUsersState,
        searchResultsState: sendActionToSearchUsers(state, {
          type: "updateSearchResults",
          payload: { savedUsers: removeDeskSavedUsersState.savedUsers },
        }),
      };
    }
    case "addDesk": {
      const addDeskSavedUsersState = sendActionToSavedUsers(state, {
        type: "addDesk",
        payload: {
          deskRecipients: action.payload.recipients.map(mapDistribution.toApi),
        },
      });

      return {
        ...state,
        searchResultsState: sendActionToSearchUsers(state, {
          type: "updateSearchResults",
          payload: { savedUsers: addDeskSavedUsersState.savedUsers },
        }),
        savedUsersState: addDeskSavedUsersState,
      };
    }
    case "addsUsersAsEmail": {
      const addUsersEmailSavedUsersState = sendActionToSavedUsers(state, {
        type: "addsUsersAsEmail",
        payload: action.payload,
      });

      return {
        ...state,
        vanillaEmailSearchState: distributionVanillaEmailSearchReducer(state.vanillaEmailSearchState, {
          type: "addsUserAsEmail",
          payload: action.payload,
        }),
        savedUsersState: addUsersEmailSavedUsersState,
        searchResultsState: sendActionToSearchUsers(state, {
          type: "updateSearchResults",
          payload: { savedUsers: addUsersEmailSavedUsersState.savedUsers },
        }),
      };
    }
    case "addAbsentOwner": {
      const addAbsentOwnerState = sendActionToSavedUsers(state, {
        type: "addAbsentOwner",
        payload: action.payload,
      });

      return {
        ...state,
        vanillaEmailSearchState: distributionVanillaEmailSearchReducer(state.vanillaEmailSearchState, {
          type: "addsUserAsEmail",
          payload: [],
        }),
        savedUsersState: addAbsentOwnerState,
        searchResultsState: sendActionToSearchUsers(state, {
          type: "updateSearchResults",
          payload: { savedUsers: addAbsentOwnerState.savedUsers },
        }),
      };
    }
    case "removeUser": {
      const removeUserSavedUsersState = sendActionToSavedUsers(state, {
        type: "removeUser",
        payload: action.payload,
      });
      return {
        ...state,
        savedUsersState: removeUserSavedUsersState,
        searchResultsState: sendActionToSearchUsers(state, {
          type: "updateSearchResults",
          payload: { savedUsers: removeUserSavedUsersState.savedUsers },
        }),
      };
    }
    case "setSearchTerm": {
      return {
        ...state,
        searchResultsState: sendActionToSearchUsers(state, {
          type: "setSearchTerm",
          payload: action.payload,
        }),
      };
    }
    case "resolveSearch": {
      const resolveSearchResultsState = sendActionToSearchUsers(state, {
        type: "resolveSearch",
        payload: {
          ...action.payload,
          savedUsers: state.savedUsersState.savedUsers,
        },
      });
      return {
        ...state,
        searchResultsState: resolveSearchResultsState,
      };
    }
    case "resolveUsersByEmailAddress": {
      return {
        ...state,
        vanillaEmailSearchState: distributionVanillaEmailSearchReducer(state.vanillaEmailSearchState, {
          type: "resolveUsersReturnedBySearch",
          payload: action.payload,
        }),
        savedUsersState: sendActionToSavedUsers(state, {
          type: "resolveUsersByEmailAddress",
          payload: {
            searchUsers: action.payload,
          },
        }),
      };
    }
    case "setSavedUserRole": {
      if (action.payload.email.length === 0)
        throw new Error(`setSavedUserRole payload contains no email ${JSON.stringify(action.payload)}`);

      const newState = {
        ...state,
        searchResultsState: sendActionToSearchUsers(state, {
          type: "updateUserRole",
          payload: action.payload,
        }),
        savedUsersState: sendActionToSavedUsers(state, {
          type: "setSavedUserRole",
          payload: action.payload,
        }),
      };
      return {
        ...newState,
        validationShow: state.validationShow === "NONE" ? "NONE" : validate(newState),
      };
    }
    case "showValidation": {
      return { ...state, validationShow: validate(state) };
    }
    default: {
      return state;
    }
  }
};

export const validate = (state: DistributionState): ValidationType => {
  if (hasUsersWithNoRoleSelected(state)) {
    return "GENERAL_VALIDATION_WARNING";
  }
  if (containsMoreThanOneChartererFromDifferentCompanies(state)) {
    return "MULTIPLE_CHARTERER_WARNING";
  }
  if (containsDifferentRolesFromDifferentCompanies(state)) {
    return "SAME_COMPANY_DIFFERENT_ROLE_WARNING";
  }
  return "NONE";
};

const containsMoreThanOneChartererFromDifferentCompanies = (state: DistributionState): boolean => {
  let result = false;
  const savedUsers = flatten(state.savedUsersState.savedUsers);
  const charterers = savedUsers.filter((u) => u.role === "charterer");
  charterers.forEach((c) => {
    if (savedUsers.some((u) => u.role === "charterer" && u.knownUser?.company.id !== c.knownUser?.company.id)) {
      result = true;
    }
  });
  return result;
};

const containsDifferentRolesFromDifferentCompanies = (state: DistributionState): boolean => {
  let result = false;
  const savedUsers = flatten(state.savedUsersState.savedUsers).filter((u) => u.knownUser !== null && u.status !== "success");
  savedUsers.forEach((u) => {
    if (state.currentlyLoggedInUser?.knownUser?.company.id === u.knownUser?.company.id && u.role === "owner") {
      result = true;
    }
  });
  return result;
};
