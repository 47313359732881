import { ReactNode } from "react";
import { auth, RouteSeed } from "@/models";
import { orders } from "./orders";
import { owner } from "./owner";
import { coa } from "./coa";
import { archivedOrders } from "./archivedOrders";
import { archivedCoa } from "./archivedCoa";
import { archivedOwner } from "./archivedOwner";
import { unauthorised } from "./unauthorised";
import { captureMainTerms } from "./captureMainTerms";
import { mainTerms } from "./mainTerms";
import { offerrep } from "./offerrep";
import { handleNewOwnerUser } from "./handleNewOwnerUser";
import { arcNegotiationHashRedirect } from "./arcNegotiationHashRedirect";

export const routes = {
  comp: Root,
  sidenav: ["orders", "owner", "coa"],
  children: {
    orders,
    owner,
    coa,
    archivedOrders,
    archivedCoa,
    archivedOwner,
    captureMainTerms,
    mainTerms,
    offerrep,
    unauthorised,
    handleNewOwnerUser,
    arcNegotiationHashRedirect,
  },
  redirect,
  seaChatWidgetProps: { position: "bottomLeft" },
} as RouteSeed;

function Root(props: Props) {
  return <>{props.children}</>;
}

function redirect() {
  const companyRoleMap = auth.trade.user?._.companyRoleMap;

  if (companyRoleMap?.charterer) return "orders";
  if (companyRoleMap?.broker) return "orders";
  if (companyRoleMap?.owner) return "owner";
}

interface Props {
  children: ReactNode;
}
