import { LABEL_MAP } from "___REFACTOR___/constants";
import { Location, LocationProps } from "../Location";

class DischargeLocation extends Location {
  static label = LABEL_MAP.dischargeLocation;
  static get Editor() {
    return LocationProps.prototype.Editor;
  }
}

class DischargeLocationProps extends LocationProps {}

DischargeLocation.prototype.Props = DischargeLocationProps;
DischargeLocationProps.prototype.label = "Discharge Location";

export { DischargeLocation, DischargeLocationProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface DischargeLocation {
  Props: typeof DischargeLocationProps;
  _: DischargeLocationProps;
}
