import React, { useState, useEffect } from "react";
import styles from "negotiations/CreateNegotiation.module.scss";
import classNames from "classnames";
import { OrderDetailsITO } from "negotiations/components/createNegotiation/OrderDetailsITO";
import { MinimalConfigurationContext } from "__legacy/sharedFolder/MinimalConfigurationContext";
import LoadingSpinner from "sharedFolder/components/common/LoadingSpinner/LoadingSpinner";
import { useHistory } from "react-router-dom";
import Button from "__legacy/sharedFolder/components/common/Button/Button";
import {
  INegotiationCreateView,
  INegotiationCreate,
  IOrderITOCreate,
  IOrderCOACreate,
} from "negotiations/models/NegotiationCreateView";
import { IValidationHandler, ValidationHandler, ValidationContext } from "sharedFolder/contexts/ValidationContext";
import { getCirculateOrder } from "negotiations/services/getCirculateOrder";
import { CreateNegotiationITO } from "negotiations/CreateNegotiationITO";
import { OrderDetailsCOA } from "negotiations/components/createNegotiation/OrderDetailsCOA";
import ButtonLink from "__legacy/sharedFolder/components/common/ButtonLink/ButtonLink";
import { mapDistributeOrder } from "negotiations/services/circulateOrder";
import { distributionService } from "negotiations/services/distribution";
import ifITOElseCOA from "sharedFolder/Utilities/ifITOElseCOA";
import { IOrder } from "orders/models/IOrder";
import { useDismissableDisclaimer } from "@/components/Offerrep/Form/useDismissableDisclaimer";
import { DistributionTypeEvents, usageMetrics } from "@/services/UsageMetrics";
import { IRecipient } from "api/orders/models/IDistributionList";
interface ICreateNegotiationsProps {
  className?: string;
  ctradeUrl: string;
  order: IOrder;
  isOneOfTheRecipientsAnOwner: boolean;
  noOfUsers: number;
  isLoading: boolean;
}

/**
 * Create Negotiation.
 * Conditionally prepopulate from an order, if orderId exists in querystring
 */
export const DistributionOrderTerms: React.FC<ICreateNegotiationsProps> = (props: ICreateNegotiationsProps) => {
  const validationHandler: IValidationHandler = new ValidationHandler(); // ref????

  const { ctradeUrl, order } = props;

  const [formState, setFormState] = useState<INegotiationCreateView | null>(null);
  const [distributeButtonState, setDistributeButtonState] = useState<INegotiationCreateView | null>(null);
  const [validationMode, setValidationMode] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const { hasUserAlreadyAgreed, getDisclaimerAgreement, setDisclaimerAgreed } = useDismissableDisclaimer();
  const history = useHistory();

  useEffect(() => {
    const sub = getCirculateOrder(`${ctradeUrl}/orders`, order.id).subscribe(_setNegCreateViewState);

    function _setNegCreateViewState(state: INegotiationCreateView) {
      setFormState(state);

      sub.unsubscribe();
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    const sub = getCirculateOrder(`${ctradeUrl}/orders`, order.id).subscribe(setDistributeButtonState);
    return () => sub.unsubscribe();
  }, [order]); // eslint-disable-line

  const handleOrderDetailsChanged = (newOrderDetails: IOrderITOCreate | IOrderCOACreate) => {
    if (formState) {
      setFormState({
        ...formState,
        details: {
          ...formState.details,
          order: newOrderDetails,
        },
      });
    }
  };

  /**
   * Looks at JWT to see if user has agreed to disclaimer in the past, if not,
   * then will display dialog to allow them to accept or 'cancel' the disclaimer
   * @returns true if the user has agreed to the disclaimer, now or in the past
   */
  const hasDisclaimerBeenAgreedTo = async (): Promise<boolean> => {
    if (!hasUserAlreadyAgreed()) {
      const agreed = (): Promise<boolean> => setDisclaimerAgreed();
      return await getDisclaimerAgreement(agreed);
    }
    return true;
  };

  const submit = async () => {
    if (validationHandler.isValid && props.ctradeUrl && distributeButtonState && formState) {
      const formStateWithLatestOrderData = {
        // formState
        voySelected: formState.voySelected,
        tctSelected: formState.tctSelected,
        details: formState.details,

        // latestOrder
        invitees: distributeButtonState.invitees,
        distributionList: distributeButtonState.distributionList,
        originallySelectedTypes: distributeButtonState.originallySelectedTypes,
        updateToken: distributeButtonState.updateToken,
        teamMembers: distributeButtonState.teamMembers,
        permittedMethods: distributeButtonState.permittedMethods,
      };

      setSaving(true);
      if (await hasDisclaimerBeenAgreedTo()) {
        const distributeOrderRequestModel = mapDistributeOrder(formStateWithLatestOrderData, props.order);
        const response = await distributionService(ctradeUrl).distributeOrder(order.id, distributeOrderRequestModel);

        setSaving(false);

        if (response.ok) {
          // eslint-disable-next-line no-restricted-globals
          fireDistributionEvent(getUserCount(formStateWithLatestOrderData.distributionList));

          if (location.href.includes("coas-archive")) {
            history.push(`/coas-archive/orders/${order.id}/negotiations`);
          } else {
            history.push(`${ifITOElseCOA(displayITOorCOA, "/", "/coas/")}orders/${order.id}/negotiations`);
          }
        } else {
          console.error(`Some of the recipients did not receive the order ${response}`);
        }
      } else {
        setSaving(false);
      }
    }
    setValidationMode(true);
  };

  const getUserCount = (distributionList: IRecipient[]) => {
    const userCount = {
      absent: 0,
      owner: 0,
      broker: 0,
      charterer: 0,
      unknown: 0,
    };
    distributionList.forEach((user) => {
      if (user.email?.includes("@owner.absent.trade.sea.live")) {
        userCount.absent++;
        return;
      }
      if (user.role === "broker") {
        userCount.broker++;
        return;
      }
      if (user.role === "charterer") {
        userCount.charterer++;
        return;
      }
      if (user.role === "owner") {
        userCount.owner++;
        return;
      }
      if (user.role === "unknown") {
        userCount.unknown++;
        return;
      }
    });
    return userCount;
  };

  const fireDistributionEvent = (userCount: {
    absent: number;
    owner: number;
    broker: number;
    charterer: number;
    unknown: number;
  }) => {
    let event = DistributionTypeEvents.DISTRIBUTE_ORDER;
    if (userCount.absent > 0) {
      event = DistributionTypeEvents.DISTRIBUTE_ORDER_ABSENT_OWNER;
      if (userCount.owner > 0 || (userCount.broker > 0 && userCount.charterer > 0) || userCount.unknown > 0) {
        event = DistributionTypeEvents.DISTRIBUTE_ORDER_MIXED_USERS;
      }
    }
    usageMetrics.trackEvent(event, userCount);
  };

  const handleDetailsChanged = (newDetails: INegotiationCreate) => {
    if (formState) {
      setFormState({
        ...formState,
        details: { ...formState.details, details: newDetails },
      });
    }
  };

  const selectVoyOrTct = (check: "VOY" | "TCT") => {
    if (!formState) return;
    const toggledTct = !(formState && formState.tctSelected);
    const toggledVoy = !(formState && formState.voySelected);
    const newState = check === "VOY" ? { voySelected: toggledVoy } : { tctSelected: toggledTct };
    setFormState({
      ...formState,
      ...newState,
    });
  };

  if (!formState || !distributeButtonState) return null;

  const displayITOorCOA: "ITO" | "COA" = formState.originallySelectedTypes.some((type) => type === "COA") ? "COA" : "ITO";

  const orderDetailsITO = formState.details.order as IOrderITOCreate;
  const orderDetailsCOA = formState.details.order as IOrderCOACreate;

  const numberOfUsersInStateEqualsExpectedUsers = distributeButtonState.distributionList.length === props.noOfUsers;

  return (
    <ValidationContext.Provider
      value={{
        fieldValidityChanged: validationHandler.fieldValidityChanged,
        isFieldValid: validationHandler.isFieldValid,
      }}
    >
      <section
        id="createNegotiations"
        className={classNames(props.className, validationMode ? "validationMode" : null)}
        data-test="createNegotiationsSection"
      >
        <MinimalConfigurationContext.Provider
          value={{
            ctradeUrl,
          }}
        >
          {formState && formState.details ? (
            <>
              <div />
              {order.id && orderDetailsITO && displayITOorCOA === "ITO" && props.isOneOfTheRecipientsAnOwner && (
                <>
                  <OrderDetailsITO orderData={orderDetailsITO} onOrderChanged={handleOrderDetailsChanged} />
                  <CreateNegotiationITO
                    {...props}
                    view={formState}
                    orderId={order.id}
                    onVoyOrTctSelect={selectVoyOrTct}
                    handleDetailsChanged={handleDetailsChanged}
                  />
                </>
              )}
              {order.id && props.isOneOfTheRecipientsAnOwner && orderDetailsCOA && displayITOorCOA === "COA" && (
                <>
                  <OrderDetailsCOA orderData={orderDetailsCOA} onOrderChanged={handleOrderDetailsChanged} />
                </>
              )}
              <div className={styles.distributionActions}>
                {(props.isLoading || saving) && <LoadingSpinner />}
                <ButtonLink
                  href={`${ifITOElseCOA(displayITOorCOA, "/", "/coas/")}orders/${order.id}/distribution`}
                  router={true}
                  type="flat"
                  icon="arrow-back"
                  ariaLabel="Back Button"
                >
                  Back
                </ButtonLink>
                <Button
                  onClick={submit}
                  type="action"
                  disabled={
                    (distributeButtonState && !distributeButtonState.permittedMethods.includes("DistributeOrder")) ||
                    props.isLoading ||
                    !numberOfUsersInStateEqualsExpectedUsers ||
                    saving
                  }
                  dataTest="circulateOrderButton"
                >
                  Distribute
                </Button>
              </div>
            </>
          ) : (
            <div className={styles.spinnerContainer}>
              <LoadingSpinner loadingText="Loading..." />
            </div>
          )}
        </MinimalConfigurationContext.Provider>
      </section>
    </ValidationContext.Provider>
  );
};
