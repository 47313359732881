import { makeObservable, observable, action } from "mobx";
import { config } from "@/config";
import { SeaChatWidgetProps, defaultSeaChatWidgetProps } from "@/components";

class SeaChatWidget {
  constructor() {
    this.promise = new Promise((resolve) => {
      this.resolve = resolve;
    });
    this.props = defaultSeaChatWidgetProps;
    this.routeProps = defaultSeaChatWidgetProps;
    this.open = false;
    this.initialized = false;

    makeObservable(this, {
      props: observable.ref,
      routeProps: observable.ref,
      open: observable,
      initialized: observable,
      update: action,
      show: action,
      hide: action,
    });
  }

  update(props: SeaChatWidgetProps) {
    this.props = props;
  }

  openGroupChat = (id) => {
    this.open = true;

    this.instance.openGroupChat(id);
  };

  show = () => {
    this.open = true;
    this.instance.show();
  };

  hide = () => {
    this.open = false;
    this.instance.hide();
  };

  setup = () => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = `${config.cchatWidgetUrl}?v=${new Date().getTime()}`;
    script.id = "SeaChatWidgetScript";
    script.onload = this.onLoad;
    document.body.appendChild(script);
  };

  onLoad = async () => {
    const seaChatWidgetConfig = {
      baseUrl: config.cchatBaseUrl,
      flags: [window.SeaChatWidget.defaultFlags],
      externalCss: null,
      hideDefaultLauncher: false,
      verticalPadding: 100,
    };

    this.instance = new window.SeaChatWidget(seaChatWidgetConfig);

    await this.instance.init();

    this.initialized = true;

    this.resolve();
  };
}

export const seaChatWidget = new SeaChatWidget();

interface SeaChatWidget {
  promise: Promise<void>;
  resolve(): void;
  open: boolean;
  initialized: boolean;
  props: SeaChatWidgetProps;
  routeProps: SeaChatWidgetProps;
  instance: any;
}
