type fileIconType =
  | "file"
  | "file-pdf"
  | "file-presentation"
  | "file-text"
  | "file-image"
  | "file-email"
  | "file-sheet"
  | "file-compressed"
  | "file-audio";

export const getFileIcon = (extension: string): fileIconType => {
  if (["msg"].includes(extension)) return "file-email";
  if (["pdf"].includes(extension)) return "file-pdf";
  if (["pps", "ppt", "pptm", "pptx"].includes(extension)) return "file-presentation";
  if (["msg", "txt", "doc", "docx"].includes(extension)) return "file-text";
  if (["cad", "vsd", "vsdx", "png", "tif", "tiff", "gif", "jpeg", "jpg"].includes(extension)) return "file-image";
  if (["xls", "xlsx", "csv"].includes(extension)) return "file-sheet";
  if (["zip", "rar"].includes(extension)) return "file-compressed";
  if (["mp4"].includes(extension)) return "file-audio";

  console.error(`No icon found for ${extension}`);
  return "file";
};

export function getFileExtension(fileName: string) {
  const fileNameRegexPattern = /^.+\..+$/;
  const extensionIndicator = /^.+\.(.+)$/;
  const extensionRegex = fileName.match(extensionIndicator);
  if (!fileNameRegexPattern.test(fileName)) {
    return "";
  } else {
    return extensionRegex ? extensionRegex[1] : "";
  }
}

export function shortenFilename(fileName: string) {
  const fileNameRegexPattern = /^.+\..+$/;
  const versionIndicatorPattern = /^.+(\(\d\))\..+$/;
  let versonIndicator;
  let output;

  const fileNameWOExtension = fileName.replace(/\..+$/, "");

  // file name is less than 20 so we don't want to do anything
  if (fileNameWOExtension.length <= 20) {
    return fileName;
  }

  if (fileNameRegexPattern.test(fileName)) {
    const versionRegex = fileName.match(versionIndicatorPattern);
    // result of match: ["filename", "(versionNumber)", ...];
    versonIndicator = versionRegex ? versionRegex[1] : "";
    const extension = getFileExtension(fileName);

    output = `${fileNameWOExtension.slice(0, 20)}${versonIndicator}....${extension}`;
  } else {
    // if for whatever reason, the filename doesn't match a file name (?) just return the name
    output = fileName;
  }

  return output;
}

export function formatFileSize(bytes: number): string {
  if (bytes === 0) {
    return "0";
  }
  const k = 1024,
    dm = 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}
