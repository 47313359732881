import { makeAutoObservable, observable } from "mobx";
import { SlideoutProps } from "@/components";

class Slideout {
  constructor() {
    this.open = false;
    this.props = undefined;
    this.promise = undefined;
    this.resolve = undefined;

    makeAutoObservable(this, {
      props: observable.shallow,
    });
  }

  show = (props: SlideoutProps) => {
    this.open = true;
    this.props = props;
    this.promise = new Promise<SlideoutResult>((resolve) => {
      this.resolve = resolve;
    });

    return this.promise;
  };

  hide = async (result?: SlideoutResult) => {
    if (this.resolve) this.resolve(result || {});

    this.open = false;
    this.promise = undefined;
    this.resolve = undefined;
  };
}

export const slideout = new Slideout();

interface Slideout {
  open: boolean;
  props?: SlideoutProps;
  resolve?: Resolve;
  promise: Promise<SlideoutResult> | undefined;
}

type Resolve = (result: SlideoutResult) => void;

type SlideoutResult = { res?: any; [key: string]: any };
