import React, { useMemo, useEffect, useImperativeHandle, useState, Ref, forwardRef } from "react";
import { createPortal } from "react-dom";
import { Status } from "___REFACTOR___/models";
import { Editor, Aggrid } from "___REFACTOR___/components/common";
import "./CellEditorPopup.scss";

function CellEditorPopupComp(props: CellEditorPopup.Props, ref?: Ref<Aggrid.Cell.Editor.Comp>) {
  const { cellEditorParams, editorProps, Editor } = props;
  const { node, api, colDef, eGridCell } = cellEditorParams;
  const { rowIndex } = node;
  const { colId } = colDef;
  const portalCnt = useMemo<HTMLElement>(createPortalCnt, []);
  const keyboardEventCallbackMap = useMemo(getKeyboardEventCallbackMap, []);
  const [cellEditorValue, setCellEditorValue] = useState(cellEditorParams.value);
  const eGridCellRect = eGridCell.getBoundingClientRect();
  const top = eGridCellRect.top + Aggrid.meta.cell.padding.top;
  const left = eGridCellRect.left + Aggrid.meta.cell.padding.left;
  const width = eGridCellRect.width - Aggrid.meta.cell.padding.left;
  const style = { top, left, width };
  const cellEditorProps = { value: cellEditorValue, onChange: setCellEditorValue };

  useEffect(onMount, []);
  useEffect(setupEventListeners, []);
  useImperativeHandle(ref, getRefValue);

  return createPortal(
    <>
      <app-aggrid-cell-editor-popup-backdrop onClick={onBackdropClick} />
      <app-aggrid-cell-editor-popup style={style}>
        <Editor {...cellEditorProps} {...editorProps} autoFocus autoSelectText />
      </app-aggrid-cell-editor-popup>
    </>,
    portalCnt
  );

  function getKeyboardEventCallbackMap() {
    return {
      Escape: ignoreChangeStop,
      Enter: applyChangeStop,
      Tab: tabToNextCell,
      ShiftTab: tabToPreviousCell,
    };
  }

  function applyChangeStop() {
    api?.stopEditing();

    api?.setFocusedCell(rowIndex!, colId!);
  }

  function ignoreChangeStop() {
    api?.stopEditing(true);

    api?.setFocusedCell(rowIndex!, colId!);
  }

  function tabToNextCell() {
    api?.tabToNextCell();
  }

  function tabToPreviousCell() {
    api?.tabToPreviousCell();
  }

  function onBackdropClick() {
    api?.stopEditing();
  }

  function setupEventListeners() {
    portalCnt.addEventListener("keydown", keyDownHandler);

    return removeEventListeners;

    function keyDownHandler(e: KeyboardEvent) {
      e.stopPropagation();

      if (e.shiftKey) keyboardEventCallbackMap[`Shift${e.key}`]?.(e);
      //
      else keyboardEventCallbackMap[e.key]?.(e);
    }

    function removeEventListeners() {
      portalCnt.removeEventListener("keydown", keyDownHandler);
    }
  }

  function createPortalCnt() {
    const portalCnt = document.createElement("app-aggrid-cell-editor-popup-portal");

    window.react.services.root.append(portalCnt);

    return portalCnt;
  }

  function onMount() {
    return onUnmount;
  }

  function onUnmount() {
    portalCnt.remove();
  }

  function getRefValue() {
    return {
      getValue,
    };
  }

  function getValue() {
    return cellEditorValue;
  }
}

const CellEditorPopup = forwardRef(CellEditorPopupComp);

export { CellEditorPopup };

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

declare namespace CellEditorPopup {
  interface Props {
    Editor: Editor.Component;
    cellEditorParams: Aggrid.Cell.Editor.Params;
    editorProps?: Omit<Editor.Props, "type">;
  }
}
