import { history } from "@/history";
import { Order, dialog } from "@/models";
import { Context } from "../Orders";

export async function getOrder(model: Order | undefined, context: Context) {
  if (!model) {
    console.error("getOrder:", { model, context });

    throw new Error(`getOrder: Order model is undefined`);
  }

  const isNew = `${model.id}`.includes("$");

  if (isNew) return;

  model._.status.loading = true;
  model._.status.message = "Loading";

  const res = await model.getData();

  if (!res?.ok) {
    model._.status.type = "error";
    model._.status.title = "Order Fetch Failure";
    model._.status.dump = { res };

    dialog.show({
      status: model._.status,
      dataTest: `${context.dataTest}-order-fetch-fail`,
    });

    model._.status.loading = false;
    model._.status.message = null;

    return res;
  }

  await validateOrderPath(model.data, context.area);

  model._.status.loading = false;
  model._.status.message = null;
}

function validateOrderPath(data: Order["data"], area: Context["area"]) {
  // eslint-disable-next-line no-restricted-globals
  const url = location.href;
  const partialPath = url.split(
    /\/owner-dashboard\/|\/owner-archive\/|\/orders\/|\/orders-archive\/|\/coas\/|\/coas-archive\//g
  )[1];

  let validUrl: string | undefined;

  const isCoaOrder = data.details.originallySelectedTypes?.includes("Coa");
  const archivePostfix = "-archive";
  const isValidOrderUrl = !isCoaOrder && ((data.isArchived && area === "ito-archive") || (!data.isArchived && area === "ito"));
  const isValidCoaUrl = isCoaOrder && ((data.isArchived && area === "coa-archive") || (!data.isArchived && area === "coa"));
  const isValidOwnerUrl = area === "owner-archive" || area === "owner-dashboard";
  const isInvalidUrl = !(isValidCoaUrl || isValidOrderUrl || isValidOwnerUrl);

  if (isInvalidUrl) {
    if (data.isArchived) {
      validUrl = `${isCoaOrder ? "/coas" : "/orders"}${archivePostfix}/${partialPath}`;
    } else {
      validUrl = `${isCoaOrder ? "/coas" : "/orders"}/${partialPath}`;
    }
  }

  if (validUrl) history.replace(validUrl);
}
