// BLACKBOX
import { DismissableNotificationProvider } from "negotiations/components/Liftings/DismissableNotificationContext";
import { FetchInterceptor } from "__legacy/dashboard/components/FetchInterceptor/FetchInterceptorOld";
import { UserProvider } from "__legacy/dashboard/contexts/UserProvider";
import { MinimalConfigurationContext } from "__legacy/sharedFolder/MinimalConfigurationContext";
import { ConfigurationContext } from "__legacy/sharedFolder/ConfigurationContext";
import { OnlyLiftingsOrderNegs } from "orderNegs/ONLY_LIFTINGS_OrderNegs";

import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { config } from "@/config";
import { Context } from "../../../Orders";
import "./Liftings.scss";

function Liftings() {
  const { orderId } = useParams<StringRecord>();
  const context = useContext(Context);
  const { orderNegotiationStore } = context;
  const order = orderNegotiationStore?.orderMap?.[orderId];
  const version = order?._.version; // eslint-disable-line @typescript-eslint/no-unused-vars

  if (!order) return null;

  return (
    <div className="OrderLiftings LEGACY_CONTAINER">
      <FetchInterceptor baseUrl={config.ctradeUrl} exceptions={["/token$"]}>
        <MinimalConfigurationContext.Provider value={config}>
          <ConfigurationContext.Provider value={config}>
            <UserProvider config={config}>
              <DismissableNotificationProvider>
                <OnlyLiftingsOrderNegs config={config} />
              </DismissableNotificationProvider>
            </UserProvider>
          </ConfigurationContext.Provider>
        </MinimalConfigurationContext.Provider>
      </FetchInterceptor>
    </div>
  );
}

const Observer = observer(Liftings);

export { Observer as Liftings };
