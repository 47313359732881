import { IRecipient } from "api/orders/models/IDistributionList";
import { IUserContext } from "__legacy/dashboard/contexts/UserProvider";
import { find, groupBy, intersectionWith, isEmpty, isString } from "lodash-es";
import { updateRecipientRoles } from "../roles/roleUtilities";

export const getRecipientEmail = (recipient: IRecipient): string | undefined =>
  recipient.knownUser?.email || recipient.email || undefined;
/**
 * Returns the email addresses of recipients that are in both collections
 * @param colA Collection of recipients, saved or search
 * @param colB Collection of recipients, saved or search
 */
export const getEmailsOfUsersInBothCollections = (colA: IRecipient[], colB: IRecipient[]): string[] => {
  return intersectionWith(colA, colB, (a, b) => getRecipientEmail(a) === getRecipientEmail(b))
    .map(getRecipientEmail)
    .filter(isString);
};

/**
 * Open (isDeskOpen:true) all desks defined in deskIds
 * @param recipients
 * @param deskIds
 */
export const openDesks = (recipients: IRecipient[], deskIds: string[]): IRecipient[] => {
  return recipients.map((recipient) => ({
    ...recipient,
    additionalData: {
      ...recipient.additionalData,
      isDeskOpen: deskIds.includes(recipient.knownUser?.desk.id || ""),
    },
  }));
};

/**
 * 1. Update the 'availableRoles' based on the details of the @param currentlyLoggedInUser
 * 2. Determine which users should be disabled
 * 3. Determine which desk should be disabled
 * @param distributionListModel
 * @param currentlyLoggedInUser
 */
export const getUsersAndDesksState = (recipients: IRecipient[], currentlyLoggedInUser?: IUserContext): IRecipient[] => {
  return updateRecipientRoles(recipients, currentlyLoggedInUser);
};

export const removeUsersWithNoAvailableRoles = (recipients: IRecipient[]): IRecipient[] => {
  return recipients.filter((recipient) => recipient.availableRoles.length > 0);
};

/**
 * Will map through the savedRecipients and if a match is found in foundRecipients it will replace
 * the former with the later
 * @param foundRecipients Recipients returned from search
 * @param savedRecipients Currently displayed users in the saved section
 */
export const combineSavedAndFoundRecipients = (foundRecipients: IRecipient[], savedRecipients: IRecipient[]) => {
  return savedRecipients.map((savedRecipient) => {
    const foundUser = find(foundRecipients, function (foundRecipient: IRecipient) {
      // Does a foundRecipient have match to one of the savedRecipients
      // (that is just a vanilla email address by virtue of knownUser being null)
      return (
        getRecipientEmail(foundRecipient)?.toLowerCase() === savedRecipient.email?.toLowerCase() &&
        savedRecipient.knownUser === null
      );
    });
    return foundUser || savedRecipient;
  });
};

/**
 * Will replace contents of savedRecipients where the email address is a group email
 * address, with the expanded list of these groups
 * @param foundRecipients Recipients returned from search
 * @param savedRecipients Currently displayed users in the saved section
 */
export const combineFoundAndSavedBasedOnGroupEmail = (
  foundRecipients: IRecipient[],
  savedRecipients: IRecipient[]
): IRecipient[] => {
  const getEmailGroupedByGroupEmail = (groupEmailAddress: string): IRecipient[] => {
    /**
    * This is what a grouped list will look like
    * {
      'group_email_1@sea.live': [
          {
            email: 'member_group_1@sea.live',
          },
          {
            email: 'member_group_1@sea.live',
          }
        ],
        'group_email_2@sea.live': [
          {
            email: 'member_group_2@sea.live',
          },
          {
            email: 'member_group_2@sea.live',
          }
        ],
        null: [
          {
            email: 'not_a_member@sea.live',
          }
        ]
      }
   */
    const groupedEmailList = groupBy(foundRecipients, (recipient: IRecipient) =>
      recipient.additionalData?.groupEmailAddress?.toLowerCase()
    );
    return groupedEmailList[groupEmailAddress.toLowerCase()];
  };

  return savedRecipients.flatMap((value: IRecipient) => {
    const groupedResults = getEmailGroupedByGroupEmail(value.email || "");
    return isEmpty(groupedResults) ? [value] : groupedResults;
  });
};
