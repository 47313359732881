import React from "react";
import { FreightRate, FreightRateProps } from "@/models";
import { UnitValueEditorProps, UnitValueEditor } from "../UnitValue";

export function FreightRateEditor(props: Props) {
  props = { ...props };

  props.className = `${props.className} FreightRate`;

  function onChange(value: FreightRate) {
    const next = new FreightRate(value);

    props.onChange?.(next);
  }

  return (
    <UnitValueEditor
      {...props}
      units={FreightRateProps.prototype.units}
      defaultUnit={FreightRateProps.prototype.JSONDefaults?.unit}
      onChange={onChange}
      dataTest={`${props.dataTest}`}
    />
  );
}

interface Props extends Omit<UnitValueEditorProps, "value" | "onChange"> {
  value?: FreightRate;
  onChange?: (value: FreightRate) => void;
}

export type FreightRateEditorProps = Props;
