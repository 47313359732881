import React from "react";
import seanetIcon from "./assets/net-wht.svg";
import seacontractsIcon from "./assets/sea.contracts.wht-new.svg";
import { NegotiationTypeEvents, usageMetrics } from "@/services/UsageMetrics";
import styles from "./ExternalButton.module.scss";

interface IExternalButtonProps {
  url: string;
  appName: string;
  image: string;
  imageWidth: number;
  dataTest: string;
  className?: string;
}

interface ISeaNetButtonProps {
  seaNetUrl: string;
  cloudToken: string;
  vesselID: string;
}

interface ISeaContractsButtonProps {
  seaContractsUrl: string;
  className?: string;
}

const ExternalButton = (props: IExternalButtonProps) => (
  <a
    className={`${styles.container} ${props.className ?? ""}`}
    onClick={() => {
      if (props.appName === "SeaContracts") usageMetrics.trackEvent(NegotiationTypeEvents.VIEW_IN_CONTRACTS);
    }}
    href={props.url}
    target="_blank"
    rel="noopener noreferrer"
    aria-label={`View ${props.appName}`}
    data-test={props.dataTest}
  >
    <label className={styles.action}> VIEW IN </label>
    <img src={props.image} alt={`${props.appName} Logo`} width={props.imageWidth} />
  </a>
);

export const SeaNetButton = (props: ISeaNetButtonProps) => (
  <ExternalButton
    url={`${props.seaNetUrl}/${props.vesselID}?token=${encodeURIComponent(props.cloudToken)}`}
    appName="SeaNet"
    image={seanetIcon}
    imageWidth={60}
    dataTest="sea-net-button"
  />
);

export const SeaContracts = (props: ISeaContractsButtonProps) => (
  // wait to be given the seacontracts url
  <ExternalButton
    className={props.className}
    url={props.seaContractsUrl}
    appName="SeaContracts"
    image={seacontractsIcon}
    imageWidth={110}
    dataTest="sea-contracts-button"
  />
);
