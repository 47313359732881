import { TextField } from "@/components";
import { PrimitiveDataModel, PrimitiveDataModelProps } from "../PrimitiveDataModel";

export class String extends PrimitiveDataModel<Data> {}

class StringProps extends PrimitiveDataModelProps<Data> {}

StringProps.prototype.editor = TextField;

String.prototype._ = new StringProps();

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

export interface String {
  data: Data;
}

type Data = string;
