import { LABEL_MAP } from "___REFACTOR___/constants";
import { TradeAPI } from "___REFACTOR___/apis";
import { FreightRateEditor } from "___REFACTOR___/components/common/Editor/type/FreightRate/FreightRate";
import { UnitValue, UnitValueProps } from "../UnitValue";
import { schema } from "./schema";

class FreightRate extends UnitValue {
  static label = LABEL_MAP.freightRate;
  static get Editor() {
    return FreightRateEditor;
  }

  constructor(data?: PartialData) {
    super(data);
  }
}
class FreightRateProps extends UnitValueProps {
  get schema() {
    return schema;
  }
}

FreightRate.prototype.Props = FreightRateProps;
FreightRateProps.prototype.Editor = FreightRateEditor;
FreightRateProps.prototype.sortKey = "value";
FreightRateProps.prototype.label = LABEL_MAP.freightRate;
FreightRateProps.prototype.JSONDefaults = {
  unit: "PerMT",
};
FreightRateProps.prototype.unitArr = ["PerMT", "LumpSum"];

export { FreightRate, FreightRateProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface FreightRate extends Data {
  Props: typeof FreightRateProps;
  _: FreightRateProps;
}

interface FreightRateProps {
  sortKey: keyof Data;
  JSONDefaults: Partial<Data>;
  unitArr: TradeAPI.FreightRate.Unit[];
}

type PartialData = Partial<Data>;
type Data = TradeAPI.FreightRate;
