import React from "react";
import { ILaycanView } from "sharedFolder/Models/IDetails";
import { LaycanBaseEditor } from "../LaycanBaseEditor/LaycanBaseEditor";

interface IProps {
  id?: string;
  required: boolean | undefined;
  onChange: (laycan?: ILaycanView) => void;
  value?: ILaycanView;
  url: string;
  label?: string;
  disabled?: boolean;
  focus?: boolean;
}

// This wrapper was added solely so that we can use LaycanEditor more generically since PeriodEditor
// also needs to use it

const LaycanEditor = (props: IProps) => {
  const { disabled, id, onChange, required, url, value } = props;
  return (
    <LaycanBaseEditor id={id || "laycan"} required={required} url={url} value={value} onChange={onChange} disabled={disabled} />
  );
};

export default LaycanEditor;
