import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Icon, IconProps } from "@/components";
import "./StatusIcon.scss";

function StatusIcon(props: Props) {
  props = { ...props };

  if (!props.status?.type) return null;

  props.className = classNames(props.className, "status-icon", props.status?.type);

  const { status, ...rest } = props;

  return <Icon {...rest} name={status?.icon || ICON[props.status.type]} />;
}

const Observer = observer(StatusIcon);

export { Observer as StatusIcon };

export const statusMap = {
  accepted: { type: "success" },
  rejected: { type: "withdrawn" },
} as AnyRecord;

const ICON = {
  error: "alert-note",
  withdrawn: "withdrawn",
  warning: "warning",
  info: "alert-note",
  confirm: "confirm",
  success: "done",
  failure: "alert-note",
};

interface Props extends IconProps {
  status?: Status;
}
