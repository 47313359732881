import React, { MutableRefObject, useEffect, useRef } from "react";
import { Input } from "../../Input";
import { Container } from "../../Container";

function TextInput(props: TextInput.Props) {
  const {
    autoFocus,
    autoSelectText,
    dataTest,
    onKeyDown,
    onChange,
    placeholder,
    disabled,
    tabIndex,
    value = "",
    onFocus,
    onBlur,
    onSubmit,
  } = props;
  const domRef = useRef(null) as TextInput.DomRef;

  useEffect(onMount, []);

  return (
    <Container {...props} type="Text">
      <input
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={comboOnKeyDown}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        disabled={disabled}
        tabIndex={tabIndex}
        data-test={`${dataTest}.input`}
        ref={domRef}
        type="text"
        autoFocus={autoFocus}
      />
    </Container>
  );

  function onMount() {
    const eInput = domRef.current!;

    if (props.domRef) props.domRef.current = eInput;

    if (autoSelectText) eInput.setSelectionRange(0, eInput.value.length);

    return onUnmount;
  }

  function onUnmount() {
    if (props.domRef) props.domRef.current = null;
  }

  function comboOnKeyDown(e) {
    if (onKeyDown) onKeyDown(e);
    if (onSubmit && e.key === "Enter" && (value || value !== "")) {
      onSubmit();
    }
  }
}

export { TextInput };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

// eslint-disable-next-line no-redeclare
declare namespace TextInput {
  interface Props extends Input.Props {
    value: Value;
    onChange: (value: Value) => void;
    domRef?: DomRef;
    autoSelectText?: boolean;
  }

  type Value = string | undefined;
  type DomRef = MutableRefObject<HTMLInputElement | null>;
}
