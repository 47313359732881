import React, { useLayoutEffect, useState, createContext, useContext } from "react";
import { INegotiationView } from "../Models/Negotiation/INegotiationView";
import { getNegotiations } from "negotiations/services/getNegotiations";
import { negotiations as negotiationUpdates } from "dashboard/services/realtime/updates";
import { useConfig } from "__legacy/sharedFolder/ConfigurationContext";
import { useAuth } from "__legacy/auth-provider";

interface IProps {
  children: React.ReactNode;
  orderId: string;
}

interface INegotiationsByOrderIdContext {
  isLoading: boolean;
  hasError: boolean;
  negotiations: INegotiationView[];
}

const NegotiationsByOrderId = createContext<INegotiationsByOrderIdContext>({
  isLoading: false,
  hasError: false,
  negotiations: [],
});

function NegotiationsByOrderIdProvider(props: IProps) {
  const { children, orderId } = props;
  const { auth } = useAuth();
  const [getNegsIsLoading, setGetNegsIsLoading] = useState<boolean>(false);
  const [negotiations, setNegotiations] = useState<INegotiationView[]>([]);
  const [errorLoadingNegs, setErrorLoadingNegs] = useState<boolean>(false);
  const { ctradeUrl } = useConfig();

  useLayoutEffect(() => {
    setGetNegsIsLoading(true);
    const sub = getNegotiations(ctradeUrl, orderId, negotiationUpdates(ctradeUrl, orderId, auth.claims)).subscribe(
      (data) => {
        setErrorLoadingNegs(false); // reset error state in case previous fetch caused an error
        setNegotiations(data.negotiations.filter((neg) => neg.type !== "LFT"));
        setGetNegsIsLoading(false);
      },
      (error) => {
        setErrorLoadingNegs(true);
        setGetNegsIsLoading(false);
      }
    );

    return () => sub.unsubscribe();
  }, [ctradeUrl, orderId, auth.claims]);

  return (
    <NegotiationsByOrderId.Provider
      value={{
        isLoading: getNegsIsLoading,
        hasError: errorLoadingNegs,
        negotiations,
      }}
    >
      {children}
    </NegotiationsByOrderId.Provider>
  );
}

function useNegotiationsByOrderId() {
  const context = useContext(NegotiationsByOrderId);
  if (context === undefined) {
    throw new Error("useNegotiationsByOrderId must be within a NegotiationsByOrderIdProvider");
  }

  return context;
}

export { NegotiationsByOrderIdProvider, useNegotiationsByOrderId };
