import { ILaycan as ILaycanApi } from "../../api/models/ILaycan";
import { ILaycanView, laycanAmendDisplay } from "../Models/IDetails";
import { IMapper, withNullable } from "./map";

const map: IMapper<ILaycanApi, ILaycanView> = {
  toApi: (laycan: ILaycanView) => {
    return {
      display: laycan.display,
      shortDisplay: "",
      from: laycan.from || "",
      to: laycan.to || "",
      notes: laycan.notes,
    };
  },
  toView: (laycan: ILaycanApi) => {
    return laycanAmendDisplay({
      shortDisplay: laycan.shortDisplay,
      display: laycan.notes ? `${laycan.display} ${laycan.notes}` : laycan.display,
      from: laycan.from,
      to: laycan.to,
      notes: laycan.notes,
    });
  },
  emptyViewModel: {
    display: "",
    shortDisplay: "",
    from: "",
    to: "",
  },
};
export const mapLaycan = withNullable(map);
