import { Negotiation, dialog } from "@/models";
import { NegotiationTypeEvents, usageMetrics } from "@/services/UsageMetrics";
import { Context } from "../Orders";
import { CopyIndication } from "../Order/Negotiation";
import { CopyIndicationType, formatDataToCopyTemplate, getCopyIndicationType } from "@/utils";

export async function copyIndication(model: Negotiation, context: Context, enableTcFields: boolean) {
  const type = getCopyIndicationType(model) as CopyIndicationType;
  const forLogging = true;

  if (!model || !type) {
    console.error("copyIndication:", { model, context });

    const status = {} as Status;

    status.type = "error";
    status.title = "Copy Indication Failure";

    dialog.show({
      status,
      dataTest: `copy-indication-fail`,
    });

    return;
  }

  const { res: confirmed } = await dialog.show({
    content: CopyIndication(model, enableTcFields),
    dataTest: "copy-indication-confirm",
  });

  if (!confirmed) {
    usageMetrics.trackEvent(NegotiationTypeEvents.COPY_INDICATION_CANCELLED, {
      negotiationId: model?.id,
      negotiationType: getCopyIndicationType(model, forLogging),
    });
    return;
  }

  model._.status.loading = true;
  model._.status.message = `Copying`;

  const text = formatDataToCopyTemplate(model, type, enableTcFields);

  let res;
  try {
    await navigator.clipboard.writeText(text).then(() => {
      document.dispatchEvent(
        new CustomEvent("copyIndicationEvent", {
          detail: {
            copiedText: navigator.clipboard.readText(),
            type: getCopyIndicationType(model),
          },
        })
      );
    });

    res = { ok: true };
  } catch (err) {
    console.error("Failed to copy: ", err);
  }

  if (!res?.ok) {
    model._.status.type = "error";
    model._.status.title = "Copy Indication Failure";
    model._.status.dump = { res, entity: model };

    dialog.show({
      status: model._.status,
      dataTest: `copy-indication-fail`,
    });

    model._.status.loading = false;
    model._.status.message = null;

    return res;
  }

  model._.status.loading = false;
  model._.status.message = null;

  usageMetrics.trackEvent(NegotiationTypeEvents.COPY_INDICATION, {
    negotiationId: model?.id,
    negotiationType: getCopyIndicationType(model, forLogging),
  });

  return res;
}
