import React, { useContext } from "react";
import { observer } from "mobx-react";
import { Status } from "@/components";
import { Context } from "../../NegotiationDetail";
import { OnSubsActions } from "../OnSubs";
import "./SubsLifted.scss";

function SubsLifted() {
  const { negotiation } = useContext(Context);
  const status = {
    type: "success",
    title: "All subjects have been lifted",
    icon: "done-all",
  } as Status;

  return (
    <div className="SubsLifted">
      <Status status={status} />
      <OnSubsActions negotiation={negotiation} />
    </div>
  );
}

const Observer = observer(SubsLifted);

export { Observer as SubsLifted };
