import React, { useState, useRef, useEffect, useReducer, useContext } from "react";
import { IOfferRepViewNegotiation } from "negotiations/models/common/IOfferRep";
import styles from "./FormWrapper.module.scss";
import classnames from "classnames";
import { IOfferState } from "./IOfferState";
import { mergeOfferIntoNegotiation } from "./mergeOfferStateWithNegotiation";
import { VesselSelector } from "./VesselSelector/VesselSelector";
import { ValidationHandler, ValidationContext } from "sharedFolder/contexts/ValidationContext";
import { FirmNoticePanel } from "./FirmBid/FirmNoticePanel";
import { Messages } from "sharedFolder/components/common/Messages/Messages";
import {
  offerRepFormEnabledReducer,
  initialState as initialStateOfferRepFormEnabledReducer,
} from "../reducers/offerRepFormEnabledReducer";
import { FirmOfferTimer } from "./FirmBid/FirmOfferTimer";
import { FirmOfferSummary } from "./FirmBid/FirmSummary";
import { ExpiresOnValue } from "sharedFolder/components/common/types";
import expiryTypeReducer from "sharedFolder/reducers/expiryTypeReducer";
import { safeGet } from "negotiations/services/utils/generics";
import { uniq } from "lodash-es";
import { useOfferRepStatus } from "./useOfferRepStatus";
import { NegotiationUIStatus, NegotiationEvent } from "../reducers/offerRepStatusReducer";
import { TrackingContext } from "../../../analytics/TrackingContext";
import { DetailTypePlusOwner } from "./Negotiable/INegotiableItemProps";
import { IConfiguration } from "sharedFolder/Configuration";
import { useVesselSelector } from "./VesselSelector/useVesselSelector";
import { offerRepContext, useDetailsAndVesselsToPost } from "./useDetailsAndVesselsToPost";
import { SendIndicationFirmOfferButtons } from "./SendIndicationButtons/SendIndicationFirmOfferButtons";
import { OfferRepNegotiationContext } from "../Contexts/OfferRepNegotiationContext";
import { OfferRepNegotiableItems } from "./Negotiable/OfferRepNegotiableItems";
import { INegotiationView } from "sharedFolder/Models/Negotiation/INegotiationView";
import { NegotiableItemContainer } from "./Negotiable/NegotiableItemContainer";
import { VesselNominateProvider } from "sharedFolder/contexts/vesselnominate-context";
import { negotiationMessageReducer } from "sharedFolder/reducers/messagesReducer";
import { DisclaimerModal } from "offerRep/DisclaimerModal/DisclaimerModal";
import { useDismissableDisclaimer } from "offerRep/DisclaimerModal/useDismissableDisclaimer";
import { default as getRedirectUrl } from "../../../sharedFolder/Utilities/redirectUrl";

interface IFormWrapperProps {
  offer?: IOfferState;
  onOfferChange?: (offer: IOfferState) => void;
  config: IConfiguration;
  negotiation: IOfferRepViewNegotiation;
  offerRepContext: offerRepContext;
}

export const FormWrapper: React.FC<IFormWrapperProps> = (props: IFormWrapperProps) => {
  const [offer, setOffer] = useState<IOfferState>(props.offer || {});

  const redirectUrl = getRedirectUrl(`/postsignup/${props.negotiation.offerRepToken}`);
  const signupUrl = `${props.config.signUpUrl}&redirect=${encodeURIComponent(redirectUrl)}`;

  function onOfferChange() {
    props.onOfferChange && props.onOfferChange(offer);
  }

  useEffect(onOfferChange, [offer]);

  const negotiationMergedWithOffer = mergeOfferIntoNegotiation(props.negotiation as INegotiationView, offer);

  const { firmOrIndicationState, setFirmOrIndicationState } = useContext(OfferRepNegotiationContext);
  const [messageDisplayed, setMessageDisplayed] = useState<NegotiationUIStatus>();

  const [expiryDate, setExpiryDate] = useState<ExpiresOnValue | undefined>();

  const { negotiationUIStatus, negotiationUIStatusDispatch } = useOfferRepStatus(props.negotiation);

  const { onRemoveVessel, onAddNewVessel, onEditVessel, selectedVessels, vessels } = useVesselSelector(props.negotiation);

  const { show, onDismiss, onPresentDisclaimer } = useDismissableDisclaimer(
    props.config.ctradeUrl,
    props.negotiation.updateToken,
    props.negotiation.offerRepToken ?? "EMPTY"
  );

  const [offerRepFormState, offerRepFormStateDispatch] = useReducer(
    offerRepFormEnabledReducer,
    initialStateOfferRepFormEnabledReducer
  );

  useEffect(() => {
    setMessageDisplayed(negotiationUIStatus);
  }, [negotiationUIStatus]);

  const validationHandlerTermsForm = useRef(new ValidationHandler());
  const validationHandlerFirmBidOfferPanel = useRef(new ValidationHandler());
  const [isValid, setIsValid] = useState<boolean>(true);
  const { sendFirmOffer, sendIndication, detailTypesChanged, setDetailTypesChanged } = useDetailsAndVesselsToPost(
    props.offerRepContext,
    onPresentDisclaimer,
    () =>
      negotiationUIStatusDispatch({
        type: "RequestErrorOccured",
        payload: props.negotiation,
      }),
    selectedVessels
  );

  // tracking - log on interactions
  const tracking = useContext(TrackingContext);
  useEffect(() => {
    // treat the initial render of this component as a 'login' event
    tracking.authEvent("login", `offerRep.${props.negotiation.invitee}`);
  }, [tracking, props.negotiation]);
  // end tracking

  useEffect(() => {
    offerRepFormStateDispatch({
      type: negotiationUIStatus,
      payload: {
        detailTypesChanged,
        vessels: vessels,
        allVesselsSelectedOnTheClient: selectedVessels,
      },
    });
  }, [negotiationUIStatus, selectedVessels, detailTypesChanged, vessels]);

  useEffect(() => {
    const sub = validationHandlerTermsForm.current.onChange.subscribe((newValidity) => {
      setIsValid(newValidity);
    });
    return () => sub.unsubscribe();
  }, []);

  function onChange<T>(offerState: T, detailType: DetailTypePlusOwner) {
    const key = detailType as keyof T;
    const newStateValue = {
      ...offer,
      [detailType.toString()]: safeGet(offerState as T, key),
    };
    setDetailTypesChanged(uniq([...detailTypesChanged, detailType]));
    setOffer(newStateValue);
  }

  const update = () => {
    tracking.interaction("OfferRepIndicate");
    negotiationUIStatusDispatch({
      type: "RequestInProgress",
      payload: props.negotiation,
    });
    sendIndication(offer).then(() => {
      setDetailTypesChanged([]);
      setOffer({});
    });
  };

  const updateAndSetToFirm = (date: string) => {
    tracking.interaction("OfferRepFirmOffer");
    negotiationUIStatusDispatch({
      type: "RequestInProgress",
      payload: props.negotiation,
    });
    sendFirmOffer(offer, date).then(() => {
      setOffer({});
    });
  };

  const expiryDateHasBeenSelected = (date: string) => {
    negotiationUIStatusDispatch({
      type: "SetExpiryDateOfFirmOffer",
      payload: props.negotiation,
    });
  };

  const dispatchAction = (negotiationEvent: NegotiationEvent) => {
    negotiationUIStatusDispatch({
      type: negotiationEvent,
      payload: props.negotiation,
    });
  };

  const setExpiryDateAction = (expires: ExpiresOnValue) => {
    setExpiryDate(expires);
    expiryDateHasBeenSelected(expiryTypeReducer[expires.type](expires.value));
  };

  return (
    <>
      <div className={classnames(styles.negoTermsSection)} data-test="negotiationForm">
        <Messages<NegotiationUIStatus>
          message={messageDisplayed}
          negotiationId={props.negotiation.id}
          messageReducer={negotiationMessageReducer}
          circulatedByRole={props.negotiation.circulatedByRole}
        />
        <ValidationContext.Provider value={validationHandlerFirmBidOfferPanel.current}>
          {/*GADX - TODO Patrick's refactor can remove this */}
          {props.negotiation.status !== "Withdrawn" && (
            <FirmNoticePanel
              negotiation={props.negotiation}
              ownerNegotiation={negotiationMergedWithOffer}
              orderType={props.negotiation.type}
              dispatchAction={dispatchAction}
              displayMessage={(negotiationEvent: NegotiationUIStatus) => setMessageDisplayed(negotiationEvent)}
              setCharaterIndicationToFirmOffer={updateAndSetToFirm}
              negotiationUIStatus={negotiationUIStatus}
              maintermsOrsignUpUrl={props.negotiation.isOfferRepClaimed ? props.negotiation.ctradeOfferRepUrl! : signupUrl}
            />
          )}
        </ValidationContext.Provider>
        <ValidationContext.Provider value={validationHandlerTermsForm.current}>
          <div
            className={classnames(
              styles.contentContainer,
              !isValid && "validationMode",
              !offerRepFormState.isEnabled && styles.disabledOfferRepForm
            )}
            id="offerBidFormContainer"
          >
            <OfferRepNegotiableItems
              offer={offer}
              chartererNegotiation={props.negotiation}
              currentOwnerNegotiation={negotiationMergedWithOffer}
              onChange={(offerState, detailType) => onChange<IOfferState>(offerState, detailType)}
              onFirmOrIndicationSelected={setFirmOrIndicationState}
              negotiationUIStatus={negotiationUIStatus}
              disabled={!offerRepFormState.isEnabled}
            />

            <NegotiableItemContainer typeTitle="Vessels" lastUpdated={null}>
              <VesselNominateProvider showAdditionalNotes={false} postFixUrl={`/offerrep/${props.negotiation.offerRepToken}`}>
                <VesselSelector
                  negotiation={props.negotiation}
                  vessels={selectedVessels}
                  onAddNewVessel={onAddNewVessel}
                  onRemoveVessel={onRemoveVessel}
                  onEditVessel={onEditVessel}
                  disabled={!offerRepFormState.isEnabled}
                />
              </VesselNominateProvider>
            </NegotiableItemContainer>
          </div>

          {firmOrIndicationState === "FIRM_OFFER" && (
            <>
              <FirmOfferTimer disabled={!offerRepFormState.isEnabled} expiryDateHasBeenSelected={setExpiryDateAction} />
              <div className={classnames(styles.firmSummary, !offerRepFormState.isEnabled ? styles.disabled : "")}>
                <FirmOfferSummary
                  ownerNegotiation={mergeOfferIntoNegotiation(props.negotiation as INegotiationView, offer)}
                  orderType={props.negotiation.type}
                />
              </div>
            </>
          )}
        </ValidationContext.Provider>
      </div>
      <div className={classnames(styles.negoFooter)}>
        <div className={classnames(!offerRepFormState.isEnabled && styles.disabledOfferRepForm)}>
          <SendIndicationFirmOfferButtons
            disabled={!isValid || !offerRepFormState.isSubmitButtonEnabled || props.negotiation.status === "Withdrawn"}
            update={update}
            updateFirmBid={updateAndSetToFirm}
            expiryDateHasBeenSelected={expiryDateHasBeenSelected}
            negotiation={props.negotiation}
            negotiationUIStatus={negotiationUIStatus}
            expiryDate={expiryDate}
          />
        </div>
      </div>
      {show && props.offerRepContext === "OfferRep" && <DisclaimerModal onDismiss={onDismiss} />}
    </>
  );
};
