import { TradeAPI } from "___REFACTOR___/apis";
import { CLDDU } from "../CLDDU";
import { DataModel } from "../DataModel";

class DistributionUser extends DataModel {
  constructor(data?: PartialData) {
    super(data);
  }

  inheritFromCLDDU(clddu: CLDDU) {
    this.email = this.email || clddu.email;
    this.knownUser = this.knownUser || {};
    this.knownUser.id = this.knownUser.id || clddu.userId;
    this.knownUser.name = this.knownUser.name || clddu.userName;
    this.knownUser.email = this.knownUser.email || clddu.email;
    this.knownUser.systemUserId = this.knownUser.systemUserId || clddu.systemUserId;
    this.knownUser.companyType = this.knownUser.companyType || clddu.companyType;
    this.knownUser.company = {
      id: this.knownUser.company.id || clddu.companyId,
      name: this.knownUser.company.name || clddu.companyName,
    };
    this.knownUser.location = {
      id: this.knownUser.location.id || clddu.locationId,
      name: this.knownUser.location.name || clddu.locationName,
    };
    this.knownUser.division = {
      id: this.knownUser.division.id || clddu.divisionId,
      name: this.knownUser.division.name || clddu.divisionName,
    };
    this.knownUser.desk = {
      id: this.knownUser.desk.id || clddu.deskId,
      name: this.knownUser.desk.name || clddu.deskName,
    };
  }
}

export { DistributionUser };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface DistributionUser extends Data {}

type PartialData = Partial<Data>;
type Data = TradeAPI.Distribution.User;
