import React from "react";
import { FreightRate, FreightRateProps } from "___REFACTOR___/models";
import { UnitValueEditor } from "../UnitValue";

function FreightRateEditor(props: FreightRateEditor.Props) {
  return (
    <UnitValueEditor
      {...props}
      onChange={onChange}
      units={FreightRateProps.prototype.unitArr}
      defaultUnit={FreightRateProps.prototype.JSONDefaults?.unit}
      editorType="FreightRate"
    />
  );

  function onChange(value: FreightRate) {
    const next = new FreightRate(value);

    props.onChange?.(next);
  }
}

export { FreightRateEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace FreightRateEditor {
  export interface Props extends Omit<UnitValueEditor.Props, "value" | "onChange"> {
    value: Value;
    onChange: (value: Value) => void;
  }

  export type Value = FreightRate | undefined;
}
