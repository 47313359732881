import { LABEL_MAP } from "___REFACTOR___/constants";
import { Location, LocationProps } from "../Location";

class RedeliveryLocation extends Location {
  static label = LABEL_MAP.redeliveryLocation;
}

class RedeliveryLocationProps extends LocationProps {}

RedeliveryLocation.prototype.Props = RedeliveryLocationProps;
RedeliveryLocationProps.prototype.label = LABEL_MAP.redeliveryLocation;

export { RedeliveryLocation, RedeliveryLocationProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface RedeliveryLocation {
  Props: typeof RedeliveryLocationProps;
  _: RedeliveryLocationProps;
}
