import { action, makeObservable, observable } from "mobx";
import { debounce } from "lodash-es";
import { globallyUniqueEntityStorage, log } from "___REFACTOR___/services";
import { TradeAPI } from "___REFACTOR___/apis";
import { Aggrid } from "___REFACTOR___/components/common/Aggrid/Aggrid";
import { Order as OrderModel } from "___REFACTOR___/models/Order";
import { Negotiation as NegotiationModel } from "___REFACTOR___/models/Negotiation";
import { Status } from "../Status";

class OrderNegStore {
  constructor() {
    makeObservable(this, {
      resolveUnseenCounts: action,
      totalOrderCount: observable,
      isOrderDataArrayFullyResolved: observable,
      isOrderDataArrayEndReached: observable,
      orderArrayStatus: observable,
      negotiationArrStatus: observable,
      unseenNegCount: observable,
      unseenOrderCount: observable,
    });
  }

  OrderConstructor = OrderModel;
  NegotiationConstructor = NegotiationModel;

  orderDataQuery = new URLSearchParams();

  supportedOrderType = { Voy: true, Tct: true, Coa: true } as BoolRecord;

  orderArrayPromise = new Promise((resolve) => {
    this.resolveOrderArrayPromise = resolve;
  }) as Promise<OrderNegStore.Order[]>;
  orderArray = [] as OrderNegStore.Order[];
  orderMap = {} as MaybeRecordOf<OrderNegStore.Order>;
  negotiationArrPromise = new Promise((resolve) => {
    this.resolveNegotiationArrPromise = resolve;
  }) as Promise<OrderNegStore.Negotiation[]>;
  negArr = [] as OrderNegStore.Negotiation[];

  unseenOrderCount = 0;
  unseenNegCount = 0;

  isOrderArrayInitialized = false;
  isNegotiationArrInitialized = false;

  visibleRowCount = Math.ceil((window.innerHeight - 191) / 54);
  totalOrderCount = Infinity;
  isOrderDataArrayEndReached = false;
  isOrderDataArrayFullyResolved = false;
  orderDataArrayEndReachedPromise = new Promise((resolve) => {
    this.resolveOrderDataArrayEndReachedPromise = resolve;
  }) as Promise<void>;

  orderGridContext = new Aggrid.Context({
    EntityModel: OrderModel,
    onGridReady: this.onOrderGridReady.bind(this),
  });
  negotiationGridContext = new Aggrid.Context({
    EntityModel: NegotiationModel,
    onGridReady: this.onNegotiationGridReady.bind(this),
  });

  orderArrayStatus = new Status();
  negotiationArrStatus = new Status();

  defaultInjection = { version: 0, orderNegStore: this } as OrderNegStore.Injection;
  defaultOrderInjection = { ...this.defaultInjection, Type: "Order" } as OrderNegStore.Order.Injection;
  defaultNegotiationInjection = { ...this.defaultInjection, Type: "Negotiation" } as OrderNegStore.Negotiation.Injection;

  orderStub = { lastUpdated: "000", version: 0 } as OrderNegStore.Order;
  negotiationStub = { lastUpdated: "000", version: 0 } as OrderNegStore.Negotiation;

  async onOrderGridReady() {
    await this.orderArrayPromise;

    this.orderGridContext.api?.applyTransactionAsync({ add: [...this.orderArray] });
  }

  async onNegotiationGridReady() {
    await this.negotiationArrPromise;

    this.negotiationGridContext.api?.applyTransactionAsync({ add: [...this.negArr] });
  }

  upsertOrder(data: Partial<TradeAPI.Order>, config?: OrderNegStore.Order.UpsertConfig) {
    data.lastUpdated = data.lastUpdated || this.orderStub.lastUpdated;
    data.version = data.version || this.orderStub.version;

    if (typeof data.id !== "string" || typeof data.lastUpdated !== "string" || typeof data.version !== "number") {
      logError("OrderNegStore.upsertOrder: Missing data.id or data.lastUpdated or data.version", { data });

      throw new Error("OrderNegStore.upsertOrder: Missing data.id or data.lastUpdated or data.version");
    }

    const order = data as OrderNegStore.Order;
    const _config = { ...defaultOrderUpsertConfig, ...config };
    const { shouldUpdateModel, shouldUpdateArray, shouldAddExistingOrderToArray, forceUpdate } = _config;
    const existingOrder = this.orderMap[order.id];
    const isTheNewRef = !existingOrder || _config?.isTheNewRef;
    const isExistingOrderVersionNewer = existingOrder && order.version < existingOrder.version;
    const isExistingOrderLastUpdatedNewer = existingOrder && new Date(order.lastUpdated) < new Date(existingOrder.lastUpdated);
    let isExistingOrderNewer = isExistingOrderVersionNewer || isExistingOrderLastUpdatedNewer;

    if (forceUpdate) isExistingOrderNewer = false;

    this.resolveUnseenCountsDebounced();

    if (isTheNewRef) {
      this.orderMap[order.id] = order;
    }

    if (isTheNewRef && isExistingOrderNewer) {
      Object.assign(order, existingOrder);
    }

    if (isTheNewRef && existingOrder) {
      order._ = existingOrder._;

      if (order._.model?._.orderNegStore) {
        order._.model._.orderNegStore.order = order;
      }

      return order;
    }

    if (existingOrder && isExistingOrderNewer) {
      // DO NOTHING -- IT IS A STALE UPDATE

      return existingOrder;
    }

    if (existingOrder) {
      if (!existingOrder._) {
        logError("OrderNegStore.upsertOrder: Missing existingOrder._", { order, existingOrder, _config });

        throw new Error("OrderNegStore.upsertOrder: Missing existingOrder._");
      }

      Object.assign(existingOrder, order);

      existingOrder._.version++;

      if (shouldUpdateModel) {
        existingOrder._.model?.update(existingOrder, undefined, { ...defaultModelUpdateConfig, forceUpdate });
      }

      if (shouldAddExistingOrderToArray) {
        this.orderArray.push(existingOrder);
        this.orderGridContext.api?.applyTransactionAsync({ add: [existingOrder], addIndex: 0 });

        //
      } else if (existingOrder._.aggridContext) {
        existingOrder._.aggridContext?.api?.applyTransactionAsync({ update: [existingOrder] });

        //
      } else {
        this.orderGridContext?.api?.applyTransactionAsync({ add: [], addIndex: 0 });
      }

      return existingOrder;
    }

    if (!existingOrder) {
      order._ = { ...this.defaultOrderInjection };

      if (shouldUpdateArray && this.isOrderArrayInitialized) {
        this.orderArray.push(order);
        this.orderGridContext?.api?.applyTransactionAsync({ add: [order], addIndex: 0 });
      }

      return order;
    }

    logError("OrderNegStore.upsertOrder: Failed to upsert order", { _config, order, existingOrder, isExistingOrderNewer });

    throw new Error("OrderNegStore.upsertOrder: Failed to upsert order");
  }

  upsertNeg(data: Partial<TradeAPI.Negotiation>, config?: OrderNegStore.Negotiation.UpsertConfig) {
    data.lastUpdated = data.lastUpdated || this.negotiationStub.lastUpdated;
    data.version = data.version || this.negotiationStub.version;
    data.updateToken = data.updateToken || this.negotiationStub.updateToken;

    if (
      typeof data.id !== "string" ||
      typeof data.orderId !== "string" ||
      typeof data.lastUpdated !== "string" ||
      typeof data.version !== "number"
    ) {
      logError("OrderNegStore.upsertNeg: Missing data.id or data.orderId or data.lastUpdated or data.version", {
        data,
      });

      throw new Error("OrderNegStore.upsertNeg: Missing data.id or data.orderId or data.lastUpdated or data.version");
    }

    const negotiation = data as OrderNegStore.Negotiation;
    const _config = { ...defaultNegotiationUpsertConfig, ...config };
    const { shouldUpdateModel, shouldUpdateArray, forceUpdate } = _config;
    const existingOrder = this.orderMap[negotiation.orderId];
    const order = existingOrder || this.upsertOrder({ id: negotiation.orderId });

    if (!order._) {
      logError("OrderNegStore.upsertNeg: Missing order._", { order, negotiation, _config });

      throw new Error("OrderNegStore.upsertNeg: Missing order._");
    }

    order._.negArr = order._.negArr || [];
    order._.negotiationMap = order._.negotiationMap || {};

    const existingNegotiation = order._.negotiationMap[negotiation.id];
    const isTheNewRef = !existingNegotiation || _config?.isTheNewRef;
    const negotiationLastUpdated = negotiation.lastUpdated;
    const existingNegotiationLastUpdated = existingNegotiation?.lastUpdated || 0;
    const isExistingNegotiationLastUpdatedNewer = new Date(negotiationLastUpdated) < new Date(existingNegotiationLastUpdated);
    const negotiationVersion = negotiation.version;
    const existingNegotiationVersion = existingNegotiation?.version || 0;
    const isExistingNegotiationVersionNewer = negotiationVersion < existingNegotiationVersion;
    let isExistingNegotiationNewer = isExistingNegotiationLastUpdatedNewer || isExistingNegotiationVersionNewer;

    if (forceUpdate) isExistingNegotiationNewer = false;

    this.resolveUnseenCountsDebounced();

    if (isTheNewRef) {
      order._.negotiationMap[negotiation.id] = negotiation;
    }

    if (isTheNewRef && isExistingNegotiationNewer) {
      Object.assign(negotiation, existingNegotiation);
    }

    if (isTheNewRef && existingNegotiation) {
      negotiation._ = existingNegotiation._;

      if (negotiation._.model?._.orderNegStore) {
        negotiation._.model._.orderNegStore.neg = negotiation;
      }

      return negotiation;
    }

    if (existingNegotiation && isExistingNegotiationNewer) {
      // DO NOTHING -- IT IS A STALE UPDATE

      return existingNegotiation;
    }

    if (existingNegotiation) {
      if (!existingNegotiation._) {
        logError("OrderNegStore.upsertNeg: Missing existingNegotiation._", { existingNegotiation, order, negotiation, _config });

        throw new Error("OrderNegStore.upsertNeg: Missing existingNegotiation._");
      }

      Object.assign(existingNegotiation, negotiation);

      existingNegotiation._.version++;

      if (shouldUpdateModel) {
        existingNegotiation._.model?.update(existingNegotiation, undefined, { ...defaultModelUpdateConfig, forceUpdate });
      }

      if (existingNegotiation._.aggridContext) {
        existingNegotiation._.aggridContext?.api?.applyTransactionAsync({ update: [existingNegotiation] });

        //
      } else {
        this.orderGridContext?.api?.applyTransactionAsync({ add: [], addIndex: 0 });
      }

      return existingNegotiation;
    }

    if (!existingNegotiation) {
      negotiation._ = { ...this.defaultNegotiationInjection };

      order._.negArr.push(negotiation);
      order._.negotiationGridContext?.api?.applyTransactionAsync({ add: [negotiation], addIndex: 0 });

      if (order._.model?._.orderNegStore) {
        order._.model._.orderNegStore.negArr = [...order._.negArr];
      }

      if (shouldUpdateArray && this.isNegotiationArrInitialized) {
        this.negArr.push(negotiation);

        this.negotiationGridContext?.api?.applyTransactionAsync({ add: [negotiation], addIndex: 0 });
      }

      return negotiation;
    }

    const dump = { _config, order, negotiation, existingNegotiation, isExistingNegotiationNewer };

    logError("OrderNegStore.upsertNeg: Failed to upsert negotiation", dump);

    throw new Error("OrderNegStore.upsertNeg: Failed to upsert negotiation");
  }

  resolveUnseenCounts() {
    this.unseenNegCount = 0;
    this.unseenOrderCount = 0;

    for (let i = 0; i < this.orderArray.length; i++) {
      const order = this.orderArray[i];
      const isUnseen = this.isUnseen(order);

      if (isUnseen) this.unseenOrderCount++;
    }

    for (let i = 0; i < this.negArr.length; i++) {
      const negotiation = this.negArr[i];
      const isUnseen = this.isUnseen(negotiation);

      if (isUnseen) this.unseenNegCount++;
    }
  }

  resolveUnseenCountsDebounced = debounce(this.resolveUnseenCounts.bind(this), 333);

  isUnseen(entity: OrderNegStore.Order | OrderNegStore.Negotiation) {
    return entity.version !== globallyUniqueEntityStorage.get(entity)?.seenVersion;
  }
}

const defaultEntityUpsertConfig = {
  isTheNewRef: false,
  shouldUpdateModel: true,
  shouldUpdateArray: true,
} as OrderNegStore.UpsertConfig;

const defaultOrderUpsertConfig = {
  ...defaultEntityUpsertConfig,
} as OrderNegStore.Order.UpsertConfig;

const defaultNegotiationUpsertConfig = {
  ...defaultEntityUpsertConfig,
} as OrderNegStore.Negotiation.UpsertConfig;

const defaultModelUpdateConfig = {
  shouldUpdateOrderNegStore: false,
};

const errorDialogProps = {
  status: {
    type: "error",
    title: "Order Negotiation Store Failure",
  } as Status,
  dataTest: "order-negotiation-store-error",
};

function logError(message, dump) {
  log.error(errorDialogProps, message, dump);
}

export { OrderNegStore };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace OrderNegStore {
  export interface Order extends TradeAPI.Order {
    _: Order.Injection;
  }
  export namespace Order {
    export interface Injection extends OrderNegStore.Injection {
      readonly Type: "Order";
      model?: OrderModel;
      negArr: Negotiation[];
      negotiationMap: RecordOf<Negotiation | undefined>;
      negotiationGridContext: Aggrid.Context;
    }

    export interface UpsertConfig extends OrderNegStore.UpsertConfig {
      shouldAddExistingOrderToArray?: boolean;
    }
  }

  export interface Negotiation extends TradeAPI.Negotiation {
    _: Negotiation.Injection;
  }
  export namespace Negotiation {
    export interface Injection extends OrderNegStore.Injection {
      Type: "Negotiation";
      model?: NegotiationModel;
      order?: Order;
      negotiationGridContext?: Aggrid.Context;
    }

    export type UpsertConfig = OrderNegStore.UpsertConfig;
  }

  export interface Injection {
    version: number;
    orderNegStore: OrderNegStore;
    aggridContext?: Aggrid.Context;
    aggridRowNode?: Aggrid.Row.Node;
  }

  export interface UpsertConfig {
    isTheNewRef?: boolean;
    shouldUpdateModel?: boolean;
    shouldUpdateArray?: boolean;
    forceUpdate?: boolean;
    callee?: any;
  }
}

interface OrderNegStore {
  resolveOrderDataArrayEndReachedPromise(): void;
  resolveOrderArrayPromise(orderArray: OrderNegStore.Order[]): void;
  resolveNegotiationArrPromise(negArr: OrderNegStore.Negotiation[]): void;
}
