import { makeObservable } from "mobx";
import { tradeAPI, TradeAPI } from "___REFACTOR___/apis";
import { Status } from "___REFACTOR___/models";
import { ProformaLayout } from "___REFACTOR___/models/ProformaLayout";

export class Layout extends ProformaLayout {
  constructor(data: TradeAPI.Termset.Content.ProformaLayout) {
    super(data);

    makeObservable(this, {});
  }

  $status = {} as Status;

  $getData = async () => {
    if (!this.key) return;

    this.$status.loading = true;

    const res = await tradeAPI.getProformaLayout(this.key);

    if (res.ok) Object.assign(this, new ProformaLayout(res.data));

    this.$status.loading = false;

    return res;
  };
}
