import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { Claims } from "../ClaimsProvider";
import { connection } from "./connection";
import { filterMemberships } from "./filterMemberships";

export function order(apiUrl: string, orderId: string, claims: Claims): Observable<{ id: string; version: number }> {
  const fm = filterMemberships(claims);

  return connection(apiUrl)
    .pipe(filter((evt) => evt.eventType === "OrderUpdated"))
    .pipe(filter((evt) => evt.id === orderId))
    .pipe(fm)
    .pipe(map((event) => ({ id: event.id, version: event.version })));
}

/**
 * Realtime update messages for all negotiations within an order
 * @param apiUrl API Url
 * @param orderId Order ID
 */
export function negotiations(apiUrl: string, orderId: string, claims: Claims): Observable<{ id: string; version: number }> {
  const fm = filterMemberships(claims);
  return connection(apiUrl)
    .pipe(filter((evt) => evt.eventType === "OrderUpdated"))
    .pipe(filter((evt) => evt.id === orderId))
    .pipe(fm)
    .pipe(map((event) => ({ id: event.id, version: event.version })));
}
