import { useRef } from "react";

function useAutoChangingRef<T>(val: T) {
  const ref = useRef(val);

  ref.current = val;

  return ref;
}

export { useAutoChangingRef };
