import React from "react";
import { FieldProps } from "../../Field";
import { Container } from "../../Container";
import { SelectInput, SelectInputProps } from "@/components";

export function SelectField(props: SelectFieldProps) {
  const { className, desc, ...rest } = props;

  return (
    <Container {...rest} className={className} desc={desc}>
      <SelectInput {...rest} />
    </Container>
  );
}

export interface SelectFieldProps extends FieldProps, SelectInputProps {}
