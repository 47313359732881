import { UserContext } from "__legacy/dashboard/contexts/UserProvider";
import React from "react";
import { useVesselNominate } from "sharedFolder/contexts/vesselnominate-context";
import { MinimalConfigurationContext } from "__legacy/sharedFolder/MinimalConfigurationContext";
import { IVesselView } from "sharedFolder/Models/IDetails";
import { IVesselSearch } from "sharedFolder/Models/IVessel";
import EditorRowEmptyValue from "../EditorRowEmptyValue/EditorRowEmptyValue";
import Field from "../Field/Field";
import { formatVesselName, VesselSearchPicker } from "../VesselSearchPicker/VesselSearchPicker";

interface IVesselSearchFieldProps<T> {
  isFieldValid: (key: T) => boolean;
  updateForm: (key: T, value: any) => void;
  selectedItem?: IVesselSearch;
  isExpanded: boolean;
  setExpandedItem: (name: T) => void;
  required: boolean;
}

export const VesselSearchField = (props: IVesselSearchFieldProps<"vessel">) => {
  const { postFixUrl } = useVesselNominate();

  return (
    <Field
      id={"vessel"}
      label={"Vessel *"}
      name={"vessel"}
      edit={
        <MinimalConfigurationContext.Consumer>
          {(config) => (
            <UserContext.Consumer>
              {(user) => (
                <VesselSearchPicker
                  baseUrl={postFixUrl ? `${config.ctradeUrl}${postFixUrl}` : config.ctradeUrl}
                  id="vesselsearch"
                  division={user.divisionId}
                  onChange={(value) => props.updateForm("vessel", value)}
                  defaultVessel={props.selectedItem}
                  hideHeader={true}
                  focus={props.isExpanded}
                />
              )}
            </UserContext.Consumer>
          )}
        </MinimalConfigurationContext.Consumer>
      }
      expanded={props.isExpanded}
      expand={() => {
        props.setExpandedItem("vessel");
      }}
      display={
        <>{(props.selectedItem && vesselDisplay(props.selectedItem)) || <EditorRowEmptyValue required={props.required} />}</>
      }
      isValid={props.isFieldValid("vessel")}
    />
  );
};

const vesselDisplay = (value?: IVesselView) => {
  return value ? formatVesselName(value) : null;
};
