import { IBunkerView } from "sharedFolder/Models/IBunker";
import { bunkerFieldDisplayFormatter } from "sharedFolder/components/common/fields";
import { DataModelProps } from "___REFACTOR___/models/DataModel";
import { TradeAPI } from "___REFACTOR___/apis";
import { BunkerRedeliveryEditor } from "___REFACTOR___/components";
import { DataModel } from "___REFACTOR___/models";
import { LABEL_MAP } from "___REFACTOR___/constants";
import { schema } from "../schema";
import { Format } from "negotiations/services/utils/converters";

class BunkerRedelivery extends DataModel {
  static get Editor() {
    return BunkerRedeliveryEditor;
  }

  static label = LABEL_MAP.bunkerRedelivery;

  constructor(data?: PartialData) {
    super(data);
  }

  isValid() {
    return schema.isValidSync(this);
  }

  toString() {
    const emptyFieldText = "--";
    const { price, quantity, fuelTypes, notes } = this;
    const value = {
      price,
      quantity,
      fuelTypes,
      notes,
    } as IBunkerView;

    return bunkerFieldDisplayFormatter(value, emptyFieldText, Format.FIXED_TO);
  }

  toJSON() {
    const json = DataModel.prototype.toJSON.call(this) as TradeAPI.Bunker;

    return json;
  }
}
class BunkerRedeliveryProps extends DataModelProps {
  get Editor() {
    return BunkerRedeliveryEditor;
  }

  get label() {
    return "BunkerRedelivery";
  }

  get schema() {
    return schema;
  }
}

BunkerRedelivery.prototype.Props = BunkerRedeliveryProps;

export { BunkerRedelivery, BunkerRedeliveryProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface BunkerRedelivery extends PartialData {
  Props: typeof BunkerRedeliveryProps;
  _: BunkerRedeliveryProps;
}

type PartialData = Partial<Data>;
type Data = TradeAPI.Bunker;
