import { getNegotiationApiService } from "../../api/negotiations/Negotiations";
import { IWithdrawNegotiationDetail } from "../../api/negotiations/models";

export function withdrawNegotiation(ctradeUrl: string, orderId: string, negId: string, updateToken: string) {
  const withdrawNegotiationFunc = getNegotiationApiService(ctradeUrl, orderId).withdrawNegotiation;

  const body: IWithdrawNegotiationDetail = {
    updateToken,
  };
  return withdrawNegotiationFunc(orderId, negId, body);
}
