import { LABEL_MAP } from "___REFACTOR___/constants";
import { TradeAPI } from "___REFACTOR___/apis";
import { DemurrageEditor } from "___REFACTOR___/components/common/Editor/type/Demurrage/Demurrage";
import { UnitValue, UnitValueProps } from "../UnitValue";
import { schema } from "./schema";

class Demurrage extends UnitValue {
  static label = LABEL_MAP.demurrage;
  static get Editor() {
    return DemurrageEditor;
  }

  constructor(data?: PartialData) {
    super(data);
  }
}
class DemurrageProps extends UnitValueProps {
  get schema() {
    return schema;
  }
}

Demurrage.prototype.Props = DemurrageProps;
DemurrageProps.prototype.Editor = DemurrageEditor;
DemurrageProps.prototype.sortKey = "value";
DemurrageProps.prototype.label = LABEL_MAP.demurrage;
DemurrageProps.prototype.JSONDefaults = {
  unit: "PerDay",
};
DemurrageProps.prototype.unitArr = ["PerDay", "LumpSum"];

export { Demurrage, DemurrageProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface Demurrage extends Data {
  Props: typeof DemurrageProps;
  _: DemurrageProps;
}

interface DemurrageProps {
  sortKey: keyof Data;
  JSONDefaults: Partial<Data>;
  unitArr: TradeAPI.Demurrage.Unit[];
}

type PartialData = Partial<Data>;
type Data = TradeAPI.Demurrage;
