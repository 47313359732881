import React from "react";
import { TradeAPI } from "___REFACTOR___/apis";
import { observer } from "mobx-react";
import { companies } from "___REFACTOR___/stores";
import { SelectField, Field } from "___REFACTOR___/components";

function _CompanyEditor(props: CompanyEditor.Props) {
  const { dataTest, value } = props;

  return (
    <SelectField
      {...props}
      editorType="Company"
      value={companies.dataById[value!]}
      onChange={onChange}
      data={companies.data}
      filterKey="name"
      noDataStatus={noDataStatus}
      dataTest={`${dataTest}.value`}
      unclearable
    />
  );

  function onChange(company: TradeAPI.Company) {
    props.onChange?.(company?.companyId);
  }
}

const noDataStatus = { message: "No companies found" };

const CompanyEditor = observer(_CompanyEditor);

export { CompanyEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace CompanyEditor {
  export interface Props extends Field.Props {
    value: Value;
    onChange: (value: Value) => void;
  }

  export type Value = TradeAPI.Company.Id | undefined;
}
