import React, { MutableRefObject, ReactNode, useEffect, useRef } from "react";
import { emptyFn } from "___REFACTOR___/utils";
import { Input } from "../../Input";
import { Container } from "../../Container";
import "./Checkbox.scss";

function CheckboxInput(props: CheckboxInput.Props) {
  const resolvedProps = resolveProps();
  const { onClick, checked, xlabel, placeholder, disabled, dataTest, domEvents } = resolvedProps;
  const { onDomClick } = resolvedProps;
  const cntDomRef = useRef(null) as Container.DomRef;
  const inputDomRef = useRef(null) as CheckboxInput.DomRef;
  const childrenDomRef = useRef(null) as ReactElementDomRef;
  const resolvePropsRef = useRef(resolvedProps);

  resolvePropsRef.current = resolvedProps;

  let children = resolvedProps.children;

  if (xlabel) children = <label>{xlabel}</label>;

  if (children) children = <checkbox-input-children ref={childrenDomRef}>{children}</checkbox-input-children>;

  useEffect(onMount, []);
  useEffect(setupDomEvents, [inputDomRef.current]);

  return (
    <Container {...props} onClick={onClick} type="Checkbox" domRef={cntDomRef}>
      <input
        checked={checked}
        onChange={emptyFn}
        placeholder={placeholder}
        disabled={disabled}
        ref={inputDomRef}
        data-test={`${dataTest}.input`}
        type="checkbox"
      />
      {children}
    </Container>
  );

  function onMount() {
    if (props.domRef) props.domRef.current = inputDomRef.current;

    return onUnmount;
  }

  function onUnmount() {
    if (props.domRef) props.domRef.current = null;
  }

  function setupDomEvents() {
    if (!cntDomRef.current) return;

    if (onDomClick) cntDomRef.current.onclick = onDomClick;
  }

  function resolveProps() {
    const { value, checkedValue = true, uncheckedValue = false, domEvents } = props;
    const checked = value === checkedValue;
    const base = { checkedValue, uncheckedValue, checked, originalProps: props };

    if (domEvents) {
      return {
        ...props,
        ...base,

        onDomClick: onClickUniversal,
      };
    }

    return {
      ...props,
      ...base,

      onDomClick: undefined,
      onClick: onClickUniversal,
    };
  }

  function onClickUniversal(e: MouseEvent) {
    const { onChange, checked, checkedValue, uncheckedValue, stopPropagation, originalProps } = resolvePropsRef.current;

    if (stopPropagation) e.stopPropagation();

    onChange?.(checked ? uncheckedValue : checkedValue);

    originalProps.onClick?.(e);
  }
}

export { CheckboxInput };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

// eslint-disable-next-line no-redeclare
declare namespace CheckboxInput {
  interface Props extends Input.Props {
    value: Value;
    onChange: (value: Value) => void;
    checkedValue?: CheckedValue;
    uncheckedValue?: UncheckedValue;
    xlabel?: ReactNode;
    domRef?: DomRef;
    domEvents?: boolean;
    stopPropagation?: boolean;
    children?: ReactNode;
    indeterminate?: boolean;
  }

  type Value = any;
  type CheckedValue = any;
  type UncheckedValue = any;
  type DomRef = MutableRefObject<HTMLInputElement | null>;
}
