import React from "react";
import styles from "./FirmSentNotification.module.scss";
import { TimerWithUnits } from "sharedFolder/components/common/TimerWithUnits/TimerWithUnits";
import Icon from "__legacy/sharedFolder/components/common/Icon/Icon";
import { NegotiationUIStatus } from "../../reducers/offerRepStatusReducer";
import { CirculatedByRole, CirculatedByRoleDescription } from "negotiations/components/negotiate/Negotiation/Role";

const TimerAndMessage: React.SFC<{
  children: JSX.Element;
  negotiationUIStatus: NegotiationUIStatus;
  circulatedByRole?: CirculatedByRole;
}> = (props) => {
  const counterParty = CirculatedByRoleDescription(props.circulatedByRole);
  switch (props.negotiationUIStatus) {
    case "FirmBid":
    case "CounterFirmBid":
      return (
        <div>
          <span>The Charterer has sent you a </span>
          <span className={styles.highlight}>Firm Bid</span>.<br />
          <span> You have {props.children} to respond.</span>
        </div>
      );
    case "FirmOffer":
    case "FirmOfferCounteredWithIndication":
      return (
        <div>
          <span>You have sent the Charterer a </span>
          <span className={styles.highlight}>Firm OFFER</span>. <br />
          <span> The Charterer has {props.children} to respond.</span>
        </div>
      );
    case "FirmOfferRequested":
      return (
        <div>
          <span>The {counterParty} has requested you make your last Indication a</span>
          <span className={styles.highlight}> Firm OFFER</span>.
        </div>
      );
    default:
      return null;
  }
};

export const FirmSentNotification: React.SFC<{
  onTimeExpired: () => void;
  firmBidExpiresOn: string | null;
  firmOfferExpiresOn: string | null;
  negotiationUIStatus: NegotiationUIStatus;
  circulatedByRole?: CirculatedByRole;
}> = (props) => {
  const firmDateTime =
    props.negotiationUIStatus === "FirmBid" || props.negotiationUIStatus === "CounterFirmBid"
      ? props.firmBidExpiresOn
      : props.firmOfferExpiresOn;

  return (
    <div className={styles.container}>
      <Icon icon="info-schedule" className={styles.scheduleIcon} />
      <div className={styles.messageAndTimer}>
        <TimerAndMessage negotiationUIStatus={props.negotiationUIStatus} circulatedByRole={props.circulatedByRole}>
          <span className={styles.timer}>
            {firmDateTime && <TimerWithUnits endDateTime={firmDateTime} onTimeExpired={() => props.onTimeExpired()} />}
          </span>
        </TimerAndMessage>
      </div>
    </div>
  );
};
