import { TradeAPI } from "@/apis";
import { CompanyEditor } from "@/components";
import { DataModel, DataModelProps } from "../DataModel";

export class Company extends DataModel<Data> {
  toString() {
    return this.name;
  }
}

class CompanyProps extends DataModelProps<Data> {}

CompanyProps.prototype.editor = CompanyEditor;
CompanyProps.prototype.label = "Company";

Company.prototype._ = new CompanyProps();

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

export interface Company extends Data {
  _: CompanyProps;
}

type Data = DeepPartial<TradeAPI["Company"]>;
