import { OrderNegApiType, OrderNegType } from "../../api/negotiations/models";
import { IMapper } from "./map";

export const mapOrderNegType: IMapper<OrderNegApiType, OrderNegType> = {
  toApi: (viewModel: OrderNegType) => {
    switch (viewModel) {
      case "TCT":
        return "Tct";
      case "VOY":
        return "Voy";
      case "COA":
        return "Coa";
      case "LFT":
        return "Lft";
      default:
        throw Error("Unable to render negotiation view for view type " + viewModel);
    }
  },
  toView: (apiModel: OrderNegApiType) => {
    switch (apiModel) {
      case "Tct":
        return "TCT";
      case "Voy":
        return "VOY";
      case "Coa":
        return "COA";
      case "Lft":
        return "LFT";
      default:
        throw Error("Unable to render negotiation view for view type " + apiModel);
    }
  },
  emptyViewModel: "VOY",
};
