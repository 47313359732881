import React, { ReactNode } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import { Response } from "@/request";
import { StatusIcon } from "@/components";
import "./Status.scss";

function Status(props: Props) {
  props = { ...props };

  props.className = classNames(props.className, "generic-status");

  const { hidden, status } = props;

  if (!status || hidden) return null;

  const statusMessage = status?.type && STATUS_MESSAGE[status?.type];
  const { title, message } = status;

  let _message = message as ReactNode;

  if (statusMessage) _message = statusMessage(status);

  return (
    <div className={props.className}>
      <div className="generic-status-header">
        {!status.hideIcon && <StatusIcon status={props.status} />}
        <h2>{title}</h2>
      </div>
      <div className="generic-status-message">{_message}</div>
    </div>
  );
}

const Observer = observer(Status);

export { Observer as Status };

const STATUS_MESSAGE = {
  error: (status: Status) => {
    const { message, dump } = status;

    return (
      <div>
        <div>{message}</div>
        {Dump(dump)}
        <br />
        <div className="generic-status-error-footer">
          If you have any questions or need help, please contact <a href="mailto:support@sea.live">support@sea.live</a>
        </div>
      </div>
    );
  },
  failure: (status: Status) => {
    const { message } = status;
    return (
      <div>
        <div>{message}</div>
      </div>
    );
  },
} as StatusMessage;

function Dump(dump) {
  if (!dump) return null;

  const { entity } = dump;

  const res = dump.res as Response;

  if (!entity || !res) return null;

  return (
    <div className="generic-status-dump">
      {res && (
        <div>
          {JSON.stringify(res.data)} {res.status} {res.config?.method?.toUpperCase()} {res.config?.url}
        </div>
      )}
      {entity && (
        <div>
          {entity.data?.id} {entity.data?.name}
        </div>
      )}
    </div>
  );
}

type StatusMessage = Record<string, (message: Status["message"]) => ReactNode>;

interface Props {
  className?: string;
  status?: Status;
  hidden?: boolean;
}
