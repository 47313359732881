import React, { memo } from "react";
import { Button, ButtonMouseEvent } from "@/components";
import { Dialog as Model } from "@/models";
import { DialogProps, DialogAction } from "../Dialog";
import "./Actions.scss";

function Actions(props: ActionsProps) {
  if (!props.actions && !props.status) return null;

  props = { ...props };

  props.actions = props.actions || [];

  const statusType = props.status?.type;
  const statusActions = props.suppressStatusActions ? [] : (statusType && STATUS_ACTIONS[statusType]) || [];
  const actions = [...statusActions, ...props.actions]?.map(mapAction.bind(null, props));

  return <div className="dialog-box-actions">{actions.map(ActionHOC)}</div>;
}

function ActionHOC(props: DialogAction, i: number) {
  props.dataTest = props.label;

  const { label, ...rest } = props;

  return (
    <Button {...rest} key={i} autoFocus={true}>
      {label}
    </Button>
  );
}

function mapAction(props: ActionsProps, action: DialogAction) {
  return {
    ...action,
    onClick: async (e: ButtonMouseEvent) => {
      e.stopPropagation();

      const res = action.onClick ? await action.onClick(e) : undefined;

      props.model.resolve?.({ res, action });

      props.model.hide();
    },
  };
}

const STATUS_ACTIONS = {
  confirm: [
    { label: "Cancel", onClick: () => false },
    { label: "Confirm", variant: "action", onClick: () => true, autoFocus: true },
  ],
  error: [
    { label: "Reload", onClick: () => window.location.reload() },
    { label: "Confirm", variant: "action", autoFocus: true },
  ],
  warning: [
    { label: "Cancel", onClick: () => false },
    { label: "Confirm", variant: "action", onClick: () => true, autoFocus: true },
  ],
  info: [{ label: "Confirm", variant: "action", autoFocus: true }],
  success: [{ label: "Confirm", variant: "action", autoFocus: true }],
  failure: [{ label: "OK", variant: "action", autoFocus: true }],
} as StatusActions;

const Memo = memo(Actions);

export { Memo as Actions };

interface ActionsProps {
  model: Model;
  status?: Status;
  actions: DialogProps["actions"];
  suppressStatusActions: DialogProps["suppressStatusActions"];
}

type StatusActions = Record<string, DialogAction[]>;
