import React from "react";
import { auth } from "___REFACTOR___/services";
import { CLDDUBroker } from "___REFACTOR___/models";
import { CLDDUEditor } from "../CLDDU";

function CLDDUBrokerEditor(props: CLDDUBrokerEditor.Props) {
  let CLDDUQuery = "companyType eq 'broker'";

  if (auth.trade.user?._.companyRoleMap.broker) {
    CLDDUQuery += ` and companyId eq '${auth.trade.user.CompanyId}'`;
  }

  return (
    <CLDDUEditor
      {...props}
      editorType="CLDDUBroker"
      searchMessageMap={searchMessageMap}
      placeholder={placeholder}
      CLDDUSubset="users"
      CLDDUQuery={CLDDUQuery}
      Constructor={CLDDUBroker}
    />
  );
}

const placeholder = "Search for brokers";

const searchMessageMap = {
  initial: "Waiting for your input",
  searching: "Searching for brokers",
  noData: "No brokers found",
} as CLDDUEditor.SearchMessageMap;

export { CLDDUBrokerEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace CLDDUBrokerEditor {
  export interface Props extends CLDDUEditor.Props {
    value: Value;
    onChange: (value: Value) => void;
  }

  export type Value = CLDDUBroker | undefined;
}
