import { TradeAPI } from "@/apis";
import { DataModel, DataModelProps } from "../DataModel";

export class Desk extends DataModel<Data> {
  toString() {
    if (!this.name) return "";

    return this.name;
  }

  toGridView() {
    if (!this.name) return "";

    return this.name;
  }
}

class DeskProps extends DataModelProps<Data> {}

DeskProps.prototype.sortKey = "name";

Desk.prototype._ = new DeskProps();

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

export interface Desk extends Data {
  _: DeskProps;
}

type Data = DeepPartial<TradeAPI["Order"]["createdBy"]["desk"]>;
