import { LABEL_MAP } from "___REFACTOR___/constants";
import { TradeAPI } from "___REFACTOR___/apis";
import { CargoTypeEditor } from "___REFACTOR___/components";
import { DataModel, DataModelProps } from "../DataModel";
import { schema } from "./schema";

class CargoType extends DataModel {
  static label = LABEL_MAP.cargoType;
  static get Editor() {
    return CargoTypeEditor;
  }

  constructor(data?: PartialData) {
    super(data);
  }

  toJSON() {
    const json = DataModel.prototype.toJSON.call(this) as TradeAPI.CargoType;

    return json;
  }

  isValid() {
    return schema.isValidSync(this);
  }

  toString() {
    if (!this) return "";
    if (!this.name) return "";

    return `${this.name} ${this.notes || ""}`;
  }

  toDropdownView() {
    if (!this) return "";
    if (!this.name) return "";

    return this.name;
  }

  toGridView() {
    if (!this) return "";
    if (!this.name) return "";

    return this.name;
  }
}

class CargoTypeProps extends DataModelProps {
  get Editor() {
    return CargoTypeEditor;
  }

  get label() {
    return LABEL_MAP.cargoType;
  }

  get schema() {
    return schema;
  }
}

CargoType.prototype.Props = CargoTypeProps;

export { CargoType, CargoTypeProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface CargoType extends PartialData {
  Props: typeof CargoTypeProps;
  _: CargoTypeProps;
}

type PartialData = Partial<Data>;
type Data = TradeAPI.CargoType;
