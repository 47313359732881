import React from "react";
import { UnverifiedAccount } from "___REFACTOR___/models";
import { Icon, TextField } from "___REFACTOR___/components";

function UnverifiedAccountEditor(props: UnverifiedAccountEditor.Props) {
  const { dataTest, value } = props;

  return (
    <>
      <TextField
        {...props}
        editorType="UnverifiedAccount"
        value={value?.accountName}
        onChange={onChange}
        label="Please identify the Owner you represent"
        dataTest={`${dataTest}.value`}
      />
      <div className="OwnerAccount-info">
        <Icon icon="help" />
        Can&apos;t find the right entity? Search for and select &apos;TBN&apos; for now and let us know the details of the entity
        by clicking{" "}
        <a href="mailto:support@sea.live?subject=Please%20add%20this%20owner%20entity&amp;body=Hi%2C%0A%0AI%20tried%20to%20use%20the%20below%20entity%20in%20Sea%2Ftrade%20but%20could%20not%20find%20it.%20Could%20you%20please%20add%20them%20to%20your%20system.%0A%0AClient%20full%20name%3A%20XXXX%0ARegistered%20Address%3A%20XXXX%0ATelephone%20Number%3A%20XXXX%0AEmail%20Address%3A%20XXXX%0A%20%20">
          here
        </a>
      </div>
    </>
  );

  function onChange(accountName: TextField.Input.Value) {
    if (typeof accountName !== "string") return;

    props.onChange?.(new UnverifiedAccount({ accountName }));
  }
}
export { UnverifiedAccountEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace UnverifiedAccountEditor {
  export interface Props extends Omit<TextField.Props, "value" | "onChange"> {
    value: Value;
    onChange: (value: Value) => void;
  }

  export type Value = UnverifiedAccount | undefined;
}
