import { DISABLE_SEA_CHAT, DISABLE_SEA_CHAT_IN_DEV, PRODUCTION } from "@/config";
import { auth } from "./Auth";
import { cargoTypes } from "./CargoTypes";
import { companies } from "./Companies";
import { timezones } from "./Timezones";
import { router } from "./Router";
import { seaChatWidget } from "./SeaChatWidget";
import { unsavedChanges } from "./UnsavedChanges";
import { ownerOrderNegotiationArchiveStore, ownerOrderNegotiationStore } from "./OwnerOrderNegotiationStore";
import { orderNegotiationArchiveStore, standardOrderNegotiationStore } from "./StandardOrderNegotiationStore";
import { coaOrderNegotiationArchiveStore, coaOrderNegotiationStore } from "./COAOrderNegotiationStore";

export async function setup() {
  router.setup();

  Promise.all([cargoTypes.getData(), timezones.getData(), companies.getData()]);

  // initial fetch for order/negotiation data
  if (auth.authorised && auth.trade.user) {
    if (auth.trade.user?._.companyRoleMap.owner) {
      ownerOrderNegotiationStore.getUnarchivedNegotiationsData();
      ownerOrderNegotiationArchiveStore.getArchivedNegotiationsData();
    }

    standardOrderNegotiationStore.getUnarchivedOrderDataArray();
    coaOrderNegotiationStore.getUnarchivedOrderDataArray();
    orderNegotiationArchiveStore.getArchivedOrderDataArray();
    coaOrderNegotiationArchiveStore.getArchivedOrderDataArray();
  }

  setupUnsavedChanges();
  setupSeaChatWidget();
}

function setupSeaChatWidget() {
  if ((PRODUCTION || !DISABLE_SEA_CHAT_IN_DEV) && !DISABLE_SEA_CHAT) {
    setTimeout(seaChatWidget.setup, 5000);
  }
}

function setupUnsavedChanges() {
  window.addEventListener("beforeunload", function (e) {
    if (!unsavedChanges.exist) {
      return undefined;
    }

    const message = "In the end, it doesn't even maAaAAaatter...";

    (e || window.event).returnValue = message;

    return message;
  });
}
