import React, { useState, useEffect, useRef, CSSProperties } from "react";
import { Transition } from "react-transition-group";
import { TransitionStatus } from "react-transition-group/Transition";
import classNames from "classnames";
import { useKeepRerenderingUntilTruthy } from "@/utils";
import { Icon, Button, IconProps } from "@/components";
import "./Banner.scss";

function Banner(props: BannerProps) {
  props = { ...props };

  props.className = classNames(props.className, props.subClassName, "banner");

  const { label, version, description, icon = "info-outline", dependencies = [], isClosable = true } = props;

  const [closed, setClosed] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const { height: contentHeight = 0 } = contentRef.current?.getBoundingClientRect() || {};

  useEffect(handleDependencyChange, [version, ...dependencies]);

  useKeepRerenderingUntilTruthy(contentHeight);

  const visible = !!label && contentHeight > 0 && !closed;

  const content = (
    <>
      <Icon icon={icon} />
      <label>{label}</label>
      {description && <sea-banner-description>{description}</sea-banner-description>}
      {isClosable && <Button icon="close" onClick={hide} />}
    </>
  );

  const contentTransitionStyles = {
    entering: { minHeight: contentHeight },
    entered: {},
    exiting: { minHeight: contentHeight },
    exited: { minHeight: 0 },
  };

  return (
    <sea-banner class={props.className}>
      {props.customAnimation ? (
        <sea-banner-content>{content}</sea-banner-content>
      ) : (
        <Transition in={visible} timeout={timeout}>
          {Content}
        </Transition>
      )}
      <sea-banner-content-height-provider ref={contentRef}>{content}</sea-banner-content-height-provider>
    </sea-banner>
  );

  function Content(status: TransitionStatus) {
    const style = {
      ...defaultContentStyle,
      ...contentTransitionStyles[status],
    };
    const contentStyle = {
      position: status === "entered" ? "static" : "absolute",
    } as CSSProperties;

    return (
      <sea-banner-content-transition style={style}>
        <sea-banner-content style={contentStyle}>{content}</sea-banner-content>
      </sea-banner-content-transition>
    );
  }

  function handleDependencyChange() {
    setClosed(false);
  }

  function hide() {
    setClosed(true);

    props.onClose?.();
  }
}

function BannerManager(props: BannerProps) {
  if (props.hidden) return null;

  return <Banner {...props} className={`${props.className} ${props.customAnimation || ""}`} />;
}

const transitionDuration = 500;
const timeout = { enter: transitionDuration };

const defaultContentStyle = {
  transition: `min-height ${transitionDuration}ms ease-in-out`,
};

export { BannerManager as Banner };

/*-------------------------------------------------------------*/
/*                            TYPES                            */
/*-------------------------------------------------------------*/

export interface BannerProps {
  icon?: IconProps["icon"];
  label?: string | null | JSX.Element;
  description?: string | null;
  className?: string;
  subClassName?: string;
  version?: any;
  dependencies?: any[];
  hidden?: boolean;
  onClose?: () => void;
  isClosable?: boolean;
  customAnimation?: "fade-in-from-bottom";
}
