import { DataModel, DataModelProps } from "../DataModel";

export class PrimitiveDataModel<Data> extends DataModel<Data> {
  update(data?: Data) {
    this.data = data;

    this._.version = this._.version || 0;

    this.onUpdate(data);

    this._.version++;
  }

  toString() {
    const data = this.data as string | undefined;

    return data;
  }

  toJSON() {
    return this.data || this._.JSONDefault;
  }
}

export class PrimitiveDataModelProps<Data> extends DataModelProps<Data> {}

PrimitiveDataModelProps.prototype.sortKey = "data";

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

export interface PrimitiveDataModel<Data = DefaultData> {
  _: PrimitiveDataModelProps<Data>;
  data?: Data;
}

export interface PrimitiveDataModelProps<Data = DefaultData> {}

type DefaultData = Primitive;
