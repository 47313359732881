import React from "react";
import TextArea from "sharedFolder/components/common/TextArea/TextArea";
import { usePrevious } from "sharedFolder/Utilities/usePrevious";

interface INotesProps {
  ariaLabel?: string | "";
  className?: string;
  dataKey?: string;
  dataTest?: string;
  disabled?: boolean;
  id?: string;
  focus?: boolean;
  label?: string;
  maxLength?: number;
  name?: string;
  onChange: (newText: string) => void;
  placeholder?: string;
  required?: boolean | undefined;
  rows?: number;
  value?: string;
}

export const Notes: React.SFC<INotesProps> = (props) => {
  const prevState = usePrevious<boolean>(props.focus);
  const valid = !props.required || Boolean(props.value);

  const handleTextBlur = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const value = e.currentTarget.value.replace(/\n\s*\n\s*\n/g, "\n\n");
    props.onChange(value);
  };

  return (
    <div data-component="<Notes />" className={props.className}>
      <TextArea
        {...props}
        focus={prevState !== props.focus && props.focus}
        id={props.id}
        isValid={valid}
        label={props.label || "Notes"}
        onBlur={handleTextBlur}
        rows={5}
        value={props.value || ""}
        onChange={(evt: React.FocusEvent<HTMLTextAreaElement>) => {
          props.onChange(evt.target.value);
        }}
      />
    </div>
  );
};
