import { Negotiation, Order } from "___REFACTOR___/models";
import { userStorage } from "../UserStorage";

class EntityStorage {
  get = (entity, defaultValue?) => {
    if (!entity || !entity.id) return;
    if (entity.id.includes("$")) return;

    const { id } = entity;

    if (entity instanceof Order) return userStorage.get(`order/${id}`, defaultValue);

    if (entity instanceof Negotiation) return userStorage.get(`negotiation/${id}`, defaultValue);
  };

  set(entity: Negotiation | Order | undefined, data) {
    if (!entity || !entity.id) return;
    if (entity.id.includes("$")) return;

    const current = this.get(entity);

    if (current) {
      data = Object.assign(current, data);
    }

    if (entity instanceof Order) userStorage.set(`order/${entity.id}`, data);

    if (entity instanceof Negotiation) userStorage.set(`negotiation/${entity.id}`, data);
  }

  setVersionWhenBannerWasClosed(entity?: Negotiation | Order) {
    this.set(entity, { versionWhenBannerWasClosed: entity?.version });
  }
}

const entityStorage = new EntityStorage();

class GloballyUniqueEntityStorage {
  get = (entity: GloballyUniqueEntityStorage.Entity) =>
    userStorage.get(`guid/${entity.id}`, {}) as Partial<GloballyUniqueEntityStorage.Data>;

  set = (entity: GloballyUniqueEntityStorage.Entity, data: Partial<GloballyUniqueEntityStorage.Data>) => {
    const stored = this.get(entity);

    return userStorage.set(`guid/${entity.id}`, { ...stored, ...data });
  };

  setSeenVersion(entity) {
    this.set(entity, { seenVersion: entity.version });
  }
}

const globallyUniqueEntityStorage = new GloballyUniqueEntityStorage();

export { entityStorage, globallyUniqueEntityStorage };

/*-------------------------------------------------------------*/
/*                            TYPES                            */
/*-------------------------------------------------------------*/

declare namespace GloballyUniqueEntityStorage {
  interface Data {
    seenVersion: number;
  }

  interface Entity {
    id: string;
  }
}
