import { dialog } from "@/models";
import React, { CSSProperties, forwardRef, Ref } from "react";
import * as Common from "___REFACTOR___/components/common";
import {
  Container,
  Body,
  Footer,
  Form,
  Actions,
  Message,
  ToggleGroup,
  Tabs,
  InputHeading,
  useForm,
  errorToStatus,
} from "../Form";
import "./StandardForm.scss";

function StandardForm<Values>(config: StandardForm.Config<Values>) {
  const props = useForm(config) as StandardForm.Props<Values>;

  return <Container {...props}>{config.children}</Container>;
}

// https://formik.org/docs/overview
function useStandardForm<Values>(config: StandardForm.Config<Values>) {
  const props = useForm(config) as StandardForm.Props<Values>;

  props.getSubmitButtonProps = getSubmitButtonProps;

  props.defaultSubmitButton = <StandardSubmitButton {...getSubmitButtonProps({ label: "Submit" })} />;
  props.defaultResetButton = <StandardResetButton {...getResetButtonProps({ label: "Reset" })} />;
  props.defaultActions = <StandardDefaultActions {...props} />;
  props.defaultFooter = <StandardDefaultFooter {...props} />;

  return props;

  function getSubmitButtonProps(overrideProps?: Common.Button.Props, overrideFormProps = props) {
    const { submitForm, isSubmitDisabled, isSubmitting } = overrideFormProps;
    const configSubmitButtonProps = {
      ...config.submitButtonProps,
      ...config.getSubmitButtonProps?.(overrideProps, overrideFormProps),
    };

    return {
      ...configSubmitButtonProps,
      loading: isSubmitting,
      disabled: isSubmitDisabled,
      ...overrideProps,
      onClick: (e) => {
        overrideProps?.onClick?.(e);
        configSubmitButtonProps.onClick?.(e);
        submitForm();
      },
      variant: "action" as Common.Button.Variant,
      ...configSubmitButtonProps,
      className: [configSubmitButtonProps.className, { submitting: isSubmitting }],
    };
  }

  function getResetButtonProps(buttonProps: Common.Button.Props, overrideFormProps = props) {
    const { isSubmitting } = overrideFormProps;

    return {
      ...buttonProps,
      variant: "flat" as Common.Button.Variant,
      disabled: isSubmitting,
      onClick: resetForm,
      ...config.resetButtonProps,
      ...config.getResetButtonProps?.(buttonProps, overrideFormProps),
    };

    async function resetForm() {
      if (config?.resetButtonProps?.confirmationProps && props.dirty) {
        const { title, message, submitText, cancelText } = config.resetButtonProps.confirmationProps;
        dialog.show({
          status: { type: "warning", title: title, message: message, hideIcon: true },
          suppressStatusActions: true,
          actions: [
            { label: cancelText, variant: "flat" },
            { label: submitText, variant: "warning", onClick: () => props.resetForm() },
          ],
        });
      } else {
        props.resetForm();
      }
    }
  }
}

function StandardContainer<Values>(props: StandardContainer.Props<Values>) {
  const { children, isSubmitting } = props;
  const className = [props.className, "standard-form", { submitting: isSubmitting }];

  return <Container className={className}>{children}</Container>;
}

function StandardBody<Values>(props: StandardBody.Props<Values>) {
  const { isSubmitting, children } = props;

  return <Body disabled={isSubmitting}>{children}</Body>;
}

function StandardToggleGroup<Values>(props: StandardToggleGroup.Props<Values>) {
  const { children, direction, label } = props;

  return (
    <ToggleGroup direction={direction} label={label}>
      {children}
    </ToggleGroup>
  );
}

function StandardTabs<Values>(props: StandardTabs.Props<Values>) {
  const { children } = props;

  return <Tabs>{children}</Tabs>;
}

function StandardFooter<Values>(props: StandardFooter.Props<Values>) {
  const { shouldDisplayAllErrors } = props;

  return (
    <Footer>
      <Message hidden={!shouldDisplayAllErrors} type="error">
        Please fill in all the required fields
      </Message>
      {props.children}
    </Footer>
  );
}

function StandardActions<Values>(props: StandardActions.Props<Values>) {
  const { children } = props;

  return <Actions>{children}</Actions>;
}

function StandardResetButton<Values>(props: Common.Button.Props) {
  return <Common.Button {...props} />;
}

function StandardSubmitButton<Values>(props: Common.Button.Props) {
  return <Common.Button {...props} />;
}

function StandardDefaultContainer<Values>(props: StandardContainer.Props<Values>) {
  const { formProps, rest } = extractFormProps(props);

  return (
    <StandardContainer {...rest} {...props}>
      <StandardBody {...formProps}>{rest.children}</StandardBody>
      <StandardDefaultFooter {...formProps} />
    </StandardContainer>
  );
}

function StandardDefaultFooter<Values>(props: StandardFooter.Props<Values>) {
  return (
    <StandardFooter {...props}>
      <StandardDefaultActions {...props} />
    </StandardFooter>
  );
}

function StandardDefaultActions<Values>(props: StandardActions.Props<Values>) {
  const { defaultResetButton, defaultSubmitButton } = props;

  return (
    <StandardActions {...props}>
      {defaultResetButton}
      {defaultSubmitButton}
    </StandardActions>
  );
}
function StandardSeparator() {
  return <hr style={{ border: "none", borderBottom: "1px solid rgba(255,255,255,0.1)", margin: "16px 0px" }} />;
}

function extractFormProps<P extends StandardForm.Props<any>>(props: P) {
  const {
    values,
    errors,
    touched,
    status,
    isSubmitting,
    isValidating,
    submitCount,
    initialValues,
    initialErrors,
    initialTouched,
    initialStatus,
    handleBlur,
    handleChange,
    handleReset,
    handleSubmit,
    resetForm,
    setErrors,
    setFormikState,
    setFieldTouched,
    setFieldValue,
    setFieldError,
    setStatus,
    setSubmitting,
    setTouched,
    setValues,
    submitForm,
    validateForm,
    validateField,
    isValid,
    dirty,
    unregisterField,
    registerField,
    getFieldProps,
    getFieldMeta,
    getFieldHelpers,
    validateOnBlur,
    validateOnChange,
    validateOnMount,
    getEditorProps,
    addEventListener,
    removeEventListener,
    isInvalid,
    hasAttemptedSubmit,
    shouldDisplayAllErrors,
    isSubmitDisabled,
    getSubmitButtonProps,
    defaultSubmitButton,
    defaultResetButton,
    defaultActions,
    defaultFooter,

    ...rest
  } = props;

  return {
    formProps: {
      values,
      errors,
      touched,
      status,
      isSubmitting,
      isValidating,
      submitCount,
      initialValues,
      initialErrors,
      initialTouched,
      initialStatus,
      handleBlur,
      handleChange,
      handleReset,
      handleSubmit,
      resetForm,
      setErrors,
      setFormikState,
      setFieldTouched,
      setFieldValue,
      setFieldError,
      setStatus,
      setSubmitting,
      setTouched,
      setValues,
      submitForm,
      validateForm,
      validateField,
      isValid,
      dirty,
      unregisterField,
      registerField,
      getFieldProps,
      getFieldMeta,
      getFieldHelpers,
      validateOnBlur,
      validateOnChange,
      validateOnMount,
      getEditorProps,
      addEventListener,
      removeEventListener,
      isInvalid,
      hasAttemptedSubmit,
      shouldDisplayAllErrors,
      isSubmitDisabled,
      getSubmitButtonProps,
      defaultSubmitButton,
      defaultResetButton,
      defaultActions,
      defaultFooter,
    },
    rest,
  };
}

const create = {
  aggrid: {
    cell: {
      editor: function <Values>(params: Create.Cell.Editor.Params<Values>) {
        return forwardRef((cellEditorParams: Common.Aggrid.Cell.Editor.Params, ref: Ref<Common.Aggrid.Cell.Editor.Comp>) => {
          const { editorPropsGetter, Editor } = params;
          const editorProps = editorPropsGetter(cellEditorParams);

          return (
            <Common.Aggrid.CellEditorPopup
              Editor={Editor}
              cellEditorParams={cellEditorParams}
              editorProps={editorProps}
              ref={ref}
            />
          );
        });
      },
    },
  },
};

StandardForm.Container = StandardContainer;
StandardForm.Body = StandardBody;
StandardForm.Footer = StandardFooter;
StandardForm.Actions = StandardActions;
StandardForm.ResetButton = StandardResetButton;
StandardForm.SubmitButton = StandardSubmitButton;
StandardForm.DefaultContainer = StandardDefaultContainer;
StandardForm.DefaultFooter = StandardDefaultFooter;
StandardForm.DefaultActions = StandardDefaultActions;
StandardForm.ToggleGroup = StandardToggleGroup;
StandardForm.Tabs = StandardTabs;
StandardForm.Separator = StandardSeparator;
StandardForm.create = create;
StandardForm.use = useStandardForm;

export {
  StandardForm,
  useStandardForm,
  StandardContainer as Container,
  StandardBody as Body,
  StandardFooter as Footer,
  StandardActions as Actions,
  StandardResetButton as ResetButton,
  StandardSubmitButton as SubmitButton,
  StandardDefaultContainer as DefaultContainer,
  StandardDefaultFooter as DefaultFooter,
  StandardDefaultActions as DefaultActions,
  StandardToggleGroup as ToggleGroup,
  InputHeading,
  StandardTabs as Tabs,
  StandardSeparator as Separator,
  create,
  errorToStatus,
};

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace StandardForm {
  interface Config<Values> extends Form.Config<Values> {
    submitButtonProps?: Common.Button.Props;
    getSubmitButtonProps?(buttonProps?: Common.Button.Props, formProps?: Props<Values>): Common.Button.Props;
    resetButtonProps?: Common.Button.ResetButtonProps;
    getResetButtonProps?(buttonProps?: Common.Button.Props, formProps?: Props<Values>): Common.Button.Props;
  }

  interface Props<Values> extends Form.Props<Values> {
    getSubmitButtonProps(buttonProps?: Common.Button.Props, formProps?: Props<Values>): SubmitButtonProps;
    addEventListener(
      type: Form.Event.Type,
      listener: Form.Event.Listener<Values, Props<Values>>
    ): Form.Event.RemoveThisEventListener;
    removeEventListener: Form.Event.RemoveEventListener<Values>;

    defaultFooter: JSX.Element;
    defaultActions: JSX.Element;
    defaultSubmitButton: JSX.Element;
    defaultResetButton: JSX.Element;
  }

  interface SubmitButtonProps extends Common.Button.Props {
    onClick: NonNullable<Common.Button.Props["onClick"]>;
  }

  type EditorProps<Value> = Form.EditorProps<Value>;
}

declare namespace StandardContainer {
  interface Props<Values> extends StandardForm.Props<Values>, Container.Props {}
}

declare namespace StandardBody {
  interface Props<Values> extends StandardForm.Props<Values>, Body.Props {}
}

declare namespace StandardToggleGroup {
  interface Props<Values> extends StandardForm.Props<Values>, ToggleGroup.Props {}
}

declare namespace StandardTabs {
  interface Props<Values> extends StandardForm.Props<Values>, Tabs.Props {}
}

declare namespace StandardFooter {
  interface Props<Values> extends StandardForm.Props<Values>, Footer.Props {}
}

declare namespace StandardActions {
  interface Props<Values> extends StandardForm.Props<Values>, Actions.Props {}
}

declare namespace Create {
  namespace Cell {
    namespace Editor {
      interface Params<Values> {
        editorPropsGetter(cellEditorParams: Common.Aggrid.Cell.Editor.Params): StandardForm.EditorProps<Values>;
        Editor: Common.Editor.Component;
      }
    }
  }
}
