import { DataModel, DataModelProps } from "../DataModel";

export class ArrayDataModel<Data> extends DataModel<Data> {
  update(data?: Data) {
    this.data = data;

    this._.version = this._.version || 0;

    this.onUpdate(data);

    this._.version++;
  }

  toJSON() {
    return this.data || this._.JSONDefault;
  }
}

export class ArrayDataModelProps<Data> extends DataModelProps<Data> {}

ArrayDataModelProps.prototype.sortKey = "data";

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

export interface ArrayDataModel<Data = DefaultData> {
  _: ArrayDataModelProps<Data>;
  data?: Data;
}

export interface ArrayDataModelProps<Data = DefaultData> {}

type DefaultData = DeepPartial<any[]>;

export type { DeepPartialExceptForPrimitiveArraysAndDataModelKeys } from "../DataModel";
