import React from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useSimpleEffect } from "@/utils";
import { Button } from "@/components";
import { seaChatWidget } from "@/models";
import { usageMetrics, SeaChatEvents } from "@/services/UsageMetrics";
import "./SeaChatWidget.scss";

function SeaChatWidget() {
  const props = { ...seaChatWidget.props, ...seaChatWidget.routeProps };
  const { open } = seaChatWidget;
  const { position } = props;
  const className = classNames("sea-chat-widget", position);
  const containerClassName = classNames("sea-chat-widget-container", position);

  useSimpleEffect(onPositionChange, [position]);
  useSimpleEffect(onVisibilityChange, [open]);

  async function show() {
    usageMetrics.trackEvent(SeaChatEvents.SEA_CHAT_BTN_LEFT_SIDE_MENU);
    seaChatWidget.show();
    seaChatWidget.instance.container.className = containerClassName;
  }

  async function hide() {
    seaChatWidget.hide();
  }

  async function onVisibilityChange() {
    if (!seaChatWidget.instance?.container) return;

    seaChatWidget.instance.container.className = containerClassName;
  }

  async function onPositionChange() {
    if (!seaChatWidget.instance?.container) return;

    seaChatWidget.instance.container.className = containerClassName;
  }

  return seaChatWidget.initialized ? (
    <>
      <Button onClick={show} className={className} icon="chat" data-key="SeaChatWidget" />
      <div className="sea-chat-widget-backdrop" onClick={hide} hidden={!open} />
    </>
  ) : null;
}

const Observer = observer(SeaChatWidget);

export { Observer as SeaChatWidget };

export const defaultSeaChatWidgetProps = {
  position: "bottomLeft",
} as SeaChatWidgetProps;

export interface SeaChatWidgetProps {
  position?: "topLeft" | "topRight" | "bottomRight" | "bottomLeft";
}
