import React, { Component } from "react";
import styles from "./MoreLessChevronButton.module.scss";
import { Tag } from "sharedFolder/components/common/Tag/Tag";
import Button from "__legacy/sharedFolder/components/common/Button/Button";

interface MoreLessChevronButtonProps {
  otherActions?: JSX.Element;
  status?: string;
  closeText: string;
  openText: string;
  onToggle?: (isOpen: boolean) => void;
}

export default class MoreLessChevronButton extends Component<
  MoreLessChevronButtonProps,
  { displayContents: boolean },
  { children: JSX.Element }
> {
  constructor(props: MoreLessChevronButtonProps) {
    super(props);
    this.state = { displayContents: false };
    this.onToggle = this.onToggle.bind(this);
  }

  onToggle() {
    if (this.props.onToggle) {
      this.props.onToggle(!this.state.displayContents);
    }
    this.setState({ displayContents: !this.state.displayContents });
  }

  render() {
    return (
      <div className={styles.chevronPointer}>
        <Button
          dataKey="ShowMoreLessButton"
          type="flat"
          icon={this.state.displayContents ? "chevron-down" : "chevron-right"}
          onClick={this.onToggle}
        >
          {this.state.displayContents ? this.props.closeText : this.props.openText}
          {this.props.status && (
            <div className={styles.statusTagWrapper}>
              <Tag tag="NEW" />
            </div>
          )}
        </Button>
        {this.props.otherActions && <>{this.props.otherActions}</>}
        {this.state.displayContents && <div className={styles.moreDetailsContent}>{this.props.children}</div>}
      </div>
    );
  }
}
