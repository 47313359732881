import React from "react";
import styles from "./CreateNegotiationSectionHeader.module.scss";
import Heading from "sharedFolder/components/common/Heading/Heading";

interface ICreateNegotiationSectionHeaderProps {
  header: string;
}

export const CreateNegotiationSectionHeader: React.SFC<ICreateNegotiationSectionHeaderProps> = (
  props: ICreateNegotiationSectionHeaderProps
) => {
  return (
    <div className={styles.headerRow}>
      <Heading className={styles.headerRowHeading}>{props.header}</Heading>
      <div className={styles.labels}>
        <span className={styles.label}>Negotiable</span>
        <span className={styles.label}>Exclude</span>
      </div>
    </div>
  );
};
