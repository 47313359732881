import React, { useEffect, useRef, useMemo } from "react";
import { observer } from "mobx-react";
import classNames, * as ClassNames from "classnames";
import { Transition } from "react-transition-group";
import { useKeepRerenderingUntilTruthy, useKeepTruthyValue } from "___REFACTOR___/utils";
import { Icon } from "___REFACTOR___/components";
import { Status } from "___REFACTOR___/models";
import * as Services from "___REFACTOR___/services";
import "./Slideout.scss";

function SlideoutComponent(instanceProps: Slideout.InstanceProps) {
  const { service } = instanceProps;
  const { props, isOpen } = service;
  const { children, className, dataTest } = useKeepTruthyValue(props, 500) || {};
  const keyboardEventCallbackMap = useMemo(getKeyboardEventCallbackMap, []);
  const cntRef = useRef() as ReactElementDomRef;

  useEffect(setupEventListeners, [cntRef.current]);
  useKeepRerenderingUntilTruthy(props ? cntRef.current : true);

  return (
    <Transition {...TRANSITION_PROPS} in={isOpen}>
      {(status) => {
        if (status === "exited") return null;

        return (
          <>
            <app-slideout-backdrop class={classNames(className, status)} onClick={close} />
            <app-slideout class={status} data-test={dataTest} ref={cntRef} tabIndex={0}>
              {children}
              <Icon className="app-slideout-close-btn" icon="close" onClick={close} />
            </app-slideout>
          </>
        );
      }}
    </Transition>
  );

  function getKeyboardEventCallbackMap() {
    return {
      Escape: close,
    };
  }

  function close() {
    service.close();
  }

  function setupEventListeners() {
    cntRef.current?.focus();

    cntRef.current?.addEventListener("keydown", keyDownHandler);

    return removeEventListeners;

    function keyDownHandler(e: KeyboardEvent) {
      e.stopPropagation();

      if (e.shiftKey) keyboardEventCallbackMap[`Shift${e.key}`]?.(e);
      //
      else keyboardEventCallbackMap[e.key]?.(e);
    }

    function removeEventListeners() {
      cntRef.current?.removeEventListener("keydown", keyDownHandler);
    }
  }
}

const TRANSITION_PROPS = {
  timeout: { exit: 500 },
};

const Slideout = observer(SlideoutComponent);

export { Slideout };

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

declare namespace Slideout {
  interface InstanceProps {
    service: Services.Slideout;
  }

  interface Props {
    className?: ClassNames.Argument;
    status?: Status;
    children?: React.ReactNode;
    dataTest?: string;
  }
}
