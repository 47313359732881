import { LABEL_MAP } from "___REFACTOR___/constants";
import { format } from "___REFACTOR___/utils";
import { TradeAPI } from "___REFACTOR___/apis";
import { NominationsEditor } from "___REFACTOR___/components";
import { DataModel, DataModelProps } from "../DataModel";
import { schema } from "./schema";

class Nominations extends DataModel {
  constructor(data: PartialData) {
    super(data);
  }

  toJSON() {
    const json = DataModel.prototype.toJSON.call(this) as TradeAPI.Nominations;

    return json;
  }

  isValid() {
    return schema.isValidSync(this);
  }

  toString() {
    if (
      typeof this.noticePerLaycan !== "number" ||
      typeof this.laycanSpread !== "number" ||
      typeof this.finalLaycanNotice !== "number"
    )
      return "";

    const notes = this.notes || "";

    return `${format.number.display(this.noticePerLaycan)}d  ${format.number.display(this.laycanSpread)}d  ${
      this.finalLaycanNotice
    }d ${notes}`;
  }

  toGridView() {
    if (
      typeof this.noticePerLaycan !== "number" ||
      typeof this.laycanSpread !== "number" ||
      typeof this.finalLaycanNotice !== "number"
    )
      return "";

    return `${format.number.display(this.noticePerLaycan)}d  ${format.number.display(this.laycanSpread)}d  ${
      this.finalLaycanNotice
    }d`;
  }
}

class NominationsProps extends DataModelProps {
  get Editor() {
    return NominationsEditor;
  }

  get schema() {
    return schema;
  }
}

Nominations.prototype.Props = NominationsProps;
NominationsProps.prototype.label = LABEL_MAP.nominations;

export { Nominations, NominationsProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface Nominations extends Data {
  Props: typeof NominationsProps;
  _: NominationsProps;
}

type PartialData = Partial<Data>;
type Data = TradeAPI.Nominations;
