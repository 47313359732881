import React, { useContext, useMemo, createContext, useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { Negotiation as NegotiationModel } from "@/models";
import { Overlay } from "@/components";
import { Context as OrderContext } from "../../../Orders";

function Negotiation(props: Props) {
  const { negotiationId, orderId } = useParams<StringRecord>();
  const { orderNegotiationStore, routes } = useContext(OrderContext);
  const order = orderId ? orderNegotiationStore?.upsertOrderModel({ id: orderId }) : undefined;
  const negotiation = order?.upsertNegotiation({ id: negotiationId });
  const context = useMemo(createContext, [negotiation?._.version, negotiationId]);
  const status = useMemo(getStatus, [orderNegotiationStore?.negotiationArrayStatus.loading]);
  const stage = negotiation?.getStage();

  useEffect(navigateToStatus, [stage]);

  setSeenVersion();

  return (
    <Context.Provider value={context}>
      {props.children}
      <Overlay status={status} />
    </Context.Provider>
  );

  async function setSeenVersion() {
    if (!negotiation) return;

    orderNegotiationStore?.setSeenVersion(negotiation);
  }

  function createContext() {
    return { negotiation, version: negotiation?._.version };
  }

  function getStatus() {
    return [
      orderNegotiationStore?.negotiationArrayStatus.message === "Fetching Negotiations" && {
        loading: true,
        message: "Loading",
      },
      negotiation?._.status,
    ];
  }

  function navigateToStatus() {
    if (!negotiation?.orderId) return;

    const isLifting = negotiation.type === "Lft";
    const isWithdrawn = negotiation.status === "Withdrawn";
    const isLiftingAndWithdrawn = isLifting && isWithdrawn;
    const isBeyondInitialTermsAndNotWithdrawn = negotiation.isBeyondInitialTerms() && !isWithdrawn;

    if (isLiftingAndWithdrawn) return;

    let routeName;

    if (isLifting) routeName = "lifting";
    else if (isBeyondInitialTermsAndNotWithdrawn) routeName = "negotiationMainTerms";
    else routeName = "negotiationInitialTerms";

    routes.order[routeName]?.replace(
      {
        orderId: negotiation.orderId,
        negotiationId: negotiation.id,
      },
      { ignoreUnsavedChanges: true }
    );
  }
}

// @ts-ignore
export const Context = createContext<Context>(null);

const Observer = observer(Negotiation);

export { Observer as Negotiation };

interface Props {
  children?: React.ReactNode;
}

interface Context {
  negotiation?: NegotiationModel;
}
