import { TradeAPI } from "@/apis";
import { OwnerAccountEditor } from "@/components";
import { DataModelProps } from "../DataModel";
import { Account } from "../Account";

export class OwnerAccount extends Account {}

class OwnerAccountProps extends DataModelProps<Data> {}

OwnerAccountProps.prototype.editor = OwnerAccountEditor;

OwnerAccount.prototype._ = new OwnerAccountProps();

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

type Data = DeepPartial<TradeAPI["Account"]>;
