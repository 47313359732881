import React from "react";
import classNames from "classnames";
import * as ClassNames from "classnames";
import { Status } from "___REFACTOR___/models";
import "./Message.scss";

function Message(props: Message.Props) {
  let { className, type, status, hidden, ...rest } = props;
  type = type || status?.type;

  if (hidden) return null;

  className = classNames(className, type);

  return <app-form-message class={className} {...rest} />;
}

export { Message };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace Message {
  export interface Props extends Omit<ReactHTMLFormProps, "className">, Omit<Status, "title"> {
    className?: ClassNames.Argument;
    status?: Status;
  }

  type ReactHTMLFormProps = React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLDivElement>, HTMLDivElement>;
}
