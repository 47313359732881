import React from "react";
import Slider, { SliderProps } from "@material-ui/core/Slider";
import { Input } from "../../Input";
import { Container } from "../../Container";
import "./Range.scss";

function RangeInput(props: RangeInput.Props) {
  const { min, max, value, step } = props;

  return (
    <Container {...props} type="Range">
      <Slider value={value} onChange={onChange} min={min} max={max} step={step} aria-labelledby="range-slider" />
    </Container>
  );

  function onChange(e, value) {
    props.onChange?.(value);
  }
}

export { RangeInput };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

// eslint-disable-next-line no-redeclare
declare namespace RangeInput {
  export interface Props extends Input.Props {
    value: Value;
    onChange: (value: Value) => void;
    min?: SliderProps["min"];
    max?: SliderProps["max"];
    step?: SliderProps["step"];
  }

  export type Value = SliderProps["value"];
}
