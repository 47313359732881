import { stringArrToBoolMap } from "___REFACTOR___/utils";
import { TradeAPI } from "___REFACTOR___/apis";
import { auth } from "___REFACTOR___/services";
import { DataModel, DataModelProps } from "../DataModel";

class User extends DataModel {
  constructor(data?: PartialData) {
    super(data);

    this._.companyRoleMap = stringArrToBoolMap(this.CompanyRoles);
  }

  toString() {
    if (auth.trade.user?.SystemUserId === this.SystemUserId) return "Me";

    return this.UserName;
  }

  getUserId(): string | null {
    return this.memberships?.find((m) => m.level === "user")?.id ?? null;
  }
}

class UserProps extends DataModelProps {}

User.prototype.Props = UserProps;

export { User, UserProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface User extends Data {
  Props: typeof UserProps;
  _: UserProps;
}

interface UserProps {
  companyRoleMap: {
    owner: true;
    charterer: true;
    broker: true;
  };
}

type PartialData = Partial<Data>;
type Data = TradeAPI.User;
