import React, { Component } from "react";
import styles from "./NonNegotiableRow.module.scss";
import { DetailType, detailTypeDisplay } from "negotiations/models/common/DetailType";
import { IBidOfferView } from "sharedFolder/Models/Negotiation/NegotiationViews";
import { OrderNegType } from "sharedFolder/Models/OrderNegType";

interface INonBidableRow<T> {
  type: DetailType;
  item: IBidOfferView<T>;
  Component: any;
  orderNegType?: OrderNegType;
}

interface INonNegotiableRow<T> {
  type: DetailType;
  item: T;
  Component: any;
}

const Detail: React.SFC<{
  type: DetailType;
  negotiable: boolean;
  included: boolean;
  orderNegType?: OrderNegType;
}> = (props) => {
  return (
    <>
      {
        <div className={styles.detail} aria-label="Non Negotiable Detail">
          <span className={styles.type}>{detailTypeDisplay(props.type, props.orderNegType)}</span>
          {props.children}
        </div>
      }
    </>
  );
};

/**
 * Returns back a row for which the user has set the detail to non negotiable
 */
export class NonBidableRow<T> extends Component<INonBidableRow<T>> {
  render() {
    const { item, orderNegType, type } = this.props;
    return (
      <Detail type={type} negotiable={item.negotiable} orderNegType={orderNegType} included={item.included}>
        <this.props.Component item={item.bid} type={type} />
      </Detail>
    );
  }
}

/**
 * Returns back a row for which the detail will be non negotiable by default
 */
//TODay - duplicate this to start to style the NonNeg rows
export class NonNegotiableRow<T> extends Component<INonNegotiableRow<T>> {
  render() {
    const { item, type } = this.props;
    return (
      <Detail type={type} negotiable={true} included={true}>
        <this.props.Component item={item} type={type} />
      </Detail>
    );
  }
}
