import { Location, LocationProps } from "../Location";
import { LABELS } from "@/constants";

class LoadLocation extends Location {}

class LoadLocationProps extends LocationProps {}

LoadLocationProps.prototype.label = LABELS["loadLocation"];

LoadLocation.prototype.Props = LoadLocationProps;
LoadLocation.prototype._ = new LoadLocationProps();

export { LoadLocation, LoadLocationProps };

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */
