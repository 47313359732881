import React, { useMemo } from "react";
import { set } from "lodash-es";
import * as yup from "yup";
import { TradeAPI } from "@/apis";
import { Form, FormProps } from "@/components";
import { CargoSizeProps, UniversalOrderNegotiationFormValues } from "@/models";
import schema from "./schema.json";
import "./UniversalOrderNegotiationForm.scss";
import dayjs from "dayjs";

export function UniversalOrderNegotiationForm(props: Props) {
  const _schema = useMemo(createSchema, [props.required]);
  const definition = props.definition || props.initialValues;

  function createSchema() {
    return { ...schema, required: props.required };
  }

  return <Form {...props} definition={definition} JSONSchema={_schema} />;
}

interface Props extends FormProps {
  initialValues?: UniversalOrderNegotiationFormValues;
  required?: string[];
}

const voyageCargoSizeValidation = {
  test: validateCargoSize,
  name: "voyage-cargo-size",
  message: "Something is wrong with VOY Cargo Size",
};

const coaCargoSizeValidation = {
  test: validateCargoSize,
  name: "coa-cargo-size",
  message: "Something is wrong with COA Cargo Size",
};

const coaCargoSizeMinMaxValidation = {
  test: validateMinMax,
  name: "coa-cargo-size",
  message: "Something is wrong with COA Cargo Size",
};

const voyageDurationValidation = {
  test: validateMinMax,
  name: "voyage-duration",
  message: "Something is wrong with TCT Duration",
};

const coaLiftingsValidation = {
  test: validateMinMax,
  name: "coa-liftings",
  message: "Something is wrong with COA Liftings",
};

const vesselsValidation = {
  test: validateVessels,
  name: "vessels",
  message: "Something is wrong with Vessels",
};

function validateCargoSize(this: yup.TestContext, cargoSize?: TradeAPI["CargoSize"] | TradeAPI["COACargoSize"]) {
  if (!cargoSize) return true;

  let { option, variance } = cargoSize;

  option = option || CargoSizeProps.prototype.JSONDefaults?.option;

  const error = this.createError();

  if (option === "MIN/MAX" && variance) {
    error.message = "Variance value should be 0 with MIN/MAX option";
    error.path = `${error.path}.variance`;

    return error;
  }

  if (option === "MOLCHOPT" && !variance) {
    error.message = "Variance value should be above 0 with MOLCHOPT option";
    error.path = `${error.path}.variance`;

    return error;
  }

  if (option === "MOLOO" && !variance) {
    error.message = "Variance value should be above 0 with MOLOO option";
    error.path = `${error.path}.variance`;

    return error;
  }

  return true;
}

function validateMinMax(this: yup.TestContext, value: TradeAPI["Duration"] | TradeAPI["Liftings"] | TradeAPI["COACargoSize"]) {
  if (!value) return true;

  const { min, max } = value;

  const error = this.createError();

  if (typeof min === "number" && typeof max === "number" && min > max) {
    error.message = "Min should be less than or equal to Max";
    error.path = `${error.path}.min`;

    return error;
  }

  return true;
}

function validateVessels(this: yup.TestContext, vessels: TradeAPI["Vessel"][]) {
  const validateETA = (vessel: TradeAPI["Vessel"], i) => {
    if (isNaN(Date.parse(vessel.eta))) return;

    const error = this.createError();
    const currentDate = dayjs().startOf("day").toDate();
    const etaDate = new Date(vessel.eta);

    if (currentDate > etaDate) {
      error.message = "The selected date is in the past";
      error.path = `${error.path}.${i}.eta`;

      return error;
    }
  };

  if (vessels == null) return true;

  for (let i = 0; i < vessels.length; i++) {
    const etaError = validateETA(vessels[i], i);
    //TODO: Add other validation rules for vessels
    if (etaError) return etaError;
  }

  return true;
}

function enchanceSchema(schema) {
  const voyageCargoSize = "properties.cargoSize.test";
  const coaCargoSize = "properties.coaCargoSize.test";
  const tctDuration = "properties.duration.test";
  const coaLiftings = "properties.liftings.test";
  const vesselsPath = "properties.vessels.test";

  set(schema, voyageCargoSize, voyageCargoSizeValidation);
  set(schema, tctDuration, voyageDurationValidation);
  set(schema, coaLiftings, coaLiftingsValidation);
  set(schema, coaCargoSize, [coaCargoSizeValidation, coaCargoSizeMinMaxValidation]);
  set(schema, vesselsPath, vesselsValidation);

  return schema;
}

enchanceSchema(schema);
