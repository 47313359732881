import { IWithdrawLifting } from "../../api/negotiations/models";
import { getLiftingsApiService } from "api/liftings/Liftings";

export function withdrawLifting(
  apiUrl: string,
  orderId: string,
  liftingId: string,
  liftingCargoId: string,
  isConfirmed: boolean,
  updateToken: string
) {
  const details: IWithdrawLifting = {
    updateToken,
  };

  return getLiftingsApiService(apiUrl, orderId).withdrawLifting(orderId, liftingId, liftingCargoId, isConfirmed, details);
}
