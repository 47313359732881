import React, { useContext } from "react";
import { observer } from "mobx-react";
import { Button, Status } from "@/components";
import { usageMetrics, MainTermsTypeEvents } from "@/services/UsageMetrics";
import { Context } from "../../NegotiationDetail";
import "./SubsFailed.scss";

function SubsFailed() {
  const { negotiation } = useContext(Context);
  const { arcUrlWithCentralToken } = negotiation;
  const status = {
    type: "fail",
    title: "All subjects have failed",
    icon: "failed",
  } as Status;

  return (
    <div className="SubsFailed">
      <Status status={status} />
      <Button
        variant="action"
        href={arcUrlWithCentralToken}
        data-test="open-main-terms"
        icon="file"
        openHrefInNewWindow
        onClick={() => {
          usageMetrics.trackEvent(MainTermsTypeEvents.MAIN_TERMS_OPENED);
        }}
      >
        open Main Terms
      </Button>
    </div>
  );
}

const Observer = observer(SubsFailed);

export { Observer as SubsFailed };
