import { DataModel, DataModelProps } from "../DataModel";

class ArrayDataModel extends DataModel {
  constructor(data?: Data, props?) {
    super({ data }, props);
  }

  toJSON() {
    return this.data;
  }
}

class ArrayDataModelProps extends DataModelProps {}

ArrayDataModel.prototype.Props = ArrayDataModelProps;

export { ArrayDataModel, ArrayDataModelProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface ArrayDataModel {
  Props: typeof ArrayDataModelProps;
  _: ArrayDataModelProps;

  data: any[];
}

type Data = any[];
