import { IMapper, withNullable } from "./map";
import { ILiftingsCollectionView } from "../Models/ILiftingsCollectionView";
import { ILiftingsCollection } from "../../api/models/ILiftingsCollection";
import { mapCargoType } from "./mapCargoType";
import { mapLocation } from "./mapLocation";
import { mapToNegotiableView } from "negotiations/services/mapping/subMapping";
import { mapFreightRate, mapDemurrage } from "./mapUnitValue";

const map: IMapper<ILiftingsCollection, ILiftingsCollectionView> = {
  toApi: (liftings: ILiftingsCollectionView) => {
    throw new Error("unable to parse ILiftingsCollectionView to ILiftingsCollection");
  },
  toView: (liftings: ILiftingsCollection) => {
    let displayValues;
    if (liftings.liftingStatus === "slots_ready" && liftings.displayValues) {
      displayValues = {
        demurrage: mapDemurrage.toViewOrUndefined(liftings.displayValues.demurrage?.value),
        freightRate: mapFreightRate.toViewOrUndefined(liftings.displayValues.freightRate?.value),
        cargoType: mapCargoType.toView(liftings.displayValues.cargoType.value),
        loadLocation: mapLocation.toViewOrUndefined(liftings.displayValues.loadLocation?.value),
        dischargeLocation: mapLocation.toViewOrUndefined(liftings.displayValues.dischargeLocation?.value),
      };
    }

    return {
      displayValues,
      updateToken: liftings?.updateToken,
      liftings: liftings.liftings?.map((liftingNeg) => ({
        id: liftingNeg.id,
        cargoId: liftingNeg.cargoId,
        neg: liftingNeg.neg && mapToNegotiableView(liftingNeg.neg),
      })),
      liftingStatus: liftings.liftingStatus,
      canChartererManagedLiftings: liftings.canChartererManagedLiftings,
    };
  },
  emptyViewModel: {
    displayValues: {
      cargoType: mapCargoType.emptyViewModel,
      demurrage: mapDemurrage.emptyViewModel,
      freightRate: mapFreightRate.emptyViewModel,
      loadLocation: mapLocation.emptyViewModel,
      dischargeLocation: mapLocation.emptyViewModel,
    },
    liftingStatus: "waiting_for_slots",
    liftings: [],
    updateToken: "",
  },
};

export const mapLiftingCollections = withNullable(map);
