import React, { useContext, createContext } from "react";

interface IVesselNominateProviderProps {
  showAdditionalNotes: boolean;
  postFixUrl: string;
  children: React.ReactNode;
}

const VesselNominateContext = createContext<{
  showAdditionalNotes: boolean;
  postFixUrl: string;
}>({
  showAdditionalNotes: false,
  postFixUrl: "",
});

function VesselNominateProvider(props: IVesselNominateProviderProps) {
  const { children, showAdditionalNotes, postFixUrl } = props;

  return <VesselNominateContext.Provider value={{ showAdditionalNotes, postFixUrl }}>{children}</VesselNominateContext.Provider>;
}

function useVesselNominate() {
  const context = useContext(VesselNominateContext);
  if (context === undefined) {
    throw new Error(" useVesselNominate must be within a VesselNominateProvider");
  }

  return context;
}

export { VesselNominateProvider, useVesselNominate };
