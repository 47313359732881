import { emptyArrowFn } from "./utils";

export const format = {
  monetary: {
    input: emptyArrowFn,
    display: (number) => {
      if (typeof number !== "number" && typeof number !== "string") return;

      number = Number(number);

      const string = `${number}`;
      const split = string?.split(".");
      const decimals = split[1];

      if (decimals) return min3FractionDigitsFormatter.format(number);

      return standardNumberFormatter.format(number);
    },
  },
  number: {
    input: emptyArrowFn,
    display: (number) => {
      if (typeof number !== "number" && typeof number !== "string") return;

      return standardNumberFormatter.format(Number(number));
    },
  },
};

const standardNumberFormatter = new Intl.NumberFormat("en-UK", {
  style: "decimal",
});

const min3FractionDigitsFormatter = new Intl.NumberFormat("en-UK", {
  style: "decimal",
  minimumFractionDigits: 3,
});
