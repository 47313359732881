import { TradeAPI } from "@/apis";
import { Laycan, Location, Negotiation } from "@/models";
import { Actions } from "@/models/Negotiation/Actions";

export function getCopyIndicationType(
  negotiation: Negotiation | TradeAPI["Negotiation"],
  descriptionForLogging?: boolean
): CopyIndicationType | CopyIndicationDescription | undefined {
  const status = negotiation.status;
  const actions = new Actions(negotiation.actions);
  const {
    lastUpdatedByOwner,
    ownerFirmed,
    brokerChartererFirmed,
    firmBidAccepted,
    firmOfferAccepted,
    ownerFirmedExpired,
    brokerChartererFirmedExpired,
    lastUpdatedByBrokerCharterer,
  } = actions;
  const notExpiredBid = !brokerChartererFirmedExpired && brokerChartererFirmed && lastUpdatedByBrokerCharterer;
  let copyIndicationType: CopyIndicationType | undefined = undefined;

  if (status === "Active") {
    if (ownerFirmed && lastUpdatedByOwner && !ownerFirmedExpired) {
      copyIndicationType = "Firm Offer";
    } else if (!notExpiredBid) {
      copyIndicationType = "Owner's Indication";
    }
  }

  if (status === "Firm") {
    if (firmBidAccepted) copyIndicationType = "Bid Summary";
    if (firmOfferAccepted) copyIndicationType = "Offer Summary";
  }

  if (copyIndicationType) return descriptionForLogging ? getDescriptionTypeForLogging(copyIndicationType) : copyIndicationType;
}

export function getTemplateTitle(type: CopyIndicationType): CopyIndicationTitle {
  switch (type) {
    case "Firm Offer":
      return "Offer Details";
    case "Bid Summary":
    case "Offer Summary":
      return type;
    case "Owner's Indication":
    default:
      return "Indication Details";
  }
}

export function getStatusFieldValue(type: CopyIndicationType): CopyIndicationStatusValue {
  switch (type) {
    case "Firm Offer":
      return type;
    case "Bid Summary":
      return "Firm Bid Accepted";
    case "Offer Summary":
      return "Firm Offer Accepted";
    case "Owner's Indication":
    default:
      return "Active";
  }
}

export function getDescriptionTypeForLogging(type: CopyIndicationType): CopyIndicationDescription {
  switch (type) {
    case "Owner's Indication":
      return "Active";
    case "Bid Summary":
      return "Firm (after Firm Bid accepted)";
    case "Offer Summary":
      return "Firm (after Firm Offer accepted)";
    case "Firm Offer":
    default:
      return type;
  }
}

function formatValuesforDisplay(negotiation: Negotiation, keysArray: string[]) {
  const emptyFieldIndication = "--";
  const orderType = negotiation.type;
  const fieldsData: string[] = [];

  for (let i = 0; i < keysArray.length; i++) {
    const fieldKeyName = keysArray[i];
    const fieldName = FieldNames[orderType]?.[fieldKeyName];
    let fieldValueString;

    if (fieldName) {
      const isLocationValue = fieldName?.includes("Location");

      if (fieldName === "Laycan") {
        fieldValueString = `${fieldName}: ${
          Laycan.prototype.toView.call(negotiation.offer.laycan?.value) || emptyFieldIndication
        }`;
      } else if (isLocationValue) {
        fieldValueString = `${fieldName}: ${
          negotiation.offer[fieldKeyName]?.value.display && negotiation.offer[fieldKeyName]?.value.display !== "-"
            ? `${Location.prototype.toString.call(negotiation.offer[fieldKeyName].value)}`
            : emptyFieldIndication
        }`;
      } else {
        fieldValueString = `${fieldName}: ${
          negotiation.offer[fieldKeyName]?.value.display && negotiation.offer[fieldKeyName]?.value.display !== "-"
            ? negotiation.offer[fieldKeyName]?.value.display
            : emptyFieldIndication
        }`;
      }

      fieldsData.push(fieldValueString);
    }
  }
  return fieldsData;
}

export function formatDataToCopyTemplate(negotiation: Negotiation, type: CopyIndicationType, enableTcFields: boolean): string {
  const emptyFieldIndication = "--";
  const tbnFieldIndication = "TBN";
  const negotiableRowsKeys = negotiation.getFilledNegotiableFieldsForOfferKeysArray(negotiation, enableTcFields);
  const nonNegotiableRowsKeys = negotiation.getFilledNonNegotiableFieldsForOfferKeysArray();
  const negotiableFieldsData = formatValuesforDisplay(negotiation, negotiableRowsKeys);
  const nonNegotiableFieldsData = formatValuesforDisplay(negotiation, nonNegotiableRowsKeys);

  if (type) {
    return `Counterparty: ${negotiation.owningCompany?.accountName || tbnFieldIndication}
Invitee: ${negotiation.isOwnerAbsent ? "No owner assigned (owner absent negotiation)" : negotiation.invitee}
Vessel(s): ${negotiation.vessels.length ? negotiation.vessels.map((vessel) => vessel.display).toString() : tbnFieldIndication}
Status: ${getStatusFieldValue(type) || emptyFieldIndication}
    
+++ ${getTemplateTitle(type)} +++
${negotiableFieldsData.join(`\n`)}

+++ Other Order Details +++
Account: ${negotiation.chartererAccount?.accountName || tbnFieldIndication}${
      nonNegotiableFieldsData ? "\n" + nonNegotiableFieldsData.join("\n") : ""
    }
Order Notes: ${negotiation.orderNotes || emptyFieldIndication}
`;
  }
  return emptyFieldIndication;
}

export type CopyIndicationType = "Owner's Indication" | "Firm Offer" | "Bid Summary" | "Offer Summary";
export type CopyIndicationDescription =
  | "Active"
  | "Firm Offer"
  | "Firm (after Firm Bid accepted)"
  | "Firm (after Firm Offer accepted)";
type CopyIndicationTitle = "Indication Details" | "Offer Details" | "Bid Summary" | "Offer Summary";
type CopyIndicationStatusValue = "Active" | "Firm Offer" | "Firm Bid Accepted" | "Firm Offer Accepted";

const FieldNames = {
  Tct: {
    laycan: "Laycan",
    addressCommission: "Add.Commission",
    brokerCommission: "Broker Commission",
    cargoType: "Cargo Type(s)",
    deliveryLocation: "Delivery Location",
    duration: "Duration",
    redeliveryLocation: "Redelivery Location",
    vesselSize: "Vessel Size",
    viaLocation: "Via Location",
    demmurage: "Demmurage",
    hireRate: "Hire Rate",
    cleaningPrice: "ILOCH",
    supplyPrice: "CVE",
    tradingExclusions: "Trading Exclusions",
    bunkerDelivery: "Bunker Delivery",
    bunkerRedelivery: "Bunker Re-Delivery",
    cargoExclusionsText: "Cargo Exclusions",
    ballastBonus: "Ballast Bonus",
    operationalSubs: "Operational Subs",
    commercialSubs: "Commercial Subs",
  },
  Voy: {
    laycan: "Laycan",
    addressCommission: "Add. Commission",
    brokerCommission: "Broker Commission",
    cargoType: "Cargo Type(s)",
    cargoSize: "Cargo Size(s)",
    dischargeLocation: "Discharge Location(s)",
    loadLocation: "Load Location(s)",
    freightRate: "Freight Rate",
    demurrage: "Demurrage",
    operationalSubs: "Operational Subs",
    commercialSubs: "Commercial Subs",
  },
  Coa: {
    laycan: "Laycan",
    //TODO: bug: there is no "Period" on UI, only in detailedSettings #188198
    period: "Period",
    liftings: "Liftings",
    nominations: "Nominations",
    addressCommission: "Add. Commission",
    brokerCommission: "Broker Commission",
    cargoType: "Cargo Type(s)",
    coaCargoSize: "Cargo Size(s)",
    dischargeLocation: "Discharge Location(s)",
    loadLocation: "Load Location(s)",
    freightRate: "Freight Rate",
    demurrage: "Demurrage",
    operationalSubs: "Operational Subs",
    commercialSubs: "Commercial Subs",
  },
};
