import React, { useState } from "react";
import { IOfferRepViewNegotiation } from "negotiations/models/common/IOfferRep";
import styles from "./LiftingsLayout.module.scss";
import classnames from "classnames";
import { IConfiguration } from "sharedFolder/Configuration";
import { VesselsWrapper } from "./VesselsWrapper";
import { CargoDetails } from "sharedFolder/components/common/CargoDetails/CargoDetails";
import { useSendNomination } from "./useSendNomination";
import { Messages } from "sharedFolder/components/common/Messages/Messages";
import { liftingsMessageReducer } from "sharedFolder/reducers/liftingsMessageReducer";
import { LiftingsUIStatus } from "./liftingUIStatusReducer";

interface ILiftingsFormProps {
  config: IConfiguration;
  onRequestChanges: () => void;
  negotiation: IOfferRepViewNegotiation;
}

export const LiftingsForm: React.FC<ILiftingsFormProps> = (props: ILiftingsFormProps) => {
  const {
    requestChangesButtonEnabled,
    sendNominationButtonEnabled,
    sendNomination,
    onRemoveVessel,
    onAddNewVessel,
    onEditVessel,
    selectedVessels,
    liftingsState,
  } = useSendNomination(props.negotiation);

  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Messages<LiftingsUIStatus>
        message={liftingsState}
        onOpenStateChanged={setIsOpen}
        negotiationId={props.negotiation.id}
        messageReducer={liftingsMessageReducer}
      />
      <div
        className={classnames(
          styles.liftingsLayout,
          isOpen && styles.bannerHeight,
          liftingsState === "Withdrawn" && styles.disabledForm
        )}
        data-test="owner-dashboard-form"
      >
        <div className={styles.topSection}>
          <div className={styles.title} data-test="Lifting Slot Title">
            Lifting {props.negotiation.liftingId}
          </div>
          <div className={styles.subTitle}>Nominated Vessel(s)</div>
          <VesselsWrapper
            negotiation={props.negotiation}
            onRemoveVessel={onRemoveVessel}
            onAddNewVessel={onAddNewVessel}
            onEditVessel={onEditVessel}
            selectedVessels={selectedVessels}
          />
          <div className={styles.cargoDetails}>
            <CargoDetails negotiation={props.negotiation} />
          </div>
        </div>
        <div className={classnames(styles.bottomSection)}>
          <button
            className="action-button"
            disabled={!sendNominationButtonEnabled}
            onClick={sendNomination}
            data-test="Send Nomination Button"
          >
            Send Nomination
          </button>
          <button
            className="action-button"
            onClick={props.onRequestChanges}
            disabled={!requestChangesButtonEnabled}
            data-test="Request Changes Button"
          >
            Request Changes
          </button>
        </div>
      </div>
    </>
  );
};
