import React from "react";
import styles from "./ErrorMessage.module.scss";

const ErrorMessage = () => (
  <div data-test="error-message" className={styles.errorContainer}>
    An error has occured, please refresh the page.
    <br />
    If the problem persists please contact: <a href="mailto:support@sea.live">support@sea.live</a>
  </div>
);

export default ErrorMessage;
