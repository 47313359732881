import { toIAccountView, fromIAccountView, IAccountSearchResult } from "../../../../models/client/IAccountSearchClient";
import {
  ILaycanView,
  IAccountView,
  ICargoTypeView,
  ILiftingsView,
  INominationsView,
  ICOACargoSizeView,
  ICargoSizeView,
} from "sharedFolder/Models/IDetails";
import { ILocationView } from "sharedFolder/Models/ILocation";
import { ICargoSizeValue } from "../../../../../orders/models/ICargoSize";
import { ILiftings } from "../../../../../api/models/ILiftings";
import { INominations } from "../../../../../api/models/INominations";
import { ICOACargoSize, ICargoType, ILaycan } from "../../../../../api/orders/models";
import { valueFromString } from "sharedFolder/components/common/NumberInput/NumberInput";

export const editorValueResolverFunctions: any = {
  cargoType: {
    to: (value: ICargoType) => value,
    from: (value: ICargoTypeView) => value,
  },
  cargoSize: {
    to: (value: ICargoSizeValue) => value,
    from: (value: ICargoSizeView) => value,
  },
  deliveryLocation: {
    to: (value: ILocationView) => value,
    from: (value: ILocationView) => value,
  },
  laycan: {
    from: (value: ILaycanView) => value,
    to: (value: ILaycan) => value,
  },
  period: {
    from: (value: ILaycanView) => value,
    to: (value: ILaycan) => value,
  },
  liftings: {
    from: (value: ILiftingsView): ILiftings => fromILiftingsView(value),
    to: (value: ILiftings): ILiftingsView => toILiftingsView(value),
  },
  nominations: {
    from: (value: INominationsView): INominations => fromINominationsView(value),
    to: (value: INominations): INominationsView => toINominationsView(value),
  },
  coaCargoSize: {
    from: (value: ICOACargoSizeView): ICOACargoSize => fromICOACargoSizeView(value),
    to: (value: ICOACargoSize): ICOACargoSizeView => toICOACargoSizeView(value),
  },
  loadLocation: {
    to: (value: ILocationView) => value,
    from: (value: ILocationView) => value,
  },
  redeliveryLocation: {
    to: (value: ILocationView) => value,
    from: (value: ILocationView) => value,
  },
  dischargeLocation: {
    to: (value: ILocationView) => value,
    from: (value: ILocationView) => value,
  },
  viaLocation: {
    to: (value: ILocationView) => value,
    from: (value: ILocationView) => value,
  },
  ownerAccount: {
    to: (value: IAccountSearchResult): IAccountView | undefined => toIAccountView(value),
    from: (value: IAccountView): IAccountSearchResult | undefined => fromIAccountView(value),
  },
};

const toILiftingsView = (apiModel: ILiftings): ILiftingsView => {
  return {
    min: apiModel.min,
    max: apiModel.max,
    dispersal: apiModel.dispersal,
    display: apiModel.display,
    notes: apiModel.notes,
  };
};
const fromILiftingsView = (apiModel: ILiftingsView): ILiftings => {
  return {
    min: apiModel.min || 0,
    max: apiModel.max || 0,
    dispersal: apiModel.dispersal || "Adhoc",
    display: apiModel.display || "",
    notes: apiModel.notes || "",
  };
};

const toINominationsView = (apiModel: INominations): INominationsView => {
  return {
    display: apiModel.display,
    noticePerLaycan: apiModel.noticePerLaycan,
    laycanSpread: apiModel.laycanSpread,
    finalLaycanNotice: apiModel.finalLaycanNotice,
    notes: apiModel.notes,
  };
};
const fromINominationsView = (apiModel: INominationsView): INominations => {
  return {
    display: apiModel.display || "",
    noticePerLaycan: apiModel.noticePerLaycan || 0,
    laycanSpread: apiModel.laycanSpread || 0,
    finalLaycanNotice: apiModel.finalLaycanNotice || 0,
    notes: apiModel.notes || "",
  };
};

const toICOACargoSizeView = (apiModel: ICOACargoSize): ICOACargoSizeView => {
  return {
    display: apiModel.display,
    unit: apiModel.unit,
    min: apiModel.min ? String(apiModel.min) : undefined,
    max: apiModel.max ? String(apiModel.max) : undefined,
    variance: apiModel.variance ? String(apiModel.variance) : undefined,
    option: apiModel.option ? String(apiModel.option) : undefined,
    notes: apiModel.notes,
  };
};
const fromICOACargoSizeView = (apiModel: ICOACargoSizeView): ICOACargoSize => {
  return {
    display: apiModel.display || "",
    unit: apiModel.unit || "",
    min: valueFromString(apiModel.min) || 0,
    max: valueFromString(apiModel.max) || 0,
    variance: apiModel.variance ? Number(apiModel.variance) : undefined,
    option: apiModel.option,
    notes: apiModel.notes,
  };
};
