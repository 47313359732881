import { TradeAPI } from "@/apis";
import { BunkerBaseEditor } from "@/components";
import { Format } from "negotiations/services/utils/converters";
import { bunkerFieldDisplayFormatter } from "sharedFolder/components/common/fields";
import { IBunkerView } from "sharedFolder/Models/IBunker";
import { DataModel, DataModelProps } from "../DataModel";

export class Bunker extends DataModel<Data> {
  onUpdate() {
    delete this.display;
    delete this.shortDisplay;
  }

  toGridView() {
    const emptyFieldText = "--";
    const price = this?.price;
    const quantity = this?.quantity;
    const fuelTypes = this?.fuelTypes;
    const value = {
      price,
      quantity,
      fuelTypes,
    } as IBunkerView;

    return bunkerFieldDisplayFormatter(value, emptyFieldText, 0);
  }

  toString() {
    const emptyFieldText = "--";
    const price = this?.price;
    const quantity = this?.quantity;
    const fuelTypes = this?.fuelTypes;
    const notes = this?.notes;
    const value = {
      price,
      quantity,
      fuelTypes,
      notes,
    } as IBunkerView;

    return bunkerFieldDisplayFormatter(value, emptyFieldText, Format.COMBINED);
  }
}

export class BunkerProps extends DataModelProps<Data> {}

BunkerProps.prototype.editor = BunkerBaseEditor;
Bunker.prototype._ = new BunkerProps();

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

export interface Bunker extends Data {
  _: BunkerProps;
}

type Data = DeepPartial<TradeAPI["Bunker"]>;
