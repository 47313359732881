import { LABEL_MAP } from "___REFACTOR___/constants";
import { TradeAPI } from "___REFACTOR___/apis";
import { HireRateEditor } from "___REFACTOR___/components";
import { UnitValue, UnitValueProps } from "../UnitValue";

class HireRate extends UnitValue {
  static label = LABEL_MAP.hireRate;
  static get Editor() {
    return HireRateEditor;
  }

  constructor(data?: PartialData) {
    super(data);
  }
}
class HireRateProps extends UnitValueProps {}

HireRate.prototype.Props = HireRateProps;
HireRateProps.prototype.Editor = HireRateEditor;
HireRateProps.prototype.sortKey = "value";
HireRateProps.prototype.label = LABEL_MAP.hireRate;
HireRateProps.prototype.JSONDefaults = {
  unit: "PerDay",
};
HireRateProps.prototype.unitArr = ["PerDay", "LumpSum"];

export { HireRate, HireRateProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface HireRate extends Data {
  Props: typeof HireRateProps;
  _: HireRateProps;
}

interface HireRateProps {
  sortKey: keyof Data;
  JSONDefaults: Partial<Data>;
  unitArr: TradeAPI.HireRate.Unit[];
}

type PartialData = Partial<Data>;
type Data = TradeAPI.HireRate;
