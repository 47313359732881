import React from "react";
import { slideout, slideout0, dialog, dialog0 } from "___REFACTOR___/services";
import { Dialog } from "./Dialog";
import { Dropdown } from "./Dropdown";
import { MiniConfirm } from "./MiniConfirm";
import { Slideout } from "./Slideout";
import { Spinners } from "./Spinners";
import "./Services.scss";

function Services() {
  return (
    <>
      <Slideout service={slideout} />
      <Slideout service={slideout0} />
      <Dialog service={dialog} />
      <Dialog service={dialog0} />
      <dialog-portal ref={setupDialogPortal} />
      <Dropdown />
      <Spinners />
      <MiniConfirm />
    </>
  );
}

function setupDialogPortal(dom) {
  window.react.services.dialogPortal = dom;
}

export { Services };
