import { Account, AccountProps } from "../Account";

export class ChartererAccount extends Account {
  onUpdate() {
    this._ = this._ || new ChartererAccountProps();
  }
}

class ChartererAccountProps extends AccountProps {}

ChartererAccountProps.prototype.label = "Charterer Account";

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */
