import React from "react";
import { observer } from "mobx-react";
import { Status, Button, StripedLoader } from "@/components";
import { usageMetrics, MainTermsTypeEvents } from "@/services/UsageMetrics";
import "./TermsLoading.scss";

function TermsLoading() {
  const status = {
    title: "Terms Loading",
    icon: "on-subs",
  } as Status;

  return (
    <div className="TermsLoading">
      <Status status={status} />
      <div>
        <Button
          variant="action"
          icon="file"
          data-test="open-main-terms"
          disabled
          onClick={() => {
            usageMetrics.trackEvent(MainTermsTypeEvents.MAIN_TERMS_OPENED);
          }}
        >
          open Main Terms
        </Button>
        <StripedLoader status={{ loading: true }} size="small" />
      </div>
    </div>
  );
}

const Observer = observer(TermsLoading);

export { Observer as TermsLoading };
