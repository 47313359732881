// @ts-nocheck
import { useState, useContext } from "react";
import { IVesselSelectedView } from "./VesselSelector/VesselSelector";
import { IOfferState } from "./IOfferState";
import { DetailTypePlusOwner } from "./Negotiable/INegotiableItemProps";
import { of, forkJoin } from "rxjs";
import { IDetailType, valueTypes, IMergedPost } from "../../api/offerRepUpdate";
import { OfferRepNegotiationContext } from "../Contexts/OfferRepNegotiationContext";
import { mapVessel } from "sharedFolder/mappers/mapVessel";
interface IDetailTypes {
  details: IDetailType;
}
function hasKey<O>(obj: O, key: keyof any): key is keyof O {
  return key in obj;
}

export type offerRepContext = "OfferRep" | "OwnerDashboard";
export function useDetailsAndVesselsToPost(
  offerRepContext: offerRepContext,
  onPresentDisclaimer: () => Promise<void>,
  errorFunc: () => void,
  selectedVessels: IVesselSelectedView[]
) {
  const [detailTypesChanged, setDetailTypesChanged] = useState<DetailTypePlusOwner[]>([]);
  const { offerRepApi } = useContext(OfferRepNegotiationContext);

  const getDetailTypesToPost = (offer: IOfferState): IDetailTypes | null => {
    const detailTypesChangedExceptOwner = detailTypesChanged.filter((detailType) => detailType !== "ownerAccount");
    if (detailTypesChangedExceptOwner.length) {
      const details = detailTypesChangedExceptOwner.map((key) => {
        if (offer && key && hasKey(offer, key)) {
          const obj: IDetailType = {};
          obj[key] = {
            value: {
              ...(offer[key] as valueTypes),
            },
          };
          return obj;
        }
        return undefined;
      });
      return {
        details: {
          ...details.filter((detailType) => detailType !== null).reduce((prev, current) => ({ ...prev, ...current })),
        },
      };
    }
    return null;
  };

  const sendIndicationOrFirmOffer = (
    offer: IOfferState,
    negotiationType: {
      action: "indicated" | "firmed";
      expiresOn: string | undefined;
    }
  ) => {
    /**
     * Returns the ownerAccount selected if it exists || undefined
     */
    const getAccountName = () => {
      const account = detailTypesChanged.filter((x) => x === "ownerAccount");
      if (account.length && offer.ownerAccount) {
        return offer.ownerAccount.accountName;
      }
      return undefined;
    };

    /**
     * Returns an observable of the the {accountName:'Acc'}
     */
    const getAccountObservable = () => {
      const accountName = getAccountName();
      if (accountName) {
        return of({ accountName });
      }
      return of(undefined);
    };

    return new Promise((resolve) => {
      forkJoin({
        vessels: of(selectedVessels),
        owner: getAccountObservable(),
      }).subscribe(
        (result) => {
          const detailTypes = getDetailTypesToPost(offer);
          const { expiresOn, action } = negotiationType;
          const itemsToPost = {
            vessels: result.vessels.map(mapVessel.toApi),
            details: detailTypes?.details,
            expiresOn,
            account: result.owner,
            action,
          } as IMergedPost;
          offerRepApi.sendOffer(itemsToPost).subscribe(
            (result) => resolve(result),
            () => errorFunc()
          );
        },
        () => errorFunc()
      );
    });
  };

  const sendIndication = (offer: IOfferState) => {
    if (offerRepContext === "OfferRep") {
      return onPresentDisclaimer().then(() => {
        return sendIndicationOrFirmOffer(offer, {
          action: "indicated",
          expiresOn: undefined,
        });
      });
    }
    return sendIndicationOrFirmOffer(offer, {
      action: "indicated",
      expiresOn: undefined,
    });
  };

  const sendFirmOffer = (offer: IOfferState, expiresOn: string) => {
    if (offerRepContext === "OfferRep") {
      return onPresentDisclaimer().then(() => {
        return sendIndicationOrFirmOffer(offer, {
          action: "firmed",
          expiresOn,
        });
      });
    }
    return sendIndicationOrFirmOffer(offer, {
      action: "firmed",
      expiresOn,
    });
  };

  return {
    sendIndication,
    sendFirmOffer,
    detailTypesChanged,
    setDetailTypesChanged,
  };
}
