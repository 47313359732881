import * as React from "react";
import fetchIntercept from "fetch-intercept";
import NoAccess from "sharedFolder/components/common/NoAccess/NoAccess";
import { TrackingContext } from "analytics/TrackingContext";
import { withContext } from "sharedFolder/contexts/withContext";
import { ITracking } from "analytics/ITracking";
import { tokens } from "__legacy/services/TokenProvider";
import { auth } from "@/models";
import { emptyArrowFn } from "@/utils";

interface IFetchInterceptorProps {
  baseUrl: string;
  children: React.ReactNode;
  /**
   * exceptions - do not intercept requests to endpoints that match these expressions
   * */
  exceptions?: string[];
}

interface IFetchInterceptorState {
  userHasAccess: boolean;
}

/**
 * Higher-order component that ensures all fetch() requests to props.baseUrl
 * for the lifetime of this component will contain the ctrade and cloud tokens
 * which are requried for authentiation
 */
class FetchInterceptorComponent extends React.Component<IFetchInterceptorProps & ITracking, IFetchInterceptorState> {
  private interceptorUnregister: undefined | (() => void);

  constructor(props: IFetchInterceptorProps & ITracking) {
    super(props);

    this.interceptRequest = this.interceptRequest.bind(this);
    this.applyHeaders = this.applyHeaders.bind(this);
    this.state = {
      userHasAccess: true,
    };
    this.interceptorUnregister = fetchIntercept.register({
      // intercept request
      request: this.interceptRequest,
      requestError: (err) => {
        return Promise.reject(err);
      },
      response: (response) => {
        // pass through
        return response;
      },
      responseError: (err) => {
        props.interaction("PostTokenFailed", err);
        // pass through
        return Promise.reject(err);
      },
    });
  }

  public onResponseError = emptyArrowFn;

  public render() {
    return <>{this.state.userHasAccess ? this.props.children : <NoAccess />}</>;
  }

  public componentWillUnmount() {
    if (this.interceptorUnregister) {
      this.interceptorUnregister();
    }
  }

  private interceptRequest(url: string, config: any): any | Promise<any> {
    // apply auth headers to all fetch calls to the given URL
    const exceptions = this.props.exceptions || [];
    return url.startsWith(this.props.baseUrl) && !exceptions.some((e) => RegExp(e).test(url))
      ? this.applyHeaders(url, config)
      : [url, config];
  }

  private async applyHeaders(url: string, config: any): Promise<any> {
    const cloudToken = tokens.cloudToken;
    try {
      const ctradeToken = auth.trade.token;
      const headers = config ? config.headers || {} : {};

      const newConfig = {
        ...config,
        headers: {
          ...headers,
          "Clarksons.Cloud.LoginToken": cloudToken,
          Authorization: `Bearer ${ctradeToken}`,
        },
      };

      return [url, newConfig];
    } catch (e) {
      this.setState({ userHasAccess: false });
      return Promise.reject(e);
    }
  }
}

export const FetchInterceptor = withContext<IFetchInterceptorProps, ITracking>(FetchInterceptorComponent, TrackingContext);
