import { Request } from "@/request";
import { wait } from "@/utils";
import { dialog, env } from "@/models";

const searchParams = new URLSearchParams(window.location.search);

export let config: Config;
export const DEVELOPMENT = process.env.NODE_ENV === "development" || localStorage.DEVELOPMENT;
export const PRODUCTION = process.env.NODE_ENV === "production";
export const TEST = process.env.NODE_ENV === "test";
export let APP_DEVELOPMENT: boolean;
export let APP_PRODUCTION: boolean;
export const DISABLE_INTERCOM = !!searchParams.get("mute-intercom");
export const DISABLE_SEA_CHAT = !!searchParams.get("mute-sea-chat");
export const DISABLE_SEA_CHAT_IN_DEV = true;
export const IS_LOCAL_HOST = window.location.host.includes("localhost");
export const IS_DEV_HOST = window.location.host.includes("dev");
export const IS_TEST_HOST = window.location.host.includes("test");
export const IS_INT_HOST = window.location.host.includes("int");
export const ALLOW_OVERRIDING_CONFIG_JSON = DEVELOPMENT || TEST || IS_DEV_HOST || IS_TEST_HOST;

export async function setup() {
  const request = new Request();
  const { data } = (await request.get(`/config.json?now=${now}`)) as any;
  const envName = env.getEnv();

  let configJSON = data;

  if (!configJSON) {
    dialog.show(fetchErrorDialog);

    await wait(266642069);
  }

  if (ALLOW_OVERRIDING_CONFIG_JSON) {
    if (ENVS[envName]) configJSON = { ...ENVS[envName], ...configJSON } || configJSON;
  }

  const normalized = await normalize(configJSON);

  if (normalized) {
    config = normalized;
  }
}

const ENVS_DEFAULT = {
  dev: {
    featureToggleSdkKey: "626d6bd25a57c7150b059fa3",
    seaChatsignUpUrl: "https://login-test.sea.live/#/manual-signup?moduleId=28",
    signUpUrl: "https://login-test.sea.live/#/manual-signup?moduleId=40",
    authUrl: "https://ctrade-poc-auth-provider.azurewebsites.net",
    headerCdnUrl: "https://ctrade-poc-auth-provider.azurewebsites.net",
    ctradeUrl: "https://trade-gateway-dev1-func-we.azurewebsites.net",
    cchatUrl: "https://ctrade-poc-auth-provider.azurewebsites.net/seachat",
    cchatWidgetUrl: "https://cloudheader-dev.azureedge.net",
    cchatBaseUrl: "https://chat-test4.sea.live",
    vesselActivityUrl: "https://analytics-staging.sea.live/home/report?rid=vessel&imo=",
    vesselViewUrl: "https://net-staging.sea.live/map",
    vesselScoreApiUrl: "https://trade-mockservice-dev3-func-we.azurewebsites.net",
    cloudApplicationCode: "cloudlocal",
    version: "1.0.5812.1",
    environment: "development",
    useMockTermSetClient: "true",
    appInsightsKey: "436bdc19-8010-4ff6-ba04-6e0a8089f55a",
  },

  test: {
    featureToggleSdkKey: "626d6b218488c714aa6b79d9",
    seaChatsignUpUrl: "https://login-staging.sea.live/#/manual-signup?moduleId=28",
    signUpUrl: "https://login-staging.sea.live/#/manual-signup?moduleId=40",
    authUrl: "https://login-staging.sea.live",
    headerCdnUrl: "https://cloudheader-uat.azureedge.net",
    ctradeUrl: "https://trade-gateway-tst1-func-we.azurewebsites.net",
    cchatUrl: "https://chat-staging.sea.live",
    cchatWidgetUrl: "https://pltfrmcdn-cloudsa-cdn-stg.sea.live",
    cchatBaseUrl: "https://chat-staging.sea.live",
    vesselActivityUrl: "https://analytics-staging.sea.live/home/report?rid=vessel&imo=",
    vesselViewUrl: "https://net-staging.sea.live/map",
    cloudApplicationCode: "cloudtest",
    version: "1.0.5810.1",
    environment: "production",
    useMockTermSetClient: "false",
    appInsightsKey: "fa8f4517-f467-42ab-a3fc-884bbb552cb2",
    vesselScoreApiUrl: "https://vesselscore2-api-staging.sea.live/api",
  },

  int: {
    featureToggleSdkKey: "63579d2ff0eac4117a3f329b",
    seaChatsignUpUrl: "https://login-staging.sea.live/#/manual-signup?moduleId=28",
    signUpUrl: "https://login-staging.sea.live/#/manual-signup?moduleId=40",
    authUrl: "https://login-staging.sea.live",
    headerCdnUrl: "https://cloudheader-uat.azureedge.net",
    ctradeUrl: "https://trade-gateway-int-func-we.azurewebsites.net",
    cchatUrl: "https://chat-staging.sea.live",
    cchatWidgetUrl: "https://pltfrmcdn-cloudsa-cdn-stg.sea.live",
    cchatBaseUrl: "https://chat-staging.sea.live",
    vesselActivityUrl: "https://analytics-staging.sea.live/home/report?rid=vessel&imo=",
    vesselViewUrl: "https://net-staging.sea.live/map",
    cloudApplicationCode: "cloudint",
    version: "1.0.5944.1",
    environment: "production",
    useMockTermSetClient: "false",
    appInsightsKey: "6aff7899-ed9a-4c4c-8c15-c6e4e816a0e4",
    vesselScoreApiUrl: "https://vesselscore2-api-staging.sea.live/api",
  },

  prod: {
    featureToggleSdkKey: "626d6b218488c714aa6b79da",
    seaChatsignUpUrl: "https://login.sea.live/#/manual-signup?moduleId=28",
    signUpUrl: "https://login.sea.live/#/manual-signup?moduleId=40",
    authUrl: " https://login.sea.live",
    headerCdnUrl: "https://cloudheader-prd.azureedge.net",
    ctradeUrl: "https://trade-gateway-prd-func-we.azurewebsites.net",
    cchatUrl: "https://chat.sea.live",
    cchatWidgetUrl: "https://cloudheader-prd.azureedge.net",
    cchatBaseUrl: "https://chat.sea.live",
    vesselActivityUrl: "https://analytics.sea.live/home/report?rid=vessel&imo=",
    vesselViewUrl: "https://net.sea.live/map",
    cloudApplicationCode: "cloudlive",
    version: "1.0.6640.1",
    environment: "production",
    useMockTermSetClient: "false",
    appInsightsKey: "6b55e884-d4ba-4b04-84b6-c7cfc182e0c9",
    intercomSettingsAppId: "h2488k8w",
  },
};

const ENVS = {
  dev1: {
    ...ENVS_DEFAULT.dev,
    ctradeUrl: "https://trade-gateway-dev1-func-we.azurewebsites.net",
    featureToggleSdkKey: "63579f4eef2dad11a194a4dc",
  },
  dev2: {
    ...ENVS_DEFAULT.dev,
    ctradeUrl: "https://trade-gateway-dev2-func-we.azurewebsites.net",
    appInsightsKey: "90ad8eb5-2259-472a-8531-a70d2011f6a5",
    featureToggleSdkKey: "63579f552a1c6e12176b84f1",
  },
  dev3: {
    ...ENVS_DEFAULT.dev,
    ctradeUrl: "https://trade-gateway-dev3-func-we.azurewebsites.net",
    appInsightsKey: "f85bea69-26ef-4d66-bca2-5c6b2dbda227",
    featureToggleSdkKey: "63579f5de8ccb111df25d0e8",
  },
  dev4: {
    ...ENVS_DEFAULT.dev,
    ctradeUrl: "https://trade-gateway-dev4-func-we.azurewebsites.net",
    appInsightsKey: "d21fb67f-b545-4056-9625-222b50c659fc",
    featureToggleSdkKey: "63579f682a1c6e12176b8516",
  },
  test1: {
    ...ENVS_DEFAULT.test,
    ctradeUrl: "https://trade-gateway-tst1-func-we.azurewebsites.net",
    featureToggleSdkKey: "63579f76e8ccb111df25d106",
  },
  test2: {
    ...ENVS_DEFAULT.test,
    ctradeUrl: "https://trade-gateway-tst2-func-we.azurewebsites.net",
    appInsightsKey: "9bec606e-6ace-4c3c-a6f0-e352b9790f1b",
    featureToggleSdkKey: "63579f7cef2dad11a194a513",
  },
  test3: {
    ...ENVS_DEFAULT.test,
    ctradeUrl: "https://trade-gateway-tst3-func-we.azurewebsites.net",
    appInsightsKey: "1016d53f-c388-48a6-b496-ce83bac85c7c",
    featureToggleSdkKey: "63579f84635ca211482c2861",
  },
  test4: {
    ...ENVS_DEFAULT.test,
    ctradeUrl: "https://trade-gateway-tst4-func-we.azurewebsites.net",
    appInsightsKey: "109b1deb-6a10-4a0b-8e3c-6aa29588b008",
    featureToggleSdkKey: "63579f8cd01a49116bf74985",
  },
  int: ENVS_DEFAULT.int,
  prod: ENVS_DEFAULT.prod,
};

async function normalize(json: Config) {
  const { authUrl, cloudApplicationCode, ctradeUrl, environment, headerCdnUrl } = json;

  if (!authUrl || !cloudApplicationCode || !ctradeUrl || !headerCdnUrl) {
    dialog.show(missingValuesDialog);

    await wait(26664206969);

    return;
  }

  APP_DEVELOPMENT = environment === "development";
  APP_PRODUCTION = environment === "production";

  return {
    ...json,
    intercomSettingsAppId: "oa9w606x",
    mockedHeader: environment === "development",
    mockedAuth: `${authUrl}`.includes("ctrade-poc-auth-provider"),
  };
}

const now = new Date().getTime();

const fetchErrorDialog = {
  status: {
    type: "error",
    title: "Config Fetch Failure",
    message: "We sincerely apologise for any inconvenience caused.",
  } as Status,
  dataTest: "config-fetch-fail",
};

const missingValuesDialog = {
  status: {
    type: "error",
    title: "Missing Config Values",
    message: "We sincerely apologise for any inconvenience caused.",
  } as Status,
  dataTest: "missing-config-values",
};

export type FeatureValue = "true" | "false";

export type FeatureFlagName = "odc";

export enum LaunchDarklyFeature {
  OrderWithdrawal = "orderWithdrawal",
  TCFields = "tcFields",
  CopyIndication = "copyIndication",
}

export type Environments = "development" | "production";

export interface FeatureFlags {
  enableOdc: FeatureValue;
  enableOdcCompanies: string;
}

export interface UsageMetricsConfig {
  enabled: string;
  debug: string;
}

export interface ArchiveWaitPolicy {
  bulkWaitRetryCount: number;
  bulkWaitRetryDelayMs: number;
  singleWaitRetryCount: number;
  singleWaitRetryDelayMs: number;
}

export interface FaultTolerancePolicy {
  retryCount: number;
  minRetryPauseMs: number;
  maxRetryPauseMs: number;
  pauseIncremenet: number;
}

interface Config {
  appInsightsKey: string;
  authUrl: string;
  cchatBaseUrl: string;
  cchatUrl: string;
  cchatWidgetUrl: string;
  cloudApplicationCode: string;
  ctradeUrl: string;
  environment: Environments;
  featureFlags?: FeatureFlags;
  headerCdnUrl: string;
  intercomSettingsAppId: string;
  mockedAuth?: boolean;
  mockedHeader?: boolean;
  seaChatsignUpUrl: string;
  signUpUrl: string;
  useMockTermSetClient: string;
  version: string;
  vesselActivityUrl: string;
  vesselViewUrl: string;
  recordCount: number;
  vesselScoreApiUrl: string;
  usageMetrics: UsageMetricsConfig;
  faultTolerancePolicy: FaultTolerancePolicy;
  archiveWaitPolicy: ArchiveWaitPolicy;
  featureToggleSdkKey: string;
}
