import { Moment } from "moment";
import { getCreateOrderPayload, UniversalOrderNegotiationFormValues, Order, dialog } from "@/models";
import { usageMetrics, OrderCreationTypeEvents } from "@/services/UsageMetrics";
import { when } from "mobx";
import { Context } from "../Orders";

// prettier-ignore
export async function createOrder(model: Order | undefined, context: Context, values: UniversalOrderNegotiationFormValues, eventStartTime: Moment) {
  if (!model) {
    console.error("createOrder:", { model, context, values });

    throw new Error(`createOrder: Order model is undefined`);
  }

  model._.status.loading = true;
  model._.status.message = "Creating Order";

  const orderCreationPayload = {
    ...getCreateOrderPayload(values),
  };

  const res = await model.create(orderCreationPayload, context.view.order.orderCreationQuery);

  if (!res?.data?.id) {
    model._.status.type = "error";
    model._.status.title = `${context.view.order.name} Creation Failure`;
    model._.status.dump = { res, entity: orderCreationPayload };

    dialog.show({
      status: model._.status,
      dataTest: `${context.dataTest}-creation-fail`,
    });

    model._.status.loading = false;
    model._.status.message = null;

    return;
  }

  const newOrderModel = context.activeItemsStoreRef ? context.activeItemsStoreRef?.upsertOrderModel({ id: res?.data?.id }, { orderNegotiationStoreOrderUpsertConfig: {
    shouldUpdateArray: false
  } }) : context.orderNegotiationStore?.upsertOrderModel({ id: res?.data?.id }, { orderNegotiationStoreOrderUpsertConfig: {
    shouldUpdateArray: false
  } });

  const currentNewOrderModelVersion = newOrderModel?._.version;

  await when(() => currentNewOrderModelVersion !== newOrderModel?._.version);

  if (newOrderModel?._.orderNegotiationStoreOrder) {
    context.activeItemsStoreRef ? context.activeItemsStoreRef.upsertOrder(newOrderModel?._.orderNegotiationStoreOrder, { shouldAddExistingOrderToArray: true }): context?.orderNegotiationStore?.upsertOrder(newOrderModel?._.orderNegotiationStoreOrder, { shouldAddExistingOrderToArray: true })
    context.routes.order.inactive?.push({ orderId: res?.data?.id, }, { ignoreUnsavedChanges: true });
  }

  const charterTypes = orderCreationPayload.types;

  let event;
  if (charterTypes?.includes("Voy") && charterTypes?.includes("Tct")) {
    event = OrderCreationTypeEvents.STANDARD_TCT_AND_VOY
  } else if (charterTypes?.includes("Voy") && !charterTypes?.includes("Tct")) {
    event = OrderCreationTypeEvents.STANDARD_VOY
  } else if (!charterTypes?.includes("Voy") && charterTypes?.includes("Tct")) {
    event = OrderCreationTypeEvents.STANDARD_TCT
  } else if (charterTypes?.includes("Coa")) {
    event = OrderCreationTypeEvents.STANDARD_COA
  }

  usageMetrics.finishTrackingAction(event, eventStartTime, undefined, undefined, {
    orderType: "Standard Order",
    charterType: charterTypes
  });

  usageMetrics.trackEvent(OrderCreationTypeEvents.CARGO_TYPE_SELECTED, {
    cargoType: orderCreationPayload.cargoType?.name
  }, true);

  model._.status.loading = false;
  model._.status.message = null;
}
