import React from "react";
import { TradeAPI } from "@/apis";
import { observer } from "mobx-react";
import classNames from "classnames";
import { companies } from "@/models";
import { SelectField, SelectFieldProps } from "@/components";

function CompanyEditor(props: SelectFieldProps) {
  props = { ...props };

  props.className = classNames(props.className, "Company");

  function onChange(company: TradeAPI["Company"]) {
    props.onChange?.(company?.companyId);
  }

  return (
    <SelectField
      {...props}
      value={companies.dataById[props.value]}
      onChange={onChange}
      data={companies.data}
      filterKey="name"
      noDataStatus={noDataStatus}
      dataTest={`${props.dataTest}.value`}
      unclearable
    />
  );
}

const noDataStatus = { message: "No companies found" };

const Observer = observer(CompanyEditor);

export { Observer as CompanyEditor };
