/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from "react";
import { observer } from "mobx-react";
import { Route as RRRoute, useLocation, useParams } from "react-router-dom";
import { APP_DEVELOPMENT } from "@/config";
import { router, Route as RouteModel, seaChatWidget } from "@/models";

function Route(props: Props) {
  const { route } = props;
  const { comp: Comp, absPath, children } = route;

  if (!Comp) return null;

  return (
    <RRRoute path={absPath}>
      <CompWrapper route={route}>
        <Comp>{children.map(RouteHOC)}</Comp>
      </CompWrapper>
      <Redirect route={route} />
    </RRRoute>
  );
}

function CompWrapper(props: CompWrapperProps) {
  const { route, children } = props;
  const { pageTitle, authorised, seaChatWidgetProps } = route;

  if (!authorised) {
    router.get("unauthorised").push();

    return null;
  }

  seaChatWidget.routeProps = seaChatWidgetProps;

  function activate() {
    if (pageTitle) document.title = APP_DEVELOPMENT ? `Sea/trade [RF] ${pageTitle}` : `Sea/trade ${pageTitle}`;

    router.setActive(route);
  }

  setTimeout(activate);

  return children;
}

const Redirect = observer(function Redirect(props: Props) {
  const location = useLocation();
  const params = useParams();
  const prevParams = useRef(params);
  let redirect = props.route.redirect;

  const paramsChanged = params !== prevParams.current;

  prevParams.current = params;

  if (typeof redirect === "function") redirect = redirect();

  if (!redirect) return null;

  const { to, from } = redirect;
  const match = from.match(window.location.pathname);
  const shouldRedirect = match && (paramsChanged || !to.active);

  if (match && shouldRedirect) {
    setTimeout(() => to.replace(match?.params, undefined, new URLSearchParams(location.search)));
  }

  return null;
});

function RouteHOC(route: RouteModel, i: number) {
  return <Route route={route} key={i} />;
}

const Observer = observer(Route);

export { Observer as Route, RouteHOC };

interface Props {
  route: RouteModel;
}

interface CompWrapperProps {
  route: RouteModel;
  children: JSX.Element;
}
