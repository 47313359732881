import React from "react";
import * as ClassNames from "classnames";
import classNames from "classnames";
import { Status } from "___REFACTOR___/models";
import "./Spinner.scss";

function Spinner(props: Spinner.Props) {
  const { status } = props;

  if (status) props = { ...props, hidden: !status.loading && !status.message, loading: status.loading, message: status.message };

  const {
    overlay,
    alignment = "top-center",
    className: propsClassName,
    hidden,
    controlled,
    message,
    loading,
    status: ignore,
    ...rest
  } = props;
  const className = classNames(propsClassName, alignment, { overlay, controlled: controlled || message, message, loading });

  if (hidden) return null;

  return (
    <order-neg-layout-spinner-container {...rest} class={className}>
      <order-neg-layout-spinner-container-inner>
        <order-neg-layout-spinner>
          <stripe-outer>
            <stripe-inner />
          </stripe-outer>
          <stripe-outer>
            <stripe-inner />
          </stripe-outer>
          <stripe-outer>
            <stripe-inner />
          </stripe-outer>
          <stripe-outer>
            <stripe-inner />
          </stripe-outer>
          <stripe-outer>
            <stripe-inner />
          </stripe-outer>
        </order-neg-layout-spinner>
      </order-neg-layout-spinner-container-inner>
      {message && <order-neg-layout-spinner-message>{message}</order-neg-layout-spinner-message>}
    </order-neg-layout-spinner-container>
  );
}

export { Spinner };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace Spinner {
  interface Props extends Omit<HTMLProps, "className"> {
    className?: ClassNames.Argument;
    alignment?:
      | "top-left"
      | "top-center"
      | "top-right"
      | "center"
      | "center-left"
      | "center-right"
      | "bottom-left"
      | "bottom-center"
      | "bottom-right"
      | "none";
    overlay?: boolean;
    controlled?: boolean;
    loading?: boolean;
    huge?: boolean;
    message?: React.ReactNode;
    status?: Status;
  }

  type HTMLProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;
}
