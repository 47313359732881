import * as React from "react";
import { Notes } from "../../common/Notes/Notes";
import { CargoTypePicker, toCargoTypeSearchResult } from "../../common/CargoTypePicker/CargoTypePicker";
import styles from "../CargoTypeEditor/CargoTypeEditor.module.scss";
import { ICargoTypeView } from "sharedFolder/Models/IDetails";
import { mapCargoType } from "sharedFolder/mappers/mapCargoType";
import { ICargoTypeSearchResult } from "sharedFolder/components/common/interfaces";

interface IProps {
  disabled?: boolean;
  selectedItem?: ICargoTypeView;
  onChange: (value?: ICargoTypeView) => void;
  isValid?: boolean;
  focus?: boolean;
  label?: string;
}

export class NewCargoTypeEditor extends React.Component<IProps> {
  constructor(props: IProps) {
    super(props);
    this.onItemSelected = this.onItemSelected.bind(this);
    this.handleNotesChange = this.handleNotesChange.bind(this);
  }

  private handleNotesChange(value: string) {
    const selected = this.props.selectedItem || mapCargoType.emptyViewModel;
    this.props.onChange({
      ...selected,
      display: `${selected.name} ${value || ""}`,
      notes: value,
    });
  }

  private onItemSelected(selected?: ICargoTypeSearchResult) {
    if (selected) {
      this.props.onChange({
        ...(this.props.selectedItem || { notes: "" }),
        arcId: String(selected.key),
        name: selected.name,
        display: `${selected.name} ${this.props.selectedItem?.notes || ""}`,
      });
    } else {
      this.props.onChange();
    }
  }

  render() {
    return (
      <>
        {this.props.label && <label htmlFor="">{this.props.label}</label>}
        <div className={styles.groupInput}>
          <CargoTypePicker
            id={"CargoTypePicker"}
            isValid={this.props.isValid}
            selectedItem={this.props.selectedItem && toCargoTypeSearchResult(this.props.selectedItem)}
            onChange={this.onItemSelected}
            disabled={this.props.disabled}
            focus={this.props.focus}
          />
          <Notes
            className={styles.notes}
            dataTest="Cargo Type notes textarea"
            onChange={this.handleNotesChange}
            value={this.props.selectedItem && this.props.selectedItem.notes}
            ariaLabel="Cargo Type Notes text area"
            disabled={this.props.disabled}
          />
        </div>
      </>
    );
  }
}
