import React, { useImperativeHandle, useRef, useState, forwardRef, Ref, useEffect } from "react";
import classNames from "classnames";
import { ICellEditorParams } from "@ag-grid-enterprise/all-modules";
import { useSimpleEffect, useKeepRerenderingUntilTruthy, usePromise } from "@/utils";
import { Editor, EditorProps, PortalPopup } from "@/components";
import "./Editor.scss";

function EditorFrameworkComponent(params: Params, ref: Ref<any>) {
  const { api, colDef, context, props } = params;
  const [value, setValue] = useState(params.value);
  const colField = colDef.field;
  const contextProps = colField && context?.[colField];
  const container = useRef<HTMLDivElement>(null);
  const input = useRef<HTMLInputElement>(null);
  const rect = container.current?.getBoundingClientRect();
  const isContainerDrawn = rect?.width || rect?.height;
  const [isContainerDrawnPromise, resolveIsContainerDrawnPromise] = usePromise();

  props.className = classNames("Aggrid-editor", {
    visible: true,
  });

  useEffect(onIsContainerDrawnChange, [isContainerDrawn]);
  useSimpleEffect(focusInput, []);
  useImperativeHandle(ref, current.bind(null, value));
  useKeepRerenderingUntilTruthy(isContainerDrawn);

  return (
    <aggrid-editor-container ref={container}>
      {isContainerDrawn && (
        <PortalPopup onBackdropClick={onBackdropClick} target={container.current}>
          <Editor {...props} {...contextProps} value={value} onChange={onChange} input={input} />
        </PortalPopup>
      )}
    </aggrid-editor-container>
  );

  async function focusInput() {
    await isContainerDrawnPromise;

    input.current?.focus();
  }

  function onChange(value) {
    setValue(value);
  }

  function onBackdropClick() {
    api?.stopEditing();
  }

  function onIsContainerDrawnChange() {
    if (isContainerDrawn) {
      resolveIsContainerDrawnPromise();
    }
  }
}

function current(value) {
  return {
    getValue: () => {
      return value;
    },
    // isPopup: () => true, // https://ag-grid.zendesk.com/hc/en-us/requests/13982
  };
}

const ForwardRef = forwardRef(EditorFrameworkComponent);

export { ForwardRef as Editor };

interface Params extends ICellEditorParams {
  props: EditorProps;
}
