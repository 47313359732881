import { createContext } from "react";
import { IPermittedMethods } from "../Models/PermittedMethods";

interface INegotiationPermittedMethodsContext {
  permittedMethods: IPermittedMethods[];
}

const NegotiationPermittedMethodsContextComponent = createContext<INegotiationPermittedMethodsContext>({
  permittedMethods: [],
});

NegotiationPermittedMethodsContextComponent.displayName = "NegotiationPermittedMethodsContext";

export const NegotiationPermittedMethodsContext = NegotiationPermittedMethodsContextComponent;
