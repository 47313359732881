import * as React from "react";
import classNames from "classnames";
import { DatePicker } from "office-ui-fabric-react";
import { IconTypes } from "sharedFolder/components/common/types";
import styles from "./DatePicker.module.scss";

interface IDatePicker {
  allowTextInput?: boolean;
  className?: string;
  disabled?: boolean;
  icon?: IconTypes;
  onSelectDate: (date: Date | null | undefined) => void;
  value?: Date;
  dataTest?: string;
}

export default ({ allowTextInput, className, disabled, icon, onSelectDate, value, dataTest }: IDatePicker) => {
  const iconClassName = classNames(styles.icon, `icon--${icon ? icon : "date-range"}`);

  return (
    <DatePicker
      data-test={dataTest}
      allowTextInput={allowTextInput}
      className={className}
      data-component="<DatePicker />"
      disabled={disabled}
      onSelectDate={onSelectDate}
      styles={() => ({ icon: iconClassName })}
      value={value}
      showGoToToday={false}
    />
  );
};
