import dayjs from "dayjs";
import { uid } from "___REFACTOR___/utils";
import { tradeAPI, TradeAPI } from "___REFACTOR___/apis";
import * as Common from "___REFACTOR___/models/common";
import { DataModel } from "___REFACTOR___/models/DataModel";
import { CustomString } from "___REFACTOR___/models/CustomString";
import { Clddu } from "../Clddu";
import { Sharing } from "./Sharing";
import { createSchema } from "./schema";

class Props extends DataModel.Props {
  constructor(emailList: EmailList) {
    super();

    this.emailList = emailList;
    this.lastUpdated = new LastUpdated(emailList.lastUpdate);
    this.createdOn = new CreatedOn(emailList.createdOn);
    this.sharing = new Sharing(emailList);
    this.emailArr = [];
    this.emailMap = {};
  }
}

class EmailList extends DataModel {
  static Props = Props;
  static new(data: EmailList.Data) {
    return new EmailList(data);
  }
  static create = {
    schema: createSchema,
  };
  static get Email() {
    return Common.Email;
  }

  /**
   * For now bunch of immutability driven methods as we want the emailMap for performance reasons, however editing with formik does not reconstruct the instances (lodash/cloneDeep)
   * need to come up with a nice pattern to allow for future complex interactions between inheritance, Aggrid, Formik
   * this is beyond formik
   */
  static delete = {
    email: (instance: EmailList, index: number) => {
      const emailArr = [] as EmailList.Email[];
      const emailMap = { ...instance._.emailMap } as EmailList.EmailMap;
      const currentEmailArr = instance._.emailArr;

      for (let i = 0; i < currentEmailArr.length; i++) {
        const email = currentEmailArr[i];

        if (index !== i) emailArr.push(email);

        if (index === i) delete emailMap[email.value];
      }

      return { emailArr, emailMap };
    },
  };

  static add = {
    placeholders: (instance: EmailList, values: string[] = []) => {
      const emailArr = [] as EmailList.Email[];
      const emailMap = { ...instance._.emailMap };

      for (let i = 0; i < values.length; i++) {
        const value = values[i];
        const id = uid();
        const email = new EmailList.Email({ value, prevValue: value, id, isPlaceholder: true });

        if (!emailMap[value]) emailArr.push(email);

        emailMap[value] = email;
      }

      emailArr.push(...instance._.emailArr);

      for (let i = 0; i < emailArr.length; i++) {
        const email = emailArr[i];

        email.i = i;
      }

      return { emailArr, emailMap };
    },
    values: (instance: EmailList, values: string[] = []) => {
      const emailArr = [] as EmailList.Email[];
      const emailMap = { ...instance._.emailMap };

      for (let i = 0; i < values.length; i++) {
        const value = values[i];
        const id = uid();
        const email = new EmailList.Email({ value, prevValue: value, id });

        if (!emailMap[value]) emailArr.push(email);

        emailMap[value] = email;
      }

      emailArr.push(...instance._.emailArr);

      for (let i = 0; i < emailArr.length; i++) {
        const email = emailArr[i];

        email.i = i;
      }

      return { emailArr, emailMap };
    },
    clddu: {
      entity: (instance: EmailList, entity: Clddu.Entity) => {
        const userArr = entity.getUserArr();
        const emailMap = { ...instance._.emailMap };
        const emailArr = [] as EmailList.Email[];

        for (let i = 0; i < userArr.length; i++) {
          const user = userArr[i];
          const value = user.email;
          const id = uid();
          const email = new EmailList.Email({ value, prevValue: value, id });

          if (!emailMap[value]) emailArr.push(email);

          emailMap[value] = email;
        }

        emailArr.push(...instance._.emailArr);

        for (let i = 0; i < emailArr.length; i++) {
          const email = emailArr[i];

          email.i = i;
        }

        return { emailArr, emailMap };
      },
    },
  };

  constructor(data?: Partial<EmailList.Data>) {
    super(data, { suppressProps: true });

    this.sharing = this.sharing || [];

    this._ = new Props(this);

    const change = EmailList.add.values(this, this.values);

    this._.emailArr = change.emailArr;
    this._.emailMap = change.emailMap;
  }

  toJSON() {
    const { _, ...rest } = this;
    const json = {
      ...rest,
      sharing: _.sharing,
      values: _.emailArr,
    };

    return json;
  }

  get isShared() {
    return !!this.sharing.length;
  }

  get delete() {
    return tradeAPI.settings.emailLists.id.delete.bind(null, this.id, this.updateToken);
  }
}

EmailList.prototype.Props = Props;

class LastUpdated extends CustomString {
  toString() {
    return dayjs(this.data).format("DD MMM YY hh:mm A");
  }
}

class CreatedOn extends CustomString {
  toString() {
    return dayjs(this.data).format("DD MMM YY hh:mm A");
  }
}

export { EmailList, Props };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface EmailList extends EmailList.Data {
  Props: typeof Props;
  _: Props;
}

declare namespace EmailList {
  type Data = TradeAPI.EmailList;
  interface Props {
    emailList: EmailList;
    lastUpdated: LastUpdated;
    createdOn: CreatedOn;
    sharing: Sharing;
    emailArr: EmailList.Email[];
    emailMap: EmailMap;
  }

  type Email = Common.Email;

  interface EmailMap {
    [email: string]: Email;
  }
}

interface Props extends EmailList.Props {}
