import { observer } from "mobx-react";
import { Banner } from "@/components";
import { WithdrawalState } from "@/apis";
import { slideout } from "___REFACTOR___/services";
import moment from "moment";
import "./WithdrawBanner.scss";

function WithdrawBanner(props: Props) {
  const { status, withdrawalState, ribbonContext } = props;

  return (
    <Banner
      subClassName="orange"
      icon="info-outline"
      label={<WithdrawalBannerLabel withdrawalState={withdrawalState} ribbonContext={ribbonContext}></WithdrawalBannerLabel>}
      className="BrokerBanner"
      isClosable={false}
      hidden={status != "Withdrawn"}
    />
  );
}

function WithdrawalBannerLabel(props: LabelProps) {
  const { withdrawalState, ribbonContext } = props;

  const tooltip = ribbonContext == "Negotiation" ? "You can no longer submit INDICATIONS or OFFERS." : "";
  let timeString = "";
  if (withdrawalState) {
    const momentTime = moment(withdrawalState.withdrawnOn);
    timeString = momentTime.format(" [at] h:mm A [UTC] on MMMM D, YYYY").toString();
  }

  const label = `Order and all negotiations withdrawn${timeString}. ${tooltip}`;
  if (!withdrawalState?.reason) {
    return (
      <div className="withdrawal-banner-label">
        <p>{label}</p>
      </div>
    );
  }

  return (
    <div className="withdrawal-banner-label">
      <p>{label}</p>
      <a onClick={() => slideout.open(<WithdrawalReason reason={withdrawalState.reason}></WithdrawalReason>)}>
        {"See reason for withdrawal"}
      </a>
    </div>
  );
}

function WithdrawalReason(props: ReasonProps) {
  const { reason } = props;
  return (
    <withdrawal-reason>
      <app-h2>Reason for withdrawal</app-h2>
      <withdrawal-reason-content>{reason}</withdrawal-reason-content>
    </withdrawal-reason>
  );
}

const Observer = observer(WithdrawBanner);

export { Observer as WithdrawBanner };

export type ObjectContext = "Negotiation" | "Order";

interface ReasonProps {
  reason: string;
}

interface LabelProps {
  withdrawalState?: WithdrawalState;
  ribbonContext: ObjectContext;
}

interface Props {
  status?: string;
  withdrawalState?: WithdrawalState;
  ribbonContext: ObjectContext;
}
