import React, { Children, cloneElement, ReactNode } from "react";
import classNames from "classnames";
import { useShallowStateOld } from "@/utils";
import { DataModel } from "@/models";
import { InputProps, InputFocusEvent } from "../Input";
import { Icon } from "../Icon";
import "./Container.scss";

export function Container(props: Props) {
  let { desc, type, status, img, icon, unclearable, dataTest, value, checkedValue, contentBasedWidth, onClick, hidden } = props;
  const [state, setState] = useShallowStateOld(defaultState);
  const isCheckable = type === "Checkbox" || type === "Radio";
  const checked = isCheckable && checkedValue === value;
  let hasValue = value;

  if (value instanceof DataModel) {
    hasValue = value?.toJSON();

    value = value?.toString();
  }

  const clearable = props.clearable && !unclearable && hasValue;
  const className = classNames(props.className, status?.type, {
    icon: icon || img,
    value,
    status,
    clearable,
    checked,
    ...state,
  });
  const children = Children.map(props.children, mapChild);

  if (hidden) return null;

  function mapChild(child: ReactNode) {
    if (!child) return child;

    // @ts-ignore
    return cloneElement(child, { ...child?.props, onFocus, onBlur });
  }

  function onClear() {
    if (props.onClear) {
      props.onClear();

      return;
    }

    let cleared = null;

    // @ts-ignore
    if (value instanceof DataModel) cleared = new value.constructor();

    props.onChange?.(cleared);
  }

  function onFocus(e: InputFocusEvent) {
    setState({ focus: true });

    props?.onFocus?.(e);
  }

  function onBlur(e: InputFocusEvent) {
    setState({ focus: false });

    props?.onBlur?.(e);
  }

  function onMouseEnter(e) {
    setState({ mouseover: true });
  }

  function onMouseLeave(e) {
    setState({ mouseover: false });
  }

  return (
    <sea-input class={className} title={desc} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onClick}>
      <Icon icon={icon} img={img} dataTest={dataTest} />
      {children}
      <Icon onClick={onClear} hidden={!clearable} icon="close" align="right" dataTest={dataTest} />
      {contentBasedWidth && <sea-input-content-width-provider>{value}</sea-input-content-width-provider>}
    </sea-input>
  );
}

const defaultState = {
  focus: false,
  mouseover: false,
} as State;

interface Props extends InputProps {
  children: React.ReactNode;
  checkedValue?: any;
  type?: string;
  onClick?: () => void;
}

interface State {
  focus: boolean;
  mouseover: boolean;
}
