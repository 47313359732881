import React from "react";
import classNames from "classnames";
import * as ClassNames from "classnames";
import "./Body.scss";

function Body(props: Body.Props) {
  let { className, ...rest } = props;

  className = classNames(className);

  return <app-form-body class={className} {...rest} />;
}

export { Body };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace Body {
  export interface Props extends Omit<ReactHTMLFormProps, "className"> {
    className?: ClassNames.Argument;
    disabled?: boolean;
  }

  type ReactHTMLFormProps = React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLDivElement>, HTMLDivElement>;
}
