import { NumberField } from "___REFACTOR___/components";
import { PrimitiveDataModel, PrimitiveDataModelProps } from "../PrimitiveDataModel";

class CustomNumber extends PrimitiveDataModel {
  constructor(data: Data) {
    super(data);
  }
}

class CustomNumberProps extends PrimitiveDataModelProps {}

CustomNumber.prototype.Props = CustomNumberProps;
CustomNumberProps.prototype.Editor = NumberField;

export { CustomNumber, CustomNumberProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface CustomNumber {
  Props: typeof CustomNumberProps;
  _: CustomNumberProps;

  data: Data;
}

type Data = number;
