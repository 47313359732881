import { getNegotiationApiService } from "../../api/negotiations/Negotiations";
import { IVesselEdit } from "../../api/negotiations/models/INegotiationUpdate";
import { IVesselView } from "sharedFolder/Models/IDetails";

export function editVessel(
  ctradeUrl: string,
  orderId: string,
  negId: string,
  updateToken: string,
  vessel: IVesselView,
  chartererCompanyId?: string
) {
  const editVessel = getNegotiationApiService(ctradeUrl, orderId).editVessel;
  const body: IVesselEdit = {
    action: "update",
    details: {
      ownerChain: vessel.ownerChain || "",
      speedAndConsumption: vessel.speedAndConsumption || "",
      additionalNotes: vessel.additionalNotes || "",
      vesselDescription: vessel.vesselDescription || "",
      eta: vessel.eta || "",
      itinerary: vessel.itinerary || "",
    },
    vesselIMO: vessel.vesselIMO,
    updateToken,
  };
  return editVessel(orderId, negId, body, chartererCompanyId);
}
