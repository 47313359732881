import { memo } from "react";
import classNames from "classnames";
import ReactSplit from "react-split";
import { ElementHOC } from "../Element";
import "./Split.scss";

function Split(props) {
  let { element } = props;

  element = { ...element };

  element.className = classNames(element.className, "Split");

  const { children, ...rest } = element;

  if (!children) return null;

  return <ReactSplit {...rest}>{children.map(ElementHOC)}</ReactSplit>;
}

const Memo = memo(Split);

export { Memo as Split };
