import React from "react";
import { observer } from "mobx-react";
import { timezones } from "___REFACTOR___/stores";
import { Timezone } from "___REFACTOR___/models";
import { SelectField } from "___REFACTOR___/components";

function _TimezoneEditor(props: TimezoneEditor.Props) {
  const { dataTest, value } = props;
  const timezone = timezones.dataById[value!];

  return (
    <SelectField
      {...props}
      editorType="Timezone"
      value={timezone}
      onChange={onChange}
      data={timezones.data}
      dataTest={`${dataTest}.value`}
      filterKey="display"
      noDataStatus={noDataStatus}
    />
  );

  function onChange(timezone: Timezone) {
    props.onChange?.(timezone.id);
  }
}

const noDataStatus = { message: "No matching timezones" };

const TimezoneEditor = observer(_TimezoneEditor);

export { TimezoneEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace TimezoneEditor {
  export interface Props extends SelectField.Props {
    value: Value;
    onChange: (value: Value) => void;
  }

  export type Value = string | undefined;
}
