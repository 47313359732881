import React, { useState } from "react";
import dayjs from "dayjs";
import classNames from "classnames";
import { useSimpleEffect, wait } from "@/utils";

export function Timepassed(props: Props) {
  props = { ...props };

  props.className = classNames(props.className, "timepassed");

  const { since, textOnly } = props;

  const [counter, update] = useState(0);
  let timepassed = "";
  let interval = msinsecond;

  if (since) {
    const diff = dayjs(since).diff(new Date());

    if (diff < msinminute) interval = msinsecond;
    else if (diff < msinhour) interval = msinminute;
    else if (diff < msinday) interval = msinhour;

    timepassed = dayjs(since).fromNow();
  }

  useSimpleEffect(rerender, [counter]);

  async function rerender() {
    await wait(interval);

    update(counter + 1);
  }

  const content = textOnly ? <>{timepassed}</> : <span className={props.className}>{timepassed}</span>;

  return content;
}

const msinsecond = 1000;
const msinminute = 60 * msinsecond;
const msinhour = 60 * msinminute;
const msinday = 24 * msinhour;

interface Props {
  className?: string;
  since: string | null | undefined;
  textOnly?: boolean;
}
