import { action, makeObservable, observable } from "mobx";
import { TradeAPI } from "___REFACTOR___/apis";
import { DataModel } from "../DataModel";
import { ChartererAccount, OwnerAccount } from "../Account";
import { Period } from "../Period";
import { Laycan } from "../Laycan";
import { AddressCommission } from "../AddressCommission";
import { BrokerCommission } from "../BrokerCommission";
import { CargoSize } from "../CargoSize";
import { CargoType } from "../CargoType";
import { VesselSize } from "../VesselSize";
import { Duration } from "../Duration";
import { DischargeLocation } from "../DischargeLocation";
import { DeliveryLocation } from "../DeliveryLocation";
import { RedeliveryLocation } from "../RedeliveryLocation";
import { LoadLocation } from "../LoadLocation";
import { ViaLocation } from "../ViaLocation";
import { Nominations } from "../Nominations";
import { Liftings } from "../Liftings";
import { COACargoSize } from "../COACargoSize";
import { CLDDUBroker, CLDDUCharterer } from "../CLDDU";
import { OrderNegStore } from "../OrderNegStore";
import { COANotes, TCTNotes, VOYNotes } from "../OrderNotes";
import { TradingExclusions, CargoExclusionsText } from "../Subs";
import { Attachment } from "../Trade/Order/Attachment";
import { OrderProps } from "./Props";
import { FreightRate } from "../FreightRate";
import { Demurrage } from "../Demurrage";
import { HireRate } from "../HireRate";
import { CleaningPrice } from "../CleaningPrice";
import { SupplyPrice } from "../SupplyPrice";
import { BallastBonus } from "../BallastBonus";
import { Vessels } from "../Vessels";
import { BunkerDelivery } from "../Bunker/BunkerDelivery";
import { BunkerRedelivery } from "../Bunker/BunkerRedelivery";

class Order extends DataModel {
  static Attachment = Attachment;

  constructor(data?: Order.PartialData, props?: Partial<OrderProps.ConstructorProps>) {
    super(data, { suppressProps: true });

    this._ = new OrderProps({ ...props, order: this });

    if (!this.isDataStale(data)) {
      this.assignData(data);
    }

    if (!this.isDataStale(this._.orderNegStore?.order)) {
      this.assignData(this._.orderNegStore?.order);
    }

    this.makeObservable();
  }

  get hasData() {
    return !!this.updateToken;
  }

  get legacyOrderType() {
    return this.types.includes("Coa") ? "COA" : "ITO";
  }

  toJSON() {
    const { _, ...rest } = this;

    return rest;
  }

  update(data?: Order.PartialData, props?: OrderProps, updateConfig = defaultUpdateConfig) {
    if (!data) return;
    if (this.isDataStale(data)) return;

    this.assignData(data);
    this._.version++;

    if (updateConfig.shouldUpdateOrderNegStore) {
      this._.orderNegStore?.store.upsertOrder(data, { shouldUpdateModel: false, callee: this });
    }
  }

  isDataStale(data?: Order.PartialData, props?: OrderProps, updateConfig = defaultUpdateConfig) {
    if (updateConfig?.forceUpdate) return false;

    if (!data) return true;

    const incomingVersion = data.version || 0;
    const incomingLastUpdated = data.lastUpdated || 0;

    const isCurrentVersionSameOrNewer = incomingVersion <= this.version;
    const isCurrentLastUpdatedSameOrNewer = new Date(incomingLastUpdated) <= new Date(this.lastUpdated);

    const isStale = isCurrentVersionSameOrNewer || isCurrentLastUpdatedSameOrNewer;

    return isStale;
  }

  makeObservable() {
    Object.assign(this, {
      version: this.version || undefined,
      lastUpdated: this.lastUpdated || undefined,
      distributionList: this.distributionList || undefined,
    });

    makeObservable(this, { version: observable, lastUpdated: observable, distributionList: observable.ref, update: action });
  }
}

Order.prototype.Props = OrderProps;

const defaultUpdateConfig = {
  shouldUpdateOrderNegStore: true,
} as Order.UpdateConfig;

export { Order };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface Order extends TradeAPI.Order {
  Props: typeof OrderProps;
  _: OrderProps;
}

declare namespace Order {
  namespace T {
    export { Attachment };
  }

  type PartialData = Partial<TradeAPI.Order>;

  interface UpdateConfig {
    forceUpdate?: boolean;
    shouldUpdateOrderNegStore?: boolean;
    orderNegStore?: {
      upsertConfig: OrderNegStore.Order.UpsertConfig;
    };
  }

  namespace Stage {
    type All = TradeAPI.Order.Status.All | "InactiveNegotiations" | "FirmBid" | "FirmOffer";

    namespace Metadata {}
    interface Metadata {
      value: Stage.All;
      label: string;
    }
  }
}

export interface OrderCoaDetails {
  coaNotes: COANotes;
  coaCargoSize?: COACargoSize;
}

export interface OrderExtraDetails {
  liftings?: Liftings;
  nominations?: Nominations;
  brokerContact?: CLDDUBroker;
  chartererContact?: CLDDUCharterer;
}

export interface OrderDetails {
  laycan?: Laycan;
  vessels?: Vessels;
  vesselSize?: VesselSize;
  addressCommission?: AddressCommission;
  brokerCommission?: BrokerCommission;
  chartererAccount?: ChartererAccount;
  ownerAccount?: OwnerAccount;
  period?: Period;
  loadLocation?: LoadLocation;
  dischargeLocation?: DischargeLocation;
  cargoType?: CargoType;
  cargoSize?: CargoSize;
  duration?: Duration;
  deliveryLocation?: DeliveryLocation;
  viaLocation?: ViaLocation;
  redeliveryLocation?: RedeliveryLocation;
  tctNotes?: TCTNotes;
  voyageNotes?: VOYNotes;
  freightRate?: FreightRate;
  demurrage?: Demurrage;
  hireRate?: HireRate;
  cleaningPrice?: CleaningPrice;
  supplyPrice?: SupplyPrice;
  tradingExclusions?: TradingExclusions;
  bunkerDelivery?: BunkerDelivery;
  bunkerRedelivery?: BunkerRedelivery;
  cargoExclusionsText?: CargoExclusionsText;
  ballastBonus?: BallastBonus;
}
