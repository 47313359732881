import React, { useContext } from "react";
import { observer } from "mobx-react";
import { router } from "@/models";
import { Button, NegotiableDetailSummary } from "@/components";
import { NegotiationTypeEvents, usageMetrics } from "@/services/UsageMetrics";
import { Context } from "../../NegotiationDetail";
import "./Firm.scss";

function Firm() {
  const { negotiation } = useContext(Context);
  const actions = negotiation.getActionsModel();
  const { firmOfferAccepted } = actions || {};
  const mainTermsAbsPath = router.get("mainTerms").compile({ orderId: negotiation.orderId, negotiationId: negotiation.id });

  return (
    <div className="Firm">
      <NegotiableDetailSummary negotiation={negotiation} side={firmOfferAccepted ? "offer" : "bid"} />
      <Button
        variant="action"
        to={mainTermsAbsPath}
        onClick={() => usageMetrics.trackEvent(NegotiationTypeEvents.PROCEEED_TO_MAIN_TERMS)}
      >
        proceed to Main Terms
      </Button>
    </div>
  );
}

const Observer = observer(Firm);

export { Observer as Firm };
