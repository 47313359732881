import numeral from "numeral";

/**
 * Formats a string or a number a currency based on the precision given. If no precision given, it formats it to 2dp
 * Returns null if given value is not numeric, or if number is less than zero or exceeds `max`.
 */

type Currency = {
  value?: string | number | null;
  precision?: number;
  max?: number;
};
function formatCurrency({ value, precision = 3, max = 100000000 }: Currency): string | null {
  const numValue = typeof value === "string" ? Number(value) : value;
  if (numValue === undefined || numValue === null || isNaN(numValue) || numValue > max || numValue < 0) {
    return null;
  }

  return numeral(value).format(`0,0.${Array(precision + 1).join("0")}`);
}

export { formatCurrency };
