import { memo } from "react";
import classNames from "classnames";
import { CheckboxInput, RadioInput, StatusMessages } from "@/components";
import { ElementHOC } from "../Element";
import "./CollapsibleGroup.scss";

function CollapsibleGroup(props) {
  const { element, status, ...editorProps } = props;
  let { children, collapseControl, label, getState, dataTest } = element;
  const state = getState?.(props); // eslint-disable-line;
  const { collapsed, onCollapse } = state || {};
  const className = classNames(element.className, "CollapsibleGroup", {
    error: status?.type === "error",
  });

  if (collapsed) children = null;

  function _onCollapse() {
    editorProps.onBlur();

    onCollapse();
  }

  const header = label && (
    <header className="CollapsibleGroup-header">
      {collapseControl === "checkbox" && <CheckboxInput onChange={_onCollapse} value={!collapsed} dataTest={dataTest} />}
      {collapseControl === "radio" && <RadioInput onChange={_onCollapse} value={!collapsed} dataTest={dataTest} />}
      <h2>{label}</h2>
    </header>
  );

  return (
    <div className={className}>
      {header}
      <StatusMessages status={status} dataTest={dataTest} />
      {children?.map(ElementHOC)}
    </div>
  );
}

const Memo = memo(CollapsibleGroup);

export { Memo as CollapsibleGroup };
