// BLACKBOX
import { FetchInterceptor } from "__legacy/dashboard/components/FetchInterceptor/FetchInterceptorOld";
import { OwnerNegotiationProvider } from "ownerDashboard/context/owner-negotiation-context";
import DashboardForm from "ownerDashboard/components/Form/DashboardForm";
import { UserProvider } from "__legacy/dashboard/contexts/UserProvider";
import { ConfigurationProvider } from "__legacy/sharedFolder/ConfigurationContext";

import React from "react";
import { config } from "@/config";
import "./Liftings.scss";

export function Liftings() {
  return (
    <div className="OrderNegotiationLiftings LEGACY_CONTAINER">
      <FetchInterceptor baseUrl={config.ctradeUrl} exceptions={["/token$"]}>
        <ConfigurationProvider value={config}>
          <UserProvider config={config}>
            <OwnerNegotiationProvider>
              <DashboardForm />
            </OwnerNegotiationProvider>
          </UserProvider>
        </ConfigurationProvider>
      </FetchInterceptor>
    </div>
  );
}
