import { Observable, ReplaySubject, combineLatest } from "rxjs";
import { DetailType, CombinedDetailType } from "../../../models/common/DetailType";
import { map, scan } from "rxjs/operators";
import { IGetView } from "sharedFolder/Models/Negotiation/IGetView";
import { moveNegotiationToOrder, updateNote, visibleToCharterer } from "./update";
import { IBidOfferView } from "sharedFolder/Models/Negotiation/NegotiationViews";
import { Actors } from "sharedFolder/Models/Actors";
import { NegotiationSide } from "sharedFolder/Models/Direction";
import { Role } from "../../../components/negotiate/Negotiation/Role";
import { OrderNegType } from "sharedFolder/Models/OrderNegType";
import { INegotiationView, INegotiationDetailsView } from "sharedFolder/Models/Negotiation/INegotiationView";

export interface INegotiations {
  orderId: string;
  negotiations: INegotiationView[];
}

interface INegotiationUpdate {
  orderId: string;
  negotiationId: string;
  detail: DetailType;
  value: any;
  direction: NegotiationSide;
  actor: Actors;
  version: number;
}

interface INegotiationDelete {
  orderId: string;
  negotiationId: string;
}

interface IVersionUpdate {
  id: string;
  version: number;
}

interface INegotiationService {
  moveNegotiationToOrder: (targetOrderId: string, negotiation: IGetView<any>) => Observable<INegotiations>;
  updateNote: (
    orderId: string,
    orderType: OrderNegType,
    negotiationId: string,
    role: Role,
    note: string,
    updateToken: string
  ) => Observable<IVersionUpdate>;
  visibleToCharterer: (
    orderId: string,
    orderType: OrderNegType,
    negotiationId: string,
    updateToken: string,
    visibility: boolean
  ) => Observable<IVersionUpdate>;
}

/**
 * Central service enabling optimistic updates for Negotiations.
 *
 * Creating one or more new negotiations will emit a negotiation in a temporary loading state (without details).
 *
 * Submitting bid, offer and accept will update the affected negotiation immediately without waiting for an API response.
 *
 * Resolve this service using getNegotiationService() provider method to
 * ensure the same service instance is used throughout different consumers.
 */
class NegotiationService implements INegotiationService {
  private readonly listSubjects: {
    [orderId: string]: ReplaySubject<INegotiations>;
  } = {};

  private readonly listMutationSubject = new ReplaySubject<INegotiationUpdate>(1);

  private readonly listDeleteSubject = new ReplaySubject<INegotiationDelete>(1);

  commonHeaders = {
    "X-API-Version": "2",
  };

  // TODO: inject notifications
  constructor(private apiUrl: string) {
    // This is because combineLatest in the accumulate function won't be fired until all the observables have an initial value
    this.listMutationSubject.next(undefined);
    this.listDeleteSubject.next(undefined);
  }

  /**
   * This function does the following:
   * 1st call the correct API to make the change for the neg to be moved to the target order
   * 2nd optimistically remove the negotiation from the source order
   * 3rd Update the orders pane's order details to reflect things like number of negs and Status (this is handled by getAll subscriptions on the Negotiations component)
   * @param orderId
   * @param negotiationId
   */
  public moveNegotiationToOrder(targetOrderId: string, negotiation: IGetView<any>): Observable<INegotiations> {
    const sourceOrderId = negotiation.orderId;
    // fetch targetOrderId negotiationId

    moveNegotiationToOrder(this.apiUrl, negotiation.id, sourceOrderId, targetOrderId, negotiation.type, negotiation.updateToken);

    this.listDeleteSubject.next({
      orderId: sourceOrderId,
      negotiationId: negotiation.id,
    });
    return accumulate(this.listSubjects[sourceOrderId], this.listMutationSubject, this.listDeleteSubject);
  }

  public updateNote(
    orderId: string,
    orderType: OrderNegType,
    negotiationId: string,
    role: Role,
    note: string,
    updateToken: string
  ): Observable<IVersionUpdate> {
    return updateNote(this.apiUrl, orderId, orderType, negotiationId, role, note, updateToken);
  }

  public visibleToCharterer(
    orderId: string,
    orderType: OrderNegType,
    negotiationId: string,
    updateToken: string,
    visibility: boolean
  ) {
    return visibleToCharterer(this.apiUrl, orderId, orderType, negotiationId, updateToken, visibility);
  }
}

/**
 * Given a sequence of negotiations collections,
 * ensures that the most recent neg versions overwrite the previous
 * @param negs Observable stream of negotiations
 */
function accumulate(
  negs: Observable<INegotiations>,
  updates: Observable<INegotiationUpdate>,
  listDeleteSubject: Observable<INegotiationDelete>
) {
  const sortByInvitee = (a: IGetView<any>, b: IGetView<any>) => (a.invitee > b.invitee ? 1 : -1);
  const latestVersionNegotiation = negs.pipe(
    scan((acc, seed) => ({
      orderId: seed.orderId,
      negotiations: [...seed.negotiations, ...acc.negotiations.filter((a) => !seed.negotiations.some((s) => s.id === a.id))].sort(
        sortByInvitee
      ),
    }))
  );

  // combine the latest list of negotiations
  // with the most recent mutations
  return combineLatest(latestVersionNegotiation, updates, listDeleteSubject).pipe(
    map((updates) => {
      type BidOfferView = { [key in NegotiationSide]: any };
      const [negs, update, deleted] = updates;
      if (update !== undefined && negs.orderId === update.orderId) {
        const negotiation = negs.negotiations.find((n) => n.id === update.negotiationId);
        if (negotiation && negotiation.details && negotiation.version <= update.version) {
          const detail = getDetail(negotiation.details, update.detail as CombinedDetailType);
          if (detail) {
            (detail as BidOfferView)[update.direction] = update.value;
            detail.lastUpdated = update.direction;
            negotiation.lastUpdate = new Date().toUTCString();
          }
        }
        // filter out the deleted negs
        if (deleted !== undefined && negs.orderId === deleted.orderId) {
          const negToDelete = negs.negotiations.find((n) => n.id === deleted.negotiationId);
          if (negToDelete) {
            negs.negotiations = negs.negotiations.filter((n) => n.id !== negToDelete.id);
          }
        }
      }
      return negs;
    })
  );
}

function getDetail(details: INegotiationDetailsView, type: CombinedDetailType): IBidOfferView<any> | undefined {
  if (Object.prototype.hasOwnProperty.call(details, type)) {
    type DetailType = { [key in CombinedDetailType]: any };
    return (details as DetailType)[type];
  }
  return undefined;
}

/**
 * Canonical store of NegotiationServices, one per API endpoint.
 * (yes, this is probably overkill but it's the best way to ensure the same
 * instance is provided wheneger getNegotiationService provider is called)
 */
const services: { [key: string]: INegotiationService } = {};
/**
 * Service provider for Negotiation API client.
 * Will return the same instance for all calls for the same API URL
 * @param apiUrl The API URL
 */
export function getNegotiationService(apiUrl: string) {
  if (!Object.prototype.hasOwnProperty.call(services, apiUrl)) {
    services[apiUrl] = new NegotiationService(apiUrl);
  }

  return services[apiUrl];
}
