import React from "react";
import { CheckboxInput } from "___REFACTOR___/components";
import { Field } from "../../Field";
import { Container } from "../../Container";

function CheckboxField(props: CheckboxField.Props) {
  return (
    <Container {...props} type="Checkbox">
      <CheckboxInput {...props} />
    </Container>
  );
}

export { CheckboxField };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace CheckboxField {
  export interface Props extends Omit<CheckboxInput.Props, "type">, Omit<Field.Props, "value" | "onChange"> {}

  export { CheckboxInput as Input };
}
