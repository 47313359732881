import React, { Component } from "react";
import classNames from "classnames";
import Checkbox from "__legacy/sharedFolder/components/common/Checkbox/Checkbox";
import { MinimalConfigurationContext } from "__legacy/sharedFolder/MinimalConfigurationContext";
import { INegotiationCreateView, INegotiationCreate } from "./models/NegotiationCreateView";
import { IValidationHandler, ValidationHandler, IValidationContext } from "sharedFolder/contexts/ValidationContext";
import { withValidationContext } from "sharedFolder/contexts/withValidationContext";
import { NegotiationDetails } from "./components/createNegotiation/NegotiationDetails";
import Heading from "sharedFolder/components/common/Heading/Heading";
import styles from "./CreateNegotiation.module.scss";

interface ICreateNegotiationsProps {
  className?: string;
  ctradeUrl: string;
  orderId: string;
  view: INegotiationCreateView;
  onVoyOrTctSelect: (check: "VOY" | "TCT") => void;
  handleDetailsChanged: (newDetails: INegotiationCreate) => void;
}

interface ICreateNegotiationState {
  view: INegotiationCreateView;
  validationMode: boolean;
  saving: boolean;
}

/**
 * Create Negotiation.
 * Conditionally prepopulate from an order, if orderId exists in querystring
 */

class CreateNegotiationComponent extends Component<ICreateNegotiationsProps & IValidationContext, ICreateNegotiationState> {
  private readonly validationHandler: IValidationHandler;

  constructor(props: ICreateNegotiationsProps & IValidationContext) {
    super(props);

    this.validationHandler = new ValidationHandler();

    this.state = {
      validationMode: false,
      saving: false,
      view: this.props.view,
    };
  }

  public componentDidMount() {
    this.props.fieldValidityChanged("voyOrTCTSelected", true);
  }

  public render() {
    const showOrderTypesNotSelectedError = this.state.validationMode && !this.props.isFieldValid("voyOrTCTSelected");

    return (
      <MinimalConfigurationContext.Provider
        value={{
          ctradeUrl: this.props.ctradeUrl,
        }}
      >
        <>
          <div className={styles.orderTypeRow}>
            <Checkbox
              className={classNames(showOrderTypesNotSelectedError && styles.error)}
              defaultChecked={this.props.view.voySelected}
              onChange={() => this.props.onVoyOrTctSelect("VOY")}
            />
            <Heading className={classNames(showOrderTypesNotSelectedError && styles.error)}>Voyage Terms</Heading>
          </div>
          {this.props.view.voySelected && (
            <NegotiationDetails
              details={this.props.view.details.details}
              onChanged={this.props.handleDetailsChanged}
              orderType="VOY"
            />
          )}
          <div className={styles.orderTypeRow}>
            <Checkbox
              className={classNames(showOrderTypesNotSelectedError && styles.error)}
              defaultChecked={this.props.view.tctSelected}
              onChange={() => this.props.onVoyOrTctSelect("TCT")}
            />
            <h1 className={classNames(showOrderTypesNotSelectedError && styles.error)}>Time Charter Terms</h1>
          </div>
          {this.props.view.tctSelected && (
            <NegotiationDetails
              details={this.props.view.details.details}
              onChanged={this.props.handleDetailsChanged}
              orderType="TCT"
            />
          )}
        </>
      </MinimalConfigurationContext.Provider>
    );
  }
}
//this is the voyage terms/TC terms for ITO
export const CreateNegotiationITO = withValidationContext(CreateNegotiationComponent);
