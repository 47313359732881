import React, { Component, createRef } from "react";
import { IValidationContext } from "sharedFolder/contexts/ValidationContext";
import { withValidationContext } from "sharedFolder/contexts/withValidationContext";
import styles from "./Invitees.module.scss";
import tokenSearchStyles from "__legacy/sharedFolder/styles/token-search-styles.module.scss";
import { FocusContext } from "../common/Context/FocusContext";
import classnames from "classnames";
import { isEmailValid } from "sharedFolder/Utilities/emailValidator";
import { BLOCKED_DOMAIN } from "sharedFolder/Utilities/blockedDomains";

interface IInviteesProps {
  className?: string;
  orderId?: string;
  invitees?: string[];
  placeholder?: string;
  onInviteesChanged: (emails: string[]) => void;
}

interface ITag {
  val: string;
  valid: boolean;
  nonWhitelistedDomain?: string;
}

interface IInviteesState {
  valid: boolean;
  tags: Array<ITag>;
  nonWhitelistedDomainInInput?: string;
}

/**
 * Placeholder for Negotiations widget
 */
class InviteesComponent extends Component<IInviteesProps & IValidationContext, IInviteesState> {
  private tagInput = createRef<HTMLInputElement>();

  constructor(props: IInviteesProps & IValidationContext) {
    super(props);
    this.handleInviteesChange = this.handleInviteesChange.bind(this);
    this.isValid = this.isValid.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onPaste = this.onPaste.bind(this);
    this.handlePotentialEmails = this.handlePotentialEmails.bind(this);

    const valid = this.isValid(this.props.invitees);
    this.state = {
      valid: valid,
      tags: [],
    };
    this.props.fieldValidityChanged("invitees", valid);
  }

  componentDidUpdate(prevProps: IInviteesProps, prevState: IInviteesState) {
    if (this.props.invitees && JSON.stringify(prevProps.invitees) !== JSON.stringify(this.props.invitees)) {
      const tags = this.createTagsFromEmails(this.props.invitees);
      const newTags = [...tags];

      // find at least an invalid email or input is empty
      this.checkAndUpdateValidity(newTags);

      this.setState({
        tags: newTags,
      });
    }
  }

  /**
   * no need to check for required, invitees are always required.
   * @param invitees
   */
  private isValid(invitees?: string[]): boolean {
    if (invitees === undefined || invitees.length === 0) {
      return false;
    } else {
      const invalidEmails = invitees.filter((item: string) => {
        return !isEmailValid(item.trim()) && !resolveNonWhitelistedDomain(item);
      });

      const containsAnAddress = invitees.length > 0;
      return invalidEmails.length === 0 && containsAnAddress;
    }
  }

  private handleInviteesChange(newTags: ITag[]) {
    const validEmails = newTags.filter((tag: ITag) => tag.valid).map((item: ITag) => item.val);
    this.props.onInviteesChanged?.(validEmails);

    this.checkAndUpdateValidity(newTags);

    this.setState({
      tags: newTags,
    });
  }

  private createTagsFromEmails(emails: string[]) {
    // filter actual emails
    const potentialEmails = emails.filter((email) => email.trim() !== "");
    const newEmails = potentialEmails.map((email: string) => {
      const nonWhitelistedDomain = resolveNonWhitelistedDomain(email);
      const valid = !nonWhitelistedDomain && isEmailValid(email);

      return {
        val: email,
        valid,
        nonWhitelistedDomain,
      };
    });

    return newEmails;
  }

  private checkAndUpdateValidity(tags: ITag[]) {
    // find at least an invalid email or input is empty
    const isValid = tags.length > 0 && tags.every((item: ITag) => item.valid === true);
    this.props.fieldValidityChanged("invitees", isValid);

    this.setState({
      valid: isValid,
    });
  }

  private removeTag(i: number) {
    const newTags = [...this.state.tags];
    newTags.splice(i, 1);
    this.handleInviteesChange(newTags);
  }

  private onKeyDown(e: React.KeyboardEvent) {
    const isKeyDelimeter = e.key === " " || e.key === "," || e.key === ";";
    const val: string = (this.tagInput.current && this.tagInput.current.value && this.tagInput.current.value.trim()) || "";
    if (isKeyDelimeter && val) {
      setTimeout(() => {
        const nonWhitelistedDomain = resolveNonWhitelistedDomain(val);
        const valid = !nonWhitelistedDomain && isEmailValid(val);

        const newTags = [
          ...this.state.tags,
          {
            val: val,
            valid,
            nonWhitelistedDomain,
          },
        ];
        this.handleInviteesChange(newTags);

        if (this.tagInput.current) {
          this.tagInput.current.value = "";
        }
      }, 1);
    } else if (e.key === "Backspace" && (!val || val.length === 0)) {
      this.removeTag(this.state.tags.length - 1);
    }
  }

  private onPaste() {
    // set timeout for 1 tick so as the data are pasted in the input field
    setTimeout(() => {
      this.handlePotentialEmails();
    }, 1);
  }

  private handlePotentialEmails() {
    const val = (this.tagInput.current && this.tagInput.current.value) || "";
    if (!val) {
      return;
    }
    // replace all ; and , with ' '
    const spaceDelimiterEmails = val.replace(/;|,/g, " ");
    const emails = spaceDelimiterEmails.split(" ");
    const newTags = this.createTagsFromEmails(emails);
    const tags = [...this.state.tags, ...newTags];

    this.handleInviteesChange(tags);

    if (this.tagInput.current) {
      this.tagInput.current.value = "";
    }
  }

  public render() {
    const { tags } = this.state;
    const isInvalid = this.state.valid ? "" : "error-state";
    const nonWhitelisted = tags.reduce(reduceTagsToNonWhitelistedDomains, []);
    const nonWhitelistedString = nonWhitelisted.join(", ");

    return (
      <FocusContext.Consumer>
        {(focus) => (
          <>
            <div className={`${styles.inputTagContainer} ${isInvalid}`}>
              <ul className={styles.inputTagList}>
                {tags.map((tag, i) => (
                  <li
                    key={tag.val + i}
                    className={classnames(tokenSearchStyles.tokenSearchItem, !tag.valid && styles.error)}
                    onClick={() => {
                      this.removeTag(i);
                    }}
                  >
                    {tag.val}
                  </li>
                ))}
                <li className={styles.emailsInput}>
                  <input
                    type="text"
                    data-test="invitees"
                    onKeyDown={this.onKeyDown}
                    ref={this.tagInput}
                    onPaste={this.onPaste}
                    onBlur={this.handlePotentialEmails}
                    autoFocus={focus}
                    placeholder={tags.length ? "" : this.props.placeholder || ""}
                  />
                </li>
              </ul>
            </div>
            <div className={styles.nonWhitelistedDomainMsg} hidden={nonWhitelisted.length !== 1}>
              The domain <span>{nonWhitelistedString}</span> is not verified by Maritech
            </div>
            <div className={styles.nonWhitelistedDomainMsg} hidden={nonWhitelisted.length < 2}>
              The domains <span>{nonWhitelistedString}</span> are not verified by Maritech
            </div>
          </>
        )}
      </FocusContext.Consumer>
    );
  }
}

function reduceTagsToNonWhitelistedDomains(acc: string[], tag: ITag) {
  if (tag.nonWhitelistedDomain && !acc.includes(tag.nonWhitelistedDomain)) {
    acc.push(tag.nonWhitelistedDomain);
  }

  return acc;
}

function resolveNonWhitelistedDomain(email: string) {
  const emailFragments = email.split("@");
  const emailDomain = emailFragments[1]?.trim();
  const nonWhitelistedDomain = BLOCKED_DOMAIN[emailDomain]?.Domain;

  return nonWhitelistedDomain;
}

export const Invitees = withValidationContext(InviteesComponent);
