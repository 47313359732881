import { useMemo } from "react";
import { observer } from "mobx-react";
import { useExecuteAtDate, useForceUpdate } from "@/utils";
import { entityStorage } from "@/services";
import { Negotiation } from "@/models";
import { Banner, BannerProps } from "@/components";
import "./OwnerBanner.scss";

function OwnerBanner(props: Props) {
  const { negotiation, dependencies } = props;
  const version = negotiation?.version || {};
  const orderVersion = negotiation?._.order?.version;
  const actionsModel = negotiation?.getActionsModel();
  const stage = negotiation?.getStage();
  const orderStage = negotiation?._.order?.getStage();
  const _refreshDependencies = dependencies || [version, orderVersion];
  const {
    ownerFirmed,
    ownerFirmedExpired,
    brokerChartererFirmRequested,
    brokerChartererFirmed,
    brokerChartererFirmedExpired,
    ownerFirmExpiresOn,
    brokerChartererFirmExpiresOn,
    brokerChartererIndicated,
    ownerIndicated,
    firmOfferAccepted,
    firmBidAccepted,
    hasExpiredAction,
  } = actionsModel || {};
  const banner = useMemo(resolveBanner, _refreshDependencies);
  const { icon, ...rest } = banner || {};
  const persisted = entityStorage.get(negotiation);
  const onExpireUpdate = useForceUpdateExpiry();

  useExecuteAtDate(ownerFirmExpiresOn, onExpireUpdate);
  useExecuteAtDate(brokerChartererFirmExpiresOn, onExpireUpdate);

  return (
    <Banner
      {...rest}
      className="OwnerBanner"
      dependencies={_refreshDependencies}
      // @ts-ignore
      icon={icon}
      onClose={onClose}
      hidden={
        persisted?.versionWhenBannerWasClosed >= version &&
        (persisted?.expiryDismiss === undefined || (persisted?.expiryDismiss && persisted?.expiryDismissVersion === version))
      }
    />
  );

  function useForceUpdateExpiry() {
    const forceUpdate = useForceUpdate();
    if (hasExpiredAction) entityStorage.setExpiryDismissBannerToggle(false, negotiation);
    return forceUpdate;
  }

  function onClose() {
    if (hasExpiredAction) entityStorage.setExpiryDismissBannerToggle(true, negotiation);
    entityStorage.setVersionWhenBannerWasClosed(negotiation);
  }

  function resolveBanner() {
    // This banner can be displayed on the OwnerDashboard
    if (stage === "Withdrawn" && orderStage !== "Withdrawn") {
      return {
        label: "This negotiation has been withdrawn",
        description: "You can no longer submit INDICATIONS or OFFERS",
        subClassName: "orange",
        icon: "flag",
      };
    }

    if (!actionsModel?.owner) return null;

    if (stage === "Fixed") {
      return {
        label: "Negotiation is Fixed",
        subClassName: "positive",
        icon: "thumbs-up",
      };
    }

    if (stage === "MainTerms" || stage === "OnSubs" || stage === "SubsLifted") {
      return {
        label: "Ready For Main Terms",
        subClassName: "positive",
        icon: "clock",
      };
    }

    if (stage === "TermsLoading") {
      return {
        label: "Loading Main Terms. You will shortly receive an email to participate in Main Terms negotiation",
        subClassName: "positive",
        icon: "clock",
      };
    }

    if (firmBidAccepted) {
      return {
        label: "Firm Bid Accepted. You will shortly receive an email to participate in Main Terms negotiation",
        subClassName: "positive",
      };
    }

    if (firmOfferAccepted) {
      return {
        label: "Firm Offer Accepted. You will shortly receive an email to participate in Main Terms negotiation",
        subClassName: "positive",
      };
    }

    if (brokerChartererFirmed) {
      if (brokerChartererFirmedExpired) {
        return {
          label: "The Charterer's firm Bid Expired. You can continue to negotiate below",
          subClassName: "orange",
        };
      }

      return {
        label: "The Charterer sent a firm Bid. Respond below",
        subClassName: "orange",
      };
    }

    if (brokerChartererFirmRequested) {
      return {
        label: "The Charterer has requested a firm Offer. Respond below",
      };
    }

    if (brokerChartererIndicated) {
      return { label: "The Charterer has responded, it's your turn to respond below" };
    }

    if (ownerFirmed) {
      if (ownerFirmedExpired) {
        return {
          label: "Your firm offer has expired. You can continue to negotiate below",
          subClassName: "orange",
        };
      }

      return {
        label: "Your firm offer was submitted. The Charterer has until the timer runs out to respond",
      };
    }

    if (ownerIndicated) {
      return {
        label: "Your indication was submitted. It's now the Charterer's turn to respond. You can still edit your response below",
      };
    }

    return null;
  }
}

const Observer = observer(OwnerBanner);

export { Observer as OwnerBanner };

interface Props extends BannerProps {
  negotiation?: Negotiation;
  dependencies?: any[];
}
