import React, { useState, ReactNode } from "react";
import { Button, IconProps } from "@/components";
import "./Collapse.scss";

export function Collapse(props: Props) {
  let { btnDataTest, iconCollapsed, iconExpanded, children } = props;
  const [open, setOpen] = useState(false);
  const icon = open ? iconExpanded || "chevron-down" : iconCollapsed || "chevron-right";
  const label = open && props.labelExpanded ? props.labelExpanded : props.label;

  if (!open) children = null;

  function collapse() {
    setOpen(!open);
  }

  return (
    <div className="collapse">
      <Button icon={icon} variant="flat" onClick={collapse} data-test={btnDataTest}>
        {label}
      </Button>
      {children}
    </div>
  );
}

interface Props {
  btnDataTest?: string | "";
  iconExpanded?: IconProps["icon"];
  iconCollapsed?: IconProps["icon"];
  label?: string;
  labelExpanded?: string;
  children: ReactNode;
}
