import React, { useMemo, useEffect } from "react";
import { createPortal } from "react-dom";
import { Popup, PopupProps } from "./Popup";
import "./Popup.scss";

export function PortalPopup(props: PopupProps) {
  const container = useMemo(createContainer, []);

  useEffect(onMount, []);

  function onMount() {
    return onUnmount;
  }

  function onUnmount() {
    container.remove();
  }

  return createPortal(<Popup {...props} />, container);
}

function createContainer() {
  const container = document.createElement("div");

  container.classList.add("portal-popup");

  document.body.appendChild(container);

  return container;
}
