import React from "react";
import { observer } from "mobx-react";
import { getCreateDealCapturePayload } from "@/models";
import { createDealCapture } from "../../../actions";
import { NewOrderForm } from "../NewOrderForm";

function Capture() {
  return <NewOrderForm getPayload={getCreateDealCapturePayload} section="capture" onSubmit={createDealCapture} />;
}

const Observer = observer(Capture);

export { Observer as Capture };
