import { useMemo } from "react";
import * as yup from "yup";
import { observer } from "mobx-react";
import { TradeAPI } from "@/apis";
import { auth, UniversalOrderNegotiationFormValues, Negotiation, slideout } from "@/models";
import { UniversalOrderNegotiationForm, Button, FormContext, Overlay } from "@/components";
import {
  postNegotiationBid as postNegotiationBidAction,
  postNegotiationOffer as postNegotiationOfferAction,
} from "@/components/Orders/actions";
import { NegotiationTypeEvents, usageMetrics } from "@/services/UsageMetrics";
import { LaunchDarklyFeature } from "@/config";
import { filterElementsByElementName, NewTcFieldsTitles, shouldEnableLaunchDarklyFeature } from "@/utils";
import { LDFlagSet } from "launchdarkly-js-client-sdk";

function SlideoutBidOfferForm(props: Props) {
  const { negotiation, type } = props;
  const initialValues = useMemo(getInitialValues, []);
  const actorIsCharterer = !auth.trade.user?._.companyRoleMap.broker;
  const hasBroker = negotiation.hasBroker;
  const isChartererWithBrokeredNeg = actorIsCharterer && hasBroker;
  const config = CONFIG(isChartererWithBrokeredNeg)[type];
  const { defaultElements, button, postNegotiationBidOffer } = config;
  const { values } = form;
  let { indicationType } = values || {};
  indicationType = indicationType || "indicated";
  const enableTcFields = shouldEnableLaunchDarklyFeature(LaunchDarklyFeature.TCFields, props.featureFlags);
  let elements = useMemo(createElements, [negotiation._.version, indicationType]);
  if (enableTcFields === false && elements) {
    elements = filterElementsByElementName(elements, NewTcFieldsTitles, "title");
  }
  const isSubmitDisabled = indicationType === "indicated" ? !form.isChanged : false;

  return (
    <div className={`order-negotiations-negotiation-form ${type}`}>
      <UniversalOrderNegotiationForm
        initialValues={initialValues}
        elements={elements}
        context={form}
        onSubmit={onSubmit}
        mutateValues={mutateValues}
        required={required}
        yup={schema}
      />
      <div className="order-negotiations-negotiation-form-control">
        <Button onClick={form.reset} disabled={!form.isChanged}>
          reset
        </Button>
        <Button variant="action" onClick={submit} disabled={isSubmitDisabled}>
          {button.submit[indicationType]}
        </Button>
      </div>
      <Overlay status={negotiation._.status} />
    </div>
  );

  function submit() {
    if (indicationType === "firmRequested") {
      usageMetrics.trackEvent(NegotiationTypeEvents.REQUEST_FIRM);
    }
    if (indicationType === "firmed") {
      usageMetrics.trackEvent(NegotiationTypeEvents.SEND_FIRM_BID);
    }
    if (indicationType === "indicated") {
      usageMetrics.trackEvent(NegotiationTypeEvents.SEND_OWNER_INDICATION);
    }
    form.submit();
  }

  function createElements() {
    const elements = [...defaultElements] as any[];

    if (indicationType === "firmRequested" && type === "bid") {
      return elements.concat({
        type: "CollapsibleGroup",
        label: "Owner's Indication",
        className:
          "order-negotiations-negotiation-details-form-content order-negotiations-negotiation-details-form-content-firmRequested",
        children: negotiation.getBrokerChartererBidFormStuff().negotiableOfferReadOnlyElements,
      });
    }

    return elements.concat({
      type: "CollapsibleGroup",
      className: "order-negotiations-negotiation-details-form-content",
      children:
        type === "bid"
          ? negotiation.getBrokerChartererBidFormStuff().elements
          : negotiation.getBrokerChartererOfferFormStuff().elements,
    });
  }

  async function onSubmit(data: UniversalOrderNegotiationFormValues) {
    const res = await postNegotiationBidOffer(negotiation, data);

    if (!res?.ok) return;

    slideout.hide();
  }

  function getInitialValues() {
    if (props.indicationType)
      return new UniversalOrderNegotiationFormValues({
        ...negotiation.getBidOfferFormValues(true),
        indicationType: props.indicationType,
      });

    return negotiation.getBidOfferFormValues(true);
  }
}

const schema = yup
  .object()
  .test(
    "Validation0",
    "Something is wrong 0",
    function validate0(this: yup.TestContext, values: UniversalOrderNegotiationFormValues) {
      if (!values) return false;

      const error = this.createError();

      if (values.indicationType === "firmed") {
        if (values.timelimitVariant === "DateOffset" && !values.timelimitDateOffset) {
          error.message = "Required";
          error.path = "timelimitDateOffset";

          return error;
        }

        if (values.timelimitVariant === "Date" && !values.timelimitDate) {
          error.message = "Required";
          error.path = "timelimitDate";

          return error;
        }
      }

      return true;
    }
  );

const form = new FormContext<UniversalOrderNegotiationFormValues>();

function mutateValues(data: UniversalOrderNegotiationFormValues) {
  data.termset = undefined;
  data.ownerAccount = undefined;
}

const required = ["timelimit"];

const CONFIG = (isChartererWithBrokeredNeg: boolean) => ({
  bid: {
    postNegotiationBidOffer: postNegotiationBidAction,
    button: {
      submit: {
        indicated: "send indication",
        firmRequested: "request firm",
        firmed: "send firm",
      },
    },
    defaultElements: [
      {
        type: "CollapsibleGroup",
        label: "I would like to...",
        className: "order-negotiations-negotiation-details-form-header",
        children: [
          {
            type: "Radio",
            name: "indicationType",
            radioLabel: isChartererWithBrokeredNeg ? "Send the Broker an Indication only " : "Send the Owner an Indication only ",
            checkedValue: "indicated",
          },
          {
            type: "Radio",
            name: "indicationType",
            radioLabel: isChartererWithBrokeredNeg
              ? "Request a firm Offer from the Broker **"
              : "Request a firm Offer from the Owner **",
            checkedValue: "firmRequested",
          },
          {
            type: "Radio",
            name: "indicationType",
            radioLabel: isChartererWithBrokeredNeg ? "Send the Broker a firm Bid *" : "Send the Owner a firm Bid *",
            checkedValue: "firmed",
          },
          {
            type: "TextOnly",
            children: [
              { text: "** References to 'Offer' herein refer to an Owner's Offer" },
              { text: "* References to 'Bid' herein refer to a Charterer's Offer" },
            ],
          },
        ],
      },

      {
        label: "Time limit",
        visible: (context) => context.values?.indicationType === "firmed",
        type: "CollapsibleGroup",
        className: "order-negotiations-negotiation-details-form-time-limit",
        children: [
          {
            type: "RadioTimelimit",
            radio: {
              name: "timelimitVariant",
              checkedValue: "DateOffset",
            },
            timelimit: {
              name: "timelimitDateOffset",
              variant: "DateOffset",
              units: ["hour", "minute"],
              getLayout: (input) => [`${isChartererWithBrokeredNeg ? "Broker/Owner" : "Owner"} will have`, input, "to respond"],
            },
          },
          {
            type: "RadioTimelimit",
            radio: {
              name: "timelimitVariant",
              checkedValue: "Date",
            },
            timelimit: {
              name: "timelimitDate",
              getLayout: (input) => [`${isChartererWithBrokeredNeg ? "Broker/Owner" : "Owner"} to respond by`, input],
            },
          },
        ],
      },
    ],
  },
  offer: {
    postNegotiationBidOffer: postNegotiationOfferAction,
    button: {
      submit: {
        indicated: "send indication",
        firmRequested: "request firm",
        firmed: "send firm",
      },
    },
    defaultElements: [
      {
        type: "CollapsibleGroup",
        label: "This response",
        className: "order-negotiations-negotiation-details-form-header",
        children: [
          {
            type: "Radio",
            name: "indicationType",
            radioLabel: "This is an Indication only",
            checkedValue: "indicated",
          },
          {
            type: "Radio",
            name: "indicationType",
            radioLabel: "This is a firm Offer",
            checkedValue: "firmed",
          },
        ],
      },
      {
        label: "Time limit",
        visible: (context) => context.values?.indicationType === "firmed",
        type: "CollapsibleGroup",
        className: "order-negotiations-negotiation-details-form-time-limit",
        children: [
          {
            type: "RadioTimelimit",
            radio: {
              name: "timelimitVariant",
              checkedValue: "DateOffset",
            },
            timelimit: {
              name: "timelimitDateOffset",
              variant: "DateOffset",
              units: ["hour", "minute"],
              getLayout: (input) => ["Charterer will have", input, "to respond"],
            },
          },
          {
            type: "RadioTimelimit",
            radio: {
              name: "timelimitVariant",
              checkedValue: "Date",
            },
            timelimit: {
              name: "timelimitDate",
              getLayout: (input) => ["Charterer to respond by", input],
            },
          },
        ],
      },
    ],
  },
});

const Observer = observer(SlideoutBidOfferForm);

export { Observer as SlideoutBidOfferForm };

interface Props {
  negotiation: Negotiation;
  type: "bid" | "offer";
  indicationType?: TradeAPI["IndicationType"];
  featureFlags: LDFlagSet;
}
