import { IOrderType } from "__legacy/sharedFolder/contexts/order-type-context";

function ifITOElseCOA(orderType: IOrderType, ITOExpression: any, COAExpression: any) {
  if (orderType === "ITO") {
    return ITOExpression;
  } else if (orderType === "COA") {
    return COAExpression;
  } else {
    throw new Error("unknown order type");
  }
}

export default ifITOElseCOA;
