import { IAccountView } from "sharedFolder/Models/IDetails";

interface IAccountSearchDocument {
  accountId: string;
  accountName: string;
  arcContactId: string;
  companyId?: string;
  companyName?: string;
  display?: string;
  gainAccountGroupId?: string;
  gainAccountId?: string;
  id?: string;
  isLegalEntity?: boolean;
}

export interface IAccountSearchResult {
  text: string;
  document: IAccountSearchDocument;
}

export const fromIAccountView = (accountView: IAccountView): IAccountSearchResult | undefined => {
  if (accountView.accountName) {
    const result: IAccountSearchResult = {
      text: accountView.accountName,
      document: {
        accountId: accountView.accountId,
        accountName: accountView.accountName,
        arcContactId: accountView.arcContactId ? accountView.arcContactId : "",
        companyId: undefined,
        companyName: undefined,
        display: accountView.accountName,
        gainAccountGroupId: accountView.gainAccountGroupId,
        gainAccountId: accountView.gainAccountId,
        isLegalEntity: accountView.isLegalEntity,
        id: undefined,
      },
    };
    return result;
  } else {
    return undefined;
  }
};

export const toIAccountView = (accountView: IAccountSearchResult): IAccountView | undefined => {
  if (!accountView) {
    return {
      accountId: "",
      accountName: "",
      arcContactId: "",
      gainAccountGroupId: "",
      gainAccountId: "",
      isLegalEntity: false,
    };
  } else {
    return {
      accountId: accountView.document.accountId,
      accountName: accountView.document.accountName,
      arcContactId: accountView.document.arcContactId,
      display: accountView.document.accountName,
      gainAccountGroupId: accountView.document.gainAccountGroupId,
      gainAccountId: accountView.document.gainAccountId,
      isLegalEntity: accountView.document.isLegalEntity,
    };
  }
};
