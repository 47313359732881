import { Observable, of } from "rxjs";
import { filter, switchMap, map } from "rxjs/operators";
import { Claims } from "../ClaimsProvider";

/**
 * Filter given observable by memberships from current claims
 * @param observable Observable to filter by claims memberships
 */
export function filterMemberships(claims: Claims) {
  return function <T extends { memberships: string[] }>(observable: Observable<T>) {
    return (
      of(claims)
        // no claims, no memberships
        .pipe(filter((claims) => claims !== null))
        .pipe(map((claims) => claims as Claims))
        .pipe(
          switchMap((claims: Claims) =>
            observable.pipe(filter((o) => o.memberships.some((m) => claims.memberships.map((m) => m.id).includes(m))))
          )
        )
    );
  };
}
