import React, { useMemo } from "react";
import { RouteSeed } from "@/models";
import { CaptureMainTerms, Context } from "@/components/MainTerms";

export const captureMainTerms = {
  path: "/capturemainterms/:orderId/:negotiationId/:type?",
  pageTitle: "Deal Capture Main Terms",
  comp: CaptureMainTermsWrapper,
  sidenav: ["orders", "owner", "coa"],
} as RouteSeed;

function CaptureMainTermsWrapper() {
  const context = useMemo(getContext, []);

  return <CaptureMainTerms context={context} />;
}

function getContext() {
  return context;
}

const context = {
  dataTest: "Deal Capture MainTerms",
} as Context;
