import React from "react";
import { observer } from "mobx-react";
import { popup } from "@/models";
import { Popup } from "./Popup";
import "./Popup.scss";

function ObserverPopup() {
  if (!popup.props) return null;

  return <Popup {...popup.props} onBackdropClick={popup.hide} />;
}

const Observer = observer(ObserverPopup);

export { Observer as ObserverPopup };
