import React, { createRef, PureComponent } from "react";
import classnames from "classnames";
import { Subscription } from "rxjs";
import { isEqual } from "lodash-es";
import { getLaycan } from "sharedFolder/apis/LaycanService";
import styles from "./LaycanBaseEditor.module.scss";
import { withContext } from "sharedFolder/contexts/withContext";
import { ValidationContext, IValidationContext } from "sharedFolder/contexts/ValidationContext";
import { ILaycanView, isLaycanValid } from "sharedFolder/Models/IDetails";
import { ILaycanSearchResult, mapLaycanSearchResultToILaycanView } from "sharedFolder/Models/ILaycanSearchResult";

interface IProps {
  id: string;
  required: boolean | undefined;
  onChange: (laycan?: ILaycanView) => void;
  value?: ILaycanView;
  url: string;
  label?: string;
  disabled?: boolean;
  focus?: boolean;
}

interface IState {
  laycan?: ILaycanView;
  display: string;
  valid: boolean;
  isEnterKeyPressed: boolean;
  isValidOnEnterKeyPressed: boolean;
  loading?: boolean;
}

class LaycanBaseEditorComponent extends PureComponent<IProps & IValidationContext, IState> {
  private readonly subscriptions: Subscription[] = [];
  private inputRef = createRef<HTMLInputElement>();

  constructor(props: IProps & IValidationContext) {
    super(props);

    const valid = this.isValid(props);
    this.state = {
      laycan: props.value,
      display: props.value ? props.value.display : "",
      valid,
      isEnterKeyPressed: false,
      isValidOnEnterKeyPressed: true,
    };

    props.fieldValidityChanged(props.id, valid);

    this.handleBlur = this.handleBlur.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.handleLaycanResultReceived = this.handleLaycanResultReceived.bind(this);
    this.handleLaycanChange = this.handleLaycanChange.bind(this);
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.state.loading !== prevState.loading) {
      const valid = this.isValid(this.props) && this.state.isValidOnEnterKeyPressed;

      this.props.fieldValidityChanged(this.props.id, valid);
    }

    // isEqual is used because editorValueResolverFunctions create new references each time, making value change side-effects triggered on any parent rerender
    if (!isEqual(this.props.value, prevProps.value)) {
      const valid = this.isValid(this.props) && this.state.isValidOnEnterKeyPressed;
      this.setState({
        laycan: this.props.value,
        display: this.props.value ? this.props.value.display : "",
        valid,
      });

      this.props.fieldValidityChanged(this.props.id, valid);
    }
    if (this.props.focus && this.props.focus !== prevProps.focus) {
      this.focusInput();
    }
  }

  public componentWillUnmount() {
    this.props.fieldValidityChanged(this.props.id, true);
  }

  public render() {
    const singleValidationErrorClass =
      this.state.isEnterKeyPressed && !this.state.isValidOnEnterKeyPressed ? styles.singleValidationError : null;
    const inputFieldErrorClass = !this.state.valid && styles.invalid;

    return (
      <div className={styles.inputRow}>
        <div className={styles.inputGroup}>
          {this.props.label && <label htmlFor={this.props.id}>{this.props.label}</label>}
          <input
            ref={this.inputRef}
            type="text"
            id={this.props.id}
            aria-label={this.props.id}
            data-test={this.props.id}
            value={this.state.display}
            className={classnames(styles.input, inputFieldErrorClass, singleValidationErrorClass)}
            onBlur={this.handleBlur}
            onPaste={this.handlePaste}
            onChange={this.handleLaycanChange}
            onKeyDown={this.handleOnKeyDown}
            disabled={this.props.disabled}
            autoFocus={this.props.focus}
            autoComplete="off"
          />
        </div>
      </div>
    );
  }

  private handlePaste(event: React.ClipboardEvent) {
    // please refer to commit 029fb92c9f24efea46edabf0ffeebd69c2d5465a to add tests when react-testing-library supports event.clipboardData
    this.handleLaycanFetch(event.clipboardData.getData("Text"));
  }

  private handleBlur(event: React.FormEvent<HTMLInputElement>) {
    this.handleLaycanFetch(event.currentTarget.value);
  }

  private handleLaycanFetch(laycanInputText: string) {
    if (laycanInputText) {
      this.subscriptions.push(getLaycan(laycanInputText).subscribe(this.handleLaycanResultReceived));
    } else {
      this.setState(
        {
          laycan: undefined,
          display: laycanInputText,
          isValidOnEnterKeyPressed: !this.props.required,
          isEnterKeyPressed: true,
        },
        () => this.props.onChange(undefined)
      );
    }
  }

  private handleLaycanResultReceived(result: ILaycanSearchResult | null) {
    const laycan = mapLaycanSearchResultToILaycanView(result);

    this.setState(
      {
        laycan,
        loading: false,
        display: laycan ? laycan.display : this.state.display,
        isValidOnEnterKeyPressed: laycan ? true : false,
        isEnterKeyPressed: laycan ? false : true,
      },
      () => this.props.onChange(laycan)
    );
  }

  private handleLaycanChange(evt: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ display: evt.target.value, loading: true });
  }

  private isValid(props: IProps): boolean {
    if (!props.required) return true;

    if (this.state?.loading) return false;
    if (!props.value || props.value.display === "") {
      return !props.required;
    }
    return isLaycanValid(props.value);
  }

  private handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      this.handleBlur(event);
      this.setState({
        isValidOnEnterKeyPressed: true,
        isEnterKeyPressed: true,
      });
    }
  };

  private focusInput = () => {
    if (this.inputRef && this.inputRef.current) {
      this.inputRef.current.focus();
    }
  };
}

export const LaycanBaseEditor = withContext<IProps, IValidationContext>(LaycanBaseEditorComponent, ValidationContext);
