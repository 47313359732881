/* eslint-disable no-template-curly-in-string */
import * as yup from "yup";
import { get } from "lodash-es";
import { capitalizeFirstLetter } from "@/utils";

window.yup = yup;

export function JSONSchemaToYup(schema, _key, path, root) {
  let res;

  const fieldName = capitalizeFirstLetter(_key);

  if (schema.$ref) {
    const ref = get(root, schema.$ref.split("/").slice(1));

    schema = { ...schema, ...ref };
  }

  const required = (schema.required || []).reduce((acc, key) => {
    acc[key] = true;

    return acc;
  }, {});

  const type = [].concat(schema.type).reduce((types, type) => {
    if (!SUPPORTED_TYPE[type]) {
      console.error(`Schema contains unsupported type "${type}"`, schema);
    }

    types[type] = true;

    if (type === "integer") {
      types.number = true;
    }

    return types;
  }, {});

  if (type.object) {
    const object = {};
    const entries = Object.entries(schema.properties);

    for (let i = 0; i < entries.length; i += 1) {
      const [key, child] = entries[i];

      const childPath = path ? `${path}.${key}` : key;

      child.isRequired = required[key];

      object[key] = JSONSchemaToYup(child, key, childPath, root || schema);
    }

    res = yup.object(object);

    //
  } else if (type.array) {
    res = yup.array().of(JSONSchemaToYup(schema.items, null, null, root || schema));

    //
  } else if (type.number) {
    res = yup.number(`${fieldName} must be a number`);

    if (type.integer) {
      res = res.integer(`${fieldName} cannot have decimals`);
    }

    if (schema.minimum !== undefined) res = res.min(schema.minimum, `${fieldName} cannot be less than ${schema.minimum}`);
    if (schema.maximum !== undefined) res = res.max(schema.maximum, `${fieldName} cannot be more than ${schema.maximum}`);

    //
  } else if (type.string) {
    res = yup.string(`${fieldName} must be a string`);

    //
  } else if (type.email) {
    res = yup.string().email(`${fieldName} must be a valid email`);

    //
  }

  if (schema.enum) {
    let enums = [].concat(schema.enum);

    if (type.null) enums = enums.concat(null);

    res = res.oneOf(enums);
  }

  if (schema.test) {
    const tests = [].concat(schema.test);

    tests.forEach((test) => {
      res = res.test(test);
    });
  }

  if (type.null) {
    res = res.default(null);
    res = res.nullable(true);
  }

  if (schema.isRequired) {
    res = res.required(`Required`);
  }

  // res = res.typeError("Required");

  return res;
}

const SUPPORTED_TYPE = {
  object: true,
  string: true,
  email: true,
  number: true,
  integer: true,
  array: true,
  null: true,
};
