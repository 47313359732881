import { TradeAPI } from "___REFACTOR___/apis";
import { VesselsEditor } from "___REFACTOR___/components";
import { ArrayDataModel, ArrayDataModelProps } from "../ArrayDataModel";
import { Vessel, createVessel } from "../Vessel";

class Vessels extends ArrayDataModel {
  static sortVessels<T extends TradeAPI.Vessel>(vessels: T[]) {
    // sort will mutate the array in question
    return vessels.sort((va, vb) => {
      const a = Vessel.Status[va.status];
      const b = Vessel.Status[vb.status];

      if (b === a) return 0;

      return b > a ? 1 : -1;
    });
  }

  static get Editor() {
    return VesselsEditor;
  }

  static label = "Vessels";

  constructor(data?: Data) {
    super(data);

    this.vessels = data?.map(createVessel) || [];
    this.acceptedVessels = this.vessels?.filter(acceptedVesselSelector);
    this.acceptedVessel = this.acceptedVessels?.[0];
    this.nonRejectedVessels = this.vessels?.filter(nonRejectedVesselSelector);
    this.firstVessel = this.vessels?.[0];
    this.acceptedOrFirstVessel = this.acceptedVessel || this.firstVessel;
    this.count = this.acceptedVessels?.length || this.nonRejectedVessels?.length;
  }

  toJSON() {
    return this.vessels;
  }

  toString() {
    const vessel = this.acceptedOrFirstVessel?.toString() || "TBN";
    const count = this.count || 0;
    const countText = count > 1 ? ` +${count - 1}` : "";

    return `${vessel}${countText}`;
  }

  getAcceptedOrFirstVessel() {
    if (this.data) return getAcceptedOrFirstVessel(this.data);
  }

  getAcceptedOrFirstVesselModel() {
    if (this.data) return new Vessel(getAcceptedOrFirstVessel(this.data));
  }

  getAcceptedVessel() {
    if (this.data) return this.data.find(acceptedVesselSelector);
  }

  getAcceptedVesselModel() {
    if (this.data) return new Vessel(this.data.find(acceptedVesselSelector));
  }
}

function getAcceptedOrFirstVessel(data: Data) {
  const acceptedVessel = data?.find(acceptedVesselSelector);

  if (acceptedVessel) return acceptedVessel;

  const firstVessel = data?.[0];

  return firstVessel;
}

class VesselsProps extends ArrayDataModelProps {}

Vessels.prototype.Props = VesselsProps;
VesselsProps.prototype.Editor = VesselsEditor;
VesselsProps.prototype.label = "Vessels";
VesselsProps.prototype.sortKey = "acceptedOrFirstVessel";

function acceptedVesselSelector(vessel: Data[number]) {
  return vessel.status === "accepted";
}

function nonRejectedVesselSelector(vessel: Data[number]) {
  return vessel.status !== "rejected";
}

export { Vessels, VesselsProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface Vessels {
  Props: typeof VesselsProps;
  _: VesselsProps;

  data: Data;
  vessels: Vessel[];
  nonRejectedVessels: Vessel[];
  acceptedVessels: Vessel[];
  acceptedVessel: Vessel;
  firstVessel: Vessel;
  acceptedOrFirstVessel: Vessel;
  count: number;
}

interface VesselsProps {
  sortKey: keyof Vessels;
}

type Data = TradeAPI.Vessel[];
