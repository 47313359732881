import React, { useState, useContext } from "react";
import { MinimalConfigurationContext } from "__legacy/sharedFolder/MinimalConfigurationContext";
import { INegotiationCreate, INegotiableViewOptional } from "../../models/NegotiationCreateView";
import { HireRateOptions } from "sharedFolder/Models/IHireRate";
import { CleaningPriceOptions } from "sharedFolder/Models/ICleaningPrice";
import { SupplyPriceOptions } from "sharedFolder/Models/ISupplyPrice";
import { BallastBonusOptions } from "../../../api/negotiations/models";
import { CreateNegotiationRow } from "./CreateNegotiationRow";
import { LocationEditor } from "sharedFolder/components/editors/LocationEditor/LocationEditor";
import { ICargoSizeView, IUnitValueView, IDurationView, IVesselSizeView, INotesView } from "sharedFolder/Models/IDetails";
import { UnitValueEditor } from "sharedFolder/components/editors/UnitValueEditor/UnitValueEditor";
import { Excluded } from "./Excluded";
import { ValidationContext } from "sharedFolder/contexts/ValidationContext";
import { ILocationView } from "sharedFolder/Models/ILocation";
import { cargoSizeText } from "sharedFolder/display/cargoSize";
import { FreightRateOptions } from "sharedFolder/Models/IFreightRate";
import { DemurrageOptions } from "sharedFolder/Models/IDemurrage";
import { OrderNegType } from "../../../api/orders/models";
import { VesselSizeEditor } from "sharedFolder/components/editors/VesselSizeEditor/VesselSizeEditor";
import { DurationEditor } from "sharedFolder/components/editors/DurationEditor/DurationEditor";
import { BunkerRedeliveryEditor, BunkerDeliveryEditor } from "@/components";
import { Notes } from "sharedFolder/components/common/Notes/Notes";
import { NewUnitValueEditorWithValidContext } from "sharedFolder/components/editors/NewUnitValueEditor/NewUnitValueEditor";
import { NewCargoSizeEditorWithValidContext } from "sharedFolder/components/editors/NewCargoSizeEditor/NewCargoSizeEditor";
import { ISubsTextView } from "sharedFolder/Models/ISubsText";
import { IBunkerView } from "sharedFolder/Models/IBunker";
import { SubsTextEditorWithValidContext } from "sharedFolder/components/editors/SubsTextEditor/SubsTextEditor";
import { useFlags } from "launchdarkly-react-client-sdk";
import { shouldEnableLaunchDarklyFeature } from "@/utils";
import { LaunchDarklyFeature } from "@/config";
import { bunkerFieldDisplay } from "sharedFolder/components/common/fields";

interface INegotiationDetailsProps {
  className?: string;
  orderId?: string;
  details: INegotiationCreate;
  onChanged: (newValue: INegotiationCreate) => void;
  orderType: OrderNegType;
}

interface IVoyNegotiationDetailsState {
  expandedItem: string | null;
}

class LocationRow extends CreateNegotiationRow<INegotiableViewOptional<ILocationView>, INegotiationCreate> {}
class CargoSizeRow extends CreateNegotiationRow<INegotiableViewOptional<ICargoSizeView>, INegotiationCreate> {}
class NotesRow extends CreateNegotiationRow<INegotiableViewOptional<INotesView>, INegotiationCreate> {}

class OperationalSubsRow extends CreateNegotiationRow<INegotiableViewOptional<ISubsTextView>, INegotiationCreate> {}

class CommercialSubsRow extends CreateNegotiationRow<INegotiableViewOptional<ISubsTextView>, INegotiationCreate> {}

class FreightRateRow extends CreateNegotiationRow<INegotiableViewOptional<IUnitValueView>, INegotiationCreate> {}
class DemurrageRow extends CreateNegotiationRow<INegotiableViewOptional<IUnitValueView>, INegotiationCreate> {}
class VesselSizeRow extends CreateNegotiationRow<INegotiableViewOptional<IVesselSizeView>, INegotiationCreate> {}

class DurationRow extends CreateNegotiationRow<INegotiableViewOptional<IDurationView>, INegotiationCreate> {}

class CleaningPriceRow extends CreateNegotiationRow<INegotiableViewOptional<IUnitValueView>, INegotiationCreate> {}
class SupplyPriceRow extends CreateNegotiationRow<INegotiableViewOptional<IUnitValueView>, INegotiationCreate> {}
class TradingExclusionsRow extends CreateNegotiationRow<INegotiableViewOptional<ISubsTextView>, INegotiationCreate> {}

class BunkerDeliveryRow extends CreateNegotiationRow<INegotiableViewOptional<IBunkerView>, INegotiationCreate> {}
class BunkerRedeliveryRow extends CreateNegotiationRow<INegotiableViewOptional<IBunkerView>, INegotiationCreate> {}

class CargoExclusionsTextRow extends CreateNegotiationRow<INegotiableViewOptional<ISubsTextView>, INegotiationCreate> {}
class HireRateRow extends CreateNegotiationRow<INegotiableViewOptional<IUnitValueView>, INegotiationCreate> {}
class BallastBonusRow extends CreateNegotiationRow<INegotiableViewOptional<IUnitValueView>, INegotiationCreate> {}
class ExcludedItems extends Excluded<INegotiationCreate> {}

export const NegotiationDetails: React.FC<INegotiationDetailsProps> = (props: INegotiationDetailsProps) => {
  const [state, setState] = useState<IVoyNegotiationDetailsState>({
    expandedItem: null,
  });
  const configContext = useContext(MinimalConfigurationContext);

  const excludedVoyItems: Array<{
    key: keyof INegotiationCreate;
    included?: boolean;
    label: string;
  }> = [
    {
      key: "loadLocation",
      included: props.details.loadLocation && props.details.loadLocation.included,
      label: "Load Location",
    },
    {
      key: "dischargeLocation",
      included: props.details.dischargeLocation && props.details.dischargeLocation.included,
      label: "Discharge Location",
    },
    {
      key: "voyOperationalSubs",
      included: props.details.voyOperationalSubs && props.details.voyOperationalSubs.included,
      label: "Operational Subs",
    },
    {
      key: "voyCommercialSubs",
      included: props.details.voyCommercialSubs && props.details.voyCommercialSubs.included,
      label: "Commercial Subs",
    },
    {
      key: "tctOperationalSubs",
      included: props.details.tctOperationalSubs && props.details.tctOperationalSubs.included,
      label: "Operational Subs",
    },
    {
      key: "tctCommercialSubs",
      included: props.details.tctCommercialSubs && props.details.tctCommercialSubs.included,
      label: "Commercial Subs",
    },
    {
      key: "cargoSize",
      included: props.details.cargoSize && props.details.cargoSize.included,
      label: "Cargo Size(s)",
    },
    {
      key: "demurrage",
      included: props.details.demurrage && props.details.demurrage.included,
      label: "Demurrage",
    },
    {
      key: "freightRate",
      included: props.details.freightRate && props.details.freightRate.included,
      label: "Freight Rate",
    },
    {
      key: "notes",
      included: props.details.notes && props.details.notes.included,
      label: "Voyage Notes",
    },
  ];

  const excludedTctItems: Array<{
    key: keyof INegotiationCreate;
    included?: boolean;
    label: string;
  }> = [
    {
      key: "deliveryLocation",
      included: props.details.deliveryLocation && props.details.deliveryLocation.included,
      label: "Delivery Location",
    },
    {
      key: "viaLocation",
      included: props.details.viaLocation && props.details.viaLocation.included,
      label: "Via Location",
    },
    {
      key: "redeliveryLocation",
      included: props.details.redeliveryLocation && props.details.redeliveryLocation.included,
      label: "Redelivery Location",
    },
    {
      key: "vesselSize",
      included: props.details.vesselSize && props.details.vesselSize.included,
      label: "Vessel Size",
    },
    {
      key: "duration",
      included: props.details.duration && props.details.duration.included,
      label: "Duration",
    },
    {
      key: "notes",
      label: "TC Notes",
    },
    {
      key: "ballastBonus",
      included: props.details.ballastBonus && props.details.ballastBonus.included,
      label: "Ballast Bonus",
    },
    {
      key: "hireRate",
      included: props.details.hireRate && props.details.hireRate.included,
      label: "Hire Rate",
    },
    {
      key: "cleaningPrice",
      included: props.details.cleaningPrice && props.details.cleaningPrice.included,
      label: "ILOHC",
    },
    {
      key: "supplyPrice",
      included: props.details.supplyPrice && props.details.supplyPrice.included,
      label: "CVE",
    },
    {
      key: "tradingExclusions",
      included: props.details.tradingExclusions && props.details.tradingExclusions.included,
      label: "Trading Exclusions",
    },
    {
      key: "bunkerDelivery",
      included: props.details.bunkerDelivery && props.details.bunkerDelivery.included,
      label: "Bunker Delivery",
    },
    {
      key: "bunkerRedelivery",
      included: props.details.bunkerRedelivery && props.details.bunkerRedelivery.included,
      label: "Bunker Re-Delivery",
    },
    {
      key: "cargoExclusionsText",
      included: props.details.cargoExclusionsText && props.details.cargoExclusionsText.included,
      label: "Cargo Exclusions",
    },
  ];

  const excludedItems = props.orderType === "VOY" ? excludedVoyItems : excludedTctItems;

  const handleFocusRow = (key: string | null) => {
    setState({
      expandedItem: key,
    });
  };

  /* VOY Handlers */
  const handleLoadLocationChanged = (value: INegotiableViewOptional<ILocationView>) => {
    props.onChanged({
      ...props.details,
      loadLocation: {
        ...value,
      },
    });
  };

  const handleLoadLocationValueChanged = (value?: ILocationView) => {
    handleLoadLocationChanged({
      ...props.details.loadLocation,
      value: value,
    });
  };

  const handleDischargeLocationChanged = (value: INegotiableViewOptional<ILocationView>) => {
    props.onChanged({
      ...props.details,
      dischargeLocation: {
        ...value,
      },
    });
  };

  const handleDischargeLocationValueChanged = (value?: ILocationView) => {
    handleDischargeLocationChanged({
      ...props.details.dischargeLocation,
      value: value,
    });
  };

  const handleCargoSizeValueChanged = (value: ICargoSizeView) => {
    handleCargoSizeChanged({
      ...props.details.cargoSize,
      value: value,
    });
  };

  const handleCargoSizeChanged = (value: INegotiableViewOptional<ICargoSizeView>) => {
    props.onChanged({
      ...props.details,
      cargoSize: {
        ...value,
      },
    });
  };

  const handleVoyNotesValueChanged = (value: string) => {
    handleVoyNotesChange({
      ...props.details.voyNotes,
      value: { value: value, display: value },
    });
  };

  const handleVoyNotesChange = (value: INegotiableViewOptional<INotesView>) => {
    props.onChanged({
      ...props.details,
      voyNotes: {
        ...value,
      },
    });
  };

  const handleTCTNotesValueChanged = (value: string) => {
    handleTCTNotesChange({
      ...props.details.tctNotes,
      value: { value: value, display: value },
    });
  };

  const handleTCTNotesChange = (value: INegotiableViewOptional<INotesView>) => {
    props.onChanged({
      ...props.details,
      tctNotes: {
        ...value,
      },
    });
  };

  const handleTradingExclusionsValueChanged = (value: ISubsTextView) => {
    handleTradingExclusionsChanged({
      ...props.details.tradingExclusions,
      value,
    });
  };

  const handleTradingExclusionsChanged = (value: INegotiableViewOptional<ISubsTextView>) => {
    props.onChanged({
      ...props.details,
      tradingExclusions: {
        ...value,
      },
    });
  };

  const handleBunkerDeliveryValueChanged = (value: IBunkerView) => {
    handleBunkerDeliveryChanged({
      ...props.details.bunkerDelivery,
      value,
    });
  };

  const handleBunkerDeliveryChanged = (value: INegotiableViewOptional<IBunkerView>) => {
    props.onChanged({
      ...props.details,
      bunkerDelivery: {
        ...value,
        value: {
          ...value.value,
          display: bunkerFieldDisplay(value.value),
        },
      },
    });
  };

  const handleBunkerRedeliveryValueChanged = (value: IBunkerView) => {
    handleBunkerRedeliveryChanged({
      ...props.details.bunkerRedelivery,
      value,
    });
  };

  const handleBunkerRedeliveryChanged = (value: INegotiableViewOptional<IBunkerView>) => {
    props.onChanged({
      ...props.details,
      bunkerRedelivery: {
        ...value,
        value: {
          ...value.value,
          display: bunkerFieldDisplay(value.value),
        },
      },
    });
  };

  const handleCargoExclusionsTextValueChanged = (value: ISubsTextView) => {
    handleCargoExclusionsTextChanged({
      ...props.details.cargoExclusionsText,
      value,
    });
  };

  const handleCargoExclusionsTextChanged = (value: INegotiableViewOptional<ISubsTextView>) => {
    props.onChanged({
      ...props.details,
      cargoExclusionsText: {
        ...value,
      },
    });
  };

  const handleFreightRateValueChanged = (value?: IUnitValueView) => {
    handleFreightRateChanged({
      ...props.details.freightRate,
      value: value || {
        unit: "",
        unitDisplay: "",
        value: null,
        notes: "",
      },
    });
  };

  const handleFreightRateChanged = (value: INegotiableViewOptional<IUnitValueView>) => {
    props.onChanged({
      ...props.details,
      freightRate: {
        ...value,
      },
    });
  };

  const handleDemurrageValueChanged = (value?: IUnitValueView) => {
    handleDemurrageChanged({
      ...props.details.demurrage,
      value: value || {
        unit: "",
        unitDisplay: "",
        value: null,
      },
    });
  };

  const handleDemurrageChanged = (value: INegotiableViewOptional<IUnitValueView>) => {
    props.onChanged({
      ...props.details,
      demurrage: {
        ...value,
      },
    });
  };

  const handleIncludeDetail = (key: keyof INegotiationCreate) => {
    const update = { ...props.details };
    const updatedItem = update[key];
    if (updatedItem) {
      updatedItem.included = true;
      props.onChanged({ ...update, ...updatedItem });
      // contract the expanded item upon restore
      if (state.expandedItem === key) {
        setState({
          expandedItem: null,
        });
      }
    }
  };

  const handleDeliveryLocationChanged = (location: INegotiableViewOptional<ILocationView>) => {
    props.onChanged({
      ...props.details,
      deliveryLocation: {
        ...location,
      },
    });
  };

  const handleDeliveryLocationValueChanged = (location?: ILocationView) => {
    handleDeliveryLocationChanged({
      ...props.details.deliveryLocation,
      value: location,
    });
  };

  const handleVoyCommercialSubsValueChanged = (value: ISubsTextView) => {
    handleVoyCommercialSubsChanged({
      ...props.details.voyCommercialSubs,
      value,
    });
  };

  const handleVoyCommercialSubsChanged = (value: INegotiableViewOptional<ISubsTextView>) => {
    props.onChanged({
      ...props.details,
      voyCommercialSubs: {
        ...value,
      },
    });
  };

  const handleVoyOperationalSubsValueChanged = (value: ISubsTextView) => {
    handleVoyOperationalSubsChanged({
      ...props.details.voyOperationalSubs,
      value,
    });
  };

  const handleVoyOperationalSubsChanged = (value: INegotiableViewOptional<ISubsTextView>) => {
    props.onChanged({
      ...props.details,
      voyOperationalSubs: {
        ...value,
      },
    });
  };

  const handleTctOperationalSubsValueChanged = (value: ISubsTextView) => {
    handleTctOperationalSubsChanged({
      ...props.details.tctOperationalSubs,
      value,
    });
  };

  const handleTctOperationalSubsChanged = (value: INegotiableViewOptional<ISubsTextView>) => {
    props.onChanged({
      ...props.details,
      tctOperationalSubs: {
        ...value,
      },
    });
  };

  const handleTctCommercialSubsValueChanged = (value: ISubsTextView) => {
    handleTctCommercialSubsChanged({
      ...props.details.tctCommercialSubs,
      value,
    });
  };

  const handleTctCommercialSubsChanged = (value: INegotiableViewOptional<ISubsTextView>) => {
    props.onChanged({
      ...props.details,
      tctCommercialSubs: {
        ...value,
      },
    });
  };

  const handleViaLocationChanged = (location: INegotiableViewOptional<ILocationView>) => {
    props.onChanged({
      ...props.details,
      viaLocation: {
        ...location,
      },
    });
  };

  const handleViaLocationValueChanged = (location?: ILocationView) => {
    handleViaLocationChanged({
      ...props.details.viaLocation,
      value: location,
    });
  };

  const handleRedeliveryLocationChanged = (location: INegotiableViewOptional<ILocationView>) => {
    props.onChanged({
      ...props.details,
      redeliveryLocation: {
        ...location,
      },
    });
  };

  const handleRedeliveryLocationValueChanged = (location?: ILocationView) => {
    handleRedeliveryLocationChanged({
      ...props.details.redeliveryLocation,
      value: location,
    });
  };

  const handleVesselSizeChanged = (value: INegotiableViewOptional<IVesselSizeView>) => {
    props.onChanged({
      ...props.details,
      vesselSize: {
        ...value,
      },
    });
  };

  const handleDurationChanged = (value: INegotiableViewOptional<IDurationView>) => {
    props.onChanged({
      ...props.details,
      duration: {
        ...value,
      },
    });
  };

  const handleVesselSizeValueChanged = (value: IVesselSizeView) => {
    handleVesselSizeChanged({
      ...props.details.vesselSize,
      value: value,
    });
  };

  const handleDurationValueChanged = (value: IDurationView) => {
    handleDurationChanged({
      ...props.details.duration,
      value: value,
    });
  };

  const handleHireRateChanged = (value: INegotiableViewOptional<IUnitValueView>) => {
    props.onChanged({
      ...props.details,
      hireRate: {
        ...value,
      },
    });
  };

  const handleCleaningPriceChanged = (value: INegotiableViewOptional<IUnitValueView>) => {
    props.onChanged({
      ...props.details,
      cleaningPrice: {
        ...value,
      },
    });
  };
  const handleSupplyPriceChanged = (value: INegotiableViewOptional<IUnitValueView>) => {
    props.onChanged({
      ...props.details,
      supplyPrice: {
        ...value,
      },
    });
  };

  const handleHireRateValueChanged = (value: IUnitValueView) => {
    handleHireRateChanged({
      ...props.details.hireRate,
      value: value,
    });
  };

  const handleCleaningPriceValueChanged = (value: IUnitValueView) => {
    handleCleaningPriceChanged({
      ...props.details.cleaningPrice,
      value: value,
    });
  };
  const handleSupplyPriceValueChanged = (value: IUnitValueView) => {
    handleSupplyPriceChanged({
      ...props.details.supplyPrice,
      value: value,
    });
  };

  const handleBallastBonusChanged = (value: INegotiableViewOptional<IUnitValueView>) => {
    props.onChanged({
      ...props.details,
      ballastBonus: {
        ...value,
      },
    });
  };

  const handleBallastBonusValueChanged = (value: IUnitValueView) => {
    handleBallastBonusChanged({
      ...props.details.ballastBonus,
      value: value,
    });
  };
  const showForOrderType = (...orderTypes: OrderNegType[]) => {
    return orderTypes.some((ot) => ot === props.orderType);
  };
  const flags = useFlags();
  const enableTcFields = shouldEnableLaunchDarklyFeature(LaunchDarklyFeature.TCFields, flags);
  return (
    <>
      <ValidationContext.Consumer>
        {(validation) => (
          <div>
            {showForOrderType("TCT") && props.details.vesselSize && (
              <VesselSizeRow
                id="vesselSize"
                label="Vessel Size"
                expanded={state.expandedItem === "vesselSize"}
                onExpand={handleFocusRow}
                value={props.details.vesselSize}
                text={props.details.vesselSize.value && props.details.vesselSize.value.display}
                onValueChanged={handleVesselSizeChanged}
                {...validation}
              >
                <VesselSizeEditor
                  required={!props.details.vesselSize.negotiable}
                  vesselSize={
                    props.details.vesselSize.value || {
                      notes: "",
                      sizeFrom: "",
                      sizeTo: "",
                      vesselSizeAbbreviation: "",
                      vesselSizeFull: "",
                    }
                  }
                  onChange={handleVesselSizeValueChanged}
                  focus={state.expandedItem === "vesselSize"}
                />
              </VesselSizeRow>
            )}
            {showForOrderType("TCT") && props.details.duration && (
              <DurationRow
                id="duration"
                label="Duration"
                expanded={state.expandedItem === "duration"}
                onExpand={handleFocusRow}
                value={props.details.duration}
                text={props.details.duration.value && props.details.duration.value.display}
                onValueChanged={handleDurationChanged}
                {...validation}
              >
                <DurationEditor
                  // TODO: add back when validation is added
                  // required={!props.details.duration.negotiable}
                  value={props.details.duration.value}
                  onChange={handleDurationValueChanged}
                  focus={state.expandedItem === "duration"}
                />
              </DurationRow>
            )}
            {showForOrderType("TCT") && props.details.deliveryLocation && (
              <LocationRow
                id="deliveryLocation"
                label="Delivery Location"
                expanded={state.expandedItem === "deliveryLocation"}
                onExpand={handleFocusRow}
                value={props.details.deliveryLocation}
                onValueChanged={handleDeliveryLocationChanged}
                text={props.details.deliveryLocation.value && props.details.deliveryLocation.value.display}
                {...validation}
              >
                <LocationEditor
                  id="deliveryLocationValue"
                  required={!props.details.deliveryLocation.negotiable}
                  value={props.details.deliveryLocation.value}
                  onChange={(value) => handleDeliveryLocationValueChanged(value)}
                  ctradeUrl={configContext.ctradeUrl}
                  focus={state.expandedItem === "deliveryLocation"}
                  hideLocationSafetyWarranty={true}
                />
              </LocationRow>
            )}
            {showForOrderType("TCT") && props.details.viaLocation && (
              <LocationRow
                id="viaLocation"
                label="Via Location"
                expanded={state.expandedItem === "viaLocation"}
                onExpand={handleFocusRow}
                value={props.details.viaLocation}
                onValueChanged={handleViaLocationChanged}
                text={props.details.viaLocation.value && props.details.viaLocation.value.display}
                {...validation}
              >
                <LocationEditor
                  id="viaLocationValue"
                  required={!props.details.viaLocation.negotiable}
                  value={props.details.viaLocation.value}
                  onChange={(value) => handleViaLocationValueChanged(value)}
                  ctradeUrl={configContext.ctradeUrl}
                  focus={state.expandedItem === "viaLocation"}
                  hideLocationSafetyWarranty={true}
                />
              </LocationRow>
            )}
            {showForOrderType("TCT") && props.details.redeliveryLocation && (
              <LocationRow
                id="redeliveryLocation"
                label="Redelivery Location"
                expanded={state.expandedItem === "redeliveryLocation"}
                onExpand={handleFocusRow}
                value={props.details.redeliveryLocation}
                onValueChanged={handleRedeliveryLocationChanged}
                text={props.details.redeliveryLocation.value && props.details.redeliveryLocation.value.display}
                {...validation}
              >
                <LocationEditor
                  id="redeliveryLocationValue"
                  required={!props.details.redeliveryLocation.negotiable}
                  value={props.details.redeliveryLocation.value}
                  onChange={(value) => handleRedeliveryLocationValueChanged(value)}
                  ctradeUrl={configContext.ctradeUrl}
                  focus={state.expandedItem === "redeliveryLocation"}
                  hideLocationSafetyWarranty={true}
                />
              </LocationRow>
            )}
            {showForOrderType("TCT") && props.details.hireRate && (
              <HireRateRow
                id="hireRate"
                label="Hire Rate"
                expanded={state.expandedItem === "hireRate"}
                onExpand={handleFocusRow}
                value={props.details.hireRate}
                onValueChanged={handleHireRateChanged}
                text={props.details.hireRate.value && props.details.hireRate.value.display}
                {...validation}
              >
                <UnitValueEditor
                  id="hireRateValue"
                  type="hireRate"
                  label="Hire Rate"
                  displayNotes={true}
                  notesPlaceholderText={"Additional hire rate notes"}
                  notesId={"hirerate_notes"}
                  required={!props.details.hireRate.negotiable}
                  options={HireRateOptions}
                  defaultOption={"PerDay"}
                  onChange={handleHireRateValueChanged}
                  value={
                    props.details.hireRate.value || {
                      unit: "",
                      unitDisplay: "",
                      value: null,
                      notes: "",
                    }
                  }
                  focus={state.expandedItem === "hireRate"}
                />
              </HireRateRow>
            )}
            {enableTcFields && showForOrderType("TCT") && props.details.cleaningPrice && (
              <CleaningPriceRow
                id="cleaningPrice"
                label="ILOHC"
                expanded={state.expandedItem === "cleaningPrice"}
                onExpand={handleFocusRow}
                value={props.details.cleaningPrice}
                onValueChanged={handleCleaningPriceChanged}
                text={props.details.cleaningPrice.value && props.details.cleaningPrice.value.display}
                {...validation}
              >
                <UnitValueEditor
                  id="cleaningPriceValue"
                  type="cleaningPrice"
                  label="ILOHC"
                  displayNotes={true}
                  notesPlaceholderText={"Additional ILOHC notes"}
                  notesId={"cleaningprice_notes"}
                  required={!props.details.cleaningPrice.negotiable}
                  options={CleaningPriceOptions}
                  defaultOption={"PerDay"}
                  onChange={handleCleaningPriceValueChanged}
                  value={
                    props.details.cleaningPrice.value || {
                      unit: "",
                      unitDisplay: "",
                      value: null,
                      notes: "",
                    }
                  }
                  focus={state.expandedItem === "cleaningPrice"}
                />
              </CleaningPriceRow>
            )}
            {enableTcFields && showForOrderType("TCT") && props.details.supplyPrice && (
              <SupplyPriceRow
                id="supplyPrice"
                label="CVE"
                expanded={state.expandedItem === "supplyPrice"}
                onExpand={handleFocusRow}
                value={props.details.supplyPrice}
                onValueChanged={handleSupplyPriceChanged}
                text={props.details.supplyPrice.value && props.details.supplyPrice.value.display}
                {...validation}
              >
                <UnitValueEditor
                  id="supplyPriceValue"
                  type="supplyPrice"
                  label="CVE"
                  displayNotes={true}
                  notesPlaceholderText={"Additional CVE notes"}
                  notesId={"cleaningprice_notes"}
                  required={!props.details.supplyPrice.negotiable}
                  options={SupplyPriceOptions}
                  defaultOption={"PerDay"}
                  onChange={handleSupplyPriceValueChanged}
                  value={
                    props.details.supplyPrice.value || {
                      unit: "",
                      unitDisplay: "",
                      value: null,
                      notes: "",
                    }
                  }
                  focus={state.expandedItem === "supplyPrice"}
                />
              </SupplyPriceRow>
            )}
            {enableTcFields && showForOrderType("TCT") && props.details.tradingExclusions && (
              <TradingExclusionsRow
                id="tradingExclusions"
                label="Trading Exclusions"
                expanded={state.expandedItem === "tradingExclusions"}
                onExpand={handleFocusRow}
                value={props.details.tradingExclusions}
                onValueChanged={handleTradingExclusionsChanged}
                text={props.details.tradingExclusions.value && props.details.tradingExclusions.value.value}
                {...validation}
              >
                <SubsTextEditorWithValidContext
                  id="tradingExclusionsValue"
                  required={!props.details.tradingExclusions.negotiable}
                  value={
                    props.details.tradingExclusions.value || {
                      value: "",
                      display: "",
                      title: "",
                    }
                  }
                  onChange={handleTradingExclusionsValueChanged}
                  focus={state.expandedItem === "tradingExclusions"}
                />
              </TradingExclusionsRow>
            )}
            {enableTcFields && showForOrderType("TCT") && props.details.bunkerDelivery && (
              <BunkerDeliveryRow
                label="Bunker Delivery"
                id="bunkerDelivery"
                expanded={state.expandedItem === "bunkerDelivery"}
                onExpand={handleFocusRow}
                value={props.details.bunkerDelivery}
                onValueChanged={handleBunkerDeliveryChanged}
                text={props.details.bunkerDelivery.value && props.details.bunkerDelivery.value.display}
                {...validation}
              >
                <BunkerDeliveryEditor
                  label="Bunker Delivery"
                  value={props.details.bunkerDelivery.value ? props.details.bunkerDelivery.value : undefined}
                  onChange={handleBunkerDeliveryValueChanged}
                />
              </BunkerDeliveryRow>
            )}
            {enableTcFields && showForOrderType("TCT") && props.details.bunkerRedelivery && (
              <BunkerRedeliveryRow
                label="Bunker Re-Delivery"
                id="bunkerRedelivery"
                expanded={state.expandedItem === "bunkerRedelivery"}
                onExpand={handleFocusRow}
                value={props.details.bunkerRedelivery || {}}
                onValueChanged={handleBunkerRedeliveryChanged}
                text={props.details.bunkerRedelivery?.value && props.details.bunkerRedelivery.value.display}
                {...validation}
              >
                <BunkerRedeliveryEditor
                  label="Bunker Re-Delivery"
                  value={props.details.bunkerRedelivery?.value ? props.details.bunkerRedelivery.value : undefined}
                  onChange={handleBunkerRedeliveryValueChanged}
                />
              </BunkerRedeliveryRow>
            )}
            {enableTcFields && showForOrderType("TCT") && props.details.cargoExclusionsText && (
              <CargoExclusionsTextRow
                id="cargoExclusionsText"
                label="Cargo Exclusions"
                expanded={state.expandedItem === "cargoExclusionsText"}
                onExpand={handleFocusRow}
                value={props.details.cargoExclusionsText}
                onValueChanged={handleCargoExclusionsTextChanged}
                text={props.details.cargoExclusionsText.value && props.details.cargoExclusionsText.value.value}
                {...validation}
              >
                <SubsTextEditorWithValidContext
                  id="cargoExclusionsTextValue"
                  required={!props.details.cargoExclusionsText.negotiable}
                  value={
                    props.details.cargoExclusionsText.value || {
                      value: "",
                      display: "",
                      title: "",
                    }
                  }
                  onChange={handleCargoExclusionsTextValueChanged}
                  focus={state.expandedItem === "cargoExclusionsText"}
                />
              </CargoExclusionsTextRow>
            )}
            {showForOrderType("TCT") && props.details.ballastBonus && (
              <BallastBonusRow
                id="ballastBonus"
                label="Ballast Bonus"
                expanded={state.expandedItem === "ballastBonus"}
                onExpand={handleFocusRow}
                value={props.details.ballastBonus}
                onValueChanged={handleBallastBonusChanged}
                text={props.details.ballastBonus.value?.display}
                {...validation}
              >
                <UnitValueEditor
                  id="ballastBonusValue"
                  type="ballastBonus"
                  label="Ballast Bonus"
                  displayNotes={true}
                  notesPlaceholderText={"Additional ballast bonus notes"}
                  notesId={"ballastbonus_notes"}
                  required={!props.details.ballastBonus.negotiable}
                  options={BallastBonusOptions}
                  defaultOption={"LumpSum"}
                  onChange={handleBallastBonusValueChanged}
                  value={
                    props.details.ballastBonus.value || {
                      unit: "",
                      unitDisplay: "",
                      value: null,
                    }
                  }
                  focus={state.expandedItem === "ballastBonus"}
                />
              </BallastBonusRow>
            )}
            {showForOrderType("TCT") && props.details.tctOperationalSubs && (
              <OperationalSubsRow
                id="tctOperationalSubs"
                label="Operational Subs"
                expanded={state.expandedItem === "tctOperationalSubs"}
                onExpand={handleFocusRow}
                value={props.details.tctOperationalSubs}
                onValueChanged={handleTctOperationalSubsChanged}
                text={props.details.tctOperationalSubs.value && props.details.tctOperationalSubs.value.display}
                {...validation}
              >
                <SubsTextEditorWithValidContext
                  id="tctOperationalSubsValue"
                  required={!props.details.tctOperationalSubs.negotiable}
                  value={
                    props.details.tctOperationalSubs.value || {
                      value: "",
                      display: "",
                      title: "",
                    }
                  }
                  onChange={handleTctOperationalSubsValueChanged}
                  focus={state.expandedItem === "tctOperationalSubs"}
                />
              </OperationalSubsRow>
            )}
            {showForOrderType("TCT") && props.details.tctCommercialSubs && (
              <CommercialSubsRow
                id="tctCommercialSubs"
                label="Commercial Subs"
                expanded={state.expandedItem === "tctCommercialSubs"}
                onExpand={handleFocusRow}
                value={props.details.tctCommercialSubs}
                onValueChanged={handleTctCommercialSubsChanged}
                text={props.details.tctCommercialSubs.value && props.details.tctCommercialSubs.value.display}
                {...validation}
              >
                <SubsTextEditorWithValidContext
                  id="tctCommercialSubsValue"
                  required={!props.details.tctCommercialSubs.negotiable}
                  value={
                    props.details.tctCommercialSubs.value || {
                      value: "",
                      display: "",
                      title: "",
                    }
                  }
                  onChange={handleTctCommercialSubsValueChanged}
                  focus={state.expandedItem === "tctCommercialSubs"}
                />
              </CommercialSubsRow>
            )}
            {showForOrderType("TCT") && props.details.tctNotes && (
              <NotesRow
                id="notes"
                label="TC Notes"
                expanded={state.expandedItem === "notes"}
                onExpand={handleFocusRow}
                value={props.details.tctNotes}
                onValueChanged={handleTCTNotesChange}
                text={props.details.tctNotes.value && props.details.tctNotes.value.display}
                {...validation}
              >
                <Notes
                  dataTest="TCT Notes textarea"
                  value={props.details.tctNotes.value ? props.details.tctNotes.value.value : undefined}
                  onChange={handleTCTNotesValueChanged}
                  ariaLabel="TCT Notes text area"
                  focus={state.expandedItem === "notes"}
                />
              </NotesRow>
            )}
            {showForOrderType("VOY") && props.details.cargoSize && (
              <CargoSizeRow
                id="cargoSize"
                label="Cargo Size(s)"
                expanded={state.expandedItem === "cargoSize"}
                onExpand={handleFocusRow}
                value={props.details.cargoSize}
                onValueChanged={handleCargoSizeChanged}
                text={props.details.cargoSize.value && cargoSizeText(props.details.cargoSize.value)}
                {...validation}
              >
                <NewCargoSizeEditorWithValidContext
                  defaultOption={"MOLOO"}
                  required={!props.details.cargoSize.negotiable}
                  onChange={handleCargoSizeValueChanged}
                  value={
                    props.details.cargoSize.value || {
                      display: "",
                      value: null,
                      unit: "",
                      option: null,
                      variance: null,
                      notes: "",
                    }
                  }
                  label="Cargo Size(s)"
                  focus={state.expandedItem === "cargoSize"}
                  withNotes
                />
              </CargoSizeRow>
            )}
            {showForOrderType("VOY") && props.details.loadLocation && (
              <LocationRow
                id="loadLocation"
                label="Load Location"
                expanded={state.expandedItem === "loadLocation"}
                onExpand={handleFocusRow}
                value={props.details.loadLocation}
                onValueChanged={handleLoadLocationChanged}
                text={props.details.loadLocation.value && props.details.loadLocation.value.display}
                {...validation}
              >
                <LocationEditor
                  id="loadLocationValue"
                  required={!props.details.loadLocation.negotiable}
                  onChange={(value) => handleLoadLocationValueChanged(value)}
                  value={props.details.loadLocation.value}
                  ctradeUrl={configContext.ctradeUrl}
                  focus={state.expandedItem === "loadLocation"}
                  hideLocationSafetyWarranty={false}
                />
              </LocationRow>
            )}
            {showForOrderType("VOY") && props.details.dischargeLocation && (
              <LocationRow
                id="dischargeLocation"
                label="Discharge Location"
                expanded={state.expandedItem === "dischargeLocation"}
                onExpand={handleFocusRow}
                value={props.details.dischargeLocation}
                onValueChanged={handleDischargeLocationChanged}
                text={props.details.dischargeLocation.value && props.details.dischargeLocation.value.display}
                {...validation}
              >
                <LocationEditor
                  id="dischargeLocationValue"
                  required={!props.details.dischargeLocation.negotiable}
                  value={props.details.dischargeLocation.value}
                  onChange={(value) => handleDischargeLocationValueChanged(value)}
                  ctradeUrl={configContext.ctradeUrl}
                  focus={state.expandedItem === "dischargeLocation"}
                  hideLocationSafetyWarranty={false}
                />
              </LocationRow>
            )}
            {showForOrderType("VOY") && props.details.freightRate && (
              <FreightRateRow
                id="freightRate"
                label="Freight Rate"
                expanded={state.expandedItem === "freightRate"}
                onExpand={handleFocusRow}
                value={props.details.freightRate}
                onValueChanged={handleFreightRateChanged}
                text={props.details.freightRate.value?.display}
                {...validation}
              >
                <NewUnitValueEditorWithValidContext
                  displayNotes
                  dataTest="freight-rate-notes"
                  id="freightRateValue"
                  notesId={"freight-rate-notes"}
                  type="freightRate"
                  label="Freight Rate"
                  defaultOption={"PerMT"}
                  required={!props.details.freightRate.negotiable}
                  options={FreightRateOptions}
                  onChange={handleFreightRateValueChanged}
                  notesPlaceholderText={"Additional freight rate notes"}
                  value={
                    props.details.freightRate.value || {
                      unit: "",
                      unitDisplay: "",
                      value: null,
                      notes: "",
                    }
                  }
                  focus={state.expandedItem === "freightRate"}
                />
              </FreightRateRow>
            )}
            {showForOrderType("VOY") && props.details.demurrage && (
              <DemurrageRow
                id="demurrage"
                label="Demurrage"
                expanded={state.expandedItem === "demurrage"}
                onExpand={handleFocusRow}
                value={props.details.demurrage}
                onValueChanged={handleDemurrageChanged}
                text={props.details.demurrage.value?.display}
                {...validation}
              >
                <NewUnitValueEditorWithValidContext
                  displayNotes
                  dataTest="demurrage-notes"
                  id="demurrageValue"
                  type="demurrage"
                  label="Demurrage"
                  notesId={"demurrage-notes"}
                  defaultOption={"PerDay"}
                  required={!props.details.demurrage.negotiable}
                  options={DemurrageOptions}
                  onChange={handleDemurrageValueChanged}
                  notesPlaceholderText={"Additional demurrage notes"}
                  value={
                    props.details.demurrage.value || {
                      unit: "",
                      unitDisplay: "",
                      value: null,
                      notes: "",
                    }
                  }
                  focus={state.expandedItem === "demurrage"}
                />
              </DemurrageRow>
            )}
            {showForOrderType("VOY") && props.details.voyOperationalSubs && (
              <OperationalSubsRow
                id="voyOperationalSubs"
                label="Operational Subs"
                expanded={state.expandedItem === "voyOperationalSubs"}
                onExpand={handleFocusRow}
                value={props.details.voyOperationalSubs}
                onValueChanged={handleVoyOperationalSubsChanged}
                text={props.details.voyOperationalSubs.value && props.details.voyOperationalSubs.value.display}
                {...validation}
              >
                <SubsTextEditorWithValidContext
                  id="voyOperationalSubsValue"
                  required={!props.details.voyOperationalSubs.negotiable}
                  value={
                    props.details.voyOperationalSubs.value || {
                      value: "",
                      display: "",
                      title: "",
                    }
                  }
                  onChange={handleVoyOperationalSubsValueChanged}
                  focus={state.expandedItem === "voyOperationalSubs"}
                />
              </OperationalSubsRow>
            )}
            {showForOrderType("VOY") && props.details.voyCommercialSubs && (
              <CommercialSubsRow
                id="voyCommercialSubs"
                label="Commercial Subs"
                expanded={state.expandedItem === "voyCommercialSubs"}
                onExpand={handleFocusRow}
                value={props.details.voyCommercialSubs}
                onValueChanged={handleVoyCommercialSubsChanged}
                text={props.details.voyCommercialSubs.value && props.details.voyCommercialSubs.value.display}
                {...validation}
              >
                <SubsTextEditorWithValidContext
                  id="voyCommercialSubsValue"
                  required={!props.details.voyCommercialSubs.negotiable}
                  value={
                    props.details.voyCommercialSubs.value || {
                      value: "",
                      display: "",
                      title: "",
                    }
                  }
                  onChange={handleVoyCommercialSubsValueChanged}
                  focus={state.expandedItem === "voyCommercialSubs"}
                />
              </CommercialSubsRow>
            )}
            {showForOrderType("VOY") && props.details.voyNotes && (
              <NotesRow
                id="notes"
                label="Voyage Notes"
                expanded={state.expandedItem === "notes"}
                onExpand={handleFocusRow}
                value={props.details.voyNotes}
                onValueChanged={handleVoyNotesChange}
                text={props.details.voyNotes.value && props.details.voyNotes.value.display}
                {...validation}
              >
                <Notes
                  dataTest="Voyage Notes textarea"
                  value={props.details.voyNotes.value ? props.details.voyNotes.value.value : undefined}
                  onChange={handleVoyNotesValueChanged}
                  ariaLabel="Voyages Notes text area"
                  focus={state.expandedItem === "notes"}
                />
              </NotesRow>
            )}
            {/* // Excluded, VOY and TCT, finish harmonginsing this file, then make
            sure the TCT and VOY editors look right, then make sure freightRate
            etc is being POSTED */}

            <ExcludedItems items={excludedItems} onInclude={handleIncludeDetail} />
            {/* <ExcludedItems
              items={excludedTctItems}
              onInclude={handleIncludeDetail}
            /> */}
          </div>
        )}
      </ValidationContext.Consumer>
    </>
  );
};
