import { wait } from "___REFACTOR___/utils";

export async function actionRetry(params, numberOfRetries = 1, retryDelay = 0) {
  const { action, handleSuccess, handleFailure } = params;

  for (let i = 0; i < numberOfRetries; i++) {
    if (retryDelay !== 0) {
      await wait(retryDelay);
    }

    const success = await action();

    if (success) {
      const res = await handleSuccess();

      return res;
    }
  }

  const res = await handleFailure();

  return res;
}

export async function actionRetryWithResponce(params, numberOfRetries = 1, retryDelay = 0) {
  const { action, handleSuccess, handleFailure } = params;
  let responce;

  for (let i = 0; i < numberOfRetries; i++) {
    if (retryDelay !== 0 && i > 0) {
      await wait(retryDelay);
    }

    responce = await action();
    const success = responce && responce.ok;

    if (success) {
      const res = await handleSuccess(responce);

      return res;
    }
  }

  const res = await handleFailure(responce);

  return res;
}
