import React, { HTMLProps, useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { InputWidthType } from "../types";
import styles from "__legacy/sharedFolder/styles/form-styles.module.scss";

type InputProps = Omit<HTMLProps<HTMLInputElement>, "onChange" | "defaultValue" | "value">;

interface INumberInputProps extends InputProps {
  onChange?: (value: number | undefined) => void;
  onOutOfBoundsChanged?: (valid: boolean) => void;
  defaultValue?: number;
  formatOnBlur?: (value: undefined | number) => string;
  correctOnBlur?: (value: number) => number;
  min?: number;
  max?: number;
  autoFocus?: boolean;
  width?: InputWidthType;
}

/**
 * Resolve value from a given string
 * Accommodates "K" notation
 */
export const valueFromString = (value?: string): number | undefined => {
  if (value === undefined || value.length === 0) {
    return undefined;
  }
  // 'k' formatting
  const multiplier = /[Kk]$/.test(value) ? 1000 : 1;
  const sanitizedValue = value.toLocaleLowerCase().replace("k", "").replace(/,/g, "");
  const numVal = Number(sanitizedValue);
  return isNaN(numVal) ? undefined : multiplier * numVal;
};

function isValid(min?: number, max?: number, value?: number): boolean | undefined {
  if (value === undefined) {
    return undefined;
  }
  if (min !== undefined && value < min) {
    return false;
  }
  if (max !== undefined && value > max) {
    return false;
  }
  return true;
}

/**
 * Text input for Numbers.
 * Will call onChange once a valid number value is entered.
 * Provides options for:
 * - Formatting the content on blur (e.g. converting numerical 1000 to display value "1,000")
 * - Correcting/mutating the value on blur (e.g. rounding numberical 1.75 to 2)
 *
 * @example
    <NumberInput
      id="cargoSize"
      onChange={value => setValue(value)}
      defaultValue={10}
      formatOnBlur={value =>
        value === undefined
          ? ""
          : Math.round(value).toLocaleString()
      }}
      correctOnBlur={value => Math.round(value)}
      autoComplete="off"
      required={true}
    />
 *
 * @param props Input props
 */
export const NumberInput: React.FC<INumberInputProps> = (props: INumberInputProps) => {
  const {
    className,
    onChange,
    onOutOfBoundsChanged,
    defaultValue,
    formatOnBlur,
    correctOnBlur,
    min,
    max,
    autoFocus,
    width,
    ...otherProps
  } = props;

  function usePrevious(value?: boolean): boolean | undefined {
    const ref = useRef<boolean | undefined>();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevState = usePrevious(autoFocus);

  useEffect(() => {
    if (prevState !== autoFocus && autoFocus) {
      inputRef?.current?.focus();
    }
  });

  // string value - typed and entered into
  const [stringValue, setStringValue] = useState<string>(defaultValue === undefined ? "" : String(defaultValue));
  const [previousValue, setPreviousValue] = useState<number | undefined>(defaultValue);

  const [focussed, setFocussed] = useState<boolean>(Boolean(props.autoFocus));
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const num = valueFromString(stringValue);
    const correctedNum = correctOnBlur && num ? correctOnBlur(num) : num;
    if (onChange && correctedNum !== previousValue) {
      setPreviousValue(correctedNum);
      onChange(correctedNum);
    }
    if (onOutOfBoundsChanged) {
      const valid = isValid(min, max, num);
      if (valid !== undefined) {
        onOutOfBoundsChanged(valid);
      }
    }
  }, [stringValue, previousValue, correctOnBlur, onChange, min, max, onOutOfBoundsChanged]);

  const styleWidth = width ? styles[width] : "";
  const inputClasses = classNames(styles.input, styleWidth, className);

  return (
    <input
      data-test={`${props.id}-input`}
      className={inputClasses}
      ref={inputRef}
      type="text"
      onChange={(evt) => {
        setStringValue(evt.target.value);
      }}
      value={focussed ? stringValue : (formatOnBlur && formatOnBlur(valueFromString(stringValue))) || stringValue}
      onBlur={(evt) => {
        setFocussed(false);
        const value = valueFromString(stringValue);
        if (correctOnBlur && value) {
          setStringValue(String(correctOnBlur(value)));
        }
      }}
      min={props.min}
      max={props.max}
      onFocus={(evt) => setFocussed(true)}
      pattern="^[0-9,\.]*[Kk]?$"
      autoFocus={props.autoFocus}
      {...otherProps}
    />
  );
};
