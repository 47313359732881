import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useSimpleEffect } from "@/utils";
import { tradeAPI } from "@/apis";
import { dialog } from "@/models";
import { Status } from "@/components";
import "./HandleNewOwnerUser.scss";

export function HandleNewOwnerUser() {
  const { offerRepToken } = useParams<MaybeStringRecord>();
  const centralToken = new URLSearchParams(window.location.search).get("token")!;
  const [status, setStatus] = useState<Status>({});

  useSimpleEffect(handleNewOwnerUser, []);

  async function handleNewOwnerUser() {
    if (!offerRepToken) {
      const status = {
        type: "error",
        title: "Missing Offerrep Token",
      } as Status;

      dialog.show({
        status,
        dataTest: "handle-new-owner-user-missing-offerrep-token",
      });

      setStatus(status);

      return;
    }

    const negotiationRes = await tradeAPI.getNegotiationViaOfferrep(offerRepToken);

    if (!negotiationRes.ok) {
      const status = {
        type: "error",
        title: "Negotiation Fetch Failure",
        dump: { res: negotiationRes },
      } as Status;

      dialog.show({
        status,
        dataTest: "handle-new-owner-user-negotiation-fetch-fail",
      });

      setStatus(status);

      return;
    }

    const addUserToNegotiationGroupChatRes = await tradeAPI.addUserToNegotiationGroupChatViaOfferrep(offerRepToken, {
      headers: {
        "clarksons.cloud.logintoken": centralToken,
        "X-API-Version": "3",
        groupChatId: negotiationRes.data?.groupChat?.id,
      },
    });

    if (!addUserToNegotiationGroupChatRes.ok) {
      const status = {
        type: "error",
        title: "Add User to Negotiation Group Chat Failure",
        dump: { res: addUserToNegotiationGroupChatRes },
      } as Status;

      dialog.show({
        status,
        dataTest: "handle-new-owner-user-add-user-group-chat-fail",
      });

      setStatus(status);

      return;
    }

    const tokenRes = await tradeAPI.getToken(centralToken);

    if (!tokenRes.ok) {
      const status = {
        type: "error",
        title: "Token Fetch Failure",
        dump: { res: tokenRes },
      } as Status;

      dialog.show({
        status,
        dataTest: "handle-new-owner-user-token-fetch-fail",
      });

      setStatus(status);

      return;
    }

    window.location.pathname = "/";
  }

  return (
    <div className="handleNewOwnerUser">
      <Status status={status} />
    </div>
  );
}
