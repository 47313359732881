import { Aggrid } from "___REFACTOR___/components";
import { stringArrToBoolMap } from "___REFACTOR___/utils";
import * as Stores from "___REFACTOR___/stores";
import { Clddu, Company } from "../../Clddu";
import { EmailList } from "../EmailList";

class Sharing {
  static extractSelection(entity: Clddu.Entity, res = [] as string[]) {
    if (entity.selection.state === "selected") {
      res.push(entity.id);
    }
    //
    else {
      for (let i = 0; i < entity.childArr.length; i++) {
        const child = entity.childArr[i];

        if (child.selection.state === "selected") {
          res.push(child.id);

          continue;
        }

        this.extractSelection(child, res);
      }
    }

    return res;
  }

  constructor(emailList: EmailList) {
    this.emailList = emailList;

    this.sharingMap = stringArrToBoolMap(emailList.sharing);
  }

  initClddu() {
    this.clddu = new Clddu();
    this.company = this.clddu.upsertChild({ ...Stores.userCompany.data, parent: this.clddu, clddu: this.clddu });
    this.company.initFromFullData();

    this.clddu.forEachChild((child) => {
      if (this.sharingMap[child.id]) child.selection.select();
    });
  }

  toJSON() {
    return Sharing.extractSelection(this.company);
  }
}

export { Sharing };

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

interface Sharing {
  clddu: Clddu;
  company: Company;
  emailList: EmailList;
  aggridContext?: Aggrid.Context;
  sharingMap: { [id: string]: true };
}

declare namespace Sharing {
  interface Seed {}

  interface EntityMap {
    [path: string]: Clddu.Entity;
  }
}
