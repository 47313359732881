import React, { useRef } from "react";
import { emptyFn } from "___REFACTOR___/utils";
import { Period } from "___REFACTOR___/models";
import { MaritimeDateRange } from "@maritech/maritime-date";
import { IOEditor } from "../IO";

function PeriodEditor(props: PeriodEditor.Props) {
  const { value, dataTest, onChange } = props;

  return (
    <IOEditor
      {...props}
      editorType="Period"
      value={value?.toView()}
      onInputChange={onInputChange}
      dataTest={`${dataTest}.value`}
      seedInputWithCurrentValue
    />
  );

  function onInputChange(text: IOEditor.InputValue) {
    if (text === undefined) onChange?.(undefined);

    if (!text) return;
    const newParsedLaycan = MaritimeDateRange.parse(text, { americanFormat: false, contextualParsing: "future" });
    if (newParsedLaycan.isValid)
      props.onChange?.(
        new Period({
          style: 0,
          styleDescription: "Exact",
          start: newParsedLaycan.start.toJSDate().toISOString(),
          end: newParsedLaycan.end.startOf("day").toJSDate().toISOString(),
          term: text,
        })
      );
  }
}

export { PeriodEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace PeriodEditor {
  interface Props extends IOEditor.Props {
    value: Value;
    onChange: (value: Value) => void;
  }

  type Value = Period | undefined;
}
