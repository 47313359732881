import { object, string, number } from "yup";
import { CargoType } from "../CargoType";

// https://github.com/jquense/yup
const schema = object({
  arcId: number().integer().required("Required"),
  name: string().required("Required"),
  notes: string(),
}).transform(toJSON);

function toJSON(cargoType: CargoType) {
  return cargoType?.toJSON();
}

export { schema };
