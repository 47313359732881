import { object, string, number, TestContext } from "yup";
import { TradeAPI } from "___REFACTOR___/apis";
import { CargoSize } from "../CargoSize";

const options = ["MIN/MAX", "MOLCHOPT", "MOLOO"] as TradeAPI.CargoSize.Option[];

// https://github.com/jquense/yup
const schema = object({
  value: number().integer().required("Required"),
  variance: number().integer().nullable(),
  option: string().oneOf(options),
  notes: string().nullable(),
})
  .test({
    test: varianceValidation,
    name: "varianceValidation",
    message: "Something is wrong with Variance",
  })
  .transform(toJSON);

function toJSON(cargoSize: CargoSize) {
  return cargoSize?.toJSON();
}

function varianceValidation(this: TestContext, cargoSize) {
  if (!cargoSize) return true;

  const { option, variance } = cargoSize as TradeAPI.CargoSize;

  const error = this.createError();

  if (option === "MIN/MAX" && variance) {
    error.message = "Variance should be 0 with MIN/MAX option";
    error.path = `${error.path}.variance`;

    return error;
  }

  if (option === "MOLCHOPT" && !variance) {
    error.message = "Variance should be above 0 with MOLCHOPT option";
    error.path = `${error.path}.variance`;

    return error;
  }

  if (option === "MOLOO" && !variance) {
    error.message = "Variance should be above 0 with MOLOO option";
    error.path = `${error.path}.variance`;

    return error;
  }

  return true;
}

export { schema };
