import React from "react";
import styles from "./OrderDetails.module.scss";
import { INegotiationDetailsView } from "sharedFolder/Models/Negotiation/INegotiationView";
import { NonNegotiableDetails } from "../NonNegotiableDetails/NonNegotiableDetails";
import classNames from "classnames";
import { OrderNegType } from "sharedFolder/Models/OrderNegType";

export const OrderDetails: React.SFC<{
  negotiation: INegotiationDetailsView;
  orderNegType: OrderNegType;
  displayWithBorders?: boolean;
}> = ({ negotiation, orderNegType, displayWithBorders }) => {
  return (
    <>
      <div>
        <div className={classNames(styles.orderDetails, displayWithBorders && styles.orderDetailsWithBorders)}>
          {negotiation && <NonNegotiableDetails details={negotiation} orderNegType={orderNegType} />}
        </div>
      </div>
    </>
  );
};
