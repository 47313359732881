import React, { Children, cloneElement, ReactNode } from "react";
import classNames from "classnames";
import { DataModel } from "___REFACTOR___/models";
import { useShallowStateOld } from "___REFACTOR___/utils";
import { StatusMessages } from "___REFACTOR___/components";
import { RadioField, CheckboxField } from "../type";
import { Field } from "../Field";
import { Label } from "../Label";
import "./Container.scss";

function Container(props: Container.Props) {
  const { checkedValue, type } = props;
  const { desc, status, suppressStatusMessages, editorType, hidden, value, readOnly } = props;
  const [state, setState] = useShallowStateOld(defaultState);
  const isCheckable = type === "Radio" || type === "Checkbox";
  const checked = isCheckable && checkedValue === value;
  const className = classNames(props.className, editorType, type, status?.type, {
    status,
    checked,
    "read-only": readOnly,
    ...state,
  });
  let children = Children.map(props.children, mapChild) as ReactNode;

  if (readOnly) {
    children = (
      <app-field-read-only-value>{(value instanceof DataModel ? value.toView() : value) || "--"}</app-field-read-only-value>
    );
  }

  if (hidden) return null;

  return (
    <app-field class={className} title={desc}>
      <Label {...props} />
      {children}
      <StatusMessages status={status} hidden={suppressStatusMessages} />
    </app-field>
  );

  function mapChild(child: ReactNode) {
    // @ts-ignore
    return cloneElement(child, { ...child?.props, onFocus, onBlur });
  }

  function onFocus(e) {
    setState({ focus: true });

    props?.onFocus?.(e);
  }

  function onBlur(e) {
    setState({ focus: false });

    props?.onBlur?.(e);
  }
}

const defaultState = {
  focus: false,
} as State;

export { Container };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace Container {
  interface Props extends Field.Props {
    children: ReactNode;
    checkedValue?: RadioField.Input.CheckedValue | CheckboxField.Input.CheckedValue;
  }
}

interface State {
  focus: boolean;
}
