import { IOrderSummary } from "../../models/IOrderSummary";
import { permissionsFromJwt } from "sharedFolder/apis/permissionsFromJwt";
import { IPermittedMethods } from "sharedFolder/Models/PermittedMethods";
import { IOrderSummary as IOrderSummaryApi } from "../../../api/orders/models/IOrderSummary";

/**
 * Map API Order Summary to Order Grid summary view model
 * @param order API order summary
 */
export function mapOrderSummary(order: IOrderSummaryApi): IOrderSummary {
  return {
    id: order.id,
    coaCargoSize: order.summary.coaCargoSize || "",
    createdBy: order.summary.createdBy,
    updateToken: order.updateToken,
    createdOn: order.createdOn,
    firmExpires: order.summary.firmExpires,
    hasUpdated: false,
    isNew: false,
    tctCount: order.summary.tctCount,
    voyCount: order.summary.voyCount,
    liftings: order.summary.liftings || "",
    orderNumber: order.summary.orderReference,
    onSubsExpires: order.summary.onSubsExpires,
    period: order.summary.period || "",
    responseRequiredOn: order.responseRequired || null,
    seaContractsUrl: order.seaContractsUrl,
    status: order.status,
    version: order.version,
    chartererAccount: order.summary.chartererAccount,
    cargoSize: order.summary.cargoSize || "",
    cargoType: order.summary.cargoType || "",
    laycan: order.summary.laycan,
    locations: order.summary.locations,
    groupChats: order.groupChats,
    permittedMethods: order.updateToken ? permissionsFromJwt<IPermittedMethods>(order.updateToken) : [],
    fixedOn: order.fixedOn,
    owningCompany: order.owningCompany,
  };
}
