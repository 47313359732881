import React, { MutableRefObject, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { get } from "lodash-es";
import { useShallowStateRef } from "___REFACTOR___/utils";
import { ARROW_KEY, EDIT_KEY, SPECIAL_KEY } from "___REFACTOR___/constants";
import { DataModel, Status } from "___REFACTOR___/models";
import * as Services from "___REFACTOR___/services";
import { Dropdown, ImgProps } from "___REFACTOR___/components";
import { Input } from "../../Input";
import { Container } from "../../Container";
import { Icon } from "../../Icon";
import "./Select.scss";

function _SelectInput(props: SelectInput.Props) {
  let {
    img,
    data,
    value,
    onFilter,
    dataTest,
    filterKey,
    noDataStatus,
    dropdownStatus,
    nonFilterSelect,
    dropdownClassName,
    hybridMode,
    className,
  } = props;

  const [state, setState] = useShallowStateRef<State>({ ...defaultState });

  className = classNames(className, { open: state.open, "non-filter-select": nonFilterSelect });

  if (value instanceof DataModel) {
    img = state.shadowFocus ? undefined : value?._.img;

    value = value.toSelectView?.();
  }

  if (!value) value = "";

  const originalData = data;
  data = state.filtered || originalData;

  if (!data || !data.length) dropdownStatus = dropdownStatus || noDataStatus;

  let inputValue = value;

  if (state.shadowFocus) inputValue = state.search;
  if (nonFilterSelect) inputValue = value;

  const domRef = useRef(null) as SelectInput.DomRef;
  const dropdown = useRef<Dropdown.Instance>(null);
  const searchIndexTimeoutId = useRef(-1);

  useEffect(onMount, []);
  useEffect(setIndex, [state.indexSearch]);
  useEffect(controlDropdown, [state.open]);
  useEffect(updateDropdown, [data, dropdownStatus, dropdown, dropdownClassName]);

  return (
    <Container
      {...props}
      className={className}
      type="Select"
      onFocus={onFocus}
      onBlur={onBlur}
      onClick={onClick}
      img={img}
      clearable={!nonFilterSelect}
    >
      <input
        value={inputValue}
        onChange={onSearchChange}
        onKeyDown={onKeyDown}
        placeholder={props.placeholder}
        disabled={props.disabled}
        ref={domRef}
        data-test={`${props.dataTest}.input`}
        type="text"
      />
      <Icon icon="chevron-down" align="right" onClick={onClick} />
    </Container>
  );

  function onMount() {
    if (props.domRef) props.domRef.current = domRef.current;

    function onUnmount() {
      if (props.domRef) props.domRef.current = null;
    }

    return onUnmount;
  }

  function updateDropdown() {
    if (dropdown.current && !dropdown.current.open) return;

    Services.dropdown.update({
      data,
      dropdown,
      dataTest,
      status: dropdownStatus,
      className: dropdownClassName,
    });
  }

  function controlDropdown() {
    if (state.open) showDropdown();
    //
    else Services.dropdown.hide();
  }

  async function showDropdown() {
    const target = domRef.current;

    if (target) {
      const item = await Services.dropdown.show({
        target,
        data,
        dataTest,
        status: dropdownStatus,
        dropdown,
        className: dropdownClassName,
      });

      if (hybridMode) {
        const search = state.search;

        setState({ open: false, shadowFocus: false, search: "", filtered: null });

        if (item || search) props.onChange?.(item || search);

        //
      } else {
        setState({ open: false, shadowFocus: false, search: "", filtered: null });

        if (item) props.onChange?.(item);
      }
    }
  }

  function setIndex() {
    if (!state.indexSearch) return;

    const { index = 0, setIndex, data, list } = dropdown.current || {};

    function reducer(search: string, acc: any, item: Dropdown.Item, i: number) {
      if (startsWith(search, itemFilterSelector(item))) {
        acc.array.push(i);
        acc.map[i] = acc.array.length - 1;
      }

      return acc;
    }

    function findAll(search: string) {
      return data?.reduce(reducer.bind(null, search), { array: [], map: {} });
    }

    function resolveNextIndex(search: string) {
      const match = findAll(search);
      const matchIndex = match?.map[index];
      const indexOfLastMatch = match?.array[match?.array.length - 1];

      let nextIndex;

      // if (search.length > 1 && match.array.length) nextIndex = index;
      //
      if (typeof matchIndex !== "number") nextIndex = match?.array[0];
      //
      else if (index === indexOfLastMatch) nextIndex = match?.array[0];
      //
      else nextIndex = match?.array[matchIndex + 1];

      return nextIndex;
    }

    let nextIndex = resolveNextIndex(state.indexSearch);

    if (typeof nextIndex !== "number") {
      const lastChar = state.indexSearch.slice(-1);

      nextIndex = resolveNextIndex(lastChar);
    }

    if (typeof nextIndex === "number") {
      setIndex?.(nextIndex);

      list?.current?.scrollToItem(nextIndex, "smart");
    }
  }

  function updateIndexSearch(key: string) {
    clearTimeout(searchIndexTimeoutId.current);

    searchIndexTimeoutId.current = setTimeout(setState.bind(null, { indexSearch: "" }), 400);

    setState({ indexSearch: state.indexSearch + key });
  }

  function itemFilterSelector(item: Dropdown.Item) {
    if (item instanceof DataModel) {
      item = item.toDropdownView();

      //
    } else if (typeof filterKey === "function") {
      item = filterKey(item);

      //
    } else if (filterKey) {
      item = get(item, filterKey);
    }

    return item;
  }

  function itemFilterer(search: string, item: Dropdown.Item) {
    item = itemFilterSelector(item);

    search = search.toLowerCase();

    const str = `${item}`.toLowerCase();

    const res = startsWith(search, str) || str.split(" ").some(startsWith.bind(null, search)) || str?.includes(search);

    return res;
  }

  function filter(search: string) {
    if (!originalData) return;

    if (!search) return setState({ filtered: null });

    const filtered = originalData.filter(itemFilterer.bind(null, search));

    setState({ filtered });
  }

  function onSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    const search = e.currentTarget.value;

    if (nonFilterSelect) return;

    setState({ search });

    if (!onFilter) return filter(search);

    onFilter(search);
  }

  function onClick() {
    if (hybridMode) {
      setState({ open: true, shadowFocus: true });
    } else {
      setState({ open: true, search: "", shadowFocus: true });
    }
  }

  function onFocus(e) {
    if (hybridMode) {
      setState({ open: true, shadowFocus: true });
    } else {
      setState({ open: true, search: "", shadowFocus: true });
    }

    props.onFocus?.(e);
  }

  function onBlur(e) {
    if (hybridMode) {
      setState({ open: false, shadowFocus: false, filtered: null });
    } else {
      setState({ open: false, search: "", shadowFocus: false, filtered: null });
    }

    props.onBlur?.(e);
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (hybridMode) {
      if (nonFilterSelect && !ARROW_KEY[e.key]) {
        updateIndexSearch(e.key);
      }

      if (state.shadowFocus) return;

      if (EDIT_KEY[e.key]) {
        setState({ open: true, shadowFocus: true, filtered: null });

        return;
      }

      if (!SPECIAL_KEY[e.key]) {
        setState({ open: true, shadowFocus: true });
      }

      return;
    }

    if (nonFilterSelect && !ARROW_KEY[e.key]) {
      updateIndexSearch(e.key);
    }

    if (state.shadowFocus) return;

    if (EDIT_KEY[e.key]) {
      setState({ open: true, shadowFocus: true, search: "", filtered: null });

      return;
    }

    if (!SPECIAL_KEY[e.key]) {
      setState({ open: true, shadowFocus: true });
    }
  }
}

function startsWith(substr: string, str: string) {
  return str?.toLowerCase().startsWith(substr.toLowerCase());
}

const defaultState = {
  search: "",
  indexSearch: "",
  shadowFocus: false,
  filtered: null,
  open: false,
} as State;

const { setTimeout, clearTimeout } = window;

const SelectInput = observer(_SelectInput);

export { SelectInput };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

// eslint-disable-next-line no-redeclare
declare namespace SelectInput {
  interface Props extends Input.Props {
    data?: Dropdown.Props["data"];
    onFilter?: (search: string) => void;
    dropdownClassName?: string;
    dropdownStatus?: Status;
    getDropdownImg?: (value: Dropdown.Item) => ImgProps["img"] | undefined;
    filterKey?: string | number | ((item: Dropdown.Item) => string | number);
    noDataStatus?: Status;
    nonFilterSelect?: boolean;
    hybridMode?: boolean;
    domRef?: DomRef;
  }

  export type DomRef = MutableRefObject<HTMLInputElement | null>;
}

interface State {
  open: boolean;
  search: string;
  indexSearch: string;
  shadowFocus: boolean;
  filtered?: Dropdown.Props["data"];
}
