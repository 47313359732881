import React, { useState, useLayoutEffect, useEffect } from "react";
import { IOfferRepViewNegotiation } from "negotiations/models/common/IOfferRep";
import styles from "./LiftingsLayout.module.scss";
import classnames from "classnames";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { IConfiguration } from "sharedFolder/Configuration";
import { OrderDetails } from "sharedFolder/components/common/OrderDetails/OrderDetails";
import { OrderNotesAndAttachmentsForDashboard } from "../OrderDetails/OrderNotesAndAttachments";
import { LiftingsForm } from "./LiftingsForm";
import { RequestChangesForm } from "./RequestChangesForm";
import { INegotiationDetailsView, INegotiationView } from "sharedFolder/Models/Negotiation/INegotiationView";
import { Subscription } from "rxjs";
import LoadingSpinner from "sharedFolder/components/common/LoadingSpinner/LoadingSpinner";
import { getNegotiationApiService } from "api/negotiations/Negotiations";
import offerRepMapper from "../api/OfferRepMapper";

type LiftingsFormOrRequestChanges = "LiftingsForm" | "RequestChanges";

interface ILiftingsLayoutProps {
  config: IConfiguration;
  negotiation: IOfferRepViewNegotiation;
}

export const LiftingsLayout: React.FC<ILiftingsLayoutProps> = (props: ILiftingsLayoutProps) => {
  const [isLoadingCoaNegotiation, setIsLoadingCoaNegotiation] = useState(false);
  const [coaNegotiation, setCoaNegotiation] = useState<INegotiationView>();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { coaNegotiationId, orderId } = props.negotiation;
  const ctradeUrl = props.config.ctradeUrl;

  type LiftingsFormOrRequestChanges = "LiftingsForm" | "RequestChanges";
  const [whichFormToDisplay, setWhichFormToDisplay] = useState<LiftingsFormOrRequestChanges>("LiftingsForm");

  useLayoutEffect(() => {
    setIsLoadingCoaNegotiation(true);
    const sub: Subscription = getNegotiationApiService(ctradeUrl, orderId)
      .getOwnerNegotiation(orderId, coaNegotiationId!)
      .subscribe(
        (negotiation) => {
          setCoaNegotiation(offerRepMapper(negotiation));
          setIsLoadingCoaNegotiation(false);
        },
        (error) => {
          setIsLoadingCoaNegotiation(false);
          // TODO Scream in dispair at the lack of error handling across this entire application
        }
      );

    return () => sub && sub.unsubscribe();
  }, [coaNegotiationId, orderId, ctradeUrl]);

  useEffect(() => {
    setWhichFormToDisplay("LiftingsForm");
  }, [selectedTabIndex]);

  return (
    <div className={classnames(styles.tabsContainer, selectedTabIndex === 0 && styles.fixHeightHack)}>
      <Tabs onSelect={setSelectedTabIndex}>
        <TabList className={classnames(styles.tabHeaderRibbon)}>
          <Tab data-test="liftings-tab">
            <label>Liftings</label>
          </Tab>
          <Tab data-test="coa-details-tab">
            <label>COA Details</label>
          </Tab>
          <Tab data-test="notes-attachments">
            <label>Notes &amp; Attachments</label>
            <sup className={styles.badge}>
              {props.negotiation.attachments.length +
                (props.negotiation.details?.controllerNegotiationNotes?.value ? 1 : 0) +
                (props.negotiation.details?.offerRepNegotiationNotes?.value ? 1 : 0)}
            </sup>
          </Tab>
        </TabList>
        <TabPanel>
          {whichFormToDisplay === "LiftingsForm" && (
            <LiftingsForm {...props} onRequestChanges={() => setWhichFormToDisplay("RequestChanges")} />
          )}
          {whichFormToDisplay === "RequestChanges" && (
            <RequestChangesForm {...props} onCancel={() => setWhichFormToDisplay("LiftingsForm")} />
          )}
        </TabPanel>
        <TabPanel>
          <div className={styles.summaryLayout}>
            {isLoadingCoaNegotiation && (
              <div className={styles.spinnerContainer} data-test="loading-coa-details">
                <LoadingSpinner />
              </div>
            )}

            {coaNegotiation && (
              <OrderDetails negotiation={coaNegotiation.details as INegotiationDetailsView} orderNegType={coaNegotiation.type} />
            )}
          </div>
        </TabPanel>
        <TabPanel>
          <OrderNotesAndAttachmentsForDashboard negotiation={props.negotiation} />
        </TabPanel>
      </Tabs>
    </div>
  );
};
