// @ts-nocheck
import { Observable, ReplaySubject, combineLatest } from "rxjs";
import { map, startWith } from "rxjs/operators";

/**
 * Common optimistic update functionality.
 * Tracks both remote and local versions, and provides the live data
 * as an Observable of Array<T>.
 */
export class Optimistic<T extends { version: number; id: string | null }> {
  private readonly sessionStorageKey = `${this.identifier}-local`;

  /**
   * Represents current state of data as held in remote storage (API endpoint)
   */
  private remoteState: T[] = [];

  /**
   * Represents current state of the data ahead of that held remotely
   */
  private localState: T[] = [];

  /**
   * Observable from a Replay Subject.
   * Keeps a history of 1, so that any new subscribers receive the latest state.
   */
  private remoteSubject = new ReplaySubject<T[]>(1);

  /**
   * Track latest offline changes
   */
  private localSubject = new ReplaySubject<T[]>(1);

  constructor(private identifier: string) {}

  public getItem(id: string): T | undefined {
    // this will always point to the remote store because you need an update token to do an update
    const items = this.remoteState;
    return items.find((item) => item.id === id);
  }

  /* remove an item from the collection */
  public removeItemFromLocalState(id: string) {
    const found = this.localState.find((o) => o.id === id);

    if (found) {
      this.localState.splice(this.localState.indexOf(found), 1);
    }
    this.localSubject.next(this.localState);
  }
  /**
   * Replace the known state of the remote storage; should be called when a new Get All API
   */
  public replaceAllRemote(newValue: T[]) {
    this.remoteState = newValue;
    this.remoteSubject.next(this.remoteState);
  }

  /**
   * Replace a single value on the Remote storage.
   * e.g. when fetching individual resource by ID, rather than Get All
   * @param newValue
   */
  public replaceSingleRemote(newValue: T) {
    // match by ID and ensure the new remote version is at least the same as the local version
    const found = this.remoteState.find((o) => o.id === newValue.id && newValue.version >= o.version);
    if (found) {
      this.remoteState.splice(this.remoteState.indexOf(found), 1, newValue);
    } else {
      this.remoteState.push(newValue);
    }
    this.remoteSubject.next(this.remoteState);
  }

  public replaceOrPushSingleLocal(newValue: T) {
    const found = this.localState.find((o) => o.id === newValue.id && newValue.version > o.version);
    if (found) {
      this.localState.splice(this.localState.indexOf(found), 1, newValue);
    } else {
      this.localState.push(newValue);
    }
    this.localSubject.next(this.localState);
  }

  /**
   * Add a new entry to local state.
   * @param newValue New
   */
  public pushToLocal(newValue: T) {
    this.localState.push(newValue);
    this.localSubject.next(this.localState);
  }

  /**
   * Clear all cached data
   * and emit an empty model
   */
  public clear() {
    this.localState.length = 0;
    this.remoteState.length = 0;
    this.remoteSubject.next(this.remoteState);
  }

  /**
   * Observable stream of current states.
   * Combines remote state and local state. Emits on every local change,
   * and whenever the state of the remote data changes.
   */
  get data(): Observable<T[]> {
    return combineLatest([this.remoteSubject.pipe(startWith(null)), this.localSubject.pipe(startWith([]))]).pipe(
      map((latest) => {
        const [remote, local] = latest;
        if (remote === null) {
          // if we haven't yet received data from remote
          return local;
        }

        const result = [...remote];
        // if we have any data from remote
        // splice the latest version of local on top of it
        local
          // sort by version ascending
          .sort((a, b) => a.version - b.version)
          .forEach((localItem) => {
            // if the local entity matches a remote entity
            const remoteItem = remote.find((r) => r.id === localItem.id && localItem.id !== null);
            if (remoteItem && remoteItem.version < localItem.version) {
              // where the local entity is a more recent version
              // use the local item in preference to the remote one
              result.splice(remote.indexOf(remoteItem), 1, localItem);
            } else if (!remoteItem) {
              // if the local entity is new
              result.push(localItem);
            }
          });
        return result;
      })
    );
  }
}
