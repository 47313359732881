import { config } from "@/config";
import { auth } from "@/models";
import { emptyArrowFn } from "@/utils";

class IntercomWidget {
  constructor() {
    this.initialized = false;
  }

  setup = (...rest) => {
    if (!window.location.pathname.includes("offerrep")) {
      const { email, UserName } = auth.trade.user || {};

      const w: any = window;
      const ic = w.Intercom;

      w.intercomSettings = {
        alignment: "left",
        app_id: "h2488k8w",
        name: UserName,
        email,
      };

      if (typeof ic === "function") {
        ic("reattach_activator");
        ic("update", w.intercomSettings);
      } else {
        const i: any = function () {
          i.c(rest);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
      }

      const { Intercom = emptyArrowFn, intercomSettings = {} } = w as any;
      Intercom("update", intercomSettings);

      const script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src = `https://widget.intercom.io/widget/${config.intercomSettingsAppId}`;
      script.id = "SeaChatWidgetScript";
      script.onload = this.onLoad;
      document.body.appendChild(script);
    }
  };

  onLoad = async () => {
    const w: any = window;
    w.intercomSettings.app_id = config.intercomSettingsAppId;
    this.initialized = true;
  };
}

export const intercomWidget = new IntercomWidget();

interface IntercomWidget {
  initialized: boolean;
  intercomSettingsId?: string;
}
