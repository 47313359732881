import { HubConnectionBuilder, HubConnection } from "@microsoft/signalr";
import { makeObservable, observable } from "mobx";
import { JSONSchema7 } from "json-schema";
import { Request, Config } from "@/request";
import { auth } from "@/models";
import { log } from "@/services";
import { config } from "@/config";

export class TradeAPI {
  constructor() {
    this.connection = undefined;

    makeObservable(this, {
      connection: observable.ref,
    });
  }

  setup = async () => {
    this.request = new Request({ baseURL: config.ctradeUrl });

    await auth.trade.promise;

    const headers = this.request.axios.defaults.headers.common;

    Object.assign(headers, {
      "clarksons.cloud.logintoken": headers["clarksons.cloud.logintoken"] || auth.central.token,
      authorization: `Bearer ${auth.trade.token}`,
    });

    const signalRCredentials = await this.getSignalRCredentials();

    if (signalRCredentials.ok && signalRCredentials.data) {
      this.connection?.off?.("seatrade"); // we unsub, because trade.setup may be called more than once -- src\@\request\request.ts

      const { url, accessToken } = signalRCredentials.data;
      const accessTokenFactory = () => accessToken;

      this.connection = new HubConnectionBuilder().withUrl(url, { accessTokenFactory }).build();

      this.connection.on("seatrade", logSignalRMessage.bind(null, "Update"));

      this.connection.onclose(this.onConnectionClose);

      this.connection.start();
    }

    logSignalRMessage("Successfully connected.");

    this.resolve();
  };

  promise = new Promise((resolve) => {
    this.resolve = resolve;
  });

  onConnectionClose = async () => {
    logSignalRMessage("Closed. Refreshing Auth.");

    const token = await auth.trade.refresh();

    if (!token) {
      logSignalRMessage("Auth refresh failure. Resetting Auth.");

      auth.reset();

      return;
    }

    logSignalRMessage("Auth successfully refreshed. Resetting TradeAPI with new connection.");

    this.setup();
  };

  getToken = (centralToken = auth.central.token, config?: Config) => {
    config = {
      ignoreStatus: { 401: true, 403: true },
      headers: {
        "clarksons.cloud.logintoken": centralToken,
        ...config?.headers,
      },
      ...config,
    };

    return this.request.post<Token>("/token", null, config);
  };

  getSignalRCredentials = () => {
    return this.request.post<SignalRCredentials>("/notifications", null);
  };

  getUnarchivedOrders = async (searchParams?: URLSearchParams | string, skip = 0, take = config.recordCount) => {
    searchParams = searchParams?.toString();
    searchParams = searchParams ? `?${searchParams}` : "";

    return this.request.get<{ totalCount: number; dataset: Order[] }>(`/orders/unarchived/paged/${skip}/${take}${searchParams}`);
  };

  getArchivedOrders = async (searchParams?: URLSearchParams | string, skip = 0, take = config.recordCount) => {
    searchParams = searchParams?.toString();
    searchParams = searchParams ? `?${searchParams}` : "";

    return this.request.get<{ totalCount: number; dataset: Order[] }>(`/orders/archived/paged/${skip}/${take}${searchParams}`);
  };

  getOrder = (id: string) => {
    return this.request.get<Order>(`/orders/${id}`);
  };

  createDealCapture = (data: OrderCreationPayload, query: string) => {
    return this.request.post<OrderCreationResPayload>(`/orders/capture${query || ""}`, data);
  };

  createOrder = (data: OrderCreationPayload, query: string) => {
    return this.request.post<OrderCreationResPayload>(`/orders${query || ""}`, data);
  };

  archiveOrder = (data: OrderArchivingRequestPayload) => {
    return this.request.post<OrderArchivingResponsePayload>(`/orders/archive`, data);
  };

  unarchiveOrder = (data: OrderArchivingRequestPayload) => {
    return this.request.post<OrderArchivingResponsePayload>(`/orders/unarchive`, data);
  };

  bulkArchiveOrders = (data: OrderArchivingRequestPayload[]) => {
    return this.request.post<OrderArchivingResponsePayload[]>(`/orders/bulk-archive`, data);
  };

  bulkUnarchiveOrders = (data: OrderArchivingRequestPayload[]) => {
    return this.request.post<OrderArchivingResponsePayload[]>(`/orders/bulk-unarchive`, data);
  };

  archiveNegotiation = (data: NegotiationArchivingRequestPayload) => {
    return this.request.post<NegotiationArchivingResponsePayload>(`/negotiations/archive`, data);
  };

  unarchiveNegotiation = (data: NegotiationArchivingRequestPayload) => {
    return this.request.post<NegotiationArchivingResponsePayload>(`/negotiations/unarchive`, data);
  };

  bulkArchiveNegotiations = (data: NegotiationArchivingRequestPayload[]) => {
    return this.request.post<NegotiationArchivingResponsePayload[]>(`/negotiations/bulk-archive`, data);
  };

  bulkUnarchiveNegotiations = (data: NegotiationArchivingRequestPayload[]) => {
    return this.request.post<NegotiationArchivingResponsePayload[]>(`/negotiations/bulk-unarchive`, data);
  };

  withdrawOrder = (orderId: string, data: OrderWithdrawRequestPayload) => {
    return this.request.post<OrderWithdrawRequestPayload>(`/orders/${orderId}/withdraw`, data);
  };

  postOrderDistributionSend = (orderId: Order["id"], data) => {
    const url = `/orders/${orderId}/distribution/send`;

    return this.request.post<GenericEntityResponse>(url, data);
  };

  postOrderDistribution = (orderId: Order["id"], data) => {
    const url = `/orders/${orderId}/distribution`;

    return this.request.post<GenericEntityResponse>(url, data);
  };

  getNegotiations = (orderId: Order["id"]) => {
    return this.request.get<Negotiation[]>(`/orders/${orderId}/negotiations`);
  };

  getNegotiation = (orderId: Order["id"], id: Negotiation["id"]) => {
    const url = `/orders/${orderId}/negotiations/${id}`;

    return this.request.get<Negotiation>(url);
  };

  postNegotiationMainTerms = (orderId: Order["id"], id: Negotiation["id"], data: NegotiationMainTermsPayload) => {
    const url = `/orders/${orderId}/negotiations/${id}/mainterms`;

    return this.request.post<GenericEntityResponse>(url, data);
  };

  postNegotiationVessel = (orderId: Order["id"], id: Negotiation["id"], data: Vessel) => {
    const url = `/orders/${orderId}/negotiations/${id}/vessels`;

    return this.request.post<GenericEntityResponse>(url, data);
  };

  postNegotiationVesselAction = (orderId: Order["id"], id: Negotiation["id"], data: PostNegotiationVesselActionPayload) => {
    const url = `/orders/${orderId}/negotiations/${id}/vessels/${data.vesselIMO}`;

    return this.request.put<GenericEntityResponse>(url, data);
  };

  postNegotiationOwningCompany = (orderId: Order["id"], id: Negotiation["id"], data: PutNegotiationOwningCompanyPayload) => {
    const url = `/orders/${orderId}/negotiations/${id}/owningcompany`;

    return this.request.put<GenericEntityResponse>(url, data);
  };

  putNegotiationNote = (orderId: Order["id"], id: Negotiation["id"], data: PutNegotiationNotePayload) => {
    const url = `/orders/${orderId}/negotiations/${id}/note`;

    return this.request.put<GenericEntityResponse>(url, data);
  };

  deleteNegotiation = (orderId: Order["id"], id: Negotiation["id"], data: DeleteNegotiationPayload) => {
    const url = `/orders/${orderId}/negotiations/${id}`;

    return this.request.delete<GenericEntityResponse>(url, data);
  };

  postNegotiationBid = (orderId: Order["id"], id: Negotiation["id"], data: PostNegotiationBidOfferPayload) => {
    const url = `/orders/${orderId}/negotiations/${id}/bid`;

    return this.request.put<GenericEntityResponse>(url, data);
  };

  postNegotiationOffer = (orderId: Order["id"], id: Negotiation["id"], data: PostNegotiationBidOfferPayload) => {
    const url = `/orders/${orderId}/negotiations/${id}/offer`;

    return this.request.put<GenericEntityResponse>(url, data);
  };

  postNegotiationOfferAll = (orderId: Order["id"], id: Negotiation["id"], data: PostNegotiationBidOfferPayload) => {
    const url = `/orders/${orderId}/negotiations/${id}/all`;

    return this.request.put<GenericEntityResponse>(url, data);
  };

  postNegotiationPublish = (orderId: Order["id"], id: Negotiation["id"], data: PostNegotiationPublishPayload) => {
    const url = `/orders/${orderId}/negotiations/${id}/publish`;

    return this.request.put<GenericEntityResponse>(url, data);
  };

  getArcNegotiation = (arcNegotiationHash: string) => {
    const url = `/arcsubs/negotiation/${arcNegotiationHash}`;

    return this.request.get<GetArcNegotiationPayload>(url);
  };

  postArcNegotiationSubsLift = (arcNegotiationHash: string, data: ArcNegotiationPayload) => {
    const url = `/arcsubs/negotiation/${arcNegotiationHash}/lift`;

    return this.request.post<GenericEntityResponse>(url, data);
  };

  postArcNegotiationSubsLiftAndFix = (arcNegotiationHash: string, data: ArcNegotiationPayload) => {
    const url = `/arcsubs/negotiation/${arcNegotiationHash}/liftandfix`;

    return this.request.post<GenericEntityResponse>(url, data);
  };

  postArcNegotiationSubsFail = (arcNegotiationHash: string, data: ArcNegotiationPayload) => {
    const url = `/arcsubs/negotiation/${arcNegotiationHash}/fail`;

    return this.request.post<GenericEntityResponse>(url, data);
  };

  postArcNegotiationRecap(arcNegotiationHash: string) {
    const url = `arcfacade/sendrecapemail/${arcNegotiationHash}`;

    return this.request.post(url, null);
  }

  getNegotiationViaOfferrep = (offerRepToken: Negotiation["offerRepToken"]) => {
    return this.request.get<Negotiation>(`/offerrep/${offerRepToken}`);
  };

  addUserToNegotiationGroupChatViaOfferrep = (offerRepToken: Negotiation["offerRepToken"], config: Config) => {
    return this.request.post<Negotiation>(`/offerrep/${offerRepToken}`, null, config);
  };

  putNegotiationOfferViaOfferrep = (offerRepToken: Negotiation["offerRepToken"], data: PostNegotiationBidOfferPayload) => {
    const url = `/offerrep/${offerRepToken}/all`;

    return this.request.put<GenericEntityResponse>(url, data);
  };

  getUnarchivedOwnerNegotiationsAll = () => {
    return this.request.get<Negotiation[]>(`/owner/negotiations/unarchived?includeCoa=true`);
  };

  getArchivedOwnerNegotiationsAll = () => {
    return this.request.get<Negotiation[]>(`/owner/negotiations/archived?includeCoa=true`);
  };

  getOwnerNegotiations = (orderId: Order["id"]) => {
    return this.request.get<Negotiation[]>(`owner/orders/${orderId}/negotiations`);
  };

  getOwnerNegotiation = (orderId: Order["id"], id: Negotiation["id"]) => {
    return this.request.get<Negotiation>(`owner/orders/${orderId}/negotiations/${id}`);
  };

  searchOrderTemplates = (search: string, query?: string) => {
    const url = "/ordertemplates?";
    const urlParams = new URLSearchParams({ encodedTerm: search });

    if (query) {
      return this.request.get<OrderTemplate[]>(`${url}${urlParams}${query}`);
    }
    return this.request.get<OrderTemplate[]>(`${url}${urlParams}`);
  };

  getOrderTemplate = (id: string, companyId: string) => {
    const isMaritech = auth.trade.user?.isMaritechUser;
    companyId = isMaritech ? companyId : "";

    return this.request.get<OrderTemplate>(`/ordertemplates/${id}/${companyId}`);
  };

  putOrderTemplate = (data: OrderTemplate) => {
    return this.request.put<OrderTemplate>(`/ordertemplates`, data);
  };

  deleteOrderTemplate = (id: string, companyId: string) => {
    const isMaritech = auth.trade.user?.isMaritechUser;
    companyId = isMaritech ? companyId : "";
    const url = `/ordertemplates/${id}/${companyId}`;

    return this.request.delete<undefined>(url);
  };

  getOrderTemplateSchema = () => {
    return this.request.get<JSONSchema7>("/ordertemplates/schema");
  };

  searchTermsets = (search: string, typeId?: number | string) => {
    const url = "/termsets?";
    const urlParams = new URLSearchParams({ encodedTerm: search });

    if (typeId) {
      urlParams.append("termsetType", typeId.toString());
    }

    return this.request.get<Termset[]>(`${url}${urlParams}`);
  };

  putTermset = (data: Termset) => {
    return this.request.put<Termset>(`/termsets`, data);
  };

  getTermset = (id: string) => {
    // is not /admin because TradeUI needs the endpoint.
    // const isMaritech = auth.trade.user?.isMaritechUser;
    // companyId = isMaritech ? companyId : "";
    const url = `/termsets/${id}`;

    return this.request.get<Termset>(url);
  };

  deleteTermset = (id: string, companyId: string) => {
    const isMaritech = auth.trade.user?.isMaritechUser;
    companyId = isMaritech ? companyId : "";
    const url = `/termsets/${id}/${companyId}`;

    return this.request.delete<undefined>(url);
  };

  termsetImport = async (data) => {
    return this.request.post<undefined>("/termsetimport", data);
  };

  getTermsetSchema = () => {
    return this.request.get<JSONSchema7>("/termsets/schema");
  };

  getProformaLayouts = () => {
    return this.request.get<ProformaLayout[]>(`/proforma/layouts`);
  };

  getProformaLayout = (id: number) => {
    return this.request.get<ProformaLayout>(`/proforma/layouts/${id}`);
  };

  searchAccounts = (search: string, legalEntitiesOnly: boolean) => {
    const url = `/accounts?q=${search}&legalEntitiesOnly=${legalEntitiesOnly}`;

    return this.request.get<AccountSearchItem[]>(url);
  };

  searchCLDDUs = (subset: CLDDUSubset, search: string, query?: string) => {
    let url = `/clddus/${subset}/${search}`;

    if (query) url += `/${query}`;

    return this.request.get<CLDDU[]>(url);
  };

  searchLocations = (search: string) => {
    return this.request.get<LocationSearchItem[]>(`/locations?term=${search}`);
  };

  getCompanies = () => {
    return this.request.get<Company[]>(`/companies`);
  };

  searchVessels = (search: string) => {
    return this.request.get<Vessel[]>(`/vessels?term=${search}`);
  };

  getTimezones = () => {
    return this.request.get<Timezone[]>(`/timezones`);
  };

  disclaimerConfirm = (data: DisclaimerPayload) => {
    return this.request.post<null>(`/user/confirm`, data);
  };

  disclaimerConfirmAnoymousUser = (offerRepToken: string, data: OfferRepDisclaimerPayload) => {
    return this.request.post<null>(`/offerrep/${offerRepToken}/confirm`, data);
  };
}

function logSignalRMessage(message, ...rest) {
  log.system(["Sea/trade SignalR Connection:", message], ...rest);
}

export const tradeAPI = new TradeAPI();

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

export interface TradeAPI {
  request: Request;
  connection?: HubConnection;
  resolve: (value?) => void;

  /* -------------------------------------------------------------------------- */
  /*                                  TYPES                                     */
  /* -------------------------------------------------------------------------- */
  Event: Event;
  User: User;
  CLDDU: CLDDU;
  DistributionUser: DistributionUser;
  KnownUser: KnownUser;
  Order: Order;
  Negotiation: Negotiation;
  Details: Details;
  BidOffer: BidOffer;
  DetailSettings: DetailSettings;
  DetailSetting: DetailSetting;
  NegotiationActions: NegotiationActions;
  NegotiationDetailSetting: NegotiationDetailSetting;
  NegotiationNotes: NegotiationNotes;
  NegotiationNote: NegotiationNote;
  Company: Company;
  Account: Account;
  COACargoSize: COACargoSize;
  Nominations: Nominations;
  Liftings: Liftings;
  CargoSize: CargoSize;
  UnitValue: UnitValue;
  FreightRate: FreightRate;
  HireRate: HireRate;
  CleaningPrice: CleaningPrice;
  SupplyPrice: SupplyPrice;
  TradingExclusions: TradingExclusions;
  Bunker: Bunker;
  BunkerDelivery: Bunker;
  BunkerRedelivery: Bunker;
  CargoExclusionsText: CargoExclusionsText;
  Demurrage: Demurrage;
  BallastBonus: BallastBonus;
  CargoType: CargoType;
  Period: Period;
  Duration: Duration;
  Commission: Commission;
  Subs: Subs;
  VesselSize: VesselSize;
  Location: Location;
  Vessel: Vessel;
  OrderTemplate: OrderTemplate;
  OrderTemplateCOA: OrderTemplateCOA;
  OrderTemplateOrder: OrderTemplateOrder;
  OrderTemplateVOY: OrderTemplateVOY;
  OrderTemplateTCT: OrderTemplateTCT;
  Termset: Termset;
  TermsetContent: TermsetContent;
  TermsetTerm: TermsetTerm;
  ProformaLayout: ProformaLayout;
  Timezone: Timezone;
  OrderParsedUpdateToken: OrderParsedUpdateToken;
  NegotiationParsedUpdateToken: NegotiationParsedUpdateToken;
  LocationSearchItem: LocationSearchItem;
  AccountSearchItem: AccountSearchItem;
  TermsetImportPayload: TermsetImportPayload;
  Membership: Membership;
  GroupChat: GroupChat;
  NegotiationMainTermsPayload: NegotiationMainTermsPayload;
  NegotiationMainTermsPayloadDetails: NegotiationMainTermsPayloadDetails;
  OrderCreationPayload: OrderCreationPayload;
  OrderCreationResPayload: OrderCreationResPayload;
  SendOrderDistributionPayload: SendOrderDistributionPayload;
  GenericEntityResponse: GenericEntityResponse;
  DistributionPayload: DistributionPayload;
  SignalRCredentials: SignalRCredentials;
  PostNegotiationVesselActionPayload: PostNegotiationVesselActionPayload;
  PostNegotiationBidOfferPayload: PostNegotiationBidOfferPayload;
  PostNegotiationPublishPayload: PostNegotiationPublishPayload;
  UnverifiedAccount: UnverifiedAccount;
  PutNegotiationOwningCompanyPayload: PutNegotiationOwningCompanyPayload;
  PutNegotiationNotePayload: PutNegotiationNotePayload;
  DeleteNegotiationPayload: DeleteNegotiationPayload;
  ArcNegotiationPayload: ArcNegotiationPayload;
  GenericArcResponse: GenericArcResponse;
  CLDDUSubset: CLDDUSubset;
  IndicationType: IndicationType;
  OrderType: OrderType;
  OrderNotes: string;
}

type Token = string;

type CLDDUSubset = "owners" | "charterers" | "brokers" | "users" | "usersandowners";

interface LocationSearchItem {
  document: Location;
  "@search.text": string;
}

interface AccountSearchItem {
  document: Account;
  "@search.text": string;
}

interface Timezone {
  id: string;
  display: string;
}

interface CLDDU {
  companyId: string;
  companyName: string;
  companyRoles: string[];
  companyType: string;
  deskId: string;
  deskName: string;
  divisionId: string;
  divisionName: string;
  email: string;
  groupEmailAddress: string;
  locationId: string;
  locationName: string;
  systemUserId: number;
  userId: string;
  userName: string;
  name: string;

  // these are CLDDU "TeamMember" properties, separate types should created ideally and hell, BE should have a single user type
  desk: {
    id: string;
    name: string;
  };
  location: {
    id: string;
    name: string;
  };
}

interface OrderTemplate {
  id: string;
  name: string;
  orderType: string;
  companyId: string;
  companyName: string;
  isDeleted: boolean;
  template: {
    coa: OrderTemplateCOA;
    order: OrderTemplateOrder;
    voyage: OrderTemplateVOY;
    tct: OrderTemplateTCT;
  };
}

interface OrderTemplateCOA {
  coaCargoSize: COACargoSize;
  nominations: Nominations;
  period: Period;
  liftings: Liftings;
  loadLocation: Location;
  dischargeLocation: Location;
  notes: string;
}

interface OrderTemplateOrder {
  chartererAccount: Account;
  cargoType: CargoType;
  laycan: Period;
  addressCommission: Commission;
  brokerCommission: Commission;
}

interface OrderTemplateVOY {
  cargoSize: CargoSize;
  loadLocation: Location;
  dischargeLocation: Location;
  notes: string;
}

interface OrderTemplateTCT {
  vesselSize: VesselSize;
  deliveryLocation: Location;
  viaLocation: Location;
  redeliveryLocation: Location;
  duration: Duration;
  notes: string;
}

interface Company {
  companyId: string;
  name: string;
}

interface Account {
  id: string;
  companyId: string;
  companyName: string;
  accountId: string;
  arcContactId: string;
  accountName: string;
  gainAccountId: string;
  gainAccountGroupId: string | null;
  isLegalEntity: boolean;
  email: string;
}

interface COACargoSize {
  option: "MIN/MAX" | "MOLOO" | "MOLCHOPT";
  min: number;
  max: number;
  variance: number;
  notes: string;

  display: string;
  shortDisplay: string;
}

interface Nominations {
  noticePerLaycan: number;
  laycanSpread: number;
  finalLaycanNotice: number;
  notes: string;

  display: string;
  shortDisplay: string;
}

interface Liftings {
  min: number;
  max: number;
  dispersal: "Fairly even spread" | "Monthly" | "Adhoc";
  notes: string;

  display: string;
  shortDisplay: string;
}

interface CargoSize {
  option: "MIN/MAX" | "MOLOO" | "MOLCHOPT";
  value: number;
  variance: number;
  notes: string;

  display: string;
  shortDisplay: string;
}

interface UnitValue {
  unit: "PerMT" | "PerDay" | "LumpSum";
  value: number;
  variance: number;
  notes: string;

  display: string;
  shortDisplay: string;
}

interface FreightRate extends UnitValue {
  unit: "PerMT" | "LumpSum";
}

interface HireRate extends UnitValue {
  unit: "PerDay" | "LumpSum";
}
interface CleaningPrice extends UnitValue {
  unit: "PerMT" | "PerDay" | "LumpSum";
}
interface SupplyPrice extends UnitValue {
  unit: "PerMT" | "PerDay" | "LumpSum";
}

interface Demurrage extends UnitValue {
  unit: "PerDay" | "LumpSum";
}

interface BallastBonus extends UnitValue {
  unit: "PerDay" | "LumpSum";
}

interface CargoType {
  arcId: number;
  name: string;
  notes: string;

  display: string;
  shortDisplay: string;
}

interface Period {
  start: string;
  end: string;
  style: number;
  styleDescription: string;
  term: string;

  // /laycandate returns start/end, but BE decided they want from/to
  from: string;
  to: string;

  display: string;
  shortDisplay: string;
}

interface Duration {
  unit: "Days" | "Months";
  min: number;
  max: number;
  notes: string;

  display: string;
  shortDisplay: string;
}

interface Commission {
  value: number;

  display: string;
  shortDisplay: string;
}

interface Bunker {
  price: number;
  quantity: number;
  fuelTypes: string;
  notes: string;

  display: string;
  shortDisplay: string;
}

interface TradingExclusions {
  value: string;

  display: string;
  shortDisplay: string;
}

interface CargoExclusionsText {
  value: string;

  display: string;
  shortDisplay: string;
}

interface Subs {
  value: string;

  display: string;
  shortDisplay: string;
}

interface VesselSize {
  vesselSizeFull: string;
  vesselSizeAbbreviation: string;
  sizeFrom: number;
  sizeTo: number;
  notes: string;

  display: string;
  shortDisplay: string;
}

interface Location {
  country: string;
  countryCode: string;
  countryId: string;
  locationId: string;
  name: string;
  zone: string;
  zoneId: string;
  parents: string[];
  notes: string;
  safeBerthsMin: number;
  safeBerthsMax: number;
  safePortsMin: number;
  safePortsMax: number;
  safeAnchoragesMin: number;
  safeAnchoragesMax: number;

  display: string;
  shortDisplay: string;
}

interface Termset {
  updated: string;
  updatedBy: User;
  created: string;
  createdBy: User;
  published: string;
  publishedBy: User;
  isPublished: boolean;
  isDeleted: boolean;
  id: string;
  typeId: number;
  companyId: string;
  companyName: string;
  name: string;
  content: TermsetContent;
}

interface TermsetContent {
  proformaLayoutId: number;
  previouslyExecutedCpId?: number;
  proformaLayoutName: string;
  proformaOtherName: string;
  templateDetails: {
    displayName: string;
    description: string;
  };
  mainTermTemplates: TermsetTerm[];
  orderDetails: any;
}

interface TermsetTerm {
  title: string;
  cpmProformaKey: string;
  content: string;
  termId: string;
}

interface ProformaLayout {
  layoutId: number; // alias for id, only present in /proforma/layouts
  text: string; // alias for name, only present in /proforma/layouts
  key: number; // alias for id, only present in /proforma/layouts
  id: number;
  name: string;
  proformaKey: string;
  keys: string[];
  ownerCpmProformaKeyOverride: string;
  chartererCpmProformaKeyOverride: string;
}

interface TermsetImportPayload {
  proformaLayoutId: number;
  previouslyExecutedCpId?: number;
  proformaLayoutName: string;
  orderDetails: {
    orderTypeId: number;
    laycanOffsetDays: number;
    laycanFromToDays: number;
    cargoType: number;
    loadPortId: string;
    dischargePortId: string;
    deliveryLocationId: string;
    viaLocationId: string;
    redeliveryLocationId: string;
    cargoSizeFrom: number;
    cargoSizeTo: number;
    cargoSizeText: string;
    arcContactId: number;
    replyTimeMinutes: number;
  };
  templateDetails: { displayName: string };
  mainTermTemplates: TermsetTerm[];
}

interface User {
  Company: string;
  CompanyId: string;
  CompanyRoles: CompanyRole[];
  Desk: string;
  DeskId: string;
  Division: string;
  DivisionId: string;
  Features: null;
  Timezone: string;
  Location: string;
  LocationId: string;
  SystemUserId: number;
  UserEmail: string;
  UserName: string;
  aud: string;
  email: string;
  exp: number;
  iat: number;
  isCtradeAdministrator: boolean;
  isMaritechUser: boolean;
  HasConfirmedDisclaimer: boolean;
  iss: string;
  memberships: Membership[];
  name: string;
  sub: string;
}

type CompanyRole = "owner" | "charterer" | "broker";

interface Membership {
  companyRoles: string[];
  id: string;
  level: string;
  name: string;
}

type OrderType = "Voy" | "Tct" | "Coa";

export enum DealCaptureType {
  Charterer = 0,
  Owner = 1,
}

interface GroupChat {
  name: string;
  id: string;
  brokerCompany: CompanyGroupChat;
  chartererCompany: CompanyGroupChat;
}

interface CompanyGroupChat {
  companyType: string;
  id: string;
  name: string;
}

interface DistributionUser {
  email: string; // always send
  role: string; // always send
  status: string; // appears in the order after POST id/distribution update
  sentOn: string; // appears in the order after POST id/distribution update
  knownUser: KnownUser;
  actor: KnownUser;
  availableRoles: string[];
  negDetails: {
    voyNegId: string;
    voyNegOfferRepToken: string;
    coaNegId: string;
    coaNegOfferRepToken: string;
    tctNegId: string;
    tctNegOfferRepToken: string;
  };
}

interface KnownUser {
  id: string;
  name: string;
  email: string;
  systemUserId: number;
  companyType: string;
  company: {
    name: string;
    id: string;
  };
  location: {
    name: string;
    id: string;
  };
  division: {
    name: string;
    id: string;
  };
  desk: {
    name: string;
    id: string;
  };
}

interface Order {
  id: string;
  dealCaptureBrokerContact: CLDDU;
  dealCaptureChartererContact: CLDDU;
  details: Details;
  charterer: string;
  chartererEmail: string;
  charterersCompanyId: string;
  status: OrderStatus;
  teamMembers: KnownUser[];
  distributionList: DistributionUser[];
  updateToken: string;
  groupChats: GroupChat[];
  types: OrderType[];
  version: number;
  createdOn: string;
  lastUpdated: string;
  owningCompany: string;
  fixedOn: string;
  brokers: string[];
  sharedWith: string[];
  attachments: any[];
  responseRequired: string;
  negAttachments: any[];
  createdBy: KnownUser;
  isArchived: boolean;
  archivedOn?: string;
  withdrawalState?: WithdrawalState;
  summary: {
    orderReference: string;
    chartererAccount: string;
    cargoType: string;
    laycan: string;
    locations: string[];
    createdBy: string;
    negotiationCount: number;
    tctCount: number;
    voyCount: number;
    onSubsExpires: string[];
    firmExpires: object[];
    invitees: string[];
  };
}

export interface WithdrawalState {
  reason: string;
  withdrawnOn: string;
}

interface OrderParsedUpdateToken {
  exp: number;
  iat: number;
  orderId: string;
  sub: string;
  version: number;
  permittedMethods: OrderPermittedMethod[];
}

type OrderPermittedMethod =
  | "ArchiveOrder"
  | "SetCharterer"
  | "AddTeamMember"
  | "CirculateOrder"
  | "DistributeOrder"
  | "UpdateDistributionList";

interface Negotiation {
  id: string;
  orderId: string;
  jumpToStatus: string;
  mainTermsDetails: Details;
  bid: BidOffer;
  offer: BidOffer;
  detailSettings: DetailSettings;
  circulatedBy: string;
  createdOn: string;
  version: number;
  status: NegotiationStatus;
  orderReference: string;
  lastUpdated: string;
  lastUpdatedBy: string;
  vessels: Vessel[];
  attachments: any[];
  type: OrderType | "Lft";
  invitee: string;
  inviteeSystemUserId: number;
  updateToken: string;
  chartererAccount: Account;
  offerRepToken: string;
  actions: NegotiationActions;
  owningCompany: Account;
  sortIndexes: {
    laycan: string;
    invitee: string;
    createdDate: number;
    updatedDate: number;
  };
  filterIndexes: {
    isExactLaycan: boolean;
    active: boolean;
  };
  orderNotes: string;
  groupChat: { id: string; name: string };
  groupChatId: string;
  circulatedDivision: string;
  hasBroker: boolean;
  hasCharterer: boolean;
  brokerGroupChatId: string;
  isOfferRepClaimed: boolean;
  isDealCapture: boolean;
  isOwnerAbsent: boolean;
  brokerEmailAddresses: string[];
  chartererEmailAddresses: string[];
  ownerEmailAddresses: string[];
  seaContractsUrl: string;
  notes: NegotiationNotes;
  orderVersion: number;
  orderAttachments: any[];
  responseRequired: string;
  arcUrl: string;
  offerRepArcUrl: string;
  arcNegotiationHash: string;
  commercialSubsLifted: boolean;
  operationalSubsLifted: boolean;
  publishedNeg?: {
    bid: BidOffer;
    actions: NegotiationActions;
    version: number;
  };
  isArchived: boolean;
  archivedOn?: string;
}

interface Details {
  isDealCapture?: boolean;
  dealCaptureType?: DealCaptureType;
  originallySelectedTypes?: OrderType[];
  notes?: string;
  voyageNotes?: string;
  coaNotes?: string;
  tctNotes?: string;
  vessels?: Vessel[];
  chartererAccount?: Account;
  ownerAccount?: Account; // AKA owningCompany
  laycan?: Period;
  period?: Period;
  liftings?: Liftings;
  nominations?: Nominations;
  cargoType?: CargoType;
  cargoSize?: CargoSize;
  coaCargoSize?: COACargoSize;
  loadLocation?: Location;
  dischargeLocation?: Location;
  vesselSize?: VesselSize;
  deliveryLocation?: Location;
  viaLocation?: Location;
  redeliveryLocation?: Location;
  duration?: Duration;
  addressCommission?: Commission;
  brokerCommission?: Commission;
  freightRate?: FreightRate;
  demurrage?: Demurrage;
  hireRate?: HireRate;
  cleaningPrice?: CleaningPrice;
  supplyPrice?: SupplyPrice;
  tradingExclusions?: Subs;
  bunkerDelivery?: Bunker;
  bunkerRedelivery?: Bunker;
  cargoExclusionsText?: Subs;
  ballastBonus?: BallastBonus;
  operationalSubs?: Subs;
  commercialSubs?: Subs;
}

export interface BidOffer {
  laycan?: BidOfferDetail<Period>;
  period?: BidOfferDetail<Period>;
  liftings?: BidOfferDetail<Liftings>;
  nominations?: BidOfferDetail<Nominations>;
  cargoType?: BidOfferDetail<CargoType>;
  cargoSize?: BidOfferDetail<CargoSize>;
  coaCargoSize?: BidOfferDetail<COACargoSize>;
  loadLocation?: BidOfferDetail<Location>;
  dischargeLocation?: BidOfferDetail<Location>;
  vesselSize?: BidOfferDetail<VesselSize>;
  deliveryLocation?: BidOfferDetail<Location>;
  viaLocation?: BidOfferDetail<Location>;
  redeliveryLocation?: BidOfferDetail<Location>;
  duration?: BidOfferDetail<Duration>;
  addressCommission?: BidOfferDetail<Commission>;
  brokerCommission?: BidOfferDetail<Commission>;
  freightRate?: BidOfferDetail<FreightRate>;
  hireRate?: BidOfferDetail<HireRate>;
  cleaningPrice?: BidOfferDetail<CleaningPrice>;
  supplyPrice?: BidOfferDetail<SupplyPrice>;
  tradingExclusions?: BidOfferDetail<Subs>;
  bunkerDelivery?: BidOfferDetail<Bunker>;
  bunkerRedelivery?: BidOfferDetail<Bunker>;
  cargoExclusionsText?: BidOfferDetail<Subs>;
  demurrage?: BidOfferDetail<Demurrage>;
  ballastBonus?: BidOfferDetail<BallastBonus>;
  operationalSubs?: BidOfferDetail<Subs>;
  commercialSubs?: BidOfferDetail<Subs>;
}

export interface BidOfferDetail<T> {
  value: T;
}

export interface DetailSettings {
  chartererAccount: DetailSetting;
  laycan: DetailSetting;
  period: DetailSetting;
  liftings: DetailSetting;
  nominations: DetailSetting;
  cargoType: DetailSetting;
  cargoSize: DetailSetting;
  coaCargoSize: DetailSetting;
  loadLocation: DetailSetting;
  dischargeLocation: DetailSetting;
  vesselSize: DetailSetting;
  deliveryLocation: DetailSetting;
  viaLocation: DetailSetting;
  redeliveryLocation: DetailSetting;
  duration: DetailSetting;
  addressCommission: DetailSetting;
  brokerCommission: DetailSetting;
  freightRate: DetailSetting;
  demurrage: DetailSetting;
  hireRate: DetailSetting;
  cleaningPrice: DetailSetting;
  supplyPrice: DetailSetting;
  tradingExclusions: DetailSetting;
  bunkerDelivery: DetailSetting;
  bunkerRedelivery: DetailSetting;
  cargoExclusionsText: DetailSetting;
  ballastBonus: DetailSetting;
  operationalSubs: DetailSetting;
  commercialSubs: DetailSetting;
}

interface DetailSetting {
  negotiable: boolean;
  side: string;
}

interface NegotiationActions {
  brokerCharterer: IndicationType;
  lastUpdatedBy: "owner" | "brokerCharterer";
  owner: string;
  ownerFirmExpiresOn: string;
  brokerChartererFirmExpiresOn: string;
}

interface NegotiationParsedUpdateToken {
  exp: number;
  iat: number;
  negotiationId: string | null;
  orderId: string;
  sub: string;
  version: number;
  permittedMethods: NegotiationPermittedMethod[];
}

type NegotiationPermittedMethod =
  | "BidIndicate"
  | "BidFirm"
  | "BidFirmAccept"
  | "OfferIndicate"
  | "OfferFirm"
  | "OfferFirmRequest"
  | "PtMainTerms"
  | "PtSubs"
  | "Fix"
  | "NameVessel"
  | "UpdateVessel"
  | "UpdateNotes"
  | "WithdrawNeg"
  | "SetOwningCompany"
  | "AddVessel"
  | "AcceptVessel"
  | "RejectVessel"
  | "GroupChatOfferRepAdded"
  | "AddAttachment"
  | "DeleteAttachment"
  | "PublishToCharterer"
  | "OwnerRequestChangesToDeclaredCargo";

type NegotiationStatus =
  | "Withdrawn"
  | "Nomination"
  | "Inactive"
  | "Active"
  | "Failed"
  | "Firm"
  | "TermsLoading"
  | "MainTerms"
  | "OnSubs"
  | "SubsLifted"
  | "SubsFailed"
  | "Fixed"
  | "Confirmed"
  | "Archived";

interface NegotiationDetailSetting {
  negotiable: boolean;
  sid: string;
}

interface NegotiationNotes {
  brokerCharterer: NegotiationNote;
  owner: NegotiationNote;
}

interface NegotiationNote {
  timestamp: string;
  value: string;
}

interface NegotiationMainTermsPayload {
  details: NegotiationMainTermsPayloadDetails;
  termsetId: string;
  type: OrderType;
  orderId: string;
  negotiationId: string;
  chartererEmail: string;
  updateToken: string;
  offerRepToken: string;
  jumpToStatus: string;
  responseDateTime: string;
  isOfferRepClaimed: boolean;
  brokerEmailAddresses: string[];
  chartererEmailAddresses: string[];
  ownerEmailAddresses: string[];
}

interface NegotiationMainTermsPayloadDetails {
  notes: string | string;
  invitee: string | string;
  controllers: any[];
  vessels: Vessel[];
  ownerAccount: Account;
}

interface OrderCreationPayload extends Details {
  voyageNotes: string;
  tctNotes: string;
  types: OrderType[];
}

interface OrderArchivingRequestPayload {
  orderId: string;
  updateToken: string;
}

export interface OrderArchivingResponsePayload {
  id: string;
  message: string;
  version: number;
}

interface NegotiationArchivingRequestPayload extends OrderArchivingRequestPayload {
  negotiationId: string;
}

interface OrderWithdrawRequestPayload {
  reason: string;
  updateToken: string;
}

export interface NegotiationArchivingResponsePayload extends OrderArchivingResponsePayload {}

interface OrderCreationResPayload {
  id: string;
  version: number;
}

interface SendOrderDistributionPayload {
  groupChats: string[];

  // probably comes from Order.types, the only differnece is full caps
  types: OrderType[];
  updateToken: string;

  // distributionList come from the order AFTER order/id/distribution has been successful
  distributionList: DistributionUser[];

  // teamMembers come from the order AFTER order/id/distribution has been successful
  teamMembers: DistributionUser[];

  // provided by User, matches Order.details
  detailsVoy: any;
  detailsTct: any;
  detailsCoa: any;
}

interface GenericEntityResponse {
  id: string;
  version: number;
}

interface DistributionPayload {
  updateToken: string;
  distributionList: DistributionUser[];
}

type OrderStatus =
  | "Inactive"
  | "Active"
  | "Firm"
  | "TermsLoading"
  | "MainTerms"
  | "OnSubs"
  | "SubsLifted"
  | "SubsFailed"
  | "Fixed"
  | "Failed"
  | "Archived"
  | "Withdrawn";

interface SignalRCredentials {
  accessToken: string;
  url: string;
}

interface Event {
  eventType: string;
  id: string;
  memberships: string[];
  messageVersion: number;
  version: number;
}

interface Vessel {
  // stuff you get TradeAPI.searchVessels
  vesselIMO: number;
  dwt: number;
  arcVesselId: number;
  buildYear: number;
  registrationDataName: string;

  // stuff you have to put to TradeAPI.Negotiation
  vesselImo: number;
  status: "named" | "accepted" | "rejected" | "deleted";
  eta: string;
  itinerary: string;
  speedAndConsumption: string;
  ownerChain: string;
  additionalNotes: string;
  vesselDescription: string;
  updateToken: string;

  display: string;
  shortDisplay: string;
}

interface PostNegotiationVesselActionPayload {
  vesselIMO: number;
  action: "accept" | "reject" | string;
  updateToken: string;
}

interface PostNegotiationBidOfferPayload {
  action: IndicationType;
  details: Details;
  updateToken: string;
  expiresOn: string;
}

interface PostNegotiationPublishPayload {
  action: string;
  updateToken: string;
}

interface UnverifiedAccount {
  accountName: string;
  ownerSearchResults: any[];
}

interface PutNegotiationOwningCompanyPayload {
  value: Account;
  updateToken: string;
}

interface PutNegotiationNotePayload {
  as: string;
  value: NegotiationNote["value"];
  updateToken: string;
}

interface DeleteNegotiationPayload {
  updateToken: string;
}

interface ArcNegotiationPayload {
  updateToken: string;
}

interface GetArcNegotiationPayload {
  expiresOn: string | null;
  negotiationId: string;
  negotiationType: string;
  negotiationVersion: number;
  orderId: string;
  status: string;
  updateToken: string;
  version: number;
}

interface GenericArcResponse {
  id: string;
  version: number;
}

interface DisclaimerPayload {
  disclaimer: string;
}

export interface OfferRepDisclaimerPayload extends DisclaimerPayload {
  updateToken: string;
}

type IndicationType = "indicated" | "firmRequested" | "firmed" | "firmAccepted" | null | undefined;
