import { VesselNominiationStatus, IVesselView } from "../Models/IDetails";

export function orderVessels(vessels: IVesselView[]): IVesselView[] {
  const order: { [key in VesselNominiationStatus]: number } = {
    accepted: -1,
    named: 0,
    rejected: 1,
  };
  // sort will mutate the array in question
  vessels.sort((a, b) => {
    if (a.status === b.status) {
      return 0;
    }
    return order[a.status || "named"] > order[b.status || "named"] ? 1 : -1;
  });
  return vessels;
}
