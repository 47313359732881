import { BunkerDelivery, BunkerRedelivery } from "___REFACTOR___/models";
import { object, string, number } from "yup";

// https://github.com/jquense/yup
const schema = object({
  price: number().required("Required"),
  quantity: number().integer().required("Required"),
  fuelTypes: string().required("Required"),
  notes: string(),
}).transform(toJSON);

function toJSON(bunker: BunkerDelivery | BunkerRedelivery) {
  return bunker?.toJSON();
}

export { schema };
