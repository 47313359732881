import { Negotiation, dialog } from "@/models";

// prettier-ignore
export async function getNegotiation(model: Negotiation | undefined) {
  if (!model) {
    console.error("getNegotiation:", { model });

    throw new Error(`getNegotiation: Negotiation model is undefined`);
  }

  model._.status.loading = true;
  model._.status.message = "Loading";

  const res = await model.getDataViaOfferrep();

  if (!res?.ok) {
    model._.status.type = "error";
    model._.status.title = "Negotiation Fetch Failure";
    model._.status.dump = { res };

    dialog.show({
      status: model._.status,
      dataTest: `offerep-negotiation-fetch-fail`,
    });

    model._.status.loading = false;
    model._.status.message = null;

    return;
  }

  model._.status.loading = false;
  model._.status.message = null;

  return res;
}
