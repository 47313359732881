import { TradeAPI } from "___REFACTOR___/apis";
import { UnverifiedAccountEditor } from "___REFACTOR___/components";
import { DataModel, DataModelProps } from "../DataModel";

class UnverifiedAccount extends DataModel {
  constructor(data?: PartialData) {
    super(data);
  }

  toString() {
    return this.accountName;
  }
}

class UnverifiedAccountProps extends DataModelProps {}

UnverifiedAccount.prototype.Props = UnverifiedAccountProps;
UnverifiedAccountProps.prototype.Editor = UnverifiedAccountEditor;

export { UnverifiedAccount, UnverifiedAccountProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface UnverifiedAccount extends Data {
  Props: typeof UnverifiedAccountProps;
  _: UnverifiedAccountProps;
}

type PartialData = Partial<Data>;
type Data = TradeAPI.UnverifiedAccount;
