import React from "react";
import { Note as NegotiationNote } from "___REFACTOR___/models/Negotiation/Note";
import { Group } from "../../Group";
import { Icon, TextareaField, Field } from "___REFACTOR___/components";
import "./NegotiationNote.scss";

function NegotiationNoteEditor(props: NegotiationNoteEditor.Props) {
  const { name, dataTest, ...rest } = props;

  return (
    <Group {...props} editorType="NegotiationNote" hidden={props.hidden}>
      <TextareaField {...rest} value={props.value?.value} onChange={onChange} dataTest={`${dataTest}.value`} />
      <div className="NegotiationNote-info">
        <Icon icon="info-outline">
          {name === "negotiationOwnerNote"
            ? "You are updating this note on the Owner's behalf. You can change what is written here at any time."
            : "The Owner will receive this note as soon as you click 'SEND'. You can change what is written here at any time."}
        </Icon>
      </div>
    </Group>
  );

  function onChange(value: TextareaField.Input.Value) {
    if (typeof value !== "string") return;

    const next = new NegotiationNote({ value });

    props.onChange?.(next);
  }
}

export { NegotiationNoteEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace NegotiationNoteEditor {
  export interface Props extends Field.Props {
    value: Value;
    onChange: (value: Value) => void;
  }

  export type Value = NegotiationNote | undefined;
}
