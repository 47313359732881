import React, { useContext, useState, useEffect, createContext } from "react";
import { IConfiguration, Configuration } from "sharedFolder/Configuration";

const ConfigurationContext = createContext<IConfiguration>({
  seaChatsignUpUrl: "",
  signUpUrl: "",
  cloudApplicationCode: "",
  headerCdnUrl: "",
  authUrl: "",
  cchatUrl: "",
  ctradeUrl: "",
  cchatWidgetUrl: "",
  cchatBaseUrl: "",
  environment: "development",
  useMockTermSetClient: "true",
  version: "",
  appInsightsKey: "",
  // intercomSettingsAppId: "",
  vesselActivityUrl: "",
  vesselViewUrl: "",
});

ConfigurationContext.displayName = "ConfigurationContext";

function ConfigurationProvider(props: { children: React.ReactNode; value?: IConfiguration }) {
  const [config, setConfig] = useState<IConfiguration | null>(null);
  useEffect(() => {
    async function fetchConfig() {
      try {
        const config = await Configuration();

        config.maxDistributionCount = config.maxDistributionCount || 700;

        setConfig(config);
      } catch (e) {
        setConfig(() => {
          throw new Error("fetchConfig" + e.message);
        });
      }
    }

    // if config value is passed in use it otherwise fetch to get it...this is mainly to testing
    if (props.value) {
      setConfig(props.value);
    } else {
      fetchConfig();
    }
  }, [props.value]);

  if (config !== null) {
    return <ConfigurationContext.Provider value={config}>{props.children}</ConfigurationContext.Provider>;
  }

  return null;
}

function useConfig() {
  const context = useContext(ConfigurationContext);

  if (context === undefined) {
    throw new Error("useConfig must be used within a ConfigProvider");
  }

  return context;
}

export { ConfigurationProvider, ConfigurationContext, useConfig };
