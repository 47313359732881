import React from "react";
import { BunkerBaseEditor, Field } from "___REFACTOR___/components";
import { BunkerDelivery } from "___REFACTOR___/models";

function BunkerDeliveryEditor(props: BunkerDeliveryEditor.Props) {
  return (
    <BunkerBaseEditor
      {...props}
      editorType="BunkerDelivery"
      bunkerType="Delivery"
      dataTest={props.dataTest || "bunker-delivery"}
    />
  );
}

export { BunkerDeliveryEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace BunkerDeliveryEditor {
  export interface Props extends Field.Props {
    value: Value;
    onChange: (value: Value) => void;
  }

  export type Value = BunkerDelivery | undefined;
}
