import { ValidationContext, ValidationHandler } from "./ValidationContext";
import * as React from "react";
import { Subscription } from "rxjs";

export interface IValidationContainerProps {
  isValid?: boolean;
}

/**
 * Higher-order Component for injecting the current ValidationContext state as an isValid prop.
 * @param Component The component to inject the current valid state
 */
export function withValidation<P>(
  Component: React.ComponentClass<IValidationContainerProps & P> | React.SFC<IValidationContainerProps & P>
) {
  return class ValidationContainer extends React.Component<P & IValidationContainerProps, { isValid?: boolean }> {
    private readonly validationHandler = new ValidationHandler();
    private subscriptions: Subscription[] = [];

    constructor(props: P) {
      super(props);
      this.state = {
        isValid: true,
      };
    }

    public componentDidMount() {
      this.subscriptions.push(
        this.validationHandler.onChange.subscribe((valid: boolean) => {
          this.setState({ isValid: valid });
        })
      );
    }

    public componentWillUnmount() {
      this.subscriptions.forEach((s) => s.unsubscribe());
    }

    public render() {
      return (
        <ValidationContext.Provider value={this.validationHandler}>
          <Component isValid={this.state.isValid} {...this.props} />
        </ValidationContext.Provider>
      );
    }
  };
}
