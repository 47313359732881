import classNames from "classnames";
import React, { useEffect, PureComponent, createRef } from "react";
import { CustomDropdown } from "sharedFolder/components/common/CustomDropdown/CustomDropdown";
import { DetailType, detailTypeDisplay } from "negotiations/models/common/DetailType";
import { unitValueFormatter, isUnitValueValid, unitValueText } from "sharedFolder/components/common/fields";
import { IKeyValuePair } from "sharedFolder/Models/IKeyValuePair";
import { IUnitValueView } from "sharedFolder/Models/IDetails";
import { Notes } from "sharedFolder/components/common/Notes/Notes";
import formStyles from "__legacy/sharedFolder/styles/form-styles.module.scss";
import styles from "../UnitValueEditor/UnitValueEditor.module.scss";
import { withValidationContext } from "sharedFolder/contexts/withValidationContext";

interface IUnitValueProps {
  dataTest?: string;
  defaultOption?: string;
  focus?: boolean;
  label?: string;
  id: string;
  required?: boolean;
  disabled?: boolean;
  displayNotes?: boolean;
  notesId?: string;
  notesPlaceholderText?: string;
  type: DetailType;
  value: IUnitValueView;
  options: IKeyValuePair[];
  onChange: (value: IUnitValueView) => void;
  isValid: boolean;
}

interface IUnitValueState {
  displayValue: string | null;
  rawValue: string;
  unit: string;
  notes?: string;
}

/*
  This component works the following way:
    - we hold two local states of rawValue and displayValue
    - rawValue gets hydrated by the initial value coming from the parent component and onChange is responsible for changing it
    - displayValue will hold the formatted value being updated on componentDidMount, componentDidUpdate when the value prop has changed, and onBlur
    - validation will only run on componentDidUpdate in response to the parent data model change because "isRequired" is something controlled from the outside
*/

export class NewUnitValueEditor extends PureComponent<IUnitValueProps, IUnitValueState> {
  private field = createRef<HTMLInputElement>();

  constructor(props: IUnitValueProps) {
    super(props);
    this.state = {
      unit: props.value.unit || props.defaultOption || "",
      rawValue: !props.value.value ? "" : props.value.value,
      displayValue: unitValueFormatter(props.value.value),
    };
  }

  sanitiseValue = (value: string | null) => {
    // basic formatting of two decimal places should occur
    // do not sanitise if the value is invalid
    return value ? (isUnitValueValid(value) ? Number(value).toFixed(3) : value) : "";
  };

  public componentDidUpdate(prevProps: IUnitValueProps, prevState: IUnitValueState) {
    // TODO: maybe there is a better way to do a select(), so I can simplify this method
    if (this.field.current && prevState.displayValue !== this.state.displayValue && this.state.displayValue === null) {
      this.field.current.select();
    }
    if (this.field.current && prevProps.focus !== this.props.focus) {
      this.field.current.focus();
    }
    if (
      (this.props.value && prevProps.value.value !== this.props.value.value) ||
      (this.props.value && prevProps.value.unit !== this.props.value.unit) ||
      prevProps.required !== this.props.required
    ) {
      this.setState({
        rawValue: !this.props.value.value ? "" : this.props.value.value,
        displayValue: unitValueFormatter(this.props.value.value),
        unit: this.props.value.unit,
      });
    }
  }

  private onValueChange = (evt: React.FormEvent<HTMLInputElement>) => {
    this.setState({ rawValue: evt.currentTarget.value });
  };

  private onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    // 1. sanitisation
    // 2. formatting
    // 3. set the default unit value if none already set
    // update the displayValue and pass up the sanitized rawValue

    const value = this.state.rawValue;
    const sanitisedRawValue = this.sanitiseValue(value.replaceAll ? value.replaceAll(",", "") : value);
    const unit = this.state.unit || this.props.defaultOption || "";
    const unitKeyValue = this.props.options.find((o) => o.key === unit);
    this.setState({
      rawValue: !sanitisedRawValue ? "" : sanitisedRawValue,
      displayValue: unitValueFormatter(sanitisedRawValue),
      unit,
    });
    const newValue = {
      ...this.props.value,
      value: sanitisedRawValue,
      unit: unit,
      unitDisplay: unitKeyValue ? unitKeyValue.value : "",
    };
    this.props.onChange({
      ...newValue,
      display: unitValueText(newValue),
    });
  };

  private onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    // on focus, remove the display component so user can edit the raw value
    this.setState({
      displayValue: null,
    });
  };

  private handleUnitChanged = (unit: string | number) => {
    const unitOption = this.props.options.find((_) => _.key === unit);
    const unitValue = String(unit);

    this.setState({
      unit: unitValue,
    });

    const newValue = {
      ...this.props.value,
      unit: unitValue,
      unitDisplay: unitOption && unitOption.value,
    };

    this.props.onChange({
      ...newValue,
      display: unitValueText(newValue),
    });
  };

  private notesChangedHandler(notes: string) {
    const newValue = {
      ...this.props.value,
      notes: notes,
    };

    const unitOption = this.props.options.find((_) => _.key === this.props.value.unit);

    this.props.onChange({
      ...newValue,
      display: unitValueText(newValue),
      unitDisplay: unitOption ? unitOption && unitOption.value : "",
    });
  }

  public render() {
    return (
      <div className={styles.inputRow}>
        <div className={styles.inputGroup}>
          {this.props.label && (
            <label htmlFor={`${this.props.id}_value`} className={this.props.required ? styles.mandatory : undefined}>
              {detailTypeDisplay(this.props.type)}
              <span className={styles.dollar}> ($)</span>
            </label>
          )}
          <div className={styles.editorsContainer}>
            <input
              ref={this.field}
              id={`${this.props.id}_value`}
              data-test={`${this.props.id}`}
              className={classNames(formStyles.input, styles.input, formStyles.small, !this.props.isValid ? "error-state" : null)}
              onChange={this.onValueChange}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              value={this.state.displayValue || this.state.rawValue}
              type="text"
              autoComplete="off"
              disabled={this.props.disabled}
              autoFocus={this.props.focus}
            />
            <div className={styles.unit} data-test={`${this.props.id}_dropdown`}>
              <CustomDropdown
                id={`${this.props.id}_dropdown`}
                ariaLabel={this.props.type}
                isValid={true}
                options={this.props.options.map((_) => ({
                  key: _.key,
                  text: _.value,
                }))}
                defaultSelectedKey={this.state.unit}
                onChange={this.handleUnitChanged}
                disabled={this.props.disabled}
                width="small"
              />
            </div>
          </div>
          {this.props.displayNotes && (
            <Notes
              dataTest={this.props.dataTest || "unit-value-editor-notes"}
              id={this.props.notesId}
              label="Notes"
              placeholder={this.props.notesPlaceholderText}
              className={styles.notes}
              value={this.props.value.notes}
              onChange={(value) => this.notesChangedHandler(value)}
              disabled={this.props.disabled}
            />
          )}
        </div>
      </div>
    );
  }
}

export const NewUnitValueEditorWithValidContext = withValidationContext<Omit<IUnitValueProps, "isValid">>((props) => {
  const { fieldValidityChanged, id, value, required } = props;
  const isValid = isUnitValueValid(props.value.value, props.required);

  useEffect(() => {
    fieldValidityChanged(id, isUnitValueValid(value.value, required));
  }, [id, required, value, fieldValidityChanged]);

  return <NewUnitValueEditor {...props} isValid={isValid} />;
});
