export const LABEL_MAP = {
  addressCommission: "Address Commission",
  ballastBonus: "Ballast Bonus ($)",
  brokerCommission: "Broker Commission",
  brokerContact: "Broker Contact",
  cargoSize: "Cargo Size",
  cargoType: "Cargo Type",
  charterer: "Charterer",
  chartererAccount: "Charterer",
  chartererContact: "Charterer Contact",
  ownerContact: "Owner Contact",
  coaCargoSize: "Cargo Size",
  coaNotes: "COA Notes",
  coaTerms: "COA Terms",
  commercialSubs: "Commercial Subs",
  deliveryLocation: "Delivery Location",
  demurrage: "Demurrage ($)",
  dischargeLocation: "Discharge Location",
  duration: "Duration",
  freightRate: "Freight Rate ($)",
  hireRate: "Hire Rate ($)",
  cleaningPrice: "ILOHC ($)",
  supplyPrice: "CVE ($)",
  tradingExclusions: "Trading Exclusions",
  bunkerDelivery: "Bunker Delivery",
  bunkerRedelivery: "Bunker Re-Delivery",
  cargoExclusionsText: "Cargo Exclusions",
  invitee: "Invitee",
  laycan: "Laycan",
  liftings: "Liftings",
  loadLocation: "Load Location",
  nominations: "Nominations",
  notes: "Order Notes",
  operationalSubs: "Operational Subs",
  ownerAccount: "Owning Company",
  period: "Period",
  redeliveryLocation: "Redelivery Location",
  tctNotes: "TC Notes",
  vessels: "Vessel(s)",
  vesselSize: "Vessel Size",
  viaLocation: "Via Location",
  voyageNotes: "Voyage Notes",
};

export const EMDASH = "—";
