import React from "react";
import styles from "./DetailsViewOfferRep.module.scss";
import {
  ICargoTypeView,
  ICargoSizeView,
  ICOACargoSizeView,
  ICommissionView,
  IUnitValueView,
  ILaycanView,
  IVesselSizeView,
  IDurationView,
  ILiftingsView,
  INominationsView,
  IPeriodView,
} from "sharedFolder/Models/IDetails";
import { cargoSizeText } from "sharedFolder/display/cargoSize";
import { getWarrantySafetyInformationAbbreviation } from "negotiations/services/client/search/locationSafetyWarranty";
import { formatToDecimal } from "negotiations/services/utils/converters";
import { ILocationView } from "sharedFolder/Models/ILocation";
import { ISubsTextView } from "sharedFolder/Models/ISubsText";
import { IBunkerView } from "sharedFolder/Models/IBunker";

const EmptyDetailDisplay: React.SFC<{}> = () => <span>--</span>;

/**
 * Returns JSX Element for a given cargoType
 * @param item cargoType given
 * @param type This param will be used for the aria-label. If not given, cargoType will be used
 */
export const CargoTypeHasValue = (cargoType: ICargoTypeView): boolean => Boolean(cargoType.name || cargoType.notes);
export const CargoTypeDisplay: React.SFC<{
  item: ICargoTypeView;
  type?: string;
}> = (props) => {
  return (
    <>
      {CargoTypeHasValue(props.item) ? (
        <span className={styles.value} aria-label={props.type || `cargoType`}>
          {props.item.name}
          {props.item.notes && (
            <>
              <br />
              <span className={styles.note}>{props.item.notes}</span>
            </>
          )}
        </span>
      ) : (
        <EmptyDetailDisplay />
      )}
    </>
  );
};

/**
 * Returns JSX Element for a given cargoType
 * @param item cargoSize given
 * @param type This param will be used for the aria-label. If not given, cargoSize will be used
 */
export const CargoSizeHasValue = (cargoSize: ICargoSizeView): boolean => Boolean(cargoSizeText(cargoSize));
export const CargoSizeDisplay: React.SFC<{
  item: ICargoSizeView;
  type?: string;
}> = (props) => {
  const summary = cargoSizeText(props.item);
  return (
    <span className={styles.value} aria-label={props.type || `cargoSize`}>
      {CargoSizeHasValue(props.item) ? summary : <EmptyDetailDisplay />}
    </span>
  );
};

export const COACargoSizeHasValue = (coaCargoSize: ICOACargoSizeView): boolean => Boolean(coaCargoSize.display);
export const COACargoSizeDisplay: React.SFC<{
  item: ICOACargoSizeView;
  type?: string;
}> = (props) => {
  return (
    <>
      {COACargoSizeHasValue(props.item) ? (
        <span className={styles.value} aria-label={props.type || `COACargoSize`}>
          {props.item.display}
        </span>
      ) : (
        <EmptyDetailDisplay />
      )}
    </>
  );
};

/**
 * Returns JSX Element for a given account
 * @param item commission given
 * @param type type of commission, e.g, 'addressCommission', 'brokerCommission'. This param will be used for the aria-label and if not given commission will be used
 */
export const CommissionHasValue = (commission: ICommissionView): boolean => Boolean(commission.value);
export const CommissionDisplay: React.SFC<{
  item: ICommissionView;
  type?: string;
}> = (props) => {
  return (
    <>
      {CommissionHasValue(props.item) ? (
        <span className={styles.value} aria-label={props.type || `commission`}>
          {formatToDecimal(props.item.value, 3)}
          <span className={styles.percentage}>&nbsp;%</span>
        </span>
      ) : (
        <EmptyDetailDisplay />
      )}
    </>
  );
};

/**
 * Returns JSX Element for a given duration
 * @param item duration given
 * @param type This param will be used for the aria-label and if not given, duration will be used
 */
export const DurationDisplay: React.SFC<{
  item: IDurationView;
  type?: string;
}> = (props) => {
  const { min, max, unit, notes } = props.item;
  const doesDetailExist = (min || max) && unit;

  return (
    <>
      {doesDetailExist ? (
        <div>
          <span>
            {min}-{max} {unit}
          </span>
          <br />
          <span className={styles.note}>{notes}</span>
        </div>
      ) : (
        <EmptyDetailDisplay />
      )}
    </>
  );
};

/**
 * Returns JSX Element for a given cargoType
 * @param item laycan given
 * @param type This param will be used for the aria-label. If not given, laycan will be used
 */
export const LaycanHasValue = (laycan: ILaycanView): boolean => Boolean(laycan.display);
export const LaycanDisplay: React.SFC<{
  item: ILaycanView;
  type?: string;
}> = (props) => {
  return (
    <>
      {LaycanHasValue(props.item) ? (
        <span className={styles.value} aria-label={props.type || `laycan`}>
          {props.item.display}
        </span>
      ) : (
        <EmptyDetailDisplay />
      )}
    </>
  );
};

/**
 * Returns JSX Element for a given lifting
 */
export const LiftingsHasValue = (liftings: ILiftingsView): boolean => Boolean(liftings.display);
export const LiftingsDisplay: React.SFC<{
  item: ILiftingsView;
  type?: string;
}> = (props) => {
  return (
    <>
      {LiftingsHasValue(props.item) ? (
        <span className={styles.value} aria-label={props.type || `liftings`}>
          {props.item.display}
        </span>
      ) : (
        <EmptyDetailDisplay />
      )}
    </>
  );
};

/**
 * Returns JSX Element for a given account
 * @param item location given, e.g, loadLocation, dischargeLocation, etc
 * @param type type of location, e.g, 'loadLocation', 'dischargeLocation'. This param will be used for the aria-label and if not given, location will be used
 */
export const LocationHasValue = (location: ILocationView): boolean =>
  Boolean(location.name || location.country || location.zone || location.notes);
export const LocationDisplay: React.SFC<{
  item: ILocationView;
  type?: string;
}> = (props) => {
  const { name, country, zone, notes } = props.item;
  const warranties = getWarrantySafetyInformationAbbreviation(props.item);
  return (
    <>
      {LocationHasValue(props.item) ? (
        <div className={styles.value} aria-label={props.type || `location`}>
          {warranties} {name || country || zone}
          <br />
          <span className={styles.note}>{notes}</span>
        </div>
      ) : (
        <EmptyDetailDisplay />
      )}
    </>
  );
};

/**
 * Returns JSX Element for a given nomination
 */
export const NominationsHasValue = (nominations: INominationsView): boolean => Boolean(nominations.display);
export const NominationsDisplay: React.SFC<{
  item: INominationsView;
  type?: string;
}> = (props) => {
  return (
    <>
      {NominationsHasValue(props.item) ? (
        <span className={styles.value} aria-label={props.type || `nominations`}>
          {props.item.display}
        </span>
      ) : (
        <EmptyDetailDisplay />
      )}
    </>
  );
};

/**
 * Returns JSX Element for a given period
 */
const PeriodHasValue = (period: IPeriodView): boolean => Boolean(period.display);
export const PeriodDisplay: React.SFC<{
  item: IPeriodView;
  type?: string;
}> = (props) => {
  return (
    <>
      {PeriodHasValue(props.item) ? (
        <span className={styles.value} aria-label={props.type || `period`}>
          {props.item.display}
        </span>
      ) : (
        <EmptyDetailDisplay />
      )}
    </>
  );
};

/**
 * Returns JSX Element for a given account
 * @param item unitValue given, e.g, freightRate, demurrage, hireRate, etc
 * @param type type of unitValue, e.g, 'freightRate', 'demurrage'. This param will be used for the aria-label and if not given, unitValue will be used
 */
export const UnitValueHasValue = (unitValue: IUnitValueView): boolean => Boolean(unitValue.value);
export const UnitValueDisplay: React.SFC<{
  item: IUnitValueView;
  type?: string;
}> = (props) => {
  const { value, display } = props.item;
  const doesDetailExist = UnitValueHasValue(props.item);

  return (
    <>
      {doesDetailExist || (value !== null && value.toString() === "0") ? (
        <>
          <span className={styles.value} aria-label={props.type || `unitValue`}>
            {display ? (
              <div className={styles.unit}>
                <div>{display}</div>
              </div>
            ) : (
              ""
            )}
          </span>
        </>
      ) : (
        <EmptyDetailDisplay />
      )}
    </>
  );
};

/**
 * Returns JSX Element for a given vessel size
 * @param item vesselSize given,
 * @param type This param will be used for the aria-label and if not given, vesselSize will be used
 */
export const VesselSizeDisplay: React.SFC<{
  item: IVesselSizeView;
  type?: string;
}> = (props) => {
  const { vesselSizeAbbreviation: size, notes } = props.item;
  const doesDetailExist = size || notes;
  return (
    <>
      {doesDetailExist ? (
        <div className={styles.value} aria-label={props.type || `vesselSize`}>
          {size}
          <br />
          <span className={styles.accompanyingNote}>{notes}</span>
        </div>
      ) : (
        <EmptyDetailDisplay />
      )}
    </>
  );
};

/**
 * Returns JSX Element for a given cargoType
 * @param item laycan given
 * @param type This param will be used for the aria-label. If not given, laycan will be used
 */
export const SubsTextHasValue = (subsText: ISubsTextView): boolean => Boolean(subsText.display);
export const SubsTextDisplay: React.SFC<{
  item: ISubsTextView;
}> = (props) => {
  return (
    <>
      {SubsTextHasValue(props.item) ? (
        <span className={styles.value} aria-label="substext">
          {props.item.display}
        </span>
      ) : (
        <EmptyDetailDisplay />
      )}
    </>
  );
};

export const BunkerHasValue = (bunker: IBunkerView): boolean => Boolean(bunker.display);
