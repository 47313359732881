import React from "react";
import seanetIcon from "./assets/seanet.png";
import styles from "./ExternalButton.module.scss";

interface IExternalButtonProps {
  url: string;
  appName: string;
  image: string;
  imageWidth: number;
  dataTest: string;
}

interface ISeaNetButtonProps {
  seaNetUrl: string;
  cloudToken: string;
  vesselID: string;
}

const ExternalButton = (props: IExternalButtonProps) => (
  <a
    className={styles.container}
    href={props.url}
    target="_blank"
    rel="noopener noreferrer"
    aria-label={`View ${props.appName}`}
    data-test={props.dataTest}
  >
    <label className={styles.action}> VIEW IN </label>
    <img src={props.image} alt={`${props.appName} Logo`} width={props.imageWidth} />
  </a>
);

export const SeaNetButton = (props: ISeaNetButtonProps) => (
  <ExternalButton
    url={`${props.seaNetUrl}/${props.vesselID}?token=${encodeURIComponent(props.cloudToken)}`}
    appName="SeaNet"
    image={seanetIcon}
    imageWidth={60}
    dataTest="sea-net-button"
  />
);
