import { useState } from "react";
import { Panel, PanelType } from "office-ui-fabric-react";
import styles from "./EmailListPanel.module.scss";
import Button from "__legacy/sharedFolder/components/common/Button/Button";
import { PanelCloseButton } from "sharedFolder/components/common/CloseButton/CloseButton";
import { Invitees } from "negotiations/components/createNegotiation/Invitees";

interface IDistributionListPanelProps {
  onUpdateEmails: (newList?: string[]) => void;
  onDismiss: () => void;
  currentCount: number;
  maxCount: number;
}

export const EmailListPanel = ({ onDismiss, onUpdateEmails, currentCount, maxCount }: IDistributionListPanelProps) => {
  const [emails, setEmails] = useState<string[]>([]);

  currentCount = currentCount || 0;
  maxCount = maxCount || Infinity;

  const overflowCount = Math.max(0, emails.length + (currentCount - maxCount));

  return (
    <Panel
      isOpen={true}
      type={PanelType.medium}
      onRenderFooter={() => <></>}
      onDismiss={onDismiss}
      onRenderNavigation={() => <PanelCloseButton onClick={onDismiss} />}
    >
      <div>
        <h3>Add Emails</h3>
        <Invitees onInviteesChanged={setEmails} />
      </div>
      <div className={styles.distributionOverflow} hidden={!overflowCount}>
        You may only add a maximum of {maxCount} email addresses. You will not be able to proceed until you remove{" "}
        {overflowCount === 1 ? "one email" : `${overflowCount} emails`}
      </div>
      <div className={styles.doneButton}>
        <Button type="flat" onClick={onDismiss} ariaLabel="Cancel" dataTest="cancel-emails">
          Cancel
        </Button>
        <Button
          type="action"
          onClick={() => onUpdateEmails(emails)}
          ariaLabel="Add"
          dataTest="add-emails"
          disabled={!emails.length}
        >
          Add
        </Button>
      </div>
    </Panel>
  );
};
