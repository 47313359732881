import { Negotiation, Order } from "@/models";
import { makeObservable, observable } from "mobx";
import { AggridApi, AggridColumnApi, AggridProps, AggridState, AggridGridReadyEvent } from "../Aggrid";
import { AggridColumnStateAPI } from "../ColumnStateAPI";

export class AggridContext {
  constructor(props?: Partial<AggridContext>) {
    Object.assign(this, props);

    this.api = null;
    this.columnApi = null;
    this.state = null;
    this.columnStateAPI = null;
    this.gridReadyPromise = new Promise((resolve) => {
      this.resolveGridReadyPromise = resolve;
    });

    makeObservable(this, {
      api: observable.ref,
      columnApi: observable.ref,
      state: observable.ref,
      columnStateAPI: observable.ref,
    });
  }

  resetGridReadyPromise() {
    this.gridReadyPromise = new Promise((resolve) => {
      this.resolveGridReadyPromise = resolve;
    });
  }
}

export interface AggridContext {
  api: AggridApi | null;
  columnApi: AggridColumnApi | null;
  state: AggridState | null;
  setState: ((partialState: AggridState) => void) | null;
  columnStateAPI: AggridColumnStateAPI | null;
  props: AggridProps;
  EntityModel?: typeof Negotiation | typeof Order;
  gridReadyPromise: Promise<AggridGridReadyEvent>;
  resolveGridReadyPromise(params: AggridGridReadyEvent): void;
  onGridReady(params: AggridGridReadyEvent): void;
  enableSortingFilteringPromise?: Promise<void>;
  onSortFilterAccess?(): void;
}
