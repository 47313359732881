import React from "react";
import classNames from "classnames";
import { auth, CLDDUCharterer } from "@/models";
import { CLDDUEditor, CLDDUEditorProps } from "../CLDDU";

export function CLDDUChartererEditor(props: Props) {
  props = { ...props };

  props.className = classNames(props.className, "CLDDUCharterer");

  const { className, ...rest } = props;

  let CLDDUQuery = "companyType eq 'charterer'";

  if (auth.trade.user?._.companyRoleMap.charterer) {
    CLDDUQuery += ` and companyId eq '${auth.trade.user.CompanyId}'`;
  }

  return (
    <CLDDUEditor
      {...rest}
      searchMessages={searchMessages}
      placeholder={placeholder}
      CLDDUSubset="users"
      CLDDUQuery={CLDDUQuery}
      Constructor={CLDDUCharterer}
    />
  );
}

const placeholder = "Search for charterers";

const searchMessages = {
  initial: "Waiting for your input",
  searching: "Searching for charterers",
  noData: "No charterers found",
} as Props["searchMessages"];

type Props = CLDDUEditorProps;

export type CLDDUChartererEditorProps = Props;
