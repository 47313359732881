import React, { useState, useEffect, useContext, useRef } from "react";
import classnames from "classnames";
import { IOfferRepViewNegotiation } from "negotiations/models/common/IOfferRep";
import { DismissableNotification } from "sharedFolder/components/common/DismissableNotification/DismissableNotification";
import { OrderNegStatus } from "sharedFolder/Models/OrderNegStatus";
import { OfferRepNegotiationContext } from "../OwnerIndicationForm/Contexts/OfferRepNegotiationContext";
import Button from "__legacy/sharedFolder/components/common/Button/Button";
import Icon from "__legacy/sharedFolder/components/common/Icon/Icon";
import TextArea from "sharedFolder/components/common/TextArea/TextArea";
import styles from "./NegotiationNotes.module.scss";
import { formatDateWithTime } from "sharedFolder/Utilities/getNow";

interface IOfferRepNegotiationNotesProps {
  id: string;
  negotiation: IOfferRepViewNegotiation;
}

export const NegotiationNotes: React.SFC<IOfferRepNegotiationNotesProps> = (props) => {
  const [yourNoteEditing, setYourNoteEditing] = useState<boolean>(Boolean(false));

  const [displayUpdate, setDisplayUpdate] = useState<boolean>(false);

  const { offerRepApi } = useContext(OfferRepNegotiationContext);
  const previousNegotiation = useRef(props.negotiation);

  useEffect(() => {
    if (previousNegotiation.current.details && props.negotiation.details) {
      if (
        previousNegotiation.current.details.controllerNegotiationNotes.value !==
        props.negotiation.details.controllerNegotiationNotes.value
      ) {
        setDisplayUpdate(true);
        previousNegotiation.current = props.negotiation;
      }
    }
  }, [props.negotiation, props.negotiation.details]);

  const [offerRepNote, setOfferRepNote] = useState<string>(
    props.negotiation.details && props.negotiation.details.offerRepNegotiationNotes.value
      ? props.negotiation.details.offerRepNegotiationNotes.value
      : ""
  );

  const hasOfferRepNote = props.negotiation.details && props.negotiation.details.offerRepNegotiationNotes.value;

  const hasChartererNote = props.negotiation.details && props.negotiation.details.controllerNegotiationNotes.value;

  const disabledNotesStatuses: OrderNegStatus[] = ["MainTerms", "Firm", "Fixed", "OnSubs", "SubsFailed", "Withdrawn"];
  const disabledNotes = disabledNotesStatuses.includes(props.negotiation.status);

  function submitOfferRepNote(offerRepNote: string) {
    offerRepApi.sendOfferRepNote(offerRepNote).subscribe(() => {
      setYourNoteEditing(false);
    });
  }

  return (
    <div id={props.id} className={styles.negoNotesContainer}>
      {props.negotiation.details && (
        <>
          <div className={styles.sectionTitle}>Notes</div>
          {displayUpdate ? (
            <DismissableNotification
              onClose={() => {
                setDisplayUpdate(false);
              }}
              backgroundColor="blue"
              icon="lightbulb-outline"
              title="The Charterer Sent You A Note"
              body="You can send a note of your own below."
            />
          ) : (
            <></>
          )}
          <div>
            <div className={styles.noteContainer}>
              <div className={styles.information}>
                <Icon icon="alert-note" />
                <label>
                  From here you can read notes from the Charterer or send them a note of your own. You can update your note at any
                  time.
                </label>
              </div>

              <div className={styles.notesEntry}>
                <div className={styles.chartererNotes}>
                  <label>{props.negotiation.controllerNames.join(",")}</label>

                  <label
                    className={classnames(
                      styles.chartererNote,
                      hasChartererNote ? styles.chartererHasNote : styles.chartererNoNote
                    )}
                  >
                    {props.negotiation.details.controllerNegotiationNotes.value || "No note yet"}
                  </label>

                  <div className={styles.lastUpdated}>
                    {props.negotiation.details.controllerNegotiationNotes.timestamp
                      ? `Updated ${formatDateWithTime(props.negotiation.details.controllerNegotiationNotes.timestamp)}`
                      : ""}
                  </div>
                </div>

                <div className={styles.offerRepNotes}>
                  <label>{props.negotiation.invitee}</label>
                  {yourNoteEditing ? (
                    <>
                      <TextArea
                        ariaLabel="offerrep-text-area"
                        dataTest="Offer rep notes textarea"
                        value={offerRepNote}
                        label="Your Note"
                        maxLength={10000}
                        onChange={(evt: React.FocusEvent<HTMLTextAreaElement>) => setOfferRepNote(evt.target.value)}
                      />
                      <div className={styles.actions}>
                        <Button
                          ariaLabel="cancel-edit"
                          dataTest="cancel-edit"
                          type="flat"
                          onClick={() => {
                            setOfferRepNote(
                              props.negotiation.details && props.negotiation.details.offerRepNegotiationNotes.value
                                ? props.negotiation.details.offerRepNegotiationNotes.value
                                : ""
                            );
                            setYourNoteEditing(false);
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          ariaLabel="send-offerrep-note"
                          dataTest="send-offerrep-note"
                          type="action"
                          onClick={() => {
                            submitOfferRepNote(offerRepNote);
                          }}
                        >
                          Send
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className={classnames(
                          styles.offerRepNote,
                          hasOfferRepNote ? styles.offerRepHasNote : styles.offerRepNoNote
                        )}
                      >
                        {props.negotiation.details.offerRepNegotiationNotes.value || "No note yet"}
                      </div>
                      <Button
                        aria-label="edit-offerrep-note"
                        dataTest={hasOfferRepNote ? "edit-offerrep-note" : "send-offerrep-note"}
                        className={styles.sendNote}
                        type="flat"
                        disabled={disabledNotes}
                        onClick={() => setYourNoteEditing(true)}
                      >
                        {hasOfferRepNote ? "EDIT THIS NOTE" : "SEND A NOTE"}
                      </Button>
                      {hasOfferRepNote && (
                        <label className={styles.lastUpdated}>
                          {props.negotiation.details.offerRepNegotiationNotes.timestamp
                            ? `Updated ${formatDateWithTime(props.negotiation.details.offerRepNegotiationNotes.timestamp)}`
                            : ""}
                        </label>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
