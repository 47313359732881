import React from "react";
import { NumberInput } from "___REFACTOR___/components";
import { Field } from "../../Field";
import { Container } from "../../Container";

function NumberField(props: NumberField.Props) {
  const { className, desc, ...rest } = props;

  return (
    <Container {...props} type="Number">
      <NumberInput {...rest} />
    </Container>
  );
}

export { NumberField };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace NumberField {
  export interface Props extends Omit<NumberInput.Props, "type">, Omit<Field.Props, "value" | "onChange"> {}

  export { NumberInput as Input };
}
