import { useContext, useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { observer } from "mobx-react";
import { useParams, Route as RRRoute, useHistory } from "react-router-dom";
import { TradeAPI } from "@/apis";
import {
  lowerCaseFirstLetter,
  setCheckboxColumnVisible,
  useForceUpdate,
  shouldEnableLaunchDarklyFeature,
  CopyIndicationType,
} from "@/utils";
import { legacy } from "@/legacy";
import {
  auth,
  coaOrderNegotiationArchiveStore,
  coaOrderNegotiationStore,
  Order,
  orderNegotiationArchiveStore,
  Route,
  standardOrderNegotiationStore,
} from "@/models";
import { AggridRowClickedEvent } from "@/components";
import { AggridGetContextMenuItemsParams, Banner, Icon } from "@/components/common";
import { BulkOrdersArchiveAction, BulkOrdersUnarchiveAction, OrderArchivingAction, OrderUnarchivingAction } from "../../actions";
import { Context } from "../../Orders";
import { BaseGrid, KebabMenuAction } from "../BaseGrid";
import { bannerHideTimeout } from "sharedFolder/constants";
import { testSelectors } from "@/constants";
import { ArchivingAction } from "../../actions/archiving/BaseArchivingAction";
import { usageMetrics, OrderArchiveEvents, WithdrawEvents } from "@/services/UsageMetrics";
import { LaunchDarklyFeature } from "@/config";
import { useFlags } from "launchdarkly-react-client-sdk";
import { MenuItemDef } from "@ag-grid-enterprise/all-modules";
import { withdrawOrder } from "../../actions/withdrawOrder";
import "./OrderGrid.scss";

function OrderGrid() {
  const { orderId: selectedOrderId } = useParams<StringRecord>();
  const context = useContext(Context);

  const { orderNegotiationStore, routes } = context;
  const status = orderNegotiationStore?.orderArrayStatus;
  const order = selectedOrderId ? orderNegotiationStore?.upsertOrderModel({ id: selectedOrderId }) : undefined;
  const isArchiveSection = ["ito-archive", "coa-archive"].includes(context.area);
  const forceUpdate = useForceUpdate();
  const history = useHistory();
  const [ordersCounter, setOrdersCounter] = useState(orderNegotiationStore?.orderArray.length);
  const [banner, setBanner] = useState<string | null>(null);
  const [bulkPanel, setBulkPanel] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const flags = useFlags();
  const enableWithdrawal = shouldEnableLaunchDarklyFeature(LaunchDarklyFeature.OrderWithdrawal, flags);
  const currentUserId = legacy.user.SystemUserId;

  const closeBulkPanel = () => {
    context.orderNegotiationStore?.orderGridContext.api?.deselectAll();
    const ordersColumnApi = orderNegotiationStore?.orderGridContext.columnApi;
    const coasColumnApi = coaOrderNegotiationStore?.orderGridContext.columnApi;

    setBulkPanel(false);
    if (ordersColumnApi) {
      setCheckboxColumnVisible(false, ordersColumnApi);
    }
    if (coasColumnApi) {
      setCheckboxColumnVisible(false, coasColumnApi);
    }
  };

  useEffect(() => {
    document.addEventListener("copyIndicationEvent", (event: CustomEventInit<CustomCopyEventDetail>) => {
      const type = event.detail?.type;

      if (type) showCopiedNotification(type);
    });
  }, []);

  useEffect(() => {
    setOrdersCounter(orderNegotiationStore?.orderArray.length);
  }, [orderNegotiationStore?.orderArray.length]);

  if (!orderNegotiationStore) return null;

  const archiveRoute = `/${context.area === "coa" ? "coas" : "orders"}-archive`;

  const bulkActionsMenuItem = {
    label: "Bulk Actions",
    icon: "list",
    onClick: () => {
      setBulkPanel(true);
      const ordersColumnApi = orderNegotiationStore?.orderGridContext.columnApi;
      const coasColumnApi = coaOrderNegotiationStore?.orderGridContext.columnApi;

      if (ordersColumnApi) {
        setCheckboxColumnVisible(true, ordersColumnApi);
      }
      if (coasColumnApi) {
        setCheckboxColumnVisible(true, coasColumnApi);
      }
    },
    dataTest: testSelectors.openBulkPanelButton,
    disabled: bulkPanel,
  };

  const viewArchiveActionMenuItem = {
    label: ["ito", "ito-archive"].includes(context.area) ? "View Archived Orders" : "View Archived COAs",
    icon: "archived",
    onClick: () => history.push(archiveRoute),
    dataTest: testSelectors.openArchivedDashboardButton,
  };

  const headerKebabActions = new Array<KebabMenuAction>();

  if (["ito", "ito-archive", "coa", "coa-archive"].includes(context.area)) {
    headerKebabActions.push(bulkActionsMenuItem);
  }

  if (!isArchiveSection) {
    headerKebabActions.push(viewArchiveActionMenuItem);
  }

  function getContextMenuItems(params: AggridGetContextMenuItemsParams): MenuItemDef[] {
    const order = params?.node?.data as TradeAPI["Order"];
    const orderId = order?.id;
    if (!orderId) {
      return [];
    }

    const menuItems: MenuItemDef[] = [];
    const archiveItem = getArchivingContextMenuItem(params);
    if (archiveItem) {
      menuItems.push(archiveItem);
    }
    const withdrawalMenuItems = getWithdrawalMenuItem(orderId);
    if (withdrawalMenuItems) {
      menuItems.push(withdrawalMenuItems);
    }
    return menuItems;
  }

  function getWithdrawalMenuItem(orderId: string): MenuItemDef | null {
    if (!enableWithdrawal) {
      return null;
    }

    const orderModel = orderNegotiationStore!.upsertOrderModel({ id: orderId });
    // Only order creator or his collegues should be able to witdraw the order
    if (orderModel.createdBy.company.id !== auth.trade.user?.CompanyId) {
      return null;
    }

    const withdrawOrderAction = async () => {
      const startMeasureTime =
        currentUserId != orderModel.createdBy.systemUserId
          ? usageMetrics.startTrackingAction(WithdrawEvents.ORDER_WITHDRAWAL_BY_ANOTHER_USER, orderModel.id)
          : usageMetrics.startTrackingAction(WithdrawEvents.ORDER_WITHDRAWAL_START, orderModel.id);
      const orderWithdrawn = await withdrawOrder(orderModel);
      if (orderWithdrawn) {
        showBanner("1 order withdrawn");
        usageMetrics.finishTrackingAction(WithdrawEvents.ORDER_WITHDRAWAL_FINISH, startMeasureTime, orderId);
      } else {
        usageMetrics.finishTrackingAction(WithdrawEvents.ORDER_WITHDRAWAL_FAILED, startMeasureTime, orderId);
      }
    };

    const withdrawDisabled = orderModel.status !== "Inactive" && orderModel.status !== "Active";
    const tooltip = orderModel.status === "Withdrawn" ? "Order has already been withdrawn" : "";

    return {
      name: "Withdraw",
      icon: ReactDOMServer.renderToStaticMarkup(
        <Icon icon="withdrawn" className="context-menu-item-icon" data-test={testSelectors.orderWithdrawButton} />
      ),
      action: withdrawOrderAction,
      disabled: withdrawDisabled,
      tooltip: tooltip,
    };
  }

  function getArchivingContextMenuItem(params: AggridGetContextMenuItemsParams): MenuItemDef | null {
    const order = params?.node?.data as TradeAPI["Order"];
    const orderId = order?.id;
    const orderModel = orderId ? orderNegotiationStore?.upsertOrderModel({ id: orderId }) : undefined;

    if (!orderId) {
      return null;
    }

    const archiveOrder = async () => {
      const startMeasureTime = usageMetrics.startTrackingAction(OrderArchiveEvents.SINGLE_ORDER_ARCHIVE_START, orderId);

      if (routes.orders) {
        unselectOrder(routes.orders, orderId);
      }

      if (!order.updateToken || !orderModel) {
        return;
      }

      if (orderModel.isArchived) {
        // If order is already archived, then we just need to update the state
        updateStateAfterSingleArchvhing(ArchivingAction.Archive, orderId);
        usageMetrics.finishTrackingAction(OrderArchiveEvents.SINGLE_ORDER_ARCHIVE_FINISH, startMeasureTime, orderId);
        return;
      }

      const res = await new OrderArchivingAction(orderModel, context).performActionAsync();
      if (!res?.ok) {
        usageMetrics.finishTrackingAction(OrderArchiveEvents.ARCHIVE_OPERATION_FAILED, startMeasureTime, orderId);
        return;
      }

      updateStateAfterSingleArchvhing(ArchivingAction.Archive, orderId);
      usageMetrics.finishTrackingAction(OrderArchiveEvents.SINGLE_ORDER_ARCHIVE_FINISH, startMeasureTime, orderId);
    };

    const unarchiveOrder = async () => {
      const startMeasureTime = usageMetrics.startTrackingAction(OrderArchiveEvents.SINGLE_ORDER_UNARCHIVE_START, orderId);
      if (routes.archivedOrders) {
        unselectOrder(routes.archivedOrders, orderId);
      }

      if (!order.updateToken || !orderModel) {
        return;
      }

      if (!orderModel.isArchived) {
        // If order is already unarchived, then we just need to update the state
        updateStateAfterSingleArchvhing(ArchivingAction.Unarchive, orderId);
        usageMetrics.finishTrackingAction(OrderArchiveEvents.SINGLE_ORDER_UNARCHIVE_FINISH, startMeasureTime, orderId);
        return;
      }

      const res = await new OrderUnarchivingAction(orderModel, context).performActionAsync();
      if (!res?.ok) {
        usageMetrics.finishTrackingAction(OrderArchiveEvents.UNARCHIVE_OPERATION_FAILED, startMeasureTime, orderId);
        return;
      }

      updateStateAfterSingleArchvhing(ArchivingAction.Unarchive, orderId);
      usageMetrics.finishTrackingAction(OrderArchiveEvents.SINGLE_ORDER_UNARCHIVE_FINISH, startMeasureTime, orderId);
    };

    return context.area === "ito" || context.area === "coa"
      ? {
          name: "Archive",
          icon: ReactDOMServer.renderToStaticMarkup(
            <Icon icon="archived" className="context-menu-item-icon" data-test={testSelectors.archiveButton} />
          ),
          action: archiveOrder,
        }
      : {
          name: "Unarchive",
          icon: ReactDOMServer.renderToStaticMarkup(
            <Icon icon="unarchive" className="context-menu-item-icon" data-test={testSelectors.unarchiveButton} />
          ),
          action: unarchiveOrder,
        };
  }

  const unselectOrder = (stagedRoute: Route, orderId?: string) => {
    let isSelectedOrder;

    if (orderId) {
      isSelectedOrder = orderId === selectedOrderId;
    } else {
      const selectedNodesArray = orderNegotiationStore?.orderGridContext.api?.getSelectedNodes();
      const selectedIds = selectedNodesArray?.map((item) => item.id);
      isSelectedOrder = selectedIds?.includes(selectedOrderId);
    }

    if (isSelectedOrder) stagedRoute.replace({});
  };

  const getArchivedNotificationLabel = (archivedItemsCounter: number) => {
    return ["ito", "coa"].includes(context.area)
      ? `${archivedItemsCounter} order${archivedItemsCounter > 1 ? "s" : ""} moved to archive`
      : `${archivedItemsCounter} order${archivedItemsCounter > 1 ? "s" : ""} moved out of archive`;
  };

  const getCopyNotificationLabel = (copyTitle: CopyIndicationType) => {
    return `${copyTitle} copied to clipboard`;
  };

  const showArchivedNotification = (archivedItemCount: number) => {
    if (ordersCounter) {
      setOrdersCounter(ordersCounter - archivedItemCount);
    }

    const label = getArchivedNotificationLabel(archivedItemCount);
    showBanner(label);
  };

  const showCopiedNotification = (copyTitle: CopyIndicationType) => {
    if (copyTitle) {
      const label = getCopyNotificationLabel(copyTitle);

      showBanner(label);
    }
  };

  const showBanner = (label: string) => {
    if (banner && timeoutId) {
      setBanner(null);
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }

    setBanner(label);

    const id = setTimeout(() => {
      setBanner(null);
    }, bannerHideTimeout);
    setTimeoutId(id);
  };

  const updateStateAfterSingleArchvhing = async (action: ArchivingAction, orderId: string) => {
    showArchivedNotification(1);

    const order = orderNegotiationStore.orderMap[orderId];
    const targetStore = getTargetStoreToMoveOrder(action);
    orderNegotiationStore.moveOrderToStore(order!, targetStore);
  };

  const getSelectedOrderModels = () => {
    const selectedNodesArray = orderNegotiationStore.orderGridContext.api!.getSelectedNodes();
    return selectedNodesArray.map((item) => item.id).map((orderId) => orderNegotiationStore.upsertOrderModel({ id: orderId })!);
  };

  const updateStateAfterBulkArchiving = async (action: ArchivingAction, orderModels: Order[]) => {
    showArchivedNotification(orderModels.length);

    const targetStore = getTargetStoreToMoveOrder(action);
    for (const order of orderModels) {
      orderNegotiationStore.moveOrderToStore(order, targetStore);
    }
  };

  const getTargetStoreToMoveOrder = (action: ArchivingAction) => {
    switch (action) {
      case ArchivingAction.BulkArchive:
      case ArchivingAction.Archive:
        return context.area === "coa" ? coaOrderNegotiationArchiveStore : orderNegotiationArchiveStore;
      case ArchivingAction.BulkUnarchive:
      case ArchivingAction.Unarchive:
        return context.area === "coa-archive" ? coaOrderNegotiationStore : standardOrderNegotiationStore;
      default:
        throw new Error("Unsupported action " + action);
    }
  };

  const updateStateOfSucceededOrders = (actionResult: any, action: ArchivingAction) => {
    if (!actionResult?.succeededEntitiesIds) {
      return;
    }

    const targetStore = getTargetStoreToMoveOrder(action);
    for (const orderId of actionResult.succeededEntitiesIds) {
      const node = orderNegotiationStore?.orderGridContext.api?.getRowNode(orderId);
      if (node) {
        orderNegotiationStore?.orderGridContext.api?.deselectNode(node);
      }

      const orderModel = orderNegotiationStore.getOrderModel(orderId)!;
      orderNegotiationStore.moveOrderToStore(orderModel, targetStore);
    }
  };

  const bulkArchiveOrders = async () => {
    const selectedOrderModels = getSelectedOrderModels();
    if (routes.orders) {
      unselectOrder(routes.orders);
    }
    const nonArchvhiedOrders = selectedOrderModels.filter((o) => !o.isArchived);
    const orderIds: string[] = nonArchvhiedOrders.map((item) => item.id);
    const startMeasureTime = usageMetrics.startTrackingAction(
      OrderArchiveEvents.BULK_ORDER_ARCHIVE_START,
      orderIds,
      nonArchvhiedOrders.length
    );
    if (!nonArchvhiedOrders?.length) {
      // If all orders were already archived, then we just need to update the state
      updateStateAfterBulkArchiving(ArchivingAction.BulkArchive, selectedOrderModels);
      usageMetrics.finishTrackingAction(
        OrderArchiveEvents.BULK_ORDER_ARCHIVE_FINISH,
        startMeasureTime,
        orderIds,
        nonArchvhiedOrders.length
      );
      return;
    }

    const res = await new BulkOrdersArchiveAction(nonArchvhiedOrders, context).performActionAsync();
    if (res?.ok) {
      updateStateAfterBulkArchiving(ArchivingAction.BulkArchive, selectedOrderModels);
      usageMetrics.finishTrackingAction(
        OrderArchiveEvents.BULK_ORDER_ARCHIVE_FINISH,
        startMeasureTime,
        orderIds,
        nonArchvhiedOrders.length
      );
    } else {
      const succeededOrderIds = res.succeededEntitiesIds;
      const failedOrderIds = orderIds.filter((item) => succeededOrderIds.indexOf(item) < 0);
      updateStateOfSucceededOrders(res, ArchivingAction.BulkArchive);
      usageMetrics.finishTrackingAction(
        OrderArchiveEvents.ARCHIVE_OPERATION_FAILED,
        startMeasureTime,
        failedOrderIds,
        failedOrderIds.length
      );
    }
  };

  const bulkUnarchiveOrders = async () => {
    const selectedOrderModels = getSelectedOrderModels();
    if (routes.archivedOrders) {
      unselectOrder(routes.archivedOrders);
    }
    const archivedOrders = selectedOrderModels.filter((o) => o.isArchived);
    const orderIds: string[] = archivedOrders.map((item) => item.id);
    const startMeasureTime = usageMetrics.startTrackingAction(
      OrderArchiveEvents.BULK_ORDER_UNARCHIVE_START,
      orderIds,
      archivedOrders.length
    );
    if (!archivedOrders?.length) {
      // If all orders were already unarchived, then we just need to update the state
      updateStateAfterBulkArchiving(ArchivingAction.BulkUnarchive, selectedOrderModels);
      usageMetrics.finishTrackingAction(
        OrderArchiveEvents.BULK_ORDER_UNARCHIVE_FINISH,
        startMeasureTime,
        orderIds,
        archivedOrders.length
      );
      return;
    }

    const res = await new BulkOrdersUnarchiveAction(archivedOrders, context).performActionAsync();
    if (res?.ok) {
      updateStateAfterBulkArchiving(ArchivingAction.BulkUnarchive, selectedOrderModels);
      usageMetrics.finishTrackingAction(
        OrderArchiveEvents.BULK_ORDER_UNARCHIVE_FINISH,
        startMeasureTime,
        orderIds,
        archivedOrders.length
      );
    } else {
      const succeededOrderIds = res.succeededEntitiesIds;
      const failedOrderIds = orderIds.filter((item) => succeededOrderIds.indexOf(item) < 0);
      updateStateOfSucceededOrders(res, ArchivingAction.BulkUnarchive);
      usageMetrics.finishTrackingAction(
        OrderArchiveEvents.UNARCHIVE_OPERATION_FAILED,
        startMeasureTime,
        failedOrderIds,
        failedOrderIds.length
      );
    }
  };

  const bulkPanelActions = !isArchiveSection
    ? [
        {
          label: "Archive",
          icon: "archived",
          onClick: bulkArchiveOrders,
          dataTest: testSelectors.bulkArchiveButton,
        },
      ]
    : [
        {
          label: "Unarchive",
          icon: "unarchive",
          onClick: bulkUnarchiveOrders,
          dataTest: testSelectors.bulkUnarchiveButton,
        },
      ];

  return (
    <>
      <BaseGrid
        bulkPanelIsVisible={bulkPanel}
        bulkPanelActions={bulkPanelActions}
        context={orderNegotiationStore.orderGridContext}
        selectedEntityId={selectedOrderId}
        entity={order}
        entityType={typeof Order}
        rowCount={ordersCounter}
        totalRowCount={orderNegotiationStore.totalOrderCount}
        isEndReached={orderNegotiationStore.isOrderDataArrayEndReached && orderNegotiationStore.isOrderDataArrayFullyResolved}
        onGridReady={onGridReady}
        className="OrderGrid"
        onRowClicked={onRowClicked}
        status={status}
        headerHeight={52}
        rowHeight={54}
        getContextMenuItems={getContextMenuItems}
        headerKebabActions={headerKebabActions}
        closeBulkPanelAction={closeBulkPanel}
        noRowsOverlayText={
          ["ito-archive", "coa-archive"].includes(context.area)
            ? "There are no archived orders, archiving an order does not impact the order status " +
              "and you can still negotiate on the order"
            : undefined
        }
      />
      {banner && (
        <Banner
          isClosable
          label={banner}
          className="bottom-left"
          onClose={() => {
            setBanner(null);

            if (timeoutId) {
              clearTimeout(timeoutId);
              setTimeoutId(null);
            }
          }}
          customAnimation="fade-in-from-bottom"
        />
      )}
    </>
  );

  function onGridReady() {
    const ordersColumnApi = orderNegotiationStore?.orderGridContext.columnApi;
    const coasColumnApi = coaOrderNegotiationStore?.orderGridContext.columnApi;

    if (ordersColumnApi) {
      setCheckboxColumnVisible(false, ordersColumnApi);
    }
    if (coasColumnApi) {
      setCheckboxColumnVisible(false, coasColumnApi);
    }

    orderNegotiationStore?.orderGridContext.api?.addEventListener("rowDataUpdated", onDataChange);
    coaOrderNegotiationStore?.orderGridContext.api?.addEventListener("rowDataUpdated", onDataChange);
  }

  function onDataChange() {
    forceUpdate();
  }

  function onRowClicked(e: AggridRowClickedEvent) {
    const event = e.event as PointerEvent;
    const bothMouseButtonsPressed = event.buttons === 2;

    if (bothMouseButtonsPressed) return;

    const data = e.data as TradeAPI["Order"];
    const order = new Order(data);

    let stage = lowerCaseFirstLetter(order.getStage());

    if (!stage) return;

    if (stage === "firmOffer" || stage === "firmBid") stage = "active";

    const stageRoute = routes.order[stage];

    if (stageRoute) {
      stageRoute?.replace({ orderId: order.id });
    }
  }
}

interface CustomCopyEventDetail {
  copiedText: string;
  type: CopyIndicationType | undefined;
}

const Observer = observer(OrderGrid);

function OrderGridWrapper() {
  const context = useContext(Context);
  const { routes } = context;
  const orderPath = `${routes.order.index?.absPath}?`;

  return (
    <RRRoute path={orderPath}>
      <Observer />
    </RRRoute>
  );
}

export { OrderGridWrapper as OrderGrid };
