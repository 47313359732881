import { makeAutoObservable } from "mobx";
import { keyBy } from "lodash-es";
import { tradeAPI, TradeAPI } from "___REFACTOR___/apis";
import { Status } from "___REFACTOR___/models";
import { Layout } from "./Layout";

export class ProformaLayouts {
  constructor() {
    makeAutoObservable(this);
  }

  status = {} as Status;
  layouts = null as Layout[] | null;
  layoutsById = null as LayoutsById | null;

  getData = async () => {
    const res = await tradeAPI.getProformaLayouts();

    if (res.ok && res.data) {
      this.layouts = res.data?.map(newLayout);
      this.layoutsById = keyBy(this.layouts, "key");
    }
  };
}

function newLayout(layout: TradeAPI.Termset.Content.ProformaLayout) {
  return new Layout(layout);
}

export const proformaLayouts = new ProformaLayouts();

type LayoutsById = Record<string, Layout>;
