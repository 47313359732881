import { TextField as Text } from "./Text";
import { NumberField as Number } from "./Number";
import { TextareaField as Textarea } from "./Textarea";
import { SelectField as Select } from "./Select";
import { DateField as Date } from "./Date";
import { RadioField as Radio } from "./Radio";
import { DateOffsetField as DateOffset } from "./DateOffset";
import { RangeField as Range } from "./Range";

export const FIELD_TYPE = {
  Text,
  Number,
  Select,
  Textarea,
  Date,
  Radio,
  DateOffset,
  Range,
};

export type FieldType = keyof typeof FIELD_TYPE;
