import React, { useMemo, useContext, useState, useEffect } from "react";
import * as yup from "yup";
import { observer } from "mobx-react";
import { UniversalOrderNegotiationFormValues, slideout } from "@/models";
import { UniversalOrderNegotiationForm, Button, FormContext, OwnerBanner, OwnerNegotiationLegalMessage } from "@/components";
import { sendNegotiationOfferViaOfferrep } from "../actions";
import { Context } from "../Offerrep";
import "./Form.scss";
import classNames from "classnames";
import { useDismissableDisclaimerAnoymousUser } from "./useDismissableDisclaimer";

function Form() {
  const { negotiation } = useContext(Context);
  const { offerRepToken, updateToken } = negotiation;
  const [indicationView, setIndicationView] = useState(false);
  const actions = negotiation?.getActionsModel();
  const {
    brokerChartererFirmed,
    brokerChartererFirmedExpired,
    brokerChartererFirmRequested,
    firmBidAccepted,
    firmOfferAccepted,
  } = actions || {};
  const firmAccepted = firmBidAccepted || firmOfferAccepted;
  const brokerChartererNonExpiredFirmed = brokerChartererFirmed && !brokerChartererFirmedExpired;
  const indicationViewHidden = brokerChartererNonExpiredFirmed || brokerChartererFirmRequested ? !indicationView : false;
  const brokerChartererFirmedViewHidden = indicationView ? true : !brokerChartererNonExpiredFirmed;
  const brokerChartererFirmRquestedViewHidden = indicationView ? true : !brokerChartererFirmRequested;
  const initialValues = useMemo(getInitialValues, [brokerChartererFirmRquestedViewHidden, negotiation?.version]);
  const elementDependencies = [
    indicationViewHidden,
    brokerChartererFirmRquestedViewHidden,
    brokerChartererNonExpiredFirmed,
    negotiation?.version,
  ];
  const elements = useMemo(getElements, [elementDependencies]);
  const disabledStages = ["Withdrawn"];
  const isWholeFormDisabled = disabledStages.includes(negotiation?.getStage() || "");
  const isFormEnabled = form.isChanged && !isWholeFormDisabled;
  let { indicationType } = form.values || {};
  indicationType = indicationType || "indicated";

  const { hasUserAlreadyAgreed, getDisclaimerAgreement, setDisclaimerAgreed } = useDismissableDisclaimerAnoymousUser();
  useEffect(resetIndicationView, [brokerChartererFirmRequested, brokerChartererNonExpiredFirmed]);

  if (!negotiation?.updateToken || !negotiation?.offerRepToken) return null;

  return (
    <div className={classNames("OrderNegotiationForm", isWholeFormDisabled && "formDisabled")}>
      <OwnerBanner negotiation={negotiation} dependencies={elementDependencies} />
      <OwnerNegotiationLegalMessage
        negotiation={negotiation}
        indicationView={indicationView}
        toggleIndicationView={toggleIndicationView}
        onAcceptFirmBid={acceptFirmBid}
      />
      <UniversalOrderNegotiationForm
        initialValues={initialValues}
        elements={elements}
        context={form}
        onSubmit={onSubmit}
        mutateValues={mutateValues}
        yup={schema}
        suppressUnsavedChanges
        enableReinitialize
      />
      <div className="OrderNegotiationForm-form-control" hidden={firmAccepted}>
        <Button onClick={form.reset} disabled={!isFormEnabled} hidden={indicationViewHidden}>
          reset
        </Button>
        <Button variant="action" onClick={form.submit} disabled={!isFormEnabled} hidden={indicationViewHidden}>
          {indicationFormControlLabels[indicationType].submit}
        </Button>
        <Button variant="positive" onClick={acceptFirmBid} hidden={brokerChartererFirmedViewHidden}>
          accept and go firm
        </Button>
        <Button variant="action" onClick={form.submit} hidden={brokerChartererFirmRquestedViewHidden}>
          send firm offer
        </Button>
      </div>
    </div>
  );

  /**
   * Looks at localStorage to see if user has agreed to disclaimer in the past, if not,
   * then will display dialog to allow them to accept or 'cancel' the disclaimer
   * @returns true if the user has agreed to the disclaimer, now or in the past
   */
  async function hasDisclaimerBeenAgreedTo(): Promise<boolean> {
    if (!hasUserAlreadyAgreed()) {
      const setDisclaimerAsAgreed = (): Promise<boolean> => setDisclaimerAgreed(String(offerRepToken), String(updateToken));
      if (!(await getDisclaimerAgreement(setDisclaimerAsAgreed))) {
        return false;
      }
    }
    return true;
  }

  async function onSubmit(data: UniversalOrderNegotiationFormValues) {
    if (!(await hasDisclaimerBeenAgreedTo())) {
      return;
    }

    const res = await sendNegotiationOfferViaOfferrep(negotiation, data);

    if (!res?.ok) return;

    slideout.hide();
  }

  function acceptFirmBid() {
    sendNegotiationOfferViaOfferrep(negotiation, new UniversalOrderNegotiationFormValues({ indicationType: "firmAccepted" }));
  }

  function toggleIndicationView() {
    setIndicationView(!indicationView);
  }

  function getInitialValues() {
    if (!brokerChartererFirmRquestedViewHidden)
      return new UniversalOrderNegotiationFormValues({ ...negotiation?.getBidOfferFormValues(), indicationType: "firmed" });

    return negotiation?.getBidOfferFormValues();
  }

  function getElements() {
    const formStuff = negotiation?.getOwnerFormStuff();

    if (firmBidAccepted) {
      return [
        {
          type: "CollapsibleGroup",
          label: "Firm Bid Summary",
          className: "OrderNegotiationForm-summary",
          children: formStuff?.negotiableBidReadOnlyElements,
        },
      ];
    }

    if (firmOfferAccepted) {
      return [
        {
          type: "CollapsibleGroup",
          label: "Firm Offer Summary",
          className: "OrderNegotiationForm-summary",
          children: formStuff?.negotiableOfferReadOnlyElements,
        },
      ];
    }

    const elements = [
      {
        hidden: indicationViewHidden,

        type: "CollapsibleGroup",
        label: "This response",
        className: "order-negotiations-negotiation-details-form-header",
        children: [
          {
            type: "Radio",
            name: "indicationType",
            radioLabel: "This is an Indication only",
            checkedValue: "indicated",
            dataTest: "indicationTypeOnly",
          },
          {
            type: "Radio",
            name: "indicationType",
            radioLabel: "This is a firm Offer *",
            checkedValue: "firmed",
            dataTest: "indicationTypeFirm",
          },
          {
            type: "TextOnly",
            children: [
              { text: "* References to 'Offer' herein refer to an Owner's Offer" },
              { text: "** References to 'Bid' herein refer to a Charterer's Offer" },
            ],
          },
        ],
      },
      timelimitElement,
      {
        hidden: brokerChartererFirmedViewHidden,
        type: "CollapsibleGroup",
        label: "Bid Summary",
        className: "OrderNegotiationForm-summary",
        children: formStuff?.negotiableBidReadOnlyElements,
      },
      {
        hidden: brokerChartererFirmRquestedViewHidden,
        type: "CollapsibleGroup",
        label: "Offer Summary",
        className: "OrderNegotiationForm-summary",
        children: formStuff?.negotiableOfferReadOnlyElements,
      },
      {
        hidden: indicationViewHidden,
        type: "CollapsibleGroup",
        className: "OrderNegotiationForm-negotiable",
        children: formStuff?.elements,
      },
    ];

    return elements;
  }

  function resetIndicationView() {
    setIndicationView(false);
  }
}

const timelimitElement = {
  label: "Time limit",
  visible: (context) => context.values?.indicationType === "firmed",
  type: "CollapsibleGroup",
  className: "order-negotiations-negotiation-details-form-time-limit",
  children: [
    {
      type: "RadioTimelimit",
      radio: {
        name: "timelimitVariant",
        checkedValue: "DateOffset",
      },
      timelimit: {
        name: "timelimitDateOffset",
        variant: "DateOffset",
        units: ["hour", "minute"],
        getLayout: (input) => ["Charterer will have", input, "to accept the firm Offer"],
      },
    },
    {
      type: "RadioTimelimit",
      radio: {
        name: "timelimitVariant",
        checkedValue: "Date",
      },
      timelimit: {
        name: "timelimitDate",
        getLayout: (input) => ["Charterer to respond by", input],
      },
    },
  ],
};

const indicationFormControlLabels = {
  indicated: {
    submit: "send indication",
  },
  firmed: {
    submit: "send firm offer",
  },
};

function mutateValues(data: UniversalOrderNegotiationFormValues) {
  data.termset = undefined;
  data.ownerAccount = undefined;
}

const schema = yup
  .object()
  .test(
    "Validation0",
    "Something is wrong 0",
    function validate0(this: yup.TestContext, values: UniversalOrderNegotiationFormValues) {
      if (!values) return false;

      const error = this.createError();

      if (values.indicationType === "firmed") {
        if (values.timelimitVariant === "DateOffset" && !values.timelimitDateOffset) {
          error.message = "Required";
          error.path = "timelimitDateOffset";

          return error;
        }

        if (values.timelimitVariant === "Date" && !values.timelimitDate) {
          error.message = "Required";
          error.path = "timelimitDate";

          return error;
        }
      }

      return true;
    }
  );

const form = new FormContext<UniversalOrderNegotiationFormValues>();

const Observer = observer(Form);

export { Observer as Form };
