import { action, makeObservable, observable } from "mobx";
import { TradeAPI, tradeAPI } from "@/apis";
import { createEmailHref, filterObject, isTruthy } from "@/utils";
import { entityStorage } from "@/services";
import { DataModel, DataModelProps } from "../DataModel";
import { router } from "../Router";
import { ChartererAccount } from "../ChartererAccount";
import { Period } from "../Period";
import { CargoSize } from "../CargoSize";
import { CargoType } from "../CargoType";
import { VesselSize } from "../VesselSize";
import { LoadLocation } from "../LoadLocation";
import { DischargeLocation } from "../DischargeLocation";
import { DeliveryLocation } from "../DeliveryLocation";
import { RedeliveryLocation } from "../RedeliveryLocation";
import { ViaLocation } from "../ViaLocation";
import { Duration } from "../Duration";
import { Nominations } from "../Nominations";
import { Liftings } from "../Liftings";
import { COACargoSize } from "../COACargoSize";
import { Vessels } from "../Vessels";
import { OwnerAccount } from "../OwnerAccount";
import { FreightRate, HireRate, Demurrage, BallastBonus, CleaningPrice, SupplyPrice } from "../UnitValue";
import { CommercialSubs, OperationalSubs, TradingExclusions, CargoExclusionsText } from "../Subs";
import { CLDDU } from "../CLDDU";
import { CreatedOn, Timepassed } from "../Timestamp";
import { Actions } from "./Actions";
import { Note } from "./Note";
import { ParsedUpdateToken } from "./ParsedUpdateToken";
import { SubjectTermset } from "../Termset";
import { UniversalOrderNegotiationFormValues } from "../UniversalOrderNegotiationFormValues";
import { Order } from "../Order";
import { Laycan } from "../Laycan";
import { OrderNegotiationStore } from "../OrderNegotiationStore";
import { OrderNotes } from "../OrderNotes";
import { AddressCommission } from "../AddressCommission";
import { BrokerCommission } from "../BrokerCommission";
import { BunkerDelivery, BunkerRedelivery } from "../Bunker";
import { CLDDUOwnerInvitee } from "../CLDDUOwner";
import { auth } from "../Auth";
import {
  getTitledRowElementArrayForDisplay,
  getReadOnlyElementArrayForDisplay,
  getNegDetailArrayForDisplay,
  getDetailArrayForDisplay,
  getAbstractDetailArrayForDisplay,
} from "../Order/utils";

export class Negotiation extends DataModel<Data, Props, UpdateConfig, PartialData, PartialProps> {
  get arcUrlWithCentralToken(): string {
    return this.appendEncodedCentralToken(this.arcUrl);
  }

  get arcCpTermsUrlWithCentralToken(): string {
    return this.appendEncodedCentralToken(`${this.arcUrl}/cp-terms`);
  }

  appendEncodedCentralToken(url: string): string {
    if (!auth.central.token) {
      return url;
    }
    return `${url}?token=${encodeURIComponent(auth.central.token)}`;
  }

  onConstruct(data?: PartialData, props?: PartialProps) {
    if (this._.orderNegotiationStore && data) {
      this._.orderNegotiationStoreNegotiation = this._.orderNegotiationStore.upsertNegotiation(data, {
        shouldUpdateModel: false,
        callee: this,
      });
      this._.orderNegotiationStoreNegotiation._.model = this;

      this.assignData(this._.orderNegotiationStoreNegotiation);
    }

    this.makeObservable();
  }

  onUpdate(data?: PartialData, props?: PartialProps, updateConfig = defaultUpdateConfig) {
    if (updateConfig.shouldUpdateOrderNegotiationStore && data) {
      this._.orderNegotiationStore?.upsertNegotiation(data, { shouldUpdateModel: false, callee: this });
    }
  }

  isDataStale(data?: PartialData, props?: PartialProps, updateConfig = defaultUpdateConfig) {
    if (updateConfig?.forceUpdate) return false;

    if (!data) return true;

    const incomingVersion = data.version || 0;
    const incomingLastUpdated = data.lastUpdated || 0;

    const isCurrentVersionSameOrNewer = incomingVersion <= this.version;
    const isCurrentLastUpdatedSameOrNewer = new Date(incomingLastUpdated) <= new Date(this.lastUpdated);

    const isStale = isCurrentVersionSameOrNewer || isCurrentLastUpdatedSameOrNewer;

    return isStale;
  }

  makeObservable() {
    Object.assign(this, {
      version: this.version || undefined,
      lastUpdated: this.lastUpdated || undefined,
    });

    makeObservable(this, { update: action, version: observable, lastUpdated: observable });
    makeObservable(this._, { version: observable, status: observable });
  }

  get data() {
    return {
      id: this.id,
      orderId: this.orderId,
      jumpToStatus: this.jumpToStatus,
      mainTermsDetails: this.mainTermsDetails,
      bid: this.bid,
      offer: this.offer,
      detailSettings: this.detailSettings,
      circulatedBy: this.circulatedBy,
      createdOn: this.createdOn,
      version: this.version,
      status: this.status,
      orderReference: this.orderReference,
      lastUpdated: this.lastUpdated,
      lastUpdatedBy: this.lastUpdatedBy,
      vessels: this.vessels,
      attachments: this.attachments,
      type: this.type,
      invitee: this.invitee,
      inviteeSystemUserId: this.inviteeSystemUserId,
      updateToken: this.updateToken,
      chartererAccount: this.chartererAccount,
      offerRepToken: this.offerRepToken,
      actions: this.actions,
      owningCompany: this.owningCompany,
      orderNotes: this.orderNotes,
      groupChat: this.groupChat,
      groupChatId: this.groupChatId,
      circulatedDivision: this.circulatedDivision,
      hasBroker: this.hasBroker,
      hasCharterer: this.hasCharterer,
      brokerGroupChatId: this.brokerGroupChatId,
      isOfferRepClaimed: this.isOfferRepClaimed,
      isDealCapture: this.isDealCapture,
      brokerEmailAddresses: this.brokerEmailAddresses,
      chartererEmailAddresses: this.chartererEmailAddresses,
      ownerEmailAddresses: this.ownerEmailAddresses,
      seaContractsUrl: this.seaContractsUrl,
      notes: this.notes,
      orderVersion: this.orderVersion,
      orderAttachments: this.orderAttachments,
      responseRequired: this.responseRequired,
      arcUrl: this.arcUrl,
      offerRepArcUrl: this.offerRepArcUrl,
      arcNegotiationHash: this.arcNegotiationHash,
      commercialSubsLifted: this.commercialSubsLifted,
      operationalSubsLifted: this.operationalSubsLifted,
      publishedNeg: this.publishedNeg,
      sortIndexes: this.sortIndexes,
      filterIndexes: this.filterIndexes,
      isArchived: this.isArchived,
      archivedOn: this.archivedOn,
    };
  }

  isAbsentOwnerEmail(invitee: string) {
    if (invitee.includes("@owner.absent.trade.sea.live")) return true;
    else return false;
  }

  getIndexFromAbsentOwnerEmail(absentOwnerEmail: string) {
    return parseInt(absentOwnerEmail.slice(0, absentOwnerEmail.indexOf("#")));
  }

  getAbsentOwnerDisplay(absentOwnerEmail: string) {
    if (this.isAbsentOwnerEmail(absentOwnerEmail)) {
      const index = this.getIndexFromAbsentOwnerEmail(absentOwnerEmail);
      return `Absent Owner Negotiation ${index}`;
    } else return undefined;
  }

  getCounterparty() {
    return this.mainTermsDetails?.ownerAccount?.accountName || this.owningCompany?.accountName || "TBN";
  }

  getChartererAccountModel() {
    if (this.chartererAccount) return new ChartererAccount(this.chartererAccount);
  }

  getInviteeModel() {
    if (this.invitee) return new CLDDU({ email: this.invitee });
  }

  getVesselsModel() {
    if (this.vessels) return new Vessels(this.vessels);
  }

  getOwningCompanyModel() {
    if (this.owningCompany) return new OwnerAccount(this.owningCompany);
  }

  getNotesModelMap() {
    return {
      brokerCharterer: this.notes?.brokerCharterer ? new Note(this.notes?.brokerCharterer) : undefined,
      owner: this.notes?.owner ? new Note(this.notes?.owner) : undefined,
    };
  }

  getCreatedOnModel() {
    if (this.createdOn) return new CreatedOn(this.createdOn);
  }

  getLastUpdatedModel() {
    if (this.lastUpdated) return new Timepassed(this.lastUpdated);
  }

  getActionsModel() {
    if (this.actions) return new Actions(this.actions);
  }

  getStage() {
    const { status } = this;

    const actions = new Actions(this.actions);

    let res = status as Stage;

    if (!isBeyondInitialTerms(this)) {
      if (actions?.brokerChartererFirmed && !actions?.brokerChartererFirmedExpired) res = "FirmBid";

      if (actions?.ownerFirmed && !actions?.ownerFirmedExpired) res = "FirmOffer";
    }

    return res;
  }

  getParsedUpdateToken() {
    if (this.updateToken) return new ParsedUpdateToken(this.updateToken);
  }

  isBeyondInitialTerms() {
    return isBeyondInitialTerms(this);
  }

  isResponseRequiredExpired() {
    return new Date() > new Date(this.responseRequired || 0);
  }

  getSeenVersion() {
    const seenVersion = entityStorage.get(this)?.seenVersion as number | undefined;

    return seenVersion;
  }

  getBidModelMap() {
    const { bid } = this;

    return {
      laycan: bid?.laycan?.value && new Laycan(bid?.laycan?.value),
      period: bid?.period?.value && new Period(bid?.period?.value),
      liftings: bid?.liftings?.value && new Liftings(bid?.liftings?.value),
      nominations: bid?.nominations?.value && new Nominations(bid?.nominations?.value),
      addressCommission: bid?.addressCommission?.value && new AddressCommission(bid?.addressCommission?.value),
      brokerCommission: bid?.brokerCommission?.value && new BrokerCommission(bid?.brokerCommission?.value),
      cargoType: bid?.cargoType?.value && new CargoType(bid?.cargoType?.value),
      cargoSize: bid?.cargoSize?.value && new CargoSize(bid?.cargoSize?.value),
      coaCargoSize: bid?.coaCargoSize?.value && new COACargoSize(bid?.coaCargoSize?.value),
      dischargeLocation: bid?.dischargeLocation?.value && new DischargeLocation(bid?.dischargeLocation?.value),
      loadLocation: bid?.loadLocation?.value && new LoadLocation(bid?.loadLocation?.value),
      deliveryLocation: bid?.deliveryLocation?.value && new DeliveryLocation(bid?.deliveryLocation?.value),
      duration: bid?.duration?.value && new Duration(bid?.duration?.value),
      redeliveryLocation: bid?.redeliveryLocation?.value && new RedeliveryLocation(bid?.redeliveryLocation?.value),
      vesselSize: bid?.vesselSize?.value && new VesselSize(bid?.vesselSize?.value),
      viaLocation: bid?.viaLocation?.value && new ViaLocation(bid?.viaLocation?.value),
      ballastBonus: bid?.ballastBonus?.value && new BallastBonus(bid?.ballastBonus?.value),
      demurrage: bid?.demurrage?.value && new Demurrage(bid?.demurrage?.value),
      freightRate: bid?.freightRate?.value && new FreightRate(bid?.freightRate?.value),
      hireRate: bid?.hireRate?.value && new HireRate(bid?.hireRate?.value),
      cleaningPrice: bid?.cleaningPrice?.value && new CleaningPrice(bid?.cleaningPrice?.value),
      supplyPrice: bid?.supplyPrice?.value && new SupplyPrice(bid?.supplyPrice?.value),
      tradingExclusions: bid?.tradingExclusions?.value && new TradingExclusions(bid?.tradingExclusions?.value),
      bunkerDelivery: bid?.bunkerDelivery?.value && new BunkerDelivery(bid?.bunkerDelivery?.value),
      bunkerRedelivery: bid?.bunkerRedelivery?.value && new BunkerRedelivery(bid?.bunkerRedelivery?.value),
      cargoExclusionsText: bid?.cargoExclusionsText?.value && new CargoExclusionsText(bid?.cargoExclusionsText?.value),
      operationalSubs: bid?.operationalSubs?.value && new OperationalSubs(bid?.operationalSubs?.value),
      commercialSubs: bid?.commercialSubs?.value && new CommercialSubs(bid?.commercialSubs?.value),
    };
  }

  getMainTermsDetailOrOfferOrBidMap() {
    const { mainTermsDetails, offer, bid } = this;

    return {
      laycan: mainTermsDetails?.laycan || offer?.laycan?.value || bid?.laycan?.value,
      period: mainTermsDetails?.period || offer?.period?.value || bid?.period?.value,
      liftings: mainTermsDetails?.liftings || offer?.liftings?.value || bid?.liftings?.value,
      nominations: mainTermsDetails?.nominations || offer?.nominations?.value || bid?.nominations?.value,
      addressCommission: mainTermsDetails?.addressCommission || offer?.addressCommission?.value || bid?.addressCommission?.value,
      brokerCommission: mainTermsDetails?.brokerCommission || offer?.brokerCommission?.value || bid?.brokerCommission?.value,
      cargoType: mainTermsDetails?.cargoType || offer?.cargoType?.value || bid?.cargoType?.value,
      cargoSize: mainTermsDetails?.cargoSize || offer?.cargoSize?.value || bid?.cargoSize?.value,
      coaCargoSize: mainTermsDetails?.coaCargoSize || offer?.coaCargoSize?.value || bid?.coaCargoSize?.value,
      dischargeLocation: mainTermsDetails?.dischargeLocation || offer?.dischargeLocation?.value || bid?.dischargeLocation?.value,
      loadLocation: mainTermsDetails?.loadLocation || offer?.loadLocation?.value || bid?.loadLocation?.value,
      deliveryLocation: mainTermsDetails?.deliveryLocation || offer?.deliveryLocation?.value || bid?.deliveryLocation?.value,
      duration: mainTermsDetails?.duration || offer?.duration?.value || bid?.duration?.value,
      redeliveryLocation:
        mainTermsDetails?.redeliveryLocation || offer?.redeliveryLocation?.value || bid?.redeliveryLocation?.value,
      vesselSize: mainTermsDetails?.vesselSize || offer?.vesselSize?.value || bid?.vesselSize?.value,
      viaLocation: mainTermsDetails?.viaLocation || offer?.viaLocation?.value || bid?.viaLocation?.value,
      ballastBonus: mainTermsDetails?.ballastBonus || offer?.ballastBonus?.value || bid?.ballastBonus?.value,
      demurrage: mainTermsDetails?.demurrage || offer?.demurrage?.value || bid?.demurrage?.value,
      freightRate: mainTermsDetails?.freightRate || offer?.freightRate?.value || bid?.freightRate?.value,
      hireRate: mainTermsDetails?.hireRate || offer?.hireRate?.value || bid?.hireRate?.value,
      cleaningPrice: mainTermsDetails?.cleaningPrice || offer?.cleaningPrice?.value || bid?.cleaningPrice?.value,
      supplyPrice: mainTermsDetails?.supplyPrice || offer?.supplyPrice?.value || bid?.supplyPrice?.value,
      tradingExclusions: mainTermsDetails?.tradingExclusions || offer?.tradingExclusions?.value || bid?.tradingExclusions?.value,
      bunkerDelivery: mainTermsDetails?.bunkerDelivery || offer?.bunkerDelivery?.value || bid?.bunkerDelivery?.value,
      bunkerRedelivery: mainTermsDetails?.bunkerRedelivery || offer?.bunkerRedelivery?.value || bid?.bunkerRedelivery?.value,
      cargoExclusionsText:
        mainTermsDetails?.cargoExclusionsText || offer?.cargoExclusionsText?.value || bid?.cargoExclusionsText?.value,
      operationalSubs: mainTermsDetails?.operationalSubs || offer?.operationalSubs?.value || bid?.operationalSubs?.value,
      commercialSubs: mainTermsDetails?.commercialSubs || offer?.commercialSubs?.value || bid?.commercialSubs?.value,
    };
  }

  getMainTermsDetailOrOfferOrBidMapSensitivelyToBrokerHandbrake() {
    const { mainTermsDetails, offer } = this;
    const isCharterer = auth.trade.user?._.companyRoleMap.charterer;
    const isManagedByBroker = this.hasBroker;
    const isSensitiveToBrokerHandbrake = isCharterer && isManagedByBroker;
    let bid = this.bid as Negotiation["bid"] | undefined;

    if (isSensitiveToBrokerHandbrake) {
      bid = undefined;
    }

    return {
      laycan: mainTermsDetails?.laycan || offer?.laycan?.value || bid?.laycan?.value,
      period: mainTermsDetails?.period || offer?.period?.value || bid?.period?.value,
      liftings: mainTermsDetails?.liftings || offer?.liftings?.value || bid?.liftings?.value,
      nominations: mainTermsDetails?.nominations || offer?.nominations?.value || bid?.nominations?.value,
      addressCommission: mainTermsDetails?.addressCommission || offer?.addressCommission?.value || bid?.addressCommission?.value,
      brokerCommission: mainTermsDetails?.brokerCommission || offer?.brokerCommission?.value || bid?.brokerCommission?.value,
      cargoType: mainTermsDetails?.cargoType || offer?.cargoType?.value || bid?.cargoType?.value,
      cargoSize: mainTermsDetails?.cargoSize || offer?.cargoSize?.value || bid?.cargoSize?.value,
      coaCargoSize: mainTermsDetails?.coaCargoSize || offer?.coaCargoSize?.value || bid?.coaCargoSize?.value,
      dischargeLocation: mainTermsDetails?.dischargeLocation || offer?.dischargeLocation?.value || bid?.dischargeLocation?.value,
      loadLocation: mainTermsDetails?.loadLocation || offer?.loadLocation?.value || bid?.loadLocation?.value,
      deliveryLocation: mainTermsDetails?.deliveryLocation || offer?.deliveryLocation?.value || bid?.deliveryLocation?.value,
      duration: mainTermsDetails?.duration || offer?.duration?.value || bid?.duration?.value,
      redeliveryLocation:
        mainTermsDetails?.redeliveryLocation || offer?.redeliveryLocation?.value || bid?.redeliveryLocation?.value,
      vesselSize: mainTermsDetails?.vesselSize || offer?.vesselSize?.value || bid?.vesselSize?.value,
      viaLocation: mainTermsDetails?.viaLocation || offer?.viaLocation?.value || bid?.viaLocation?.value,
      ballastBonus: mainTermsDetails?.ballastBonus || offer?.ballastBonus?.value || bid?.ballastBonus?.value,
      demurrage: mainTermsDetails?.demurrage || offer?.demurrage?.value || bid?.demurrage?.value,
      freightRate: mainTermsDetails?.freightRate || offer?.freightRate?.value || bid?.freightRate?.value,
      hireRate: mainTermsDetails?.hireRate || offer?.hireRate?.value || bid?.hireRate?.value,
      cleaningPrice: mainTermsDetails?.cleaningPrice || offer?.cleaningPrice?.value || bid?.cleaningPrice?.value,
      supplyPrice: mainTermsDetails?.supplyPrice || offer?.supplyPrice?.value || bid?.supplyPrice?.value,
      tradingExclusions: mainTermsDetails?.tradingExclusions || offer?.tradingExclusions?.value || bid?.tradingExclusions?.value,
      bunkerDelivery: mainTermsDetails?.bunkerDelivery || offer?.bunkerDelivery?.value || bid?.bunkerDelivery?.value,
      bunkerRedelivery: mainTermsDetails?.bunkerRedelivery || offer?.bunkerRedelivery?.value || bid?.bunkerRedelivery?.value,
      cargoExclusionsText:
        mainTermsDetails?.cargoExclusionsText || offer?.cargoExclusionsText?.value || bid?.cargoExclusionsText?.value,
      operationalSubs: mainTermsDetails?.operationalSubs || offer?.operationalSubs?.value || bid?.operationalSubs?.value,
      commercialSubs: mainTermsDetails?.commercialSubs || offer?.commercialSubs?.value || bid?.commercialSubs?.value,
    };
  }

  getMainTermsDetailOrOfferOrBidModelMap() {
    const { mainTermsDetails, offer, bid } = this;

    const laycan = mainTermsDetails?.laycan || offer?.laycan?.value || bid?.laycan?.value;
    const period = mainTermsDetails?.period || offer?.period?.value || bid?.period?.value;
    const liftings = mainTermsDetails?.liftings || offer?.liftings?.value || bid?.liftings?.value;
    const nominations = mainTermsDetails?.nominations || offer?.nominations?.value || bid?.nominations?.value;
    const addressCommission =
      mainTermsDetails?.addressCommission || offer?.addressCommission?.value || bid?.addressCommission?.value;
    const brokerCommission = mainTermsDetails?.brokerCommission || offer?.brokerCommission?.value || bid?.brokerCommission?.value;
    const cargoType = mainTermsDetails?.cargoType || offer?.cargoType?.value || bid?.cargoType?.value;
    const cargoSize = mainTermsDetails?.cargoSize || offer?.cargoSize?.value || bid?.cargoSize?.value;
    const coaCargoSize = mainTermsDetails?.coaCargoSize || offer?.coaCargoSize?.value || bid?.coaCargoSize?.value;
    const dischargeLocation =
      mainTermsDetails?.dischargeLocation || offer?.dischargeLocation?.value || bid?.dischargeLocation?.value;
    const loadLocation = mainTermsDetails?.loadLocation || offer?.loadLocation?.value || bid?.loadLocation?.value;
    const deliveryLocation = mainTermsDetails?.deliveryLocation || offer?.deliveryLocation?.value || bid?.deliveryLocation?.value;
    const duration = mainTermsDetails?.duration || offer?.duration?.value || bid?.duration?.value;
    const redeliveryLocation =
      mainTermsDetails?.redeliveryLocation || offer?.redeliveryLocation?.value || bid?.redeliveryLocation?.value;
    const vesselSize = mainTermsDetails?.vesselSize || offer?.vesselSize?.value || bid?.vesselSize?.value;
    const viaLocation = mainTermsDetails?.viaLocation || offer?.viaLocation?.value || bid?.viaLocation?.value;
    const ballastBonus = mainTermsDetails?.ballastBonus || offer?.ballastBonus?.value || bid?.ballastBonus?.value;
    const demurrage = mainTermsDetails?.demurrage || offer?.demurrage?.value || bid?.demurrage?.value;
    const freightRate = mainTermsDetails?.freightRate || offer?.freightRate?.value || bid?.freightRate?.value;
    const hireRate = mainTermsDetails?.hireRate || offer?.hireRate?.value || bid?.hireRate?.value;
    const cleaningPrice = mainTermsDetails?.cleaningPrice || offer?.cleaningPrice?.value || bid?.cleaningPrice?.value;
    const supplyPrice = mainTermsDetails?.supplyPrice || offer?.supplyPrice?.value || bid?.supplyPrice?.value;
    const tradingExclusions =
      mainTermsDetails?.tradingExclusions || offer?.tradingExclusions?.value || bid?.tradingExclusions?.value;
    const bunkerDelivery = mainTermsDetails?.bunkerDelivery || offer?.bunkerDelivery?.value || bid?.bunkerDelivery?.value;
    const bunkerRedelivery = mainTermsDetails?.bunkerRedelivery || offer?.bunkerRedelivery?.value || bid?.bunkerRedelivery?.value;
    const cargoExclusionsText =
      mainTermsDetails?.cargoExclusionsText || offer?.cargoExclusionsText?.value || bid?.cargoExclusionsText?.value;
    const operationalSubs = mainTermsDetails?.operationalSubs || offer?.operationalSubs?.value || bid?.operationalSubs?.value;
    const commercialSubs = mainTermsDetails?.commercialSubs || offer?.commercialSubs?.value || bid?.commercialSubs?.value;

    return {
      laycan: laycan && new Laycan(laycan),
      period: period && new Period(period),
      liftings: liftings && new Liftings(liftings),
      nominations: nominations && new Nominations(nominations),
      addressCommission: addressCommission && new AddressCommission(addressCommission),
      brokerCommission: brokerCommission && new BrokerCommission(brokerCommission),
      cargoType: cargoType && new CargoType(cargoType),
      cargoSize: cargoSize && new CargoSize(cargoSize),
      coaCargoSize: coaCargoSize && new COACargoSize(coaCargoSize),
      dischargeLocation: dischargeLocation && new DischargeLocation(dischargeLocation),
      loadLocation: loadLocation && new LoadLocation(loadLocation),
      deliveryLocation: deliveryLocation && new DeliveryLocation(deliveryLocation),
      duration: duration && new Duration(duration),
      redeliveryLocation: redeliveryLocation && new RedeliveryLocation(redeliveryLocation),
      vesselSize: vesselSize && new VesselSize(vesselSize),
      viaLocation: viaLocation && new ViaLocation(viaLocation),
      ballastBonus: ballastBonus && new BallastBonus(ballastBonus),
      demurrage: demurrage && new Demurrage(demurrage),
      freightRate: freightRate && new FreightRate(freightRate),
      hireRate: hireRate && new HireRate(hireRate),
      cleaningPrice: cleaningPrice && new CleaningPrice(cleaningPrice),
      supplyPrice: supplyPrice && new SupplyPrice(supplyPrice),
      tradingExclusions: tradingExclusions && new TradingExclusions(tradingExclusions),
      bunkerDelivery: bunkerDelivery && new BunkerDelivery(bunkerDelivery),
      bunkerRedelivery: bunkerRedelivery && new BunkerRedelivery(bunkerRedelivery),
      cargoExclusionsText: cargoExclusionsText && new CargoExclusionsText(cargoExclusionsText),
      operationalSubs: operationalSubs && new OperationalSubs(operationalSubs),
      commercialSubs: commercialSubs && new CommercialSubs(commercialSubs),
    };
  }

  getOfferModelMap() {
    const { offer } = this;

    return {
      laycan: offer?.laycan?.value && new Laycan(offer?.laycan?.value),
      period: offer?.period?.value && new Period(offer?.period?.value),
      liftings: offer?.liftings?.value && new Liftings(offer?.liftings?.value),
      nominations: offer?.nominations?.value && new Nominations(offer?.nominations?.value),
      addressCommission: offer?.addressCommission?.value && new AddressCommission(offer?.addressCommission?.value),
      brokerCommission: offer?.brokerCommission?.value && new BrokerCommission(offer?.brokerCommission?.value),
      cargoType: offer?.cargoType?.value && new CargoType(offer?.cargoType?.value),
      cargoSize: offer?.cargoSize?.value && new CargoSize(offer?.cargoSize?.value),
      coaCargoSize: offer?.coaCargoSize?.value && new COACargoSize(offer?.coaCargoSize?.value),
      dischargeLocation: offer?.dischargeLocation?.value && new DischargeLocation(offer?.dischargeLocation?.value),
      loadLocation: offer?.loadLocation?.value && new LoadLocation(offer?.loadLocation?.value),
      deliveryLocation: offer?.deliveryLocation?.value && new DeliveryLocation(offer?.deliveryLocation?.value),
      duration: offer?.duration?.value && new Duration(offer?.duration?.value),
      redeliveryLocation: offer?.redeliveryLocation?.value && new RedeliveryLocation(offer?.redeliveryLocation?.value),
      vesselSize: offer?.vesselSize?.value && new VesselSize(offer?.vesselSize?.value),
      viaLocation: offer?.viaLocation?.value && new ViaLocation(offer?.viaLocation?.value),
      ballastBonus: offer?.ballastBonus?.value && new BallastBonus(offer?.ballastBonus?.value),
      demurrage: offer?.demurrage?.value && new Demurrage(offer?.demurrage?.value),
      freightRate: offer?.freightRate?.value && new FreightRate(offer?.freightRate?.value),
      hireRate: offer?.hireRate?.value && new HireRate(offer?.hireRate?.value),
      cleaningPrice: offer?.cleaningPrice?.value && new CleaningPrice(offer?.cleaningPrice?.value),
      supplyPrice: offer?.supplyPrice?.value && new SupplyPrice(offer?.supplyPrice?.value),
      tradingExclusions: offer?.tradingExclusions?.value && new TradingExclusions(offer?.tradingExclusions?.value),
      bunkerDelivery: offer?.bunkerDelivery?.value && new BunkerDelivery(offer?.bunkerDelivery?.value),
      bunkerRedelivery: offer?.bunkerRedelivery?.value && new BunkerRedelivery(offer?.bunkerRedelivery?.value),
      cargoExclusionsText: offer?.cargoExclusionsText?.value && new CargoExclusionsText(offer?.cargoExclusionsText?.value),
      operationalSubs: offer?.operationalSubs?.value && new OperationalSubs(offer?.operationalSubs?.value),
      commercialSubs: offer?.commercialSubs?.value && new CommercialSubs(offer?.commercialSubs?.value),
    };
  }

  getBidOrOfferModelMap() {
    const { offer, bid } = this;

    const laycan = bid?.laycan?.value || offer?.laycan?.value;
    const period = bid?.period?.value || offer?.period?.value;
    const liftings = bid?.liftings?.value || offer?.liftings?.value;
    const nominations = bid?.nominations?.value || offer?.nominations?.value;
    const addressCommission = bid?.addressCommission?.value || offer?.addressCommission?.value;
    const brokerCommission = bid?.brokerCommission?.value || offer?.brokerCommission?.value;
    const cargoType = bid?.cargoType?.value || offer?.cargoType?.value;
    const cargoSize = bid?.cargoSize?.value || offer?.cargoSize?.value;
    const coaCargoSize = bid?.coaCargoSize?.value || offer?.coaCargoSize?.value;
    const dischargeLocation = bid?.dischargeLocation?.value || offer?.dischargeLocation?.value;
    const loadLocation = bid?.loadLocation?.value || offer?.loadLocation?.value;
    const deliveryLocation = bid?.deliveryLocation?.value || offer?.deliveryLocation?.value;
    const duration = bid?.duration?.value || offer?.duration?.value;
    const redeliveryLocation = bid?.redeliveryLocation?.value || offer?.redeliveryLocation?.value;
    const vesselSize = bid?.vesselSize?.value || offer?.vesselSize?.value;
    const viaLocation = bid?.viaLocation?.value || offer?.viaLocation?.value;
    const ballastBonus = bid?.ballastBonus?.value || offer?.ballastBonus?.value;
    const demurrage = bid?.demurrage?.value || offer?.demurrage?.value;
    const freightRate = bid?.freightRate?.value || offer?.freightRate?.value;
    const hireRate = bid?.hireRate?.value || offer?.hireRate?.value;
    const cleaningPrice = bid?.cleaningPrice?.value || offer?.cleaningPrice?.value;
    const supplyPrice = bid?.supplyPrice?.value || offer?.supplyPrice?.value;
    const tradingExclusions = bid?.tradingExclusions?.value || offer?.tradingExclusions?.value;
    const bunkerDelivery = bid?.bunkerDelivery?.value || offer?.bunkerDelivery?.value;
    const bunkerRedelivery = bid?.bunkerRedelivery?.value || offer?.bunkerRedelivery?.value;
    const cargoExclusionsText = bid?.cargoExclusionsText?.value || offer?.cargoExclusionsText?.value;
    const operationalSubs = bid?.operationalSubs?.value || offer?.operationalSubs?.value;
    const commercialSubs = bid?.commercialSubs?.value || offer?.commercialSubs?.value;

    return {
      laycan: laycan && new Laycan(laycan),
      period: period && new Period(period),
      liftings: liftings && new Liftings(liftings),
      nominations: nominations && new Nominations(nominations),
      addressCommission: addressCommission && new AddressCommission(addressCommission),
      brokerCommission: brokerCommission && new BrokerCommission(brokerCommission),
      cargoType: cargoType && new CargoType(cargoType),
      cargoSize: cargoSize && new CargoSize(cargoSize),
      coaCargoSize: coaCargoSize && new COACargoSize(coaCargoSize),
      dischargeLocation: dischargeLocation && new DischargeLocation(dischargeLocation),
      loadLocation: loadLocation && new LoadLocation(loadLocation),
      deliveryLocation: deliveryLocation && new DeliveryLocation(deliveryLocation),
      duration: duration && new Duration(duration),
      redeliveryLocation: redeliveryLocation && new RedeliveryLocation(redeliveryLocation),
      vesselSize: vesselSize && new VesselSize(vesselSize),
      viaLocation: viaLocation && new ViaLocation(viaLocation),
      ballastBonus: ballastBonus && new BallastBonus(ballastBonus),
      demurrage: demurrage && new Demurrage(demurrage),
      freightRate: freightRate && new FreightRate(freightRate),
      hireRate: hireRate && new HireRate(hireRate),
      cleaningPrice: cleaningPrice && new CleaningPrice(cleaningPrice),
      supplyPrice: supplyPrice && new SupplyPrice(supplyPrice),
      tradingExclusions: tradingExclusions && new TradingExclusions(tradingExclusions),
      bunkerDelivery: bunkerDelivery && new BunkerDelivery(bunkerDelivery),
      bunkerRedelivery: bunkerRedelivery && new BunkerRedelivery(bunkerRedelivery),
      cargoExclusionsText: cargoExclusionsText && new CargoExclusionsText(cargoExclusionsText),
      operationalSubs: operationalSubs && new OperationalSubs(operationalSubs),
      commercialSubs: commercialSubs && new CommercialSubs(commercialSubs),
    };
  }

  getMainTermsFormValues() {
    const subjectTermset = new SubjectTermset(DEFAULT_SUBJECT_TERMSET);
    const actions = new Actions(this.actions);
    const { firmOfferAccepted } = actions;
    const isFirm = this.status === "Firm";
    const bidOrOfferMap = filterObject(this.getBidOrOfferModelMap(), isTruthy);
    const offerMap = filterObject(this.getOfferModelMap(), isTruthy);
    const details = isFirm ? (firmOfferAccepted ? { ...bidOrOfferMap, ...offerMap } : bidOrOfferMap) : bidOrOfferMap;

    if (subjectTermset?.content?.mainTermTemplates?.[0]) {
      subjectTermset.content.mainTermTemplates[0].content = bidOrOfferMap.operationalSubs?.value;
    }

    if (subjectTermset?.content?.mainTermTemplates?.[1]) {
      subjectTermset.content.mainTermTemplates[1].content = bidOrOfferMap.commercialSubs?.value;
    }
    const notes = this.orderNotes ? new OrderNotes(this.orderNotes) : undefined;
    return new UniversalOrderNegotiationFormValues({
      ...details,
      notes: notes,
      coaNotes: this.type === "Coa" ? notes : undefined,
      tctNotes: this.type === "Tct" ? notes : undefined,
      voyageNotes: this.type === "Voy" ? notes : undefined,
      ownerAccount: this.owningCompany ? new OwnerAccount(this.owningCompany) : undefined,
      chartererAccount: this.chartererAccount ? new ChartererAccount(this.chartererAccount) : undefined,
      invitee: this.invitee ? new CLDDUOwnerInvitee({ email: this.invitee }) : undefined,
      vessels: this.vessels ? new Vessels(new Vessels(this.vessels).getNonRejectedVesselsSortedByStatus()) : undefined,
      subjectTermset,
    });
  }

  getNegotiableBidModelArray() {
    const { detailSettings, bid, type } = this;

    return getNegDetailArrayForDisplay(bid, { detailSettings, type, negotiableFlag: true });
  }

  getNegotiableOfferModelArray() {
    const { detailSettings, offer, type } = this;

    return getNegDetailArrayForDisplay(offer, { detailSettings, type, negotiableFlag: true });
  }

  getNegotiablePublishedNegOfferModelArray() {
    const { detailSettings, type } = this;

    return this.publishedNeg?.bid
      ? getNegDetailArrayForDisplay(this.publishedNeg?.bid, { detailSettings, type, negotiableFlag: true })
      : [];
  }

  getNonNegotiableInitialTermModelArray() {
    const { detailSettings, offer, bid, chartererAccount, orderNotes, type } = this;

    const map = {
      chartererAccount,
      orderNotes,
      laycan: bid?.laycan || offer?.laycan,
      period: bid?.period || offer?.period,
      liftings: bid?.liftings || offer?.liftings,
      nominations: bid?.nominations || offer?.nominations,
      addressCommission: bid?.addressCommission || offer?.addressCommission,
      brokerCommission: bid?.brokerCommission || offer?.brokerCommission,
      cargoType: bid?.cargoType || offer?.cargoType,
      cargoSize: bid?.cargoSize || offer?.cargoSize,
      coaCargoSize: bid?.coaCargoSize || offer?.coaCargoSize,
      dischargeLocation: bid?.dischargeLocation || offer?.dischargeLocation,
      loadLocation: bid?.loadLocation || offer?.loadLocation,
      deliveryLocation: bid?.deliveryLocation || offer?.deliveryLocation,
      duration: bid?.duration || offer?.duration,
      redeliveryLocation: bid?.redeliveryLocation || offer?.redeliveryLocation,
      vesselSize: bid?.vesselSize || offer?.vesselSize,
      viaLocation: bid?.viaLocation || offer?.viaLocation,
      ballastBonus: bid?.ballastBonus || offer?.ballastBonus,
      demurrage: bid?.demurrage || offer?.demurrage,
      freightRate: bid?.freightRate || offer?.freightRate,
      hireRate: bid?.hireRate || offer?.hireRate,
      cleaningPrice: bid?.cleaningPrice || offer?.cleaningPrice,
      supplyPrice: bid?.supplyPrice || offer?.supplyPrice,
      tradingExclusions: bid?.tradingExclusions || offer?.tradingExclusions,
      bunkerDelivery: bid?.bunkerDelivery || offer?.bunkerDelivery,
      bunkerRedelivery: bid?.bunkerRedelivery || offer?.bunkerRedelivery,
      cargoExclusionsText: bid?.cargoExclusionsText || offer?.cargoExclusionsText,
      operationalSubs: bid?.operationalSubs || offer?.operationalSubs,
      commercialSubs: bid?.commercialSubs || offer?.commercialSubs,
    };

    return getNegDetailArrayForDisplay(map, { detailSettings, type, negotiableFlag: false });
  }

  getNegotiableBidAndOfferModelArray(shouldIncludeNewTctFields: boolean) {
    const { detailSettings, offer, bid, type } = this;

    const map: any = {
      laycan: {
        name: "laycan",
        label: Laycan.prototype._.label,
        bid: bid?.laycan?.value && new Laycan(bid.laycan.value),
        offer: offer?.laycan?.value && new Period(offer.laycan.value),
      },
      period: {
        name: "period",
        label: Laycan.prototype._.label,
        bid: bid?.period?.value && new Laycan(bid.period.value),
        offer: offer?.period?.value && new Period(offer.period.value),
      },
      liftings: {
        name: "liftings",
        label: Liftings.prototype._.label,
        bid: bid?.liftings?.value && new Liftings(bid.liftings.value),
        offer: offer?.liftings?.value && new Liftings(offer.liftings.value),
      },
      nominations: {
        name: "nominations",
        label: Nominations.prototype._.label,
        bid: bid?.nominations?.value && new Nominations(bid.nominations.value),
        offer: offer?.nominations?.value && new Nominations(offer.nominations.value),
      },
      addressCommission: {
        name: "addressCommission",
        label: AddressCommission.prototype._.label,
        bid: bid?.addressCommission?.value && new AddressCommission(bid.addressCommission.value),
        offer: offer?.addressCommission?.value && new AddressCommission(offer.addressCommission.value),
      },
      brokerCommission: {
        name: "brokerCommission",
        label: BrokerCommission.prototype._.label,
        bid: bid?.brokerCommission?.value && new BrokerCommission(bid.brokerCommission.value),
        offer: offer?.brokerCommission?.value && new BrokerCommission(offer.brokerCommission.value),
      },
      cargoType: {
        name: "cargoType",
        label: CargoType.prototype._.label,
        bid: bid?.cargoType?.value && new CargoType(bid.cargoType.value),
        offer: offer?.cargoType?.value && new CargoType(offer.cargoType.value),
      },
      cargoSize: {
        name: "cargoSize",
        label: CargoSize.prototype._.label,
        bid: bid?.cargoSize?.value && new CargoSize(bid.cargoSize.value),
        offer: offer?.cargoSize?.value && new CargoSize(offer.cargoSize.value),
      },
      coaCargoSize: {
        name: "coaCargoSize",
        label: COACargoSize.prototype._.label,
        bid: bid?.coaCargoSize?.value && new COACargoSize(bid.coaCargoSize.value),
        offer: offer?.coaCargoSize?.value && new COACargoSize(offer.coaCargoSize.value),
      },
      dischargeLocation: {
        name: "dischargeLocation",
        label: DischargeLocation.prototype._.label,
        bid: bid?.dischargeLocation?.value && new DischargeLocation(bid.dischargeLocation.value),
        offer: offer?.dischargeLocation?.value && new DischargeLocation(offer.dischargeLocation.value),
      },
      loadLocation: {
        name: "loadLocation",
        label: LoadLocation.prototype._.label,
        bid: bid?.loadLocation?.value && new LoadLocation(bid.loadLocation.value),
        offer: offer?.loadLocation?.value && new LoadLocation(offer.loadLocation.value),
      },
      deliveryLocation: {
        name: "deliveryLocation",
        label: DeliveryLocation.prototype._.label,
        bid: bid?.deliveryLocation?.value && new DeliveryLocation(bid.deliveryLocation.value),
        offer: offer?.deliveryLocation?.value && new DeliveryLocation(offer.deliveryLocation.value),
      },
      duration: {
        name: "duration",
        label: Duration.prototype._.label,
        bid: bid?.duration?.value && new Duration(bid.duration.value),
        offer: offer?.duration?.value && new Duration(offer.duration.value),
      },
      redeliveryLocation: {
        name: "redeliveryLocation",
        label: RedeliveryLocation.prototype._.label,
        bid: bid?.redeliveryLocation?.value && new RedeliveryLocation(bid.redeliveryLocation.value),
        offer: offer?.redeliveryLocation?.value && new RedeliveryLocation(offer.redeliveryLocation.value),
      },
      vesselSize: {
        name: "vesselSize",
        label: VesselSize.prototype._.label,
        bid: bid?.vesselSize?.value && new VesselSize(bid.vesselSize.value),
        offer: offer?.vesselSize?.value && new VesselSize(offer.vesselSize.value),
      },
      viaLocation: {
        name: "viaLocation",
        label: ViaLocation.prototype._.label,
        bid: bid?.viaLocation?.value && new ViaLocation(bid.viaLocation.value),
        offer: offer?.viaLocation?.value && new ViaLocation(offer.viaLocation.value),
      },
      freightRate: {
        name: "freightRate",
        label: FreightRate.prototype._.label,
        bid: bid?.freightRate?.value && new FreightRate(bid.freightRate.value),
        offer: offer?.freightRate?.value && new FreightRate(offer.freightRate.value),
      },
      demurrage: {
        name: "demurrage",
        label: Demurrage.prototype._.label,
        bid: bid?.demurrage?.value && new Demurrage(bid.demurrage.value),
        offer: offer?.demurrage?.value && new Demurrage(offer.demurrage.value),
      },
      hireRate: {
        name: "hireRate",
        label: HireRate.prototype._.label,
        bid: bid?.hireRate?.value && new HireRate(bid.hireRate.value),
        offer: offer?.hireRate?.value && new HireRate(offer.hireRate.value),
      },
      ballastBonus: {
        name: "ballastBonus",
        label: BallastBonus.prototype._.label,
        bid: bid?.ballastBonus?.value && new BallastBonus(bid.ballastBonus.value),
        offer: offer?.ballastBonus?.value && new BallastBonus(offer.ballastBonus.value),
      },
      operationalSubs: {
        name: "operationalSubs",
        label: OperationalSubs.prototype._.label,
        bid: bid?.operationalSubs?.value && new OperationalSubs(bid.operationalSubs.value),
        offer: offer?.operationalSubs?.value && new OperationalSubs(offer.operationalSubs.value),
      },
      commercialSubs: {
        name: "commercialSubs",
        label: CommercialSubs.prototype._.label,
        bid: bid?.commercialSubs?.value && new CommercialSubs(bid.commercialSubs.value),
        offer: offer?.commercialSubs?.value && new CommercialSubs(offer.commercialSubs.value),
      },
    } as any;

    if (shouldIncludeNewTctFields) {
      map.cleaningPrice = {
        name: "cleaningPrice",
        label: CleaningPrice.prototype._.label,
        bid: bid?.cleaningPrice?.value && new CleaningPrice(bid.cleaningPrice.value),
        offer: offer?.cleaningPrice?.value && new CleaningPrice(offer.cleaningPrice.value),
      };

      map.supplyPrice = {
        name: "supplyPrice",
        label: SupplyPrice.prototype._.label,
        bid: bid?.supplyPrice?.value && new SupplyPrice(bid.supplyPrice.value),
        offer: offer?.supplyPrice?.value && new SupplyPrice(offer.supplyPrice.value),
      };

      map.tradingExclusions = {
        name: "tradingExclusions",
        label: TradingExclusions.prototype._.label,
        bid: bid?.tradingExclusions?.value && new TradingExclusions(bid.tradingExclusions.value),
        offer: offer?.tradingExclusions?.value && new TradingExclusions(offer.tradingExclusions.value),
      };

      map.bunkerDelivery = {
        name: "bunkerDelivery",
        label: BunkerDelivery.prototype._.label,
        bid: bid?.bunkerDelivery?.value && new BunkerDelivery(bid.bunkerDelivery.value),
        offer: offer?.bunkerDelivery?.value && new BunkerDelivery(offer.bunkerDelivery.value),
      };

      map.bunkerRedelivery = {
        name: "bunkerRedelivery",
        label: BunkerRedelivery.prototype._.label,
        bid: bid?.bunkerRedelivery?.value && new BunkerRedelivery(bid.bunkerRedelivery.value),
        offer: offer?.bunkerRedelivery?.value && new BunkerRedelivery(offer.bunkerRedelivery.value),
      };

      map.cargoExclusionsText = {
        name: "cargoExclusionsText",
        label: CargoExclusionsText.prototype._.label,
        bid: bid?.cargoExclusionsText?.value && new CargoExclusionsText(bid.cargoExclusionsText.value),
        offer: offer?.cargoExclusionsText?.value && new CargoExclusionsText(offer.cargoExclusionsText.value),
      };
    }

    const array = Object.values(map) as any[];

    return getAbstractDetailArrayForDisplay(map, { detailSettings, negotiableFlag: true, type }).filter(isTruthy) as typeof array;
  }

  getFilledNonNegotiableFieldsForOfferKeysArray() {
    const { offer, chartererAccount, orderNotes, detailSettings, type } = this;

    const map = {
      chartererAccount,
      orderNotes,
      laycan: offer?.laycan,
      period: offer?.period,
      liftings: offer?.liftings,
      nominations: offer?.nominations,
      addressCommission: offer?.addressCommission,
      brokerCommission: offer?.brokerCommission,
      cargoType: offer?.cargoType,
      cargoSize: offer?.cargoSize,
      coaCargoSize: offer?.coaCargoSize,
      dischargeLocation: offer?.dischargeLocation,
      loadLocation: offer?.loadLocation,
      deliveryLocation: offer?.deliveryLocation,
      duration: offer?.duration,
      redeliveryLocation: offer?.redeliveryLocation,
      vesselSize: offer?.vesselSize,
      viaLocation: offer?.viaLocation,
      ballastBonus: offer?.ballastBonus,
      demurrage: offer?.demurrage,
      freightRate: offer?.freightRate,
      hireRate: offer?.hireRate,
      cleaningPrice: offer?.cleaningPrice,
      supplyPrice: offer?.supplyPrice,
      tradingExclusions: offer?.tradingExclusions,
      bunkerDelivery: offer?.bunkerDelivery,
      bunkerRedelivery: offer?.bunkerRedelivery,
      cargoExclusionsText: offer?.cargoExclusionsText,
      operationalSubs: offer?.operationalSubs,
      commercialSubs: offer?.commercialSubs,
    };

    const nonNegotionableDisplayValues = getAbstractDetailArrayForDisplay(map, { detailSettings, type, negotiableFlag: false });

    for (const key in map) {
      if (Object.prototype.hasOwnProperty.call(map, key) && typeof map[key] === "object") {
        map[key].keyName = key;
      }

      if (typeof map[key] === "string") {
        map[key] = { value: map[key], keyName: key };
      }
    }

    const keysForFilledNonNegotiableFieldsForOffer = nonNegotionableDisplayValues
      .filter((key) => {
        if (typeof key === "string") key = { value: key, keyName: "orderNotes" };

        return key?.keyName !== undefined;
      })
      .map((key) => (typeof key === "string" ? "orderNotes" : key.keyName));

    return keysForFilledNonNegotiableFieldsForOffer;
  }

  getFilledNegotiableFieldsForOfferKeysArray(negotiation: Negotiation, enableTcFields: boolean) {
    const negotiableBidAndOfferModelArray = negotiation.getNegotiableBidAndOfferModelArray(enableTcFields);
    const keysForFilledNegotiableFieldsForOffer = negotiableBidAndOfferModelArray.map((neg) => neg.name);

    return keysForFilledNegotiableFieldsForOffer;
  }

  getMainTermsDetailsModelMap() {
    const { mainTermsDetails } = this;

    return {
      notes: mainTermsDetails?.notes && new OrderNotes(mainTermsDetails.notes),
      chartererAccount: mainTermsDetails?.chartererAccount && new ChartererAccount(mainTermsDetails.chartererAccount),
      laycan: mainTermsDetails?.laycan && new Period(mainTermsDetails.laycan),
      period: mainTermsDetails?.period && new Period(mainTermsDetails.period),
      liftings: mainTermsDetails?.liftings && new Liftings(mainTermsDetails.liftings),
      nominations: mainTermsDetails?.nominations && new Nominations(mainTermsDetails.nominations),
      addressCommission: mainTermsDetails?.addressCommission && new AddressCommission(mainTermsDetails.addressCommission),
      brokerCommission: mainTermsDetails?.brokerCommission && new BrokerCommission(mainTermsDetails.brokerCommission),
      cargoType: mainTermsDetails?.cargoType && new CargoType(mainTermsDetails.cargoType),
      cargoSize: mainTermsDetails?.cargoSize && new CargoSize(mainTermsDetails.cargoSize),
      coaCargoSize: mainTermsDetails?.coaCargoSize && new COACargoSize(mainTermsDetails.coaCargoSize),
      dischargeLocation: mainTermsDetails?.dischargeLocation && new DischargeLocation(mainTermsDetails.dischargeLocation),
      loadLocation: mainTermsDetails?.loadLocation && new LoadLocation(mainTermsDetails.loadLocation),
      deliveryLocation: mainTermsDetails?.deliveryLocation && new DeliveryLocation(mainTermsDetails.deliveryLocation),
      duration: mainTermsDetails?.duration && new Duration(mainTermsDetails.duration),
      redeliveryLocation: mainTermsDetails?.redeliveryLocation && new RedeliveryLocation(mainTermsDetails.redeliveryLocation),
      vesselSize: mainTermsDetails?.vesselSize && new VesselSize(mainTermsDetails.vesselSize),
      viaLocation: mainTermsDetails?.viaLocation && new ViaLocation(mainTermsDetails.viaLocation),
      ballastBonus: mainTermsDetails?.ballastBonus && new BallastBonus(mainTermsDetails.ballastBonus),
      demurrage: mainTermsDetails?.demurrage && new Demurrage(mainTermsDetails.demurrage),
      freightRate: mainTermsDetails?.freightRate && new FreightRate(mainTermsDetails.freightRate),
      hireRate: mainTermsDetails?.hireRate && new HireRate(mainTermsDetails.hireRate),
      cleaningPrice: mainTermsDetails?.cleaningPrice && new CleaningPrice(mainTermsDetails.cleaningPrice),
      supplyPrice: mainTermsDetails?.supplyPrice && new SupplyPrice(mainTermsDetails.supplyPrice),
      tradingExclusions: mainTermsDetails?.tradingExclusions && new TradingExclusions(mainTermsDetails.tradingExclusions),
      bunkerDelivery: mainTermsDetails?.bunkerDelivery && new BunkerDelivery(mainTermsDetails.bunkerDelivery),
      bunkerRedelivery: mainTermsDetails?.bunkerRedelivery && new BunkerRedelivery(mainTermsDetails.bunkerRedelivery),
      cargoExclusionsText: mainTermsDetails?.cargoExclusionsText && new CargoExclusionsText(mainTermsDetails.cargoExclusionsText),
      operationalSubs: mainTermsDetails?.operationalSubs && new OperationalSubs(mainTermsDetails.operationalSubs),
      commercialSubs: mainTermsDetails?.commercialSubs && new CommercialSubs(mainTermsDetails.commercialSubs),
      vessels: mainTermsDetails?.vessels && new Vessels(mainTermsDetails.vessels),
    };
  }

  getMainTermsDetailsModelArray() {
    const { mainTermsDetails, type } = this;

    if (!mainTermsDetails) return;

    return getDetailArrayForDisplay(mainTermsDetails, { type });
  }

  getBidOfferFormValues(suppressVessels?: boolean) {
    const { bid, offer } = this;

    return new UniversalOrderNegotiationFormValues({
      vessels: !suppressVessels && this.vessels ? new Vessels(this?.vessels) : undefined,
      account: this?.owningCompany && new OwnerAccount(this?.owningCompany),

      laycan0: bid?.laycan && new Period(bid?.laycan?.value),
      period0: bid?.period && new Period(bid?.period?.value),
      liftings0: bid?.liftings && new Liftings(bid?.liftings?.value),
      nominations0: bid?.nominations && new Nominations(bid?.nominations?.value),
      addressCommission0: bid?.addressCommission && new AddressCommission(bid?.addressCommission?.value),
      brokerCommission0: bid?.brokerCommission && new BrokerCommission(bid?.brokerCommission?.value),
      cargoType0: bid?.cargoType && new CargoType(bid?.cargoType?.value),
      cargoSize0: bid?.cargoSize && new CargoSize(bid?.cargoSize?.value),
      coaCargoSize0: bid?.coaCargoSize && new COACargoSize(bid?.coaCargoSize?.value),
      dischargeLocation0: bid?.dischargeLocation && new DischargeLocation(bid?.dischargeLocation?.value),
      loadLocation0: bid?.loadLocation && new LoadLocation(bid?.loadLocation?.value),
      deliveryLocation0: bid?.deliveryLocation && new DeliveryLocation(bid?.deliveryLocation?.value),
      duration0: bid?.duration && new Duration(bid?.duration?.value),
      redeliveryLocation0: bid?.redeliveryLocation && new RedeliveryLocation(bid?.redeliveryLocation?.value),
      vesselSize0: bid?.vesselSize && new VesselSize(bid?.vesselSize?.value),
      viaLocation0: bid?.viaLocation && new ViaLocation(bid?.viaLocation?.value),
      ballastBonus0: bid?.ballastBonus && new BallastBonus(bid?.ballastBonus?.value),
      demurrage0: bid?.demurrage && new Demurrage(bid?.demurrage?.value),
      freightRate0: bid?.freightRate && new FreightRate(bid?.freightRate?.value),
      hireRate0: bid?.hireRate && new HireRate(bid?.hireRate?.value),
      cleaningPrice0: bid?.cleaningPrice && new CleaningPrice(bid?.cleaningPrice?.value),
      supplyPrice0: bid?.supplyPrice && new SupplyPrice(bid?.supplyPrice?.value),
      tradingExclusions0: bid?.tradingExclusions && new TradingExclusions(bid?.tradingExclusions?.value),
      bunkerDelivery0: bid?.bunkerDelivery && new BunkerDelivery(bid?.bunkerDelivery?.value),
      bunkerRedelivery0: bid?.bunkerRedelivery && new BunkerRedelivery(bid?.bunkerRedelivery?.value),
      cargoExclusionsText0: bid?.cargoExclusionsText && new CargoExclusionsText(bid?.cargoExclusionsText?.value),
      operationalSubs0: bid?.operationalSubs && new OperationalSubs(bid?.operationalSubs?.value),
      commercialSubs0: bid?.commercialSubs && new CommercialSubs(bid?.commercialSubs?.value),

      laycan: offer?.laycan && new Period(offer?.laycan?.value),
      period: offer?.period && new Period(offer?.period?.value),
      liftings: offer?.liftings && new Liftings(offer?.liftings?.value),
      nominations: offer?.nominations && new Nominations(offer?.nominations?.value),
      addressCommission: offer?.addressCommission && new AddressCommission(offer?.addressCommission?.value),
      brokerCommission: offer?.brokerCommission && new BrokerCommission(offer?.brokerCommission?.value),
      cargoType: offer?.cargoType && new CargoType(offer?.cargoType?.value),
      cargoSize: offer?.cargoSize && new CargoSize(offer?.cargoSize?.value),
      coaCargoSize: offer?.coaCargoSize && new COACargoSize(offer?.coaCargoSize?.value),
      dischargeLocation: offer?.dischargeLocation && new DischargeLocation(offer?.dischargeLocation?.value),
      loadLocation: offer?.loadLocation && new LoadLocation(offer?.loadLocation?.value),
      deliveryLocation: offer?.deliveryLocation && new DeliveryLocation(offer?.deliveryLocation?.value),
      duration: offer?.duration && new Duration(offer?.duration?.value),
      redeliveryLocation: offer?.redeliveryLocation && new RedeliveryLocation(offer?.redeliveryLocation?.value),
      vesselSize: offer?.vesselSize && new VesselSize(offer?.vesselSize?.value),
      viaLocation: offer?.viaLocation && new ViaLocation(offer?.viaLocation?.value),
      ballastBonus: offer?.ballastBonus && new BallastBonus(offer?.ballastBonus?.value),
      demurrage: offer?.demurrage && new Demurrage(offer?.demurrage?.value),
      freightRate: offer?.freightRate && new FreightRate(offer?.freightRate?.value),
      hireRate: offer?.hireRate && new HireRate(offer?.hireRate?.value),
      cleaningPrice: offer?.cleaningPrice && new CleaningPrice(offer?.cleaningPrice?.value),
      supplyPrice: offer?.supplyPrice && new SupplyPrice(offer?.supplyPrice?.value),
      tradingExclusions: offer?.tradingExclusions && new TradingExclusions(offer?.tradingExclusions?.value),
      bunkerDelivery: offer?.bunkerDelivery && new BunkerDelivery(offer?.bunkerDelivery?.value),
      bunkerRedelivery: offer?.bunkerRedelivery && new BunkerRedelivery(offer?.bunkerRedelivery?.value),
      cargoExclusionsText: offer?.cargoExclusionsText && new CargoExclusionsText(offer?.cargoExclusionsText?.value),
      operationalSubs: offer?.operationalSubs && new OperationalSubs(offer?.operationalSubs?.value),
      commercialSubs: offer?.commercialSubs && new CommercialSubs(offer?.commercialSubs?.value),
    });
  }

  getOwnerFormStuff() {
    const { detailSettings, type } = this;

    const negotiableBidReadOnlyElements = getReadOnlyElementArrayForDisplay("0", { detailSettings, type });
    const negotiableOfferReadOnlyElements = getReadOnlyElementArrayForDisplay("", { detailSettings, type });

    const titledRows = getTitledRowElementArrayForDisplay(
      {
        suffix0: "0",
        suffix1: "",
        label0: "Charterer's Indication",
        label1: "Your Indication",
        readOnly0: true,
        readOnly1: false,
      },
      { detailSettings, type, negotiableFlag: true }
    );

    return {
      negotiableBidReadOnlyElements,
      negotiableOfferReadOnlyElements,
      elements: [
        { type: "TitledRow", title: "Owning Group", className: "owning-group", children: ["account"] },
        ...titledRows,
        {
          type: "TitledRow",
          title: Vessels.prototype._.label,
          className: "owner-vessels",
          children: [{ name: "vessels" }],
        },
      ],
    };
  }

  getBrokerChartererOfferFormStuff() {
    const { detailSettings, type } = this;

    const negotiableBidReadOnlyElements = getReadOnlyElementArrayForDisplay("0", { detailSettings, type });
    const negotiableOfferReadOnlyElements = getReadOnlyElementArrayForDisplay("", { detailSettings, type });

    const titledRows = getTitledRowElementArrayForDisplay(
      {
        suffix0: "0",
        suffix1: "",
        label0: "Charterer's Indication",
        label1: "Owner's Indication",
        readOnly0: true,
        readOnly1: false,
      },
      { detailSettings, type, negotiableFlag: true }
    );

    return {
      negotiableBidReadOnlyElements,
      negotiableOfferReadOnlyElements,
      elements: titledRows,
    };
  }

  getBrokerChartererBidFormStuff() {
    const { detailSettings, type } = this;

    const negotiableBidReadOnlyElements = getReadOnlyElementArrayForDisplay("0", { detailSettings, type });
    const negotiableOfferReadOnlyElements = getReadOnlyElementArrayForDisplay("", { detailSettings, type });

    const titledRows = getTitledRowElementArrayForDisplay(
      {
        suffix0: "0",
        suffix1: "",
        label0: "Charterer's Indication",
        label1: "Owner's Indication",
        readOnly0: false,
        readOnly1: true,
      },
      { detailSettings, type, negotiableFlag: true }
    );

    return {
      negotiableBidReadOnlyElements,
      negotiableOfferReadOnlyElements,
      elements: titledRows,
    };
  }

  getInvitationEmail() {
    const { circulatedBy, invitee, offerRepToken, orderId, id: negotiationId, inviteeSystemUserId } = this;

    if (!offerRepToken) return "";

    const offerRepUrl = router.get("offerrep").compile({ offerRepToken });
    const ownerDashboardUrl = `${router.get("owner").compile()}/${orderId}/${negotiationId}`;
    const href = `${window.location.origin}${inviteeSystemUserId ? ownerDashboardUrl : offerRepUrl}`;

    const subject = "Sea/trade: Invitation to Offer";
    const seaTradeUserTermsLink = "https://www.sea.live/security-policy";
    let disclaimer =
      'Any reference to "Order" within Sea/trade shall refer to an invitation which is non-binding and subject to contract and terms and conditions. Any reference to "Offer" or "Indication" shall be subject to contract and terms and conditions.\n';
    disclaimer += `Sea/trade's User Terms ${seaTradeUserTermsLink} shall apply to your use of this email and its contents and shall apply to your access to and use of Sea/trade, including its website and services.`;

    let summary = "";

    const nonNegotiableInitialTermModelArray = this.getNonNegotiableInitialTermModelArray();

    for (let i = 0; i < nonNegotiableInitialTermModelArray.length; i++) {
      const model = nonNegotiableInitialTermModelArray[i];

      if (!model) continue;

      summary += `${model._.label}: ${model.toGridView()}`;
      summary += "\n";
    }

    const defaultSummaryMessage = "TO SEE THE ORDER DETAILS, PLEASE CLICK ON THE LINK ABOVE";
    const summaryOrMessage = summary.length > 600 ? defaultSummaryMessage : summary;

    let body = "Hello,\n\n";
    body += `${circulatedBy} has invited you to offer on the below Order. Please click on the link below (or copy and paste it into your browser) to make an Offer within Sea/trade:\n\n`;
    body += `${href}\n\n`;
    body += "+++ Order Details +++\n\n";
    body += `${summaryOrMessage}\n`;
    body += "***\n\n";
    body += `${disclaimer}`;

    return createEmailHref(`${invitee}`, subject, body);
  }

  isLifting() {
    return this.type === "Lft";
  }

  getOrderNegotiationStoreFixedCOANegotiationModel() {
    const orderNegotiationStore = this._.orderNegotiationStore;
    const orderModel = orderNegotiationStore?.upsertOrderModel({ id: this.orderId });
    const fixedCoaNegotiation = orderNegotiationStore?.negotiationArray.find((n) => n.type === "Coa" && n.status === "Fixed");

    if (!fixedCoaNegotiation) return;

    const fixedCoaNegotiationModel = orderModel?.upsertNegotiation(fixedCoaNegotiation);

    return fixedCoaNegotiationModel;
  }

  async getData() {
    if (!this.orderId || !this.id) return;

    const res = await tradeAPI.getNegotiation(this.orderId, this.id);

    if (res.ok && res.data) this.update(res.data);

    return res;
  }

  async getDataViaOfferrep() {
    if (!this.offerRepToken) return;

    const res = await tradeAPI.getNegotiationViaOfferrep(this.offerRepToken);

    if (res.ok && res.data) this.update(res.data);

    return res;
  }

  async postNegotiationMainTerms(data) {
    if (!this.orderId || !this.id) return;

    if (!data.details.vessels.vessels?.length) {
      data.details.vessels.vessels = data.details.vessels.data;
    }

    const res = await tradeAPI.postNegotiationMainTerms(this.orderId, this.id, data);

    return res;
  }

  async postVessel(data) {
    if (!this.orderId || !this.id) return;

    data.updateToken = this.updateToken;

    const res = await tradeAPI.postNegotiationVessel(this.orderId, this.id, data);

    return res;
  }

  async postUpdateVessel(data) {
    if (!this.orderId || !this.id) return;

    data = {
      action: "update",
      details: data,
      vesselIMO: data.vesselIMO,
      updateToken: this.updateToken,
    };

    const res = await tradeAPI.postNegotiationVesselAction(this.orderId, this.id, data);

    return res;
  }

  async postAcceptVessel(data) {
    if (!this.orderId || !this.id) return;

    data = {
      action: "accept",
      vesselIMO: data.vesselIMO,
      updateToken: this?.updateToken,
    };

    const res = await tradeAPI.postNegotiationVesselAction(this.orderId, this.id, data);

    return res;
  }

  async postRejectVessel(data) {
    if (!this.orderId || !this.id) return;

    data = {
      vesselIMO: data.vesselIMO,
      action: "reject",
      updateToken: this?.updateToken,
    };

    const res = await tradeAPI.postNegotiationVesselAction(this.orderId, this.id, data);

    return res;
  }

  async postBid(data) {
    if (!this.orderId || !this.id) return;

    const res = await tradeAPI.postNegotiationBid(this.orderId, this.id, data);

    return res;
  }

  async postOffer(data) {
    if (!this.orderId || !this.id) return;

    const res = await tradeAPI.postNegotiationOffer(this.orderId, this.id, data);

    return res;
  }

  async postOfferAll(data) {
    if (!this.orderId || !this.id) return;

    const res = await tradeAPI.postNegotiationOfferAll(this.orderId, this.id, data);

    return res;
  }

  async postPublish(publishType: "published" | "revealedToOwner") {
    if (!this.orderId || !this.id || !this.updateToken) return;

    const data = {
      action: publishType,
      updateToken: this.updateToken,
    };

    return tradeAPI.postNegotiationPublish(this.orderId, this.id, data);
  }

  async getArcData() {
    if (!this.arcNegotiationHash) return;

    const res = await tradeAPI.getArcNegotiation(this.arcNegotiationHash);

    return res;
  }

  async postArcSendRecap() {
    if (!this.arcNegotiationHash) return;

    const res = await tradeAPI.postArcNegotiationRecap(this.arcNegotiationHash);

    return res;
  }

  async postArcSubsLift(data) {
    if (!this.arcNegotiationHash) return;

    const res = await tradeAPI.postArcNegotiationSubsLift(this.arcNegotiationHash, data);

    return res;
  }

  async postArcSubsLiftAndFix(data) {
    if (!this.arcNegotiationHash) return;

    const res = await tradeAPI.postArcNegotiationSubsLiftAndFix(this.arcNegotiationHash, data);

    return res;
  }

  async postArcSubsFail(data) {
    if (!this.arcNegotiationHash || !this.updateToken) return;

    const res = await tradeAPI.postArcNegotiationSubsFail(this.arcNegotiationHash, data);

    return res;
  }

  async putOfferViaOfferrep(data) {
    if (!this.offerRepToken) return;

    const res = await tradeAPI.putNegotiationOfferViaOfferrep(this.offerRepToken, data);

    return res;
  }

  async postOwningCompany(data) {
    if (!this.orderId || !this.id) return;

    data.updateToken = this.updateToken;

    const res = await tradeAPI.postNegotiationOwningCompany(this.orderId, this.id, data);

    return res;
  }

  async putNote(data) {
    if (!this.orderId || !this.id) return;

    data.updateToken = this.updateToken;

    const res = await tradeAPI.putNegotiationNote(this.orderId, this.id, data);

    return res;
  }

  async deleteNegotiation() {
    if (!this.orderId || !this.id || !this.updateToken) return;

    const data = { updateToken: this.updateToken };

    const res = await tradeAPI.deleteNegotiation(this.orderId, this.id, data);

    return res;
  }
}

function isBeyondInitialTerms(data: PartialData) {
  if (!data.status) return false;

  return !INITIAL_TERMS_STATUS[data.status];
}

class Props extends DataModelProps<Data> {
  version = 0;
  status = {} as Status;
}

Props.prototype.stageMetadataMap = {
  Inactive: { value: "Inactive", label: "New" },
  Active: { value: "Active", label: "Active" },
  FirmOffer: { value: "FirmOffer", label: "Firm Offer" },
  FirmBid: { value: "FirmBid", label: "Firm Bid" },
  Firm: { value: "Firm", label: "Firm" },
  TermsLoading: { value: "TermsLoading", label: "Terms Loading" },
  MainTerms: { value: "MainTerms", label: "Main Terms" },
  OnSubs: { value: "OnSubs", label: "On Subs" },
  SubsLifted: { value: "SubsLifted", label: "Subjects Lifted" },
  SubsFailed: { value: "SubsFailed", label: "Subjects Failed" },
  Fixed: { value: "Fixed", label: "Fixed" },
  Nomination: { value: "Nomination", label: "Nomination" },
  Confirmed: { value: "Confirmed", label: "Confirmed" },
  Withdrawn: { value: "Withdrawn", label: "Withdrawn" },
};

Negotiation.prototype.Props = Props;
Negotiation.prototype._ = new Props();

const INITIAL_TERMS_STATUS = {
  Inactive: true,
  Active: true,
  Firm: true,
};

const DEFAULT_SUBJECT_TERMSET = {
  content: {
    mainTermTemplates: [
      {
        title: "Operational Subs",
        TermTemplateType: "Operational",
        cpmProformaKey: undefined,
        content: "",
        termId: "OPERATIONAL_SUBS",
      },
      {
        title: "Commercial Subs",
        TermTemplateType: "Commercial",
        cpmProformaKey: undefined,
        content: "",
        termId: "COMMERCIAL_SUBS",
      },
    ],
  },
};

const defaultUpdateConfig = {
  shouldUpdateOrderNegotiationStore: true,
} as UpdateConfig;

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

export interface Negotiation extends Data {
  _: Props;
}

type PartialProps = Partial<Props>;

interface Props {
  order: Order;
  orderNegotiationStore?: OrderNegotiationStore;
  orderNegotiationStoreNegotiation?: OrderNegotiationStore["Negotiation"];
  stageMetadataMap: Record<Stage, StageMetadata>;
}

type Stage =
  | "Inactive"
  | "Active"
  | "FirmOffer"
  | "FirmBid"
  | "Firm"
  | "TermsLoading"
  | "MainTerms"
  | "OnSubs"
  | "SubsLifted"
  | "SubsFailed"
  | "Fixed"
  | "Nomination"
  | "Withdrawn"
  | "Confirmed";

type PartialData = DeepPartial<Data>;
type Data = TradeAPI["Negotiation"];

type UpdateConfig = {
  shouldUpdateOrderNegotiationStore?: boolean;
  forceUpdate?: boolean;
};

interface StageMetadata {
  value: Stage;
  label: string;
}
