import { IDistributionListViewModel, ICompany, IDeskView } from "../Models/IDetails";
import { IMapper, withNullable } from "./map";
import { IRecipient } from "../../api/orders/models/IDistributionList";
import { mapDistribution } from "./mapDistribution";
import { groupBy, head } from "lodash-es";

const map: IMapper<IRecipient[], IDistributionListViewModel> = {
  toApi: (distribution: IDistributionListViewModel): IRecipient[] => {
    const companyDeskRecipients = distribution.companies.flatMap((company) =>
      company.desks.flatMap((desk) =>
        desk.recipients.map(
          (recipient) =>
            ({
              ...mapDistribution.toApi(recipient),
              additionalData: {
                isDeskOpen: desk.isDeskOpen,
                disableDesk: desk.isDeskDisabled,
                disableUserRow: recipient.disableUserRow,
                groupEmailAddress: recipient.groupEmailAddress,
              },
            } as IRecipient)
        )
      )
    );
    return [...distribution.recipients.map(mapDistribution.toApi), ...companyDeskRecipients];
  },
  toView: (recipients: IRecipient[]): IDistributionListViewModel => {
    const groupByDesk = (recipientsToGroup: IRecipient[]): IDeskView[] => {
      const groupedDesks = groupBy(recipientsToGroup, (searchUser: IRecipient) => searchUser.knownUser?.desk.id);
      return Object.keys(groupedDesks).map((deskId) => {
        const firstRecipientKnownUser = head(groupedDesks[deskId])?.knownUser;
        if (!firstRecipientKnownUser)
          throw new Error(`No company name found in recipent ${JSON.stringify(firstRecipientKnownUser)}`);
        const deskName = firstRecipientKnownUser.desk.name;
        return {
          deskId,
          deskName,
          isDeskOpen: recipientsToGroup.some((recipient) => recipient.additionalData?.isDeskOpen),
          isDeskDisabled: recipientsToGroup.some((recipient) => recipient.additionalData?.disableDesk),
          companyName: firstRecipientKnownUser.company.name,
          companyId: firstRecipientKnownUser.company.id,
          recipients: groupedDesks[deskId].map(mapDistribution.toView),
        } as IDeskView;
      });
    };
    const groupByCompany = (recipientsToGroup: IRecipient[]): ICompany[] => {
      const groupedCompanies = groupBy(recipientsToGroup, (searchUser: IRecipient) => searchUser.knownUser?.company.id);
      return Object.keys(groupedCompanies).map((companyId) => {
        const firstRecipient = head(groupedCompanies[companyId]);
        const companyName = firstRecipient?.knownUser?.company.name;
        if (!companyName) throw new Error(`No company name found in recipent ${JSON.stringify(firstRecipient)}`);
        const desks = groupByDesk(groupedCompanies[companyId]);
        return {
          desks,
          companyName,
          companyId: companyId,
          hasPendingRecipients: desks.some((d) => d.recipients.some((r) => r.status === "pending")),
        };
      });
    };

    return {
      recipients: recipients.filter((recipient) => !recipient.knownUser).map(mapDistribution.toView),
      companies: groupByCompany(recipients.filter((recipient) => !!recipient.knownUser)),
    };
  },
  emptyViewModel: {
    recipients: [],
    companies: [],
  },
};

export const mapRecipientList = withNullable(map);
