import { useContext, useState } from "react";
import { MinimalConfigurationContext } from "__legacy/sharedFolder/MinimalConfigurationContext";
import { OwnerDashboardApi } from "../api/OwnerDashboardApi";
import { LiftingsUIStatus } from "./liftingUIStatusReducer";
import { IOfferRepViewNegotiation } from "negotiations/models/common/IOfferRep";

export function useRequestChanges(negotiation: IOfferRepViewNegotiation) {
  const config = useContext(MinimalConfigurationContext);
  const [liftingsState, setLiftingsState] = useState<LiftingsUIStatus>("None");
  const [notes, setNotes] = useState("");
  const sendRequestChanges = async () => {
    setLiftingsState("RequestInProgress");
    const result = await OwnerDashboardApi(config.ctradeUrl, negotiation.updateToken).requestChanges(
      negotiation.orderId,
      negotiation.id,
      notes
    );

    if (result.ok) {
      setLiftingsState("ChangesRequestedSuccessful");
    }
    // TODO: Deal with error
  };

  return {
    sendButtonEnabled: notes.length > 0 && liftingsState !== "RequestInProgress",
    sendRequestChanges,
    liftingsState,
    notes,
    setNotes,
  };
}
