import { OrderNegotiationStore } from "../OrderNegotiationStore";

class COAOrderNegotiationArchiveStore extends OrderNegotiationStore {
  supportedOrderType = {
    Coa: true,
  };

  orderDataQuery = new URLSearchParams({ type: "coa" });
}

export const coaOrderNegotiationArchiveStore = new COAOrderNegotiationArchiveStore();
