import { LABEL_MAP } from "___REFACTOR___/constants";
import { Commission, CommissionProps } from "../Commission";

class AddressCommission extends Commission {
  static label = LABEL_MAP.addressCommission;
}

class AddressCommissionProps extends CommissionProps {
  get label() {
    return LABEL_MAP.addressCommission;
  }
}

AddressCommission.prototype.Props = AddressCommissionProps;

export { AddressCommission, AddressCommissionProps };
