import React from "react";
import { Negotiation } from "@/models";
import { DistributionListIndividual, DistributionStretchColumn } from "./DistributionListIndividual";
import styles from "./DistributionList.module.scss";
import { IDistributionViewModel, IDistributionListViewModel, IDeskView, ICompany } from "sharedFolder/Models/IDetails";
import { DesksMoreOrLessChevronButton } from "./DesksMoreOrLessChevronButton";

export const DistributionList = (props: { icon: "add" | "delete"; distributions: IDistributionListViewModel }) => {
  const { recipients, companies } = props.distributions;

  const hasUsers = Boolean(companies.length + recipients.length);

  recipients.sort(function (a, b) {
    const statusSort = [undefined, null, "pending", "success", "fail"];
    return a.status !== b.status
      ? statusSort.indexOf(a.status) - statusSort.indexOf(b.status)
      : a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: "base" });
  });

  companies.sort(function (a, b) {
    const result =
      a.hasPendingRecipients !== b.hasPendingRecipients
        ? a.hasPendingRecipients > b.hasPendingRecipients
          ? -1
          : 1
        : a.companyName.localeCompare(b.companyName);
    return result;
  });

  const numberOfDeskSectionsToDisplay = companies.flatMap((company) => company.desks).length;
  let currentIndex = 0;

  const companiesSection = companies.map((company: ICompany) => (
    <React.Fragment key={company.companyId}>
      <h2 className={styles.header}>{company.companyName}</h2>
      {company.desks.map((desk: IDeskView) => {
        currentIndex++;
        return (
          <React.Fragment key={desk.deskId}>
            <DesksMoreOrLessChevronButton
              closeText={`${desk.deskName} (${desk.recipients.length})`}
              openText={`${desk.deskName}  (${desk.recipients.length})`}
              desk={desk}
              showAction={props.icon}
            >
              <>
                {desk.recipients.map((distributionModel: IDistributionViewModel) => (
                  <DistributionListIndividual
                    user={distributionModel}
                    deleteOrAddMode={props.icon}
                    key={distributionModel.email}
                  />
                ))}
              </>
            </DesksMoreOrLessChevronButton>
            {currentIndex !== numberOfDeskSectionsToDisplay && <DistributionStretchColumn />}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  ));

  const unregisteredRecipients = recipients
    .filter((recipient: IDistributionViewModel) => !Negotiation.prototype.isAbsentOwnerEmail(recipient.email))
    .map((recipient: IDistributionViewModel) => {
      return <DistributionListIndividual user={recipient} deleteOrAddMode={props.icon} key={recipient.email} />;
    });

  const absentOwners = recipients
    .filter((recipient: IDistributionViewModel) => Negotiation.prototype.isAbsentOwnerEmail(recipient.email))
    .map((recipient: IDistributionViewModel) => {
      return <DistributionListIndividual user={recipient} deleteOrAddMode={props.icon} key={recipient.email} />;
    });

  const unregisteredOwnersSection = (
    <>
      <h2 className={`${styles.header} ${styles.unregisteredOwnerHeader}`}>Unregistered Owners</h2>
      {unregisteredRecipients}
    </>
  );

  const absentOwnersSection = (
    <>
      <h2 className={`${styles.header} ${styles.absentOwnerHeader}`}>Absent Owners</h2>
      {absentOwners}
    </>
  );

  const sections = [
    companiesSection,
    unregisteredRecipients.length ? unregisteredOwnersSection : null,
    absentOwners.length ? absentOwnersSection : null,
  ];

  if (hasUsers)
    return (
      <div className={styles.grid} data-test={`grid-${props.icon === "add" ? "search" : "saved"}`}>
        {sections}
      </div>
    );
  return null;
};
