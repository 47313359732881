import { Negotiation, dialog } from "@/models";
import { Context } from "../Orders";

export async function getNegotiation(model: Negotiation | undefined, context: Context, suppressFetchStatus?: boolean) {
  if (!model) {
    console.error("getNegotiation:", { model, context });

    throw new Error(`getNegotiation: Negotiation model is undefined`);
  }

  if (!suppressFetchStatus) {
    model._.status.loading = true;
    model._.status.message = "Loading";
  }

  const res = await model.getData();

  if (!res?.ok) {
    model._.status.type = "error";
    model._.status.title = `${context.view.order.name} Negotiation Fetch Failure`;
    model._.status.dump = { res };

    dialog.show({
      status: model._.status,
      dataTest: `${context.dataTest}-negotiation-fetch-fail`,
    });

    model._.status.loading = false;
    model._.status.message = null;

    return res;
  }

  model._.status.loading = false;
  model._.status.message = null;
}
