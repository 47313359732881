import { IUserOrderPreferences, sortKey, IUserSettings } from "../Models/userSettings";

/* Response from a call to getting the sortfilter user settings */
interface IApiSortFilterSetting {
  updateToken: string;
  id: string;
  orderId: string;
  filter: string;
  sort: string;
}

interface IApiSortFilterResponse {
  updateToken: string;
  sortFilterSettings: IApiSortFilterSetting[];
}

const nameMapping = {
  "Exact Laycan": "isExactLaycan",
  Active: "active",
};

function mapApiUserOrderPreferences(apiResponse: IApiSortFilterSetting[]): IUserOrderPreferences[] {
  return apiResponse.map((item: IApiSortFilterSetting) => {
    const filters = item.filter.split(",");

    let sortBy;
    switch (item.sort) {
      case "laycan":
        sortBy = sortKey.laycan;
        break;
      case "createdDate":
        sortBy = sortKey.createdDate;
        break;
      case "updatedDate":
        sortBy = sortKey.updatedDate;
        break;
      case "invitee":
        sortBy = sortKey.invitee;
        break;
      case "bestOffer":
        sortBy = sortKey.bestOffer;
        break;
      default:
        sortBy = sortKey.bestOffer;
    }

    return {
      id: item.id,
      filters: [
        {
          name: "Exact Laycan",
          selected: filters.indexOf("isExactLaycan") > -1,
        },
        { name: "Active", selected: filters.indexOf("active") > -1 },
      ],
      sort: sortBy,
      orderId: item.orderId,
    };
  });
}

export function loadUserOrderPreferences(baseUrl: string): Promise<{ success: boolean; data: IUserSettings }> {
  return fetch(`${baseUrl}/settings/sortfilters`)
    .then((response) => response.json())
    .then((apiData: IApiSortFilterResponse) => {
      return {
        success: true,
        data: {
          updateToken: apiData.updateToken,
          settings: mapApiUserOrderPreferences(apiData.sortFilterSettings),
        },
      };
    })
    .catch(() => {
      return {
        success: false,
        data: {
          updateToken: "",
          settings: [],
        },
      };
    });
}

export async function createUserOrderPreference(baseUrl: string, setting: IUserOrderPreferences): Promise<string> {
  const selectedItems = setting.filters
    .filter((item) => item.selected === true)
    .map((selectedItem) => nameMapping[selectedItem.name as keyof typeof nameMapping]);

  const body = {
    orderId: setting.orderId,
    filter: selectedItems.join(","),
    sort: sortKey[setting.sort],
  };

  const url = `${baseUrl}/settings/sortfilters`;

  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
    throw Error("Unable to create user setting. " + response.statusText);
  }
  const data = await response.json();
  return data.id;
}

export function updateUserOrderPreference(baseUrl: string, setting: IUserOrderPreferences, updateToken: string) {
  const selectedItems = setting.filters
    .filter((item) => item.selected === true)
    .map((selectedItem) => nameMapping[selectedItem.name as keyof typeof nameMapping]);

  const body = {
    orderId: setting.orderId,
    filter: selectedItems.join(","),
    sort: sortKey[setting.sort],
    updateToken: updateToken,
  };

  const url = `${baseUrl}/settings/sortfilters/${setting.id}`;

  return fetch(url, {
    method: "PUT",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
    },
  });
}
