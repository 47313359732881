import React, { useState } from "react";
import { IOrder } from "orders/models/IOrder";
import LoadingSpinner from "sharedFolder/components/common/LoadingSpinner/LoadingSpinner";
import { OrderAttachments as OrderAttachmentsShared } from "__legacy/sharedFolder/components/common/Attachments/OrderAttachments";
import { NegotiationAttachments } from "__legacy/sharedFolder/components/common/Attachments/NegotiationAttachments";
import {
  deleteOrderAttachment,
  downloadOrderAttachment,
  uploadOrderAttachment,
} from "__legacy/negotiations/services/attachments";
import { Button } from "@/components";
import { auth } from "@/models";
import "./OrderAttachments.scss";

interface IProps {
  ctradeUrl: string;
  order?: IOrder;
  orderId: string;
}

export const OrderAttachments = (props: IProps) => {
  const { order, ctradeUrl } = props;
  const { user } = auth.trade as any;
  const canAddOrDelete = !user.CompanyRoles.includes("broker");
  const [orderAttachmentsVisible, setOrderAttachmentsVisible] = useState(false);

  if (!order)
    return (
      <div className="order-attachments">
        <LoadingSpinner></LoadingSpinner>
      </div>
    );
  return (
    <div className="order-attachments">
      <div className="order-attachments-header">
        <h2>Order Attachments</h2>
        {canAddOrDelete && (
          <Button
            variant="flat"
            onClick={() => setOrderAttachmentsVisible(!orderAttachmentsVisible)}
            icon="attach-file"
            dataTest="upload-new-attachment"
          >
            Upload New Attachment
          </Button>
        )}
      </div>
      <OrderAttachmentsShared
        attachments={order.orderAttachments}
        onDelete={(fileId: string) => deleteOrderAttachment(ctradeUrl, order.id, fileId, order.updateToken)}
        onUpload={(formData: FormData) => uploadOrderAttachment(order.id, ctradeUrl)(formData, order.updateToken)}
        onDownload={(fileId: string, fileName: string) => downloadOrderAttachment(order.id)(fileId, fileName, order.updateToken)}
        fileDropAreaVisible={orderAttachmentsVisible}
        userRoles={user.CompanyRoles}
      />
      <div className="order-attachments-header">
        <h2>Negotiations Attachments</h2>
      </div>
      <NegotiationAttachments
        negotiationAttachments={order.negsAttachments}
        orderId={order.id}
        updateToken={order.updateToken}
        emptyText="There are currently no files attached to negotiations in this order"
      />
    </div>
  );
};
