import React, { useState } from "react";
import { Observable } from "rxjs";
import classNames from "classnames";
import moment from "moment";
import { Modal } from "office-ui-fabric-react";
import { CompanyRole } from "sharedFolder/Models/CompanyRole";
import { ConfirmationDialog } from "sharedFolder/components/common/ConfirmationDialog/ConfirmationDialog";
import { IAttachment } from "__legacy/sharedFolder/Models/IAttachment";
import { formatDateWithTime } from "sharedFolder/Utilities/getNow";
import { IAttachmentErrors } from "__legacy/sharedFolder/components/common/interfaces";
import { formatFileSize, getFileExtension, getFileIcon, shortenFilename } from "./attachmentUtilites";
import { Button, Icon } from "@/components";
import { auth } from "@/models";
import "./AttachmentsList.scss";

interface IProps {
  invitee?: string;
  attachments: IAttachment[];
  onDelete: (fileId: string) => Observable<{ success: boolean }>;
  onDownload: (fileId: string, fileName: string, isOrderAttachment: boolean) => void;
  onChangeValidationErrors: (attachmentErrors: IAttachmentErrors | null) => void;
  userRoles: Array<CompanyRole>;
  emptyMessage?: string;
  areOrderLevelAttachments: boolean;
}

export const AttachmentsList = (props: IProps) => {
  const { user } = auth.trade;
  const [attachmentToDelete, setAttachmentToDelete] = useState<IAttachment | null>(null);

  const onFileDelete = (fileId: string | null) => {
    if (!fileId) {
      console.error("fileid is missing");
      return;
    }
    props.onDelete(fileId).subscribe(() => {
      props.onChangeValidationErrors(null);
      setAttachmentToDelete(null);
    });
  };

  const onFileDownload = (fileId: string, fileName: string, isOrderAttachment: boolean) => {
    props.onDownload(fileId, fileName, isOrderAttachment);
  };

  const areDatesValid = (attachments: IAttachment[]) => attachments.every((d) => moment(d.timeStamp).isValid());

  const sortingFunction = (a: IAttachment, b: IAttachment) => {
    const dateA = moment(a.timeStamp);
    const dateB = moment(b.timeStamp);
    return dateA.isAfter(dateB) ? -1 : 1;
  };
  return (
    <div className={classNames("attachmentTable", "withoutDeleteButton")}>
      <div className={classNames("column", "field")}>Name</div>
      <div className={classNames("column", "field")}>Uploaded At</div>
      <div className={classNames("column", "field")}>Size</div>
      {!props.attachments.length && (
        <div className="emptyField">{props.emptyMessage || "There are currently no attachments"}</div>
      )}
      {props.attachments
        .filter((d) => d.fileName)
        .sort(areDatesValid(props.attachments) ? sortingFunction : () => 0)
        .map((attachment) => {
          const attachmentCreator = attachment.offerRepToken ? props.invitee : attachment.user.name;
          return (
            <div className="row" key={attachment.fileId}>
              <div className="field" data-test={"attachment-name"}>
                {attachment.fileName && (
                  <div
                    className="iconFilenameWrapper"
                    data-test={`download-link-${attachment.fileId}`}
                    onClick={() => onFileDownload(attachment.fileId, attachment.fileName, props.areOrderLevelAttachments)}
                  >
                    <Icon icon={getFileIcon(getFileExtension(attachment.fileName))}>{shortenFilename(attachment.fileName)}</Icon>
                  </div>
                )}
              </div>
              <div className="field">
                {areDatesValid([attachment]) ? formatDateWithTime(attachment.timeStamp) : ""}{" "}
                {attachmentCreator && ` by ${attachmentCreator}`}
              </div>
              <div className="field delete-dialog-button">
                {formatFileSize(attachment.fileSizeInBytes)}

                {/* 
                Only ye who created the attachment, has the power to delete the attachment.
                 */}
                {((attachment.offerRepToken && !user?.getUserId()) ||
                  (!attachment.offerRepToken && user?.getUserId() === attachment.user?.id)) && (
                  <Button
                    dataTest="delete-dialog-button"
                    variant="flat"
                    icon="delete"
                    title="Delete attachment"
                    onClick={() => {
                      setAttachmentToDelete(attachment);
                    }}
                  />
                )}
              </div>
            </div>
          );
        })}
      {attachmentToDelete && (
        <Modal isOpen={true} onDismiss={() => setAttachmentToDelete(null)} className="modalPanel">
          <ConfirmationDialog
            title="Confirm delete attachment"
            message={`Are you sure you want to delete ${attachmentToDelete.fileName}?`}
            dataTestId="delete-attachment-confirmation"
            ariaLabel="fail subs confirm"
            onSubmit={() => {
              onFileDelete(attachmentToDelete && attachmentToDelete.fileId);
            }}
            onCancel={() => setAttachmentToDelete(null)}
          />
        </Modal>
      )}
    </div>
  );
};
