import { Negotiation } from "@/models";
import { CLDDUOwnerInviteeEditor } from "@/components";
import { CLDDU, CLDDUProps } from "../CLDDU";
import { LABELS } from "@/constants";

export class CLDDUOwnerInvitee extends CLDDU {
  toJSON() {
    return this.email;
  }

  toString() {
    if (this.email)
      if (Negotiation.prototype.isAbsentOwnerEmail(this.email)) {
        return "No owner assigned (owner absent negotiation)";
      }
    return this.email!;
  }
}

class CLDDUOwnerProps extends CLDDUProps {}

CLDDUOwnerProps.prototype.editor = CLDDUOwnerInviteeEditor;
CLDDUOwnerProps.prototype.label = LABELS["invitee"];

CLDDUOwnerInvitee.prototype._ = new CLDDUOwnerProps();
CLDDUOwnerInvitee.prototype.Props = CLDDUOwnerProps;

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

export interface CLDDUOwnerInvitee {
  _: CLDDUOwnerProps;
}
