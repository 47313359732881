import React from "react";
import { BunkerBaseEditor, Field } from "___REFACTOR___/components";
import { BunkerRedelivery } from "___REFACTOR___/models";

function BunkerRedeliveryEditor(props: BunkerRedeliveryEditor.Props) {
  return (
    <BunkerBaseEditor
      {...props}
      editorType="BunkerRedelivery"
      bunkerType="Re-Delivery"
      dataTest={props.dataTest || "bunker-redelivery"}
    />
  );
}

export { BunkerRedeliveryEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace BunkerRedeliveryEditor {
  export interface Props extends Field.Props {
    value: Value;
    onChange: (value: Value) => void;
  }

  export type Value = BunkerRedelivery | undefined;
}
