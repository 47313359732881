import { memo, useMemo } from "react";
import { observer } from "mobx-react";
import * as yup from "yup";
import classNames from "classnames";
import { UniversalOrderNegotiationFormValues, dialog } from "@/models";
import { UniversalOrderNegotiationForm, FormContext, Button } from "@/components";
import "./ProceedTo.scss";

const CaptureProceedTo = observer(() => {
  const initialValues = useMemo(getInitialValues, []);
  const elements = useMemo(getElements, []);

  return (
    <div className="main-terms-procced-to">
      <h2>Deal status</h2>
      <h2>What is the current status of the deal you are capturing?</h2>
      <UniversalOrderNegotiationForm
        initialValues={initialValues}
        elements={elements}
        context={form}
        onSubmit={onSubmit}
        yup={schema}
      />
      <div className={classNames("main-terms-procced-to-actions")}>
        <Button onClick={dialog.hide}>Cancel</Button>
        <Button onClick={form.submit} variant="action">
          Proceed
        </Button>
      </div>
    </div>
  );

  function onSubmit() {
    dialog.resolve?.({ res: { ...form.values } });

    dialog.hide();
  }

  function getInitialValues() {
    return new UniversalOrderNegotiationFormValues({ jumpTo: "MainTerms" });
  }

  function getElements() {
    return [
      {
        type: "RadioRadioTimelimits",
        radio: {
          name: "jumpTo",
          radioLabel: "Negotiating Main Terms",
          checkedValue: "MainTerms",
        },
        children: [
          {
            name: "auditNotes",
          },
        ],
        bottomText: "Proceeding with this option will set the captured deal to 'Main Terms'.",
        radioTimelimits: [
          {
            type: "RadioTimelimit",
            radio: {
              name: "timelimitVariant",
              checkedValue: "DateOffset",
            },
            timelimit: {
              name: "timelimitDateOffset",
              variant: "DateOffset",
              units: ["hour", "minute"],
              getLayout: (input) => ["Owner will have", input, "to accept the firm bid"],
            },
          },
          {
            type: "RadioTimelimit",
            radio: {
              name: "timelimitVariant",
              checkedValue: "Date",
            },
            timelimit: {
              name: "timelimitDate",
              getLayout: (input) => ["Owner to accept the firm bid by", input],
            },
          },
        ],
      },
      {
        type: "RadioRadioTimelimits",
        radio: {
          name: "jumpTo",
          checkedValue: "OnSubs",
          radioLabel: "On Subs",
        },
        children: [
          {
            name: "auditNotes",
          },
        ],
        bottomText:
          "Proceeding with this option will set the captured deal to 'On Subs'. All main terms will be considered agreed by both parties.",
        radioTimelimits: [
          {
            type: "RadioTimelimit",
            radio: {
              name: "timelimitVariant",
              checkedValue: "DateOffset",
            },
            timelimit: {
              name: "timelimitDateOffset",
              variant: "DateOffset",
              units: ["hour", "minute"],
              getLayout: (input) => ["Owner will have", input, "to meet the requirements of these subjects"],
            },
          },
          {
            type: "RadioTimelimit",
            radio: {
              name: "timelimitVariant",
              checkedValue: "Date",
            },
            timelimit: {
              name: "timelimitDate",
              getLayout: (input) => ["Owner to meet the requirements of these subjects by", input],
            },
          },
        ],
      },
      {
        type: "RadioDateTimezone",
        radio: {
          name: "jumpTo",
          checkedValue: "Fixed",
          radioLabel: "Clean Fixed",
          dataTest: "fix-immediately-radio",
        },
        date: {
          name: "cpDate",
          withoutTimezone: true,
        },
        timezone: {
          name: "userTimeZone",
          unclearable: true,
        },
        bottomText:
          "Proceeding with this option will set the captured deal as Fixed. All main terms and CP details will be considered agreed by both parties, and all subjects lifted.",
        children: [
          {
            name: "auditNotes",
          },
        ],
      },
    ];
  }
});

const schema = yup
  .object()
  .test(
    "Validation0",
    "Something is wrong 0",
    function validate0(this: yup.TestContext, values: UniversalOrderNegotiationFormValues) {
      if (!values) return false;
      if (values.jumpTo === "Fixed") return true;

      const error = this.createError();

      if (values.timelimitVariant === "DateOffset" && !values.timelimitDateOffset) {
        error.message = "Required";
        error.path = "timelimitDateOffset";

        return error;
      }

      return true;
    }
  )
  .test(
    "Validation1",
    "Something is wrong 1",
    function validate1(this: yup.TestContext, values: UniversalOrderNegotiationFormValues) {
      if (!values) return false;
      if (values.jumpTo === "Fixed") return true;

      const error = this.createError();

      if (values.timelimitVariant === "Date" && !values.timelimitDate) {
        error.message = "Required";
        error.path = "timelimitDate";

        return error;
      }

      return true;
    }
  );

const form = new FormContext();

const Memo = memo(CaptureProceedTo);

export { Memo as CaptureProceedTo };
