import { observer } from "mobx-react";
import classNames from "classnames";
import { CargoType, cargoTypes } from "@/models";
import { TextareaField, SelectField, SelectFieldProps } from "@/components";
import { Group } from "../../Group";
import "./CargoType.scss";

function CargoTypeEditor(props: Props) {
  props = { ...props };

  props.className = classNames(props.className, "CargoType");

  const { value, status } = props;

  function onValueChange(cargoType: CargoType) {
    props.onChange?.(new CargoType(cargoType));
  }

  function onNotesChange(notes: string) {
    props.onChange?.(new CargoType({ ...value, notes }));
  }

  return (
    <Group className={props.className}>
      <SelectField
        {...props}
        className="CargoType-value"
        data={cargoTypes.data}
        onChange={onValueChange}
        dataTest={`${props.dataTest}.value`}
        filterKey="name"
        noDataStatus={noDataStatus}
      />
      <TextareaField
        {...props}
        label="Notes"
        desc="Cargo type notes"
        className="CargoType-notes"
        value={value?.notes || ""}
        onChange={onNotesChange}
        status={status?.children?.notes}
        dataTest={`${props.dataTest}.notes`}
      />
    </Group>
  );
}

const noDataStatus = { message: "No matching cargo types" };

const Observer = observer(CargoTypeEditor);

export { Observer as CargoTypeEditor };

interface Props extends Omit<SelectFieldProps, "value" | "onChange"> {
  value?: CargoType | null;
  onChange?: (value: CargoType | null) => void;
}
