import React, { useReducer, useEffect } from "react";
import { isEqual } from "lodash-es";
import { isNumeric } from "validator";
import { IDropdownOption } from "office-ui-fabric-react";
import { CustomDropdown } from "sharedFolder/components/common/CustomDropdown/CustomDropdown";
import { LiftingDisperalType } from "api/models/ILiftings";
import { parseValuesAsString } from "sharedFolder/Utilities/parseValuesAsString";
import { ILiftingsProps } from "sharedFolder/components/common/interfaces";
import { ILiftingsView } from "sharedFolder/Models/IDetails";
import Input from "sharedFolder/components/common/Input/Input";
import { Notes } from "sharedFolder/components/common/Notes/Notes";
import styles from "../LiftingsEditor/LiftingsEditor.module.scss";
import { emptyArrowFn } from "../../../../@/utils";

const LiftingUnits = [
  { key: "Fairly even spread", value: "Fairly even spread" },
  { key: "Monthly", value: "Monthly" },
  { key: "Adhoc", value: "Adhoc" },
];

type minOrMax = number | string | null;

interface ILiftingsEditorState {
  min?: minOrMax;
  max?: minOrMax;
  dispersal?: LiftingDisperalType;
  notes?: string;
}

type action = "replaceForm" | "setMin" | "setMax" | "setDispersal" | "setNotes";

const theReducer =
  (onChange: (value: ILiftingsView) => void) =>
  (
    state: ILiftingsEditorState,
    action: {
      type: action;
      value: string | number | LiftingDisperalType | ILiftingsEditorState;
    }
  ) => {
    const triggerOnChange = (state: ILiftingsEditorState) => {
      const min =
        state.min !== undefined &&
        isNumeric(String(state.min), {
          no_symbols: true,
        })
          ? Number(state.min)
          : null;

      const max =
        state.max !== undefined &&
        isNumeric(String(state.max), {
          no_symbols: true,
        })
          ? Number(state.max)
          : null;

      onChange({
        min,
        max,
        dispersal: state.dispersal,
        notes: state.notes,
      });
      return state;
    };

    switch (action.type) {
      case "setMin":
        return triggerOnChange({ ...state, min: action.value as minOrMax });
      case "setMax":
        return triggerOnChange({ ...state, max: action.value as minOrMax });
      case "setNotes":
        return triggerOnChange({ ...state, notes: action.value as string });
      case "setDispersal":
        return triggerOnChange({
          ...state,
          dispersal: action.value as LiftingDisperalType,
        });
      case "replaceForm":
        return triggerOnChange({
          ...state,
          ...(action.value as ILiftingsEditorState),
        });
    }
  };

export const NewLiftingsEditor: React.SFC<ILiftingsProps> = ({ onChange, required, disabled, focus, value }) => {
  const parsedValue = {
    min: parseValuesAsString(value?.min),
    max: parseValuesAsString(value?.max),
    dispersal: value?.dispersal,
    notes: value?.notes,
  };
  const [state, dispatch] = useReducer(theReducer(onChange), parsedValue);
  useEffect(() => {
    // this is mainly for when a tempalte changes, a better approach would be to lift the state of this component up to the form level
    if (!isEqual(parsedValue, state)) {
      dispatch({ type: "replaceForm", value: parsedValue });
    }
  }, [parsedValue, state]);

  const dropdownOptions: IDropdownOption[] = LiftingUnits ? LiftingUnits.map((o) => ({ key: o.key, text: o.value })) : [];

  return (
    <>
      <div className={styles.inputRow}>
        <div className={styles.inputGroup}>
          <div className={styles.container}>
            <div className={styles.part}>
              <div className={styles.label}>Min</div>
              <Input
                id={"liftings_min"}
                ariaLabel={"Liftings Min"}
                dataTest={"liftings-min"}
                onChange={(evt) => {
                  dispatch({ type: "setMin", value: evt.currentTarget.value });
                }}
                value={state.min === null ? "" : state.min}
                required={required}
                focus={focus}
                disabled={disabled}
                width="tiny"
              />
            </div>
            <div className={styles.part}>
              <div className={styles.label}>Max</div>
              <Input
                id={"liftings_max"}
                ariaLabel={"Liftings Max"}
                dataTest={"liftings-max"}
                onChange={(evt) => {
                  dispatch({ type: "setMax", value: evt.currentTarget.value });
                }}
                value={state.max === null ? "" : state.max}
                required={required}
                onBlur={emptyArrowFn}
                disabled={disabled}
                width="tiny"
              />
            </div>
            <div className={styles.unit}>
              <div className={styles.label}>Dispersal</div>
              <CustomDropdown
                id={"dispersal_unit"}
                dataTest={"dispersal_unit"}
                ariaLabel={"Dispersal"}
                disabled={disabled}
                options={dropdownOptions}
                defaultSelectedKey={state.dispersal}
                onChange={(value) => {
                  dispatch({ type: "setDispersal", value: String(value) });
                }}
                dropdownClassName={styles.dropdown}
                width="small"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.inputRow}>
        <div className={styles.inputGroup}>
          <Notes
            id={`liftings_notes`}
            dataTest="liftings_notes"
            placeholder="Additional liftings notes"
            label="Notes"
            className={styles.notes}
            value={state.notes === undefined || state.notes === null ? "" : state.notes}
            onChange={(value) => {
              dispatch({ type: "setNotes", value: value });
            }}
            disabled={disabled}
            maxLength={200}
          />
        </div>
      </div>
    </>
  );
};
