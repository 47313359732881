import { User } from "___REFACTOR___/models";

import { TradeAPI } from "@/apis";
import { capitalizeFirstLetter, isTruthy } from "@/utils";
import { CLDDUEditor, CLDDUBrokerEditor, CLDDUChartererEditor, CLDDUOwnerEditor } from "@/components";
import { auth } from "../Auth";
import { DataModel, DataModelProps } from "../DataModel";
export { CLDDU, CLDDUProps, CLDDUBroker, CLDDUBrokerProps, CLDDUCharterer, CLDDUChartererProps, CLDDUOwner };

class CLDDU extends DataModel<Data, PartialProps> {
  onConstruct(data?: Data, props?: PartialProps) {
    if (props?.user) {
      this.inheritFromUser(props.user);
    }
  }

  inheritFromUser(user: User) {
    this.companyType = user.CompanyRoles?.[0];
    this.companyName = user.Company;
    this.userName = user.UserName;
    this.companyId = user.CompanyId;
    this.companyName = user.Company;
    this.companyRoles = user.CompanyRoles;
    this.deskId = user.DeskId;
    this.deskName = user.Desk;
    this.divisionId = user.DivisionId;
    this.divisionName = user.Division;
    this.email = user.email;
    this.locationId = user.LocationId;
    this.locationName = user.Location;
    this.systemUserId = user.SystemUserId;
    this.userId = user.getUserId() || undefined;
    this.userName = user.UserName;
  }

  toString() {
    if (typeof auth.trade.user?.SystemUserId === "number" && auth.trade.user?.SystemUserId === this.systemUserId) return "Me";

    const text = [this.getName(), capitalizeFirstLetter(this.companyType), this.email, this.deskName, this.companyName]
      .filter(isTruthy)
      .join(" | ");

    return text;
  }

  toGridView() {
    if (typeof auth.trade.user?.SystemUserId === "number" && auth.trade.user?.SystemUserId === this.systemUserId) return "Me";

    return getName(this);
  }

  getName() {
    return getName(this);
  }
}

function getName(clddu: Data) {
  return clddu.name || clddu.userName;
}

class CLDDUProps extends DataModelProps<Data> {}

CLDDUProps.prototype.editor = CLDDUEditor;

CLDDU.prototype.Props = CLDDUProps;
CLDDU.prototype._ = new CLDDUProps();

/* -------------------------------------------------------------------------- */
/*                                 CLDDUBroker                                */
/* -------------------------------------------------------------------------- */

class CLDDUBroker extends CLDDU {
  onConstruct(data?: Data, props?: PartialProps) {
    if (props?.user?._.companyRoleMap.broker) {
      this.inheritFromUser(props.user);
    }
  }
}

class CLDDUBrokerProps extends CLDDUProps {}

CLDDUBrokerProps.prototype.editor = CLDDUBrokerEditor;
CLDDUBrokerProps.prototype.label = "Broker Contact";

CLDDUBroker.prototype.Props = CLDDUBrokerProps;
CLDDUBroker.prototype._ = new CLDDUBrokerProps();

/* -------------------------------------------------------------------------- */
/*                               CLDDUCharterer                               */
/* -------------------------------------------------------------------------- */

class CLDDUCharterer extends CLDDU {
  onConstruct(data?: Data, props?: PartialProps) {
    if (props?.user?._.companyRoleMap.charterer) {
      this.inheritFromUser(props.user);
    }
  }
}

class CLDDUChartererProps extends CLDDUProps {}

CLDDUChartererProps.prototype.editor = CLDDUChartererEditor;
CLDDUChartererProps.prototype.label = "Charterer Contact";

CLDDUCharterer.prototype.Props = CLDDUChartererProps;
CLDDUCharterer.prototype._ = new CLDDUChartererProps();

/* -------------------------------------------------------------------------- */
/*                               CLDDUOwner                                   */
/* -------------------------------------------------------------------------- */

class CLDDUOwner extends CLDDU {
  onConstruct(data?: Data, props?: PartialProps) {
    if (props?.user?._.companyRoleMap.owner) {
      this.inheritFromUser(props.user);
    }
  }
}

class CLDDUOwnerProps extends CLDDUProps {}

CLDDUOwnerProps.prototype.editor = CLDDUOwnerEditor;
CLDDUOwnerProps.prototype.label = "Owner Contact";

CLDDUOwner.prototype.Props = CLDDUOwnerProps;
CLDDUOwner.prototype._ = new CLDDUOwnerProps();

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

interface CLDDU extends Data {
  _: CLDDUProps;
}

interface CLDDUProps {
  user: User;
}

type PartialProps = Partial<CLDDUProps>;

type Data = DeepPartial<TradeAPI["CLDDU"]>;
