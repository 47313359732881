import React from "react";
import { SelectInput } from "___REFACTOR___/components";
import { Field } from "../../Field";
import { Container } from "../../Container";

function SelectField(props: SelectField.Props) {
  return (
    <Container {...props} type="Select">
      <SelectInput {...props} />
    </Container>
  );
}

export { SelectField };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace SelectField {
  export interface Props extends Omit<SelectInput.Props, "type">, Omit<Field.Props, "value" | "onChange"> {}
}
