class _Storage {
  constructor() {
    this.local = new BrowserStorage(localStorage);
    this.session = new BrowserStorage(sessionStorage);
  }
}

class BrowserStorage {
  constructor(browserStorage: Storage) {
    function get(key: key, defaultValue?: any) {
      let val = browserStorage.getItem(`${key}`);

      if (typeof val !== "string") {
        return defaultValue !== undefined ? defaultValue : val;
      }

      val = JSON.parse(val);

      return val;
    }

    function set(key: key, val: any) {
      browserStorage.setItem(`${key}`, JSON.stringify(val));
    }

    this.get = get;
    this.set = set;
  }
}

interface _Storage {
  local: BrowserStorage;
  session: BrowserStorage;
}
interface BrowserStorage {
  get: (key: key, defaultValue?: any) => any;
  set: (key: key, val: any) => void;
}
type key = string | number;

export const storage = new _Storage();

export { _Storage };
