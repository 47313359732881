import React, { memo } from "react";
import classNames from "classnames";
import "./Icon.scss";

function Icon(props: Props) {
  let { icon: props0, hidden } = props;

  if (hidden) return null;

  props0 = typeof props0 === "string" ? { name: props0 } : props0;

  props = { ...props, ...props0 };

  const { icon, name, className, dom, tooltip, title, ...spreadableProps } = props;

  if (!name) return null;

  const _props = {
    ...spreadableProps,
    title: title || tooltip || "",
    class: classNames(className, props.class, `icon--${name}`, { "has-content": props.children }),
  };

  return <sea-icon {..._props} ref={dom} />;
}

const Memo = memo(Icon);

export { Memo as Icon };

interface Props {
  children?: React.ReactNode;
  icon?: IconName | Omit<Props, "icon">;
  name?: IconName | false | null | "";
  class?: string;
  className?: string;
  tooltip?: string | null;
  title?: string | null;
  onClick?: (e) => void;
  hidden?: boolean;
  dom?: React.MutableRefObject<HTMLElement | null>;
}

export type IconProps = Props;

export type IconName =
  | "88pmh"
  | "abacus"
  | "add-circle-outline"
  | "add-circle"
  | "add-column-after"
  | "add-column-before"
  | "add-location"
  | "add-row-after"
  | "add-row-before"
  | "add-table"
  | "add-tag"
  | "alarm-clock"
  | "alert-note"
  | "align-center"
  | "align-left"
  | "align-middle"
  | "align-right"
  | "anchor"
  | "apply-to-all"
  | "approachingduedatetime"
  | "apps"
  | "archive"
  | "archived"
  | "arrow-back"
  | "arrow-circle-down"
  | "arrow-circle-up"
  | "arrow-drop-down"
  | "arrow-drop-left"
  | "arrow-drop-right"
  | "arrow-drop-up"
  | "arrow-forward"
  | "ascending"
  | "attach-file"
  | "autorenew"
  | "bento-box"
  | "big-mac"
  | "boary-mc-boatface"
  | "bold-text"
  | "branch"
  | "broadcast"
  | "brush"
  | "building"
  | "bunker"
  | "c-trade"
  | "calculator"
  | "call"
  | "cancel"
  | "cashola"
  | "caution"
  | "cerp"
  | "charter-of-afraitment"
  | "chat"
  | "chevron-down"
  | "chevron-left"
  | "chevron-right"
  | "chevron-up"
  | "circle-done"
  | "clear"
  | "clk-edit"
  | "clock-arrow"
  | "clock-strike"
  | "clock"
  | "clone"
  | "close"
  | "closed-group"
  | "cloud-download"
  | "cloud-slash"
  | "cloud-upload"
  | "cloud"
  | "clouds"
  | "coa"
  | "codensed-row"
  | "column-control"
  | "compare"
  | "completed"
  | "connected"
  | "contact-dialer"
  | "contact-support"
  | "content-copy"
  | "cp-file"
  | "create-layout"
  | "cube"
  | "cubes"
  | "custom-line"
  | "dark-theme"
  | "date-range"
  | "day"
  | "delete-forever"
  | "delete"
  | "descending"
  | "disc"
  | "discharge"
  | "disconnected"
  | "distance-calculator"
  | "done-all"
  | "done"
  | "donner"
  | "dont-be-late"
  | "double-chevron-left"
  | "double-chevron-right"
  | "draft"
  | "drag-handle"
  | "dustbin"
  | "edit"
  | "email-download"
  | "email-notice"
  | "email-parsed"
  | "email-sent"
  | "email-settings"
  | "email"
  | "emoji"
  | "empty-filter"
  | "empty"
  | "event-clock"
  | "excel-file"
  | "exclude-token"
  | "exit-to-app"
  | "expand-all"
  | "expand-left"
  | "expand-right"
  | "expanded-row"
  | "export"
  | "eye-closed"
  | "eye-open"
  | "failed"
  | "favourite-outline"
  | "favourite"
  | "feed"
  | "file-audio"
  | "file-compressed"
  | "file-dev"
  | "file-download"
  | "file-email"
  | "file-excel"
  | "file-image"
  | "file-pdf"
  | "file-presentation"
  | "file-sheet"
  | "file-text"
  | "file-upload"
  | "file-video"
  | "file-word"
  | "file"
  | "filter-clear"
  | "filter-restore"
  | "filter"
  | "find-replace"
  | "firm-received"
  | "firm-sent"
  | "fixed"
  | "fixtures"
  | "flag"
  | "flash-off"
  | "flash-on"
  | "folder-add"
  | "folder-link"
  | "folder-locked"
  | "folder-shared"
  | "folder-special"
  | "folder"
  | "font-color"
  | "fork"
  | "fuel-stop"
  | "full-screen-exit"
  | "full-screen"
  | "fully-fixed"
  | "gateway"
  | "generic-file"
  | "globe"
  | "globe"
  | "graph"
  | "grid-view"
  | "grin"
  | "grip"
  | "group-lock"
  | "group"
  | "hamburger"
  | "handset"
  | "headset"
  | "help"
  | "highlight"
  | "hour-glass"
  | "hurricane"
  | "ignore"
  | "image-file"
  | "image"
  | "in-negotiation"
  | "in-the-arc"
  | "inbox"
  | "include-token"
  | "info-outline"
  | "insert-drive-file"
  | "italics"
  | "kebab"
  | "keyboard"
  | "layout-column"
  | "layout-row"
  | "library-add"
  | "library-terms"
  | "light-theme"
  | "lightbulb-outline"
  | "link"
  | "list"
  | "live-help"
  | "load"
  | "location-filter"
  | "lock-filled"
  | "lock-open"
  | "lock-outline"
  | "map-globe"
  | "map-marker"
  | "map"
  | "marine-sync"
  | "meatball-mariana"
  | "meatballs-on-a-stick"
  | "message-in-a-bottle"
  | "mic-off"
  | "mic-on"
  | "michael-fish"
  | "mode-edit"
  | "money"
  | "moon"
  | "more-vert"
  | "my-fleets"
  | "near-due"
  | "new-tag"
  | "new"
  | "night"
  | "no-entry"
  | "no-sort"
  | "note"
  | "notifications"
  | "number-cruncher"
  | "numbered-list"
  | "off-mkt"
  | "offline"
  | "offshore"
  | "on-hunt-group"
  | "on-mkt"
  | "on-subs"
  | "onhuntgroup"
  | "online"
  | "open-in-new"
  | "operations"
  | "order-management"
  | "orders"
  | "outlook"
  | "pad-a-locka-people"
  | "palm"
  | "parsing"
  | "partial-progress"
  | "passed-due"
  | "passedduedatetime"
  | "pdf-file"
  | "pending"
  | "people"
  | "person-add"
  | "person-outline"
  | "person"
  | "phone-chat"
  | "phone"
  | "pin"
  | "piracy"
  | "planet"
  | "play-fill"
  | "play-it-again-sam"
  | "play-outline"
  | "plus-circle"
  | "plus"
  | "polygon"
  | "powerpoint"
  | "priority-high"
  | "proforma"
  | "progress"
  | "quote-received"
  | "quote-sent"
  | "range-left"
  | "range-middle"
  | "range-right"
  | "rating-scale"
  | "recap-file"
  | "recent-files"
  | "refresh"
  | "remove_circle"
  | "remove-column"
  | "remove-row"
  | "reply-all"
  | "reply"
  | "round-a-bout"
  | "royale-with-cheese"
  | "sanctioned"
  | "save"
  | "saved-search"
  | "schedule"
  | "seanet"
  | "search-filled"
  | "search-off"
  | "search"
  | "seca"
  | "security"
  | "send"
  | "settings"
  | "share-docs"
  | "share"
  | "sheet-file"
  | "ship"
  | "skip-over"
  | "slide-deck"
  | "smiley-face"
  | "sort"
  | "speech-bubbles"
  | "speed-dial"
  | "speed"
  | "split"
  | "stacked-boxes"
  | "star-bubble"
  | "star-filled"
  | "star-outline"
  | "statistics"
  | "stay-on-target"
  | "stop-fill"
  | "stop-outline"
  | "storm"
  | "sub"
  | "subject"
  | "subs-failed"
  | "subs-lifted"
  | "sun-has-got-his-hat-on"
  | "sun"
  | "support"
  | "sushi-in-a-square"
  | "system-sheet"
  | "tag-tick"
  | "tag"
  | "talk-to-the-hand"
  | "the-moon-is-made-of-cheese"
  | "three-and-a-half-inch-floppy"
  | "three-cubes"
  | "thumbs-up"
  | "tick-in-a-radio"
  | "tick"
  | "ticked-circle"
  | "time-update"
  | "times"
  | "toggle-minus"
  | "toggle-outline-empty"
  | "track-vessel"
  | "trash-off"
  | "trash-on"
  | "trash"
  | "unarchive"
  | "underline"
  | "unlink"
  | "unpinned"
  | "updated"
  | "user-chat"
  | "user-management"
  | "user-settings"
  | "users"
  | "vessel-detail"
  | "vessel-filter"
  | "vessel-folder"
  | "vessel-search"
  | "view-column"
  | "view-row"
  | "visibility-off"
  | "visibility"
  | "voyage-estimation"
  | "warning-field"
  | "warning"
  | "weather"
  | "whopper"
  | "wildcard"
  | "windy-miller"
  | "withdrawn"
  | "word-doc"
  | "word-file"
  | "workbook"
  | "worksheet"
  | "world";
