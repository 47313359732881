import React, { Component } from "react";
import classnames from "classnames";
import { isDecimal } from "validator";
import editorStyles from "__legacy/sharedFolder/styles/editors.module.scss";
import { formatToDecimal } from "../../../../services/utils/converters";
import { IValidationContext } from "sharedFolder/contexts/ValidationContext";
import { withValidationContext } from "sharedFolder/contexts/withValidationContext";
import { ICommissionView } from "sharedFolder/Models/IDetails";
import Input from "sharedFolder/components/common/Input/Input";

interface ICommissionProps {
  id: string;
  label?: string;
  disabled?: boolean;
  required?: boolean | undefined;
  value?: string;
  focus?: boolean;
  onChange: (value: ICommissionView) => void;
}

interface ICommissionState {
  value: string;
  valid: boolean;
}

class CommissionComponent extends Component<ICommissionProps & IValidationContext, ICommissionState> {
  constructor(props: ICommissionProps & IValidationContext) {
    super(props);
    this.handleCommissionChange = this.handleCommissionChange.bind(this);
    this.handleCommissionBlur = this.handleCommissionBlur.bind(this);

    const valid = this.isValid(props.value);
    this.state = {
      value: props.value || "",
      valid: valid,
    };
    this.props.fieldValidityChanged(this.props.id, valid);
  }

  public isValid(value: any): boolean {
    if (value === "" || value === undefined) {
      return !this.props.required;
    } else {
      const isValidNumber = !isNaN(value) && isDecimal(String(value), { decimal_digits: "0,5" }) && value >= 0 && value <= 100;
      return isValidNumber;
    }
  }

  public componentDidUpdate(prevProps: ICommissionProps & IValidationContext) {
    if (prevProps.required !== this.props.required || prevProps.value !== this.props.value) {
      const isValid = this.isValid(this.state.value);
      this.setState({
        valid: isValid,
      });
      this.props.fieldValidityChanged(this.props.id, isValid);
    }
  }

  public render() {
    return (
      <div className={editorStyles.inputRow}>
        <div className={editorStyles.inputGroup}>
          {this.props.label && <label htmlFor={this.props.id}>{this.props.label}</label>}
          <Input
            type="text"
            autoComplete="off"
            id={this.props.id}
            dataTest={this.props.id}
            ariaLabel={this.props.id}
            className={classnames(editorStyles.input, this.state.valid ? "" : "error-state")}
            value={this.state.value}
            onChange={this.handleCommissionChange}
            onBlur={this.handleCommissionBlur}
            focus={this.props.focus}
            disabled={this.props.disabled}
          />
        </div>
      </div>
    );
  }

  private handleCommissionChange(evt: React.FormEvent<HTMLInputElement>) {
    this.setState({ value: evt.currentTarget.value });
  }

  private handleCommissionBlur(evt: React.FocusEvent<HTMLInputElement>) {
    const sanitizedValue = parseFloat(evt.target.value.replace(/,/g, ""));
    const isValid = this.isValid(sanitizedValue);
    const updatedValue = isValid ? formatToDecimal(sanitizedValue, 3) : "";

    this.setState({
      value: evt.target.value,
      valid: isValid,
    });

    this.props.onChange({
      value: updatedValue,
      display: updatedValue,
    });
  }
}

export const CommissionEditor = withValidationContext(CommissionComponent);
