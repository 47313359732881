import React from "react";
import classNames, { Argument } from "classnames";
import { Label } from "___REFACTOR___/components/common/Field/Label";
import "./ToggleGroup.scss";

function ToggleGroup(props: ToggleGroup.Props) {
  let { className, direction = "vertical", children, label = "", ...rest } = props;

  className = classNames(className, direction);

  return (
    <app-form-toggle-group class={className} {...rest}>
      <Label label={label} />
      <app-form-toggle-group-body>{children}</app-form-toggle-group-body>
    </app-form-toggle-group>
  );
}

export { ToggleGroup };

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

// eslint-disable-next-line no-redeclare
declare namespace ToggleGroup {
  interface Props extends Omit<ReactHTMLFormProps, "className"> {
    className?: Argument;
    direction?: "vertical" | "horizontal";
    label?: string;
  }

  type ReactHTMLFormProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
}
