import * as Legacy from "__legacy/services/CloudAuthenticator";

import { config } from "___REFACTOR___/config";
import { log, history } from "___REFACTOR___/services";
import { jwtDecode } from "___REFACTOR___/utils";
import { TradeAPI, tradeAPI, CentralAPI, centralAPI } from "___REFACTOR___/apis";
import { User } from "___REFACTOR___/models";

class Auth {
  get authenticated() {
    return this.trade.authenticated;
  }

  get authorised() {
    return !!this.trade.user;
  }

  setup() {
    this.authenticate();

    return this.promise;
  }

  authenticate = async () => {
    await this.central.login();
    this.trade.login();
  };

  reset = async (path = "/login-external") => {
    if (localStorage.INTERCEPT_AUTH_RESET) await new Promise((resolve) => document.body.addEventListener("click", resolve));

    log.system("Auth: Resetting...");

    this.trade.logout();
    this.central.logout(path);
  };

  central = {
    user: undefined as CentralAPI.User | undefined,
    token: undefined as CentralAPI.Token | undefined,
    authenticated: false,

    login: async () => {
      const newToken = new URLSearchParams(window.location.search).get("token") || "";
      let token = localStorage.getItem(CENTRAL_STORAGE_KEY) || "";

      if (!token && !newToken) {
        return this.reset();
      }

      if (newToken) {
        token = newToken;
      }

      if (token) {
        const res = await centralAPI.getUser(token);

        if (res.ok) {
          this.central.user = res.data;
        }
      }

      localStorage.setItem(CENTRAL_STORAGE_KEY, token);

      this.central.token = token;
      this.central.authenticated = true;

      Legacy.authenticationResult.setAuthorisationToken(token);

      history.push(window.location.pathname);

      this.centralResolve();
    },

    logout: (path = "/logout") => {
      this.central.clearStorage();
      this.central.redirect(path);
    },

    redirect: (path = "") => {
      this.central.clearStorage();

      window.location.replace(`${config.authUrl}${path}?redirect=${window.location}`);
    },

    clearStorage: () => {
      localStorage.removeItem(CENTRAL_STORAGE_KEY);
    },

    promise: new Promise((resolve) => {
      this.centralResolve = resolve;
    }),
  };

  trade = {
    token: undefined as TradeAPI.Token | undefined,
    user: undefined as User | undefined,
    authenticated: false,
    refreshPromise: undefined as Promise<TradeAPI.Token> | undefined,

    login: async () => {
      if (!this.central.token) return;

      let res;
      let token = localStorage.getItem(TRADE_STORAGE_KEY) || "";

      let user = jwtDecode(token, {}) as User | undefined;
      let userExpMs = (user?.exp || 0) * 1000;

      if (!token || isExpired(userExpMs)) {
        res = await tradeAPI.getToken(this.central.token);

        if (!res.ok) return;

        token = res.data;
        user = jwtDecode(res.data) as User;
        userExpMs = user.exp * 1000;
      }

      // sequence matters
      this.trade.user = user ? new User(user) : undefined;
      this.trade.token = token;
      this.trade.authenticated = true;

      localStorage.setItem(TRADE_STORAGE_KEY, token);
      localStorage.setItem(TRADE_EXPIRY_STORAGE_KEY, `${userExpMs}`);

      this.tradeResolve();

      return res;
    },

    refresh: async () => {
      let refreshResolve;

      this.trade.refreshPromise = new Promise((resolve) => {
        refreshResolve = resolve;
      });

      const res = await tradeAPI.getToken(this.central.token);

      const { data: token, ok } = res;

      if (!ok) return;

      const user = jwtDecode(token) as User | undefined;
      const userExpMs = (user?.exp || 0) * 1000;

      this.trade.user = user ? new User(user) : undefined;
      this.trade.token = token;
      this.trade.authenticated = true;

      localStorage.setItem(TRADE_STORAGE_KEY, token);
      localStorage.setItem(TRADE_EXPIRY_STORAGE_KEY, `${userExpMs}`);

      refreshResolve(token);

      this.trade.refreshPromise = undefined;

      return token;
    },

    logout: () => {
      this.trade.clearStorage();
    },

    clearStorage: () => {
      localStorage.removeItem(TRADE_STORAGE_KEY);
      localStorage.removeItem(TRADE_EXPIRY_STORAGE_KEY);
    },

    promise: new Promise((resolve) => {
      this.tradeResolve = resolve;
    }),
  };

  promise = Promise.all([this.trade.promise]);
}

function isExpired(date) {
  if (typeof date !== "number" && typeof date !== "string") return true;

  return new Date() > new Date(date);
}

const CENTRAL_STORAGE_KEY = "central-token";
const TRADE_STORAGE_KEY = "ctrade-token-ctradeToken"; // this is what Old-TradeUI uses
const TRADE_EXPIRY_STORAGE_KEY = "ctrade-token-ctradeTokenExpiry"; // this is what Old-TradeUI uses

const auth = new Auth();

export { auth, Auth };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface Auth {
  tradeResolve: (value?) => void;
  centralResolve: (value?) => void;
}
