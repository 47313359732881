import React, { useState, useCallback } from "react";
import { Observable } from "rxjs";
import { PanelType, Panel } from "office-ui-fabric-react";
import { RespondingFor } from "../RespondingFor/RespondingFor";
import { Role } from "negotiations/components/negotiate/Negotiation/Role";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { INegotiationNotesView } from "sharedFolder/Models/IDetails";
import { PanelCloseButton } from "../CloseButton/CloseButton";
import Button from "__legacy/sharedFolder/components/common/Button/Button";
import Icon from "__legacy/sharedFolder/components/common/Icon/Icon";
import TextArea from "sharedFolder/components/common/TextArea/TextArea";
import styles from "./NegotiationNote.module.scss";
import { emptyArrowFn } from "../../../../@/utils";
import { NegotiationTypeEvents, usageMetrics } from "@/services/UsageMetrics";

interface INegotiationNotePanelProps {
  /*
   * Determines if the panel should be opened for the Owner or the Charterer
   */
  role: Role;
  /*
   * Current content of the controller notes
   */
  controllerNegotiationNotes: INegotiationNotesView;
  /*
   * Current content of the owner notes
   */
  offerRepNegotiationNotes: INegotiationNotesView;
  /*
   * Called when user dismisses the panel
   */
  onDismiss: () => void;
  /*
   * Called when the use updates the note
   */
  updateNote: (role: Role, note: string) => Observable<{ id: string; version: number }>;
}

export const NegotiationNote: React.FC<INegotiationNotePanelProps> = (props: INegotiationNotePanelProps) => {
  const { role, onDismiss, updateNote } = props;

  const focusRef = useCallback((node) => {
    if (node !== null) {
      node.focus = true;
    }
  }, []);

  const getCurrentNote = (role: Role) => {
    return role === "charterer" ? props.controllerNegotiationNotes.value || "" : props.offerRepNegotiationNotes.value || "";
  };

  const [note, setNote] = useState<string>(getCurrentNote(props.role));
  const [roleState, setRole] = useState<Role>(props.role);
  const [processing, setProcessing] = useState<boolean>(false);

  const onSubmit = () => {
    setProcessing(true);
    updateNote(roleState, note).subscribe(
      () => {
        onDismiss();
      },
      emptyArrowFn,
      () => {
        setProcessing(false);
      }
    );
  };

  return (
    <Panel
      isOpen={true}
      type={PanelType.medium}
      onDismiss={onDismiss}
      onRenderHeader={() => (
        <RespondingFor
          defaultValue={role}
          onChange={(newRole) => {
            setRole(newRole);
            setNote(getCurrentNote(newRole));
          }}
        />
      )}
      onRenderNavigation={() => <PanelCloseButton onClick={onDismiss} />}
    >
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <h2>Send a note to the {roleState === "charterer" ? "Owner" : "Charterer"} </h2>
          {processing && <LoadingSpinner />}
        </div>
        <TextArea
          className={styles.textarea}
          onChange={(evt: React.FocusEvent<HTMLTextAreaElement>) => setNote(evt.target.value)}
          maxLength={9999}
          value={note}
          ref={focusRef}
        />
        <div className={styles.warningMessageContainer}>
          <Icon icon="info-outline" />
          <div>
            <span>
              {roleState === "charterer"
                ? "The Owner will receive this note as soon as you click 'SEND'. You can change what is written here at any time."
                : "You are updating this note on the Owner's behalf. You can change what is written here at any time."}
            </span>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button type="flat" onClick={onDismiss} dataTest="cancel-send-note-button">
            Cancel
          </Button>
          <Button type="action" onClick={onSubmit} disabled={processing} dataTest="send-note-button">
            Send
          </Button>
        </div>
      </div>
    </Panel>
  );
};
