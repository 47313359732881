import { ILiftingsCollection, ILiftingsNegotiation } from "api/models/ILiftingsCollection";
import { Observable, empty } from "rxjs";
import { observableFetch } from "negotiations/services/client/negotiation/observableFetch";
import { repeatWhen, filter, map, catchError } from "rxjs/operators";
import { IResponse } from "api/models/IResponse";
import { Optimistic } from "api/Optimistic";
import { INegotiation, IVesselRejectUpdate, IConfirmLifting, IWithdrawLifting } from "api/negotiations/models";
import { ILifting } from "api/negotiations/models/ILifting";

interface IApiLiftingsService {
  addLifting: (orderId: string, updateToken: string) => Observable<IResponse>;
  declareLiftingCargo: (orderId: string, liftingId: string, lifting: ILifting) => Observable<IResponse>;
  updateLiftingCargo: (orderId: string, liftingId: string, liftingCargoId: string, lifting: ILifting) => Observable<IResponse>;
  captureLiftingCargo: (orderId: string, liftingId: string, lifting: ILifting) => Observable<IResponse>;
  getLiftings: (orderId: string) => Observable<ILiftingsCollection>;
  rejectVessel: (orderId: string, negotiationId: string, detail: IVesselRejectUpdate, imo: string) => Observable<IResponse>;
  confirmLifting: (
    orderId: string,
    liftingId: string,
    liftingCargoId: string,
    vesselImo: string,
    detail: IConfirmLifting
  ) => Observable<IResponse>;
  withdrawLifting: (
    orderId: string,
    liftingId: string,
    liftingCargoId: string,
    isConfirmed: boolean,
    detail: IWithdrawLifting
  ) => Observable<IResponse>;
}

class LiftingsService implements IApiLiftingsService {
  private readonly optimisticLiftings = new Optimistic<ILiftingsNegotiation>("liftings");
  constructor(private ctradeUrl: string, private withUpdates?: Observable<{ id: string; version: number }>) {}

  public confirmLifting = (
    orderId: string,
    liftingId: string,
    liftingCargoId: string,
    vesselImo: string,
    detail: IConfirmLifting
  ): Observable<IResponse> => {
    return observableFetch<INegotiation>(() =>
      fetch(`${this.ctradeUrl}/orders/${orderId}/liftings/${liftingId}/cargo/${liftingCargoId}/vessels/${vesselImo}/accept`, {
        method: "PUT",
        headers: {
          "X-API-Version": "3",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(detail),
      })
    );
  };

  public withdrawLifting = (
    orderId: string,
    liftingId: string,
    liftingCargoId: string,
    isConfirmed: boolean,
    detail: IWithdrawLifting
  ): Observable<IResponse> => {
    return observableFetch<INegotiation>(() =>
      fetch(
        `${this.ctradeUrl}/orders/${orderId}/liftings/${liftingId}/cargo/${liftingCargoId}?isConfirmedLifting=${isConfirmed}`,
        {
          method: "DELETE",
          headers: {
            "X-API-Version": "3",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(detail),
        }
      )
    );
  };

  public addLifting = (orderId: string, orderUpdateToken: string): Observable<IResponse> => {
    return observableFetch<IResponse>(() =>
      fetch(`${this.ctradeUrl}/orders/${orderId}/liftings`, {
        method: "POST",
        headers: {
          "X-API-Version": "3",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ updateToken: orderUpdateToken }),
      })
    ).pipe(
      map((result: IResponse) => {
        return result;
      }),
      catchError(() => empty())
    );
  };

  public declareLiftingCargo = (orderId: string, liftingId: string, lifting: ILifting): Observable<IResponse> => {
    return observableFetch<IResponse>(() =>
      fetch(`${this.ctradeUrl}/orders/${orderId}/liftings/${liftingId}/cargo`, {
        method: "POST",
        headers: {
          "X-API-Version": "3",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(lifting),
      })
    );
  };

  public captureLiftingCargo = (orderId: string, liftingId: string, lifting: ILifting): Observable<IResponse> => {
    return observableFetch<IResponse>(() =>
      fetch(`${this.ctradeUrl}/orders/${orderId}/liftings/${liftingId}/capturecargo`, {
        method: "POST",
        headers: {
          "X-API-Version": "3",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(lifting),
      })
    );
  };

  public updateLiftingCargo = (
    orderId: string,
    liftingId: string,
    liftingCargoId: string,
    lifting: ILifting
  ): Observable<IResponse> => {
    return observableFetch<IResponse>(() =>
      fetch(`${this.ctradeUrl}/orders/${orderId}/liftings/${liftingId}/cargo/${liftingCargoId}`, {
        method: "PUT",
        headers: {
          "X-API-Version": "3",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(lifting),
      })
    );
  };

  public getLiftings = (orderId: string): Observable<ILiftingsCollection> => {
    return observableFetch<ILiftingsCollection>(() =>
      fetch(`${this.ctradeUrl}/orders/${orderId}/liftings`, {
        method: "GET",
        headers: {
          "X-API-Version": "3",
        },
      })
    )
      .pipe(
        // and fetch all again whenever we receive an update notification
        // for this order
        repeatWhen(() => (this.withUpdates || empty()).pipe(filter((update) => update.id === orderId)))
      )
      .pipe(
        map((results: ILiftingsCollection) => {
          return results;
        })
      );
  };

  public rejectVessel = (
    orderId: string,
    negotiationId: string,
    detail: IVesselRejectUpdate,
    imo: string
  ): Observable<IResponse> => {
    return observableFetch<INegotiation>(() =>
      fetch(`${this.ctradeUrl}/orders/${orderId}/negotiations/${negotiationId}/vessels/${imo}`, {
        method: "PUT",
        headers: {
          "X-API-Version": "3",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(detail),
      })
    );
  };
}

const services: { [key: string]: IApiLiftingsService } = {};

export function getLiftingsApiService(
  apiUrl: string,
  orderId: string,
  withUpdates?: Observable<{ id: string; version: number }>
) {
  const id = `${apiUrl}-${orderId}`;
  if (!Object.prototype.hasOwnProperty.call(services, id)) {
    services[id] = new LiftingsService(apiUrl, withUpdates);
  }

  return services[id];
}
