import { getAbstractDetailArrayForDisplay } from "@/models/Order/utils";

export const CAPTURE_CONFIG = {
  elements: [
    {
      type: "CollapsibleGroup",
      label: "Deal Capture - Order Details",
      children: [
        { type: "CollapsibleRow", name: "chartererContact", readOnly: false },
        { type: "CollapsibleRow", name: "brokerContact" },
        { type: "CollapsibleRow", name: "ownerContact" },
        { type: "CollapsibleRow", name: "invitee", onChangeSelector: "email", hidden: true },
      ],
    },
  ],
};

export const VOY_CONFIG = {
  elements: [
    {
      type: "CollapsibleGroup",
      label: "Order Terms",
      children: [
        { type: "CollapsibleRow", name: "chartererAccount" },
        { type: "CollapsibleRow", name: "laycan" },
        { type: "CollapsibleRow", name: "cargoType" },
        { type: "CollapsibleRow", name: "addressCommission" },
        { type: "CollapsibleRow", name: "brokerCommission" },
      ],
    },
    {
      type: "CollapsibleGroup",
      label: "Voyage Terms",
      children: getAbstractDetailArrayForDisplay(
        {
          cargoSize: { type: "CollapsibleRow", name: "cargoSize" },
          dischargeLocation: { type: "CollapsibleRow", name: "dischargeLocation" },
          loadLocation: { type: "CollapsibleRow", name: "loadLocation" },
          freightRate: { type: "CollapsibleRow", name: "freightRate" },
          demurrage: { type: "CollapsibleRow", name: "demurrage" },
          notes: { type: "CollapsibleRow", name: "voyageNotes" },
        },
        { type: "Voy" }
      ),
    },
  ],
  required: [
    "chartererAccount",
    "laycan",
    "cargoType",
    "cargoSize",
    "loadLocation",
    "dischargeLocation",
    "freightRate",
    "demurrage",
  ],
};

export const TCT_CONFIG = {
  elements: [
    {
      type: "CollapsibleGroup",
      label: "Order Terms",
      children: [
        { type: "CollapsibleRow", name: "chartererAccount" },
        { type: "CollapsibleRow", name: "laycan" },
        { type: "CollapsibleRow", name: "cargoType" },
        { type: "CollapsibleRow", name: "addressCommission" },
        { type: "CollapsibleRow", name: "brokerCommission" },
      ],
    },
    {
      type: "CollapsibleGroup",
      label: "Time Charter Terms",
      children: getAbstractDetailArrayForDisplay(
        {
          vesselSize: { type: "CollapsibleRow", name: "vesselSize" },
          duration: { type: "CollapsibleRow", name: "duration" },
          deliveryLocation: { type: "CollapsibleRow", name: "deliveryLocation", hideSafes: true },
          viaLocation: { type: "CollapsibleRow", name: "viaLocation", hideSafes: true },
          redeliveryLocation: { type: "CollapsibleRow", name: "redeliveryLocation", hideSafes: true },
          notes: { type: "CollapsibleRow", name: "tctNotes" },
          hireRate: { type: "CollapsibleRow", name: "hireRate" },
          cleaningPrice: { type: "CollapsibleRow", name: "cleaningPrice" },
          supplyPrice: { type: "CollapsibleRow", name: "supplyPrice" },
          tradingExclusions: { type: "CollapsibleRow", name: "tradingExclusions" },
          bunkerDelivery: { type: "CollapsibleRow", name: "bunkerDelivery" },
          bunkerRedelivery: { type: "CollapsibleRow", name: "bunkerRedelivery" },
          cargoExclusionsText: { type: "CollapsibleRow", name: "cargoExclusionsText" },
          ballastBonus: { type: "CollapsibleRow", name: "ballastBonus" },
        },
        { type: "Tct" }
      ),
    },
  ],
  required: ["chartererAccount", "laycan", "cargoType", "deliveryLocation", "duration"],
};

export const COA_CONFIG = {
  elements: [
    {
      type: "CollapsibleGroup",
      label: "COA Terms",
      children: getAbstractDetailArrayForDisplay(
        {
          chartererAccount: { type: "CollapsibleRow", name: "chartererAccount" },
          period: { type: "CollapsibleRow", name: "period" },
          liftings: { type: "CollapsibleRow", name: "liftings" },
          nominations: { type: "CollapsibleRow", name: "nominations" },
          cargoType: { type: "CollapsibleRow", name: "cargoType" },
          coaCargoSize: { type: "CollapsibleRow", name: "coaCargoSize" },
          addressCommission: { type: "CollapsibleRow", name: "addressCommission" },
          brokerCommission: { type: "CollapsibleRow", name: "brokerCommission" },
          loadLocation: { type: "CollapsibleRow", name: "loadLocation" },
          dischargeLocation: { type: "CollapsibleRow", name: "dischargeLocation" },
          freightRate: { type: "CollapsibleRow", name: "freightRate" },
          demurrage: { type: "CollapsibleRow", name: "demurrage" },
          notes: { type: "CollapsibleRow", name: "notes" },
        },
        { type: "Coa" }
      ),
    },
  ],
  required: [
    "chartererAccount",
    "period",
    "liftings",
    "nominations",
    "cargoType",
    "coaCargoSize",
    "loadLocation",
    "dischargeLocation",
    "freightRate",
    "demurrage",
  ],
};

const TERMSET_ELEMENT = {
  type: "CollapsibleGroup",
  children: [{ name: "termset", type: "Termset" }],
};

export const SPLIT_ELEMENT = (children) => [
  {
    type: "Split",
    sizes: [40, 60],
    gutterSize: 5,
    children: [
      {
        type: "CollapsibleGroup",
        children,
      },
      TERMSET_ELEMENT,
    ],
  },
];

export const TYPE_ID_BY_TYPE = {
  Voy: 1,
  Tct: 2,
  Coa: 3,
};
