import { observer } from "mobx-react";
import * as Common from "___REFACTOR___/components/common";
import { Dialog as Service } from "___REFACTOR___/services";

const Dialog = observer((instanceProps: Dialog.InstanceProps) => {
  const { service } = instanceProps;

  return <Common.Dialog {...service.props} isOpen={service.isOpen} onClose={service.close} suppressPortal />;
});

export { Dialog };

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

declare namespace Dialog {
  interface InstanceProps {
    service: Service;
  }

  type Props = Omit<Common.Dialog.Props, "isOpen" | "onClose">;
}
