// BLACKBOX
import React from "react";
import { observer } from "mobx-react";
import { Button } from "@/components";
import { config } from "@/config";
import { OrderNegotiationStore, slideout } from "@/models";
import { FetchInterceptor } from "__legacy/dashboard/components/FetchInterceptor/FetchInterceptorOld";
import { UserContext, UserProvider } from "__legacy/dashboard/contexts/UserProvider";
import { Attachments } from "__legacy/sharedFolder/components/common/Attachments/Attachments";
import { ConfigurationContext } from "__legacy/sharedFolder/ConfigurationContext";
import {
  deleteNegAttachment,
  downloadOrderAttachment,
  downloadNegAttachment,
  uploadNegAttachment,
} from "__legacy/negotiations/services/attachments";
import "./NegotiationAttachments.scss";

const NegotiationAttachments = (props) => {
  const negotiation = props.negotiation as OrderNegotiationStore["Negotiation"];
  const { orderNegotiationStore } = negotiation._;
  const orderModel = orderNegotiationStore.upsertOrderModel({ id: negotiation.orderId });
  const negotiationModel = orderModel.upsertNegotiation(negotiation);
  const { orderId, id, attachments, updateToken } = negotiationModel;
  const { version } = negotiationModel._; // eslint-disable-line @typescript-eslint/no-unused-vars
  if (!attachments || !updateToken) {
    return null;
  }

  return (
    <div className="negotiation-attachments LEGACY_CONTAINER">
      <div className="negotiation-attachments-content">
        <FetchInterceptor baseUrl={config.ctradeUrl} exceptions={["/token$"]}>
          <ConfigurationContext.Provider value={config}>
            <UserProvider config={config}>
              <h2>Attachments</h2>
              <div className="negotiation-attachments-scroll">
                <UserContext.Consumer>
                  {(user) => (
                    <Attachments
                      areOrderLevelAttachments={false}
                      attachments={attachments}
                      invitee={props.negotiation.invitee}
                      userRoles={user.companyRoles}
                      onDelete={(fileId: string) => deleteNegAttachment(orderId, id)(config.ctradeUrl, fileId, updateToken)}
                      onDownload={(fileId: string, fileName: string, isOrderAttachment: boolean) =>
                        isOrderAttachment
                          ? downloadOrderAttachment(orderId)(fileId, fileName, updateToken)
                          : downloadNegAttachment(orderId, id)(fileId, fileName, updateToken)
                      }
                      onUpload={(formData: FormData) => uploadNegAttachment(orderId, id)(config.ctradeUrl, formData, updateToken)}
                    />
                  )}
                </UserContext.Consumer>
              </div>
            </UserProvider>
          </ConfigurationContext.Provider>
        </FetchInterceptor>
      </div>
      <div className="negotiation-attachments-control">
        <Button variant="flat" onClick={() => slideout.hide()}>
          Close
        </Button>
      </div>
    </div>
  );
};

const Observer = observer(NegotiationAttachments);

export { Observer as NegotiationAttachments };
