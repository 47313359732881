import { useContext } from "react";
import classNames from "classnames";
import { Dialog, DialogTitle, DialogActions, DialogContent, ThemeProvider, Box, DialogProps } from "@material-ui/core";
import { theme } from "../../../../styles/muiTheme";
import { Context, ContextValue } from "./Dialog";

export const NewDialog = (props: DialogProps) => {
  const { isOpen, onClose, id, children, dialogTitle, dialogActions } = useContext<ContextValue>(Context);
  const className = props.className || "standard";
  const { maxWidth } = props;

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        id={id}
        data-component="mui-dialog"
        className={classNames(className, `dialog-${className}`)}
        open={isOpen}
        onClose={onClose}
        maxWidth={maxWidth || "lg"}
      >
        {dialogTitle && <DialogTitle>{dialogTitle}</DialogTitle>}
        <Box paddingBottom={2}>
          <DialogContent>{children}</DialogContent>
        </Box>
        {dialogActions && <DialogActions>{dialogActions}</DialogActions>}
      </Dialog>
    </ThemeProvider>
  );
};
