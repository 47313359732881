import React, { PropsWithChildren, createContext, useState, useEffect, useContext } from "react";
import { UserProviderLoading } from "sharedFolder/components/common/UserProviderLoading/UserProviderLoading";
import {
  IUserSettings,
  defaultUserOrderPreference,
  ISelectableOption,
  sortKey,
  IUserOrderPreferences,
} from "sharedFolder/Models/userSettings";
import {
  loadUserOrderPreferences,
  createUserOrderPreference,
  updateUserOrderPreference,
} from "sharedFolder/apis/UserSettingsProvider";
import { cloneDeep } from "lodash-es";
import { CompanyRole } from "sharedFolder/Models/CompanyRole";
import { IConfiguration } from "sharedFolder/Configuration";
import { useAuth } from "__legacy/auth-provider";
import { legacy } from "@/legacy";
import { emptyArrowFn } from "@/utils";

export interface IUserContext {
  id: string;
  systemUserId: number;
  email: string;
  userName: string;
  companyId: string;
  companyName: string;
  deskId: string;
  deskName: string;
  locationId: string;
  locationName: string;
  divisionId: string;
  divisionName: string;
  companyRoles: Array<CompanyRole>;
  memberships: string[];
  archivedOrders: string[];
  onOrderArchived: (orderId: string) => void;
  hasConfirmedDisclaimer: boolean;
  orderPreferences: {
    preferences: IUserSettings | null;
    createUserOrderPreference: (orderId: string) => void;
    clearOrderFilters: (orderId: string) => void;
    getOrderPreference: (orderId: string) => IUserOrderPreferences | null | undefined;
    setOrderSort: (orderId: string, sort: sortKey) => void;
    setOrderFilter: (orderId: string, filters: ISelectableOption[]) => void;
  };
}

const UserContext = createContext<IUserContext>({
  id: "",
  systemUserId: 0,
  email: "",
  userName: "",
  companyId: "",
  companyName: "",
  deskId: "",
  deskName: "",
  divisionId: "",
  divisionName: "",
  locationId: "",
  locationName: "",
  companyRoles: [],
  memberships: [],
  archivedOrders: [],
  onOrderArchived: emptyArrowFn,
  hasConfirmedDisclaimer: false,
  orderPreferences: {
    preferences: null,
    createUserOrderPreference: emptyArrowFn,
    clearOrderFilters: emptyArrowFn,
    getOrderPreference: () => defaultUserOrderPreference(""),
    setOrderSort: emptyArrowFn,
    setOrderFilter: emptyArrowFn,
  },
});

UserContext.displayName = "UserContext";

const UserProvider: React.FC<
  PropsWithChildren<{
    config: IConfiguration;
  }>
> = (props: { config: IConfiguration; children?: React.ReactNode }) => {
  const { config, children } = props;
  const {
    auth: { claims },
  } = useAuth();
  const [archivedOrders, setArchivedOrders] = useState<string[]>([]);
  const [orderPreferences, setOrderPreferences] = useState<IUserSettings | null>(null);

  //load the existing settings, unfortunate clunky function to create a dummy setting so we have an update token on load.
  useEffect(() => {
    if (!orderPreferences) {
      loadUserOrderPreferences(config.ctradeUrl).then((response) => {
        if (response.data.updateToken === null) {
          if (orderPreferences) {
            const newPreference = defaultUserOrderPreference("00000000-0000-0000-0000-000000000000");
            createUserOrderPreference(config.ctradeUrl, newPreference).then(() => {
              loadUserOrderPreferences(config.ctradeUrl).then((response) => {
                setOrderPreferences(response.data);
              });
            });
          }
        }
        setOrderPreferences(response.data);
      });
    }
  }, [config.ctradeUrl, orderPreferences]);

  if (!claims || !orderPreferences) {
    return <UserProviderLoading />;
  }

  const {
    memberships,
    email,
    UserName,
    Company,
    CompanyId,
    Division,
    DivisionId,
    Location,
    LocationId,
    Desk,
    DeskId,
    CompanyRoles,
    SystemUserId,
  } = legacy.user as any;

  const user = {
    id: memberships.find((m) => m.level === "user")?.id ?? "",
    systemUserId: SystemUserId,
    email,
    companyId: CompanyId,
    companyName: Company,
    companyRoles: CompanyRoles,
    divisionId: DivisionId,
    divisionName: Division,
    locationId: LocationId,
    locationName: Location,
    deskId: DeskId,
    deskName: Desk,
    memberships: memberships.map((m: any) => m.id),
    userName: UserName,
    archivedOrders,
    onOrderArchived: (orderId: string) => {
      const newArchivedOrders = cloneDeep(archivedOrders);
      newArchivedOrders.push(orderId);
      setArchivedOrders(newArchivedOrders);
    },
    hasConfirmedDisclaimer: claims.HasConfirmedDisclaimer,
    orderPreferences: {
      preferences: orderPreferences,
      createUserOrderPreference: (orderId: string) => {
        const newPreference = defaultUserOrderPreference(orderId);
        createUserOrderPreference(config.ctradeUrl, newPreference).then((id) => {
          newPreference.id = id;
          const newPreferences = cloneDeep(orderPreferences).settings.concat(newPreference);
          const newOrderPreference: IUserSettings = {
            settings: newPreferences,
            updateToken: orderPreferences.updateToken,
          };

          setOrderPreferences(newOrderPreference);
          return newPreference;
        });
      },
      getOrderPreference: (orderId: string): IUserOrderPreferences | undefined | null => {
        if (orderPreferences !== null) {
          const preference = cloneDeep(orderPreferences).settings.find((item) => item.orderId === orderId);
          return preference;
        } else {
          return null;
        }
      },
      setOrderSort: (orderId: string, sort: sortKey) => {
        if (orderPreferences) {
          const updatedPreferences = cloneDeep(orderPreferences);
          const preference = updatedPreferences.settings.find((item) => item.orderId === orderId);

          if (preference) {
            preference.sort = sort;
            updateUserOrderPreference(config.ctradeUrl, preference, orderPreferences.updateToken);
          }
          setOrderPreferences(updatedPreferences);
        }
      },
      clearOrderFilters: (orderId: string) => {
        if (orderPreferences) {
          const updatedPreferences = cloneDeep(orderPreferences);
          const preference = updatedPreferences.settings.find((item) => item.orderId === orderId);

          if (preference) {
            preference.filters = preference.filters.map((filter) => {
              filter.selected = false;
              return filter;
            });
            updateUserOrderPreference(config.ctradeUrl, preference, orderPreferences.updateToken);
            setOrderPreferences(updatedPreferences);
          }
        }
      },
      setOrderFilter: (orderId: string, filters: ISelectableOption[]) => {
        if (orderPreferences) {
          const updatedPreferences = cloneDeep(orderPreferences);
          const preference = updatedPreferences.settings.find((item) => item.orderId === orderId);

          if (preference) {
            preference.filters = filters;
            updateUserOrderPreference(config.ctradeUrl, preference, orderPreferences.updateToken);
            setOrderPreferences(updatedPreferences);
          }
        }
      },
    },
  };

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

function useUser() {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }

  return context;
}

export { UserContext, UserProvider, useUser };
