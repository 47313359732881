import React, { useState } from "react";
import styles from "./DesksMoreOrLessChevronButton.module.scss";
import Button from "__legacy/sharedFolder/components/common/Button/Button";
import { useDistribution } from "./DistributionContext";
import { IDeskView } from "sharedFolder/Models/IDetails";

interface MoreLessChevronButtonProps {
  otherActions?: JSX.Element;
  status?: string;
  closeText: string;
  openText: string;
  onToggle?: (isOpen: boolean) => void;
  desk: IDeskView;
  children: JSX.Element;
  showAction: "delete" | "add";
}

export const DesksMoreOrLessChevronButton = (props: MoreLessChevronButtonProps) => {
  const [displayContents, setDisplayContents] = useState(props.desk.isDeskOpen || false);
  const context = useDistribution();
  const onToggle = () => {
    if (props.onToggle) {
      props.onToggle(!displayContents);
    }
    setDisplayContents(!displayContents);
  };
  const areRecipientsAllSuccessfullyAdded = props.desk.recipients.every((desk) => desk.status === "success");
  return (
    <>
      <div className={styles.chevronPointer}>
        <div className={styles.stretchColumn}>
          <Button
            dataTest={`show-desk-${props.desk.deskId}`}
            type="flat"
            icon={displayContents ? "arrow-drop-down" : "arrow-drop-right"}
            onClick={onToggle}
            className={styles.buttonOverride}
          >
            {displayContents ? props.closeText : props.openText}
          </Button>
        </div>
        <div className={styles.buttonColumn}>
          {props.showAction === "add" && (
            <Button
              dataTest={`distribution-btn-add-desk-${props.desk.deskId}`}
              disabled={props.desk.isDeskDisabled}
              type="flat"
              icon="add-circle"
              className={styles.rightSideButton}
              onClick={() => context.dispatch({ type: "addDesk", payload: props.desk })}
            ></Button>
          )}
          {props.showAction === "delete" && (
            <Button
              dataTest={`distribution-btn-delete-desk-${props.desk.deskId}`}
              disabled={props.desk.isDeskDisabled || areRecipientsAllSuccessfullyAdded}
              type="flat"
              icon="delete"
              className={styles.rightSideButton}
              onClick={() => context.dispatch({ type: "removeDesk", payload: props.desk })}
            ></Button>
          )}
        </div>
        {props.otherActions && <>{props.otherActions}</>}
      </div>
      {displayContents && <>{props.children}</>}
    </>
  );
};
