import React from "react";
import { SupplyPrice, SupplyPriceProps } from "___REFACTOR___/models";
import { UnitValueEditor } from "../UnitValue";

function SupplyPriceEditor(props: SupplyPriceEditor.Props) {
  return (
    <UnitValueEditor
      {...props}
      onChange={onChange}
      units={SupplyPriceProps.prototype.unitArr}
      defaultUnit={SupplyPriceProps.prototype.JSONDefaults?.unit}
      editorType="SupplyPrice"
    />
  );

  function onChange(value: SupplyPrice) {
    const next = new SupplyPrice(value);

    props.onChange?.(next);
  }
}

export { SupplyPriceEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace SupplyPriceEditor {
  export interface Props extends Omit<UnitValueEditor.Props, "value" | "onChange"> {
    value: Value;
    onChange: (value: Value) => void;
  }

  export type Value = SupplyPrice | undefined;
}
