import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { get } from "lodash-es";
import { useShallowStateRef } from "@/utils";
import { ARROW_KEY, EDIT_KEY, SPECIAL_KEY } from "@/constants";
import { DataModel, dropdown as dropdownModel } from "@/models";
import { DropdownProps, DropdownItem as Item, DropdownInstance, ImgProps } from "@/components";
import { InputProps, InputFocusEvent } from "../../Input";
import { Container } from "../../Container";
import { Icon } from "../../Icon";
import "./Select.scss";

function SelectInput(props: SelectInputProps) {
  props = { ...props };

  let {
    img,
    data,
    value,
    onFilter,
    dataTest,
    filterKey,
    noDataStatus,
    dropdownStatus,
    nonFilterSelect,
    dropdownClassName,
    hybridMode,
  } = props;

  const [state, setState] = useShallowStateRef<State>({ ...defaultState });

  if (value instanceof DataModel) {
    img = state.shadowFocus ? undefined : value?._.img;

    value = value.toSelectView?.();
  }

  if (!value) value = "";

  const originalData = data;
  data = state.filtered || originalData;

  if (!data || !data.length) dropdownStatus = dropdownStatus || noDataStatus;

  dropdownClassName = classNames(dropdownClassName, "select");

  let inputValue = value;

  if (state.shadowFocus) inputValue = state.search;
  if (nonFilterSelect) inputValue = value;

  const input = useRef<HTMLInputElement>(null);
  const dropdown = useRef<DropdownInstance>(null);
  const searchIndexTimeoutId = useRef(-1);

  props.className = classNames(props.className, "select", {
    open: state.open,
  });

  useEffect(onMount, []);
  useEffect(setIndex, [state.indexSearch]);
  useEffect(controlDropdown, [state.open]);
  useEffect(updateDropdown, [data, dropdownStatus, dropdown, dropdownClassName]);

  function onMount() {
    if (props.input) props.input.current = input.current;

    function onUnmount() {
      if (props.input) props.input.current = null;
    }

    return onUnmount;
  }

  function updateDropdown() {
    if (dropdown.current && !dropdown.current.open) return;

    dropdownModel.update({
      data,
      dropdown,
      dataTest,
      status: dropdownStatus,
      className: dropdownClassName,
    });
  }

  function controlDropdown() {
    if (state.open) showDropdown();
    //
    else dropdownModel.hide();
  }

  async function showDropdown() {
    const target = input.current;

    if (target) {
      const item = await dropdownModel.show({
        target,
        data,
        dataTest,
        status: dropdownStatus,
        dropdown,
        className: dropdownClassName,
      });

      if (hybridMode) {
        const search = state.search;

        setState({ open: false, shadowFocus: false, search: "", filtered: null });

        if (item || search) props.onChange?.(item || search);

        //
      } else {
        setState({ open: false, shadowFocus: false, search: "", filtered: null });

        if (item) props.onChange?.(item);
      }

      if (window.innerWidth <= 768) {
        input.current?.blur();
      }
    }
  }

  function setIndex() {
    if (!state.indexSearch) return;

    const { index = 0, setIndex, data, list } = dropdown.current || {};

    function reducer(search: string, acc: any, item: Item, i: number) {
      if (startsWith(search, itemFilterSelector(item))) {
        acc.array.push(i);
        acc.map[i] = acc.array.length - 1;
      }

      return acc;
    }

    function findAll(search: string) {
      return data?.reduce(reducer.bind(null, search), { array: [], map: {} });
    }

    function resolveNextIndex(search: string) {
      const match = findAll(search);
      const matchIndex = match?.map[index];
      const indexOfLastMatch = match?.array[match?.array.length - 1];

      let nextIndex;

      // if (search.length > 1 && match.array.length) nextIndex = index;
      //
      if (typeof matchIndex !== "number") nextIndex = match?.array[0];
      //
      else if (index === indexOfLastMatch) nextIndex = match?.array[0];
      //
      else nextIndex = match?.array[matchIndex + 1];

      return nextIndex;
    }

    let nextIndex = resolveNextIndex(state.indexSearch);

    if (typeof nextIndex !== "number") {
      const lastChar = state.indexSearch.slice(-1);

      nextIndex = resolveNextIndex(lastChar);
    }

    if (typeof nextIndex === "number") {
      setIndex?.(nextIndex);

      list?.current?.scrollToItem(nextIndex, "smart");
    }
  }

  function updateIndexSearch(key: string) {
    clearTimeout(searchIndexTimeoutId.current);

    searchIndexTimeoutId.current = setTimeout(setState.bind(null, { indexSearch: "" }), 400);

    setState({ indexSearch: state.indexSearch + key });
  }

  function itemFilterSelector(item: Item) {
    if (item instanceof DataModel) {
      item = item.toDropdownView();

      //
    } else if (typeof filterKey === "function") {
      item = filterKey(item);

      //
    } else if (filterKey) {
      item = get(item, filterKey);
    }

    return item;
  }

  function itemFilterer(search: string, item: Item) {
    item = itemFilterSelector(item);

    search = search.toLowerCase();

    const str = `${item}`.toLowerCase();

    const res = startsWith(search, str) || str.split(" ").some(startsWith.bind(null, search)) || str?.includes(search);

    return res;
  }

  function filter(search: string) {
    if (!originalData) return;

    if (!search) return setState({ filtered: null });

    const filtered = originalData.filter(itemFilterer.bind(null, search));

    setState({ filtered });
  }

  function onSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    const search = e.currentTarget.value;

    if (nonFilterSelect) return;

    setState({ search });

    if (!onFilter) return filter(search);

    onFilter(search);
  }

  function onClick() {
    if (hybridMode) {
      setState({ open: true, shadowFocus: true });
    } else {
      setState({ open: true, search: "", shadowFocus: true });
    }
  }

  function onFocus(e: InputFocusEvent) {
    if (hybridMode) {
      setState({ open: true, shadowFocus: true });
    } else {
      setState({ open: true, search: "", shadowFocus: true });
    }

    props.onFocus?.(e);
  }

  function onBlur(e: InputFocusEvent) {
    if (hybridMode) {
      setState({ open: false, shadowFocus: false, filtered: null });
    } else {
      setState({ open: false, search: "", shadowFocus: false, filtered: null });
    }

    props.onBlur?.(e);
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (hybridMode) {
      if (nonFilterSelect && !ARROW_KEY[e.key]) {
        updateIndexSearch(e.key);
      }

      if (state.shadowFocus) return;

      if (EDIT_KEY[e.key]) {
        setState({ open: true, shadowFocus: true, filtered: null });

        return;
      }

      if (!SPECIAL_KEY[e.key]) {
        setState({ open: true, shadowFocus: true });
      }

      return;
    }

    if (nonFilterSelect && !ARROW_KEY[e.key]) {
      updateIndexSearch(e.key);
    }

    if (state.shadowFocus) return;

    if (EDIT_KEY[e.key]) {
      setState({ open: true, shadowFocus: true, search: "", filtered: null });

      return;
    }

    if (!SPECIAL_KEY[e.key]) {
      setState({ open: true, shadowFocus: true });
    }
  }

  return (
    <Container {...props} onFocus={onFocus} onBlur={onBlur} img={img} clearable>
      <input
        value={inputValue}
        onChange={onSearchChange}
        onKeyDown={onKeyDown}
        onClick={onClick}
        placeholder={props.placeholder}
        disabled={props.disabled}
        ref={input}
        data-test={`${props.dataTest}.input`}
        type="text"
      />
      <Icon icon="chevron-down" align="right" onClick={onClick} />
    </Container>
  );
}

function startsWith(substr: string, str: string) {
  return str?.toLowerCase().startsWith(substr.toLowerCase());
}

const defaultState = {
  search: "",
  indexSearch: "",
  shadowFocus: false,
  filtered: null,
  open: false,
} as State;

const { setTimeout, clearTimeout } = window;

const Observer = observer(SelectInput);

export { Observer as SelectInput };

export interface SelectInputProps extends InputProps<Item> {
  data?: DropdownProps["data"];
  onFilter?: (search: string) => void;
  dropdownClassName?: string;
  dropdownStatus?: Status;
  getDropdownImg?: (value: Item) => ImgProps["img"] | undefined;
  filterKey?: string | number | ((item: Item) => string | number);
  noDataStatus?: Status;
  nonFilterSelect?: boolean;
  hybridMode?: boolean;
}

interface State {
  open: boolean;
  search: string;
  indexSearch: string;
  shadowFocus: boolean;
  filtered?: DropdownProps["data"];
}
