import React from "react";
import styles from "./EditorRowEmptyValue.module.scss";

interface IEditorRowEmptyValueProps {
  required?: boolean;
}

const EditorRowEmptyValue = (props: IEditorRowEmptyValueProps) => {
  const { required = false } = props;
  return (
    <div className={styles.emptyItem}>
      --
      {required && <span className={styles.requiredText}>Required</span>}
    </div>
  );
};

export default EditorRowEmptyValue;
