import { ISubsText, ISubsTextView } from "sharedFolder/Models/ISubsText";
import { IMapper, withNullable } from "./map";

const map: IMapper<ISubsText, ISubsTextView> = {
  toApi: (viewModel: ISubsTextView) => {
    return {
      title: viewModel.title,
      value: viewModel.value,
      display: viewModel.display,
    };
  },
  toView: (viewModel: ISubsText) => {
    return {
      title: viewModel.title,
      value: viewModel.value,
      display: viewModel.display,
    };
  },
  emptyViewModel: {
    title: "",
    display: "",
    value: "",
  },
};

export const mapSubsText = withNullable(map);
