import Axios, { AxiosRequestConfig, AxiosError, AxiosResponse, AxiosInstance, CancelTokenSource, Canceler, Method } from "axios";

class Request {
  constructor(config?: AxiosRequestConfig) {
    this.axios = Axios.create(config) as Request.Axios;
  }

  interceptors = {
    request: {
      use: (resolver?: Request.Interceptor.Resolver, rejecter?: Request.Interceptor.Rejecter) => {
        return this.axios.interceptors.request.use(resolver as any, rejecter);
      },
      eject: (id: number) => this.axios.interceptors.request.eject(id),
    },

    response: {
      use: (resolver?: Response.Interceptor.Resolver, rejecter?: Response.Interceptor.Rejecter) => {
        return this.axios.interceptors.response.use(
          resolver ? (res) => resolver(new Response(res)) : undefined,
          rejecter ? (err) => rejecter(new Response(err)) : undefined
        );
      },
      eject: (id: number) => this.axios.interceptors.response.eject(id),
    },
  };

  get = <SuccessData, FailureData = any>(url: string, config?: AxiosRequestConfig) =>
    this.exec<SuccessData, FailureData>("get", url, undefined, config);

  post = <SuccessData, FailureData = any>(url: string, data, config?: AxiosRequestConfig) =>
    this.exec<SuccessData, FailureData>("post", url, data, config);

  put = <SuccessData, FailureData = any>(url: string, data, config?: AxiosRequestConfig) =>
    this.exec<SuccessData, FailureData>("put", url, data, config);

  patch = <SuccessData, FailureData = any>(url: string, data, config?: AxiosRequestConfig) =>
    this.exec<SuccessData, FailureData>("patch", url, data, config);

  delete = <SuccessData, FailureData = any>(url: string, data?, config?: AxiosRequestConfig) =>
    this.exec<SuccessData, FailureData>("delete", url, data, config);

  exec = <SuccessData, FailureData>(method: Method, url: string, data, config?: AxiosRequestConfig) => {
    // eslint-disable-next-line import/no-named-as-default-member
    const cancelTokenSource = Axios.CancelToken.source();
    const extensions = {
      method,
      url,
      data,
      cancelTokenSource,
      cancelToken: cancelTokenSource.token,
    };
    const extendedConfig = {
      ...config,
      ...extensions,
      executor: (override) => this.exec<SuccessData, FailureData>(method, url, data, { ...config, ...override }),
    } as Config<SuccessData>;

    return Promise<SuccessData, FailureData>(extendedConfig, (resolve, reject) =>
      this.axios<SuccessData>(extendedConfig).then(resolve).catch(reject)
    );
  };
}

class Response<SuccessData, FailureData> {
  static isErrorSeed(seed): seed is Error {
    return (seed.isAxiosError || seed.__CANCEL__) === true;
  }

  constructor(seed: Response.Seed<SuccessData>) {
    if (Response.isErrorSeed(seed)) seed = { ...seed, ...seed.response, __CANCEL__: seed.__CANCEL__ };

    Object.assign(this, seed);
  }

  get ok() {
    return this.status < 400;
  }

  get cancelled() {
    return this.__CANCEL__;
  }

  get isError() {
    return this.isAxiosError;
  }

  get failureData() {
    return this.data as unknown as FailureData;
  }
}

function Promise<SuccessData, FailureData>(config: Config<SuccessData>, axiosExecutor: Promise.Axios.Executor<SuccessData>) {
  let resolve!: Promise.Resolve<SuccessData>;
  let reject!: Promise.Reject<SuccessData>;

  const promise = new window.Promise((resolver, rejecter) => {
    resolve = resolver;
    reject = rejecter;
  }) as Promise<Response<SuccessData, FailureData>>;

  promise.config = config;
  promise.cancel = config.cancelTokenSource.cancel;

  axiosExecutor(
    (res) => resolve(new Response(res)),
    (err) => resolve(new Response(err))
  );

  return promise;
}

export { Request, Response };

/*-------------------------------------------------------------*/
/*                            TYPES                            */
/*-------------------------------------------------------------*/

interface Request {
  axios: Request.Axios;
}
declare namespace Request {
  export { Result, Promise, Config as ExtendedConfig, AxiosRequestConfig as Config };
}

declare namespace Request {
  interface Axios extends AxiosInstance {
    <SuccessData>(config: Config<SuccessData>): Promise<AxiosResponse<SuccessData>>;
  }

  namespace Interceptor {
    type Resolver = (config: Config<any>) => Config<any>;
    type Rejecter = (err: any) => any;
  }
}

interface Config<SuccessData = any> extends AxiosRequestConfig {
  cancelTokenSource: CancelTokenSource;
  executor(override?: AxiosRequestConfig): Promise<Response<SuccessData>>;
  isRetry?: boolean;
}

interface Response<SuccessData = any, FailureData = any> extends AxiosResponse<SuccessData>, Omit<Error, "response"> {
  config: Config<SuccessData>;
}
declare namespace Response {
  type Seed<SuccessData> = AxiosResponse<SuccessData> | Error;

  namespace Interceptor {
    type Resolver = (res: Response) => Response | globalThis.Promise<Response>;
    type Rejecter = (err: Response) => Response | globalThis.Promise<Response>;
  }
}

interface Error extends Omit<AxiosError, "config"> {
  __CANCEL__: boolean;
}

interface Promise<SuccessData> extends globalThis.Promise<SuccessData> {
  config: AxiosRequestConfig;
  cancel: Canceler;
}
declare namespace Promise {
  type Resolve<SuccessData> = (res: Response<SuccessData>) => void;
  type Reject<FailureData> = (res?: Response<FailureData>) => void;

  namespace Axios {
    type Executor<SuccessData> = (resolve: Resolve<SuccessData>, reject: Reject) => void;
    type Resolve<SuccessData> = (res: AxiosResponse<SuccessData>) => void;
    type Reject = (reason?: any) => void;
  }
}

type Result<SuccessData> = Promise<Response<SuccessData>>;
