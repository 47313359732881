import React from "react";
import { Demurrage, DemurrageProps } from "___REFACTOR___/models";
import { UnitValueEditor } from "../UnitValue";

function DemurrageEditor(props: DemurrageEditor.Props) {
  return (
    <UnitValueEditor
      {...props}
      onChange={onChange}
      units={DemurrageProps.prototype.unitArr}
      defaultUnit={DemurrageProps.prototype.JSONDefaults?.unit}
      editorType="Demurrage"
    />
  );

  function onChange(value: Demurrage) {
    const next = new Demurrage(value);

    props.onChange?.(next);
  }
}

export { DemurrageEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace DemurrageEditor {
  export interface Props extends Omit<UnitValueEditor.Props, "value" | "onChange"> {
    value: Value;
    onChange: (value: Value) => void;
  }

  export type Value = Demurrage | undefined;
}
