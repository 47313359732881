import { ALLOW_OVERRIDING_CONFIG_JSON, config } from "@/config";
import { storage } from "@/utils";
import "./Env.scss";

class Env {
  setup = () => {
    if (!ALLOW_OVERRIDING_CONFIG_JSON) return;

    const env = getEnv();
    const eEnv = document.createElement("sea-env");
    const eEnvSelect = document.createElement("select");
    const eCtradeUrl = document.createElement("span");

    eEnvSelect.onclick = onEnvClick;
    eEnvSelect.className = "env";
    eEnvSelect.onchange = onEnvChange;
    ENV_OPTIONS.forEach(appendOption.bind(null, eEnvSelect, env));

    eCtradeUrl.innerHTML = config.ctradeUrl;

    eEnv.appendChild(eEnvSelect);
    eEnv.appendChild(eCtradeUrl);

    document.body.append(eEnv);

    function onEnvChange(e) {
      setEnv(e.target.value);
    }

    function appendOption(eSelect, selected, option) {
      const eOption = document.createElement("option");

      Object.assign(eOption, option);

      if (option.value === selected) eOption.selected = true;

      eSelect.appendChild(eOption);
    }

    function onEnvClick(e: Event) {
      e.stopPropagation();
    }
  };

  getEnv = getEnv;
  setEnv = setEnv;
}

const ENV_STORAGE_KEY = "TRADE_ENV";

function getEnv() {
  const env = storage.local.get(ENV_STORAGE_KEY, null);

  return env;
}
function setEnv(env) {
  localStorage.clear();

  storage.local.set(ENV_STORAGE_KEY, env);

  window.location.reload();
}

const ENV_OPTIONS = [
  { innerHTML: "Config.json", value: null },
  { innerHTML: "Dev 1", value: "dev1" },
  { innerHTML: "Dev 2", value: "dev2" },
  { innerHTML: "Dev 3", value: "dev3" },
  { innerHTML: "Dev 4", value: "dev4" },
  { innerHTML: "Test 1", value: "test1" },
  { innerHTML: "Test 2", value: "test2" },
  { innerHTML: "Test 3", value: "test3" },
  { innerHTML: "Test 4", value: "test4" },
  { innerHTML: "Int", value: "int" },
  { innerHTML: "Prod", value: "prod" },
];

export const defaultOrderCount = 5;

export const env = new Env();
