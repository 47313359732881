import { IAttachment, INegAttachment } from "__legacy/sharedFolder/Models/IAttachment";
import * as api from "../../../../api/negotiations/models";
import { mapOrderNegType } from "sharedFolder/mappers/mapOrderNegType";

export function mapAttachment(attachment: api.IAttachment): IAttachment {
  return {
    user: attachment.user,
    fileId: attachment.fileId,
    fileName: attachment.fileName,
    fileSizeInBytes: attachment.fileSizeInBytes,
    timeStamp: attachment.timeStamp,
    offerRepToken: attachment.offerRepToken,
    uploadedBy: attachment.user.companyType,
  };
}

export function mapNegAttachment(attachment: api.INegAttachment): INegAttachment {
  return {
    ...mapAttachment(attachment),
    negId: attachment.negId,
    owner: attachment.owner,
    vesselNames: attachment.vesselNames,
    type: mapOrderNegType.toView(attachment.type),
    status: attachment.status,
  };
}
