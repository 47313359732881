import { Context } from "../../Orders";
import { dialog } from "@/models";
import { actionRetry } from "@/utils";
import { Response } from "@/request";

export enum ArchivingAction {
  Archive = "archive",
  Unarchive = "unarchive",
  BulkArchive = "bulk-archive",
  BulkUnarchive = "bulk-unarchive",
}

export abstract class BaseArchivingAction<Model, ResponsePayload> {
  protected BulkDefaultWaitRetryCount = 30;
  protected SingleDefaultWaitRetryCount = 5;
  protected DefaultRetryDelayMs = 1000;

  protected abstract actionName: ArchivingAction;
  protected abstract entityName: string;
  protected abstract operationName: string;
  protected abstract finishedOperationName: string;
  protected abstract countOfRetries: number;
  protected abstract retryDelay: number;

  protected abstract getEntityArray: () => IArchivingEntity[];
  protected abstract isModelUpdatedAsync: () => Promise<boolean>;
  protected abstract archivingActionAsync: () => Promise<Response<ResponsePayload>>;

  protected model: Model;
  protected context: Context;

  constructor(model: Model, context: Context) {
    this.model = model;
    this.context = context;
  }

  public async performActionAsync(): Promise<any> {
    this.validateModel();

    this.showLoading();

    const response = await this.archivingActionAsync();

    if (!response?.ok) {
      await dialog.show({
        status: this.getFailureStatus(),
        dataTest: `${this.actionName}-${this.entityName.toLowerCase()}-fail`,
      });

      this.hideLoading();

      return response;
    }

    return await actionRetry(
      {
        action: async () => await this.isModelUpdatedAsync(),
        handleSuccess: () => this.handleSuccess(response),
        handleFailure: () => this.handleFailure(response),
      },
      this.countOfRetries,
      this.retryDelay
    );
  }

  protected getArrayStatus = () => this.context.orderNegotiationStore?.orderArrayStatus;

  private isArchivingSucceeded = (entity: IArchivingEntity) => entity.isArchived;
  private isUnarchivingSucceded = (entity: IArchivingEntity) => !entity.isArchived;

  private validateModel(): void {
    if (!this.model) {
      console.error(`${this.operationName} ${this.entityName}/s:`, this.model, this.context);

      dialog.show({
        status: this.getFailureStatus(),
        dataTest: `${this.actionName}-${this.entityName}-model-fail`,
      });

      throw new Error(`Unable to perform ${this.operationName} of ${this.entityName}/s: ${this.entityName}/s model is undefined`);
    }
  }

  private showLoading(): void {
    const arrayStatus = this.getArrayStatus();

    if (arrayStatus) {
      arrayStatus.loading = true;
      arrayStatus.message = `${this.operationName}...`;
    }
  }

  private hideLoading(): void {
    const arrayStatus = this.getArrayStatus();

    if (arrayStatus) {
      arrayStatus.loading = false;
      arrayStatus.message = null;
    }
  }

  private getFailureStatus(): Status {
    const message = `The following ${this.entityName}${this.getFailedEntitiesCount() > 1 ? "s" : ""} could not be ${
      this.finishedOperationName
    }, please try again later.\n\n${this.getFailedMessage()}`;

    return {
      type: "failure",
      title: `${this.operationName} Failure`,
      message: message,
    };
  }

  private handleSuccess(response: Response<ResponsePayload>): Response<ResponsePayload> {
    this.hideLoading();

    return response;
  }

  private handleFailure(response: Response<ResponsePayload>): Response<ResponsePayload> & { succeededEntitiesIds: any } {
    dialog.show({
      status: this.getFailureStatus(),
      dataTest: `${this.actionName}-${this.entityName}-request-failure`,
    });

    this.hideLoading();

    response.ok = false;

    const extendedResponse = { ...response, succeededEntitiesIds: this.getSucceededEntitiesIds() };

    return extendedResponse;
  }

  protected getValidationAction = () => {
    if (this.actionName.includes("unarchive")) {
      return this.isUnarchivingSucceded;
    }

    return this.isArchivingSucceeded;
  };

  private getSucceededEntitiesIds = () => {
    return this.getEntityArray()
      .filter((entity) => this.getValidationAction()(entity))
      .map((failedEntity: IArchivingEntity) => failedEntity.orderId ?? failedEntity.id);
  };

  private getFailedEntitiesIds = () => {
    return this.getFailedEntities().map((failedEntity: IArchivingEntity) => failedEntity.orderId ?? failedEntity.id);
  };

  private getFailedEntitiesCount = () => {
    return this.getFailedEntities().length;
  };

  private getFailedEntities = () => {
    return this.getEntityArray().filter((entity) => this.getValidationAction()(entity) === false);
  };

  private getFailedMessage = () => {
    const failedEntitiesIds = this.getFailedEntitiesIds();

    if (failedEntitiesIds) {
      return failedEntitiesIds.map((failedId: string) => `${this.entityName} Id - ${failedId.slice(0, 6)}`).join("\n");
    }

    return "";
  };
}

export type IArchivingEntity = {
  orderId?: string;
  id: string;
  isArchived: boolean;
};
