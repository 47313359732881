import React from "react";
import classnames from "classnames";
import { DateOffsetInput, DateOffsetInputProps } from "@/components";
import { FieldProps as FullFieldProps } from "../../Field";
import { Container } from "../../Container";

export function DateOffsetField(props: DateOffsetFieldProps) {
  let { className, desc, ...rest } = props;

  className = classnames(className, "DateOffset");

  return (
    <Container {...rest} className={className} desc={desc}>
      <DateOffsetInput {...rest} />
    </Container>
  );
}

export interface DateOffsetFieldProps extends FieldProps, DateOffsetInputProps {}

type FieldProps = Omit<FullFieldProps, "value" | "onChange">;
