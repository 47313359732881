import React, { ReactNode, useEffect, useRef } from "react";
import classNames from "classnames";
import { DEVELOPMENT } from "@/config";
import { DateOffset } from "@/models";
import { InputProps } from "../../Input";
import { Container } from "../../Container";
import { NumberInput } from "../Number";
import "./DateOffset.scss";

export function DateOffsetInput(props: DateOffsetInputProps) {
  props = { ...props };

  props.className = classNames(props.className, "DateOffset");
  props.dataTest = props.dataTest || "dateOffset";

  let { value, units = [], className, onChange, dataTest, ...rest } = props;

  if (DEVELOPMENT) units = [...units, "second"];

  const input = useRef<HTMLInputElement | null>(null);

  useEffect(onMount, []);

  function onMount() {
    if (props.input) props.input.current = input.current;

    return onUnmount;
  }

  function onUnmount() {
    if (props.input) props.input.current = null;
  }

  function onUnitChange(unit: Unit, unitValue: number) {
    onChange?.(new DateOffset({ ...value, [unit]: unitValue }));
  }

  function NumberInputHOC(acc: ReactNode[], unit: Unit, i: number, arr: string[]) {
    const input = (
      <NumberInput
        dataTest={`${dataTest}.${unit}`}
        {...rest}
        value={value?.[unit]}
        onChange={onUnitChange.bind(null, unit)}
        {...PROP_MAP[unit]}
        key={i}
      />
    );
    const colon = <span key={arr.length + i}>:</span>;

    if (i < arr.length - 1) return [...acc, input, colon];

    return [...acc, input];
  }

  return (
    <Container {...props} className={className}>
      {units?.reduce(NumberInputHOC, [])}
    </Container>
  );
}

const PROP_MAP = {
  second: { placeholder: "secs" },
  minute: { placeholder: "mins", max: 59 },
  hour: { placeholder: "hrs", max: 72 },
  month: { placeholder: "mths" },
  year: { placeholder: "yrs" },
};

export interface DateOffsetInputProps extends InputProps<Value> {
  units?: Unit[];
}

type Unit = "second" | "minute" | "hour" | "month" | "year";

type Value = DateOffset | null | undefined;
