import { useRef, useState } from "react";

export function useShallowStateOld<T extends Shallow>(initialState: T) {
  const [, setCounter] = useState(0);
  const state = useRef<T>({ ...initialState });
  const newStateRef = useRef<T>({ ...initialState });

  function setState(partialState?: Shallow) {
    Object.assign(state.current, partialState);

    newStateRef.current = { ...state.current };

    setCounter((counter) => counter + 1);
  }

  return [state.current, setState, newStateRef.current] as const;
}

interface Shallow {
  [name: string]: any;
}
