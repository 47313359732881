import { object, number } from "yup";
import { Commission } from "../Commission";

// https://github.com/jquense/yup
const schema = object({
  value: number().required("Required"),
}).transform(toJSON);

function toJSON(commission: Commission) {
  return commission?.toJSON();
}

export { schema };
