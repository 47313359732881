import React from "react";
import { BunkerProps, BunkerRedelivery } from "@/models";
import { IBunkerView } from "sharedFolder/Models/IBunker";
import { BunkerBaseEditor } from "../BunkerBase";

export function BunkerRedeliveryEditor(props: Props) {
  props = { ...props };

  function onChange(key: keyof BunkerRedelivery, propertyValue) {
    const newValue = { ...props.value, [key]: propertyValue };
    const next = new BunkerRedelivery(newValue);

    props.onChange?.(next);
  }

  return (
    <BunkerBaseEditor {...props} onChange={onChange} bunkerType="Re-Delivery" dataTest={props.dataTest || "bunker-redelivery"} />
  );
}

interface Props extends Omit<BunkerProps, "value" | "onChange" | "dataTest"> {
  value?: BunkerRedelivery | IBunkerView;
  onChange?: (value: BunkerRedelivery) => void;
  dataTest?: string;
}

export type BunkerRedeliveryEditorProps = Props;
