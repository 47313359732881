import React from "react";
import styles from "./WarningMessage.module.scss";
import Icon from "__legacy/sharedFolder/components/common/Icon/Icon";

export const WarningMessage = (props: { title: string; children: JSX.Element }) => (
  <div className={styles.warningContainer}>
    <Icon icon="info-outline" />
    <div>
      <div className={styles.title}>{props.title}</div>
      <div className={styles.content}>{props.children}</div>
    </div>
  </div>
);
