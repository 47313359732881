import React from "react";
import classNames from "classnames";
import { SelectField, SelectFieldProps } from "@/components";

import "./Location.scss";

export function LocationEditor(props: LocationEditorProps) {
  props = { ...props };

  props.className = classNames(props.className, "Location");

  return <SelectField {...props} placeholder="e.g. UK, USA" dropdownClassName="Location-dropdown" />;
}

export type LocationEditorProps = SelectFieldProps;
