export const isLocationValid = (value?: ILocationView, required?: boolean): boolean => {
  return required ? Boolean(value && value.locationId) : true;
};

export interface ILocationSafeWarranty {
  key: string;
  value: { min: number; max: number };
}

export interface ILocationView {
  country: string;
  countryCode: string;
  countryId: string;
  locationId: string;
  name: string;
  zone: string;
  zoneId: string;
  globalZone: string;
  parents?: string[];
  notes: string;
  safeBerthsMin?: number;
  safeBerthsMax?: number;
  safeAnchoragesMin?: number;
  safeAnchoragesMax?: number;
  safePortsMin?: number;
  safePortsMax?: number;
  display?: string;
  shortDisplay?: string;
}

export type ILocationViewPart = Pick<
  ILocationView,
  "locationId" | "name" | "countryCode" | "countryId" | "zone" | "zoneId" | "parents" | "country"
>;
