import React, { forwardRef, Ref, useImperativeHandle, useRef } from "react";
import { observer } from "mobx-react";
import { capitalizeFirstLetter } from "@/utils";
import { AggridHeaderCompParams, AggridHeaderComp, Icon } from "@/components";
import { renderTooltipString } from "../utils";
import "./MultiValueHeaderCell.scss";

function MultiValueHeaderCell(params: AggridHeaderCompParams, ref: Ref<AggridHeaderComp>) {
  const { h1, h2, context, column, api, sequence } = params;
  const { colId } = column;
  const { columnStateAPI, enableSortingFilteringPromise, onSortFilterAccess } = context;
  const columnState = columnStateAPI?.state?.[colId];
  const multiValueSortName = columnState?.multiValueSortName;
  const sortDirection = columnState?.sort;
  const isFilterActive = columnState?.isFilterActive;
  const sortIcon = sortDirection && (sortDirection === "asc" ? "ascending" : "descending");
  const tooltip = renderTooltipString([h1, h2]);
  const eIcon = useRef<HTMLElement>(null);

  useImperativeHandle(ref, createRefProps);

  return (
    <sea-aggrid-multi-value-header-cell>
      <sea-aggrid-multi-value-header-cell-headings title={tooltip}>
        {sequence.map(SequenceHOC)}
      </sea-aggrid-multi-value-header-cell-headings>
      <Icon icon="filter-clear" onClick={destroyFilter} hidden={!isFilterActive} />
      <Icon icon="hamburger" onClick={showColumnMenu} dom={eIcon} />
    </sea-aggrid-multi-value-header-cell>
  );

  function createRefProps() {
    return {
      refresh,
    };
  }

  function refresh(params: AggridHeaderCompParams) {
    return true;
  }

  async function showColumnMenu() {
    onSortFilterAccess?.();

    await enableSortingFilteringPromise;

    params.showColumnMenu(eIcon.current);
  }

  function destroyFilter() {
    api.destroyFilter(colId);
  }

  async function updateSort(multiValueSortNameNext, event) {
    event?.persist();

    onSortFilterAccess?.();

    await enableSortingFilteringPromise;

    columnStateAPI?.updateMultiValueSort(colId, multiValueSortNameNext, params, event);
  }

  function SequenceHOC(item, i) {
    if (Array.isArray(item))
      return (
        <sea-aggrid-multi-value-header-cell-heading-row key={i}>
          {item.map(HeadingHOC)}
        </sea-aggrid-multi-value-header-cell-heading-row>
      );

    return HeadingHOC(item, i);
  }

  function HeadingHOC(item, i) {
    if (!item.heading) return null;

    return (
      <sea-aggrid-multi-value-header-cell-heading title={item.heading} onClick={updateSort.bind(null, item.name)} key={i}>
        <span>{item.heading}</span>
        <Icon name={sortIcon} hidden={item.name !== multiValueSortName} title={capitalizeFirstLetter(sortIcon)} />
      </sea-aggrid-multi-value-header-cell-heading>
    );
  }
}

const ForwardRef = forwardRef(MultiValueHeaderCell);
const Observer = observer(ForwardRef);

// Observer.displayName = "MultiValueHeaderCell";

export { Observer as MultiValueHeaderCell };
