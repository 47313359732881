import { isDayjs } from "dayjs";
import classNames from "classnames";
import { DatePicker } from "office-ui-fabric-react";
import { CustomDate } from "@/models";
import { InputProps } from "../../Input";
import { Container } from "../../Container";
import { NumberInput } from "../Number";
import "./Date.scss";

export function DateInput(props: DateInputProps) {
  props = { ...props };

  props.className = classNames(props.className, "Date");

  let { value, dataTest, time, withoutTimezone, startOfDay } = props;

  const customDate = typeof value === "string" ? new CustomDate({ date: value, withoutTimezone, startOfDay }) : value;

  dataTest = `${props.dataTest}.input`;

  let datePickerDate: Date | undefined;

  if (typeof customDate?.date === "string") {
    datePickerDate = new Date(customDate?.date);
  }

  if (customDate?.date instanceof Date) {
    datePickerDate = customDate?.date;
  }

  if (isDayjs(customDate?.date)) {
    datePickerDate = customDate?.date.toDate();
  }

  return (
    <Container {...props}>
      {time && (
        <div className="Date-time-unit">
          <NumberInput
            placeholder="hr"
            max={23}
            value={customDate?.hour}
            onChange={onTimeChange.bind(null, "hour")}
            dataTest={`${dataTest}.hour`}
          />
          <span>:</span>
          <NumberInput
            placeholder="min"
            max={59}
            value={customDate?.minute}
            onChange={onTimeChange.bind(null, "minute")}
            dataTest={`${dataTest}.minute`}
          />
        </div>
      )}
      <DatePicker
        // @ts-ignore
        calendarProps={calendarProps}
        value={datePickerDate}
        onSelectDate={onDateInputChange}
        disabled={props.disabled}
        styles={styles}
        data-test={dataTest}
        placeholder={props.placeholder}
      />
    </Container>
  );

  function onDateInputChange(date: Date | null | undefined) {
    if (!date) return;

    const next: CustomDate | string | undefined = new CustomDate({ ...customDate, date, withoutTimezone, startOfDay });

    props.onChange?.(next);
    props.onChangeCallback?.(date);
  }

  function onTimeChange(unit: "hour" | "minute", unitValue: number) {
    const next: CustomDate | string | undefined = new CustomDate({
      ...customDate,
      [unit]: unitValue,
      withoutTimezone,
      startOfDay,
    });

    props.onChange?.(next);
  }
}

const calendarProps = {
  className: "sea-input-DateInput-DatePicker",
};

function styles() {
  return { icon: "icon icon--data-range" };
}

export interface DateInputProps extends InputProps<Value> {
  time?: boolean;
  onChangeCallback?: (newDate: Date) => void;
  withoutTimezone?: true;
  startOfDay?: true;
}

type Value = CustomDate | string | undefined;
