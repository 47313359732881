import { useRef, useEffect, HTMLProps } from "react";
import Button from "__legacy/sharedFolder/components/common/Button/Button";
import { INegotiationView } from "sharedFolder/Models/Negotiation/INegotiationView";
import { useConfig } from "__legacy/sharedFolder/ConfigurationContext";
import { useAuth } from "__legacy/auth-provider";

type TSendRecapStatus = {
  success: boolean;
  title: string;
  body: string;
} | null;

interface IProps extends HTMLProps<HTMLButtonElement> {
  ariaLabel?: string | "";
  className?: string;
  dataKey?: string;
  dataTest?: string;
  disabled?: boolean;
  focus?: boolean | false;
  onClick?: () => void;
  onEmailSent: (status: TSendRecapStatus) => void;
  negotiation: INegotiationView;
  type: any;
}

export const SendRecapToAllParties = (props: IProps) => {
  const { ariaLabel, dataKey, dataTest, className, disabled, focus, onEmailSent, onClick, negotiation, type } = props;
  const config = useConfig();
  const { auth } = useAuth();
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const button = buttonRef.current;
    if (focus) {
      button?.focus();
    } else {
      button?.blur();
    }
  }, [focus]);

  function sendFetch() {
    onClick && onClick();
    const success = {
      success: true,
      title: "Recap Sent",
      body: "A recap of this negotiation has been sent to all parties via email",
    };
    const error = {
      success: false,
      title: "Recap not Sent",
      body: "There was an error sending the recap email to all parties. Please try again later",
    };

    fetch(`${config.ctradeUrl}/arcfacade/sendrecapemail/${negotiation.arcNegotiationHash}`, {
      method: "POST",
      headers: {
        "x-entity-authorization": `Bearer ${auth.ctradeToken}`,
        "Clarksons.Cloud.LoginToken": auth.cloudToken,
      },
    })
      .then((response) => onEmailSent(response.status === 200 ? success : error))
      .catch((err) => {
        console.error("an error occured while sending the recap email", err);
        onEmailSent(error);
      });
  }

  return (
    <Button
      className={className}
      ariaLabel={ariaLabel}
      dataKey={dataKey}
      dataTest={dataTest}
      disabled={disabled}
      onClick={sendFetch}
      reactRef={buttonRef}
      type={type}
    >
      Send Recap to all parties
    </Button>
  );
};
