import { useParams } from "react-router-dom";
import { tradeAPI } from "@/apis";
import { router } from "@/models";

export function ArcNegotiationHashRedirect() {
  const { arcNegotiationHash } = useParams() as StringRecord;

  async function getArcNegotiationAndRedirect() {
    const res = await tradeAPI.getArcNegotiation(arcNegotiationHash);

    if (!res.ok || !res.data) return;

    const { orderId, negotiationType } = res.data;

    if (negotiationType === "Coa") {
      router.get("coa.order").replace({ orderId });

      //
    } else {
      router.get("orders.order").replace({ orderId });
    }
  }

  getArcNegotiationAndRedirect();

  return null;
}
