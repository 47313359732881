import { Location, LocationProps } from "../Location";
import { LABELS } from "@/constants";

class DischargeLocation extends Location {}

class DischargeLocationProps extends LocationProps {}

DischargeLocationProps.prototype.label = LABELS["dischargeLocation"];

DischargeLocation.prototype.Props = DischargeLocationProps;
DischargeLocation.prototype._ = new DischargeLocationProps();

export { DischargeLocation, DischargeLocationProps };

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */
