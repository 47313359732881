import React, { createRef, PureComponent } from "react";
import classnames from "classnames";
import { Subscription } from "rxjs";
import styles from "../LaycanBaseEditor/LaycanBaseEditor.module.scss";
import { ILaycanView } from "sharedFolder/Models/IDetails";

interface IProps {
  id: string;
  isValid: boolean;
  required: boolean | undefined;
  onChange: (laycan?: ILaycanView) => void;
  handleFetch: (laycanInputText: string) => void;
  value?: ILaycanView;
  url: string;
  label?: string;
  disabled?: boolean;
  focus?: boolean;
}

interface IState {
  display: string;
}

export class NewLaycanBaseEditor extends PureComponent<IProps, IState> {
  private readonly subscriptions: Subscription[] = [];
  private inputRef = createRef<HTMLInputElement>();

  constructor(props: IProps) {
    super(props);

    this.state = {
      display: props.value ? props.value.display : "",
    };

    this.handleBlur = this.handleBlur.bind(this);
    this.handlePaste = this.handlePaste.bind(this);
    this.handleLaycanChange = this.handleLaycanChange.bind(this);
  }

  public componentDidUpdate(prevProps: IProps) {
    if (this.props.focus && this.props.focus !== prevProps.focus) {
      this.focusInput();
    }

    if (this.props.value?.display && this.props.value?.display !== prevProps.value?.display) {
      this.setState({
        display: this.props.value ? this.props.value.display : "",
      });
    }
  }

  public render() {
    const inputFieldErrorClass = !this.props.isValid && styles.invalid;

    return (
      <div className={styles.inputRow}>
        <div className={styles.inputGroup}>
          {this.props.label && <label htmlFor={this.props.id}>{this.props.label}</label>}
          <input
            ref={this.inputRef}
            type="text"
            id={this.props.id}
            aria-label={this.props.id}
            data-test={this.props.id}
            value={this.state.display}
            className={classnames(styles.input, inputFieldErrorClass)}
            onBlur={this.handleBlur}
            onPaste={this.handlePaste}
            onChange={this.handleLaycanChange}
            onKeyDown={this.handleOnKeyDown}
            disabled={this.props.disabled}
            autoFocus={this.props.focus}
            autoComplete="off"
          />
        </div>
      </div>
    );
  }

  private handlePaste(event: React.ClipboardEvent) {
    // please refer to commit 029fb92c9f24efea46edabf0ffeebd69c2d5465a to add tests when react-testing-library supports event.clipboardData
    this.props.handleFetch(event.clipboardData.getData("Text"));
  }

  private handleBlur(event: React.FormEvent<HTMLInputElement>) {
    this.props.handleFetch(event.currentTarget.value);
  }

  private handleLaycanChange(evt: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ display: evt.target.value });
  }

  private handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      this.handleBlur(event);
    }
  };

  private focusInput = () => {
    if (this.inputRef && this.inputRef.current) {
      this.inputRef.current.focus();
    }
  };
}
