import { object, StringSchema, array } from "yup";
import { Email } from "___REFACTOR___/models/common/Email";

// https://github.com/jquense/yup
function getSchema(itemValue: StringSchema) {
  return object({
    itemArr: array().of(object({ value: itemValue })),
  });
}

export { getSchema };
