import React from "react";
import "./Group.scss";

export function Group(props: Props) {
  const { label } = props;

  return (
    <sea-field-group class={props.className}>
      {label && <sea-field-group-label>{label}</sea-field-group-label>}
      {props.children}
    </sea-field-group>
  );
}

interface Props {
  label?: string;
  className?: string;
  children: React.ReactNode;
}
