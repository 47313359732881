import React from "react";
import classnames from "classnames";
import { RadioInput, RadioInputProps } from "@/components";
import { FieldProps as FullFieldProps } from "../../Field";
import { Container } from "../../Container";

export function RadioField(props: RadioFieldProps) {
  let { className, desc, ...rest } = props;

  className = classnames(className, "Radio");

  return (
    <Container {...rest} className={className} desc={desc}>
      <RadioInput {...rest} />
    </Container>
  );
}

export interface RadioFieldProps extends FieldProps, RadioInputProps {}

type FieldProps = Omit<FullFieldProps, "value" | "onChange">;
