import { PureComponent } from "react";
import { withContext } from "withContext";
import { IAccountView } from "sharedFolder/Models/IDetails";
import { IValidationContext, ValidationContext } from "sharedFolder/contexts/ValidationContext";
import { getAccounts } from "./accountSearch";
import { LookUpSingleEntry } from "../../editors/LookUpSingleEntry/LookUpSingleEntry";

interface IChartererSearchProps {
  id: string;
  baseUrl: string;
  selectedItem?: IAccountView;
  onChange: (value: IAccountView, isValid: boolean) => void;
  disabled?: boolean;
  focus?: boolean;
}

interface IChartererSearchState {
  isValid: boolean;
}

class ChartererSearchComponent extends PureComponent<IChartererSearchProps & IValidationContext, IChartererSearchState> {
  constructor(props: IChartererSearchProps & IValidationContext) {
    super(props);
    this.onItemSelected = this.onItemSelected.bind(this);
    this.getDataItems = this.getDataItems.bind(this);
    const isValid = this.props.selectedItem !== undefined;
    this.state = { isValid };
    this.props.fieldValidityChanged(this.props.id, isValid);
  }

  public render() {
    return (
      <LookUpSingleEntry
        id={"ChartererSearch"}
        isValid={this.state.isValid}
        defaultValue={this.props.selectedItem && this.props.selectedItem}
        autoCompleteFunction={this.getDataItems}
        onChange={this.onItemSelected}
        getDisplayText={(item: IAccountView) => (item && item.display) || ""}
        disabled={this.props.disabled}
        focus={this.props.focus}
      />
    );
  }

  public componentDidUpdate(prevProps: IChartererSearchProps) {
    if (prevProps.selectedItem !== this.props.selectedItem) {
      const isValid = Boolean(this.props.selectedItem && this.props.selectedItem.accountId);

      this.setState({
        isValid: isValid,
      });
      this.props.fieldValidityChanged(this.props.id, isValid);
    }
  }

  public updateLocalValidationState = (isValid: boolean) => {
    this.setState({
      isValid: isValid,
    });
  };

  public componentDidMount() {
    // Required field
    this.updateLocalValidationState(this.state.isValid);
  }

  private async getDataItems(filter: string): Promise<IAccountView[]> {
    return await getAccounts(this.props.baseUrl, filter, true);
  }

  private readonly onItemSelected = (selected: any) => {
    const isValid = !!selected;
    this.props.onChange(selected, isValid);
    this.updateLocalValidationState(isValid);
    this.props.fieldValidityChanged(this.props.id, isValid);
  };
}

export const ChartererSearch = withContext<IChartererSearchProps, IValidationContext>(
  ChartererSearchComponent,
  ValidationContext
);
