import React, { useState } from "react";
import classNames from "classnames";
import { find, groupBy } from "lodash-es";
import { INegAttachment } from "__legacy/sharedFolder/Models/IAttachment";
import { formatDateWithTime } from "sharedFolder/Utilities/getNow";
import { formatFileSize, getFileExtension, getFileIcon, shortenFilename } from "./attachmentUtilites";
import NegotiationAttachmentsPanel from "__legacy/negotiations/components/negotiate/Negotiation/NegotiationAttachments/NegotiationAttachmentsPanel";
import { downloadNegAttachment } from "__legacy/negotiations/services/attachments";
import { Icon } from "@/components";
import "./NegotiationAttachments.scss";

interface IProps {
  negotiationAttachments: INegAttachment[];
  orderId: string;
  updateToken: string;
  emptyText: string;
}

type NegotiationAttachmentTreeType = {
  negotiationId: string;
  title: string;
  attachments: INegAttachment[];
};

const convertToTreeView = (negAttachments: INegAttachment[]): NegotiationAttachmentTreeType[] => {
  const groupedNegAttachments = groupBy(negAttachments, "negId");

  const negIdChartererAndVessels = Object.keys(groupedNegAttachments)
    .map((negId) => find(negAttachments, (negAttachment) => negAttachment.negId === negId))
    .map((negAttachment) => ({
      negId: negAttachment?.negId,
      owner: negAttachment?.owner || `Negotiation: ${negAttachment?.negId}`,
      vesselNames:
        negAttachment?.vesselNames.map(
          (vesselName) => vesselName.replace(" - ", "") // Currently the vesselName are being returned with " - " prepended, fixing that here but should be looked at on BE eventually
        ) || [],
    }));

  const getTitle = (negId: string) => {
    const negAttachment = find(negIdChartererAndVessels, (x) => x.negId === negId);
    const separator = negAttachment?.vesselNames.length !== 0 ? "-" : "";
    return `${negAttachment?.owner} ${separator} ${negAttachment?.vesselNames.join(", ")}`;
  };

  return Object.keys(groupedNegAttachments).map((key) => ({
    negotiationId: key,
    title: getTitle(key),
    attachments: groupedNegAttachments[key],
  }));
};

export const NegotiationAttachments = (props: IProps) => {
  const [closedExpandedItems, setClosedExpandedItems] = useState<string[]>([]);
  const attachmentsTree = convertToTreeView(props.negotiationAttachments);
  const { orderId, updateToken, emptyText } = props;
  const openIt = (id: string) => {
    if (closedExpandedItems.includes(id)) {
      setClosedExpandedItems(closedExpandedItems.filter((x) => x !== id));
    } else {
      setClosedExpandedItems([...closedExpandedItems, id]);
    }
  };

  const AttachmentsList = (props: { attachments: INegAttachment[] }) => {
    return (
      <>
        {props.attachments.map((attachment) => (
          <>
            <div className={classNames("fileField", "field")}>
              <div
                data-test={`download-link-${attachment.fileId}`}
                className={"iconFilenameWrapper"}
                onClick={() =>
                  downloadNegAttachment(orderId, attachment.negId)(attachment.fileId, attachment.fileName, updateToken)
                }
              >
                <Icon icon={getFileIcon(getFileExtension(attachment.fileName))}>{shortenFilename(attachment.fileName)}</Icon>
              </div>
            </div>
            <div className="field">{formatDateWithTime(attachment.timeStamp)}</div>
            <div className="rightAlignField">{formatFileSize(attachment.fileSizeInBytes)}</div>
          </>
        ))}
      </>
    );
  };

  const AttachmentPaperClipButton = (props: {
    negotiationAttachmentTree: NegotiationAttachmentTreeType;
    orderId: string;
    updateToken: string;
  }) => {
    const [fileAttachmentPanel, setFileAttachmentPanel] = useState(false);
    const { attachments, negotiationId, title } = props.negotiationAttachmentTree;
    return (
      <div
        onClick={(evt: React.MouseEvent) => {
          evt.preventDefault();
          evt.stopPropagation();
          setFileAttachmentPanel(true);
        }}
      >
        <Icon icon="attach-file">
          {`(${attachments.length})`}
          {fileAttachmentPanel && (
            <NegotiationAttachmentsPanel
              attachmentPanelExpanded={true}
              setAttachmentPanelExpanded={(status) => setFileAttachmentPanel(status)}
              negotiationId={negotiationId}
              orderId={props.orderId}
              updateToken={props.updateToken}
              attachments={attachments}
              title={title}
            />
          )}
        </Icon>
      </div>
    );
  };

  return (
    <div className="attachmentPanelContent">
      <div className="negotiation-attachments">
        <div className={classNames("column", "field")}>Name</div>
        <div className={classNames("column", "field")}>Uploaded At</div>
        <div className={classNames("column", "field")}>Size </div>
        {!attachmentsTree.length && <div className="emptyField">{emptyText}</div>}
        {attachmentsTree.map((attachmentLeaf) => (
          <>
            <div className="parent" onClick={() => openIt(attachmentLeaf.negotiationId)}>
              {closedExpandedItems.includes(attachmentLeaf.negotiationId) ? (
                <Icon icon="arrow-drop-right" />
              ) : (
                <Icon icon="arrow-drop-down" />
              )}
              <span className="negotiation-attachments-title">{attachmentLeaf.title}</span>
              <span className="negotiation-attachments-button">
                <AttachmentPaperClipButton
                  negotiationAttachmentTree={attachmentLeaf}
                  orderId={orderId}
                  updateToken={updateToken}
                />
              </span>
            </div>
            {!closedExpandedItems.includes(attachmentLeaf.negotiationId) && (
              <AttachmentsList attachments={attachmentLeaf.attachments} />
            )}
          </>
        ))}
      </div>
    </div>
  );
};
