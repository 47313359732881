import React from "react";
import { IconTypes } from "sharedFolder/components/common/types";
import formatStatus from "sharedFolder/Utilities/formatStatus";
import Icon from "__legacy/sharedFolder/components/common/Icon/Icon";
import styles from "./Status.module.scss";

interface IStatusProps {
  icon: IconTypes;
  status: string;
  timeRemaining?: string | null;
}

export const Status = ({ icon, timeRemaining, status }: IStatusProps) => {
  return (
    <div className={styles.container}>
      {icon && <Icon icon={icon} className={styles[status.toLowerCase()]} />}
      <div className={styles.statusText}>{formatStatus(status.toString())}</div>
      {timeRemaining && <div className={styles.timeRemaining}>{timeRemaining}</div>}
    </div>
  );
};
