import { config, FeatureFlagName, LaunchDarklyFeature } from "@/config";
import { auth } from "___REFACTOR___/services";
import { LDFlagSet } from "launchdarkly-js-client-sdk";

export function shouldEnableLaunchDarklyFeature(feature: LaunchDarklyFeature, flags: LDFlagSet) {
  return flags && flags[feature];
}

export function shouldEnableFeature(featureFlagName: FeatureFlagName) {
  const user = auth.trade.user;
  const companyId = user?.CompanyId ?? ``;
  const featureFlags = config.featureFlags;

  let enableFeatureFlag;
  let enableFeatureCompanies;

  switch (featureFlagName) {
    case "odc":
      enableFeatureFlag = featureFlags?.enableOdc === "true" ?? false;
      enableFeatureCompanies = featureFlags?.enableOdcCompanies ?? "";

      break;

    default:
      enableFeatureFlag = false;
      enableFeatureCompanies = "";

      break;
  }

  return (enableFeatureFlag && user?.IsCompanyVerified) || enableFeatureCompanies.indexOf(companyId) !== -1;
}

export function filterElementsByElementName(elements: any[], elementNamesToDiscard: string[], elementNameProp = "name") {
  if (!elements) {
    return elements;
  }

  const filteredElements: any[] = [];

  for (const element of elements) {
    if (!element) {
      filteredElements.push(element);
      continue;
    }

    if (element[elementNameProp] && elementNamesToDiscard.indexOf(element[elementNameProp]) >= 0) {
      continue;
    }

    if (!element.children) {
      filteredElements.push(element);
      continue;
    }

    const filteredChildren = filterElementsByElementName(element.children, elementNamesToDiscard, elementNameProp);
    const elementWithFilteredChildren = {} as any;
    Object.assign(elementWithFilteredChildren, element);
    elementWithFilteredChildren.children = filteredChildren;
    filteredElements.push(elementWithFilteredChildren);
  }

  return filteredElements;
}

export const NewTcFields: string[] = [
  "cleaningPrice",
  "supplyPrice",
  "tradingExclusions",
  "bunkerDelivery",
  "bunkerRedelivery",
  "cargoExclusionsText",
];

export const NewTcFieldsTitles: string[] = [
  "ILOHC ($)",
  "CVE ($)",
  "Trading Exclusions",
  "Bunker Delivery",
  "Bunker Re-Delivery",
  "Cargo Exclusions",
];
