import React, { useState, useEffect, useCallback } from "react";
import styles from "./IndicationOrFirmResponse.module.scss";
import { NegotiableItemContainer } from "./NegotiableItemContainer";
import { NegotiationUIStatus } from "../../reducers/offerRepStatusReducer";

export type firmOrIndicationType = "INDICATION_ONLY" | "FIRM_OFFER";

export const IndicationOrFirmResponse: React.SFC<{
  onFirmOrIndicationSelected: (firmOrIndication: firmOrIndicationType) => void;
  negotiationUIStatus: NegotiationUIStatus;
}> = ({ onFirmOrIndicationSelected, negotiationUIStatus }) => {
  const [disabled, setDisabled] = useState(false);

  const [firmOrIndicationValue, firmOrIndicationInputProps, setRadioButtonValue] = useRadioButtons(
    "firmOrIndication",
    (firmOrIndcation) => onFirmOrIndicationSelected(firmOrIndcation as firmOrIndicationType),
    "INDICATION_ONLY"
  );

  useEffect(() => {
    setDisabled(false); // Set to enabled by default

    if (["Withdrawn"].includes(negotiationUIStatus)) {
      setDisabled(true);
    }

    if (["FirmOfferAccepted", "FirmBidAccepted"].includes(negotiationUIStatus)) {
      setRadioButtonValue("INDICATION_ONLY");
      setDisabled(true);
    }
    if (["FirmBidExpired", "FirmOfferExpired"].includes(negotiationUIStatus)) {
      setRadioButtonValue("INDICATION_ONLY");
      setDisabled(false);
    }

    if (["FirmOffer", "FirmOfferCounteredWithIndication", "FirmBid"].includes(negotiationUIStatus)) {
      setRadioButtonValue("FIRM_OFFER");
      setDisabled(true);
    }
  }, [negotiationUIStatus, setRadioButtonValue]);

  return (
    <NegotiableItemContainer typeTitle="This response" lastUpdated={null}>
      <div className={styles.container}>
        <div className={styles.option}>
          <input
            data-test="indicationRadioButton"
            value="INDICATION_ONLY"
            checked={firmOrIndicationValue === "INDICATION_ONLY"}
            disabled={disabled}
            {...firmOrIndicationInputProps}
          />
          <label htmlFor={"indicationResponse"}>This is an Indication only</label>
        </div>
        <div className={styles.optionAndFootnote}>
          <div className={styles.option}>
            <input
              data-test="firmOfferRadioButton"
              value="FIRM_OFFER"
              checked={firmOrIndicationValue === "FIRM_OFFER"}
              disabled={disabled}
              {...firmOrIndicationInputProps}
            />
            <label htmlFor={"firmResponse"}>This is a firm Offer *</label>
          </div>
          <div className={styles.footnote}>
            * References to &apos;Offer&apos; herein refer to an Owner&apos;s Offer
            <br />
            ** References to &apos;Bid&apos; herein refer to a Charterer&apos;s Offer
          </div>
        </div>
      </div>
    </NegotiableItemContainer>
  );
};

function useRadioButtons(
  name: string,
  callback: (value: string) => void,
  defaultValue: null | string
): [
  string | null,
  {
    id: string;
    name: string;
    type: string;
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  },
  (value: string) => void
] {
  const [value, setState] = useState<null | string>(defaultValue);

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setState(e.currentTarget.value);
    callback(e.currentTarget.value);
  };

  const inputProps = {
    id: name,
    name,
    type: "radio",
    onChange: handleChange,
  };

  const setRadioButtonValue = useCallback(() => {
    if (value !== null) {
      setState(value);
      callback(value);
    }
  }, [value, setState, callback]);

  return [value, inputProps, setRadioButtonValue];
}
