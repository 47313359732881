import React from "react";
import styles from "./DistributionListSearchResults.module.scss";
import { IDistributionListViewModel } from "sharedFolder/Models/IDetails";
import { DistributionStretchColumn } from "./DistributionListIndividual";
import { SearchStateType } from "./reducers/distributionSearchReducer";
import LoadingSpinner from "sharedFolder/components/common/LoadingSpinner/LoadingSpinner";
import Button from "__legacy/sharedFolder/components/common/Button/Button";
import { useDistribution } from "./DistributionContext";
import { DistributionList } from "./DistributionList";
import { flatten } from "./reducers/distributionReducer";

export const DistributionListSearchResults = (props: {
  searchState: SearchStateType;
  distributions: IDistributionListViewModel;
}) => {
  const context = useDistribution();
  const allUsersAreAdded = flatten(context.state.searchResultsState.searchResultsDistributions).every(
    (recipient) => recipient.additionalData?.disableUserRow
  );

  const addAllUsers = () => {
    context.dispatch({ type: "addAllUsers" });
  };
  if (props.searchState === "loading") {
    return (
      <>
        <div className={styles.searchResultsLoading}>
          <LoadingSpinner />
        </div>
      </>
    );
  }

  if (props.searchState === "untouched") {
    return (
      <>
        <div className={styles.searchResultsLoading}>
          <div>Search results will appear here</div>
        </div>
      </>
    );
  }

  if (props.searchState === "noresults") {
    return (
      <>
        <div className={styles.searchResultsLoading}>
          <div>No search matches found</div>
        </div>
      </>
    );
  }
  if (props.searchState === "results") {
    return (
      <>
        <div className={styles.searchResults}>
          <div className={styles.searchResultsTopRow}>
            <Button type="flat" icon="add-circle" dataTest="add-all-recipients" onClick={addAllUsers} disabled={allUsersAreAdded}>
              Add All
            </Button>
          </div>
          <DistributionList distributions={props.distributions} icon="add" />
        </div>
      </>
    );
  }
  return null;
};
