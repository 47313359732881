const testSelectors = {
  //general
  configFetchFailDialog: "config-fetch-fail-dialog",
  missingConfigValuesDialog: "missing-config-values-dialog",
  sessionExpiredDialog: "session-expired-dialog",
  invalidUserConfigurationDialog: "invalid-user-configuration-dialog",
  newOrderForm: "new-order-form",
  newOrderButton: "new-order-button",
  submitNewOrderButton: "create-order",
  newOrderFormVoyageTermsCheckbox: "new-order-form-voyage-terms-checkbox",
  negotiateCOAMaintermsCheckbox: "coa-main-terms-radio.input",
  distibuteRadio: "distributeRadio.input",
  distributeToAbsentOwnerRadio: "absent-owner-radio.input",
  ownerAccountValue: "ownerAccount.value",
  ownerAccountValueInput: "ownerAccount.value.input",
  maintermsSuccessDialog: "mainterms-success-dialog",
  negotiationOrderHeaderNav: "order-header-nav",
  negotiationsTab: "Negotiations-Tab",
  distributionTab: "Distribution-Tab",
  liftingsTab: "Liftings-Tab",
  notesAndAttachmentsTab: "Notes & Attachments-Tab",
  negotiationsAttachmentIcon: "attachments-icon-and-count",
  timeLimitHours: "timelimitDateOffset.value.hour",
  fixImmediatelyRadioButton: "fix-immediately-radio",
  continueFromOrderButton: "continue-from-order",
  addAbsentOwnerButton: "add-absent-owner-button",
  deleteAbsentOwnerButton: "distribution-btn-delete-",
  absentOwnerNegRowIcon: "noUserIcon",
  userTag: "user-tag",
  liftSubsDialog: "lift-subs-dialog",
  liftSubsSubmitButton: "lift-subs-submit-button",
  liftOperationalSubsOption: "lift-operational-subs-option",
  liftOperationalSubsOptionInput: "lift-operational-subs-option.input",
  liftOperationalSubsButton: "lift-operational-subs",
  liftCommercialSubsOption: "lift-commercial-subs-option",
  liftAllSubsOption: "lift-all-subs-option",
  liftAllSubsOptionInput: "lift-all-subs-option.input",
  liftAllSubsButton: "lift-all-subs",
  liftAllSubsAndFixOption: "lift-all-subs-and-fix-option",
  liftAllSubsAndFixOptionInput: "lift-all-subs-and-fix-option.input",
  liftAllSubsAndFixButton: "lift-all-subs-and-fix",
  cleanFixNowButton: "clean-fix-now",
  confirmSubsFailDialog: "confirm-subs-fail-dialog-dialog-dialog-dialog",
  newOrderFormTCTTermsCheckbox: "new-order-form-tct-terms-checkbox",
  goToTcMainTermsFromOrderRadio: "main-terms-radio.input",
  distributeOrderFromOrderRadio: "distribute-radio.input",
  deskUserSearch: "search-input",
  loadingOverlay: "loading-overlay",
  termsetSearch: "owner-deal-capture.termsetId.value",
  addMultipleEmailsButton: "Add Multiple Emails",
  emailGroupsButton: "email-groups-button",
  manageDistributionLists: "manage-distribution-lists",
  orderTemplateInput: "orderTemplate.value",
  ptmtVoyCheckbox: "voyage-mainterms-radio.input",

  createNewEmailGroup: "create-new-group",
  newDistributionListNameInput: "email-list-form.emailList.name.input",
  addContacts: "add-contacts",
  invitees: "invitees",
  sendIndicationButton: "send-indication",

  addDeskButton: "distribution-btn-add-desk-{{deskId}}",
  nextButtonDistribute: "NextButton",
  circulateOrderButton: "circulateOrderButton",
  moreActionsKebabMenu: "more-actions",
  nominateVesselKebabMenuOption: "nominate-vessel",
  vesselNameInput: "vessel.value.input",
  vesselDAteInput: "vessel.date.input",
  vesselOwnerChainInput: "vessel.ownerChain.input",
  vesselItineraryInput: "vessel.itinerary.input",
  vesselSpeedAndConsumptionInput: "vessel.speedAndConsumption.input",
  vesselAdditionalNotesInput: "vessel.additionalNotes.input",
  vesselAcceptButton: "vessel-accept",
  vesselAcceptButton2: "accept-now",
  vesselNominateButton: "nominate",
  vesselViewButton: "view-vessel-btn",
  dropdownItem0: "dropdown-item.0",
  activeOrderDropdown: "more-actions",
  ptmtFromActiveOrder: "proceed-to-main-terms",
  nominateVessel: "nominate-vessel",
  firmBidOfferMenuOption: "firm-bid-offer",
  requestfirmOfferMenuOption: "request-firm-from-Owner",
  firmBidNegotiationStatus: "status-firm-bid",
  firmOfferNegotiationStatus: "status-firm-offer",
  demurrageInputPopOut: "demurrage0.value.input",
  freighRateInputPopOut: "freightRate0.value.input",
  firmStatusIcon: "status-firm",

  offerFreightRateInputValue: "freightRate.value",
  offerFreightRateValueInput: "freightRate.value.input",
  offerDemurrageInputValue: "demurrage.value",
  offerDemurrageValueInput: "demurrage.value.input",

  requestFirmBidButton: "request-firm",
  sendFirmOfferButton: "send-firm-offer",

  //bid
  editButtonBid: "edit-btn-bid",
  indicationTypeCheckbox: "indicationType.input",
  indicationTypeRadio: "indicationType.input",
  indicationTypeFirm: "indicationTypeFirm.input",

  laycanBid: "laycan0.value",
  laycanBidClearButton: "laycan0.value.output.icon",
  laycanBidEditInput: "laycan0.value.output.input",
  laycanBidEditNoInput: "laycan0.value.output",

  periodBidClearButton: "period0.value.output.icon",
  periodBidEditInput: "period0.value.output.input",
  periodBidEditNoInput: "period0.value.output",

  liftingsMinBidEditInput: "liftings0.min.input",
  liftingsMinBidEditNoInput: "liftings0.min",
  liftingsMaxBidEditInput: "liftings0.max.input",
  liftingsMaxBidEditNoInput: "liftings0.max",
  liftingsDispersalBidEditInput: "liftings0.dispersal.input",
  liftingsDispersalBidEditNoInput: "liftings0.dispersal",
  liftingsNotesBidEditInput: "liftings0.notes.input",
  liftingsNotesBidEditNoInput: "liftings0.notes",

  nominationsNoticePerLaycanBidEditInput: "nominations0.notice.input",
  nominationsNoticePerLaycanBidEditNoInput: "nominations0.notice",
  nominationsLaycanSpreadBidEditInput: "nominations0.spread.input",
  nominationsLaycanSpreadBidEditNoInput: "nominations0.spread",
  nominationsFinalLaycanNoticeBidEditInput: "nominations0.final.input",
  nominationsFinalLaycanNoticeBidEditNoInput: "nominations0.final",
  nominationsNotesBidEditInput: "nominations0.notes.input",
  nominationsNotesBidEditNoInput: "nominations0.notes",

  addressCommissionBidValueEditInput: "addressCommission0.value.input",
  addressCommissionBidValueEditNoInput: "addressCommission0.value",

  cargoTypeBidValueEditInput: "cargoType0.value.input",
  cargoTypeBidValueEditNoInput: "cargoType0.value",
  cargoTypeBidNotesEditInput: "cargoType0.notes.input",
  cargoTypeBidNotesEditNoInput: "cargoType0.notes",

  coaCargoSizeBidMinEditNoInput: "coaCargoSize0.min",
  coaCargoSizeBidMaxEditNoInput: "coaCargoSize0.max",
  coaCargoSizeBidVarienceNoEditInput: "coaCargoSize0.variance",
  coaCargoSizeBidNotesEditNoInput: "coaCargoSize0.notes",

  deliveryLocationBidClearButton: "deliveryLocation0.value.icon",
  deliveryLocationBidValueEditInput: "deliveryLocation0.value.input",
  deliveryLocationBidValueEditNoInput: "deliveryLocation0.value",
  deliveryLocationBidBerthEditInput: "deliveryLocation0.berth.input",
  deliveryLocationBidBerthEditNoInput: "deliveryLocation0.berth",
  deliveryLocationBidEditPortInput: "deliveryLocation0.port.input",
  deliveryLocationBidEditPortNoInput: "deliveryLocation0.port",
  deliveryLocationBidEditAnchorageInput: "deliveryLocation0.anchorage.input",
  deliveryLocationBidEditAnchorageNoInput: "deliveryLocation0.anchorage",
  deliveryLocationBidEditNotesInput: "deliveryLocation0.notes.input",
  deliveryLocationBidEditNotesNoInput: "deliveryLocation0.notes",

  dischargeLocationBidClearButton: "dischargeLocation0.value.icon",
  dischargeLocationBidValueEditInput: "dischargeLocation0.value.input",
  dischargeLocationBidValueEditNoInput: "dischargeLocation0.value",
  dischargeLocationBidBerthEditInput: "dischargeLocation0.berth.input",
  dischargeLocationBidBerthEditNoInput: "dischargeLocation0.berth",
  dischargeLocationBidEditPortInput: "dischargeLocation0.port.input",
  dischargeLocationBidEditPortNoInput: "dischargeLocation0.port",
  dischargeLocationBidEditAnchorageInput: "dischargeLocation0.anchorage.input",
  dischargeLocationBidEditAnchorageNoInput: "dischargeLocation0.anchorage",
  dischargeLocationBidEditNotesInput: "dischargeLocation0.notes.input",
  dischargeLocationBidEditNotesNoInput: "dischargeLocation0.notes",

  loadLocationBidClearButton: "loadLocation0.value.icon",
  loadLocationBidValueEditInput: "loadLocation0.value.input",
  loadLocationBidValueEditNoInput: "loadLocation0.value",
  loadLocationBidBerthEditInput: "loadLocation0.berth.input",
  loadLocationBidBerthEditNoInput: "loadLocation0.berth",
  loadLocationBidEditPortInput: "loadLocation0.port.input",
  loadLocationBidEditPortNoInput: "loadLocation0.port",
  loadLocationBidEditAnchorageInput: "loadLocation0.anchorage.input",
  loadLocationBidEditAnchorageNoInput: "loadLocation0.anchorage",
  loadLocationBidEditNotesInput: "loadLocation0.notes.input",
  loadLocationBidEditNotesNoInput: "loadLocation0.notes",

  durationMinBidEditInput: "duration0.min.input",
  durationMinBidEditNoInput: "duration0.min.input",
  durationMaxBidEditInput: "duration0.max.input",
  durationMaxBidEditNoInput: "duration0.max.input",
  durationUnitBidEditInput: "duration0.unit.input",
  durationUnitBidEditNoInput: "duration0.unit.input",
  durationNotesBidEditInput: "duration0.notes.input",
  durationNotesBidEditNoInput: "duration0.notes.input",

  demurrageValueBid: "demurrage0.value",
  demurrageValueBidEditInput: "demurrage0.value.input",
  demurrageValueBidEditNoInput: "demurrage0.value.input",
  demurrageUnitBidEditInput: "demurrage0.unit.input",
  demurrageUnitBidEditNoInput: "demurrage0.unit.input",
  demurrageNotesBidEditInput: "demurrage0.notes.input",
  demurrageNotesBidEditNoInput: "demurrage0.notes.input",

  freightRateValueBid: "freightRate0.value",
  freightRateValueBidEditInput: "freightRate0.value.input",
  freightRateValueBidEditNoInput: "freightRate0.value.input",
  freightRateUnitBidEditInput: "freightRate0.unit.input",
  freightRateUnitBidEditNoInput: "freightRate0.unit.input",
  freightRateNotesBidEditInput: "freightRate0.notes.input",
  freightRateNotesBidEditNoInput: "freightRate0.notes.input",

  ballastBonusValueBidEditInput: "ballastBonus0.value.input",
  ballastBonusValueBidEditNoInput: "ballastBonus0.value",
  ballastBonusUnitBidEditInput: "ballastBonus0.unit.input",
  ballastBonusUnitBidEditNoInput: "ballastBonus0.unit.input",
  ballastBonusNotesBidEditInput: "ballastBonus0.notes.input",
  ballastBonusNotesBidEditNoInput: "ballastBonus0.notes.input",

  hireRateValueBidInput: "hireRate0.value",
  hireRateValueBidEditInput: "hireRate0.value.input",
  hireRateValueBidEditNoInput: "hireRate0.value",
  hireRateUnitBidEditInput: "hireRate0.unit.input",
  hireRateUnitBidEditNoInput: "hireRate0.unit.input",
  hireRateNotesBidEditInput: "hireRate0.notes.input",
  hireRateNotesBidEditNoInput: "hireRate0.notes.input",

  ilohcValueBidEditInput: "cleaningPrice0.value.input",
  ilohcUnitBidEditInput: "cleaningPrice0.unit.input",
  ilohcNotesBidEditInput: "cleaningPrice0.notes.input",

  cveValueBidEditInput: "supplyPrice0.value.input",
  cveUnitBidEditInput: "supplyPrice0.unit.input",
  cveNotesBidEditInput: "supplyPrice0.notes.input",

  tradingExclusionsNotesBidEditInput: "tradingExclusions0.value.input",

  bunkerDeliveryPriceBidEditInput: "bunkerDelivery0.price.input",
  bunkerDeliveryQuantityBidEditInput: "bunkerDelivery0.quantity.input",
  bunkerDeliveryFuelTypesBidEditInput: "bunkerDelivery0.fuelTypes.input",
  bunkerDeliveryNotesBidEditInput: "bunkerDelivery0.notes.input",

  bunkerRedeliveryPriceBidEditInput: "bunkerRedelivery0.price.input",
  bunkerRedeliveryQuantityBidEditInput: "bunkerRedelivery0.quantity.input",
  bunkerRedeliveryFuelTypesBidEditInput: "bunkerRedelivery0.fuelTypes.input",
  bunkerRedeliveryNotesBidEditInput: "bunkerRedelivery0.notes.input",

  cargoExclusionsNotesBidEditInput: "cargoExclusionsText0.value.input",

  operationalSubsValueBidEditInput: "operationalSubs0.value.input",
  operationalSubsValueBidEditNoInput: "operationalSubs0.value.input",
  operationalSubsBidValue: "bid-operationalSubs",
  commercialSubsValueBidEditInput: "commercialSubs0.value.input",
  commercialSubsValueBidEditNoInput: "commercialSubs0.value.input",
  operationalSubsLabel: "Operational Subs-label",
  operationalSubsRow: "OPERATIONAL_SUBS",
  operationalSubsRowEdit: "owner-deal-capture.subjectTermsetMainTermTemplates.0.content.input",
  operationalSubsEditOnDistribution: "voyOperationalSubsValue",
  commericalSubsLabel: "Commercial Subs-label",
  commercialSubsRow: "COMMERCIAL_SUBS",
  commercialSubsRowEdit: "owner-deal-capture.subjectTermsetMainTermTemplates.1.content.input",
  commercialSubsEditOnDistribution: "voyCommercialSubsValue",

  bidPeriodValue: "bid-period",
  bidFreightRateValue: "bid-freightRate",
  bidDemurrageValue: "bid-demurrage",
  bidDurationValue: "bid-duration",
  bidDeliveryLocationValue: "bid-deliveryLocation",
  bidRedeliveryLocationValue: "bid-redeliveryLocation",
  bidLaycanValue: "bid-laycan",
  bidHireRateValue: "bid-hireRate",
  bidBallastBonusValue: "bid-ballastBonus",
  bidOperationalSubsValue: "bid-operationalSubs",
  bidCommercialSubsValue: "bid-commercialSubs",
  bidLiftings: "bid-liftings",
  bidNominations: "bid-nominations",
  bidAddressCommission: "bid-addressCommission",
  bidcargoType: "bid-cargoType",
  bidCoaCargoSize: "bid-coaCargoSize",
  bidDischargeLocation: "bid-dischargeLocation",
  bidLoadLocation: "bid-loadLocation",
  bidIlohcValue: "bid-cleaningPrice",
  bidCveValue: "bid-supplyPrice",
  bidTradingExclusionsValue: "bid-tradingExclusions",
  bidBunkerDeliveryValue: "bid-bunkerDelivery",
  bidBunkerRedeliveryValue: "bid-bunkerRedelivery",
  bidCargoExclusionsValue: "bid-cargoExclusionsText",

  //offer
  editButtonOffer: "edit-btn-offer",

  laycanOfferValue: "laycan.value",
  laycanOfferClearButton: "laycan.value.output.icon",
  laycanOfferEditInput: "laycan.value.output.input",
  laycanOfferInput: "laycan.value.input.input",

  periodOfferClearButton: "period.value.output.icon",
  periodOfferEditInput: "period.value.output.input",

  liftingsMinOfferEditInput: "liftings.min.input",
  liftingsMaxOfferEditInput: "liftings.max.input",
  liftingsDispersalOfferEditInput: "liftings.dispersal.input",
  liftingsNotesOfferEditInput: "liftings.notes.input",

  nominationsNoticePerLaycanOfferEditInput: "nominations.notice.input",
  nominationsLaycanSpreadOfferEditInput: "nominations.spread.input",
  nominationsFinalLaycanNoticeOfferEditInput: "nominations.final.input",
  nominationsNotesOfferEditInput: "nominations.notes.input",

  deliveryLocationOfferClearButton: "deliveryLocation.value.icon",
  deliveryLocationOfferValueEditInput: "deliveryLocation.value.input",
  deliveryLocationOfferBerthEditInput: "deliveryLocation.berth.input",
  deliveryLocationOfferEditPortInput: "deliveryLocation.port.input",
  deliveryLocationOfferEditAnchorageInput: "deliveryLocation.anchorage.input",
  deliveryLocationOfferEditNotesInput: "deliveryLocation.notes.input",

  durationMinOfferEditInput: "duration.min.input",
  durationMaxOfferEditInput: "duration.max.input",
  durationUnitOfferEditInput: "duration.unit.input",
  durationNotesOfferEditInput: "duration.notes.input",

  ballastBonusValueOfferEditInput: "ballastBonus.value.input",
  ballastBonusValueOfferEditNoInput: "ballastBonus.value",
  ballastBonusUnitOfferEditInput: "ballastBonus.unit.input",
  ballastBonusNotesOfferEditInput: "ballastBonus.notes.input",

  hireRateValueOfferEditInput: "hireRate.value.input",
  hireRateValueOfferEditNoInput: "hireRate.value",
  hireRateUnitOfferEditInput: "hireRate.unit.input",
  hireRateNotesOfferEditInput: "hireRate.notes.input",

  operationalSubsValueOfferEditInput: "operationalSubs.value.input",
  operationalSubsOfferValue: "offer-operationalSubs",
  commercialSubsValueOfferEditInput: "commercialSubs.value.input",

  ilohcValueOfferEditInput: "cleaningPrice.value.input",
  ilohcUnitOfferEditInput: "cleaningPrice.unit.input",
  ilohcNotesOfferEditInput: "cleaningPrice.notes.input",

  cveValueOfferEditInput: "supplyPrice.value.input",
  cveUnitOfferEditInput: "supplyPrice.unit.input",
  cveNotesOfferEditInput: "supplyPrice.notes.input",

  tradingExclusionsNotesOfferEditInput: "tradingExclusions.value.input",

  bunkerDeliveryPriceOfferEditInput: "bunkerDelivery.price.input",
  bunkerDeliveryQuantityOfferEditInput: "bunkerDelivery.quantity.input",
  bunkerDeliveryFuelTypesOfferEditInput: "bunkerDelivery.fuelTypes.input",
  bunkerDeliveryNotesOfferEditInput: "bunkerDelivery.notes.input",

  bunkerRedeliveryPriceOfferEditInput: "bunkerRedelivery.price.input",
  bunkerRedeliveryQuantityOfferEditInput: "bunkerRedelivery.quantity.input",
  bunkerRedeliveryFuelTypesOfferEditInput: "bunkerRedelivery.fuelTypes.input",
  bunkerRedeliveryNotesOfferEditInput: "bunkerRedelivery.notes.input",

  cargoExclusionsNotesOfferEditInput: "cargoExclusionsText.value.input",

  offerLaycanValue: "offer-laycan",
  offerPeriodValue: "offer-period",
  offerValueLiftings: "offer-liftings",
  offerValueNominations: "offer-nominations",
  offerValueDuration: "offer-duration",
  offerValueBallastBonus: "offer-ballastBonus",
  offerValuehireRate: "offer-hireRate",
  offerFreightRateValue: "offer-freightRate",
  offerDemurrageValue: "offer-demurrage",
  offerDurationValue: "offer-duration",
  offerDeliveryLocationValue: "offer-deliveryLocation",
  offerRedeliveryLocationValue: "offer-redeliveryLocation",
  offerHireRateValue: "offer-hireRate",
  offerBallastBonusValue: "offer-ballastBonus",
  offerOperationalSubsValue: "offer-operationalSubs",
  offerCommercialSubsValue: "offer-commercialSubs",
  offerIlohcValue: "offer-cleaningPrice",
  offerCveValue: "offer-supplyPrice",
  offerTradingExclusionsValue: "offer-tradingExclusions",
  offerBunkerDeliveryValue: "offer-bunkerDelivery",
  offerBunkerRedeliveryValue: "offer-bunkerRedelivery",
  offerCargoExclusionsValue: "offer-cargoExclusionsText",
  revealToChartererbtn: "show-to-charterer-btn",
  revealToChartererSliderbtn: "reveal-to-charterer",
  chartererResponsebtn: "accept-charterer-suggestions-btn",
  cancelRevealbtn: "cancel",
  revealToOwnerbtn: "reveal-to-owner",
  seeMoreButton: "see-more",

  // attachments
  attachmentsDropzone: "dropzone",
  attachmentName: "attachment-name",
  closeButton: "close",
  deleteButton: "delete-dialog-button",
  deleteConfirmationButton: "delete-attachment-confirmatioon-subs-radio.input",
  deleteConfirmButton: "delete-attachment-confirmation",

  // distribution
  orderNegotiationForm: "Order-Negotiation-Form",
  orderDetailsTab: "Order Details-Tab",
  summaryDetails: "Summary-Details",
  freightRateNotes: "freight-rate-notes",
  demurrageNotes: "demurrage-notes",
  negotiationTab: "Negotiation-Tab",

  // ptmt
  timeLimitHoursInput: "timelimitDateOffset.value.hour.input",
  timeLimitMinutesInput: "timelimitDateOffset.value.minute.input",
  firmOfferRadio: "firmOfferRadio.input",
  onSubsRadio: "on-subs-radio.input",
  fixImmediatelyRadio: "fix-immediately-radio.input",
  moreActions: "more-actions",
  proceedToMainTerms: "proceed-to-main-terms",
  vesselsInput: "vessels.value.input",

  //on subs
  mainTermsOrderFetchFailDialog: "MainTerms-order-fetch-fail-dialog",
  mainTermsImportFailDialog: "mainterms-termset-import-fail-dialog",
  negotiationfetchFailureDialog: "owner-negotiation-fetch-fail-dialog",
  manageSubsButton: "manage-subs",
  failSubsButton: "fail-subs",
  statusFixed: "status-fixed",
  statusOnSubs: "status-on-subs",
  statusSubsFailed: "status-subs-failed",
  statusSubsLifted: "status-subs-lifted",
  mainTermsFailDialog: "MainTerms",
  inviteeInput: "invitee.value.input",
  termsetInput: "owner-deal-capture.termsetId.value.input",
  finishMainTermsRadioInput: "jumpTo.input",
  operationalSubsTextField: "owner-deal-capture.subjectTermsetMainTermTemplates.0.content.input",
  commercialSubsTextField: "owner-deal-capture.subjectTermsetMainTermTemplates.1.content.input",

  // buttons
  continueButton: "continue",
  confirmButton: "Confirm",
  proceedButton: "proceed",
  saveButton: "save",
  doneButton: "done",
  rejectButton: "reject",
  updateButton: "update",
  sendFirmBidButton: "send-firm",
  acceptAndGoFirmButton: "accept-and-go-firm",
  declineButton: "decline",
  declareToOwnerButton: "Declare to Owner",
  additionalLiftingsButton: "additional-lifting-btn",
  acceptFirmOKButton: "AcceptFirmOKButton",
  deleteNominatedVesselButton: "delete",
  acceptBidButton: "accept-bid",

  nominatedVesselSearchInput: "nominatedVesselSearch",
  vesselAddToLiftingsButton: "vessel-add",
  sendVesselLiftingNominationButton: "Send Nomination Button",
  vesselNominationSentNotification: "Dismissable-Notification-Nomination Sent",
  vesselRejectButton: "vessel-reject",
  vesselNominationAcceptedNotification: "Dismissable-Notification-Vessel Accepted",
  vesselNominationRejectedNotification: "Dismissable-Notification-Vessel Rejected",

  acceptOffer: "accept-offer",

  //deal capture
  dealCaptureButton: "new-order-button",
  chartererContactField: "chartererAccount.value.input",
  brokerContactField: "brokerContact.value.input",
  ownerContactField: "ownerContact.value.input",
  brokerContact: "brokerContact.value",
  ownerContact: "ownerContact.value",

  // owner deal capture
  ownerDealCaptureButton: "new-deal-capture-button",
  captureDealAsOwner: "deal-capture-menu-item-owner",
  captureDealAsCharterer: "deal-capture-menu-item-charterer",
  submitOwnerDealCapture: "submit",
  ownerDealCaptureVesselsInput: "owner-deal-capture.details.vessels.value.input",
  previouslyExecutedInput: "owner-deal-capture.cpSearchValue.input",

  //External Buttons
  seaNetExternalButton: "sea-net-button",
  seaContractsExternalButton: "sea-contracts-button",

  // Bulk Actions
  openBulkPanelButton: "open-bulk-panel-button",
  closeBulkPanelButton: "close-bulk-panel-button",
  bulkSelectAllCheckbox: "bulk-select-all-checkbox",
  bulkSelectRowCheckbox: "select-for-bulk-",
  openArchivedDashboardButton: "open-archived-dashboard-button",
  closeArchivedDashboardButton: "close-archived-dashboard-button",
  bulkArchiveButton: "bulk-archive-button",
  bulkUnarchiveButton: "bulk-unarchive-button",
  archiveButton: "archive-menu-item",
  unarchiveButton: "unarchive-menu-item",
  dashboardkebabIto: "header-kebab-ito",
  dashboardkebabArchiveIto: "header-kebab-ito-archive",
  dashboardkebabCoa: "header-kebab-coa",
  dashboardkebabArchiveCoa: "header-kebab-coa-archive",
  dashboardkebabOwnersDashboard: "header-kebab-owner-dashboard",
  dashboardkebabArchiveOwnersDashboard: "header-kebab-owner-archive",

  // Order Withdrawal
  orderWithdrawButton: "withdraw-order-button",
  orderWithdrawDialog: "withdraw-order-dialog",
  orderWithdrawSubmitButton: "withdraw",
  orderWithdrawCancelButton: "withdraw-order-dialog-cancel",
  orderWithdrawReasonTextBox: "withdraw-order-dialog-reason",

  // Copy Indication
  copyIndicationSubmitButton: "copy-indication-dialog-submit",
  copyIndicationCancelButton: "copy-indication-dialog-cancel",

  openMainTermsButton: "open-main-terms",
  seaContractsButton: "sea-contracts-button",
};

module.exports = { testSelectors };
