import { TradeAPI } from "___REFACTOR___/apis";
import { OrderTemplateSearchEditor } from "___REFACTOR___/components";
import { DataModel, DataModelProps } from "../DataModel";

class OrderTemplate extends DataModel {
  constructor(data: PartialData) {
    super(data);
  }

  toString() {
    return this.name;
  }
}

class OrderTemplateProps extends DataModelProps {
  get label() {
    return "Order Template";
  }

  get Editor() {
    return OrderTemplateSearchEditor;
  }
}

OrderTemplate.prototype.Props = OrderTemplateProps;

export { OrderTemplate, OrderTemplateProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface OrderTemplate extends Data {
  Props: typeof OrderTemplateProps;
  _: OrderTemplateProps;
}

type PartialData = Partial<Data>;
type Data = TradeAPI.Order.Template;
