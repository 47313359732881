import React from "react";
import { TextInput } from "___REFACTOR___/components";
import { Field } from "../../Field";
import { Container } from "../../Container";

function TextField(props: TextField.Props) {
  return (
    <Container {...props} type="Text">
      <TextInput {...props} />
    </Container>
  );
}

export { TextField };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace TextField {
  export interface Props extends Omit<TextInput.Props, "type">, Omit<Field.Props, "value" | "onChange"> {}

  export { TextInput as Input };
}
