import { emptyArrowFn } from "@/utils";
import React, { useState, createContext, useContext } from "react";

type DismissableNotificationType =
  | "CHANGE_REQUESTED"
  | "NONE"
  | "WITHDRAW_LIFTING"
  | "ACCEPT_LIFTING"
  | "REJECT_LIFTING"
  | "DECLARED_CARGO"
  | "CAPTURED_CARGO";

type IDismissableNotificationParams = AcceptLiftingParams;

interface AcceptLiftingParams {
  liftingId: string;
}

interface DismissableNotification {
  type: DismissableNotificationType;
  params?: IDismissableNotificationParams;
}

interface IDismissableNotificationContext {
  notification: DismissableNotification;
  showNotification(notificationType: "ACCEPT_LIFTING", params: AcceptLiftingParams): void;
  showNotification(
    notificationType: "CHANGE_REQUESTED" | "NONE" | "WITHDRAW_LIFTING" | "REJECT_LIFTING" | "DECLARED_CARGO" | "CAPTURED_CARGO"
  ): void;
}

const DismissableNotificationContext = createContext<IDismissableNotificationContext>({
  notification: {
    type: "NONE",
  },
  showNotification: emptyArrowFn,
});

interface IDismissableNotificationProviderProps {
  children: React.ReactNode;
}

function DismissableNotificationProvider(props: IDismissableNotificationProviderProps) {
  const { children } = props;
  const [notification, setNotification] = useState<DismissableNotification>({
    type: "NONE",
  });

  function showNotification(
    notificationType: "CHANGE_REQUESTED" | "NONE" | "WITHDRAW_LIFTING" | "REJECT_LIFTING" | "DECLARED_CARGO" | "CAPTURED_CARGO"
  ): void;
  function showNotification(notificationType: "ACCEPT_LIFTING", params: AcceptLiftingParams): void;
  function showNotification(notificationType: any, params?: any): void {
    setNotification({ type: notificationType, params });
  }

  return (
    <DismissableNotificationContext.Provider
      value={{
        notification: notification,
        showNotification: showNotification,
      }}
    >
      {children}
    </DismissableNotificationContext.Provider>
  );
}

function useDismissableNotification() {
  const context = useContext(DismissableNotificationContext);
  if (context === undefined) {
    throw new Error(" useDismissableNotification must be within a DismissableNotificationProvider");
  }

  return context;
}

export { DismissableNotificationProvider, useDismissableNotification };
