import { get } from "lodash-es";

export function downloadArrayAsCSV<Item>(fileName = "export", array: Item[], columnConfigs: ColumnConfig<Item>[]) {
  const headings = columnConfigs.map((column) => column.heading);
  let csv = headings.toString();

  for (let i = 0; i < array.length; i++) {
    const item = array[i];

    for (let j = 0; j < columnConfigs.length; j++) {
      const columnConfig = columnConfigs[j];
      let value = "" as Value;

      if (j === 0) csv += "\n";
      if (j !== 0) csv += ",";

      if (typeof columnConfig.value === "string") {
        value = columnConfig.value;
      }

      if (typeof columnConfig.valuePath === "string") {
        value = get(item, columnConfig.valuePath);
      }

      if (typeof columnConfig.getValue === "function") {
        value = columnConfig.getValue(item);
      }

      value = `${value}`.replace('"', "'");

      if (`${value}`.includes(",")) value = `"${value}"`;

      csv += value;
    }
  }

  download(`${fileName}.csv`, csv);
}

function download(fileName, text) {
  const element = document.createElement("a");
  element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text));
  element.setAttribute("download", fileName);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

interface ColumnConfig<Item> {
  heading?: string;
  value?: Value;
  valuePath?: string;
  getValue?: (item: Item) => Value;
}

type Value = string | number;
