import React, { useState } from "react";
import { DatePicker } from "office-ui-fabric-react";
import { CustomDate } from "___REFACTOR___/models";
import { Input } from "../../Input";
import { Container } from "../../Container";
import { NumberInput } from "../Number";
import "./Date.scss";

function DateInput(props: DateInput.Props) {
  const { value, dataTest, time, withoutTimezone, startOfDay, className: propsClassName } = props;
  const [isOpen, setOpen] = useState(false);
  const className = [propsClassName, { open: isOpen }];

  return (
    <Container {...props} className={className} type="Date">
      {time && (
        <div className="Date-time-unit">
          <NumberInput
            placeholder="hr"
            max={23}
            value={value?.unitMap?.hour}
            onChange={onTimeChange.bind(null, "hour")}
            dataTest={`${dataTest}.hour`}
          />
          <span>:</span>
          <NumberInput
            placeholder="min"
            max={59}
            value={value?.unitMap?.minute}
            onChange={onTimeChange.bind(null, "minute")}
            dataTest={`${dataTest}.minute`}
          />
        </div>
      )}
      <DatePicker
        // @ts-ignore
        calendarProps={calendarProps}
        value={value?.date}
        onSelectDate={onDateInputChange}
        disabled={props.disabled}
        styles={styles}
        data-test={`${props.dataTest}.input`}
        placeholder={props.placeholder}
        onClick={setOpen.bind(null, true)}
        onAfterMenuDismiss={setOpen.bind(null, false)}
      />
    </Container>
  );

  function onDateInputChange(date: Date | null | undefined) {
    if (!date) return;

    props.onChange?.(new CustomDate({ value: date, withoutTimezone, startOfDay }));
  }

  function onTimeChange(unit: "hour" | "minute", unitValue: NumberInput.Value) {
    props.onChange?.(
      new CustomDate({ ...value, unitMap: { ...value?.unitMap, [unit]: unitValue }, withoutTimezone, startOfDay })
    );
  }
}

const calendarProps = {
  className: "app-input-DateInput-DatePicker",
};

function styles() {
  return { icon: "icon icon--data-range" };
}

export { DateInput };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

// eslint-disable-next-line no-redeclare
declare namespace DateInput {
  export interface Props extends Input.Props {
    value: Value;
    onChange: (value: Value) => void;
    time?: boolean;
    withoutTimezone?: boolean;
    startOfDay?: boolean;
  }

  export type Value = CustomDate | undefined;
}
