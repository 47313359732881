import React, { useMemo } from "react";
import {
  coaOrderNegotiationArchiveStore,
  UniversalOrderNegotiationFormValues,
  RouteSeed,
  router,
  coaOrderNegotiationStore,
} from "@/models";

import { FormContext } from "@/components/common/Form/Context/Context";
import { Orders, Context } from "@/components/Orders";
import { commonColumnMap, getOrderColumnMap, getNegotiationColumnMap } from "@/components/Orders/Grid/columns";
import {
  Order as OrderComp,
  Creation,
  Active,
  Capture,
  New,
  Withdrawn,
  Nomination,
  Inactive,
  Failed,
  Firm,
  TermsLoading,
  MainTerms,
  OnSubs,
  SubsLifted,
  SubsFailed,
  Fixed,
  Archived,
} from "@/components/Orders/Order";
import { getAbstractDetailArrayForDisplay } from "@/models/Order/utils";

const attachments = {
  path: "/coa-attachments",
  label: "Attachments",
  getMiscInfo: ({ orderId }) => {
    const order = orderId ? coaOrderNegotiationStore.upsertOrderModel({ id: orderId }) : undefined;
    const loading = order?._.status.loading;
    let badgeCount = order?.attachments?.length || 0;

    const fixedNegotiationAttachments = order?.negAttachments?.filter((attachment) => attachment.status === "Fixed");
    const fixedNegotiationAttachmentsCount = fixedNegotiationAttachments?.length || 0;
    const liftingsAttachments = order?.negAttachments?.filter((attachment) => attachment.type === "Lft");
    const liftingsAttachmentsCount = liftingsAttachments?.length || 0;
    const negotiationsAttachmentCount = order?.negAttachments?.length || 0;

    if (fixedNegotiationAttachments?.length) {
      badgeCount += fixedNegotiationAttachmentsCount;
      badgeCount += liftingsAttachmentsCount;
    } else {
      badgeCount += negotiationsAttachmentCount;
    }

    return { badgeCount, loading };
  },
};

const circulate = {
  path: "/circulate/:noOfUsers?",
  label: "Circulate",
};

const coaDetails = {
  label: "COA Details",
  path: "/coa-details",
};

const distribution = {
  label: "Distribution",
};

const initialization = {
  label: "Negotiations",
};

const liftings = {
  label: "Liftings",
};

const negotiations = {
  label: "Negotiations",
};

export const archivedCoa = {
  path: "/coas-archive",
  comp: OrdersWrapper,
  getMiscInfo,
  icon: "coa",
  desc: "COAs",
  auth: (auth) => {
    const companyRoleMap = auth.trade.user?._.companyRoleMap;

    return companyRoleMap?.charterer || companyRoleMap?.broker;
  },
  sidenav: ["orders", "owner", "coa"],
  children: {
    order: {
      path: "/:orderId",
      comp: OrderComp,
      children: {
        new: {
          comp: New,
          redirect: "./creation",
          children: {
            creation: {
              comp: Creation,
              label: "Creation",
            },
            capture: {
              comp: Capture,
              label: "Deal Capture",
            },
          },
        },
        withdrawn: {
          comp: Withdrawn,
          redirect: "./initialization",
          nav: ["./initialization", "./coaDetails", "./distribution", "./attachments"],
          children: {
            negotiations,
            liftings,
            coaDetails,
            initialization,
            distribution,
            attachments,
            circulate,
          },
        },
        nomination: {
          comp: Nomination,
          redirect: "./negotiations",
          children: { negotiations, distribution },
        },
        inactive: {
          comp: Inactive,
          nav: ["./initialization", "./coaDetails", "./distribution", "./attachments"],
          redirect: "./initialization",
          children: {
            negotiations,
            liftings,
            coaDetails,
            initialization,
            distribution,
            attachments,
            circulate,
          },
        },
        inactiveNegotiations: {
          comp: Inactive,
          nav: ["./negotiations", "./liftings", "./coaDetails", "./distribution", "./attachments"],
          redirect: "./negotiations",
          children: {
            negotiations,
            liftings,
            coaDetails,
            distribution,
            attachments,
            circulate,
          },
        },
        active: {
          comp: Active,
          redirect: "./negotiations",
          nav: ["./negotiations", "./liftings", "./coaDetails", "./distribution", "./attachments"],
          children: { negotiations, liftings, coaDetails, distribution, circulate, attachments },
        },
        failed: {
          comp: Failed,
          redirect: "./negotiations",
          nav: ["./negotiations", "./liftings", "./coaDetails", "./distribution", "./attachments"],
          children: { negotiations, liftings, coaDetails, distribution, circulate, attachments },
        },
        firm: {
          comp: Firm,
          redirect: "./negotiations",
          nav: ["./negotiations", "./liftings", "./coaDetails", "./distribution", "./attachments"],
          children: { negotiations, liftings, coaDetails, distribution, circulate, attachments },
        },
        termsLoading: {
          comp: TermsLoading,
          redirect: "./negotiations",
          nav: ["./negotiations", "./liftings", "./coaDetails", "./distribution", "./attachments"],
          children: { negotiations, liftings, coaDetails, distribution, circulate, attachments },
        },
        mainTerms: {
          comp: MainTerms,
          redirect: "./negotiations",
          nav: ["./negotiations", "./liftings", "./coaDetails", "./distribution", "./attachments"],
          children: { negotiations, liftings, coaDetails, distribution, circulate, attachments },
        },
        onSubs: {
          comp: OnSubs,
          redirect: "./negotiations",
          nav: ["./negotiations", "./liftings", "./coaDetails", "./distribution", "./attachments"],
          children: { negotiations, liftings, coaDetails, distribution, circulate, attachments },
        },
        subsLifted: {
          comp: SubsLifted,
          redirect: "./negotiations",
          nav: ["./negotiations", "./liftings", "./coaDetails", "./distribution", "./attachments"],
          children: { negotiations, liftings, coaDetails, distribution, circulate, attachments },
        },
        subsFailed: {
          comp: SubsFailed,
          redirect: "./negotiations",
          nav: ["./negotiations", "./liftings", "./coaDetails", "./distribution", "./attachments"],
          children: { negotiations, liftings, coaDetails, distribution, circulate, attachments },
        },
        fixed: {
          comp: Fixed,
          redirect: "./liftings",
          nav: ["./negotiations", "./liftings", "./coaDetails", "./distribution", "./attachments"],
          children: { negotiations, liftings, coaDetails, distribution, circulate, attachments },
        },
        archived: {
          comp: Archived,
          redirect: "./negotiations",
          nav: ["./negotiations", "./liftings", "./coaDetails", "./distribution", "./attachments"],
          children: { negotiations, liftings, coaDetails, distribution, circulate, attachments },
        },
      },
    },
  },
} as RouteSeed;

function getMiscInfo() {
  return { unseenCount: coaOrderNegotiationStore.unseenOrderCount, loading: coaOrderNegotiationStore.orderArrayStatus.loading };
}

function OrdersWrapper(props) {
  const context = useMemo(getContext, []);

  return <Orders {...props} context={context} />;
}

function getContext() {
  context.orderNegotiationStore = coaOrderNegotiationArchiveStore;
  context.activeItemsStoreRef = coaOrderNegotiationStore;
  context.routes.archivedOrders = router.get("archivedCoa");
  context.routes.order.index = router.get("archivedCoa.order");
  context.routes.order.new = router.get("coa.order.new");
  context.routes.order.withdrawn = router.get("archivedCoa.order.withdrawn");
  context.routes.order.nomination = router.get("archivedCoa.order.nomination");
  context.routes.order.inactive = router.get("archivedCoa.order.inactive");
  context.routes.order.inactiveNegotiations = router.get("archivedCoa.order.inactiveNegotiations");
  context.routes.order.active = router.get("archivedCoa.order.active");
  context.routes.order.failed = router.get("archivedCoa.order.failed");
  context.routes.order.firm = router.get("archivedCoa.order.firm");
  context.routes.order.termsLoading = router.get("archivedCoa.order.termsLoading");
  context.routes.order.mainTerms = router.get("archivedCoa.order.mainTerms");
  context.routes.order.onSubs = router.get("archivedCoa.order.onSubs");
  context.routes.order.subsLifted = router.get("archivedCoa.order.subsLifted");
  context.routes.order.subsFailed = router.get("archivedCoa.order.subsFailed");
  context.routes.order.fixed = router.get("archivedCoa.order.fixed");
  context.routes.order.archived = router.get("archivedCoa.order.archived");

  if (context.view.grid.props) {
    const orderColumnMap = getOrderColumnMap();

    context.view.grid.props.columnDefs = [
      commonColumnMap.gutterForUnseenBookmark,
      orderColumnMap.checkbox,
      orderColumnMap.accountAndOrderReference,
      orderColumnMap.cargoSizeAndCargoType,
      orderColumnMap.liftingsAndPeriod,
      orderColumnMap.loadLocationAndDischargeLocation,
      orderColumnMap.owningCompanyAndFixedOn,
      orderColumnMap.statusAndLastUpdated,
      orderColumnMap.createdByAndCreatedOn,
      {
        ...orderColumnMap.archivedOn,
        $defaultColumnState: { sort: "desc", multiValueSortName: "archivedOn" },
      },
      orderColumnMap.orderChat,
      orderColumnMap.kebab,
    ];
  }

  if (context.view.order.negotiations.grid) {
    const negotiationColumnMap = getNegotiationColumnMap();

    context.view.order.negotiations.grid.columnDefs = [
      commonColumnMap.gutterForUnseenBookmark,
      commonColumnMap.chevron,
      negotiationColumnMap.vessel,
      negotiationColumnMap.ownerAccountOrInvitee,
      {
        ...negotiationColumnMap["freightRate | hireRate of mainTermsDetails | firm bid/offer | offer"],
        $defaultColumnState: {
          sort: "asc",
          multiValueSortName: "freightRate | hireRate of mainTermsDetails | firm bid/offer | offer",
        },
      },
      negotiationColumnMap["demurrage | ballastBonus of mainTermsDetails | firm bid/offer | offer"],
      negotiationColumnMap.coaLastUpdated,
      negotiationColumnMap.coaStatus,
      commonColumnMap.attachments,
      negotiationColumnMap.chat,
      commonColumnMap.kebab,
    ];
  }

  return context;
}

export const context = {
  area: "coa-archive",

  legacyOrderType: "COA",

  routes: {
    order: {},
  },

  view: {
    grid: {
      btn: {
        close: {
          dataTest: "Close Archived",
          label: "Close",
          variant: "link",
        },
        capture: {
          dataTest: "Deal capture",
          icon: "flash-on",
          label: "Deal capture",
          variant: "action",
        },
        add: {
          dataTest: "new order",
          icon: "add-circle-outline",
          label: "Create new COA order",
          variant: "action",
        },
        kebab: {
          dataTest: "More actions",
          icon: "kebab",
          variant: "text",
        },
      },
      name: "Archived COAs",
      props: {},
    },
    order: {
      name: "Order",
      orderSearchQuery: "&orderType=coa",
      orderCreationQuery: "?type=coa",
      capture: {
        title: "Deal Capture - Add COA Order Details",
        buttons: {
          submit: "Continue",
          cancel: "",
        },
        form: {
          elements: getAbstractDetailArrayForDisplay(
            {
              chartererAccount: { type: "CollapsibleRow", name: "chartererAccount" },
              period: { type: "CollapsibleRow", name: "period" },
              liftings: { type: "CollapsibleRow", name: "liftings" },
              nominations: { type: "CollapsibleRow", name: "nominations" },
              cargoType: { type: "CollapsibleRow", name: "cargoType" },
              coaCargoSize: { type: "CollapsibleRow", name: "coaCargoSize" },
              addressCommission: { type: "CollapsibleRow", name: "addressCommission" },
              brokerCommission: { type: "CollapsibleRow", name: "brokerCommission" },
              loadLocation: { type: "CollapsibleRow", name: "loadLocation" },
              dischargeLocation: { type: "CollapsibleRow", name: "dischargeLocation" },
              coaNotes: { type: "CollapsibleRow", name: "coaNotes" },
            },
            { type: "Coa" }
          ),
          required: ["types", "period", "chartererAccount"],
          context: new FormContext(),
          mutateValues: mutateCreationFormValues,
        },
      },
      creation: {
        title: "New COA Order",
        buttons: {
          submit: "Create Order",
          cancel: "",
        },
        form: {
          elements: getAbstractDetailArrayForDisplay(
            {
              chartererAccount: { type: "CollapsibleRow", name: "chartererAccount" },
              period: { type: "CollapsibleRow", name: "period" },
              liftings: { type: "CollapsibleRow", name: "liftings" },
              nominations: { type: "CollapsibleRow", name: "nominations" },
              cargoType: { type: "CollapsibleRow", name: "cargoType" },
              coaCargoSize: { type: "CollapsibleRow", name: "coaCargoSize" },
              addressCommission: { type: "CollapsibleRow", name: "addressCommission" },
              brokerCommission: { type: "CollapsibleRow", name: "brokerCommission" },
              loadLocation: { type: "CollapsibleRow", name: "loadLocation" },
              dischargeLocation: { type: "CollapsibleRow", name: "dischargeLocation" },
              coaNotes: { type: "CollapsibleRow", name: "coaNotes" },
            },
            { type: "Coa" }
          ),
          required: ["types", "period", "chartererAccount"],
          context: new FormContext(),
          mutateValues: mutateCreationFormValues,
        },
      },
      negotiations: {
        grid: {
          columnDefs: [],
        },
      },
    },
  },

  dataTest: "archived-coa",
  name: "Archived COAs",
} as Context;

function mutateCreationFormValues(values: UniversalOrderNegotiationFormValues) {
  values.types = ["Coa"];
}
