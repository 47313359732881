import { ICOACargoSizeView } from "../Models/IDetails";
import { ICOACargoSize } from "../../api/models/ICOACargoSize";
import { IMapper, withNullable } from "./map";

const map: IMapper<ICOACargoSize, ICOACargoSizeView> = {
  toApi: (value: ICOACargoSizeView) => {
    const min = value.min ? Number(value.min.replace(/,/g, "")) : 0;
    const max = value.max ? Number(value.max.replace(/,/g, "")) : 0;
    const variance = value.variance ? Number(value.variance) : undefined;

    return {
      display: value.display || "",
      option: value.option,
      unit: value.unit || "",
      min: isNaN(min) ? 0 : min,
      max: isNaN(max) ? 0 : max,
      notes: value.notes,
      variance: variance && isNaN(variance) ? undefined : variance,
    };
  },
  toView: (coaCargoSize: ICOACargoSize) => {
    return {
      display: coaCargoSize.display,
      min: String(coaCargoSize.min),
      max: String(coaCargoSize.max),
      variance: coaCargoSize.variance !== undefined ? String(coaCargoSize.variance) : undefined,
      option: coaCargoSize.option ? String(coaCargoSize.option) : undefined,
      notes: coaCargoSize.notes,
      unit: coaCargoSize.unit,
    };
  },
  emptyViewModel: {},
};

export const mapCOACargoSize = withNullable(map);
