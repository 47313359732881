import React, { useRef, useState } from "react";
import { useShallowStateOld, emptyFn } from "___REFACTOR___/utils";
import { tradeAPI } from "___REFACTOR___/apis";
import { OrderTemplate, Status } from "___REFACTOR___/models";
import { SelectField, Field } from "___REFACTOR___/components";

function OrderTemplateSearchEditor(props: OrderTemplateSearchEditor.Props) {
  const { searchMessageMap = defaultSearchMessages, orderSearchQuery, dataTest } = props;
  const [data, setData] = useState<OrderTemplate[] | undefined>(undefined);
  const [, setDropdownStatus, dropdownStatus] = useShallowStateOld<Status>({
    message: searchMessageMap?.initial,
  });
  const cancel = useRef(emptyFn);

  return (
    <SelectField
      {...props}
      editorType="OrderTemplateSearch"
      data={data}
      placeholder="Search for Order Type, Account or Cargo Type"
      dropdownStatus={dropdownStatus}
      onFilter={onFilter}
      onBlur={onBlur}
      dataTest={`${dataTest}.value`}
      unclearable
    />
  );

  async function onFilter(search: string) {
    cancel.current();

    setData(undefined);
    setDropdownStatus({ message: undefined, loading: false, search });

    if (!search) {
      setDropdownStatus({ message: searchMessageMap?.initial });

      return;
    }

    setDropdownStatus({ message: searchMessageMap?.searching, loading: true });

    const promise = tradeAPI.searchOrderTemplates(search, orderSearchQuery);

    cancel.current = promise.cancel;

    const res = await promise;

    if (res.cancelled) return;

    if (!res.data || !res.data.length) {
      setData(undefined);
      setDropdownStatus({ message: searchMessageMap?.noData, loading: false });

      return;
    }

    const data = res.data.map((data) => new OrderTemplate(data));

    setData(data);
    setDropdownStatus({ message: undefined, loading: false });
  }

  function onBlur(e) {
    cancel.current();

    setData(undefined);
    setDropdownStatus({
      message: searchMessageMap?.initial,
      loading: false,
    });

    props.onBlur?.(e);
  }
}

const defaultSearchMessages = {
  initial: "Waiting for your input",
  searching: "Searching for order templates",
  noData: "No order templates found",
} as OrderTemplateSearchEditor.SearchMessageMap;

export { OrderTemplateSearchEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace OrderTemplateSearchEditor {
  export interface Props extends Field.Props {
    value: Value;
    onChange: (value: Value) => void;
    orderSearchQuery?: string;
    searchMessageMap?: SearchMessageMap;
  }

  export type Value = OrderTemplate | undefined;
  export interface SearchMessageMap {
    initial: string;
    searching: string;
    noData: string;
  }
}
