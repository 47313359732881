import React from "react";
import classNames from "classnames";
import { TradeAPI } from "@/apis";
import { Group } from "../../Group";
import { CLDDUEditor, CLDDUEditorProps } from "../CLDDU";
import "./CLDDUOwner.scss";

export function CLDDUOwnerEditor(props: Props) {
  props = { ...props };
  props.className = classNames(props.className, "CLDDUOwner");
  const { className, ...rest } = props;
  return (
    <CLDDUEditor
      {...rest}
      searchMessages={searchMessages}
      placeholder={placeholder}
      CLDDUSubset="usersandowners"
      CLDDUSelector={CLDDUSelector}
    />
  );
}

export function CLDDUOwnerInviteeEditor(props: Props) {
  props = { ...props };

  props.className = classNames(props.className, "CLDDUOwner");

  const { className, ...rest } = props;

  return (
    <Group className={className}>
      <CLDDUEditor
        {...rest}
        searchMessages={searchMessages}
        placeholder={placeholder}
        CLDDUSubset="usersandowners"
        CLDDUSelector={CLDDUSelector}
      />
      <div className="CLDDUOwner-info">
        Can&apos;t find the right owner? Just enter the owner&apos;s email and we will do the rest
      </div>
    </Group>
  );
}

function CLDDUSelector(clddu: TradeAPI["CLDDU"]) {
  return clddu.companyType === "owner" || (clddu.companyRoles && clddu.companyRoles.indexOf("owner") >= 0);
}

const placeholder = "Search for owners";

const searchMessages = {
  initial: "Waiting for your input",
  searching: "Searching for owners",
  noData: "No owners found",
} as Props["searchMessages"];

type Props = CLDDUEditorProps;

export type CLDDUOwnerEditorProps = Props;
