import React from "react";
import styles from "./Circulate.module.scss";
import { DistributionUserTagList } from "./DistributionUserTagList";
import { IOrder } from "orders/models/IOrder";
import LoadingSpinner from "sharedFolder/components/common/LoadingSpinner/LoadingSpinner";
import { DistributionOrderTerms } from "./DistributionOrderTerms";
import { useUser } from "__legacy/dashboard/contexts/UserProvider";

interface ICirculationProps {
  order?: IOrder;
  ctradeUrl: string;
  noOfUsers: number;
}

export const Circulate: React.FC<ICirculationProps> = (props: ICirculationProps) => {
  const { ctradeUrl, order, noOfUsers } = props;
  const user = useUser();

  const recipients = [
    ...(order?.distributionLists.companies.flatMap((company) => company.desks.flatMap((desk) => desk.recipients)) || []),
    ...(order?.distributionLists.recipients || []),
  ];

  const hasPendingOwner = recipients.some((recipient) => recipient.role === "owner" && recipient.status !== "success");
  const hasOwners = recipients.some((recipient) => recipient.role === "owner");
  const hasCharterers = recipients.some((recipient) => recipient.role === "charterer");
  const hasBrokers = recipients.some((recipient) => recipient.role === "broker");

  const recipientsWithoutActor = recipients.filter((recipient) => recipient.userId !== user.id);
  // True if there is a mix of both newly added recipients and existing recipients
  const existingUsersAndNewUsers =
    recipientsWithoutActor.some((recipient) => recipient.status !== "success") &&
    recipientsWithoutActor.some((recipient) => recipient.status === "success");

  /**
   * If the number of users expected on this page matches the number of users
   * displayed on the page then set 'numberOfExpectedUsersArePresent' to true, also
   * set it to true if it is zero, this is the default number in the routing,
   * if this zero is the number, something weird has happened and we don't want
   * to set the button as disabled
   */
  const numberOfExpectedUsersArePresent = Number(recipients.length) === Number(noOfUsers) || Number(noOfUsers) === 0;

  if (!order) {
    return (
      <div className={styles.loading}>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h2>Distribution</h2>
      {hasCharterers && (
        <>
          <DistributionUserTagList
            title="Charterer Contact(s)"
            tags={[
              ...recipients
                .filter((recipient) => recipient.role === "charterer")
                .map((recipient) => ({
                  display: recipient.name,
                  faded: ["success"].includes(recipient.status),
                })),
            ]}
          />
          <div className={styles.divider}></div>
        </>
      )}
      {hasBrokers && (
        <>
          {" "}
          <DistributionUserTagList
            title="Brokers"
            tags={[
              ...recipients
                .filter((recipient) => recipient.role === "broker")
                .map((recipient) => ({
                  display: recipient.name,
                  faded: ["success"].includes(recipient.status),
                })),
            ]}
          />
          <div className={styles.divider}></div>
        </>
      )}
      {hasOwners && (
        <DistributionUserTagList
          title="Owners (direct negotiations)"
          tags={[
            ...recipients
              .filter((recipient) => recipient.role === "owner")
              .map((recipient) => ({
                display: recipient.name,
                faded: ["success"].includes(recipient.status),
              })),
          ]}
        />
      )}
      {existingUsersAndNewUsers && (
        <div className={styles.distributionButtonWarningMessage}>
          Please note that this order will only be circulated to the new recipients
        </div>
      )}
      <DistributionOrderTerms
        order={order}
        ctradeUrl={ctradeUrl}
        isOneOfTheRecipientsAnOwner={hasPendingOwner}
        isLoading={!numberOfExpectedUsersArePresent}
        noOfUsers={noOfUsers}
      />
    </div>
  );
};
