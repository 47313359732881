import React from "react";
import { Router } from "react-router-dom";
import { observer } from "mobx-react";
import { history } from "@/history";
import { dialog, dialog0 } from "@/models";
import { Dialog, Slideout, GlobalDropdown, Main, SeaChatWidget, ObserverPopup } from "@/components";
import "./Root.scss";

function Root() {
  return (
    <Router history={history}>
      <Main />
      <Slideout />
      <GlobalDropdown />
      <Dialog model={dialog} />
      <Dialog model={dialog0} />
      <ObserverPopup />
      <SeaChatWidget />
    </Router>
  );
}

const Observer = observer(Root);

export { Observer as Root };
