import { action, makeObservable, observable } from "mobx";
import { debounce } from "lodash-es";
import { globallyUniqueEntityStorage, log } from "@/services";
import { TradeAPI, tradeAPI, VesselScoreAPI } from "@/apis";
import { AggridContext, AggridRowNode } from "@/components";
import { DataModel } from "../DataModel";
import { Order } from "../Order";
import { Negotiation } from "../Negotiation";
import { dialog } from "../Dialog";
import { wait } from "@/utils";

export class OrderNegotiationStore extends DataModel {
  OrderConstructor = Order;
  NegotiationConstructor = Negotiation;

  chunkSize = 100;
  waitTimeBeforeRequests = 250;

  orderDataQuery = new URLSearchParams();

  supportedOrderType = { Voy: true, Tct: true, Coa: true } as BoolRecord;

  orderArrayPromise = new Promise((resolve) => {
    this.resolveOrderArrayPromise = resolve;
  }) as Promise<OrderNegotiationStoreOrder[]>;
  orderArray = [] as OrderNegotiationStoreOrder[];
  orderMap = {} as MaybeRecordOf<OrderNegotiationStoreOrder>;
  movedOrdersLocations = {} as OrderNegotiationStore;

  negotiationArrayPromise = new Promise((resolve) => {
    this.resolveNegotiationArrayPromise = resolve;
  }) as Promise<OrderNegotiationStoreNegotiation[]>;
  negotiationArray = [] as OrderNegotiationStoreNegotiation[];

  unseenOrderCount = 0;
  unseenNegotiationCount = 0;

  isOrderArrayInitialized = false;
  isNegotiationArrayInitialized = false;

  visibleRowCount = Math.ceil((window.innerHeight - 191) / 54);
  totalOrderCount = Infinity;

  isOrderDataArrayEndReached = false;
  isOrderDataArrayFullyResolved = false;

  orderDataArrayEndReachedPromise = new Promise((resolve) => {
    this.resolveOrderDataArrayEndReachedPromise = resolve;
  }) as Promise<void>;

  orderGridContext = new AggridContext({
    EntityModel: Order,
    onGridReady: this.onOrderGridReady.bind(this),
  });
  negotiationGridContext = new AggridContext({
    EntityModel: Negotiation,
    onGridReady: this.onNegotiationGridReady.bind(this),
  });

  orderArrayStatus = {} as Status;
  negotiationArrayStatus = {} as Status;

  defaultInjection = { version: 0, orderNegotiationStore: this } as Injection;
  defaultOrderInjection = { ...this.defaultInjection, Type: "Order" } as OrderInjection;
  defaultNegotiationInjection = { ...this.defaultInjection, Type: "Negotiation" } as NegotiationInjection;

  orderStub = { lastUpdated: "000", version: 0 } as OrderNegotiationStoreOrder;
  negotiationStub = { lastUpdated: "000", version: 0 } as OrderNegotiationStoreNegotiation;

  onConstruct() {
    setTimeout(this.makeObservable.bind(this));
  }

  makeObservable() {
    makeObservable(this, {
      upsertNegotiations: action,
      upsertOrders: action,
      resolveUnseenCounts: action,
      getOrderData: action,
      getNegotiationsDataByOrderId: action,
      totalOrderCount: observable,
      isOrderDataArrayFullyResolved: observable,
      isOrderDataArrayEndReached: observable,
      orderArrayStatus: observable,
      negotiationArrayStatus: observable,
      unseenNegotiationCount: observable,
      unseenOrderCount: observable,
    });
  }

  async onOrderGridReady() {
    await this.orderArrayPromise;

    this.orderGridContext.api?.applyTransactionAsync({ add: [...this.orderArray] });
  }

  async onNegotiationGridReady() {
    await this.negotiationArrayPromise;

    this.negotiationGridContext.api?.applyTransactionAsync({ add: [...this.negotiationArray] });
  }

  async getOrderData(id: string, upsertConfig?: EntityUpsertConfig) {
    const order = this.upsertOrderModel({ id });

    order._.status.loading = true;
    order._.status.message = "Loading";

    const res = await tradeAPI.getOrder(id);

    if (!res?.ok) {
      order._.status.type = "error";
      order._.status.title = "Order Fetch Failure";
      order._.status.dump = { res, entity: order };

      dialog.show({
        status: order._.status,
        dataTest: "orderNegotiationStore-order-fetch-fail",
      });

      order._.status.loading = false;
      order._.status.message = null;

      return res;
    }

    if (res.ok && res.data) {
      this.upsertOrder(res.data, upsertConfig);
    }

    order._.status.loading = false;
    order._.status.message = null;

    return res;
  }

  async getArchivedOrderDataArray() {
    this.orderArrayStatus.loading = true;
    this.orderArrayStatus.message = "Fetching Orders";

    const res = await tradeAPI.getArchivedOrders(this.orderDataQuery, 0, this.visibleRowCount);

    if (!res?.ok) {
      this.orderArrayStatus.type = "error";
      this.orderArrayStatus.title = "Order Fetch Failure";
      this.orderArrayStatus.dump = { res };

      dialog.show({
        status: this.orderArrayStatus,
        dataTest: "orderNegotiationStore-orderArray-fetch-fail",
      });

      this.orderArrayStatus.loading = false;
      this.orderArrayStatus.message = null;

      return res;
    }

    if (res.ok && res.data) {
      const filtered = res.data.dataset.filter((order) => order.isArchived);
      if (res.data.totalCount >= 0) this.totalOrderCount = res.data.totalCount;

      this.upsertOrders(filtered);
    }

    this.orderArrayStatus.loading = false;
    this.orderArrayStatus.message = null;

    this.getFullOrderDataArrayOverTime(true);
  }

  async getUnarchivedOrderDataArray() {
    this.orderArrayStatus.loading = true;
    this.orderArrayStatus.message = "Fetching Orders";

    const res = await tradeAPI.getUnarchivedOrders(this.orderDataQuery, 0, this.visibleRowCount);

    if (!res?.ok) {
      this.orderArrayStatus.type = "error";
      this.orderArrayStatus.title = "Order Fetch Failure";
      this.orderArrayStatus.dump = { res };

      dialog.show({
        status: this.orderArrayStatus,
        dataTest: "orderNegotiationStore-orderArray-fetch-fail",
      });

      this.orderArrayStatus.loading = false;
      this.orderArrayStatus.message = null;

      return res;
    }

    if (res.ok && res.data) {
      const filtered = res.data.dataset.filter((order) => !order.isArchived);

      if (res.data.totalCount >= 0) this.totalOrderCount = res.data.totalCount;

      this.upsertOrders(filtered);
    }

    this.orderArrayStatus.loading = false;
    this.orderArrayStatus.message = null;

    this.getFullOrderDataArrayOverTime(false);
  }

  async getFullOrderDataArrayOverTime(archived: boolean) {
    if (localStorage.SUPPRESS_ORDER_DATA_FETCH_OVER_TIME) {
      return;
    }

    if (isFinite(this.totalOrderCount)) {
      this.sendFiniteAmountOfRequests(archived);
    } else {
      this.sendIndefiniteAmountOfRequests(archived);
    }
  }

  async sendFiniteAmountOfRequests(archived: boolean) {
    const promises = [] as Promise<any>[];
    for (
      let skip = this.visibleRowCount;
      skip <= this.totalOrderCount && !this.isOrderDataArrayEndReached;
      skip += this.chunkSize
    ) {
      await wait(this.waitTimeBeforeRequests);
      promises.push(this.getOrderDataArraySubset(skip, this.chunkSize, archived));
    }
    await Promise.all(promises);
    this.isOrderDataArrayEndReached = true;
    this.resolveOrderDataArrayEndReachedPromise();
    this.isOrderDataArrayFullyResolved = true;
  }

  async sendIndefiniteAmountOfRequests(archived: boolean) {
    const promises = [] as Promise<any>[];
    let skip = this.visibleRowCount;
    while (!this.isOrderDataArrayEndReached) {
      await wait(this.waitTimeBeforeRequests);
      promises.push(this.getOrderDataArraySubset(skip, this.chunkSize, archived));
      skip += this.chunkSize;
    }
    await Promise.all(promises);
    this.isOrderDataArrayFullyResolved = true;
  }

  async getOrderDataArraySubset(skip = 0, take = this.chunkSize, archived: boolean) {
    if (archived) {
      return tradeAPI.getArchivedOrders(this.orderDataQuery, skip, take).then((res) => {
        if (!res?.ok || !res.data) {
          this.isOrderDataArrayEndReached = true;

          logError("Incremental Order Fetch Failed", { res });

          return res;
        }

        this.upsertOrders(res.data.dataset);

        if (res.data.dataset.length < take) {
          this.isOrderDataArrayEndReached = true;

          this.resolveOrderDataArrayEndReachedPromise();

          return;
        }
      });
    } else if (archived === false) {
      return tradeAPI.getUnarchivedOrders(this.orderDataQuery, skip, take).then((res) => {
        if (!res?.ok || !res.data) {
          this.isOrderDataArrayEndReached = true;

          logError("Incremental Order Fetch Failed", { res });

          return res;
        }

        const filtered = res.data.dataset.filter((order) => !order.isArchived);

        this.upsertOrders(filtered);

        if (res.data.dataset.length < take) {
          this.isOrderDataArrayEndReached = true;

          this.resolveOrderDataArrayEndReachedPromise();

          return;
        }
      });
    }
  }

  async upsertOrders(data: TradeAPI["Order"][]) {
    const orderArray = data as OrderNegotiationStoreOrder[];
    const isFirstLoad = !this.orderArray.length;

    if (isFirstLoad) {
      this.orderArray = orderArray;
      this.isOrderArrayInitialized = true;
      this.resolveOrderArrayPromise(orderArray);

      for (let i = 0; i < orderArray.length; i++) {
        this.upsertOrder(orderArray[i], { isTheNewRef: true, shouldUpdateArray: false });
      }

      //
    } else {
      for (let i = 0; i < orderArray.length; i++) {
        this.upsertOrder(orderArray[i]);
      }
    }

    this.resolveUnseenCountsDebounced();
  }

  moveOrderToStore(order: DeepPartial<TradeAPI["Order"]>, targetStore: OrderNegotiationStore) {
    if (!order) {
      return;
    }

    this.removeOrder(order);
    targetStore.insertExistingOrder(order);
    this.movedOrdersLocations[order.id!] = targetStore;
  }

  removeOrder(data: DeepPartial<TradeAPI["Order"]>) {
    const order = data as OrderNegotiationStoreOrder;

    if (!order) {
      return;
    }

    const existingOrder = this.orderMap[order.id];
    if (!existingOrder) {
      return;
    }

    this.orderGridContext.api?.applyTransactionAsync({ remove: [existingOrder] });
    this.orderArray = this.orderArray.filter((order) => order.id !== existingOrder.id);
    delete this.orderMap[order.id];
  }

  insertExistingOrder(data: DeepPartial<TradeAPI["Order"]>) {
    const order = data as OrderNegotiationStoreOrder;
    if (!order) {
      return;
    }

    this.orderMap[order.id] = order;
    order._ = { ...this.defaultOrderInjection };
    this.orderArray.push(order);
    this.orderGridContext?.api?.applyTransactionAsync({ add: [order], addIndex: 0 });
  }

  upsertOrder(data: DeepPartial<TradeAPI["Order"]>, config?: OrderUpsertConfig) {
    data.lastUpdated = data.lastUpdated || this.orderStub.lastUpdated;
    data.version = data.version || this.orderStub.version;

    if (typeof data.id !== "string" || typeof data.lastUpdated !== "string" || typeof data.version !== "number") {
      logError("OrderNegotiationStore.upsertOrder: Missing data.id or data.lastUpdated or data.version", { data });

      throw new Error("OrderNegotiationStore.upsertOrder: Missing data.id or data.lastUpdated or data.version");
    }

    const isDummy = data.id.includes("$");
    const order = data as OrderNegotiationStoreOrder;
    const _config = { ...defaultOrderUpsertConfig, ...config };
    const { shouldUpdateModel, shouldUpdateArray, shouldAddExistingOrderToArray, forceUpdate } = _config;
    const existingOrder = this.orderMap[order.id];
    const isTheNewRef = !existingOrder || _config?.isTheNewRef;
    const isExistingOrderVersionNewer = existingOrder && order.version < existingOrder.version;
    const isExistingOrderLastUpdatedNewer = existingOrder && new Date(order.lastUpdated) < new Date(existingOrder.lastUpdated);

    let isExistingOrderNewer = isExistingOrderVersionNewer || isExistingOrderLastUpdatedNewer;

    if (forceUpdate) isExistingOrderNewer = false;

    this.resolveUnseenCountsDebounced();

    if (isTheNewRef) {
      this.orderMap[order.id] = order;
    }

    if (isTheNewRef && isExistingOrderNewer) {
      Object.assign(order, existingOrder);
    }

    if (isTheNewRef && existingOrder) {
      order._ = existingOrder._;

      if (order._.model) {
        order._.model._.orderNegotiationStoreOrder = order;
      }

      return order;
    }

    if (existingOrder && isExistingOrderNewer) {
      // DO NOTHING -- IT IS A STALE UPDATE

      return existingOrder;
    }

    if (existingOrder) {
      if (!existingOrder._) {
        logError("OrderNegotiationStore.upsertOrder: Missing existingOrder._", { order, existingOrder, _config });

        throw new Error("OrderNegotiationStore.upsertOrder: Missing existingOrder._");
      }

      Object.assign(existingOrder, order);

      existingOrder._.version++;

      if (shouldUpdateModel) {
        existingOrder._.model?.update(existingOrder, undefined, { ...defaultModelUpdateConfig, forceUpdate });
      }

      if (shouldAddExistingOrderToArray) {
        const duplicatedOrder = this.orderArray.find((order) => order.id === existingOrder.id);

        if (!duplicatedOrder) {
          this.orderArray.push(existingOrder);
          this.orderGridContext.api?.applyTransactionAsync({ add: [existingOrder], addIndex: 0 });
        }
      } else if (existingOrder._.aggridContext) {
        // lead to Aggrid console error with missing rowId after it was removed
        // TODO: add some check to skip update

        existingOrder._.aggridContext?.api?.applyTransactionAsync({ update: [existingOrder] });

        //
      } else {
        this.orderGridContext?.api?.applyTransactionAsync({ add: [], addIndex: 0 });
      }

      return existingOrder;
    }

    if (!existingOrder) {
      order._ = { ...this.defaultOrderInjection };

      if (shouldUpdateArray && this.isOrderArrayInitialized && !isDummy) {
        this.orderArray.push(order);
        this.orderGridContext?.api?.applyTransactionAsync({ add: [order], addIndex: 0 });
      }

      return order;
    }

    logError("OrderNegotiationStore.upsertOrder: Failed to upsert order", {
      _config,
      order,
      existingOrder,
      isExistingOrderNewer,
    });

    throw new Error("OrderNegotiationStore.upsertOrder: Failed to upsert order");
  }

  //TODO: small suggestion here, if the return value is not used maybe not return it?
  //It's considered good clean code practice for methods  either return something or do something (operate on data), but not both.
  async getNegotiationsDataByOrderId(orderId: string, upsertConfig?: EntityUpsertConfig) {
    const order = this.upsertOrderModel({ id: orderId });

    order._.status.loading = true;
    order._.status.message = "Fetching Negotiations";

    const res = await tradeAPI.getNegotiations(orderId);

    if (!res?.ok) {
      order._.status.type = "error";
      order._.status.title = "Order Negotiations Fetch Failure";
      order._.status.dump = { res, entity: order };

      dialog.show({
        status: order._.status,
        dataTest: "orderNegotiationStore-order-negotiationArray-fetch-fail",
      });

      order._.status.loading = false;
      order._.status.message = null;

      return res;
    }

    if (res.ok && res.data) {
      this.upsertNegotiations(res.data, upsertConfig);
    }

    order._.status.loading = false;
    order._.status.message = null;

    return res;
  }

  async upsertNegotiations(data: TradeAPI["Negotiation"][], upsertConfig?: EntityUpsertConfig) {
    const negotiationArray = data as OrderNegotiationStoreNegotiation[];
    const isFirstLoad = !this.negotiationArray.length;

    if (isFirstLoad) {
      this.negotiationArray = negotiationArray;
      this.isNegotiationArrayInitialized = true;
      this.resolveNegotiationArrayPromise(negotiationArray);

      for (let i = 0; i < negotiationArray.length; i++) {
        this.upsertNegotiation(negotiationArray[i], { ...upsertConfig, isTheNewRef: true, shouldUpdateArray: false });
      }

      //
    } else {
      for (let i = 0; i < negotiationArray.length; i++) {
        this.upsertNegotiation(negotiationArray[i], upsertConfig);
      }
    }

    this.resolveUnseenCountsDebounced();
  }

  moveNegotiationToStore(negotiation: DeepPartial<TradeAPI["Negotiation"]>, targetStore: OrderNegotiationStore) {
    if (!negotiation) {
      return;
    }

    this.removeNegotitaiton(negotiation);
    targetStore.insertExistingNegotiationIfNotExists(negotiation);
  }

  removeNegotitaiton(data: DeepPartial<TradeAPI["Negotiation"]>) {
    const negotiation = data as OrderNegotiationStoreNegotiation;
    if (!negotiation) {
      return;
    }

    const orderId = negotiation.orderId;
    const existingOrder = this.orderMap[orderId];
    if (!existingOrder || !existingOrder._ || !existingOrder._.negotiationMap) {
      return;
    }

    const existingNegotiation = existingOrder._.negotiationMap[negotiation.id];

    this.negotiationGridContext.api?.applyTransactionAsync({ remove: [existingNegotiation] });
    this.negotiationArray = this.negotiationArray.filter((neg) => neg.id !== existingNegotiation!.id);
    delete existingOrder._.negotiationMap[negotiation.id];

    if (Object.keys(existingOrder._.negotiationMap).length === 0) {
      // There can be more than 1 negotiation in the parent order
      delete this.orderMap[orderId];
    }
  }

  insertExistingNegotiationIfNotExists(data: DeepPartial<TradeAPI["Negotiation"]>) {
    const negotiation = data as OrderNegotiationStoreNegotiation;
    if (!negotiation) {
      return;
    }

    const order = this.upsertOrder({ id: negotiation.orderId });
    if (!order._.negotiationMap) {
      order._.negotiationMap = {};
    }

    if (order._.negotiationMap[negotiation.id]) {
      // Negotiation is already there, may happen as a result of insertion after SignalR update
      return;
    }

    order._.negotiationMap[negotiation.id] = negotiation;
    if (!order._.negotiationArray) {
      order._.negotiationArray = [negotiation];
    } else {
      order._.negotiationArray.push(negotiation);
    }
    order._.negotiationGridContext?.api?.applyTransactionAsync({ add: [negotiation], addIndex: 0 });

    negotiation._ = { ...this.defaultNegotiationInjection };
    this.negotiationArray.push(negotiation);
    this.negotiationGridContext.api?.applyTransactionAsync({ add: [negotiation], addIndex: 0 });
  }

  protected canNegotationBeInserted(neg: TradeAPI["Negotiation"]) {
    return true;
  }

  upsertNegotiation(
    data: DeepPartial<TradeAPI["Negotiation"]>,
    config?: NegotiationUpsertConfig
  ): OrderNegotiationStoreNegotiation {
    data.lastUpdated = data.lastUpdated || this.negotiationStub.lastUpdated;
    data.version = data.version || this.negotiationStub.version;
    data.updateToken = data.updateToken || this.negotiationStub.updateToken;

    if (
      typeof data.id !== "string" ||
      typeof data.orderId !== "string" ||
      typeof data.lastUpdated !== "string" ||
      typeof data.version !== "number"
    ) {
      logError("OrderNegotiationStore.upsertNegotiation: Missing data.id or data.orderId or data.lastUpdated or data.version", {
        data,
      });

      throw new Error(
        "OrderNegotiationStore.upsertNegotiation: Missing data.id or data.orderId or data.lastUpdated or data.version"
      );
    }

    const negotiation = data as OrderNegotiationStoreNegotiation;
    const existingOrder = this.orderMap[negotiation.orderId];
    if (!existingOrder && this.movedOrdersLocations[negotiation.orderId]) {
      // There can be a situation, when Trade received response from the backend with a list of negotiations,
      // but the order model was moved to a different store (e.g in case of archving). In this case we need to upsert negotiation model into different store

      const newOrderLocation = this.movedOrdersLocations[negotiation.orderId];
      return newOrderLocation.upsertNegotiation(data, config);
    }

    const _config = { ...defaultNegotiationUpsertConfig, ...config };
    const {
      shouldUpdateModel,
      shouldUpdateArray,
      forceUpdate,
      shouldAddExistingNegotiationToArray,
      shouldRemoveExistingNegotiationFromArray,
    } = _config;

    const order = existingOrder || this.upsertOrder({ id: negotiation.orderId });

    if (!order._) {
      logError("OrderNegotiationStore.upsertNegotiation: Missing order._", { order, negotiation, _config });

      throw new Error("OrderNegotiationStore.upsertNegotiation: Missing order._");
    }

    order._.negotiationArray = order._.negotiationArray || [];
    order._.negotiationMap = order._.negotiationMap || {};

    const isDummy = data.id.includes("$");
    const existingNegotiation = order._.negotiationMap[negotiation.id];
    if (!existingNegotiation && !this.canNegotationBeInserted(negotiation)) {
      // Attemp to insert negotiation into the store, that doesn't suppose to contain it
      // We won't store it
      return negotiation;
    }

    const isTheNewRef = !existingNegotiation || _config?.isTheNewRef;
    // const negotiationUpdateTokenExp = Negotiation.prototype.getParsedUpdateToken.call(negotiation)?.exp || 0;
    // const existingNegotiationUpdateTokenExp = Negotiation.prototype.getParsedUpdateToken.call(negotiation)?.exp || 0;
    // const isExistingNegotiationUpdateTokenNewer = negotiationUpdateTokenExp < existingNegotiationUpdateTokenExp;
    const negotiationLastUpdated = negotiation.lastUpdated;
    const existingNegotiationLastUpdated = existingNegotiation?.lastUpdated || 0;
    const isExistingNegotiationLastUpdatedNewer = new Date(negotiationLastUpdated) < new Date(existingNegotiationLastUpdated);
    const negotiationVersion = negotiation.version;
    const existingNegotiationVersion = existingNegotiation?.version || 0;
    const isExistingNegotiationVersionNewer = negotiationVersion < existingNegotiationVersion;
    let isExistingNegotiationNewer = isExistingNegotiationLastUpdatedNewer || isExistingNegotiationVersionNewer;

    if (forceUpdate) isExistingNegotiationNewer = false;

    // log.system("OrderNegotiationStore.upsertNegotiation:", {
    //   // updateTokenExp: {
    //   //   incoming: negotiationUpdateTokenExp,
    //   //   existing: existingNegotiationUpdateTokenExp,
    //   //   isExistingNegotiationUpdateTokenNewer,
    //   // },
    //   lastUpdated: {
    //     incoming: negotiationLastUpdated,
    //     existing: existingNegotiationLastUpdated,
    //     isExistingNegotiationLastUpdatedNewer,
    //   },
    //   version: {
    //     incoming: negotiationVersion,
    //     existing: existingNegotiationVersion,
    //     isExistingNegotiationVersionNewer,
    //   },
    //   data: {
    //     incoming: { ...negotiation },
    //     existing: { ...existingNegotiation },
    //   },
    //   model: {
    //     incoming: { ...negotiation._?.model },
    //     existing: { ...existingNegotiation?._?.model },
    //   },
    //   config: _config,
    //   isExistingNegotiationNewer,
    // });

    this.resolveUnseenCountsDebounced();

    if (isTheNewRef) {
      order._.negotiationMap[negotiation.id] = negotiation;
    }

    if (isTheNewRef && isExistingNegotiationNewer) {
      Object.assign(negotiation, existingNegotiation);
    }

    if (isTheNewRef && existingNegotiation) {
      negotiation._ = existingNegotiation._;

      if (negotiation._.model) {
        negotiation._.model._.orderNegotiationStoreNegotiation = negotiation;
      }

      return negotiation;
    }

    if (existingNegotiation && isExistingNegotiationNewer) {
      // DO NOTHING -- IT IS A STALE UPDATE

      return existingNegotiation;
    }

    if (existingNegotiation) {
      if (!existingNegotiation._) {
        logError("OrderNegotiationStore.upsertNegotiation: Missing existingNegotiation._", {
          existingNegotiation,
          order,
          negotiation,
          _config,
        });

        throw new Error("OrderNegotiationStore.upsertNegotiation: Missing existingNegotiation._");
      }

      Object.assign(existingNegotiation, negotiation);

      existingNegotiation._.version++;

      if (shouldUpdateModel) {
        existingNegotiation._.model?.update(existingNegotiation, undefined, { ...defaultModelUpdateConfig, forceUpdate });
      }
      if (shouldAddExistingNegotiationToArray) {
        const duplicatedNegotiation = this.negotiationArray.find((neg) => neg.id === existingNegotiation.id);

        if (!duplicatedNegotiation) {
          this.negotiationArray.push(existingNegotiation);
          this.negotiationGridContext.api?.applyTransactionAsync({ add: [existingNegotiation], addIndex: 0 });
        }
      } else if (shouldRemoveExistingNegotiationFromArray) {
        this.negotiationGridContext.api?.applyTransactionAsync({ remove: [existingNegotiation] });
        this.negotiationArray = this.negotiationArray.filter((neg) => neg.id !== existingNegotiation.id);
      } else if (existingNegotiation._.aggridContext) {
        existingNegotiation._.aggridContext?.api?.applyTransactionAsync({ update: [existingNegotiation] });

        //
      } else {
        this.orderGridContext?.api?.applyTransactionAsync({ add: [], addIndex: 0 });
      }

      return existingNegotiation;
    }

    if (!existingNegotiation) {
      negotiation._ = { ...this.defaultNegotiationInjection };

      if (!isDummy) order._.negotiationArray.push(negotiation);
      order._.negotiationGridContext?.api?.applyTransactionAsync({ add: [negotiation], addIndex: 0 });

      if (order._.model) {
        order._.model._.orderNegotiationStoreOrderNegotiationArray = [...order._.negotiationArray];
      }

      if (shouldUpdateArray && this.isNegotiationArrayInitialized && !isDummy) {
        this.negotiationArray.push(negotiation);

        this.negotiationGridContext?.api?.applyTransactionAsync({ add: [negotiation], addIndex: 0 });
      }

      return negotiation;
    }

    const dump = { _config, order, negotiation, existingNegotiation, isExistingNegotiationNewer };

    logError("OrderNegotiationStore.upsertNegotiation: Failed to upsert negotiation", dump);

    throw new Error("OrderNegotiationStore.upsertNegotiation: Failed to upsert negotiation");
  }

  getOrderTreeData(id?: string, order?: TradeAPI["Order"], upsertConfig?: EntityUpsertConfig) {
    id = id || order?.id;

    if (!id) return;

    if (order) {
      this.upsertOrder(order, upsertConfig);
    } else {
      this.getOrderData(id, upsertConfig);
    }

    this.getNegotiationsDataByOrderId(id, upsertConfig);
  }

  getOrderModel(orderId: string | undefined) {
    if (!orderId) {
      return;
    }

    return this.orderMap[orderId]?._.model;
  }

  upsertOrderModel(data: DeepPartial<TradeAPI["Order"]>, updateConfig?: Order["UpdateConfig"]) {
    if (!data.id) {
      logError("OrderNegotiationStore.upsertOrder: Missing order.id", { data });

      throw new Error("OrderNegotiationStore.upsertOrder: Missing order.id");
    }

    const existingOrderModel = this.getOrderModel(data.id);

    if (existingOrderModel) return existingOrderModel;

    const orderModel = new this.OrderConstructor(
      data,
      { orderNegotiationStore: this, NegotiationConstructor: this.NegotiationConstructor },
      updateConfig
    );

    return orderModel;
  }

  getNegotiationModel(negotiationId: string) {
    if (!negotiationId) {
      return;
    }

    return this.negotiationArray.find((obj) => obj.id === negotiationId)?._.model;
  }

  upsertNegotiationModel(data: DeepPartial<TradeAPI["Negotiation"]>) {
    if (!data.id) {
      logError("OrderNegotiationStore.upsertNegotiation: Missing negotiation.id", { data });

      throw new Error("OrderNegotiationStore.upsertNegotiation: Missing negotiation.id");
    }

    const existingModel = this.getNegotiationModel(data.id);

    if (existingModel) return existingModel;

    const model = new this.NegotiationConstructor(data, { orderNegotiationStore: this });

    return model;
  }

  resolveUnseenCounts() {
    this.unseenNegotiationCount = 0;
    this.unseenOrderCount = 0;

    for (let i = 0; i < this.orderArray.length; i++) {
      const order = this.orderArray[i];
      const isUnseen = this.isUnseen(order);

      if (isUnseen) this.unseenOrderCount++;
    }

    for (let i = 0; i < this.negotiationArray.length; i++) {
      const negotiation = this.negotiationArray[i];
      const isUnseen = this.isUnseen(negotiation);

      if (isUnseen) this.unseenNegotiationCount++;
    }
  }

  resolveUnseenCountsDebounced = debounce(this.resolveUnseenCounts.bind(this), 333);

  async setSeenVersion(entity: OrderNegotiationStoreOrder | OrderNegotiationStoreNegotiation | Order | Negotiation) {
    globallyUniqueEntityStorage.setSeenVersion(entity);

    if (entity instanceof Order) {
      entity._.orderNegotiationStoreOrder?._.aggridRowNode?.setData(entity._.orderNegotiationStoreOrder);

      //
    } else if (entity instanceof Negotiation) {
      entity._.orderNegotiationStoreNegotiation?._.aggridRowNode?.setData(entity._.orderNegotiationStoreNegotiation);

      //
    } else if (entity._.Type === "Order") {
      const order = this.orderMap[entity.id];

      order?._.aggridRowNode?.setData(order);

      //
    } else if (entity._.Type === "Negotiation") {
      // @ts-ignore
      const order = this.orderMap[entity.orderId];
      const negotiation = order?._.negotiationMap?.[entity.id];

      negotiation?._.aggridRowNode?.setData(negotiation);
    }

    this.resolveUnseenCountsDebounced();
  }

  isUnseen(entity: OrderNegotiationStoreOrder | OrderNegotiationStoreNegotiation) {
    return entity.version !== globallyUniqueEntityStorage.get(entity)?.seenVersion;
  }
}

const defaultEntityUpsertConfig = {
  isTheNewRef: false,
  shouldUpdateModel: true,
  shouldUpdateArray: true,
} as EntityUpsertConfig;

const defaultOrderUpsertConfig = {
  ...defaultEntityUpsertConfig,
} as OrderUpsertConfig;

const defaultNegotiationUpsertConfig = {
  ...defaultEntityUpsertConfig,
} as NegotiationUpsertConfig;

const defaultModelUpdateConfig = {
  shouldUpdateOrderNegotiationStore: false,
};

const errorDialogProps = {
  status: {
    type: "error",
    title: "Order Negotiation Store Failure",
  } as Status,
  dataTest: "order-negotiation-store-error",
};

function logError(message, dump) {
  log.error(errorDialogProps, message, dump);
}

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

export interface OrderNegotiationStore {
  resolveOrderDataArrayEndReachedPromise(): void;
  resolveOrderArrayPromise(orderArray: OrderNegotiationStoreOrder[]): void;
  resolveNegotiationArrayPromise(negotiationArray: OrderNegotiationStoreNegotiation[]): void;

  Order: OrderNegotiationStoreOrder;
  Negotiation: OrderNegotiationStoreNegotiation;
  OrderInjection: OrderInjection;
  NegotiationInjection: NegotiationInjection;
  Injection: Injection;
  OrderUpsertConfig: OrderUpsertConfig;
  NegotiationUpsertConfig: NegotiationUpsertConfig;
  EntityUpsertConfig: EntityUpsertConfig;
}

interface OrderNegotiationStoreOrder extends The<TradeAPI["Order"]> {
  _: OrderInjection;
}

interface OrderNegotiationStoreNegotiation extends The<TradeAPI["Negotiation"]> {
  _: NegotiationInjection;
}

interface OrderInjection extends Injection {
  Type: "Order";
  model?: Order;
  negotiationArray?: OrderNegotiationStoreNegotiation[];
  negotiationMap?: RecordOf<OrderNegotiationStoreNegotiation | undefined>;
  negotiationGridContext?: AggridContext;
}

interface NegotiationInjection extends Injection {
  Type: "Negotiation";
  model?: Negotiation;
  order?: OrderNegotiationStoreOrder;
  negotiationGridContext?: AggridContext;
  vesselScoreMap?: Map<string, VesselScoreAPI.VesselScore>;
  vesselScoreError?: {
    status: number;
    statusText: string;
  };
}

interface Injection {
  version: number;
  orderNegotiationStore: OrderNegotiationStore;
  aggridContext?: AggridContext;
  aggridRowNode?: AggridRowNode;
}

interface OrderUpsertConfig extends EntityUpsertConfig {
  shouldAddExistingOrderToArray?: boolean;
}

interface NegotiationUpsertConfig extends EntityUpsertConfig {
  shouldAddExistingNegotiationToArray?: boolean;
  shouldRemoveExistingNegotiationFromArray?: boolean;
}

export interface EntityUpsertConfig {
  isTheNewRef?: boolean;
  shouldUpdateModel?: boolean;
  shouldUpdateArray?: boolean;
  forceUpdate?: boolean;
  callee?: any;
}
