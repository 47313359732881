import React, { useContext } from "react";
import { observer } from "mobx-react";
import { Context } from "../../Orders";
import { OrderGrid } from "./OrderGrid";
import { OwnerNegotiationGrid } from "./OwnerNegotiationGrid";

function Grid() {
  const context = useContext(Context);
  const type = context.view.grid.type || "OrderGrid";
  const Grid = GRID_MAP[type];

  return <Grid />;
}

const GRID_MAP = {
  OwnerNegotiationGrid,
  OrderGrid,
};

const Observer = observer(Grid);

export { Observer as Grid };
