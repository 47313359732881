import { OrderNegotiationStore } from "../OrderNegotiationStore";

class StandardOrderNegotiationStore extends OrderNegotiationStore {
  supportedOrderType = {
    Voy: true,
    Tct: true,
  };
}

export const standardOrderNegotiationStore = new StandardOrderNegotiationStore();
