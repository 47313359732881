import React, { useRef, useState } from "react";
import { useShallowStateOld, emptyFn } from "___REFACTOR___/utils";
import { tradeAPI } from "___REFACTOR___/apis";
import { Account, Status } from "___REFACTOR___/models";
import { SelectField } from "___REFACTOR___/components";

function AccountEditor(props: AccountEditor.Props) {
  const { searchMessageMap = defaultsearchMessageMap, legalEntitiesOnly = false, dataTest } = props;
  const [data, setData] = useState<Account[] | undefined>(undefined);
  const [, setDropdownStatus, dropdownStatus] = useShallowStateOld<Status>({
    message: searchMessageMap?.initial,
  });
  const cancelRef = useRef(emptyFn);

  return (
    <SelectField
      {...props}
      data={data}
      dropdownStatus={dropdownStatus}
      onFilter={onFilter}
      onBlur={onBlur}
      dataTest={`${dataTest}.value`}
      editorType="Account"
    />
  );

  async function onFilter(search: string) {
    cancelRef.current();

    setData(undefined);
    setDropdownStatus({ message: undefined, loading: false, search });

    if (!search) {
      setDropdownStatus({ message: searchMessageMap?.initial });

      return;
    }

    setDropdownStatus({ message: searchMessageMap?.searching, loading: true });

    const promise = tradeAPI.searchAccounts(search, legalEntitiesOnly);

    cancelRef.current = promise.cancel;

    const res = await promise;

    if (res.cancelled) return;

    if (!res.data || !res.data.length) {
      setData(undefined);
      setDropdownStatus({ message: searchMessageMap?.noData, loading: false });

      return;
    }

    const data = res.data.map((el) => new Account(el.document));

    setData(data);
    setDropdownStatus({ message: undefined, loading: false });
  }

  function onBlur(e) {
    cancelRef.current();

    setData(undefined);
    setDropdownStatus({
      message: searchMessageMap?.initial,
      loading: false,
    });

    props.onBlur?.(e);
  }
}

const defaultsearchMessageMap = {
  initial: "Please enter a charterer's name",
  searching: "Searching for charterers",
  noData: "No charterers found",
} as AccountEditor.SearchMessageMap;

export { AccountEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace AccountEditor {
  export interface Props extends SelectField.Props {
    value: Value;
    onChange: (value: Value) => void;
    legalEntitiesOnly?: boolean;
    searchMessageMap?: SearchMessageMap;
  }

  export type Value = Account | undefined;

  export interface SearchMessageMap {
    initial: string;
    searching: string;
    noData: string;
  }
}
