import React, { ReactNode } from "react";
import { observer } from "mobx-react";
import { useParams, NavLinkProps } from "react-router-dom";
import { router, Route as RouteModel } from "@/models";
import { NavLink, Spinner } from "@/components";
import "./Tabs.scss";

const Tabs = (props: Props) => {
  const params = useParams();
  const { links = [] } = props;
  const { active } = router;
  const { nav = [] } = active;

  if (!nav?.length) return null;

  return (
    <nav className="tabs" data-test="order-header-nav">
      {links.map(TabHOC.bind(null, params, props.onClick))}
      {nav.map(TabRouteHOC.bind(null, params, props.onClick))}
      {props.children}
    </nav>
  );
};

function TabRouteHOC(params: any, onClick, route: RouteModel) {
  return <TabObserver onClick={onClick} params={params} route={route} key={route.absPath} />;
}

function TabHOC(params: any, onClick, props: TabProps) {
  return <TabObserver onClick={onClick} {...props} params={params} key={props.to} />;
}

const TabObserver = observer(function Tab(props: TabProps) {
  let { route, params, id, to, label, exact } = props;
  let badgeCount = 0;
  let loading = false;
  let desc;

  if (route) {
    const miscInfo = route.getMiscInfo?.(params);
    badgeCount = miscInfo?.badgeCount;
    loading = miscInfo?.loading;
    to = route.compile(params);
    label = route.label;
    desc = route.desc;
  }

  const spinner = loading ? <Spinner status={{ loading: true }} /> : null;

  return (
    <NavLink dataTest={`${label}-Tab`} to={to} key={to} title={desc} id={id} exact={exact} onClick={onClick}>
      {label}
      {badgeCount > 0 && !loading && <span>{badgeCount}</span>}
      {spinner}
    </NavLink>
  );

  function onClick(e) {
    props.onClick?.(e, props);
  }
});

const Observer = observer(Tabs);

export { Observer as Tabs };

interface Props {
  children?: ReactNode;
  links?: TabProps[];
  onClick?: (e, tabProps: TabProps) => void;
}

interface TabProps extends Omit<NavLinkProps, "to" | "onClick"> {
  onClick?(e, tabProps: TabProps): void;
  label?: string;
  to?: string;
  params?: any;
  route?: RouteModel;
}
