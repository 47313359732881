import { AggridContext, AggridRowNode, IconProps, ImgProps } from "@/components";
import { cleanValue } from "@/utils";
import { makeAutoObservable } from "mobx";

export class DataModel<
  Data,
  Props,
  UpdateConfig,
  ConstructorData = Data,
  ConstructorProps = Props,
  UpdateData = ConstructorData,
  UpdateProps = ConstructorProps
> {
  constructor(data?: ConstructorData, props?: ConstructorProps, config?: UpdateConfig) {
    this.construct(data, props, config);
    this.update(data, props, config);
  }

  construct(data?: ConstructorData, props?: ConstructorProps, config?: UpdateConfig) {
    if (data) {
      // @ts-ignore
      const { _, ...assignable } = data;

      Object.assign(this, assignable);
    }

    if (this.Props) {
      this._ = new this.Props();
    }

    if (props) {
      Object.assign(this._, props);
    }

    this.onConstruct(data, props, config);
  }

  update(data?: ConstructorData | UpdateData, props?: ConstructorProps | UpdateProps, config?: UpdateConfig) {
    if (this.isDataStale(data, props, config)) return;

    if (data) {
      // @ts-ignore
      const { _, ...assignable } = data;

      Object.assign(this, assignable);
    }

    this.onUpdate(data, props, config);

    if (this._) {
      this._.version = this._.version || 0;
      this._.version++;
    }
  }

  assignData(data?: ConstructorData | UpdateData, props?: ConstructorProps | UpdateProps, config?: UpdateConfig) {
    if (this.isDataStale(data, props, config)) return;

    if (data) {
      // @ts-ignore
      const { _, ...assignable } = data;

      Object.assign(this, assignable);
    }
  }

  isDataStale(data?: ConstructorData | UpdateData, props?: ConstructorProps | UpdateProps, config?: UpdateConfig) {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onConstruct(data?: ConstructorData, props?: ConstructorProps, config?: UpdateConfig) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onUpdate(data?: ConstructorData | UpdateData, props?: ConstructorProps | UpdateProps, config?: UpdateConfig) {}

  makeObservable() {
    // @ts-ignore
    makeAutoObservable(this);
  }

  isValid() {
    return true;
  }

  toJSON(): any {
    let { _, ...data } = this;

    data = cleanValue(data);

    if (!data) return;

    const entries = Object.entries(data);

    if (!entries.length) return;

    if (_?.JSONDefaults) {
      const defaultEntries = Object.entries(_.JSONDefaults);

      for (let i = 0; i < defaultEntries.length; i++) {
        const [key, value] = defaultEntries[i];

        data[key] = data[key] || value;
      }
    }

    // @ts-ignore
    return data;
  }

  toString() {
    return "" as any;
  }

  toView() {
    return this.toString();
  }

  toSelectView() {
    return this.toDropdownView();
  }

  toDropdownView() {
    return this.toString();
  }

  toGridView() {
    return this.toString();
  }
}

export class DataModelProps<Data> {}

DataModelProps.prototype.toJSON = function () {
  return null;
};

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

export interface DataModel<Data = any, Props extends DataModelProps<Data> = DataModelProps<Data>, UpdateConfig = any> {
  _: Props;
  Props: {
    new (...args: any[]): Props;
  };
}

export interface DataModelProps<Data = any> {
  version?: number;
  aggridContext?: AggridContext;
  aggridRowNode?: AggridRowNode;
  img?: ImgProps;
  icon?: IconProps;
  label?: string;
  editor?: Function;
  sortKey?: string | number;
  sortValue?: any;
  handlebarKey?: Primitive;
  JSONDefaults?: DeepPartial<Data>;
  JSONDefault?: Data;

  status?: Status;
  errors?: RecordOf<DataModelMessage>;
  statuses?: RecordOf<DataModelMessage>;
  dialogs?: RecordOf<DataModelMessage>;
  banners?: RecordOf<DataModelMessage>;

  toJSON?(): any;
}

interface DataModelMessage {
  type: "success" | "fail" | "warning" | "error" | "info" | "stimulus" | "confirmation";
  icon?: IconProps;
  img?: ImgProps;
  text?: string;
  title?: string;
  loading?: boolean;
}

/**
 * Makes all properties optional recursively, except for primitives within arrays and model property keys;
 */
export type DeepPartialExceptForPrimitiveArraysAndDataModelKeys<T> = T extends Primitive[]
  ? T
  : T extends object[]
  ? {
      [K in keyof T]: DeepPartialExceptForPrimitiveArraysAndDataModelKeys<T[K]>;
    }
  : T extends DataModel
  ? {
      [K in keyof Omit<T, DataModelKeys>]?: T[K] extends object
        ? DeepPartialExceptForPrimitiveArraysAndDataModelKeys<T[K]>
        : T[K];
    } & {
      [K in keyof Pick<T, DataModelKeys>]: T[K];
    }
  : {
      [K in keyof T]?: T[K] extends object ? DeepPartialExceptForPrimitiveArraysAndDataModelKeys<T[K]> : T[K];
    };

type DataModelKeys = keyof DataModel;
