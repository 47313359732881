import { Period, PeriodProps } from "../Period";
import { LABELS } from "@/constants";

class Laycan extends Period {}

class LaycanProps extends PeriodProps {}

LaycanProps.prototype.label = LABELS["laycan"];

Laycan.prototype._ = new LaycanProps();

export { Laycan, LaycanProps };

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */
