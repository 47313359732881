import { IVesselSearch } from "../Models/IVessel";
import { IMapper, withNullable } from "./map";
import { IVesselView } from "../Models/IDetails";
import { IVessel, IVesselSearchApi } from "../../api/models/IVessel";

export const mapVesselsSearchResults = (vessel: IVesselSearchApi): IVesselSearch => {
  return {
    arcVesselId: vessel.arcVesselId || 0,
    buildYear: vessel.buildYear || 0,
    dwt: vessel.dwt || 0,
    vesselName: vessel.registrationDataName || "",
    vesselIMO: String(vessel.vesselIMO || ""),
  };
};

const map: IMapper<IVessel, IVesselView> = {
  toApi: (viewModel: IVesselView) => {
    return {
      registrationDataName: viewModel.vesselName,
      vesselImo: Number(viewModel.vesselIMO),
      arcVesselId: viewModel.arcVesselId,
      dwt: viewModel.dwt || 0,
      buildYear: viewModel.buildYear || 0,
      ownerChain: viewModel.ownerChain || "",
      speedAndConsumption: viewModel.speedAndConsumption || "",
      eta: viewModel.eta || "",
      itinerary: viewModel.itinerary || "",
      additionalNotes: viewModel.additionalNotes || "",
      vesselDescription: viewModel.vesselDescription || "",
    };
  },
  toView: (apiModel: IVessel) => {
    return {
      vesselName: apiModel.registrationDataName,
      vesselIMO: String(apiModel.vesselImo),
      arcVesselId: apiModel.arcVesselId,
      dwt: apiModel.dwt,
      buildYear: apiModel.buildYear,
      status: apiModel.status,
      ownerChain: apiModel.ownerChain,
      speedAndConsumption: apiModel.speedAndConsumption,
      eta: apiModel.eta,
      itinerary: apiModel.itinerary,
      additionalNotes: apiModel.additionalNotes,
      vesselDescription: apiModel.vesselDescription,
    };
  },
  emptyViewModel: {
    vesselIMO: "",
    vesselName: "",
    arcVesselId: 0,
    dwt: 0,
    buildYear: 0,
    ownerChain: "",
    speedAndConsumption: "",
    eta: "",
    itinerary: "",
  },
};

export const mapVessel = withNullable(map);
