import { TradeAPI } from "@/apis";
import {
  Negotiation,
  Period,
  Laycan,
  AddressCommission,
  BrokerCommission,
  CargoSize,
  CargoType,
  VesselSize,
  Duration,
  DischargeLocation,
  DeliveryLocation,
  RedeliveryLocation,
  LoadLocation,
  ViaLocation,
  Nominations,
  Liftings,
  COACargoSize,
  OperationalSubs,
  COANotes,
  CommercialSubs,
  OrderNotes,
  TCNotes,
  VoyageNotes,
  ChartererAccount,
  FreightRate,
  Demurrage,
  HireRate,
  CleaningPrice,
  SupplyPrice,
  TradingExclusions,
  BunkerDelivery,
  BunkerRedelivery,
  CargoExclusionsText,
  BallastBonus,
  CLDDUBroker,
  CLDDUCharterer,
} from "@/models";

function getTitledRowElementArrayForDisplay({ suffix0, suffix1, label0, label1, readOnly0, readOnly1 }, config = defaultConfig) {
  const map = {
    laycan: {
      type: "TitledRow",
      title: Laycan.prototype._.label,
      children: [
        { name: `laycan${suffix0}`, label: label0, readOnly: readOnly0 },
        { name: `laycan${suffix1}`, label: label1, readOnly: readOnly1 },
      ],
    },
    period: {
      type: "TitledRow",
      title: Period.prototype._.label,
      children: [
        { name: `period${suffix0}`, label: label0, readOnly: readOnly0 },
        { name: `period${suffix1}`, label: label1, readOnly: readOnly1 },
      ],
    },
    liftings: {
      type: "TitledRow",
      title: Liftings.prototype._.label,
      children: [
        { name: `liftings${suffix0}`, label: label0, readOnly: readOnly0 },
        { name: `liftings${suffix1}`, label: label1, readOnly: readOnly1 },
      ],
    },
    nominations: {
      type: "TitledRow",
      title: Nominations.prototype._.label,
      children: [
        { name: `nominations${suffix0}`, label: label0, readOnly: readOnly0 },
        { name: `nominations${suffix1}`, label: label1, readOnly: readOnly1 },
      ],
    },
    addressCommission: {
      type: "TitledRow",
      title: AddressCommission.prototype._.label,
      children: [
        { name: `addressCommission${suffix0}`, label: label0, readOnly: readOnly0 },
        { name: `addressCommission${suffix1}`, label: label1, readOnly: readOnly1 },
      ],
    },
    brokerCommission: {
      type: "TitledRow",
      title: BrokerCommission.prototype._.label,
      children: [
        { name: `brokerCommission${suffix0}`, label: label0, readOnly: readOnly0 },
        { name: `brokerCommission${suffix1}`, label: label1, readOnly: readOnly1 },
      ],
    },
    cargoType: {
      type: "TitledRow",
      title: CargoType.prototype._.label,
      children: [
        { name: `cargoType${suffix0}`, label: label0, readOnly: readOnly0 },
        { name: `cargoType${suffix1}`, label: label1, readOnly: readOnly1 },
      ],
    },
    cargoSize: {
      type: "TitledRow",
      title: CargoSize.prototype._.label,
      children: [
        { name: `cargoSize${suffix0}`, label: label0, readOnly: readOnly0 },
        { name: `cargoSize${suffix1}`, label: label1, readOnly: readOnly1 },
      ],
    },
    coaCargoSize: {
      type: "TitledRow",
      title: COACargoSize.prototype._.label,
      children: [
        { name: `coaCargoSize${suffix0}`, label: label0, readOnly: readOnly0 },
        { name: `coaCargoSize${suffix1}`, label: label1, readOnly: readOnly1 },
      ],
    },
    dischargeLocation: {
      type: "TitledRow",
      title: DischargeLocation.prototype._.label,
      children: [
        { name: `dischargeLocation${suffix0}`, label: label0, readOnly: readOnly0 },
        { name: `dischargeLocation${suffix1}`, label: label1, readOnly: readOnly1 },
      ],
    },
    loadLocation: {
      type: "TitledRow",
      title: LoadLocation.prototype._.label,
      children: [
        { name: `loadLocation${suffix0}`, label: label0, readOnly: readOnly0 },
        { name: `loadLocation${suffix1}`, label: label1, readOnly: readOnly1 },
      ],
    },
    deliveryLocation: {
      type: "TitledRow",
      title: DeliveryLocation.prototype._.label,
      children: [
        { name: `deliveryLocation${suffix0}`, label: label0, readOnly: readOnly0, hideSafes: true },
        { name: `deliveryLocation${suffix1}`, label: label1, readOnly: readOnly1, hideSafes: true },
      ],
    },
    duration: {
      type: "TitledRow",
      title: Duration.prototype._.label,
      children: [
        { name: `duration${suffix0}`, label: label0, readOnly: readOnly0 },
        { name: `duration${suffix1}`, label: label1, readOnly: readOnly1 },
      ],
    },
    redeliveryLocation: {
      type: "TitledRow",
      title: RedeliveryLocation.prototype._.label,
      children: [
        { name: `redeliveryLocation${suffix0}`, label: label0, readOnly: readOnly0, hideSafes: true },
        { name: `redeliveryLocation${suffix1}`, label: label1, readOnly: readOnly1, hideSafes: true },
      ],
    },
    vesselSize: {
      type: "TitledRow",
      title: VesselSize.prototype._.label,
      children: [
        { name: `vesselSize${suffix0}`, label: label0, readOnly: readOnly0 },
        { name: `vesselSize${suffix1}`, label: label1, readOnly: readOnly1 },
      ],
    },
    viaLocation: {
      type: "TitledRow",
      title: ViaLocation.prototype._.label,
      children: [
        { name: `viaLocation${suffix0}`, label: label0, readOnly: readOnly0, hideSafes: true },
        { name: `viaLocation${suffix1}`, label: label1, readOnly: readOnly1, hideSafes: true },
      ],
    },
    freightRate: {
      type: "TitledRow",
      title: FreightRate.prototype._.label,
      children: [
        { name: `freightRate${suffix0}`, label: label0, readOnly: readOnly0 },
        { name: `freightRate${suffix1}`, label: label1, readOnly: readOnly1 },
      ],
    },
    demurrage: {
      type: "TitledRow",
      title: Demurrage.prototype._.label,
      children: [
        { name: `demurrage${suffix0}`, label: label0, readOnly: readOnly0 },
        { name: `demurrage${suffix1}`, label: label1, readOnly: readOnly1 },
      ],
    },
    hireRate: {
      type: "TitledRow",
      title: HireRate.prototype._.label,
      children: [
        { name: `hireRate${suffix0}`, label: label0, readOnly: readOnly0 },
        { name: `hireRate${suffix1}`, label: label1, readOnly: readOnly1 },
      ],
    },
    cleaningPrice: {
      type: "TitledRow",
      title: CleaningPrice.prototype._.label,
      children: [
        { name: `cleaningPrice${suffix0}`, label: label0, readOnly: readOnly0 },
        { name: `cleaningPrice${suffix1}`, label: label1, readOnly: readOnly1 },
      ],
    },
    supplyPrice: {
      type: "TitledRow",
      title: SupplyPrice.prototype._.label,
      children: [
        { name: `supplyPrice${suffix0}`, label: label0, readOnly: readOnly0 },
        { name: `supplyPrice${suffix1}`, label: label1, readOnly: readOnly1 },
      ],
    },
    tradingExclusions: {
      type: "TitledRow",
      title: TradingExclusions.prototype._.label,
      children: [
        { name: `tradingExclusions${suffix0}`, label: label0, readOnly: readOnly0 },
        { name: `tradingExclusions${suffix1}`, label: label1, readOnly: readOnly1 },
      ],
    },
    bunkerDelivery: {
      type: "TitledRow",
      title: BunkerDelivery.prototype._.label,
      children: [
        { name: `bunkerDelivery${suffix0}`, label: label0, readOnly: readOnly0 },
        { name: `bunkerDelivery${suffix1}`, label: label1, readOnly: readOnly1 },
      ],
    },
    bunkerRedelivery: {
      type: "TitledRow",
      title: BunkerRedelivery.prototype._.label,
      children: [
        { name: `bunkerRedelivery${suffix0}`, label: label0, readOnly: readOnly0 },
        { name: `bunkerRedelivery${suffix1}`, label: label1, readOnly: readOnly1 },
      ],
    },
    cargoExclusionsText: {
      type: "TitledRow",
      title: CargoExclusionsText.prototype._.label,
      children: [
        { name: `cargoExclusionsText${suffix0}`, label: label0, readOnly: readOnly0 },
        { name: `cargoExclusionsText${suffix1}`, label: label1, readOnly: readOnly1 },
      ],
    },
    ballastBonus: {
      type: "TitledRow",
      title: BallastBonus.prototype._.label,
      children: [
        { name: `ballastBonus${suffix0}`, label: label0, readOnly: readOnly0 },
        { name: `ballastBonus${suffix1}`, label: label1, readOnly: readOnly1 },
      ],
    },
    operationalSubs: {
      type: "TitledRow",
      title: OperationalSubs.prototype._.label,
      children: [
        { name: `operationalSubs${suffix0}`, label: label0, readOnly: readOnly0 },
        { name: `operationalSubs${suffix1}`, label: label1, readOnly: readOnly1 },
      ],
    },
    commercialSubs: {
      type: "TitledRow",
      title: CommercialSubs.prototype._.label,
      children: [
        { name: `commercialSubs${suffix0}`, label: label0, readOnly: readOnly0 },
        { name: `commercialSubs${suffix1}`, label: label1, readOnly: readOnly1 },
      ],
    },
  };

  return getAbstractDetailArrayForDisplay(map, config);
}

function getReadOnlyElementArrayForDisplay(suffix: string, config = defaultConfig) {
  const map = {
    laycan: { name: `laycan${suffix}`, readOnly: true },
    period: { name: `period${suffix}`, readOnly: true },
    liftings: { name: `liftings${suffix}`, readOnly: true },
    nominations: { name: `nominations${suffix}`, readOnly: true },
    addressCommission: { name: `addressCommission${suffix}`, readOnly: true },
    brokerCommission: { name: `brokerCommission${suffix}`, readOnly: true },
    cargoType: { name: `cargoType${suffix}`, readOnly: true },
    cargoSize: { name: `cargoSize${suffix}`, readOnly: true },
    coaCargoSize: { name: `coaCargoSize${suffix}`, readOnly: true },
    dischargeLocation: { name: `dischargeLocation${suffix}`, readOnly: true },
    loadLocation: { name: `loadLocation${suffix}`, readOnly: true },
    deliveryLocation: { name: `deliveryLocation${suffix}`, readOnly: true },
    duration: { name: `duration${suffix}`, readOnly: true },
    redeliveryLocation: { name: `redeliveryLocation${suffix}`, readOnly: true },
    vesselSize: { name: `vesselSize${suffix}`, readOnly: true },
    viaLocation: { name: `viaLocation${suffix}`, readOnly: true },
    ballastBonus: { name: `ballastBonus${suffix}`, readOnly: true },
    demurrage: { name: `demurrage${suffix}`, readOnly: true },
    freightRate: { name: `freightRate${suffix}`, readOnly: true },
    hireRate: { name: `hireRate${suffix}`, readOnly: true },
    cleaningPrice: { name: `cleaningPrice${suffix}`, readOnly: true },
    supplyPrice: { name: `supplyPrice${suffix}`, readOnly: true },
    tradingExclusions: { name: `tradingExclusions${suffix}`, readOnly: true },
    bunkerDelivery: { name: `bunkerDelivery${suffix}`, readOnly: true },
    bunkerRedelivery: { name: `bunkerRedelivery${suffix}`, readOnly: true },
    cargoExclusionsText: { name: `cargoExclusionsText${suffix}`, readOnly: true },
    operationalSubs: { name: `operationalSubs${suffix}`, readOnly: true },
    commercialSubs: { name: `commercialSubs${suffix}`, readOnly: true },
  };

  return getAbstractDetailArrayForDisplay(map, config);
}

function getNegDetailArrayForDisplay<T extends GetDetailArrayForDisplay.PartialNegDetailMap>(
  partialNegDetailMap: T,
  config = defaultConfig
) {
  const map = {
    chartererAccount: partialNegDetailMap.chartererAccount,
    orderNotes: partialNegDetailMap.orderNotes,
    cargoType: partialNegDetailMap.cargoType?.value,
    cargoSize: partialNegDetailMap.cargoSize?.value,
    loadLocation: partialNegDetailMap.loadLocation?.value,
    dischargeLocation: partialNegDetailMap.dischargeLocation?.value,
    laycan: partialNegDetailMap.laycan?.value,
    period: partialNegDetailMap.period?.value,
    addressCommission: partialNegDetailMap.addressCommission?.value,
    brokerCommission: partialNegDetailMap.brokerCommission?.value,
    freightRate: partialNegDetailMap.freightRate?.value,
    demurrage: partialNegDetailMap.demurrage?.value,
    vesselSize: partialNegDetailMap.vesselSize?.value,
    duration: partialNegDetailMap.duration?.value,
    deliveryLocation: partialNegDetailMap.deliveryLocation?.value,
    viaLocation: partialNegDetailMap.viaLocation?.value,
    redeliveryLocation: partialNegDetailMap.redeliveryLocation?.value,
    operationalSubs: partialNegDetailMap.operationalSubs?.value,
    commercialSubs: partialNegDetailMap.commercialSubs?.value,
    hireRate: partialNegDetailMap.hireRate?.value,
    cleaningPrice: partialNegDetailMap.cleaningPrice?.value,
    supplyPrice: partialNegDetailMap.supplyPrice?.value,
    tradingExclusions: partialNegDetailMap.tradingExclusions?.value,
    bunkerDelivery: partialNegDetailMap.bunkerDelivery?.value,
    bunkerRedelivery: partialNegDetailMap.bunkerRedelivery?.value,
    cargoExclusionsText: partialNegDetailMap.cargoExclusionsText?.value,
    ballastBonus: partialNegDetailMap.ballastBonus?.value,
    nominations: partialNegDetailMap.nominations?.value,
    liftings: partialNegDetailMap.liftings?.value,
    coaCargoSize: partialNegDetailMap.coaCargoSize?.value,
  };

  return getDetailArrayForDisplay(map, config);
}

function getDetailArrayForDisplay<T extends GetDetailArrayForDisplay.PartialDetailMap>(
  partialDetailMap: T,
  config = defaultConfig
) {
  const {
    chartererAccount,
    cargoType,
    cargoSize,
    loadLocation,
    dischargeLocation,
    laycan,
    period,
    addressCommission,
    brokerCommission,
    orderNotes,
    voyageNotes,
    tctNotes,
    coaNotes,
    freightRate,
    demurrage,
    vesselSize,
    duration,
    deliveryLocation,
    viaLocation,
    redeliveryLocation,
    operationalSubs,
    commercialSubs,
    hireRate,
    cleaningPrice,
    supplyPrice,
    tradingExclusions,
    bunkerDelivery,
    bunkerRedelivery,
    cargoExclusionsText,
    ballastBonus,
    nominations,
    liftings,
    coaCargoSize,
    brokerContact,
    chartererContact,
  } = partialDetailMap;

  const map = {
    chartererAccount: chartererAccount ? new ChartererAccount(chartererAccount) : undefined,
    orderNotes: orderNotes ? new OrderNotes(orderNotes) : undefined,
    tradingExclusions: tradingExclusions ? new TradingExclusions(tradingExclusions) : undefined,
    bunkerDelivery: bunkerDelivery ? new BunkerDelivery(bunkerDelivery) : undefined,
    bunkerRedelivery: bunkerRedelivery ? new BunkerRedelivery(bunkerRedelivery) : undefined,
    cargoExclusionsText: cargoExclusionsText ? new CargoExclusionsText(cargoExclusionsText) : undefined,
    voyageNotes: voyageNotes ? new VoyageNotes(voyageNotes) : undefined,
    tctNotes: tctNotes ? new TCNotes(tctNotes) : undefined,
    coaNotes: coaNotes ? new COANotes(coaNotes) : undefined,
    brokerContact: brokerContact ? new CLDDUBroker(brokerContact) : undefined,
    chartererContact: chartererContact ? new CLDDUCharterer(chartererContact) : undefined,
    cargoType: cargoType ? new CargoType(cargoType) : undefined,
    cargoSize: cargoSize ? new CargoSize(cargoSize) : undefined,
    loadLocation: loadLocation ? new LoadLocation(loadLocation) : undefined,
    dischargeLocation: dischargeLocation ? new DischargeLocation(dischargeLocation) : undefined,
    laycan: laycan ? new Laycan(laycan) : undefined,
    addressCommission: addressCommission ? new AddressCommission(addressCommission) : undefined,
    brokerCommission: brokerCommission ? new BrokerCommission(brokerCommission) : undefined,
    operationalSubs: operationalSubs ? new OperationalSubs(operationalSubs) : undefined,
    commercialSubs: commercialSubs ? new CommercialSubs(commercialSubs) : undefined,
    freightRate: freightRate ? new FreightRate(freightRate) : undefined,
    demurrage: demurrage ? new Demurrage(demurrage) : undefined,
    vesselSize: vesselSize ? new VesselSize(vesselSize) : undefined,
    duration: duration ? new Duration(duration) : undefined,
    deliveryLocation: deliveryLocation ? new DeliveryLocation(deliveryLocation) : undefined,
    viaLocation: viaLocation ? new ViaLocation(viaLocation) : undefined,
    redeliveryLocation: redeliveryLocation ? new RedeliveryLocation(redeliveryLocation) : undefined,
    hireRate: hireRate ? new HireRate(hireRate) : undefined,
    cleaningPrice: cleaningPrice ? new CleaningPrice(cleaningPrice) : undefined,
    supplyPrice: supplyPrice ? new SupplyPrice(supplyPrice) : undefined,
    ballastBonus: ballastBonus ? new BallastBonus(ballastBonus) : undefined,
    period: period ? new Period(period) : undefined,
    nominations: nominations ? new Nominations(nominations) : undefined,
    liftings: liftings ? new Liftings(liftings) : undefined,
    coaCargoSize: coaCargoSize ? new COACargoSize(coaCargoSize) : undefined,
  };

  const array = Object.values(map);

  return getAbstractDetailArrayForDisplay(map, config) as typeof array;
}

function getAbstractDetailArrayForDisplay<T extends GetDetailArrayForDisplay.PartialAbstractDetailMap>(
  partialAbstractDetailMap: T,
  config = defaultConfig
) {
  const { detailSettings, negotiableFlag, type } = config;
  const { chartererAccount, orderNotes, voyageNotes, tctNotes, coaNotes, notes, brokerContact, chartererContact } =
    partialAbstractDetailMap;

  const cargoType = isIncluded("cargoType") ? partialAbstractDetailMap.cargoType : undefined;
  const cargoSize = isIncluded("cargoSize") ? partialAbstractDetailMap.cargoSize : undefined;
  const loadLocation = isIncluded("loadLocation") ? partialAbstractDetailMap.loadLocation : undefined;
  const dischargeLocation = isIncluded("dischargeLocation") ? partialAbstractDetailMap.dischargeLocation : undefined;
  const laycan = isIncluded("laycan") ? partialAbstractDetailMap.laycan : undefined;
  const addressCommission = isIncluded("addressCommission") ? partialAbstractDetailMap.addressCommission : undefined;
  const brokerCommission = isIncluded("brokerCommission") ? partialAbstractDetailMap.brokerCommission : undefined;
  const operationalSubs = isIncluded("operationalSubs") ? partialAbstractDetailMap.operationalSubs : undefined;
  const commercialSubs = isIncluded("commercialSubs") ? partialAbstractDetailMap.commercialSubs : undefined;
  const freightRate = isIncluded("freightRate") ? partialAbstractDetailMap.freightRate : undefined;
  const demurrage = isIncluded("demurrage") ? partialAbstractDetailMap.demurrage : undefined;
  const vesselSize = isIncluded("vesselSize") ? partialAbstractDetailMap.vesselSize : undefined;
  const duration = isIncluded("duration") ? partialAbstractDetailMap.duration : undefined;
  const deliveryLocation = isIncluded("deliveryLocation") ? partialAbstractDetailMap.deliveryLocation : undefined;
  const viaLocation = isIncluded("viaLocation") ? partialAbstractDetailMap.viaLocation : undefined;
  const redeliveryLocation = isIncluded("redeliveryLocation") ? partialAbstractDetailMap.redeliveryLocation : undefined;
  const hireRate = isIncluded("hireRate") ? partialAbstractDetailMap.hireRate : undefined;
  const cleaningPrice = isIncluded("cleaningPrice") ? partialAbstractDetailMap.cleaningPrice : undefined;
  const supplyPrice = isIncluded("supplyPrice") ? partialAbstractDetailMap.supplyPrice : undefined;
  const tradingExclusions = isIncluded("tradingExclusions") ? partialAbstractDetailMap.tradingExclusions : undefined;
  const bunkerDelivery = isIncluded("bunkerDelivery") ? partialAbstractDetailMap.bunkerDelivery : undefined;
  const bunkerRedelivery = isIncluded("bunkerRedelivery") ? partialAbstractDetailMap.bunkerRedelivery : undefined;
  const cargoExclusionsText = isIncluded("cargoExclusionsText") ? partialAbstractDetailMap.cargoExclusionsText : undefined;
  const ballastBonus = isIncluded("ballastBonus") ? partialAbstractDetailMap.ballastBonus : undefined;
  const period = isIncluded("period") ? partialAbstractDetailMap.period : undefined;
  const nominations = isIncluded("nominations") ? partialAbstractDetailMap.nominations : undefined;
  const liftings = isIncluded("liftings") ? partialAbstractDetailMap.liftings : undefined;
  const coaCargoSize = isIncluded("coaCargoSize") ? partialAbstractDetailMap.coaCargoSize : undefined;

  if (type === "Voy") {
    return [
      chartererAccount,
      cargoType,
      cargoSize,
      loadLocation,
      dischargeLocation,
      laycan,
      addressCommission,
      brokerCommission,
      freightRate,
      demurrage,
      operationalSubs,
      commercialSubs,
      orderNotes,
      voyageNotes,
      notes,
    ];
  }

  if (type === "Tct") {
    return [
      chartererAccount,
      vesselSize,
      duration,
      cargoType,
      deliveryLocation,
      viaLocation,
      redeliveryLocation,
      laycan,
      addressCommission,
      brokerCommission,
      hireRate,
      cleaningPrice,
      supplyPrice,
      tradingExclusions,
      bunkerDelivery,
      bunkerRedelivery,
      cargoExclusionsText,
      ballastBonus,
      operationalSubs,
      commercialSubs,
      orderNotes,
      tctNotes,
      notes,
    ];
  }

  if (type === "Coa") {
    return [
      chartererAccount,
      chartererContact,
      period,
      nominations,
      liftings,
      cargoType,
      coaCargoSize,
      loadLocation,
      dischargeLocation,
      addressCommission,
      brokerCommission,
      brokerContact,
      freightRate,
      demurrage,
      operationalSubs,
      commercialSubs,
      orderNotes,
      coaNotes,
      notes,
    ];
  }

  return [
    chartererAccount,
    chartererContact,
    laycan,
    period,
    liftings,
    nominations,
    addressCommission,
    brokerCommission,
    brokerContact,
    freightRate,
    demurrage,
    hireRate,
    cleaningPrice,
    supplyPrice,
    tradingExclusions,
    bunkerDelivery,
    bunkerRedelivery,
    cargoExclusionsText,
    ballastBonus,
    cargoType,
    cargoSize,
    coaCargoSize,
    loadLocation,
    dischargeLocation,
    vesselSize,
    duration,
    deliveryLocation,
    viaLocation,
    redeliveryLocation,
    operationalSubs,
    commercialSubs,
    voyageNotes,
    tctNotes,
    coaNotes,
    orderNotes,
    notes,
  ];

  /* UTILS */

  function isIncluded(name: keyof GetDetailArrayForDisplay.DetailMap) {
    if (!detailSettings) return true;

    if (typeof negotiableFlag !== "boolean") return true;

    return detailSettings[name]?.negotiable === negotiableFlag;
  }
}

const defaultConfig = {} as GetDetailArrayForDisplay.Config;

export {
  getTitledRowElementArrayForDisplay,
  getReadOnlyElementArrayForDisplay,
  getNegDetailArrayForDisplay,
  getDetailArrayForDisplay,
  getAbstractDetailArrayForDisplay,
};

/* TYPES */

declare namespace GetDetailArrayForDisplay {
  export interface Config {
    type: Negotiation["type"];
    detailSettings?: Negotiation["detailSettings"];
    negotiableFlag?: boolean;
  }

  export type PartialDetailMap = Partial<DetailMap>;
  export type PartialNegDetailMap = Partial<NegDetailMap>;
  export type PartialAbstractDetailMap = Partial<AbstractDetailMap>;

  export interface NegDetailMap extends The<TradeAPI["BidOffer"]> {
    chartererAccount: DetailMap["chartererAccount"];
    orderNotes: DetailMap["orderNotes"];
  }

  export interface DetailMap {
    brokerContact: TradeAPI["CLDDU"];
    chartererContact: TradeAPI["CLDDU"];
    chartererAccount: TradeAPI["Account"];
    laycan: TradeAPI["Period"];
    period: TradeAPI["Period"];
    liftings: TradeAPI["Liftings"];
    nominations: TradeAPI["Nominations"];
    freightRate: TradeAPI["FreightRate"];
    demurrage: TradeAPI["Demurrage"];
    hireRate: TradeAPI["HireRate"];
    cleaningPrice: TradeAPI["CleaningPrice"];
    supplyPrice: TradeAPI["SupplyPrice"];
    tradingExclusions: TradeAPI["Subs"];
    bunkerDelivery: TradeAPI["BunkerDelivery"];
    bunkerRedelivery: TradeAPI["BunkerRedelivery"];
    cargoExclusionsText: TradeAPI["Subs"];
    ballastBonus: TradeAPI["BallastBonus"];
    addressCommission: TradeAPI["Commission"];
    brokerCommission: TradeAPI["Commission"];
    cargoType: TradeAPI["CargoType"];
    cargoSize: TradeAPI["CargoSize"];
    coaCargoSize: TradeAPI["COACargoSize"];
    loadLocation: TradeAPI["Location"];
    dischargeLocation: TradeAPI["Location"];
    deliveryLocation: TradeAPI["Location"];
    duration: TradeAPI["Duration"];
    redeliveryLocation: TradeAPI["Location"];
    vesselSize: TradeAPI["VesselSize"];
    viaLocation: TradeAPI["Location"];
    operationalSubs: TradeAPI["Subs"];
    commercialSubs: TradeAPI["Subs"];
    voyageNotes: TradeAPI["OrderNotes"];
    coaNotes: TradeAPI["OrderNotes"];
    tctNotes: TradeAPI["OrderNotes"];
    orderNotes: TradeAPI["OrderNotes"];
    notes: TradeAPI["OrderNotes"];
  }

  export interface AbstractDetailMap {
    brokerContact: any;
    chartererContact: any;
    chartererAccount: any;
    laycan: any;
    period: any;
    liftings: any;
    nominations: any;
    freightRate: any;
    demurrage: any;
    hireRate: any;
    cleaningPrice: any;
    supplyPrice: any;
    tradingExclusions: any;
    bunkerDelivery: any;
    bunkerRedelivery: any;
    cargoExclusionsText: any;
    ballastBonus: any;
    addressCommission: any;
    brokerCommission: any;
    cargoType: any;
    cargoSize: any;
    coaCargoSize: any;
    loadLocation: any;
    dischargeLocation: any;
    deliveryLocation: any;
    duration: any;
    redeliveryLocation: any;
    vesselSize: any;
    viaLocation: any;
    operationalSubs: any;
    commercialSubs: any;
    voyageNotes: any;
    coaNotes: any;
    tctNotes: any;
    orderNotes: any;
    notes: any;
  }
}

export type { GetDetailArrayForDisplay };
