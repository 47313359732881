import React from "react";
import styles from "./RespondingFor.module.scss";
import { Role } from "negotiations/components/negotiate/Negotiation/Role";

interface IRespondingForProps {
  defaultValue: Role;
  onChange: (direction: Role) => void;
}

/**
 * "Responding For" radio buttons
 */
export const RespondingFor: React.FC<IRespondingForProps> = (props: IRespondingForProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <label>Edit Response</label>
      </div>
      <div className={styles.respondingForContainer}>
        Responding for
        <input
          type="radio"
          id="respondingForCharterer"
          name="respondingFor"
          value={"charterer"}
          defaultChecked={props.defaultValue === "charterer"}
          onChange={(evt) => evt.currentTarget.value === "charterer" && props.onChange(evt.currentTarget.value)}
        />
        <label htmlFor="respondingForCharterer">Charterer</label>
        <input
          type="radio"
          id="respondingForOwner"
          name="respondingFor"
          value={"owner"}
          defaultChecked={props.defaultValue === "owner"}
          onChange={(evt) => evt.currentTarget.value === "owner" && props.onChange(evt.currentTarget.value)}
        />
        <label htmlFor="respondingForOwner">Owner</label>
      </div>
    </div>
  );
};
