import { useState } from "react";
import { dialog, history } from "___REFACTOR___/services";
import { Icon, Button, Input, DateInput, TimezoneEditor } from "___REFACTOR___/components/common";
import { Spinner } from "___REFACTOR___/components/OrderNegLayout/common";
import "./DealCapture.scss";
import { router } from "@/models/Router/Router";

export function CaptureProceedTo(props: any) {
  const [loading, setLoading] = useState(false);
  const { section, formProps } = props;
  const { getEditorProps, values: body } = formProps;
  const backHref = router.get("owner").compile();

  if (section === "deal-capture") {
    return (
      <deal-capture-submit-dialog>
        <dialog-title>
          Deal Status
          <Icon
            name="close"
            disabled={loading}
            onClick={() => {
              if (!loading) {
                dialog.close({ reject: true });
              }
            }}
          />
        </dialog-title>
        <app-vertical-separator></app-vertical-separator>
        <h2>Clean Fix</h2>
        <deal-capture-submit-dialog-clean-fix>
          <span>Charter Party Date:</span>
          <DateInput {...getEditorProps("statusDetails.charterPartyDate")} />
        </deal-capture-submit-dialog-clean-fix>
        <deal-capture-submit-dialog-clean-fix>
          <span>Time Zone:</span>
          <TimezoneEditor {...getEditorProps("statusDetails.timeZone")} unclearable />
        </deal-capture-submit-dialog-clean-fix>
        <deal-capture-submit-dialog-audit-notes>
          Audit Notes
          <Input
            type="Textarea"
            {...getEditorProps("statusDetails.auditNotes")}
            className="deal-capture-submit-dialog-time-input-radio"
          />
        </deal-capture-submit-dialog-audit-notes>
        <i>
          Proceeding will set the captured deal as Fixed. All main terms and CP details will be considered agreed by both parties,
          and all subjects lifted.
        </i>
        <deal-capture-submit-dialog-actions style={{ position: "relative" }}>
          <Spinner overlay hidden={!loading} />
          <Button
            disabled={loading}
            type="submit"
            variant="action"
            label="Proceed"
            onClick={async () => {
              setLoading(true);
              await body.onSubmit();
              setLoading(false);
              await dialog.close();
              history.push(backHref, { ignoreUnsavedChanges: true });
            }}
          />
          <Button
            label="Cancel"
            disabled={loading}
            onClick={() => {
              dialog.close({ reject: true });
            }}
          />
        </deal-capture-submit-dialog-actions>
      </deal-capture-submit-dialog>
    );
  }

  return null;

  /*
  
    IMPORTANT NOTE: Don't remove the below code just yet until you have viewed the issue
    https://dev.azure.com/clarksonscloud/CTrade/_workitems/edit/130610

    It details that it was supposed to bring the two versions of the deal / order creation submission dialog together into one
    so we can lessen the code confusion between OLD and NEW code versions. 
    This work isn't quite there but the below is in preparation for this last piece to happen.

    Thank you
    Smooth
    
  */

  // return (
  // <deal-capture-submit-dialog className="main-terms-procced-to">
  //   <dialog-title>
  //     Deal Status
  //     <Icon
  //       name="close"
  //       onClick={() => {
  //         dialog.close();
  //       }}
  //     />
  //   </dialog-title>
  //   <app-vertical-separator></app-vertical-separator>
  //   What is the current status of the deal you are capturing?
  //   <sf.InputHeading
  //     {...getEditorProps("statusDetails.selectedStatus")}
  //     type="Radio"
  //     value={statusMap.MainTerms}
  //     onChange={toggleStatus.bind(null, "MainTerms")}
  //   >
  //     Negotiating Main Terms
  //   </sf.InputHeading>
  //   <RadioInput
  //     {...getEditorProps("statusDetails.type")}
  //     checkedValue="dateOffset"
  //     className="deal-capture-submit-dialog-time-input-radio"
  //     hidden={!body.statusMap.MainTerms}
  //   >
  //     <deal-capture-submit-dialog-time-radio-label>
  //       Owner will have
  //       <DateOffsetInput
  //         {...getEditorProps("statusDetails.dateOffset")}
  //         // value={body.statusDetails.dateOffset}
  //         // onChange={async value => {
  //         //   setFieldValue("statusDetails.dateOffset", value);
  //         // }}
  //       />
  //       to respond
  //     </deal-capture-submit-dialog-time-radio-label>
  //   </RadioInput>
  //   <RadioInput
  //     {...getEditorProps("statusDetails.type")}
  //     checkedValue="date"
  //     className="deal-capture-submit-dialog-time-input-radio"
  //     hidden={!body.statusMap.MainTerms}
  //   >
  //     <deal-capture-submit-dialog-time-radio-label>
  //       <span>Owner to accept the bid by</span>
  //       <DateInput {...getEditorProps("statusDetails.date")} time />
  //     </deal-capture-submit-dialog-time-radio-label>
  //   </RadioInput>
  //   <deal-capture-submit-dialog-audit-notes hidden={!body.statusMap.MainTerms}>
  //     Audit Notes
  //     <Input type="Textarea" {...getEditorProps("statusDetails.auditNotes")} className="deal-capture-submit-dialog-time-input-radio" />
  //   </deal-capture-submit-dialog-audit-notes>
  //   <i>Proceeding with this option will set the captured deal to 'Main Terms'.</i>
  //   <sf.InputHeading
  //     {...getEditorProps("statusDetails.selectedStatus")}
  //     type="Radio"
  //     value={statusMap.OnSubs}
  //     onChange={toggleStatus.bind(null, "OnSubs")}
  //   >
  //     On Subs
  //   </sf.InputHeading>
  //   <RadioInput
  //     {...getEditorProps("statusDetails.type")}
  //     checkedValue="dateOffset"
  //     className="deal-capture-submit-dialog-time-input-radio"
  //     hidden={!body.statusMap.OnSubs}
  //   >
  //     <deal-capture-submit-dialog-time-radio-label>
  //       Owner will have
  //       <DateOffsetInput {...getEditorProps("statusDetails.dateOffset")} />
  //       to respond
  //     </deal-capture-submit-dialog-time-radio-label>
  //   </RadioInput>
  //   <RadioInput
  //     {...getEditorProps("statusDetails.type")}
  //     checkedValue="date"
  //     className="deal-capture-submit-dialog-time-input-radio"
  //     hidden={!body.statusMap.OnSubs}
  //   >
  //     <deal-capture-submit-dialog-time-radio-label>
  //       <span>Owner to accept the bid by</span>
  //       <DateInput {...getEditorProps("statusDetails.date")} time />
  //     </deal-capture-submit-dialog-time-radio-label>
  //   </RadioInput>
  //   <deal-capture-submit-dialog-audit-notes hidden={!body.statusMap.OnSubs}>
  //     Audit Notes
  //     <Input type="Textarea" {...getEditorProps("statusDetails.auditNotes")} className="deal-capture-submit-dialog-time-input-radio" />
  //   </deal-capture-submit-dialog-audit-notes>
  //   <i>Proceeding with this option will set the captured deal to 'On Subs'. All main terms will be considered agreed by both parties.</i>
  //   <sf.InputHeading
  //     {...getEditorProps("statusDetails.selectedStatus")}
  //     type="Radio"
  //     value={statusMap.Fixed}
  //     onChange={toggleStatus.bind(null, "Fixed")}
  //   >
  //     Clean Fixed
  //   </sf.InputHeading>
  //   <deal-capture-submit-dialog-clean-fix hidden={!body.statusMap.Fixed}>
  //     <span>Charter Party Date:</span>
  //     <DateInput {...getEditorProps("statusDetails.charterPartyDate")} />
  //   </deal-capture-submit-dialog-clean-fix>
  //   <deal-capture-submit-dialog-clean-fix hidden={!body.statusMap.Fixed}>
  //     <span>Time Zone:</span>
  //     <TimezoneEditor {...getEditorProps("statusDetails.timeZone")} unclearable />
  //   </deal-capture-submit-dialog-clean-fix>
  //   <deal-capture-submit-dialog-audit-notes hidden={!body.statusMap.Fixed}>
  //     Audit Notes
  //     <Input type="Textarea" {...getEditorProps("statusDetails.auditNotes")} className="deal-capture-submit-dialog-time-input-radio" />
  //   </deal-capture-submit-dialog-audit-notes>
  //   <i>
  //     Proceeding with this option will set the captured deal as Fixed. All main terms and CP details will be considered agreed by both parties, and
  //     all subjects lifted.
  //   </i>
  //   <deal-capture-submit-dialog-actions style={{ position: "relative" }}>
  //     <Spinner overlay hidden={!loading} />
  //     <Button
  //       disabled={loading}
  //       type="submit"
  //       variant="action"
  //       label="Proceed"
  //       onClick={async () => {
  //         oldDialog.resolve?.({ res: { ...body } });
  //         oldDialog.hide();
  //         history.push(backHref);
  //       }}
  //     />
  //     <Button
  //       label="Cancel"
  //       onClick={() => {
  //         dialog.close();
  //       }}
  //     />
  //   </deal-capture-submit-dialog-actions>
  // </deal-capture-submit-dialog>
  // );
}
