import { ISearchUserView } from "../Models/IDetails";
import { IMapper, withNullable } from "./map";
import { ISearchUser } from "../../api/models/ISearchUser";

const map: IMapper<ISearchUser, ISearchUserView> = {
  toApi: (viewModel: ISearchUserView) => {
    throw new Error("mapSearchUser.toApi not implemented");
  },
  toView: (apiModel: ISearchUser) => {
    return {
      userId: apiModel.userId,
      systemUserId: apiModel.systemUserId,
      email: apiModel.email,
      userName: apiModel.userName,
      companyName: apiModel.companyName,
      companyId: apiModel.companyId,
      companyRoles: apiModel.companyRoles,
      locationName: apiModel.locationName,
      locationId: apiModel.locationId,
      deskName: apiModel.deskName,
      deskId: apiModel.deskId,
      divisionName: apiModel.divisionName,
      divisionId: apiModel.divisionId,
      groupEmailAddress: apiModel.groupEmailAddress,
    };
  },
  emptyViewModel: {
    userId: "",
    systemUserId: "",
    userName: "",
    email: "",
    companyName: "",
    companyId: "",
    companyRoles: [],
    locationName: "",
    locationId: "",
    divisionName: "",
    divisionId: "",
    deskName: "",
    deskId: "",
    groupEmailAddress: null,
  },
};

export const mapSearchUser = withNullable(map);
