import dayjs from "dayjs";
import classNames from "classnames";
import { isTruthy } from "___REFACTOR___/utils";
import { AggridColDef, AggridResolveDefParams, ImgProps } from "___REFACTOR___/components";
import { MultiValueHeaderCell } from "./MultiValueHeaderCell";
import { EMDASH } from "___REFACTOR___/constants";
import { Aggrid } from "___REFACTOR___/components/common";
import { get } from "lodash-es";
import { DataModel } from "___REFACTOR___/models";

function resolveMultiValueColDef(params: resolveMultiValueColDef.Params): AggridColDef {
  const { sequence, cellRendererParams } = params;

  return {
    headerName: joinSequenceHeadings(sequence),
    $reactHeaderComponent: MultiValueHeaderCell,
    headerComponentParams: { sequence },
    cellClass: resolveMultiValueCellClass,
    cellRenderer: standardMultiValueCellRenderer,
    cellRendererParams: { ...cellRendererParams, sequence },
    filterValueGetter: standardMultiValueFilterValueGetter,
    $resolveDef: (params) => ({
      comparator: multiValueComparator.bind(null, params),
    }),
  };
}

function joinSequenceHeadings(sequence: MultiValueSequence) {
  const headings = sequence.flat().map((item) => item.heading);

  return headings.join(" / ");
}

function multiValueComparator(params: AggridResolveDefParams, a, b, aNode, bNode, isInverted) {
  const { columnStateAPI, col } = params;
  const multiValueSortName = `${columnStateAPI.state[`${col.colId}`]?.multiValueSortName}`;
  const _a = get(a, multiValueSortName);
  const _b = get(b, multiValueSortName);

  a = _a?.sortValue || _a?.value || _a?.text || _a;
  b = _b?.sortValue || _b?.value || _b?.text || _b;

  return standardComparator(a, b, aNode, bNode, isInverted);
}

function standardComparator(a, b, aNode, bNode, isInverted) {
  // isInverted === false -- asc
  // isInverted === true -- desc

  if (a && typeof a === "string") a = a.toUpperCase();
  if (b && typeof b === "string") b = b.toUpperCase();

  if (a === undefined || a === null || a === "" || Number.isNaN(a)) a = null;
  if (b === undefined || b === null || b === "" || Number.isNaN(b)) b = null;

  // @ts-ignore
  if (a instanceof dayjs) a = a.unix();
  // @ts-ignore
  if (b instanceof dayjs) b = b.unix();

  if (a === null && b !== null) return isInverted ? -1 : 1;
  if (a !== null && b === null) return isInverted ? 1 : -1;

  if (a === b) return 0;

  return a < b ? -1 : 1;
}

function standardMultiValueFilterValueGetter(params, sequence?: MultiValueSequence) {
  const _sequence = sequence || (params.colDef.cellRendererParams.sequence as MultiValueSequence);
  const filterRenderer = params.colDef?.filterParams?.filterRenderer;
  const valueMap = params.colDef.valueGetter(params) as RecordOf<MultiValueValue>;
  const joint = _sequence.flat().map(sequenceValueSelector).filter(isTruthy).join(` ${EMDASH} `);

  return joint;

  function sequenceValueSelector(sequenceItem: MultiValueSequenceItem) {
    let value = valueMap[sequenceItem.name];

    if (value && typeof value === "object") {
      if (value.suppressFilterValue) return;

      return value.filterValue || value.text || value.value;
    }
    if (value && filterRenderer) {
      value = filterRenderer(value);
    }
    return value;
  }
}

function standardMultiValueCellRenderer(params) {
  const sequence = params.sequence as MultiValueSequence;
  const valueMap = params.value as RecordOf<MultiValueValue>;
  const sequenceFlat = sequence.flat();

  if (sequenceFlat.length === 1) {
    const value = valueMap[sequenceFlat[0].name];
    const text = value && typeof value === "object" ? value?.text || value?.value : value;

    params.value = text;

    return standardCellRenderer(params);
  }

  function sequenceValueSelector(sequenceItem: MultiValueSequenceItem | MultiValueSequenceItem[]) {
    if (Array.isArray(sequenceItem)) {
      return sequenceItem.map(sequenceValueSelector);
    }

    const value = get(valueMap, sequenceItem.name);

    if (value && typeof value === "object") return { ...value, text: value.text || value.value };

    return value;
  }

  // @ts-ignore
  const rows = sequence.map(sequenceValueSelector);

  return standardMultipleRowRenderer({ rows, params });
}

function standardCellRenderer(params) {
  if (params.node.group) return;

  const value = standardValueDisplayFormatter(params.value);

  params.eGridCell.innerHTML = value;
  params.eGridCell.setAttribute("title", value);
}

function standardMultipleRowRenderer({ rows, params, ...options }) {
  const tooltip = options.tooltip || renderTooltipString(rows.flat());

  rows = rows.map(renderRowOrMergeRowsHTML.bind(null, options, params)).join("");

  params.eGridCell.innerHTML = rows;
  params.eGridCell.setAttribute("title", tooltip);

  return;
}

function renderRowOrMergeRowsHTML(options, params, rowOrRows) {
  const rows = [].concat(rowOrRows);

  return `<app-aggrid-text-row>${rows.map(renderRowContentHTML.bind(null, options, params)).join(" / ")}</app-aggrid-text-row>`;
}

function renderRowContentHTML(options, params, row) {
  if (row instanceof DataModel) row = row.toGridView();

  if (typeof row !== "object") row = { text: row };

  let { icon, img } = { ...options, ...row } as any;
  let { text } = row;

  text = text || "--";
  icon = renderIconHTML(icon || params.icon);
  img = renderImgHTML(img);

  return `${img}${icon}${text}`;
}

export function renderIcon(icon, params) {
  const { eGridCell, colDef, title } = params;

  if (typeof icon !== "object") icon = { name: icon };

  const { name, className, disabled } = icon;

  if (!name) return;

  const eIcon = document.createElement("app-icon");

  eIcon.className = classNames(className, `icon--${name}`, { disabled });
  if (title) eIcon.title = title;

  eGridCell.onclick = colDef.onCellClicked && onClick;

  function onClick(e) {
    colDef.onCellClicked(params, e);
  }

  return eIcon;
}

function renderIconHTML(icon) {
  if (typeof icon !== "object") icon = { name: icon };

  let { name, className } = icon;

  if (!name) return "";

  className = className || "";

  return `<app-icon data-test class="${className} icon--${name}"></app-icon>`;
}

function renderImgHTML(img) {
  if (typeof img !== "object") img = { src: img };

  const { src } = img;

  if (!src) return "";

  return `<img src="${src}">`;
}

function getRowText(row) {
  if (row instanceof DataModel) row = row.toGridView();

  if (typeof row !== "object") row = { text: row };

  return row?.text || "";
}

function renderTooltipString(...rows) {
  rows = rows.flat().map(getRowText).filter(isTruthy);

  return rows.join(`
`);
}

function resolveMultiValueCellClass(params) {
  if (params.colDef.cellRendererParams.sequence.length === 1) return "aggrid-multi-value-cell-single-value";

  return "";
}

function standardValueDisplayFormatter(value) {
  if (value instanceof DataModel) value = value.toGridView();

  return value || "--";
}

export { resolveMultiValueColDef };

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

type MultiValueValue = Primitive | IMultiValueValue;

interface IMultiValueValue {
  value: Primitive;
  text?: Primitive;
  sortValue?: Primitive | Date;
  filterValue?: Primitive;
  suppressFilterValue?: boolean;
  img?: ImgProps["img"];
}

declare namespace resolveMultiValueColDef {
  interface Params<D = any> extends Aggrid.Col.Def<D> {
    sequence: MultiValueSequence;
    cellRendererParams?: AggridColDef["cellRendererParams"];
  }

  type SequenceItem = MultiValueSequenceItem;
}

type MultiValueSequence = (MultiValueSequenceItem | MultiValueSequenceItem[])[];

interface MultiValueSequenceItem {
  name: string;
  heading: string | undefined;
}
