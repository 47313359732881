import { loadTheme } from "office-ui-fabric-react";
import { initializeIcons } from "@uifabric/icons";
import { dark } from "./theme";

function setup() {
  loadTheme(dark);
  initializeIcons();
}

export { setup };
