import { TradeAPI } from "___REFACTOR___/apis";
import { format } from "___REFACTOR___/utils";
import { COACargoSizeEditor } from "___REFACTOR___/components";
import { DataModel, DataModelProps } from "../DataModel";
import { schema } from "./schema";
import { LABEL_MAP } from "___REFACTOR___/constants";

class COACargoSize extends DataModel {
  constructor(data?: PartialData) {
    super(data);
  }

  toJSON() {
    const json = DataModel.prototype.toJSON.call(this) as TradeAPI.COACargoSize;

    return json;
  }

  isValid() {
    return schema.isValidSync(this);
  }

  toString() {
    if (!this) return "";
    if (typeof this.variance !== "number" || typeof this.min !== "number" || typeof this.max !== "number") return "";

    const notes = this.notes || "";

    return `${format.number.display(this.min)}-${format.number.display(this.max)}} / ${this.variance}% - ${this.option} ${notes}`;
  }

  toGridView() {
    if (!this) return "";
    if (typeof this.variance !== "number" || typeof this.min !== "number" || typeof this.max !== "number") return "";

    return `${format.number.display(this.min)}-${format.number.display(this.max)} / ${this.variance}% - ${this.option}`;
  }
}

class COACargoSizeProps extends DataModelProps {
  get Editor() {
    return COACargoSizeEditor;
  }

  get schema() {
    return schema;
  }
}

COACargoSize.prototype.Props = COACargoSizeProps;
COACargoSizeProps.prototype.sortKey = "max";
COACargoSizeProps.prototype.label = LABEL_MAP.coaCargoSize;
COACargoSizeProps.prototype.JSONDefaults = {
  option: "MOLOO",
};

export { COACargoSize, COACargoSizeProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface COACargoSize extends PartialData {
  Props: typeof COACargoSizeProps;
  _: COACargoSizeProps;

  sizeRangeToString(): string;
}

interface COACargoSizeProps {
  JSONDefaults: PartialData;
  sortKey: keyof Data;
}

type PartialData = Partial<Data>;
type Data = TradeAPI.COACargoSize;
