import React, { useEffect, useReducer } from "react";
import styles from "./DashboardForm.module.scss";
import { IOfferRepViewNegotiation } from "negotiations/models/common/IOfferRep";
import { getNegotiationApiService } from "../../../api/negotiations/Negotiations";
import offerRepMapper from "../../../offerRep/api/OfferRepMapper";
import Dashboard from "../../../offerRep/Dashboard";
import { useConfig } from "__legacy/sharedFolder/ConfigurationContext";
import LoadingSpinner from "sharedFolder/components/common/LoadingSpinner/LoadingSpinner";
import { Subscription } from "rxjs";
import { useParams } from "react-router-dom";
import { useOwnerNegotiations } from "../../context/owner-negotiation-context";
import Icon from "__legacy/sharedFolder/components/common/Icon/Icon";

interface IDasboardFormState {
  negotiation?: IOfferRepViewNegotiation;
  pageState: "error" | "noNegotiationSelected" | "loading" | "showNegotiation";
}

interface IFetchReducerAction {
  type: "setIsLoading" | "setNegotiation" | "setError";
  negotiation?: IOfferRepViewNegotiation;
  negotiationId?: string;
}

const fetchReducer = (state: IDasboardFormState, action: IFetchReducerAction): IDasboardFormState => {
  const { type, negotiation, negotiationId } = action;

  switch (type) {
    case "setIsLoading": {
      // Do not display the 'loading' state if there is already
      // a negotiation being displayed to the user
      if (state.negotiation && state.negotiation.id === negotiationId) {
        return state;
      }
      return {
        negotiation: undefined,
        pageState: "loading",
      };
    }
    case "setNegotiation": {
      return {
        negotiation,
        pageState: "showNegotiation",
      };
    }
    case "setError": {
      return {
        negotiation: undefined,
        pageState: "error",
      };
    }
    default:
      return state;
  }
};

const DashboardForm = () => {
  const [state, dispatch] = useReducer(fetchReducer, {
    pageState: "noNegotiationSelected",
  });

  const config = useConfig();
  // @ts-ignore
  const { orderId, negotiationId } = useParams();

  const { lastUpdatedOrder } = useOwnerNegotiations();

  // orderLastUpdatedDateTime - holds the date the Order last received
  // a SignalR update, will be NULL to start
  const orderLastUpdatedDateTime = orderId === lastUpdatedOrder?.orderId ? lastUpdatedOrder?.lastUpdated : null;

  useEffect(() => {
    let subscription: Subscription;
    if (orderId && negotiationId) {
      const getNegotiationService = getNegotiationApiService(config.ctradeUrl, orderId);
      dispatch({ type: "setIsLoading", negotiationId });
      subscription = getNegotiationService.getOwnerNegotiation(orderId, negotiationId).subscribe(
        (negotiationApiModel) => {
          dispatch({
            type: "setNegotiation",
            negotiation: offerRepMapper(negotiationApiModel),
          });
        },
        () => dispatch({ type: "setError" })
      );
    }
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [orderLastUpdatedDateTime, orderId, negotiationId, config.ctradeUrl]);

  return (
    <>
      {state.pageState === "loading" && (
        <div className={styles.centreAlignedContent}>
          <LoadingSpinner loadingText="Loading Negotiation..." />
        </div>
      )}
      {state.pageState === "showNegotiation" && state.negotiation && (
        <Dashboard config={config} negotiation={state.negotiation} />
      )}
      {state.pageState === "noNegotiationSelected" && (
        <>
          <div className={styles.noNegSelected}>
            <Icon icon="info-outline" />{" "}
            <div>
              <h4>No negotiation selected</h4>
              <p>
                Select a negotiation from the left hand side
                <br /> to view and offer terms
              </p>
            </div>
          </div>
        </>
      )}
      {state.pageState === "error" && (
        <>
          <div className={styles.centreAlignedContent}>
            <h3>An error has occcured. Please refresh the page</h3>
          </div>
        </>
      )}
    </>
  );
};
// Test this file and fix up message text

export default DashboardForm;
