import { DistributionUserRole } from "sharedFolder/Models/IDetails";
import { IUserContext } from "__legacy/dashboard/contexts/UserProvider";
import { isEmpty, uniq } from "lodash-es";
import { IRecipient } from "api/orders/models/IDistributionList";

export const roles: DistributionUserRole[] = ["unknown", "broker", "charterer", "owner"];

/**
 *  Role selection for each recipient
 * *****************************************************************
 * 1. Each recipient in the distribution list (the user we are distributing to), has a drop down,
 *    and it should contain only the valid roles for that recipient, those roles are called 'availableRoles'
 * 2. 'availableRoles' are those returned from the Backend in the 'companyRoles' (on API contracts) field.
 *    This 'availableRoles' should exclude 'broker' if the actor (currently logged in user) is a 'broker' from a different company. The preceeding rule also applies to 'charterer'. So if the actor
 *    and the recipient are from the same company we should allow 'broker' as a legitimate role if the backend is suggesting it. If however they are from different
 *    companies, we should now allow the 'broker' role for this recipient
 * 3. The default role should be owner if 'owner' is available in the 'availableRoles' and in other cases select the first role in the array
 * *****************************************************************
 */

/**
 * Will return the available roles for the recipientUser, minus the roles they are not
 * allowed to have. so a 'broker' from one company should not be able to distribute to
 * a 'broker' from a different company. Same for 'charterer'.
 * @param currentlyLoggedInUser
 * @param recipientUser
 */
export const filterAvailableRoles = (recipientUser: IRecipient, currentlyLoggedInUser: IUserContext): DistributionUserRole[] => {
  // If there are no 'availbleRoles' and the user has no 'role' already defined, return availableRoles of 'owner'
  const hasNoRoleOrIsOwner = !recipientUser.role || recipientUser.role === "owner";
  if (isEmpty(recipientUser.availableRoles) && hasNoRoleOrIsOwner) {
    // The default 'owner' role is not needed for known users as they will
    // always have some 'availableRoles' but for an unknown user (vanilla email address) we need to
    // have a default role of 'owner'
    return ["owner"];
  }
  // If the currentlyLoggedInUser and the recipient are NOT from the same company
  if (currentlyLoggedInUser.companyId !== recipientUser.knownUser?.company.id) {
    // And the currently logged in user has a 'broker' role
    if (currentlyLoggedInUser.companyRoles.includes("broker")) {
      // Ensure that the recipient, cannot be set as a 'broker' (because they are not from the same company)
      const availableRolesExceptBroker = recipientUser.availableRoles.filter((role) => role !== "broker");
      return getUniqueSetOfRolesExceptUnknown(availableRolesExceptBroker, recipientUser.role);
    }
    // And the currently logged in user has a 'charterer' role
    if (currentlyLoggedInUser.companyRoles.includes("charterer")) {
      // Ensure that the recipient, cannot be set as a 'charterer' (because they are not from the same company)
      const availableRolesExceptCharterer = recipientUser.availableRoles.filter((role) => role !== "charterer");
      return getUniqueSetOfRolesExceptUnknown(availableRolesExceptCharterer, recipientUser.role);
    }
  }
  return getUniqueSetOfRolesExceptUnknown(recipientUser.availableRoles, recipientUser.role);
};

const getUniqueSetOfRolesExceptUnknown = (
  availableRoles: DistributionUserRole[],
  role: DistributionUserRole
): DistributionUserRole[] => {
  return uniq([...availableRoles, role]).filter((role) => role !== "unknown");
};
