import { format } from "___REFACTOR___/utils";
import { LABEL_MAP } from "___REFACTOR___/constants";
import { TradeAPI } from "___REFACTOR___/apis";
import { DurationEditor } from "___REFACTOR___/components";
import { DataModel, DataModelProps } from "../DataModel";
import { schema } from "./schema";

class Duration extends DataModel {
  static get Editor() {
    return DurationEditor;
  }

  static label = LABEL_MAP.duration;

  constructor(data?: PartialData) {
    super(data);
  }

  toJSON() {
    const json = DataModel.prototype.toJSON.call(this) as TradeAPI.Duration;

    return json;
  }

  isValid() {
    return schema.isValidSync(this);
  }

  toString() {
    if (typeof this.min !== "number" || typeof this.max !== "number") return "";

    return `${format.number.display(this.min)}-${format.number.display(this.max)} ${this.unit}`;
  }

  toGridView() {
    if (typeof this.min !== "number" || typeof this.max !== "number") return "";

    return `${format.number.display(this.min)}-${format.number.display(this.max)} ${this.unit}`;
  }
}

class DurationProps extends DataModelProps {
  get Editor() {
    return DurationEditor;
  }

  get schema() {
    return schema;
  }

  get JSONDefaults() {
    return {
      unit: "Days",
    };
  }
}

Duration.prototype.Props = DurationProps;
DurationProps.prototype.sortKey = "max";
DurationProps.prototype.label = LABEL_MAP.duration;

export { Duration, DurationProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface Duration extends Data {
  Props: typeof DurationProps;
  _: DurationProps;
}

interface DurationProps {
  sortKey: keyof Data;
}

type PartialData = Partial<Data>;
type Data = TradeAPI.Duration;
