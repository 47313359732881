import { TradeAPI } from "@/apis";
import { DataModel, DataModelProps } from "../DataModel";
import { CargoTypeEditor } from "@/components";
import { LABELS } from "@/constants";

class CargoType extends DataModel<Data> {
  onUpdate() {
    this.arcId = Number(this.arcId);

    delete this.display;
    delete this.shortDisplay;
  }

  toString() {
    if (!this) return "";

    if (!this.name) return "";

    return `${this.name} ${this.notes || ""}`;
  }

  toDropdownView() {
    return this.name;
  }

  toGridView() {
    if (!this) return "";

    if (!this.name) return "";

    return this.name;
  }
}

class CargoTypeProps extends DataModelProps<Data> {}

CargoTypeProps.prototype.editor = CargoTypeEditor;
CargoTypeProps.prototype.label = LABELS["cargoType"];

CargoType.prototype._ = new CargoTypeProps();

export { CargoType, CargoTypeProps };

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

interface CargoType extends Data {
  _: CargoTypeProps;
}

type Data = DeepPartial<TradeAPI["CargoType"]>;
