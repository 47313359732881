import React from "react";
import classNames from "classnames";
import { Duration, DurationProps } from "@/models";
import { StatusMessages, NumberField, SelectField, SelectFieldProps, TextareaField } from "@/components";
import { Group } from "../../Group";
import { Row } from "../../Row";
import "./Duration";

export function DurationEditor(props: Props) {
  props = { ...props };

  props.className = classNames(props.className, "Duration");

  const { className, value, status, ...rest } = props;

  const { min, max, unit } = value || {};

  function onChange(name: keyof Duration, inputValue: number | null) {
    const next = new Duration({
      ...value,
      [name]: inputValue,
    });

    props.onChange?.(next);
  }

  function onNotesChange(notes: string) {
    props.onChange?.(new Duration({ ...value, notes }));
  }

  return (
    <Group className={className}>
      <Row>
        <NumberField
          {...rest}
          label="Min"
          desc="Duration min"
          className="Duration-min"
          placeholder="e.g. 3"
          status={status?.children ? status?.children?.min : status}
          value={min}
          onChange={onChange.bind(null, "min")}
          dataTest={`${props.dataTest}.min`}
          suppressStatusMessages
        />
        <NumberField
          {...rest}
          label="Max"
          desc="Duration max"
          placeholder="e.g. 4"
          className="Duration-max"
          status={status?.children ? status?.children?.max : status}
          value={max}
          onChange={onChange.bind(null, "max")}
          dataTest={`${props.dataTest}.max`}
          suppressStatusMessages
        />
        <SelectField
          {...rest}
          label="Unit"
          desc="Duration unit"
          className="Duration-unit"
          data={OPTIONS}
          status={status?.children?.unit}
          value={unit || DurationProps.prototype.JSONDefaults?.unit}
          onChange={onChange.bind(null, "unit")}
          dataTest={`${props.dataTest}.unit`}
          unclearable
          nonFilterSelect
          suppressStatusMessages
        />
      </Row>
      <StatusMessages status={status} />
      <TextareaField
        {...props}
        label="Notes"
        desc="Duration notes"
        value={value?.notes || ""}
        onChange={onNotesChange}
        status={status?.children?.notes}
        dataTest={`${props.dataTest}.notes`}
      />
    </Group>
  );
}

const OPTIONS = ["Days", "Months"];

interface Props extends Omit<SelectFieldProps, "value" | "onChange"> {
  value?: Duration;
  onChange?: (value: Duration) => void;
}
