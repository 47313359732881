import { LABEL_MAP } from "___REFACTOR___/constants";
import { format } from "___REFACTOR___/utils";
import { TradeAPI } from "___REFACTOR___/apis";
import { VesselSizeEditor } from "___REFACTOR___/components";
import { DataModel, DataModelProps } from "../DataModel";
import { schema } from "./schema";
import vesselSizeArr from "./vesselSizeArr.json";

class VesselSize extends DataModel {
  static get Editor() {
    return VesselSizeEditor;
  }

  static label = LABEL_MAP.vesselSize;

  constructor(data?: PartialData) {
    super(data);
  }

  toJSON() {
    const json = DataModel.prototype.toJSON.call(this) as TradeAPI.VesselSize;

    return json;
  }

  isValid() {
    return schema.isValidSync(this);
  }

  toString() {
    return this.vesselSizeAbbreviation;
  }

  toDropdownView() {
    if (!this.vesselSizeFull || !this.vesselSizeAbbreviation) return "";

    return `${this.vesselSizeFull} (${this.vesselSizeAbbreviation})`;
  }

  toGridView() {
    if (!this) return "";

    if (!this.sizeFrom || !this.sizeTo || !this.vesselSizeAbbreviation) return "";

    return `${format.number.display(this.sizeFrom)} / ${format.number.display(this.sizeTo)} / ${this.vesselSizeAbbreviation}`;
  }
}

class VesselSizeProps extends DataModelProps {
  get Editor() {
    return VesselSizeEditor;
  }

  get schema() {
    return schema;
  }
}

VesselSize.prototype.Props = VesselSizeProps;
VesselSizeProps.prototype.label = LABEL_MAP.vesselSize;
VesselSizeProps.prototype.sortKey = "sizeTo";
VesselSizeProps.prototype.vesselSizeArray = (vesselSizeArr as Data[]).map(createVesselSize);

function createVesselSize(data: Data) {
  return new VesselSize(data);
}

export { VesselSize, VesselSizeProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface VesselSize extends Data {
  _: VesselSizeProps;
}

interface VesselSizeProps {
  vesselSizeArray: VesselSize[];
}

type PartialData = Partial<Data>;
type Data = TradeAPI.VesselSize;
