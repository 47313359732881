import React from "react";
import classNames from "classnames";
import * as ClassNames from "classnames";
import { Container } from "../../Field/Container";
import { Field } from "../../Field";
import "./Group.scss";

export function Group(props: Props) {
  const { hidden, editorType, readOnly } = props;
  const className = classNames(props.className, editorType);
  let children = props.children;

  if (readOnly) {
    children = <Container {...props} />;
  }

  if (hidden) return null;

  return <app-field-group class={className}>{children}</app-field-group>;
}

interface Props extends Field.Props {
  hidden?: boolean;
  className?: ClassNames.Argument;
  children: React.ReactNode;
}
