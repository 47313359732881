import React from "react";
import { Modal } from "office-ui-fabric-react";
import { ConfirmationDialog } from "sharedFolder/components/common/ConfirmationDialog/ConfirmationDialog";
import { emptyArrowFn } from "@/utils";

export const LiftingsModalCapture = (props: { onCancel: () => void; onConfirm: () => void; isSaving: boolean }) => {
  return (
    <Modal isOpen={true} onDismiss={emptyArrowFn}>
      <ConfirmationDialog
        title="Capture lifting cargo?"
        message={<span>The lifting cargo will be captured.</span>}
        dataTestId="AcceptFirmOKButton"
        isDisabled={props.isSaving}
        isCancelDisabled={props.isSaving}
        displayLoading={props.isSaving}
        ariaLabel="Capture"
        okButtonText="Capture"
        onSubmit={props.onConfirm}
        onCancel={props.onCancel}
      />
    </Modal>
  );
};
