import { TextareaField } from "@/components";
import { PrimitiveDataModel, PrimitiveDataModelProps } from "../PrimitiveDataModel";

export class TextareaString extends PrimitiveDataModel<Data> {}

export class TextareaStringProps extends PrimitiveDataModelProps<Data> {}

TextareaStringProps.prototype.editor = TextareaField;

TextareaString.prototype._ = new TextareaStringProps();

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

export interface TextareaString {
  data: Data;
}

type Data = string;
