import { ISearchUserView, IDistributionViewModel, DistributionUserRole } from "../Models/IDetails";
import { IMapper, withNullable } from "./map";
import { mapDistribution } from "./mapDistribution";

const map: IMapper<ISearchUserView, IDistributionViewModel> = {
  toApi: (viewModel: IDistributionViewModel) => {
    throw new Error("mapSearchUserToDistributionUser.toApi not implemented");
  },
  toView: (apiModel: ISearchUserView) => {
    return {
      userId: apiModel.userId,
      systemUserId: apiModel.systemUserId?.length > 0 ? Number(apiModel.systemUserId) : undefined,
      name: apiModel.userName,
      email: apiModel.email,
      companyName: apiModel.companyName,
      companyId: apiModel.companyId,
      locationName: apiModel.locationName,
      locationId: apiModel.locationId,
      deskName: apiModel.deskName,
      deskId: apiModel.deskId,
      divisionName: apiModel.divisionName,
      divisionId: apiModel.divisionId,
      status: "pending",
      loading: false,
      role: "unknown",
      negDetails: null,
      availableRoles: (apiModel.companyRoles || []) as DistributionUserRole[],
      disableUserRow: false,
      groupEmailAddress: apiModel.groupEmailAddress,
    };
  },
  emptyViewModel: mapDistribution.emptyViewModel,
};

export const mapSearchUserToDistributionUser = withNullable(map);
