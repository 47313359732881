import { PureComponent } from "react";
import styles from "./Excluded.module.scss";
import Button from "__legacy/sharedFolder/components/common/Button/Button";

interface IExcludedProps<T> {
  items: Array<{ key: keyof T; included?: boolean; label: string }>;
  onInclude: <K extends keyof T>(property: K) => void;
}

export class Excluded<T> extends PureComponent<IExcludedProps<T>> {
  public render() {
    const excludedItems = this.props.items.filter((i) => i.included === false);
    if (excludedItems.length === 0) {
      return null;
    }

    return (
      <>
        <h3 className={styles.header}>Excluded</h3>
        {excludedItems.map((i) => (
          <Button key={i.label} type="flat" onClick={() => this.props.onInclude(i.key)}>
            {i.label}
          </Button>
        ))}
      </>
    );
  }
}
