import React, { createContext, useContext } from "react";

/* Even though ITO is not technically correct as an order type, we are using it to distinguish it with COA as the main ITO screen.
At some point in the future we will have separate VOY and TC screens as well with lots of shared screens and functionality and then we will need to add VOY and TCT
*/
export type IOrderType = "ITO" | "COA";

interface IOrderTypeProviderProps {
  orderType: IOrderType;
  children: React.ReactNode;
}

const OrderTypeContext = createContext<IOrderType>("ITO");

function OrderTypeProvider(props: IOrderTypeProviderProps) {
  const { children, orderType } = props;

  return <OrderTypeContext.Provider value={orderType}>{children}</OrderTypeContext.Provider>;
}

function useOrderType() {
  const context = useContext(OrderTypeContext);
  if (context === undefined) {
    throw new Error("useOrderType must be within a OrderTypeProvider");
  }

  return context;
}

export { OrderTypeProvider, useOrderType };
