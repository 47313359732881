import React, { useState, useContext, useEffect } from "react";
import classNames from "classnames";
import moment, { duration as momentDuration, utc } from "moment";
import { ValidationContext } from "sharedFolder/contexts/ValidationContext";
import { ExpiresOnValue } from "sharedFolder/components/common/types";
import DatePicker from "../DatePicker/DatePicker";
import { DigitalClock } from "../DigitalClock/DigitalClock";
import styles from "./ExpiresOn.module.scss";

type ExpiryDuration = { h: string; m: string };
type ExpiryTimeStamp = { h: string; m: string; d: Date | undefined | null };

interface IExpiresOnProps {
  id?: string;
  defaultDurationHours?: string;
  defaultDurationMinutes?: string;
  durationAsTimestamp?: boolean;

  /**
   * Text to describe the target role.
   * e.g. "Owner" or "Charterer"
   */
  targetRole: string;

  /**
   * Text to describe the action
   * e.g. "firm bid" or "firm offer"
   */
  action: string;

  /**
   * Autofocus on the first input
   */
  autoFocus?: boolean;

  /**
   * When a valid value selected, applies the value either as a timestamp or a duration.
   * Timestamp is ISO8601 (UTC) timestamp e.g. "2019-06-19T16:01:06Z"
   * Duration is ISO8601 timespan of hours, minutes and seconds e.g "H0M30S0"
   */
  onChange: (value: ExpiresOnValue) => void;
}

export const ExpiresOn: React.FC<IExpiresOnProps> = (props: IExpiresOnProps) => {
  const { action, targetRole, onChange, autoFocus, id, defaultDurationHours, defaultDurationMinutes, durationAsTimestamp } =
    props;
  const [option, setOption] = useState<"duration" | "timestamp">("duration");
  const [duration, setDuration] = useState<ExpiryDuration>({
    h: defaultDurationHours || "",
    m: defaultDurationMinutes || "",
  });
  const [timestamp, setTimestamp] = useState<ExpiryTimeStamp>({
    h: "",
    m: "",
    d: undefined,
  });
  const [isExpiryValid, setIsExpiryValid] = useState({
    durationHours: true,
    durationMinutes: true,
    timestampHours: true,
    timestampMinutes: true,
    timestampDate: true,
  });

  const validity = useContext(ValidationContext);

  const optionChange = (type: "duration" | "timestamp") => {
    setOption(type);
    if (type === "duration") {
      setTimestamp({ h: "", m: "", d: undefined });
      onChange({ type: "duration", value: "" });
    } else {
      setDuration({ h: "", m: "" });
      onChange({ type: "timestamp", value: "" });
    }
  };
  // when duration/option change, recalculate validity for duration
  useEffect(() => {
    const validNumber = (value: string, min: number, max: number): boolean => {
      const n = Number(value);
      return value.length === 0 || isNaN(n) || !Number.isInteger(n) ? false : n >= min && n <= max;
    };

    const total = Number(duration.h) + Number(duration.m);

    const durationValid: { h: boolean; m: boolean; total: boolean } = {
      h: validNumber(duration.h, 0, 99) || (validNumber(duration.m, 0, 59) && duration.h === ""),
      m: validNumber(duration.m, 0, 59) || (validNumber(duration.h, 0, 99) && duration.m === ""),
      total: !isNaN(total) && total > 0,
    };

    const constructResponseUTCDate = (): moment.Moment => {
      const d = timestamp.d!;
      // By default we construct a time at the end of the selected date - 23:59
      // the validation will require a specific time to be entered
      const hours = timestamp.h.length !== 0 ? timestamp.h : "23";
      const minutes = timestamp.m.length !== 0 ? timestamp.m : "59";
      const date: Date = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(), Number(hours), Number(minutes), 0));
      return moment(date);
    };

    const dateIsInTheFuture = (): boolean => {
      if (!isNaN(Number(timestamp.h)) && !isNaN(Number(timestamp.m)) && timestamp.d) {
        const responseDate = constructResponseUTCDate();
        const now = moment().utc();
        return responseDate >= now;
      }
      return true;
    };

    const timestampValid: { h: boolean; m: boolean; d: boolean } = {
      h: validNumber(timestamp.h, 0, 23) && dateIsInTheFuture(),
      m: validNumber(timestamp.m, 0, 59) && dateIsInTheFuture(),
      d: Boolean(timestamp.d) && dateIsInTheFuture(),
    };

    const durationSelected = option === "duration";
    const timestampSelected = option === "timestamp";

    validity.fieldValidityChanged("duration-hours", timestampSelected || durationValid.h);
    validity.fieldValidityChanged("duration-minutes", timestampSelected || durationValid.m);
    validity.fieldValidityChanged("duration-total", timestampSelected || durationValid.total);

    validity.fieldValidityChanged("timestamp-hours", durationSelected || timestampValid.h);
    validity.fieldValidityChanged("timestamp-minutes", durationSelected || timestampValid.m);

    validity.fieldValidityChanged("timestamp-date", durationSelected || timestampValid.d);

    setIsExpiryValid({
      durationHours: validity.isFieldValid("duration-hours") && validity.isFieldValid("duration-total"),
      durationMinutes: validity.isFieldValid("duration-minutes") && validity.isFieldValid("duration-total"),
      timestampHours: validity.isFieldValid("timestamp-hours"),
      timestampMinutes: validity.isFieldValid("timestamp-minutes"),
      timestampDate: validity.isFieldValid("timestamp-date"),
    });

    if (durationSelected && durationValid.h && durationValid.m && durationValid.total) {
      let value = `P0Y0M0DT${duration.h || 0}H${duration.m || 0}M0S`;

      if (durationAsTimestamp) {
        const date = moment().utc();

        date.add(momentDuration(value));

        value = date.toISOString();
      }

      onChange({
        type: "duration",
        value,
      });
    } else if (timestampSelected && timestampValid.h && timestampValid.m && timestampValid.d) {
      const responseDate = constructResponseUTCDate();
      onChange({
        type: "timestamp",
        value: utc(responseDate).toISOString(),
      });
    } else {
      onChange({
        type: "timestamp",
        value: "",
      });
    }

    return () => {
      validity.fieldValidityChanged("duration-hours", true);
      validity.fieldValidityChanged("duration-minutes", true);
      validity.fieldValidityChanged("duration-total", true);
      validity.fieldValidityChanged("timestamp-hours", true);
      validity.fieldValidityChanged("timestamp-minutes", true);
      validity.fieldValidityChanged("timestamp-date", true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration, timestamp, option, validity]);

  const smallInputStyles = classNames(styles.input, styles.smallInput);

  return (
    <div className={styles.container}>
      <div className={styles.option}>
        <input
          id="durationToRespond"
          data-test={id ? `${id}-time-to-respond` : "time-to-respond"}
          type="radio"
          name="option"
          value="duration"
          defaultChecked={option === "duration"}
          onChange={() => optionChange("duration")}
        />
        <label
          htmlFor={"durationToRespond"}
          className={classNames(option !== "duration" ? styles.disabled : undefined, styles.label)}
        >
          {targetRole} will have
          <input
            type="text"
            data-test={id ? `${id}-expiresOnHoursInput` : "expiresOnHoursInput"}
            aria-label="expiresOnHoursInput"
            className={classNames(smallInputStyles, isExpiryValid.durationHours ? undefined : styles.invalid)}
            size={2}
            placeholder="h"
            value={duration.h}
            onChange={(evt) => setDuration({ ...duration, h: evt.currentTarget.value })}
            autoFocus={autoFocus}
            disabled={option !== "duration"}
          />
          :
          <input
            type="text"
            data-test={id ? `${id}-expiresOnMinutesInput` : "expiresOnMinutesInput"}
            aria-label="expiresOnMinutesInput"
            className={classNames(smallInputStyles, isExpiryValid.durationMinutes ? undefined : styles.invalid)}
            size={2}
            placeholder="m"
            value={duration.m}
            onChange={(evt) => setDuration({ ...duration, m: evt.currentTarget.value })}
            disabled={option !== "duration"}
          />
          {action}.
        </label>
      </div>
      <div className={classNames(styles.option, styles.timestampSelected)}>
        <div className={styles.dateContent}>
          <input
            id="timestamp"
            data-test={id ? `${id}-respond-by` : "respond-by"}
            type="radio"
            name="option"
            value="timestamp"
            defaultChecked={option === "timestamp"}
            onChange={() => optionChange("timestamp")}
          />
          <label htmlFor={"timestamp"} className={classNames(styles.label, option !== "timestamp" ? styles.disabled : undefined)}>
            {targetRole} {action} by
            <input
              type="text"
              data-test={id ? `${id}-timestamp-hour-input` : "timestamp-hour-input"}
              className={classNames(smallInputStyles, isExpiryValid.timestampHours ? undefined : styles.invalid)}
              size={2}
              placeholder="h"
              value={timestamp.h}
              onChange={(evt) => setTimestamp({ ...timestamp, h: evt.currentTarget.value })}
              autoFocus={option === "timestamp"}
              disabled={option !== "timestamp"}
            />
            :
            <input
              type="text"
              className={classNames(smallInputStyles, isExpiryValid.timestampMinutes ? undefined : styles.invalid)}
              size={2}
              placeholder="m"
              value={timestamp.m}
              onChange={(evt) => setTimestamp({ ...timestamp, m: evt.currentTarget.value })}
              disabled={option !== "timestamp"}
            />
            <div className={styles.date}>GMT/UTC on the</div>
            <div className={styles.datePicker}>
              <DatePicker
                className={isExpiryValid.timestampDate ? undefined : styles.invalid}
                icon="date-range"
                value={timestamp.d as Date}
                onSelectDate={(date: Date | null | undefined) => setTimestamp({ ...timestamp, d: date })}
                disabled={option !== "timestamp"}
              />
              <div className={styles.dateInPast}>
                {timestamp.d && !isExpiryValid.timestampDate && (
                  <span className={styles.errorMessage}>The date you have entered is in the past</span>
                )}
              </div>
            </div>
          </label>
        </div>
        <div className={styles.utcMessage}>{option === "timestamp" && <DigitalClock />}</div>
      </div>
    </div>
  );
};
