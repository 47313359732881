import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { DataModel } from "@/models";
import { InputProps, InputChangeEvent } from "../../Input";
import { Container } from "../../Container";

export function TextInput(props: TextInputProps) {
  props = { ...props };

  props.className = classNames(props.className, "text");

  let value = props.value;

  if (value instanceof DataModel) value = value.toView();

  if (!value) value = "";

  const input = useRef<HTMLInputElement | null>(null);

  useEffect(onMount, []);

  function onMount() {
    if (props.input) props.input.current = input.current;

    return onUnmount;
  }

  function onUnmount() {
    if (props.input) props.input.current = null;
  }

  function onChange(e: InputChangeEvent) {
    let next = e.currentTarget.value;

    // @ts-ignore
    if (props.value instanceof DataModel) next = new props.value.constructor(next);

    props.onChange?.(next);
  }

  return (
    <Container {...props}>
      <input
        // @ts-ignore
        value={value}
        onChange={onChange}
        onKeyDown={props.onKeyDown}
        placeholder={props.placeholder}
        disabled={props.disabled}
        tabIndex={props.tabIndex}
        data-test={`${props.dataTest}.input`}
        ref={input}
        type="text"
      />
    </Container>
  );
}

export type TextInputProps = InputProps<Value>;

type Value = any;
