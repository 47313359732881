import { Body as PostBody } from "___REFACTOR___/models/Trade/Enp/Settings/EmailLists/POST/Req/Body";

class Body extends PostBody {
  toJSON() {
    const emailList = this.emailList.toJSON();

    const json = { data: this.isPrivate ? { ...emailList, sharing: [] } : emailList, updateToken: emailList.updateToken };

    return json as any;
  }
}

export { Body };
