import classNames from "classnames";
import { Period } from "@/models";
import { MaritimeDateRange } from "@maritech/maritime-date";
import { IOEditor, IOEditorProps } from "../IO";

export function PeriodEditor(props: Props) {
  props = { ...props };

  props.className = classNames(props.className, "Period");

  const { value } = props;

  function onInputChange(string: string) {
    if (!string || string === "") {
      props.onChange?.(null);
      return;
    }
    const newParsedLaycan = MaritimeDateRange.parse(string, { americanFormat: false, contextualParsing: "future" });
    if (newParsedLaycan.isValid)
      props.onChange?.(
        new Period({
          style: 0,
          styleDescription: "Exact",
          start: newParsedLaycan.start.toJSDate().toISOString(),
          end: newParsedLaycan.end.startOf("day").toJSDate().toISOString(),
          term: string,
        })
      );
  }

  return (
    <IOEditor
      {...props}
      value={value?.toView()}
      onInputChange={onInputChange}
      dataTest={`${props.dataTest}.value`}
      seedInputWithCurrentValue
    />
  );
}

interface Props extends Omit<IOEditorProps, "value" | "onChange"> {
  value?: Period;
  onChange?: (value: Period | null) => void;
}
