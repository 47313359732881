import React, { ReactNode } from "react";
import { Argument } from "classnames";
import { Status } from "___REFACTOR___/models";
import { Icon, ImgProps } from "___REFACTOR___/components";
import {
  TextInput as Text,
  NumberInput as Number,
  SelectInput as Select,
  TextareaInput as Textarea,
  CheckboxInput as Checkbox,
  DateInput as Date,
  RadioInput as Radio,
  DateOffsetInput as DateOffset,
  RangeInput as Range,
  CommaSeparatedValuesInput as CommaSeparatedValues,
} from "./type";

function Input(props: Props) {
  const { type } = props;

  const Type = type && Input.TYPE_MAP[type];

  if (!Type) {
    console.error(`Unrecognized input type: "${type}"`, props);

    return null;
  }

  return <Type {...props} />;
}

Input.TYPE_MAP = {
  Text,
  Number,
  Select,
  Checkbox,
  Textarea,
  Date,
  Radio,
  DateOffset,
  Range,
  CommaSeparatedValues,
};

export { Input };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

// eslint-disable-next-line no-redeclare
declare namespace Input {
  export interface Props {
    type?: Input.Type;
    value: any;
    onChange: (value: any) => void;
    onClear?: () => void;
    onSubmit?: () => void;
    onFocus?: (e) => void;
    onBlur?: (e) => void;
    onKeyDown?: (e) => void;
    onClick?: (e) => void;
    interceptChangeHandler?: (value) => any;
    status?: Status;
    className?: Argument;
    placeholder?: string;
    desc?: string;
    tabIndex?: number;
    disabled?: boolean;
    hidden?: boolean;
    icon?: Icon;
    img?: Img;
    clearable?: boolean;
    submitable?: boolean;
    unclearable?: boolean;
    dataTest?: DataTest;
    contentBasedWidth?: boolean;
    autoFocus?: boolean;
    children?: ReactNode;
  }

  export type Type = keyof typeof Input.TYPE_MAP;
  export type Icon = Icon.IconProp;
  export type Img = ImgProps["img"];
  export type DataTest = string;

  export { Text, Number, Select, Checkbox, Textarea, Date, Radio, DateOffset, Range };
}

interface Props extends Input.Props {
  domRef?: any;
}
