import { MutableRefObject, useEffect } from "react";
import { wait } from "../utils";
import "./useRipple.scss";

function useRipple<T extends HTMLElement | null>(DomRef: MutableRefObject<T>) {
  const target = DomRef.current;

  useEffect(setup, [target]);

  function setup() {
    if (!target) return;

    target.onclick = onClick;
  }

  async function onClick(e: MouseEvent) {
    if (!target) return;

    const { x, y } = e;
    const { top, left, width, height } = target.getBoundingClientRect();
    const eRipple = document.createElement("app-ripple");

    target.style.position = "relative";
    target.style.overflow = "hidden";

    eRipple.style.top = `${y - top}px`;
    eRipple.style.left = `${x - left}px`;
    eRipple.style.transform = "scale(0)";
    eRipple.style.opacity = "0";

    target?.appendChild(eRipple);

    await wait(0);

    const hypotenuse = Math.sqrt(width * width + height * height);

    eRipple.style.transform = `scale(${hypotenuse * 1.33})`;
    eRipple.style.opacity = "1";

    await wait(450);

    eRipple.style.opacity = "0";

    await wait(450);

    eRipple.remove();
  }
}

export { useRipple };

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */
