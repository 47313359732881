import * as Module from "history";
import * as prod from "@/history";

class History {
  constructor() {
    // this.h = Module.createBrowserHistory();

    this.h = prod.history as Module.History<History.Location.State>;
  }

  get listen() {
    return this.h.listen;
  }

  get push() {
    return this.h.push;
  }

  get replace() {
    return this.h.replace;
  }
}

const history = new History();

export { History, history };

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

interface History {
  h: Module.History<History.Location.State>;
}

declare namespace History {
  type Location = Module.Location<Location.State>;
  namespace Location {
    interface State {}
  }

  type Action = Module.Action;
}

type A = History.Location.State;
