import classNames from "classnames";
import { useState, useEffect } from "react";
import { renderDistributionEmailError, IGenericErrorObject } from "./DistributionListPanel";
import { IEmailList, IEmailListPUT } from "../../services/settings";
import { useAPIFetch } from "./useAPIFetch";
import { isEmail } from "validator";
import { Invitees } from "../createNegotiation/Invitees";
import Button from "__legacy/sharedFolder/components/common/Button/Button";
import styles from "./DistributionListPanel.module.scss";

interface INewDistributionEmailForm {
  currentEmails: string[];
  item: IEmailList;
  onFormClose: () => void;
  updateList: (updatedItem: IEmailList) => void;
}

export const NewDistributionEmailForm = ({ currentEmails, item, onFormClose, updateList }: INewDistributionEmailForm) => {
  const [emailArray, setEmailArray] = useState<string[]>([]);
  const [updatedList, setUpdatedList] = useState<null | IEmailListPUT>(null);
  const [validationErrors, setValidationErrors] = useState<null | IGenericErrorObject>(null);
  const {
    fetching,
    error: apiError,
    response: apiResponse,
  } = useAPIFetch<IEmailListPUT>(`/settings/emaillists/${item.id}`, "PUT", updatedList);

  const errorMessage = renderDistributionEmailError({
    apiError,
    validationErrors,
  });

  useEffect(() => {
    function softUpdateGroup() {
      updateList({
        ...item,
        values: [...emailArray, ...item.values],
      });
    }
    if (apiResponse) {
      // response from the server means the post was successful
      // optimistic update
      softUpdateGroup();
      onFormClose();
    }
  }, [apiResponse, emailArray, item, onFormClose, updateList]);

  function submitForm() {
    // this is right now identical to the errors object in NewDistributionGroupForm.tsx, maybe it should be different?
    const errors: IGenericErrorObject = {
      invalidEmail: emailArray.some((email) => !isEmail(email)),
      emailEmpty: !emailArray.length,
      emailAlreadyExist: emailArray.some((email) => currentEmails.indexOf(email) >= 0),
      emailTooLong: emailArray.some((email) => email.length > 100),
    };
    if (Object.keys(errors).some((key) => errors[key])) {
      setValidationErrors(errors);
    } else {
      setValidationErrors(null);
      setUpdatedList({
        data: { name: item.name, values: [...emailArray, ...item.values] },
        updateToken: item.updateToken,
      });
    }
  }

  return (
    <div className={styles.newEmailListForm} aria-label="new-email-list-form">
      New Email Address
      <Invitees
        onInviteesChanged={(emails: string[]) => setEmailArray(emails)}
        invitees={emailArray}
        placeholder="Enter an email address"
      />
      <div className={classNames(styles.actionsContainer, styles.rightAlgined)}>
        {errorMessage && <p className={styles.formErrorMessage}>{errorMessage}</p>}
        <Button ariaLabel={"cancel-new-entity"} type="flat" onClick={onFormClose}>
          Cancel
        </Button>
        <Button ariaLabel={"save-new-entity"} type="action" disabled={fetching} onClick={() => submitForm()}>
          Save
        </Button>
      </div>
    </div>
  );
};
