import { TradeAPI } from "___REFACTOR___/apis";
import { AccountEditor, OwnerAccountEditor } from "___REFACTOR___/components";
import { LABEL_MAP } from "___REFACTOR___/constants";
import { DataModel, DataModelProps } from "../DataModel";
import { schema } from "./schema";

class Account extends DataModel {
  constructor(data: PartialData) {
    super(data);
  }

  toJSON() {
    const json = DataModel.prototype.toJSON.call(this) as TradeAPI.Account;

    return json;
  }

  isValid() {
    return schema.isValidSync(this);
  }

  toString() {
    return this.accountName;
  }

  toGridView() {
    return this.accountName;
  }
}

class AccountProps extends DataModelProps {
  get label() {
    return "Account";
  }

  get Editor() {
    return AccountEditor;
  }

  get schema() {
    return schema;
  }
}

Account.prototype.Props = AccountProps;

class ChartererAccount extends Account {
  static get Editor() {
    return AccountEditor;
  }

  static label = LABEL_MAP.chartererAccount;
}

class ChartererAccountProps extends AccountProps {
  get label() {
    return LABEL_MAP.chartererAccount;
  }
}

ChartererAccount.prototype.Props = ChartererAccountProps;

class OwnerAccount extends Account {
  static get Editor() {
    return OwnerAccountEditor;
  }

  static label = "Owning Company";
}

class OwnerAccountProps extends AccountProps {
  get Editor() {
    return OwnerAccountEditor;
  }
}

export { Account, AccountProps, ChartererAccount, ChartererAccountProps, OwnerAccount, OwnerAccountProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface Account extends Data {
  Props: typeof AccountProps;
  _: AccountProps;
}

type PartialData = Partial<Data>;
type Data = TradeAPI.Account;

interface ChartererAccount {
  Props: typeof ChartererAccountProps;
  _: ChartererAccountProps;
}

interface OwnerAccount {
  Props: typeof OwnerAccountProps;
  _: OwnerAccountProps;
}
