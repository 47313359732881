import React from "react";
import { observer } from "mobx-react";
import * as Services from "___REFACTOR___/services";
import * as Common from "___REFACTOR___/components/common";

function DropdownComponent() {
  const { props, open } = Services.dropdown;

  if (!props || !open) return null;

  return <Common.Dropdown {...props} hide={Services.dropdown.hide} select={Services.dropdown.select} />;
}

const Dropdown = observer(DropdownComponent);

export { Dropdown };

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

declare namespace Dropdown {
  type Props = Omit<Common.Dropdown.Props, "hide" | "select">;

  type Item = Common.Dropdown.Item;
}
