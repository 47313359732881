import { TradeAPI } from "@/apis";
import { format } from "@/utils";
import { LiftingsEditor } from "@/components";
import { DataModel, DataModelProps } from "../DataModel";
import { PrimitiveDataModel, PrimitiveDataModelProps } from "../PrimitiveDataModel";
import { LABELS } from "@/constants";

class Liftings extends DataModel<Data> {
  onUpdate() {
    delete this.display;
    delete this.shortDisplay;
  }

  toString() {
    if (!isValid(this)) return "";

    const notes = this.notes || "";
    const dispersal = Dispersal.prototype.toString.call({ data: this.dispersal });

    return `${format.number.display(this.min)}-${format.number.display(this.max)} / ${dispersal} ${notes}`;
  }

  toGridView() {
    if (!isValid(this)) return "";

    const dispersal = Dispersal.prototype.toString.call({ data: this.dispersal });

    return `${format.number.display(this.min)}-${format.number.display(this.max)} / ${dispersal}`;
  }

  isValid() {
    return isValid(this);
  }
}

function isValid(data: Data) {
  return typeof data.min === "number" && typeof data.max === "number";
}

const dispersals = ["Fairly even spread", "Monthly", "Adhoc"] as DispersalData[];

class Dispersal extends PrimitiveDataModel<DispersalData> {
  toString() {
    if (this.data === "Fairly even spread") return this.data;
    if (this.data === "Monthly") return this.data;
    if (this.data === "Adhoc") return "Ad hoc";

    return "";
  }
}
class DispersalProps extends PrimitiveDataModelProps<DispersalData> {}

Dispersal.prototype.Props = DispersalProps;

function newDispersal(dispersal: DispersalData) {
  return new Dispersal(dispersal);
}

class LiftingsProps extends DataModelProps<Data> {}
LiftingsProps.prototype.editor = LiftingsEditor;
LiftingsProps.prototype.sortKey = "max";
LiftingsProps.prototype.label = LABELS["liftings"];
LiftingsProps.prototype.JSONDefaults = {
  dispersal: "Fairly even spread",
};
LiftingsProps.prototype.dispersals = dispersals.map(newDispersal);
Liftings.prototype._ = new LiftingsProps();

export { Liftings, LiftingsProps };

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

interface Liftings extends Data {
  _: LiftingsProps;
}

interface LiftingsProps {
  dispersals: Dispersal[];
}

type DispersalData = TradeAPI["Liftings"]["dispersal"];

type Data = DeepPartial<TradeAPI["Liftings"]>;
