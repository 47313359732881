import { DependencyList, useEffect, useMemo } from "react";
import { observable } from "mobx";

function useObsBox<T>(val: T, deps?: DependencyList) {
  const box = useMemo(() => observable.box(val), []);

  useEffect(() => box.set(val), deps || [val]);

  return box;
}

export { useObsBox };
