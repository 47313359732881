import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import { uid } from "@/utils";
import { InputProps, InputChangeEvent } from "../../Input";
import { Container } from "../../Container";
import "./Checkbox.scss";

export function CheckboxInput(props: CheckboxProps) {
  props = { ...props };

  props.className = classNames(props.className, "checkbox");

  const { checkedValue = true, uncheckedValue = false, label } = props;

  const checked = checkedValue === props.value;
  const input = useRef<HTMLInputElement | null>(null);

  useEffect(onMount, []);

  function onMount() {
    if (props.input) props.input.current = input.current;

    return onUnmount;
  }

  function onUnmount() {
    if (props.input) props.input.current = null;
  }

  function onChange(e: InputChangeEvent) {
    props.onChange?.(checked ? uncheckedValue : checkedValue);
  }

  const id = `${uid()}`;

  return (
    <Container {...props}>
      <input
        checked={checked}
        onChange={onChange}
        placeholder={props.placeholder}
        disabled={props.disabled}
        data-test={`${props.dataTest}.input`}
        id={id}
        ref={input}
        type="checkbox"
      />
      {label && <label htmlFor={id}>{label}</label>}
    </Container>
  );
}

export interface CheckboxProps extends InputProps<Value> {
  checkedValue?: any;
  uncheckedValue?: any;
  label?: string;
}

type Value = boolean | null;
