import React from "react";
import styles from "./DistributionUserTagList.module.scss";
import tokenSearchStyles from "__legacy/sharedFolder/styles/token-search-styles.module.scss";
import classNames from "classnames";
import { blocked } from "sharedFolder/Utilities/blockedDomains";
import { Negotiation } from "@/models";

export const DistributionUserTagList = ({ tags, title }: { tags: { display: string; faded: boolean }[]; title: string }) => {
  const getTagStyle = (tag = ""): "None" | "Warning" | "Yellow" | "Orange" => {
    if (blocked(tag)) {
      return "Warning";
    }
    if (Negotiation.prototype.isAbsentOwnerEmail(tag)) {
      return "Orange";
    }
    return tag.includes("@") ? "Yellow" : "None";
  };

  const getTagDisplay = (tag: string) => {
    const absentOwnerName = Negotiation.prototype.getAbsentOwnerDisplay(tag);
    if (absentOwnerName) {
      return absentOwnerName;
    }
    return tag;
  };

  return (
    <>
      <h4>{title}</h4>
      <div className={`${styles.inputTagContainer}`}>
        <ul className={styles.inputTagList}>
          {tags.map((tag, i) => {
            const tagStyle = getTagStyle(tag.display);
            return (
              <li
                key={tag.display + i}
                className={classNames(
                  tagStyle === "Yellow"
                    ? tokenSearchStyles.tokenItemYellow
                    : tagStyle === "Warning"
                    ? tokenSearchStyles.tokenItemWarning
                    : tagStyle === "Orange"
                    ? tokenSearchStyles.tokenItemOrange
                    : tokenSearchStyles.tokenItem,
                  tag.faded ? styles.faded : undefined
                )}
                data-test="user-tag"
              >
                {getTagDisplay(tag.display)}
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};
