import React, { useEffect, useState, useCallback, createContext, useContext } from "react";
import { useAsync } from "react-async";
import { useConfig } from "__legacy/sharedFolder/ConfigurationContext";
import * as signalR from "@aspnet/signalr";
import { useUser } from "__legacy/dashboard/contexts/UserProvider";
import { IOwnerNegotiationApi, IOwnerNegotiation } from "../types";
import { intersection } from "lodash-es";
import { useLocation } from "react-router-dom";
import { formatDateWithTime, getNow } from "sharedFolder/Utilities/getNow";
import moment from "moment";
import { orderVessels } from "sharedFolder/Utilities/orderVessels";
import { getVesselSummary } from "sharedFolder/Utilities/getVesselSummary";
import lastUpdatedAction from "sharedFolder/Utilities/lastUpdatedAction";
import { mapVessel } from "sharedFolder/mappers/mapVessel";
import { mapOrderNegType } from "sharedFolder/mappers/mapOrderNegType";
import { FreightRateUnit, FreightRateUnitDisplay, IFreightRate } from "sharedFolder/Models/IFreightRate";
import { IValue } from "api/negotiations/models/INegSide";
import { HireRateUnit, HireRateUnitDisplay, IHireRate } from "sharedFolder/Models/IHireRate";
import { CleaningPriceUnit, CleaningPriceUnitDisplay, ICleaningPrice } from "sharedFolder/Models/ICleaningPrice";
import { SupplyPriceUnit, SupplyPriceUnitDisplay, ISupplyPrice } from "sharedFolder/Models/ISupplyPrice";
import { IBunkerView } from "sharedFolder/Models/IBunker";
import { DemurrageUnit, DemurrageUnitDisplay, IDemurrage } from "sharedFolder/Models/IDemurrage";
import { BallastBonusUnit, BallastBonusUnitDisplay, IBallastBonus } from "api/negotiations/models";
import { bunkerFieldDisplayFormatter } from "sharedFolder/components/common/fields";
import { Format } from "negotiations/services/utils/converters";

interface ILastUpdatedOrder {
  orderId?: string;
  lastUpdated?: string;
}
interface IOwnerNegotiationProviderProps {
  children: React.ReactNode;
}

const OwnerNegotiationContext = createContext<{
  isLoading: boolean;
  negotiations: null | IOwnerNegotiation[];
  lastUpdatedOrder: null | ILastUpdatedOrder;
}>({ isLoading: false, negotiations: null, lastUpdatedOrder: null });

function mapApiToSummary(negotiations: IOwnerNegotiationApi[]): IOwnerNegotiation[] {
  return negotiations.map((negotiation) => {
    const negVessels = negotiation.vessels || [];

    const vessels = orderVessels(negVessels.map(mapVessel.toView));

    const vesselSummary = getVesselSummary(vessels);

    const primaryDwt = vessels && vessels.length && vesselSummary.short !== "(TBN)" ? String(vessels[0].dwt) : ""; // get the DWT of the primary vessel;

    const getBallastBonus = (ballastBonus?: IValue<IBallastBonus>): string => {
      const ballastBonusValue = ballastBonus?.value?.value;
      const ballastBonusUnit = ballastBonus?.value?.unit;
      return ballastBonusValue
        ? `$${ballastBonusValue.toFixed(3)} ${BallastBonusUnitDisplay[ballastBonusUnit as BallastBonusUnit]}`
        : "-";
    };

    const getDemurrage = (demurrage?: IValue<IDemurrage>): string => {
      const demurrageValue = demurrage?.value?.value;
      const demurrageUnit = demurrage?.value?.unit;
      return demurrageValue ? `$${demurrageValue.toFixed(3)} ${DemurrageUnitDisplay[demurrageUnit as DemurrageUnit]}` : "-";
    };

    const getFreightRate = (freightRate?: IValue<IFreightRate>): string => {
      const freightRateValue = freightRate?.value?.value;
      const freightRateUnit = freightRate?.value?.unit;
      return freightRateValue
        ? `$${freightRateValue.toFixed(3)} ${FreightRateUnitDisplay[freightRateUnit as FreightRateUnit]}`
        : "-";
    };

    const getHireRate = (hireRate?: IValue<IHireRate>): string => {
      const hireRateValue = hireRate?.value?.value;
      const hireRateUnit = hireRate?.value?.unit;
      return hireRateValue ? `$${hireRateValue.toFixed(3)} ${HireRateUnitDisplay[hireRateUnit as HireRateUnit]}` : "-";
    };
    const getCleaningPrice = (cleaningPrice?: IValue<ICleaningPrice>): string => {
      const cleaningPriceValue = cleaningPrice?.value?.value;
      const cleaningPriceUnit = cleaningPrice?.value?.unit;
      return cleaningPriceValue
        ? `$${cleaningPriceValue.toFixed(3)} ${CleaningPriceUnitDisplay[cleaningPriceUnit as CleaningPriceUnit]}`
        : "-";
    };
    const getSupplyPrice = (supplyPrice?: IValue<ISupplyPrice>): string => {
      const supplyPriceValue = supplyPrice?.value?.value;
      const supplyPriceUnit = supplyPrice?.value?.unit;
      return supplyPriceValue
        ? `$${supplyPriceValue.toFixed(3)} ${SupplyPriceUnitDisplay[supplyPriceUnit as SupplyPriceUnit]}`
        : "-";
    };

    const getBunker = (bunkerValue?: IValue<IBunkerView>): string => {
      const emptyFieldText = "-";

      return bunkerValue?.value
        ? bunkerFieldDisplayFormatter(bunkerValue?.value, emptyFieldText, Format.FIXED_TO)
        : emptyFieldText;
    };

    return {
      account: negotiation?.chartererAccount?.accountName || "",
      cargo: negotiation?.bid?.cargoType?.value?.name || "",
      ballastBonusIn: getBallastBonus(negotiation?.bid?.ballastBonus),
      ballastBonusOut: getBallastBonus(negotiation?.offer?.ballastBonus),
      demurrageIn: getDemurrage(negotiation?.bid?.demurrage),
      demurrageOut: getDemurrage(negotiation?.offer?.demurrage),
      demurrageSide: negotiation?.detailSettings?.demurrage?.side || "",
      dischargeLocation: negotiation?.bid?.dischargeLocation?.value?.shortDisplay || "",
      dwt: primaryDwt,
      firmBidExpiresOn: negotiation.actions.brokerChartererFirmExpiresOn || null,
      firmOfferExpiresOn: negotiation.actions.ownerFirmExpiresOn || null,
      freightIn: getFreightRate(negotiation?.bid?.freightRate),
      freightOut: getFreightRate(negotiation?.offer?.freightRate),
      freightSide: negotiation?.detailSettings?.freightRate?.side || "",
      from: negotiation.circulatedBy || "",
      groupChat: negotiation.groupChat || null,
      hireIn: getHireRate(negotiation?.bid?.hireRate),
      hireOut: getHireRate(negotiation?.offer?.hireRate),
      cleaningPriceIn: getCleaningPrice(negotiation?.bid?.cleaningPrice),
      cleaningPriceOut: getCleaningPrice(negotiation?.offer?.cleaningPrice),
      supplyPriceIn: getSupplyPrice(negotiation?.bid?.supplyPrice),
      supplyPriceOut: getSupplyPrice(negotiation?.offer?.supplyPrice),
      tradingExclusionsIn: negotiation?.bid?.tradingExclusions?.value?.value || "",
      tradingExclusionsOut: negotiation?.offer?.tradingExclusions?.value?.value || "",
      bunkerDeliveryIn: getBunker(negotiation?.bid?.bunkerDelivery),
      bunkerDeliveryOut: getBunker(negotiation?.offer?.bunkerDelivery),
      bunkerRedeliveryIn: getBunker(negotiation?.bid?.bunkerRedelivery),
      bunkerRedeliveryOut: getBunker(negotiation?.offer?.bunkerRedelivery),
      cargoExclusionsTextIn: negotiation?.bid?.cargoExclusionsText?.value?.value || "",
      cargoExclusionsTextOut: negotiation?.offer?.cargoExclusionsText?.value?.value || "",
      id: negotiation.id,
      lastUpdatedAction: lastUpdatedAction(negotiation.actions),
      lastUpdated: negotiation.lastUpdated ? formatDateWithTime(negotiation.lastUpdated) : "",
      lastUpdatedOriginal: negotiation.lastUpdated || "",
      lastUpdatedBy: negotiation.lastUpdatedBy || "",
      lastUpdatedByForGrid: negotiation.lastUpdatedBy || "",
      laycan: negotiation?.bid?.laycan?.value?.display || "",
      period: negotiation?.bid?.period?.value?.display || "",
      loadLocation: negotiation?.bid?.loadLocation?.value?.shortDisplay || "",
      onSubsExpiresOn: negotiation.onSubsExpiresOn || null,
      orderId: negotiation.orderId,
      quantity: negotiation?.bid?.cargoSize?.value?.summarydisplay || "",
      received: negotiation.createdOn ? moment(negotiation.createdOn).format("DD MMM YY") : "",
      status: negotiation.status,
      vesselSummary,
      type: mapOrderNegType.toView(negotiation.type),
      deliveryLocation: negotiation?.bid?.deliveryLocation?.value?.shortDisplay || "",
      viaLocation: negotiation?.bid?.viaLocation?.value?.shortDisplay || "",
      redeliveryLocation: negotiation?.bid?.redeliveryLocation?.value?.shortDisplay || "",
      version: negotiation.version,
      liftingId: negotiation.liftingId || null,
      liftingCargoId: negotiation.liftingCargoId || null,
      coaNegotiationId: negotiation.coaNegotiationId || null,
    };
  });
}

async function getSignalRConnection({ apiUrl }: any, { signal }: AbortController) {
  return fetch(`${apiUrl}/notifications`, {
    method: "POST",
    signal,
  })
    .then((response) => response.json())
    .then((data) =>
      new signalR.HubConnectionBuilder()
        .withUrl(data.url, { accessTokenFactory: () => data.accessToken })
        .configureLogging(signalR.LogLevel.Debug)
        .build()
    );
}

const loadNegotiations = async (args: any, { url }: any, { signal }: AbortController) => {
  const res = await fetch(url, {
    method: "GET",
    signal,
  });
  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return res.json();
};

function OwnerNegotiationProvider(props: IOwnerNegotiationProviderProps) {
  const { children } = props;
  const [fetchOriginator, setFetchOriginator] = useState<"default" | "signalR">("default"); // this is a bad pattern but i spent too much time on the issue anyway, it would be better if useAsync could be aware of the originator and return it when setting isLoading to true. Two options to clean this up: write your own useFetch hook or pass a custom reducer to useAsync
  const [lastUpdatedOrderId, setLastUpdatedOrderId] = useState<ILastUpdatedOrder>();

  const { ctradeUrl } = useConfig();
  const { memberships } = useUser();
  const location = useLocation();
  const filter = new URLSearchParams(location.search).get("filter");
  const addIncludeCOA = "?includeCoa=true";
  const queryParams = filter ? `${addIncludeCOA}&filterJson=${filter}` : addIncludeCOA;
  const url = `${ctradeUrl}/owner/negotiations/${queryParams}`;

  const {
    data: negotiations,
    isLoading,
    run: readNegotiations,
  } = useAsync({
    deferFn: loadNegotiations,
    url,
  });

  const callReadNegotiations = useCallback(
    (originator: "default" | "signalR") => {
      // this wrapper is there so you never forget to call readNegotiations without also setting an originator
      setFetchOriginator(originator);
      readNegotiations();
    },
    [readNegotiations]
  );

  const showLoading = isLoading && fetchOriginator !== "signalR"; // show the loading only if the call wasn't initiated by signalR

  useEffect(() => {
    callReadNegotiations("default");
  }, [callReadNegotiations, filter]); // load the negotiations initially and when the filter changes

  const { data: signalRConnection, error: signalRError } = useAsync({
    promiseFn: getSignalRConnection,
    apiUrl: ctradeUrl,
  });

  if (signalRError) {
    console.error("signal R error");
  }

  useEffect(() => {
    function isMemberOf(targetMembership: string[]) {
      return intersection(memberships, targetMembership).length; // any intersection between user's and target's membership would mean the user is a member of the target membership array
    }

    // this needs to be called only once as calling connection.start twice throws an error hence useEffect
    if (signalRConnection) {
      // The if statement here wll ensure that if a signalR connection hasn't been made,
      // we don't want that to break the flow of this component
      signalRConnection.on("seatrade", (data) => {
        if (isMemberOf(data.memberships)) {
          if (data.eventType === "OrderUpdated") {
            setLastUpdatedOrderId({
              orderId: data.id,
              lastUpdated: getNow().toISOString(),
            });
          }
          callReadNegotiations("signalR");
        }
      });

      signalRConnection.onclose(() => {
        console.warn("signalR connection closed!");
        window.location.reload(); // token has expired, refresh to force login
      });
    }

    return () => {
      signalRConnection?.off("seatrade"); //without this multiple signalR even listeners will stay open as different filters are applied
    };
  }, [signalRConnection, memberships, callReadNegotiations]);

  return (
    <OwnerNegotiationContext.Provider
      value={{
        isLoading: showLoading,
        negotiations: negotiations ? mapApiToSummary(negotiations) : null,
        lastUpdatedOrder: lastUpdatedOrderId || null,
      }}
    >
      {children}
    </OwnerNegotiationContext.Provider>
  );
}

function useOwnerNegotiations() {
  const context = useContext(OwnerNegotiationContext);
  if (context === undefined) {
    throw new Error("useOwnerNegotiations must be within a OwnerNegotiationProvider");
  }

  return context;
}

export { OwnerNegotiationProvider, useOwnerNegotiations };
