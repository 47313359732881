import { TradeAPI } from "@/apis";
import { CommissionEditor } from "@/components";
import { DataModel, DataModelProps } from "../DataModel";

export class Commission extends DataModel<Data> {
  onUpdate() {
    delete this.display;
    delete this.shortDisplay;
  }

  toString() {
    if (typeof this.value !== "number") return;

    return `${this.value?.toFixed(3)}%`;
  }
}

export class CommissionProps extends DataModelProps<Data> {}

CommissionProps.prototype.editor = CommissionEditor;
CommissionProps.prototype.label = "Commission";

Commission.prototype._ = new CommissionProps();

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

export interface Commission extends Data {
  _: CommissionProps;
}

type Data = DeepPartial<TradeAPI["Commission"]>;
