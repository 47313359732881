import React, { useRef, useState } from "react";
import classNames from "classnames";
import { emptyFn } from "@/utils";
import { TextField, TextFieldProps, InputFocusEvent } from "@/components";
import { Row } from "../../Row";
import { Container } from "@/components/common/Field/Container";
import "./IO.scss";

export function IOEditor(props: IOEditorProps) {
  props = { ...props };

  props.className = classNames(props.className, "IO");

  const { className, value, label, name, seedInputWithCurrentValue, ...rest } = props;

  const inputRef = useRef<any>();
  const [inputValue, setInputValue] = useState<string>("");
  const [inputFocus, setInputFocus] = useState(false);

  function onInputChange(value: string) {
    setInputValue(value);

    props.onInputChange?.(value);
  }

  function onOutputFocus(e: InputFocusEvent) {
    e.preventDefault();

    inputRef.current.focus();
  }

  function onInputFocus(e: InputFocusEvent) {
    setInputFocus(true);

    // When editing begins (onInputFocus), put the underlying value into the input.
    if (seedInputWithCurrentValue) {
      setInputValue(value);
    }

    props.onFocus && props.onFocus(e);
  }

  function onInputBlur(e: InputFocusEvent) {
    setInputValue("");
    setInputFocus(false);

    props.onBlur && props.onBlur(e);
  }

  return (
    <Container {...props}>
      <Row className={className}>
        <TextField
          {...rest}
          label=""
          className={classNames("IO-input")}
          value={inputValue}
          placeholder="Date range, e.g. Q4"
          onChange={onInputChange}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          input={inputRef}
          dataTest={`${props.dataTest}.input`}
          suppressStatusMessages
        />
        <TextField
          {...rest}
          label=""
          className="IO-output"
          value={value}
          onBlur={emptyFn}
          onFocus={onOutputFocus}
          tabIndex={-1}
          dataTest={`${props.dataTest}.output`}
          clearable
          suppressStatusMessages
        />
      </Row>
    </Container>
  );
}

export interface IOEditorProps extends TextFieldProps {
  onInputChange?: (string: string) => void;
  seedInputWithCurrentValue?: boolean;
}
