import { emptyArrowFn } from "@/utils";
import { useState } from "react";
import { TokenStore } from "services/TokenStore";
import { firstLine, secondLine } from "./DisclaimerModal";

type DisclaimerAgreedType = "NOT_SEEN" | "AGREED";

/**
 * This will hold a promise that is created only once and will resolve either
 * when the user clicks 'OK' on the disclaimer or if the disclaimer has already
 * been displaced to the user
 */
function Deferred() {
  let resolve = emptyArrowFn;
  const promise = new Promise<void>((res) => {
    resolve = res;
  });
  return { promise, resolve };
}
const { promise, resolve } = Deferred();

export function useDismissableDisclaimer(ctradeUrl: string, updateToken: string, offerRepToken: string) {
  const store = new TokenStore("OfferRepDisclaimerAgreed");
  const isAlreadyAgreed = store.get() === "AGREED";
  const [show, setShow] = useState(false);
  const [disclaimerAgreedState, setDisclaimerAgreedState] = useState<DisclaimerAgreedType>(
    isAlreadyAgreed ? "AGREED" : "NOT_SEEN"
  );

  const callConfirmEndpoint = async () => {
    return fetch(`${ctradeUrl}/offerrep/${offerRepToken}/confirm`, {
      method: "POST",
      body: JSON.stringify({
        updateToken,
        disclaimer: `${firstLine} ${secondLine}`,
      }),
      headers: {
        "X-API-Version": "3",
        "Content-Type": "application/json",
      },
    });
  };

  const onDismiss = async () => {
    setShow(false);
    store.set("AGREED");
    setDisclaimerAgreedState("AGREED");
    resolve();
    const result = await callConfirmEndpoint();
    if (!result.ok) {
      // TODO: Log error here
      console.error("Unable to call /confirm endpoint for OfferRep disclaimer", result);
    }
  };

  const onPresentDisclaimer = () => {
    if (disclaimerAgreedState === "NOT_SEEN") {
      setShow(true);
    }
    if (disclaimerAgreedState === "AGREED") {
      resolve();
    }
    return promise;
  };

  return {
    show,
    onDismiss,
    onPresentDisclaimer,
    disclaimerAgreedState,
  };
}
