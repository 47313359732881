import Split from "react-split";
import { useEffect, ReactNode, useRef } from "react";
import { observer } from "mobx-react";
import { CSSTransition } from "react-transition-group";
import { Icon } from "@/components";
import { slideout } from "@/models";
import "./Slideout.scss";

function Slideout(props: Props) {
  let { dataTest, content, hide, adjustable } = props;
  const container = useRef<any>(null);
  dataTest = `${dataTest}-slideout`;

  useEffect(addEventListeners, [slideout.open]);

  const KEYBOARD_EVENT_HANDLER = {
    Escape: (e: KeyboardEvent) => {
      e.preventDefault();

      hide?.();
    },
  } as KeyboardEventMap;

  if (adjustable) {
    return (
      <Split className="slideout" ref={container} data-test={dataTest} sizes={[70, 30]} gutterSize={5}>
        <div className="slideout-backdrop" onClick={hide} />
        <div className="slideout-container">
          <div className="slideout-content">{content}</div>
          <Icon icon="close" onClick={hide} />
        </div>
      </Split>
    );
  } else {
    return (
      <div className="slideout" ref={container} data-test={dataTest}>
        <div className="slideout-backdrop fix-slideout-position" onClick={hide} />
        <div className="slideout-container fix-slideout-position">
          <div className="slideout-content">{content}</div>
          <Icon icon="close" onClick={hide} />
        </div>
      </div>
    );
  }

  function addEventListeners() {
    window.addEventListener("keydown", keyDownHandler);

    function keyDownHandler(e: KeyboardEvent) {
      const handler = KEYBOARD_EVENT_HANDLER[e.key];

      if (handler) handler(e);
    }

    function removeEventListeners() {
      window.removeEventListener("keydown", keyDownHandler);
    }

    return removeEventListeners;
  }
}

function SlideoutTransition() {
  return (
    <CSSTransition {...CSSTransitionProps} in={slideout.open} unmountOnExit>
      <Slideout {...slideout.props} hide={slideout.hide} />
    </CSSTransition>
  );
}

const CSSTransitionProps = {
  timeout: { appear: 0, exit: 400, enter: 400 },
  classNames: "slideout",
};

const Observer = observer(SlideoutTransition);

export { Observer as Slideout };

interface Props {
  hide?: () => void;
  open?: boolean;
  content?: ReactNode;
  status?: Status;
  dataTest?: string;
  adjustable?: boolean;
}

type KeyboardEventMap = RecordOf<(e: KeyboardEvent) => void>;

export type SlideoutProps = Props;
