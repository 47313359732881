import React, { useRef, useState } from "react";
import { useShallowStateOld, emptyFn } from "@/utils";
import { tradeAPI } from "@/apis";
import { OrderTemplate } from "@/models";
import { SelectField, SelectFieldProps, InputFocusEvent } from "@/components";
import { OrderCreationTypeEvents, usageMetrics } from "@/services/UsageMetrics";

export function OrderTemplateSearchEditor(props: Props) {
  props = { ...props };

  props.className = `${props.className} OrderTemplateSearch`;
  props.dataTest = props.dataTest || "orderTemplateSearch";

  const { searchMessages = defaultSearchMessages, orderSearchQuery } = props;
  const [data, setData] = useState<SelectFieldProps["data"]>(undefined);
  const [, setDropdownStatus, dropdownStatus] = useShallowStateOld<Status>({
    message: searchMessages?.initial,
  });

  const cancel = useRef(emptyFn);

  async function onFilter(search: string) {
    cancel.current();

    setData(undefined);
    setDropdownStatus({ message: undefined, loading: false, search });

    if (!search) {
      setDropdownStatus({ message: searchMessages?.initial });

      return;
    }

    setDropdownStatus({ message: searchMessages?.searching, loading: true });

    const promise = tradeAPI.searchOrderTemplates(search, orderSearchQuery);

    cancel.current = promise.cancel;

    const res = await promise;

    if (res.cancelled) return;

    if (!res.data || !res.data.length) {
      setData(undefined);
      setDropdownStatus({ message: searchMessages?.noData, loading: false });

      return;
    }

    const data = res.data.map((data) => new OrderTemplate(data));

    setData(data);
    usageMetrics.trackEvent(OrderCreationTypeEvents.TEMPLATE_SELECTED);
    setDropdownStatus({ message: undefined, loading: false });
  }

  function onBlur(e: InputFocusEvent) {
    cancel.current();

    setData(undefined);
    setDropdownStatus({
      message: searchMessages?.initial,
      loading: false,
    });

    props.onBlur && props.onBlur(e);
  }

  return (
    <SelectField
      {...props}
      value={props.value}
      data={data}
      placeholder="Search for Order Type, Account, Cargo Type(s), Route"
      dropdownStatus={dropdownStatus}
      onFilter={onFilter}
      onBlur={onBlur}
      dataTest={`${props.dataTest}.value`}
      unclearable
    />
  );
}

const defaultSearchMessages = {
  initial: "Waiting for your input",
  searching: "Searching for order templates",
  noData: "No order templates found",
} as Props["searchMessages"];

interface Props extends SelectFieldProps {
  orderSearchQuery?: string;
  searchMessages?: {
    initial: string;
    searching: string;
    noData: string;
  };
}

export type OrderTemplateEditorProps = Props;
