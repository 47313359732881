import { IDistributionListViewModel, ISearchUserView } from "sharedFolder/Models/IDetails";
import { ISearchUser } from "../../api/models/ISearchUser";
import { mapSearchUser } from "sharedFolder/mappers/mapSearchUser";
import { mapSearchUserToDistributionList } from "sharedFolder/mappers/mapSearchUserToDistributionList";
import { mapRecipientList } from "sharedFolder/mappers/mapRecipientList";
import { IDistributeOrder } from "api/negotiations/models/ICirculateOrder";
import { IKnownUser, IRecipient } from "api/orders/models/IDistributionList";
import { tradeAPI } from "@/apis";

let Refactor: Await<ReturnType<typeof resolveRefactorExports>>;
async function resolveRefactorExports() {
  return {
    ...(await import("___REFACTOR___/services/EntityStorage")),
    ...(await import("___REFACTOR___/utils/utils")),
  };
}
resolveRefactorExports().then((exports) => (Refactor = exports));

export const distributionService = (ctradeUrl: string) => {
  const searchAsGET = async (term: string): Promise<ISearchUser[]> => {
    const url = `${ctradeUrl}/clddus/usersandowners/${term}`;
    const result = await fetch(url, {
      method: "GET",
      headers: {
        "X-API-Version": "3",
        "Content-Type": "application/json",
      },
    });
    if (result.ok) {
      return (await result.json()) as ISearchUser[];
    }
    if (result.status === 422) {
      return [];
    }
    throw new Error(`Unable to GET ${url}`);
  };
  const searchAsPOST = async (terms: string[]): Promise<ISearchUser[]> => {
    const url = `${ctradeUrl}/clddus/usersandowners`;
    const result = await fetch(url, {
      method: "POST",
      headers: {
        "X-API-Version": "3",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(terms),
    });
    if (result.ok) {
      return (await result.json()) as ISearchUser[];
    }
    if (result.status === 422) {
      return [];
    }
    throw new Error(`Unable to GET ${url}`);
  };
  return {
    sendDistributionList: async (
      orderId: string,
      distribution: IDistributionListViewModel,
      updateToken: string,
      actor: IKnownUser,
      currentlyLoggedInUser?: IRecipient
    ) => {
      const recipients = mapRecipientList.toApi(distribution);
      const distributionList = currentlyLoggedInUser ? recipients.concat(currentlyLoggedInUser) : recipients;

      const attachActorToRecipientIfRequired = (recipient: IRecipient) => {
        // Delete the additionalData on the recipient, which is
        // there to preserve view data when mapping to IRecipient and back
        delete recipient.additionalData;
        if (!recipient.actor) {
          return { ...recipient, actor };
        }
        return recipient;
      };

      const distributionListToPost = {
        updateToken: updateToken,
        distributionList: distributionList.map(attachActorToRecipientIfRequired),
      };
      return fetch(`${ctradeUrl}/orders/${orderId}/distribution`, {
        method: "POST",
        body: JSON.stringify(distributionListToPost),
        headers: {
          "X-API-Version": "3",
          "Content-Type": "application/json",
        },
      });
    },
    distributeOrder: async (orderId: string, distribution: IDistributeOrder) => {
      const teamMembers = distribution?.distributionList
        .map((member) => String(member?.knownUser?.systemUserId))
        .filter((member) => !!member);
      const res = await tradeAPI.getNegotiations(orderId);
      const negs = res.data;

      return fetch(`${ctradeUrl}/orders/${orderId}/distribution/send`, {
        method: "POST",
        body: JSON.stringify(distribution),
        headers: {
          "X-API-Version": "3",
          "Content-Type": "application/json",
        },
      });
    },
    searchSingleTerm: async (term: string): Promise<IDistributionListViewModel> => {
      return mapSearchUserToDistributionList.toView(await searchAsGET(term));
    },
    searchMultipleTerms: async (emailCollection: string[]): Promise<ISearchUserView[]> => {
      const result = await searchAsPOST(emailCollection);
      return result.map(mapSearchUser.toView);
    },
  };
};
