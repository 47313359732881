export class UndoHistory<T> {
  get value(): T {
    return this.history[this.index];
  }

  constructor(initialValue: T) {
    this.history = [initialValue];
    this.index = 0;

    this.do = (value: T) => {
      if (this.blocked) return;

      if (this.index < this.history.length - 1) {
        this.history = this.history.slice(0, this.index + 1).concat(value);
      } else {
        this.history = this.history.concat(value);
      }

      this.index = this.index + 1;
    };

    this.undo = () => {
      this.index = max(0, this.index - 1);
    };

    this.redo = () => {
      this.index = min(this.index + 1, this.history.length - 1);
    };
  }

  block = () => {
    this.blocked = true;
  };

  unblock = () => {
    this.blocked = false;
  };
}

const { min, max } = Math;

export interface UndoHistory<T> {
  history: T[];
  index: number;
  blocked: boolean;
  do: (value: T) => void;
  undo: () => void;
  redo: () => void;
}
