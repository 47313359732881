import * as api from "../../api/negotiations/models";
import { IUnitValueView, INotesView } from "../Models/IDetails";
import { IMapper, withNullable } from "./map";
import {
  FreightRateUnit,
  HireRateUnit,
  CleaningPriceUnit,
  SupplyPriceUnit,
  DemurrageUnit,
  BallastBonusUnit,
  HireRateUnitDisplay,
  CleaningPriceUnitDisplay,
  SupplyPriceUnitDisplay,
  FreightRateUnitDisplay,
  DemurrageUnitDisplay,
  BallastBonusUnitDisplay,
} from "../../api/negotiations/models";
import { formatCurrency } from "sharedFolder/display/currency";

type UnitValueTypes =
  | api.DemurrageUnit
  | api.FreightRateUnit
  | api.HireRateUnit
  | api.CleaningPriceUnit
  | api.SupplyPriceUnit
  | api.IBallastBonus;

const mapUnitValue: IMapper<api.IUnitValue<UnitValueTypes>, IUnitValueView> = {
  toApi: (viewModel: IUnitValueView) => {
    return {
      display: viewModel.display,
      notes: viewModel.notes,
      value: (viewModel.value && parseFloat(viewModel.value)) || undefined,
    };
  },
  toView: (apiModel: api.IUnitValue<UnitValueTypes>) => {
    return {
      display: apiModel.display || "",
      unit: apiModel.unit ? String(apiModel.unit) : "",
      unitDisplay: apiModel.display || "",
      value: apiModel.value ? String(apiModel.value) : "",
      notes: apiModel.notes || "",
    };
  },
  emptyViewModel: {
    unit: "",
    unitDisplay: "",
    value: "",
    notes: "",
  },
};

const mapFr: IMapper<api.IUnitValue<FreightRateUnit>, IUnitValueView> = {
  toApi: (viewModel: IUnitValueView) => {
    return {
      unit: mapStringToFreightRateUnit(viewModel.unit),
      value: mapStringValue(viewModel.value),
      display: viewModel.display,
      notes: viewModel.notes,
    } as api.IUnitValue<FreightRateUnit>;
  },
  toView: (apiModel: api.IUnitValue<UnitValueTypes>) => {
    const freightRate = apiModel as api.IUnitValue<FreightRateUnit>;
    return {
      ...mapUnitValue.toView(apiModel),
      display: apiModel.value
        ? `$${formatCurrency(apiModel)} ${FreightRateUnitDisplay[freightRate.unit as FreightRateUnit]} ${apiModel.notes || ""}`
        : "",
    };
  },
  emptyViewModel: mapUnitValue.emptyViewModel,
};
export const mapFreightRate = withNullable(mapFr);

const mapHr: IMapper<api.IUnitValue<HireRateUnit>, IUnitValueView> = {
  toApi: (viewModel: IUnitValueView) => {
    return {
      unit: mapStringToHireRateUnit(viewModel.unit),
      value: mapStringValue(viewModel.value),
      display: viewModel.display,
      notes: viewModel.notes,
    } as api.IUnitValue<HireRateUnit>;
  },
  toView: (apiModel: api.IUnitValue<UnitValueTypes>) => {
    const hireRateUnitValue = apiModel as api.IUnitValue<HireRateUnit>;
    return {
      ...mapUnitValue.toView(apiModel),
      display: apiModel.value
        ? `$${formatCurrency(apiModel)} ${HireRateUnitDisplay[hireRateUnitValue.unit as HireRateUnit]} ${apiModel.notes || ""}`
        : "",
    };
  },
  emptyViewModel: mapUnitValue.emptyViewModel,
};
export const mapHireRate = withNullable(mapHr);

const mapperCleaningPrice: IMapper<api.IUnitValue<CleaningPriceUnit>, IUnitValueView> = {
  toApi: (viewModel: IUnitValueView) => {
    return {
      unit: mapStringToCleaningPriceUnit(viewModel.unit),
      value: mapStringValue(viewModel.value),
      display: viewModel.display,
      notes: viewModel.notes,
    } as api.IUnitValue<CleaningPriceUnit>;
  },
  toView: (apiModel: api.IUnitValue<UnitValueTypes>) => {
    const cleaningPriceUnitValue = apiModel as api.IUnitValue<CleaningPriceUnit>;
    return {
      ...mapUnitValue.toView(apiModel),
      display: apiModel.value
        ? `$${formatCurrency(apiModel)} ${CleaningPriceUnitDisplay[cleaningPriceUnitValue.unit as CleaningPriceUnit]} ${
            apiModel.notes || ""
          }`
        : "",
    };
  },
  emptyViewModel: mapUnitValue.emptyViewModel,
};
export const mapCleaningPrice = withNullable(mapperCleaningPrice);

const mapperSupplyPrice: IMapper<api.IUnitValue<SupplyPriceUnit>, IUnitValueView> = {
  toApi: (viewModel: IUnitValueView) => {
    return {
      unit: mapStringToSupplyPriceUnit(viewModel.unit),
      value: mapStringValue(viewModel.value),
      display: viewModel.display,
      notes: viewModel.notes,
    } as api.IUnitValue<SupplyPriceUnit>;
  },
  toView: (apiModel: api.IUnitValue<UnitValueTypes>) => {
    const supplyPriceUnitValue = apiModel as api.IUnitValue<SupplyPriceUnit>;
    return {
      ...mapUnitValue.toView(apiModel),
      display: apiModel.value
        ? `$${formatCurrency(apiModel)} ${SupplyPriceUnitDisplay[supplyPriceUnitValue.unit as SupplyPriceUnit]} ${
            apiModel.notes || ""
          }`
        : "",
    };
  },
  emptyViewModel: mapUnitValue.emptyViewModel,
};
export const mapSupplyPrice = withNullable(mapperSupplyPrice);

const mapD: IMapper<api.IUnitValue<DemurrageUnit>, IUnitValueView> = {
  toApi: (viewModel: IUnitValueView) => {
    return {
      unit: mapStringToDemurrageUnit(viewModel.unit),
      value: mapStringValue(viewModel.value),
      display: viewModel.display,
      notes: viewModel.notes,
    } as api.IUnitValue<DemurrageUnit>;
  },
  toView: (apiModel: api.IUnitValue<UnitValueTypes>) => {
    const demurrage = apiModel as api.IUnitValue<DemurrageUnit>;
    return {
      ...mapUnitValue.toView(apiModel),
      display: apiModel.value
        ? `$${formatCurrency(apiModel)} ${DemurrageUnitDisplay[demurrage.unit as DemurrageUnit]} ${apiModel.notes || ""}`
        : "",
    };
  },
  emptyViewModel: mapUnitValue.emptyViewModel,
};

export const mapDemurrage = withNullable(mapD);

const mapBB: IMapper<api.IUnitValue<BallastBonusUnit>, IUnitValueView> = {
  toApi: (viewModel: IUnitValueView) => {
    return {
      unit: viewModel.unit !== "" ? mapStringToBallastBonusUnit(viewModel.unit) : viewModel.unit,
      value: mapStringValue(viewModel.value),
      display: viewModel.display,
      notes: viewModel.notes,
    } as api.IUnitValue<BallastBonusUnit>;
  },
  toView: (apiModel: api.IUnitValue<UnitValueTypes>) => {
    const ballastBonus = apiModel as api.IUnitValue<BallastBonusUnit>;
    return {
      ...mapUnitValue.toView(apiModel),
      display: apiModel.value
        ? `$${formatCurrency(apiModel)} ${BallastBonusUnitDisplay[ballastBonus.unit as BallastBonusUnit]} ${apiModel.notes || ""}`
        : "",
    };
  },
  emptyViewModel: mapUnitValue.emptyViewModel,
};
export const mapBallastBonus = withNullable(mapBB);

const mapN: IMapper<string, INotesView> = {
  toApi: (viewModel: INotesView) => {
    return viewModel.value;
  },
  toView: (apiModel: string) => {
    return { value: apiModel, display: apiModel };
  },
  emptyViewModel: { value: "", display: "" },
};
export const mapNotes = withNullable(mapN);

const mapStringToFreightRateUnit = (unit?: string): FreightRateUnit | undefined => {
  if (!unit) {
    return undefined;
  }

  switch (unit.toLowerCase()) {
    case "permt":
      return "PerMT";
    case "lump sum":
      return "LumpSum";
    case "lumpsum":
      return "LumpSum";
    default:
      throw new Error(`Freight Rate: unable to parse ${unit.toLowerCase()}`);
  }
};

const mapStringToHireRateUnit = (unit?: string): HireRateUnit | undefined => {
  if (!unit) {
    return undefined;
  }

  switch (unit.toLowerCase()) {
    case "perday":
      return "PerDay";
    case "lumpsum":
      return "LumpSum";
    default:
      throw new Error(`HireRate: unable to to parse ${unit.toLowerCase()}`);
  }
};

const mapStringToCleaningPriceUnit = (unit?: string): CleaningPriceUnit | undefined => {
  if (!unit) {
    return undefined;
  }

  switch (unit.toLowerCase()) {
    case "perday":
      return "PerDay";
    case "lumpsum":
      return "LumpSum";
    case "permt":
      return "PerMT";
    default:
      throw new Error(`ILOHC: unable to to parse ${unit.toLowerCase()}`);
  }
};

const mapStringToSupplyPriceUnit = (unit?: string): SupplyPriceUnit | undefined => {
  if (!unit) {
    return undefined;
  }

  switch (unit.toLowerCase()) {
    case "perday":
      return "PerDay";
    case "lumpsum":
      return "LumpSum";
    case "permt":
      return "PerMT";
    default:
      throw new Error(`CVE: unable to to parse ${unit.toLowerCase()}`);
  }
};
const mapStringToDemurrageUnit = (unit?: string): DemurrageUnit => {
  const result = unit === "PerDay" || unit === "LumpSum" ? unit : undefined;
  if (!result) throw new Error(`DemurrageUnit: unable to to parse ${unit}`);
  return result;
};

const mapStringValue = (value?: string | null): number | undefined => {
  const result = !value ? undefined : parseFloat(value);
  return result;
};

const mapStringToDemurrageValue = (value?: string | null): number | undefined => {
  const result = !value ? undefined : parseFloat(value);
  return result;
};

const mapStringToBallastBonusUnit = (unit?: string): BallastBonusUnit => {
  const result = unit === "PerDay" || unit === "LumpSum" ? unit : undefined;
  if (!result) throw new Error(`BallastBonusUnit : unable to to parse ${unit}`);
  return result;
};
