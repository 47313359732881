import React, { createContext, useContext } from "react";

interface IProps {
  children: React.ReactNode;
  isEditable: boolean;
}

const EditorFieldContext = createContext<boolean>(true);

function EditorFieldProvider({ children, isEditable }: IProps) {
  return <EditorFieldContext.Provider value={isEditable}>{children}</EditorFieldContext.Provider>;
}

function useEditorFieldProvider() {
  const context = useContext(EditorFieldContext);
  return Boolean(context);
}

export { EditorFieldProvider, useEditorFieldProvider };
