import { useEffect, useState } from "react";
import { IEmailListPUT, IEmailList } from "../../services/settings";
import { useAPIFetch } from "./useAPIFetch";
import { IGenericErrorObject, renderDistributionEmailError } from "./DistributionListPanel";
import { InlineSingleTextEditor } from "./InlineSingleTextEditor";
import { isEmail } from "validator";

interface IDistributionEmailEditor {
  currentEmails: string[];
  email: string;
  item: IEmailList;
  onEditorClose: () => void;
  updateList: (updatedItem: IEmailList) => void;
}

export function DistributionEmailEditor(props: IDistributionEmailEditor) {
  const { currentEmails, email, item, onEditorClose, updateList } = props;
  const [updatedEmail, setUpdatedEmail] = useState("");
  const [updatedList, setUpdatedList] = useState<null | IEmailListPUT>(null);

  const updateAction = useAPIFetch<IEmailListPUT>(`/settings/emaillists/${item.id}`, "PUT", updatedList);

  useEffect(() => {
    // on item changes from the outside, sync the local email with the passed in prop so it's up to date when the editor is open
    setUpdatedEmail(email);
  }, [email]);

  useEffect(() => {
    function softUpdateGroup() {
      updateList({
        ...item,
        values: [updatedEmail, ...item.values.filter((value) => value !== email)],
      });
    }
    // on successful update
    if (updateAction && updateAction.response) {
      // update our local distribution state
      softUpdateGroup();
      onEditorClose();
    }
  }, [email, item, updatedEmail, onEditorClose, updateAction, updateList]);

  const [validationErrors, setValidationErrors] = useState<null | IGenericErrorObject>(null);

  function submitEditForm() {
    // this is right now identical to the errors object in NewDistributionGroupForm.tsx, maybe it should be different?
    const errors: IGenericErrorObject = {
      invalidEmail: !isEmail(updatedEmail),
      emailEmpty: !updatedEmail,
      emailAlreadyExist: currentEmails.indexOf(updatedEmail) >= 0,
      emailTooLong: updatedEmail.length > 100,
    };
    if (Object.keys(errors).some((key) => errors[key])) {
      setValidationErrors(errors);
    } else {
      setValidationErrors(null);
      setUpdatedList({
        data: {
          name: item.name,
          values: [updatedEmail, ...item.values.filter((value) => value !== email)],
        },
        updateToken: item.updateToken,
      });
    }
  }
  return (
    <InlineSingleTextEditor
      disabled={updateAction.fetching || !updatedEmail || updatedEmail === email}
      errorMessage={
        validationErrors || updateAction.error
          ? renderDistributionEmailError({
              apiError: updateAction.error,
              validationErrors,
            })
          : null
      }
      onClose={() => {
        onEditorClose();
        // reset the local group name state
        setUpdatedEmail(item.name);
      }}
      onSubmit={() => {
        submitEditForm();
      }}
      placeholder="Edit Email"
      setValue={(value: string) => setUpdatedEmail(value)}
      value={updatedEmail}
    />
  );
}
