import { DataModel } from "../DataModel";
import { CLDDU } from "../CLDDU";
import { Desk } from "../Desk";
import { User } from "../User";

export class CreatedByAndDesk extends DataModel {
  constructor(data: Partial<CreatedByAndDesk>) {
    super(data);
  }

  toString() {
    const name = this.createdBy?.toString();
    const desk = this.desk?.name ? `| ${this.desk?.name}` : "";

    if (!name && !desk) return "";

    return `${name} ${desk}`;
  }
}

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

export interface CreatedByAndDesk {
  createdBy: User | CLDDU;
  desk: Desk;
}
