import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ITracking, Interaction } from "./ITracking";

/** Abstraction for logging analytics events */
export class AppInsightsTrackingProvider implements ITracking {
  constructor(private appInsights: ApplicationInsights) {
    this.pageView = this.pageView.bind(this);
    this.componentView = this.componentView.bind(this);
    this.interaction = this.interaction.bind(this);
    this.authEvent = this.authEvent.bind(this);
  }

  /**
   * Track page views
   * Logs the given URI, stripping IDs (stripped IDs are logged as properties)
   */
  public pageView(uri: string, name: string) {
    const { sanitizedUri, guids } = this.stripGuids(uri);
    this.appInsights.trackPageView({
      pageType: "route",
      name,
      uri: sanitizedUri,
      properties: guids,
    });
  }

  /**
   * Log the visibility of a named component
   * @param name Name of active component
   */
  public componentView(componentName: string, url: string) {
    const { sanitizedUri, guids } = this.stripGuids(url);
    this.appInsights.trackPageView({
      pageType: "component",
      name: componentName,
      uri: sanitizedUri,
      properties: guids,
    });
  }

  /**
   * Track an interaction; typically a button press etc that does not alter the route
   * @param name Name or identifier of the interaction
   * @param properties Additional properties
   */
  public interaction(
    name: Interaction,
    properties?: {
      [key: string]: any;
    }
  ) {
    this.appInsights &&
      this.appInsights.trackEvent({
        name,
        properties,
      });
  }

  /**
   * Track an exception
   */
  public trackException(error: Error) {
    /*
      Severity levels:
        Verbose = 0,
        Information = 1,
        Warning = 2,
        Error = 3,
        Critical = 4,
    */

    this.appInsights.trackException({ exception: error, severityLevel: 3 });
  }

  /**
   * Track an authentication event
   * @param type Type of auth event
   * @param userId User ID (if known)
   * @param companyId Company ID (if known)
   */
  public authEvent(type: "login" | "logout" | "expiry", userId?: string, companyId?: string) {
    switch (type) {
      case "login":
        userId && this.appInsights.setAuthenticatedUserContext(userId, companyId);
        break;
      case "logout": // TODO: track logout / token expiry events
      case "expiry":
        this.appInsights && this.appInsights.clearAuthenticatedUserContext();
        break;
    }
  }

  private stripGuids(uri: string): {
    sanitizedUri: string;
    guids?: { [key: string]: any };
  } {
    // strip all GUIds in URIs, replacing them with friendly "{id}"
    const guids = uri.match(/[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/);
    return {
      sanitizedUri: guids ? guids.reduce((p, c, i) => p.replace(c, `{id${i + 1}}`), uri) : uri,
      guids: guids
        ? guids.reduce((p, c, i) => {
            p[`id${i + 1}`] = c;
            return p;
          }, {} as { [key: string]: any })
        : undefined,
    };
  }
}
