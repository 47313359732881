import React from "react";
import { Duration, DurationProps } from "___REFACTOR___/models";
import { StatusMessages, NumberField, SelectField, Field, TextareaField } from "___REFACTOR___/components";
import { Group } from "../../Group";
import { Row } from "../../Row";
import "./Duration";
import { cleanValue } from "___REFACTOR___/utils";

function DurationEditor(props: DurationEditor.Props) {
  const { value, status, ...rest } = props;
  const { min, max, unit } = value || {};

  return (
    <Group {...props} editorType="Duration" hidden={props.hidden}>
      <Row>
        <NumberField
          {...rest}
          label="Duration / Min"
          desc="Duration min"
          className="Duration-min"
          placeholder="e.g. 3"
          status={status?.childMap ? status?.childMap?.min : status}
          value={min}
          onChange={onChange.bind(null, "min")}
          dataTest={`${props.dataTest}.min`}
          suppressStatusMessages
        />
        <NumberField
          {...rest}
          label="Max"
          desc="Duration max"
          placeholder="e.g. 4"
          className="Duration-max"
          status={status?.childMap ? status?.childMap?.max : status}
          value={max}
          onChange={onChange.bind(null, "max")}
          dataTest={`${props.dataTest}.max`}
          suppressStatusMessages
        />
        <SelectField
          {...rest}
          label="Unit"
          desc="Duration unit"
          className="Duration-unit"
          data={OPTIONS}
          status={status?.childMap?.unit}
          value={unit || DurationProps.prototype.JSONDefaults?.unit}
          onChange={onChange.bind(null, "unit")}
          dataTest={`${props.dataTest}.unit`}
          unclearable
          nonFilterSelect
          suppressStatusMessages
        />
      </Row>
      <StatusMessages status={status} />
      <TextareaField
        {...props}
        label="Notes"
        desc="Duration notes"
        value={value?.notes || ""}
        onChange={onNotesChange}
        status={status?.childMap?.notes}
        dataTest={`${props.dataTest}.notes`}
        hidden={!value}
      />
    </Group>
  );

  function onChange(name: keyof Duration, inputValue: NumberField.Input.Value) {
    const next = { ...value, [name]: inputValue } as Duration;
    const { notes, ...rest } = next;
    const isEmpty = !cleanValue(rest);

    if (isEmpty) return props.onChange(undefined);

    props.onChange?.(new Duration(next));
  }

  function onNotesChange(notes: TextareaField.Input.Value) {
    if (typeof notes !== "string") return;

    props.onChange?.(new Duration({ ...value, notes }));
  }
}

const OPTIONS = ["Days", "Months"];

export { DurationEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace DurationEditor {
  export interface Props extends Field.Props {
    value: Value;
    onChange: (value: Value) => void;
  }

  export type Value = Duration | undefined;
}
