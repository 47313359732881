import * as api from "../../../api/orders/models";
import { mapOrderSummary } from "./mapOrderSummary";
import { IOrder } from "../../models/IOrder";
import { IPermittedMethods } from "sharedFolder/Models/PermittedMethods";
import { permissionsFromJwt } from "sharedFolder/apis/permissionsFromJwt";
import { mapRecipientList } from "sharedFolder/mappers/mapRecipientList";
import { mapAttachment, mapNegAttachment } from "negotiations/services/mapping/view/mapAttachment";
import { mapCommission } from "sharedFolder/mappers/mapCommission";

export function mapOrder(order: api.IOrder): IOrder {
  const summary = mapOrderSummary(order);
  return {
    ...summary,
    order: {
      laycan: order.details.laycan?.display || "",
      chartererAccount: order.details.chartererAccount.display || "",
      addressCommission: mapCommission.toViewOrEmpty(order.details.addressCommission)?.display || "",
      brokerCommission: mapCommission.toViewOrEmpty(order.details.brokerCommission)?.display || "",
      cargoType: (order.details.cargoType && order.details.cargoType.display) || "",
    },
    details: {
      cargoSize: (order.details.cargoSize && order.details.cargoSize.display) || "",
      coaNotes: (order.details.coaNotes && order.details.coaNotes) || "",
      deliveryLocation: (order.details.deliveryLocation && order.details.deliveryLocation.display) || "",
      dischargeLocation: (order.details.dischargeLocation && order.details.dischargeLocation.display) || "",
      duration: (order.details.duration && order.details.duration.display) || "",
      liftings: (order.details.liftings && order.details.liftings.display) || "",
      loadLocation: (order.details.loadLocation && order.details.loadLocation.display) || "",
      period: (order.details.period && order.details.period.display) || "",
      redeliveryLocation: (order.details.redeliveryLocation && order.details.redeliveryLocation.display) || "",
      tctNotes: (order.details.tctNotes && order.details.tctNotes) || "",
      vesselSize: (order.details.vesselSize && order.details.vesselSize.display) || "",
      viaLocation: (order.details.viaLocation && order.details.viaLocation.display) || "",
      voyNotes: (order.details.voyageNotes && order.details.voyageNotes) || "",
      isDealCapture: order.details.isDealCapture,
    },
    updateToken: order.updateToken,
    permittedMethods: order.updateToken ? permissionsFromJwt<IPermittedMethods>(order.updateToken) : [],
    brokers: order.brokers,
    sharedWith: order.sharedWith,
    charterer: order.charterer,
    groupChats: order.groupChats,
    teamMembers: order.teamMembers,
    id: order.id,
    distributionLists: mapRecipientList.toViewOrEmpty(order.distributionList),
    orderAttachments: order.attachments.map(mapAttachment),
    negsAttachments: order.negAttachments.map(mapNegAttachment),
    status: order.status,
  };
}
