import { LABEL_MAP } from "___REFACTOR___/constants";
import { Location, LocationProps } from "../Location";

class DeliveryLocation extends Location {
  static label = LABEL_MAP.deliveryLocation;
}

class DeliveryLocationProps extends LocationProps {}

DeliveryLocation.prototype.Props = DeliveryLocationProps;
DeliveryLocationProps.prototype.label = LABEL_MAP.deliveryLocation;

export { DeliveryLocation, DeliveryLocationProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface DeliveryLocation {
  Props: typeof DeliveryLocationProps;
  _: DeliveryLocationProps;
}
