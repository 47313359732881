import React, { useState, useEffect } from "react";
import moment from "moment";
import { IVesselSearch, IVesselForm } from "sharedFolder/Models/IVessel";
import { VesselSearchPicker } from "sharedFolder/components/common/VesselSearchPicker/VesselSearchPicker";
import DatePicker from "sharedFolder/components/common/DatePicker/DatePicker";
import { MinimalConfigurationContext } from "__legacy/sharedFolder/MinimalConfigurationContext";
import { UserContext } from "__legacy/dashboard/contexts/UserProvider";
import { useVesselNominate } from "sharedFolder/contexts/vesselnominate-context";
import { IVesselView } from "sharedFolder/Models/IDetails";
import TextArea from "sharedFolder/components/common/TextArea/TextArea";
import styles from "./VesselNominate.module.scss";

interface IVesselNominateProps {
  onVesselChanged: (vessel: IVesselForm | undefined) => void;
  nominatedVessels?: IVesselView[];
}

interface IVesselNominateState {
  vessel?: IVesselSearch;
  vesselAlreadySelected: boolean;
  dateIsInThePast: boolean;
  ownerChain?: string;
  itinerary?: string;
  additionalNotes?: string;
  vesselDescription?: string;
  speedAndConsumption?: string;
  eta?: string;
  isValid?: boolean;
}

export const VesselNominate: React.FC<IVesselNominateProps> = (props) => {
  const { onVesselChanged } = props;
  const { showAdditionalNotes, postFixUrl } = useVesselNominate();

  const [vesselForm, setVesselForm] = useState<IVesselNominateState>({
    vesselAlreadySelected: false,
    dateIsInThePast: false,
  });

  useEffect(() => {
    // HACKY - hacky way to remove the icon since the styles added in the datePicker add our icon but dont remove ui-fabric icon
    const icon = document.querySelector("i.icon--date-range");
    if (icon) {
      icon.innerHTML = "";
    }
  }, []);

  const handleVesselSelected = (selectedVessel: IVesselSearch | undefined) => {
    // clear form when a vessel is selected or removed
    const newState = {
      ...vesselForm,
      eta: undefined,
      itinerary: "",
      speedAndConsumption: "",
      vesselDescription: "",
      ownerChain: "",
      vessel: selectedVessel,
    };

    setVesselForm({
      ...newState,
      dateIsInThePast: false,
      vesselAlreadySelected: false,
    });
    onVesselChanged({
      ...newState,
      isValid: Boolean(selectedVessel),
    });
  };

  const handleVesselAlreadySelected = () => {
    setVesselForm({ ...vesselForm, vesselAlreadySelected: true });
    onVesselChanged({ ...vesselForm, isValid: false });
  };

  const handleOwnerChainChanged = (event: React.FormEvent<HTMLTextAreaElement>) => {
    const ownerChain = event.currentTarget.value;
    const newState = { ...vesselForm, ownerChain };
    setVesselForm(newState);
    onVesselChanged({
      ...newState,
      isValid: isVesselFormValid(),
    });
  };

  const handleItineraryChanged = (event: React.FormEvent<HTMLTextAreaElement>) => {
    const itinerary = event.currentTarget.value;
    const newState = { ...vesselForm, itinerary };
    setVesselForm(newState);
    onVesselChanged({
      ...newState,
      isValid: isVesselFormValid(),
    });
  };

  const handleAdditionalNotesChanged = (event: React.FormEvent<HTMLTextAreaElement>) => {
    const notes = event.currentTarget.value;
    const newState = { ...vesselForm, additionalNotes: notes };
    setVesselForm(newState);
    onVesselChanged({
      ...newState,
      isValid: isVesselFormValid(),
    });
  };

  const handleSpeedAndConsumption = (event: React.FormEvent<HTMLTextAreaElement>) => {
    const speedAndConsumption = event.currentTarget.value;
    const newState = { ...vesselForm, speedAndConsumption };
    setVesselForm(newState);
    onVesselChanged({
      ...newState,
      isValid: isVesselFormValid(),
    });
  };

  const handleDescripton = (event: React.FormEvent<HTMLTextAreaElement>) => {
    const vesselDescription = event.currentTarget.value;
    const newState = { ...vesselForm, vesselDescription };
    setVesselForm(newState);
    onVesselChanged({
      ...newState,
      isValid: isVesselFormValid(),
    });
  };

  const handleETAChanged = (eta: Date | undefined) => {
    const newState = { ...vesselForm, eta: eta ? eta.toISOString() : "" };
    const now = new Date();

    if (eta && !moment(eta).isSameOrAfter(now, "day")) {
      // date is in the past
      setVesselForm({ ...newState, dateIsInThePast: true });
      onVesselChanged({ ...newState, isValid: false });
    } else {
      setVesselForm({ ...newState, dateIsInThePast: false });
      onVesselChanged({
        ...newState,
        isValid: Boolean(vesselForm.vessel) && !vesselForm.vesselAlreadySelected,
      });
    }
  };

  const isVesselFormValid = () => {
    return Boolean(vesselForm.vessel) && !vesselForm.vesselAlreadySelected && !vesselForm.dateIsInThePast;
  };

  return (
    <div className={styles.container}>
      {
        <MinimalConfigurationContext.Consumer>
          {(config) => (
            <>
              <div data-test="vessel-search-picker" className={styles.detail}>
                <UserContext.Consumer>
                  {(user) => (
                    <VesselSearchPicker
                      baseUrl={postFixUrl ? `${config.ctradeUrl}${postFixUrl}` : config.ctradeUrl}
                      id="nominatedVesselSearch"
                      division={user.divisionName}
                      required={false}
                      onChange={handleVesselSelected}
                      selected={vesselForm.vessel}
                      focus={true}
                      selectedVessels={props.nominatedVessels}
                      onVesselSelectedInSelectedVessels={handleVesselAlreadySelected}
                    />
                  )}
                </UserContext.Consumer>
                {vesselForm.vesselAlreadySelected && (
                  <label className={styles.errorMessage}>
                    This vessel has already been nominated or rejected. Please select another vessel.
                  </label>
                )}
              </div>
              <div className={styles.detail}>
                <TextArea
                  className={styles.textarea}
                  dataTest="vessel-owner-chain"
                  label="Chartering Chain"
                  onChange={handleOwnerChainChanged}
                  maxLength={2000}
                  value={vesselForm.ownerChain}
                  rows={3}
                />
              </div>
              <div className={styles.detail}>
                <TextArea
                  className={styles.textarea}
                  dataTest="vessel-itinerary"
                  label="Vessel Itinerary"
                  onChange={handleItineraryChanged}
                  maxLength={2000}
                  value={vesselForm.itinerary}
                  rows={3}
                />
              </div>
              <div className={styles.detail} data-test="vessel-eta">
                ETA
                <DatePicker
                  className={styles.etaContainer}
                  icon="date-range"
                  value={(vesselForm.eta && new Date(vesselForm.eta)) || undefined}
                  onSelectDate={(date: Date | null | undefined) => handleETAChanged(date || undefined)}
                />
                {vesselForm.dateIsInThePast && (
                  <span className={styles.errorMessage}>The date you have entered is in the past</span>
                )}
              </div>
              <div className={styles.lastDetail}>
                <TextArea
                  className={styles.textarea}
                  dataTest="vessel-speed-and-consumption"
                  label="Speed and Consumption"
                  onChange={handleSpeedAndConsumption}
                  maxLength={2000}
                  value={vesselForm.speedAndConsumption}
                  rows={8}
                />
              </div>
              <div className={styles.lastDetail}>
                <TextArea
                  className={styles.textarea}
                  dataTest="vessel-description"
                  label="Vessel Description"
                  onChange={handleDescripton}
                  maxLength={2000}
                  value={vesselForm.vesselDescription}
                  rows={8}
                />
              </div>
              {showAdditionalNotes && (
                <div className={showAdditionalNotes ? styles.lastDetail : styles.detail}>
                  <TextArea
                    className={styles.textarea}
                    dataTest="vessel-additional-notes"
                    label="Additional Notes"
                    onChange={handleAdditionalNotesChanged}
                    maxLength={2000}
                    value={vesselForm.additionalNotes}
                    rows={3}
                  />
                </div>
              )}
            </>
          )}
        </MinimalConfigurationContext.Consumer>
      }
    </div>
  );
};
