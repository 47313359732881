import { LABEL_MAP } from "___REFACTOR___/constants";
import { Location, LocationProps } from "../Location";

class ViaLocation extends Location {
  static label = LABEL_MAP.viaLocation;
}
class ViaLocationProps extends LocationProps {}

ViaLocation.prototype.Props = ViaLocationProps;
ViaLocationProps.prototype.label = LABEL_MAP.viaLocation;

export { ViaLocation, ViaLocationProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface ViaLocation {
  Props: typeof ViaLocationProps;
  _: ViaLocationProps;
}
