import { object, string, date } from "yup";
import { Period } from "../Period";

// https://github.com/jquense/yup
const schema = object({
  display: string().required("Required"), // has to be provided by FE, otherwise TradeAPI emails will be mising missing laycan / period values
  from: date().required("Required"),
  to: date().required("Required"),
}).transform(toJSON);

function toJSON(period: Period) {
  return period?.toJSON();
}

export { schema };
