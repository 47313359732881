import { Observable } from "rxjs";
import { getNegotiationApiService } from "../../api/negotiations/Negotiations";
import { map } from "rxjs/operators";
import { INegotiations } from "./client/negotiation/NegotiationService";
import * as api from "../../api/negotiations/models";
import { mapToNegotiableView } from "./mapping/subMapping";

export function getNegotiations(
  apiUrl: string,
  orderId: string,
  withUpdates?: Observable<{ id: string; version: number }>
): Observable<INegotiations> {
  return getNegotiationApiService(apiUrl, orderId, withUpdates)
    .getNegotiations(orderId)
    .pipe(map((result) => mapToNegotiations(orderId, result)));
}

function mapToNegotiations(orderId: string, negotiations: api.INegotiation[]): INegotiations {
  return {
    orderId,
    negotiations: negotiations.map(mapToNegotiableView),
  };
}
