import React from "react";
import { TextareaField, NumberField, Field, StatusMessages } from "___REFACTOR___/components";
import { Nominations } from "___REFACTOR___/models";
import { Group } from "../../Group";
import { Row } from "../../Row";
import "./Nominations.scss";

function NominationsEditor(props: NominationsEditor.Props) {
  const { className, value, status, ...rest } = props;
  const { noticePerLaycan, laycanSpread, finalLaycanNotice } = value || {};

  return (
    <Group {...props} editorType="Nominations">
      <Row className="Nominations-value">
        <NumberField
          {...rest}
          label="Nominations / Notice per laycan"
          className="Nominations-notice"
          placeholder="e.g. 30"
          desc="Nominations notice per laycan"
          status={status?.childMap?.noticePerLaycan}
          value={noticePerLaycan}
          onChange={onValueChange.bind(null, "noticePerLaycan")}
          dataTest={`${props.dataTest}.notice`}
          suppressStatusMessages
        />
        <NumberField
          {...rest}
          label="Laycan spread"
          className="Nominations-spread"
          desc="Nominations laycan spread"
          placeholder="e.g. 10"
          status={status?.childMap?.laycanSpread}
          value={laycanSpread}
          onChange={onValueChange.bind(null, "laycanSpread")}
          dataTest={`${props.dataTest}.spread`}
          suppressStatusMessages
        />
        <NumberField
          {...rest}
          label="Final laycan notice"
          className="Nominations-final"
          desc="Nominations final laycan notice"
          placeholder="e.g. 10"
          status={status?.childMap?.finalLaycanNotice}
          value={finalLaycanNotice}
          onChange={onValueChange.bind(null, "finalLaycanNotice")}
          dataTest={`${props.dataTest}.final`}
          suppressStatusMessages
        />
      </Row>
      <StatusMessages status={status} />
      <TextareaField
        {...props}
        label="Notes"
        desc="Nominations notes"
        className="Nominations-notes"
        value={value?.notes || ""}
        onChange={onNotesChange}
        status={status?.childMap?.notes}
        dataTest={`${props.dataTest}.notes`}
      />
    </Group>
  );

  function onValueChange(name: keyof Nominations, inputValue: NumberField.Input.Value) {
    const next = new Nominations({
      ...value,
      [name]: inputValue,
    });

    props.onChange?.(next);
  }

  function onNotesChange(notes: TextareaField.Input.Value) {
    if (typeof notes !== "string") return;

    props.onChange?.(new Nominations({ ...props.value, notes }));
  }
}

export { NominationsEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace NominationsEditor {
  export interface Props extends Field.Props {
    value: Value;
    onChange: (value: Value) => void;
  }

  export type Value = Nominations | undefined;
}
