import React, { Component } from "react";
import { IDropdownOption } from "office-ui-fabric-react";
import { CustomDropdown } from "sharedFolder/components/common/CustomDropdown/CustomDropdown";
import { durationText } from "sharedFolder/display/duration";
import { IDurationView } from "sharedFolder/Models/IDetails";
import Input from "sharedFolder/components/common/Input/Input";
import { Notes } from "sharedFolder/components/common/Notes/Notes";
import styles from "./DurationEditor.module.scss";

const DurationUnits = [
  { key: "Days", value: "Days" },
  { key: "Months", value: "Months" },
];

export enum DurationUnit {
  Days = "Days",
  Months = "Months",
}

interface IDurationEditorProps {
  disabled?: boolean;
  value: IDurationView | undefined;
  required?: boolean;
  onChange: (value: IDurationView) => void;
  focus?: boolean;
}

interface IDurationState {
  min: string;
  max: string;
  unit: DurationUnit | undefined;
  notes?: string;
}

export class DurationEditor extends Component<IDurationEditorProps, IDurationState> {
  constructor(props: IDurationEditorProps) {
    super(props);

    this.state = this.convertPropsToState(props);

    this.minDurationChangedHandler = this.minDurationChangedHandler.bind(this);
    this.maxDurationChangedHandler = this.maxDurationChangedHandler.bind(this);
    this.unitTypeChangedHandler = this.unitTypeChangedHandler.bind(this);
    this.notesChangedHandler = this.notesChangedHandler.bind(this);
    this.maxDurationHandleBlur = this.maxDurationHandleBlur.bind(this);
    this.update = this.update.bind(this);
  }

  public componentDidUpdate(prevProps: IDurationEditorProps) {
    if (this.props.value !== prevProps.value) {
      this.setState(this.convertPropsToState(this.props));
    }
  }

  public render() {
    const dropdownOptions: IDropdownOption[] = DurationUnits ? DurationUnits.map((o) => ({ key: o.key, text: o.value })) : [];

    return (
      <>
        <div className={styles.inputRow}>
          <div className={styles.inputGroup}>
            <div className={styles.container}>
              <div className={styles.part}>
                <div className={styles.label}>Min</div>
                <Input
                  id={"duration_min"}
                  ariaLabel={"Duration Min"}
                  dataTest={"duration-min"}
                  onChange={this.minDurationChangedHandler}
                  value={this.state.min}
                  required={this.props.required}
                  focus={this.props.focus}
                  disabled={this.props.disabled}
                  width="tiny"
                />
              </div>
              <div className={styles.part}>
                <div className={styles.label}>Max</div>
                <Input
                  id={"duration_max"}
                  ariaLabel={"Duration Max"}
                  dataTest={"duration-max"}
                  onChange={this.maxDurationChangedHandler}
                  value={this.state.max}
                  required={this.props.required}
                  onBlur={this.maxDurationHandleBlur}
                  disabled={this.props.disabled}
                  width="tiny"
                />
              </div>
              <div className={styles.unit}>
                <div className={styles.label}>&nbsp;</div>
                <CustomDropdown
                  id={"duration_unit"}
                  ariaLabel={"Duration Unit"}
                  disabled={this.props.disabled}
                  options={dropdownOptions}
                  defaultSelectedKey={this.state.unit}
                  onChange={this.unitTypeChangedHandler}
                  dropdownClassName={styles.dropdown}
                  width="tiny"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.inputRow}>
          <div className={styles.inputGroup}>
            <Notes
              dataTest="Additional duration notes textarea"
              id={`duration_notes`}
              placeholder="Additional duration notes"
              label="Notes"
              value={this.state.notes}
              onChange={(value) => this.notesChangedHandler(value)}
              disabled={this.props.disabled}
              maxLength={200}
            />
          </div>
        </div>
      </>
    );
  }

  private convertPropsToState(props: IDurationEditorProps) {
    return {
      min: !props.value || !props.value.min || props.value.min === null ? "" : String(props.value.min),
      max: !props.value || !props.value.max || props.value.max === null ? "" : String(props.value.max),
      unit: (props.value && props.value.unit) || DurationUnit.Days,
      notes: (props.value && props.value.notes) || "",
    };
  }

  private minDurationChangedHandler(event: React.FormEvent<HTMLInputElement>) {
    this.setState({ min: event.currentTarget.value }, () => this.update());
    // TODO: validation - invalidate if NaN
  }

  /**
   * Save the maximum value
   * @param event onChange event
   */
  private maxDurationChangedHandler(event: React.FormEvent<HTMLInputElement>) {
    this.setState({ max: event.currentTarget.value }, () => this.update());
  }

  private maxDurationHandleBlur(event: React.FocusEvent<HTMLInputElement>) {
    const { min, max } = calculateMinAndMaxDuration(this.state.min, this.state.max);
    this.setState({ min: min, max: max }, () => this.update());
  }

  private unitTypeChangedHandler(value: string | number) {
    this.setState({ unit: value as DurationUnit }, () => this.update());
  }

  private notesChangedHandler(notes: string) {
    this.setState({ notes: notes }, () => this.update());
  }

  private update() {
    const minVal = Number(this.state.min);
    const min = isNaN(minVal) ? null : minVal;
    const maxVal = Number(this.state.max);
    const max = isNaN(maxVal) ? null : maxVal;
    const duration = {
      min,
      max,
      unit: this.state.unit,
      notes: this.state.notes,
    };
    this.props.onChange({
      ...duration,
      display: durationText(duration),
    });
  }
}

const calculateMinAndMaxDuration = (min: string, max: string): { min: string; max: string } => {
  const result: { min: string; max: string } = {
    min: min,
    max: max,
  };
  if (min && max && Number(max) < Number(min)) {
    result.min = max;
  }
  if (!min && max) {
    result.min = "0";
  }
  if (min && !max) {
    result.max = min;
  }
  return result;
};
