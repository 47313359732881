import { makeAutoObservable } from "mobx";
import { CargoType } from "___REFACTOR___/models";
import { CARGO_TYPES, CargoType as CargoTypeObject } from "@/constants";

class CargoTypes {
  constructor() {
    makeAutoObservable(this);
  }

  data: CargoType[] = [];

  promise = new Promise((resolve) => {
    this.resolve = resolve;
  });

  getData = () => {
    const data = CARGO_TYPES.map(newCargoType);

    if (data) this.setData(data);
  };

  setData = (data: CargoType[]) => {
    this.data = data;

    this.resolve();
  };
}

function newCargoType(cargoType: CargoTypeObject) {
  return new CargoType({ arcId: cargoType.cargoTypeId, name: cargoType.commodity });
}

export const cargoTypes = new CargoTypes();

interface CargoTypes {
  resolve: (value?) => void;
}
