import { makeAutoObservable, observable } from "mobx";
import { PopupProps } from "@/components";

export class Popup {
  constructor() {
    this.open = false;
    this.props = undefined;
    this.promise = undefined;
    this.resolve = undefined;

    makeAutoObservable(this, {
      props: observable.ref,
      resolve: false,
    });
  }

  show = (props: PopupProps) => {
    this.open = true;
    this.props = props;
    this.promise = new Promise((resolve) => {
      this.resolve = resolve;
    });

    return this.promise;
  };

  hide = () => {
    if (this.resolve) this.resolve();

    this.open = false;
    this.props = undefined;
    this.promise = undefined;
    this.resolve = undefined;
  };
}

export const popup = new Popup();

export interface Popup {
  open: boolean;
  props?: PopupProps;
  resolve?: (value?) => void;
  promise: Promise<any> | undefined;
}
