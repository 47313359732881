import React, { useEffect, useState } from "react";
import { Moment } from "moment";
import { IOrder } from "../models/IOrder";
import styles from "./Distribution.module.scss";
import NegotiationTab from "../../negotiations/NegotiationTab";
import { notAssigned } from "../../orders/components/OrderSharing/OrderSharing";
import { distributionService } from "../../negotiations/services/distribution";
import { debounce } from "lodash-es";
import { DistributionListSearchResults } from "./DistributionListSearchResults";
import { useDistribution } from "./DistributionContext";
import { DistributionList } from "./DistributionList";
import { ViewGroups } from "./ViewGroups";
import { InputWithCancelButton } from "sharedFolder/components/common/InputWithCancelButton/InputWithCancelButton";
import { DistributionMessages, DistributionMessageType } from "./DistributionMessages";
import { useHistory } from "react-router-dom";
import { EmailListPanel } from "./EmailListPanel";
import { useUser } from "__legacy/dashboard/contexts/UserProvider";
import { isEmailValid } from "sharedFolder/Utilities/emailValidator";
import ifITOElseCOA from "sharedFolder/Utilities/ifITOElseCOA";
import { useOrderType } from "__legacy/sharedFolder/contexts/order-type-context";
import Button from "__legacy/sharedFolder/components/common/Button/Button";
import LoadingSpinner from "sharedFolder/components/common/LoadingSpinner/LoadingSpinner";
import { validate } from "./reducers/distributionReducer";
import { mapUserContext } from "./mapUserContext";
import { useDisableNext } from "./useDisableNext";
import { BLOCKED_DOMAIN } from "sharedFolder/Utilities/blockedDomains";
import { useConfig } from "__legacy/sharedFolder/ConfigurationContext";
import { haveUsersOrRolesChanges } from "./roles/haveUsersOrRolesChanged";
import { validateSearchInput } from "./reducers/distributionSearchReducer";
import { usageMetrics, DistributionTypeEvents } from "@/services/UsageMetrics";

interface IDistributionProps {
  order?: IOrder;
  area?: string;
  ctradeUrl: string;
}

export const Distribution: React.FC<IDistributionProps> = (props: IDistributionProps) => {
  const { order, ctradeUrl } = props;
  const orderId = order?.id;
  const { state, dispatch } = useDistribution();
  const { vanillaEmailSearchState } = state;
  const orderType = useOrderType();
  const user = useUser();
  const currentlyLoggedInAsKnownUser = mapUserContext(user);
  const history = useHistory();
  const [searchText, setSearchText] = useState<string>("");
  const [emailListPanel, setEmailListPanel] = useState<boolean>(false);
  const [distributionMessage, setDistributionMessage] = useState<DistributionMessageType | undefined>(undefined);
  const searchTermEmailFragments = searchText.split("@");
  const searchTermEmailDomain = searchTermEmailFragments[1]?.trim();
  const searchTermEmailDomainIsBlocked = BLOCKED_DOMAIN[searchTermEmailDomain];
  const config = useConfig();
  const maxCount = config.maxDistributionCount || 700;
  const currentCount = state.savedUsersState.totalNumberOfUsersSelected;
  const overflowCount = Math.max(0, currentCount - maxCount);
  const isOverflowing = overflowCount > 0;
  const isWithdrawn = order?.status === "Withdrawn";
  const currentlyLoggedInUser = useUser();
  const { email: currentLoggedInUserEmail } = currentlyLoggedInUser;
  const [startTime, setStartTime] = useState<Moment>();

  useEffect(
    () => setStartTime(usageMetrics.startTrackingAction(DistributionTypeEvents.SELECT_DISTRIBUTION_USERS_START, orderId)),
    []
  );

  useEffect(() => {
    if (order)
      dispatch({
        type: "setExistingUsers",
        payload: {
          distributionList: order.distributionLists,
          currentLoggedInUserEmail,
        },
      });
  }, [order, dispatch, currentLoggedInUserEmail]);

  const { setIsSubmitting, isDisabled } = useDisableNext(state, currentLoggedInUserEmail, order);

  useEffect(() => {
    if (state.vanillaEmailSearchState.hasVanillaEmailsToSearchOn) {
      const searchOnEmail = async (emailAddresses: string[]) => {
        try {
          setDistributionMessage(undefined);
          const results = await distributionService(ctradeUrl).searchMultipleTerms(emailAddresses);
          dispatch({
            type: "resolveUsersByEmailAddress",
            payload: results,
          });
        } catch (e) {
          setDistributionMessage("ResolveUserRolesFailed");
          dispatch({
            type: "resolveUsersByEmailAddress",
            payload: [],
          });
          // TODO: Log error
          console.error(e);
        }
      };
      searchOnEmail(state.vanillaEmailSearchState.vanillaEmailsToSearchOn);
    }
  }, [state.vanillaEmailSearchState, ctradeUrl, dispatch]);

  const search = async (searchTerm: string) => {
    if (searchTerm.length) {
      try {
        setDistributionMessage(undefined);
        const results = await distributionService(ctradeUrl).searchSingleTerm(encodeURIComponent(searchTerm));
        dispatch({
          type: "resolveSearch",
          payload: { results, searchTerm },
        });
      } catch (e) {
        const empty = { companies: [], recipients: [] };
        dispatch({
          type: "resolveSearch",
          payload: { results: empty, searchTerm },
        });
        setDistributionMessage("SearchFailed");
        // TODO: Log error
        console.error(e);
      }
    }
  };

  const setSearchTerm = (searchTerm: string) => {
    dispatch({ type: "setSearchTerm", payload: searchTerm });
    if (isEmailValid(searchTerm.trim()) && !searchTermEmailDomainIsBlocked) {
      setSearchText(searchTerm.trim());
    } else {
      setSearchText("");
      if (validateSearchInput(searchTerm) === "Valid") debounce(() => search(searchTerm), 150)();
    }
  };

  const moveToCirculate = () => {
    const archivedOrder = props.area === "ito-archive";
    const archivedCoa = props.area === "coa-archive";
    const itoExpression = archivedOrder ? "/orders-archive" : "/orders";
    const coaExpression = archivedCoa ? "/coas-archive/orders" : "/coas/orders";

    history.push(
      `${ifITOElseCOA(orderType, itoExpression, coaExpression)}/${orderId}/circulate/${
        state.savedUsersState.totalNumberOfUsersSelected + 1
      }`
    );
  };

  const submit = async () => {
    if (startTime)
      usageMetrics.finishTrackingAction(DistributionTypeEvents.SELECT_DISTRIBUTION_USERS_FINISHED, startTime, orderId);
    if (order && order.updateToken) {
      setIsSubmitting(true);
      dispatch({ type: "showValidation" });
      if (validate(state) === "NONE") {
        if (haveUsersOrRolesChanges(state)) {
          const response = await distributionService(ctradeUrl).sendDistributionList(
            orderId!,
            state.savedUsersState.savedUsers,
            order.updateToken,
            currentlyLoggedInAsKnownUser,
            state.currentlyLoggedInUser
          );
          if (response.ok) {
            moveToCirculate();
          } else {
            setDistributionMessage("NextFailed");
          }
        } else {
          moveToCirculate();
        }
      }
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <section id="distributeOrder" data-test="distributeOrder">
        <NegotiationTab orderId={orderId || ""} filters={[]} showFilters={false} />
        <DistributionMessages messageType={distributionMessage} onClose={() => setDistributionMessage(undefined)} />
        {order ? (
          <>
            <ChartererOrBroker order={order} />
            <div hidden={isWithdrawn}>
              <div className={styles.distributionRow}>
                <h2>Distribution</h2>
                <Button
                  hidden={isWithdrawn}
                  type="flat"
                  icon="plus"
                  dataTest="add-emails-button"
                  onClick={() => setEmailListPanel(true)}
                >
                  Add Multiple Emails
                </Button>
              </div>
              <InputWithCancelButton
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
                onKeyUp={(e) => {
                  if (searchText && e.keyCode === 13 && !searchTermEmailDomainIsBlocked) {
                    dispatch({ type: "setSearchTerm", payload: "" });
                    dispatch({
                      type: "addsUsersAsEmail",
                      payload: [searchText],
                    });
                    setSearchText("");
                  }
                }}
                onCleared={() => setSearchTerm("")}
                value={state.searchResultsState.searchTerm}
                data-test="search-input"
                hidden={isWithdrawn}
                placeholder="Search for a desk, a user or enter an email address"
              />
              {searchTermEmailDomainIsBlocked && (
                <div className={styles.searchTermValidationMessage} data-test="blocked-email-message">
                  The domain <span>{searchTermEmailDomain}</span> is not verified by Maritech
                </div>
              )}
              <div className={styles.viewGroupsWrapper}>
                <ViewGroups
                  onGroupSelection={(distributionEmails) =>
                    dispatch({
                      type: "addsUsersAsEmail",
                      payload: distributionEmails,
                    })
                  }
                  addAbsentOwner={(generatedEmail) =>
                    dispatch({
                      type: "addAbsentOwner",
                      payload: generatedEmail,
                    })
                  }
                />
              </div>
              <div data-test="warning-message">
                {isOverflowing && (
                  <div className={styles.distributionOverflow} data-test="max-emails-overflow">
                    You may only add a maximum of {maxCount} email addresses. You will not be able to proceed until you remove{" "}
                    {overflowCount === 1 ? "one email" : `${overflowCount} emails`}
                  </div>
                )}
                {state.searchResultsState.searchState === "two-email-address" && (
                  <div className={styles.distributionOverflow} data-test="multiple-email-address-warning">
                    Your search input contains more than one email address. You can enter only one email address at a time, hit
                    &apos;Enter&apos; after you have typed in the full email address
                  </div>
                )}
                {state.searchResultsState.searchState === "search-string-too-long" && (
                  <div className={styles.distributionOverflow} data-test="search-input-too-long">
                    Your search input contains more than 50 characters, please make sure you search for one user at a time{" "}
                  </div>
                )}
              </div>
              <DistributionListSearchResults
                searchState={state.searchResultsState.searchState}
                distributions={state.searchResultsState.searchResultsDistributions}
              />
            </div>
            <DistributionList icon="delete" distributions={state.savedUsersState.savedUsers} />
            {state.savedUsersState.totalNumberOfUsersSelected === 0 && (
              <div className={styles.noUsersSelected} data-test="emptyRecipients">
                <div>Added recipients will show here</div>
              </div>
            )}
          </>
        ) : (
          <div className={styles.loading}>
            <LoadingSpinner />
          </div>
        )}

        {emailListPanel && (
          <EmailListPanel
            maxCount={maxCount}
            currentCount={currentCount}
            onDismiss={() => setEmailListPanel(false)}
            onUpdateEmails={(distributionEmails?: string[]) => {
              setEmailListPanel(false);
              if (distributionEmails) {
                dispatch({
                  type: "addsUsersAsEmail",
                  payload: distributionEmails,
                });
              }
            }}
          />
        )}
      </section>
      <div hidden={isWithdrawn}>
        <div className={styles.nextButtonWrapper}>
          {state.validationShow === "GENERAL_VALIDATION_WARNING" && (
            <span className={styles.noRoleSelectedWarning} data-test="validation-warning">
              Users selected have not yet been assigned a role
            </span>
          )}
          {state.validationShow === "MULTIPLE_CHARTERER_WARNING" && (
            <span className={styles.noRoleSelectedWarning} data-test="validation-warning">
              Only one Charterer from a different company may be set
            </span>
          )}
          {state.validationShow === "SAME_COMPANY_DIFFERENT_ROLE_WARNING" && (
            <span className={styles.noRoleSelectedWarning} data-test="validation-warning">
              You cannot send an order to a user within your company with a different role than your own
            </span>
          )}
          <Button
            type="action"
            disabled={isDisabled || isOverflowing || distributionMessage === "ResolveUserRolesFailed"}
            onClick={submit}
            dataTest="NextButton"
          >
            {vanillaEmailSearchState.hasVanillaEmailsToSearchOn ? "loading user roles" : "next"}
          </Button>
        </div>
      </div>
    </>
  );
};

const ChartererOrBroker = (props: { order?: IOrder }) => {
  const user = useUser();
  const isCharterer = user.companyRoles.includes("charterer");
  const isBroker = user.companyRoles.includes("broker");

  return (
    <>
      {isBroker && <Charterer order={props.order} />}
      {isCharterer && <Broker order={props.order} />}
    </>
  );
};

const Charterer = (props: { order?: IOrder }) => {
  const charterer = props.order?.charterer;
  return (
    <>
      <h2>Charterer</h2>
      {charterer ? displayCharterer("charterer")(charterer) : notAssigned("charterer")}
    </>
  );
};

const Broker = (props: { order?: IOrder }) => {
  const brokers = props.order?.brokers || [];
  return (
    <>
      <h2>Broker</h2>
      {brokers && brokers.length ? brokers.map(displayCharterer("broker")) : notAssigned("broker")}
    </>
  );
};

const displayCharterer = (chartererOrBroker: "charterer" | "broker") => (userDisplay: string, key?: string | number) => {
  return (
    <div key={key} className={styles.display} data-test={`${chartererOrBroker}-display`}>
      {userDisplay}
    </div>
  );
};
