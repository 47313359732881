import {
  INegotiationCreateView,
  INegotiationCreateViewDetails,
  IOrderITOCreate,
  IOrderCOACreate,
} from "../models/NegotiationCreateView";
import * as api from "../../api/negotiations/models";
import { mapAccount } from "sharedFolder/mappers/mapAccount";
import { mapCommission } from "sharedFolder/mappers/mapCommission";
import { ICirculateOrderDetails, OrderNegType } from "../../api/negotiations/models";
import { mapCOACargoSize } from "sharedFolder/mappers/mapCOACargoSize";
import { mapLiftings } from "sharedFolder/mappers/mapLiftings";
import { toNegotiableApi, toNegotiableApiOptional } from "sharedFolder/mappers/map";
import { mapLocation } from "sharedFolder/mappers/mapLocation";
import { mapLaycan } from "sharedFolder/mappers/mapLaycan";
import { mapDuration } from "sharedFolder/mappers/mapDuration";
import { mapVesselSize } from "sharedFolder/mappers/mapVesselSize";
import { mapCargoType } from "sharedFolder/mappers/mapCargoType";
import { mapCargoSize } from "sharedFolder/mappers/mapCargoSize";
import { mapNominations } from "sharedFolder/mappers/mapNominations";
import {
  mapFreightRate,
  mapHireRate,
  mapCleaningPrice,
  mapSupplyPrice,
  mapDemurrage,
  mapBallastBonus,
  mapNotes,
} from "sharedFolder/mappers/mapUnitValue";
import { IOrder } from "orders/models/IOrder";
import { mapSubsText } from "sharedFolder/mappers/mapSubsText";
import { mapBunker } from "sharedFolder/mappers/mapBunker";

export function mapDistributeOrder(neg: INegotiationCreateView, order: IOrder): api.IDistributeOrder {
  // types
  const types: OrderNegType[] = [];
  if (neg.voySelected) {
    types.push("VOY");
  }
  if (neg.tctSelected) {
    types.push("TCT");
  }
  if (neg.originallySelectedTypes.some((t) => t === "COA")) {
    types.push("COA");
  }

  const result: api.IDistributeOrder = {
    updateToken: neg.updateToken,
    distributionList: neg.distributionList,
    types,
    groupChats: order.groupChats,
    teamMembers: order.teamMembers,
    detailsCoa: types.includes("COA") ? mapDetailsForType("COA", neg.details) : undefined,
    detailsVoy: types.includes("VOY") ? mapDetailsForType("VOY", neg.details) : undefined,
    detailsTct: types.includes("TCT") ? mapDetailsForType("TCT", neg.details) : undefined,
  };

  return result;
}

function mapDetailsForType(type: OrderNegType, details: INegotiationCreateViewDetails): ICirculateOrderDetails {
  const {
    chartererAccount: _chartererAccount,
    cargoType: _cargoType,
    addressCommission: _addressCommission,
    brokerCommission: _brokerCommission,
  } = details.order;
  const {
    cargoSize: _cargoSize,
    vesselSize: _vesselSize,
    deliveryLocation: _deliveryLocation,
    viaLocation: _viaLocation,
    redeliveryLocation: _redeliveryLocation,
    duration: _duration,
    hireRate: _hireRate,
    cleaningPrice: _cleaningPrice,
    supplyPrice: _supplyPrice,
    tradingExclusions: _tradingExclusions,
    bunkerDelivery: _bunkerDelivery,
    bunkerRedelivery: _bunkerRedelivery,
    cargoExclusionsText: _cargoExclusionsText,
    ballastBonus: _ballastBonus,
  } = details.details;
  const coaDetails = details.order as IOrderCOACreate;
  const itoDetails = details.order as IOrderITOCreate;

  let _notes;
  let _loadLocation;
  let _commercialSubs;
  let _operationalSubs;
  let _dischargeLocation;
  let _demurrage;
  let _freightRate;

  switch (type) {
    case "COA": {
      _commercialSubs = coaDetails.commercialSubs;
      _operationalSubs = coaDetails.operationalSubs;
      _demurrage = coaDetails.demurrage;
      _freightRate = coaDetails.freightRate;
      _notes = coaDetails.coaNotes;
      _loadLocation = coaDetails.loadLocation;
      _dischargeLocation = coaDetails.dischargeLocation;
      break;
    }
    case "VOY": {
      _commercialSubs = details.details.voyCommercialSubs;
      _operationalSubs = details.details.voyOperationalSubs;
      _demurrage = details.details.demurrage;
      _freightRate = details.details.freightRate;
      _notes = details.details.voyNotes;
      _loadLocation = details.details.loadLocation;
      _dischargeLocation = details.details.dischargeLocation;
      break;
    }
    case "TCT": {
      _commercialSubs = details.details.tctCommercialSubs;
      _operationalSubs = details.details.tctOperationalSubs;
      _notes = details.details.tctNotes;
      _loadLocation = details.details.loadLocation;
      _dischargeLocation = details.details.dischargeLocation;
      break;
    }
  }

  const charterer = mapAccount.toApiOrUndefined(_chartererAccount.value) || {
    accountName: "",
  };

  const cargoType = toNegotiableApiOptional(mapCargoType)(_cargoType);

  const addressCommission = toNegotiableApiOptional(mapCommission)(_addressCommission);

  const brokerCommission = toNegotiableApiOptional(mapCommission)(_brokerCommission);

  const cargoSize = toNegotiableApiOptional(mapCargoSize)(_cargoSize);

  const coaCargoSize = coaDetails.coaCargoSize && toNegotiableApi(mapCOACargoSize)(coaDetails.coaCargoSize);

  const commercialSubs = toNegotiableApiOptional(mapSubsText)(_commercialSubs);

  const operationalSubs = toNegotiableApiOptional(mapSubsText)(_operationalSubs);

  const laycan = itoDetails.laycan && toNegotiableApi(mapLaycan)(itoDetails.laycan);

  const loadLocation = toNegotiableApiOptional(mapLocation)(_loadLocation);

  const dischargeLocation = toNegotiableApiOptional(mapLocation)(_dischargeLocation);
  const freightRate = _freightRate && toNegotiableApiOptional(mapFreightRate)(_freightRate);

  const demurrage = _demurrage && toNegotiableApiOptional(mapDemurrage)(_demurrage);

  const vesselSize = toNegotiableApiOptional(mapVesselSize)(_vesselSize);

  const deliveryLocation = toNegotiableApiOptional(mapLocation)(_deliveryLocation);

  const viaLocation = toNegotiableApiOptional(mapLocation)(_viaLocation);

  const redeliveryLocation = toNegotiableApiOptional(mapLocation)(_redeliveryLocation);

  const duration = toNegotiableApiOptional(mapDuration)(_duration);

  const notes = _notes && _notes.value && mapNotes.toApi(_notes.value);

  const hireRate = _hireRate && toNegotiableApiOptional(mapHireRate)(_hireRate);

  const cleaningPrice = _cleaningPrice && toNegotiableApiOptional(mapCleaningPrice)(_cleaningPrice);

  const supplyPrice = _supplyPrice && toNegotiableApiOptional(mapSupplyPrice)(_supplyPrice);

  const tradingExclusions = toNegotiableApiOptional(mapSubsText)(_tradingExclusions);

  const bunkerDelivery = toNegotiableApiOptional(mapBunker)(_bunkerDelivery);

  const bunkerRedelivery = toNegotiableApiOptional(mapBunker)(_bunkerRedelivery);

  const cargoExclusionsText = toNegotiableApiOptional(mapSubsText)(_cargoExclusionsText);

  const ballastBonus = _ballastBonus && toNegotiableApiOptional(mapBallastBonus)(_ballastBonus);

  const period = toNegotiableApiOptional(mapLaycan)(coaDetails.period);

  const liftings = coaDetails.liftings && toNegotiableApi(mapLiftings)(coaDetails.liftings);

  const nominations = toNegotiableApiOptional(mapNominations)(coaDetails.nominations);

  let circulateOrder: ICirculateOrderDetails = {
    charterer: {
      value: charterer,
    },
  };

  if (["VOY", "TCT"].includes(type)) {
    circulateOrder = {
      ...circulateOrder,
      commercialSubs,
      operationalSubs,
      addressCommission,
      brokerCommission,
      cargoType,
      laycan,
      notes,
    };
    if (type === "VOY") {
      circulateOrder = {
        ...circulateOrder,
        cargoSize,
        demurrage,
        dischargeLocation,
        freightRate,
        loadLocation,
      };
    } else if (type === "TCT") {
      circulateOrder = {
        ...circulateOrder,
        ballastBonus,
        deliveryLocation,
        duration,
        hireRate,
        cleaningPrice,
        supplyPrice,
        tradingExclusions,
        bunkerDelivery,
        bunkerRedelivery,
        cargoExclusionsText,
        redeliveryLocation,
        vesselSize,
        viaLocation,
      };
    }
  } else if (type === "COA") {
    circulateOrder = {
      ...circulateOrder,
      addressCommission,
      brokerCommission,
      cargoType,
      coaCargoSize,
      commercialSubs,
      demurrage,
      dischargeLocation,
      freightRate,
      liftings,
      loadLocation,
      nominations,
      notes,
      operationalSubs,
      period,
    };
  }

  return circulateOrder;
}
