import React from "react";
import classNames from "classnames";
import { Liftings, LiftingsProps } from "@/models";
import { SelectField, SelectFieldProps, TextareaField, NumberField, StatusMessages } from "@/components";
import { Group } from "../../Group";
import { Row } from "../../Row";
import "./Liftings.scss";

export function LiftingsEditor(props: Props) {
  props = { ...props };

  props.className = classNames(props.className, "Liftings");

  const { className, value, status, ...rest } = props;

  const { min, max, dispersal } = value || {};

  function onChange(name: keyof Liftings, inputValue: number | null) {
    const next = new Liftings({
      ...value,
      [name]: inputValue,
    });

    props.onChange?.(next);
  }

  function onDispersalChange(dispersal) {
    const next = new Liftings({
      ...value,
      dispersal,
    });

    props.onChange?.(next);
  }

  function onNotesChange(notes: string) {
    props.onChange?.(new Liftings({ ...props.value, notes }));
  }

  return (
    <Group className={props.className}>
      <Row>
        <NumberField
          {...rest}
          label="Min"
          desc="Decides what is the minimum value for liftings option"
          placeholder="e.g. 5"
          className="Liftings-min"
          status={status?.children?.min}
          value={min}
          onChange={onChange.bind(null, "min")}
          dataTest={`${props.dataTest}.min`}
          suppressStatusMessages
        />
        <NumberField
          {...rest}
          label="Max"
          desc="Decides what is the maximum value for liftings option"
          placeholder="e.g. 10"
          className="Liftings-max"
          status={status?.children?.max}
          value={max}
          onChange={onChange.bind(null, "max")}
          dataTest={`${props.dataTest}.max`}
          suppressStatusMessages
        />
        <SelectField
          {...rest}
          label="Dispersal"
          desc="Dispersal defines the lifting option type"
          className="Liftings-dispersal"
          data={LiftingsProps.prototype.dispersals}
          filterKey="name"
          status={status?.children?.dispersal}
          value={dispersal || LiftingsProps.prototype.JSONDefaults?.dispersal}
          onChange={onDispersalChange}
          dataTest={`${props.dataTest}.dispersal`}
          unclearable
          nonFilterSelect
          suppressStatusMessages
        />
      </Row>
      <StatusMessages status={status} />
      <TextareaField
        {...props}
        label="Notes"
        desc="Liftings notes"
        value={value?.notes || ""}
        onChange={onNotesChange}
        status={status?.children?.notes}
        dataTest={`${props.dataTest}.notes`}
      />
    </Group>
  );
}

interface Props extends Omit<SelectFieldProps, "value" | "onChange"> {
  value?: Liftings;
  onChange?: (value: Liftings) => void;
}
