import React, { CSSProperties, useRef, useEffect, useState } from "react";
import Autosize from "react-virtualized-auto-sizer";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import classNames from "classnames";
import { DataModel, Status } from "___REFACTOR___/models";
import { Img, ImgProps, Icon, Overlay } from "___REFACTOR___/components";
import "./Dropdown.scss";

function Dropdown(props: Dropdown.Props) {
  const { target, dropdown: instance, dataTest } = props;
  const [data, setData] = useState(props.data);
  const [index, setIndex] = useState(0);
  const [collapsed, setCollapsed] = useState(true);
  const dataLength = data?.length ? data?.length - 1 : 0;
  const dropdown = useRef<HTMLDivElement>(null);
  const box = useRef<HTMLDivElement>(null);
  const list = useRef<FixedSizeList>(null);
  const style = { ...props.style } as CSSProperties;
  const contentStyle = {} as CSSProperties;
  const prevRef = useRef<Prev>(defaultPrev);
  const prev = prevRef.current;
  if (instance) {
    instance.current = {
      open: true,
      data,
      index,
      setIndex,
      dataLength,
      list,
    };
  }
  useEffect(unsetInstance, []);
  useEffect(uncollapse, []);
  useEffect(initData, [props.data, target]);
  useEffect(initEvents, [data, target, index]);
  useEffect(resetPrev, [target]);
  let isTopHalf = false;
  if (target) {
    const t = target.getBoundingClientRect();
    const _minHeight = prev.height < minHeight ? minHeight : prev.height;
    const _maxHeight = min(itemSize * (data?.length || 0), maxHeight);
    const height = data ? _maxHeight : _minHeight;
    const width = max(box.current?.clientWidth || 0, t.width);
    const clippingRight = t.left + width > window.innerWidth;
    const clippingLeft = t.left < 0;
    isTopHalf = t.top < window.innerHeight / 2;
    style.width = width;
    style.height = height + 2;
    contentStyle.height = height;
    if (isTopHalf) {
      style.top = t.top + t.height;
      style.left = t.left;
      contentStyle.bottom = 0;
    } else {
      style.bottom = window.innerHeight - t.top;
      style.left = t.left;
      contentStyle.top = 0;
    }
    if (clippingRight) {
      const clippingAmount = t.left + width - window.innerWidth;
      style.left = t.left - clippingAmount - 12;
    }
    if (clippingLeft) {
      const clippingAmount = 0 - t.left;
      style.left = t.left + clippingAmount + 12;
    }
    prev.height = height;
  }
  function unsetInstance() {
    return function () {
      if (instance) instance.current = null;
    };
  }
  function uncollapse() {
    setCollapsed(false);
  }
  function resetPrev() {
    prevRef.current = defaultPrev;
  }
  function initData() {
    const _data = props.data?.length ? props.data : undefined;
    const data = isTopHalf ? _data : _data?.slice(0).reverse();
    const dataLength = data?.length ? data?.length - 1 : 0;
    const index = isTopHalf ? 0 : dataLength;
    setTimeout(() => list.current?.scrollToItem(index));
    setData(data);
    setIndex(index);
  }
  function initEvents() {
    dropdown.current?.addEventListener("mousedown", mouseDownHandler);
    window.addEventListener("keydown", keyDownHandler);
    function mouseDownHandler(e: MouseEvent) {
      e.preventDefault();
    }
    function keyDownHandler(e: KeyboardEvent) {
      const key = e.key as keyof typeof KEYBOARD_EVENT_HANDLER;
      const handler = KEYBOARD_EVENT_HANDLER[key];
      if (handler) handler(e);
    }
    return () => {
      window.removeEventListener("keydown", keyDownHandler);
      dropdown.current?.removeEventListener("mousedown", mouseDownHandler);
    };
  }

  const KEYBOARD_EVENT_HANDLER = {
    ArrowUp: (e: KeyboardEvent) => {
      e.preventDefault();
      const nextIndex = index > 0 ? index - 1 : dataLength;
      setIndex(nextIndex);
      list.current?.scrollToItem(nextIndex);
    },
    ArrowDown: (e: KeyboardEvent) => {
      e.preventDefault();
      const nextIndex = index < dataLength ? index + 1 : 0;
      setIndex(nextIndex);
      list.current?.scrollToItem(nextIndex);
    },
    Tab: (e: KeyboardEvent) => {
      props.hide?.();
    },
    Enter: (e: KeyboardEvent) => {
      e.preventDefault();
      props.select?.(data?.[index]);
    },
    Escape: (e: KeyboardEvent) => {
      e.preventDefault();
      props.hide?.();
    },
  };
  return (
    <div className={classNames(props?.className, "dropdown")} ref={dropdown}>
      <div className="dropdown-backdrop" onClick={props.hide} />
      <div className={classNames("dropdown-box", { collapsed })} style={style} ref={box} data-test={`${dataTest}-dialog`}>
        <div className="dropdown-box-content-wrapper">
          <div className="dropdown-box-content" style={contentStyle}>
            <List {...props} data={data} isTopHalf={isTopHalf} activeIndex={index} setActiveIndex={setIndex} list={list} />
            <Overlay status={props.status} />
          </div>
        </div>
      </div>
    </div>
  );
}
function List(props) {
  const { data, list } = props;
  return (
    <Autosize>
      {(size) => (
        <FixedSizeList
          {...size}
          className="dropdown-virtual-list"
          itemCount={data?.length || 0}
          itemSize={itemSize}
          itemData={props}
          ref={list}
        >
          {Item}
        </FixedSizeList>
      )}
    </Autosize>
  );
}
function Item(props: ListChildComponentProps) {
  const { index, style } = props;
  const { data, getIcon, select, activeIndex, setActiveIndex, isTopHalf } = props.data;
  const dataLength = data?.length || 0;
  if (!data) return null;
  const itemData = data[index];
  let label = itemData;
  let img = undefined as ImgProps | undefined;

  if (itemData instanceof DataModel) {
    label = itemData.toDropdownView?.();
    img = itemData._?.img;
  }

  if (!label) label = "";

  const dataTest = `dropdown-item.${isTopHalf ? index : dataLength - 1 - index}`;

  const icon = getIcon?.(itemData);
  function onClick() {
    select(itemData);
  }
  function onMouseOver() {
    setActiveIndex(index);
  }
  return (
    <div
      className={classNames("dropdown-item", { active: index === activeIndex })}
      style={style}
      onClick={onClick}
      onMouseOver={onMouseOver}
      data-test={dataTest}
    >
      <Img img={img} />
      <Icon icon={icon} />
      <span>{label}</span>
    </div>
  );
}

const itemSize = 36;
const minHeight = itemSize * 3;
const maxHeight = itemSize * 7;
const defaultPrev = {
  height: 0,
};
const { min, max } = Math;

export { Dropdown };

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

declare namespace Dropdown {
  interface Props {
    target?: HTMLElement | null;
    data?: Data;
    className?: string;
    style?: CSSProperties;
    status?: Status;
    getImg?: GetImg;
    getIcon?: GetIcon;
    hide?: () => void;
    select?: (item: Item) => void;
    index?: number | null;
    dropdown?: React.MutableRefObject<Instance | null>;
    dataTest?: string;
  }
  type GetImg = ((item: Item) => ItemImg) | undefined;
  type ItemImg = ImgProps["img"];
  type GetIcon = ((item: Item) => ItemIcon) | undefined;
  type ItemIcon = Icon.IconProp;
  type Data = Item[] | undefined | null | readonly Item[];
  type Item = any;

  interface Instance {
    open: boolean;
    data: Data;
    index: number;
    setIndex: (index: number) => void;
    dataLength: number;
    list: React.RefObject<FixedSizeList>;
  }
}

type Prev = {
  height: number;
};
