import { observer } from "mobx-react";
import { memo, useMemo } from "react";
import * as yup from "yup";
import classNames from "classnames";
import { UniversalOrderNegotiationFormValues, dialog } from "@/models";
import { UniversalOrderNegotiationForm, FormContext, Button } from "@/components";
import { MainTermsTypeEvents, usageMetrics } from "@/services/UsageMetrics";
import "./ProceedTo.scss";

let Refactor: Await<ReturnType<typeof resolveRefactorExports>>;
const resolveRefactorExports = async () => ({
  ...(await import("___REFACTOR___/services/Auth")),
  ...(await import("___REFACTOR___/utils/utils")),
  ...(await import("___REFACTOR___/components/common/Input/type/Checkbox")),
  ...(await import("___REFACTOR___/services/EntityStorage")),
  ...(await import("___REFACTOR___/components/common/Icon")),
  ...(await import("___REFACTOR___/stores/UserCompany")),
});
resolveRefactorExports().then((exports) => (Refactor = exports));

const ProceedTo = observer(() => {
  const initialValues = useMemo(getInitialValues, []);
  const elements = useMemo(getElements, []);

  return (
    <div className="main-terms-procced-to">
      <h2>Proceeding...</h2>
      <UniversalOrderNegotiationForm
        initialValues={initialValues}
        elements={elements}
        context={form}
        onSubmit={onSubmit}
        yup={schema}
      />
      <div className={classNames("main-terms-procced-to-actions")}>
        <Button onClick={dialog.hide}>Cancel</Button>
        <Button onClick={form.submit} variant="action" dataTest="proceed">
          Proceed
        </Button>
      </div>
    </div>
  );

  function onSubmit() {
    if (form.values.jumpTo === "MainTerms") {
      usageMetrics.trackEvent(MainTermsTypeEvents.MAIN_TERMS_SEND_FIRM_BID);
    }
    if (form.values.jumpTo === "OnSubs") {
      usageMetrics.trackEvent(MainTermsTypeEvents.MAIN_TERMS_SEND_ON_SUBS);
    }
    if (form.values.jumpTo === "Fixed") {
      usageMetrics.trackEvent(MainTermsTypeEvents.MAIN_TERMS_SEND_CLEAN_FIXED);
    }
    dialog.resolve?.({ res: { ...form.values } });

    dialog.hide();
  }

  function getInitialValues() {
    return new UniversalOrderNegotiationFormValues({ jumpTo: "MainTerms" });
  }

  function getElements() {
    return [
      {
        type: "RadioRadioTimelimits",
        radio: {
          name: "jumpTo",
          radioLabel: "Send firm bid",
          checkedValue: "MainTerms",
          dataTest: "send-firm-bid-radio",
        },
        children: [
          {
            name: "auditNotes",
          },
        ],
        bottomText: "Proceeding with this option will set the negotiation to 'Main Terms'.",
        radioTimelimits: [
          {
            type: "RadioTimelimit",
            radio: {
              name: "timelimitVariant",
              checkedValue: "DateOffset",
              dataTest: "time-limit-radio",
            },
            timelimit: {
              name: "timelimitDateOffset",
              variant: "DateOffset",
              units: ["hour", "minute"],
              getLayout: (input) => ["Owner will have", input, "to accept the firm bid"],
            },
          },
          {
            type: "RadioTimelimit",
            radio: {
              name: "timelimitVariant",
              checkedValue: "Date",
              dataTest: "date-offset-radio",
            },
            timelimit: {
              name: "timelimitDate",
              getLayout: (input) => ["Owner to accept the firm bid by", input],
            },
          },
        ],
      },
      {
        type: "RadioRadioTimelimits",
        radio: {
          name: "jumpTo",
          checkedValue: "OnSubs",
          radioLabel: "On Subs",
          dataTest: "on-subs-radio",
        },
        children: [
          {
            name: "auditNotes",
          },
        ],
        bottomText:
          "Proceeding with this option will set the negotiation to 'On Subs'. All main terms will be considered agreed by both parties.",
        radioTimelimits: [
          {
            type: "RadioTimelimit",
            radio: {
              name: "timelimitVariant",
              checkedValue: "DateOffset",
              dataTest: "time-limit-radio",
            },
            timelimit: {
              name: "timelimitDateOffset",
              variant: "DateOffset",
              units: ["hour", "minute"],
              getLayout: (input) => ["Owner will have", input, "to meet the requirements of these subjects"],
            },
          },
          {
            type: "RadioTimelimit",
            radio: {
              name: "timelimitVariant",
              checkedValue: "Date",
              dataTest: "date-offset-radio",
            },
            timelimit: {
              name: "timelimitDate",
              getLayout: (input) => ["Owner to meet the requirements of these subjects by", input],
            },
          },
        ],
      },
      {
        type: "RadioDateTimezone",
        radio: {
          name: "jumpTo",
          checkedValue: "Fixed",
          radioLabel: "Clean Fixed",
          dataTest: "fix-immediately-radio",
        },
        date: {
          name: "cpDate",
          withoutTimezone: true,
        },
        timezone: {
          name: "userTimeZone",
          unclearable: true,
        },
        bottomText:
          "Proceeding with this option will set the negotiation as Fixed. All main terms and CP details will be considered agreed by both parties, and all subject lifted.",
        children: [
          {
            name: "auditNotes",
          },
        ],
      },
    ];
  }
});

const schema = yup
  .object()
  .test(
    "Validation0",
    "Something is wrong 0",
    function validate0(this: yup.TestContext, values: UniversalOrderNegotiationFormValues) {
      if (!values) return false;

      const error = this.createError();

      if (values.jumpTo === "Fixed" && !values.userTimeZone) {
        error.message = "You must provide a Time Zone";
        error.path = "userTimeZone";

        return error;
      }

      if (values.jumpTo === "Fixed") return true;

      if (values.timelimitVariant === "DateOffset" && !values.timelimitDateOffset) {
        error.message = "Required";
        error.path = "timelimitDateOffset";

        return error;
      }

      return true;
    }
  )
  .test(
    "Validation1",
    "Something is wrong 1",
    function validate1(this: yup.TestContext, values: UniversalOrderNegotiationFormValues) {
      if (!values) return false;
      if (values.jumpTo === "Fixed") return true;

      const error = this.createError();

      if (values.timelimitVariant === "Date" && !values.timelimitDate) {
        error.message = "Required";
        error.path = "timelimitDate";

        return error;
      }

      return true;
    }
  );

const form = new FormContext();

const Memo = memo(ProceedTo);

export { Memo as ProceedTo };
