import React, { useState, useEffect } from "react";
import { Subscription } from "rxjs";
import styles from "./LiftingsCargoDetails.module.scss";
import classNames from "classnames";
import Button from "__legacy/sharedFolder/components/common/Button/Button";
import { CargoTypesField } from "sharedFolder/components/common/EditorFields/CargoTypesField";
import Heading from "sharedFolder/components/common/Heading/Heading";
import { LaycanWithNotesField } from "sharedFolder/components/common/EditorFields/LaycanWithNotesField";
import { ILocationView, isLocationValid } from "sharedFolder/Models/ILocation";
import { NotesField } from "sharedFolder/components/common/EditorFields/NotesField";
import { CargoSizeField } from "sharedFolder/components/common/EditorFields/CargoSizeField";
import {
  ICargoSizeView,
  IUnitValueView,
  isLaycanValid,
  ILaycanView,
  ICargoTypeView,
  isCargoTypeViewValid,
  IVesselView,
} from "sharedFolder/Models/IDetails";
import { FreightRateField } from "sharedFolder/components/common/EditorFields/FreightRateField";
import { isUnitValueValid, isCargoSizeValid } from "sharedFolder/components/common/fields";
import { DemurrageField } from "sharedFolder/components/common/EditorFields/DemurrageField";
import { LiftingsModalConfirm } from "./LiftingsModalConfirm";
import { useConfig } from "__legacy/sharedFolder/ConfigurationContext";
import { declareLiftingCargo } from "../../services/declareLifting";
import { updateLiftingCargo } from "../../services/updateLifting";
import { captureLiftingCargo } from "../../services/captureLifting";
import { mapCargoType } from "sharedFolder/mappers/mapCargoType";
import { mapCargoSize } from "sharedFolder/mappers/mapCargoSize";
import { mapLocation } from "sharedFolder/mappers/mapLocation";
import { mapFreightRate, mapDemurrage } from "sharedFolder/mappers/mapUnitValue";
import { useDismissableNotification } from "./DismissableNotificationContext";
import { mapLaycan } from "sharedFolder/mappers/mapLaycan";
import { LocationField } from "sharedFolder/components/common/EditorFields/LocationField";
import { formatDateWithTime } from "sharedFolder/Utilities/getNow";
import { LiftingsModalCapture } from "./LiftingsModalCapture";
import { VesselSearchField } from "sharedFolder/components/common/EditorFields/VesselSearchField";
import { mapVessel } from "sharedFolder/mappers/mapVessel";
import { useUser } from "__legacy/dashboard/contexts/UserProvider";

interface ILiftingsCargoDetailsProps {
  liftingId?: string;
  liftingCargoId?: string;
  updateToken: string;
  orderId: string;
  onCancel: () => void;
  createdOn?: string;
  isEditable: boolean;
  liftingsDetails: ILiftingsDetails;
  buttons?: JSX.Element;
  isDealCapture: boolean;
  canChartererManagedLiftings: boolean;
}

interface ILiftingsDetails {
  laycan?: ILaycanView;
  cargoType?: ICargoTypeView;
  cargoSize?: ICargoSizeView;
  load?: ILocationView;
  discharge?: ILocationView;
  freightRate?: IUnitValueView;
  demurrage?: IUnitValueView;
  notes?: string;
  vessel?: IVesselView;
}

export const LiftingsCargoDetails = (props: ILiftingsCargoDetailsProps) => {
  const [form, setForm] = useState<ILiftingsDetails>(props.liftingsDetails);
  const [confirmDialog, setConfirmDialog] = useState<boolean>(false); // {delivery: undefined}
  const [captureDialog, setCaptureDialog] = useState<boolean>(false);
  const [formState, setFormMode] = useState<"showErrors" | "saving" | "success">();
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [validation, setValidation] = useState<{
    isValid: boolean;
    errors: string[];
  }>({ isValid: false, errors: [] });
  const [expandedItem, sei] = useState<keyof ILiftingsDetails | null>();
  const dismissableNotification = useDismissableNotification();
  const config = useConfig();
  const isDealCapture = props.isDealCapture;
  const canChartererManagedLiftings = props.canChartererManagedLiftings;
  const hasChartererRole = useUser().companyRoles.includes("charterer");
  useEffect(() => {
    return () => {
      subscriptions && subscriptions.map((s) => s.unsubscribe());
    };
  }, [subscriptions]);

  function setExpandedItem(name: keyof ILiftingsDetails) {
    if (props.isEditable) sei(expandedItem !== name ? name : null);
  }

  function displayIsEditable(name: keyof ILiftingsDetails) {
    return name === expandedItem && props.isEditable;
  }

  function updateForm(key: keyof ILiftingsDetails, value: any) {
    const updatedForm = {
      ...form,
      [key]: value,
    };
    setForm(updatedForm);
    runValidation(updatedForm);
  }

  function runValidation(form: ILiftingsDetails) {
    const [errors, isValid] = validateLiftingsCargoDetails(form, props.isDealCapture);

    setValidation({
      errors,
      isValid,
    });
  }

  async function handleConfirm() {
    if (props.createdOn) {
      await handleUpdate();
    } else {
      await handleCreate();
    }
  }

  async function handleCreate() {
    setFormMode("saving");
    const details = {
      updateToken: props.updateToken,
      laycan: mapLaycan.toApiOrUndefined(form.laycan),
      cargoType: mapCargoType.toApiOrUndefined(form.cargoType),
      cargoSize: mapCargoSize.toApiOrUndefined(form.cargoSize),
      loadLocation: mapLocation.toApiOrUndefined(form.load),
      dischargeLocation: mapLocation.toApiOrUndefined(form.discharge),
      freightRate: mapFreightRate.toApiOrUndefined(form.freightRate),
      demurrage: mapDemurrage.toApiOrUndefined(form.demurrage),
      liftingNotes: form.notes,
    };
    setSubscriptions((s) => [
      ...s,
      declareLiftingCargo(config.ctradeUrl, props.orderId, props.liftingId!, details).subscribe(
        () => {
          setFormMode("success");

          setConfirmDialog(false);
          props.onCancel();
          dismissableNotification.showNotification("DECLARED_CARGO");
        },
        (err) => {
          setFormMode("showErrors");
          throw new Error(`Unexpected status ${err.status}`);
        }
      ),
    ]);
  }

  async function handleCapture() {
    setFormMode("saving");
    const details = {
      updateToken: props.updateToken,
      laycan: mapLaycan.toApiOrUndefined(form.laycan),
      cargoType: mapCargoType.toApiOrUndefined(form.cargoType),
      cargoSize: mapCargoSize.toApiOrUndefined(form.cargoSize),
      loadLocation: mapLocation.toApiOrUndefined(form.load),
      dischargeLocation: mapLocation.toApiOrUndefined(form.discharge),
      freightRate: mapFreightRate.toApiOrUndefined(form.freightRate),
      demurrage: mapDemurrage.toApiOrUndefined(form.demurrage),
      liftingNotes: form.notes,
      vessel: mapVessel.toApiOrUndefined(form.vessel),
    };
    setSubscriptions((s) => [
      ...s,
      captureLiftingCargo(config.ctradeUrl, props.orderId, props.liftingId!, details).subscribe(
        () => {
          setFormMode("success");

          setCaptureDialog(false);
          props.onCancel();
          dismissableNotification.showNotification("CAPTURED_CARGO");
        },
        (err) => {
          setFormMode("showErrors");
          throw new Error(`Unexpected status ${err.status}`);
        }
      ),
    ]);
  }

  async function handleUpdate() {
    setFormMode("saving");
    const details = {
      updateToken: props.updateToken,
      laycan: mapLaycan.toApiOrUndefined(form.laycan),
      cargoType: mapCargoType.toApiOrUndefined(form.cargoType),
      cargoSize: mapCargoSize.toApiOrUndefined(form.cargoSize),
      loadLocation: mapLocation.toApiOrUndefined(form.load),
      dischargeLocation: mapLocation.toApiOrUndefined(form.discharge),
      freightRate: mapFreightRate.toApiOrUndefined(form.freightRate),
      demurrage: mapDemurrage.toApiOrUndefined(form.demurrage),
      liftingNotes: form.notes,
    };
    setSubscriptions((s) => [
      ...s,
      updateLiftingCargo(config.ctradeUrl, props.orderId, props.liftingId!, props.liftingCargoId!, details).subscribe(
        () => {
          setFormMode("success");

          setConfirmDialog(false);
          props.onCancel();
          dismissableNotification.showNotification("DECLARED_CARGO");
        },
        (err) => {
          setFormMode("showErrors");
          throw new Error(`Unexpected status ${err.status}`);
        }
      ),
    ]);
  }

  function confirm() {
    runValidation(form);
    if (!validation.isValid) {
      setFormMode("showErrors");
    } else {
      setConfirmDialog(true);
    }
  }

  function capture() {
    runValidation(form);

    if (!validation.isValid) {
      setFormMode("showErrors");
    } else {
      setCaptureDialog(true);
    }
  }

  function isFieldValid(key: keyof ILiftingsDetails) {
    return validation.errors.indexOf(key) < 0; //if "key" not in errors, there is no error
  }

  return (
    <>
      <section id="liftingsCargoDetails" className={classNames(formState === "showErrors" ? "validationMode" : null)}>
        {canChartererManagedLiftings && hasChartererRole ? (
          <div className={styles.headingWrapperWithoutButtons}>
            <div className={styles.title}>
              {isDealCapture ? <Heading>Capture Cargo Details</Heading> : <Heading>Cargo Details</Heading>}
              <span>Your broker is managing this lifting </span>
              {props.createdOn && <span className={styles.spanMargin}>{formatDateWithTime(props.createdOn)}</span>}
            </div>
          </div>
        ) : (
          <div className={styles.headingWrapper}>
            <div className={styles.title}>
              {isDealCapture ? <Heading>Capture Cargo Details</Heading> : <Heading>Cargo Details</Heading>}
              {props.createdOn && <span>{formatDateWithTime(props.createdOn)}</span>}
            </div>
            <div className={styles.buttons}>{props.buttons}</div>
          </div>
        )}
        <LaycanWithNotesField
          isFieldValid={isFieldValid}
          updateForm={(key: string, laycan: ILaycanView) => updateForm("laycan", laycan)}
          selectedItem={form.laycan}
          setExpandedItem={setExpandedItem}
          isExpanded={displayIsEditable("laycan")}
          isRequired={isValidationRequired(canChartererManagedLiftings, hasChartererRole)}
        />

        <CargoTypesField
          required={isValidationRequired(canChartererManagedLiftings, hasChartererRole)}
          isFieldValid={isFieldValid}
          updateForm={updateForm}
          selectedItem={form.cargoType}
          setExpandedItem={setExpandedItem}
          isExpanded={displayIsEditable("cargoType")}
        />

        <CargoSizeField
          isFieldValid={isFieldValid}
          updateForm={updateForm}
          selectedItem={form.cargoSize}
          setExpandedItem={setExpandedItem}
          isExpanded={displayIsEditable("cargoSize")}
          required={isValidationRequired(canChartererManagedLiftings, hasChartererRole)}
        />

        <LocationField
          required={isValidationRequired(canChartererManagedLiftings, hasChartererRole)}
          locationType="load"
          isFieldValid={isFieldValid}
          updateForm={updateForm}
          selectedItem={form.load}
          setExpandedItem={setExpandedItem}
          isExpanded={displayIsEditable("load")}
        />

        <LocationField
          required={isValidationRequired(canChartererManagedLiftings, hasChartererRole)}
          locationType="discharge"
          isFieldValid={isFieldValid}
          updateForm={updateForm}
          selectedItem={form.discharge}
          setExpandedItem={setExpandedItem}
          isExpanded={displayIsEditable("discharge")}
        />

        <FreightRateField
          isFieldValid={isFieldValid}
          updateForm={updateForm}
          selectedItem={form.freightRate}
          setExpandedItem={setExpandedItem}
          isExpanded={displayIsEditable("freightRate")}
          required={isValidationRequired(canChartererManagedLiftings, hasChartererRole)}
        />

        <DemurrageField
          isFieldValid={isFieldValid}
          updateForm={updateForm}
          selectedItem={form.demurrage}
          setExpandedItem={setExpandedItem}
          isExpanded={displayIsEditable("demurrage")}
          required={isValidationRequired(canChartererManagedLiftings, hasChartererRole)}
        />

        <NotesField
          labelOverride="Additional Notes"
          notesType="notes"
          isFieldValid={isFieldValid}
          updateForm={updateForm}
          selectedItem={form.notes}
          setExpandedItem={setExpandedItem}
          isExpanded={displayIsEditable("notes")}
        />

        {isDealCapture && (
          <VesselSearchField
            isFieldValid={isFieldValid}
            updateForm={updateForm}
            selectedItem={form.vessel}
            setExpandedItem={setExpandedItem}
            isExpanded={displayIsEditable("vessel")}
            required={isDealCapture}
          />
        )}

        {props.isEditable && (
          <div className={styles.actions}>
            {formState === "showErrors" && !validation.isValid && (
              <p className={styles.formErrorMessage} data-test="form-error-message">
                Please correct invalid entries
              </p>
            )}
            {canChartererManagedLiftings && hasChartererRole ? null : (
              <Button
                type="flat"
                onClick={() => {
                  // reset form to the original passed in values
                  setForm(props.liftingsDetails);
                  props.onCancel();
                }}
                dataTest="Cancel"
              >
                Cancel
              </Button>
            )}
            {isDealCapture ? (
              <Button onClick={capture} disabled={formState === "saving"} type="action" dataTest="Capture Cargo">
                Capture Cargo
              </Button>
            ) : canChartererManagedLiftings && hasChartererRole ? null : (
              <Button onClick={confirm} disabled={formState === "saving"} type="action" dataTest="Declare to Owner">
                Declare to Owner
              </Button>
            )}
          </div>
        )}
      </section>
      {confirmDialog && (
        <LiftingsModalConfirm
          onCancel={() => setConfirmDialog(false)}
          onConfirm={handleConfirm}
          isSaving={formState === "saving"}
        />
      )}
      {captureDialog && (
        <LiftingsModalCapture
          onCancel={() => setCaptureDialog(false)}
          onConfirm={handleCapture}
          isSaving={formState === "saving"}
        />
      )}
    </>
  );
};

function isVesselValid(vessel: IVesselView | undefined): boolean {
  return vessel !== undefined && vessel !== null;
}

function isValidationRequired(canChartererManagedLiftings: boolean, hasChartererRole: boolean) {
  return canChartererManagedLiftings && hasChartererRole ? false : true;
}

function validateLiftingsCargoDetails(form: ILiftingsDetails, isDealCapture: boolean): [string[], boolean] {
  const isCargoSizeInvalid = form.cargoSize && isCargoSizeValid(form.cargoSize, true);

  const errors: string[] = [
    !isLaycanValid(form.laycan) ? "laycan" : "",
    !isCargoSizeInvalid ? "cargoSize" : "",
    !isCargoTypeViewValid(form.cargoType, true) ? "cargoType" : "",
    !isLocationValid(form.load, true) ? "load" : "",
    !isLocationValid(form.discharge, true) ? "discharge" : "",
    !isUnitValueValid(form.freightRate && form.freightRate.value, true) ? "freightRate" : "",
    !isUnitValueValid(form.demurrage && form.demurrage.value, true) ? "demurrage" : "",
    isDealCapture && !isVesselValid(form.vessel) ? "vessel" : "",
  ].filter(Boolean);

  const isValid = errors.length === 0;

  return [errors, isValid];
}
