import { TradeAPI } from "@/apis";
import { UnverifiedAccountEditor } from "@/components";
import { DataModel, DataModelProps } from "../DataModel";

export class UnverifiedAccount extends DataModel<Data> {
  toString() {
    return this.accountName;
  }
}

class UnverifiedAccountProps extends DataModelProps<Data> {}

UnverifiedAccountProps.prototype.editor = UnverifiedAccountEditor;

UnverifiedAccount.prototype._ = new UnverifiedAccountProps();

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

export interface UnverifiedAccount extends Data {}

type Data = DeepPartial<TradeAPI["UnverifiedAccount"]>;
