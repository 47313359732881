import React from "react";
import { DateInput } from "___REFACTOR___/components";
import { Field } from "../../Field";
import { Container } from "../../Container";

function DateField(props: DateField.Props) {
  return (
    <Container {...props} type="Date">
      <DateInput {...props} />
    </Container>
  );
}

export { DateField };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace DateField {
  export interface Props extends Omit<DateInput.Props, "type">, Omit<Field.Props, "value" | "onChange"> {}

  export { DateInput as Input };
}
