import React, { useContext } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { SummaryDetails } from "@/components";
import { Context } from "../../../Orders";
import styles from "./COADetails.module.scss";

function COADetails() {
  const { orderId } = useParams<StringRecord>();
  const context = useContext(Context);
  const { orderNegotiationStore } = context;
  const order = orderId ? orderNegotiationStore?.upsertOrderModel({ id: orderId }) : undefined;

  return (
    <div className={styles.container}>
      <SummaryDetails skinny list={order?.getDetailsModelArray({ type: "Coa" })} />
    </div>
  );
}

const Observer = observer(COADetails);

export { Observer as COADetails };
