import { IConfirmLifting, IResponse } from "../../api/negotiations/models";
import { Observable } from "rxjs";

export function confirmLifting(
  ctradeUrl: string,
  orderId: string,
  liftingId: string,
  liftingCargoId: string,
  imo: string,
  updateToken: string,
  updater: (
    orderId: string,
    liftingId: string,
    liftingCargoId: string,
    imo: string,
    body: IConfirmLifting
  ) => Observable<IResponse>
) {
  const body: IConfirmLifting = {
    updateToken,
  };
  return updater(orderId, liftingId, liftingCargoId, imo, body);
}
