import React from "react";
import { BallastBonus, BallastBonusProps } from "@/models";
import { UnitValueEditorProps, UnitValueEditor } from "../UnitValue";

export function BallastBonusEditor(props: Props) {
  props = { ...props };

  props.className = `${props.className} BallastBonus`;

  function onChange(value: BallastBonus) {
    const next = new BallastBonus(value);

    props.onChange?.(next);
  }

  return (
    <UnitValueEditor
      {...props}
      units={BallastBonusProps.prototype.units}
      defaultUnit={BallastBonusProps.prototype.JSONDefaults?.unit}
      onChange={onChange}
      dataTest={`${props.dataTest}`}
    />
  );
}

interface Props extends Omit<UnitValueEditorProps, "value" | "onChange"> {
  value?: BallastBonus;
  onChange?: (value: BallastBonus) => void;
}

export type BallastBonusEditorProps = Props;
