import React from "react";
import { Details } from "../../Details/Details";

export function Inactive(props: Props) {
  return (
    <div className="order-negotiations-negotiation-status">
      <Details />
    </div>
  );
}

interface Props {
  children?: React.ReactNode;
}
