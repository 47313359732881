import { Observable, Subject } from "rxjs";

/**
 * Typed wrapper for fetch, returns Observable with slim error handling.
 * Reduces boilerplate code.
 * @param fetch Fetch command, returns Promise of Response
 */
export function observableFetch<T>(fetch: () => Promise<Response>): Observable<T> {
  return Observable.create((subject: Subject<T>) => {
    fetch()
      .then((response) => {
        if (!response.ok) {
          subject.error(`${response.status}: ${response.statusText}`);
        } else {
          return response.json();
        }
      })
      .then((data: T) => {
        subject.next(data);
        subject.complete();
      })
      .catch((error) => subject.error(error));
  });
}
