import { TradeAPI } from "@/apis";
import { ProformaLayoutEditor } from "@/components";
import { DataModel, DataModelProps } from "../DataModel";

export class ProformaLayout extends DataModel<Data> {
  onUpdate() {
    this.id = this.layoutId || this.id || this.key;
    this.name = this.name || this.proformaKey || this.text;
  }

  toString() {
    return this.name;
  }
}

class ProformaLayoutProps extends DataModelProps<Data> {}

ProformaLayoutProps.prototype.editor = ProformaLayoutEditor;

ProformaLayout.prototype._ = new ProformaLayoutProps();

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

export interface ProformaLayout extends Data {}

type Data = DeepPartial<TradeAPI["ProformaLayout"]>;
