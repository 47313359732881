import { CLDDUOwnerEditor } from "___REFACTOR___/components";
import { CLDDU, CLDDUProps } from "../CLDDU";

export class CLDDUOwner extends CLDDU {
  static get Editor() {
    return CLDDUOwnerEditor;
  }

  static label = "Invitee";

  toJSON() {
    return this.email;
  }
}

class CLDDUOwnerProps extends CLDDUProps {}

CLDDUOwner.prototype.Props = CLDDUOwnerProps;
CLDDUOwnerProps.prototype.Editor = CLDDUOwnerEditor;
CLDDUOwnerProps.prototype.label = "Invitee";

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

export interface CLDDUOwner {
  Props: typeof CLDDUOwnerProps;
  _: CLDDUOwnerProps;
}
