import React from "react";
import * as ClassNames from "classnames";
import classNames from "classnames";
import "./CircularSpinner.scss";

function CircularSpinner(props: CircularSpinner.Props) {
  const { className: propsClassName, ...rest } = props;
  const className = classNames(propsClassName, "CircularSpinner MuiCircularProgress-root MuiCircularProgress-indeterminate");

  return (
    <circular-spinner {...rest} class={className} role="progressbar">
      <svg className="MuiCircularProgress-svg" viewBox="22 22 44 44">
        <circle
          className="MuiCircularProgress-circle MuiCircularProgress-circleIndeterminate"
          cx="44"
          cy="44"
          r="18.4"
          fill="none"
          strokeWidth="7.2"
        ></circle>
      </svg>
    </circular-spinner>
  );
}

export { CircularSpinner };

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

declare namespace CircularSpinner {
  export interface Props extends Omit<ReactHTMLFormProps, "className"> {
    className?: ClassNames.Argument;
    disabled?: boolean;
  }

  type ReactHTMLFormProps = React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLDivElement>, HTMLDivElement>;
}
