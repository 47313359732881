import React, { useState } from "react";
import { Observable } from "rxjs";
import { IAttachment } from "__legacy/sharedFolder/Models/IAttachment";
import { CompanyRole } from "sharedFolder/Models/CompanyRole";
import { IAttachmentErrors } from "__legacy/sharedFolder/components/common/interfaces";
import AttachmentErrors from "__legacy/sharedFolder/components/common/AttachmentErrors/AttachmentErrors";
import { AttachmentsList } from "./AttachmentsList";
import { AttachmentUploadDrop } from "./AttachmentUploadDrop";
import { auth } from "@/models";
import "./Attachments.scss";
import { UploadAttachmentResult } from "__legacy/negotiations/services/attachments";

interface IProps {
  attachments: IAttachment[];
  onDelete: (fileId: string) => Observable<{ success: boolean }>;
  onDownload: (fileId: string, fileName: string) => void;
  onUpload: (formData: FormData) => Observable<UploadAttachmentResult>;
  userRoles: Array<CompanyRole>;
  fileDropAreaVisible: boolean;
}

export const OrderAttachments = (props: IProps) => {
  const { user } = auth.trade as any;

  const canUploadOrderAttachments = !user.CompanyRoles.includes("broker");

  const [validationErrors, setValidationErrors] = useState<null | IAttachmentErrors>(null);

  return (
    <>
      <div className="attachments">
        <AttachmentsList
          {...props}
          areOrderLevelAttachments={true}
          onChangeValidationErrors={(validationErrors: IAttachmentErrors | null) => setValidationErrors(validationErrors)}
          emptyMessage="There are currently no files attached for this order"
        />
        {props.fileDropAreaVisible && canUploadOrderAttachments && (
          <AttachmentUploadDrop
            numberOfAttachments={props.attachments.length}
            onUpload={props.onUpload}
            onChangeValidationErrors={(validationErrors: IAttachmentErrors | null) => setValidationErrors(validationErrors)}
          />
        )}
      </div>
      <AttachmentErrors errors={validationErrors} />
    </>
  );
};
