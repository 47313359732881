import { useState, useEffect } from "react";
import moment, { duration as momentDuration, Moment } from "moment";
import { useInterval } from "./useInterval";
import { getNow } from "sharedFolder/Utilities/getNow";
import { ITimerCount } from "sharedFolder/components/common/interfaces";

const leadingZero = (value: number): string => {
  return value.toString().padStart(2, "0");
};

function calculateCountdown(endDate: Moment): ITimerCount {
  if (!endDate.isValid()) {
    return { Days: "", Hours: "", Minutes: "", Seconds: "", Expired: true };
  }
  const now = getNow();
  const duration = momentDuration(endDate.diff(now));

  return {
    Days: leadingZero(Math.floor(duration.asDays())),
    Hours: leadingZero(duration.hours()),
    Minutes: leadingZero(duration.minutes()),
    Seconds: leadingZero(duration.seconds()),
    Expired: now.add(1, "seconds").isAfter(endDate),
  };
}

export const getIsDateExpired = (dateTime: string | null): boolean => {
  if (dateTime !== null) {
    const countDown = calculateCountdown(moment(dateTime));
    return countDown.Expired;
  }
  return true;
};

export function hasDateExpired(date: string | null) {
  if (date === null) {
    return true; // expired if its null
  }
  return calculateCountdown(moment(date)).Expired;
}

type IntervalFunction = (timeDiff: ITimerCount) => void;

export function useTimer(callback: IntervalFunction, endDateTime: string) {
  const [expiredEventSent, setExpiredEventSent] = useState<boolean>(false);

  useInterval(() => {
    const countDown = calculateCountdown(moment(endDateTime));
    if (countDown.Expired && !expiredEventSent) {
      setExpiredEventSent(true);
    }
    callback(countDown);
  }, 1000);

  useEffect(() => {
    const countDown = calculateCountdown(moment(endDateTime));
    if (countDown.Expired && !expiredEventSent) {
      setExpiredEventSent(true);
    }
    callback(countDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDateTime]);
}
