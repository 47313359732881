import React, { useMemo } from "react";
import { testSelectors } from "@/constants";
import { RouteSeed, router, standardOrderNegotiationStore } from "@/models";
import { FormContext } from "@/components/common/Form/Context/Context";
import { Orders, Context } from "@/components/Orders";
import { getOrderColumnMap, commonColumnMap, getNegotiationColumnMap } from "@/components/Orders/Grid/columns";
import {
  Order as OrderComp,
  Creation,
  Active,
  Capture,
  New,
  Withdrawn,
  Nomination,
  Inactive,
  Failed,
  Firm,
  TermsLoading,
  MainTerms,
  OnSubs,
  SubsLifted,
  SubsFailed,
  Fixed,
  Archived,
} from "@/components/Orders/Order";
import { getAbstractDetailArrayForDisplay } from "@/models/Order/utils";

const attachments = {
  label: "Attachments",
  getMiscInfo: ({ orderId }) => {
    const order = orderId ? standardOrderNegotiationStore.upsertOrderModel({ id: orderId }) : undefined;
    const loading = order?._.status.loading;
    let badgeCount = order?.attachments?.length || 0;

    const fixedNegotiationAttachments = order?.negAttachments?.filter((attachment) => attachment.status === "Fixed");
    const fixedNegotiationAttachmentsCount = fixedNegotiationAttachments?.length || 0;
    const negotiationsAttachmentCount = order?.negAttachments?.length || 0;

    if (fixedNegotiationAttachments?.length) {
      badgeCount += fixedNegotiationAttachmentsCount;
    } else {
      badgeCount += negotiationsAttachmentCount;
    }

    return { badgeCount, loading };
  },
};

const negotiations = {
  label: "Negotiations",
};

const distribution = {
  label: "Distribution",
};

const initialization = {
  label: "Negotiations",
};

const circulate = {
  path: "/circulate/:noOfUsers?",
  label: "Circulate",
};

export const orders = {
  path: "/orders",
  comp: OrdersWrapper,
  getMiscInfo,
  icon: "orders",
  desc: "Orders",
  seaChatButtonPos: "bottomLeft",
  auth: (auth) => {
    const companyRoleMap = auth.trade.user?._.companyRoleMap;

    return companyRoleMap?.charterer || companyRoleMap?.broker;
  },
  sidenav: ["orders", "owner", "coa"],
  children: {
    order: {
      path: "/:orderId",
      comp: OrderComp,
      children: {
        new: {
          comp: New,
          redirect: "./creation",
          children: {
            creation: {
              comp: Creation,
              label: "Creation",
            },
            capture: {
              comp: Capture,
              label: "Deal Capture",
            },
          },
        },
        inactive: {
          comp: Inactive,
          nav: ["./initialization", "./distribution", "./attachments"],
          redirect: "./initialization",
          children: { initialization, distribution, circulate, attachments },
        },
        inactiveNegotiations: {
          comp: Inactive,
          nav: ["./negotiations", "./distribution", "./attachments"],
          redirect: "./negotiations",
          children: { distribution, circulate, negotiations, attachments },
        },
        withdrawn: {
          comp: Withdrawn,
          redirect: "./negotiations",
          nav: ["./negotiations", "./distribution", "./attachments"],
          children: { distribution, negotiations, attachments, circulate },
        },
        nomination: {
          comp: Nomination,
          redirect: "./negotiations",
          children: { distribution, negotiations },
        },
        active: {
          comp: Active,
          redirect: "./negotiations",
          nav: ["./negotiations", "./distribution", "./attachments"],
          children: { distribution, negotiations, attachments, circulate },
        },
        failed: {
          comp: Failed,
          redirect: "./negotiations",
          nav: ["./negotiations", "./distribution", "./attachments"],
          children: { distribution, negotiations, attachments, circulate },
        },
        firm: {
          comp: Firm,
          redirect: "./negotiations",
          nav: ["./negotiations", "./distribution", "./attachments"],
          children: { distribution, negotiations, attachments, circulate },
        },
        termsLoading: {
          comp: TermsLoading,
          redirect: "./negotiations",
          nav: ["./negotiations", "./distribution", "./attachments"],
          children: { distribution, negotiations, attachments, circulate },
        },
        mainTerms: {
          comp: MainTerms,
          redirect: "./negotiations",
          nav: ["./negotiations", "./distribution", "./attachments"],
          children: { distribution, negotiations, attachments, circulate },
        },
        onSubs: {
          comp: OnSubs,
          redirect: "./negotiations",
          nav: ["./negotiations", "./distribution", "./attachments"],
          children: { distribution, negotiations, attachments, circulate },
        },
        subsLifted: {
          comp: SubsLifted,
          redirect: "./negotiations",
          nav: ["./negotiations", "./distribution", "./attachments"],
          children: { distribution, negotiations, attachments, circulate },
        },
        subsFailed: {
          comp: SubsFailed,
          redirect: "./negotiations",
          nav: ["./negotiations", "./distribution", "./attachments"],
          children: { distribution, negotiations, attachments, circulate },
        },
        fixed: {
          comp: Fixed,
          redirect: "./negotiations",
          nav: ["./negotiations", "./distribution", "./attachments"],
          children: { distribution, negotiations, attachments, circulate },
        },
        archived: {
          comp: Archived,
          redirect: "./negotiations",
          nav: ["./negotiations", "./distribution", "./attachments"],
          children: { distribution, negotiations, attachments, circulate },
        },
      },
    },
  },
} as RouteSeed;

function getMiscInfo() {
  return {
    unseenCount: standardOrderNegotiationStore.unseenOrderCount,
    loading: standardOrderNegotiationStore.orderArrayStatus.loading,
  };
}

function OrdersWrapper(props) {
  const context = useMemo(getContext, []);

  return <Orders {...props} context={context} />;
}

function getContext() {
  context.orderNegotiationStore = standardOrderNegotiationStore;
  context.routes.orders = router.get("orders");
  context.routes.order.index = router.get("orders.order");
  context.routes.order.new = router.get("orders.order.new");
  context.routes.order.inactive = router.get("orders.order.inactive");
  context.routes.order.inactiveNegotiations = router.get("orders.order.inactiveNegotiations");
  context.routes.order.withdrawn = router.get("orders.order.withdrawn");
  context.routes.order.nomination = router.get("orders.order.nomination");
  context.routes.order.active = router.get("orders.order.active");
  context.routes.order.failed = router.get("orders.order.failed");
  context.routes.order.firm = router.get("orders.order.firm");
  context.routes.order.termsLoading = router.get("orders.order.termsLoading");
  context.routes.order.mainTerms = router.get("orders.order.mainTerms");
  context.routes.order.onSubs = router.get("orders.order.onSubs");
  context.routes.order.subsLifted = router.get("orders.order.subsLifted");
  context.routes.order.subsFailed = router.get("orders.order.subsFailed");
  context.routes.order.fixed = router.get("orders.order.fixed");
  context.routes.order.archived = router.get("orders.order.archived");

  if (context.view.grid.props) {
    const orderColumnMap = getOrderColumnMap();

    context.view.grid.props.columnDefs = [
      commonColumnMap.gutterForUnseenBookmark,
      orderColumnMap.checkbox,
      orderColumnMap.accountAndOrderReference,
      orderColumnMap.cargoSizeAndCargoType,
      orderColumnMap.laycanStartAndLaycanEnd,
      orderColumnMap.locations,
      orderColumnMap.voyTcCount,
      orderColumnMap.statusAndLastUpdated,
      {
        ...orderColumnMap.createdByAndCreatedOn,
        $defaultColumnState: { sort: "desc", multiValueSortName: "createdOn" },
      },
      orderColumnMap.orderChat,
    ];

    context.view.grid.props.columnDefs?.push(orderColumnMap.kebab);
  }

  if (context.view.order.negotiations.grid) {
    const negotiationColumnMap = getNegotiationColumnMap();

    context.view.order.negotiations.grid.columnDefs = [
      commonColumnMap.gutterForUnseenBookmark,
      commonColumnMap.chevron,
      negotiationColumnMap.vessel,
      negotiationColumnMap.ownerAccountOrInvitee,
      {
        ...negotiationColumnMap["freightRate | hireRate of mainTermsDetails | firm bid/offer | offer"],
        $defaultColumnState: {
          sort: "asc",
          multiValueSortName: "freightRate | hireRate of mainTermsDetails | firm bid/offer | offer",
        },
      },
      negotiationColumnMap["demurrage | ballastBonus of mainTermsDetails | firm bid/offer | offer"],
      negotiationColumnMap.lastUpdated,
      negotiationColumnMap.status,
      commonColumnMap.attachments,
      negotiationColumnMap.chat,
      commonColumnMap.kebab,
    ];
  }

  return context;
}

export const context = {
  area: "ito",

  legacyOrderType: "ITO",

  routes: {
    order: {},
  },

  view: {
    grid: {
      btn: {
        capture: {
          dataTest: "Deal capture",
          icon: "flash-on",
          label: "Deal capture",
          variant: "action",
        },
        add: {
          dataTest: "new order",
          label: "Create new VOY/TC order",
          icon: "add-circle-outline",
          variant: "action",
        },
        kebab: {
          dataTest: "More actions",
          icon: "kebab",
          variant: "text",
        },
      },
      name: "Orders",
      props: {},
    },
    order: {
      name: "Order",
      orderSearchQuery: "&orderType=std",
      capture: {
        title: "Deal Capture - Add Order Details",
        buttons: {
          submit: "Continue",
          cancel: "",
        },
        form: {
          mutateValues: mutateNewDealCaptureValues,
          elements: [
            {
              type: "CollapsibleGroup",
              label: "Order Terms",

              children: [
                { type: "CollapsibleRow", name: "chartererAccount" },
                { type: "CollapsibleRow", name: "laycan" },
                { type: "CollapsibleRow", name: "cargoType" },
                { type: "CollapsibleRow", name: "addressCommission" },
                { type: "CollapsibleRow", name: "brokerCommission" },
              ],
            },
            {
              name: "types",
              type: "CollapsibleGroup",
              collapseControl: "radio",
              label: "Voyage Terms",
              orderType: "Voy",
              dataTest: testSelectors.newOrderFormVoyageTermsCheckbox,
              getState: getRadioInputState,
              children: getAbstractDetailArrayForDisplay(
                {
                  cargoSize: { type: "CollapsibleRow", name: "cargoSize" },
                  loadLocation: { type: "CollapsibleRow", name: "loadLocation" },
                  dischargeLocation: { type: "CollapsibleRow", name: "dischargeLocation" },
                  voyageNotes: { type: "CollapsibleRow", name: "voyageNotes" },
                },
                { type: "Voy" }
              ),
            },

            {
              name: "types",
              type: "CollapsibleGroup",
              collapseControl: "radio",
              label: "Time Charter Terms",
              orderType: "Tct",
              dataTest: testSelectors.newOrderFormTCTTermsCheckbox,
              getState: getRadioInputState,
              children: getAbstractDetailArrayForDisplay(
                {
                  vesselSize: { type: "CollapsibleRow", name: "vesselSize" },
                  deliveryLocation: { type: "CollapsibleRow", name: "deliveryLocation", hideSafes: true },
                  viaLocation: { type: "CollapsibleRow", name: "viaLocation", hideSafes: true },
                  redeliveryLocation: { type: "CollapsibleRow", name: "redeliveryLocation", hideSafes: true },
                  duration: { type: "CollapsibleRow", name: "duration" },
                  tctNotes: { type: "CollapsibleRow", name: "tctNotes" },
                },
                { type: "Tct" }
              ),
            },
          ],
          required: ["types", "laycan", "chartererAccount"],
          context: new FormContext(),
        },
      },
      creation: {
        title: "Create Order",
        buttons: {
          submit: "Create Order",
          cancel: "",
        },
        form: {
          elements: [
            {
              type: "CollapsibleGroup",
              label: "Order Terms",

              children: [
                { type: "CollapsibleRow", name: "chartererAccount" },
                { type: "CollapsibleRow", name: "laycan" },
                { type: "CollapsibleRow", name: "cargoType" },
                { type: "CollapsibleRow", name: "addressCommission" },
                { type: "CollapsibleRow", name: "brokerCommission" },
              ],
            },
            {
              name: "types",
              type: "CollapsibleGroup",
              collapseControl: "checkbox",
              label: "Voyage Terms",
              orderType: "Voy",
              dataTest: testSelectors.newOrderFormVoyageTermsCheckbox,
              getState: getCheckboxInputState,
              children: getAbstractDetailArrayForDisplay(
                {
                  cargoSize: { type: "CollapsibleRow", name: "cargoSize" },
                  loadLocation: { type: "CollapsibleRow", name: "loadLocation" },
                  dischargeLocation: { type: "CollapsibleRow", name: "dischargeLocation" },
                  voyageNotes: { type: "CollapsibleRow", name: "voyageNotes" },
                },
                { type: "Voy" }
              ),
            },

            {
              name: "types",
              type: "CollapsibleGroup",
              collapseControl: "checkbox",
              label: "Time Charter Terms",
              orderType: "Tct",
              dataTest: testSelectors.newOrderFormTCTTermsCheckbox,
              getState: getCheckboxInputState,
              children: getAbstractDetailArrayForDisplay(
                {
                  vesselSize: { type: "CollapsibleRow", name: "vesselSize" },
                  deliveryLocation: { type: "CollapsibleRow", name: "deliveryLocation", hideSafes: true },
                  viaLocation: { type: "CollapsibleRow", name: "viaLocation", hideSafes: true },
                  redeliveryLocation: { type: "CollapsibleRow", name: "redeliveryLocation", hideSafes: true },
                  duration: { type: "CollapsibleRow", name: "duration" },
                  tctNotes: { type: "CollapsibleRow", name: "tctNotes" },
                },
                { type: "Tct" }
              ),
            },
          ],
          required: ["types", "laycan", "chartererAccount"],
          context: new FormContext(),
        },
      },
      negotiations: {
        grid: {},
      },
    },
  },

  dataTest: "order",
  name: "Orders",
} as Context;

function mutateNewDealCaptureValues(values) {
  if (values?.types?.length > 1) {
    values.types = [values.types[0]];
  }
}

function getCheckboxInputState(props) {
  const { element, value, onChange } = props;
  const types = value || [];
  const type = element.orderType;
  const collapsed = !value?.includes(type);
  let nextTypes: any;

  if (collapsed) nextTypes = [...types, type];
  //
  else nextTypes = types.filter((_type) => _type !== type);

  function onCollapse() {
    onChange(nextTypes);
  }

  return { collapsed, onCollapse };
}

function getRadioInputState(props) {
  const { element, value, onChange } = props;
  const type = element.orderType;
  const collapsed = !value?.includes(type);

  function onCollapse() {
    onChange([type]);
  }

  return { collapsed, onCollapse };
}
