import { ReactNode, useContext, useMemo, useState } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useExecuteAtDate, emptyArrowFn, shouldEnableLaunchDarklyFeature } from "@/utils";
import { auth, dialog, Negotiation, slideout, UniversalOrderNegotiationFormValues } from "@/models";
import { Timepassed, Timeleft, Button, Icon, SummaryDetails, Banner } from "@/components";
import {
  postNegotiationBid,
  postNegotiationOffer,
  publishNegotiation as publishNegotiationAction,
} from "@/components/Orders/actions";
import { NegotiationTypeEvents, usageMetrics } from "@/services/UsageMetrics";
import { Context } from "../NegotiationDetail";
import { SlideoutBidOfferForm } from "../SlideoutBidOfferForm";
import "./Details.scss";
import { AcceptChartererSuggestionsButton } from "./AcceptChartererSuggestionsButton";
import { useFlags } from "launchdarkly-react-client-sdk";
import { LaunchDarklyFeature } from "@/config";

let block1Class = "dark";
let block2Class = "dark";

export function Details() {
  const [proceeding, setProceeding] = useState<boolean>(false);
  const [rowVersion, setRowVersion] = useState(0);
  const context = useContext(Context);
  const { negotiation } = context;
  const { lastUpdated, hasBroker, hasCharterer } = negotiation;
  const actions = negotiation.getActionsModel();
  const flags = useFlags();
  const enableTcFields = shouldEnableLaunchDarklyFeature(LaunchDarklyFeature.TCFields, flags);
  const {
    ownerFirmed,
    ownerFirmExpiresOn,
    ownerFirmedExpired,
    ownerNonExpiredFirmed,
    brokerChartererFirmedExpired,
    lastUpdatedByOwner,
    brokerChartererFirmed,
    brokerChartererFirmExpiresOn,
    lastUpdatedByBrokerCharterer,
  } = actions || {};
  const rows = useMemo(resolveRows, [negotiation._.version, proceeding, rowVersion, enableTcFields]);
  useExecuteAtDate(ownerFirmExpiresOn, incrementRowVersion);

  const className = classNames("order-negotiations-negotiation-details", {
    ownerFirmedExpired,
    brokerChartererFirmedExpired,
  });

  return (
    <div className={className}>
      <table>
        <tbody>{rows}</tbody>
      </table>
    </div>
  );

  async function acceptFirmOffer() {
    const { res: shouldProceed } = await dialog.show(confirmProceedOfferDialogProps);

    if (!shouldProceed) return;

    setProceeding(true);

    usageMetrics.trackEvent(NegotiationTypeEvents.ACCEPT_FIRM_OFFER);
    postNegotiationBid(negotiation, new UniversalOrderNegotiationFormValues({ indicationType: "firmAccepted" }));
  }

  async function acceptFirmBid() {
    const { res: shouldProceed } = await dialog.show(confirmProceedBidDialogProps);

    if (!shouldProceed) return;

    setProceeding(true);

    usageMetrics.trackEvent(NegotiationTypeEvents.ACCEPT_FIRM_BID);
    postNegotiationOffer(negotiation, new UniversalOrderNegotiationFormValues({ indicationType: "firmAccepted" }));
  }

  async function publishNegotiation() {
    const { confirmed } = await slideout.show({
      content: <NegotiationPublishConfirmation negotiation={negotiation} />,
    });

    if (!confirmed) return;

    publishNegotiationAction("published", negotiation);
  }

  async function acceptChartererSuggestions() {
    const { confirmed } = await slideout.show({
      content: <AcceptChartererSuggestionsConfirmation negotiation={negotiation} />,
    });

    if (!confirmed) return;

    publishNegotiationAction("revealedToOwner", negotiation);
  }

  function openForm(type: "bid" | "offer") {
    slideout.show({
      adjustable: true,
      content: <SlideoutBidOfferForm type={type} negotiation={negotiation} featureFlags={flags} />,
    });
  }

  function incrementRowVersion() {
    setRowVersion(rowVersion + 1);
  }

  function resolveRows() {
    const timepassed = <Timepassed since={lastUpdated} />;
    const editBidButton = (
      <Button icon="edit" variant="flat" onClick={openForm.bind(null, "bid")} dataTest="edit-btn-bid">
        edit
      </Button>
    );
    const editOfferButton = (
      <Button icon="edit" variant="flat" onClick={openForm.bind(null, "offer")} dataTest="edit-btn-offer">
        edit
      </Button>
    );
    const acceptOfferButton = (
      <Button
        className="reveal-button"
        variant="positive"
        onClick={acceptFirmOffer}
        disabled={proceeding}
        dataTest="accept-offer"
      >
        {proceeding ? "Proceeding" : "Accept Firm Offer"}
      </Button>
    );
    const acceptBidButton = (
      <Button icon="tick" variant="positive" onClick={acceptFirmBid} disabled={proceeding} dataTest="accept-bid">
        {proceeding ? "Proceeding" : "Proceed"}
      </Button>
    );
    const revealToChartererButton = (
      <Button
        icon="visibility"
        className="reveal-button"
        variant="flat"
        tooltip="Reveal to Charterer"
        onClick={publishNegotiation}
        dataTest="show-to-charterer-btn"
      >
        {ownerNonExpiredFirmed ? "" : "reveal to charterer"}
      </Button>
    );
    const actorIsBroker = auth.trade.user?._.companyRoleMap.broker;
    const actorIsCharterer = auth.trade.user?._.companyRoleMap.charterer;
    const isRevealButtonVisible = actorIsBroker && negotiation.status !== "Inactive";
    const isChartererWithABrokeredNeg = actorIsCharterer && hasBroker;

    let ownerHeaderLeft: ReactNode = "Indication";
    let ownerHeaderRight: ReactNode = null;
    let ownerFooterLeft: ReactNode = editOfferButton;
    let ownerFooterMiddle: ReactNode = null;
    let ownerFooterRight: ReactNode = null;
    let chartererHeaderLeft: ReactNode = "Indication";
    let chartererHeaderRight: ReactNode = null;
    const chartererFooterLeft: ReactNode = editBidButton;
    let chartererFooterMiddle: ReactNode = null;
    let chartererFooterRight: ReactNode = null;

    if (ownerFirmed) ownerHeaderLeft = <Timeleft until={ownerFirmExpiresOn} format={timeleftFormat.bind(null, "Firm Offer")} />;
    if (lastUpdatedByOwner) ownerHeaderRight = timepassed;
    if (ownerFirmed && !ownerFirmedExpired)
      ownerFooterRight = <div className="flex justify-content-end">{acceptOfferButton}</div>;

    if (brokerChartererFirmed)
      chartererHeaderLeft = <Timeleft until={brokerChartererFirmExpiresOn} format={timeleftFormat.bind(null, "Firm Bid")} />;
    if (lastUpdatedByBrokerCharterer) chartererHeaderRight = timepassed;

    if (brokerChartererFirmed && !brokerChartererFirmedExpired && !isChartererWithABrokeredNeg)
      chartererFooterRight = acceptBidButton;

    if (hasBroker) {
      ownerFooterLeft = nbsp;
    }

    if (hasCharterer && !ownerNonExpiredFirmed && isRevealButtonVisible) {
      ownerFooterRight = <div className="flex justify-content-end">{revealToChartererButton}</div>;
    }

    if (actorIsBroker) {
      // should we consider here, whether to show the button if we haven't revealed to the charterer in the first place??
      chartererFooterMiddle = (
        <div>
          <AcceptChartererSuggestionsButton
            negotiationId={negotiation.id}
            version={negotiation.publishedNeg?.version}
            acceptChartererSuggestions={acceptChartererSuggestions}
          />
        </div>
      );
    }
    if (hasCharterer && ownerNonExpiredFirmed && isRevealButtonVisible) {
      ownerFooterMiddle = revealToChartererButton;
    }

    const ownerActions = (
      <div className="order-negotiations-negotiation-details-actions">
        {ownerFooterLeft}
        {ownerFooterMiddle}
        {ownerFooterRight}
      </div>
    );

    const chartererActions = (
      <div className="order-negotiations-negotiation-details-actions">
        {chartererFooterLeft}
        {chartererFooterMiddle}
        {chartererFooterRight}
      </div>
    );

    block1Class = lastUpdatedByOwner ? "fade" : "dark";
    block2Class = lastUpdatedByOwner ? "dark" : "fade";

    const negotiableBidAndOfferModelArray = negotiation.getNegotiableBidAndOfferModelArray(enableTcFields);
    const negotiableRows = negotiableBidAndOfferModelArray.map(getNegotiableRow);

    const className = `${lastUpdatedByOwner ? "offer" : "bid"} order-negotiations-negotiation-details-arrow`;

    const arrow = (
      <div className={className}>
        <Icon icon="arrow-back" />
      </div>
    );

    const rows = [
      {
        className: "first-row",
        cells: [
          {
            content: "",
            className: "col1",
            colSpan: 1,
          },
          {
            content: TitleHOC("Charterer side"),
            className: classNames(block1Class, "col2"),
            colSpan: 3,
          },
          {
            content: arrow,
            className: "col5",
            colSpan: 1,
            arrow: true,
          },
          {
            content: TitleHOC("Owner side"),
            className: classNames(block2Class, "col6"),
            colSpan: 3,
          },
        ],
      },

      {
        className: "second-row",
        cells: [
          {
            content: "",
            className: "col1",
            colSpan: 1,
          },
          {
            content: chartererHeaderLeft,
            className: classNames(block1Class, "col2"),
            colSpan: 1,
          },
          {
            content: "",
            className: classNames(block1Class, "col3"),
            colSpan: 1,
          },
          {
            content: chartererHeaderRight,
            className: classNames(block1Class, "col4"),
            colSpan: 1,
          },
          {
            content: "",
            className: "col5",
            colSpan: 1,
            arrow: true,
          },
          {
            content: ownerHeaderLeft,
            className: classNames(block2Class, "col6"),
            colSpan: 1,
          },
          {
            content: "",
            className: classNames(block2Class, "col7"),
            colSpan: 1,
          },
          {
            content: ownerHeaderRight,
            className: classNames(block2Class, "col8"),
            colSpan: 1,
          },
        ],
      },

      ...negotiableRows,

      {
        className: "button-row",
        cells: [
          {
            content: "",
            className: "col1",
            colSpan: 1,
          },
          {
            content: chartererActions,
            className: classNames(block1Class, "col2"),
            colSpan: 3,
          },
          {
            content: "",
            className: "col5",
            colSpan: 1,
            arrow: true,
          },
          {
            content: ownerActions,
            className: classNames(block2Class, "col6"),
            colSpan: 3,
          },
        ],
      },
      {
        className: "legal-row",
        cells: [
          {
            content: "",
            className: "",
            colSpan: 1,
          },
          {
            content: "References to 'Bid' herein refer to a Charterer's Offer",
            className: "",
            colSpan: 3,
          },
          {
            content: "",
            className: "",
            colSpan: 1,
            arrow: true,
          },
          {
            content: "References to 'Offer' herein refer to an Owner's Offer",
            className: "",
            colSpan: 3,
          },
        ],
      },
    ];

    return rows.map((row, key) => RowHOC(row, key, rows.length));

    function getNegotiableRow(detail: NegotiableDetail, i) {
      const { label, bid, offer, name } = detail || {};
      const contentLeft = bid?.toView?.();
      const contentRight = offer?.toView();

      return {
        className: "data-rows",
        cells: [
          {
            content: label,
            className: "col1",
            colSpan: 1,
          },
          {
            content: contentLeft || "--",
            className: classNames(block1Class, "col2"),
            dataTest: `bid-${name}`,
            colSpan: 3,
          },
          {
            content: "", //arrow,
            className: "col5",
            colSpan: 1,
            arrow: true,
          },
          {
            content: contentRight || "--",
            className: classNames(block2Class, "col6"),
            dataTest: `offer-${name}`,
            colSpan: 3,
          },
        ],
      };
    }

    type NegotiableDetail = typeof negotiableBidAndOfferModelArray[number];
  }
}

const AcceptChartererSuggestionsConfirmation = observer(function NegotiationPublishConfirmation({
  negotiation,
}: {
  negotiation?: Negotiation;
}) {
  const indicationType = negotiation?.publishedNeg?.actions.brokerCharterer || null;

  if (indicationType === null || negotiation?.publishedNeg == null) {
    return null;
  }
  const offerModelArray = negotiation.getNegotiablePublishedNegOfferModelArray();

  return (
    <negotiation-publish-confirmation data-negotiation-version={negotiation?._.version}>
      <h2>Charterer Response</h2>
      {indicationType === "firmRequested" && (
        <Banner
          className="panelBanner"
          version={1}
          onClose={emptyArrowFn}
          hidden={false}
          label="The Charterer has requested a Firm Offer from the owner"
          isClosable={false}
        />
      )}
      {indicationType === "firmed" && (
        <>
          <Banner
            className="panelBanner"
            version={1}
            onClose={emptyArrowFn}
            hidden={false}
            label="The Charterer has requested a firm Bid"
            isClosable={false}
          />
          {negotiation.publishedNeg.actions.brokerChartererFirmExpiresOn && (
            <div className="actionNotification">
              <Timeleft
                until={negotiation.publishedNeg.actions.brokerChartererFirmExpiresOn}
                format={timeleftFormat.bind(null, "Firm Bid")}
              />
            </div>
          )}
        </>
      )}
      {indicationType === "indicated" && <h4>The Charterer has requested an indication</h4>}
      <SummaryDetails list={offerModelArray} />
      <br />
      <negotiation-publish-confirmation-actions>
        <Button onClick={onCancel}>cancel</Button>
        <Button onClick={onConfirm} variant="action">
          Reveal to Owner
        </Button>
      </negotiation-publish-confirmation-actions>
      <div className="footerNotes">By accepting these values you will be revealing these to the Owner</div>
    </negotiation-publish-confirmation>
  );

  function onCancel() {
    slideout.hide();
  }

  function onConfirm() {
    slideout.hide?.({ confirmed: true });
  }
});

const NegotiationPublishConfirmation = observer(function NegotiationPublishConfirmation({
  negotiation,
}: {
  negotiation?: Negotiation;
}) {
  const offerModelArray = negotiation?.getNegotiableOfferModelArray();
  const bidModelArray = negotiation?.getNegotiableBidModelArray();
  return (
    <negotiation-publish-confirmation data-negotiation-version={negotiation?._.version}>
      <h2>Reveal to Charterer</h2>
      <>
        <br />
        <h3>From Charterer</h3>
        <SummaryDetails list={bidModelArray} />
      </>
      <>
        <br />
        <h3>From Owner</h3>
      </>
      <SummaryDetails list={offerModelArray} />
      <negotiation-publish-confirmation-actions>
        <Button onClick={onCancel}>cancel</Button>
        <Button onClick={onConfirm} variant="action">
          reveal to charterer
        </Button>
      </negotiation-publish-confirmation-actions>
      <div className="footerNotes">Both the Bid and the Offer will be revealed to the Charterer</div>
    </negotiation-publish-confirmation>
  );

  function onCancel() {
    slideout.hide();
  }

  function onConfirm() {
    slideout.hide?.({ confirmed: true });
  }
});

function RowHOC(row, i, count) {
  if (!row) return null;

  return (
    <tr key={i} className={row.className}>
      {row.cells.map((data, key) => CellHOC(data, count, i, key))}
    </tr>
  );
}

function CellHOC(cell, count, rowI, key) {
  let addNode = true;

  let rowSpan = 1;
  if (rowI === 0) {
    if (cell.arrow && rowI === 0) {
      rowSpan = count;
      cell.className = `${cell.className} arrow`;
    }
  } else {
    if (cell.arrow) addNode = false;
  }
  return (
    addNode && (
      <td key={key} colSpan={cell.colSpan} rowSpan={rowSpan} className={cell.className} data-test={cell.dataTest || null}>
        {cell.content}
      </td>
    )
  );
}

function TitleHOC(title: string) {
  return <div className="order-negotiations-negotiation-details-title">{title}</div>;
}

function timeleftFormat(thing: string, timeleft: string) {
  if (!timeleft) return `${thing} expired`;

  return `${thing} ${timeleft}`;
}

const confirmProceedOfferDialogProps = {
  status: {
    title: "Accept Firm Offer",
    type: "warning",
    message: "Proceed to main terms subject to contract and terms and conditions",
  } as Status,
  dataTest: "accept-firm-offer-dialog",
};

const confirmProceedBidDialogProps = {
  status: {
    title: "Accept Firm Bid",
    type: "warning",
    message: "Proceed to main terms subject to contract and terms and conditions",
  } as Status,
  dataTest: "accept-firm-bid-dialog",
};

const nbsp = "\u00A0";
