export const ARROW_KEY = {
  ArrowUp: true,
  ArrowDown: true,
  ArrowLeft: true,
  ArrowRight: true,
} as Record<string, boolean>;

export const EDIT_KEY = {
  Enter: true,
  Backspace: true,
  Delete: true,
} as Record<string, boolean>;

export const SPECIAL_KEY = {
  Escape: true,
  F1: true,
  F2: true,
  F3: true,
  F4: true,
  F5: true,
  F6: true,
  F7: true,
  F8: true,
  F9: true,
  F10: true,
  F11: true,
  F12: true,
  Pause: true,
  Insert: true,
  Home: true,
  PageUp: true,
  Delete: true,
  End: true,
  PageDown: true,
  NumLock: true,
  Enter: true,
  Backspace: true,
  Shift: true,
  Control: true,
  ContextMenu: true,
  AltGraph: true,
  Alt: true,
  Meta: true,
  CapsLock: true,
  Tab: true,
} as Record<string, boolean>;
