import { TradeAPI } from "___REFACTOR___/apis";
import { TimezoneEditor } from "___REFACTOR___/components";
import { DataModel, DataModelProps } from "../DataModel";

class Timezone extends DataModel {
  constructor(data?: PartialData) {
    super(data);
  }

  toString() {
    return this.display;
  }
}

class TimezoneProps extends DataModelProps {}

Timezone.prototype.Props = TimezoneProps;
TimezoneProps.prototype.Editor = TimezoneEditor;

function createTimezone(data: Data) {
  return new Timezone(data);
}

export { Timezone, TimezoneProps, createTimezone };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface Timezone extends Data {
  Props: typeof TimezoneProps;
  _: TimezoneProps;
}

type PartialData = Partial<TradeAPI.Timezone>;
type Data = TradeAPI.Timezone;
