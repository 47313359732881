import React, { Component } from "react";
import Button from "__legacy/sharedFolder/components/common/Button/Button";
import classnames from "classnames";
import EditorRowEmptyValue from "sharedFolder/components/common/EditorRowEmptyValue/EditorRowEmptyValue";
import { FocusContext } from "../common/Context/FocusContext";
import { IValidationContext, ValidationHandler, ValidationContext } from "sharedFolder/contexts/ValidationContext";
import { Subscription } from "rxjs";
import styles from "./CreateNegotiationRow.module.scss";

interface INegotiableValue {
  /**
   * Row item is negotiable.
   * If undefined, will not render a negotiable toggle
   */
  negotiable?: boolean;
  /**
   * Row item is included
   * If false, row item will not render
   */
  included?: boolean;
  /**
   * Value - any. We don't care what type it is, just that it exists.
   */
  value?: any;
}

// TODO: Add tabbing into Negotiable and Exclude items ? - WIP FOR FOCUS STORY

interface ICreateNegotiationRowProps<TChild extends INegotiableValue, TParent> extends IValidationContext {
  label: string;
  postLabelCtrl?: JSX.Element;
  children: React.ReactNode;
  expanded: boolean;
  onExpand: (key: keyof TParent | null) => void;
  value: TChild;
  onValueChanged: (value: TChild) => void;
  text?: string;
  id: keyof TParent;
  required?: boolean;
}

interface ICreateNegotiationRowState {
  valid: boolean;
  contentValid: boolean;
}

export class CreateNegotiationRow<T extends INegotiableValue, T2> extends Component<
  ICreateNegotiationRowProps<T, T2> & IValidationContext,
  ICreateNegotiationRowState
> {
  private readonly validationHandler: ValidationHandler = new ValidationHandler();
  private readonly subscriptions: Subscription[] = [];

  constructor(props: ICreateNegotiationRowProps<T, T2> & IValidationContext) {
    super(props);

    const propsValid = this.isValid(props);

    this.state = {
      valid: propsValid,
      contentValid: true,
    };

    this.props.fieldValidityChanged(`negRow_${this.props.id}`, propsValid);

    this.isValid = this.isValid.bind(this);
    this.handleRowClicked = this.handleRowClicked.bind(this);
    this.handleClickLabel = this.handleClickLabel.bind(this);
    this.handleRowFocussed = this.handleRowFocussed.bind(this);
    this.handleInnerValidationChanged = this.handleInnerValidationChanged.bind(this);
    this.handleNegotiableClicked = this.handleNegotiableClicked.bind(this);
    this.handleExcludeClicked = this.handleExcludeClicked.bind(this);
    this.handleIncludeClicked = this.handleIncludeClicked.bind(this);
  }

  public componentDidMount() {
    this.subscriptions.push(this.validationHandler.onChange.subscribe(this.handleInnerValidationChanged));
  }

  public componentWillUnmount() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.props.fieldValidityChanged(`negRow_${this.props.id}`, true);
  }

  public componentDidUpdate() {
    const valid = this.isValid(this.props);
    if (this.state.valid !== valid) {
      this.setState({ valid });
    }

    this.props.fieldValidityChanged(`negRow_${this.props.id}`, this.state.valid && this.state.contentValid);
  }

  public render() {
    if (this.props.value.included === false) {
      // render nothing if we're not included
      return null;
    }

    return (
      <div className={styles.row}>
        <label
          data-test={`${this.props.label || "empty"}-label`}
          className={classnames(styles.label, !(this.state.valid && this.state.contentValid) && "textError")}
          onClick={this.handleClickLabel}
        >
          {this.props.label}
          {this.props.required && " *"}
          {this.props.postLabelCtrl}
        </label>
        <div
          tabIndex={0}
          onClick={this.handleRowClicked}
          onFocus={this.handleRowFocussed}
          className={classnames(
            styles.content,
            styles.textOnly,
            !this.props.expanded && styles.contentVisible,
            !(this.state.valid && this.state.contentValid) && styles.rowHasError
          )}
        >
          {this.props.text || <EditorRowEmptyValue required={this.props.required} />}
        </div>
        <div className={classnames(styles.content, this.props.expanded && styles.contentVisible)}>
          <ValidationContext.Provider value={this.validationHandler}>
            <FocusContext.Provider value={this.props.expanded}>{this.props.children}</FocusContext.Provider>
          </ValidationContext.Provider>
        </div>
        <div className={styles.actions}>
          <span className={styles.negotiableCheckbox}>
            {this.props.value.negotiable !== undefined && (
              <Button
                onClick={this.handleNegotiableClicked}
                type="plain"
                className={classnames(this.props.value.negotiable ? styles.negotiable : styles.nonNegotiable)}
                icon={this.props.value.negotiable ? "in-negotiation" : "fixed"}
                ariaLabel={`${this.props.label} IN button`}
                dataTest={`${this.props.label} IN button`}
              />
            )}
          </span>
          {!this.props.required && this.props.value.included !== undefined && (
            <Button
              className={styles.excludeButton}
              type="plain"
              icon="remove_circle"
              onClick={this.handleExcludeClicked}
              ariaLabel={`${this.props.label} exclude button`}
            />
          )}
        </div>
      </div>
    );
  }

  private isValid(props: ICreateNegotiationRowProps<T, T2>): boolean {
    // valid if...

    const hasValue = props.value.value ? (props.value.value.display ? true : false) : false;

    return (
      !props.value.included ||
      // or has a value
      hasValue ||
      // or is negotiable (when negotiable is undefined, we must return true)
      props.value.negotiable !== false
    );
  }

  private handleRowClicked(evt: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    this.props.onExpand(this.props.id);
  }

  private handleClickLabel(evt: React.MouseEvent) {
    this.props.onExpand(this.props.expanded ? null : this.props.id);
  }

  private handleRowFocussed(evt: React.FocusEvent<HTMLDivElement>) {
    this.props.onExpand(this.props.id);
  }

  private handleNegotiableClicked(evt: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
    this.props.onValueChanged({
      ...this.props.value,
      negotiable: !this.props.value.negotiable,
    });
  }

  private handleExcludeClicked() {
    this.props.onValueChanged({
      ...this.props.value,
      included: false,
    });
    this.setState({
      contentValid: true,
    });
  }

  private handleIncludeClicked() {
    this.props.onValueChanged({
      ...this.props.value,
      included: true,
    });
  }

  private handleInnerValidationChanged(contentValid: boolean) {
    this.setState({
      contentValid,
    });
  }
}
