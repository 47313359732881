import { getNegotiationApiService } from "../../api/negotiations/Negotiations";
import { IVesselNominateUpdate } from "../../api/negotiations/models";
import { IVesselView } from "sharedFolder/Models/IDetails";

export function nominateVessel(
  ctradeUrl: string,
  orderId: string,
  negId: string,
  updateToken: string,
  vessel: IVesselView,
  chartererCompanyId?: string
) {
  // take the current neg and apply the action
  const nominateVessel = getNegotiationApiService(ctradeUrl, orderId).nominateVessel;
  const body: IVesselNominateUpdate = {
    arcVesselId: vessel.arcVesselId,
    buildYear: vessel.buildYear,
    dwt: vessel.dwt,
    registrationDataName: vessel.vesselName,
    vesselImo: vessel.vesselIMO,
    ownerChain: vessel.ownerChain || "",
    speedAndConsumption: vessel.speedAndConsumption || "",
    vesselDescription: vessel.vesselDescription || "",
    eta: vessel.eta || "",
    itinerary: vessel.itinerary || "",
    chartererCompanyId,
    updateToken,
  };
  return nominateVessel(orderId, negId, body);
}
