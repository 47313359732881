import { TextField } from "___REFACTOR___/components";
import { DataModel } from "../../DataModel";
import { schema } from "./schema";

class Props extends DataModel.Props {}

class Email extends DataModel {
  constructor(data?: Email.Data) {
    super(data);
  }

  static Props = Props;
  static Editor = TextField;
  static schema = schema;

  toJSON() {
    return this.value;
  }
}

Email.prototype.Props = Props;

export { Email };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface Email extends Email.Data {
  Props: typeof Props;
  _: Props;
  i: number;
}

declare namespace Email {
  interface Data {
    id: string;
    value: Value;
    prevValue: Value;
    isPlaceholder?: boolean;
  }

  type Value = string;

  type Props = DataModel.Props;
}

interface Props extends Email.Props {}
