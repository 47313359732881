import { IOfferRepViewNegotiation } from "negotiations/models/common/IOfferRep";

export type LiftingsUIStatus =
  | "None"
  | "NominationSuccessful"
  | "ChangesRequestedSuccessful"
  | "Withdrawn"
  | "Accepted"
  | "Rejected"
  | "RequestInProgress";

export function liftingsUIStatusReducer(
  state: LiftingsUIStatus,
  action: {
    type: "InProgress" | "NominationSuccessful" | "ChangesRequestedSuccessful" | "UpdateNegotiation" | "SetToNone";
    payload?: IOfferRepViewNegotiation;
  }
): LiftingsUIStatus {
  const vesselIsAccepted = action.payload?.details?.vessels.some((vessel) => vessel.status === "accepted");
  const vesselIsRejcted = action.payload?.details?.vessels.some((vessel) => vessel.status === "rejected");

  if (action.payload?.status === "Withdrawn") return "Withdrawn";
  if (vesselIsAccepted) return "Accepted";
  if (vesselIsRejcted) return "Rejected";

  switch (action.type) {
    case "SetToNone":
      return "None";
    case "InProgress":
      return "RequestInProgress";
    case "NominationSuccessful":
      return "NominationSuccessful";
    case "ChangesRequestedSuccessful":
      return "ChangesRequestedSuccessful";
    default:
      return state;
  }
}
