import React from "react";
import { Commission } from "@/models";
import { NumberField, NumberFieldProps } from "@/components";

export function CommissionEditor(props: Props) {
  props = { ...props };

  props.className = `${props.className} Commission`;

  function onChange(value: number) {
    const next = new Commission({ value });

    props.onChange?.(next);
  }

  return (
    <NumberField
      {...props}
      value={props.value?.value}
      onChange={onChange}
      placeholder="e.g. 3.75"
      min={0}
      max={100}
      dataTest={`${props.dataTest}.value`}
    />
  );
}

interface Props extends Omit<NumberFieldProps, "value" | "onChange"> {
  value?: Commission;
  onChange?: (value: Commission) => void;
}

export type CommissionEditorProps = Props;
