import { TradeAPI } from "@/apis";
import { DataModel, DataModelProps } from "../DataModel";
import { TermsetBuilder, TermsetBuilderObserver } from "@/components/common/Editor/type/TermsetBuilder";
export { Termset, TermsetProps, SubjectTermset, SubjectTermsetProps };

class Termset extends DataModel<Data> {
  toString() {
    return this.name;
  }
}

class TermsetProps extends DataModelProps<Data> {}

TermsetProps.prototype.editor = TermsetBuilder;

Termset.prototype._ = new TermsetProps();

/* -------------------------------------------------------------------------- */
/*                               SubjectTermset                               */
/* -------------------------------------------------------------------------- */

class SubjectTermset extends Termset {}

class SubjectTermsetProps extends TermsetProps {}

SubjectTermsetProps.prototype.editor = TermsetBuilderObserver;

SubjectTermset.prototype._ = new SubjectTermsetProps();

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

interface Termset extends Data {
  _: TermsetProps;
}

type Data = DeepPartial<TradeAPI["Termset"]>;
