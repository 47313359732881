import { Request } from "___REFACTOR___/models/common";
import { auth } from "___REFACTOR___/services";
import { config } from "___REFACTOR___/config";

class CentralAPI {
  setup = async () => {
    this.req = new Request({ baseURL: config.authUrl });

    await auth.central.promise;

    Object.assign(this.req.axios.defaults.headers.common, {
      "clarksons.cloud.logintoken": auth.central.token,
    });
  };

  getUser = async (centralToken = auth.central.token) => {
    const config = {
      headers: {
        "clarksons.cloud.logintoken": centralToken,
      },
    };

    return this.req.get<CentralAPI.User>("/API/1_4/Security/GetCurrentUser", config);
  };
}

const centralAPI = new CentralAPI();

export { centralAPI, CentralAPI };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface CentralAPI {
  req: Request;
}

declare namespace CentralAPI {
  type Token = Encoding.JWT.ISOString;
  interface User {
    TokenExpiryUtc: string;
    AllTermsAndConditionsAccepted: true;
    AuthConfigurationId: string;
    ClarksonsADGuid: string;
    ClarksonsADSamAccountName: string;
    CompanyGroups: number[];
    CompanyId: number;
    CompanyLogoUrl: Encoding.Url.ISOString;
    CompanyName: string;
    ConfigurationRoles: number[];
    DefaultLicenceAccountCodeId: string;
    Department: string;
    Division: string;
    EmailAddress: Encoding.Url.ISOString;
    Extension: string;
    FirstName: string;
    GroupEmailAddress: string;
    IsEnabled: true;
    IsExternal: true;
    IsService: true;
    IsSystem: false;
    LastName: string;
    LogoPath: string;
    MobileNumber: string;
    Office: string;
    PhoneNumber: string;
    Roles: any[];
    SecurityRights: { [right: number]: number[] };
    Settings: any[];
    SystemUserId: number;
    ThumbnailPath: string;
    ThumbnailUrl: string;
    Timezone: string;
    Username: string;
  }
  namespace User {}

  namespace Encoding {
    namespace Url {
      type ISOString = string;
    }

    namespace Date {
      type ISOString = string;
      type Unix = number;
    }

    namespace Timezone {
      type ISOString = string;
    }

    namespace Id {
      type GUID = string;
      type UUID = string;
    }

    namespace JWT {
      type ISOString = string;
    }

    namespace Email {
      type ISOString = string;
    }
  }
}
