import { TradeAPI } from "___REFACTOR___/apis";
import { ProformaLayoutEditor } from "___REFACTOR___/components";
import { DataModel, DataModelProps } from "../DataModel";

class ProformaLayout extends DataModel {
  constructor(data?: PartialData) {
    super(data);

    this.id = this.layoutId || this.id || this.key;
    this.name = this.name || this.proformaKey || this.text;
  }

  toString() {
    return this.name;
  }
}

class ProformaLayoutProps extends DataModelProps {}

ProformaLayout.prototype.Props = ProformaLayoutProps;
ProformaLayoutProps.prototype.Editor = ProformaLayoutEditor;

export { ProformaLayout };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface ProformaLayout extends Data {}

type PartialData = Partial<Data>;
type Data = TradeAPI.Termset.Content.ProformaLayout;
