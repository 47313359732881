import { IRecipient } from "api/orders/models/IDistributionList";
import { IUserContext } from "__legacy/dashboard/contexts/UserProvider";
import { head } from "lodash-es";
import { DistributionUserRole } from "sharedFolder/Models/IDetails";

export const getDefaultRole = (recipient: IRecipient, currentlyLoggedInUser?: IUserContext): DistributionUserRole => {
  const roleIsNotSet = Boolean(recipient.role === undefined || recipient.role === "unknown");
  if (roleIsNotSet) {
    // If no 'owner' role, return the first role in the collection
    if (!recipient.availableRoles.includes("owner")) {
      return head(recipient.availableRoles) as DistributionUserRole;
    }
    const isSameCompany: boolean = currentlyLoggedInUser?.companyId === recipient.knownUser?.company.id;

    const containsBothChartererAndOwner =
      recipient.availableRoles.includes("charterer") && recipient.availableRoles.includes("owner");

    // If the recipient is in the same company as the 'actor' and has roles 'owner' and 'charterer' then return 'charterer'
    if (isSameCompany && containsBothChartererAndOwner) {
      return "charterer";
    }
    return "owner";
  }
  return recipient.role as DistributionUserRole;
};
