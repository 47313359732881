import React, { ReactNode } from "react";
import { observer } from "mobx-react";
import * as ReactRouterDOM from "react-router-dom";
import classNames from "classnames";
import { Status } from "___REFACTOR___/models";
import { Route } from "___REFACTOR___/models/common/Route";
import { Icon } from "___REFACTOR___/components";
import "./NavLink.scss";

function _NavLink(props: NavLink.Props) {
  const route = props.route || props.to;

  if (route instanceof Route) {
    props = { ...props, to: route.compile(), title: route.desc, children: route.label };
  }

  let { icon, children, desc, hidden, route: ignore, to, badge, status, isActive, className, suppressLabel, ...rest } = props;
  className = classNames(className, "nav-link", typeof icon === "string" ? icon : icon?.name, {
    active: typeof isActive === "boolean" ? isActive : false,
  });

  if (suppressLabel) children = undefined;

  if (hidden) return null;

  if (typeof to !== "string") return null;

  return (
    <ReactRouterDOM.NavLink {...rest} className={className} to={to} title={rest.title || ""}>
      <Icon icon={icon} />
      {children}
      <Badge {...props} />
    </ReactRouterDOM.NavLink>
  );
}

function Badge(props: NavLink.Props) {
  const { badge } = props;

  if (!badge) return null;

  if (typeof badge === "number") return <nav-link-badge>{badge}</nav-link-badge>;

  const { data } = badge;

  if (!data) return null;

  return <nav-link-badge>{data}</nav-link-badge>;
}

const NavLink = observer(_NavLink);

export { NavLink };

declare namespace NavLink {
  export interface Props extends Omit<ReactRouterDOM.NavLinkProps, "title" | "to" | "isActive"> {
    desc?: string;
    to?: string | Route;
    icon?: Icon.IconProp;
    children?: ReactNode;
    title?: string;
    route?: Route;
    badge?: number | BadgeProps;
    status?: Status;
    isActive?: boolean | ReactRouterDOM.NavLinkProps["isActive"];
    suppressLabel?: boolean;
  }

  export interface BadgeProps {
    data: number;
    loading?: boolean;
    status?: Status;
  }
}
