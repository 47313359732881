import { IUnitValue } from "./IUnitValue";
import { IKeyValuePair } from "./IKeyValuePair";

export type HireRateUnit = "PerDay" | "LumpSum";

export type IHireRate = IUnitValue<HireRateUnit>;

export const HireRateUnitDisplay: { [key in HireRateUnit]: string } = {
  PerDay: "Per Day",
  LumpSum: "Lump Sum",
};

export const HireRateUnitShortDisplay: { [key in string]: string } = {
  PerDay: " / Day",
  LumpSum: "LS",
};

export const HireRateOptions: IKeyValuePair[] = [
  { key: "LumpSum", value: "Lump Sum" },
  { key: "PerDay", value: "Per Day" },
];
