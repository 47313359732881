import React, { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import { emptyFn } from "___REFACTOR___/utils";
import { TextField, Field } from "___REFACTOR___/components";
import { Row } from "../../Row";
import { Container } from "___REFACTOR___/components/common/Field/Container";
import "./IO.scss";

function IOEditor(props: IOEditor.Props) {
  const { className, value, label, name, editorType, seedInputWithCurrentValue, dataTest, status, ...rest } = props;
  const domRef = useRef() as TextField.Input.DomRef;
  const [inputFocus, setInputFocus] = useState(false);
  const [inputValue, setInputValue] = useState<InputValue>();

  useEffect(() => {
    if (seedInputWithCurrentValue && !inputFocus) setInputValue(value);
  }, [inputFocus, value]);

  return (
    <Container className={className} editorType={editorType} {...props}>
      <Row>
        <TextField
          value={inputValue}
          onChange={onInputChange}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          className={classNames("io-input", { "io-input-hidden": !inputFocus })}
          placeholder="Date range, e.g. Q4"
          domRef={domRef}
          status={status}
          label=""
          dataTest={`${dataTest}.input`}
          suppressStatusMessages
        />
        <TextField
          value={value}
          onChange={emptyFn}
          onBlur={emptyFn}
          onFocus={onOutputFocus}
          tabIndex={-1}
          className="io-output"
          dataTest={`${dataTest}.output`}
          status={status}
          onClear={() => props.onInputChange?.(undefined)}
          label=""
          clearable
          suppressStatusMessages
        />
      </Row>
    </Container>
  );

  function onInputChange(value: InputValue) {
    setInputValue(value);

    props.onInputChange?.(value);
  }

  function onOutputFocus(e) {
    e.preventDefault();

    domRef.current?.focus();
  }

  function onInputFocus(e) {
    setInputFocus(true);

    props.onFocus?.(e);
  }

  function onInputBlur(e) {
    setInputValue("");
    setInputFocus(false);

    props.onBlur?.(e);
  }
}

export { IOEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace IOEditor {
  interface Props extends Field.Props {
    onInputChange?: (value: InputValue) => void;
    seedInputWithCurrentValue?: boolean;
  }
}

declare namespace IOEditor {
  export { InputValue };
}

type InputValue = TextField.Input.Value;
