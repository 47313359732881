import { useMemo } from "react";
import { observer } from "mobx-react";
import { FormContext, Form, Button } from "@/components";
import { UniversalOrderNegotiationFormValues, Vessel, Negotiation, slideout } from "@/models";
import {
  postNegotiationVesselAccept as postNegotiationVesselAcceptAction,
  postNegotiationVesselReject as postNegotiationVesselRejectAction,
  updateNegotiationVessel as updateNegotiationVesselAction,
} from "@/components/Orders/actions";
import { Context } from "@/components/Orders/Orders";
import "./EditVesselSlideout.scss";

function EditVesselSlideout(props: Props) {
  const { negotiation, context, vessel } = props;
  const initialValues = useMemo(getInitialValues, [vessel]);
  const permittedMethods = negotiation.getParsedUpdateToken()?.permittedMethodsMap;

  async function postNegotiationVesselAccept(values: UniversalOrderNegotiationFormValues) {
    return postNegotiationVesselAcceptAction(negotiation, context, values);
  }

  async function postNegotiationVesselReject(values: UniversalOrderNegotiationFormValues) {
    return postNegotiationVesselRejectAction(negotiation, context, values);
  }

  async function updateNegotiationVessel(values: UniversalOrderNegotiationFormValues) {
    return updateNegotiationVesselAction(negotiation, context, values);
  }

  async function updateAndAccept() {
    slideout.hide();

    const values = form.values;

    if (form.isChanged) await updateNegotiationVessel(values);
    postNegotiationVesselAccept(values);
  }

  async function updateAndReject() {
    slideout.hide();

    const values = form.values;

    if (form.isChanged) await updateNegotiationVessel(values);
    postNegotiationVesselReject(values);
  }

  function update() {
    slideout.hide();

    updateNegotiationVessel(form.values);
  }

  function getInitialValues() {
    return new UniversalOrderNegotiationFormValues({ vessel });
  }

  return (
    <div className="edit-vessel-slideout negotiation-details-slideout-form">
      <Form initialValues={initialValues} definition={initialValues} elements={elements} context={form} />
      <div className="vessel-slideout-form-control">
        <div>
          <Button onClick={slideout.hide}>Cancel</Button>
        </div>
        <div>
          <Button onClick={form.reset}>reset</Button>

          <Button
            variant="warning"
            onClick={updateAndReject}
            disabled={!permittedMethods?.RejectVessel}
            hidden={vessel.status === "rejected"}
          >
            Reject
          </Button>
          <Button
            variant="positive"
            onClick={updateAndAccept}
            disabled={!permittedMethods?.AcceptVessel}
            hidden={vessel.status === "accepted"}
          >
            Accept
          </Button>
          <Button variant="action" onClick={update} disabled={!permittedMethods?.UpdateVessel || !form.isChanged}>
            Update
          </Button>
        </div>
      </div>
    </div>
  );
}

const form = new FormContext();

const elements = [
  {
    type: "CollapsibleGroup",
    label: "View Vessel",
    children: [{ type: "Vessel", name: "vessel" }],
  },
];

const Observer = observer(EditVesselSlideout);

export { Observer as EditVesselSlideout };

interface Props {
  negotiation: Negotiation;
  context: Context;
  vessel: Vessel;
}
