import * as api from "../../api/negotiations/models";
import { IMapper, withNullable } from "./map";
import { ILocationView } from "../Models/ILocation";

const map: IMapper<api.ILocation, ILocationView> = {
  toApi: (location: ILocationView) => {
    return {
      name: location.name,
      locationId: location.locationId,
      country: location.country,
      countryCode: location.countryCode,
      countryId: location.countryId,
      notes: location.notes,
      parents: location.parents,
      safeAnchoragesMax: location.safeAnchoragesMax,
      safeAnchoragesMin: location.safeAnchoragesMin,
      safeBerthsMax: location.safeBerthsMax,
      safeBerthsMin: location.safeBerthsMin,
      safePortsMax: location.safePortsMax,
      safePortsMin: location.safePortsMin,
      zone: location.zone,
      zoneId: location.zoneId,
      globalZone: location.globalZone,
    };
  },
  toView: (location: api.ILocation) => {
    return {
      country: location.country || "",
      countryCode: location.countryCode || "",
      countryId: location.countryId || "",
      display: location.display || "",
      shortDisplay: location.shortDisplay,
      locationId: location.locationId || "",
      name: location.name || "",
      notes: location.notes || "",
      parents: location.parents,
      safeAnchoragesMax: location.safeAnchoragesMax,
      safeAnchoragesMin: location.safeAnchoragesMin,
      safeBerthsMax: location.safeBerthsMax,
      safeBerthsMin: location.safeBerthsMin,
      safePortsMax: location.safePortsMax,
      safePortsMin: location.safePortsMin,
      zone: location.zone || "",
      zoneId: location.zoneId || "",
      globalZone: location.globalZone || "",
    };
  },
  emptyViewModel: {
    country: "",
    countryCode: "",
    countryId: "",
    locationId: "",
    name: "",
    notes: "",
    zone: "",
    zoneId: "",
    globalZone: "",
    safeBerthsMin: undefined,
    safeBerthsMax: undefined,
    safeAnchoragesMin: undefined,
    safeAnchoragesMax: undefined,
    safePortsMin: undefined,
    safePortsMax: undefined,
    shortDisplay: "",
  },
};

export const mapLocation = withNullable(map);
