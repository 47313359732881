import { Body } from "@/components/common/Editor/type/TermsetBuilder";
import { Route } from "___REFACTOR___/models/common/Route";
import { root } from "../Root";

class DealCapture extends Route<Params, SearchParams> {
  readonly relpath = "/deal-capture";

  label = "Deal Capture";

  get parent() {
    return root;
  }

  get root() {
    return root;
  }
}

const dealCapture = new DealCapture();

export { dealCapture, DealCapture };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace DealCapture {
  export { Params };
}

interface Params {}

interface SearchParams {
  as: Body.As;
}
