import { BunkerDeliveryEditor } from "@/components";
import { LABELS } from "@/constants";
import { Bunker, BunkerProps } from "@/models/Bunker";

export class BunkerDelivery extends Bunker {}
export class BunkerDeliveryProps extends BunkerProps {}

BunkerDeliveryProps.prototype.editor = BunkerDeliveryEditor;
BunkerDeliveryProps.prototype.label = LABELS["bunkerDelivery"];
BunkerDelivery.prototype._ = new BunkerDeliveryProps();
