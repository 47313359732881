import React from "react";
import { CargoSize, CargoSizeProps } from "___REFACTOR___/models";
import { NumberField, SelectField, Field, StatusMessages, TextareaField } from "___REFACTOR___/components";
import { Row } from "../../Row";
import { Group } from "../../Group";
import "./CargoSize.scss";

function CargoSizeEditor(props: CargoSizeEditor.Props) {
  const { className, status, value, dataTest, ...rest } = props;

  return (
    <Group {...props} className={className} editorType="CargoSize" hidden={props.hidden}>
      <Row>
        <NumberField
          {...rest}
          label="Cargo Size"
          desc="Cargo weight"
          placeholder="Metric tons, e.g. 120,000"
          status={status?.childMap?.value}
          value={value?.value}
          onBlur={onBlur}
          onChange={onChange.bind(null, "value")}
          dataTest={`${dataTest}.value`}
          suppressStatusMessages
          max={10000000}
        />
        <SelectField
          {...rest}
          required={false}
          label="Option"
          className="CargoSize-option"
          desc={`Cargo size option

      MIN/MAX - the lowest / highest acceptable load weight.

      MOLCHOP - More or Less in Charterer's Option - Option allowed to a voyage charterer to load up to a certain quantity, normally expressed as a percentage or a number of tons, over or under a quantity specified in the contract of carriage. This option may be sought if the charterer is not certain of the exact quantity that will be available at the time of loading.

      MOLOO - More or Less in Owner's Option - Option allowed to a shipowner to carry up to a certain quantity, normally expressed as a percentage or number of tons, over or under a quantity specified in the voyage charter. This option may be sought if the shipowner is not certain what the ship's cargo capacity will be, taking into consideration bunkers, stores and fresh water, or if he wants flexibility to adjust the ship's trim.
      `}
          data={OPTIONS}
          status={status?.childMap?.option}
          value={value?.option || CargoSizeProps.prototype.JSONDefaults?.option}
          onChange={onChange.bind(null, "option")}
          dataTest={`${dataTest}.option`}
          unclearable
          nonFilterSelect
          suppressStatusMessages
        />
        <NumberField
          {...rest}
          max={99}
          label="Variance (%)"
          className="CargoSize-variance"
          placeholder="e.g. 3"
          desc="Cargo weight variance (%)"
          status={status?.childMap?.variance}
          value={value?.variance}
          onChange={onChange.bind(null, "variance")}
          dataTest={`${dataTest}.variance`}
          suppressStatusMessages
        />
      </Row>
      <StatusMessages status={status} />
      <TextareaField
        {...props}
        required={false}
        label="Notes"
        desc="Cargo size notes"
        value={value?.notes || ""}
        onChange={onNotesChange}
        status={status?.childMap?.notes}
        dataTest={`${dataTest}.notes`}
        hidden={!value}
      />
    </Group>
  );

  function onChange(name: keyof CargoSize, inputValue: NumberField.Input.Value) {
    props.onChange?.(new CargoSize({ ...value, [name]: inputValue }));
  }

  function onNotesChange(notes: TextareaField.Input.Value) {
    if (typeof notes !== "string") return;

    props.onChange?.(new CargoSize({ ...value, notes }));
  }

  function onBlur() {
    if (!value?.value) return;

    if (value.value < 1000) {
      props.onChange?.(new CargoSize({ ...value, value: value.value * 1000 }));
    }
  }
}

const OPTIONS = ["MIN/MAX", "MOLOO", "MOLCHOPT"];

export { CargoSizeEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace CargoSizeEditor {
  export interface Props extends Field.Props {
    value: Value;
    onChange: (value: Value) => void;
  }

  export type Value = CargoSize | undefined;
}
