import React, { ReactNode } from "react";
import { observer } from "mobx-react";
import { CSSTransition } from "react-transition-group";
import classNames from "classnames";
import { Spinner } from "@/components";
import "./Overlay.scss";

function Overlay(props: Props) {
  const { children, align = "top-center", status, hidden } = props;

  let activeStatusExists = false;
  let statusArr = [] as NullableStatus[];

  if (status) statusArr = statusArr.concat(status);

  const spinners = statusArr.map(SpinnerHOC);

  const visible = !hidden && !!(children || activeStatusExists);

  const className = classNames("Overlay", align);

  function SpinnerHOC(status: NullableStatus, i: number) {
    if (!status) return null;

    const { loading, message } = status;

    if (loading || message) activeStatusExists = true;

    return <Spinner status={status} key={i} />;
  }

  return (
    <CSSTransition {...CSSTransitionProps} in={visible} unmountOnExit data-test="loading-overlay">
      <div className={className}>
        <div className="Overlay-content">
          {spinners}
          {children}
        </div>
      </div>
    </CSSTransition>
  );
}

const CSSTransitionProps = { timeout: { enter: 0, appear: 500, exit: 500 }, classNames: "Overlay" };

const Observer = observer(Overlay);

export { Observer as Overlay };

interface Props {
  align?:
    | "top-left"
    | "top-center"
    | "top-right"
    | "center"
    | "center-left"
    | "center-right"
    | "bottom-left"
    | "bottom-center"
    | "bottom-right";
  children?: ReactNode;
  className?: string;
  status?: NullableStatus[] | NullableStatus;
  hidden?: boolean;
}

type NullableStatus = Status | null | undefined | false;
