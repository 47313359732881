import { TradeAPI } from "@/apis";
import { format } from "@/utils";
import { DurationEditor } from "@/components";
import { DataModel, DataModelProps } from "../DataModel";
import { LABELS } from "@/constants";

export class Duration extends DataModel<Data> {
  onUpdate() {
    delete this.display;
    delete this.shortDisplay;
  }

  toString() {
    if (!this.isValid()) return "";

    return `${format.number.display(this.min)}-${format.number.display(this.max)} ${this.unit} ${this.notes || ""}`;
  }

  toGridView() {
    if (!this.isValid()) return "";

    return `${format.number.display(this.min)}-${format.number.display(this.max)} ${this.unit}`;
  }

  isValid() {
    return typeof this.min === "number" && typeof this.max === "number";
  }
}

export class DurationProps extends DataModelProps<Data> {}

DurationProps.prototype.editor = DurationEditor;
DurationProps.prototype.sortKey = "max";
DurationProps.prototype.label = LABELS["duration"];
DurationProps.prototype.JSONDefaults = {
  unit: "Days",
};

Duration.prototype._ = new DurationProps();

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

export interface Duration extends Data {
  _: DurationProps;
}

type Data = DeepPartial<TradeAPI["Duration"]>;
