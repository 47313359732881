import React, { useMemo } from "react";
import { observer } from "mobx-react";
import * as yup from "yup";
import { FormContext, UniversalOrderNegotiationForm, Button } from "@/components";
import { UniversalOrderNegotiationFormValues, Vessel, Negotiation, slideout } from "@/models";
import {
  postNegotiationVessel as postNegotiationVesselAction,
  postNegotiationVesselAndAccept as postNegotiationVesselAndAcceptAction,
} from "@/components/Orders/actions";
import { Context } from "@/components/Orders/Orders";
import "./NameVesselSlideout.scss";
import { NegotiationTypeEvents, usageMetrics } from "@/services/UsageMetrics";

function NameVesselSlideout(props: Props) {
  const { negotiation, context } = props;
  const schema = useMemo(getSchema.bind(null, negotiation.vessels), []);
  const initialValues = useMemo(getInitialValues, []);
  const elements = [
    {
      type: "CollapsibleGroup",
      label: negotiation.vessels && negotiation?.vessels?.length > 0 ? "Nominate another vessel" : "Nominate a vessel",
      children: ["vessel"],
    },
  ];

  return (
    <div className="negotiation-details-slideout-form">
      <UniversalOrderNegotiationForm
        initialValues={initialValues}
        definition={initialValues}
        elements={elements}
        context={form}
        onSubmit={console.info}
        yup={schema}
        required={required}
      />
      <div className="vessel-slideout-form-control">
        <div>
          <Button onClick={slideout.hide}>Cancel</Button>
        </div>
        <div>
          <Button variant="positive" onClick={submitAndAccept} disabled={!form.isChanged || !form.isValid}>
            Accept Now
          </Button>
          <Button variant="action" onClick={submit} disabled={!form.isChanged || !form.isValid}>
            nominate
          </Button>
        </div>
      </div>
    </div>
  );

  function postNegotiationVessel(values: FormValues) {
    postNegotiationVesselAction(negotiation, context, values.vessel);
  }

  function postNegotiationVesselAndAccept(values: FormValues) {
    postNegotiationVesselAndAcceptAction(negotiation, context, values.vessel);
  }

  async function submit() {
    form.submit();

    if (!form.isValid) return;

    slideout.hide();

    postNegotiationVessel(form.values);

    usageMetrics.trackEvent(NegotiationTypeEvents.NOMINATE_VESSEL_CONFIRM);
  }

  async function submitAndAccept() {
    form.submit();

    if (!form.isValid) return;

    slideout.hide();

    postNegotiationVesselAndAccept(form.values);

    usageMetrics.trackEvent(NegotiationTypeEvents.NOMINATE_VESSEL_ACCEPT);
  }

  function getInitialValues() {
    return new UniversalOrderNegotiationFormValues();
  }
}

const getSchema = (vessels: Negotiation["vessels"]) =>
  yup
    .object()
    .test(
      "validateVessel",
      "Something is wrong",
      function validateVessel(this: yup.TestContext, values: UniversalOrderNegotiationFormValues) {
        if (!values) return false;

        const error = this.createError();

        if (vessels?.find((vessel) => vessel.vesselImo === values.vessel?.vesselImo)) {
          error.message = "Vessel is already nominated";
          error.path = "vessel";

          return error;
        }

        return true;
      }
    );

const required = ["vessel"];

const form = new FormContext();

const Observer = observer(NameVesselSlideout);

export { Observer as NameVesselSlideout };

interface Props {
  negotiation: Negotiation;
  context: Context;
}

interface FormValues {
  vessel: Vessel;
}
