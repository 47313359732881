import { cloneDeep } from "lodash-es";
import { IBidOfferView } from "sharedFolder/Models/Negotiation/NegotiationViews";
import { IOfferState } from "./IOfferState";
import { INegotiationView, INegotiationDetailsView } from "sharedFolder/Models/Negotiation/INegotiationView";
import { INegotiationCommonView } from "sharedFolder/Models/Negotiation/INegotiationCommonView";

/*
  Merge the modified offer fields and a negotiation together.
  This is to keep the changes made by the offerRep and to overwrite
  any other subsequent updates from the charterer side.
*/
export function mergeOfferIntoNegotiation(negotiation: INegotiationView, offer: IOfferState): INegotiationView {
  if (!negotiation.details) return negotiation;
  else {
    const mergedNegotiation = cloneDeep(negotiation);
    const properties = Object.keys(offer);

    properties.forEach((property) => {
      const prop = property as keyof IOfferState;
      const mergedProp = property as keyof INegotiationDetailsView | keyof INegotiationCommonView;
      const updatedValue = offer[prop];

      if (mergedNegotiation.details && mergedNegotiation.details[mergedProp]) {
        (mergedNegotiation.details[mergedProp] as IBidOfferView<any>).offer = updatedValue;
      }
    });
    return mergedNegotiation;
  }
}
