import { timer, empty, Subscription, Observable } from "rxjs";
import { switchMap, retryWhen, delay, tap } from "rxjs/operators";
import { getNegotiation } from "./api/OfferRepClient";
import { IOfferRepViewNegotiation } from "negotiations/models/common/IOfferRep";
import { useState, useEffect, useCallback } from "react";
import { OfferRepNegotiationService } from "./Contexts/OfferRepNegotiationService";
import { emptyArrowFn } from "@/utils";

const subscriptions: Subscription[] = [];
const unsubscribeAll = () => {
  subscriptions.forEach((sub) => sub.unsubscribe());
};
export function useOfferRepNegotiation(baseUrl: string, offerRepToken: string) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [negotiation, setNegotiation] = useState<IOfferRepViewNegotiation | null>(null);

  let retryCount = 0;

  const setNegotiationIfNewer = useCallback(
    (negData: IOfferRepViewNegotiation) => {
      if (negData.version !== negotiation?.version || negData.orderVersion !== negotiation.orderVersion) {
        setNegotiation(negData);
      }
    },
    [negotiation]
  );

  useEffect(() => {
    subscriptions.push(
      timer(0, 2000)
        .pipe(switchMap(() => (baseUrl ? getNegotiation(baseUrl, offerRepToken) : empty())))
        .pipe(
          retryWhen((errors) =>
            errors.pipe(
              delay(2000),
              tap((errorStatus) => {
                retryCount++;
                if (retryCount > 3) {
                  return Observable.throw(errorStatus);
                }
              })
            )
          )
        )
        .subscribe(
          (data: IOfferRepViewNegotiation) => {
            setNegotiationIfNewer(data);
            setLoading(false);
          },
          () => {
            setError(true);
            setLoading(false);
            unsubscribeAll();
          },
          emptyArrowFn
        )
    );
    return unsubscribeAll;
  }, [baseUrl, offerRepToken, retryCount, setNegotiationIfNewer]);

  const offerRepNegotiationService = OfferRepNegotiationService(
    baseUrl,
    offerRepToken,
    negotiation ? negotiation.updateToken : ""
  );

  return { offerRepNegotiationService, loading, negotiation, error };
}
