import * as api from "../../../../api/negotiations/models";
import { INegotiationNotesView } from "sharedFolder/Models/IDetails";

export function mapNotesToView(notes: api.INegNotes, side: "brokerCharterer" | "owner"): INegotiationNotesView {
  // TODO: the typeof notes not being an object is something i'm using for now until BE change notes to be an object. This check can be removed once that happens
  return notes === undefined || typeof notes !== "object"
    ? emptyNotes
    : {
        timestamp: (notes[side] && (notes[side] as INegotiationNotesView).timestamp) || "",
        value: (notes[side] && (notes[side] as INegotiationNotesView).value) || "",
      };
}

const emptyNotes: INegotiationNotesView = {
  timestamp: "",
  value: "",
};
