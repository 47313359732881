import dayjs from "dayjs";
import { dialog, Dialog } from "___REFACTOR___/services";
import { Status } from "___REFACTOR___/models";
import { encoder } from "node-esapi";

class Log {
  error(dialogProps = defaultErrorDialog, message, dump) {
    dialog.open(dialogProps);

    console.error(message, dump);
  }

  system = console.system;
}

const defaultErrorDialog = {
  status: {
    type: "error",
    title: "Something Went Wrong",
  } as Status,
  dataTest: "something-went-wrong",
} as Dialog.Props;

console.system = function consoleSystem(message: Primitive | Primitive[], ...rest) {
  // @ts-ignore
  message = [].concat(message);

  console.info(`%c[${encoder().encodeForHTML(dayjs().format("hh:mm:ss"))}] ${message.join(" ")}`, "color: #00A69A;", ...rest);

  // console.info(`%c[${dayjs().format("hh:mm:ss")}] ${message.join(" ")}`, "color: #00A69A;", ...rest);
};

export const log = new Log();

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

declare global {
  interface Console {
    system(message: Primitive | Primitive[], ...rest): void;
  }
}
