import React, { useContext, useMemo, useState, useEffect, useRef, createContext as createReactContext } from "react";
import { observer } from "mobx-react";
import dayjs, { extend } from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { object, array, TestContext } from "yup";
import { CellClickedEvent, ColumnApi, RowDragEndEvent } from "@ag-grid-community/core";
import { Switch } from "@material-ui/core";
import * as sf from "___REFACTOR___/components/common/StandardForm";
import { useAsyncEffect, uid, useKeepRerenderingUntilTruthy, isPopulatedArray, emptyArrowFn } from "___REFACTOR___/utils";
import { useAutoChangingRef, useObsBox } from "___REFACTOR___/utils/reactHooks";
import { tradeAPI, TradeAPI } from "___REFACTOR___/apis";
import { dialog0, dialog, HandlebarParser } from "___REFACTOR___/services";
import { Termset, Status, CP, CpSearchItem, Subs } from "___REFACTOR___/models";
import { dialog as dialog1 } from "@/models";
import {
  Icon,
  Aggrid,
  Button,
  TextField,
  TextareaField,
  StatusMessages,
  SelectInput,
  Input,
  AggridRowClickedEvent,
  TermsetSearchEditor,
} from "___REFACTOR___/components/common";
import { renderIcon, resolveMultiValueColDef } from "___REFACTOR___/components/Orders/Grid/columns";
import { Spinner } from "___REFACTOR___/components/OrderNegLayout/common";
import { MainTermsTypeEvents, usageMetrics } from "@/services/UsageMetrics";
import { Body } from "./Body";
import Tooltip from "./Tooltip";
import { TermDetails } from "./TermDetails";
import "./TermsetBuilder.scss";

const OVERRIDE_CP_COLUMN_ID = "override-cp-column";
const TERMSET_ROW_HEIGHT = 42;

function TermsetBuilder(props: TermsetBuilderProps) {
  return <TermsetBuilderObserver {...props} />;
}

const handlebarParser = new HandlebarParser();

const TermsetBuilderObserver = observer((props: TermsetBuilderProps) => {
  const formConfig = useMemo(getFormConfig, []);
  const formProps = sf.useStandardForm(formConfig);

  // TermsetBuilder is a child of Deal Capture as Owner page
  const orderTermsBody = props?.formProps?.values;
  if (orderTermsBody) {
    formProps.values.details = orderTermsBody.details;
    useEffect(onOrderDetailsChange, [orderTermsBody]);
  }

  // TermsetBuilder is a child of Deal Capture as Charterer page
  const parentContext = props?.context;
  if (parentContext?.values) {
    const orderTermsBody = parentContext.values;
    formProps.values.details = orderTermsBody;
    useEffect(onOrderDefinitionChange, [parentContext]);
  }

  const formPropsBox = useObsBox(formProps, [formProps.values]);
  const formPropsRef = useAutoChangingRef(formProps);
  const Context = useMemo(createContext, []);
  const { getEditorProps, values: body, setFieldValue, errors, setValues } = formProps;
  const { termsetId, termset, selectedOption, template } = body;
  const { onChange, onChangeSubjects, onReset, status } = props;
  // Currently this component we use in two places in ODC - new way component and
  // as editor in UniversalOrderNegotiationFormValues. UniversalOrderNegotiationFormValues have subjects and termset as own component,
  // but tis TermsetBuilder are responsible for edit both, so we add this hack to modify subjects inside UniversalOrderNegotiationFormValues
  // If component used in ODC so we just pass onChangeSubjects callback
  let onSubjectChanged: any = null;
  let typeKey: TradeAPI.Order.Type;

  if (!onChangeSubjects) {
    const subjectTermsetProps = props.context.getFieldHelpers("subjectTermset.content.mainTermTemplates");
    onSubjectChanged = (value) => subjectTermsetProps.setValue(value);
    typeKey = props.context.values.type || (window.location.href.endsWith("/Tct") ? "Tct" : "Voy");
  } else {
    onSubjectChanged = (value) => onChangeSubjects(value);
    typeKey = props.formProps?.values.type || "Voy";
  }

  body.typeMap = { [typeKey]: true };

  useEffect(() => {
    if (onReset) {
      onReset.current = clear;
    }
  }, []);
  const termsetColDefs = useMemo(getTermsetColDefs, []);
  const cpSearchResultsColDefs = useMemo(getCpSearchResultsColDefs, []);
  const subjectTermsetColDefs = useMemo(getSubjectTermsetColDefs, []);
  const types = { Voy: 1, Tct: 2, Coa: 3 };
  const typeId = types[typeKey];
  const [termsetStatus, setTermsetStatus] = useState<Status>();
  const [lastRemovedTerm, setLastRemovedTerm] = useState<CellClickedEvent>(undefined as any);

  useAsyncEffect(loadTermset, [termsetId]);
  useEffect(onBodyChange, [body]);

  useEffect(onTemplateChange, [template?.id]);
  useEffect(consumeToRemove, [lastRemovedTerm]);

  // Logic to restore default values for subs or pick values from firm negotiation
  const initialSubjects = props.context?.values?.subjectTermset;
  const operationalSubs = props.context?.values?.operationalSubs;
  const commercialSubs = props.context?.values?.commercialSubs;
  const subsInitialiesed = useRef<boolean>();
  useEffect(() => {
    if (
      props.context && // this we need while the component lives under two different structures (PTMT and Deal Captrue flows)
      !subsInitialiesed.current && // we only want these values to be set once on page load, the user is then free to edit them
      Array.isArray(initialSubjects?.content?.mainTermTemplates) &&
      Array.isArray(body.subjectTermsetMainTermTemplates) &&
      body.subjectTermsetMainTermTemplates.some((item) => item.content === "") &&
      (operationalSubs.value || commercialSubs.value)
    ) {
      const defaultOperationalSubs = initialSubjects.content.mainTermTemplate && initialSubjects.content.mainTermTemplate[0];
      const defaultCommercialSubs = initialSubjects.content.mainTermTemplate && initialSubjects.content.mainTermTemplate[1];
      const operationalSubsValue = operationalSubs.value || defaultOperationalSubs?.content;
      const commercialSubsValue = commercialSubs.value || defaultCommercialSubs?.content;

      const subjects = [
        { ...body.subjectTermsetMainTermTemplates[0], content: operationalSubsValue || "" },
        { ...body.subjectTermsetMainTermTemplates[1], content: commercialSubsValue || "" },
      ];

      const newSubsAreEqual = body.subjectTermsetMainTermTemplates.reduce(
        (allEqual, sub, i) => allEqual && sub.content === subjects[i].content,
        true
      );

      if (!newSubsAreEqual) {
        subsInitialiesed.current = true;
        body.subjectTermsetMainTermTemplates = subjects;
        onBodyChange();
      }
    }
  }, [initialSubjects?.content?.mainTermTemplates]);

  const columnApi = useRef<ColumnApi>();

  function isCPSelected() {
    return !!termset && termset.content.mainTermTemplates.some((term) => term.parentCPID);
  }

  function setVisibilityForOverrideCP(columnApi: ColumnApi) {
    columnApi.setColumnVisible(OVERRIDE_CP_COLUMN_ID, isCPSelected());
  }

  useEffect(() => {
    if (columnApi.current) {
      setVisibilityForOverrideCP(columnApi.current);
    }
  }, [body, columnApi.current]);

  const deleteRowCell = termsetColDefs.find((x) => Array.isArray(x.cellClass) && x.cellClass.includes("delete-row-cell"));
  if (deleteRowCell) {
    deleteRowCell.onCellClicked = async (params: CellClickedEvent) => {
      const term = params.data as TradeAPI.Termset.Content.Term;
      dialog1.show({
        dataTest: "term-delete-confirm",
        status: {
          type: "warning",
          title: "Delete Term?",
          message: (
            <>
              Are you sure you want to delete the term{term.title ? <strong>&nbsp;{term.title}?</strong> : "?"} <br />
              This action cannot be undone.
            </>
          ),
          hideIcon: true,
        },
        suppressStatusActions: true,
        actions: [
          {
            label: "Cancel",
            variant: "flat",
          },
          {
            label: "Delete Term",
            variant: "warning",
            onClick: () => setLastRemovedTerm(params),
          },
        ],
      });
    };
  }

  return (
    <Context.Provider value={formProps}>
      <owner-deal-capture-termset>
        <h1 className="main-title">Terms Builder</h1>
        <div className="fixed-header">
          <owner-deal-capture-message>
            Import a Termset Template or Previously Executed Charter Party to use as the basis for this negotiation
          </owner-deal-capture-message>
          <owner-deal-capture-search>
            <SelectInput
              {...getEditorProps("selectedOption")}
              data={["Termset Template", "Previously Executed"]}
              nonFilterSelect={true}
            />
            {TemplateOrCPSearch()}
          </owner-deal-capture-search>
        </div>

        <h2>Subjects</h2>
        <Aggrid
          columnDefs={subjectTermsetColDefs}
          rowData={body.subjectTermsetMainTermTemplates}
          className="subject-termset-grid"
          getRowNodeId={getTermsetRowNodeId}
          domLayout="autoHeight"
          immutableData
        />
        <terms-title>
          <div className="term-title-row">
            <h2>Terms</h2>
            {isCPSelected() && body.currentCpSearchItem && (
              <dealcapture-terms-cp-additional-info hidden={false}>
                <dealcapture-terms-cp-additional-info-based>
                  Termset based on CPID{" "}
                  <a className="st-hyperlink" target="_blank" rel="noreferrer" href={body.contractUrlBySearchItem}>
                    {body.currentCpSearchItem.cpId}
                  </a>
                </dealcapture-terms-cp-additional-info-based>
                {body.currentCpSearchItem?.hasCautions && (
                  <>
                    <Icon name="warning" title="Cautionary clauses apply" />
                    <dealcapture-terms-cp-additional-info-warning className="warningMessage">
                      Cautionary clauses apply to this{" "}
                      <a target="_blank" rel="noreferrer" href={body.contractUrlBySearchItem} className="link danger">
                        Charter Party
                      </a>
                    </dealcapture-terms-cp-additional-info-warning>
                  </>
                )}
              </dealcapture-terms-cp-additional-info>
            )}
          </div>
          <Button icon="plus" onClick={addTerm} className="add-term-btn">
            Add Term
          </Button>
        </terms-title>

        <StatusMessages status={status || sf.errorToStatus(errors.termset?.content?.mainTermTemplates)} />
        <sea-aggrid>
          <Aggrid
            columnDefs={termsetColDefs}
            rowData={termset?.content.mainTermTemplates}
            getRowNodeId={getTermsetRowNodeId}
            onRowDragEnd={onRowDragEnd}
            onGridReady={(gridEvent) => {
              columnApi.current = gridEvent.columnApi;
              setVisibilityForOverrideCP(columnApi.current);
            }}
            domLayout="autoHeight"
            rowHeight={TERMSET_ROW_HEIGHT}
            immutableData
            rowDragManaged
          />
          <owner-deal-capture-message hidden={!!termset?.content.mainTermTemplates && !termsetStatus}>
            No terms added yet. Use the dropdown at the top to search for a Termset Template or Previously Executed deal...
          </owner-deal-capture-message>
        </sea-aggrid>
        <Spinner status={termsetStatus} hidden={!termsetStatus} />
      </owner-deal-capture-termset>
      <Button id="clearTermsetBuilderButton" hidden={true} onClick={clear} />
    </Context.Provider>
  );

  function clear() {
    formProps.resetForm();
    onBodyChange();
  }

  function onTemplateChange() {
    if (!template) return;
    body.inheritFromTemplate(template);
    setValues(body);
  }

  function consumeToRemove() {
    if (!lastRemovedTerm) return;
    // @ts-ignore
    // check termId if it's terms from termset, check uid if it's UI added fresh terms
    const isTermToKeep = (term: TradeAPI.Termset.Content.Term) =>
      term.termId ? term.termId !== lastRemovedTerm.data?.termId : term.uid !== lastRemovedTerm.data?.uid;
    // @ts-ignore
    const next = body.termset?.content.mainTermTemplates.filter(isTermToKeep);
    setFieldValue("termset.content.mainTermTemplates", next);
  }

  function onRowDragEnd(params: RowDragEndEvent) {
    const { api } = params;
    api.forEachNode((node, i) => {
      if (body.termset) {
        const data = node?.data;
        if (data) {
          data.i = i;
        }
        body.termset.content.mainTermTemplates[i] = data;
      }
    });
    onChange(body.termset);
  }

  function TemplateOrCPSearch() {
    if (selectedOption === "Termset Template")
      return (
        <TermsetSearchEditor
          {...getEditorProps("termsetId")}
          icon="search"
          typeId={typeId}
          className="owner-deal-capture-search-editor"
        />
      );
    else
      return (
        <>
          <Input
            {...getEditorProps("cpSearchValue")}
            placeholder="Search for a Charter Party e.g. counterparty or vessel"
            type="Text"
            submitable
            onSubmit={async () => {
              const searchDialogRes = await openCPSearchDialog();
              if (searchDialogRes) {
                if (searchDialogRes.termset) {
                  usageMetrics.trackEvent(MainTermsTypeEvents.PREV_EXECTUTED_CHARTER_PARTY_SELECTED);
                  const details = formProps.values.details;
                  const termset = searchDialogRes.termset;
                  updateHandlebarsInTermset(termset, details);
                  formProps.setFieldValue("termset", termset);
                  formProps.setFieldValue("termsetId", termset?.Id);
                  formProps.setFieldValue("currentCpSearchItem", searchDialogRes.cpSearchItem);
                  formProps.setFieldValue("termset.content.previouslyExecutedCpId", searchDialogRes.cpSearchItem.cpId);
                }
              }
            }}
            icon="search"
          />
          {/* <PeriodEditor {...getEditorProps("cpSearchDateRange")} label="Date Range" /> */}
        </>
      );
  }

  async function openCPSearchDialog() {
    const ResultsGrid = observer(() => {
      const formProps = formPropsBox.get();
      const { getEditorProps, values: body } = formProps;
      const [cpsStatus, setCpsStatus] = useState<Status>();
      const [cps, setCps] = useState<TradeAPI.CpSearchItem[]>();
      const [dialogStatus, setDialogStatus] = useState<Status>();

      async function searchForCps() {
        setCpsStatus({ loading: true, message: "Loading CPs" });
        setCps(undefined);
        let res: TradeAPI.CpSearchItem[];
        if (body.cpSearchDateRange) res = await body.getCpsWithDate(body.cpSearchValue!, body.cpSearchDateRange!);
        else res = await body.searchCps(body.cpSearchValue!);
        setCpsStatus(undefined);
        setCps(res);
      }

      useAsyncEffect(async () => {
        searchForCps();
      }, []);

      const frameworkComponents = {
        CPTermsetsResults: observer((params: Aggrid.MasterDetail.CustomDetailCell.Params) => {
          const { node } = params;
          const cpSearchItem = params.data as CpSearchItem;
          const cpTermsetsSearchResultsColDefs = useMemo(getCpTermsetsSearchResultsColDefs, []);
          const formProps = formPropsBox.get();
          const { values: body } = formProps;
          const [termsets, setTermsets] = useState<Termset[]>();
          const [termsetsStatus, setTermsetsStatus] = useState<Status>();
          const containerRef = useRef() as ReactElementDomRef;

          useKeepRerenderingUntilTruthy(containerRef.current?.clientHeight);
          useEffect(onContainerHeightChange, [containerRef.current?.clientHeight, termsets]);
          useAsyncEffect(async () => {
            setTermsetsStatus({ loading: true, message: "Loading Termsets" });
            const termsets = await body.getCpTermsets(params.data);
            setTermsetsStatus(undefined);
            setTermsets(termsets as Termset[]);
          }, []);

          return (
            <cp-termsets-grid ref={containerRef}>
              <Aggrid
                columnDefs={cpTermsetsSearchResultsColDefs}
                rowData={termsets}
                getRowNodeId={(termset) => termset.id}
                onRowClicked={async (params) => {
                  setDialogStatus({ loading: true });
                  const termsetSearchItem = params.data as Termset;
                  const cp = new CP(await body.getCp(cpSearchItem.cpId));
                  const termset = new Termset(await body.getTermset(termsetSearchItem.id));
                  termset.content.mainTermTemplates.forEach((term) => {
                    term.cpField = cp.DataFields[term.cpmProformaKey];
                    if (term.cpField) {
                      // @ts-ignore
                      const { CP } = cp;
                      term.cpValueOverride = true;
                      term.parentCPID = CP.CPID;
                    }
                  });
                  setDialogStatus(undefined);
                  dialog.close({ termset, cpSearchItem });
                }}
                immutableData
                domLayout="autoHeight"
                hidden={!termsets}
              />
              <owner-deal-capture-message hidden={termsets ? termsets.length > 0 : true}>
                No termsets found
              </owner-deal-capture-message>
              <Spinner status={termsetsStatus} hidden={!termsetsStatus} />
            </cp-termsets-grid>
          );

          function getCpTermsetsSearchResultsColDefs() {
            return [
              Aggrid.create.multiValue.colDef({
                sequence: [{ name: "name", heading: "" }],
                valueGetter: (params) => params.data,
                suppressMenu: true,
              }),
            ];
          }

          function onContainerHeightChange() {
            if (!containerRef.current?.clientHeight) return;
            node.setRowHeight(containerRef.current?.clientHeight + 1);
            params.api.onRowHeightChanged();
          }
        }),
      };

      return (
        <cp-results-dialog>
          <cp-results-dialog-title>
            Search Results{" "}
            <Icon
              name="close"
              onClick={() => {
                dialog.close();
              }}
            />
          </cp-results-dialog-title>
          <app-vertical-separator></app-vertical-separator>
          <cp-results-dialog-search-inputs>
            <Input
              {...getEditorProps("cpSearchValue")}
              placeholder="Search for a Charter Party e.g. counterparty or vessel"
              type="Text"
              submitable
              onSubmit={() => {
                searchForCps();
              }}
              icon="search"
            />
            {/* <PeriodEditor {...getEditorProps("cpSearchDateRange")} /> */}
          </cp-results-dialog-search-inputs>
          <app-vertical-separator style={{ marginBottom: "-1px" }}></app-vertical-separator>
          <cp-results-dialog-grid>
            <Aggrid
              columnDefs={cpSearchResultsColDefs}
              rowData={cps}
              getRowNodeId={getCpRowNodeId}
              onRowClicked={onRowClicked}
              detailCellRenderer="CPTermsetsResults"
              detailCellRendererParams={detailCellRendererParams}
              frameworkComponents={frameworkComponents}
              masterDetail
              detailRowHeight={0}
              immutableData
              hidden={!isPopulatedArray(cps)}
            />
            <owner-deal-capture-message hidden={!!cpsStatus || isPopulatedArray(cps)}>
              No results found
            </owner-deal-capture-message>
            <Spinner status={cpsStatus} hidden={!cpsStatus} />
          </cp-results-dialog-grid>
          <Spinner status={dialogStatus} hidden={!dialogStatus} overlay alignment="center" />
          <app-vertical-separator style={{ marginTop: 0 }}></app-vertical-separator>
          <cp-results-dialog-footer>
            <Button
              label="cancel"
              onClick={() => {
                dialog.close();
              }}
            />
          </cp-results-dialog-footer>
        </cp-results-dialog>
      );
    });

    return await dialog.open<any>(<ResultsGrid />, { suppressCloseBtn: true });
  }

  function getFormConfig() {
    return {
      initialValues: new Body(
        { as: undefined, typeMap: { Voy: true }, statusMap: { MainTerms: true }, selectedOption: "Termset Template" },
        "MainTerms"
      ),
      onSubmit: emptyArrowFn,
      validationSchema,
      dataTest: "owner-deal-capture",
    } as sf.StandardForm.Config<Body>;
  }

  function createContext() {
    return createReactContext(formPropsRef.current);
  }

  function onBodyChange() {
    // @ts-ignore
    body.termset?.content.mainTermTemplates.forEach((term, i) => (term.i = i));
    // @ts-ignore
    body.subjectTermsetMainTermTemplates?.forEach((term, i) => (term.i = i));
    onChange(body.termset);
    onSubjectChanged(body.subjectTermsetMainTermTemplates);
  }

  function addTerm() {
    const original = body.termset?.content.mainTermTemplates || [];
    const next = [{ title: "", content: "", termId: "", uid: uid() }, ...original];

    setFieldValue("termset.content.mainTermTemplates", next);
    usageMetrics.trackEvent(MainTermsTypeEvents.ADD_TERM);
  }

  async function loadTermset() {
    if (!termsetId) return;

    setTermsetStatus({ loading: true, message: "Loading Termset" });

    const res = await tradeAPI.termsets.get(termsetId);

    setTermsetStatus(undefined);

    if (!res.ok) return dialog0.open({ type: "error", res });

    // @ts-ignore
    res.data.content.mainTermTemplates.forEach((term) => (term.uid = uid()));
    const details = formPropsBox.get().values.details;
    updateHandlebarsInTermset(res.data, details);
    setFieldValue("termset", new Termset(res.data));
  }

  function onOrderDefinitionChange() {
    const orderDetails = parentContext?.values;
    body.details = orderDetails;
    setValues(body);
    body.termset?.content?.mainTermTemplates?.forEach((term, i) => {
      updateHandlebarsIfChanged(term.content, term.handlebars, orderDetails, i);
    });
  }

  function onOrderDetailsChange() {
    const orderDetails = orderTermsBody?.details;
    body.details = orderDetails;
    setValues(body);
    body.termset?.content?.mainTermTemplates?.forEach((term, i) => {
      updateHandlebarsIfChanged(term.content, term.handlebars, orderDetails, i);
    });
  }

  function onTermDetailsChanged(term: any, newValue: string) {
    const details = formPropsBox.get().values.details;
    updateHandlebarsIfChanged(newValue, term.handlebars, details, term.i);
  }

  function updateHandlebarsIfChanged(termDetails: string, currentHandlebars: any, orderDetails: any, i: number) {
    const newHandlebars = handlebarParser.getHandlebars(termDetails, orderDetails);
    if (!handlebarParser.areHandlebarsSame(currentHandlebars, newHandlebars)) {
      setFieldValue(`termset.content.mainTermTemplates.${i}.handlebars`, newHandlebars);
    }
  }

  function updateHandlebarsInTermset(termset: TradeAPI.Termset, details: Body.Details) {
    termset.content.mainTermTemplates.forEach((term) => (term.handlebars = handlebarParser.getHandlebars(term.content, details)));
  }

  function onRowClicked(params: AggridRowClickedEvent) {
    const { api, node } = params;
    const nextState = !node.expanded;
    api.forEachNode((otherNode) => otherNode.expanded && api.onGroupExpandedOrCollapsed(otherNode.setExpanded(false)));
    if (!node.detail) api.onGroupExpandedOrCollapsed(node.setExpanded(nextState));
  }

  function getCpSearchResultsColDefs() {
    extend(advancedFormat);
    return [
      Aggrid.create.chevron.colDef({}),
      Aggrid.create.multiValue.colDef({
        sequence: [{ name: "cpId", heading: "CPID" }],
        valueGetter: (params) => params.data,
        $reactCellRenderer: (params) => (
          <a
            className="st-hyperlink"
            href={params.data.linkUrl}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {params.data.cpId}
          </a>
        ),
        maxWidth: 75,
        suppressMenu: true,
        $defaultColumnState: { sort: "desc", multiValueSortName: "cpId" },
      }),
      Aggrid.create.multiValue.colDef({
        sequence: [{ name: "cpFormName", heading: "CP Form" }],
        valueGetter: (params) => params.data,
        resizable: true,
      }),
      Aggrid.create.multiValue.colDef<CP>({
        sequence: [{ name: "cpDate", heading: "CP Date" }],
        valueGetter: (params) => {
          return params.data;
        },
        filterParams: {
          filterRenderer: (value) => ((value as string) ? dayjs(value).format("YYYY-MM-DD") : value),
        },
        $reactCellRenderer: (params) => (params.data.cpDate ? dayjs(params.data.cpDate).format("Do MMM YYYY") : ""),
        resizable: true,
        maxWidth: 100,
      }),
      Aggrid.create.multiValue.colDef({
        sequence: [{ name: "vesselName", heading: "Vessel Name" }],
        valueGetter: (params) => params.data,
        resizable: true,
      }),
      Aggrid.create.multiValue.colDef({
        sequence: [{ name: "charterer", heading: "Charterer" }],
        valueGetter: (params) => params.data,
        resizable: true,
      }),
      Aggrid.create.multiValue.colDef({
        sequence: [{ name: "broker", heading: "Broker" }],
        valueGetter: (params) => params.data,
        resizable: true,
      }),
      Aggrid.create.multiValue.colDef({
        sequence: [{ name: "owner", heading: "Owner" }],
        valueGetter: (params) => params.data,
        resizable: true,
      }),
      Aggrid.create.multiValue.colDef({
        sequence: [{ name: "status", heading: "Status" }],
        valueGetter: (params) => {
          const statusArray = [
            "Draft",
            "On Subs",
            "Fully Fixed",
            "Cancelled",
            "Working Copy",
            "Final",
            "Dry Draft",
            "Dry Cancelled",
            "Proforma",
            "Tanker Proforma",
            "Unknown",
          ];
          return { status: statusArray[+params.data] };
        },
        resizable: true,
      }),
      Aggrid.create.icon.colDef(
        {
          name: "warning",
          title: "Cautionary clauses apply",
        },
        {
          field: "hasCautions",
          initialWidth: 65,
          minWidth: 65,
          headerName: "Cautions",
          cellClass: "caution",
          cellClassRules: { "no-caution": (params) => !params.data.hasCautions },
        }
      ),
    ];
  }

  function getSubjectTermsetColDefs() {
    return [
      Aggrid.create.multiValue.colDef<TradeAPI.Termset.Content.Term>({
        sequence: [{ name: "", heading: "" }],
        rowDrag: true,
        suppressMenu: true,
        sortable: false,
        width: 35,
        maxWidth: 35,
        cellClass: "hidden-cell",
      }),
      Aggrid.create.multiValue.colDef<TradeAPI.Termset.Content.Term>({
        sequence: [{ name: "title", heading: "Title" }],
        valueGetter: (params) => params.data,
        filter: false,
        suppressMenu: true,
        sortable: false,
        maxWidth: 250,
        lockPosition: true,
      }),
      Aggrid.create.multiValue.colDef<TradeAPI.Termset.Content.Term>({
        sequence: [{ name: "content", heading: "Details" }],
        valueGetter: (params) => params.data,
        $reactCellEditor: sf.create.aggrid.cell.editor<Body>({
          Editor: TextareaField,
          editorPropsGetter: (params) =>
            useContext(Context).getEditorProps(`subjectTermsetMainTermTemplates.${params.data.i}.content`), // eslint-disable-line react-hooks/rules-of-hooks
        }),
        editable: true,
        filter: false,
        suppressMenu: true,
        sortable: false,
        lockPosition: true,
      }),
    ];
  }

  function getTermsetColDefs() {
    return [
      Aggrid.create.multiValue.colDef<TradeAPI.Termset.Content.Term>({
        sequence: [{ name: "", heading: "" }],
        rowDrag: true,
        suppressMenu: true,
        sortable: false,
        width: 30,
        maxWidth: 30,
        cellClass: "draggable-row-handle",
      }),
      Aggrid.create.multiValue.colDef<TradeAPI.Termset.Content.Term>({
        sequence: [{ name: "title", heading: "Title" }],
        valueGetter: (params) => params.data,
        $reactCellRenderer: (params) => <>{params.data.title}</>,
        $reactCellEditor: sf.create.aggrid.cell.editor<Body>({
          Editor: TextField,
          editorPropsGetter: (params) =>
            useContext(Context).getEditorProps(`termset.content.mainTermTemplates.${params.data.i}.title`), // eslint-disable-line react-hooks/rules-of-hooks
        }),
        editable: true,
        filter: false,
        suppressMenu: true,
        sortable: false,
        maxWidth: 250,
        lockPosition: true,
        cellStyle: { paddingTop: 0 },
      }),
      Aggrid.create.multiValue.colDef<TradeAPI.Termset.Content.Term>({
        sequence: [{ name: "content", heading: "Details" }],
        valueGetter: (params) => {
          if ("cpValueOverride" in params.data) {
            const asPerCpidPill = `As per CPID ${params.data.parentCPID}`;
            const currentUniquePillsSet = new Set(params.data.pills);

            if (!params.data.cpValueOverride) {
              currentUniquePillsSet.add(asPerCpidPill);
            } else {
              currentUniquePillsSet.delete(asPerCpidPill);
            }
            params.data.pills = [...currentUniquePillsSet];
          }

          return params.data;
        },
        $reactCellRenderer: (params) => {
          return (
            <Tooltip
              placement="bottom"
              title={
                <div className="tooltip-container">
                  <p className="enhanced-readability">
                    <TermDetails term={params.data}></TermDetails>
                  </p>
                </div>
              }
              interactive
              enterDelay={500}
              leaveDelay={100}
            >
              <span className="minimal-hover-surface">
                <TermDetails term={params.data}></TermDetails>
              </span>
            </Tooltip>
          );
        },
        $reactCellEditor: sf.create.aggrid.cell.editor<Body>({
          Editor: TextareaField,
          /*eslint-disable react-hooks/rules-of-hooks*/
          editorPropsGetter: (params) => {
            return useContext(Context).getEditorProps(
              `termset.content.mainTermTemplates.${params.data.i}.content`,
              undefined,
              (value: string) => onTermDetailsChanged(params.data, value)
            ) as sf.StandardForm.EditorProps<Body>;
          },
          /*eslint-enable */
        }),
        editable: (params) => params.data.cpValueOverride === undefined || params.data.cpValueOverride === true,
        filter: false,
        suppressMenu: true,
        sortable: false,
        cellStyle: { paddingTop: 0, alignItems: "center" },
        lockPosition: true,
      }),
      Aggrid.create.multiValue.colDef<TradeAPI.Termset.Content.Term>({
        flex: 0,
        sequence: [{ name: "", heading: "" }],
        width: 36,
        editable: false,
        suppressMenu: true,
        suppressMovable: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
        headerName: "",
        cellClass: ["aggrid-icon-cell", "delete-row-cell"],
        cellRenderer: renderIcon.bind(null, "delete"),
        lockPosition: true,
      }),
      Aggrid.create.multiValue.colDef<TradeAPI.Termset.Content.Term>({
        colId: OVERRIDE_CP_COLUMN_ID,
        sequence: [{ name: "cpValueOverride", heading: "Override CP" }],
        $reactHeaderComponent: (params: resolveMultiValueColDef.Params<TradeAPI.Termset.Content.Term>) => {
          return (
            <Tooltip
              placement="left"
              className="override-cp-tooltip"
              title={
                <div className="tooltip-container">
                  <div className="tooltip-customArrow tooltip-customArrow--right" />
                  <p>
                    When turned ON, new main terms data will override main terms from the previously executed charter party.
                    <br />
                    <br />
                    Turn OFF to keep as per last.
                  </p>
                </div>
              }
            >
              <div className="override-cp-header">
                <span className="column-header-text">
                  {(params.sequence[0] as resolveMultiValueColDef.SequenceItem).heading}
                  <Icon icon="info-outline" className="column-header-icon" />
                </span>
              </div>
            </Tooltip>
          );
        },
        valueGetter: (params) => params.data,
        $reactCellRenderer: (params: Aggrid.Cell.ValueGetter.Params<TradeAPI.Termset.Content.Term>) => {
          if (params.data.cpField) {
            return (
              <div data-ui-library="mui" className="termset-switch-container">
                <Switch
                  size="medium"
                  color="primary"
                  checked={params.data.cpValueOverride}
                  onChange={() => overrideCpTerm(params.data)}
                />
              </div>
            );
          }
          return null;
        },
        maxWidth: 110,
        headerClass: "termsetOverride",
        cellClass: "align-right",
        cellStyle: { paddingTop: 0 },
        editable: false,
        filter: false,
        sortable: false,
        suppressMenu: true,
        lockPosition: true,
      }),
    ];
  }

  function overrideCpTerm(data: TradeAPI.Termset.Content.Term) {
    // @ts-ignore
    const { i, cpValueOverride, cpField } = data;
    setFieldValue(`termset.content.mainTermTemplates.${i}.cpValueOverride`, !cpValueOverride);
    setFieldValue(`termset.content.mainTermTemplates.${i}.overrideContent`, data.content ?? "");
    setFieldValue(
      `termset.content.mainTermTemplates.${i}.content`,
      (!cpValueOverride ? data.overrideContent : cpField.Value) ?? ""
    );
  }
});

const getTermsetRowNodeId = (term: TradeAPI.Termset.Content.Term) => term.uid || term.termId;
const getCpRowNodeId = (cpSearchItem: CpSearchItem) => cpSearchItem.cpId;

const validationSchema = object({
  details: object({}),
  termset: object({
    content: object({
      mainTermTemplates: array().of(
        object({}).test({
          name: "Unique Term Title",
          message: "Something is wrong with the Terms",
          test: function (this: TestContext, value) {
            const term = value as TradeAPI.Termset.Content.Term;
            const duplicateTerm = resolveDuplicateTermTitle(this.parent);
            const isDuplicate = duplicateTerm?.title.toUpperCase() === term.title.toUpperCase();

            if (duplicateTerm && isDuplicate) {
              const error = this.createError();
              error.message = `Duplicate term title: ${term.title}, ${duplicateTerm.title}`;
              error.path = `${this.path}.title`;

              return error;
            }

            return true;
          },
        })
      ),
    }),
  }),
});

function resolveDuplicateTermTitle(mainTermTemplates: TradeAPI.Termset.Content.Term[]) {
  mainTermTemplates = mainTermTemplates || [];

  let duplicateTerm: TradeAPI.Termset.Content.Term | undefined;
  const termTitleCountMap = {};

  for (let i = 0; i < mainTermTemplates.length; i++) {
    const term = mainTermTemplates[i];
    const termTitle = term.title?.toUpperCase();

    if (!termTitle) continue;

    termTitleCountMap[termTitle] = termTitleCountMap[termTitle] || 0;

    termTitleCountMap[termTitle]++;

    if (termTitleCountMap[termTitle] > 1) {
      duplicateTerm = term;
    }
  }

  return duplicateTerm;
}

const detailCellRendererParams = {
  // refreshStrategy: "everything",
};

export { TermsetBuilder, TermsetBuilderObserver, resolveDuplicateTermTitle };

interface TermsetBuilderProps {
  context?: any;
  formProps?: any;
  onChange: (termset?: Termset) => void;
  onChangeSubjects?: (subjects: Subs) => void;
  onReset?: React.MutableRefObject<() => void>;
  status?: Status;
}
