import { Commission, CommissionProps } from "../Commission";
import { LABELS } from "@/constants";

export class AddressCommission extends Commission {}

class AddressCommissionProps extends CommissionProps {}

AddressCommissionProps.prototype.label = LABELS["addressCommission"];

AddressCommission.prototype.Props = AddressCommissionProps;
AddressCommission.prototype._ = new AddressCommissionProps();

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */
