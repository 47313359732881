import React, { useContext } from "react";
import { SummaryDetails } from "@/components";
import { Context } from "../Negotiation";

export function Details() {
  const context = useContext(Context);
  const negotiation = context.negotiation;
  const negotiationOrFixedCOANegotiation = negotiation?.isLifting()
    ? negotiation?.getOrderNegotiationStoreFixedCOANegotiationModel()
    : negotiation;
  const details = negotiationOrFixedCOANegotiation?.getNonNegotiableInitialTermModelArray();

  return <SummaryDetails list={details} />;
}
