import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { useExecuteAtDate, useForceUpdate } from "@/utils";
import { entityStorage } from "@/services";
import { auth, Negotiation } from "@/models";
import { Banner, BannerProps } from "@/components";
import "./BrokerBanner.scss";

function BrokerBanner(props: Props) {
  const { negotiation } = props;

  const { hasBroker } = negotiation || {};
  const actorIsBroker = auth.trade.user?._.companyRoleMap.broker;
  const isChartererWithBrokeredNeg = !actorIsBroker && hasBroker;
  const ownerOrBrokerText = isChartererWithBrokeredNeg ? "Broker" : "Owner";
  const version = negotiation?.version || {};
  const actionsModel = negotiation?.getActionsModel();
  const isBeyondInitialTerms = negotiation?.isBeyondInitialTerms();
  const isOnSubs = negotiation?.status === "OnSubs";
  const isNegotiationLoading = negotiation?._.status.loading;
  const {
    ownerFirmed,
    ownerFirmedExpired,
    brokerChartererFirmRequested,
    brokerChartererFirmed,
    brokerChartererFirmedExpired,
    ownerFirmExpiresOn,
    brokerChartererFirmExpiresOn,
    firmBidAccepted,
    firmOfferAccepted,
    hasExpiredAction,
  } = actionsModel || {};
  const banner = useMemo(resolveBanner, [version, isNegotiationLoading]);
  const persisted = entityStorage.get(negotiation);
  const onExpireUpdate = useForceUpdateExpiry();
  useExecuteAtDate(ownerFirmExpiresOn, onExpireUpdate);
  useExecuteAtDate(brokerChartererFirmExpiresOn, onExpireUpdate);

  return (
    <Banner
      className="BrokerBanner"
      {...banner}
      version={version}
      onClose={onClose}
      hidden={
        persisted?.versionWhenBannerWasClosed >= version &&
        (persisted?.expiryDismiss === undefined || (persisted?.expiryDismiss && persisted?.expiryDismissVersion === version))
      }
    />
  );

  function onClose() {
    if (hasExpiredAction) entityStorage.setExpiryDismissBannerToggle(true, negotiation);
    entityStorage.setVersionWhenBannerWasClosed(negotiation);
  }

  function useForceUpdateExpiry() {
    const forceUpdate = useForceUpdate();
    if (hasExpiredAction) entityStorage.setExpiryDismissBannerToggle(false, negotiation);
    return forceUpdate;
  }

  function resolveBanner(): BannerProps | void {
    if (isNegotiationLoading) {
      return;
    }
    if (isBeyondInitialTerms) return;

    if (firmBidAccepted) {
      return {
        label: 'Firm Bid Accepted. Click "Proceed to Main Terms" to begin negotiating Main Terms',
        subClassName: "positive",
      };
    }

    if (firmOfferAccepted) {
      return {
        label: 'Firm Offer Accepted. Click "Proceed to Main Terms" to begin negotiating Main Terms',
        subClassName: "positive",
      };
    }

    if (brokerChartererFirmed) {
      if (brokerChartererFirmedExpired) {
        return {
          label: `Your Firm Bid Expired. ${hasBroker ? "" : "You can continue to negotiate below"}`,
          subClassName: "orange",
        };
      }

      return {
        label: `You have sent a firm bid to the ${ownerOrBrokerText}. They have until the timer runs out to respond`,
      };
    }

    if (brokerChartererFirmRequested) {
      return {
        label: `You requested a firm offer from the ${ownerOrBrokerText}. They have not responded yet`,
      };
    }

    if (ownerFirmed) {
      if (ownerFirmedExpired) {
        return {
          label: `The Owner's Firm Offer Expired. ${hasBroker ? "" : "You can continue to negotiate below"}`,
          subClassName: "orange",
        };
      }

      return {
        label: `The Owner sent a Firm Offer. ${hasBroker ? "" : "Respond below"}`,
        subClassName: "orange",
      };
    }

    return;
  }
}

const Observer = observer(BrokerBanner);

export { Observer as BrokerBanner };

interface Props extends BannerProps {
  negotiation?: Negotiation;
}
