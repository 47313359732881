import { TokenStore } from "services/TokenStore";
import { authenticationResult } from "./CloudAuthenticator";
import { Configuration } from "sharedFolder/Configuration";

interface ITokenProvider {
  ctradeToken: Promise<string | null>;
  cloudToken: string;
  clear: () => void;
}

/**
 * Provider to retrieve the token.
 * If token is present in the query string, this will strip the token from the query string
 */

class TokenProvider implements ITokenProvider {
  private readonly ctradeTokenLifespan = 1000 * 60 * 10; // 10 minutes
  private cachedCtradeToken = new TokenStore("ctradeToken");
  private cachedCtradeTokenExpiry = new TokenStore("ctradeTokenExpiry");

  public constructor() {
    this.invalidateCtradeToken = this.invalidateCtradeToken.bind(this);
  }

  public get ctradeToken(): Promise<string | null> {
    // invalidate cached token if expired
    const expires = this.cachedCtradeTokenExpiry.get();
    if (!expires || parseInt(expires, 10) < new Date().valueOf()) {
      this.invalidateCtradeToken();
    } else if (this.cachedCtradeToken.get()) {
      return Promise.resolve(this.cachedCtradeToken.get());
    }

    return this.retrieveCtradeToken().then(({ error, token }) => {
      if (error) {
        throw error;
      }
      if (token) {
        this.cachedCtradeToken.set(token);
        this.cachedCtradeTokenExpiry.set((new Date().valueOf() + this.ctradeTokenLifespan).toString());
      }
      return token || null;
    });
  }

  public get cloudToken(): string {
    if (!authenticationResult.hasBeenAuthenticated()) {
      this.clear();
    }

    return authenticationResult.getAuthorisationToken();
  }

  public clear() {
    this.cachedCtradeToken.clear();
    this.cachedCtradeTokenExpiry.clear();
  }

  /**
   * Retrieve CTrade token (JWT)
   * Will immediately return the cached token if present,
   * or resolve a new token from the identity service if not.
   * If neither ctrade nor cloud token exist, will return null.
   */
  private async retrieveCtradeToken(): Promise<{
    token?: string;
    error?: { message: string; cloudToken: string };
  }> {
    try {
      const config = await Configuration();
      // const client = Axios.create({
      //   baseURL: config.ctradeUrl,
      //   headers: { 'Clarksons.Cloud.LoginToken': this.cloudToken || '' }
      // });
      // const response = await client.post('token',);
      // const token = await response.data;
      const response = await fetch(`${config.ctradeUrl}/token`, {
        method: "POST",
        headers: {
          "Clarksons.Cloud.LoginToken": this.cloudToken || "",
        },
      }).then((response) => {
        // this is added so we can expose the whole Response error object rather than just the error message
        if (!response.ok) {
          throw response;
        }
        return response;
      });
      const token = await response.text();

      // ensure the ctradeToken is short-lived
      return { token };
    } catch (err: any) {
      const errorStatus = !err.status ? "undefined" : err.status;
      return {
        error: {
          message: `${errorStatus} error occured during POST /token`,
          cloudToken: this.cloudToken ? this.cloudToken.substring(0, 3) : "not found",
        },
      };
    }
  }

  /**
   * Invalidate the CTrade token.
   * This will cause the next ctrade token retrieval to resolve the token from
   * the identity service using the cached cloud token.
   */
  private invalidateCtradeToken() {
    this.cachedCtradeToken.clear();
  }
}

export const tokens: ITokenProvider = new TokenProvider();
