import { emptyArrowFn } from "@/utils";
import { createContext } from "react";
import { ITracking, Interaction } from "./ITracking";

export const EmptyTrackingProvider: ITracking = {
  pageView: (uri: string, name: string) => {
    /* */
  },
  componentView: (componentName: string, url: string) => {
    /* */
  },
  interaction: (
    name: Interaction,
    properties?: {
      [key: string]: any;
    }
  ) => {
    /* */
  },
  authEvent: (type: "login" | "logout" | "expiry", userId?: string) => {
    /* */
  },
  trackException: emptyArrowFn,
};

const TrackingContextComponent = createContext<ITracking>(EmptyTrackingProvider);

TrackingContextComponent.displayName = "TrackingContext";

export const TrackingContext = TrackingContextComponent;
