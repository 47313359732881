import {
  IDistributionViewModel,
  IDistributionListViewModel,
  IDeskView,
  DistributionUserRole,
} from "../../../sharedFolder/Models/IDetails";
import { mapRecipientList } from "sharedFolder/mappers/mapRecipientList";
import { updateRecipientRole } from "../roles/roleUtilities";
import { getEmailsOfUsersInBothCollections, getUsersAndDesksState } from "./distributionReducerUtilities";
import { IUserContext } from "__legacy/dashboard/contexts/UserProvider";
import { unflatten } from "./distributionReducer";

export type SearchStateType = "untouched" | "loading" | "noresults" | "results" | "search-string-too-long" | "two-email-address";

export interface DistributionSearchState {
  searchResultsDistributions: IDistributionListViewModel;
  searchTerm: string;
  searchState: SearchStateType;
  currentlyLoggedInUserContext?: IUserContext;
}

export const initialSearchState: DistributionSearchState = {
  searchState: "untouched",
  searchResultsDistributions: mapRecipientList.emptyViewModel,
  searchTerm: "",
};

type ResolveSearchAction = {
  type: "resolveSearch";
  payload: {
    savedUsers: IDistributionListViewModel;
    results: IDistributionListViewModel;
    searchTerm: string;
  };
};

type UpdateSearchAction = {
  type: "updateSearchResults";
  payload: {
    savedUsers: IDistributionListViewModel;
  };
};

type SetSearchTermAction = {
  type: "setSearchTerm";
  payload: string;
};

type UpdateUserRole = {
  type: "updateUserRole";
  payload: { email: string; role: DistributionUserRole };
};

export type DistributionSearchActions = ResolveSearchAction | SetSearchTermAction | UpdateSearchAction | UpdateUserRole;

/**
 * These are alias over the mapping functions just to make the code a little clearer as they are
 * essentially being used as functions to flatten and recontruct the tree view
 */

const flatten = mapRecipientList.toApi;

/**
 * Returns Search results with recipients disabled (if they are in the savedUsers) and
 * desks disabled if any of the recipients are disabled
 * @param savedUsers
 * @param searchResults
 */
const getSearchResultsWithSavedUsersSpecified = (
  savedUsers: IDistributionListViewModel,
  searchResults: IDistributionListViewModel
): IDistributionListViewModel => {
  const emailsInSavedAndSearchResults = getEmailsOfUsersInBothCollections(flatten(searchResults), flatten(savedUsers));

  const setDesksDisbledState = (desk: IDeskView): IDeskView => {
    const recipients = desk.recipients.map((deskModel: IDistributionViewModel) => {
      if (emailsInSavedAndSearchResults.map((email) => email.toLowerCase()).includes(deskModel.email.toLowerCase())) {
        return { ...deskModel, disableUserRow: true };
      }
      return {
        ...deskModel,
        disableUserRow: !deskModel.availableRoles.length,
      };
    });
    return {
      ...desk,
      recipients,
      isDeskDisabled: recipients.some((recip) => recip.disableUserRow),
    };
  };
  return {
    ...searchResults,
    companies: searchResults.companies.map((company) => ({
      ...company,
      desks: company.desks.map(setDesksDisbledState),
    })),
  };
};

const returnWithUpdatedResults = (
  state: DistributionSearchState,
  savedUsers: IDistributionListViewModel,
  results: IDistributionListViewModel
): DistributionSearchState => {
  const containsResults = results.recipients.length + results.companies.length;

  return {
    ...state,
    searchState: containsResults ? "results" : "noresults",
    searchResultsDistributions: getSearchResultsWithSavedUsersSpecified(savedUsers, results),
  };
};

const regexForEmail = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi;

export const validateSearchInput = (searchTerm: string): SearchStateType | "Valid" => {
  // If the search input has more than one email address
  if ([...searchTerm.matchAll(regexForEmail)].length > 1) return "two-email-address";
  // OR is longer than 50 characters
  if (searchTerm.length > 50) return "search-string-too-long";
  return "Valid";
};
export const distributionSearchReducer = (
  state: DistributionSearchState,
  action: DistributionSearchActions
): DistributionSearchState => {
  switch (action.type) {
    case "setSearchTerm": {
      if (!action.payload.length) {
        return {
          ...state,
          searchResultsDistributions: mapRecipientList.emptyViewModel,
          searchTerm: action.payload,
          searchState: "untouched",
        };
      }
      const searchInputValidationResult = validateSearchInput(action.payload);
      if (searchInputValidationResult !== "Valid") {
        return {
          ...state,
          searchTerm: action.payload,
          searchState: searchInputValidationResult,
        };
      }
      const isUserEnteringAnEmail = action.payload.includes("@");
      if (isUserEnteringAnEmail) {
        return {
          ...state,
          searchTerm: action.payload,
          searchState: "noresults",
        };
      }
      return {
        ...state,
        searchTerm: action.payload,
        searchState: "loading",
      };
    }
    case "resolveSearch": {
      // If the most recent search term is not the one we are about to display
      // the results of, then ignore this action and return the state
      if (
        action.payload.searchTerm !== state.searchTerm ||
        ["search-string-too-long", "two-email-address"].includes(state.searchState)
      ) {
        return state;
      }
      const updatedSearchResults = returnWithUpdatedResults(state, action.payload.savedUsers, action.payload.results);

      const asdf = getUsersAndDesksState(
        flatten(updatedSearchResults.searchResultsDistributions),
        state.currentlyLoggedInUserContext
      );

      return {
        ...updatedSearchResults,
        searchResultsDistributions: unflatten(asdf),
      };
    }
    case "updateSearchResults": {
      return returnWithUpdatedResults(state, action.payload.savedUsers, state.searchResultsDistributions);
    }
    case "updateUserRole": {
      return {
        ...state,
        searchResultsDistributions: unflatten(
          updateRecipientRole(flatten(state.searchResultsDistributions), action.payload.email, action.payload.role)
        ),
      };
    }
    default: {
      return state;
    }
  }
};
