import React, { useState } from "react";
import styles from "./FirmOfferRequestControls.module.scss";
import { FirmOfferTimer } from "./FirmOfferTimer";
import { ExpiresOnValue } from "sharedFolder/components/common/types";
import expiryTypeReducer from "sharedFolder/reducers/expiryTypeReducer";
import Button from "__legacy/sharedFolder/components/common/Button/Button";

interface IFirmOfferRequestedControlsProps {
  onSubmitFirmOffer: (date: string) => void;
  onDeclineFirmOfferRequest: () => void;
}

export const FirmOfferRequestedControls: React.FC<IFirmOfferRequestedControlsProps> = (props) => {
  const [expiryDate, setExpiryDate] = useState<ExpiresOnValue | undefined>();
  const setExpiryDateAction = () => {
    if (expiryDate) {
      props.onSubmitFirmOffer(expiryTypeReducer[expiryDate.type](expiryDate.value));
    }
  };
  const isDisabled = Boolean(!expiryDate || expiryDate.value.length === 0);

  return (
    <div className={styles.container}>
      <FirmOfferTimer disabled={false} expiryDateHasBeenSelected={setExpiryDate} />
      <div className={styles.type}>
        <Button type="flat" onClick={props.onDeclineFirmOfferRequest} dataTest="DeclineFirmOfferRequest">
          Decline and return to Indication
        </Button>
        <Button type="positive" dataTest="submit-firm-offer" onClick={setExpiryDateAction} disabled={isDisabled}>
          Submit firm Offer AND begin timer
        </Button>
      </div>
    </div>
  );
};
