import { memo } from "react";
import { ElementHOC } from "../Element";
import "./Row.scss";

function Row(props) {
  const { element } = props;
  const { children } = element;

  if (!children) return null;

  return (
    <div className="Row">
      <Header element={element} />
      <div className="Row-children">{children.map((el, i) => ElementHOC(el, i))}</div>
    </div>
  );
}

function Header(props) {
  const { element } = props;
  const { label } = element;

  if (!label) return null;

  return <div className="Row-header">{label}</div>;
}

const Memo = memo(Row);

export { Memo as Row };
