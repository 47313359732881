import { Vessel, Negotiation, dialog } from "@/models";
import { Context } from "../Orders";

export async function postNegotiationVessel(model: Negotiation | undefined, context: Context, data: Vessel) {
  if (!model) {
    console.error("postNegotiationVessel:", { model, context, data });

    const status = {} as Status;

    status.type = "error";
    status.title = "Vessel Naming Failure";

    dialog.show({
      status,
      dataTest: `post-negotiation-vessel-fail`,
    });

    return;
  }

  model._.status.loading = true;
  model._.status.message = `Adding ${data.registrationDataName} to the list`;

  data.updateToken = model.updateToken;

  const res = await model.postVessel(data);

  if (!res?.ok) {
    model._.status.type = "error";
    model._.status.title = "Vessel Naming Failure";
    model._.status.dump = { res, entity: model };

    dialog.show({
      status: model._.status,
      dataTest: `post-negotiation-vessel-fail`,
    });

    model._.status.loading = false;
    model._.status.message = null;

    return res;
  }

  model._.status.loading = false;
  model._.status.message = null;

  // dialog.show({
  //   status: {
  //     type: "success",
  //     title: "Successful Vessel Nomination",
  //   },
  //   dataTest: `post-negotiation-vessel-success`,
  // });

  return res;
}
