import { TradeAPI } from "@/apis";
import { COUNTRIES_BY_ALPHA2_CODE, COUNTRIES_BY_ALPHA3_CODE, COUNTRIES_BY_NAME } from "@/constants";
import { isTruthy } from "@/utils";
import { LocationEditor } from "@/components";
import { DataModel, DataModelProps } from "../DataModel";

export class Location extends DataModel<Data> {
  onUpdate() {
    this._.img = this.getImg();

    delete this.display;
    delete this.shortDisplay;
  }

  toString() {
    let safes = resolvedFormattedSafes(this);

    if (!this.name) return "";

    const country = this.country ? `, ${this.country}` : "";
    safes = safes ? `${safes} ` : "";
    const notes = this.notes ? ` ${this.notes}` : "";

    return `${safes}${this.name}${country}${notes}`;
  }

  toDropdownView() {
    if (!this.name) return;

    const country = this.country ? `, ${this.country}` : "";

    return `${this.name}${country}`;
  }

  toGridView() {
    if (!this) return "";
    if (!this.name) return "";

    return this.name;
  }

  getImg() {
    let countryCode = this.countryCode;

    if (this.name === "Georgia") countryCode = "GEO";
    if (this.name === "WAUS") countryCode = "AUS";
    if (this.name === "UK") countryCode = "GBR";
    if (this.name === "USA") countryCode = "USA";

    const COUNTRY_BY_ALPHA2 = countryCode && COUNTRIES_BY_ALPHA2_CODE[countryCode];
    const COUNTRY_BY_ALPHA3 = countryCode && COUNTRIES_BY_ALPHA3_CODE[countryCode];
    const COUNTRY_BY_NAME = this.name && COUNTRIES_BY_NAME[this.name];
    const COUNTRY = COUNTRY_BY_ALPHA2 || COUNTRY_BY_ALPHA3 || COUNTRY_BY_NAME;

    let tooltip = "";
    let src = BLANK_COUNTRY_SRC;

    if (COUNTRY) {
      tooltip = this.country || COUNTRY.name;
      src = `https://pltfrmcdn-ckncdnlive-cdn-prd.sea.live/Data/development/src/flags/3chr/${COUNTRY.alpha3Code.toLocaleLowerCase()}.svg`;
    }

    return {
      src,
      tooltip,
    };
  }
}

function resolvedFormattedSafes(location: Location) {
  let b = SAFE_OPTION_LABEL_BY_MIN_MAX[`${location.safeBerthsMin}${location.safeBerthsMax}`];
  let p = SAFE_OPTION_LABEL_BY_MIN_MAX[`${location.safePortsMin}${location.safePortsMax}`];
  let a = SAFE_OPTION_LABEL_BY_MIN_MAX[`${location.safeAnchoragesMin}${location.safeAnchoragesMax}`];

  if (!b && !p && !a) return "";

  b = b ? `${b}sb` : "";
  p = p ? `${p}sp` : "";
  a = a ? `${a}sa` : "";

  return [b, p, a].filter(isTruthy).join(", ");
}

class SafeOption extends DataModel {
  toString() {
    return this.label;
  }
}

function newSafeOption(option) {
  return new SafeOption(option);
}

const SAFE_OPTIONS = [
  {
    label: "1",
    min: 1,
    max: 1,
  },
  {
    label: "1-2",
    min: 1,
    max: 2,
  },
  {
    label: "1-3",
    min: 1,
    max: 3,
  },
  {
    label: "1-4",
    min: 1,
    max: 4,
  },
  {
    label: "1-5",
    min: 1,
    max: 5,
  },
  {
    label: "2",
    min: 2,
    max: 2,
  },
  {
    label: "2-3",
    min: 2,
    max: 3,
  },
  {
    label: "2-4",
    min: 2,
    max: 4,
  },
  {
    label: "2-5",
    min: 2,
    max: 5,
  },
  {
    label: "3",
    min: 3,
    max: 3,
  },
  {
    label: "3-4",
    min: 3,
    max: 4,
  },
  {
    label: "3-5",
    min: 3,
    max: 5,
  },
  {
    label: "4",
    min: 4,
    max: 4,
  },
  {
    label: "4-5",
    min: 4,
    max: 5,
  },
  {
    label: "5",
    min: 5,
    max: 5,
  },
].map(newSafeOption);

const SAFE_OPTION_LABEL_BY_MIN_MAX = SAFE_OPTIONS.reduce((acc, el) => {
  acc[`${el.min}${el.max}`] = el.label;

  return acc;
}, {}) as StringRecord;

const BLANK_COUNTRY_SRC = "/images/blank.png";

export class LocationProps extends DataModelProps<Data> {}

LocationProps.prototype.editor = LocationEditor;
LocationProps.prototype.safeOptions = SAFE_OPTIONS;
LocationProps.prototype.label = "Location";
LocationProps.prototype.safeOptionLabelByMinMax = SAFE_OPTION_LABEL_BY_MIN_MAX;

Location.prototype.Props = LocationProps;
Location.prototype._ = new LocationProps();

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

export interface Location extends Data {
  _: LocationProps;
}

export interface LocationProps {
  safeOptions: typeof SAFE_OPTIONS;
  safeOptionLabelByMinMax: typeof SAFE_OPTION_LABEL_BY_MIN_MAX;
}

interface SafeOption extends SafeOptionData {}

type SafeOptionData = {
  label: string;
  min: number;
  max: number;
};

type Data = DeepPartial<TradeAPI["Location"]>;
