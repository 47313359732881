import { TradeAPI } from "@/apis";
import { Negotiation } from "@/models";
import { IRecipient, IKnownUser } from "api/orders/models/IDistributionList";
import { IUserContext } from "__legacy/dashboard/contexts/UserProvider";
import { differenceBy, find, uniq, uniqBy } from "lodash-es";
import { mapDistribution } from "sharedFolder/mappers/mapDistribution";
import { mapRecipientList } from "sharedFolder/mappers/mapRecipientList";
import { mapSearchUserToDistributionUser } from "sharedFolder/mappers/mapSearchUserToDistributionUser";
import {
  DistributionUserRole,
  IDistributionListViewModel,
  IDistributionViewModel,
  ISearchUserView,
} from "../../../sharedFolder/Models/IDetails";
import { flatten, unflatten } from "./distributionReducer";
import {
  combineSavedAndFoundRecipients,
  combineFoundAndSavedBasedOnGroupEmail,
  getUsersAndDesksState,
  openDesks,
  removeUsersWithNoAvailableRoles,
} from "./distributionReducerUtilities";
import { updateRecipientRole } from "../roles/roleUtilities";

export interface DistributionSavedUsersState {
  savedUsers: IDistributionListViewModel;
  savedRecipients: IRecipient[];
  totalNumberOfUsersSelected: number;
  maxNumberOfUsers: number;
  emailAddressesToSearch: string[];
  currentlyLoggedInUserContext?: IUserContext;
}

export const initialSavedUsersState: DistributionSavedUsersState = {
  savedUsers: mapRecipientList.emptyViewModel,
  savedRecipients: [],
  totalNumberOfUsersSelected: 0,
  maxNumberOfUsers: 0,
  emailAddressesToSearch: [],
};
type SetExistingUsers = {
  type: "setExistingUsers";
  payload: {
    distributionList: IDistributionListViewModel;
    currentLoggedInUserEmail: string;
  };
};

type AddUser = {
  type: "addUser";
  payload: { recipientBeingAdded: IRecipient };
};

type AddUsers = {
  type: "addUsers";
  payload: { recipientsBeingAdded: IRecipient[] };
};

type RemoveUser = {
  type: "removeUser";
  payload: IDistributionViewModel;
};
type AddDesk = {
  type: "addDesk";
  payload: {
    deskRecipients: IRecipient[];
  };
};

type RemoveDesk = {
  type: "removeDesk";
  payload: string;
};
type AddUsersAsEmail = { type: "addsUsersAsEmail"; payload: string[] };

type AddAbsentOwner = { type: "addAbsentOwner"; payload: string };

type ResolveUsersAction = {
  type: "resolveUsersByEmailAddress";
  payload: {
    searchUsers: ISearchUserView[];
  };
};
type SetUserRole = {
  type: "setSavedUserRole";
  payload: { email: string; role: DistributionUserRole };
};
export type DistributionSavedUserActions =
  | SetUserRole
  | SetExistingUsers
  | AddUser
  | AddUsers
  | AddUsersAsEmail
  | AddAbsentOwner
  | AddDesk
  | ResolveUsersAction
  | RemoveUser
  | RemoveDesk;

export const distributionSavedUsersReducer = (
  state: DistributionSavedUsersState,
  action: DistributionSavedUserActions
): DistributionSavedUsersState => {
  switch (action.type) {
    case "setExistingUsers": {
      // Flatten both the incoming users and the existing 'savedUsers'
      const incomingRecipients = flatten(action.payload.distributionList);

      // Get a list of users that are not in both lists, so users that are not
      // in both lists are going to be considered to be the newly added users and
      // we should preserve these users
      // What the code below does not deal with is scenarios where a user has been
      // deleted on the server, this user will be preserved on the clientside
      const currentUsersNotSaved = differenceBy(state.savedRecipients, incomingRecipients, "email");
      const recipientsWithoutCurrentUser = [
        ...incomingRecipients.filter((recipient) => recipient.email !== action.payload.currentLoggedInUserEmail),
        ...currentUsersNotSaved,
      ];
      const updatedRecipients = getUsersAndDesksState(recipientsWithoutCurrentUser, state.currentlyLoggedInUserContext);
      return {
        ...state,
        savedUsers: unflatten(updatedRecipients),
        savedRecipients: updatedRecipients,
        totalNumberOfUsersSelected: updatedRecipients.length,
      };
    }
    case "addUser": {
      // If user is already in 'saved' then return state
      const userAlreadyExistsInSavedUsers = find(
        state.savedRecipients,
        (recipient) => recipient.knownUser?.email.toLowerCase() === action.payload.recipientBeingAdded.email?.toLowerCase()
      );
      if (userAlreadyExistsInSavedUsers) return state;

      // Add new user and ensure that the desk is 'Open' in the saved Users
      const deskUserBeingAddedBelongsTo = action.payload.recipientBeingAdded.knownUser?.desk.id;

      const savedUsersFromAddUser = getUsersAndDesksState(
        openDesks(
          [...state.savedRecipients, action.payload.recipientBeingAdded],
          deskUserBeingAddedBelongsTo ? [deskUserBeingAddedBelongsTo] : []
        ),
        state.currentlyLoggedInUserContext
      );

      return {
        ...state,
        savedUsers: unflatten(savedUsersFromAddUser),
        savedRecipients: savedUsersFromAddUser,
        totalNumberOfUsersSelected: savedUsersFromAddUser.length,
      };
    }
    case "addUsers": {
      const isRecipientAlreadyInSavedOrDisabled = (recipient: IRecipient): boolean => {
        const isAlreadyInSaved = state.savedRecipients.some(
          (savedRecip) => savedRecip.email?.toLowerCase() === recipient.email?.toLowerCase()
        );
        return !isAlreadyInSaved && !recipient.additionalData?.disableUserRow;
      };
      const usersNotAlreadyInSaved = action.payload.recipientsBeingAdded.filter(isRecipientAlreadyInSavedOrDisabled);
      const newSaved = getUsersAndDesksState(
        [...state.savedRecipients, ...usersNotAlreadyInSaved],
        state.currentlyLoggedInUserContext
      );
      return {
        ...state,
        savedRecipients: newSaved,
        savedUsers: unflatten(newSaved),
        totalNumberOfUsersSelected: newSaved.length,
      };
    }
    case "removeDesk": {
      const removeDeskSavedUsersFlattened = state.savedRecipients.filter(
        (recipient) => recipient.knownUser?.desk.id !== action.payload
      );
      return {
        ...state,
        savedRecipients: removeDeskSavedUsersFlattened,
        savedUsers: unflatten(removeDeskSavedUsersFlattened),
        totalNumberOfUsersSelected: removeDeskSavedUsersFlattened.length,
      };
    }
    case "addDesk": {
      const combinedExistingAndNewRecipients = [...state.savedRecipients, ...action.payload.deskRecipients];
      const combinedAndUnique = uniqBy(combinedExistingAndNewRecipients, "email");

      const listOfAllDesksToOpen = uniq(
        action.payload.deskRecipients.map((recipient) => recipient.knownUser?.desk.id || "").filter((deskId) => deskId.length > 0)
      );
      const newSavedUsersWithSavedDesk = getUsersAndDesksState(
        openDesks(combinedAndUnique, listOfAllDesksToOpen),
        state.currentlyLoggedInUserContext
      );

      //  TUESDAY passing through the newly added desk to the searchresults
      return {
        ...state,
        savedUsers: unflatten(newSavedUsersWithSavedDesk),
        savedRecipients: newSavedUsersWithSavedDesk,
        totalNumberOfUsersSelected: newSavedUsersWithSavedDesk.length,
      };
    }
    case "addsUsersAsEmail": {
      const isEmailAlreadyInRecipients = (email: string) =>
        flatten(state.savedUsers).some((recipient) => recipient.email?.toLowerCase() === email.toLowerCase());

      const isEmailThatOfCurrentlyLoggedInUser = (email: string) =>
        state.currentlyLoggedInUserContext?.email.toLowerCase() === email.toLowerCase();

      const newEmailAddresses = action.payload.filter(
        (email) => !isEmailAlreadyInRecipients(email) && !isEmailThatOfCurrentlyLoggedInUser(email)
      );

      const emailAddressesAsUsers = uniq(newEmailAddresses.map((email) => email.toLowerCase())).map(
        (email) =>
          ({
            email,
            role: "owner",
            additionalData: {
              isLoading: true,
            },
            availableRoles: ["owner"],
          } as IRecipient)
      );

      const addUserRecipients = [...state.savedRecipients, ...emailAddressesAsUsers];
      return {
        ...state,
        savedUsers: unflatten(addUserRecipients),
        savedRecipients: addUserRecipients,
        emailAddressesToSearch: newEmailAddresses,
        totalNumberOfUsersSelected: addUserRecipients.length,
      };
    }
    case "addAbsentOwner": {
      // TODO get from onClick call back
      //Mock endpoint
      const emailFromEndpoint = action.payload;
      const absentOwners = state.savedRecipients.filter((recipient) => {
        return Negotiation.prototype.isAbsentOwnerEmail(recipient.email!);
      });
      const absentOwnerIndexesInOrder = absentOwners.map((absentOwner) =>
        Negotiation.prototype.getIndexFromAbsentOwnerEmail(absentOwner.email!)
      );
      const lastAbsentOwnerIndex = absentOwnerIndexesInOrder.length < 1 ? 0 : Math.max(...absentOwnerIndexesInOrder);
      const newAbsentOwnerEmail = `${lastAbsentOwnerIndex + 1}#${emailFromEndpoint}`;
      const absentOwnerAsUser = {
        email: newAbsentOwnerEmail,
        role: "owner",
        additionalData: {
          isLoading: false,
        },
        availableRoles: ["owner"],
      } as IRecipient;

      const recipientsWithAbsentOwner = [...state.savedRecipients, absentOwnerAsUser];
      return {
        ...state,
        savedUsers: unflatten(recipientsWithAbsentOwner),
        savedRecipients: recipientsWithAbsentOwner,
        emailAddressesToSearch: [newAbsentOwnerEmail],
        totalNumberOfUsersSelected: recipientsWithAbsentOwner.length,
      };
    }
    case "removeUser": {
      const updatedSavedUsers = removeUserFromRecipients(flatten(state.savedUsers), action.payload.email);
      return {
        ...state,
        savedUsers: unflatten(updatedSavedUsers),
        savedRecipients: updatedSavedUsers,
        totalNumberOfUsersSelected: updatedSavedUsers.length,
      };
    }
    case "resolveUsersByEmailAddress": {
      const savedRecipients = flatten(state.savedUsers);
      const foundRecipients = action.payload.searchUsers.map(mapSearchUserToDistributionUser.toView).map(mapDistribution.toApi);

      // First combine the saved users with the search found users so we can replace
      // a vanilla email address with a known user
      const savedRecipientsCombined1 = combineSavedAndFoundRecipients(foundRecipients, savedRecipients);
      // Second if one of the vanilla email addresses is a group email address, then
      // unfold the collection of users in the group and replace the original vanilla email
      const savedRecipientsCombined2 = combineFoundAndSavedBasedOnGroupEmail(foundRecipients, savedRecipientsCombined1);
      const recipientsWithRoles = savedRecipientsCombined2.filter((recipient) => recipient.availableRoles.length > 0);
      const savedUsersWithOpenDesks = removeUsersWithNoAvailableRoles(
        getUsersAndDesksState(
          openDesks(
            recipientsWithRoles,
            foundRecipients.map((recipient) => recipient.knownUser?.desk.id || "")
          ),
          state.currentlyLoggedInUserContext
        )
      );
      return {
        ...state,
        totalNumberOfUsersSelected: savedUsersWithOpenDesks.length,
        savedRecipients: savedUsersWithOpenDesks,
        savedUsers: unflatten(savedUsersWithOpenDesks),
      };
    }
    case "setSavedUserRole": {
      if (action.payload.email.length === 0)
        throw new Error(`setSavedUserRole payload contains no email ${JSON.stringify(action.payload)}`);

      return {
        ...state,
        savedRecipients: updateRecipientRole(flatten(state.savedUsers), action.payload.email, action.payload.role),
        savedUsers: unflatten(updateRecipientRole(flatten(state.savedUsers), action.payload.email, action.payload.role)),
      };
    }
    default: {
      return state;
    }
  }
};

const removeUserFromRecipients = (recipients: IRecipient[], email: string): IRecipient[] => {
  return recipients.filter((recipient) => recipient.email !== email);
};
