import { ILifting } from "../../api/negotiations/models/ILifting";
import { Observable } from "rxjs";
import { getLiftingsApiService } from "api/liftings/Liftings";

export function captureLiftingCargo(
  apiUrl: string,
  orderId: string,
  liftingId: string,
  details: ILifting
): Observable<{ id: string; version: number }> {
  return getLiftingsApiService(apiUrl, orderId).captureLiftingCargo(orderId, liftingId, details);
}
