import React, { useContext } from "react";
import { OwnerBanner, Button, SeaContracts } from "@/components";
import { NegotiationSummary } from "@/components/Offerrep/Nav";
import { sendNegotiationRecap } from "@/components/Orders/actions";
import { usageMetrics, MainTermsTypeEvents } from "@/services/UsageMetrics";
import { Context } from "../Negotiation";
import "./MainTermsDetails.scss";

export function MainTermsDetails() {
  const { negotiation } = useContext(Context);

  if (!negotiation) return null;

  const { arcUrlWithCentralToken } = negotiation;

  return (
    <sea-owner-negotiation-main-terms-details>
      <OwnerBanner negotiation={negotiation} />
      <sea-owner-negotiation-main-terms-details-actions>
        <Button
          variant="action"
          href={arcUrlWithCentralToken}
          data-test="owner-open-main-terms"
          icon="file"
          openHrefInNewWindow
          onClick={() => {
            usageMetrics.trackEvent(MainTermsTypeEvents.MAIN_TERMS_OPENED);
          }}
        >
          open Main Terms
        </Button>
        <Button variant="action" onClick={sendNegotiationRecap.bind(null, negotiation)} data-test="owner-send-recap" icon="email">
          send recap to all parties
        </Button>
        {negotiation.seaContractsUrl && <SeaContracts className={"sea-button"} seaContractsUrl={negotiation.seaContractsUrl} />}
      </sea-owner-negotiation-main-terms-details-actions>
      <h2>Initial Terms</h2>
      <NegotiationSummary negotiation={negotiation} />
    </sea-owner-negotiation-main-terms-details>
  );
}
