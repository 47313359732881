import { makeObservable, observable } from "mobx";
import { Status, Order } from "___REFACTOR___/models";

class OrderNegLayout {
  constructor(order: Order) {
    this.order = order;

    makeObservable(this, { statusMap: observable.shallow });
  }

  statusMap = {
    getInitialData: undefined as Status | undefined,
    getInitialDataFail: undefined as Status | undefined,
    getInitialNegsData: undefined as Status | undefined,
    getInitialNegsDataFail: undefined as Status | undefined,
  };

  get attachmentBadgeProps() {
    if (!this.order.hasData) return undefined;

    const status = this.statusMap.getInitialData;
    let data = this.order?.attachments?.length || 0;

    const fixedNegAttachments = this.order.negAttachments?.filter(fixedNegAttachmentSelector);
    const fixedNegAttachmentCount = fixedNegAttachments.length || 0;
    const negAttachmentCount = this.order.negAttachments?.length || 0;

    if (fixedNegAttachmentCount) {
      data += fixedNegAttachmentCount;
    } else {
      data += negAttachmentCount;
    }

    return { data, status };
  }

  get heading() {
    if (!this.order.hasData) return "";

    return `${this.order.summary.chartererAccount} - ${this.order.details.cargoType?.name} - ${this.order.summary.orderReference}`;
  }
}

function fixedNegAttachmentSelector(negAttachment) {
  return negAttachment.status === "Fixed";
}

export { OrderNegLayout };

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

interface OrderNegLayout {
  order: Order;
}

declare namespace OrderNegLayout {}
