import { TradeAPI } from "@/apis";
import { NegotiationNoteEditor } from "@/components/common/Editor/type/NegotiationNote";
import { DataModel, DataModelProps } from "../../DataModel";

export class Note extends DataModel<PartialData> {
  toString() {
    return this.value;
  }
}

class NoteProps extends DataModelProps<PartialData> {
  constructor() {
    super();
    this.editor = NegotiationNoteEditor;
  }
}

NoteProps.prototype.editor = NegotiationNoteEditor;
Note.prototype.Props = NoteProps;

export interface Note extends PartialData {
  _: NoteProps;
}

type PartialData = DeepPartial<Data>;
type Data = DeepPartial<TradeAPI["NegotiationNote"]>;
