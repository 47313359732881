import { UniversalOrderNegotiationFormValues, Negotiation, dialog } from "@/models";
import { Context } from "../Orders";

export async function updateNegotiationNote(
  model: Negotiation | undefined,
  context: Context,
  values: UniversalOrderNegotiationFormValues
) {
  if (!model) {
    console.error("updateNegotiationNote:", { model, context, values });

    const status = {} as Status;

    status.type = "error";
    status.title = "Negotiation Note Update Failure";

    dialog.show({
      status,
      dataTest: `update-negotiation-note-fail`,
    });

    return;
  }

  model._.status.loading = true;
  model._.status.message = `Updating Negotiation Note`;

  const payload = {
    as: values.updateNegotiationNoteFor,
    value:
      values.updateNegotiationNoteFor === "brokerCharterer"
        ? values.negotiationBrokerChartererNote?.toString()
        : values.negotiationOwnerNote?.toString(),
  };

  const res = await model.putNote(payload);

  if (!res?.ok) {
    model._.status.type = "error";
    model._.status.title = "Negotiation Note Update Failure";
    model._.status.dump = { res, entity: model };

    dialog.show({
      status: model._.status,
      dataTest: `update-negotiation-note-fail`,
    });

    model._.status.loading = false;
    model._.status.message = null;

    return res;
  }

  model._.status.loading = false;
  model._.status.message = null;

  // dialog.show({
  //   status: {
  //     type: "success",
  //     title: "Successful Negotiation Note Update",
  //     message: "You have successfuly updated the negotiation note",
  //   },
  //   dataTest: `update-negotiation-note-success`,
  // });

  return res;
}
