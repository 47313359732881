import { TradeAPI } from "@/apis";
import { AccountEditor } from "@/components";
import { DataModel, DataModelProps } from "../DataModel";

class Account extends DataModel<Data> {
  toString() {
    return this.accountName;
  }

  toGridView() {
    return this.accountName;
  }
}

class AccountProps extends DataModelProps<Data> {}

AccountProps.prototype.editor = AccountEditor;
AccountProps.prototype.label = "Account";

Account.prototype._ = new AccountProps();

export { Account, AccountProps };

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

interface Account extends Data {
  _: AccountProps;
}

type Data = DeepPartial<TradeAPI["Account"]>;
