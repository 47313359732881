import React, { useContext, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { observer } from "mobx-react";
import { useParams, useHistory, Route as RRRoute } from "react-router-dom";
import { setCheckboxColumnVisible, useForceUpdate } from "@/utils";
import { bannerHideTimeout } from "sharedFolder/constants";
import { TradeAPI } from "@/apis";
import { NegArchivingAction, NegUnarchivingAction } from "../../actions";
import {
  Negotiation,
  OrderNegotiationStore,
  ownerOrderNegotiationArchiveStore,
  ownerOrderNegotiationStore,
  Route,
} from "@/models";
import { AggridRowClickedEvent } from "@/components";
import { AggridGetContextMenuItemsParams, Banner, Icon } from "@/components/common";
import { usageMetrics, OrderArchiveEvents } from "@/services/UsageMetrics";
import { Context } from "../../Orders";
import { BaseGrid, KebabMenuAction } from "../BaseGrid";
import "./OwnerNegotiationGrid.scss";
import { testSelectors } from "@/constants";
import { BulkNegotiationsArchiveAction } from "../../actions/archiving/BulkNegotiationsArchivingAction";
import { BulkNegotiationsUnarchiveAction } from "../../actions/archiving/BulkNegotiationsUnarchivingAction";
import { ArchivingAction } from "../../actions/archiving/BaseArchivingAction";

function OwnerNegotiationGrid() {
  const { negotiationId, orderId: selectedOrderId } = useParams<StringRecord>();
  const context = useContext(Context);
  const { orderNegotiationStore, routes } = context;
  const order = selectedOrderId ? orderNegotiationStore?.upsertOrderModel({ id: selectedOrderId }) : undefined;
  const negotiation = order?.upsertNegotiation({ id: negotiationId });
  const status = orderNegotiationStore?.negotiationArrayStatus;
  const isArchiveSection = ["owner-archive"].includes(context.area);
  const forceUpdate = useForceUpdate();
  const history = useHistory();
  const [negotiationsCounter, setsNegotiationCounter] = useState(orderNegotiationStore?.negotiationArray.length);
  const [bulkPanel, setBulkPanel] = useState(false);
  const [archivedItemsCounter, setArchivedItemsCounter] = useState(0);

  const [banner, setBanner] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const closeBulkPanel = () => {
    context.orderNegotiationStore?.negotiationGridContext.api?.deselectAll();
    const negs = orderNegotiationStore?.negotiationGridContext.columnApi;
    setBulkPanel(false);
    if (negs) setCheckboxColumnVisible(false, negs);

    return;
  };

  if (!orderNegotiationStore) return null;

  const headerKebabActions = new Array<KebabMenuAction>();

  const bulkActionsMenuItem = {
    label: "Bulk Actions",
    icon: "list",
    onClick: () => {
      setBulkPanel(true);
      const negs = orderNegotiationStore?.negotiationGridContext.columnApi;
      if (negs) setCheckboxColumnVisible(true, negs);
    },
    disabled: bulkPanel,
    dataTest: testSelectors.openBulkPanelButton,
  };

  const archiveRoute = `/owner-archive`;
  const viewArchivedNegotiationsAction = {
    label: "View Archived Negotiations",
    icon: "archived",
    onClick: () => history.push(archiveRoute),
    dataTest: testSelectors.openArchivedDashboardButton,
  };

  headerKebabActions.push(bulkActionsMenuItem);

  if (!isArchiveSection) {
    headerKebabActions.push(viewArchivedNegotiationsAction);
  }

  const getSelectedNegotiationModels = () => {
    const selectedNodesArray = orderNegotiationStore?.negotiationGridContext.api!.getSelectedNodes();
    return selectedNodesArray
      .map((node) => node?.data as TradeAPI["Negotiation"])
      .map((neg) => orderNegotiationStore?.upsertNegotiationModel({ id: neg.id, orderId: neg.orderId }));
  };

  const bulkArchiveNegotiations = async () => {
    const selectedNegotiationModels = getSelectedNegotiationModels();
    if (routes.orders) {
      unselectOrder(routes.orders);
    }
    const nonArchivedNegotiations = selectedNegotiationModels.filter((o) => !o.isArchived);
    const negIds: string[] = nonArchivedNegotiations.map((item) => item.id);
    const startMeasureTime = usageMetrics.startTrackingAction(
      OrderArchiveEvents.BULK_NEG_ARCHIVE_START,
      negIds,
      nonArchivedNegotiations.length
    );
    if (!nonArchivedNegotiations?.length) {
      // If all negotiations were already archived, then we just need to update the state
      updateStateAfterBulkArchiving(ArchivingAction.BulkArchive, selectedNegotiationModels);
      usageMetrics.finishTrackingAction(
        OrderArchiveEvents.BULK_NEG_ARCHIVE_FINISH,
        startMeasureTime,
        negIds,
        nonArchivedNegotiations.length
      );
      return;
    }

    const res = await new BulkNegotiationsArchiveAction(nonArchivedNegotiations, context).performActionAsync();

    if (res?.ok) {
      updateStateAfterBulkArchiving(ArchivingAction.BulkArchive, selectedNegotiationModels);
      usageMetrics.finishTrackingAction(
        OrderArchiveEvents.BULK_NEG_ARCHIVE_FINISH,
        startMeasureTime,
        negIds,
        nonArchivedNegotiations.length
      );
    } else {
      const succeededNegIds = res.succeededEntitiesIds;
      const failedNegIds = negIds.filter((item) => succeededNegIds.indexOf(item) < 0);
      updateStateOfSucceededOrders(res, ArchivingAction.BulkArchive);
      usageMetrics.finishTrackingAction(
        OrderArchiveEvents.ARCHIVE_OPERATION_FAILED,
        startMeasureTime,
        failedNegIds,
        failedNegIds.length
      );
    }
  };

  const bulkUnarchiveNegotiations = async () => {
    const selectedNegotiationModels = getSelectedNegotiationModels();
    if (routes.archivedOrders) {
      unselectOrder(routes.archivedOrders);
    }

    const archivedNegotiations = selectedNegotiationModels.filter((o) => o.isArchived);
    const negIds: string[] = archivedNegotiations.map((item) => item.id);
    const startMeasureTime = usageMetrics.startTrackingAction(
      OrderArchiveEvents.BULK_NEG_UNARCHIVE_START,
      negIds,
      archivedNegotiations.length
    );

    if (!archivedNegotiations?.length) {
      // If all negotiations were already unarchived, then we just need to update the state
      updateStateAfterBulkArchiving(ArchivingAction.BulkUnarchive, selectedNegotiationModels);
      usageMetrics.finishTrackingAction(
        OrderArchiveEvents.BULK_NEG_UNARCHIVE_FINISH,
        startMeasureTime,
        negIds,
        archivedNegotiations.length
      );
      return;
    }

    const res = await new BulkNegotiationsUnarchiveAction(archivedNegotiations, context).performActionAsync();

    if (res?.ok) {
      updateStateAfterBulkArchiving(ArchivingAction.BulkUnarchive, selectedNegotiationModels);
      usageMetrics.finishTrackingAction(
        OrderArchiveEvents.BULK_NEG_UNARCHIVE_FINISH,
        startMeasureTime,
        negIds,
        archivedNegotiations.length
      );
    } else {
      const succeededNegIds = res.succeededEntitiesIds;
      const failedNegIds = negIds.filter((item) => succeededNegIds.indexOf(item) < 0);
      updateStateOfSucceededOrders(res, ArchivingAction.BulkUnarchive);
      usageMetrics.finishTrackingAction(
        OrderArchiveEvents.UNARCHIVE_OPERATION_FAILED,
        startMeasureTime,
        failedNegIds,
        failedNegIds.length
      );
    }
  };

  const updateStateOfSucceededOrders = (actionResult: any, action: ArchivingAction) => {
    if (!actionResult?.succeededEntitiesIds) {
      return;
    }

    const targetStore = getTargetStoreToMoveOrder(action);
    for (const negotiationId of actionResult.succeededEntitiesIds) {
      const node = orderNegotiationStore?.negotiationGridContext.api?.getRowNode(negotiationId);
      if (node) {
        orderNegotiationStore?.negotiationGridContext.api?.deselectNode(node);
      }

      const negotiationModel = orderNegotiationStore.getNegotiationModel(negotiationId)!;
      orderNegotiationStore.moveNegotiationToStore(negotiationModel, targetStore);
    }
  };

  const showArchivedNotification = (archivedItemCount: number) => {
    if (banner && timeoutId) {
      setBanner(false);
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }

    setArchivedItemsCounter(archivedItemCount);
    if (negotiationsCounter) {
      setsNegotiationCounter(negotiationsCounter - archivedItemCount);
    }
    setBanner(true);

    const id = setTimeout(() => {
      setBanner(false);
    }, bannerHideTimeout);

    setTimeoutId(id);
  };

  const updateStateAfterSingleArchiving = async (action: ArchivingAction, orderId: string, negotiationId: string) => {
    showArchivedNotification(1);

    const negotiation = orderNegotiationStore.orderMap[orderId]!._.negotiationMap![negotiationId]!;
    const targetStore = getTargetStoreToMoveOrder(action);
    orderNegotiationStore.moveNegotiationToStore(negotiation, targetStore);
  };

  const updateStateAfterBulkArchiving = async (action: ArchivingAction, negotiationModels: Negotiation[]) => {
    showArchivedNotification(negotiationModels.length);

    const targetStore = getTargetStoreToMoveOrder(action);
    for (const negotiation of negotiationModels) {
      orderNegotiationStore.moveNegotiationToStore(negotiation, targetStore);
    }
  };

  const getTargetStoreToMoveOrder = (action: ArchivingAction) => {
    switch (action) {
      case ArchivingAction.BulkArchive:
      case ArchivingAction.Archive:
        return ownerOrderNegotiationArchiveStore;
      case ArchivingAction.BulkUnarchive:
      case ArchivingAction.Unarchive:
        return ownerOrderNegotiationStore;
      default:
        throw new Error("Unsupported action " + action);
    }
  };

  const bulkPanelActions = !isArchiveSection
    ? [
        {
          label: "Archive",
          icon: "archived",
          dataTest: testSelectors.bulkArchiveButton,
          onClick: bulkArchiveNegotiations,
        },
      ]
    : [
        {
          label: "Unarchive",
          icon: "unarchive",
          dataTest: testSelectors.bulkUnarchiveButton,
          onClick: bulkUnarchiveNegotiations,
        },
      ];
  const unselectOrder = (stagedRoute: Route) => {
    const isSelectedOrder = negotiation?.orderId === selectedOrderId;

    if (isSelectedOrder) stagedRoute.replace({});
  };

  function getContextMenuItems(params: AggridGetContextMenuItemsParams) {
    const negotiation = params?.node?.data as TradeAPI["Negotiation"];
    const negotiationModel = negotiation?.id
      ? orderNegotiationStore?.upsertNegotiationModel({ id: negotiation?.id, orderId: negotiation?.orderId })
      : undefined;

    const negotiationId = negotiationModel?.id;

    const archiveNegotiation = async () => {
      const startMeasureTime = usageMetrics.startTrackingAction(OrderArchiveEvents.NEG_ARCHIVE_START, negotiationId);
      if (routes.orders) {
        unselectOrder(routes.orders);
      }

      if (!negotiation.updateToken || !negotiationModel) {
        return;
      }

      if (negotiationModel.isArchived) {
        // If order is already archived, then we just need to update the state
        updateStateAfterSingleArchiving(ArchivingAction.Archive, negotiation.orderId, negotiation.id);
        usageMetrics.finishTrackingAction(OrderArchiveEvents.NEG_ARCHIVE_FINISH, startMeasureTime, negotiationId);
        return;
      }

      const res = await new NegArchivingAction(negotiationModel, context).performActionAsync();
      if (!res?.ok) {
        usageMetrics.finishTrackingAction(OrderArchiveEvents.ARCHIVE_OPERATION_FAILED, startMeasureTime, negotiationId);
        return;
      }
      updateStateAfterSingleArchiving(ArchivingAction.Archive, negotiation.orderId, negotiation.id);
      usageMetrics.finishTrackingAction(OrderArchiveEvents.NEG_ARCHIVE_FINISH, startMeasureTime, negotiationId);
    };

    const unarchiveNegotiation = async () => {
      const startMeasureTime = usageMetrics.startTrackingAction(OrderArchiveEvents.NEG_UNARCHIVE_START, negotiationId);
      if (routes.archivedOrders) {
        unselectOrder(routes.archivedOrders);
      }

      if (!negotiation.updateToken || !negotiationModel) {
        return;
      }

      if (!negotiationModel.isArchived) {
        // If order is already unarchived, then we just need to update the state
        updateStateAfterSingleArchiving(ArchivingAction.Unarchive, negotiation.orderId, negotiation.id);
        usageMetrics.finishTrackingAction(OrderArchiveEvents.NEG_UNARCHIVE_FINISH, startMeasureTime, negotiationId);
        return;
      }

      const res = await new NegUnarchivingAction(negotiationModel, context).performActionAsync();
      if (!res?.ok) {
        usageMetrics.finishTrackingAction(OrderArchiveEvents.UNARCHIVE_OPERATION_FAILED, startMeasureTime, negotiationId);
        return;
      }

      updateStateAfterSingleArchiving(ArchivingAction.Unarchive, negotiation.orderId, negotiation.id);
      usageMetrics.finishTrackingAction(OrderArchiveEvents.NEG_UNARCHIVE_FINISH, startMeasureTime, negotiationId);
    };

    return negotiation?.orderId
      ? context.area === "owner-dashboard"
        ? [
            {
              name: "Archive",
              icon: ReactDOMServer.renderToStaticMarkup(
                <Icon icon="archived" className="context-menu-item-icon" data-test={testSelectors.archiveButton} />
              ),
              action: archiveNegotiation,
            },
          ]
        : [
            {
              name: "Unarchive",
              icon: ReactDOMServer.renderToStaticMarkup(
                <Icon icon="unarchive" className="context-menu-item-icon" data-test={testSelectors.unarchiveButton} />
              ),
              action: unarchiveNegotiation,
            },
          ]
      : [];
  }

  return (
    <>
      <BaseGrid
        context={orderNegotiationStore?.negotiationGridContext}
        selectedEntityId={negotiationId}
        bulkPanelIsVisible={bulkPanel}
        closeBulkPanelAction={closeBulkPanel}
        bulkPanelActions={bulkPanelActions}
        entity={negotiation}
        entityType={typeof Negotiation}
        rowCount={orderNegotiationStore?.negotiationArray.length}
        isEndReached={true}
        onGridReady={onGridReady}
        className="OwnerNegotiationGrid"
        onRowClicked={onRowClicked}
        status={status}
        headerHeight={52}
        rowHeight={54}
        getContextMenuItems={["owner-dashboard", "owner-archive"].includes(context.area) ? getContextMenuItems : undefined}
        headerKebabActions={headerKebabActions}
        noRowsOverlayText={
          ["owner-archive"].includes(context.area)
            ? "There are no archived negotiations, archiving a negotiation does not impact the status " +
              "and you can still continue with the negotiation"
            : undefined
        }
      />
      {banner && (
        <Banner
          isClosable
          label={
            context.area === "owner-dashboard"
              ? `${archivedItemsCounter} negotiation${archivedItemsCounter > 1 ? "s" : ""} moved to archive`
              : `${archivedItemsCounter} negotiation${archivedItemsCounter > 1 ? "s" : ""} moved out of archive`
          }
          className="bottom-left"
          onClose={() => {
            setBanner(false);

            if (timeoutId) {
              clearTimeout(timeoutId);
              setTimeoutId(null);
            }
          }}
          customAnimation="fade-in-from-bottom"
        />
      )}
    </>
  );

  function onGridReady() {
    const negs = orderNegotiationStore?.negotiationGridContext.columnApi;
    if (negs) setCheckboxColumnVisible(false, negs);
    orderNegotiationStore?.negotiationGridContext.api?.addEventListener("rowDataUpdated", onDataChange);
  }

  function onDataChange() {
    forceUpdate();
  }

  function onRowClicked(e: AggridRowClickedEvent) {
    const data = e.data as OrderNegotiationStore["Negotiation"];
    const negotiation = new Negotiation(data);
    const isLifting = negotiation.type === "Lft";
    const isWithdrawn = negotiation.status === "Withdrawn";
    const isLiftingAndWithdrawn = isLifting && isWithdrawn;
    const isBeyondInitialTermsAndNotWithdrawn = negotiation.isBeyondInitialTerms() && !isWithdrawn;

    if (isLiftingAndWithdrawn) return;

    let routeName;

    if (isLifting) routeName = "lifting";
    else if (isBeyondInitialTermsAndNotWithdrawn) routeName = "negotiationMainTerms";
    else routeName = "negotiationInitialTerms";

    routes.order[routeName]?.push({
      orderId: negotiation?.orderId,
      negotiationId: negotiation?.id,
    });
  }
}

const Observer = observer(OwnerNegotiationGrid);

function OwnerNegotiationGridWrapper() {
  const negotiationsPath = `/:dontCare0?/:orderId?/:negotiationId?`;

  return (
    <RRRoute path={negotiationsPath}>
      <Observer />
    </RRRoute>
  );
}

export { OwnerNegotiationGridWrapper as OwnerNegotiationGrid };
