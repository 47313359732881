import { object, string, number } from "yup";
import { TradeAPI } from "___REFACTOR___/apis";
import { Demurrage } from "../Demurrage";

const unitArr = ["PerDay", "LumpSum"] as TradeAPI.Demurrage.Unit[];

// https://github.com/jquense/yup
const schema = object({
  value: number().required("Required"),
  unit: string().oneOf(unitArr).required("Required"),
  notes: string(),
}).transform(toJSON);

function toJSON(demurrage: Demurrage) {
  return demurrage.toJSON();
}

export { schema };
