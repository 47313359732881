import { AnySchema } from "yup";
import { makeAutoObservable } from "mobx";
import { AggridContext, AggridRowNode, Icon, ImgProps } from "___REFACTOR___/components";

class DataModelProps {
  /* eslint-disable-next-line @typescript-eslint/no-empty-function */
  constructor(...params) {}
}

class DataModel {
  constructor(data?, config = DEFAULT_CONFIG) {
    if (!config?.suppressProps) this._ = new this.Props();

    this.assignData(data);
  }

  static Props = DataModelProps;

  assignData(data) {
    if (data) {
      // @ts-ignore
      const { _, ...assignable } = data;

      Object.assign(this, assignable);
    }
  }

  makeObservable() {
    makeAutoObservable(this);
  }

  toJSON(): any {
    const { _, ...json } = this;

    if (this._.JSONDefaults) {
      const defaultEntries = Object.entries(this._.JSONDefaults);

      for (let i = 0; i < defaultEntries.length; i++) {
        const [key, defaultValue] = defaultEntries[i];

        json[key] = json[key] ?? defaultValue;
      }
    }

    return json;
  }

  toString() {
    return "";
  }

  toView() {
    return this.toString();
  }

  toSelectView() {
    return this.toDropdownView();
  }

  toDropdownView() {
    return this.toString();
  }

  toGridView() {
    return this.toString();
  }
}

const DEFAULT_CONFIG = {
  suppressProps: false,
} as DataModel.Config;

DataModel.prototype.Props = DataModelProps;

export { DataModel, DataModelProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface DataModel {
  Props: typeof DataModelProps;
  _: DataModelProps;
}

declare namespace DataModel {
  interface Config {
    suppressProps?: boolean;
  }

  interface Props {
    schema: AnySchema;
    aggridContext: AggridContext;
    aggridRowNode: AggridRowNode;
    img: ImgProps;
    icon: Icon.IconProp;
    label: string;
    Editor: Function;
    JSONDefaults: object;
    sortKey: string;
    sortValue: any;
    handlebarKey: Primitive;
  }
}

interface DataModelProps extends DataModel.Props {}
