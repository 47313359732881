import { TradeAPI } from "___REFACTOR___/apis";
import { DataModel } from "___REFACTOR___/models/DataModel";
import { Negotiation } from "___REFACTOR___/models/Negotiation";

class Props extends DataModel.Props {}

class Attachment extends DataModel {
  static Props = Props;

  constructor(data: Partial<Data>, neg: Negotiation) {
    super(data);

    this._.neg = neg;
  }
}

Attachment.prototype.Props = Props;

export { Attachment };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface Attachment extends Data {
  Props: typeof Props;
  _: Props;
}

interface Props {
  neg: Negotiation;
}

type Data = TradeAPI.Negotiation.Attachment;
