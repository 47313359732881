import dayjs from "dayjs";
import { object, string, number, TestContext } from "yup";
import { Vessel } from "../Vessel";
import { TradeAPI } from "___REFACTOR___/apis";

// https://github.com/jquense/yup
const schema = object({
  vesselImo: number(),
  // vesselImo: number().required("Required"),
  vesselIMO: number(),
  dwt: number(),
  arcVesselId: number(),
  buildYear: number(),
  registrationDataName: string(),
  status: string(),
  eta: string(),
  itinerary: string(),
  speedAndConsumption: string(),
  ownerChain: string(),
  additionalNotes: string(),
  vesselDescription: string(),
})
  .transform(toJSON)
  .test({
    test: etaTest,
    name: "etaTest",
    message: "Something is wrong with ETA",
  });

function toJSON(vessel: Vessel) {
  return vessel.toJSON();
}

function etaTest(this: TestContext, vessel) {
  if (!vessel) return true;

  const { eta } = vessel as TradeAPI.Vessel;

  if (!Date.parse(eta)) return true;

  const startOfToday = dayjs().startOf("day").toDate();
  const etaDate = new Date(eta);

  if (startOfToday > etaDate) {
    const path = this.path ? `${this.path}.eta` : "eta";
    const error = this.createError({ path, message: "Date is in the past" });

    return error;
  }

  return true;
}

export { schema };
