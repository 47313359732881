import { IUnitValue } from "./IUnitValue";
import { IKeyValuePair } from "./IKeyValuePair";

export type DemurrageUnit = "PerDay" | "LumpSum";

export type IDemurrage = IUnitValue<DemurrageUnit>;

export const DemurrageUnitDisplay: { [key in DemurrageUnit]: string } = {
  LumpSum: "Lump Sum",
  PerDay: "Per Day",
};

export const DemurrageUnitShortDisplay: { [key in string]: string } = {
  PerDay: "/ Day",
  LumpSum: "LS",
};

export const DemurrageOptions: IKeyValuePair[] = [
  { key: "LumpSum", value: "Lump Sum" },
  { key: "PerDay", value: "Per Day" },
];
