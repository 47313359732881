import { from } from "rxjs";
import { IChartererSearchResult } from "sharedFolder/components/common/interfaces";
import { IAccountView } from "sharedFolder/Models/IDetails";

const toAccount = (data: IChartererSearchResult): IAccountView => {
  return {
    ...data.document,
    display: data.document.accountName,
  };
};

const getAccounts = (baseUrl: string, term: string, isFilteredByCompany: boolean): Promise<IAccountView[]> => {
  const url = `${baseUrl}/accounts?q=${term}&legalEntitiesOnly=false`;
  return from(
    fetch(url)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        return (data || []).map(toAccount);
      })
  ).toPromise();
};

export { getAccounts };
