import { ColumnState } from "@ag-grid-enterprise/all-modules";
import { action, makeObservable, observable } from "mobx";
import { hasKeys, wait } from "@/utils";
import { AggridHeaderCompParams } from "../Aggrid";
import { AggridContext } from "../Context";

export class AggridColumnStateAPI {
  constructor(context: AggridContext) {
    this.context = context;

    makeObservable(this, {
      state: observable,
      inheritNativeState: action,
      updateColumnState: action,
    });
  }

  state: State = {};

  onGridReady = () => {
    this.inheritNativeState();

    this.context.api?.addEventListener("sortChanged", this.onSortChange);
    this.context.api?.addEventListener("filterChanged", this.onFilterChange);
    this.context.api?.addEventListener("columnMoved", this.onColumnMove);
  };

  onSortChange = () => {
    this.inheritNativeState();
  };

  onFilterChange = () => {
    this.inheritNativeState();
  };

  onColumnMove = () => {
    this.inheritNativeState();
  };

  inheritNativeState = () => {
    const nativeColumnStates = this.context.columnApi?.getColumnState();

    if (!nativeColumnStates) return;

    let columnStateWithDefaultSort: AggridColumnState | undefined;
    let currentSort: AggridColumnState["sort"];

    for (let i = 0; i < nativeColumnStates.length; i++) {
      const nativeColumnState = nativeColumnStates[i];
      const { colId } = nativeColumnState;
      const column = this.context.columnApi?.getColumn(colId);

      if (!colId) return;

      const columnState = this.state[colId] || { ...defaultColumnState };

      this.state[colId] = Object.assign(columnState, { ...nativeColumnState, isFilterActive: column?.isFilterActive() });

      if (columnState?.sort) currentSort = columnState?.sort;

      if (columnState?.defaults?.sort) columnStateWithDefaultSort = columnState;
    }

    if (columnStateWithDefaultSort && !currentSort) {
      const { defaults, colId } = columnStateWithDefaultSort;

      this.setMultiValueSort(colId, defaults?.multiValueSortName, defaults?.sort);
    }
  };

  updateMultiValueSort = (colId, multiValueSortNameNext, params, event) => {
    const columnState = this.state[colId];
    const multiValueSortName = columnState.multiValueSortName;
    const multiValueSortNameChanged = multiValueSortName !== multiValueSortNameNext;
    const defaultSort = columnState.defaults?.sort;
    const sort = columnState.sort;

    if (defaultSort && sort === "desc") {
      this.setMultiValueSort(colId, multiValueSortNameNext, "asc");

      return;
    }

    if (sort && multiValueSortNameChanged) {
      this.setMultiValueSort(colId, multiValueSortNameNext, "asc");

      return;
    }

    this.progressMultiValueSort(colId, multiValueSortNameNext, params, event);
  };

  progressMultiValueSort = (colId, multiValueSortName, params: AggridHeaderCompParams, event) => {
    if (typeof multiValueSortName !== "string") return;

    this.updateColumnState(colId, { multiValueSortName });

    params.progressSort(event.shiftKey);
  };

  setMultiValueSort = (colId, multiValueSortName, sort) => {
    if (typeof multiValueSortName !== "string" || !sort) return;

    this.updateColumnState(colId, { multiValueSortName, sort });
  };

  updateColumnState = async (colId, partialColumnState: AggridColumnState) => {
    const { multiValueSortName, defaults, ...nativeColumnState } = partialColumnState;

    this.state[colId] = this.state[colId] || { ...defaultColumnState };

    const columnState = this.state[colId];

    if (defaults) columnState.defaults = defaults;
    if (typeof multiValueSortName === "string") columnState.multiValueSortName = multiValueSortName;

    const nativeColumnStateEmpty = !hasKeys(nativeColumnState);

    if (nativeColumnStateEmpty) return;

    await wait(0);

    this.context.columnApi?.applyColumnState({ state: [{ colId, ...nativeColumnState }] });
  };
}

const defaultColumnState = {};

export interface AggridColumnStateAPI {
  context: AggridContext;
}

type State = RecordOf<AggridColumnState>;

export interface AggridColumnState extends ColumnState {
  multiValueSortName?: string;
  isFilterActive?: boolean;
  defaults?: AggridDefaultColumnState;
}

export type AggridDefaultColumnState = Omit<AggridColumnState, "defaults">;
