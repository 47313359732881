import { Observable } from "rxjs";
import { getLiftingsApiService } from "api/liftings/Liftings";
import { mapLiftingCollections } from "sharedFolder/mappers/mapLiftingsCollection";
import { map } from "rxjs/operators";
import { ILiftingsCollectionView } from "sharedFolder/Models/ILiftingsCollectionView";

export function getLiftings(
  apiUrl: string,
  orderId: string,
  withUpdates?: Observable<{ id: string; version: number }>
): Observable<ILiftingsCollectionView> {
  return getLiftingsApiService(apiUrl, orderId, withUpdates).getLiftings(orderId).pipe(map(mapLiftingCollections.toView));
}
