import { LABEL_MAP } from "___REFACTOR___/constants";
import { format } from "___REFACTOR___/utils";
import { TradeAPI } from "___REFACTOR___/apis";
import { LiftingsEditor } from "___REFACTOR___/components";
import { DataModel, DataModelProps } from "../DataModel";
import { Dispersal, DISPERSAL_ARR } from "./Dispersal";
import { schema } from "./schema";

class Liftings extends DataModel {
  constructor(data?: PartialData) {
    super(data);
  }

  toJSON() {
    const json = DataModel.prototype.toJSON.call(this) as TradeAPI.Liftings;

    return json;
  }

  isValid() {
    return schema.isValidSync(this);
  }

  toString() {
    if (typeof this.min !== "number" || typeof this.max !== "number") return "";

    const notes = this.notes || "";
    const dispersal = Dispersal.prototype.toString.call({ data: this.dispersal });

    return `${format.number.display(this.min)}-${format.number.display(this.max)} / ${dispersal} ${notes}`;
  }

  toGridView() {
    if (typeof this.min !== "number" || typeof this.max !== "number") return "";

    const dispersal = Dispersal.prototype.toString.call({ data: this.dispersal });

    return `${format.number.display(this.min)}-${format.number.display(this.max)} / ${dispersal}`;
  }
}

class LiftingsProps extends DataModelProps {
  get Editor() {
    return LiftingsEditor;
  }

  get schema() {
    return schema;
  }
}

LiftingsProps.prototype.sortKey = "max";
LiftingsProps.prototype.label = LABEL_MAP.liftings;
LiftingsProps.prototype.JSONDefaults = {
  dispersal: "Fairly even spread",
};
LiftingsProps.prototype.dispersalArr = DISPERSAL_ARR;

export { Liftings, LiftingsProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface Liftings extends Data {
  Props: typeof LiftingsProps;
  _: LiftingsProps;
}

interface LiftingsProps {
  dispersalArr: Dispersal[];
  sortKey: keyof Data;
  JSONDefaults: PartialData;
}

type PartialData = Partial<Data>;
type Data = TradeAPI.Liftings;
