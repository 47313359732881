import { makeAutoObservable } from "mobx";
import { get } from "lodash-es";
import { Route, Key } from "@/models";
import { routes } from "@/routes";

class Router {
  constructor() {
    // @ts-ignore
    this._active = null;

    makeAutoObservable(this);
  }

  promise = new Promise((resolve) => {
    this.resolve = resolve;
  });

  get active() {
    return this._active || this.root;
  }

  getActive() {
    return this._active || this.root;
  }

  setup = () => {
    this.root = new Route(routes);

    iterate(this.root, undefined, this.root);

    this.resolve();
  };

  setActive = (route: Route) => {
    this._active = route;
  };

  get = (path: string): Route => getChildRoute(this.root, this.root, path);
}

const router = new Router();

export { router };

function iterate(route: Route, parent: Route | undefined, root: Route) {
  const { seed } = route;
  const { sidenav, nav, redirect } = seed;

  route._sidenav = sidenav?.map((path) => getChildRoute(root, route, path));
  route._nav = nav?.map((path) => getChildRoute(root, route, path));

  if (typeof redirect === "function") route.redirect = getRedirect.bind(null, root, route, redirect);
  else route.redirect = getRedirect(root, route, redirect);

  for (let i = 0; i < route.children.length; i += 1) {
    const child = route.children[i];

    iterate(child, route, root);
  }
}

function getChildRoute(root: Route, route: Route, key: Key): Route {
  if (typeof key === "string") key = { key };

  const isRelative = key.key.startsWith("./") || key.key.startsWith("../");

  let res = route as Route | undefined;

  if (isRelative) {
    const path = key.key.split("/");

    path.forEach((item) => {
      if (item === ".") res = route;
      else if (item === "..") res = route.parent;
      else if (res) res = res.childrenMap[item];
    });

    //
  } else {
    let path = key.key.split(".");

    path = path.reduce((acc: string[], item, i) => {
      acc.push("childrenMap", item);

      return acc;
    }, []);

    res = get(root, path);
  }

  if (!res) {
    throw new Error(`route "${key.key}" does not exist`);
  }

  return res;
}

function getRedirect(root: Route, route: Route, redirect: Route["seed"]["redirect"]) {
  if (!redirect) return;

  if (typeof redirect === "function") redirect = redirect();

  if (!redirect) return;

  if (typeof redirect === "string") redirect = { to: redirect };

  const { to, from } = redirect;
  const fromRoute = from ? getChildRoute(root, route, from) : route;
  const toRoute = getChildRoute(root, route, to);

  return {
    from: fromRoute,
    to: toRoute,
  };
}

interface Router {
  root: Route;
  _active: Route;
  promise: Promise<unknown>;
  resolve: (value?) => void;
}
