import React from "react";
import classNames from "classnames";
import * as ClassNames from "classnames";
import "./Heading.scss";

function Heading(props: Heading.Props) {
  let { className, hidden, ...rest } = props;

  className = classNames(className, "app-form-heading");

  if (hidden) return null;

  return <app-h2 {...rest} class={className} />;
}

export { Heading };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace Heading {
  export interface Props extends Omit<ReactHTMLHeadingProps, "className"> {
    className?: ClassNames.Argument;
  }

  type ReactHTMLHeadingProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>;
}
