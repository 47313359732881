import { makeAutoObservable } from "mobx";
import { keyBy } from "lodash-es";
import { TradeAPI, tradeAPI } from "___REFACTOR___/apis";

class Companies {
  constructor() {
    makeAutoObservable(this);
  }

  data = [] as Data;
  dataById = {} as DataById;

  promise = new Promise((resolve) => {
    this.resolve = resolve;
  });

  getData = async () => {
    const res = await tradeAPI.getCompanies();

    if (res.data) this.setData(res.data);
  };

  setData = (data: Data) => {
    this.data = data;
    this.dataById = keyBy(data, "companyId");

    this.resolve();
  };
}

export const companies = new Companies();

type Data = TradeAPI.Company[];

interface DataById {
  [name: string]: TradeAPI.Company;
}

interface Companies {
  resolve: (value?) => void;
}
