import React from "react";
import Field from "../Field/Field";
import EditorRowEmptyValue from "../EditorRowEmptyValue/EditorRowEmptyValue";
import { ILocationView } from "sharedFolder/Models/ILocation";
import { useConfig } from "__legacy/sharedFolder/ConfigurationContext";
import { locationViewText } from "sharedFolder/display/location";
import { NewLocationEditor } from "../../editors/NewLocationEditor/NewLocationEditor";

interface ILocationFieldProps<T> {
  isFieldValid: (key: T) => boolean;
  updateForm: (key: T, value: any) => void;
  selectedItem?: ILocationView;
  isExpanded: boolean;
  setExpandedItem: (name: T) => void;
  locationType: "load" | "discharge";
  required: boolean;
}

export const LocationField = (props: ILocationFieldProps<"load" | "discharge">) => {
  const { ctradeUrl } = useConfig();
  const loadProps = {
    id: "loadLocation",
    label: "Load Location(s)".concat(`${props.required ? " *" : ""}`),
    name: "Load Locations",
  };
  const dischargeProps = {
    id: "dischargeLocation",
    label: "Discharge Location(s)".concat(`${props.required ? " *" : ""}`),
    name: "Discharge Locations",
  };
  const locationTypeProps = props.locationType === "load" ? loadProps : dischargeProps;
  return (
    <Field
      {...locationTypeProps}
      edit={
        <NewLocationEditor
          id={locationTypeProps.id}
          focus={true}
          isValid={props.isFieldValid(props.locationType)}
          required={true}
          value={props.selectedItem}
          onChange={(value?: ILocationView) => {
            props.updateForm(props.locationType, value);
          }}
          ctradeUrl={ctradeUrl}
        />
      }
      expanded={props.isExpanded}
      expand={() => {
        props.setExpandedItem(props.locationType);
      }}
      display={
        <>{(props.selectedItem && locationViewText(props.selectedItem)) || <EditorRowEmptyValue required={props.required} />}</>
      }
      isValid={props.isFieldValid(props.locationType)}
    />
  );
};
