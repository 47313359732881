import { createContext, useContext, useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import { Negotiation, Order, OrderNegotiationStore, slideout, Vessel } from "@/models";
import { VesselButton, Button, Overlay, SummaryDetails, StatusIcon, Collapse, BrokerBanner, statusMap } from "@/components";
import { formatDate } from "@/utils";
import { Context as OrdersContext } from "../../../../Orders";
import { EditVesselSlideout } from "../EditVesselSlideout";
import { NameVesselSlideout } from "../NameVesselSlideout";
import { NotesSlideout } from "../NotesSlideout";
import { OwnerAccountSlideout } from "../OwnerAccountSlideout";
import {
  New,
  Withdrawn,
  Nomination,
  Inactive,
  Active,
  Failed,
  Firm,
  TermsLoading,
  MainTerms,
  OnSubs,
  SubsLifted,
  SubsFailed,
  Fixed,
  Confirmed,
  Archived,
} from "./Status";
import "./NegotiationDetail.scss";
import { isEmpty } from "lodash-es";
import { HumanVisualizeDate } from "___REFACTOR___/utils";
import { NegotiationTypeEvents, usageMetrics } from "@/services/UsageMetrics";

let Refactor: Await<ReturnType<typeof resolveRefactorExports>>;
async function resolveRefactorExports() {
  return {
    ...(await import("___REFACTOR___/services/EntityStorage")),
    ...(await import("___REFACTOR___/utils/utils")),
  };
}
resolveRefactorExports().then((exports) => (Refactor = exports));

function NegotiationDetail(params: Params) {
  const ordersContext = useContext(OrdersContext);
  const order = ordersContext.orderNegotiationStore?.upsertOrderModel({ id: params.data.orderId }) as Order;
  const negotiation = order.upsertNegotiation(params.data) as Negotiation;
  const context = useMemo(createContext, [negotiation?.version, negotiation?._.version]);
  const noteDetailArray = useMemo(getNoteDetailArray, [negotiation?._.version]);

  if (!negotiation) return null;

  const { status } = negotiation;
  const parsedUpdateToken = negotiation.getParsedUpdateToken();
  const permittedMethodsMap = parsedUpdateToken?.permittedMethodsMap;

  if (!status) return null;

  const Status = StatusComponents[status];
  const seeMoreDetails =
    negotiation?.isBeyondInitialTerms() && negotiation.mainTermsDetails
      ? negotiation?.getMainTermsDetailsModelArray()
      : negotiation?.getNonNegotiableInitialTermModelArray();

  const vessels = negotiation.getVesselsModel()?.vessels;
  const counterpary = negotiation.getCounterparty();
  const invitee = negotiation.getInviteeModel();

  function openNotesSlideout(note) {
    slideout.show({
      content: (
        <NotesSlideout
          updateNegotiationNoteFor={note?.updateNegotiationNoteFor}
          negotiation={negotiation}
          context={ordersContext}
        />
      ),
    });
  }

  return (
    <Context.Provider value={context}>
      <div className="order-negotiations-negotiation">
        <BrokerBanner negotiation={negotiation} />

        <table className="order-negotiations-negotiation-misc-details">
          <tbody>
            {counterpary && (
              <tr className="order-negotiations-negotiation-detail">
                <td>Counterparty</td>
                <td>
                  {counterpary}{" "}
                  <Button
                    variant="flat"
                    dataTest="add-owning-company-btn"
                    onClick={openOwnerAccountSlideout}
                    hidden={!permittedMethodsMap?.SetOwningCompany}
                  >
                    Edit
                  </Button>
                </td>
              </tr>
            )}
            {invitee && (
              <tr className="order-negotiations-negotiation-detail">
                <td>Invitee</td>
                <td
                  className={
                    negotiation.isOwnerAbsent
                      ? "order-negotiations-negotiation-detail absent-owner-color"
                      : "order-negotiations-negotiation-detail"
                  }
                >
                  {inviteeToView()}
                </td>
              </tr>
            )}

            <tr className="order-negotiations-negotiation-detail order-negotiations-negotiation-detail-vessels">
              <td>Vessels</td>
              <td>
                {vessels?.length ? (
                  <>
                    {vessels.map(VesselHOC)}
                    <Button
                      onClick={openNominateVesselSlideout}
                      containerClassName="order-negotiations-negotiation-detail-vessel-additional-nominate"
                      dataTest="nominate-additional-vessel-btn"
                      hidden={!vessels?.length || !permittedMethodsMap?.NameVessel}
                    >
                      Nominate another vessel
                    </Button>
                  </>
                ) : (
                  <div className="order-negotiations-negotiation-detail-vessel">
                    <span className="vessel-name">TBN</span>
                    <Button
                      onClick={openNominateVesselSlideout}
                      dataTest="nominate-first-vessel-btn"
                      hidden={!permittedMethodsMap?.NameVessel}
                    >
                      Nominate a vessel
                    </Button>
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <Status />
        <div className="order-negotiations-negotiation-actions">
          <div className="action-area">
            {!isEmpty(seeMoreDetails) && (
              <Collapse
                label="see more"
                labelExpanded="see less"
                btnDataTest="see-more"
                iconCollapsed="chevron-right"
                iconExpanded="chevron-down"
              >
                <SummaryDetails skinny list={seeMoreDetails} />
              </Collapse>
            )}
          </div>
          <div className="action-area">
            {noteDetailArray && (
              <Collapse label="notes" btnDataTest="notes">
                <SummaryDetails skinny list={noteDetailArray} />
              </Collapse>
            )}
          </div>
        </div>
        <Overlay status={negotiation._.status} />
      </div>
    </Context.Provider>
  );

  function inviteeToView() {
    if (negotiation.isOwnerAbsent) {
      return "No owner assigned (owner absent negotiation)";
    } else return invitee?.toView();
  }

  function createContext() {
    return { negotiation };
  }

  function openOwnerAccountSlideout() {
    slideout.show({
      content: <OwnerAccountSlideout negotiation={negotiation} context={ordersContext} />,
    });
  }

  function openNominateVesselSlideout() {
    usageMetrics.trackEvent(NegotiationTypeEvents.NOMINATE_VESSEL);
    slideout.show({
      content: <NameVesselSlideout negotiation={negotiation} context={ordersContext} />,
    });
  }

  function VesselHOC(vessel: Vessel, i: number) {
    const status = vessel?.status && statusMap[vessel?.status];
    const className = `order-negotiations-negotiation-detail-vessel ${vessel?.status}`;

    function openVesselSlideout() {
      slideout.show({
        content: <EditVesselSlideout negotiation={negotiation} vessel={vessel} context={ordersContext} />,
      });
    }

    return (
      <div key={i} className={className}>
        <span className={`vessel-name ${vessel?.status}`}>{vessel.toView()}</span>
        <StatusIcon status={status} />
        <Button onClick={openVesselSlideout} dataTest="view-vessel-btn">
          action
        </Button>
        {vessel && <VesselButton vessel={vessel} seaAnalyticsOrSeaNet="Analytics" />}
      </div>
    );
  }

  function getNoteDetailArray() {
    const notesMap = negotiation.getNotesModelMap();
    const entries = Object.entries(notesMap);

    const array = [] as any[];

    entries.forEach((el) => {
      const [name, note] = el;
      let noteBy;

      if (name === "owner") noteBy = negotiation.invitee;
      if (name === "brokerCharterer") noteBy = negotiation.circulatedBy;

      if (note) {
        const noteDetail = {
          label: "Note by",
          updateNegotiationNoteFor: name,
          display: undefined as any,
        };

        noteDetail.display = (
          <div onClick={openNotesSlideout.bind(null, noteDetail)} className="clickable">
            {noteBy}
            <br />
            {note.value}
            <br />
            {formatDate(note.timestamp, "DD MMM YY, HH:mm")}
          </div>
        );

        array.push(noteDetail);
      }
    });

    if (!array.length) return;

    return array;
  }
}

const StatusComponents = {
  Confirmed,
  New,
  Withdrawn,
  Nomination,
  Inactive,
  Active,
  Failed,
  Firm,
  TermsLoading,
  MainTerms,
  OnSubs,
  SubsLifted,
  SubsFailed,
  Fixed,
  Archived,
};

// @ts-ignore
export const Context = createContext<Context>(null);

const Observer = observer(NegotiationDetail);

export { Observer as NegotiationDetail };

interface Context {
  negotiation: Negotiation;
}

interface Params {
  node: any;
  data: OrderNegotiationStore["Negotiation"];
}
