import { makeAutoObservable, observable } from "mobx";
import { GlobalDropdownProps, DropdownItem } from "@/components";

class Dropdown {
  constructor() {
    this.open = false;
    this.props = undefined;
    this.promise = undefined;
    this.resolve = undefined;

    makeAutoObservable(this, {
      props: observable.ref,
    });
  }

  show = (props: GlobalDropdownProps) => {
    this.open = true;
    this.props = props;
    this.promise = new Promise((resolve) => {
      this.resolve = resolve;
    });

    return this.promise;
  };

  update = (props: GlobalDropdownProps) => {
    this.props = { ...this.props, ...props };
  };

  hide = () => {
    if (this.resolve) this.resolve();

    this.open = false;
    this.props = undefined;
    this.promise = undefined;
    this.resolve = undefined;
  };

  select = (item) => {
    try {
      if (this.props?.onItemSelected) {
        this.props?.onItemSelected();
      }
    } catch (e) {
      console.error("dropdown:: Something went wrong with onItemSelected callback!", e);
    } finally {
      if (this.resolve) this.resolve(item);
      this.hide();
    }
  };
}

export const dropdown = new Dropdown();

interface Dropdown {
  promise: Promise<DropdownItem> | undefined;
  resolve: ((item?: DropdownItem) => void) | undefined;
  open: boolean;
  props?: GlobalDropdownProps;
}
