import React from "react";
import { uniqBy } from "lodash-es";
import { IAttachmentErrors } from "__legacy/sharedFolder/components/common/interfaces";
import "./AttachmentErrors.scss";

interface AttachmentErrorsProps {
  errors: IAttachmentErrors | null;
}

const AttachmentErrors = ({ errors }: AttachmentErrorsProps) => {
  if (!errors) {
    return null;
  }

  return (
    <div className="attachment-errors">
      <h3>{errors.partialFailure ? "Some of the files were not uploaded" : "Upload failed"}</h3>

      {errors.tooManyFilesUploaded && <p>Maximum of 10 files can be uploaded at once</p>}
      {!!errors.filesWithErrors.length && (
        <>
          <p>There was an issue with the following file(s):</p>
          <ul>
            {uniqBy(errors.filesWithErrors, "fileName").map((fileWithError) => (
              <li key={fileWithError.error}>{fileWithError.fileName}</li>
            ))}
          </ul>
          Please ensure the file is less than 50mb, you are uploading no more than 10 attachments and your file is an acceptable
          file format.
        </>
      )}
    </div>
  );
};

export default AttachmentErrors;
