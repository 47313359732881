import React, { useRef, useState } from "react";
import { useShallowStateOld, emptyFn } from "___REFACTOR___/utils";
import { tradeAPI } from "___REFACTOR___/apis";
import { Location, LocationProps, Status } from "___REFACTOR___/models";
import { TextareaField, SelectField, Field } from "___REFACTOR___/components";
import { Group } from "../../Group";
import { Row } from "../../Row";
import { LocationEditor as LocationEditorInner } from "./Editor";
import "./Location.scss";

function LocationEditor(props: LocationEditor.Props) {
  const { className, value, status, hideSafes, dataTest } = props;
  const [data, setData] = useState<Location[] | undefined>(undefined);
  const [, setDropdownStatus, dropdownStatus] = useShallowStateOld<Status>({
    message: "Please enter a location",
  });
  const cancel = useRef(emptyFn);

  return (
    <Group {...props} className={className} hidden={props.hidden}>
      <LocationEditorInner
        {...props}
        value={value}
        onChange={onChange}
        data={data}
        dropdownStatus={dropdownStatus}
        onFilter={onFilter}
        onBlur={onBlur}
        dataTest={`${dataTest}.value`}
      />
      {!hideSafes && (
        <Row hidden={!value}>
          <SelectField
            {...props}
            required={false}
            label="Berth(s)"
            desc="Safe Berth(s)"
            className="trade-location-options-berth"
            filterKey="label"
            data={LocationProps.prototype.safeOptionArr}
            value={LocationProps.prototype.safeOptionLabelByMinMaxMap[`${value?.safeBerthsMin}${value?.safeBerthsMax}`]}
            onChange={onOptionsChange.bind(null, "safeBerthsMin", "safeBerthsMax")}
            status={status?.childMap?.safeBerthsMin || status?.childMap?.safeBerthsMax}
            dataTest={`${dataTest}.berth`}
            key="berth"
            nonFilterSelect
          />
          <SelectField
            {...props}
            required={false}
            label="Port(s)"
            desc="Safe Port(s)"
            className="trade-location-options-port"
            filterKey="label"
            data={LocationProps.prototype.safeOptionArr}
            value={LocationProps.prototype.safeOptionLabelByMinMaxMap[`${value?.safePortsMin}${value?.safePortsMax}`]}
            onChange={onOptionsChange.bind(null, "safePortsMin", "safePortsMax")}
            status={status?.childMap?.safePortsMin || status?.childMap?.safePortsMax}
            dataTest={`${dataTest}.port`}
            key="port"
            nonFilterSelect
          />
          <SelectField
            {...props}
            required={false}
            label="Anchorage(s)"
            desc="Safe Anchorage(s)"
            className="trade-location-options-anchorage"
            filterKey="label"
            data={LocationProps.prototype.safeOptionArr}
            value={LocationProps.prototype.safeOptionLabelByMinMaxMap[`${value?.safeAnchoragesMin}${value?.safeAnchoragesMax}`]}
            onChange={onOptionsChange.bind(null, "safeAnchoragesMin", "safeAnchoragesMax")}
            status={status?.childMap?.safeAnchoragesMin || status?.childMap?.safeAnchoragesMax}
            key="anchorage"
            dataTest={`${dataTest}.anchorage`}
            nonFilterSelect
          />
        </Row>
      )}
      <TextareaField
        {...props}
        required={false}
        label="Notes"
        desc="Location notes"
        value={value?.notes || ""}
        onChange={onNotesChange}
        status={status?.childMap?.notes}
        dataTest={`${dataTest}.notes`}
        hidden={!value}
      />
    </Group>
  );

  async function onFilter(search: string) {
    cancel.current();

    setData(undefined);
    setDropdownStatus({ message: undefined, loading: false });

    if (!search) {
      setDropdownStatus({ message: "Please enter a location" });

      return;
    }

    setDropdownStatus({ message: "Searching for locations", loading: true });

    const promise = tradeAPI.searchLocations(search);

    cancel.current = promise.cancel;

    const res = await promise;

    if (res.cancelled) return;

    if (!res.data || !res.data.length) {
      setData(undefined);
      setDropdownStatus({ message: "No locations found", loading: false });

      return;
    }

    const data = res.data.map((el) => new Location(el.document));

    setData(data);
    setDropdownStatus({ message: undefined, loading: false });
  }

  function onBlur(e) {
    cancel.current();

    setData(undefined);
    setDropdownStatus({ message: "Please enter a location", loading: false });

    props.onBlur?.(e);
  }

  function onChange(location: Location | undefined) {
    if (location === undefined) return props.onChange(undefined);

    props.onChange?.(new Location(location ? { ...value, ...location } : undefined));
  }

  function onOptionsChange(minName: string, maxName: string, option: typeof LocationProps.prototype.safeOptionArr[number]) {
    props.onChange?.(new Location({ ...value, [minName]: option?.min || null, [maxName]: option?.max || null }));
  }

  function onNotesChange(notes: TextareaField.Input.Value) {
    if (typeof notes !== "string") return;

    props.onChange?.(new Location({ ...value, notes }));
  }
}

export { LocationEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace LocationEditor {
  export interface Props extends Field.Props {
    value: Value;
    onChange: (value: Value) => void;
    hideSafes?: boolean;
  }

  export type Value = Location | undefined;
}
