import numeral from "numeral";
import { IDurationView } from "../Models/IDetails";

function durationText(value: IDurationView | undefined): string | undefined {
  if (!value || !value.unit) {
    return undefined;
  }

  const { min, max, unit, notes } = value;

  const minDisplay = min === 0 ? null : numeral(min).format("0,0");
  const maxDisplay = max === 0 ? null : numeral(max).format("0,0");

  return minDisplay && maxDisplay
    ? minDisplay === maxDisplay
      ? `${minDisplay} ${unit || ""}`
      : `${minDisplay}-${maxDisplay} ${unit || ""} ${notes || ""}`
    : "";
}

export { durationText };
