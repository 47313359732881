import { TradeAPI } from "___REFACTOR___/apis";
import { CommissionEditor } from "___REFACTOR___/components/common/Editor/type/Commission";
import { DataModel, DataModelProps } from "../DataModel";
import { schema } from "./schema";

class Commission extends DataModel {
  static get Editor() {
    return CommissionEditor;
  }

  constructor(data: PartialData) {
    super(data);
  }

  isValid() {
    return schema.isValidSync(this);
  }

  toString() {
    if (typeof this.value !== "number") return "";

    return `${this.value?.toFixed(3)}%`;
  }

  toJSON() {
    const json = DataModel.prototype.toJSON.call(this) as TradeAPI.Commission;

    return json;
  }
}

class CommissionProps extends DataModelProps {
  get Editor() {
    return CommissionEditor;
  }

  get label() {
    return "Commission";
  }

  get schema() {
    return schema;
  }
}

Commission.prototype.Props = CommissionProps;

export { Commission, CommissionProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface Commission extends Data {
  Props: typeof CommissionProps;
  _: CommissionProps;
}

type PartialData = Partial<Data>;
type Data = TradeAPI.Commission;
