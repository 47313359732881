import dayjs from "dayjs";
import { TradeAPI } from "@/apis";
import { PeriodEditor } from "@/components";
import { DataModel, DataModelProps } from "../DataModel";

export class Period extends DataModel<Data> {
  onUpdate() {
    this.from = this.start || this.from;
    this.to = this.end || this.to;

    this._.startDate = new Date(this.start || 0);

    delete this.display;
    delete this.shortDisplay;
  }

  toString() {
    if (!this) return "";
    if (!isValid(this)) return "";

    return `${dayjs.utc(this.start || this.from).format(format)} - ${dayjs.utc(this.end || this.to).format(format)}`;
  }

  toView() {
    if (!this) return "";
    if (!isValid(this)) return "";

    return `${dayjs.utc(this.start || this.from).format(format)} - ${dayjs.utc(this.end || this.to).format(format)}`;
  }

  toGridView() {
    if (!this) return "";
    if (!isValid(this)) return "";

    return `${dayjs.utc(this.start || this.from).format(format)} - ${dayjs.utc(this.end || this.to).format(format)}`;
  }

  toSortValue() {
    if (this.from) return new Date(this.from);
    if (this.start) return new Date(this.start);
  }

  isValid() {
    return isValid(this);
  }

  toJSON() {
    const { _, ...rest } = this;
    const json = {
      ...rest,
      display: this.toView(),
      shortDisplay: this.toView(),
    };
    if (this.from) {
      json.from = dayjs.utc(this.from).toJSON();
    }
    if (this.to) {
      json.to = dayjs.utc(this.to).toJSON();
    }
    return json;
  }
}

function isValid(data: Data) {
  return !!((data.from && data.to) || (data.start && data.end));
}

export class PeriodProps extends DataModelProps<Data> {}

PeriodProps.prototype.editor = PeriodEditor;
PeriodProps.prototype.label = "Period";

Period.prototype._ = new PeriodProps();

const format = "DD MMM YY";

export { format as DefaultPeriodFormat };

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

export interface Period extends Data {
  _: PeriodProps;
}

export interface PeriodProps {
  startDate: Date;
}

type Data = DeepPartial<TradeAPI["Period"]>;
