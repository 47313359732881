import { useContext, useMemo } from "react";
import { observer } from "mobx-react";
import { useExecuteAtDate, useForceUpdate } from "@/utils";
import { testSelectors } from "@/constants";
import { auth, CustomDate, dialog, Negotiation } from "@/models";
import { Button, Status, Timeleft, Icon, FormContext, Form } from "@/components";
import { postArcNegotiationSubs } from "@/components/Orders/actions";
import { MainTermsTypeEvents, NegotiationTypeEvents, usageMetrics } from "@/services/UsageMetrics";
import { Context } from "../../NegotiationDetail";
import "./OnSubs.scss";

let Refactor: Await<ReturnType<typeof resolveRefactorExports>>;
async function resolveRefactorExports() {
  return {
    ...(await import("___REFACTOR___/services/Slideout")),
    ...(await import("___REFACTOR___/components/common/Button")),
    ...(await import("___REFACTOR___/components/OrderNegLayout")),
  };
}
resolveRefactorExports().then((exports) => (Refactor = exports));

function OnSubs() {
  const { negotiation } = useContext(Context);
  const { responseRequired } = negotiation;
  const isResponseRequiredExpired = negotiation.isResponseRequiredExpired();
  const time = isResponseRequiredExpired ? "Expired" : <Timeleft until={responseRequired} textOnly />;
  const status = {
    type: "orange",
    title: <>On Subs {time}</>,
    icon: "schedule",
  } as Status;
  const forceUpdate = useForceUpdate();

  useExecuteAtDate(responseRequired, forceUpdate);

  return (
    <div className="OnSubs">
      <Status status={status} />
      <OnSubsActions negotiation={negotiation} />
    </div>
  );
}

const Manage = observer(function Manage(props: { negotiation: Negotiation }) {
  const { commercialSubsLifted, operationalSubsLifted } = props.negotiation;
  const initialValues = useMemo(getInitialValues, []);

  if (elements[3].radio) {
    elements[3].radio.radioLabel =
      commercialSubsLifted && operationalSubsLifted ? "Clean fix" : "Lift all Subjects and clean fix";
    elements[3].radio.checkedValue.statusTitle =
      commercialSubsLifted && operationalSubsLifted ? "Clean fix now" : "Lift All Subs And Fix";
    elements[3].radio.checkedValue.loadingMessage =
      commercialSubsLifted && operationalSubsLifted ? "Fixing now" : "Lifting All Subs And Fixing";
  }

  return (
    <div className="onSubs-manage">
      <h2>Lift Subjects</h2>
      <div className="onSubs-manage-header">
        <div>
          Commercial Subs
          <span hidden={commercialSubsLifted} className="pending">
            Pending
          </span>
          <Icon name="done" className="lifted" hidden={!commercialSubsLifted}>
            Lifted
          </Icon>
        </div>
        <div>
          Operational Subs
          <span hidden={operationalSubsLifted} className="pending">
            Pending
          </span>
          <Icon name="done" className="lifted" hidden={!operationalSubsLifted}>
            Lifted
          </Icon>
        </div>
      </div>
      <Form onSubmit={onSubmit} elements={elements} initialValues={initialValues} context={form} />
      <div className="onSubs-manage-actions">
        <Button variant="flat" onClick={dialog.hide} data-test="button-lift-subs-cancel">
          Cancel
        </Button>
        <Button
          variant="action"
          onClick={form.submit}
          data-test={testSelectors.liftSubsSubmitButton}
          disabled={!form.values?.manageSubsOption}
        >
          {form.values?.manageSubsOption ? form.values?.manageSubsOption?.statusTitle : "Submit"}
        </Button>
      </div>
    </div>
  );

  function onSubmit() {
    dialog.resolve?.({
      res: {
        ...form.values?.manageSubsOption,
        payload: {
          ...form.values?.manageSubsOption?.payload,
          cpDate: form.values?.cpDate,
          userTimeZone: form.values?.userTimeZone,
        },
      },
    });

    let event;
    switch (form.values.manageSubsOption.statusTitle) {
      case "Lift Operational Subs":
        event = NegotiationTypeEvents.SUBS_LIFT_OPERATIONAL;
        break;
      case "Lift Commercial Subs":
        event = NegotiationTypeEvents.SUBS_LIFT_COMMERCIAL;
        break;
      case "Lift All Subs":
        event = NegotiationTypeEvents.SUBS_LIFT_AND_SUBJECT;
        break;
      case "Lift All Subs And Fix":
        event = NegotiationTypeEvents.SUBS_LIFT_AND_CLEAN_FIX;
        break;
      default:
        break;
    }
    if (event) usageMetrics.trackEvent(event);
    dialog.hide();
  }

  function getInitialValues() {
    return {
      cpDate: new CustomDate({ date: new Date(), withoutTimezone: true, startOfDay: true }),
      userTimeZone: auth.trade.user?.Timezone,
      manageSubsOption: false,
      commercialSubsLifted,
      operationalSubsLifted,
    };
  }
});

export const OnSubsActions = ({ negotiation }: { negotiation: Negotiation }) => {
  const { arcUrlWithCentralToken, arcCpTermsUrlWithCentralToken, status } = negotiation;
  async function confirm() {
    const { res: confirmed } = await dialog.show({
      status: {
        type: "warning",
        title: "Fail All Subs",
        message: "Are you sure you want to fail all subs? This cannot be undone.",
      },
      className: "OnSubs-dialog",
      dataTest: testSelectors.confirmSubsFailDialog,
    });

    if (!confirmed) return;
    usageMetrics.trackEvent(NegotiationTypeEvents.FAIL_SUBS_CONFIRM);
    postArcNegotiationSubs(negotiation, FAIL_SUBS_OPTION);
  }

  async function manage() {
    const { res: option } = await dialog.show({
      content: <Manage negotiation={negotiation} />,
      dataTest: testSelectors.liftSubsDialog,
    });

    if (!option) return;

    postArcNegotiationSubs(negotiation, option);
  }

  return (
    <div className="onsubs-actions">
      <div>
        <Button variant="positive" icon="done-all" onClick={manage}>
          Manage Subs
        </Button>
        <Button variant="warning" icon="failed" onClick={confirm.bind(null, "arcSubsFail")}>
          fail subs
        </Button>
      </div>
      <div>
        {status !== "SubsLifted" && (
          <Button
            variant="action"
            href={arcUrlWithCentralToken}
            data-test="open-main-terms"
            icon="file"
            openHrefInNewWindow
            onClick={() => {
              usageMetrics.trackEvent(MainTermsTypeEvents.MAIN_TERMS_OPENED);
            }}
          >
            open Main Terms
          </Button>
        )}
        <Button
          variant="action"
          href={arcCpTermsUrlWithCentralToken}
          data-test="open-cp-terms"
          icon="file"
          openHrefInNewWindow
          onClick={() => {
            usageMetrics.trackEvent(MainTermsTypeEvents.CP_TERMS_OPENED);
          }}
        >
          open cp terms
        </Button>
      </div>
    </div>
  );
};

const form = new FormContext();

const elements = [
  {
    type: "Radio",
    name: "manageSubsOption",
    radioLabel: "Lift Operational Subjects Only",
    dataTest: testSelectors.liftOperationalSubsOption,
    checkedValue: {
      action: "postArcSubsLift",
      payload: {
        subjectTermType: 3,
      },
      statusTitle: "Lift Operational Subs",
      loadingMessage: "Lifting Operational Subs",
      // successStatus: { type: "success", title: "Successful Lift of Operational Subs", message: "Operational subs were successfully lifted" },
    },
    visible: (data) => {
      const { operationalSubsLifted } = data.values;
      return !operationalSubsLifted;
    },
  },
  {
    type: "Radio",
    name: "manageSubsOption",
    radioLabel: "Lift Commercial Subjects Only",
    dataTest: testSelectors.liftCommercialSubsOption,
    checkedValue: {
      action: "postArcSubsLift",
      payload: {
        subjectTermType: 2,
      },
      statusTitle: "Lift Commercial Subs",
      loadingMessage: "Lifting Commercial Subs",
      // successStatus: { type: "success", title: "Successful Lift of Commercial Subs", message: "Commercial subs were successfully lifted" },
    },
    visible: (data) => {
      const { commercialSubsLifted } = data.values;
      return !commercialSubsLifted;
    },
  },
  {
    type: "Radio",
    name: "manageSubsOption",
    radioLabel: "Lift All Subjects (remain subject to CP details)",
    dataTest: testSelectors.liftAllSubsOption,
    checkedValue: {
      action: "postArcSubsLift",
      payload: {
        subjectTermType: 0,
      },
      shortLabel: "Lift All Subjects",
      statusTitle: "Lift All Subs",
      loadingMessage: "Lifting All Subs",
      // successStatus: { type: "success", title: "Successful Lift of All Subs", message: "All subs were successfully lifted" },
    },
    visible: (data) => {
      const { commercialSubsLifted, operationalSubsLifted } = data.values;
      return !commercialSubsLifted || !operationalSubsLifted;
    },
  },
  {
    type: "RadioDateTimezone",
    date: {
      name: "cpDate",
      withoutTimezone: true,
    },
    timezone: {
      name: "userTimeZone",
      unclearable: true,
    },
    radio: {
      name: "manageSubsOption",
      radioLabel: "Lift all Subjects and clean fix",
      dataTest: testSelectors.liftAllSubsAndFixOption,
      checkedValue: {
        action: "postArcSubsLiftAndFix",
        payload: {
          subjectTermType: 0,
        },
        statusTitle: "Lift All Subs And Fix",
        loadingMessage: "Lifting All Subs And Fixing",
        // successStatus: {
        //   type: "success",
        //   title: "Successful Lift All Subs and Fix",
        //   message: "All subs were successfully lifted and negotiation is fixed",
        // },
      },
    },
  },
];

const FAIL_SUBS_OPTION = {
  action: "postArcSubsFail",
  statusTitle: "Fail All Subs",
  loadingMessage: "Failing All Subs",
  // successStatus: {
  //   type: "success",
  //   title: "Successful Fail of All Subs",
  //   message: "All subs were successfully failed",
  // },
};

const Observer = observer(OnSubs);

export { Observer as OnSubs };
