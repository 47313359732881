import { Field } from "___REFACTOR___/components/common/Field";
import {
  AccountEditor as Account,
  PeriodEditor as Period,
  CargoTypeEditor as CargoType,
  CargoSizeEditor as CargoSize,
  COACargoSizeEditor as COACargoSize,
  CommissionEditor as Commission,
  CompanyEditor as Company,
  LocationEditor as Location,
  LiftingsEditor as Liftings,
  NominationsEditor as Nominations,
  VesselSizeEditor as VesselSize,
  DurationEditor as Duration,
  TermsetSearchEditor as TermsetSearch,
  SubjectTermsetEditor as SubjectTermset,
  ProformaLayoutEditor as ProformaLayout,
  ProformaLayoutKeyEditor as ProformaLayoutKey,
  OwnerAccountEditor as OwnerAccount,
  VesselEditor as Vessel,
  VesselsEditor as Vessels,
  TimelimitEditor as Timelimit,
  OrderTemplateSearchEditor as OrderTemplateSearch,
  UnverifiedAccountEditor as UnverifiedAccount,
  FreightRateEditor as FreightRate,
  HireRateEditor as HireRate,
  CleaningPriceEditor as CleaningPrice,
  SupplyPriceEditor as SupplyPrice,
  TradingExclusionsEditor as TradingExclusions,
  BunkerBaseEditor as Bunker,
  BunkerDeliveryEditor as BunkerDelivery,
  BunkerRedeliveryEditor as BunkerRedelivery,
  CargoExclusionsTextEditor as CargoExclusionsText,
  BallastBonusEditor as BallastBonus,
  DemurrageEditor as Demurrage,
  NegotiationNoteEditor as NegotiationNote,
  CLDDUEditor as CLDDU,
  CLDDUOwnerEditor as CLDDUOwner,
  CLDDUChartererEditor as CLDDUCharterer,
  CLDDUBrokerEditor as CLDDUBroker,
  SubsEditor as Subs,
  TimezoneEditor as Timezone,
} from "./type";

function Editor(props: Editor.Props) {
  const { type } = props;

  const Type = type && EDITOR_TYPE_MAP[type];

  if (!Type) {
    console.error(`Editor: Unrecognized editor type: ${type}`, props);

    return null;
  }

  return <Type {...props} />;
}

const EDITOR_TYPE_MAP = {
  ...Field.TYPE_MAP,
  Account,
  BallastBonus,
  CargoSize,
  CargoType,
  CLDDU,
  CLDDUBroker,
  CLDDUCharterer,
  CLDDUOwner,
  COACargoSize,
  Commission,
  Company,
  Demurrage,
  Duration,
  FreightRate,
  HireRate,
  CleaningPrice,
  SupplyPrice,
  TradingExclusions,
  Bunker,
  BunkerDelivery,
  BunkerRedelivery,
  CargoExclusionsText,
  Liftings,
  Location,
  NegotiationNote,
  Nominations,
  OrderTemplateSearch,
  OwnerAccount,
  Period,
  ProformaLayout,
  ProformaLayoutKey,
  SubjectTermset,
  Subs,
  TermsetSearch,
  Timelimit,
  Timezone,
  UnverifiedAccount,
  Vessel,
  Vessels,
  VesselSize,
};

export { Editor };

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

declare namespace Editor {
  interface Props extends Omit<Field.Props, "type"> {
    type: Type;
  }

  type Type = keyof typeof EDITOR_TYPE_MAP;
  type Component = ValueOf<typeof EDITOR_TYPE_MAP>;
}
