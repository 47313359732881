import { from, Observable } from "rxjs";
import * as api from "../../api/negotiations/models";
import offerRepMapper from "./OfferRepMapper";
import { IOfferRepViewNegotiation } from "negotiations/models/common/IOfferRep";

const getNegotiation = (baseUrl: string, offerRepToken: string): Observable<IOfferRepViewNegotiation> => {
  const url = `${baseUrl}/offerrep/${offerRepToken}`;
  return from(
    fetch(url)
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
        throw new Error("endpoint responded with " + res.status);
      })
      .then((data: api.INegotiation) => {
        return offerRepMapper(data);
      })
      .catch((e) => {
        console.error(e);
        throw new Error("Unable to fetch negotiation");
      })
  );
};

export { getNegotiation };
