import React, { useContext, useEffect, useMemo, useRef } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { CheckboxState, handleMultiSelectCheckboxClick, handleSelectionChange, shouldEnableFeature, uid } from "@/utils";
import { Negotiation, Order, auth } from "@/models";
import { Aggrid, AggridProps, Button, AggridContext, Icon } from "@/components";
import { BulkActionsPanel } from "@/components/common/BulkActionsPanel";
import { ContextMenu } from "@/components/common";
import { useHistory } from "react-router-dom";
import { Context } from "../../Orders";
import "./BaseGrid.scss";
import { archiveRequestsLimit } from "sharedFolder/constants";
import { testSelectors } from "@/constants";

let Refactor: Await<ReturnType<typeof resolveRefactorExports>>;
const resolveRefactorExports = async () => ({
  ...(await import("___REFACTOR___/services")),
  ...(await import("___REFACTOR___/components/common/Icon")),
  ...(await import("___REFACTOR___/components/common/Button")),
  ...(await import("___REFACTOR___/routes/DealCapture")),
  ...(await import("___REFACTOR___/components/common/Dialog")),
});
resolveRefactorExports().then((exports) => (Refactor = exports));

function BaseGrid(props: Props) {
  props = { ...props };
  props.className = classNames(props.className, "base-grid");

  const actorIsBroker = auth.trade.user?._.companyRoleMap.broker;
  const actorIsOwner = auth.trade.user?._.companyRoleMap.owner;
  const actorIsCharterer = auth.trade.user?._.companyRoleMap.charterer;
  const enableOdc = shouldEnableFeature("odc");
  const {
    rowData,
    selectedEntityId,
    entity,
    entityType,
    rowCount,
    className,
    isEndReached,
    totalRowCount,
    headerKebabActions,
    bulkPanelActions,
    closeBulkPanelAction,
    getContextMenuItems,
    ...rest
  } = props;
  const context = useContext(Context);
  const gridApi = ["owner-dashboard", "owner-archive"].includes(context.area)
    ? context.orderNegotiationStore?.negotiationGridContext.api
    : context.orderNegotiationStore?.orderGridContext.api;
  const selectedEntityIdRef = useRef(selectedEntityId);
  const prevSelectedEntityIdRef = useRef(selectedEntityId);
  const { view, routes } = context;
  const { btn, name, props: viewProps } = view.grid;
  const defaultAggridContext = useMemo(newAggridContext, []);
  const aggridContext = useMemo(getContext, []);
  const newOrderHref = buildCaptureHref("creation");
  const captureHref = buildCaptureHref("capture") ?? "";
  const captureHrefOwner = `${buildCaptureHref("capture")}?onbehalfof=owner`;
  const history = useHistory();
  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);
  const [selectedRowsCount, setSelectedRowsCount] = React.useState(0);
  const [multiSelectCheckboxState, setMultiSelectCheckboxState] = React.useState<CheckboxState>("unchecked");
  useEffect(() => {
    const contextMenuElement = document.querySelector("#header-kebab-menu");
    const headerKebabIcon = document.querySelector(".header-kebab .icon--kebab");

    function closeContextMenu(e) {
      if (e.target !== headerKebabIcon && e.target !== contextMenuElement) {
        setContextMenu(null);
      }
    }

    window.addEventListener("click", closeContextMenu);
    window.addEventListener("contextmenu", closeContextMenu);

    function removeListeners() {
      window.removeEventListener("click", closeContextMenu);
      window.removeEventListener("contextmenu", closeContextMenu);
    }

    return removeListeners;
  }, []);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();

    const offsetRight = 68;
    const offsetTop = 4;

    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - offsetRight,
            mouseY: event.clientY + offsetTop,
          }
        : null
    );
  };

  const handleCheckboxClick = () => {
    if (gridApi) {
      const newCheckboxState = handleMultiSelectCheckboxClick(gridApi, multiSelectCheckboxState) as CheckboxState;
      setMultiSelectCheckboxState(newCheckboxState);
    }
  };

  selectedEntityIdRef.current = selectedEntityId;

  useEffect(updateActiveRow, [selectedEntityId, entity?._.version]);

  function buildCaptureHref(action: string) {
    const baseCaptureRoute = routes.order.new?.get(action);
    const orderCaptureParams = { orderId: `$${uid()}` };
    const negotiationCaptureParams = { orderId: `$${uid()}`, negotiationId: `$${uid()}` };

    if (entityType === typeof Negotiation) {
      return baseCaptureRoute?.compile(negotiationCaptureParams);
    }
    return baseCaptureRoute?.compile(orderCaptureParams);
  }

  function updateActiveRow() {
    const { api } = aggridContext;

    if (!api) return;

    const node = api.getRowNode(selectedEntityId);
    const prevNode = api.getRowNode(prevSelectedEntityIdRef.current);
    node?.setData(node.data);
    prevNode?.setData(prevNode.data);
    prevSelectedEntityIdRef.current = selectedEntityId;
  }

  const rowClassRules = {
    unseen: (params) => !!context.orderNegotiationStore?.isUnseen(params.data),
    active: (params) => params.data.id === selectedEntityIdRef.current,
  };

  rest.rowClassRules = { ...rowClassRules, ...props.rowClassRules };

  const loadingText =
    totalRowCount !== Infinity ? (
      <>
        {`Loading ${name} `}
        <span>{rowCount}</span>
        {" out of "}
        <span>{totalRowCount}</span>
        {"..."}
      </>
    ) : (
      `Loading ${name}...`
    );

  const getMainOrderTab = () => {
    switch (context.area) {
      case "coa-archive":
        return "/coas";
      case "owner-archive":
        return "/owner-dashboard";
      case "ito-archive":
      default:
        return "/orders";
    }
  };

  const shouldShowKebabMenu = headerKebabActions?.length;

  return (
    <div className={className}>
      <div className="action-bar">
        <div style={{ display: "flex", alignItems: "center" }}>
          <h1>{isEndReached ? `${name} ${rowCount ? `(${rowCount})` : ""}` : loadingText}</h1>
          {btn && btn.close && (
            <Button
              variant="link"
              className="margins"
              onClick={() => history.push(getMainOrderTab())}
              dataTest={testSelectors.closeArchivedDashboardButton}
            >
              {btn.close.label}
            </Button>
          )}
        </div>

        {btn && (
          <div className="action-bar-buttons">
            {btn.capture && (
              <Button variant="action" icon={btn.capture.icon} onClick={onDealCaptureBtnClick} dataTest="new-deal-capture-button">
                {btn.capture.label}
              </Button>
            )}
            {btn.dealCapture && name !== "COAs" && (
              <Button
                variant="action"
                hidden={!enableOdc}
                icon={btn.dealCapture.icon}
                onClick={onDealCaptureBtnClick}
                dataTest="new-deal-capture-button"
              >
                {btn.dealCapture.label}
              </Button>
            )}
            {btn.add && (
              <Button variant="action" icon={btn.add.icon} to={newOrderHref} dataTest="new-order-button">
                {btn.add.label}
              </Button>
            )}
            {btn.kebab && shouldShowKebabMenu && (
              <Button
                icon={btn.kebab.icon}
                variant={btn.kebab.variant}
                onContextMenu={(e) => {
                  handleContextMenu(e);
                }}
                onClick={(e) => {
                  handleContextMenu(e);
                }}
                className="header-kebab"
                style={{ position: "absolute", bottom: props.bulkPanelIsVisible ? "-80px" : "-45px", right: "-2px" }}
                dataTest={`header-kebab-${context.area}`}
              />
            )}
          </div>
        )}
      </div>
      {contextMenu && <ContextMenu coordinates={contextMenu} actions={headerKebabActions} />}
      {props.bulkPanelIsVisible && (
        <BulkActionsPanel
          context={context}
          actions={bulkPanelActions}
          closeAction={closeBulkPanelAction}
          setMultiSelectCheckboxState={setMultiSelectCheckboxState}
          selectedRowsCount={selectedRowsCount}
          checkboxState={multiSelectCheckboxState}
          onCheckboxClick={handleCheckboxClick}
        />
      )}
      <Aggrid
        {...viewProps}
        {...rest}
        rowData={rowData}
        rowSelection={props.bulkPanelIsVisible ? "multiple" : "single"}
        suppressRowClickSelection
        rowMultiSelectWithClick={props.bulkPanelIsVisible}
        context={aggridContext}
        getRowNodeId={getRowNodeId}
        getContextMenuItems={props.getContextMenuItems}
        overlayNoRowsTemplate={props.noRowsOverlayText}
        onSelectionChanged={() => {
          const selectedRows = gridApi?.getSelectedNodes();
          let selectedCount = selectedRows?.length ?? 0;
          setSelectedRowsCount(selectedCount ?? 0);

          const newCheckboxState = handleSelectionChange(selectedCount, multiSelectCheckboxState, gridApi);
          if (multiSelectCheckboxState !== newCheckboxState) setMultiSelectCheckboxState(newCheckboxState);

          gridApi?.forEachNode((rowNode, index) => {
            if (selectedRows?.includes(rowNode)) return;
            rowNode.setRowSelectable(selectedCount < archiveRequestsLimit);
          });

          if (!(selectedRows && selectedCount > archiveRequestsLimit)) return;

          while (selectedCount > archiveRequestsLimit) {
            gridApi?.deselectNode(selectedRows[selectedCount - 1]);
            selectedCount--;
          }
        }}
        onRowClicked={(e) => {
          const kebabIconsArray = Array.from(document.querySelectorAll("sea-icon.icon--kebab"));
          const eventTarget = e.event?.target;

          if (!kebabIconsArray || (kebabIconsArray && eventTarget && !kebabIconsArray.includes(eventTarget as Element))) {
            props.onRowClicked?.(e);

            const { api } = aggridContext;

            api?.hidePopupMenu();
          }
        }}
      />
    </div>
  );

  async function onDealCaptureBtnClick() {
    const { dialog, dealCapture } = Refactor;

    function getCaptureUrl(role): string {
      if (role === "owner") {
        return actorIsBroker ? captureHrefOwner : `${dealCapture.relpath}?as=${"owner"}`;
      }
      return captureHref.toString();
    }

    function goToDealCapture(e) {
      const role = e.currentTarget.getAttribute("data-role");

      getCaptureUrl(role);

      dialog.close();
      history.push(getCaptureUrl(role));
    }

    if (
      name === "COAs" || // temp fix for #141333
      (actorIsCharterer && !actorIsOwner) ||
      (actorIsBroker && !actorIsOwner && !actorIsCharterer)
    ) {
      history.push(captureHref.toString());
      return;
    } else if (actorIsOwner && !actorIsCharterer) {
      history.push(getCaptureUrl("owner"));
      return;
    }

    await dialog.open(
      <deal-capture-modal data-test="deal-capture-selection-modal" id="deal-capture-modal">
        <title>
          <h2>Deal Capture</h2>
        </title>

        <body-content>
          <menu-list>
            <menu-option-item onClick={goToDealCapture} data-role="charterer" data-test="deal-capture-menu-item-charterer">
              <Icon name="orders" />
              <span>Capture deal as Charterer</span>
              <Icon name="chevron-right" />
            </menu-option-item>
            <menu-option-item
              hidden={!enableOdc}
              onClick={goToDealCapture}
              data-role="owner"
              data-test="deal-capture-menu-item-owner"
            >
              <Icon name="ship" />
              <span>Capture deal as Owner</span>
              <Icon name="chevron-right" />
            </menu-option-item>
          </menu-list>
        </body-content>

        <modal-actions>
          <modal-action-item>
            <Button onClick={dialog.close}>cancel</Button>
          </modal-action-item>
        </modal-actions>
      </deal-capture-modal>
    );
  }

  function getContext() {
    return props.context || defaultAggridContext;
  }
}

function newAggridContext() {
  return new AggridContext();
}

function getRowNodeId(entity: Negotiation | Order) {
  return entity?.id || "MISSING_ORDER_OR_NEGOTIATION_ID";
}

const Observer = observer(BaseGrid);

export { Observer as BaseGrid };

export type KebabMenuAction = {
  label: string;
  icon?: string;
  dataTest: string;
  onClick: (() => Promise<void>) | (() => void);
  disabled?: boolean;
};

export type BulkPanelAction = Omit<KebabMenuAction, "disabled">;

interface Props extends AggridProps {
  selectedEntityId: string;
  entity?: Negotiation | Order;
  entityType: string;
  rowCount?: number;
  totalRowCount?: number;
  isEndReached: boolean;
  headerKebabActions?: KebabMenuAction[];
  bulkPanelIsVisible?: boolean;
  bulkPanelActions?: BulkPanelAction[];
  closeBulkPanelAction?: () => void;
  noRowsOverlayText?: string;
}
