import React from "react";
import { observer } from "mobx-react";
import { router, Route } from "@/models";
import { NavLink, Spinner } from "@/components";
import "./Sidenav.scss";

function Sidenav() {
  const { active } = router;
  const { sidenav } = active;

  if (!sidenav) return null;

  return <nav className="sidenav">{sidenav?.map(NavLinkHOC)}</nav>;
}

function NavLinkHOC(route: Route) {
  return <NavLinkObserver route={route} key={route.absPath} />;
}

function _NavLink(props: { route: Route }) {
  const { absPath, icon, desc, getMiscInfo, authorised } = props.route;
  const { unseenCount, loading } = getMiscInfo?.() || 0;
  const sup = unseenCount ? <sup>{unseenCount}</sup> : null;
  const spinner = loading ? <Spinner status={{ loading: true }} /> : null;

  if (!authorised) return null;

  return (
    <NavLink to={absPath} icon={icon} key={absPath} title={desc}>
      {sup}
      {spinner}
    </NavLink>
  );
}

const NavLinkObserver = observer(_NavLink);

const Observer = observer(Sidenav);

export { Observer as Sidenav };
