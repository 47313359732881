import React, { useState } from "react";
import { ITimerCount } from "sharedFolder/components/common/interfaces";
import { useTimer } from "../DigitalTimer/useTimer";
import styles from "../DigitalTimer/DigitalTimer.module.scss";

export function TimerWithUnits(props: { endDateTime: string; onTimeExpired: () => void }) {
  const [countDown, setCountDown] = useState<ITimerCount | undefined>();

  useTimer((timeDiff) => {
    setCountDown(timeDiff);
    if (timeDiff.Expired) {
      props.onTimeExpired();
    }
  }, props.endDateTime);

  const formatTime = (countDown: ITimerCount): string => {
    const days = countDown.Days !== "00" ? `${countDown.Days}d` : "";
    const hours = days || countDown.Hours !== "00" ? `${countDown.Hours}h` : "";
    const minutes = days || hours || countDown.Minutes !== "00" ? `${countDown.Minutes}m` : "";
    const seconds = days || hours || minutes || countDown.Seconds !== "00" ? `${countDown.Seconds}s` : "";
    return `${days} ${hours} ${minutes} ${seconds}`;
  };
  if (!countDown) return null;
  if (countDown.Expired) {
    return <span>Expired</span>;
  }
  return <span className={styles.timer}>{formatTime(countDown)}</span>;
}
