import React, { useLayoutEffect, useState, useEffect } from "react";
import moment from "moment";
import classNames from "classnames";
import styles from "./Liftings.module.scss";
import LiftingsView from "./LiftingsView";
import NegotiationTab from "../../NegotiationTab";
import { negotiations as negotiationUpdates } from "../../../dashboard/services/realtime/updates";
import { INegotiationView } from "../../../sharedFolder/Models/Negotiation/INegotiationView";
import {
  ILiftingsCollectionView,
  ILiftingsDisplayValues,
  LiftingRowButtonIcon,
  ILiftingsNegotiation,
} from "../../../sharedFolder/Models/ILiftingsCollectionView";
import LoadingSpinner from "../../../sharedFolder/components/common/LoadingSpinner/LoadingSpinner";
import NegotiationGridAttachmentsIcon from "__legacy/negotiations/components/negotiate/Negotiation/NegotiationAttachments/NegotiationGridAttachmentsIcon";
import { DismissableNotification } from "../../../sharedFolder/components/common/DismissableNotification/DismissableNotification";
import { useDismissableNotification } from "./DismissableNotificationContext";
import { WarningMessage } from "../../../sharedFolder/components/common/WarningMessage/WarningMessage";
import Button from "__legacy/sharedFolder/components/common/Button/Button";
import { getLiftings } from "negotiations/services/getLiftings";
import NegotiationStatusContainer from "sharedFolder/components/common/NegotiationStatusContainer/NegotiationStatusContainer";
import { OrderNegStatus } from "sharedFolder/Models/OrderNegStatus";
import { Subscription } from "rxjs";
import ErrorMessage from "sharedFolder/components/common/ErrorMessage/ErrorMessage";
import { addLifting } from "negotiations/services/addLifting";
import { usePrevious } from "sharedFolder/Utilities/usePrevious";
import { useAuth } from "__legacy/auth-provider";
import { useOrder } from "__legacy/orderNegs/useOrder";

interface IProps {
  orderId: string;
  ctradeUrl: string;
  orderStatus: OrderNegStatus;
}

const findUpdatedNotes = (
  pl: ILiftingsNegotiation[] | undefined,
  cl: ILiftingsNegotiation[] | undefined
): ILiftingsNegotiation[] | null => {
  let updated: ILiftingsNegotiation[] = [];
  if (pl === undefined || cl === undefined) {
    return null;
  }

  const currentLiftings = cl.filter((d) => d.neg);
  const prevLiftings = pl.filter((d) => d.neg);

  currentLiftings
    .filter((d) => d.neg)
    .forEach((currentLifting, index) => {
      const oldNeg = prevLiftings[index]?.neg;
      if (
        oldNeg !== undefined &&
        currentLifting.neg?.details?.offerRepNegotiationNotes.value &&
        oldNeg.details?.offerRepNegotiationNotes.value !== currentLifting.neg?.details?.offerRepNegotiationNotes.value
      ) {
        updated = updated.concat(currentLifting);
      }
    });

  return updated;
};

const Liftings = (props: IProps) => {
  const { orderId, orderStatus, ctradeUrl } = props;
  const { auth } = useAuth();
  const { showNotification } = useDismissableNotification();
  const [liftings, setLiftings] = useState<ILiftingsCollectionView | undefined>(undefined);
  const prevLiftings = usePrevious<ILiftingsNegotiation[]>(liftings?.liftings);
  const [isLoading, setIsLoading] = useState(false);
  const [openPanelId, setOpenPanelId] = useState<string | null>(null);
  const { orderModel: order } = useOrder(ctradeUrl, orderId);

  const orderIsFixed = orderStatus === "Fixed";

  useLayoutEffect(() => {
    let sub: Subscription;
    if (orderIsFixed) {
      setIsLoading(true);
      sub = getLiftings(ctradeUrl, orderId, negotiationUpdates(ctradeUrl, orderId, auth.claims)).subscribe(
        (negotiations) => {
          setLiftings(negotiations);
          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);
          // TODO: Deal with error
          throw error;
        }
      );
    }
    return () => sub && sub.unsubscribe();
  }, [orderIsFixed, orderId, ctradeUrl, auth.claims]);

  useEffect(() => {
    // This useEffect is responsible for comparing new liftings with previous liftings and showing a generic banner in case any of the liftings had an updated note (i.e. Change Request)
    const liftingsWithUpdatedNotes = findUpdatedNotes(prevLiftings, liftings?.liftings);
    if (liftingsWithUpdatedNotes && liftingsWithUpdatedNotes.length) {
      showNotification("CHANGE_REQUESTED");
    }
  }, [liftings, prevLiftings, showNotification]);

  function onAddNew() {
    addLifting(ctradeUrl, orderId, liftings?.updateToken!).subscribe(
      () => {
        // handle successfully adding liftings
      },
      (err) => {
        console.error("error adding new lifting slot", err);
      }
    );
  }

  const isDealCapture = Boolean(order?.details.isDealCapture);
  const canChartererManagedLiftings = liftings?.canChartererManagedLiftings;
  return (
    <div>
      <LiftingNotifications />
      <NegotiationTab orderId={orderId} filters={[]} showFilters={false} />

      <div className={styles.grid}>
        <div className={styles.gridHead}>
          <div className={styles.gridRow}>
            <div className={styles.gridCell}>
              <span>Lifting no.</span>
              <span>Status</span>
            </div>
            <div className={styles.gridCell}>
              <span>Cargo</span>
              <span>Quantity</span>
            </div>
            <div className={styles.gridCell}>
              <span>Cargo nominated</span>
              <span>Broker</span>
            </div>
            <div className={styles.gridCell}>
              <span>Laycan</span>
              <span>Vessel Name</span>
            </div>
            <div className={styles.gridCell}>
              <span>Load Location</span>
              <span>Disc. Location</span>
            </div>
            <div className={styles.gridCell}>&nbsp;</div>
            <div className={styles.gridCell}>&nbsp;</div>
          </div>
        </div>
        <div className={styles.gridBody}>
          {!orderIsFixed ? (
            <div className={styles.warningContainer}>
              <WarningMessage title="Nothing to show yet">
                <p>
                  One you have finished negotiating the COA parent contract, you will be able to manage your liftings from here.
                </p>
              </WarningMessage>
            </div>
          ) : isLoading ? (
            <div className={styles.spinnerContainer} data-test="loading-liftings">
              <LoadingSpinner />
            </div>
          ) : liftings?.liftingStatus === "waiting_for_slots" ? (
            <div className={styles.spinnerContainer} data-test="waiting-for-liftings">
              <LoadingSpinner loadingText="We are creating your Liftings..." />
            </div>
          ) : liftings?.liftingStatus === "slots_ready" && liftings.displayValues !== undefined ? (
            <LiftingsList
              liftings={liftings}
              onAddNew={onAddNew}
              openPanelId={openPanelId}
              setOpenPanelId={setOpenPanelId}
              orderId={orderId}
              isDealCapture={isDealCapture}
              canChartererManagedLiftings={canChartererManagedLiftings!}
            />
          ) : (
            <div className={styles.warningContainer}>
              <ErrorMessage />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

interface ILiftingsListProps {
  liftings: ILiftingsCollectionView;
  setOpenPanelId: (id: string | null) => void;
  onAddNew: () => void;
  openPanelId: string | null;
  orderId: string;
  isDealCapture: boolean;
  canChartererManagedLiftings: boolean;
}

const LiftingsList = (props: ILiftingsListProps) => {
  const { onAddNew, liftings, openPanelId, orderId, setOpenPanelId, isDealCapture, canChartererManagedLiftings } = props;
  return (
    <>
      {liftings.liftings?.map((lifting, index) => (
        <LiftingsRow
          key={lifting.id}
          neg={lifting.neg}
          displayValues={liftings.displayValues!}
          id={lifting.id}
          cargoId={lifting.cargoId}
          setOpenPanel={setOpenPanelId}
          openPanelId={openPanelId}
          updateToken={liftings.updateToken || ""}
          orderId={orderId}
          isDealCapture={isDealCapture}
          canChartererManagedLiftings={canChartererManagedLiftings}
        />
      ))}
      <section className={styles.additionalLiftingContainer}>
        <Button
          dataTest="additional-lifting-btn"
          ariaLabel="additional-lifting"
          type="action"
          icon="add-circle-outline"
          onClick={onAddNew}
        >
          Additional Lifting
        </Button>
      </section>
    </>
  );
};

function lpad(value: string, padding: number): string {
  const zeroes = new Array(padding + 1).join("0");
  return (zeroes + value).slice(-padding);
}

const LiftingsRow = (props: {
  neg?: INegotiationView;
  displayValues: ILiftingsDisplayValues;
  id: string;
  cargoId: string;
  orderId: string;
  updateToken: string;
  openPanelId: string | null;
  setOpenPanel: (id: string | null) => void;
  isDealCapture: boolean;
  canChartererManagedLiftings: boolean;
}) => {
  const liftingRowbuttonIcon: LiftingRowButtonIcon = !props.neg
    ? "play-outline"
    : props.openPanelId === props.id
    ? "visibility-off"
    : "visibility";
  const isSelected = props.openPanelId === props.id;
  const details = props.neg?.details;
  const createdOn = props.neg?.createdOn;

  return (
    <>
      <div
        className={classNames(styles.gridRow, styles.gridRowHoverHighlight, isSelected && styles.selected)}
        key={`key-${props.id}`}
        onClick={() => {
          props.setOpenPanel(props.openPanelId === props.id ? null : props.id);
        }}
      >
        <div className={styles.gridCell}>
          <span>{lpad(props.id, 3)}</span>

          <span>
            {props.neg && (
              <NegotiationStatusContainer
                firmBidExpiresOn={props.neg.firmBidExpiresOn}
                firmOfferExpiresOn={props.neg.firmOfferExpiresOn}
                status={props.neg.status}
                lastUpdatedAction={props.neg.lastUpdatedAction}
                onSubsExpiresOn={props.neg.onSubsExpiresOn}
              />
            )}
          </span>
        </div>
        <div className={styles.gridCell}>
          <span>{props.neg?.details?.cargoType?.offer.shortDisplay || "--"}</span>
          <span>{props.neg?.details?.cargoSize?.offer.summarydisplay || "--"}</span>
        </div>
        <div className={styles.gridCell}>
          <span>{(createdOn && moment(createdOn).format("DD MMM YY")) || "--"}</span>
          <span>{(props.neg?.controllerNames && props.neg?.controllerNames.join(",")) || "--"}</span>
        </div>
        <div className={styles.gridCell}>
          <span>{details?.laycan.bid.shortDisplay || "--"}</span>
          <span>{props.neg?.details?.vessels.filter((x) => x.status === "accepted").pop()?.vesselName}</span>
        </div>
        <div className={styles.gridCell}>
          <span>{props.neg?.details?.loadLocation?.offer.shortDisplay || "--"}</span>
          <span>{props.neg?.details?.dischargeLocation?.offer.shortDisplay || "--"}</span>
        </div>
        <div className={styles.gridCell}>
          <div className={styles.attachmentsWrapper}>
            {props.neg && (
              <NegotiationGridAttachmentsIcon
                negotiation={props.neg}
                orderId={props.neg.orderId}
                orderStatus={props.neg.status}
              />
            )}
          </div>
        </div>
        <div className={styles.gridCell}>
          <Button
            dataTest={`display-lifting-${props.id}`}
            type="flat"
            icon={liftingRowbuttonIcon}
            onClick={() => props.setOpenPanel(props.openPanelId === props.id ? null : props.id)}
          ></Button>
        </div>
      </div>
      {props.id === props.openPanelId && (
        <LiftingsView
          displayValues={props.displayValues}
          id={props.id}
          cargoId={props.cargoId}
          neg={props.neg}
          orderId={props.orderId}
          updateToken={props.updateToken}
          onDismiss={() => props.setOpenPanel(null)}
          isDealCapture={props.isDealCapture}
          canChartererManagedLiftings={props.canChartererManagedLiftings}
        />
      )}
    </>
  );
};

const LiftingNotifications = () => {
  const { notification: displayedNotification, showNotification: toggleNotification } = useDismissableNotification();

  if (displayedNotification.type === "NONE") {
    return <></>;
  }

  if (displayedNotification.type === "CHANGE_REQUESTED") {
    return (
      <div className={styles.dismissableContainer}>
        <DismissableNotification
          onClose={() => toggleNotification("NONE")}
          backgroundColor="orange"
          icon="tick"
          title="Changes Requested"
        />
      </div>
    );
  } else if (displayedNotification.type === "ACCEPT_LIFTING") {
    return (
      <div className={styles.dismissableContainer}>
        <DismissableNotification
          onClose={() => toggleNotification("NONE")}
          backgroundColor="green"
          icon="tick"
          title="Vessel Accepted"
          body={`Lifting ${displayedNotification.params?.liftingId} Confirmed`}
        />
      </div>
    );
  } else if (displayedNotification.type === "REJECT_LIFTING") {
    return (
      <div className={styles.dismissableContainer}>
        <DismissableNotification
          onClose={() => toggleNotification("NONE")}
          backgroundColor="red"
          icon="tick"
          title="Vessel Rejected"
        />
      </div>
    );
  } else if (displayedNotification.type === "DECLARED_CARGO") {
    return (
      <div className={styles.dismissableContainer}>
        <DismissableNotification
          onClose={() => toggleNotification("NONE")}
          backgroundColor="blue"
          icon="lightbulb-outline"
          title="Cargo Declared"
          body="The cargo details have been sent to the owner, it is now their turn to respond."
        />
      </div>
    );
  } else if (displayedNotification.type === "CAPTURED_CARGO") {
    return (
      <div className={styles.dismissableContainer}>
        <DismissableNotification
          onClose={() => toggleNotification("NONE")}
          backgroundColor="blue"
          icon="lightbulb-outline"
          title="Cargo Captured"
          body="The cargo details have been captured."
        />
      </div>
    );
  } else if (displayedNotification.type === "WITHDRAW_LIFTING") {
    return (
      <div className={styles.dismissableContainer}>
        <DismissableNotification
          onClose={() => toggleNotification("NONE")}
          backgroundColor="blue"
          icon="lightbulb-outline"
          title="Lifting Withdrawn"
        />
      </div>
    );
  } else {
    return <></>;
  }
};

export default Liftings;
