import { makeAutoObservable, observable } from "mobx";
import { DialogProps, DialogAction } from "@/components";

export class Dialog {
  constructor() {
    this.open = false;
    this.props = undefined;
    this.promise = undefined;
    this.resolve = undefined;

    makeAutoObservable(this, {
      props: observable.ref,
      resolve: false,
    });
  }

  show = (props: DialogProps) => {
    this.open = true;
    this.props = props;
    this.promise = new Promise<DialogResult>((resolve) => {
      this.resolve = resolve;
    });

    return this.promise;
  };

  hide = () => {
    if (this.resolve) this.resolve({});

    this.open = false;
    this.props = undefined;
    this.promise = undefined;
    this.resolve = undefined;
  };
}

export const dialog = new Dialog();
export const dialog0 = new Dialog();

export interface Dialog {
  open: boolean;
  props?: DialogProps;
  resolve?: Resolve;
  promise: Promise<DialogResult> | undefined;
}

type Resolve = (value: DialogResult) => void;

type DialogResult = { res?: any; action?: DialogAction };
