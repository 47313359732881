import React, { useRef, useState } from "react";
import { useShallowStateOld, emptyFn } from "@/utils";
import { tradeAPI } from "@/apis";
import { Account } from "@/models";
import { SelectField, SelectFieldProps, InputFocusEvent } from "@/components";

export function AccountEditor(props: Props) {
  props = { ...props };

  props.className = `${props.className} Account`;
  props.dataTest = `${props.dataTest}.value`;

  const { searchMessages = defaultSearchMessages, legalEntitiesOnly = false } = props;
  const [data, setData] = useState<SelectFieldProps["data"]>(undefined);
  const [, setDropdownStatus, dropdownStatus] = useShallowStateOld<Status>({
    message: searchMessages?.initial,
  });

  const cancel = useRef(emptyFn);

  async function onFilter(search: string) {
    cancel.current();

    setData(undefined);
    setDropdownStatus({ message: undefined, loading: false, search });

    if (!search) {
      setDropdownStatus({ message: searchMessages?.initial });

      return;
    }

    setDropdownStatus({ message: searchMessages?.searching, loading: true });

    const promise = tradeAPI.searchAccounts(search, legalEntitiesOnly);

    cancel.current = promise.cancel;

    const res = await promise;

    if (res.cancelled) return;

    if (!res.data || !res.data.length) {
      setData(undefined);
      setDropdownStatus({ message: searchMessages?.noData, loading: false });

      return;
    }

    const data = res.data.map((el) => new Account(el.document));

    setData(data);
    setDropdownStatus({ message: undefined, loading: false });
  }

  function onBlur(e: InputFocusEvent) {
    cancel.current();

    setData(undefined);
    setDropdownStatus({
      message: searchMessages?.initial,
      loading: false,
    });

    props.onBlur && props.onBlur(e);
  }

  return (
    <SelectField
      {...props}
      value={props.value}
      data={data}
      placeholder=""
      dropdownStatus={dropdownStatus}
      onFilter={onFilter}
      onBlur={onBlur}
    />
  );
}

const defaultSearchMessages = {
  initial: "Please enter a charterer's name",
  searching: "Searching for charterers",
  noData: "No charterers found",
} as Props["searchMessages"];

interface Props extends SelectFieldProps {
  legalEntitiesOnly?: boolean;
  searchMessages?: {
    initial: string;
    searching: string;
    noData: string;
  };
}

export type AccountEditorProps = Props;
