import React, { useState } from "react";
import styles from "./NegotiationNotes.module.scss";
import { Role } from "../../Role";
import MoreLessChevronButton from "../../../../common/MoreLessChevronButton";
import { INegotiationCommonView } from "sharedFolder/Models/Negotiation/INegotiationCommonView";
import { IGetView } from "sharedFolder/Models/Negotiation/IGetView";
import { NegotiationNoteDetail } from "./NegotiationNoteDetail";

interface INotesProps {
  /*
   * Will be called when Note panel has been opened
   */
  openNotePanel: (role: Role) => void;
  /**
   * The full negotiation
   */
  negotiation: IGetView<INegotiationCommonView>;
  /**
   * Status of the neg so that we can disable the controls when the neg is 'firm'
   */
  status: "indication" | "firmIndication" | "firm";
}

export const NegotiationNotes: React.FC<INotesProps> = (props: INotesProps) => {
  const {
    details: { offerRepNegotiationNotes: offerRepNotes, controllerNegotiationNotes: controllerNotes } = {
      offerRepNegotiationNotes: { value: null, timestamp: null },
      controllerNegotiationNotes: { value: null, timestamp: null },
    },
  } = props.negotiation;

  const [offerRepTimestamp, setOfferRepTimestamp] = useState<string | null>(offerRepNotes.timestamp);

  if (!controllerNotes.timestamp && !offerRepNotes.timestamp) {
    return null;
  }

  const statusAttribute = offerRepTimestamp !== offerRepNotes.timestamp ? { status: "New" } : {};

  const onOpenNotePanel = (role: Role) => {
    props.openNotePanel(role);
    // Set the timestamp to the current timestamp in the props,
    // this will make sure the 'New' message disappears from the page
    setOfferRepTimestamp(offerRepNotes.timestamp);
  };

  return (
    <div className={styles.moreOrLess}>
      <MoreLessChevronButton
        openText="NOTES"
        closeText="NOTES"
        onToggle={(isOpen: boolean) => setOfferRepTimestamp(offerRepNotes.timestamp)}
        {...statusAttribute}
      >
        <div className={styles.container}>
          <NegotiationNoteDetail
            noteWriter={props.negotiation.controllerNames.join(",")}
            timestamp={controllerNotes.timestamp}
            openNotePanel={onOpenNotePanel}
            ownerOrCharterer={"charterer"}
            isDisabled={props.negotiation.isReadOnly}
          >
            {controllerNotes.value || ""}
          </NegotiationNoteDetail>
          <NegotiationNoteDetail
            noteWriter={props.negotiation.invitee}
            timestamp={offerRepNotes.timestamp}
            openNotePanel={onOpenNotePanel}
            ownerOrCharterer="owner"
            isDisabled={props.negotiation.isReadOnly}
          >
            {offerRepNotes.value || ""}
          </NegotiationNoteDetail>
        </div>
      </MoreLessChevronButton>
    </div>
  );
};
