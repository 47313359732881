import { config } from "___REFACTOR___/config";
import { Vessel } from "___REFACTOR___/models";
import { auth } from "___REFACTOR___/services";
import { Button } from "___REFACTOR___/components";
import "./VesselButton.scss";
import { NegotiationTypeEvents, usageMetrics } from "@/services/UsageMetrics";

export function VesselButton(props: IProps) {
  const { vessel, seaAnalyticsOrSeaNet } = props;

  const seaNetUrl = `${config.vesselViewUrl}/${vessel?.vesselIMO}?token=${auth.central.token}`;
  const seaAnalyticsUrl = `${config.vesselActivityUrl}${vessel.vesselIMO}`;
  const href = seaAnalyticsOrSeaNet === "Net" ? seaNetUrl : seaAnalyticsUrl;

  return (
    <Button
      onClick={() => {
        usageMetrics.trackEvent(
          seaAnalyticsOrSeaNet === "Net"
            ? NegotiationTypeEvents.NOMINATE_VESSEL_SEA_NET
            : NegotiationTypeEvents.NOMINATE_VESSEL_SEA_ANALYTICS
        );
      }}
      href={href}
      className={seaAnalyticsOrSeaNet === "Analytics" ? "btn-app-analytics" : "btn-app-net"}
      dataTest={`vessel.${seaAnalyticsOrSeaNet === "Analytics"}`}
      hidden={!vessel.vesselIMO || !auth.trade.user?.SystemUserId}
      openHrefInNewWindow
      disabled={!vessel.vesselIMO}
    />
  );
}

interface IProps {
  vessel: Vessel;
  seaAnalyticsOrSeaNet: "Analytics" | "Net";
}
