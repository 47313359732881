async function monitorChange<Value>(
  predicate: monitorChange.Predicate<Value>,
  valueProvider: monitorChange.ValueProvider<Value>,
  config?: monitorChange.Config
) {
  config = { ...defaultConfig, ...config };

  const { pollInterval } = config;

  let resolvePollPromise: (result: Result) => void;
  const pollPromise = new Promise<Result>((resolve) => (resolvePollPromise = resolve));

  let resolveTimeoutPromise: (result: Result) => void;
  const timeoutPromise = new Promise<Result>((resolve) => (resolveTimeoutPromise = resolve));

  window.setTimeout(timeout, config.timeout);

  const intervalId = window.setInterval(poller, pollInterval);

  return await Promise.race([pollPromise, timeoutPromise]);

  async function poller() {
    const value = await valueProvider();
    const bool = predicate(value);

    if (bool) {
      window.clearInterval(intervalId);
      resolvePollPromise({ value });
    }
  }

  function timeout() {
    window.clearInterval(intervalId);
    resolveTimeoutPromise({ timedout: true });
  }

  /* TYPES */

  type Result = monitorChange.Result<Value>;
}

const defaultConfig = {
  pollInterval: 100,
  timeout: 10000,
} as monitorChange.Config;

export { monitorChange };

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

declare namespace monitorChange {
  export interface Config {
    pollInterval?: number;
    timeout?: number;
  }

  export type ValueProvider<Value> = () => Promise<Value>;
  export type Predicate<Value> = (value: Value) => boolean;
  export interface Result<Value> {
    value?: Value;
    timedout?: boolean;
  }
}
