import * as React from "react";
import classNames from "classnames";
import uniqueId from "lodash-es/uniqueId";
import styles from "__legacy/sharedFolder/styles/form-styles.module.scss";

interface ITextAreaProps extends React.HTMLProps<HTMLTextAreaElement> {
  ariaLabel?: string | "";
  dataKey?: string;
  dataTest?: string;
  ref?: any;
  focus?: boolean;
  isValid?: boolean;
}

class TextArea extends React.PureComponent<ITextAreaProps> {
  private textareaRef: React.RefObject<HTMLTextAreaElement> = React.createRef();

  static defaultProps = {
    isValid: true,
  };

  private focusInput = () => {
    if (this.textareaRef && this.textareaRef.current) {
      this.textareaRef.current.focus();
    }
  };

  public componentDidMount() {
    if (this.props.focus) {
      this.focusInput();
    }
  }

  componentDidUpdate(prevProps: ITextAreaProps & React.HTMLProps<HTMLTextAreaElement>) {
    if (this.props.focus !== prevProps.focus) {
      this.focusInput();
    }
  }

  render() {
    const labelClasses = classNames(styles.label, this.props.required ? styles.mandatory : null);
    const textAreaClasses = classNames(this.props.className, styles.textarea, !this.props.isValid ? styles.error : null);
    const id = this.props.id ? this.props.id : uniqueId("TextArea-");

    const textAreaProps = {
      ...this.props,
      "aria-label": this.props.ariaLabel,
      "data-key": this.props.dataKey,
      "data-test": this.props.dataTest,
    };
    delete textAreaProps.ariaLabel;
    delete textAreaProps.dataKey;
    delete textAreaProps.dataTest;
    delete textAreaProps.focus;
    delete textAreaProps.isValid;

    return (
      <div className={styles.inputWrapper}>
        {this.props.label && <span className={labelClasses}>{this.props.label}</span>}
        <textarea data-component="<TextArea />" {...textAreaProps} className={textAreaClasses} ref={this.textareaRef} id={id} />
      </div>
    );
  }
}

export default TextArea;
