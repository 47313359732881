import React from "react";
import classNames from "classnames";
import Slider, { SliderProps } from "@material-ui/core/Slider";
import { InputProps } from "../../Input";
import { Container } from "../../Container";
import "./Range.scss";

export function RangeInput(props: RangeInputProps) {
  props = { ...props };

  props.className = classNames(props.className, "range");

  const { min, max, value, step } = props;

  function onChange(e, value) {
    props.onChange?.(value);
  }

  return (
    <Container {...props}>
      <Slider value={value} onChange={onChange} min={min} max={max} step={step} aria-labelledby="range-slider" />
    </Container>
  );
}

export interface RangeInputProps extends InputProps<SliderProps["value"]> {
  min?: SliderProps["min"];
  max?: SliderProps["max"];
  step?: SliderProps["step"];
}
