import { IUnitValue, IUnitValueAPI } from "./IUnitValue";
import { IKeyValuePair } from "./IKeyValuePair";
import { IUnitValueView } from "./IDetails";

export type SupplyPriceUnit = "PerDay" | "LumpSum" | "PerMT";

export type ISupplyPrice = IUnitValue<SupplyPriceUnit>;

export const SupplyPriceUnitDisplay: { [key in SupplyPriceUnit]: string } = {
  PerDay: "Per Day",
  LumpSum: "Lump Sum",
  PerMT: "Per MT",
};

export const SupplyPriceUnitShortDisplay: { [key in string]: string } = {
  PerDay: " / Day",
  LumpSum: "LS",
  PerMT: "M/T",
};

export const SupplyPriceOptions: IKeyValuePair[] = [
  { key: "LumpSum", value: "Lump Sum" },
  { key: "PerDay", value: "Per Day" },
  { key: "PerMT", value: "Per MT" },
];

export const mapStringToSupplyPriceUnit = (unit?: string): SupplyPriceUnit | undefined => {
  if (!unit) {
    return undefined;
  }

  switch (unit.toLowerCase()) {
    case "perday":
      return "PerDay";
    case "lumpsum":
      return "LumpSum";
    case "permt":
      return "PerMT";
    default:
      return undefined;
  }
};

export const mapSupplyPriceViewToISupplyPrice = (item: IUnitValueView | IUnitValueAPI | undefined): ISupplyPrice | undefined => {
  if (!item || (item.unit === undefined && item.value === undefined)) {
    return undefined;
  }

  return {
    unit: mapStringToSupplyPriceUnit(item.unit),
    value: Number(item.value) || undefined,
    notes: item.notes ? item.notes : "",
  };
};
