import { TradeAPI } from "___REFACTOR___/apis";
import { DataModel } from "___REFACTOR___/models/DataModel";

class Entity extends DataModel {
  static upsertChild(entity: Entity, Child: typeof Entity, seed: Entity.Seed) {
    let child = entity.childMap[seed.id];

    if (!child) {
      child = new Child(seed);

      entity.childMap[seed.id] = child;
      entity.childArr.push(child);
    }

    return child;
  }

  static toggle(entity: Entity) {
    const { selection } = entity;

    if (selection.state === "unselected" || selection.state === "indeterminate") selection.select();
    //
    else if (selection.state === "selected") selection.deselect();
  }

  static select(entity: Entity) {
    const { selection } = entity;

    selection.state = "selected";

    entity.forEachChild((child) => {
      child.selection.state = "selected";
    });

    entity.forEachParent((parent) => {
      let indeterminateChild: Entity | undefined;
      let selectedChildCount = 0;

      parent.childArr.forEach((child) => {
        if (child.selection.state === "indeterminate") indeterminateChild = child;
        if (child.selection.state === "selected") selectedChildCount++;
      });

      if (indeterminateChild) parent.selection.state = "indeterminate";
      //
      else if (selectedChildCount === parent.childArr.length) parent.selection.state = "selected";
      //
      else if (selectedChildCount) parent.selection.state = "indeterminate";
    });
  }

  static deselect(entity: Entity) {
    const { selection } = entity;

    selection.state = "unselected";

    entity.forEachChild((child) => {
      child.selection.state = "unselected";
    });

    entity.forEachParent((parent) => {
      let indeterminateChild: Entity | undefined;
      let selectedChildCount = 0;

      parent.childArr.forEach((child) => {
        if (child.selection.state === "indeterminate") indeterminateChild = child;
        if (child.selection.state === "selected") selectedChildCount++;
      });

      if (indeterminateChild) parent.selection.state = "indeterminate";
      //
      else if (!selectedChildCount) parent.selection.state = "unselected";
      //
      else if (selectedChildCount) parent.selection.state = "indeterminate";
    });
  }

  constructor(seed) {
    super(seed);

    this.childMap = {};
    this.childArr = [];
    this.selection = {
      state: "unselected",
      toggle: Entity.toggle.bind(null, this),
      select: Entity.select.bind(null, this),
      deselect: Entity.deselect.bind(null, this),
    };
  }

  get path() {
    const parentPath = this.parent?.path || "";

    return `${parentPath}/${this.id}`;
  }

  isChildOf(entity: Entity) {
    if (this.parent === entity) return true;

    let grandparent = entity.parent as Entity | undefined;

    while (grandparent) {
      if (grandparent === entity) return true;

      grandparent = grandparent.parent;
    }

    return false;
  }

  /**
   * Returns the child in the tree where predicate is true, and undefined
   * otherwise.
   * @param predicate findChild calls predicate once for each child in the tree, in ascending
   * order, until it finds one where predicate returns true. If such a child is found, findChild
   * immediately returns that child. Otherwise, findChild returns undefined.
   */
  findChild(predicate: Method.FindChild.Predicate) {
    for (let i = 0; i < this.childArr.length; i++) {
      const child = this.childArr[i];

      if (predicate(child)) return child;

      if (child.findChild(predicate)) return child;
    }
  }

  /**
   * Performs the specified action for each parent in the tree.
   * @param callback forEach calls the callback function one time for each parent in the tree.
   */
  forEachParent(callback: Method.ForEachParent.Callback, config?: Method.GetAllParentArr.Config) {
    const arr = this.getAllParentArr(config);

    arr.forEach(callback);
  }

  /**
   * Performs the specified action for each child in the tree.
   * @param callback forEach calls the callback function one time for each child in the tree.
   */
  forEachChild(callback: Method.ForEachChild.Callback) {
    const arr = this.getAllChildArr();

    arr.forEach(callback);
  }

  getAllParentArr(config?: Method.GetAllParentArr.Config) {
    config = { direction: "ascending", ...config };

    const { direction } = config;

    let res = [this.parent] as Entity[];

    let grandparent = this.parent.parent;

    while (grandparent) {
      if (direction === "ascending") res = [...res, grandparent];
      if (direction === "descending") res = [grandparent, ...res];

      grandparent = grandparent.parent;
    }

    return res;
  }

  getAllChildArr(res = [] as Entity[]) {
    for (let i = 0; i < this.childArr.length; i++) {
      const child = this.childArr[i];

      res.push(child);

      child.getAllChildArr(res);
    }

    return res;
  }

  getUserArr(res = [] as User[]) {
    if (this instanceof User) return [this];

    for (let i = 0; i < this.childArr.length; i++) {
      const child = this.childArr[i];

      if (child instanceof User) res.push(child);
      else child.getUserArr(res);
    }

    return res;
  }
}

class Company extends Entity {
  constructor(seed: Company.Seed) {
    super(seed);
  }

  upsertChild(seed: Location.Seed) {
    return Entity.upsertChild(this, Location, seed) as Location;
  }

  initFromFullData() {
    const { locations = [], divisions = [], desks = [] } = this;

    for (let i = 0; i < locations.length; i++) {
      const location = locations[i];

      this.upsertChild({ ...location, parent: this, clddu: this.clddu, company: this });
    }

    for (let i = 0; i < divisions.length; i++) {
      const division = divisions[i];
      const location = this.childMap[division.locationId];

      location.upsertChild({ ...division, parent: location, clddu: this.clddu, company: this, location });
    }

    for (let i = 0; i < desks.length; i++) {
      const desk = desks[i];
      const location = this.childMap[desk.locationId];
      const division = location.childMap[desk.divisionId];

      division.upsertChild({ ...desk, parent: division, clddu: this.clddu, company: this, location, division });
    }
  }
}

class Location extends Entity {
  constructor(seed: Location.Seed) {
    super(seed);
  }

  upsertChild(seed: Division.Seed) {
    return Entity.upsertChild(this, Division, seed) as Division;
  }
}

class Division extends Entity {
  constructor(seed: Division.Seed) {
    super(seed);
  }

  upsertChild(seed: Desk.Seed) {
    return Entity.upsertChild(this, Desk, seed) as Desk;
  }
}

class Desk extends Entity {
  constructor(seed: Desk.Seed) {
    super(seed);
  }

  upsertChild(seed: User.Seed) {
    return Entity.upsertChild(this, User, seed) as User;
  }
}

class User extends Entity {
  constructor(seed: User.Seed) {
    super(seed);
  }
}

class Clddu extends Entity {
  static Company = Company;
  static Location = Location;
  static Division = Division;
  static Desk = Desk;
  static User = User;

  constructor() {
    super({});
  }

  upsertChild(seed: Company.Seed) {
    return Entity.upsertChild(this, Company, seed) as Company;
  }

  upsertUser(data: TradeAPI.Clddu.User) {
    const { companyId, locationId, divisionId, deskId, userId } = data;
    const { companyName, locationName, divisionName, deskName, userName } = data;

    const company = this.upsertChild({ id: companyId, name: companyName, parent: this, clddu: this });
    const location = company.upsertChild({
      companyId,
      id: locationId,
      name: locationName,
      parent: company,
      clddu: this,
      company,
    });
    const division = location.upsertChild({
      companyId,
      locationId,
      id: divisionId,
      name: divisionName,
      parent: location,
      clddu: this,
      company,
      location,
    });
    const desk = division.upsertChild({
      companyId,
      locationId,
      divisionId,
      id: deskId,
      name: deskName,
      parent: division,
      clddu: this,
      company,
      location,
      division,
    });
    const user = desk.upsertChild({
      ...data,
      id: userId,
      name: userName,
      parent: desk,
      clddu: this,
      company,
      location,
      division,
      desk,
    });
  }
}

export { Clddu, Company, Location, Division, Desk, User, Entity };

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */

interface Entity extends Entity.Data {
  selection: Entity.Selection;
  parent: Entity;
  childArr: Entity[];
  childMap: { [id: string]: Entity };
}
declare namespace Entity {
  type Data = TradeAPI.Clddu.Entity;

  type Seed = PartialExcept<Data, "id" | "name">;

  interface Selection {
    state: Selection.State;
    toggle(): void;
    select(): void;
    deselect(): void;
  }
  namespace Selection {
    type State = "selected" | "indeterminate" | "unselected";
  }
}

declare namespace Method {
  namespace FindChild {
    type Predicate = (entity: Entity) => any;
  }

  namespace GetAllParentArr {
    interface Config {
      direction: "ascending" | "descending";
    }
  }

  namespace ForEachParent {
    type Callback = (entity: Entity, i: number, arr: Entity[]) => void;
  }

  namespace ForEachChild {
    type Callback = (entity: Entity, i: number, arr: Entity[]) => void;
  }
}

interface Clddu {
  childArr: Company[];
  childMap: { [id: string]: Company };
}
declare namespace Clddu {
  export { Entity };
}

interface Company extends Company.Data {
  parent: Clddu;
  childArr: Location[];
  childMap: { [id: string]: Location };

  clddu: Clddu;
}

declare namespace Company {
  type Data = TradeAPI.Clddu.Company;
  interface Seed extends PartialExcept<Data, "id" | "name"> {
    parent: Clddu;

    clddu: Clddu;
  }
}

interface Location extends Location.Data {
  parent: Company;
  childArr: Division[];
  childMap: { [id: string]: Division };

  clddu: Clddu;
  company: Company;
}
declare namespace Location {
  type Data = TradeAPI.Clddu.Location;
  interface Seed extends PartialExcept<Data, "id" | "name"> {
    parent: Company;

    clddu: Clddu;
    company: Company;
  }
}

interface Division extends Division.Data {
  parent: Location;
  childArr: Desk[];
  childMap: { [id: string]: Desk };

  clddu: Clddu;
  company: Company;
  location: Location;
}
declare namespace Division {
  type Data = TradeAPI.Clddu.Division;
  interface Seed extends PartialExcept<Data, "id" | "name"> {
    parent: Location;

    clddu: Clddu;
    company: Company;
    location: Location;
  }
}

interface Desk extends Desk.Data {
  parent: Division;
  childArr: User[];
  childMap: { [id: string]: User };

  clddu: Clddu;
  company: Company;
  location: Location;
  division: Division;
}
declare namespace Desk {
  type Data = TradeAPI.Clddu.Desk;
  interface Seed extends PartialExcept<Data, "id" | "name"> {
    parent: Division;

    clddu: Clddu;
    company: Company;
    location: Location;
    division: Division;
  }
}

interface User extends User.Data {
  parent: Desk;

  clddu: Clddu;
  company: Company;
  location: Location;
  division: Division;
  desk: Desk;
}
declare namespace User {
  type Data = TradeAPI.Clddu.User;

  interface Seed extends PartialExcept<Data, "id" | "name"> {
    parent: Desk;

    clddu: Clddu;
    company: Company;
    location: Location;
    division: Division;
    desk: Desk;
  }
}
