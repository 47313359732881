import { object, string } from "yup";
import { CommaSeparatedValues } from "___REFACTOR___/models/common";
import { EmailList } from "___REFACTOR___/models/Trade/EmailList";

function createSchema() {
  // https://github.com/jquense/yup
  const schema = object({
    emailList: EmailList.create.schema(),
    commaSeparatedValues: CommaSeparatedValues.get.schema(string().email("Invalid email").required("Cannot be empty")),
  });

  return schema;
}

export { createSchema };
