import { useEffect, useRef } from "react";
import classNames from "classnames";
import * as ClassNames from "classnames";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Status } from "___REFACTOR___/models";
import "./StatusMessages.scss";

export function StatusMessages(props: Props) {
  const statuses = extractStatuses(props.status);
  const className = classNames(props.className);

  return (
    <app-status-messages class={className}>
      <TransitionGroup component={null}>{MessagesHOC(statuses, props)}</TransitionGroup>
    </app-status-messages>
  );
}

function MessagesHOC(statuses: Status[], props: Props) {
  const { status, hidden } = props;

  if (!status || hidden) return null;

  const messages = statuses.map(MessageHOC.bind(null, props));

  return messages;
}

function MessageHOC(props: Props, status: Status) {
  const key = "" + status.type + status.message;
  const timeout = { enter: 0, appear: 250, exit: 250 };

  return (
    <CSSTransition timeout={timeout} classNames="visibility" key={key}>
      <Message {...props} status={status} />
    </CSSTransition>
  );
}

function Message(props: Props) {
  const ref = useRef<HTMLElement>(null);

  useEffect(onStatusChange, [!props.status?.type]);

  if (!props.status?.message) return null;

  const { status } = props;
  const { type, message } = status;
  const className = classNames(type);

  return (
    <app-status-message class={className} ref={ref}>
      {message}
    </app-status-message>
  );

  function onStatusChange() {
    if (props.status?.type === "error") {
      ref.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    }
  }
}

function extractStatuses(status: Status | undefined, res?: Status[]) {
  res = res || [];

  if (!status) return res;

  const { childMap } = status;

  res.push(status);

  if (!childMap) return res;

  const statuses = Object.values(childMap);

  for (let i = 0; i < statuses.length; i++) {
    const status = statuses[i];

    extractStatuses(status, res);
  }

  return res;
}

interface Props {
  className?: ClassNames.Argument;
  hidden?: boolean;
  status?: Status;
  dataTest?: string;
}
