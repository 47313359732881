import { DateField } from "___REFACTOR___/components";
import dayjs, { isDayjs } from "dayjs";
import { DataModel, DataModelProps } from "../DataModel";

class CustomDate extends DataModel {
  constructor(data?: PartialData) {
    super(data);

    this.resolveDate();
  }

  resolveDate() {
    const { value } = this;

    if (!value) return;

    if (typeof value === "string") this.date = new Date(value);

    if (value instanceof Date) this.date = value;

    if (isDayjs(value)) this.date = value.toDate();
  }

  toString() {
    const { unitMap, value, withoutTimezone, startOfDay } = this;
    let res = dayjs();

    if (typeof value === "string") {
      res = dayjs(value);
    }

    if (value instanceof Date) {
      res = dayjs(value);
    }

    if (isDayjs(value)) {
      res = value;
    }

    if (unitMap) {
      const { second, minute, hour, month, year } = unitMap;

      res = res.startOf("day");

      if (typeof second === "number") res = res.add(second, "second");
      if (typeof minute === "number") res = res.add(minute, "minute");
      if (typeof hour === "number") res = res.add(hour, "hour");
      if (typeof month === "number") res = res.add(month, "month");
      if (typeof year === "number") res = res.add(year, "year");
    }

    if (withoutTimezone) {
      const year = res.year();
      const month = res.month();
      const date = res.date();
      const hour = res.hour();
      const minute = res.minute();
      const second = res.second();
      const millisecond = res.millisecond();
      const dateObj = { year, month, date, hour, minute, second, millisecond };

      // @ts-ignore -- missing types for objectSupport https://day.js.org/docs/en/plugin/object-support
      res = dayjs.utc(dateObj);
    }

    if (startOfDay) {
      res = res.startOf("day");
    }

    return res.toISOString();
  }

  toJSON() {
    return this.toString();
  }
}

class CustomDateProps extends DataModelProps {
  get Editor() {
    return DateField;
  }
}

CustomDate.prototype.Props = CustomDateProps;

export { CustomDate };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface CustomDate extends Data {
  Props: typeof CustomDateProps;
  _: CustomDateProps;

  date: Date;
}

type PartialData = Partial<Data>;
interface Data {
  value: string | Date | dayjs.Dayjs;
  withoutTimezone: boolean;
  startOfDay: boolean;
  unitMap: {
    second?: number;
    minute?: number;
    hour?: number;
    month?: number;
    year?: number;
  };
}
