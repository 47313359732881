import React from "react";

// type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

/**
 * Bind a React Context consumer to a Component.
 * Returns a new component with the context attached.
 *
 * Assuming that the props of the component are a union of IProps and IContext interfaces
 * e.g. class Component extends React.PureComponent<IProps & IContext> { ... }
 *
 * Usage: const MyComponentWithContext = withContext<IProps, IContext>(Component, Context);
 *
 * @param Component The Component to compliment with a context
 * @param Context The Context with which to extend the component's props
 */
export const withContext =
  <P, C>(
    Component: string | React.ComponentClass<P & C> | React.SFC<P & C>,
    Context: React.Context<C>
  ): ((p: P) => React.ReactElement<P>) =>
  (props: P): React.ReactElement<P> =>
    (<Context.Consumer>{(ctx: C) => <Component {...ctx} {...props} />}</Context.Consumer>) as React.ReactElement<P>;
