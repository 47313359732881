import * as React from "react";
import classNames from "classnames";
import { IInputProps } from "sharedFolder/components/common/interfaces";
import styles from "__legacy/sharedFolder/styles/form-styles.module.scss";

// the sole reason this is a class, is to be able to add side effects (i.e. componentDidMount, componentDidUpdate)
class Input extends React.PureComponent<IInputProps & React.HTMLProps<HTMLInputElement>> {
  static displayName = "Input";
  static defaultProps = {
    isValid: true,
  };

  private inputRef = this.props.inputRef || React.createRef<HTMLInputElement>();

  private focusInput = () => {
    if (this.inputRef && this.inputRef.current) {
      this.inputRef.current.focus();
    }
  };

  public componentDidMount() {
    if (this.props.focus) {
      this.focusInput();
    }
  }

  componentDidUpdate(prevProps: IInputProps & React.HTMLProps<HTMLInputElement>) {
    if (this.props.focus !== prevProps.focus) {
      this.focusInput();
    }
  }

  render() {
    const { ariaLabel, className, dataKey, dataTest, isValid, labelComponent, labelText, type, width } = this.props;
    const styleWidth = width ? styles[width] : "";
    const inputClasses = classNames(styles.input, styleWidth, className, !isValid ? styles.error : null);

    const inputProps = { ...this.props };
    delete inputProps.ariaLabel;
    delete inputProps.dataKey;
    delete inputProps.dataTest;
    delete inputProps.focus;
    delete inputProps.isValid;
    delete inputProps.label;
    delete inputProps.labelText;
    delete inputProps.labelComponent;

    return (
      <div className={styles.inputWrapper}>
        {labelComponent && labelComponent}
        {labelText && <label>{labelText}</label>}
        <input
          data-component="<Input />"
          {...inputProps}
          autoComplete="off"
          aria-label={ariaLabel}
          className={inputClasses}
          data-key={dataKey}
          data-test={dataTest}
          ref={this.inputRef}
          type={type || "text"}
        />
      </div>
    );
  }
}

export default Input;
