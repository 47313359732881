import { LABEL_MAP } from "___REFACTOR___/constants";
import { TradeAPI } from "___REFACTOR___/apis";
import { CleaningPriceEditor } from "___REFACTOR___/components";
import { UnitValue, UnitValueProps } from "../UnitValue";

class CleaningPrice extends UnitValue {
  static label = LABEL_MAP.cleaningPrice;
  static get Editor() {
    return CleaningPriceEditor;
  }

  constructor(data?: PartialData) {
    super(data);
  }
}
class CleaningPriceProps extends UnitValueProps {}

CleaningPrice.prototype.Props = CleaningPriceProps;
CleaningPriceProps.prototype.Editor = CleaningPriceEditor;
CleaningPriceProps.prototype.sortKey = "value";
CleaningPriceProps.prototype.label = LABEL_MAP.cleaningPrice;
CleaningPriceProps.prototype.JSONDefaults = {
  unit: "PerDay",
};
CleaningPriceProps.prototype.unitArr = ["PerMT", "PerDay", "LumpSum"];

export { CleaningPrice, CleaningPriceProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface CleaningPrice extends Data {
  Props: typeof CleaningPriceProps;
  _: CleaningPriceProps;
}

interface CleaningPriceProps {
  sortKey: keyof Data;
  JSONDefaults: Partial<Data>;
  unitArr: TradeAPI.CleaningPrice.Unit[];
}

type PartialData = Partial<Data>;
type Data = TradeAPI.CleaningPrice;
