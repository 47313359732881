import { TextField } from "___REFACTOR___/components";
import { PrimitiveDataModel, PrimitiveDataModelProps } from "../PrimitiveDataModel";

class CustomString extends PrimitiveDataModel {
  constructor(data: Data) {
    super(data);
  }
}

class CustomStringProps extends PrimitiveDataModelProps {}

CustomString.prototype.Props = CustomStringProps;
CustomStringProps.prototype.Editor = TextField;

export { CustomString, CustomStringProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface CustomString {
  Props: typeof CustomStringProps;
  _: CustomStringProps;

  data: Data;
}

type Data = string;
