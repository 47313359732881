import React, { useContext } from "react";
import styles from "./NegotiableItem.module.scss";
import { NegotiationSide } from "sharedFolder/Models/Direction";
import { NegotiableItemContainer } from "./NegotiableItemContainer";
import { OfferRepNegotiationContext } from "../../Contexts/OfferRepNegotiationContext";
import { CirculatedByRole } from "negotiations/components/negotiate/Negotiation/Role";

interface NegotiableItemProps {
  typeTitle: string;
  lastUpdated: NegotiationSide | null;
  circulatedByRole?: CirculatedByRole;
  displayControl?: JSX.Element;
  currentValue?: JSX.Element;
  label?: string;
  children: JSX.Element;
}
interface IOwnerItemProps {
  typeTitle: string;
  label?: string;
  children: JSX.Element;
}

export const NegotiableItem: React.SFC<NegotiableItemProps> = (props) => {
  const { firmOrIndicationState } = useContext(OfferRepNegotiationContext);
  const firmOrIndicationStateDisplay = firmOrIndicationState === "INDICATION_ONLY" ? "INDICATION" : "OFFER";

  return (
    <NegotiableItemContainer
      typeTitle={props.typeTitle}
      lastUpdated={props.lastUpdated}
      circulatedByRole={props.circulatedByRole}
    >
      <div className={styles.content} aria-label="Detail Content">
        <div className={styles.chartererIndication}>
          Charterer&apos;s Indication
          <h3>{props.displayControl && props.displayControl}</h3>
        </div>
        <div className={styles.yourIndication}>
          Your {firmOrIndicationStateDisplay}
          <div>{props.children}</div>
          {props.currentValue && (
            <div className={styles.yourIndicationValues}>
              Current {firmOrIndicationStateDisplay}:<br /> {props.currentValue}
            </div>
          )}
        </div>
      </div>
    </NegotiableItemContainer>
  );
};

export const OwnerOnlyItem: React.SFC<IOwnerItemProps> = (props) => {
  return (
    <NegotiableItemContainer typeTitle={props.typeTitle} lastUpdated={null} hideDirectionalMessage={true}>
      <div className={styles.content} aria-label="Detail Content">
        <div className={styles.yourIndication}>
          {props.label ? props.label : "Your INDICATION"}
          <div>{props.children}</div>
        </div>
      </div>
    </NegotiableItemContainer>
  );
};
