import { vesselScoreAPI, VesselScoreAPI } from "@/apis";

class SectionAccess {
  constructor() {
    this.data = undefined;
  }

  async getData() {
    const res = await vesselScoreAPI.accessRights.get();

    if (res.ok) this.data = res.data;
    else this.data = undefined;

    return res;
  }

  has(type: AccessType) {
    if (type === "Inspections" && this.data?.hasInspectionsAccess) return true;
    if (type === "Sanctions" && this.data?.hasSanctionsAccess) return true;
    return false;
  }

  get hasAccess() {
    return this.data && (this.data.hasInspectionsAccess || this.data.hasSanctionsAccess);
  }

  get isDataReady() {
    return this.data !== undefined;
  }
}

export { SectionAccess };

/* -------------------------------------------------------------------------- */
/*                                    TYPES                                   */
/* -------------------------------------------------------------------------- */
interface SectionAccess {
  data: VesselScoreAPI.AccessRights | undefined;
}

type AccessType = "Inspections" | "Sanctions";
