import * as React from "react";
import styles from "./InfoMessage.module.scss";
import Icon from "__legacy/sharedFolder/components/common/Icon/Icon";

interface IInfoMessageProps {
  title: string;
  message: string;
}
export const InfoMessage: React.SFC<IInfoMessageProps> = (props) => {
  const { title, message, children } = props;
  return (
    <section className={styles.containerWrapper} data-test="info-message">
      <div className={styles.container}>
        <Icon icon="info-outline" className={styles.info} />
        <div className={styles.content}>
          <div className={styles.message}>
            <div className={styles.title}>{title}</div>
            <p>{message}</p>
          </div>
          {children}
        </div>
      </div>
    </section>
  );
};
