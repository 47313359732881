import React from "react";
import { observer } from "mobx-react";
import { ProformaLayout } from "___REFACTOR___/models";
import { proformaLayouts } from "___REFACTOR___/stores";
import { SelectField } from "___REFACTOR___/components";

function _ProformaLayoutEditor(props: ProformaLayoutEditor.Props) {
  const { dataTest } = props;
  const { layouts, layoutsById } = proformaLayouts;
  const layoutId = props.value?.layoutId;
  const value = layoutId ? layoutsById?.[layoutId] : null;

  return (
    <SelectField
      {...props}
      editorType="ProformaLayout"
      data={layouts}
      value={value}
      noDataStatus={noDataStatus}
      dataTest={`${dataTest}.value`}
      filterKey="name"
    />
  );
}

const noDataStatus = {
  message: "No Proforma Layouts found",
};

const ProformaLayoutEditor = observer(_ProformaLayoutEditor);

export { ProformaLayoutEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace ProformaLayoutEditor {
  export interface Props extends SelectField.Props {
    value: Value;
    onChange: (value: Value) => void;
  }

  export type Value = ProformaLayout | undefined;
}
