import React, { useContext } from "react";
import { ICOACargoSizeView } from "sharedFolder/Models/IDetails";
import { NewCOACargoSizeEditor } from "../NewCOACargoSizeEditor/NewCOACargoSizeEditor";
import { ICOACargoSizeEditorProps } from "sharedFolder/components/common/interfaces";
import { isCOACargoSizeValid } from "../../common/fields";
import { COAcargoSizeText } from "sharedFolder/display/cargoSize";
import { ValidationContext } from "sharedFolder/contexts/ValidationContext";

export const COACargoSizeEditor: React.FC<ICOACargoSizeEditorProps> = (props: ICOACargoSizeEditorProps) => {
  const validationContext = useContext(ValidationContext);
  const onChange = (cargoSizeView: ICOACargoSizeView) => {
    if (cargoSizeView) {
      const isValid = isCOACargoSizeValid(cargoSizeView);
      validationContext.fieldValidityChanged(props.id, isValid);
      const valueWithDisplay = {
        ...cargoSizeView,
        display: COAcargoSizeText(cargoSizeView),
      };

      props.onChange(valueWithDisplay);
    }
  };

  return <NewCOACargoSizeEditor {...props} onChange={onChange} />;
};
