import { TradeAPI } from "@/apis";
import { format } from "@/utils";
import { DataModel, DataModelProps } from "../DataModel";
import { CargoSizeEditor } from "@/components";
import { LABELS } from "@/constants";

export class CargoSize extends DataModel<Data> {
  onUpdate() {
    delete this.display;
    delete this.shortDisplay;
  }

  toString() {
    if (!this) return "";

    if (typeof this.variance !== "number" || typeof this.value !== "number" || !this.option) return "";

    const notes = this.notes || "";

    return `${format.number.display(this.value)} / ${this.variance}% / ${this.option} ${notes}`;
  }

  toGridView() {
    if (!this) return "";

    if (typeof this.variance !== "number" || typeof this.value !== "number" || !this.option) return "";

    return `${format.number.display(this.value)} / ${this.variance}% / ${this.option}`;
  }

  isValid() {
    return typeof this.variance === "number" && typeof this.value === "number";
  }
}

export class CargoSizeProps extends DataModelProps<Data> {}

CargoSizeProps.prototype.editor = CargoSizeEditor;
CargoSizeProps.prototype.sortKey = "value";
CargoSizeProps.prototype.label = LABELS["cargoSize"];
CargoSizeProps.prototype.JSONDefaults = {
  option: "MOLOO",
};

CargoSize.prototype._ = new CargoSizeProps();

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

export interface CargoSize extends Data {
  _: CargoSizeProps;
}

type Data = DeepPartial<TradeAPI["CargoSize"]>;
