import { ILocationView } from "sharedFolder/Models/ILocation";
import { getWarrantySafetyInformationAbbreviation } from "negotiations/services/client/search/locationSafetyWarranty";

function locationViewText(value?: ILocationView): string | undefined {
  if (!value || !value.locationId || value.locationId === "") return value?.name || "";

  const warrantySafetyInfo = getWarrantySafetyInformationAbbreviation(value);

  // Some names are coming through with the name set to "NULL" from the backend.
  const name = value && value.name && value.name !== "NULL" ? value.name : "";
  const notes = value && value.notes ? " " + value.notes : "";

  return `${warrantySafetyInfo.length > 0 ? warrantySafetyInfo + " " : ""}${name}${notes}`.trim();
}

export { locationViewText };
