import React from "react";
import styles from "./DistributionListIndividual.module.scss";
import { IDistributionViewModel, DistributionUserRole } from "sharedFolder/Models/IDetails";
import Button from "__legacy/sharedFolder/components/common/Button/Button";
import { useDistribution } from "./DistributionContext";
import LoadingSpinner from "sharedFolder/components/common/LoadingSpinner/LoadingSpinner";
import { RolesSelector } from "./RolesSelector";
import classNames from "classnames";
import { Negotiation } from "@/models";

export const DistributionListIndividual = (props: { user: IDistributionViewModel; deleteOrAddMode: "delete" | "add" }) => {
  const { dispatch, state } = useDistribution();
  const { user } = props;
  const { email } = user;
  const tag = user.loading ? <LoadingSpinner /> : <>{getUserName(user)}</>;
  const isUserDisabled = user.disableUserRow || user.status === "success";

  return (
    <div className={classNames(styles.container, isUserDisabled && styles.dulledStyle)}>
      <div className={styles.nameAndCompany}>
        <h3
          data-test={getDataTest()}
          title={`${user.name} ${props.user.groupEmailAddress ? `added from group: ${props.user.groupEmailAddress}` : ""}`}
        >
          {tag}
        </h3>
        <span>{props.user.companyName}</span>
      </div>
      <div>{props.user.locationName || ""}</div>
      <div>{props.user.divisionName || ""}</div>
      <div>{props.user.deskName || ""}</div>
      <div>{props.user.companyId && "Registered user"}</div>
      <div>
        <RolesSelector
          showValidation={props.deleteOrAddMode === "delete" && state.validationShow !== "NONE"}
          user={props.user}
          role={props.user.role}
          onUpdateRole={(role: DistributionUserRole) =>
            dispatch({
              type: "setSavedUserRole",
              payload: { email, role },
            })
          }
          disabled={isUserDisabled}
        />
      </div>
      <div>
        {props.deleteOrAddMode === "delete" && (
          <Button
            dataTest={`distribution-btn-delete-${props.user.userId || ""}`}
            disabled={isUserDisabled}
            type="flat"
            icon="delete"
            className={styles.rightSideButton}
            onClick={() => dispatch({ type: "removeUser", payload: props.user })}
          ></Button>
        )}
        {props.deleteOrAddMode === "add" && (
          <Button
            dataTest={`distribution-btn-add-${props.user.userId || ""}`}
            disabled={isUserDisabled}
            type="flat"
            icon="add-circle"
            className={styles.rightSideButton}
            onClick={() => dispatch({ type: "addUser", payload: props.user })}
          ></Button>
        )}
      </div>
    </div>
  );

  function getUserName(user: IDistributionViewModel) {
    const absentOwnerName = Negotiation.prototype.getAbsentOwnerDisplay(user.name);
    if (absentOwnerName) {
      return absentOwnerName;
    }
    return user.name;
  }

  function getDataTest() {
    const absentOwnerName = Negotiation.prototype.getAbsentOwnerDisplay(user.name);
    if (absentOwnerName) {
      return `user-name-${absentOwnerName.replaceAll(" ", "-")}`;
    }
    return `user-name-${props.user.email.replace("@", "-") || ""}`;
  }
};

export const DistributionStretchColumn = () => {
  return (
    <div className={styles.stretchColumn}>
      <div></div>
    </div>
  );
};
