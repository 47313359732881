import * as React from "react";
import styles from "./OrderNotesPartValue.module.scss";

interface OrderNotesPartValueProps {
  value?: string;
}

export const OrderNotesPartValue: React.SFC<OrderNotesPartValueProps> = (props) => {
  const value = props.value || "";

  return <span className={styles.content}>{value}</span>;
};
