import { memo } from "react";
import classNames from "classnames";
import { Button, Editor, Label } from "@/components";
import { DataModel } from "@/models";
import { switchTypeForFieldName } from "@/utils/switchFieldType";
import "./Field.scss";
import { NegotiationTypeEvents, usageMetrics } from "@/services/UsageMetrics";

function Field(props) {
  const { element, copyButton, onButtonClick, ...editorProps } = props;

  if (element.readOnly) {
    const { dataTest } = props;
    let value = editorProps.value;
    let label = element?.label;

    if (value instanceof DataModel) {
      label = label || value._.label;
      value = value.toView();
    }

    return (
      <div className="read-only">
        <Label label={label} />
        <div className="read-only-value">{value || "--"}</div>
        {copyButton && (
          <Button
            className="flat-button"
            variant="flat"
            disabled={!value}
            onClick={() => {
              onButtonClick(editorProps?.value);
              usageMetrics.trackEvent(NegotiationTypeEvents.USE_INDICATION, { label: label });
            }}
            dataTest={`${switchTypeForFieldName(label, dataTest)}.use-indication`}
          >
            USE INDICATION
          </Button>
        )}
      </div>
    );
  }

  const Comp = element?._?.editor || Editor;

  if (editorProps) editorProps.className = classNames(element.className, "form-field");

  return <Comp {...editorProps} {...element} value={editorProps?.value} />;
}

const Memo = memo(Field);

export { Memo as Field };
