import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { TradeAPI } from "@/apis";
import { timezones } from "@/models";
import { SelectField, SelectFieldProps } from "@/components";

function TimezoneEditor(props: Props) {
  props = { ...props };

  props.className = classNames(props.className, "Timezone");

  function onChange(timezone: TradeAPI["Timezone"]) {
    props.onChange?.(timezone.id);
  }

  const timezone = timezones.dataById[`${props.value}`];

  return (
    <SelectField
      {...props}
      value={timezone}
      onChange={onChange}
      data={timezones.data}
      dataTest={`${props.dataTest}.value`}
      filterKey="display"
      noDataStatus={noDataStatus}
    />
  );
}

const noDataStatus = { message: "No matching timezones" };

const Observer = observer(TimezoneEditor);

export { Observer as TimezoneEditor };

interface Props extends Omit<SelectFieldProps, "value" | "onChange"> {
  value?: string | null;
  onChange?: (value: string | null) => void;
}
