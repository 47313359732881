import React from "react";
import styles from "./DetailsView.module.scss";
import {
  ICargoTypeView,
  ICargoSizeView,
  ICommissionView,
  INotesView,
  IUnitValueView,
  ILaycanView,
  IAccountView,
  IVesselSizeView,
  IDurationView,
  ICOACargoSizeView,
} from "sharedFolder/Models/IDetails";
import { getWarrantySafetyInformationAbbreviation } from "../../../services/client/search/locationSafetyWarranty";
import { ILocationView } from "sharedFolder/Models/ILocation";
import { IBunkerView } from "sharedFolder/Models/IBunker";
import { cargoSizeText, COAcargoSizeText } from "sharedFolder/display/cargoSize";
import { durationText } from "sharedFolder/display/duration";
import { ISubsTextView } from "api/negotiations/models";

const EmptyDetailDisplay: React.SFC<{}> = () => {
  return <span>--</span>;
};

/**
 * Returns JSX Element for a given account
 * @param item account given
 * @param type type of account, e.g, 'chartererAccount', 'ownerAccount'. This param will be used for the aria-label and if not given account will be used
 */
export const AccountDisplay: React.SFC<{
  item: IAccountView;
  type?: string;
}> = (props) => {
  const { accountName } = props.item;
  const doesDetailExist = accountName;
  return (
    <>
      {doesDetailExist ? (
        <span className={styles.value} aria-label={props.type || `account`}>
          {accountName}
        </span>
      ) : (
        <EmptyDetailDisplay />
      )}
    </>
  );
};

/**
 * Returns JSX Element for a given cargoType
 * @param item cargoType given
 * @param type This param will be used for the aria-label. If not given, cargoType will be used
 */
export const CargoTypeDisplay: React.SFC<{
  item: ICargoTypeView;
  type?: string;
}> = (props) => {
  const { name, notes } = props.item;
  const doesDetailExist = name || notes;
  return (
    <>
      {doesDetailExist ? (
        <span className={styles.value} aria-label={props.type || `cargoType`}>{`${name} ${notes}`}</span>
      ) : (
        <EmptyDetailDisplay />
      )}
    </>
  );
};

/**
 * Returns JSX Element for a given cargoType
 * @param item cargoSize given
 * @param type This param will be used for the aria-label. If not given, cargoSize will be used
 */
export const CargoSizeDisplay: React.SFC<{
  item: ICargoSizeView;
  type?: string;
}> = (props) => {
  const summary = cargoSizeText(props.item);
  return (
    <span className={styles.value} aria-label={props.type || `cargoSize`}>
      {summary ? summary : <EmptyDetailDisplay />}
    </span>
  );
};

export const COACargoSizeDisplay: React.SFC<{
  item: ICOACargoSizeView;
  type?: string;
}> = (props) => {
  const summary = COAcargoSizeText(props.item);
  return (
    <span className={styles.value} aria-label={props.type || `COACargoSize`}>
      {summary ? summary : <EmptyDetailDisplay />}
    </span>
  );
};

/**
 * Returns JSX Element for a given account
 * @param item commission given
 * @param type type of commission, e.g, 'addressCommission', 'brokerCommission'. This param will be used for the aria-label and if not given commission will be used
 */
export const CommissionDisplay: React.SFC<{
  item: ICommissionView;
  type?: string;
}> = (props) => {
  const { value, display } = props.item;
  const doesDetailExist = value;
  return (
    <>
      {doesDetailExist ? (
        <span className={styles.value} aria-label={props.type || `commission`}>
          {display}
        </span>
      ) : (
        <EmptyDetailDisplay />
      )}
    </>
  );
};

/**
 * Returns JSX Element for a given cargoType
 * @param item notes given
 * @param type This param will be used for the aria-label. If not given, notes will be used
 */
export const NotesDisplay: React.SFC<{
  item: INotesView;
  type?: string;
}> = (props) => {
  const { value } = props.item;
  const doesDetailExist = value;
  return (
    <>
      {doesDetailExist ? (
        <span className={styles.value} aria-label={props.type || `notes`}>
          {value}
        </span>
      ) : (
        <EmptyDetailDisplay />
      )}
    </>
  );
};

/**
 * Returns JSX Element for a given cargoType
 * @param item notes given
 * @param type This param will be used for the aria-label. If not given, notes will be used
 */
export const OnSubsTextDisplay: React.SFC<{
  item: ISubsTextView;
  type?: string;
}> = (props) => {
  const { display, value } = props.item;
  const doesDetailExist = value;
  return (
    <>
      {doesDetailExist ? (
        <span className={styles.value} aria-label={props.type || `onSubsText`}>
          {display}
        </span>
      ) : (
        <EmptyDetailDisplay />
      )}
    </>
  );
};

/**
 * Returns JSX Element for a given account
 * @param item unitValue given, e.g, freightRate, demurrage, hireRate, etc
 * @param type type of unitValue, e.g, 'freightRate', 'demurrage'. This param will be used for the aria-label and if not given, unitValue will be used
 */
export const UnitValueDisplay: React.SFC<{
  item: IUnitValueView;
  type?: string;
}> = (props) => {
  const { value, display } = props.item;
  const doesDetailExist = value;

  return (
    <>
      {doesDetailExist || (value !== null && value.toString() === "0") ? (
        <span className={styles.value} aria-label={props.type || `unitValue`}>
          {display}
        </span>
      ) : (
        <EmptyDetailDisplay />
      )}
    </>
  );
};

/**
 * Returns JSX Element for a given cargoType
 * @param item laycan given
 * @param type This param will be used for the aria-label. If not given, laycan will be used
 */
export const LaycanDisplay: React.SFC<{
  item: ILaycanView;
  type?: string;
}> = (props) => {
  const { display } = props.item;
  const doesDetailExist = display;
  return (
    <>
      {doesDetailExist ? (
        <span className={styles.value} aria-label={props.type || `laycan`}>
          {display}
        </span>
      ) : (
        <EmptyDetailDisplay />
      )}
    </>
  );
};

/**
 * Returns JSX Element for a given account
 * @param item location given, e.g, loadLocation, dischargeLocation, etc
 * @param type type of location, e.g, 'loadLocation', 'dischargeLocation'. This param will be used for the aria-label and if not given, location will be used
 */
export const LocationDisplay: React.SFC<{
  item: ILocationView;
  type?: string;
}> = (props) => {
  if (!props.item) return <EmptyDetailDisplay />;
  const { name, country, zone, notes } = props.item;
  const doesDetailExist = name || country || zone || notes;
  const warranties = getWarrantySafetyInformationAbbreviation(props.item);
  return (
    <>
      {doesDetailExist ? (
        <span className={styles.value} aria-label={props.type || `location`}>
          {warranties} {name || country || zone} {notes}
        </span>
      ) : (
        <EmptyDetailDisplay />
      )}
    </>
  );
};

/**
 * Returns JSX Element for a given vessel size
 * @param item vesselSize given,
 * @param type This param will be used for the aria-label and if not given, vesselSize will be used
 */
export const VesselSizeDisplay: React.SFC<{
  item: IVesselSizeView;
  type?: string;
}> = (props) => {
  const { vesselSizeAbbreviation: size, notes } = props.item;
  const doesDetailExist = size || notes;
  return (
    <>
      {doesDetailExist ? (
        <span className={styles.value} aria-label={props.type || `vesselSize`}>
          {size} {notes}
        </span>
      ) : (
        <EmptyDetailDisplay />
      )}
    </>
  );
};

/**
 * Returns JSX Element for a given duration
 * @param item duration given
 * @param type This param will be used for the aria-label and if not given, duration will be used
 */
export const DurationDisplay: React.SFC<{
  item: IDurationView;
  type?: string;
}> = (props) => {
  return (
    <span className={styles.value} aria-label={props.type || `duration`}>
      {durationText(props.item) || <EmptyDetailDisplay />}
    </span>
  );
};

/**
 * Returns JSX Element for a given bunker component
 * @param item bunkerValue given, e.g, bunkerDelivery, bunkerRedelivery
 * @param type type of bunkerValue, e.g, 'bunkerDelivery', 'bunkerRedelivery'. This param will be used for the aria-label and if not given, bunkerField will be used
 */
export const BunkerDisplay: React.SFC<{
  item: IBunkerView;
  type?: string;
}> = (props) => {
  const { notes, display } = props.item;
  const doesDetailExist = notes;

  return (
    <>
      {doesDetailExist || (notes !== null && notes?.toString() === "0") ? (
        <span className={styles.value} aria-label={props.type || `bunkerField`}>
          {display}
        </span>
      ) : (
        <EmptyDetailDisplay />
      )}
    </>
  );
};
