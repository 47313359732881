import { Format } from "negotiations/services/utils/converters";
import { bunkerFieldDisplayFormatter } from "sharedFolder/components/common/fields";
import { IBunker, IBunkerView } from "../../api/negotiations/models";
import { IMapper, withNullable } from "./map";

const map: IMapper<IBunker, IBunkerView> = {
  toApi: (viewModel: IBunkerView) => {
    return {
      price: viewModel.price,
      quantity: viewModel.quantity,
      fuelTypes: viewModel.fuelTypes,
      notes: viewModel.notes,

      display: bunkerFieldDisplayFormatter(viewModel, "", Format.FIXED_TO),
      shortDisplay: viewModel.shortDisplay,
    };
  },
  toView: (apiModel: IBunker) => {
    return {
      price: apiModel.price,
      quantity: apiModel.quantity,
      fuelTypes: apiModel.fuelTypes,
      notes: apiModel.notes || "",

      display: bunkerFieldDisplayFormatter(apiModel, "", Format.FIXED_TO),
      shortDisplay: apiModel.shortDisplay,
    };
  },
  emptyViewModel: {
    price: undefined,
    quantity: undefined,
    fuelTypes: "",
    notes: "",
  },
};

export const mapBunker = withNullable(map);
