export interface ISelectableOption {
  name: string;
  selected: boolean;
}

export enum sortKey {
  createdDate = 1,
  laycan,
  updatedDate,
  invitee,
  bestOffer,
}

export interface IUserSettings {
  updateToken: string;
  settings: IUserOrderPreferences[];
}

export interface IUserOrderPreferences {
  id: string;
  orderId: string;
  filters: ISelectableOption[];
  sort: sortKey;
}

export function defaultUserOrderPreference(orderId: string): IUserOrderPreferences {
  return {
    orderId: orderId,
    id: "",
    sort: sortKey.bestOffer,
    filters: [
      {
        name: "Exact Laycan",
        selected: false,
      },
      { name: "Active", selected: false },
    ],
  };
}
