import React, { createContext } from "react";
import { getNegotiationService } from "../../../services/client/negotiation/NegotiationService";
import { IGetView } from "../../../../sharedFolder/Models/Negotiation/IGetView";
import { switchMap } from "rxjs/operators";
import { Role } from "../../negotiate/Negotiation/Role";
import { AllNegotiableDetails } from "../../negotiate/NegotiableTypes";
import { Observable, empty } from "rxjs";
import { NegotiationSide } from "../../../../sharedFolder/Models/Direction";
import { INegotiationCommonView } from "../../../../sharedFolder/Models/Negotiation/INegotiationCommonView";
import { indication } from "../../../services/indication";
import { requestFirm } from "../../../services/requestFirm";
import { showToCharterer } from "../../../services/showToCharterer";
import { firmed } from "../../../services/firmBid";
import { firmAccepted } from "../../../services/firmAccepted";
import { nominateVessel } from "../../../services/nominateVessel";
import { acceptVessel } from "../../../services/acceptVessel";
import { rejectVessel } from "../../../services/rejectVessel";
import { editVessel } from "../../../services/editVessel";
import { updateNote } from "../../../services/updateNote";
import { setOwningCompany } from "../../../services/setOwningCompany";
import { withdrawNegotiation } from "../../../services/withdrawNegotiation";
import { confirmLifting } from "../../../services/confirmLifting";
import { IAccountView, IVesselView } from "../../../../sharedFolder/Models/IDetails";
import { OrderNegType } from "../../../../sharedFolder/Models/OrderNegType";
import { getNegotiationApiService } from "api/negotiations/Negotiations";
import { getLiftingsApiService } from "api/liftings/Liftings";

interface INegotiationContext {
  indicate: (
    detailTypes: Array<keyof AllNegotiableDetails>,
    details: Partial<AllNegotiableDetails>,
    role: Role
  ) => Observable<{ id: string; version: number }>;
  requestFirm: () => Observable<{ id: string; version: number }>;
  showToCharterer: () => Observable<{ id: string; version: number }>;
  submitFirm: (expiresOn: string, role: Role) => Observable<{ id: string; version: number }>;
  accept: (sideToAccept: NegotiationSide) => Observable<{ id: string; version: number }>;
  removeNegotiationFromOrder: (targetOrderId: string) => void;
  nominateVessel: (vessel: IVesselView) => Observable<{ id: string; version: number }>;
  nominateAcceptVessel: (vessel: IVesselView) => Observable<{ id: string; version: number }>;
  acceptVessel: (vesselImo: string) => Observable<{ id: string; version: number }>;
  editVessel: (vessel: IVesselView) => Observable<{ id: string; version: number }>;
  rejectVessel: (vesselImo: string) => Observable<{ id: string; version: number }>;
  setOwningCompany: (company: IAccountView) => Observable<{ id: string; version: number }>;
  updateNote: (role: Role, note: string) => Observable<{ id: string; version: number }>;
  visibleToCharterer: (visible: boolean) => Observable<{ id: string; version: number }>;
  withdrawNegotiation: () => Observable<{ id: string; version: number }>;
}

const NegotiationContextComponent = createContext<INegotiationContext>({
  indicate: (detailTypes: Array<keyof AllNegotiableDetails>, details: Partial<AllNegotiableDetails>, role: Role) => empty(),
  requestFirm: () => empty(),
  showToCharterer: () => empty(),
  submitFirm: (expiresOn: string, role: Role) => empty(),
  accept: (sideToAccept: NegotiationSide) => empty(),
  removeNegotiationFromOrder: (targetOrderId: string) => {
    /* */
  },
  nominateVessel: (vessel: IVesselView) => empty(),
  nominateAcceptVessel: (vessel: IVesselView) => empty(),
  acceptVessel: (vesselImo: string) => empty(),
  editVessel: (vessel: IVesselView) => empty(),
  rejectVessel: (vesselImo: string) => empty(),
  setOwningCompany: (company: IAccountView) => empty(),
  updateNote: (role: Role, note: string) => empty(),
  visibleToCharterer: (visible: boolean) => empty(),
  withdrawNegotiation: () => empty(),
});

NegotiationContextComponent.displayName = "NegotiationContext";

export const NegotiationContext = NegotiationContextComponent;

/**
 * Handler for abstracting/simplifying service calls limited to a single negotiation.
 */
export function getNegotiationHandler(
  apiUrl: string,
  orderType: OrderNegType,
  neg: IGetView<INegotiationCommonView>
): INegotiationContext {
  // TODO: After replacing all the services, clean up everything from services/client/negotiation/NegotiationService.tsx
  const service = getNegotiationService(apiUrl);
  const handler: INegotiationContext = {
    indicate: (detailTypes: Array<keyof AllNegotiableDetails>, details: Partial<AllNegotiableDetails>, role: Role) => {
      switch (role) {
        case "charterer":
          return indication(apiUrl, neg.orderId, neg.id, detailTypes, neg.updateToken, neg.version, details, "bid");
        case "owner":
          return indication(apiUrl, neg.orderId, neg.id, detailTypes, neg.updateToken, neg.version, details, "offer");
        default:
          return empty();
      }
    },
    withdrawNegotiation: () => {
      return withdrawNegotiation(apiUrl, neg.orderId, neg.id, neg.updateToken);
    },
    requestFirm: () => {
      // TODO: bid is hardcoded here because for the time being only the broker charterer's can request a firm offer, this might change in the future and this property will be dynamic
      return requestFirm(apiUrl, neg.orderId, neg.id, neg.updateToken, "bid");
    },
    showToCharterer: () => {
      return showToCharterer(apiUrl, neg.orderId, neg.id, neg.updateToken);
    },
    submitFirm: (expiresOn: string, role: Role) => {
      switch (role) {
        case "charterer":
          return firmed(apiUrl, neg.orderId, neg.id, expiresOn, neg.updateToken, "bid");
        case "owner":
          return firmed(apiUrl, neg.orderId, neg.id, expiresOn, neg.updateToken, "offer");
        default:
          return empty();
      }
    },
    accept: (sideToAccept: NegotiationSide) => {
      return firmAccepted(apiUrl, neg.orderId, neg.id, neg.updateToken, sideToAccept);
    },

    nominateVessel: (vessel: IVesselView) => {
      return nominateVessel(apiUrl, neg.orderId, neg.id, neg.updateToken, vessel, neg.chartererCompanyId);
    },

    nominateAcceptVessel: (vessel: IVesselView) => {
      // take the current neg and apply the action
      const acceptVesselFunc = getNegotiationApiService(apiUrl, neg.orderId).acceptVessel;

      return nominateVessel(apiUrl, neg.orderId, neg.id, neg.updateToken, vessel, neg.chartererCompanyId).pipe(
        switchMap(() => acceptVessel(apiUrl, neg.orderId, neg.id, neg.updateToken, vessel.vesselIMO || "", acceptVesselFunc))
      );
    },

    removeNegotiationFromOrder: (targetOrderId: string) => {
      service.moveNegotiationToOrder(targetOrderId, neg);
    },

    acceptVessel: (vesselImo: string) => {
      // take the current neg and apply the action
      const acceptVesselFunc = getNegotiationApiService(apiUrl, neg.orderId).acceptVessel;

      return acceptVessel(apiUrl, neg.orderId, neg.id, neg.updateToken, vesselImo, acceptVesselFunc);
    },

    rejectVessel: (vesselImo: string) => {
      // take the current neg and apply the action
      const rejectVesselFunc = getNegotiationApiService(apiUrl, neg.orderId).rejectVessel;

      return rejectVessel(apiUrl, neg.orderId, neg.id, neg.updateToken, vesselImo, rejectVesselFunc);
    },

    editVessel: (vessel: IVesselView) => {
      return editVessel(apiUrl, neg.orderId, neg.id, neg.updateToken, vessel, neg.chartererCompanyId);
    },

    setOwningCompany: (company: IAccountView) => {
      return setOwningCompany(apiUrl, neg.orderId, neg.id, neg.updateToken, company, neg.chartererCompanyId);
    },

    updateNote: (role: Role, note: string) => {
      return updateNote(apiUrl, neg.orderId, neg.id, role, note, neg.updateToken);
    },

    visibleToCharterer: (visible: boolean) => {
      return service.visibleToCharterer(neg.orderId, orderType, neg.id, neg.updateToken, visible);
    },
  };

  return handler;
}

export function getLiftingsHandler(
  apiUrl: string,
  orderUpdateToken: string,
  neg: IGetView<INegotiationCommonView>
): INegotiationContext {
  const negotiationHandler = getNegotiationHandler(apiUrl, "COA", neg);
  const handler: INegotiationContext = {
    ...negotiationHandler,
    acceptVessel: (vesselImo: string) => {
      const confirmLiftingFunc = getLiftingsApiService(apiUrl, neg.orderId).confirmLifting;

      return confirmLifting(
        apiUrl,
        neg.orderId,
        neg.liftingId!,
        neg.liftingCargoId!,
        vesselImo,
        orderUpdateToken,
        confirmLiftingFunc
      );
    },

    rejectVessel: (vesselImo: string) => {
      const rejectVesselFunc = getLiftingsApiService(apiUrl, neg.orderId).rejectVessel;

      return rejectVessel(apiUrl, neg.orderId, neg.id, neg.updateToken, vesselImo, rejectVesselFunc);
    },
  };

  return handler;
}
