import { TradeAPI } from "@/apis";
import { OrderTemplateSearchEditor } from "@/components";
import { cleanValue } from "@/utils";
import { DataModel, DataModelProps } from "../DataModel";
import { ChartererAccount } from "../ChartererAccount";
import { AddressCommission } from "../AddressCommission";
import { BrokerCommission } from "../BrokerCommission";
import { CargoSize } from "../CargoSize";
import { CargoType } from "../CargoType";
import { VesselSize } from "../VesselSize";
import { LoadLocation } from "../LoadLocation";
import { DischargeLocation } from "../DischargeLocation";
import { DeliveryLocation } from "../DeliveryLocation";
import { RedeliveryLocation } from "../RedeliveryLocation";
import { ViaLocation } from "../ViaLocation";
import { Duration } from "../Duration";
import { Nominations } from "../Nominations";
import { Liftings } from "../Liftings";
import { COACargoSize } from "../COACargoSize";
import { COANotes, TCNotes, VoyageNotes } from "../OrderNotes";

export class OrderTemplate extends DataModel<Data> {
  getFormValues() {
    const types = [] as TradeAPI["OrderType"][];

    const { template, orderType } = this;
    let { order, voyage, tct, coa } = template || {};

    order = cleanValue(order);
    voyage = cleanValue(voyage);
    tct = cleanValue(tct);
    coa = cleanValue(coa);

    if (orderType === "std") {
      coa = undefined;

      if (voyage && Object.keys(voyage).length) types.push("Voy");
      if (tct && Object.keys(tct).length) types.push("Tct");
    }

    if (orderType === "coa") {
      voyage = undefined;
      tct = undefined;

      types.push("Coa");
    }

    return {
      types,
      chartererAccount: order?.chartererAccount && new ChartererAccount(order.chartererAccount),
      liftings: coa?.liftings && new Liftings(coa.liftings),
      nominations: coa?.nominations && new Nominations(coa.nominations),
      addressCommission: order?.addressCommission && new AddressCommission(order.addressCommission),
      brokerCommission: order?.brokerCommission && new BrokerCommission(order.brokerCommission),
      cargoType: order?.cargoType && new CargoType(order.cargoType),
      cargoSize: voyage?.cargoSize && new CargoSize(voyage.cargoSize),
      coaCargoSize: coa?.coaCargoSize && new COACargoSize(coa.coaCargoSize),
      dischargeLocation:
        (voyage?.dischargeLocation || coa?.dischargeLocation) &&
        new DischargeLocation(voyage?.dischargeLocation || coa?.dischargeLocation),
      loadLocation: (voyage?.loadLocation || coa?.loadLocation) && new LoadLocation(voyage?.loadLocation || coa?.loadLocation),
      deliveryLocation: tct?.deliveryLocation && new DeliveryLocation(tct.deliveryLocation),
      viaLocation: tct?.viaLocation && new ViaLocation(tct.viaLocation),
      redeliveryLocation: tct?.redeliveryLocation && new RedeliveryLocation(tct.redeliveryLocation),
      duration: tct?.duration && new Duration(tct.duration),
      vesselSize: tct?.vesselSize && new VesselSize(tct.vesselSize),
      voyageNotes: voyage?.notes && new VoyageNotes(voyage.notes),
      tctNotes: tct?.notes && new TCNotes(tct.notes),
      coaNotes: coa?.notes && new COANotes(coa.notes),
    };
  }

  toString() {
    return this.name;
  }
}

class OrderTemplateProps extends DataModelProps<Data> {}

OrderTemplateProps.prototype.editor = OrderTemplateSearchEditor;

OrderTemplate.prototype._ = new OrderTemplateProps();

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

export interface OrderTemplate extends Data {
  _: OrderTemplateProps;
}

type Data = DeepPartial<TradeAPI["OrderTemplate"]>;
