import React from "react";
import { Redirect } from "react-router-dom";
import "./OfferRep.scss";
import { IConfiguration } from "sharedFolder/Configuration";
import { OfferRepOrDashboardContainer } from "./OwnerIndicationForm/OfferRepOrDashboardContainer";
import BlankLoadingPage from "sharedFolder/components/common/BlackLoadingPage/BlackLoadingPage";
import { DashboardLayout } from "./Layouts/DashboardLayout";
import { IOfferRepViewNegotiation } from "negotiations/models/common/IOfferRep";
import { OwnerDashboardService } from "./Contexts/OwnerDashboardService";
import { LiftingsLayout } from "./Layouts/LiftingsLayout";

interface IDashboardProps {
  negotiation: IOfferRepViewNegotiation;
  config: IConfiguration;
}

const Dashboard: React.FC<IDashboardProps> = (props: IDashboardProps) => {
  const { config, negotiation } = props;

  if (negotiation) {
    const ownerDashboardService = OwnerDashboardService(
      config.ctradeUrl,
      negotiation.orderId,
      negotiation.id,
      negotiation.updateToken
    );
    const isLiftings = negotiation.type === "LFT";

    return (
      <OfferRepOrDashboardContainer
        config={config}
        negotiation={negotiation}
        error={false}
        loading={false}
        offerRepNegotiationService={ownerDashboardService}
      >
        {isLiftings ? (
          <>
            {negotiation.status === "Withdrawn" ? (
              <Redirect to="/owner-dashboard" />
            ) : (
              <LiftingsLayout negotiation={negotiation} config={config} />
            )}
          </>
        ) : (
          <DashboardLayout negotiation={negotiation} config={config} />
        )}
      </OfferRepOrDashboardContainer>
    );
  }
  return <BlankLoadingPage />;
};

export default Dashboard;
