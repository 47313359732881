import React from "react";
import LoadingSpinner from "sharedFolder/components/common/LoadingSpinner/LoadingSpinner";
import styles from "./UserProviderLoading.module.scss";

const UserProviderLoading = () => {
  return (
    <div className={styles.spinnerContainer}>
      <LoadingSpinner loadingText="Loading..." />
    </div>
  );
};

export { UserProviderLoading };
