import React, { useRef, useState } from "react";
import { get } from "lodash-es";
import { useShallowStateOld, emptyFn } from "___REFACTOR___/utils";
import { tradeAPI, TradeAPI } from "___REFACTOR___/apis";
import { CLDDU, Status } from "___REFACTOR___/models";
import { SelectField, Field } from "___REFACTOR___/components";

function CLDDUEditor(props: CLDDUEditor.Props) {
  const {
    searchMessageMap = defaultSearchMessages,
    CLDDUSubset = "usersandowners",
    CLDDUQuery,
    CLDDUSelector,
    Constructor = CLDDU,
    dataTest,
    editorType,
  } = props;
  const [data, setData] = useState<CLDDU[] | undefined>(undefined);
  const [, setDropdownStatus, dropdownStatus] = useShallowStateOld<Status>({
    message: searchMessageMap?.initial,
  });
  const cancelRef = useRef(emptyFn);

  return (
    <SelectField
      {...props}
      editorType={editorType}
      onChange={onChange}
      value={props.value}
      data={data}
      dropdownStatus={dropdownStatus}
      onFilter={onFilter}
      onBlur={onBlur}
      dataTest={`${dataTest}.value`}
    />
  );

  async function onFilter(search: string) {
    cancelRef.current();

    setData(undefined);
    setDropdownStatus({ message: undefined, loading: false, search });

    if (!search) {
      setDropdownStatus({ message: searchMessageMap?.initial });

      return;
    }

    setDropdownStatus({ message: searchMessageMap?.searching, loading: true });

    const promise = tradeAPI.searchCLDDUs(CLDDUSubset, search, CLDDUQuery);

    cancelRef.current = promise.cancel;

    const res = await promise;

    if (res.cancelled) return;

    if (!res.data || !res.data.length) {
      setData(undefined);
      setDropdownStatus({ message: searchMessageMap?.noData, loading: false });

      return;
    }

    let data = res.data;

    if (CLDDUSelector) data = res.data.filter(CLDDUSelector);

    const clddus = data.map((el) => new Constructor(el));

    setData(clddus);
    setDropdownStatus({ message: undefined, loading: false });
  }

  function onBlur(e) {
    cancelRef.current();

    setData(undefined);
    setDropdownStatus({
      message: searchMessageMap?.initial,
      loading: false,
    });

    props.onBlur?.(e);
  }

  function onChange(value: CLDDU) {
    if (props?.interceptChangeHandler) value = props.interceptChangeHandler(value);

    if (props?.onChangeSelector && value instanceof CLDDU) {
      value = get(value, props?.onChangeSelector);
    }

    props.onChange?.(value);
  }
}

const defaultSearchMessages = {
  initial: "Please enter name, email or company",
  searching: "Searching for records",
  noData: "No records found",
} as CLDDUEditor.SearchMessageMap;

export { CLDDUEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace CLDDUEditor {
  export interface Props extends Field.Props {
    value: Value;
    onChange: (value: Value) => void;
    CLDDUSubset: TradeAPI.LegacyCLDDUser.Search.Subset;
    CLDDUQuery?: string;
    CLDDUSelector?(clddu: TradeAPI.LegacyCLDDUser): boolean;
    onChangeSelector?: string;
    legalEntitiesOnly?: boolean;
    searchMessageMap?: SearchMessageMap;
    Constructor: typeof CLDDU;
  }

  export type Value = CLDDU | undefined;
  export interface SearchMessageMap {
    initial: string;
    searching: string;
    noData: string;
  }
}
