import { NumberField } from "@/components";
import { PrimitiveDataModel, PrimitiveDataModelProps } from "../PrimitiveDataModel";

export class Number extends PrimitiveDataModel<Data> {}

class NumberProps extends PrimitiveDataModelProps<Data> {}

NumberProps.prototype.editor = NumberField;

Number.prototype._ = new NumberProps();

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

export interface Number {
  data: Data;
}

type Data = Number;
