import React from "react";
import { Vessels, Vessel } from "@/models";
import { Button } from "@/components";
import { Group } from "../../Group";
import { VesselEditor, VesselEditorProps } from "../Vessel";
import { usageMetrics, MainTermsTypeEvents } from "@/services/UsageMetrics";
import "./Vessels.scss";

export function VesselsEditor(props: VesselsEditorProps) {
  props = { ...props };

  props.className = `${props.className} vessels`;

  const { className, onChange, value, ...rest } = props;

  return (
    <Group className={className}>
      {value?.vessels?.map(VesselEditorHOC)}
      <Button icon="plus" onClick={addVessel} dataTest="nominate-vessel">
        Nominate a Vessel
      </Button>
    </Group>
  );

  function onVesselChange(i: number, vessel?: Vessel | null) {
    if (!vessel || !value?.vessels) return;

    let vessels = [...value.vessels];
    const prevVessel = value.vessels[i];

    if (prevVessel.vesselImo && prevVessel.vesselImo !== vessel.vesselImo) {
      vessels = removeVessel(i)!;

      const removedVesselIx = vessels.findIndex((_vessel) => _vessel.vesselImo === vessel.vesselImo);

      if (removedVesselIx === -1) vessels.splice(i, 0, vessel);
      //
      else vessels.splice(i + 1, 0, vessel);
    }
    //
    else vessels[i] = vessel;

    const next = new Vessels();

    next.vessels = vessels;

    props.onChange?.(next);
  }

  function addVessel() {
    const vessels = value?.vessels ? [...value.vessels] : [];

    vessels.push(new Vessel());

    const next = new Vessels();

    next.vessels = vessels;

    props.onChange?.(next);
    usageMetrics.trackEvent(MainTermsTypeEvents.NOMINATE_VESSEL);
  }

  function removeVessel(i: number) {
    if (!value?.vessels) return;

    const toRemoveVessel = value.vessels[i];

    const isVesselSaved = value.data?.find((savedVessel) => savedVessel.vesselImo === toRemoveVessel.vesselImo);

    const vessels = value?.vessels ? [...value.vessels] : [];

    if (isVesselSaved) toRemoveVessel.status = "deleted";
    //
    else vessels.splice(i, 1);

    const next = new Vessels();

    next.vessels = vessels;

    props.onChange?.(next);

    return vessels;
  }

  function VesselEditorHOC(vessel: Vessel, i: number) {
    if (vessel.status === "deleted") return null;

    const key = `${vessel?.vesselIMO}${i}`;

    return (
      <div className="vessels-vessel" key={key}>
        <VesselEditor
          {...rest}
          status={rest.status?.children?.[i]}
          value={vessel}
          onRemove={removeVessel.bind(null, i)}
          onChange={onVesselChange.bind(null, i)}
        />
      </div>
    );
  }
}

export interface VesselsEditorProps extends Omit<VesselEditorProps, "onChange" | "value"> {
  value?: Vessels;
  onChange?: (value?: Vessels) => void;
}
