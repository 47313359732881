import React from "react";
import { Modal } from "office-ui-fabric-react";
import { ConfirmationDialog } from "sharedFolder/components/common/ConfirmationDialog/ConfirmationDialog";
import { emptyArrowFn } from "@/utils";

export const WithdrawLiftingModalConfirm = (props: { onCancel: () => void; onConfirm: () => void; isSaving: boolean }) => {
  return (
    <Modal isOpen={true} onDismiss={emptyArrowFn}>
      <ConfirmationDialog
        title="Withdraw Lifting?"
        message={
          <span>
            Are you sure you want to withdraw?<br></br> Any information for this lifting will be removed.
          </span>
        }
        dataTestId="AcceptFirmOKButton"
        isDisabled={props.isSaving}
        isCancelDisabled={props.isSaving}
        displayLoading={props.isSaving}
        ariaLabel="Withdraw Lifting"
        okButtonText="Withdraw"
        onSubmit={props.onConfirm}
        onCancel={props.onCancel}
      />
    </Modal>
  );
};
