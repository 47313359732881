import "___REFACTOR___/styles/index.scss";
import "@/styles/index.scss";
import { configure } from "mobx";
import { extend } from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";
import objectSupport from "dayjs/plugin/objectSupport";
import { render } from "react-dom";
import { setup, preauthSetup } from "@/setup";
import { Root, Preauth } from "@/components";
import { Services } from "___REFACTOR___/components/services";
import { createTheme, ThemeProvider } from "@material-ui/core";

configure({ enforceActions: "never" });

extend(utc);
extend(relativeTime);
extend(duration);
extend(objectSupport);

function preauth() {
  preauthSetup();

  render(<Preauth />, window.react.root);
  render(<Services />, window.react.services.root);
}

async function main() {
  await setup();

  const theme = createTheme({
    palette: {
      background: {
        default: "#0b1825",
        paper: "#0b1825",
      },
      primary: {
        main: "#2196f3",
      },
    },
  });

  render(
    <ThemeProvider theme={theme}>
      <Root />
    </ThemeProvider>,
    window.react.root
  );
}

preauth();
main();
