import { ILaycanSearchResult } from "../Models/ILaycanSearchResult";
import { Observable, defer } from "rxjs";
import moment from "moment";
import { MaritimeDateRange } from "@maritech/maritime-date";
import { isDate as lodashIsDate, isString } from "lodash-es";

interface ILaycanApiResponse {
  style?: number;
  styleDescription?: string;
  start: string;
  end: string;
  term: string;
}

export function getLaycan(term: string): Observable<ILaycanSearchResult | null> {
  return defer(async () => {
    const newParsedLaycan = MaritimeDateRange.parse(term, { americanFormat: false, contextualParsing: "future" });
    if (newParsedLaycan.isValid)
      return toLaycan({
        style: 0,
        styleDescription: "Exact",
        start: newParsedLaycan.start.toJSDate().toISOString(),
        end: newParsedLaycan.end.startOf("day").toJSDate().toISOString(),
        term: term,
      } as ILaycanApiResponse);
    else return null;
  });
}

function toLaycan(response: ILaycanApiResponse): ILaycanSearchResult {
  return {
    start: response.start,
    end: response.end,
    display: laycanDisplayFormat(response),
    shortDisplay: "",
  };
}

function laycanDisplayFormat(value: ILaycanApiResponse): string {
  const { start, end } = value;
  const yr = "YY";
  if (start && end) {
    const sameyear = doDatesMatch(start, end, "year");
    const samemonth = doDatesMatch(start, end, "month");
    const sameDay = doDatesMatch(start, end, "day");

    if (sameDay) {
      return parseDateAsMoment(start, "DD MMM YY") + "-" + parseDateAsMoment(start, "DD MMM YY");
    }

    return (
      parseDateAsMoment(start, sameyear ? (samemonth ? "DD" : "DD MMM") : "DD MMM " + yr) +
      "-" +
      parseDateAsMoment(end, "DD MMM " + yr)
    );
  } else if (start) {
    return dateAsShorthand(start);
  } else if (end) {
    return dateAsShorthand(end);
  } else {
    return "";
  }
}

function doDatesMatch(
  a: string | Date | any,
  b: string | Date | any,
  p: "year" | "month" | "week" | "day" | "hour" | "minute" | "second"
): boolean {
  if (isALaycanDate(a)) {
    return moment(a).isSame(b, p);
  } else {
    return false;
  }
}

function dateAsShorthand(d: any): string {
  if (isALaycanDate(d)) {
    return moment(d).format("DD MMM YY");
  } else {
    /* its an object ... */
    return d.value ? moment(d.value).format("DD MMM YY") : "";
  }
}

function isALaycanDate(d: string | Date | any): boolean {
  return lodashIsDate(d) || isString(d) || d._isAMomentObject;
}

function parseDateAsMoment(d: string | Date | any, f: string): string {
  if (isALaycanDate(d)) {
    return moment(d).format(f);
  } else {
    return d && d.value ? moment(d.value).format(f) : "";
  }
}
