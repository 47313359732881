import { TradeAPI } from "@/apis";
import { VesselsEditor } from "@/components";
import { LABELS } from "@/constants";
import { ArrayDataModel, ArrayDataModelProps, DeepPartialExceptForPrimitiveArraysAndDataModelKeys } from "../ArrayDataModel";
import { Vessel, newVessel } from "../Vessel";

export class Vessels extends ArrayDataModel<Data> {
  onUpdate() {
    this.vessels = this.data?.map(newVessel);
    this.acceptedVessels = this.vessels?.filter(acceptedVesselSelector);
    this.acceptedVessel = this.acceptedVessels?.[0];
    this.nonRejectedVessels = this.vessels?.filter(nonRejectedVesselSelector);
    this.firstVessel = this.nonRejectedVessels?.[0];
    this.acceptedOrFirstVessel = this.acceptedVessel || this.firstVessel;
    this.count = this.acceptedVessels?.length || this.nonRejectedVessels?.length;
  }

  toGridView() {
    if (!(this instanceof Vessels)) {
      Vessels.prototype.onUpdate.call(this);
    }

    const vessel = this.acceptedOrFirstVessel?.registrationDataName || "TBN";
    const count = this.count || 0;
    const countText = count > 1 ? ` (+${count - 1})` : "";
    return `${vessel}${countText}`;
  }

  toJSON(): Data | undefined {
    return this.vessels;
  }

  getAcceptedOrFirstVessel() {
    if (this.data) return getAcceptedOrFirstVessel(this.data);
  }

  getAcceptedOrFirstVesselModel() {
    if (this.data) return new Vessel(getAcceptedOrFirstVessel(this.data));
  }

  getAcceptedVessel() {
    if (this.data) return this.data.find(acceptedVesselSelector);
  }

  getAcceptedVesselModel() {
    if (this.data) return new Vessel(this.data.find(acceptedVesselSelector));
  }

  getNonRejectedFirstVessel() {
    if (this.data) return this.data.find(nonRejectedVesselSelector);
  }

  getNonRejectedVesselsSortedByStatus() {
    const result = this.nonRejectedVessels?.sort((a, b) => {
      const vesselAStatus = a.status || "";
      const vesselBStatus = b.status || "";
      return vesselAStatus.localeCompare(vesselBStatus);
    });
    return result;
  }
}

function getAcceptedOrFirstVessel(data: Data) {
  const acceptedVessel = data?.find(acceptedVesselSelector);

  if (acceptedVessel) return acceptedVessel;

  const firstVessel = data?.[0];

  return firstVessel;
}

class VesselsProps extends ArrayDataModelProps<Data> {}

VesselsProps.prototype.editor = VesselsEditor;
VesselsProps.prototype.label = LABELS["vessels"];
VesselsProps.prototype.sortKey = "acceptedOrFirstVessel";

Vessels.prototype._ = new VesselsProps();

function acceptedVesselSelector(vessel: Data[number]) {
  return vessel.status === "accepted";
}

function nonRejectedVesselSelector(vessel: Data[number]) {
  return vessel.status !== "rejected";
}

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

export interface Vessels extends _Vessels {
  _: VesselsProps;
}

type _Vessels = DeepPartialExceptForPrimitiveArraysAndDataModelKeys<{
  data: Data;
  vessels: Vessel[];
  nonRejectedVessels: Vessel[];
  acceptedVessels: Vessel[];
  acceptedVessel: Vessel;
  firstVessel: Vessel;
  acceptedOrFirstVessel: Vessel;
  count: number;
}>;

type Data = DeepPartial<TradeAPI["Vessel"][]>;
