import * as React from "react";
import { Menu, MenuItem } from "@material-ui/core";
import { Icon } from "@/components";
import "./ContextMenu.scss";

export const ContextMenu = ({ coordinates, actions }) => {
  const contextMenuElement = document.querySelector("#header-kebab-menu");
  const kebabIcon = document.querySelector(".header-kebab .icon--kebab");

  const [contextMenu, setContextMenu] = React.useState<boolean | null>(true);

  React.useEffect(() => {
    const closeContextMenu = (e) => {
      if (e.target !== kebabIcon && e.target !== contextMenuElement) {
        handleClose();
      }
    };

    window.addEventListener("click", closeContextMenu);
    window.addEventListener("contextmnenu", closeContextMenu);

    function removeListeners() {
      window.removeEventListener("click", closeContextMenu);
      window.removeEventListener("contextmnenu", closeContextMenu);
    }

    return removeListeners;
  }, []);

  const handleClose = () => {
    setContextMenu(null);
  };

  return (
    <Menu
      className="menu"
      open={contextMenu !== null}
      id="header-kebab-menu"
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={contextMenu !== null ? { top: coordinates.mouseY, left: coordinates.mouseX } : undefined}
    >
      {actions &&
        actions.map((item, index) => (
          <MenuItem
            className={`menu-item ${item.icon ? "small-padding" : ""}`}
            key={index}
            onClick={() => {
              item.onClick();
              handleClose();
            }}
            disabled={item.disabled}
          >
            <Icon icon={item.icon} />
            <span style={{ marginLeft: "6px" }} data-test={item.dataTest}>
              {item.label}
            </span>
          </MenuItem>
        ))}
    </Menu>
  );
};
