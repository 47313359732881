import { getIsDateExpired } from "sharedFolder/components/common/DigitalTimer/useTimer";
import { useEffect, useReducer, useContext } from "react";
import { offerRepStatusReducer, NegotiationUIStatus, getInitialState } from "../reducers/offerRepStatusReducer";
import { TrackingContext } from "../../../analytics/TrackingContext";
import { INegotiationView } from "sharedFolder/Models/Negotiation/INegotiationView";

export function useOfferRepStatus(negotiation: INegotiationView) {
  const [stateReducer, dispatch] = useReducer(offerRepStatusReducer, getInitialState(negotiation));

  const firmOfferExpired = getIsDateExpired(negotiation.firmOfferExpiresOn);
  const firmBidExpired = getIsDateExpired(negotiation.firmBidExpiresOn);

  // track changes in state similar to page views
  const tracking = useContext(TrackingContext);
  useEffect(() => {
    // ignore some noise
    const noise: NegotiationUIStatus[] = ["None", "RequestInProgress"];
    !noise.some((n) => n === stateReducer.negotiationDisplayState) &&
      tracking.pageView(`${window.URL}#${stateReducer.negotiationDisplayState}`, stateReducer.negotiationDisplayState);
  }, [tracking, stateReducer.negotiationDisplayState]);
  // end tracking

  useEffect(() => {
    dispatch({
      type: negotiation.lastUpdatedAction,
      payload: negotiation,
    });
  }, [
    negotiation,
    negotiation.lastUpdatedAction,
    negotiation.lastFirmAction,
    negotiation.firmBidExpiresOn,
    negotiation.firmOfferExpiresOn,
    firmOfferExpired,
    firmBidExpired,
  ]);

  return {
    negotiationUIStatus: stateReducer.negotiationDisplayState,
    negotiationUIStatusDispatch: dispatch,
  };
}
