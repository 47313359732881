import * as React from "react";
import classnames from "classnames";
import styles from "./VesselName.module.scss";
import { VesselNominiationStatus, IVesselView } from "sharedFolder/Models/IDetails";

interface IVesselNameProps {
  vessel: IVesselView;
}
const getVesselStatusStyle = (status?: VesselNominiationStatus) => {
  if (status === "accepted") return styles.vesselNameAccepted;
  if (status === "rejected") return styles.vesselNameRejected;
  return null;
};

export const VesselName: React.SFC<IVesselNameProps> = (props: IVesselNameProps) => {
  const { vesselIMO, status, vesselName } = props.vessel;

  return (
    <div
      id={`vessel-name-${vesselIMO}`}
      className={classnames(styles.vesselName, getVesselStatusStyle(status))}
      title={vesselName}
    >
      {vesselName}
    </div>
  );
};

VesselName.displayName = "VesselName";
