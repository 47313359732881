import React from "react";
import styles from "./Tag.module.scss";
import classNames from "classnames";

interface ITagProps {
  tag: string;
}

export const Tag = (props: ITagProps) => (
  <div className={classNames(styles.tag, styles[props.tag.toLowerCase()])}>{props.tag}</div>
);
