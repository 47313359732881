import React from "react";
import styles from "./VesselSummary.module.scss";
import { IVesselView } from "sharedFolder/Models/IDetails";
import Icon from "__legacy/sharedFolder/components/common/Icon/Icon";

interface IVesselDetailProps {
  vessel: IVesselView;
}

export const VesselSummary = (props: IVesselDetailProps) => {
  return (
    <div className={styles.container}>
      <span>
        &nbsp;-&nbsp;IMO {props.vessel.vesselIMO} YOB {props.vessel.buildYear} DWT {props.vessel.dwt}
        {props.vessel.status === "accepted" && <Icon icon="tick" className={styles.acceptedIcon} />}
        {props.vessel.status === "rejected" && <Icon icon="withdrawn" className={styles.rejectedIcon} />}
      </span>
    </div>
  );
};
