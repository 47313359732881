import React from "react";
import { Negotiation, dialog } from "@/models";
import { Button } from "___REFACTOR___/components/common";
import { Divider } from "@/components/common/Divider";
import { testSelectors } from "@/constants";
import { CopyIndicationType, formatDataToCopyTemplate, getCopyIndicationType } from "@/utils";
import "./CopyIndicationDialog.scss";

export function CopyIndication(model: Negotiation, enableTcFields: boolean) {
  const type = getCopyIndicationType(model);

  function onSubmit() {
    dialog.resolve?.({
      res: { ok: true },
    });

    dialog.hide();
  }

  return (
    <div className="dialog-container">
      <h2 className="dialog-header">Copy {type} to clipboard?</h2>

      <Divider />

      <div className="dialog-description">
        <p>You will copy the following text:</p>
      </div>

      <div className="dialog-copied-value" style={{ whiteSpace: "pre-wrap" }}>
        {formatDataToCopyTemplate(model, type as CopyIndicationType, enableTcFields)}
      </div>

      <Divider />

      <div className="dialog-actions">
        <Button variant="flat" onClick={dialog.hide} data-test={testSelectors.copyIndicationCancelButton}>
          Cancel
        </Button>
        <Button variant="action" onClick={onSubmit} data-test={testSelectors.copyIndicationSubmitButton}>
          Copy
        </Button>
      </div>
    </div>
  );
}
