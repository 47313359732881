import React from "react";
import { BunkerDelivery, BunkerProps } from "@/models";
import { IBunkerView } from "sharedFolder/Models/IBunker";
import { BunkerBaseEditor } from "../BunkerBase";

export function BunkerDeliveryEditor(props: Props) {
  props = { ...props };

  function onChange(key: keyof BunkerDelivery, propertyValue) {
    const newValue = { ...props.value, [key]: propertyValue };
    const next = new BunkerDelivery(newValue);

    props.onChange?.(next);
  }

  return <BunkerBaseEditor {...props} onChange={onChange} bunkerType="Delivery" dataTest={props.dataTest || "bunker-delivery"} />;
}

interface Props extends Omit<BunkerProps, "value" | "onChange" | "dataTest"> {
  value?: BunkerDelivery | IBunkerView;
  onChange?: (value: BunkerDelivery) => void;
  dataTest?: string;
}

export type BunkerDeliveryEditorProps = Props;
