import { PureComponent } from "react";
import { usageMetrics, MainTermsTypeEvents } from "@/services/UsageMetrics";
import styles from "./OpenMainTerms.module.scss";
import ButtonLink from "__legacy/sharedFolder/components/common/ButtonLink/ButtonLink";
interface IOpenMainTermsProps {
  ctradeOfferRepUrl: string;
  disabled?: boolean;
}

export class OpenMainTerms extends PureComponent<IOpenMainTermsProps> {
  public render() {
    let negotiationLink = this.props.ctradeOfferRepUrl;

    if (!negotiationLink) {
      negotiationLink = "#";
    }

    return (
      <ButtonLink
        ariaLabel={"Open Main Terms Button"}
        href={negotiationLink}
        target="_blank"
        rel="noopener noreferrer"
        disabled={this.props.disabled}
        className={styles.termsLink}
        type="action"
        onClick={() => {
          usageMetrics.trackEvent(MainTermsTypeEvents.MAIN_TERMS_OPENED);
        }}
      >
        Open Main Terms
      </ButtonLink>
    );
  }
}
