import React, { ReactNode } from "react";
import { Input } from "___REFACTOR___/components";
import { Editor } from "../Editor";
import {
  TextField as Text,
  NumberField as Number,
  TextareaField as Textarea,
  SelectField as Select,
  DateField as Date,
  RadioField as Radio,
  DateOffsetField as DateOffset,
  RangeField as Range,
  CheckboxField as Checkbox,
  CommaSeparatedValuesField as CommaSeparatedValues,
} from "./type";

function Field(props: Field.Props) {
  const { type } = props;

  const Type = Field.TYPE_MAP[type!];

  if (!Type) {
    console.error(`Unrecognized field type: "${props.type}"`, props);

    return null;
  }

  return <Type {...props} />;
}

Field.TYPE_MAP = {
  Text,
  Number,
  Select,
  Textarea,
  Checkbox,
  Date,
  Radio,
  DateOffset,
  Range,
  CommaSeparatedValues,
};

export { Field };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace Field {
  interface Props extends Omit<Input.Props, "type"> {
    type?: Type;
    editorType?: Editor.Type;
    name?: string;
    label?: ReactNode;
    required?: boolean;
    suppressStatusMessages?: boolean;
    readOnly?: boolean;
    children?: ReactNode;
  }

  type Type = keyof typeof Field.TYPE_MAP;
}
