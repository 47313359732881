import { useMemo } from "react";
import classNames from "classnames";
import { UnitValueDataUnion, UnitValueProps, UNIT_VALUE_LABEL_MAP } from "@/models";
import { TextareaField, NumberField, SelectField, SelectFieldProps } from "@/components";
import { Row } from "../../Row";
import { Group } from "../../Group";
import "./UnitValue.scss";

export function UnitValueEditor(props: Props) {
  props = { ...props };

  props.className = classNames(props.className, "UnitValue");

  const { className, status, ...rest } = props;

  const unitValue = props.value;

  function onChange(name, value) {
    props.onChange?.({ ...unitValue, [name]: value });
  }

  function onUnitChange(unit) {
    unit = unit.replace(/\s/g, "");
    props.onChange?.({ ...unitValue, unit });
  }

  function getUnitsWithSpace() {
    const unitsWithSpace: string[] = [];

    props.units?.forEach((unit) => {
      unitsWithSpace.push(UNIT_VALUE_LABEL_MAP[unit]);
    });

    return unitsWithSpace;
  }

  const unit = unitValue?.unit || props.defaultUnit || UnitValueProps.prototype.JSONDefaults.unit;
  const unitLabel = UNIT_VALUE_LABEL_MAP[unit];
  const unitsWithSpace = useMemo(getUnitsWithSpace, [props.units]);

  return (
    <Group className={className}>
      <Row>
        <NumberField
          {...rest}
          status={status?.children?.value || status}
          value={unitValue?.value}
          onChange={onChange?.bind(null, "value")}
          dataTest={`${props.dataTest}.value`}
        />
        <SelectField
          {...rest}
          label="Unit"
          className="UnitValue-unit"
          data={unitsWithSpace}
          status={status?.children?.unit}
          value={unitLabel}
          onChange={onUnitChange}
          dataTest={`${props.dataTest}.unit`}
          unclearable
          nonFilterSelect
        />
      </Row>
      <TextareaField
        {...rest}
        label="Notes"
        className="UnitValue-notes"
        value={unitValue?.notes || ""}
        onChange={onChange?.bind(null, "notes")}
        status={status?.children?.notes}
        dataTest={`${props.dataTest}.notes`}
      />
    </Group>
  );
}

interface Props extends Omit<SelectFieldProps, "value" | "onChange"> {
  units?: UnitValueProps["units"];
  value?: UnitValueDataUnion;
  defaultUnit?: string;
  onChange?: (value) => void;
}

export type UnitValueEditorProps = Props;
