import { TradeAPI } from "___REFACTOR___/apis";
import { CompanyEditor } from "___REFACTOR___/components";
import { DataModel, DataModelProps } from "../DataModel";

export class Company extends DataModel {
  constructor(data: PartialData) {
    super(data);
  }

  toString() {
    return this.name;
  }
}

class CompanyProps extends DataModelProps {}

CompanyProps.prototype.Editor = CompanyEditor;
CompanyProps.prototype.label = "Company";

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

export interface Company extends Data {
  Props: typeof CompanyProps;
  _: CompanyProps;
}

type PartialData = Partial<Data>;
type Data = TradeAPI.Company;
