import { Component } from "react";
import buttonStyles from "@/styles/global/button-styles.module.scss";
import Button from "__legacy/sharedFolder/components/common/Button/Button";
import classNames from "classnames";
import { IVesselView } from "../../../../../sharedFolder/Models/IDetails";
import { VesselSummary } from "../../../common/VesselNomination/VesselSummary/VesselSummary";
import { VesselName } from "../../../common/VesselNomination/VesselName/VesselName";
import styles from "./Vessels.module.scss";

interface IVesselDetailRowState {
  panelIsOpen: boolean;
  nominatingVessel?: IVesselView;
}

interface IVesselDetailRowProps {
  readonly?: boolean;
  vessels: IVesselView[];
  onVesselClicked?: (vessel: IVesselView) => void;
  onVesselRemoved?: (vessel: IVesselView) => void;
}

export class Vessels extends Component<IVesselDetailRowProps, IVesselDetailRowState> {
  constructor(props: IVesselDetailRowProps) {
    super(props);
    this.state = {
      panelIsOpen: false,
      nominatingVessel: undefined,
    };
    this.handleVesselClicked = this.handleVesselClicked.bind(this);
  }

  handleVesselClicked(vessel: IVesselView) {
    this.props.onVesselClicked && this.props.onVesselClicked(vessel);
  }

  render() {
    const sortedVessels = () => {
      /**
       * Dipslay the vessels in the following sort
       * - Accepted
       * - No Status
       * - Rejected
       * The more recently added must displayed at the bottom of the list
       */
      const vessels = this.props.vessels;
      const acceptedVessels: IVesselView[] = [];
      const vesselsWithNoStatus: IVesselView[] = [];
      const rejectedVessels: IVesselView[] = [];
      vessels.forEach((vessel) => {
        if (vessel.status === "accepted") {
          acceptedVessels.push(vessel);
        } else if (vessel.status === "rejected") {
          rejectedVessels.push(vessel);
        } else {
          vesselsWithNoStatus.push(vessel);
        }
      });
      return acceptedVessels.concat(vesselsWithNoStatus).concat(rejectedVessels) as IVesselView[];
    };
    return (
      <div className={styles.container} aria-label="Vessels">
        {sortedVessels().map((vessel) => (
          <div key={vessel.arcVesselId}>
            <div className={styles.vessel}>
              <VesselName vessel={vessel} />
              <VesselSummary vessel={vessel} />
              <Button
                dataTest={`view-vessel-btn`}
                disabled={this.props.readonly}
                className={classNames(buttonStyles.condensedHeight, buttonStyles.flatButtonHeightHack)}
                type="flat"
                onClick={() => this.handleVesselClicked(vessel)}
              >
                Action
              </Button>
              {vessel.deletable && (
                <Button
                  className={styles.deleteIconCondensed}
                  type="plain"
                  icon="delete"
                  title="Delete Vessel"
                  onClick={() => this.props.onVesselRemoved && this.props.onVesselRemoved(vessel)}
                />
              )}
            </div>
          </div>
        ))}
      </div>
    );
  }
}
