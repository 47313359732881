import React, { forwardRef, Ref, useImperativeHandle, useState } from "react";
import { chunk, uniq } from "lodash-es";
import { MultiFilter, SetFilter } from "@ag-grid-enterprise/all-modules";
import { EMDASH } from "@/constants";
import { isTruthy, useKeepRerendering } from "@/utils";
import { AggridFilterComp, AggridFilterParams, CheckboxInput } from "@/components";
import "./DeskFilter.scss";
import { OrderNegotiationStore } from "@/models";

function DeskFilter(params: AggridFilterParams, ref: Ref<AggridFilterComp>) {
  const { filterChangedCallback, api, column } = params;
  const instance = api.getFilterInstance(column) as MultiFilter;
  const secondFilter = instance?.getChildFilterInstance(1);
  const setFilter = secondFilter as SetFilter | undefined;
  const uniqueValueArray = setFilter?.getValues();
  let deskViewAndIdArray = uniqueValueArray?.map(extractDeskViewAndId).filter(isTruthy);
  deskViewAndIdArray = uniq(deskViewAndIdArray).sort();
  const deskColumns = chunk(deskViewAndIdArray, 4);
  const [state, setState] = useState(defaultState);
  const isActive = !!Object.keys(state).length;

  useImperativeHandle(ref, getCurrentInstance);
  useKeepRerendering(1000);

  return <sea-desk-filter>{deskColumns.map(ColumnHOC)}</sea-desk-filter>;

  function getCurrentInstance() {
    return {
      isFilterActive,
      doesFilterPass,
      getModel,
      setModel,
    };
  }

  function isFilterActive() {
    return isActive;
  }

  function doesFilterPass(params) {
    const order = params.data as OrderNegotiationStore["Order"];
    const deskId = order?.createdBy?.desk?.id;

    if (!deskId) return false;

    return state[deskId];
  }

  function getModel() {
    return state;
  }

  function setModel(state) {
    setState(state ? state : defaultState);
  }

  function ColumnHOC(column, i) {
    return <sea-desk-filter-column key={i}>{column.map(CheckboxHOC)}</sea-desk-filter-column>;
  }

  function CheckboxHOC(deskViewAndId) {
    let [deskView, deskId] = deskViewAndId.split(EMDASH);

    deskView = deskView.trim();
    deskId = deskId.trim();

    function onChange(value) {
      if (value) {
        setState({ ...state, [deskId]: value });

        //
      } else {
        const { [deskId]: removed, ...rest } = state;

        setState(rest);
      }

      setTimeout(filterChangedCallback);
    }

    return (
      <CheckboxInput value={state[deskId]} onChange={onChange} label={deskView} dataTest={deskViewAndId} key={deskViewAndId} />
    );
  }
}

function extractDeskViewAndId(createdByAndDeskName: string | null) {
  if (!createdByAndDeskName) return null;

  const valueArray = createdByAndDeskName?.split(EMDASH);
  let deskView: string | undefined;
  let deskId: string | undefined;

  if (valueArray.length === 4) {
    [, deskView, , deskId] = valueArray;
  }

  if (valueArray.length === 3) {
    [, deskView, deskId] = valueArray;
  }

  return [deskView, deskId].join(EMDASH);
}

const defaultState = {} as State;

const ForwardRef = forwardRef(DeskFilter);

ForwardRef.displayName = "DeskFilter";

export { ForwardRef as DeskFilter };

type State = BoolRecord;
