import { observer } from "mobx-react";
import * as yup from "yup";
import { UniversalOrderNegotiationFormValues } from "@/models";
import { MainTermsForm } from "./";
import { CAPTURE_CONFIG, SPLIT_ELEMENT, VOY_CONFIG, TCT_CONFIG, COA_CONFIG, TYPE_ID_BY_TYPE } from "./elements";
import { DealCaptureType } from "@/apis";

function CaptureMainTerms(props) {
  return <MainTermsForm {...props} getElements={getElements} yup={schema} section="deal-capture" />;
}

const schema = yup
  .object()
  .test(
    "CaptureMainTermsValidation0",
    "Something is wrong 0",
    function validate0(this: yup.TestContext, values: UniversalOrderNegotiationFormValues) {
      if (!values) return false;

      const { chartererContact, brokerContact } = values;

      const error = this.createError();

      if (!chartererContact && !brokerContact) {
        error.message = "Required";
        error.path = "chartererContact";

        return error;
      }

      return true;
    }
  )
  .test(
    "CaptureMainTermsValidation1",
    "Something is wrong 1",
    function validate1(this: yup.TestContext, values: UniversalOrderNegotiationFormValues) {
      if (!values) return false;

      const { chartererContact, brokerContact } = values;

      const error = this.createError();

      if (!brokerContact && !chartererContact) {
        error.message = "Required";
        error.path = "brokerContact";

        return error;
      }

      return true;
    }
  );

function getElements(
  type: UniversalOrderNegotiationFormValues["type"],
  isDummy: boolean,
  values: UniversalOrderNegotiationFormValues | undefined
) {
  const elements = type && ELEMENTS_BY_TYPE[type];
  const chartererElement = CAPTURE_CONFIG.elements[0].children.find((c) => c.name === "chartererContact");
  const brokerElement = CAPTURE_CONFIG.elements[0].children.find((c) => c.name === "brokerContact");
  const inviteeElement = CAPTURE_CONFIG.elements[0].children.find((c) => c.name === "invitee");
  const ownerElement = CAPTURE_CONFIG.elements[0].children.find((c) => c.name === "ownerContact");

  if (!chartererElement || !brokerElement || !inviteeElement || !ownerElement) {
    throw new Error("One of required elements wasn't defined in CAPTURE_CONFIG");
  }

  if (values?.dealCaptureType === DealCaptureType.Owner) {
    chartererElement.hidden = true;
    inviteeElement.hidden = false;
  } else {
    chartererElement.hidden = false;
    chartererElement.readOnly = !!values?.chartererContact?.systemUserId;
    inviteeElement.hidden = true;
  }
  brokerElement.readOnly = !!values?.brokerContact?.systemUserId;
  const required = type && REQUIRED_BY_TYPE[type];
  const typeId = type && TYPE_ID_BY_TYPE[type];

  return { elements, required, typeId };
}

const Observer = observer(CaptureMainTerms);

export { Observer as CaptureMainTerms };

const MISC_CONFIG = {
  elements: [
    {
      type: "CollapsibleGroup",
      label: "Owner/Vessel Details",
      children: [
        { type: "CollapsibleRow", name: "ownerAccount" },
        { type: "CollapsibleRow", name: "vessels" },
      ],
    },
  ],
  required: ["ownerAccount", "termset"],
};

const ELEMENTS_BY_TYPE = {
  Voy: SPLIT_ELEMENT([...CAPTURE_CONFIG.elements, ...VOY_CONFIG.elements, ...MISC_CONFIG.elements]),
  Tct: SPLIT_ELEMENT([...CAPTURE_CONFIG.elements, ...TCT_CONFIG.elements, ...MISC_CONFIG.elements]),
  Coa: SPLIT_ELEMENT([...CAPTURE_CONFIG.elements, ...COA_CONFIG.elements, ...MISC_CONFIG.elements]),
} as AnyRecord;

const REQUIRED_BY_TYPE = {
  Voy: [...VOY_CONFIG.required, ...MISC_CONFIG.required],
  Tct: [...TCT_CONFIG.required, ...MISC_CONFIG.required],
  Coa: [...COA_CONFIG.required, ...MISC_CONFIG.required],
} as AnyRecord;
