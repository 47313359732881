import React from "react";
import { auth } from "___REFACTOR___/services";
import { CLDDUCharterer } from "___REFACTOR___/models";
import { CLDDUEditor } from "../CLDDU";

function CLDDUChartererEditor(props: CLDDUChartererEditor.Props) {
  let CLDDUQuery = "companyType eq 'charterer'";

  if (auth.trade.user?._.companyRoleMap.charterer) {
    CLDDUQuery += ` and companyId eq '${auth.trade.user.CompanyId}'`;
  }

  return (
    <CLDDUEditor
      {...props}
      editorType="CLDDUCharterer"
      searchMessageMap={searchMessageMap}
      placeholder={placeholder}
      CLDDUSubset="users"
      CLDDUQuery={CLDDUQuery}
      Constructor={CLDDUCharterer}
    />
  );
}

const placeholder = "Search for charterers";

const searchMessageMap = {
  initial: "Waiting for your input",
  searching: "Searching for charterers",
  noData: "No charterers found",
} as CLDDUEditor.SearchMessageMap;

export { CLDDUChartererEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace CLDDUChartererEditor {
  export interface Props extends CLDDUEditor.Props {
    value: Value;
    onChange: (value: Value) => void;
  }

  export type Value = CLDDUCharterer | undefined;
}
