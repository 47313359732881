import { LABEL_MAP } from "___REFACTOR___/constants";
import { TradeAPI } from "___REFACTOR___/apis";
import { SubsEditor } from "___REFACTOR___/components";
import { DataModel, DataModelProps } from "../DataModel";

class Subs extends DataModel {
  constructor(data: PartialData) {
    super(data);
  }

  toString() {
    return this.value;
  }
}

class SubsProps extends DataModelProps {}

Subs.prototype.Props = SubsProps;
SubsProps.prototype.label = "Subs";
SubsProps.prototype.Editor = SubsEditor;

/* -------------------------------------------------------------------------- */
/*                               TradingExclusions                            */
/* -------------------------------------------------------------------------- */

class TradingExclusions extends Subs {
  static label = LABEL_MAP.tradingExclusions;
  static get Editor() {
    return SubsEditor;
  }

  constructor(data: PartialData) {
    super(data);
  }
}
class TradingExclusionsProps extends SubsProps {}

TradingExclusions.prototype.Props = TradingExclusionsProps;
TradingExclusionsProps.prototype.sortKey = "value";

TradingExclusions.prototype.Props = TradingExclusionsProps;
TradingExclusionsProps.prototype.Editor = SubsEditor;
TradingExclusionsProps.prototype.label = LABEL_MAP.tradingExclusions;

/* -------------------------------------------------------------------------- */
/*                               CargoExclusionsText                          */
/* -------------------------------------------------------------------------- */

class CargoExclusionsText extends Subs {
  static label = LABEL_MAP.cargoExclusionsText;
  static get Editor() {
    return SubsEditor;
  }

  constructor(data: PartialData) {
    super(data);
  }
}
class CargoExclusionsTextProps extends SubsProps {}

CargoExclusionsText.prototype.Props = CargoExclusionsTextProps;
CargoExclusionsTextProps.prototype.sortKey = "value";

CargoExclusionsText.prototype.Props = CargoExclusionsTextProps;
CargoExclusionsTextProps.prototype.Editor = SubsEditor;
CargoExclusionsTextProps.prototype.label = LABEL_MAP.cargoExclusionsText;

/* -------------------------------------------------------------------------- */
/*                               OperationalSubs                              */
/* -------------------------------------------------------------------------- */

class OperationalSubs extends Subs {}
class OperationalSubsProps extends SubsProps {}

OperationalSubs.prototype.Props = OperationalSubsProps;
OperationalSubsProps.prototype.label = LABEL_MAP.operationalSubs;

/* -------------------------------------------------------------------------- */
/*                               CommercialSubs                              */
/* -------------------------------------------------------------------------- */

class CommercialSubs extends Subs {}
class CommercialSubsProps extends SubsProps {}

CommercialSubs.prototype.Props = CommercialSubsProps;
CommercialSubsProps.prototype.label = LABEL_MAP.commercialSubs;

export {
  Subs,
  SubsProps,
  TradingExclusions,
  TradingExclusionsProps,
  CargoExclusionsText,
  CargoExclusionsTextProps,
  OperationalSubs,
  OperationalSubsProps,
  CommercialSubs,
  CommercialSubsProps,
};

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface Subs extends Data {
  Props: typeof SubsProps;
  _: SubsProps;
}

interface OperationalSubs extends Data {
  Props: typeof OperationalSubsProps;
  _: OperationalSubsProps;
}

interface CommercialSubs extends Data {
  Props: typeof CommercialSubsProps;
  _: CommercialSubsProps;
}

interface TradingExclusions extends Data {
  Props: typeof TradingExclusionsProps;
  _: TradingExclusionsProps;
}
interface TradingExclusionsProps {
  sortKey: keyof Data;
  JSONDefaults: Partial<Data>;
}

interface CargoExclusionsText extends Data {
  Props: typeof CargoExclusionsTextProps;
  _: CargoExclusionsTextProps;
}
interface CargoExclusionsTextProps {
  sortKey: keyof Data;
  JSONDefaults: Partial<Data>;
}

type PartialData = Partial<TradeAPI.Subs>;
type Data = TradeAPI.Subs;
