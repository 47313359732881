import React from "react";
import { DateOffsetInput } from "___REFACTOR___/components";
import { Field } from "../../Field";
import { Container } from "../../Container";

function DateOffsetField(props: DateOffsetField.Props) {
  return (
    <Container {...props} type="DateOffset">
      <DateOffsetInput {...props} />
    </Container>
  );
}

export { DateOffsetField };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace DateOffsetField {
  export interface Props extends Omit<DateOffsetInput.Props, "type">, Omit<Field.Props, "value" | "onChange"> {}

  export { DateOffsetInput as Input };
}
