import React from "react";
import classNames from "classnames";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "./Status.messages.scss";

export function StatusMessages(props: Props) {
  const statuses = extractStatuses(props.status || {});

  return (
    <status-messages hidden={!statuses.length}>
      <TransitionGroup component={null}>{MessagesHOC(statuses, props)}</TransitionGroup>
    </status-messages>
  );
}

function MessagesHOC(statuses: Status[], props: Props) {
  const { status, hidden } = props;

  if (!status || hidden) return null;

  const messages = statuses.map(MessageHOC.bind(null, props));

  return messages;
}

function MessageHOC(props: Props, status: Status, i: number) {
  const key = status.type + status.message;
  const timeout = { enter: 0, appear: 250, exit: 250 };

  return (
    <CSSTransition timeout={timeout} classNames="visibility" key={key}>
      <Message {...props} status={status} />
    </CSSTransition>
  );
}

function Message(props: Props) {
  if (!props.status?.message) return null;

  const { status, dataTest } = props;
  const { type, message } = status;
  const className = classNames(type);

  return (
    <status-message class={className} data-test={`${dataTest}.status-message.${message}`}>
      {message}
    </status-message>
  );
}

function extractStatuses(status: Status, res?: Status[]) {
  res = res || [];

  const { children } = status;

  res.push(status);

  if (!children) return res;

  const statuses = Object.values(children);

  for (let i = 0; i < statuses.length; i++) {
    const status = statuses[i];

    extractStatuses(status, res);
  }

  return res;
}

interface Props {
  hidden?: boolean;
  status?: Status;
  dataTest?: string;
}
