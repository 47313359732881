import React from "react";
import styles from "./NegotiationNotes.module.scss";
import moment from "moment";
import { Role } from "../../Role";
import classNames from "classnames";
import { Tag } from "sharedFolder/components/common/Tag/Tag";

interface INoteDetailProps {
  /*
   * String that make up the notes content
   */
  children: string;
  /*
   * The name of the note writer, invitee for owner, controller for charterer
   */
  noteWriter: string;
  /*
   * True if the note needs to have a New StatusTag against the content
   */
  isNew?: boolean;
  /*
   * The timestamp
   */
  timestamp: string | null;
  /*
   * This is the role of the Note owner
   */
  ownerOrCharterer: Role;
  /*
   * Will be called when the open content is clicked on, to open the editable panel
   */
  openNotePanel: (role: Role) => void;
  /*
   * true to prevent the Note area from being clickable
   */
  isDisabled: boolean;
}

export const NegotiationNoteDetail: React.FC<INoteDetailProps> = (props: INoteDetailProps) => {
  let fromNow;
  if (props.timestamp) {
    // friendly time stamp
    // if local time is inconsistent (updated in the future), show current timestamp instead
    const momentDate = moment(props.timestamp);
    const now = moment();
    const timestamp = momentDate < now ? momentDate : now;
    fromNow = timestamp.isValid() ? timestamp.fromNow() : null;
  }
  const isClickableProps = {
    onClick: () => props.openNotePanel(props.ownerOrCharterer),
  };

  return (
    <div
      className={classNames(styles.noteDetail, !props.isDisabled && styles.noteDetailHoverable)}
      {...(!props.isDisabled ? isClickableProps : {})}
    >
      <div className={styles.statusTagWrapper}>
        <span className={styles.email}>{props.noteWriter}</span>
        {props.isNew && <Tag tag="NEW" />}
      </div>
      <div className={styles.noteContent}>
        {props.children ? <div>{props.children}</div> : <span className={styles.emptyText}>No comments yet</span>}
      </div>
      <div className={styles.lastUpdated}>{fromNow}</div>
    </div>
  );
};
