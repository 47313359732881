import { Optimistic } from "../Optimistic";
import { map, filter, repeatWhen, concatMap } from "rxjs/operators";
import { Observable, empty } from "rxjs";
import { IResponse } from "../models/IResponse";
import { observableFetch } from "../observableFetch";
import { ICreateOrder } from "./models/ICreateOrder";
import { IOrder } from "./models/IOrder";
import { IOrderSummary } from "./models/IOrderSummary";
import { mapCreateOrder } from "./mapping/mapCreateOrder";
import { getNow } from "sharedFolder/Utilities/getNow";
import { IUserForSharing } from "../../orders/models/IUser";
import { getNegotiationApiService } from "../negotiations/Negotiations";
import { IDistributionListViewModel } from "sharedFolder/Models/IDetails";
import { distributionService } from "negotiations/services/distribution";
import { IUpdateDistributionListResponse } from "api/models/IUpdateDistributionListResponse";
import { IUserContext } from "__legacy/dashboard/contexts/UserProvider";
import { mapUserContext } from "orders/Distribution/mapUserContext";

/**
 * Orders API Service
 * Input/output API models, with optimistic updates.
 */
interface IApiOrderService {
  getAll: () => Observable<IOrderSummary[]>;
  get: (id: string) => Observable<IOrder>;
  create: (order: ICreateOrder) => Observable<IResponse>;
  share: (id: string, model: IUserForSharing, updateToken: string) => Observable<IResponse>;
  archive: (orderId: string, updateToken: string) => Observable<IResponse>;
  updateDistributionList: (
    orderId: string,
    distribution: IDistributionListViewModel,
    updateToken: string,
    ctradeUrl: string,
    userContext: IUserContext
  ) => Observable<IUpdateDistributionListResponse>;
}

class OrderService implements IApiOrderService {
  private readonly optimisticOrders = new Optimistic<IOrder>("orders");

  /**
   *
   * @param ordersUrl api URL
   * @param loginEvents logout events
   */
  constructor(private ordersUrl: string, private withUpdates?: Observable<{ id: string; version: number }>) {}

  public getAll(): Observable<IOrderSummary[]> {
    return observableFetch<IOrder[]>(() =>
      fetch(this.ordersUrl, {
        method: "GET",
        headers: {
          "X-API-Version": "3",
        },
      })
    )
      .pipe(
        // and fetch all again whenever we receive an update notification
        repeatWhen(() => {
          return this.withUpdates || empty();
        })
      )
      .pipe(
        map((results) => {
          // update cache
          this.optimisticOrders.replaceAllRemote(results);
          // and continue
          return results;
        })
      )
      .pipe(concatMap(() => this.optimisticOrders.data));
  }

  public get(id: string): Observable<IOrder> {
    return observableFetch<IOrder>(() =>
      fetch(`${this.ordersUrl}/${id}`, {
        method: "GET",
        headers: {
          "X-API-Version": "3",
        },
      })
    )
      .pipe(
        // and fetch all again whenever we receive an update notification
        // for this order
        repeatWhen(() => (this.withUpdates || empty()).pipe(filter((update) => update.id === id)))
      )
      .pipe(
        map((result) => {
          // update cached copies
          this.optimisticOrders.replaceSingleRemote(result);
          return result;
        })
      )
      .pipe(
        concatMap(() =>
          this.optimisticOrders.data
            .pipe(map((orders) => orders.find((o) => o.id === id)))
            .pipe(filter((order) => order !== undefined))
            .pipe(map((order) => order as IOrder))
        )
      );
  }

  public create(order: ICreateOrder): Observable<IResponse> {
    // 1. make the API call
    return observableFetch<IResponse>(() =>
      fetch(`${this.ordersUrl}`, {
        method: "POST",
        body: JSON.stringify(order),
        headers: {
          "X-API-Version": "3",
          "Content-Type": "application/json",
        },
      })
    ).pipe(
      map((result: IResponse) => {
        // cache locally with generated ID
        this.optimisticOrders.pushToLocal({
          ...mapCreateOrder(order),
          createdOn: getNow().format(),
          id: result.id,
        });
        return result;
      })
    );
  }

  public updateDistributionList(
    orderId: string,
    distribution: IDistributionListViewModel,
    updateToken: string,
    ctradeUrl: string,

    userContext: IUserContext
  ): Observable<IUpdateDistributionListResponse> {
    return observableFetch<IUpdateDistributionListResponse>(() => {
      return distributionService(ctradeUrl).sendDistributionList(orderId, distribution, updateToken, mapUserContext(userContext));
    });
  }

  public share(id: string, model: IUserForSharing, updateToken: string): Observable<IResponse> {
    const data = {
      updateToken,
      name: model.userName,
      email: model.email,
      id: model.userId,
      systemUserId: model.systemUserId,
      company: {
        id: model.companyId,
        name: model.companyName,
        companyType: model.companyType,
      },
      location: {
        id: model.locationId,
        name: model.locationName,
      },
      division: {
        id: model.divisionId,
        name: model.divisionName,
      },
      desk: {
        id: model.deskId,
        name: model.deskName,
      },
      groupChats: model.groupChats,
      teamMembers: model.teamMembers,
    };
    return observableFetch<IResponse>(() =>
      fetch(`${this.ordersUrl}/${id}/share`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "X-API-Version": "3",
          "Content-Type": "application/json",
        },
      })
    );
  }

  public archive(orderId: string, updateToken: string): Observable<IResponse> {
    const data = {
      updateToken: updateToken,
    };

    return observableFetch<IResponse>(() =>
      fetch(`${this.ordersUrl}/${orderId}/archive`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "X-API-Version": "3",
          "Content-Type": "application/json",
        },
      })
    ).pipe(
      map((result: IResponse) => {
        this.optimisticOrders.removeItemFromLocalState(result.id);
        const negService = getNegotiationApiService(this.ordersUrl, orderId);
        negService.withdrawInactiveNegotiationsUnderOrder(orderId);
        return result;
      })
    );
  }
}

/**
 * Retain a collection of orderServices; one for each apiUrl.
 * (this will probably only ever contain one record - we don't
 * integrate with multiple endpoints)
 */
const orderServices: { [key: string]: IApiOrderService } = {};

/**
 * Service provider for Orders client
 * Will return the same instance for all calls for the same API URL
 * @param apiUrl The API URL
 * @param withUpdates The stream of notifications of updates to any orders.
 * If passed, calls to get() and getAll() will re-fetch the source whenever
 * any relevant orders are updated
 */
export function getOrderApiService(ordersUrl: string, withUpdates?: Observable<{ id: string; version: number }>) {
  const withUpdatesSuffix = withUpdates ? "withUpdates" : "";
  const url = `${ordersUrl}.${withUpdatesSuffix}`;
  if (!Object.prototype.hasOwnProperty.call(orderServices, url)) {
    orderServices[url] = new OrderService(ordersUrl, withUpdates);
  }
  return orderServices[url];
}
