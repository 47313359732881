import React from "react";
import { DismissableNotification } from "sharedFolder/components/common/DismissableNotification/DismissableNotification";
import { tryAgainText } from "sharedFolder/constants";

export type DistributionMessageType = "OrderFailed" | "SearchFailed" | "NextFailed" | "ResolveUserRolesFailed";
interface IDistributionMessagesProps {
  messageType?: DistributionMessageType;
  onClose: () => void;
}

export const DistributionMessages: React.FC<IDistributionMessagesProps> = (props: IDistributionMessagesProps) => {
  const { messageType } = props;
  if (!messageType) return null;
  return (
    <>
      {messageType === "ResolveUserRolesFailed" && (
        <DismissableNotification
          onClose={props.onClose}
          backgroundColor="red"
          title="Failed to resolve user roles"
          icon="warning"
          body="Please refresh the browser page and try again"
          aria-label="Messages"
        />
      )}
      {messageType === "SearchFailed" && (
        <DismissableNotification
          onClose={props.onClose}
          backgroundColor="red"
          title={"Search Request Failed"}
          icon={"warning"}
          body={tryAgainText}
          aria-label="Messages"
        />
      )}
      {messageType === "OrderFailed" && (
        <DismissableNotification
          onClose={props.onClose}
          backgroundColor="red"
          title={"Failed to get order"}
          icon={"warning"}
          body={tryAgainText}
          aria-label="Messages"
        />
      )}
      {messageType === "NextFailed" && (
        <DismissableNotification
          onClose={props.onClose}
          backgroundColor="red"
          title={"Submit failed"}
          icon={"warning"}
          body={tryAgainText}
          aria-label="Messages"
        />
      )}
    </>
  );
};
