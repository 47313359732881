import { anyOrderNegotiationStore, RouteSeed } from "@/models";
import { Offerrep } from "@/components/Offerrep";
import { Nav, NotesAndAttachments, Summary } from "@/components/Offerrep/Nav";

export const offerrep = {
  seaHeaderHidden: true,
  path: "/offerrep/:offerRepToken",
  comp: Offerrep,
  pageTitle: "Offer",
  children: {
    tabs: {
      path: "/",
      comp: Nav,
      nav: ["offerrep.tabs.summary", "offerrep.tabs.notesAndAttachments"],
      children: {
        summary: {
          path: "/summary",
          comp: Summary,
          pageTitle: "Offer Summary",
          label: "Summary",
        },
        notesAndAttachments: {
          path: "/notes-and-attachments",
          comp: NotesAndAttachments,
          pageTitle: "Offer Notes & Attachments",
          label: "Notes & Attachments",
          getMiscInfo: ({ offerRepToken }) => {
            const order = anyOrderNegotiationStore.upsertOrderModel({ id: "OFFERREP_MOCK_ORDER_ID" });
            const negotiation = order.upsertNegotiation({ id: offerRepToken });
            const { version } = negotiation?._ || {}; // eslint-disable-line @typescript-eslint/no-unused-vars
            const loading = order?._.status.loading;

            let badgeCount = negotiation?.notes?.owner ? 1 : 0;
            badgeCount += (negotiation?.attachments ?? []).length;

            return { badgeCount, loading };
          },
        },
      },
    },
  },
  redirect: "offerrep.tabs.summary",
  seaChatWidgetProps: {
    position: "bottomRight",
  },
} as RouteSeed;
