import { OrderArchivingResponsePayload, tradeAPI } from "@/apis";
import { Order } from "@/models/Order";
import { ArchivingAction, BaseArchivingAction } from "./BaseArchivingAction";
import { config as tradeConfig } from "@/config";

export class BulkOrdersArchiveAction extends BaseArchivingAction<Order[], OrderArchivingResponsePayload[]> {
  protected countOfRetries = tradeConfig.archiveWaitPolicy?.bulkWaitRetryCount ?? this.BulkDefaultWaitRetryCount;
  protected retryDelay = tradeConfig.archiveWaitPolicy?.bulkWaitRetryDelayMs ?? this.DefaultRetryDelayMs;
  protected actionName = ArchivingAction.BulkArchive;
  protected operationName = "Bulk Archiving";
  protected finishedOperationName = "archived";
  protected entityName = "order";

  protected getEntityArray = () => this.model;
  protected isModelUpdatedAsync = async () => this.model.every((order: Order) => this.getValidationAction()(order));

  protected archivingActionAsync = async () => {
    const payload = this.model.map((order) => ({
      orderId: order.id,
      updateToken: order.updateToken,
    }));

    return await tradeAPI.bulkArchiveOrders(payload);
  };
}
