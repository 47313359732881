import vesselColorStyles from "__legacy/sharedFolder/styles/vessel-colors.module.scss";

export function vesselColor(color: undefined | string) {
  let vesselStyle;

  switch (color) {
    case "green":
      vesselStyle = vesselColorStyles.positiveText;
      break;
    case "grey":
      vesselStyle = vesselColorStyles.greyText;
      break;
    case "yellow":
      vesselStyle = vesselColorStyles.yellowText;
      break;
    default:
      vesselStyle = "";
  }

  return vesselStyle;
}
