import React from "react";
import { RangeInput } from "___REFACTOR___/components";
import { Field } from "../../Field";
import { Container } from "../../Container";

function RangeField(props: RangeField.Props) {
  return (
    <Container {...props} type="Range">
      <RangeInput {...props} />
    </Container>
  );
}

export { RangeField };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace RangeField {
  export interface Props extends Omit<RangeInput.Props, "type">, Omit<Field.Props, "value" | "onChange"> {}
}
