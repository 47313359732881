import { useMemo } from "react";
import { RouteSeed, router, ownerOrderNegotiationStore } from "@/models";
import { RouteStub } from "@/components";
import { FormContext } from "@/components/common/Form/Context/Context";
import { Orders, Context } from "@/components/Orders";
import { OrderStub } from "@/components/Orders/OrderStub";
import {
  Negotiation as NegotiationComp,
  Liftings,
  Details,
  NotesAndAttachments,
  Form,
  MainTermsDetails,
} from "@/components/Orders/Order/Negotiation";
import { commonColumnMap, getNegotiationColumnMap } from "@/components/Orders/Grid/columns";
import { Capture, New } from "@/components/Orders/Order";

const orderDetails = {
  path: "/order-details",
  comp: Details,
  label: "Order Details",
  pageTitle: "Order Details",
};

const form = {
  comp: Form,
  label: "Negotiation",
  pageTitle: "Negotiation",
};

const mainTermsDetails = {
  path: "/details",
  comp: MainTermsDetails,
  label: "Negotiation",
  pageTitle: "Negotiation",
};

const liftings = {
  comp: Liftings,
  label: "Liftings",
  pageTitle: "Liftings",
};

const notesAndAttachments = {
  comp: NotesAndAttachments,
  label: "Notes & Attachments",
  pageTitle: "Notes & Attachments",
  getMiscInfo: ({ orderId, negotiationId }) => {
    const order = orderId ? ownerOrderNegotiationStore.upsertOrderModel({ id: orderId }) : undefined;
    const negotiation = order?.upsertNegotiation({ id: negotiationId });
    const loading = ownerOrderNegotiationStore.orderArrayStatus.loading;

    let badgeCount = negotiation?.notes?.owner ? 1 : 0;
    badgeCount += (negotiation?.attachments ?? []).length;

    return { badgeCount, loading };
  },
};

export const owner = {
  path: "/owner-dashboard",
  getMiscInfo,
  comp: OrdersWrapper,
  icon: "vessel-search",
  desc: "Owner Dashboard",
  auth: (auth) => {
    const companyRoleMap = auth.trade.user?._.companyRoleMap;

    return companyRoleMap?.owner;
  },
  sidenav: ["orders", "owner", "coa"],
  children: {
    order: {
      path: "/:orderId/:negotiationId",
      comp: OrderStub,
      children: {
        new: {
          comp: New,
          redirect: "./capture",
          children: {
            capture: {
              comp: Capture,
              label: "Deal Capture",
            },
          },
        },
        negotiation: {
          comp: NegotiationComp,
          children: {
            mainTerms: {
              comp: RouteStub,
              path: "/main-terms",
              redirect: "./mainTermsDetails",
              nav: ["./mainTermsDetails", "./orderDetails", "./notesAndAttachments"],
              children: {
                mainTermsDetails,
                orderDetails,
                notesAndAttachments,
              },
            },
            initialTerms: {
              comp: RouteStub,
              nav: ["./form", "./orderDetails", "./notesAndAttachments"],
              redirect: "./form",
              path: "/initial-terms",
              children: {
                form,
                orderDetails,
                notesAndAttachments,
              },
            },
          },
        },
        lifting: {
          comp: NegotiationComp,
          redirect: "./liftings",
          nav: ["./liftings", "./orderDetails", "./notesAndAttachments"],
          children: {
            liftings,
            orderDetails,
            notesAndAttachments,
          },
        },
      },
    },
  },
} as RouteSeed;

function getMiscInfo() {
  return {
    unseenCount: ownerOrderNegotiationStore.unseenNegotiationCount,
    loading: ownerOrderNegotiationStore.negotiationArrayStatus.loading,
  };
}

function OrdersWrapper(props) {
  const context = useMemo(getContext, []);

  return <Orders {...props} context={context} />;
}

function getContext() {
  context.orderNegotiationStore = ownerOrderNegotiationStore;
  context.routes.orders = router.get("owner");
  context.routes.order.index = router.get("owner.order");
  context.routes.order.new = router.get("owner.order.new");
  context.routes.order.negotiation = router.get("owner.order.negotiation");
  context.routes.order.negotiationInitialTerms = router.get("owner.order.negotiation.initialTerms");
  context.routes.order.negotiationMainTerms = router.get("owner.order.negotiation.mainTerms");
  context.routes.order.lifting = router.get("owner.order.lifting");

  if (context.view.grid.props) {
    const negotiationColumnMap = getNegotiationColumnMap();

    context.view.grid.props.columnDefs = [
      commonColumnMap.gutterForUnseenBookmark,
      negotiationColumnMap.checkbox,
      negotiationColumnMap.accountAndOrderReference,
      negotiationColumnMap.cargoSizeAndCargoType,
      negotiationColumnMap.laycanOrPeriodAndNegotiationType,
      negotiationColumnMap.locations,
      negotiationColumnMap.vesselAndDWT,
      negotiationColumnMap.bidFreightRateOrHireRateAndDemurrageOrBallastBonus,
      negotiationColumnMap.offerFreightRateOrHireRateAndDemurrageOrBallastBonus,
      {
        ...negotiationColumnMap.circulatedByAndCreatedOn,
        $defaultColumnState: { sort: "desc", multiValueSortName: "createdOn" },
      },
      negotiationColumnMap.statusAndLastUpdated,
      negotiationColumnMap.chat,
      negotiationColumnMap.kebab,
    ];
  }

  return context;
}

export const context = {
  area: "owner-dashboard",

  routes: {
    order: {},
  },

  view: {
    grid: {
      type: "OwnerNegotiationGrid",
      name: "Owner negotiations",
      btn: {
        dealCapture: {
          dataTest: "Deal capture",
          icon: "flash-on",
          label: "deal capture",
          variant: "action",
        },
        kebab: {
          dataTest: "More actions",
          icon: "kebab",
          variant: "text",
        },
      },
      props: {},
    },
    order: {
      name: "Owner",
      suppressOverlay: true,
      creation: {
        title: "",
        buttons: {
          submit: "",
          cancel: "",
        },
        form: {
          elements: [],
          context: new FormContext(),
        },
      },
      negotiations: {
        grid: {},
      },
    },
  },

  dataTest: "owner",
} as Context;
