import { TradeAPI } from "@/apis";
import { DataModel, DataModelProps } from "../DataModel";
import {
  FreightRateEditor,
  HireRateEditor,
  BallastBonusEditor,
  DemurrageEditor,
  CleaningPriceEditor,
  SupplyPriceEditor,
} from "@/components";
import { LABELS } from "@/constants";
import { format } from "@/utils";

export class UnitValue<Data = UnitValueData> extends DataModel<Data> {
  onUpdate() {
    // @ts-ignore
    delete this.display;
    // @ts-ignore
    delete this.shortDisplay;
  }

  toString() {
    if (!this) return "";
    // @ts-ignore
    if (!isValid(this)) return "";

    // @ts-ignore
    return `$${format.monetary.display(this.value)} ${UNIT_VALUE_LABEL_MAP[this.unit]} ${this.notes || ""}`;
  }

  toGridView() {
    if (!this) return "";
    // @ts-ignore
    if (!isValid(this)) return "";

    // @ts-ignore
    return `$${format.monetary.display(this.value)} ${UNIT_VALUE_LABEL_MAP[this.unit]}`;
  }

  isValid() {
    // @ts-ignore
    return isValid(this);
  }
}

function isValid(data: UnitValueDataUnion) {
  return typeof data.value === "number" && typeof data.unit === "string";
}

export class UnitValueProps<Data> extends DataModelProps<Data> {}

export const UNIT_VALUE_LABEL_MAP = {
  PerDay: "Per Day",
  LumpSum: "Lump Sum",
  PerMT: "Per MT",
};

UnitValueProps.prototype.sortKey = "value";
UnitValueProps.prototype.JSONDefaults = {
  unit: "PerDay",
};
UnitValueProps.prototype.units = ["PerDay", "LumpSum"];

/* -------------------------------------------------------------------------- */
/*                                 FreightRate                                */
/* -------------------------------------------------------------------------- */

export class FreightRate extends UnitValue<FreightRateData> {}

export class FreightRateProps extends UnitValueProps<FreightRateData> {}

FreightRateProps.prototype.editor = FreightRateEditor;
FreightRateProps.prototype.sortKey = "value";
FreightRateProps.prototype.label = LABELS["freightRate"];
FreightRateProps.prototype.JSONDefaults = {
  unit: "PerMT",
};
FreightRateProps.prototype.units = ["PerMT", "LumpSum"];

FreightRate.prototype.Props = FreightRateProps;
FreightRate.prototype._ = new FreightRateProps();

/* -------------------------------------------------------------------------- */
/*                                  HireRate                                  */
/* -------------------------------------------------------------------------- */

export class HireRate extends UnitValue<HireRateData> {}

export class HireRateProps extends UnitValueProps<HireRateData> {}

HireRateProps.prototype.editor = HireRateEditor;
HireRateProps.prototype.label = LABELS["hireRate"];

HireRate.prototype.Props = HireRateProps;
HireRate.prototype._ = new HireRateProps();

/* -------------------------------------------------------------------------- */
/*                                  CleaningPrice                             */
/* -------------------------------------------------------------------------- */

export class CleaningPrice extends UnitValue<CleaningPriceData> {}

export class CleaningPriceProps extends UnitValueProps<CleaningPriceData> {}

CleaningPriceProps.prototype.editor = CleaningPriceEditor;
CleaningPriceProps.prototype.label = LABELS["cleaningPrice"];

CleaningPriceProps.prototype.units = ["PerMT", "PerDay", "LumpSum"];

CleaningPrice.prototype.Props = CleaningPriceProps;
CleaningPrice.prototype._ = new CleaningPriceProps();

/* -------------------------------------------------------------------------- */
/*                                  SupplyPrice                             */
/* -------------------------------------------------------------------------- */

export class SupplyPrice extends UnitValue<SupplyPriceData> {}

export class SupplyPriceProps extends UnitValueProps<SupplyPriceData> {}

SupplyPriceProps.prototype.editor = SupplyPriceEditor;
SupplyPriceProps.prototype.label = LABELS["supplyPrice"];

SupplyPriceProps.prototype.units = ["PerMT", "PerDay", "LumpSum"];

SupplyPrice.prototype.Props = SupplyPriceProps;
SupplyPrice.prototype._ = new SupplyPriceProps();
/* -------------------------------------------------------------------------- */
/*                                  Demurrage                                 */
/* -------------------------------------------------------------------------- */

export class Demurrage extends UnitValue<DemurrageData> {}

export class DemurrageProps extends UnitValueProps<DemurrageData> {}

DemurrageProps.prototype.editor = DemurrageEditor;
DemurrageProps.prototype.sortKey = "value";
DemurrageProps.prototype.label = LABELS["demurrage"];

Demurrage.prototype.Props = DemurrageProps;
Demurrage.prototype._ = new DemurrageProps();

/* -------------------------------------------------------------------------- */
/*                                BallastBonus                                */
/* -------------------------------------------------------------------------- */

export class BallastBonus extends UnitValue<BallastBonusData> {}

export class BallastBonusProps extends UnitValueProps<BallastBonusData> {}

BallastBonusProps.prototype.editor = BallastBonusEditor;
BallastBonusProps.prototype.sortKey = "value";
BallastBonusProps.prototype.label = LABELS["ballastBonus"];
BallastBonusProps.prototype.JSONDefaults = {
  unit: "LumpSum",
};

BallastBonus.prototype.Props = BallastBonusProps;
BallastBonus.prototype._ = new BallastBonusProps();

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

export interface UnitValue<Data = UnitValueData> {}
export interface UnitValueProps<Data = UnitValueData> {
  units: TradeAPI["UnitValue"]["unit"][];
}

export interface FreightRate extends FreightRateData {}
export interface HireRate extends HireRateData {}
export interface CleaningPrice extends CleaningPriceData {}
export interface SupplyPrice extends SupplyPriceData {}
export interface Demurrage extends DemurrageData {}
export interface BallastBonus extends BallastBonusData {}

type FreightRateData = DeepPartial<TradeAPI["FreightRate"]>;
type HireRateData = DeepPartial<TradeAPI["HireRate"]>;
type CleaningPriceData = DeepPartial<TradeAPI["CleaningPrice"]>;
type SupplyPriceData = DeepPartial<TradeAPI["SupplyPrice"]>;
type DemurrageData = DeepPartial<TradeAPI["Demurrage"]>;
type BallastBonusData = DeepPartial<TradeAPI["BallastBonus"]>;
type UnitValueData = TradeAPI["UnitValue"];
export type UnitValueDataUnion = FreightRateData | HireRateData | DemurrageData | BallastBonusData | CleaningPrice | SupplyPrice;

interface UnitData {
  label: string;
  value: string;
}
