import { from } from "rxjs";
import { IVesselSearch } from "../Models/IVessel";
import { IVesselSearchApi } from "../../api/models/IVessel";
import { mapVesselsSearchResults } from "../mappers/mapVessel";

const getDivisionQuery = (division: string): string => {
  if (division.toLowerCase() === "tankers") {
    return "&division=tankers";
  } else {
    return "";
  }
};

const vesselSearch = (baseUrl: string, term: string, division: string): Promise<IVesselSearch[]> => {
  const url = `${baseUrl}/vessels?term=${encodeURIComponent(term.trim())}${getDivisionQuery(division)}`;
  return from(
    fetch(url)
      .then((res) => {
        return res.json();
      })
      .then((data: IVesselSearchApi[]) => {
        return data && data.length ? data.map(mapVesselsSearchResults) : [];
      })
      .catch((e) => {
        return [];
      })
  ).toPromise();
};

export { vesselSearch };
