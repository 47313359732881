import * as React from "react";
import styles from "./VesselPanel.module.scss";
import { Panel, PanelType } from "office-ui-fabric-react";
import { withValidation, IValidationContainerProps } from "sharedFolder/contexts/ValidationContainer";
import { PanelCloseButton } from "sharedFolder/components/common/CloseButton/CloseButton";
import { IVesselForm } from "sharedFolder/Models/IVessel";
import { VesselPanelActions } from "sharedFolder/components/common/VesselPanelActions/VesselPanelActions";
import { VesselNominate } from "sharedFolder/components/common/VesselNominate/VesselNominate";
import { mapToVesselForm } from "sharedFolder/mappers/mapToVesselForm";
import { VesselDisplay } from "sharedFolder/components/common/VesselDisplay/VesselDisplay";
import { IVesselView } from "sharedFolder/Models/IDetails";

interface IVesselPanelProps {
  /**
   * Dismiss the current panel
   */
  onDismiss: () => void;

  /**
   * Nominate a selected vessel
   */
  onNominate?: (vessel: IVesselView) => void;

  /**
   * Nominate and accept a vessel
   */
  onNominateAccept?: (vessel: IVesselView) => void;

  /**
   * Accept a vessel
   */
  onAccept?: (vessel: IVesselView) => void;

  /**
   * Reject a vessel
   */
  onReject?: (vessel: IVesselView) => void;
  /**
   * Reject a vessel
   */
  onEdit?: (vessel: IVesselView) => void;
  /**
   * Add a vessel
   */
  onAdd?: (vessel: IVesselView) => void;
  /**
   * Current Vessel (optional)
   * If present, will enable Accept/Reject options.
   */
  vessel?: IVesselView;

  /**
   * Autofocus
   */
  focus?: boolean;

  nominatedVessels?: IVesselView[];
  isReadOnly?: boolean;
}

interface IVesselPanelState {
  vesselForm?: IVesselForm;
  vesselAlreadySelected?: boolean;
}

class VesselPanelComponent extends React.Component<IVesselPanelProps & IValidationContainerProps, IVesselPanelState> {
  constructor(props: IVesselPanelProps & IValidationContainerProps) {
    super(props);
    this.state = { vesselForm: this.mapToVesselForm(this.props.vessel) };

    this.handleAcceptVessel = this.handleAcceptVessel.bind(this);
    this.handleNominateAcceptVessel = this.handleNominateAcceptVessel.bind(this);
    this.handleRejectVessel = this.handleRejectVessel.bind(this);
    this.handleNominate = this.handleNominate.bind(this);
    this.handleVesselAlreadySelected = this.handleVesselAlreadySelected.bind(this);
    this.handleVesselChanged = this.handleVesselChanged.bind(this);
    this.handleEditVessel = this.handleEditVessel.bind(this);
    this.handleAddVessel = this.handleAddVessel.bind(this);
  }

  public render() {
    return (
      <Panel
        isOpen={true}
        type={PanelType.medium}
        onDismiss={this.props.onDismiss}
        onRenderNavigation={() => <PanelCloseButton onClick={this.props.onDismiss} />}
      >
        <div data-test="vessel-panel">
          {this.props.vessel ? (
            <>
              <h2>View Vessel</h2>
              <VesselDisplay
                vessel={this.props.vessel}
                onVesselChange={this.handleEditVessel}
                disabled={Boolean(this.props.isReadOnly)}
              />
            </>
          ) : (
            <>
              <h2>Nominate a Vessel</h2>
              <VesselNominate nominatedVessels={this.props.nominatedVessels} onVesselChanged={this.handleVesselChanged} />
              {this.state.vesselAlreadySelected && (
                <label className={styles.alreadyNominated}>
                  This vessel has already been nominated or rejected. Please select another vessel.
                </label>
              )}
            </>
          )}
          <VesselPanelActions
            isValid={this.props.isValid && this.state.vesselForm && this.state.vesselForm.isValid}
            acceptVessel={this.props.onAccept && this.handleAcceptVessel}
            rejectVessel={this.props.onReject && this.handleRejectVessel}
            nominateAcceptVessel={this.props.onNominateAccept && this.handleNominateAcceptVessel}
            nominateVessel={this.props.onNominate && this.handleNominate}
            addVessel={this.props.onAdd && this.handleAddVessel}
            onDismiss={this.props.onDismiss}
            vessel={this.props.vessel}
          />
        </div>
      </Panel>
    );
  }

  private handleVesselChanged(vessel: IVesselForm | undefined) {
    this.setState({ vesselForm: vessel });
  }

  /**
   * Trigger when a vessel that has already been selected is selected again.
   * @param vessel Vessel
   */
  private handleVesselAlreadySelected(vessel: IVesselView | undefined) {
    this.setState({
      vesselAlreadySelected: true,
    });
  }

  /**
   * Nominate the current selected vessel
   */
  private handleNominate() {
    const vessel = mapToVesselForm(this.state.vesselForm);
    if (vessel) {
      this.props.onNominate && this.props.onNominate(vessel);
    }
  }

  /**
   * Accept the vessel we're currently viewing
   */
  private handleAcceptVessel() {
    const vessel = mapToVesselForm(this.state.vesselForm);
    if (vessel) {
      this.props.onAccept && this.props.onAccept(vessel);
    }
  }

  /**
   * Nominate and accept the vessel in one action
   */
  private handleNominateAcceptVessel() {
    const vessel = mapToVesselForm(this.state.vesselForm);
    if (vessel) {
      this.props.onNominateAccept && this.props.onNominateAccept(vessel);
    }
  }

  private handleAddVessel() {
    const vessel = mapToVesselForm(this.state.vesselForm);
    if (vessel) {
      this.props.onAdd && this.props.onAdd(vessel);
    }
  }

  /**
   * Reject the vessel we're currently viewing
   */

  private handleRejectVessel() {
    const vessel = mapToVesselForm(this.state.vesselForm);
    if (vessel) {
      this.props.onReject && this.props.onReject(vessel);
    }
  }

  private handleEditVessel(vessel: IVesselView) {
    this.props.onEdit && this.props.onEdit(vessel);
  }

  private mapToVesselForm(vessel: IVesselView | undefined): IVesselForm | undefined {
    if (vessel) {
      const newVessel = {
        vessel: {
          dwt: vessel.dwt,
          buildYear: vessel.buildYear,
          vesselName: vessel.vesselName,
          vesselIMO: vessel.vesselIMO,
          arcVesselId: vessel.arcVesselId,
          consumptions: vessel.consumptions,
        },
        ownerChain: vessel.ownerChain,
        eta: vessel.eta,
        speedAndConsumption: vessel.speedAndConsumption,
        itinerary: vessel.itinerary,
      };
      return newVessel;
    }
  }
}

export const VesselPanel = withValidation<IVesselPanelProps>(VesselPanelComponent);
