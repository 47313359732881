import { Format, formatNumberToStringDisplay } from "./../../../negotiations/services/utils/converters";
import numeral from "numeral";
import { isFloat } from "validator";
import { ICargoSizeView, IUnitValueView, ICOACargoSizeView } from "sharedFolder/Models/IDetails";
import { IBunkerView } from "sharedFolder/Models/IBunker";
import { valueFromString } from "./NumberInput/NumberInput";

export const isUnitValueValid = (value?: null | string, required = false): boolean => {
  if (value === "" || value === undefined || value === null) {
    if (!required) {
      return true;
    } else {
      return false;
    }
  } else {
    const hundredMill = 100000000;
    const isNumberOrFloatOrWithinRange = isFloat(value, {
      min: 0,
      max: hundredMill,
    });
    return isNumberOrFloatOrWithinRange;
  }
};

export const unitValueFormatter = (value: null | string) => {
  if (value === "" || value === undefined || value === null) {
    return "";
  }
  // do not format if the value is invalid
  return isUnitValueValid(value) ? numeral(value).format(`0,0.000`) : value;
};

export function unitValueTextOrDisplay(value: IUnitValueView): string | undefined {
  if (value.display) return value.display;
  return unitValueText(value);
}
export function unitValueText(value: IUnitValueView): string | undefined {
  return !isUnitValueValid(value.value) || !value.value
    ? undefined
    : `$${unitValueFormatter(value.value)} ${value.unitDisplay || ""} ${value.notes || ""}`;
}

const isOptionValid = (value: null | string, required = false): boolean => {
  if (value === "" || value === undefined || value === null) {
    if (!required) {
      return true;
    } else {
      return false;
    }
  }

  return Boolean(value);
};

export const isCargoSizeSizeValid = (value: null | string, required = false): boolean => {
  if (value === "" || value === undefined || value === null) {
    if (!required) {
      return true;
    } else {
      return false;
    }
  } else {
    const tenMill = 10000000;
    const isNumberOrFloatOrWithinRange = isFloat(value.replace(/,/g, ""), {
      min: 0,
      max: tenMill,
    });
    return isNumberOrFloatOrWithinRange;
  }
};

export const isCargoSizeVarianceValid = (value: null | string, option: string | null, required = false): boolean => {
  if (value === "0") {
    if (option === "MIN/MAX") {
      return true;
    } else {
      return false;
    }
  }
  if (value === "" || value === null) {
    if (!required) {
      return true;
    } else {
      return false;
    }
  } else {
    if (option === "MIN/MAX") {
      return false;
    }
    const isNumberAndWithinRange = isFloat(value.replace(/,/g, ""), {
      min: 1,
      max: 100,
    });
    return isNumberAndWithinRange;
  }
};

export const isCargoSizeValid = (value: ICargoSizeView, required = false): boolean => {
  const optionValid = value && isOptionValid(value.option);
  const valueValid = value && isCargoSizeSizeValid(value.value, required);
  const varianceValid = value && isCargoSizeVarianceValid(value.variance, value.option, required);

  return Boolean(valueValid) && Boolean(varianceValid) && Boolean(optionValid);
};

export const areMinAndMaxValid = (value?: ICOACargoSizeView) => {
  const minValid = isCargoSizeSizeValid(value?.min || null);
  const maxValid = isCargoSizeSizeValid(value?.max || null);
  const isMinLessThanMaxValid = (min?: string, max?: string) => {
    const numberMin = valueFromString(min);
    const numberMax = valueFromString(max);
    return minValid && maxValid && numberMin && numberMax ? numberMin < numberMax : true; //if the validation pass for min and max and the numbers are not undefined, then compare the numbers otherwise it's valid (since cargo size is not a required field)
  };

  return minValid && maxValid && isMinLessThanMaxValid(value?.min, value?.max);
};

export const isCOACargoSizeValid = (value?: ICOACargoSizeView, required?: boolean) => {
  const varianceValid = isCargoSizeVarianceValid(value?.variance || null, value?.option || null, required);

  return areMinAndMaxValid(value) && varianceValid;
};

export const cargoSizeFormatter = (value: undefined | string) => {
  if (value === "" || value === undefined || value === null) {
    return "";
  }
  // format
  return isCargoSizeSizeValid(value) ? numeral(value).format(`0,0`) : value;
};

export function bunkerFieldDisplay(value: IBunkerView | undefined): string | undefined {
  return value && (value.fuelTypes || value.notes || value.price || value.quantity)
    ? bunkerFieldDisplayFormatter(value, "", Format.FIXED_TO)
    : undefined;
}

export function bunkerFieldDisplayFormatter(
  { price, quantity, fuelTypes, notes }: IBunkerView,
  defaultValue: string,
  formattingType: Format
) {
  const isFieldFilled = price || quantity || fuelTypes || notes;

  /**Differentiated logic of numbers formatting for different screens on UI*/

  const priceText = price ? `$${formatNumberToStringDisplay(price, formattingType)}` : "";
  const quantityText = quantity ? `${formatNumberToStringDisplay(quantity, Format.DEFAULT)} MT` : "";
  const fuelTypesText = fuelTypes ? `Fuel types: ${fuelTypes}` : "";
  const notesText = notes ? `${notes}` : "";

  function getSeparator(place: SeparatorPlace) {
    switch (place) {
      case SeparatorPlace.Quantity:
        return quantity && price ? " x " : "";
      case SeparatorPlace.FuelTypes:
        return fuelTypes && (price || quantity) ? ` / ` : "";
      case SeparatorPlace.Notes:
        return notes && (fuelTypes || price || quantity) ? ` / ` : "";
      default:
        return "";
    }
  }

  return isFieldFilled
    ? `${priceText}${getSeparator(SeparatorPlace.Quantity)}${quantityText}${getSeparator(
        SeparatorPlace.FuelTypes
      )}${fuelTypesText}${getSeparator(SeparatorPlace.Notes)}${notesText}`
    : defaultValue;
}

enum SeparatorPlace {
  Quantity,
  FuelTypes,
  Notes,
}
