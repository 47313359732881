import { Component } from "react";
import { IMinimalConfigurationContext, MinimalConfigurationContext } from "__legacy/sharedFolder/MinimalConfigurationContext";
import { CommissionEditor } from "../common/Editors/Commission/Commission";
import {
  ICommissionView,
  ICargoTypeView,
  ILaycanView,
  IAccountView,
  ILiftingsView,
  liftingsDisplay,
  INominationsView,
  nominationsDisplay,
  ICOACargoSizeView,
  IPeriodView,
  INotesView,
  IUnitValueView,
} from "sharedFolder/Models/IDetails";
import { IOrderCOACreate, INegotiableView, INegotiableViewOptional } from "../../models/NegotiationCreateView";
import { CreateNegotiationRow } from "./CreateNegotiationRow";
import { commissionText, cargoTypeText } from "../../services/client/SummaryService";
import styles from "./OrderDetails.module.scss";
import { CreateNegotiationSectionHeader } from "./CreateNegotiationSectionHeader";
import { Excluded } from "./Excluded";
import { ValidationContext } from "sharedFolder/contexts/ValidationContext";
import { withContext } from "sharedFolder/contexts/withContext";
import { ChartererSearch } from "sharedFolder/components/common/ChartererSearch/ChartererSearch";
import PeriodEditor from "sharedFolder/components/editors/LaycanEditor/LaycanEditor";
import { LiftingsEditor } from "sharedFolder/components/editors/LiftingsEditor/LiftingsEditor";
import { NominationsEditor } from "sharedFolder/components/editors/NominationsEditor/NominationsEditor";
import { COACargoSizeEditor } from "sharedFolder/components/editors/COACargoSizeEditor/COACargoSizeEditor";
import { ILocationView } from "sharedFolder/Models/ILocation";
import { Notes } from "sharedFolder/components/common/Notes/Notes";
import { mapLaycan } from "sharedFolder/mappers/mapLaycan";
import { CargoTypeEditor } from "sharedFolder/components/editors/CargoTypeEditor/CargoTypeEditor";
import { LocationEditor } from "sharedFolder/components/editors/LocationEditor/LocationEditor";
import { FreightRateOptions } from "sharedFolder/Models/IFreightRate";
import { DemurrageOptions } from "sharedFolder/Models/IDemurrage";
import { NewUnitValueEditorWithValidContext } from "sharedFolder/components/editors/NewUnitValueEditor/NewUnitValueEditor";
import { SubsTextEditorWithValidContext } from "sharedFolder/components/editors/SubsTextEditor/SubsTextEditor";
import { ISubsTextView } from "api/negotiations/models";
import { Period } from "@/models";

interface IOrderDetailsProps {
  className?: string;
  orderId?: string;
  orderData: IOrderCOACreate;
  ctradeUrl?: string;
  onOrderChanged: (newValue: IOrderCOACreate) => void;
}

interface IOrderDetailsState {
  expandedItem: keyof IOrderCOACreate | null;
}

class AccountRow extends CreateNegotiationRow<INegotiableViewOptional<IAccountView>, IOrderCOACreate> {}
class CargoTypeRow extends CreateNegotiationRow<INegotiableViewOptional<ICargoTypeView>, IOrderCOACreate> {}
class CommissionRow extends CreateNegotiationRow<INegotiableViewOptional<ICommissionView>, IOrderCOACreate> {}
class PeriodRow extends CreateNegotiationRow<INegotiableView<IPeriodView>, IOrderCOACreate> {}
class LiftingsRow extends CreateNegotiationRow<INegotiableView<ILiftingsView>, IOrderCOACreate> {}
class LocationRow extends CreateNegotiationRow<INegotiableViewOptional<ILocationView>, IOrderCOACreate> {}
class FreightRateRow extends CreateNegotiationRow<INegotiableViewOptional<IUnitValueView>, IOrderCOACreate> {}
class DemurrageRow extends CreateNegotiationRow<INegotiableViewOptional<IUnitValueView>, IOrderCOACreate> {}
class NominationsRow extends CreateNegotiationRow<INegotiableView<INominationsView>, IOrderCOACreate> {}
class NotesRow extends CreateNegotiationRow<INegotiableViewOptional<INotesView>, IOrderCOACreate> {}
class CargoSizeRow extends CreateNegotiationRow<INegotiableView<ICOACargoSizeView>, IOrderCOACreate> {}
class CommercialSubsRow extends CreateNegotiationRow<INegotiableViewOptional<ISubsTextView>, IOrderCOACreate> {}
class OperationalSubsRow extends CreateNegotiationRow<INegotiableViewOptional<ISubsTextView>, IOrderCOACreate> {}

class ExcludedOrderItems extends Excluded<IOrderCOACreate> {}

/**
 * Placeholder for Order Details widget
 */
class OrderDetailsComponent extends Component<IOrderDetailsProps & IMinimalConfigurationContext, IOrderDetailsState> {
  constructor(props: IOrderDetailsProps & IMinimalConfigurationContext) {
    super(props);

    this.state = {
      expandedItem: null,
    };

    this.handleCargoTypeChanged = this.handleCargoTypeChanged.bind(this);
    this.handleCargoTypeValueChanged = this.handleCargoTypeValueChanged.bind(this);

    this.handleDemurrageChanged = this.handleDemurrageChanged.bind(this);
    this.handleDemurrageValueChanged = this.handleDemurrageValueChanged.bind(this);

    this.handleFreightRateChanged = this.handleFreightRateChanged.bind(this);
    this.handleFreightRateValueChanged = this.handleFreightRateValueChanged.bind(this);

    this.handleFocusRow = this.handleFocusRow.bind(this);
    this.handleChartererAccountChanged = this.handleChartererAccountChanged.bind(this);
    this.handleChartererAccountValueChanged = this.handleChartererAccountValueChanged.bind(this);
    this.handleAddressCommissionChanged = this.handleAddressCommissionChanged.bind(this);
    this.handleAddressCommissionValueChanged = this.handleAddressCommissionValueChanged.bind(this);
    this.handleBrokerCommissionValueChanged = this.handleBrokerCommissionValueChanged.bind(this);
    this.handleBrokerCommissionChanged = this.handleBrokerCommissionChanged.bind(this);
    this.handlePeriodChanged = this.handlePeriodChanged.bind(this);
    this.handleLiftingsChanged = this.handleLiftingsChanged.bind(this);
    this.handleLiftingsValueChanged = this.handleLiftingsValueChanged.bind(this);
    this.handleLoadLocationChanged = this.handleLoadLocationChanged.bind(this);
    this.handleLoadLocationValueChanged = this.handleLoadLocationValueChanged.bind(this);
    this.handleDischargeLocationChanged = this.handleDischargeLocationChanged.bind(this);
    this.handleDischargeLocationValueChanged = this.handleDischargeLocationValueChanged.bind(this);
    this.handleCOANotesChanged = this.handleCOANotesChanged.bind(this);
    this.handleCOANotesValueChanged = this.handleCOANotesValueChanged.bind(this);
    this.handleNominationsChanged = this.handleNominationsChanged.bind(this);
    this.handleNominationsValueChanged = this.handleNominationsValueChanged.bind(this);
    this.handleCargoSizesChanged = this.handleCargoSizesChanged.bind(this);
    this.handleCargoSizesValueChanged = this.handleCargoSizesValueChanged.bind(this);
    this.handleIncludeDetail = this.handleIncludeDetail.bind(this);
    this.handleCommercialSubsChanged = this.handleCommercialSubsChanged.bind(this);
    this.handleCommercialSubsValueChanged = this.handleCommercialSubsValueChanged.bind(this);
    this.handleOperationalSubsChanged = this.handleOperationalSubsChanged.bind(this);
    this.handleOperationalSubsValueChanged = this.handleOperationalSubsValueChanged.bind(this);
  }

  public render() {
    const excludedItems: Array<{
      key: keyof IOrderCOACreate;
      included?: boolean;
      label: string;
    }> = [
      {
        key: "addressCommission",
        included: this.props.orderData.addressCommission.included,
        label: "Address Commission",
      },
      {
        key: "brokerCommission",
        included: this.props.orderData.brokerCommission.included,
        label: "Broker Commission",
      },
      {
        key: "cargoType",
        included: this.props.orderData.cargoType.included,
        label: "Cargo Type(s)",
      },
      {
        key: "demurrage",
        included: this.props.orderData.demurrage.included,
        label: "Demurrage",
      },
      {
        key: "freightRate",
        included: this.props.orderData.demurrage.included,
        label: "Freight Rate",
      },
      {
        key: "loadLocation",
        included: this.props.orderData.loadLocation.included,
        label: "Load Location(s)",
      },
      {
        key: "dischargeLocation",
        included: this.props.orderData.dischargeLocation.included,
        label: "Discharge Location(s)",
      },
    ];

    if (this.props.orderData.period.value.to && this.props.orderData.period.value.from) {
      this.props.orderData.period.value = {
        ...this.props.orderData.period.value,
        display: Period.prototype.toView.call(this.props.orderData.period.value),
        shortDisplay: Period.prototype.toView.call(this.props.orderData.period.value),
      };
    }

    return (
      <ValidationContext.Consumer>
        {(validation) => (
          <>
            <CreateNegotiationSectionHeader header="Order Terms" />
            <div className={styles.details}>
              <AccountRow
                label="Charterer"
                id="chartererAccount"
                expanded={this.state.expandedItem === "chartererAccount"}
                onExpand={this.handleFocusRow}
                value={{
                  ...this.props.orderData.chartererAccount,
                  included: true,
                }}
                onValueChanged={this.handleChartererAccountChanged}
                text={this.props.orderData.chartererAccount.value?.display}
                required={true}
                {...validation}
              >
                <ChartererSearch
                  id="chartererAccount"
                  baseUrl={this.props.ctradeUrl}
                  onChange={this.handleChartererAccountValueChanged}
                  selectedItem={this.props.orderData.chartererAccount.value}
                  focus={this.state.expandedItem === "chartererAccount"}
                />
              </AccountRow>
              <PeriodRow
                label="Period"
                id="period"
                expanded={this.state.expandedItem === "period"}
                onExpand={this.handleFocusRow}
                value={this.props.orderData.period}
                onValueChanged={this.handlePeriodChanged}
                text={this.props.orderData.period.value.display}
                required={true}
                {...validation}
              >
                <PeriodEditor
                  id="periodValue"
                  required={true}
                  url={this.props.ctradeUrl}
                  value={this.props.orderData.period.value}
                  onChange={(period) => this.handlePeriodValueChanged(period)}
                  focus={this.state.expandedItem === "period"}
                />
              </PeriodRow>
              <LiftingsRow
                label="Liftings"
                id="liftings"
                expanded={this.state.expandedItem === "liftings"}
                onExpand={this.handleFocusRow}
                value={this.props.orderData.liftings}
                onValueChanged={this.handleLiftingsChanged}
                text={this.props.orderData.liftings.value.display}
                required={true}
                {...validation}
              >
                <LiftingsEditor
                  focus={true}
                  value={this.props.orderData.liftings.value}
                  onChange={this.handleLiftingsValueChanged}
                  id="liftings"
                />
              </LiftingsRow>
              <NominationsRow
                label="Nominations"
                id="nominations"
                expanded={this.state.expandedItem === "nominations"}
                onExpand={this.handleFocusRow}
                value={{
                  ...this.props.orderData.nominations,
                  included: true,
                }}
                onValueChanged={this.handleNominationsChanged}
                text={this.props.orderData.nominations.value.display}
                required={true}
                {...validation}
              >
                <NominationsEditor
                  focus={true}
                  value={this.props.orderData.nominations.value}
                  onChange={this.handleNominationsValueChanged}
                  id="nominations"
                />
              </NominationsRow>
              <CargoTypeRow
                label="Cargo Type(s)"
                id="cargoType"
                expanded={this.state.expandedItem === "cargoType"}
                onExpand={this.handleFocusRow}
                value={this.props.orderData.cargoType}
                text={this.props.orderData.cargoType.value && this.props.orderData.cargoType.value.display}
                onValueChanged={this.handleCargoTypeChanged}
                {...validation}
              >
                <CargoTypeEditor
                  id="cargoTypeValue"
                  required={!this.props.orderData.cargoType.negotiable}
                  onChange={(cargoType) => {
                    this.handleCargoTypeValueChanged(
                      cargoType !== undefined
                        ? {
                            ...cargoType,
                            display: cargoTypeText(cargoType),
                          }
                        : undefined
                    );
                  }}
                  selectedItem={this.props.orderData.cargoType.value}
                  label="Cargo Type(s)"
                  focus={this.state.expandedItem === "cargoType"}
                />
              </CargoTypeRow>
              <CargoSizeRow
                label="Cargo Size(s)"
                id="coaCargoSize"
                expanded={this.state.expandedItem === "coaCargoSize"}
                onExpand={this.handleFocusRow}
                value={this.props.orderData.coaCargoSize}
                onValueChanged={this.handleCargoSizesChanged}
                text={this.props.orderData.coaCargoSize.value.display}
                required={true}
                {...validation}
              >
                <COACargoSizeEditor
                  id="coaCargoSize"
                  focus={true}
                  value={this.props.orderData.coaCargoSize.value}
                  onChange={this.handleCargoSizesValueChanged}
                />
              </CargoSizeRow>
              <CommissionRow
                label="Address Commission"
                id="addressCommission"
                expanded={this.state.expandedItem === "addressCommission"}
                onExpand={this.handleFocusRow}
                value={this.props.orderData.addressCommission}
                onValueChanged={this.handleAddressCommissionChanged}
                text={this.props.orderData.addressCommission.value && this.props.orderData.addressCommission.value.display}
                {...validation}
              >
                <CommissionEditor
                  id="addressCommissionValue"
                  label="Address Commission"
                  required={!this.props.orderData.addressCommission.negotiable}
                  value={
                    this.props.orderData.addressCommission.value ? this.props.orderData.addressCommission.value.value : undefined
                  }
                  onChange={this.handleAddressCommissionValueChanged}
                  focus={this.state.expandedItem === "addressCommission"}
                />
              </CommissionRow>
              <CommissionRow
                label="Broker Commission"
                id="brokerCommission"
                expanded={this.state.expandedItem === "brokerCommission"}
                onExpand={this.handleFocusRow}
                value={this.props.orderData.brokerCommission}
                onValueChanged={this.handleBrokerCommissionChanged}
                text={this.props.orderData.brokerCommission.value && this.props.orderData.brokerCommission.value.display}
                {...validation}
              >
                <CommissionEditor
                  id="brokerCommissionValue"
                  label="Broker Commission"
                  required={!this.props.orderData.brokerCommission.negotiable}
                  value={
                    this.props.orderData.brokerCommission.value ? this.props.orderData.brokerCommission.value.value : undefined
                  }
                  onChange={this.handleBrokerCommissionValueChanged}
                  focus={this.state.expandedItem === "brokerCommission"}
                />
              </CommissionRow>
              <LocationRow
                id="loadLocation"
                label="Load Location(s)"
                expanded={this.state.expandedItem === "loadLocation"}
                onExpand={this.handleFocusRow}
                value={this.props.orderData.loadLocation}
                onValueChanged={this.handleLoadLocationChanged}
                text={this.props.orderData.loadLocation.value && this.props.orderData.loadLocation.value.display}
                {...validation}
              >
                <LocationEditor
                  id="loadLocationValue"
                  required={!this.props.orderData.loadLocation.negotiable}
                  onChange={(value) => this.handleLoadLocationValueChanged(value)}
                  value={this.props.orderData.loadLocation.value}
                  ctradeUrl={this.props.ctradeUrl}
                  focus={this.state.expandedItem === "loadLocation"}
                />
              </LocationRow>
              <LocationRow
                id="dischargeLocation"
                label="Discharge Location(s)"
                expanded={this.state.expandedItem === "dischargeLocation"}
                onExpand={this.handleFocusRow}
                value={this.props.orderData.dischargeLocation}
                onValueChanged={this.handleDischargeLocationChanged}
                text={this.props.orderData.dischargeLocation.value && this.props.orderData.dischargeLocation.value.display}
                {...validation}
              >
                <LocationEditor
                  id="dischargeLocationValue"
                  required={!this.props.orderData.dischargeLocation.negotiable}
                  value={this.props.orderData.dischargeLocation.value}
                  onChange={(value) => this.handleDischargeLocationValueChanged(value)}
                  ctradeUrl={this.props.ctradeUrl}
                  focus={this.state.expandedItem === "dischargeLocation"}
                />
              </LocationRow>

              <FreightRateRow
                id="freightRate"
                label="Freight Rate"
                expanded={this.state.expandedItem === "freightRate"}
                onExpand={this.handleFocusRow}
                value={this.props.orderData.freightRate}
                onValueChanged={this.handleFreightRateChanged}
                text={this.props.orderData.freightRate.value?.display}
                {...validation}
              >
                <NewUnitValueEditorWithValidContext
                  displayNotes
                  dataTest="freight-rate-notes"
                  id="freightRateValue"
                  type="freightRate"
                  label="Freight Rate"
                  notesId={"freight-rate-notes"}
                  notesPlaceholderText={"Additional freight rate notes"}
                  defaultOption={"PerMT"}
                  required={!this.props.orderData.freightRate.negotiable}
                  options={FreightRateOptions}
                  onChange={this.handleFreightRateValueChanged}
                  value={
                    this.props.orderData.freightRate.value || {
                      unit: "",
                      unitDisplay: "",
                      value: null,
                      notes: "",
                    }
                  }
                  focus={this.state.expandedItem === "freightRate"}
                />
              </FreightRateRow>
              <DemurrageRow
                id="demurrage"
                label="Demurrage"
                expanded={this.state.expandedItem === "demurrage"}
                onExpand={this.handleFocusRow}
                value={this.props.orderData.demurrage}
                onValueChanged={this.handleDemurrageChanged}
                text={this.props.orderData.demurrage.value?.display}
                {...validation}
              >
                <NewUnitValueEditorWithValidContext
                  displayNotes
                  dataTest="demurrage-notes"
                  id="demurrageValue"
                  type="demurrage"
                  label="Demurrage"
                  defaultOption={"PerDay"}
                  notesId={"demurrage-notes"}
                  notesPlaceholderText={"Additional demurrage notes"}
                  required={!this.props.orderData.demurrage.negotiable}
                  options={DemurrageOptions}
                  onChange={this.handleDemurrageValueChanged}
                  value={
                    this.props.orderData.demurrage.value || {
                      unit: "",
                      unitDisplay: "",
                      value: null,
                      notes: "",
                    }
                  }
                  focus={this.state.expandedItem === "demurrage"}
                />
              </DemurrageRow>
              <OperationalSubsRow
                id="operationalSubs"
                label="Operational Subs"
                expanded={this.state.expandedItem === "operationalSubs"}
                onExpand={this.handleFocusRow}
                value={this.props.orderData.operationalSubs}
                onValueChanged={this.handleOperationalSubsChanged}
                text={this.props.orderData.operationalSubs.value?.display}
                {...validation}
              >
                <SubsTextEditorWithValidContext
                  id="operationalSubsValue"
                  required={false}
                  value={
                    this.props.orderData.operationalSubs.value || {
                      value: "",
                      display: "",
                      title: "",
                    }
                  }
                  onChange={this.handleOperationalSubsValueChanged}
                  focus={this.state.expandedItem === "operationalSubs"}
                />
              </OperationalSubsRow>
              <CommercialSubsRow
                id="commercialSubs"
                label="Commercial Subs"
                expanded={this.state.expandedItem === "commercialSubs"}
                onExpand={this.handleFocusRow}
                value={this.props.orderData.commercialSubs}
                onValueChanged={this.handleCommercialSubsChanged}
                text={this.props.orderData.commercialSubs.value?.display}
                {...validation}
              >
                <SubsTextEditorWithValidContext
                  id="commercialSubsValue"
                  required={false}
                  value={
                    this.props.orderData.commercialSubs.value || {
                      value: "",
                      display: "",
                      title: "",
                    }
                  }
                  onChange={this.handleCommercialSubsValueChanged}
                  focus={this.state.expandedItem === "commercialSubs"}
                />
              </CommercialSubsRow>
              <NotesRow
                id="coaNotes"
                label="COA Notes"
                expanded={this.state.expandedItem === "coaNotes"}
                onExpand={this.handleFocusRow}
                value={this.props.orderData.coaNotes}
                onValueChanged={this.handleCOANotesChanged}
                text={this.props.orderData.coaNotes.value && this.props.orderData.coaNotes.value.display}
                {...validation}
              >
                <Notes
                  dataTest="COA Notes textarea"
                  value={this.props.orderData.coaNotes.value ? this.props.orderData.coaNotes.value.value : undefined}
                  onChange={this.handleCOANotesValueChanged}
                  ariaLabel="COA Notes text area"
                  focus={this.state.expandedItem === "coaNotes"}
                />
              </NotesRow>
              <ExcludedOrderItems items={excludedItems} onInclude={this.handleIncludeDetail} />
            </div>
          </>
        )}
      </ValidationContext.Consumer>
    );
  }
  private handleCargoTypeChanged(value: INegotiableViewOptional<ICargoTypeView>) {
    this.props.onOrderChanged({
      ...this.props.orderData,
      cargoType: {
        ...value,
      },
    });
  }

  private handleCargoTypeValueChanged(value?: ICargoTypeView) {
    this.handleCargoTypeChanged({
      ...this.props.orderData.cargoType,
      value: value,
    });
  }

  private handleChartererAccountChanged(value: INegotiableViewOptional<IAccountView>) {
    this.props.onOrderChanged({
      ...this.props.orderData,
      chartererAccount: {
        ...value,
      },
    });
  }

  private handleChartererAccountValueChanged(value: IAccountView | undefined) {
    this.handleChartererAccountChanged({
      ...this.props.orderData.chartererAccount,
      value,
    });
  }

  private handleDemurrageValueChanged(value: IUnitValueView | undefined) {
    this.handleDemurrageChanged({
      ...this.props.orderData.demurrage,
      value: value || {
        unit: "",
        unitDisplay: "",
        value: null,
      },
    });
  }

  private handleDemurrageChanged(demurrage: INegotiableViewOptional<IUnitValueView>) {
    this.props.onOrderChanged({
      ...this.props.orderData,
      demurrage: {
        ...this.props.orderData.demurrage,
        ...demurrage,
      },
    });
  }

  private handleFreightRateValueChanged(value: IUnitValueView | undefined) {
    this.handleFreightRateChanged({
      ...this.props.orderData.freightRate,
      value: value || {
        unit: "",
        unitDisplay: "",
        value: null,
      },
    });
  }

  private handleFreightRateChanged(freightRate: INegotiableViewOptional<IUnitValueView>) {
    this.props.onOrderChanged({
      ...this.props.orderData,
      freightRate: {
        ...this.props.orderData.freightRate,
        ...freightRate,
      },
    });
  }

  private handleAddressCommissionValueChanged(commission: ICommissionView) {
    this.handleAddressCommissionChanged({
      ...this.props.orderData.addressCommission,
      value: {
        value: commission.value,
        display: commission.value ? commissionText(commission) : undefined,
      },
    });
  }

  private handleAddressCommissionChanged(commission: INegotiableViewOptional<ICommissionView>) {
    this.props.onOrderChanged({
      ...this.props.orderData,
      addressCommission: {
        ...this.props.orderData.addressCommission,
        ...commission,
      },
    });
  }

  private handleBrokerCommissionValueChanged(commission: ICommissionView) {
    this.handleBrokerCommissionChanged({
      ...this.props.orderData.brokerCommission,
      value: {
        value: commission.value,
        display: commission.value ? commissionText(commission) : undefined,
      },
    });
  }

  private handleBrokerCommissionChanged(commission: INegotiableViewOptional<ICommissionView>) {
    this.props.onOrderChanged({
      ...this.props.orderData,
      brokerCommission: {
        ...this.props.orderData.brokerCommission,
        ...commission,
      },
    });
  }

  private handleDischargeLocationValueChanged(value?: ILocationView) {
    this.handleDischargeLocationChanged({
      ...this.props.orderData.dischargeLocation,
      value,
    });
  }

  private handleDischargeLocationChanged(dischargeLocation: INegotiableViewOptional<ILocationView>) {
    this.props.onOrderChanged({
      ...this.props.orderData,
      dischargeLocation: {
        ...this.props.orderData.dischargeLocation,
        ...dischargeLocation,
      },
    });
  }

  private handleLoadLocationValueChanged(value?: ILocationView | undefined) {
    this.handleLoadLocationChanged({
      ...this.props.orderData.loadLocation,
      value,
    });
  }

  private handleLoadLocationChanged(loadLocation: INegotiableViewOptional<ILocationView>) {
    this.props.onOrderChanged({
      ...this.props.orderData,
      loadLocation: {
        ...this.props.orderData.loadLocation,
        ...loadLocation,
      },
    });
  }

  private handleCommercialSubsValueChanged = (value: ISubsTextView) => {
    this.handleCommercialSubsChanged({
      ...this.props.orderData.commercialSubs,
      value,
    });
  };

  private handleCommercialSubsChanged = (value: INegotiableViewOptional<ISubsTextView>) => {
    this.props.onOrderChanged({
      ...this.props.orderData,
      commercialSubs: { ...value },
    });
  };

  private handleOperationalSubsValueChanged = (value: ISubsTextView) => {
    this.handleOperationalSubsChanged({
      ...this.props.orderData.operationalSubs,
      value,
    });
  };

  private handleOperationalSubsChanged = (value: INegotiableViewOptional<ISubsTextView>) => {
    this.props.onOrderChanged({
      ...this.props.orderData,
      operationalSubs: { ...value },
    });
  };

  private handleCOANotesChanged(value: INegotiableViewOptional<INotesView>) {
    this.props.onOrderChanged({
      ...this.props.orderData,
      coaNotes: {
        ...value,
      },
    });
  }

  private handleCOANotesValueChanged = (value: string) => {
    this.handleCOANotesChanged({
      ...this.props.orderData.coaNotes,
      value: { value: value, display: value },
    });
  };

  private handlePeriodValueChanged(value: ILaycanView | undefined) {
    this.handlePeriodChanged({
      ...this.props.orderData.period,
      value: value || mapLaycan.emptyViewModel,
    });
  }

  private handlePeriodChanged(period: INegotiableView<IPeriodView>) {
    this.props.onOrderChanged({
      ...this.props.orderData,
      period: {
        ...period,
      },
    });
  }

  private handleLiftingsValueChanged(value: ILiftingsView | undefined) {
    this.handleLiftingsChanged({
      ...this.props.orderData.liftings,
      value: value
        ? {
            display: liftingsDisplay(value),
            min: value.min,
            max: value.max,
            dispersal: value.dispersal,
            notes: value.notes,
          }
        : {
            display: "",
            min: null,
            max: null,
          },
    });
  }

  private handleLiftingsChanged(liftings: INegotiableView<ILiftingsView>) {
    this.props.onOrderChanged({
      ...this.props.orderData,
      liftings: {
        ...liftings,
      },
    });
  }

  private handleNominationsValueChanged(value: INominationsView | undefined) {
    this.handleNominationsChanged({
      ...this.props.orderData.nominations,
      value: value
        ? {
            display: nominationsDisplay(value) || "",
            noticePerLaycan: value.noticePerLaycan,
            laycanSpread: value.laycanSpread,
            finalLaycanNotice: value.finalLaycanNotice,
            notes: value.notes,
          }
        : {
            display: "",
          },
    });
  }

  private handleNominationsChanged(nominations: INegotiableView<INominationsView>) {
    this.props.onOrderChanged({
      ...this.props.orderData,
      nominations: {
        ...nominations,
      },
    });
  }

  private handleCargoSizesValueChanged(value: ICOACargoSizeView | undefined) {
    this.handleCargoSizesChanged({
      ...this.props.orderData.coaCargoSize,
      value: value
        ? {
            display: value.display,
            min: value.min,
            max: value.max,
            variance: value.variance,
            option: value.option,
            notes: value.notes,
            unit: value.unit,
          }
        : {
            display: "",
          },
    });
  }

  private handleCargoSizesChanged(coaCargoSizes: INegotiableView<ICOACargoSizeView>) {
    this.props.onOrderChanged({
      ...this.props.orderData,
      coaCargoSize: {
        ...coaCargoSizes,
      },
    });
  }
  private handleFocusRow(key: keyof IOrderCOACreate | null) {
    this.setState({
      expandedItem: key,
    });
  }

  private handleIncludeDetail(key: keyof IOrderCOACreate) {
    const updatedOrderData = { ...this.props.orderData };
    updatedOrderData[key].included = true;
    this.props.onOrderChanged(updatedOrderData);
    // contract the expanded item upon restore
    if (this.state.expandedItem === key) {
      this.setState({
        expandedItem: null,
      });
    }
  }
}

//this is the entire order terms for COA
export const OrderDetailsCOA = withContext<IOrderDetailsProps, IMinimalConfigurationContext>(
  OrderDetailsComponent,
  MinimalConfigurationContext
);
