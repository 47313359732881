import React from "react";
import { HireRate, HireRateProps } from "___REFACTOR___/models";
import { UnitValueEditor } from "../UnitValue";

function HireRateEditor(props: HireRateEditor.Props) {
  return (
    <UnitValueEditor
      {...props}
      onChange={onChange}
      units={HireRateProps.prototype.unitArr}
      defaultUnit={HireRateProps.prototype.JSONDefaults?.unit}
      editorType="HireRate"
    />
  );

  function onChange(value: HireRate) {
    const next = new HireRate(value);

    props.onChange?.(next);
  }
}

export { HireRateEditor };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace HireRateEditor {
  export interface Props extends Omit<UnitValueEditor.Props, "value" | "onChange"> {
    value: Value;
    onChange: (value: Value) => void;
  }

  export type Value = HireRate | undefined;
}
