import { getNegotiationApiService } from "../../api/negotiations/Negotiations";
import { INegotiationRequestFirm } from "../../api/negotiations/models";

export function requestFirm(ctradeUrl: string, orderId: string, negId: string, updateToken: string, side: "bid" | "offer") {
  // take the current neg and apply the action
  const requestFirmOffer = getNegotiationApiService(ctradeUrl, orderId).requestFirmOffer;
  const body: INegotiationRequestFirm = {
    updateToken,
    action: "firmRequested",
  };
  return requestFirmOffer(orderId, negId, body, side);
}
