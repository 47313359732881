import { useMemo } from "react";
import { FormContext, Form, Button } from "@/components";
import { UniversalOrderNegotiationFormValues, Negotiation, slideout } from "@/models";
import { updateNegotiationOwnerAccount as updateNegotiationOwnerAccountAction } from "@/components/Orders/actions";
import { Context } from "@/components/Orders/Orders";

export function OwnerAccountSlideout(props: Props) {
  const { negotiation, context } = props;
  const initialValues = useMemo(getInitialValues, []);

  function updateNegotiationOwnerAccount(values: UniversalOrderNegotiationFormValues) {
    if (values.ownerAccount) updateNegotiationOwnerAccountAction(negotiation, context, values.ownerAccount);
  }

  function submit() {
    slideout.hide();

    updateNegotiationOwnerAccount(form.values);
  }

  function getInitialValues() {
    return new UniversalOrderNegotiationFormValues({ ownerAccount: negotiation.getOwningCompanyModel() });
  }

  return (
    <div className="negotiation-details-slideout-form">
      <Form initialValues={initialValues} definition={initialValues} elements={elements} context={form} />
      <div className="vessel-slideout-form-control">
        <Button onClick={slideout.hide}>cancel</Button>
        <Button variant="action" onClick={submit}>
          Submit
        </Button>
      </div>
    </div>
  );
}

const form = new FormContext();

const elements = [
  {
    type: "CollapsibleGroup",
    label: "Owning Company",
    children: [{ type: "ownerAccount", name: "ownerAccount", label: "" }],
  },
];

interface Props {
  negotiation: Negotiation;
  context: Context;
}
