import React from "react";
import {
  AccountDisplay,
  CargoTypeDisplay,
  CargoSizeDisplay,
  COACargoSizeDisplay,
  CommissionDisplay,
  NotesDisplay,
  UnitValueDisplay,
  LocationDisplay,
  LaycanDisplay,
  VesselSizeDisplay,
  DurationDisplay,
  OnSubsTextDisplay,
  BunkerDisplay,
} from "negotiations/components/common/DetailsView/DetailsView";
import { NonNegotiableRow, NonBidableRow } from "../NonNegotiableRow/NonNegotiableRow";
import { INegotiationDetailsView } from "sharedFolder/Models/Negotiation/INegotiationView";
import { NominationsDisplay, LiftingsDisplay } from "offerRep/DetailsView/DetailsViewOfferRep";
import { OrderNegType } from "sharedFolder/Models/OrderNegType";

interface INonNegotiableDetailsProps {
  className?: string;
  details: INegotiationDetailsView;
  orderNegType: OrderNegType;
}

export const NonNegotiableDetails: React.SFC<INonNegotiableDetailsProps> = (props: INonNegotiableDetailsProps) => {
  const { details, orderNegType } = props;
  const {
    chartererAccount,
    commercialSubs,
    operationalSubs,
    vesselSize,
    coaCargoSize,
    duration,
    period,
    nominations,
    liftings,
    cargoType,
    cargoSize,
    loadLocation,
    dischargeLocation,
    deliveryLocation,
    viaLocation,
    redeliveryLocation,
    laycan,
    addressCommission,
    brokerCommission,
    notes,
    freightRate,
    demurrage,
    hireRate,
    cleaningPrice,
    supplyPrice,
    tradingExclusions,
    bunkerDelivery,
    bunkerRedelivery,
    cargoExclusionsText,
    ballastBonus,
  } = details;

  return (
    <div aria-label="Non Negotiable Details" className={props.className}>
      <NonNegotiableRow type="chartererAccount" item={chartererAccount} Component={AccountDisplay} />

      {vesselSize && !vesselSize.negotiable && vesselSize.included && (
        <NonBidableRow type="vesselSize" item={vesselSize} Component={VesselSizeDisplay} orderNegType={orderNegType} />
      )}

      {duration && !duration.negotiable && duration.included && (
        <NonBidableRow type="duration" item={duration} Component={DurationDisplay} orderNegType={orderNegType} />
      )}

      {period && !period.negotiable && period.included && (
        <NonBidableRow type="period" item={period} Component={LaycanDisplay} orderNegType={orderNegType} />
      )}

      {nominations && !nominations.negotiable && nominations.included && (
        <NonBidableRow type="nominations" item={nominations} Component={NominationsDisplay} orderNegType={orderNegType} />
      )}

      {liftings && !liftings.negotiable && liftings.included && (
        <NonBidableRow type="liftings" item={liftings} Component={LiftingsDisplay} orderNegType={orderNegType} />
      )}

      {!cargoType.negotiable && cargoType.included && (
        <NonBidableRow type="cargoType" item={cargoType} Component={CargoTypeDisplay} orderNegType={orderNegType} />
      )}

      {cargoSize && !cargoSize.negotiable && cargoSize.included && (
        <NonBidableRow type="cargoSize" item={cargoSize} Component={CargoSizeDisplay} orderNegType={orderNegType} />
      )}

      {coaCargoSize && !coaCargoSize.negotiable && coaCargoSize.included && (
        <NonBidableRow type="coaCargoSize" item={coaCargoSize} Component={COACargoSizeDisplay} orderNegType={orderNegType} />
      )}

      {loadLocation && !loadLocation.negotiable && loadLocation.included && (
        <NonBidableRow type="loadLocation" item={loadLocation} Component={LocationDisplay} orderNegType={orderNegType} />
      )}

      {dischargeLocation && !dischargeLocation.negotiable && dischargeLocation.included && (
        <NonBidableRow
          type="dischargeLocation"
          item={dischargeLocation}
          Component={LocationDisplay}
          orderNegType={orderNegType}
        />
      )}

      {deliveryLocation && !deliveryLocation.negotiable && deliveryLocation.included && (
        <NonBidableRow type="deliveryLocation" item={deliveryLocation} Component={LocationDisplay} orderNegType={orderNegType} />
      )}

      {viaLocation && !viaLocation.negotiable && viaLocation.included && (
        <NonBidableRow type="viaLocation" item={viaLocation} Component={LocationDisplay} orderNegType={orderNegType} />
      )}

      {redeliveryLocation && !redeliveryLocation.negotiable && redeliveryLocation.included && (
        <NonBidableRow
          type="redeliveryLocation"
          item={redeliveryLocation}
          Component={LocationDisplay}
          orderNegType={orderNegType}
        />
      )}

      {laycan && !laycan.negotiable && laycan.included && (
        <NonBidableRow type="laycan" item={laycan} Component={LaycanDisplay} orderNegType={orderNegType} />
      )}

      {!addressCommission.negotiable && addressCommission.included && (
        <NonBidableRow
          type="addressCommission"
          item={addressCommission}
          Component={CommissionDisplay}
          orderNegType={orderNegType}
        />
      )}

      {!brokerCommission.negotiable && brokerCommission.included && (
        <NonBidableRow
          type="brokerCommission"
          item={brokerCommission}
          Component={CommissionDisplay}
          orderNegType={orderNegType}
        />
      )}

      {operationalSubs && !operationalSubs.negotiable && operationalSubs.included && (
        <NonBidableRow type="operationalSubs" item={operationalSubs} Component={OnSubsTextDisplay} orderNegType={orderNegType} />
      )}

      {commercialSubs && !commercialSubs.negotiable && commercialSubs.included && (
        <NonBidableRow type="commercialSubs" item={commercialSubs} Component={OnSubsTextDisplay} orderNegType={orderNegType} />
      )}

      <NonNegotiableRow type="notes" item={notes} Component={NotesDisplay} />

      {freightRate && !freightRate.negotiable && freightRate.included && (
        <NonBidableRow type="freightRate" item={freightRate} Component={UnitValueDisplay} orderNegType={orderNegType} />
      )}

      {demurrage && !demurrage.negotiable && demurrage.included && (
        <NonBidableRow type="demurrage" item={demurrage} Component={UnitValueDisplay} orderNegType={orderNegType} />
      )}

      {hireRate && !hireRate.negotiable && hireRate.included && (
        <NonBidableRow type="hireRate" item={hireRate} Component={UnitValueDisplay} orderNegType={orderNegType} />
      )}

      {cleaningPrice && !cleaningPrice.negotiable && cleaningPrice.included && (
        <NonBidableRow type="cleaningPrice" item={cleaningPrice} Component={UnitValueDisplay} orderNegType={orderNegType} />
      )}

      {supplyPrice && !supplyPrice.negotiable && supplyPrice.included && (
        <NonBidableRow type="supplyPrice" item={supplyPrice} Component={UnitValueDisplay} orderNegType={orderNegType} />
      )}

      {tradingExclusions && !tradingExclusions.negotiable && tradingExclusions.included && (
        <NonBidableRow type="tradingExclusions" item={tradingExclusions} Component={NotesDisplay} orderNegType={orderNegType} />
      )}

      {bunkerDelivery && !bunkerDelivery.negotiable && bunkerDelivery.included && (
        <NonBidableRow type="bunkerDelivery" item={bunkerDelivery} Component={BunkerDisplay} orderNegType={orderNegType} />
      )}

      {bunkerRedelivery && !bunkerRedelivery.negotiable && bunkerRedelivery.included && (
        <NonBidableRow type="bunkerRedelivery" item={bunkerRedelivery} Component={BunkerDisplay} orderNegType={orderNegType} />
      )}

      {cargoExclusionsText && !cargoExclusionsText.negotiable && cargoExclusionsText.included && (
        <NonBidableRow
          type="cargoExclusionsText"
          item={cargoExclusionsText}
          Component={NotesDisplay}
          orderNegType={orderNegType}
        />
      )}
      {ballastBonus && !ballastBonus.negotiable && ballastBonus.included && (
        <NonBidableRow type="ballastBonus" item={ballastBonus} Component={UnitValueDisplay} orderNegType={orderNegType} />
      )}
    </div>
  );
};
