import React, { CSSProperties, ReactNode, useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { useKeepRerenderingUntilTruthy } from "@/utils";
import "./Popup.scss";

function Popup(props: Props) {
  const { target, children } = props;

  const style = {} as CSSProperties;
  let rect = {} as DOMRect;

  if (target instanceof HTMLElement) {
    rect = target.getBoundingClientRect();

    //
  } else if (target?.current) {
    rect = target.current.getBoundingClientRect();
  }

  style.width = rect?.width;
  style.height = rect?.height;
  style.top = rect?.top;
  style.left = rect?.left;

  const container = useRef<HTMLDivElement>(null);
  const backdrop = useRef<HTMLDivElement>(null);

  useEffect(setupEvents, [children]);
  useKeepRerenderingUntilTruthy(style.width || style.height);

  if (!children) return null;

  return (
    <div className="popup" style={style} ref={container}>
      <div className="popup-backdrop" ref={backdrop} />
      {children}
    </div>
  );

  function setupEvents() {
    if (!backdrop.current) return;
    if (!container.current) return;

    backdrop.current.onclick = onBackdropClick;
    container.current.onclick = onContainerClick;
  }

  function onBackdropClick() {
    props.onBackdropClick?.();
  }

  function onContainerClick(e) {
    e.stopPropagation();
  }
}

const Observer = observer(Popup);

export { Observer as Popup };

interface Props {
  target?: React.MutableRefObject<HTMLElement | null> | HTMLElement | null;
  children?: ReactNode;
  onBackdropClick?: Function;
}

export type PopupProps = Props;
