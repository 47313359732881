import { ILocationView } from "../Models/ILocation";

interface ILocationApiResponse {
  text?: string;
  document: {
    locationId: string;
    name: string;
    countryCode?: string;
    countryId?: string;
    country?: string;
    zoneId?: string;
    zone?: string;
    parents?: string[];
  };
}

export async function getLocations(apiUrl: string, searchTerm: string): Promise<ILocationView[]> {
  if (!searchTerm) {
    return Promise.resolve([]);
  }
  //bin is a reserved keyword and will always fail when passed as a path parameter in an Azure Function
  //prepending with a space works
  const reserved: string[] = ["bin"];
  reserved.forEach((r) => {
    if (searchTerm.toLowerCase().startsWith(r.toLowerCase())) {
      searchTerm = ` ${searchTerm}`;
    }
  });

  return await fetch(`${apiUrl}/locations/${encodeURIComponent(searchTerm)}`)
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((data) => data as ILocationApiResponse[])
    .then((data) => data.map(toLocationView))
    // fail silently, return nothing
    .catch((err) => []);
}

function toLocationView(response: ILocationApiResponse): ILocationView {
  return {
    locationId: response.document.locationId,
    name: response.document.name || response.text || "",
    countryCode: response.document.countryCode || "",
    countryId: response.document.countryId || "",
    zone: response.document.zone || "",
    zoneId: response.document.zoneId || "",
    globalZone: response.document.zoneId || "",
    notes: "",
    parents: response.document.parents || [],
    country: response.document.country || "",
  };
}

/* Location Safety Warranty Information service functions */

const locationSafetyWarranty = [
  {
    key: "1",
    value: {
      min: 1,
      max: 1,
    },
  },
  {
    key: "1-2",
    value: {
      min: 1,
      max: 2,
    },
  },
  {
    key: "1-3",
    value: {
      min: 1,
      max: 3,
    },
  },
  {
    key: "1-4",
    value: {
      min: 1,
      max: 4,
    },
  },
  {
    key: "1-5",
    value: {
      min: 1,
      max: 5,
    },
  },
  {
    key: "2",
    value: {
      min: 2,
      max: 2,
    },
  },
  {
    key: "2-3",
    value: {
      min: 2,
      max: 3,
    },
  },
  {
    key: "2-4",
    value: {
      min: 2,
      max: 4,
    },
  },
  {
    key: "2-5",
    value: {
      min: 2,
      max: 5,
    },
  },
  {
    key: "3",
    value: {
      min: 3,
      max: 3,
    },
  },
  {
    key: "3-4",
    value: {
      min: 3,
      max: 4,
    },
  },
  {
    key: "3-5",
    value: {
      min: 3,
      max: 5,
    },
  },
  {
    key: "4",
    value: {
      min: 4,
      max: 4,
    },
  },
  {
    key: "4-5",
    value: {
      min: 4,
      max: 5,
    },
  },
  {
    key: "5",
    value: {
      min: 5,
      max: 5,
    },
  },
];

interface ILocationSafetyWarrantyItem {
  key: string;
  value: {
    min: number;
    max: number;
  };
}

export function getLocationSafetyWarrantyByKey(key: string) {
  return locationSafetyWarranty.find((item: ILocationSafetyWarrantyItem) => item.key === key);
}

export function getLocationSafetyWarranties(): ILocationSafetyWarrantyItem[] {
  return locationSafetyWarranty;
}
