import React from "react";
import { Demurrage, DemurrageProps } from "@/models";
import { UnitValueEditorProps, UnitValueEditor } from "../UnitValue";

export function DemurrageEditor(props: Props) {
  props = { ...props };

  props.className = `${props.className} Demurrage`;

  function onChange(value: Demurrage) {
    const next = new Demurrage(value);

    props.onChange?.(next);
  }

  return <UnitValueEditor {...props} units={DemurrageProps.prototype.units} onChange={onChange} dataTest={`${props.dataTest}`} />;
}

interface Props extends Omit<UnitValueEditorProps, "value" | "onChange"> {
  value?: Demurrage;
  onChange?: (value: Demurrage) => void;
}

export type DemurrageEditorProps = Props;
