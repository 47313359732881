import { IResponse } from "../models/IResponse";
import { Observable, empty } from "rxjs";
import { ICirculateOrder } from "./models/ICirculateOrder";
import { Optimistic } from "../Optimistic";
import { observableFetch } from "../observableFetch";
import { INegotiation } from "./models/INegotiation";
import { map, catchError, repeatWhen, filter, concatMap } from "rxjs/operators";
import { mapCirculateOrderToNegotiation } from "./mapping/mapCirculateOrderToNegotiation";
import {
  INegotiationIndication,
  INegotiationRequestFirm,
  INegotiationFirmed,
  INegotiationFirmAccepted,
  IVesselNominateUpdate,
  IVesselAcceptUpdate,
  IVesselRejectUpdate,
  INegotiationNoteUpdate,
  INegotiationOwningCompanyUpdate,
  IVesselEdit,
  IWithdrawNegotiationDetail,
} from "./models";
import { IOrderNegActions } from "../../sharedFolder/Models/IOrderNegActions";
import { ILiftingsCollection } from "../models/ILiftingsCollection";

type ISide = "bid" | "offer";

interface IApiNegotiationService {
  acceptVessel: (orderId: string, negotiationId: string, detail: IVesselAcceptUpdate, imo: string) => Observable<IResponse>;
  circulateOrder: (orderId: string, details: ICirculateOrder) => Observable<IResponse>;
  firmAccepted: (orderId: string, negotiationId: string, detail: INegotiationFirmAccepted, side: ISide) => Observable<IResponse>;
  firmed: (orderId: string, negotiationId: string, detail: INegotiationFirmed, side: ISide) => Observable<IResponse>;
  getNegotiations: (orderId: string) => Observable<INegotiation[]>;
  getLiftings: (orderId: string) => Observable<ILiftingsCollection>;
  getNegotiation: (orderId: string, negotiationId: string) => Observable<INegotiation>;
  getOwnerNegotiation: (orderId: string, negotiationId: string) => Observable<INegotiation>;
  indication: (orderId: string, negotiationId: string, detail: INegotiationIndication, side: ISide) => Observable<IResponse>;
  nominateVessel: (orderId: string, negotiationId: string, detail: IVesselNominateUpdate) => Observable<IResponse>;
  rejectVessel: (orderId: string, negotiationId: string, detail: IVesselRejectUpdate, imo: string) => Observable<IResponse>;
  editVessel: (orderId: string, negotiationId: string, detail: IVesselEdit, chartererCompanyId?: string) => Observable<IResponse>;
  setCounterParty: (orderId: string, negotiationId: string, detail: INegotiationOwningCompanyUpdate) => Observable<IResponse>;
  requestFirmOffer: (
    orderId: string,
    negotiationId: string,
    detail: INegotiationRequestFirm,
    side: ISide
  ) => Observable<IResponse>;
  showToCharterer: (orderId: string, negotiationId: string, updateToken: string) => Observable<IResponse>;
  updateNote: (orderId: string, negotiationId: string, detail: INegotiationNoteUpdate) => Observable<IResponse>;
  withdrawNegotiation: (orderId: string, negotiationId: string, detail: IWithdrawNegotiationDetail) => Observable<IResponse>;
  withdrawInactiveNegotiationsUnderOrder: (orderId: string) => void;
}

const sideToActor = (side: string): keyof IOrderNegActions => {
  let actor: keyof IOrderNegActions;
  switch (side) {
    case "bid":
      actor = "brokerCharterer";
      break;
    case "offer":
      actor = "owner";
      break;
    default:
      throw new Error("couldn't recognise the actor performing the action");
  }

  return actor;
};

class NegotiationService implements IApiNegotiationService {
  private readonly optimisticNegotiations = new Optimistic<INegotiation>("negotiations");

  constructor(private ctradeUrl: string, private withUpdates?: Observable<{ id: string; version: number }>) {}

  public withdrawInactiveNegotiationsUnderOrder = (orderId: string): void => {
    fetch(`${this.ctradeUrl}/orders/${orderId}/negotiations`, {
      method: "GET",
      headers: {
        "X-API-Version": "3",
      },
    })
      .then((response) => response.json())
      .then((negs: INegotiation[]) => {
        negs.forEach((neg) => {
          if (neg.updateToken) {
            const detail: IWithdrawNegotiationDetail = {
              updateToken: neg.updateToken,
            };
            this.withdrawNegotiation(orderId, neg.id, detail).subscribe();
          }
        });
      });
  };

  public acceptVessel = (
    orderId: string,
    negotiationId: string,
    detail: IVesselAcceptUpdate,
    imo: string
  ): Observable<IResponse> => {
    return observableFetch<INegotiation>(() =>
      fetch(`${this.ctradeUrl}/orders/${orderId}/negotiations/${negotiationId}/vessels/${imo}`, {
        method: "PUT",
        headers: {
          "X-API-Version": "3",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(detail),
      })
    );
  };

  public circulateOrder = (orderId: string, details: ICirculateOrder): Observable<IResponse> => {
    return observableFetch<IResponse>(() =>
      fetch(`${this.ctradeUrl}/orders/${orderId}/negotiations`, {
        method: "POST",
        headers: {
          "X-API-Version": "3",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(details),
      })
    ).pipe(
      map((result: IResponse) => {
        this.optimisticNegotiations.pushToLocal({
          ...mapCirculateOrderToNegotiation(details, orderId, result.id),
          id: result.id,
        });
        return result;
      }),
      catchError(() => empty())
    );
  };

  public getNegotiations = (orderId: string): Observable<INegotiation[]> => {
    return observableFetch<INegotiation[]>(() =>
      fetch(`${this.ctradeUrl}/orders/${orderId}/negotiations`, {
        method: "GET",
        headers: {
          "X-API-Version": "3",
        },
      })
    )
      .pipe(
        // and fetch all again whenever we receive an update notification
        // for this order
        repeatWhen(() => (this.withUpdates || empty()).pipe(filter((update) => update.id === orderId)))
      )
      .pipe(
        map((results) => {
          this.optimisticNegotiations.replaceAllRemote(results);
          // don't care about return but we need to return something in "map"
          return results;
        })
      )
      .pipe(concatMap(() => this.optimisticNegotiations.data));
  };

  public getLiftings = (orderId: string): Observable<ILiftingsCollection> => {
    return observableFetch<ILiftingsCollection>(() =>
      fetch(`${this.ctradeUrl}/orders/${orderId}/liftings`, {
        method: "GET",
        headers: {
          "X-API-Version": "3",
        },
      })
    );
    // TODO: Deal with optimistic updates here.......still to be done
    // .pipe(
    //   // and fetch all again whenever we receive an update notification
    //   // for this order
    //   repeatWhen(() =>
    //     (this.withUpdates || empty()).pipe(
    //       filter(update => update.id === orderId)
    //     )
    //   )
    // )
    // .pipe(
    //   map(results => {
    //     this.optimisticNegotiations.replaceAllRemote(results);
    //     // don't care about return but we need to return something in "map"
    //     return results;
    //   })
    // )
    // .pipe(concatMap(() => this.optimisticNegotiations.data));
  };

  /**
   * Pass the url to indicate which role you want the neg for
   * */

  private getNegotiationWithUrl = (url: string): Observable<INegotiation> => {
    return observableFetch<INegotiation>(() =>
      fetch(url, {
        method: "GET",
        headers: {
          "X-API-Version": "3",
        },
      })
    );

    // Could possibly implement this to reload a single negotiation
    // but best to wait until we have more granularity (e.g. a notification for a single neg updated)
    // .pipe(
    //   // and fetch all again whenever we receive an update notification
    //   // for this order
    //   repeatWhen(() =>
    //     (this.withUpdates || empty()).pipe(
    //       filter(update => update.id === orderId)
    //     )
    //   )
    // );
  };

  public getNegotiation = (orderId: string, negotiationId: string): Observable<INegotiation> => {
    const url = `${this.ctradeUrl}/orders/${orderId}/negotiations/${negotiationId}`;
    return this.getNegotiationWithUrl(url);
  };

  public getOwnerNegotiation = (orderId: string, negotiationId: string): Observable<INegotiation> => {
    const url = `${this.ctradeUrl}/owner/orders/${orderId}/negotiations/${negotiationId}`;
    return this.getNegotiationWithUrl(url);
  };

  public firmAccepted = (
    orderId: string,
    negotiationId: string,
    detail: INegotiationFirmAccepted,
    side: ISide
  ): Observable<IResponse> => {
    const actor = sideToActor(side);
    return observableFetch<IResponse>(() =>
      fetch(`${this.ctradeUrl}/orders/${orderId}/negotiations/${negotiationId}/${side}`, {
        method: "PUT",
        headers: {
          "X-API-Version": "3",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(detail),
      })
    ).pipe(
      map((response) => {
        const neg = this.optimisticNegotiations.getItem(negotiationId);

        if (neg) {
          let clonedNeg = JSON.parse(JSON.stringify(neg)) as INegotiation;
          clonedNeg = {
            ...clonedNeg,
            actions: {
              ...clonedNeg.actions,
              [actor]: "firmAccepted",
              lastUpdatedBy: side === "bid" ? "brokerCharterer" : "owner",
            },
            status: "Firm",
          };
          clonedNeg.version = response.version;
          this.optimisticNegotiations.replaceOrPushSingleLocal(clonedNeg);
        } else {
          console.error("couldnt find the right neg to update");
        }
        return response;
      })
    );
  };

  public firmed = (orderId: string, negotiationId: string, detail: INegotiationFirmed, side: ISide): Observable<IResponse> => {
    return observableFetch<IResponse>(() =>
      fetch(`${this.ctradeUrl}/orders/${orderId}/negotiations/${negotiationId}/${side}`, {
        method: "PUT",
        headers: {
          "X-API-Version": "3",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(detail),
      })
    ).pipe(
      map((response) => {
        const neg = this.optimisticNegotiations.getItem(negotiationId);
        const actor = sideToActor(side);
        if (neg) {
          let clonedNeg = JSON.parse(JSON.stringify(neg)) as INegotiation;
          clonedNeg = {
            ...clonedNeg,
            actions: {
              ...clonedNeg.actions,
              [actor]: "firmed",
              lastUpdatedBy: side === "bid" ? "brokerCharterer" : "owner",
            },
          };

          if (actor === "brokerCharterer") {
            clonedNeg.actions["brokerChartererFirmExpiresOn"] = detail.expiresOn;
          } else if (actor === "owner") {
            clonedNeg.actions["ownerFirmExpiresOn"] = detail.expiresOn;
          } else {
            console.error("not a valid actor, optimistc updates wont work as expected");
          }
          clonedNeg.version = response.version;
          this.optimisticNegotiations.replaceOrPushSingleLocal(clonedNeg);
        } else {
          console.error("couldnt find the right neg to update");
        }
        return response;
      })
    );
  };

  public indication = (
    orderId: string,
    negotiationId: string,
    detail: INegotiationIndication,
    side: ISide
  ): Observable<IResponse> => {
    return observableFetch<IResponse>(() =>
      fetch(`${this.ctradeUrl}/orders/${orderId}/negotiations/${negotiationId}/${side}`, {
        method: "PUT",
        headers: {
          "X-API-Version": "3",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(detail),
      })
    ).pipe(
      map((response) => {
        const neg = this.optimisticNegotiations.getItem(negotiationId);
        const actor = sideToActor(side);
        if (neg) {
          const clonedNeg = JSON.parse(JSON.stringify(neg)) as INegotiation;
          clonedNeg[side] = {
            ...clonedNeg[side],
            ...detail.details,
          };
          clonedNeg.version = response.version;
          clonedNeg.actions = {
            ...clonedNeg.actions,
            lastUpdatedBy: side === "bid" ? "brokerCharterer" : "owner",
          };
          if (actor === "brokerCharterer") {
            clonedNeg.actions["brokerCharterer"] = "indicated";
          } else if (actor === "owner") {
            clonedNeg.actions["owner"] = "indicated";
          }

          this.optimisticNegotiations.replaceOrPushSingleLocal(clonedNeg);
        } else {
          console.error("couldnt find the right neg to update");
        }
        return response;
      })
    );
  };

  public nominateVessel = (orderId: string, negotiationId: string, detail: IVesselNominateUpdate): Observable<IResponse> => {
    return this.sendNominateVesselPost(orderId, negotiationId, detail);
  };

  private sendNominateVesselPost = (
    orderId: string,
    negotiationId: string,
    detail: IVesselNominateUpdate
  ): Observable<IResponse> => {
    return observableFetch<INegotiation>(() =>
      fetch(`${this.ctradeUrl}/orders/${orderId}/negotiations/${negotiationId}/vessels`, {
        method: "POST",
        headers: {
          "X-API-Version": "3",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(detail),
      })
    );
  };

  public rejectVessel = (
    orderId: string,
    negotiationId: string,
    detail: IVesselRejectUpdate,
    imo: string
  ): Observable<IResponse> => {
    return observableFetch<INegotiation>(() =>
      fetch(`${this.ctradeUrl}/orders/${orderId}/negotiations/${negotiationId}/vessels/${imo}`, {
        method: "PUT",
        headers: {
          "X-API-Version": "3",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(detail),
      })
    );
  };

  private postEditVessel = (orderId: string, negotiationId: string, detail: IVesselEdit): Observable<IResponse> => {
    return observableFetch<INegotiation>(() =>
      fetch(`${this.ctradeUrl}/orders/${orderId}/negotiations/${negotiationId}/vessels/${detail.vesselIMO}`, {
        method: "PUT",
        headers: {
          "X-API-Version": "3",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(detail),
      })
    );
  };

  public editVessel = (
    orderId: string,
    negotiationId: string,
    detail: IVesselEdit,
    chartererCompanyId?: string //TODO
  ): Observable<IResponse> => {
    return this.postEditVessel(orderId, negotiationId, detail);
  };

  public requestFirmOffer = (
    orderId: string,
    negotiationId: string,
    detail: INegotiationRequestFirm,
    side: ISide
  ): Observable<IResponse> => {
    return observableFetch<IResponse>(() =>
      fetch(`${this.ctradeUrl}/orders/${orderId}/negotiations/${negotiationId}/${side}`, {
        method: "PUT",
        headers: {
          "X-API-Version": "3",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(detail),
      })
    ).pipe(
      map((response) => {
        const neg = this.optimisticNegotiations.getItem(negotiationId);

        if (neg) {
          let clonedNeg = JSON.parse(JSON.stringify(neg)) as INegotiation;
          clonedNeg = {
            ...clonedNeg,
            actions: {
              ...clonedNeg.actions,
              brokerCharterer: "firmRequested",
            },
          };
          clonedNeg.version = response.version;
          this.optimisticNegotiations.replaceOrPushSingleLocal(clonedNeg);
        } else {
          console.error("couldnt find the right neg to update");
        }
        return response;
      })
    );
  };

  public showToCharterer = (orderId: string, negotiationId: string, updateToken: string): Observable<IResponse> => {
    const body = {
      action: "published",
      updateToken,
    };
    return observableFetch<IResponse>(() =>
      fetch(`${this.ctradeUrl}/orders/${orderId}/negotiations/${negotiationId}/publish`, {
        method: "PUT",
        headers: {
          "X-API-Version": "3",
          "Content-Type": "application/json",
        },

        body: JSON.stringify(body),
      })
    );
  };

  public setCounterParty = (
    orderId: string,
    negotiationId: string,
    detail: INegotiationOwningCompanyUpdate
  ): Observable<IResponse> => {
    return this.setOwningCompany(orderId, negotiationId, detail);
  };

  private setOwningCompany = (
    orderId: string,
    negotiationId: string,
    detail: INegotiationOwningCompanyUpdate
  ): Observable<IResponse> => {
    return observableFetch<INegotiation>(() =>
      fetch(`${this.ctradeUrl}/orders/${orderId}/negotiations/${negotiationId}/owningcompany`, {
        method: "PUT",
        headers: {
          "X-API-Version": "3",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(detail),
      })
    );
  };

  public updateNote = (orderId: string, negotiationId: string, detail: INegotiationNoteUpdate): Observable<IResponse> => {
    return observableFetch<INegotiation>(() =>
      fetch(`${this.ctradeUrl}/orders/${orderId}/negotiations/${negotiationId}/note`, {
        method: "PUT",
        headers: {
          "X-API-Version": "3",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(detail),
      })
    );
  };

  public withdrawNegotiation = (
    orderId: string,
    negotiationId: string,
    detail: IWithdrawNegotiationDetail
  ): Observable<IResponse> => {
    return observableFetch<INegotiation>(() =>
      fetch(`${this.ctradeUrl}/orders/${orderId}/negotiations/${negotiationId}`, {
        method: "DELETE",
        headers: {
          "X-API-Version": "3",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(detail),
      })
    );
  };
}

/**
 * Retain a collection of service instances; one for each apiUrl
 */
const services: { [key: string]: IApiNegotiationService } = {};

/**
 * Service provider for Orders client
 * Will return the same instance for all calls for the same API URL
 * @param apiUrl The API URL
 */
export function getNegotiationApiService(
  apiUrl: string,
  orderId: string,
  withUpdates?: Observable<{ id: string; version: number }>
) {
  const id = `${apiUrl}-${orderId}`;
  if (!Object.prototype.hasOwnProperty.call(services, id)) {
    services[id] = new NegotiationService(apiUrl, withUpdates);
  }

  return services[id];
}
