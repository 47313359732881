import React from "react";
import styles from "./CargoDetails.module.scss";
import { INegotiationView, INegotiationDetailsView } from "sharedFolder/Models/Negotiation/INegotiationView";
import { IBidOfferView } from "sharedFolder/Models/Negotiation/NegotiationViews";
import { detailTypeDisplay } from "negotiations/models/common/DetailType";
import { INotesView } from "sharedFolder/Models/IDetails";
import { formatDateWithTime } from "sharedFolder/Utilities/getNow";
import { Laycan } from "@/models";

interface ICargoDetailsProps {
  negotiation: INegotiationView;
}

export const CargoDetails: React.FC<ICargoDetailsProps> = (props: ICargoDetailsProps) => {
  const bidItemsToDisplay: (keyof INegotiationDetailsView)[] = [
    "laycan",
    "cargoType",
    "cargoSize",
    "loadLocation",
    "dischargeLocation",
    "freightRate",
    "demurrage",
    "notes",
  ];
  const { createdOn, details } = props.negotiation;

  if (details)
    return (
      <div>
        <div className={styles.title}>
          <h3>Cargo Details</h3>
          <span>Declared {formatDateWithTime(createdOn)}</span>
        </div>
        {bidItemsToDisplay.map((item) => {
          // TODO: This should be IBidOfferView<IDisplayableView> and each of the views should inherit from IDisplayableView
          const detailItem = details[item] as IBidOfferView<any | INotesView>;
          if (detailItem) {
            if (item === "laycan") {
              detailItem.bid.display = Laycan.prototype.toView.call(detailItem.bid);
            }
            const { display = "-" } = detailItem.bid ?? detailItem;
            return (
              <div className={styles.item} key={item}>
                <span className={styles.itemTitle}>{detailTypeDisplay(item, props.negotiation.type)}</span>
                <span>{display} </span>
              </div>
            );
          }
          return null;
        })}
      </div>
    );
  return null;
};
