import React, { useEffect, HTMLProps, useRef } from "react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { TrackingContext, EmptyTrackingProvider } from "./TrackingContext";
import { AppInsightsTrackingProvider } from "./AppInsightsTrackingProvider";
import { History, Location, UnregisterCallback, LocationState, Action } from "history";
import { useConfig } from "__legacy/sharedFolder/ConfigurationContext";

interface ITrackedAppProps extends HTMLProps<HTMLElement> {
  history: History<LocationState>;
}

/**
 * Component container for tracking.
 * Creates a TrackingContext.Provider, listens for page navigation and
 * instantiates the (App Insights) metrics tracker
 */
export const TrackedApp: React.FC<ITrackedAppProps> = (props: ITrackedAppProps) => {
  const config = useConfig();
  const appInsights: ApplicationInsights | null = initAppInsights(config.appInsightsKey);
  const tracker = appInsights ? new AppInsightsTrackingProvider(appInsights) : EmptyTrackingProvider;
  const trackerRef = React.useRef(tracker);

  useEffect(() => {
    const unregister: UnregisterCallback = props.history.listen((location: Location, action: Action) => {
      tracker.pageView(window.location.href, location.pathname);
    });
    return () => unregister();
  }, [config.appInsightsKey, props.history, tracker]);
  return <TrackingContext.Provider value={trackerRef.current}>{props.children}</TrackingContext.Provider>;
};

/**
 * Safely initialise App Insights.
 * Returns null if invalid instrumentation key was provided, or if any error occurred when loading App Insights
 * @param key Instrumentation key
 */
function initAppInsights(key: string): ApplicationInsights | null {
  if (!key) {
    return null;
  }

  try {
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: key,
        disableFetchTracking: false,
        extensions: [],
        extensionConfig: {},
      },
    });

    appInsights.loadAppInsights();
    return appInsights;
  } catch (err) {
    // TODO: handle this
    return null;
  }
}
