import React from "react";
import Field from "../Field/Field";
import EditorRowEmptyValue from "../EditorRowEmptyValue/EditorRowEmptyValue";
import { NewCargoTypeEditor } from "../../editors/NewCargoTypeEditor/NewCargoTypeEditor";
import { ICargoTypeView } from "sharedFolder/Models/IDetails";

interface ICargoTypesFieldProps<T> {
  isFieldValid: (key: T) => boolean;
  updateForm: (key: T, value: any) => void;
  selectedItem?: ICargoTypeView;
  isExpanded: boolean;
  required: boolean;
  setExpandedItem: (name: T) => void;
}

export const CargoTypesField = (props: ICargoTypesFieldProps<"cargoType">) => {
  return (
    <Field
      id={"cargoTypes"}
      label="Cargo Type(s)" // The label is Cargo Type"s" and not Cargo Type because at some point in the future a new cargo type editor will need to be created for COAs that suppor multiple cargo types (this is just to get this feature out of the way)
      name={"cargoTypes"}
      edit={
        <NewCargoTypeEditor
          focus={true}
          isValid={props.isFieldValid("cargoType")}
          selectedItem={props.selectedItem}
          onChange={(value?: ICargoTypeView) => {
            props.updateForm("cargoType", value);
          }}
        />
      }
      expanded={props.isExpanded}
      expand={() => {
        props.setExpandedItem("cargoType");
      }}
      display={
        <>{(props.selectedItem && cargoTypesDisplay(props.selectedItem)) || <EditorRowEmptyValue required={props.required} />}</>
      }
      isValid={props.isFieldValid("cargoType")}
    />
  );
};

const cargoTypesDisplay = (value?: ICargoTypeView) => {
  if (value) return value?.display || `${value.name} ${value.notes}`;
  return null;
};
