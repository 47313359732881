import { NegotiationArchivingResponsePayload, tradeAPI } from "@/apis";
import { Negotiation } from "@/models/Negotiation";
import { ArchivingAction, BaseArchivingAction } from "./BaseArchivingAction";
import { config as tradeConfig } from "@/config";

export class BulkNegotiationsArchiveAction extends BaseArchivingAction<Negotiation[], NegotiationArchivingResponsePayload[]> {
  protected countOfRetries = tradeConfig.archiveWaitPolicy?.bulkWaitRetryCount ?? this.BulkDefaultWaitRetryCount;
  protected retryDelay = tradeConfig.archiveWaitPolicy?.bulkWaitRetryDelayMs ?? this.DefaultRetryDelayMs;
  protected actionName = ArchivingAction.BulkArchive;
  protected operationName = "Bulk Archiving";
  protected finishedOperationName = "archived";
  protected entityName = "negotiation";

  protected getEntityArray = () => this.model;
  protected isModelUpdatedAsync = async () => this.model.every((neg: Negotiation) => this.getValidationAction()(neg));

  protected getArrayStatus = (): Status | undefined => {
    return this.context.orderNegotiationStore?.negotiationArrayStatus;
  };

  protected archivingActionAsync = async () => {
    const payload = this.model.map((negotiation) => ({
      negotiationId: negotiation.id,
      orderId: negotiation.orderId,
      updateToken: negotiation.updateToken,
    }));

    return await tradeAPI.bulkArchiveNegotiations(payload);
  };
}
