import { autorun } from "mobx";
import { tradeAPI, TradeAPI } from "@/apis";
import { cloneDeep } from "lodash-es";
import {
  auth,
  ownerOrderNegotiationStore,
  standardOrderNegotiationStore,
  anyOrderNegotiationStore,
  coaOrderNegotiationStore,
  OrderNegotiationStore,
  Order,
  orderNegotiationArchiveStore,
  coaOrderNegotiationArchiveStore,
  ownerOrderNegotiationArchiveStore,
} from "@/models";
import { log } from "@/services";

export class OrderNegotiation {
  promiseQueue = [] as PromiseQueueItem[];

  setup = async () => {
    await tradeAPI.promise;

    tradeAPI.connection?.off("seatrade", this.seaTradeHandler);
    tradeAPI.connection?.on("seatrade", this.seaTradeHandler);
  };

  seaTradeHandler = (event: TradeAPI["Event"]) => {
    if (event.eventType === "OrderUpdated") {
      this.orderUpdateHandler(event);
      this.consumePromiseQueue(event);
    }
  };

  orderUpdateHandler = async (event: TradeAPI["Event"]) => {
    const { id } = event;
    const companyRoleMap = auth.trade.user?._.companyRoleMap;
    const isChartererOrBroker = companyRoleMap?.broker || companyRoleMap?.charterer;

    if (anyOrderNegotiationStore.orderMap[id]) {
      this.getExistingOrderTreeData(anyOrderNegotiationStore, id);
    }

    if (orderNegotiationArchiveStore.orderMap[id]) {
      this.getExistingOrderTreeData(orderNegotiationArchiveStore, id);

      return;
    }

    if (standardOrderNegotiationStore.orderMap[id]) {
      this.getExistingOrderTreeData(standardOrderNegotiationStore, id);

      return;
    }

    if (coaOrderNegotiationArchiveStore.orderMap[id]) {
      this.getExistingOrderTreeData(coaOrderNegotiationArchiveStore, id);

      return;
    }

    if (coaOrderNegotiationStore.orderMap[id]) {
      this.getExistingOrderTreeData(coaOrderNegotiationStore, id);

      return;
    }

    if (ownerOrderNegotiationArchiveStore.orderMap[id] || ownerOrderNegotiationStore.orderMap[id]) {
      // Owner can have more than 1 negotiation for the same order, which can be in difference stores.
      // So, we need to update the order's information in both stores

      if (ownerOrderNegotiationArchiveStore.orderMap[id]) {
        this.getExistingOrderTreeData(ownerOrderNegotiationArchiveStore, id);
      }

      if (ownerOrderNegotiationStore.orderMap[id]) {
        this.getExistingOrderTreeData(ownerOrderNegotiationStore, id);
      }

      return;
    }

    const order = new Order({ id, version: 0 });

    const res = await order.getData();

    if (res?.status === 404) return;

    if (!res?.ok || !res.data) {
      logError({ res, entity: order }, "OrderNegotiation.orderUpdateHandler");

      return;
    }

    this.getOrderTreeData(anyOrderNegotiationStore, order, res.data);

    if (order.isOwnerOrder()) {
      this.getOrderTreeData(ownerOrderNegotiationStore, order, res.data);

      if (res.data.isArchived) {
        this.getOrderTreeData(ownerOrderNegotiationArchiveStore, order, res.data);
      }

      return;
    }

    if (isChartererOrBroker) {
      this.getOrderTreeData(standardOrderNegotiationStore, order, res.data);
      this.getOrderTreeData(coaOrderNegotiationStore, order, res.data);
      if (res.data.isArchived) {
        this.getOrderTreeData(orderNegotiationArchiveStore, order, res.data);
        this.getOrderTreeData(coaOrderNegotiationArchiveStore, order, res.data);
      }
    }
  };

  getExistingOrderTreeData(orderNegotiationStore: OrderNegotiationStore, id: TradeAPI["Order"]["id"]) {
    orderNegotiationStore.getOrderTreeData(id, undefined, { forceUpdate: true });
  }

  getOrderTreeData = (orderNegotiationStore: OrderNegotiationStore, order: Order, data: TradeAPI["Order"]) => {
    const types = order.getAllTypes();
    const notAllowed = types.length ? types.some((type) => !orderNegotiationStore.supportedOrderType[type]) : true;

    if (notAllowed) return;

    orderNegotiationStore.getOrderTreeData(undefined, cloneDeep(data), { forceUpdate: true });
  };

  consumePromiseQueue = async (event: TradeAPI["Event"]) => {
    this.promiseQueue = this.promiseQueue.filter(findMatchingItem);

    function findMatchingItem(item: PromiseQueueItem) {
      const isMatching = item.id === event.id;

      if (isMatching) item.resolve();

      return isMatching;
    }
  };

  getNextOrderUpdatePromise = (id) => {
    let resolve;

    const promise = new Promise((_resolve) => {
      resolve = _resolve;
    });

    this.promiseQueue.push({ resolve, id });

    return promise;
  };
}

export const orderNegototiation = new OrderNegotiation();

function subscribe() {
  if (tradeAPI.connection) orderNegototiation.setup();
}

autorun(subscribe);

function logError(dump, message) {
  log.error({ ...errorDialogProps, status: { ...errorDialogProps.status, dump } }, message, dump);
}

const errorDialogProps = {
  status: {
    type: "error",
    title: "Controller Order Fetch Failed",
  } as Status,
  dataTest: "order-negotiation-controller-order-fetch-error",
};

interface PromiseQueueItem {
  id: string;
  resolve(): void;
}
