import React from "react";
import classNames from "classnames";
import styles from "./Heading.module.scss";

interface IHeaderProps {
  children: React.ReactNode;
}
const Heading = (props: IHeaderProps & React.HTMLProps<HTMLHeadingElement>) => {
  const { children, className, ...otherProps } = props;
  return (
    <h1 className={classNames(styles.header, className)} {...otherProps}>
      {children}
    </h1>
  );
};

export default Heading;
