import { makeAutoObservable } from "mobx";
import { uid } from "@/utils";

class UnsavedChanges {
  constructor() {
    makeAutoObservable(this);
  }

  get exist() {
    const dependencies = Object.values(this.dependencies);

    return dependencies.some((fn) => fn());
  }

  dependencies: Dependencies = {};

  register = (dependency: Dependency): Unregister => {
    const id = uid();

    this.dependencies[id] = dependency;

    return this.unregister.bind(null, id);
  };

  unregister = (id: number) => {
    // doesn't extract the [`[id]: removed` for some reason
    // const { [id]: removed, ...dependencies } = this.dependencies;

    const dependencies = { ...this.dependencies };

    delete dependencies[id];

    this.dependencies = dependencies;
  };
}

export const unsavedChanges = new UnsavedChanges();

interface Dependencies {
  [id: number]: Dependency;
}

type Dependency = () => boolean;
type Unregister = () => void;
