import { Format, formatNumberToStringDisplay } from "./../../../negotiations/services/utils/converters";
import { DefaultPeriodFormat } from "@/models";
import {
  FreightRateProps,
  DemurrageProps,
  OrderDetails,
  OrderExtraDetails,
  LiftingsProps,
  HireRateProps,
  SupplyPriceProps,
  CleaningPriceProps,
  BallastBonusProps,
  DurationProps,
  OrderCoaDetails,
} from "___REFACTOR___/models";
import { TradeAPI } from "___REFACTOR___/apis/Trade";
import { HandlebarWithReplacement } from "./HandlebarWithReplacement";
import { HandlebarTextChunk } from "./HandlebarTextChunk";
import { format } from "___REFACTOR___/utils";
import dayjs from "dayjs";

export class HandlebarParser {
  handleBarRegex = new RegExp("{{(\\w+)}}", "g");
  keywordToExtractor = [
    { keyword: "VESSEL_IMO_NUMBER", extractor: (details: OrderDetails) => details.vessels?.vessels?.[0]?.vesselIMO },
    { keyword: "VESSEL_NAME", extractor: (details: OrderDetails) => details.vessels?.vessels?.[0]?.registrationDataName },
    { keyword: "VESSEL_ITINERARY", extractor: (details: OrderDetails) => details.vessels?.vessels?.[0]?.itinerary },
    { keyword: "VESSEL_DESCRIPTION", extractor: (details: OrderDetails) => details.vessels?.vessels?.[0]?.vesselDescription },
    { keyword: "VESSEL_OWNER_CHAIN", extractor: (details: OrderDetails) => details.vessels?.vessels?.[0]?.ownerChain },
    { keyword: "VESSEL_ADDITIONAL_NOTES", extractor: (details: OrderDetails) => details.vessels?.vessels?.[0]?.additionalNotes },
    {
      keyword: "VESSEL_ETA",
      extractor: (details: OrderDetails) => {
        // fix for #142683
        const vessel = details.vessels?.vessels?.[0];
        let eta: string | undefined | Date | dayjs.Dayjs;
        if (vessel?.eta) {
          eta = vessel.eta;
        } else if (vessel?._) {
          eta = vessel._?.eta?.value;
        }
        return this.formatDate(eta, "ddd MMM DD YYYY");
      },
    },
    {
      keyword: "VESSEL_SPEED_AND_CONS",
      extractor: (details: OrderDetails) => details.vessels?.vessels?.[0]?.speedAndConsumption,
    },
    { keyword: "VESSEL_SIZE", extractor: (details: OrderDetails) => details.vesselSize?.toView() },

    { keyword: "ADDITIONAL_VESSELS", extractor: (details: OrderDetails) => details.vesselSize?.notes },
    { keyword: "OWNER_NAME", extractor: (details: OrderDetails) => details.ownerAccount?.accountName },
    { keyword: "CHARTERER_NAME", extractor: (details: OrderDetails) => details.chartererAccount?.accountName },

    {
      keyword: "CARGO_SIZE",
      extractor: (details: OrderDetails & OrderCoaDetails) => {
        return format.number.display(details.cargoSize?.value) || details.coaCargoSize?.sizeRangeToString();
      },
    },
    { keyword: "CARGO_SIZE_MAXIMUM", extractor: (details: OrderCoaDetails) => format.number.display(details.coaCargoSize?.max) },
    { keyword: "CARGO_SIZE_MINIMUM", extractor: (details: OrderCoaDetails) => format.number.display(details.coaCargoSize?.min) },
    {
      keyword: "CARGO_SIZE_VARIANCE_OPTION",
      extractor: (details: OrderDetails & OrderCoaDetails) => details.cargoSize?.option || details.coaCargoSize?.option,
    },
    {
      keyword: "CARGO_SIZE_VARIANCE_VALUE",
      extractor: (details: OrderDetails & OrderCoaDetails) =>
        format.number.display(details.cargoSize?.variance) || format.number.display(details.coaCargoSize?.variance),
    },
    // { keyword: "CARGO_SIZE_NOTES", extractor: (details: OrderDetails) => details.cargoSize?.notes },
    {
      keyword: "ADDITIONAL_CARGO_SIZES",
      extractor: (details: OrderDetails & OrderCoaDetails) => details.cargoSize?.notes || details.coaCargoSize?.notes,
    },
    { keyword: "CARGO", extractor: (details: OrderDetails) => details.cargoType?.name },
    // { keyword: "CARGO_TYPE_NOTES", extractor: (details: OrderDetails) => details.cargoType?.notes },
    { keyword: "ADDITIONAL_CARGO_TYPES", extractor: (details: OrderDetails) => details.cargoType?.notes },

    { keyword: "LAYCAN_FROM", extractor: (details: OrderDetails) => this.formatDate(details.laycan?.from) },
    { keyword: "LAYCAN_TO", extractor: (details: OrderDetails) => this.formatDate(details.laycan?.to) },
    { keyword: "LAYCAN", extractor: (details: OrderDetails) => details.laycan?.toView() },

    { keyword: "COA_PERIOD_FROM", extractor: (details: OrderDetails) => this.formatDate(details.period?.from) },
    { keyword: "COA_PERIOD_TO", extractor: (details: OrderDetails) => this.formatDate(details.period?.to) },
    { keyword: "COA_PERIODS", extractor: (details: OrderDetails) => details.period?.toView() },
    { keyword: "COA_ORDER_NOTES", extractor: (details: OrderCoaDetails) => details.coaNotes?.data },

    { keyword: "LOAD_LOCATIONS", extractor: (details: OrderDetails) => details.loadLocation?.toDropdownView() },
    { keyword: "ADDITIONAL_LOAD_LOCATIONS", extractor: (details: OrderDetails) => details.loadLocation?.notes },
    { keyword: "DISCHARGE_LOCATIONS", extractor: (details: OrderDetails) => details.dischargeLocation?.toDropdownView() },
    { keyword: "ADDITIONAL_DISCHARGE_LOCATIONS", extractor: (details: OrderDetails) => details.dischargeLocation?.notes },
    { keyword: "DELIVERY_LOCATION", extractor: (details: OrderDetails) => details.deliveryLocation?.toDropdownView() },
    { keyword: "DELIVERY_LOCATION_NOTES", extractor: (details: OrderDetails) => details.deliveryLocation?.notes },
    { keyword: "REDELIVERY_LOCATION", extractor: (details: OrderDetails) => details.redeliveryLocation?.toDropdownView() },
    { keyword: "REDELIVERY_LOCATION_NOTES", extractor: (details: OrderDetails) => details.redeliveryLocation?.notes },
    { keyword: "VIA_LOCATION", extractor: (details: OrderDetails) => details.viaLocation?.toDropdownView() },
    { keyword: "VIA_LOCATION_NOTES", extractor: (details: OrderDetails) => details.viaLocation?.notes },

    { keyword: "FREIGHT_RATE", extractor: (details: OrderDetails) => format.number.display(details.freightRate?.value) },
    {
      keyword: "FREIGHT_RATE_UNIT",
      extractor: (details: OrderDetails) => details.freightRate?.unit || FreightRateProps.prototype.JSONDefaults?.unit,
    },
    { keyword: "FREIGHT_RATE_NOTES", extractor: (details: OrderDetails) => details.freightRate?.notes },

    { keyword: "DEMURRAGE", extractor: (details: OrderDetails) => format.number.display(details.demurrage?.value) },
    {
      keyword: "DEMURRAGE_UNIT",
      extractor: (details: OrderDetails) => details.demurrage?.unit || DemurrageProps.prototype.JSONDefaults?.unit,
    },
    { keyword: "DEMURRAGE_NOTES", extractor: (details: OrderDetails) => details.demurrage?.notes },

    { keyword: "HIRE_RATE", extractor: (details: OrderDetails) => format.number.display(details.hireRate?.value) },
    { keyword: "HIRE_RATE_NOTES", extractor: (details: OrderDetails) => details.hireRate?.notes },
    {
      keyword: "HIRE_RATE_UNIT",
      extractor: (details: OrderDetails) => details.hireRate?.unit || HireRateProps.prototype.JSONDefaults?.unit,
    },

    { keyword: "ILOHC", extractor: (details: OrderDetails) => format.number.display(details.cleaningPrice?.value) },
    { keyword: "ILOHC_NOTES", extractor: (details: OrderDetails) => details.cleaningPrice?.notes },
    {
      keyword: "ILOHC_UNIT",
      extractor: (details: OrderDetails) => details.cleaningPrice?.unit || CleaningPriceProps.prototype.JSONDefaults?.unit,
    },

    { keyword: "CVE", extractor: (details: OrderDetails) => format.number.display(details.supplyPrice?.value) },
    { keyword: "CVE_NOTES", extractor: (details: OrderDetails) => details.supplyPrice?.notes },
    {
      keyword: "CVE_UNIT",
      extractor: (details: OrderDetails) => details.supplyPrice?.unit || SupplyPriceProps.prototype.JSONDefaults?.unit,
    },

    { keyword: "TRADING_EXCLUSIONS", extractor: (details: OrderDetails) => details.tradingExclusions?.value },

    {
      keyword: "BUNKER_PRICE_DELIVERY",
      extractor: (details: OrderDetails) => toHandlebar(details.bunkerDelivery?.price, valueType.Price),
    },
    {
      keyword: "BUNKER_QUANTITY_DELIVERY",
      extractor: (details: OrderDetails) => toHandlebar(details.bunkerDelivery?.quantity, valueType.Quantity),
    },
    { keyword: "FUEL_TYPE_DELIVERY", extractor: (details: OrderDetails) => details.bunkerDelivery?.fuelTypes },
    { keyword: "BUNKER_NOTES_DELIVERY", extractor: (details: OrderDetails) => details.bunkerDelivery?.notes },
    {
      keyword: "BUNKER_PRICE_REDELIVERY",
      extractor: (details: OrderDetails) => toHandlebar(details.bunkerRedelivery?.price, valueType.Price),
    },
    {
      keyword: "BUNKER_QUANTITY_REDELIVERY",
      extractor: (details: OrderDetails) => toHandlebar(details.bunkerRedelivery?.quantity, valueType.Quantity),
    },
    { keyword: "FUEL_TYPE_REDELIVERY", extractor: (details: OrderDetails) => details.bunkerRedelivery?.fuelTypes },
    { keyword: "BUNKER_NOTES_REDELIVERY", extractor: (details: OrderDetails) => details.bunkerRedelivery?.notes },

    { keyword: "CARGO_EXCLUSIONS", extractor: (details: OrderDetails) => details.cargoExclusionsText?.value },

    { keyword: "BALLAST_BONUS", extractor: (details: OrderDetails) => format.number.display(details.ballastBonus?.value) },
    {
      keyword: "BALLAST_BONUS_UNIT",
      extractor: (details: OrderDetails) => details.ballastBonus?.unit || BallastBonusProps.prototype.JSONDefaults?.unit,
    },
    { keyword: "BALLAST_BONUS_NOTES", extractor: (details: OrderDetails) => details.ballastBonus?.notes },
    { keyword: "TC_ORDER_NOTES", extractor: (details: OrderDetails) => details.tctNotes?.data },

    { keyword: "DURATION", extractor: (details: OrderDetails) => details.duration?.toView() },
    { keyword: "DURATION_MAXIMUM", extractor: (details: OrderDetails) => details.duration?.max },
    { keyword: "DURATION_MINIMUM", extractor: (details: OrderDetails) => details.duration?.min },
    { keyword: "DURATION_NOTES", extractor: (details: OrderDetails) => details.duration?.notes },
    {
      keyword: "DURATION_UNIT",
      extractor: (details: OrderDetails) => details.duration?.unit || DurationProps.prototype.JSONDefaults?.unit,
    },

    { keyword: "VOY_NOTES", extractor: (details: OrderDetails) => details.voyageNotes?.data },
    { keyword: "TCT_NOTES", extractor: (details: OrderDetails) => details.tctNotes?.data },

    // same as VOY_NOTES above - renamed for consistency reasons and the other may be removed in a later story
    { keyword: "VOY_ORDER_NOTES", extractor: (details: OrderDetails) => details.voyageNotes?.data },

    { keyword: "ADDRESS_COMMISSION", extractor: (details: OrderDetails) => details.addressCommission?.value },
    { keyword: "BROKER_COMMISSION", extractor: (details: OrderDetails) => details.brokerCommission?.value },

    { keyword: "LIFTINGS_MIN", extractor: (details: OrderDetails & OrderExtraDetails) => details.liftings?.min },
    { keyword: "LIFTINGS_MAX", extractor: (details: OrderDetails & OrderExtraDetails) => details.liftings?.max },
    {
      keyword: "LIFTINGS_DISPERSAL",
      extractor: (details: OrderDetails & OrderExtraDetails) =>
        details.liftings?.dispersal || LiftingsProps.prototype.JSONDefaults?.dispersal,
    },
    { keyword: "LIFTINGS_NOTES", extractor: (details: OrderDetails & OrderExtraDetails) => details.liftings?.notes },
    { keyword: "LIFTINGS", extractor: (details: OrderDetails & OrderExtraDetails) => details.liftings?.toView() },
    {
      keyword: "DISCHARGE_LOCATION_SAFE_ANCHORAGES",
      extractor: (details: OrderDetails & OrderExtraDetails) =>
        convertLocationSafe(details.dischargeLocation?.safeAnchoragesMin, details.dischargeLocation?.safeAnchoragesMax),
    },
    {
      keyword: "DISCHARGE_LOCATION_SAFE_BERTHS",
      extractor: (details: OrderDetails & OrderExtraDetails) =>
        convertLocationSafe(details.dischargeLocation?.safeBerthsMin, details.dischargeLocation?.safeBerthsMax),
    },
    {
      keyword: "DISCHARGE_LOCATION_SAFE_PORTS",
      extractor: (details: OrderDetails & OrderExtraDetails) =>
        convertLocationSafe(details.dischargeLocation?.safePortsMin, details.dischargeLocation?.safePortsMax),
    },
    {
      keyword: "LOAD_LOCATION_SAFE_ANCHORAGES",
      extractor: (details: OrderDetails & OrderExtraDetails) =>
        convertLocationSafe(details.loadLocation?.safeAnchoragesMin, details.loadLocation?.safeAnchoragesMax),
    },
    {
      keyword: "LOAD_LOCATION_SAFE_BERTHS",
      extractor: (details: OrderDetails & OrderExtraDetails) =>
        convertLocationSafe(details.loadLocation?.safeBerthsMin, details.loadLocation?.safeBerthsMax),
    },
    {
      keyword: "LOAD_LOCATION_SAFE_PORTS",
      extractor: (details: OrderDetails & OrderExtraDetails) =>
        convertLocationSafe(details.loadLocation?.safePortsMin, details.loadLocation?.safePortsMax),
    },
    {
      keyword: "NOMINATIONS_NOTICE_PER_LAYCAN",
      extractor: (details: OrderDetails & OrderExtraDetails) => details.nominations?.noticePerLaycan,
    },
    {
      keyword: "NOMINATIONS_LAYCAN_SPREAD",
      extractor: (details: OrderDetails & OrderExtraDetails) => details.nominations?.laycanSpread,
    },
    {
      keyword: "NOMINATIONS_FINAL_LAYCAN_NOTICE",
      extractor: (details: OrderDetails & OrderExtraDetails) => details.nominations?.finalLaycanNotice,
    },
    { keyword: "NOMINATIONS_NOTES", extractor: (details: OrderDetails & OrderExtraDetails) => details.nominations?.notes },
    { keyword: "NOMINATIONS", extractor: (details: OrderDetails & OrderExtraDetails) => details.nominations?.toView() },

    { keyword: "OWNER_ADDRESS", extractor: () => null },
    { keyword: "CHARTERER_ADDRESS", extractor: () => null },
    { keyword: "BROKER_ADDRESS", extractor: () => null },

    // For these handlebars is it not clear where to get data from (or if they are replaced later in ARC):
    // {keyword: "ACCOUNT", extractor:(details: OrderDetails) => details},
  ];

  deferredHandlebars = ["OWNER_ADDRESS", "CHARTERER_ADDRESS", "BROKER_ADDRESS"];

  public getHandlebars(term: string, details: OrderDetails & OrderCoaDetails): HandlebarWithReplacement[] {
    if (!term) {
      return [];
    }

    const handlebars: HandlebarWithReplacement[] = [];
    const matches = Array.from(term.matchAll(this.handleBarRegex));
    for (const match of matches) {
      const [fullMatch, keyword] = match;
      const extractor = this.keywordToExtractor.find((e) => e.keyword === keyword)?.extractor;
      if (extractor) {
        if (this.deferredHandlebars.some((word) => word === keyword)) {
          handlebars.push({ handlebar: fullMatch, replacement: "", isReplacedLater: true });
        } else {
          const parsedValue = details ? extractor(details) : "";
          handlebars.push({ handlebar: fullMatch, replacement: parsedValue || "", isReplacedLater: false });
        }
      }
    }
    return handlebars;
  }

  public areHandlebarsSame(handlebars1: HandlebarWithReplacement[], handlebars2: HandlebarWithReplacement[]) {
    if (!handlebars1 && !handlebars2) {
      return true;
    }

    if (!handlebars1 || !handlebars2) {
      return false;
    }

    if (handlebars1.length !== handlebars2.length) {
      return false;
    }

    if (handlebars1 === handlebars2) {
      return true;
    }

    const allSame = handlebars1.every((handlebar1, i) => {
      const handlebar2 = handlebars2[i];
      return handlebar1.handlebar === handlebar2.handlebar && handlebar1.replacement === handlebar2.replacement;
    });

    return allSame;
  }

  public replaceHandlebarsIfNeeded(term: TradeAPI.Termset.Content.Term): HandlebarTextChunk[] {
    let { content, handlebars } = term;
    content = content || "";

    if (!handlebars) {
      return [HandlebarTextChunk.text(content)];
    }

    const result: HandlebarTextChunk[] = [];
    const matches = Array.from(content.matchAll(this.handleBarRegex));
    let lastEnd = 0;
    for (const match of matches) {
      const [fullMatch, handlebarName] = match;
      const start = match.index;
      if (start === undefined) {
        continue;
      }
      const end = start + fullMatch.length;
      const normalString = content.substring(lastEnd, start);
      if (normalString) {
        result.push(HandlebarTextChunk.text(normalString));
      }

      const handlebar = handlebars.find((e) => e.handlebar === fullMatch);
      if (handlebar) {
        if (handlebar.isReplacedLater) {
          result.push(HandlebarTextChunk.deferredHandlebar("", handlebarName));
        } else {
          result.push(HandlebarTextChunk.handlebar(handlebar.replacement + "", handlebarName));
        }
      } else {
        result.push(HandlebarTextChunk.unknownHandlebar(fullMatch));
      }
      lastEnd = end;
    }
    const rest = content.substring(lastEnd);
    result.push(HandlebarTextChunk.text(rest));
    return result;
  }

  private formatDate(
    date: string | undefined | Date | dayjs.Dayjs,
    format: string | undefined = DefaultPeriodFormat
  ): string | undefined {
    if (date) {
      return dayjs.utc(date).format(format);
    }
    return date;
  }
}

function toHandlebar(value: number | undefined, type: valueType) {
  return value
    ? type === valueType.Quantity
      ? formatNumberToStringDisplay(value, Format.DEFAULT)
      : formatNumberToStringDisplay(value, Format.LIMITED_TO)
    : "";
}

function convertLocationSafe(min: number | undefined, max: number | undefined) {
  return min === max ? max?.toString() : `${min}-${max}`;
}

enum valueType {
  Price,
  Quantity,
}
