import * as React from "react";
import { Button } from "@/components";

export default (props: any) => {
  const {
    ariaLabel,
    children,
    className,
    dataKey,
    dataTest,
    disabled,
    openHrefInNewWindow,
    icon,
    reactRef,
    type,
    href,
    to,
    onClick,
    img,
    containerClassName,
  } = props;
  const _props = {
    children,
    className,
    disabled,
    icon,
    onClick,
    href,
    to,
    img,
    openHrefInNewWindow,
    variant: type,
    dom: reactRef,
    containerClassName,
  };

  return <Button aria-label={ariaLabel} data-key={dataKey} data-test={dataTest} dataTest={dataTest} {..._props} />;
};
