/* eslint-disable react-hooks/exhaustive-deps */
import { timer } from "@/models";
import dayjs from "dayjs";
import jwtdecode from "jwt-decode";
import { useEffect, useState, useCallback, DependencyList, useMemo, useRef } from "react";

export function isStr(val) {
  return typeof val === "string";
}

export function isFn(val) {
  return typeof val === "function";
}

export function isArr(val) {
  return Array.isArray(val);
}

export function isPrimitive(val) {
  if (typeof val === "string" || typeof val === "number" || typeof val === "symbol") return true;

  if (val === null || val === undefined || Number.isNaN(val)) return true;

  return false;
}

export function filterObject(object, predicate: (value, key) => truthyfalsy) {
  if (typeof object !== "object") return object;

  const entries = Object.entries(object);
  const res = {};

  for (let i = 0; i < entries.length; i++) {
    const entry = entries[i];
    const [key, value] = entry;

    if (predicate(value, key)) {
      res[key] = value;
    }
  }

  return res;
}

export function useQueue() {
  const [queue, setQueue] = useState<any[]>([]);

  function consumeQueue(length = queue.length) {
    const items = queue.splice(0, length);

    setQueue([...queue]);

    return items;
  }

  function pushQueue(...items) {
    setQueue(items.concat(queue));
  }

  return [queue, pushQueue, consumeQueue];
}

/**
 * Will keep calling the function until it returns truthy value;
 */
export async function poll<T>(fn: () => T, interval = 1000) {
  let resolve;
  const promise = new Promise<NonNullable<T>>((_resolve) => {
    resolve = _resolve;
  });

  const intervalId = setInterval(caller, interval);

  async function caller() {
    const res = await fn();

    if (res) {
      clearInterval(intervalId);
      resolve(res);
    }
  }

  const res = await fn();

  if (res) {
    clearInterval(intervalId);
    resolve(res);
  }

  return promise;
}

export function useExecuteAtDate(date: Date | string | undefined, callback) {
  useEffect(effect, [date]);

  function effect() {
    if (!date) return;

    const diff = new Date(date).getTime() - new Date().getTime();

    if (diff <= 0) callback();
    //
    else {
      const timeoutId = setTimeout(callback, diff);

      return clearTimeout.bind(null, timeoutId);
    }
  }
}

export function usePromise<T>(deps = [] as DependencyList) {
  const resolveRef = useRef<Function>();
  const promise = useMemo(createPromise, deps);

  function createPromise() {
    return new Promise<T>((resolve) => {
      resolveRef.current = resolve;
    });
  }

  function resolve(value?: T) {
    resolveRef.current?.(value);
  }

  return [promise, resolve] as const;
}

export function useKeepRerenderingUntilTruthy(value, interval = 50) {
  const [, setCounter] = useState(0);

  useEffect(setup, [value, interval]);

  function setup() {
    if (value) return;

    const cleanup = timer.keepExecuting(rerender, interval);

    return cleanup;
  }

  function rerender() {
    setCounter(increment);
  }

  function increment(counter) {
    return counter + 1;
  }
}

export function useKeepRerendering(interval = 1000) {
  const [, setCounter] = useState(0);

  useEffect(setup, [interval]);

  function setup() {
    const cleanup = timer.keepExecuting(rerender, interval);

    return cleanup;
  }

  function rerender() {
    setCounter(increment);
  }

  function increment(counter) {
    return counter + 1;
  }
}

export function useEfficientTimeRerenderer(...dates: EfficientTimeRerendererDate[]) {
  const now = dayjs();
  let smallestRefreshInterval = msinhour;

  for (let i = 0; i < dates.length; i++) {
    const { date, type } = dates[i];

    if (date === undefined || date === null) continue;

    const _date = dayjs(date);

    if (!_date.isValid()) continue;

    const diff = _date.diff(now);

    if (0 > diff && type === "timeleft") continue;
    if (0 < diff && type === "timepassed") continue;

    const diffAbs = Math.abs(diff);
    let interval = smallestRefreshInterval;

    if (diffAbs < msinminute) interval = msinsecond;
    else if (diffAbs < msinhour) interval = msinminute;
    else if (diffAbs < msinday) interval = msinhour;

    if (interval < smallestRefreshInterval) smallestRefreshInterval = interval;
  }

  useKeepRerendering(smallestRefreshInterval);

  return smallestRefreshInterval;
}

export function useSimpleEffect(effect: Function | undefined | null, deps: DependencyList) {
  function _effect() {
    effect?.();
  }

  useEffect(_effect, deps);
}

export function useForceUpdate() {
  const [, _update] = useState(0);

  const update = useCallback(() => {
    _update((counter) => ++counter);
  }, []);

  return update;
}

export function useRerenderOnceAfterMounting() {
  const [, setHasRerendered] = useState(false);

  useEffect(onMount, []);

  function onMount() {
    setHasRerendered(true);
  }
}

export function useDebounce(ms?: number, fn?: Function, deps?: any[]) {
  useEffect(() => {
    if (!fn) return;

    if (!ms) return fn();

    const timeoutId = setTimeout(fn, ms);

    return () => clearTimeout(timeoutId);
  }, deps);
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function emptyFn(): void {}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const emptyArrowFn = (): void => {};

export function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

let _uid = 1;

export function uid() {
  return _uid++;
}

export function jwtDecode(jwt: any, defaultValue?) {
  if (typeof jwt !== "string") return defaultValue;

  let res;

  try {
    res = jwtdecode(jwt);
  } catch {
    res = defaultValue;
  }

  return res;
}

export function capitalizeFirstLetter(str: string | null | undefined) {
  if (typeof str !== "string") return str;

  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function lowerCaseFirstLetter(str: string | null | undefined) {
  if (typeof str !== "string") return str;

  return str.charAt(0).toLowerCase() + str.slice(1);
}

export function timeleft(until: string | Date) {
  const now = new Date();
  let timeleft;

  until = until instanceof Date ? until : new Date(until);

  if (now < until) timeleft = duration(dayjs(until).diff(new Date()));

  return timeleft;
}

export function timepassed(since: dayjs.ConfigType) {
  if (!since) return "";

  return dayjs(since).fromNow();
}

export function stringArrToBoolMap<T extends string[]>(array: T) {
  const res = {} as Record<typeof array[number], true>;

  if (!array) return res;

  for (let i = 0; i < array.length; i++) {
    const string = array[i];

    res[string] = true;
  }

  return res;
}

export function isTruthy(param) {
  return !!param;
}

export function duration(ms: number) {
  const days = Math.floor(ms / msinday);
  const daysms = ms % msinday;
  const hours = Math.floor(daysms / msinhour);
  const hoursms = ms % msinhour;
  const minutes = Math.floor(hoursms / msinminute);
  const minutesms = ms % msinminute;
  const secs = Math.floor(minutesms / msinsecond);

  let daystext = "";
  let hourstext = "";
  let minutestext = "";
  let secstext = "";

  if (days < 10) daystext = "0" + days + ":";
  else daystext = days + ":";

  if (hours < 10) hourstext = "0" + hours + ":";
  else hourstext = hours + ":";

  if (minutes < 10) minutestext = "0" + minutes + ":";
  else minutestext = minutes + ":";

  if (secs < 10) secstext = "0" + secs + "";
  else secstext = secs + "";

  const text = daystext + hourstext + minutestext + secstext;

  return { days, hours, minutes, secs, text };
}

const msinsecond = 1000;
const msinminute = 60 * msinsecond;
const msinhour = 60 * msinminute;
const msinday = 24 * msinhour;

export function duplicateData(original) {
  const res = original;

  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));

  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));

  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));

  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // //
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // //
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // //
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // //
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // //
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  // res = res.concat(original.map(duplicate));
  //

  return res;
}

export function randomInteger(min, max) {
  return Math.max(min, Math.round(Math.random() * max));
}

export function hasKeys(value) {
  if (!value) return false;

  if (typeof value !== "object") return false;

  return !!Object.keys(value).length;
}

export type Merge<T, R> = Omit<T, keyof R> & R;

interface EfficientTimeRerendererDate {
  date: dayjs.ConfigType | undefined | null;
  type: "timeleft" | "timepassed";
}
