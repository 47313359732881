import { Observable, from } from "rxjs";
import { observableFetch } from "./observableFetch";
import { Role } from "../../../components/negotiate/Negotiation/Role";
import { OrderNegType } from "sharedFolder/Models/OrderNegType";

interface IUpdateResponse {
  id: string;
  version: number;
}

const orderTypeUrlPrefix: { [key in OrderNegType]: string } = {
  VOY: "voyages",
  TCT: "timechartertrips",
  COA: "coas",
  LFT: "liftings",
};

const commonHeaders = {
  "X-API-Version": "3",
  "Content-Type": "application/json",
};
export function visibleToCharterer(
  baseUrl: string,
  orderId: string,
  orderType: OrderNegType,
  negotiationId: string,
  updateToken: string,
  visibility: boolean
) {
  return observableFetch<IUpdateResponse>(() =>
    fetch(`${baseUrl}/orders/${orderId}/${orderTypeUrlPrefix[orderType]}/${negotiationId}/showcharterer`, {
      method: "PUT",
      headers: commonHeaders,
      body: JSON.stringify({
        enabled: visibility,
        updateToken,
      }),
    })
  );
}

export function updateNote(
  baseUrl: string,
  orderId: string,
  orderType: OrderNegType,
  negotiationId: string,
  role: Role,
  note: string,
  updateToken: string
): Observable<IUpdateResponse> {
  const notesUrlBaseOnRole: { [key in Role]: string } = {
    charterer: "controllernegotiationnotes",
    owner: "offerrepnegotiationnotes",
  };
  return observableFetch<IUpdateResponse>(() =>
    fetch(`${baseUrl}/orders/${orderId}/${orderTypeUrlPrefix[orderType]}/${negotiationId}/${notesUrlBaseOnRole[role]}`, {
      method: "PUT",
      headers: commonHeaders,
      body: JSON.stringify({
        updateToken,
        value: note,
      }),
    })
  );
}

export function moveNegotiationToOrder(
  baseUrl: string,
  negotiationId: string,
  sourceOrderId: string,
  targetOrderId: string,
  orderType: OrderNegType,
  updateToken: string
): Observable<IUpdateResponse> {
  return from(
    fetch(`${baseUrl}/orders/${sourceOrderId}/${orderTypeUrlPrefix[orderType]}/${negotiationId}/order/${targetOrderId}`, {
      method: "POST",
      headers: commonHeaders,
      body: JSON.stringify({ updateToken }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to move negotiation");
        }
        return response.json();
      })
      .then((data) => data as IUpdateResponse)
  );
}
