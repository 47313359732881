import { observer } from "mobx-react";
import classNames from "classnames";
import { ProformaLayout, proformaLayouts } from "@/models";
import { SelectField, SelectFieldProps } from "@/components";

function ProformaLayoutEditor(props: Props) {
  props = { ...props };

  props.className = classNames(props.className, "ProformaLayout");

  const { layouts, layoutsById } = proformaLayouts;

  const layoutId = props.value?.layoutId;

  const value = layoutId ? layoutsById?.[layoutId] : null;

  return (
    <SelectField
      {...props}
      data={layouts}
      value={value}
      noDataStatus={noDataStatus}
      dataTest={`${props.dataTest}.value`}
      filterKey="name"
    />
  );
}

const noDataStatus = {
  message: "No Proforma Layouts found",
};

const Observer = observer(ProformaLayoutEditor);

export { Observer as ProformaLayoutEditor };

interface Props extends Omit<SelectFieldProps, "value" | "onChange"> {
  value?: ProformaLayout;
  onChange?: (value: ProformaLayout) => void;
}
