import React, { useState } from "react";
import { IClock } from "sharedFolder/components/common/interfaces";
import { useTimer } from "./useTimer";
import styles from "./DigitalClock.module.scss";

export const DigitalClock: React.FC = () => {
  const [now, setNow] = useState<IClock | { Days: ""; Hours: ""; Minutes: ""; Seconds: "" }>();

  useTimer((timeDiff) => {
    setNow(timeDiff);
  });

  return (
    <span className={styles.timer}>
      {now ? (
        <span>
          (GMT/UTC is currently {now.Hours}:{now.Minutes})
        </span>
      ) : null}
    </span>
  );
};
