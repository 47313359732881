import { DealCaptureType, TradeAPI } from "@/apis";
import { cleanValue } from "@/utils";
import { TimelimitEditorProps } from "@/components";
import { auth } from "@/models";
import { SubjectTermset, Termset } from "../Termset";
import { ChartererAccount } from "../ChartererAccount";
import { OwnerAccount } from "../OwnerAccount";
import { Period } from "../Period";
import { Commission } from "../Commission";
import { AddressCommission } from "../AddressCommission";
import { BrokerCommission } from "../BrokerCommission";
import { BunkerDelivery, BunkerRedelivery } from "../Bunker";
import { CargoSize } from "../CargoSize";
import { CargoType } from "../CargoType";
import { VesselSize } from "../VesselSize";
import { LoadLocation } from "../LoadLocation";
import { DischargeLocation } from "../DischargeLocation";
import { DeliveryLocation } from "../DeliveryLocation";
import { RedeliveryLocation } from "../RedeliveryLocation";
import { ViaLocation } from "../ViaLocation";
import { Duration } from "../Duration";
import { Nominations } from "../Nominations";
import { Liftings } from "../Liftings";
import { COACargoSize } from "../COACargoSize";
import { Vessels } from "../Vessels";
import { Vessel } from "../Vessel";
import { DateOffset } from "../DateOffset";
import { OrderTemplate } from "../OrderTemplate";
import { CustomDate } from "../CustomDate";
import { FreightRate, HireRate, CleaningPrice, SupplyPrice, Demurrage, BallastBonus } from "../UnitValue";
import { Note as NegotiationNote } from "../Negotiation/Note";
import { CommercialSubs, OperationalSubs, TradingExclusions, CargoExclusionsText } from "../Subs";
import { CLDDUOwnerInvitee } from "../CLDDUOwner";
import { CLDDUBroker, CLDDUCharterer, CLDDUOwner } from "../CLDDU";
import { COANotes, OrderNotes, TCNotes, VoyageNotes, AuditNotes } from "../OrderNotes";

export class UniversalOrderNegotiationFormValues {
  constructor(values?: UniversalOrderNegotiationFormValues | null) {
    this.manageSubsOption = values?.manageSubsOption || values?.manageSubsOption;
    this.termset = values?.termset || new Termset();
    this.subjectTermset = values?.subjectTermset || new Termset();
    this.orderTemplate = values?.orderTemplate || new OrderTemplate();
    this.invitee = values?.invitee || new CLDDUOwnerInvitee();
    this.ownerAccount = values?.ownerAccount || new OwnerAccount();
    this.account = values?.account || new OwnerAccount();
    this.vessels = values?.vessels || new Vessels();
    this.vessel = values?.vessel || new Vessel();
    this.types = values?.types || values?.types;
    this.type = values?.type || values?.type;
    this.controllers = values?.controllers || [];
    this.timelimitVariant = values?.timelimitVariant || "DateOffset";
    this.indicationType = values?.indicationType || values?.indicationType || "indicated";
    this.jumpTo = values?.jumpTo || "MainTerms";
    this.updateNegotiationNoteFor = values?.updateNegotiationNoteFor || "brokerCharterer";
    this.userTimeZone = values?.userTimeZone || auth.trade.user?.Timezone;
    this.cpDate = values?.cpDate || new CustomDate({ date: new Date(), withoutTimezone: true, startOfDay: true });
    this.negotiationBrokerChartererNote = values?.negotiationBrokerChartererNote || new NegotiationNote();
    this.negotiationOwnerNote = values?.negotiationOwnerNote || new NegotiationNote();
    this.chartererContact = values?.chartererContact || new CLDDUCharterer(undefined, { user: auth.trade.user });
    this.brokerContact = values?.brokerContact || new CLDDUBroker(undefined, { user: auth.trade.user });
    this.ownerContact = values?.ownerContact || new CLDDUOwner();
    this.notes = values?.notes || new OrderNotes();
    this.voyageNotes = values?.voyageNotes || new VoyageNotes();
    this.tctNotes = values?.tctNotes || new TCNotes();
    this.coaNotes = values?.coaNotes || new COANotes();
    this.auditNotes = values?.auditNotes || new AuditNotes();
    this.chartererAccount = values?.chartererAccount || new ChartererAccount();
    this.laycan = values?.laycan || new Period();
    this.cargoType = values?.cargoType || new CargoType();
    this.addressCommission = values?.addressCommission || new AddressCommission();
    this.brokerCommission = values?.brokerCommission || new BrokerCommission();
    this.cargoSize = values?.cargoSize || new CargoSize();
    this.vesselSize = values?.vesselSize || new VesselSize();
    this.loadLocation = values?.loadLocation || new LoadLocation();
    this.dischargeLocation = values?.dischargeLocation || new DischargeLocation();
    this.deliveryLocation = values?.deliveryLocation || new DeliveryLocation();
    this.redeliveryLocation = values?.redeliveryLocation || new RedeliveryLocation();
    this.viaLocation = values?.viaLocation || new ViaLocation();
    this.duration = values?.duration || new Duration();
    this.liftings = values?.liftings || new Liftings();
    this.nominations = values?.nominations || new Nominations();
    this.coaCargoSize = values?.coaCargoSize || new COACargoSize();
    this.period = values?.period || new Period();
    this.ballastBonus = values?.ballastBonus || new BallastBonus();
    this.demurrage = values?.demurrage || new Demurrage();
    this.freightRate = values?.freightRate || new FreightRate();
    this.hireRate = values?.hireRate || new HireRate();
    this.cleaningPrice = values?.cleaningPrice || new CleaningPrice();
    this.supplyPrice = values?.supplyPrice || new SupplyPrice();
    this.tradingExclusions = values?.tradingExclusions || new TradingExclusions();
    this.bunkerDelivery = values?.bunkerDelivery || new BunkerDelivery();
    this.bunkerRedelivery = values?.bunkerRedelivery || new BunkerRedelivery();
    this.cargoExclusionsText = values?.cargoExclusionsText || new CargoExclusionsText();
    this.operationalSubs = values?.operationalSubs || new OperationalSubs();
    this.commercialSubs = values?.commercialSubs || new CommercialSubs();
    this.dealCaptureType = values?.dealCaptureType;
    this.previouslyExecutedCpId = values?.previouslyExecutedCpId;

    this.chartererAccount0 = values?.chartererAccount0 || new ChartererAccount();
    this.laycan0 = values?.laycan0 || new Period();
    this.period0 = values?.period0 || new Period();
    this.liftings0 = values?.liftings0 || new Liftings();
    this.nominations0 = values?.nominations0 || new Nominations();
    this.addressCommission0 = values?.addressCommission0 || new AddressCommission();
    this.brokerCommission0 = values?.brokerCommission0 || new BrokerCommission();
    this.cargoType0 = values?.cargoType0 || new CargoType();
    this.cargoSize0 = values?.cargoSize0 || new CargoSize();
    this.vesselSize0 = values?.vesselSize0 || new VesselSize();
    this.coaCargoSize0 = values?.coaCargoSize0 || new COACargoSize();
    this.dischargeLocation0 = values?.dischargeLocation0 || new DischargeLocation();
    this.loadLocation0 = values?.loadLocation0 || new LoadLocation();
    this.deliveryLocation0 = values?.deliveryLocation0 || new DeliveryLocation();
    this.redeliveryLocation0 = values?.redeliveryLocation0 || new RedeliveryLocation();
    this.viaLocation0 = values?.viaLocation0 || new ViaLocation();
    this.duration0 = values?.duration0 || new Duration();
    this.ballastBonus0 = values?.ballastBonus0 || new BallastBonus();
    this.demurrage0 = values?.demurrage0 || new Demurrage();
    this.freightRate0 = values?.freightRate0 || new FreightRate();
    this.hireRate0 = values?.hireRate0 || new HireRate();
    this.cleaningPrice0 = values?.cleaningPrice0 || new CleaningPrice();
    this.supplyPrice0 = values?.supplyPrice0 || new SupplyPrice();
    this.tradingExclusions0 = values?.tradingExclusions0 || new TradingExclusions();
    this.bunkerDelivery0 = values?.bunkerDelivery0 || new BunkerDelivery();
    this.bunkerRedelivery0 = values?.bunkerRedelivery0 || new BunkerRedelivery();
    this.cargoExclusionsText0 = values?.cargoExclusionsText0 || new CargoExclusionsText();
    this.operationalSubs0 = values?.operationalSubs0 || new OperationalSubs();
    this.commercialSubs0 = values?.commercialSubs0 || new CommercialSubs();
  }
}

export function getBidOfferDetails(values: UniversalOrderNegotiationFormValues) {
  return {
    tctNotes: values.tctNotes,
    voyageNotes: values.voyageNotes,
    chartererAccount: values.chartererAccount && { value: values.chartererAccount },
    laycan: values.laycan && { value: values.laycan },
    cargoType: values.cargoType && { value: values.cargoType },
    addressCommission: values.addressCommission && { value: values.addressCommission },
    brokerCommission: values.brokerCommission && { value: values.brokerCommission },
    cargoSize: values.cargoSize && { value: values.cargoSize },
    dischargeLocation: values.dischargeLocation && { value: values.dischargeLocation },
    loadLocation: values.loadLocation && { value: values.loadLocation },
    deliveryLocation: values.deliveryLocation && { value: values.deliveryLocation },
    duration: values.duration && { value: values.duration },
    redeliveryLocation: values.redeliveryLocation && { value: values.redeliveryLocation },
    vesselSize: values.vesselSize && { value: values.vesselSize },
    viaLocation: values.viaLocation && { value: values.viaLocation },
    liftings: values.liftings && { value: values.liftings },
    nominations: values.nominations && { value: values.nominations },
    coaCargoSize: values.coaCargoSize && { value: values.coaCargoSize },
    period: values.period && { value: values.period },
    ballastBonus: values.ballastBonus && { value: values.ballastBonus },
    demurrage: values.demurrage && { value: values.demurrage },
    freightRate: values.freightRate && { value: values.freightRate },
    hireRate: values.hireRate && { value: values.hireRate },
    cleaningPrice: values.cleaningPrice && { value: values.cleaningPrice },
    supplyPrice: values.supplyPrice && { value: values.supplyPrice },
    tradingExclusions: values.tradingExclusions && { value: values.tradingExclusions },
    bunkerDelivery: values.bunkerDelivery && { value: values.bunkerDelivery },
    bunkerRedelivery: values.bunkerRedelivery && { value: values.bunkerRedelivery },
    cargoExclusionsText: values.cargoExclusionsText && { value: values.cargoExclusionsText },
    operationalSubs: values.operationalSubs && { value: values.operationalSubs },
    commercialSubs: values.commercialSubs && { value: values.commercialSubs },
  };
}

export function getBidDetails(values: UniversalOrderNegotiationFormValues) {
  return {
    chartererAccount: values.chartererAccount0 && { value: values.chartererAccount0 },
    laycan: values.laycan0 && { value: values.laycan0 },
    cargoType: values.cargoType0 && { value: values.cargoType0 },
    addressCommission: values.addressCommission0 && { value: values.addressCommission0 },
    brokerCommission: values.brokerCommission0 && { value: values.brokerCommission0 },
    cargoSize: values.cargoSize0 && { value: values.cargoSize0 },
    dischargeLocation: values.dischargeLocation0 && { value: values.dischargeLocation0 },
    loadLocation: values.loadLocation0 && { value: values.loadLocation0 },
    deliveryLocation: values.deliveryLocation0 && { value: values.deliveryLocation0 },
    duration: values.duration0 && { value: values.duration0 },
    redeliveryLocation: values.redeliveryLocation0 && { value: values.redeliveryLocation0 },
    vesselSize: values.vesselSize0 && { value: values.vesselSize0 },
    viaLocation: values.viaLocation0 && { value: values.viaLocation0 },
    liftings: values.liftings0 && { value: values.liftings0 },
    nominations: values.nominations0 && { value: values.nominations0 },
    coaCargoSize: values.coaCargoSize0 && { value: values.coaCargoSize0 },
    period: values.period0 && { value: values.period0 },
    ballastBonus: values.ballastBonus0 && { value: values.ballastBonus0 },
    demurrage: values.demurrage0 && { value: values.demurrage0 },
    freightRate: values.freightRate0 && { value: values.freightRate0 },
    hireRate: values.hireRate0 && { value: values.hireRate0 },
    cleaningPrice: values.cleaningPrice0 && { value: values.cleaningPrice0 },
    supplyPrice: values.supplyPrice0 && { value: values.supplyPrice0 },
    tradingExclusions: values.tradingExclusions0 && { value: values.tradingExclusions0 },
    bunkerDelivery: values.bunkerDelivery0 && { value: values.bunkerDelivery0 },
    bunkerRedelivery: values.bunkerRedelivery0 && { value: values.bunkerRedelivery0 },
    cargoExclusionsText: values.cargoExclusionsText0 && { value: values.cargoExclusionsText0 },
    operationalSubs: values.operationalSubs0 && { value: values.operationalSubs0 },
    commercialSubs: values.commercialSubs0 && { value: values.commercialSubs0 },
  };
}

export function getOfferDetails(values: UniversalOrderNegotiationFormValues) {
  return {
    chartererAccount: values.chartererAccount && { value: values.chartererAccount },
    laycan: values.laycan && { value: values.laycan },
    cargoType: values.cargoType && { value: values.cargoType },
    addressCommission: values.addressCommission && { value: values.addressCommission },
    brokerCommission: values.brokerCommission && { value: values.brokerCommission },
    cargoSize: values.cargoSize && { value: values.cargoSize },
    dischargeLocation: values.dischargeLocation && { value: values.dischargeLocation },
    loadLocation: values.loadLocation && { value: values.loadLocation },
    deliveryLocation: values.deliveryLocation && { value: values.deliveryLocation },
    duration: values.duration && { value: values.duration },
    redeliveryLocation: values.redeliveryLocation && { value: values.redeliveryLocation },
    vesselSize: values.vesselSize && { value: values.vesselSize },
    viaLocation: values.viaLocation && { value: values.viaLocation },
    liftings: values.liftings && { value: values.liftings },
    nominations: values.nominations && { value: values.nominations },
    coaCargoSize: values.coaCargoSize && { value: values.coaCargoSize },
    period: values.period && { value: values.period },
    ballastBonus: values.ballastBonus && { value: values.ballastBonus },
    demurrage: values.demurrage && { value: values.demurrage },
    freightRate: values.freightRate && { value: values.freightRate },
    hireRate: values.hireRate && { value: values.hireRate },
    cleaningPrice: values.cleaningPrice && { value: values.cleaningPrice },
    supplyPrice: values.supplyPrice && { value: values.supplyPrice },
    tradingExclusions: values.tradingExclusions && { value: values.tradingExclusions },
    bunkerDelivery: values.bunkerDelivery && { value: values.bunkerDelivery },
    bunkerRedelivery: values.bunkerRedelivery && { value: values.bunkerRedelivery },
    cargoExclusionsText: values.cargoExclusionsText && { value: values.cargoExclusionsText },
    operationalSubs: values.operationalSubs && { value: values.operationalSubs },
    commercialSubs: values.commercialSubs && { value: values.commercialSubs },
  };
}

export function getMainTermsDetails(values: UniversalOrderNegotiationFormValues) {
  return {
    ...resolveDetailsByType(values),
    notes: values.notes,
    invitee: values.invitee,
    ownerAccount: values.ownerAccount,
    vessels: values.vessels,
    controllers: values.controllers,
  };
}

export function getCreateDealCapturePayload(details: UniversalOrderNegotiationFormValues) {
  return {
    chartererAccount: details.chartererAccount,
    laycan: details.laycan,
    cargoType: details.cargoType,
    addressCommission: details.addressCommission,
    brokerCommission: details.brokerCommission,
    types: details.types,
    ...(details.types?.includes("Voy") && {
      cargoSize: details.cargoSize,
      loadLocation: details.loadLocation,
      dischargeLocation: details.dischargeLocation,
      voyageNotes: details.voyageNotes,
      freightRate: details.freightRate,
      demurrage: details.demurrage,
    }),
    ...(details.types?.includes("Tct") && {
      vesselSize: details.vesselSize,
      deliveryLocation: details.deliveryLocation,
      redeliveryLocation: details.redeliveryLocation,
      viaLocation: details.viaLocation,
      duration: details.duration,
      tctNotes: details.tctNotes,
      hireRate: details.hireRate,
      cleaningPrice: details.cleaningPrice,
      supplyPrice: details.supplyPrice,
      tradingExclusions: details.tradingExclusions,
      bunkerDelivery: details.bunkerDelivery,
      bunkerRedelivery: details.bunkerRedelivery,
      cargoExclusionsText: details.cargoExclusionsText,
      ballastBonus: details.ballastBonus,
    }),
    ...(details.types?.includes("Coa") && {
      laycan: null,
      period: details.period,
      liftings: details.liftings,
      nominations: details.nominations,
      coaCargoSize: details.coaCargoSize,
      dischargeLocation: details.dischargeLocation,
      loadLocation: details.loadLocation,
      coaNotes: details.coaNotes,
      freightRate: details.freightRate,
      demurrage: details.demurrage,
    }),
  };
}

export function getCreateOrderPayload(details: UniversalOrderNegotiationFormValues) {
  return {
    chartererAccount: details.chartererAccount,
    laycan: details.laycan,
    cargoType: details.cargoType,
    addressCommission: details.addressCommission,
    brokerCommission: details.brokerCommission,
    types: details.types,
    ...(details.types?.includes("Voy") && {
      cargoSize: details.cargoSize,
      loadLocation: details.loadLocation,
      dischargeLocation: details.dischargeLocation,
      voyageNotes: details.voyageNotes,
      freightRate: details.freightRate,
      demurrage: details.demurrage,
    }),
    ...(details.types?.includes("Tct") && {
      vesselSize: details.vesselSize,
      deliveryLocation: details.deliveryLocation,
      redeliveryLocation: details.redeliveryLocation,
      viaLocation: details.viaLocation,
      duration: details.duration,
      tctNotes: details.tctNotes,
      hireRate: details.hireRate,
      cleaningPrice: details.cleaningPrice,
      supplyPrice: details.supplyPrice,
      tradingExclusions: details.tradingExclusions,
      bunkerDelivery: details.bunkerDelivery,
      bunkerRedelivery: details.bunkerRedelivery,
      cargoExclusionsText: details.cargoExclusionsText,
      ballastBonus: details.ballastBonus,
    }),
    ...(details.types?.includes("Coa") && {
      laycan: null,
      period: details.period,
      liftings: details.liftings,
      nominations: details.nominations,
      coaCargoSize: details.coaCargoSize,
      dischargeLocation: details.dischargeLocation,
      loadLocation: details.loadLocation,
      coaNotes: details.coaNotes,
      freightRate: details.freightRate,
      demurrage: details.demurrage,
    }),
  };
}

export function resolveDetailsByType(values: UniversalOrderNegotiationFormValues) {
  return {
    chartererAccount: values.chartererAccount,
    laycan: values.laycan,
    cargoType: values.cargoType,
    addressCommission: values.addressCommission,
    brokerCommission: values.brokerCommission,
    ...(values.type === "Voy" && {
      cargoSize: values.cargoSize,
      loadLocation: values.loadLocation,
      dischargeLocation: values.dischargeLocation,
      voyageNotes: values.voyageNotes,
      freightRate: values.freightRate,
      demurrage: values.demurrage,
    }),
    ...(values.type === "Tct" && {
      vesselSize: values.vesselSize,
      deliveryLocation: values.deliveryLocation,
      viaLocation: values.viaLocation,
      redeliveryLocation: values.redeliveryLocation,
      duration: values.duration,
      tctNotes: values.tctNotes,
      hireRate: values.hireRate,
      cleaningPrice: values.cleaningPrice,
      supplyPrice: values.supplyPrice,
      tradingExclusions: values.tradingExclusions,
      bunkerDelivery: values.bunkerDelivery,
      bunkerRedelivery: values.bunkerRedelivery,
      cargoExclusionsText: values.cargoExclusionsText,
      ballastBonus: values.ballastBonus,
    }),
    ...(values.type === "Coa" && {
      laycan: null,
      period: values.period,
      liftings: values.liftings,
      nominations: values.nominations,
      coaCargoSize: values.coaCargoSize,
      dischargeLocation: values.dischargeLocation,
      loadLocation: values.loadLocation,
      freightRate: values.freightRate,
      demurrage: values.demurrage,
    }),
  };
}

export function resolveNegotiationCreationDetails(values: UniversalOrderNegotiationFormValues) {
  values = cleanValue(values);

  return {
    [NEGOTIATION_CREATION_DETAIL_KEY_BY_TYPE[values.type || ""]]: {
      charterer: values.chartererAccount && { value: values.chartererAccount, negotiable: false },
      laycan: values.laycan && { value: values.laycan, negotiable: false },
      cargoType: values.cargoType && { value: values.cargoType, negotiable: false },
      addressCommission: values.addressCommission && { value: values.addressCommission, negotiable: false },
      brokerCommission: values.brokerCommission && { value: values.brokerCommission, negotiable: false },
      ...(values.type === "Voy" && {
        cargoSize: values.cargoSize && { value: values.cargoSize, negotiable: false },
        loadLocation: values.loadLocation && { value: values.loadLocation, negotiable: false },
        dischargeLocation: values.dischargeLocation && { value: values.dischargeLocation, negotiable: false },
        notes: values.voyageNotes,
        freightRate: values.freightRate && { value: values.freightRate, negotiable: false },
        demurrage: values.demurrage && { value: values.demurrage, negotiable: false },
      }),
      ...(values.type === "Tct" && {
        vesselSize: values.vesselSize && { value: values.vesselSize, negotiable: false },
        deliveryLocation: values.deliveryLocation && { value: values.deliveryLocation, negotiable: false },
        viaLocation: values.viaLocation && { value: values.viaLocation, negotiable: false },
        redeliveryLocation: values.redeliveryLocation && { value: values.redeliveryLocation, negotiable: false },
        duration: values.duration && { value: values.duration, negotiable: false },
        notes: values.tctNotes,
        hireRate: values.hireRate && { value: values.hireRate, negotiable: false },
        cleaningPrice: values.cleaningPrice && { value: values.cleaningPrice, negotiable: false },
        supplyPrice: values.supplyPrice && { value: values.supplyPrice, negotiable: false },
        tradingExclusions: values.tradingExclusions && { value: values.tradingExclusions, negotiable: false },
        bunkerDelivery: values.bunkerDelivery && { value: values.bunkerDelivery, negotiable: false },
        bunkerRedelivery: values.bunkerRedelivery && { value: values.bunkerRedelivery, negotiable: false },
        cargoExclusionsText: values.cargoExclusionsText && { value: values.cargoExclusionsText, negotiable: false },
        ballastBonus: values.ballastBonus && { value: values.ballastBonus, negotiable: false },
      }),
      ...(values.type === "Coa" && {
        period: values.period && { value: values.period, negotiable: false },
        liftings: values.liftings && { value: values.liftings, negotiable: false },
        nominations: values.nominations && { value: values.nominations, negotiable: false },
        coaCargoSize: values.coaCargoSize && { value: values.coaCargoSize, negotiable: false },
        dischargeLocation: values.dischargeLocation && { value: values.dischargeLocation, negotiable: false },
        loadLocation: values.loadLocation && { value: values.loadLocation, negotiable: false },
        notes: values.coaNotes,
        laycan: null,
        freightRate: values.freightRate && { value: values.freightRate, negotiable: false },
        demurrage: values.demurrage && { value: values.demurrage, negotiable: false },
      }),
    },
  };
}

const NEGOTIATION_CREATION_DETAIL_KEY_BY_TYPE = {
  Voy: "detailsVoy",
  Tct: "detailsTct",
  Coa: "detailsCoa",
} as StringRecord;

export function getTimelimit(values: UniversalOrderNegotiationFormValues) {
  const { timelimitVariant, timelimitDate, timelimitDateOffset } = values;

  const limit = timelimitVariant === "Date" ? timelimitDate : timelimitDateOffset;

  return limit?.toString();
}

export interface UniversalOrderNegotiationFormValues {
  manageSubsOption?: any;
  userTimeZone?: string;
  cpDate?: CustomDate;
  jumpTo?: string;
  types?: TradeAPI["OrderType"][];
  type?: TradeAPI["OrderType"] | TradeAPI["Negotiation"]["type"];
  ownerAccount?: OwnerAccount;
  account?: OwnerAccount;
  timelimitVariant?: TimelimitEditorProps["variant"];
  timelimitDate?: CustomDate | string;
  timelimitDateOffset?: DateOffset | string;
  indicationType?: TradeAPI["IndicationType"];
  orderTemplate?: OrderTemplate;
  termset?: Termset;
  subjectTermset?: SubjectTermset;
  invitee?: CLDDUOwner | string;
  vessels?: Vessels;
  vessel?: Vessel;
  controllers?: [];
  voyageNotes?: VoyageNotes;
  tctNotes?: TCNotes;
  coaNotes?: COANotes;
  notes?: OrderNotes;
  updateNegotiationNoteFor?: string;
  negotiationBrokerChartererNote?: NegotiationNote;
  negotiationOwnerNote?: NegotiationNote;
  chartererContact?: CLDDUCharterer;
  brokerContact?: CLDDUBroker;
  ownerContact?: CLDDUOwner;
  auditNotes?: AuditNotes;
  dealCaptureType?: DealCaptureType;
  previouslyExecutedCpId?: number;

  chartererAccount?: ChartererAccount;
  laycan?: Period;
  period?: Period;
  liftings?: Liftings;
  nominations?: Nominations;
  addressCommission?: Commission;
  brokerCommission?: Commission;
  cargoType?: CargoType;
  cargoSize?: CargoSize;
  coaCargoSize?: COACargoSize;
  dischargeLocation?: DischargeLocation;
  loadLocation?: LoadLocation;
  deliveryLocation?: DeliveryLocation;
  redeliveryLocation?: RedeliveryLocation;
  viaLocation?: ViaLocation;
  duration?: Duration;
  vesselSize?: VesselSize;
  ballastBonus?: BallastBonus;
  demurrage?: Demurrage;
  freightRate?: FreightRate;
  hireRate?: HireRate;
  cleaningPrice?: CleaningPrice;
  supplyPrice?: SupplyPrice;
  tradingExclusions?: TradingExclusions;
  bunkerDelivery?: BunkerDelivery;
  bunkerRedelivery?: BunkerRedelivery;
  cargoExclusionsText?: CargoExclusionsText;
  operationalSubs?: OperationalSubs;
  commercialSubs?: CommercialSubs;

  chartererAccount0?: ChartererAccount;
  laycan0?: Period;
  period0?: Period;
  liftings0?: Liftings;
  nominations0?: Nominations;
  addressCommission0?: Commission;
  brokerCommission0?: Commission;
  cargoType0?: CargoType;
  cargoSize0?: CargoSize;
  coaCargoSize0?: COACargoSize;
  dischargeLocation0?: DischargeLocation;
  loadLocation0?: LoadLocation;
  deliveryLocation0?: DeliveryLocation;
  redeliveryLocation0?: RedeliveryLocation;
  viaLocation0?: ViaLocation;
  duration0?: Duration;
  vesselSize0?: VesselSize;
  ballastBonus0?: BallastBonus;
  demurrage0?: Demurrage;
  freightRate0?: FreightRate;
  hireRate0?: HireRate;
  cleaningPrice0?: CleaningPrice;
  supplyPrice0?: SupplyPrice;
  tradingExclusions0?: TradingExclusions;
  bunkerDelivery0?: BunkerDelivery;
  bunkerRedelivery0?: BunkerRedelivery;
  cargoExclusionsText0?: CargoExclusionsText;
  operationalSubs0?: OperationalSubs;
  commercialSubs0?: CommercialSubs;
}
