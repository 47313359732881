import React from "react";
import Field from "../Field/Field";
import EditorRowEmptyValue from "../EditorRowEmptyValue/EditorRowEmptyValue";
import NewPeriodEditorWithNotes from "../../editors/NewPeriodEditor/NewPeriodEditorWithNotes";
import { ILaycanView } from "sharedFolder/Models/IDetails";

interface ILaycanWithNotesFieldProps<T> {
  isFieldValid: (key: T) => boolean;
  updateForm: (key: T, value: any) => void;
  selectedItem?: ILaycanView;
  isExpanded: boolean;
  isRequired: boolean;
  setExpandedItem: (name: T) => void;
}

export const LaycanWithNotesField = (props: ILaycanWithNotesFieldProps<"laycan">) => {
  return (
    <Field
      id="laycan"
      label={"Laycan * "}
      name={"Laycan"}
      edit={
        <NewPeriodEditorWithNotes
          id={"laycan"}
          focus={true}
          isValid={props.isFieldValid("laycan")}
          required={props.isRequired}
          value={props.selectedItem}
          onChange={(value?: ILaycanView) => {
            props.updateForm("laycan", value);
          }}
        />
      }
      expanded={props.isExpanded}
      expand={() => {
        props.setExpandedItem("laycan");
      }}
      display={
        <>
          {(props.selectedItem && periodDisplayOrEmpty(props.isRequired, props.selectedItem)) || (
            <EditorRowEmptyValue required={props.isRequired} />
          )}
        </>
      }
      isValid={props.isFieldValid("laycan")}
    />
  );
};

const periodDisplayOrEmpty = (isRequiredField: boolean, value?: ILaycanView) => {
  if (value) return `${value.display} ${value.notes}`;
  return null;
};
