import { ICargoSize } from "../../api/negotiations/models";
import { IMapper, withNullable } from "./map";
import { ICargoSizeView } from "../Models/IDetails";

const map: IMapper<ICargoSize, ICargoSizeView> = {
  toApi: (viewModel: ICargoSizeView) => {
    return {
      display: viewModel.display,
      option: viewModel.option || undefined,
      unit: viewModel.unit,
      value: parseInt(viewModel.value || "") || 0,
      variance: parseInt(viewModel.variance || "") || 0,
      notes: viewModel.notes || "",
    };
  },
  toView: (apiModel: ICargoSize) => {
    return {
      display: apiModel.display || "",
      shortDisplay: apiModel.shortDisplay,
      summarydisplay: apiModel.summarydisplay || "",
      option: apiModel.option === undefined ? null : apiModel.option,
      unit: apiModel.unit,
      value: apiModel.value ? String(apiModel.value) : "",
      variance: apiModel.variance !== undefined || apiModel.variance !== null ? String(apiModel.variance) : null,
      notes: apiModel.notes || "",
    };
  },
  emptyViewModel: {
    display: "",
    option: null,
    unit: "",
    value: null,
    variance: null,
    notes: "",
  },
};

export const mapCargoSize = withNullable(map);
