import { formatDate, timeleft, timepassed } from "___REFACTOR___/utils";
import { PrimitiveDataModel, PrimitiveDataModelProps } from "../PrimitiveDataModel";

class Timestamp extends PrimitiveDataModel {
  constructor(data?: Data) {
    super(data);

    this.date = new Date(data || 0);
  }

  toString() {
    if (!this.date) return "";

    return formatDate(this.date, this.format);
  }

  format = "HH:mm YYYY-MM-DD";
}

class TimestampProps extends PrimitiveDataModelProps {}

Timestamp.prototype.Props = TimestampProps;
TimestampProps.prototype.sortKey = "date";

class PeriodStartEnd extends Timestamp {
  format = "DD MMM YY";
}

class CreatedOn extends Timestamp {
  format = "DD MMM HH:mm";
}

class Timepassed extends Timestamp {
  toString() {
    return timepassed(this.date);
  }
}

class Timeleft extends Timestamp {
  toString() {
    return timeleft(this.date);
  }
}

export { Timestamp, TimestampProps, PeriodStartEnd, CreatedOn, Timepassed, Timeleft };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface Timestamp {
  Props: typeof TimestampProps;
  _: TimestampProps;

  data: Data;
  date: Date;
}

interface TimestampProps {
  sortKey: keyof Timestamp;
}

type Data = string;
