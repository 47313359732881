import { makeObservable, observable } from "mobx";
import { Order } from "___REFACTOR___/models/Order";
import { Negotiation } from "___REFACTOR___/models/Negotiation";
import { AggridApi, AggridColumnApi, AggridProps, AggridGridReadyEvent } from "../Aggrid";
import { AggridColumnStateAPI } from "../ColumnStateAPI";
import { AggridState } from "@/components";

export class AggridContext {
  static new(seed?: Partial<AggridContext>) {
    return new AggridContext(seed);
  }

  constructor(seed?: Partial<AggridContext>) {
    Object.assign(this, seed);

    this.api = null;
    this.columnApi = null;
    this.state = null;
    this.columnStateAPI = null;
    this.gridReadyPromise = new Promise((resolve) => {
      this.resolveGridReadyPromise = resolve;
    });

    makeObservable(this, {
      api: observable.ref,
      columnApi: observable.ref,
      state: observable.ref,
      columnStateAPI: observable.ref,
    });
  }

  resetGridReadyPromise() {
    this.gridReadyPromise = new Promise((resolve) => {
      this.resolveGridReadyPromise = resolve;
    });
  }
}

export interface AggridContext {
  api: AggridApi | null;
  columnApi: AggridColumnApi | null;
  state: AggridState | null;
  setState: ((partialState: AggridState) => void) | null;
  columnStateAPI: AggridColumnStateAPI | null;
  props: AggridProps;
  EntityModel?: typeof Negotiation | typeof Order;
  gridReadyPromise: Promise<AggridGridReadyEvent>;
  resolveGridReadyPromise(params: AggridGridReadyEvent): void;
  onGridReady(params: AggridGridReadyEvent): void;
  enableSortingFilteringPromise?: Promise<void>;
  onSortFilterAccess?(): void;
}
