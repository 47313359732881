import classNames from "classnames";
import { useEffect, useRef } from "react";
import formStyles from "__legacy/sharedFolder/styles/form-styles.module.scss";
import styles from "./DistributionListPanel.module.scss";
import Button from "__legacy/sharedFolder/components/common/Button/Button";

interface InlineSingleTextEditorProps {
  disabled?: boolean;
  onClose: () => void;
  onSubmit: () => void;
  placeholder: string;
  setValue: (value: string) => void;
  errorMessage?: string | null;
  value: string;
  dataTest?: string;
}

// TODO: if ended up using this, put it into its own folder
export const InlineSingleTextEditor = (props: InlineSingleTextEditorProps) => {
  const { disabled, errorMessage, onClose, onSubmit, placeholder, setValue, value, dataTest } = props;
  const inputEl = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputEl && inputEl.current) {
      inputEl.current.focus();
    }
  }, []);
  return (
    <div>
      <div className={formStyles.inputRow}>
        <input
          aria-label={"new-entity"}
          ref={inputEl}
          type="text"
          name="new-entity"
          className={formStyles.input}
          placeholder={placeholder}
          value={value}
          data-test={dataTest}
          onChange={(e) => {
            setValue(e.currentTarget.value);
          }}
        />
      </div>
      <div className={classNames(styles.actionsContainer, styles.rightAlgined)}>
        {errorMessage && <p className={styles.formErrorMessage}>{errorMessage}</p>}
        <Button ariaLabel={"cancel-new-entity"} type="flat" onClick={onClose}>
          Cancel
        </Button>
        <Button ariaLabel={"save-new-entity"} type="action" disabled={disabled} onClick={() => onSubmit()}>
          Save
        </Button>
      </div>
    </div>
  );
};
