// BLACKBOX
import { FetchInterceptor } from "__legacy/dashboard/components/FetchInterceptor/FetchInterceptorOld";
import { UserProvider } from "__legacy/dashboard/contexts/UserProvider";
import { useOrder } from "__legacy/orderNegs/useOrder";
import { OrderAttachments } from "__legacy/negotiations/OrderAttachments";

import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { config } from "@/config";
import { Context } from "../../../Orders";
import "./Attachments.scss";

function Attachments() {
  const { orderId } = useParams<StringRecord>();
  const context = useContext(Context);
  const { orderNegotiationStore } = context;
  const order = orderId ? orderNegotiationStore?.upsertOrderModel({ id: orderId }) : undefined;
  const version = order?._.version;
  const { orderModel } = useOrder(config.ctradeUrl, orderId, version);

  useEffect(getNegotiationsDataByOrderId, [order]);

  if (!order) return null;

  if (orderModel) {
    orderModel.negsAttachments = orderModel.negsAttachments || [];
    orderModel.orderAttachments = orderModel.orderAttachments || [];
  }

  return (
    <div className="OrderAttachments LEGACY_CONTAINER">
      <FetchInterceptor baseUrl={config.ctradeUrl} exceptions={["/token$"]}>
        <UserProvider config={config}>
          <OrderAttachments {...config} order={orderModel} orderId={orderId} />
        </UserProvider>
      </FetchInterceptor>
    </div>
  );

  function getNegotiationsDataByOrderId() {
    orderNegotiationStore?.getNegotiationsDataByOrderId(orderId);
  }
}

const Observer = observer(Attachments);

export { Observer as Attachments };
