import { ColumnApi, GridApi } from "@ag-grid-enterprise/all-modules";
import { archiveRequestsLimit } from "sharedFolder/constants";

function selectFirstRowsWithLimit(gridApi: GridApi, limit: number = archiveRequestsLimit) {
  for (let i = 0; i < limit; i++) {
    gridApi.getDisplayedRowAtIndex(i)?.setSelected(true);
  }
}

export function handleMultiSelectCheckboxClick(gridApi: GridApi, checkboxState: CheckboxState) {
  let newCheckboxState: CheckboxState;

  switch (checkboxState) {
    case "unchecked":
      newCheckboxState = "checked";
      selectFirstRowsWithLimit(gridApi);
      break;

    case "checked":
      newCheckboxState = "unchecked";
      gridApi.deselectAll();
      break;

    case "indeterminate":
      newCheckboxState = "checked";
      selectFirstRowsWithLimit(gridApi);
      break;

    default:
      newCheckboxState = checkboxState;
      break;
  }

  return newCheckboxState;
}

export function handleSelectionChange(
  selectedRowsCount: number,
  checkboxState: CheckboxState,
  gridApi: GridApi | undefined | null
) {
  let newCheckboxState: CheckboxState = checkboxState;
  let rowCount: number | undefined = undefined;
  if (gridApi) {
    rowCount = gridApi.getDisplayedRowCount();
  }

  if (selectedRowsCount && selectedRowsCount < archiveRequestsLimit && checkboxState !== "indeterminate") {
    newCheckboxState = "indeterminate";
  }

  if (selectedRowsCount && rowCount && selectedRowsCount === rowCount) {
    newCheckboxState = "checked";
  }

  if (selectedRowsCount >= archiveRequestsLimit) {
    newCheckboxState = "checked";
  }
  if (!selectedRowsCount && checkboxState !== "unchecked") {
    newCheckboxState = "unchecked";
  }

  return newCheckboxState;
}

function getCheckboxColumn(columnApi: ColumnApi) {
  return columnApi?.getAllColumns()?.find((obj) => obj.getColDef().field === "checkbox");
}

export function setCheckboxColumnVisible(visible: boolean, columnApi: ColumnApi) {
  const checkboxColumn = getCheckboxColumn(columnApi);

  if (checkboxColumn) columnApi.setColumnVisible(checkboxColumn, visible);
}

export type CheckboxState = "indeterminate" | "checked" | "unchecked";
