import dayjs from "dayjs";
import { LABEL_MAP } from "___REFACTOR___/constants";
import { TradeAPI } from "___REFACTOR___/apis";
import { PeriodEditor } from "___REFACTOR___/components/common/Editor/type/Period";
import { DataModel, DataModelProps } from "../DataModel";
import { schema } from "./schema";

class Period extends DataModel {
  static get Editor() {
    return PeriodEditor;
  }

  static get schema() {
    return schema;
  }

  constructor(data?: PartialData) {
    super(data);

    this.from = this.start || this.from;
    this.to = this.end || this.to;
  }

  toJSON() {
    const json = DataModel.prototype.toJSON.call(this) as TradeAPI.Period;

    return {
      ...json,
      display: this.toView(), // BE needs this to be generated by FE, lol
      shortDisplay: this.toView(), // added just in case they need this one too, as it is common across many other models
    } as TradeAPI.Period;
  }

  isValid() {
    return schema.isValidSync(this);
  }

  toString() {
    if (!this) return "";

    const start = this.from || this.start;
    const end = this.to || this.end;

    if (!start || !end) return "";

    return `${dayjs.utc(start).format(format)} - ${dayjs.utc(end).format(format)}`;
  }

  toView() {
    if (!this) return "";

    const start = this.from || this.start;
    const end = this.to || this.end;

    if (!start || !end) return "";

    return `${dayjs.utc(start).format(format)} - ${dayjs.utc(end).format(format)}`;
  }

  toGridView() {
    if (!this) return "";

    const start = this.from || this.start;
    const end = this.to || this.end;

    if (!start || !end) return "";

    return `${dayjs.utc(start).format(format)} - ${dayjs.utc(end).format(format)}`;
  }

  toSortValue() {
    if (this.from) return new Date(this.from);
    if (this.start) return new Date(this.start);
  }
}

const format = "DD MMM YY";

class PeriodProps extends DataModelProps {
  get Editor() {
    return PeriodEditor;
  }

  get label() {
    return LABEL_MAP.period;
  }

  get schema() {
    return schema;
  }
}

Period.prototype.Props = PeriodProps;

export { Period, PeriodProps };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface Period extends Data {
  Props: typeof PeriodProps;
  _: PeriodProps;
}

type PartialData = Partial<Data>;
type Data = TradeAPI.Period;
