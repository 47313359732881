import { DataModel, DataModelProps } from "../DataModel";

class NegotiationTypeCount extends DataModel<Data> {
  toString() {
    if (!this.type || !this.count) return "";

    return `${this.type} (${this.count})`;
  }

  toGridView() {
    if (!this.type || !this.count) return "";

    return `${this.type} (${this.count})`;
  }
}

class NegotiationTypeCountProps extends DataModelProps<Data> {}

NegotiationTypeCountProps.prototype.sortKey = "count";

NegotiationTypeCount.prototype._ = new NegotiationTypeCountProps();

export { NegotiationTypeCount, NegotiationTypeCountProps };

/* -------------------------------------------------------------------------- */
/*                                  TYPES                                     */
/* -------------------------------------------------------------------------- */

interface NegotiationTypeCount extends Data {
  _: NegotiationTypeCountProps;
}

type Data = {
  type: string;
  count: number;
};
