import React, { Children, cloneElement, ReactNode } from "react";
import classNames from "classnames";
import { useShallowStateOld } from "___REFACTOR___/utils";
import { Input } from "../Input";
import { Icon } from "../Icon";
import { CheckboxInput, RadioInput } from "../type";
import "./Container.scss";

function Container(props: Props) {
  const {
    desc,
    type,
    status,
    img,
    icon,
    unclearable,
    dataTest,
    value,
    checkedValue = true,
    indeterminate,
    contentBasedWidth,
    onClick,
    hidden,
    domRef,
  } = props;
  const [state, setState] = useShallowStateOld(defaultState);
  const isCheckable = type === "Checkbox" || type === "Radio";
  const checked = isCheckable && checkedValue === value;
  const unchecked = isCheckable && !checked;
  const hasValue = value !== undefined;
  const clearable = props.clearable && !unclearable && hasValue;
  const submitable = props.submitable && hasValue;
  const loading = status?.loading;
  const className = classNames(props.className, type, status?.type, {
    icon: icon || img,
    value,
    status,
    loading,
    clearable,
    submitable,
    checked,
    unchecked,
    indeterminate,
    ...state,
  });
  const children = Children.map(props.children, mapChild);

  if (hidden) return null;

  return (
    <app-input
      class={className}
      title={desc}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      ref={domRef}
    >
      <Icon icon={icon} img={img} dataTest={dataTest} />
      {children}
      <Icon className="clear-value" onClick={onClear} hidden={!clearable} icon="close" align="right" dataTest={dataTest} />
      <Icon
        className="submit-value"
        onClick={onSubmit}
        hidden={!submitable || !value || value === ""}
        icon="arrow-forward"
        align="right"
        dataTest={dataTest}
      />
      {contentBasedWidth && <app-input-content-width-provider>{value}</app-input-content-width-provider>}
    </app-input>
  );

  function mapChild(child: ReactNode) {
    if (!child) return child;

    if (typeof child === "string") return child;

    // @ts-ignore
    return cloneElement(child, { ...child?.props, onFocus, onBlur });
  }

  function onClear(e?: React.MouseEvent<Element, MouseEvent>) {
    e && e.stopPropagation();
    if (props.onClear) props.onClear();
    else props.onChange?.(undefined);
  }

  function onSubmit() {
    if (value || value !== "") {
      if (props.onSubmit) props.onSubmit();
      else console.log("onSubmit prop needs to be defined");
    }
  }

  function onFocus(e) {
    setState({ focus: true });

    props.onFocus?.(e);
  }

  function onBlur(e) {
    setState({ focus: false });

    props.onBlur?.(e);
  }

  function onMouseEnter() {
    setState({ mouseover: true });
  }

  function onMouseLeave() {
    setState({ mouseover: false });
  }
}

const defaultState = {
  focus: false,
  mouseover: false,
} as State;

export { Container };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

// eslint-disable-next-line no-redeclare
declare namespace Container {
  export type DomRef = ReactElementDomRef;
}

interface Props extends Omit<Input.Props, "domRef"> {
  children: React.ReactNode;
  checkedValue?: RadioInput.CheckedValue | CheckboxInput.CheckedValue;
  indeterminate?: boolean;
  domRef?: ReactElementDomRef;
}

interface State {
  focus: boolean;
  mouseover: boolean;
}
