import { TradeAPI } from "___REFACTOR___/apis";
import { DataModel, DataModelProps } from "../../DataModel";

class Handlebar extends DataModel {
  constructor(data?: PartialData) {
    super(data);
  }

  toString() {
    return this.name;
  }
}

class HandlebarProps extends DataModelProps {}

Handlebar.prototype.Props = HandlebarProps;
HandlebarProps.prototype.handlebarKey = "key";

export { Handlebar };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface Handlebar extends Data {
  Props: typeof HandlebarProps;
  _: HandlebarProps;
}

interface HandlebarProps {
  sortKey: keyof Data;
}

type PartialData = Partial<TradeAPI.Termset.Handlebar>;
type Data = TradeAPI.Termset.Handlebar;
