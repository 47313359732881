import React from "react";
import { CommaSeparatedValuesInput } from "___REFACTOR___/components";
import { Field } from "../../Field";
import { Container } from "../../Container";

function CommaSeparatedValuesField(props: CommaSeparatedValuesField.Props) {
  return (
    <Container {...props} type="CommaSeparatedValues">
      <CommaSeparatedValuesInput {...props} />
    </Container>
  );
}

export { CommaSeparatedValuesField };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

declare namespace CommaSeparatedValuesField {
  export interface Props extends Omit<CommaSeparatedValuesInput.Props, "type">, Omit<Field.Props, "value" | "onChange"> {}

  export { CommaSeparatedValuesInput as Input };
}
