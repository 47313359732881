import { getIsDateExpired } from "sharedFolder/components/common/DigitalTimer/useTimer";
import { IGetView } from "sharedFolder/Models/Negotiation/IGetView";
import { LastUpdatedAction } from "sharedFolder/Models/LastUpdatedAction";
import { INegotiationCommonView } from "sharedFolder/Models/Negotiation/INegotiationCommonView";

// Represents the Display Status of the Negotiation
export type NegotiationUIStatus =
  | LastUpdatedAction
  | "RequestInProgress"
  | "IndicationBidSent"
  | "FirmOfferExpired"
  | "FirmBidExpired"
  | "FirmOfferRequested"
  | "FirmOfferCounteredWithIndication"
  | "FirmBidAccepted"
  | "FirmOfferAccepted"
  | "SetExpiryDateOfFirmOffer"
  | "TimerExpired"
  | "RequestErrorOccured"
  | "MainTerms"
  | "CounterFirmBid"
  | "DeclineFirmOfferRequest"
  | "Withdrawn"
  | "SendRecap";

// Represents an 'event' that has happened to a Negotiation
export type NegotiationEvent =
  | LastUpdatedAction
  | "RequestErrorOccured"
  | "RequestInProgress"
  | "SetExpiryDateOfFirmOffer"
  | "TimerExpired"
  | "CounterFirmBid"
  | "DeclineFirmOfferRequest"
  | "SendRecap";

interface INegotiationStateAction {
  type: NegotiationEvent;
  payload: IGetView<INegotiationCommonView>;
}

interface INegotiationState {
  negotiation: IGetView<INegotiationCommonView>;
  negotiationDisplayState: NegotiationUIStatus;
}

const displayMainTermsForStatuses = ["Firm", "MainTerms", "OnSubs", "TermsLoading", "SubsFailed", "SubsLifted", "Fixed"];

export const getInitialState = (negotiation: IGetView<INegotiationCommonView>): INegotiationState => {
  return offerRepStatusReducer(
    { negotiation, negotiationDisplayState: negotiation.lastUpdatedAction },
    { type: "None", payload: negotiation }
  );
};

export const offerRepStatusReducer = (state: INegotiationState, action: INegotiationStateAction): INegotiationState => {
  const { firmBidExpiresOn, firmOfferExpiresOn, lastUpdatedAction, status, lastFirmAction } = action.payload;

  const returnNegotiationUIStatus = (negotiationUIStatus: NegotiationUIStatus) => {
    return {
      negotiationDisplayState: negotiationUIStatus,
      negotiation: action.payload,
    };
  };

  // If the NegotiationEvent is an error, return the error state
  if (action.type === "RequestErrorOccured") {
    return returnNegotiationUIStatus(action.type);
  }

  // If the status of the negotiation is 'Withdrawn'
  // return Withdrawn status
  if (status === "Withdrawn") {
    return returnNegotiationUIStatus("Withdrawn");
  }

  // For a selection of other negotiation statuses, return
  // a NegotiationUIStatus of 'MainTerms', except in the cases where the
  // lastUpdatedAction if either 'FirmBidAccepted' or 'FirmOfferAccepted'
  if (displayMainTermsForStatuses.includes(status)) {
    if (lastUpdatedAction === "FirmBidAccepted") {
      return returnNegotiationUIStatus("FirmBidAccepted");
    }
    if (lastUpdatedAction === "FirmOfferAccepted") {
      return returnNegotiationUIStatus("FirmOfferAccepted");
    }
    return returnNegotiationUIStatus("MainTerms");
  }

  /***********************************************************************/
  /****** WHEN FIRM BID/OFFER ARE CURRENTLY IN PLAY [START] **************/
  /***********************************************************************/
  const isFirmBidInPlay = firmBidExpiresOn && !getIsDateExpired(firmBidExpiresOn) && lastFirmAction === "charterer";

  const isFirmOfferInPlay = firmOfferExpiresOn && !getIsDateExpired(firmOfferExpiresOn) && lastFirmAction === "owner";

  if (isFirmOfferInPlay) {
    return returnNegotiationUIStatus(lastUpdatedAction === "IndicationBid" ? "FirmOfferCounteredWithIndication" : "FirmOffer");
  }

  if (isFirmBidInPlay && action.type !== "CounterFirmBid") {
    if (action.type === "SetExpiryDateOfFirmOffer") {
      return state;
    }
    return returnNegotiationUIStatus("FirmBid");
  }
  /*********************************************************************/
  /****** WHEN FIRM BID/OFFER ARE CURRENTLY IN PLAY [END] **************/
  /*********************************************************************/

  switch (action.type) {
    case "None":
      return state;
    case "IndicationOffer":
    case "DeclineFirmOfferRequest":
      return returnNegotiationUIStatus(action.type);

    // When the TimerExpired event is called the assumption is that the negotiation has been in either a
    // FirmOffer or FirmBid negotiationDisplayState and therefore needs to be set to either
    // FirmOfferExpired or FirBidExpired
    case "TimerExpired":
      if (state.negotiationDisplayState === "FirmOffer") {
        return returnNegotiationUIStatus("FirmOfferExpired");
      }
      if (state.negotiationDisplayState === "FirmBid") {
        return returnNegotiationUIStatus("FirmBidExpired");
      }
      return returnNegotiationUIStatus(action.type);

    // When FirmOffer/Bid is called determine if there is firm event in the negotiation
    // if this event has expired return the Expired Status
    case "FirmOffer":
      if (firmOfferExpiresOn !== null && getIsDateExpired(firmOfferExpiresOn)) {
        return returnNegotiationUIStatus("FirmOfferExpired");
      }
      return returnNegotiationUIStatus(action.type);
    case "FirmBid":
      if (firmBidExpiresOn !== null && getIsDateExpired(firmBidExpiresOn)) {
        return returnNegotiationUIStatus("FirmBidExpired");
      }
      return returnNegotiationUIStatus(action.type);

    // This case does the following:
    // - In the scenario where the negotiation is brand new the 'actions' default to
    // {brokerCharterer: "indicated", lastUpdatedBy: "brokerCharterer"} and in this scenario we do not want
    // to display the message bar 'The Charterer/Broker has indicated' because this hasn't actually happened yet
    // so we wait to see that the action is "IndicationBid" and the version has been incremented
    // - In the scenario where the current state is "FirmOffer"|"FirmBid"|"IndcationBidSent" we will want to
    // ignore the indication and keep the state of the UI the same
    //
    // This entire bit of logic should perhaps be removed from the reducer and put closer to the place where it is being used
    // which is the messagesReducer
    case "IndicationBid":
      if (
        state.negotiationDisplayState === "FirmOffer" ||
        state.negotiationDisplayState === "FirmBid" ||
        state.negotiationDisplayState === "IndicationBidSent"
      ) {
        return returnNegotiationUIStatus(state.negotiationDisplayState);
      }
      if (state.negotiation.version < action.payload.version) {
        return returnNegotiationUIStatus("IndicationBidSent");
      }
      return returnNegotiationUIStatus(action.type);

    default:
      return returnNegotiationUIStatus(action.type);
  }
};
