import { config } from "@/config";
import { Config, Request, Response } from "@/request";
import { auth } from "@/models";
import { vesselScoreStore } from "@/stores";
import { actionRetryWithResponce } from "@/utils";

class VesselScoreAPI {
  async setup() {
    this.request = new Request({
      baseURL: config.vesselScoreApiUrl,
      ignoreStatus: { 503: true, 500: true },
      retryStatus: { 502: true },
    } as Config);

    await auth.central.promise;

    const headers = this.request.axios.defaults.headers.common;

    Object.assign(headers, { Authorization: `Bearer ${auth.central.token}`, "origin-app": "SeaTrade" });
  }

  accessRights = {
    get: () => {
      const url = "/access-rights";

      return this.request.get<VesselScoreAPI.Ept.AccessRights.Get.Res.Body>(url);
    },
  };

  inspections = {
    get: (imosSearch: string) => {
      const url = "/inspections";

      return this.request.get<VesselScoreAPI.Ept.Inspections.Get.Res.Body>(`${url}${imosSearch}`);
    },
  };

  sanctions = {
    get: (imosSearch: string) => {
      const url = "/sanctions";

      return this.request.get<VesselScoreAPI.Ept.Sanctions.Get.Res.Body>(`${url}${imosSearch}`);
    },
  };

  getAllResults = async (negs) => {
    let vesselImosSearch = "";

    negs.forEach((neg, negsIndex) => {
      neg.vessels.forEach((vessel, vesselsIndex) => {
        if (!(vessel.vesselImo === 0))
          vesselImosSearch = vesselImosSearch + `${vesselsIndex === 0 && negsIndex === 0 ? "?" : "&"}imos=${vessel.vesselImo}`;
      });
    });

    if (vesselImosSearch[0] === "&") {
      const stringAsArray = [...vesselImosSearch];
      stringAsArray[0] = "?";
      vesselImosSearch = stringAsArray.join("");
    }

    const sectionAccess = vesselScoreStore.security.sectionAccess;
    const apiCallRetries = 5;
    const apiCallRetriesDelay = 10000;
    let inspectionsData: VesselScoreAPI.Ept.Inspections.Get.Res.Body | undefined;
    let sanctionsData: VesselScoreAPI.Ept.Sanctions.Get.Res.Body | undefined;

    const handleSuccessInspections = (inspectionsRes: Response<VesselScoreAPI.Ept.Inspections.Get.Res.Body>) => {
      inspectionsData = inspectionsRes?.data;
      inspectionsRes?.data.inspectionResults.forEach((inspection) => (inspection.imo = inspection.imo.toString()));

      return inspectionsRes;
    };

    const handleSuccessSanctions = (sanctionsRes: Response<VesselScoreAPI.Ept.Sanctions.Get.Res.Body>) => {
      sanctionsData = sanctionsRes.data;
      return sanctionsRes;
    };

    const handleFailure = (
      type: "Inspections" | "Sanctions",
      result: Response<VesselScoreAPI.Ept.Inspections.Get.Res.Body> | Response<VesselScoreAPI.Ept.Sanctions.Get.Res.Body>
    ) => {
      logError(`${type} request failed`, result);

      return result;
    };

    if (vesselImosSearch.length > 0) {
      if (sectionAccess.has("Inspections")) {
        const inspectionsRes = await actionRetryWithResponce(
          {
            action: () => this.inspections.get(vesselImosSearch),
            handleSuccess: (res) => handleSuccessInspections(res),
            handleFailure: (res) => handleFailure("Inspections", res),
          },
          apiCallRetries,
          apiCallRetriesDelay
        );

        if (!inspectionsRes?.ok) {
          return inspectionsRes;
        }
      }
      if (sectionAccess.has("Sanctions")) {
        const sanctionsRes = await actionRetryWithResponce(
          {
            action: () => this.sanctions.get(vesselImosSearch),
            handleSuccess: (res) => handleSuccessSanctions(res),
            handleFailure: (res) => handleFailure("Sanctions", res),
          },
          apiCallRetries,
          apiCallRetriesDelay
        );

        if (!sanctionsRes?.ok) {
          return sanctionsRes;
        }
      }
    }

    const vesselScoreMap = new Map<string, VesselScoreAPI.VesselScore>();

    if (sectionAccess.has("Inspections") && sectionAccess.has("Sanctions")) {
      sanctionsData?.sanctionResults.forEach((sanction) => {
        inspectionsData?.inspectionResults.forEach((inspection) => {
          if (sanction.imo === inspection.imo) {
            vesselScoreMap.set(sanction.imo, {
              imo: sanction.imo,
              inspectionResult: inspection,
              sanctionResult: sanction,
            } as VesselScoreAPI.VesselScore);
          }
        });
      });
    }

    if (sectionAccess.has("Inspections") && !sectionAccess.has("Sanctions")) {
      inspectionsData?.inspectionResults.forEach((inspection) => {
        vesselScoreMap.set(inspection.imo, {
          imo: inspection.imo,
          inspectionResult: inspection,
        } as VesselScoreAPI.VesselScore);
      });
    }

    if (!sectionAccess.has("Inspections") && sectionAccess.has("Sanctions")) {
      sanctionsData?.sanctionResults.forEach((sanction) => {
        vesselScoreMap.set(sanction.imo, {
          imo: sanction.imo,
          sanctionResult: sanction,
        } as VesselScoreAPI.VesselScore);
      });
    }

    vesselScoreMap.set("0", {
      imo: "0",
    } as VesselScoreAPI.VesselScore);

    return vesselScoreMap;
  };
}

function logError(message, dump) {
  console.error(message, dump);
}

// Keeping this here for now. Not sure if we might decide on using this in some way later on.
// const errorDialogProps = {
//   status: {
//     type: "error",
//     title: "Controller Order Fetch Failed",
//   } as Status,
//   dataTest: "order-negotiation-controller-order-fetch-error",
// };

const vesselScoreAPI = new VesselScoreAPI();

export { vesselScoreAPI, VesselScoreAPI };

/* -------------------------------------------------------------------------- */
/*                               TYPES                                        */
/* -------------------------------------------------------------------------- */

interface VesselScoreAPI {
  request: Request;
}

declare namespace VesselScoreAPI {
  namespace Ept {
    namespace AccessRights {
      namespace Get {
        namespace Res {
          type Body = AccessRights;
        }
      }
    }
    namespace Inspections {
      namespace Get {
        namespace Res {
          interface Body {
            inspectionResults: InspectionResult[];
            failedInspections: any;
          }
        }
      }
    }

    namespace Sanctions {
      namespace Get {
        namespace Res {
          interface Body {
            sanctionResults: SanctionResult[];
            failedInspections: any;
          }
        }
      }
    }
  }

  namespace Provider {
    type Id = number;
  }
  interface AccessRights {
    hasSanctionsAccess: boolean;
    hasInspectionsAccess: boolean;
    currentSanctionsProvider: string;
    currentInspectionsProvider: string;
  }

  interface InspectionResult {
    imo: string;
    provider: string;
    safetyScore: {
      safetyScoreValue: number;
      safetyInspectionDate: string;
      indicativeScore: boolean;
      docSafetyScore: string;
      displayValue: string;
      moreDetailsDisplayValue: string;
    };
    ghg: {
      rating: string;
      ratingDate: string;
      evdi: string;
      verified: boolean;
      plus: boolean;
      displayValue: string;
      moreDetailsDisplayValue: string;
    };
    latestInspection: {
      required: boolean;
      lastInspectionOutcome: string;
      lastInspectionValidity: string;
      displayValue: string;
      moreDetailsDisplayValue: string;
    };
  }

  interface SanctionResult {
    imo: string;
    program: [string];
    sanctionedCountryFlag: boolean;
    sanctionedVessel: boolean;
    sanctionedCompany: boolean;
    riskScore: number;
    riskLevel: string;
    provider: string;
    displayValue: string;
    lastModified: string;
    moreDetailsDisplayValue: string;
  }

  interface VesselScore {
    imo: string;
    inspectionResult: InspectionResult;
    sanctionResult: SanctionResult;
  }

  interface NegOrderImo {
    sourceSystemId: string[];
    imo: string;
  }
}
